import { Message, StringValue } from '@bufbuild/protobuf';
import { RouteParams } from './bloc';
import { MapStringString } from './utils';
import { isNil } from './utils/common.helpers';
import { isNotEmptyString } from './utils/strings.helpers';

export function base64ToBytes(base64: string): Uint8Array {
  const binaryString = window.atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}

export function bytesToBase64(bytes: Uint8Array): string {
  let binaryString = '';
  for (let i = 0; i < bytes.byteLength; i++) {
    binaryString += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binaryString);
}

export class ProtoUtils {
  static isEmpty(proto: Message): boolean {
    return proto.toBinary().length === 0;
  }

  static stringValueFromString(val?: string): StringValue {
    const str = new StringValue();

    if (isNil(val)) {
      return str;
    }
    str.value = val;
    return str;
  }

  /// Convert a base 64 encoded query params to the wanted proto message
  ///
  /// Return default proto if decoding failed
  static protoFromRouteParams<T extends Message>(
    deserializeBinary: (bytes: Uint8Array) => T,
    routeParams: RouteParams,
    queryKey: string,
  ): T {
    let proto: T;

    try {
      const binary = base64ToBytes(routeParams.queryParameters[queryKey]);
      proto = deserializeBinary(binary);
    } catch (_) {
      proto = deserializeBinary(new Uint8Array(0));
    }

    return proto;
  }

  static protoToRouteParams<T extends Message>(
    proto: T,
    queryKey: string,
    // TODO(hadrien): cleanup this routeParams
    // this function should not be responsible of merging route params together
    routeParams?: RouteParams,
  ): RouteParams {
    const base64 = bytesToBase64(proto.toBinary());

    const queryParameters: MapStringString = {
      ...routeParams?.queryParameters,
    };
    if (isNotEmptyString(base64)) {
      queryParameters[queryKey] = base64;
    } else {
      delete queryParameters[queryKey];
    }

    return {
      parameters: {},
      queryParameters,
    };
  }
}

import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianEntityFields } from '@frontend2/proto/librarian/proto/entity_fields_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianEntityFieldsClient = PromiseClient<
  typeof LibrarianEntityFields
>;

export const LibrarianEntityFieldsClientProvider = new InjectionToken(
  'LibrarianEntityFieldsClient',
  {
    factory: (): LibrarianEntityFieldsClient =>
      createPromiseClient(LibrarianEntityFields, inject(GRPC_TRANSPORT)),
  },
);

export function injectLibrarianEntityFieldsClient(): LibrarianEntityFieldsClient {
  return inject(LibrarianEntityFieldsClientProvider);
}

import {
  CreatorCardSnippet,
  CreatorTiniestCard,
  GenerikInfoCard,
} from '@frontend2/proto/librarian/proto/creators_pb';
import { isEmptyString } from '../../utils/strings.helpers';

export function isGhostCreator(creator: CreatorTiniestCard): boolean {
  return isEmptyString(creator.userId);
}

export function isGhostSnippet(creator: CreatorCardSnippet): boolean {
  return isEmptyString(creator.userId);
}

//sum of followers from all networks
export function getAllFollowersCount(infos: GenerikInfoCard[]): bigint {
  const followersCounts = infos.map(
    (i) => i.count?.followersCount ?? BigInt(0),
  );
  return followersCounts.reduce((acc, curr) => acc + curr, BigInt(0));
}

//
//This file defines the librarian search service.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/search.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { CreatorCardSnippet, GenerikInfoCard } from "./creators_pb";
import { FavoritesLabels, InfluencerCampaigns } from "../../common/proto/campaign_pb";
import { Account } from "../../universal/arkansas/proto/arkansas_pb";

/**
 * @generated from message librarian.proto.LocationInfo
 */
export class LocationInfo extends Message<LocationInfo> {
  /**
   * @generated from field: double latitude = 4;
   */
  latitude = 0;

  /**
   * @generated from field: double longitude = 5;
   */
  longitude = 0;

  constructor(data?: PartialMessage<LocationInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LocationInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 4, name: "latitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 5, name: "longitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LocationInfo {
    return new LocationInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LocationInfo {
    return new LocationInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LocationInfo {
    return new LocationInfo().fromJsonString(jsonString, options);
  }

  static equals(a: LocationInfo | PlainMessage<LocationInfo> | undefined, b: LocationInfo | PlainMessage<LocationInfo> | undefined): boolean {
    return proto3.util.equals(LocationInfo, a, b);
  }
}

/**
 * @generated from message librarian.proto.UserSearchResponse
 */
export class UserSearchResponse extends Message<UserSearchResponse> {
  /**
   * @generated from field: librarian.proto.CreatorCardSnippet base_snippet = 1;
   */
  baseSnippet?: CreatorCardSnippet;

  /**
   * @generated from field: common.proto.InfluencerCampaigns statuses = 5;
   */
  statuses?: InfluencerCampaigns;

  /**
   * @generated from field: common.proto.FavoritesLabels labels = 8;
   */
  labels?: FavoritesLabels;

  /**
   * @generated from field: repeated librarian.proto.GenerikInfoCard network_info = 11;
   */
  networkInfo: GenerikInfoCard[] = [];

  /**
   * @generated from field: bool in_pool = 12;
   */
  inPool = false;

  constructor(data?: PartialMessage<UserSearchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UserSearchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_snippet", kind: "message", T: CreatorCardSnippet },
    { no: 5, name: "statuses", kind: "message", T: InfluencerCampaigns },
    { no: 8, name: "labels", kind: "message", T: FavoritesLabels },
    { no: 11, name: "network_info", kind: "message", T: GenerikInfoCard, repeated: true },
    { no: 12, name: "in_pool", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserSearchResponse {
    return new UserSearchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserSearchResponse {
    return new UserSearchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserSearchResponse {
    return new UserSearchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UserSearchResponse | PlainMessage<UserSearchResponse> | undefined, b: UserSearchResponse | PlainMessage<UserSearchResponse> | undefined): boolean {
    return proto3.util.equals(UserSearchResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.UserSearchResponses
 */
export class UserSearchResponses extends Message<UserSearchResponses> {
  /**
   * @generated from field: repeated librarian.proto.UserSearchResponse responses = 1;
   */
  responses: UserSearchResponse[] = [];

  /**
   * @generated from field: int64 total_hits = 2;
   */
  totalHits = protoInt64.zero;

  /**
   * Pagination flag - We need to signal if there is a pagination
   * because we might return less results than expected if a user has gone private.
   *
   * @generated from field: bool has_pagination = 3;
   */
  hasPagination = false;

  /**
   * When true means the some of the details are hidden.
   * This happens when the user is not authorized to do the search.
   *
   * @generated from field: bool is_hidden = 4;
   */
  isHidden = false;

  constructor(data?: PartialMessage<UserSearchResponses>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UserSearchResponses";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "responses", kind: "message", T: UserSearchResponse, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "has_pagination", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "is_hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserSearchResponses {
    return new UserSearchResponses().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserSearchResponses {
    return new UserSearchResponses().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserSearchResponses {
    return new UserSearchResponses().fromJsonString(jsonString, options);
  }

  static equals(a: UserSearchResponses | PlainMessage<UserSearchResponses> | undefined, b: UserSearchResponses | PlainMessage<UserSearchResponses> | undefined): boolean {
    return proto3.util.equals(UserSearchResponses, a, b);
  }
}

/**
 * @generated from message librarian.proto.EnrichedAccount
 */
export class EnrichedAccount extends Message<EnrichedAccount> {
  /**
   * @generated from field: universal.arkansas.proto.Account account = 1;
   */
  account?: Account;

  /**
   * @generated from field: common.proto.InfluencerCampaigns statuses = 2;
   */
  statuses?: InfluencerCampaigns;

  /**
   * @generated from field: common.proto.FavoritesLabels labels = 3;
   */
  labels?: FavoritesLabels;

  constructor(data?: PartialMessage<EnrichedAccount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EnrichedAccount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "message", T: Account },
    { no: 2, name: "statuses", kind: "message", T: InfluencerCampaigns },
    { no: 3, name: "labels", kind: "message", T: FavoritesLabels },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnrichedAccount {
    return new EnrichedAccount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnrichedAccount {
    return new EnrichedAccount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnrichedAccount {
    return new EnrichedAccount().fromJsonString(jsonString, options);
  }

  static equals(a: EnrichedAccount | PlainMessage<EnrichedAccount> | undefined, b: EnrichedAccount | PlainMessage<EnrichedAccount> | undefined): boolean {
    return proto3.util.equals(EnrichedAccount, a, b);
  }
}

/**
 * @generated from message librarian.proto.EnrichedSearchResponse
 */
export class EnrichedSearchResponse extends Message<EnrichedSearchResponse> {
  /**
   * @generated from field: int32 total = 1;
   */
  total = 0;

  /**
   * @generated from field: repeated librarian.proto.EnrichedAccount enriched = 2;
   */
  enriched: EnrichedAccount[] = [];

  constructor(data?: PartialMessage<EnrichedSearchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EnrichedSearchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "enriched", kind: "message", T: EnrichedAccount, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EnrichedSearchResponse {
    return new EnrichedSearchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EnrichedSearchResponse {
    return new EnrichedSearchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EnrichedSearchResponse {
    return new EnrichedSearchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: EnrichedSearchResponse | PlainMessage<EnrichedSearchResponse> | undefined, b: EnrichedSearchResponse | PlainMessage<EnrichedSearchResponse> | undefined): boolean {
    return proto3.util.equals(EnrichedSearchResponse, a, b);
  }
}


import { Timestamp } from '@bufbuild/protobuf';
import { isEmptyString } from '@frontend2/core';

export interface NoteInfo {
  readonly originalContent: string;
  readonly authorName: string;
  readonly updated: Timestamp;
  readonly initial: string;
  readonly noteId?: bigint;
}

export function createNoteInfo(partial?: Partial<NoteInfo>): NoteInfo {
  return {
    originalContent: partial?.originalContent ?? '',
    authorName: partial?.authorName ?? '',
    updated: partial?.updated ?? Timestamp.fromDate(new Date()),
    initial: partial?.initial ?? '',
    noteId: partial?.noteId,
  };
}

export function isGhostNote(note: NoteInfo): boolean {
  return isEmptyString(note.originalContent);
}

export enum NoteState {
  noContent,
  editing,
  saved,
  deleteConfirmation,
}

export function initialNoteState(note: NoteInfo): NoteState {
  if (isGhostNote(note)) {
    return NoteState.noContent;
  }
  return NoteState.saved;
}

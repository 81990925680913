import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { Epson } from '@frontend2/proto/librarian/proto/epson_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianeEpsonClient = PromiseClient<typeof Epson>;

export const LibrarianEpsonClientProvider = new InjectionToken(
  'LibrarianeEpsonClient',
  {
    factory: (): LibrarianeEpsonClient =>
      createPromiseClient(Epson, inject(GRPC_TRANSPORT)),
  },
);

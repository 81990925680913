<div class="footer">
  <span class="copyright"> © {{ year }} </span>
  <a
    class="black"
    target="_blank"
    rel="noopener"
    href="https://lefty.io"
    i18n
  >
    About
  </a>
  <a
    class="black"
    target="_blank"
    rel="noopener"
    href="https://lab.lefty.io"
    i18n
  >
    Blog
  </a>
  <a
    class="black"
    target="_blank"
    rel="noopener"
    href="http://help.lefty.io/collections/832925-legal"
    i18n
  >
    Legal
  </a>
</div>

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/profile_merge_requests.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { ProfileMergeCreateRequest, ProfileMergeEditRequest, ProfileMergeRequestsFilter, ProfileMergeRequestsPb, ProfileMergeValidateRequest } from "./profile_merge_requests_pb";
import { Empty, Int64Value, MethodKind, StringValue } from "@bufbuild/protobuf";

/**
 * @generated from service librarian.proto.LibrarianProfileMergeRequests
 */
export const LibrarianProfileMergeRequests = {
  typeName: "librarian.proto.LibrarianProfileMergeRequests",
  methods: {
    /**
     * return a task handle to create profile merge request(s)
     * if there are errors, it doesn't create any request and firestore output should contain a ProfileMergeRequestErrors
     * message (but the task still returns an OK status)
     *
     * @generated from rpc librarian.proto.LibrarianProfileMergeRequests.CreateProfileMergeRequest
     */
    createProfileMergeRequest: {
      name: "CreateProfileMergeRequest",
      I: ProfileMergeCreateRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * return a task handle to edit a profile merge request
     * if there are errors, it doesn't edit the request and firestore output should contain a ProfileMergeRequestErrors
     * message (but the task still returns an OK status)
     *
     * @generated from rpc librarian.proto.LibrarianProfileMergeRequests.EditProfileMergeRequest
     */
    editProfileMergeRequest: {
      name: "EditProfileMergeRequest",
      I: ProfileMergeEditRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * for admin and client side
     *
     * @generated from rpc librarian.proto.LibrarianProfileMergeRequests.GetProfileMergeRequests
     */
    getProfileMergeRequests: {
      name: "GetProfileMergeRequests",
      I: ProfileMergeRequestsFilter,
      O: ProfileMergeRequestsPb,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianProfileMergeRequests.ValidateProfileMergeRequest
     */
    validateProfileMergeRequest: {
      name: "ValidateProfileMergeRequest",
      I: ProfileMergeValidateRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianProfileMergeRequests.DeleteProfileMergeRequest
     */
    deleteProfileMergeRequest: {
      name: "DeleteProfileMergeRequest",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;


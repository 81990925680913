import {
  CastingOptionsPb,
  CollaboratorCastingInfluencerItem,
  NoteCastingProto,
} from '@frontend2/proto/librarian/proto/casting_pb';
import { InfluencerCastingStatus } from '@frontend2/proto/librarian/proto/common_pb';
import { CreatorCardSnippet } from '@frontend2/proto/librarian/proto/creators_pb';
import {
  createInfluencer,
  isGhostInfluencer,
} from '../influencer/influencer.helpers';
import { isEmptyArray } from '../utils/common.helpers';
import { arrayToMap, updateElementInArray } from '../utils/iterables.helpers';
import { InfluencerCasting } from './casting-influencer.models';

export function createInfluencerCasting(
  idOrSnippet: string | CreatorCardSnippet,
  castingInfluencerId: bigint,
  options?: Partial<InfluencerCasting>,
): InfluencerCasting {
  const base = createInfluencer(idOrSnippet, options);
  const castingStatus =
    options?.castingStatus ??
    InfluencerCastingStatus.INFLUENCER_CASTING_PENDING;

  return {
    ...base,
    castingInfluencerId,
    castingStatus,
    comments: options?.comments ?? [],
  };
}

let ghostInfluencerCache: InfluencerCasting | undefined;

export function createGhostInfluencerCasting(): InfluencerCasting {
  return (ghostInfluencerCache ??= createInfluencerCasting('', BigInt(0)));
}

export function updateInfluencerCastingStatus(
  influencer: InfluencerCasting,
  newStatus: InfluencerCastingStatus,
): InfluencerCasting {
  return {
    ...influencer,
    castingStatus: newStatus,
  };
}

export function isInfluencerApproved(influencer: InfluencerCasting): boolean {
  return (
    influencer.castingStatus ===
    InfluencerCastingStatus.INFLUENCER_CASTING_APPROVED
  );
}

export function isInfluencerRejected(influencer: InfluencerCasting): boolean {
  return (
    influencer.castingStatus ===
    InfluencerCastingStatus.INFLUENCER_CASTING_REJECTED
  );
}

export function isInfluencerPending(influencer: InfluencerCasting): boolean {
  return (
    influencer.castingStatus ===
    InfluencerCastingStatus.INFLUENCER_CASTING_PENDING
  );
}

export function addCommentToInfluencer(
  influencer: InfluencerCasting,
  comment: NoteCastingProto,
): InfluencerCasting {
  const comments = [...influencer.comments, comment].filter(
    (c) => c.noteId !== 0n,
  );

  return {
    ...influencer,
    comments,
  };
}

export function updateCommentOfInfluencer(
  influencer: InfluencerCasting,
  comment: NoteCastingProto,
): InfluencerCasting {
  const comments = updateElementInArray(influencer.comments, {
    predicate: (c) => c.noteId === comment.noteId,
    modifier: () => comment,
    stopOnFirst: true,
  });

  return {
    ...influencer,
    comments,
  };
}

export function deleteCommentOfInfluencer(
  influencer: InfluencerCasting,
  commentId: bigint,
): InfluencerCasting {
  const comments = influencer.comments.filter((c) => c.noteId !== commentId);

  return {
    ...influencer,
    comments,
  };
}

export function createInfluencerFromCastingItem(
  profile: CollaboratorCastingInfluencerItem,
): InfluencerCasting {
  const baseSnippet = profile.baseSnippet ?? new CreatorCardSnippet();

  const comments = profile.notes;
  comments.sort((a, b) => {
    const aU = a.updated;
    const bU = b.updated;
    if (aU && bU) {
      return Number(aU.seconds - bU.seconds);
    }
    return 0;
  });

  return createInfluencerCasting(baseSnippet, profile.castingInfluencerId, {
    comments,
    castingStatus: profile.influencerStatus,
    networkInfos: arrayToMap(profile.networkInfo, (val) => val.network),
  });
}

export function updateInfluencerInList(
  influencers: InfluencerCasting[],
  influencer: InfluencerCasting,
): InfluencerCasting[] {
  return updateElementInArray(influencers, {
    predicate: (i) => i.id === influencer.id,
    modifier: () => influencer,
    stopOnFirst: true,
  });
}

export function sanitizeInfluencerCastingNetworks(
  influencer: InfluencerCasting,
  castingOptions: CastingOptionsPb,
): InfluencerCasting {
  if (isGhostInfluencer(influencer)) {
    return influencer;
  }

  const optionsNetworks = castingOptions.networks;
  const influencerNetworks = influencer.networks;

  if (isEmptyArray(optionsNetworks)) {
    return influencer;
  }

  return {
    ...influencer,
    networks: influencerNetworks.filter((n) => optionsNetworks.includes(n)),
  };
}

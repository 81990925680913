// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/affiliation_promo_codes.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreatePromoCodesRequest, CreatePromoCodesResponse, EditPromoCodeRequest, EditPromoCodeResponse, GetPromoCodeDetailsResponse, GetPromoCodesRequest, GetPromoCodesResponse, InitGetPromoCodesListsResponse, ListAffiliatesRequest, ListAffiliatesResponse, UpdatePromoCodeRequest, UpdatePromoCodeResponse } from "./affiliation_promo_codes_pb";
import { Empty, Int64Value, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service librarian.proto.LibrarianAffiliationPromoCodes
 */
export const LibrarianAffiliationPromoCodes = {
  typeName: "librarian.proto.LibrarianAffiliationPromoCodes",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliationPromoCodes.CreatePromoCodesAPI
     */
    createPromoCodesAPI: {
      name: "CreatePromoCodesAPI",
      I: CreatePromoCodesRequest,
      O: CreatePromoCodesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliationPromoCodes.GetPromoCodesAPI
     */
    getPromoCodesAPI: {
      name: "GetPromoCodesAPI",
      I: GetPromoCodesRequest,
      O: GetPromoCodesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliationPromoCodes.InitGetPromoCodesListsAPI
     */
    initGetPromoCodesListsAPI: {
      name: "InitGetPromoCodesListsAPI",
      I: Empty,
      O: InitGetPromoCodesListsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliationPromoCodes.GetPromoCodeDetailsAPI
     */
    getPromoCodeDetailsAPI: {
      name: "GetPromoCodeDetailsAPI",
      I: Int64Value,
      O: GetPromoCodeDetailsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliationPromoCodes.UpdatePromoCodeAPI
     */
    updatePromoCodeAPI: {
      name: "UpdatePromoCodeAPI",
      I: UpdatePromoCodeRequest,
      O: UpdatePromoCodeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliationPromoCodes.EditPromoCodeAPI
     */
    editPromoCodeAPI: {
      name: "EditPromoCodeAPI",
      I: EditPromoCodeRequest,
      O: EditPromoCodeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliationPromoCodes.ListAffiliatesAPI
     */
    listAffiliatesAPI: {
      name: "ListAffiliatesAPI",
      I: ListAffiliatesRequest,
      O: ListAffiliatesResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;


import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianAudience } from '@frontend2/proto/librarian/proto/audience_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianAudienceClient = PromiseClient<typeof LibrarianAudience>;

export const LibrarianAudienceClientProvider = new InjectionToken(
  'LibrarianAudienceClient',
  {
    factory: (): LibrarianAudienceClient =>
      createPromiseClient(LibrarianAudience, inject(GRPC_TRANSPORT)),
  },
);

import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  inject,
  viewChild,
} from '@angular/core';
import { isNil, isNotEmptyString } from '@frontend2/core';
import { LeftyComponent } from '../utils';
import { LeftyIconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lefty-feedback',
  templateUrl: 'lefty-feedback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LeftyIconComponent],
})
export class LeftyFeedbackComponent extends LeftyComponent {
  private readonly _el = inject(ElementRef);

  @HostBinding('class')
  get className(): string {
    return `lefty-feedback ${this.type}`;
  }

  @Input()
  type: FeedbackType = 'info';

  @Input()
  title = '';

  get isNotEmptyTitle(): boolean {
    return isNotEmptyString(this.title);
  }

  titleContainer = viewChild<ElementRef>('titleContainer');

  @HostBinding('class.has-title')
  get hasTitle(): boolean {
    if (this.isNotEmptyTitle) {
      return true;
    }

    const el = this.titleContainer()?.nativeElement;
    if (isNil(el) || isNil(el.children)) {
      return false;
    }
    return el.children.length !== 0;
  }

  @HostBinding('class.hide-icon')
  get iconIsHidden(): boolean {
    return this.hideIcon;
  }

  @Input()
  hideIcon = false;

  get icon(): string {
    // get feedback icon from css variable
    return getComputedStyle(this._el.nativeElement).getPropertyValue(
      `--feedback-${this.type}-icon`,
    );
  }
}

export type FeedbackType =
  | 'info'
  | 'warning'
  | 'error'
  | 'success'
  | 'unavailable';

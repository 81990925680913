import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Type,
  computed,
  inject,
  input,
  model,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LeftyAuthBloc } from '@frontend2/api';
import { Networks } from '@frontend2/core';
import { isGhostCreator } from '@frontend2/proto-helpers/librarian/creator-pb.helpers';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { CreatorTiniestCard } from '@frontend2/proto/librarian/proto/creators_pb';
import { NetworkIconComponent } from '../../icon/network.component';
import { LeftyFormAutocompleteComponent } from '../../lefty-form-autocomplete/lefty-form-autocomplete.component';
import { LeftyFormSelectComponent } from '../../lefty-form-select/lefty-form-select.component';
import { NetworkDropdownItemComponent } from '../../networks/network-dropdown-item.component';
import { InfluencerAutoCompleteBase } from '../influencer-autocomplete-base';
import { InfluencerWithNetworkInfoAutocomplete } from '../influencer-autocomplete.helpers';
import { InfluencerWithNetworkInfoItemComponent } from '../influencer-with-network-info-item.component/influencer-with-network-info-item.component';

@Component({
  selector: 'lefty-creator-search-with-network',
  templateUrl: 'creator-search-with-network.component.html',
  styleUrls: ['creator-search-with-network.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyFormSelectComponent,
    FormsModule,
    NetworkIconComponent,
    LeftyFormAutocompleteComponent,
  ],
})
export class LeftyInfluencerSearchComponent extends InfluencerAutoCompleteBase {
  private readonly _auth = inject(LeftyAuthBloc);

  readonly value = model<InfluencerWithNetworkInfoAutocomplete>();

  readonly influencer = computed(() => this.value()?.influencer);

  readonly valueUsername = computed(
    () => this.influencer()?.creator?.userName ?? '',
  );

  readonly availableNetworks = input(this._auth.allowedNetworks());

  readonly placeholder = computed(
    () => $localize`Search on ${this.readableNetwork(this.network())}`,
  );

  @Input()
  set hideSearchItem(val: boolean) {
    this.hideSearch.set(val);
  }

  readableNetwork(network: Network): string {
    return Networks.readable(network);
  }

  networkItemFactory(): Type<unknown> {
    return NetworkDropdownItemComponent;
  }

  influencerItemFactory(): Type<unknown> {
    return InfluencerWithNetworkInfoItemComponent;
  }

  influencerRenderer(
    influencer: InfluencerWithNetworkInfoAutocomplete,
  ): string {
    return influencer.influencer.creator?.userName ?? '';
  }

  isGhostCreator(creator: CreatorTiniestCard): boolean {
    return isGhostCreator(creator);
  }

  select(val: InfluencerWithNetworkInfoAutocomplete): void {
    this.value.set(val);
    this.influencersRawOptions.set([]);
  }

  @Input()
  isOptionDisabled: (val: InfluencerWithNetworkInfoAutocomplete) => boolean = (
    _,
  ) => false;

  trackByNetwork(index: number, network: Network): Network {
    return network;
  }
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Attribute,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { isNil } from '@frontend2/core';
import { Subject } from 'rxjs';
import { Focusable } from '../focus.directive';
import { HasDisabled, isSpaceKey } from '../utils';
import { LeftyIconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';

const uncheckedIcon = 'check_box_outline_blank';
const checkedIcon = 'check_box';
const indeterminateIcon = 'indeterminate_check_box';

const uncheckedAriaState = 'false';
const checkedAriaState = 'true';
const indeterminateAriaState = 'mixed';

/// `lefty-checkbox` is a button that can be either checked or unchecked.
///
/// User can tap the checkbox to check or uncheck it.  Usually you use
/// checkboxes to allow user to select multiple options from a set.  If you
/// have a single ON/OFF option, avoid using a single checkbox and use
/// `lefty-toggle` instead.
///
/// We are not extending ButtonDecorator because we need to override several
/// attributes, including role, tabindex, but most importantly because checkbox
/// should only be interactible with SPACE, while button is for both SPACE and
/// ENTER.
@Component({
  selector: 'lefty-checkbox',
  templateUrl: './checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: HasDisabled,
      useExisting: LeftyCheckboxComponent,
    },
  ],
  standalone: true,
  imports: [NgIf, LeftyIconComponent],
})
export class LeftyCheckboxComponent
  implements ControlValueAccessor, HasDisabled, Focusable
{
  @HostBinding('class')
  readonly hostClass = 'lefty-checkbox';

  @HostBinding('attr.role')
  readonly role: string;

  private readonly defaultTabIndex: string;

  // eslint-disable-next-line @typescript-eslint/ban-types
  private onTouched?: Function;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private root: ElementRef<HTMLElement>,
    @Self() @Optional() private cd?: NgControl,
    @Attribute('tabindex') hostTabIndex?: string,
    @Attribute('role') role?: string,
  ) {
    this.role = role ?? 'checkbox';
    this.defaultTabIndex = hostTabIndex ? hostTabIndex : '0';

    // When NgControl is present on the host element, the component
    // participates in the Forms API.
    if (cd) {
      cd.valueAccessor = this;
    }

    this.syncAriaChecked();
  }

  // Current tab index.
  @HostBinding('attr.tabindex')
  get tabIndex(): string {
    return this.disabled ? '-1' : this.defaultTabIndex;
  }

  /// Fired when checkbox is checked or unchecked, but not when set
  /// indeterminate. Sends the state of [checked].
  @Output()
  readonly checkedChange = new Subject<boolean>();

  /// Fired when checkbox goes in and out of indeterminate state, but not when
  /// set to checked.
  ///
  /// Sends the state of [indeterminate].
  @Output()
  readonly indeterminateChange = new Subject<boolean>();

  /// Fired when checkbox state changes, sends [checkedStr], i.e. ARIA state.
  @Output()
  readonly stateChange$ = new Subject<string>();

  /// Determines the state to go into when [indeterminate] state is toggled.
  ///
  /// `true` will go to checked and `false` will go to unchecked.
  @Input()
  indeterminateToChecked = false;

  private _disabled = false;

  /// Whether the checkbox should not respond to events, and have a style that
  /// suggests that interaction is not allowed.
  @Input()
  @HostBinding('class.disabled')
  @HostBinding('attr.aria-disabled')
  set disabled(val: boolean) {
    this._disabled = val;
  }

  get disabled(): boolean {
    return this._disabled;
  }

  private _indeterminate = false;

  /// Alternative state of the checkbox, not user set-able state. Between
  /// [checked] and [indeterminate], only one can be true, though both can be
  /// false.
  ///
  /// `true` is INDETERMINATE and `false` is not.
  @Input()
  set indeterminate(newValue: boolean) {
    if (this.indeterminate === newValue) {
      return;
    }
    this.setStates({ indeterminate: newValue });
  }

  get indeterminate(): boolean {
    return this._indeterminate;
  }

  private _checked = false;

  /// Whether button is checked.
  get checked(): boolean {
    return this._checked;
  }

  /// Current state of the checkbox. This is user set-able state, via
  /// [toggleChecked()], so when checked, the [indeterminate] state gets
  /// cleared.
  ///
  /// `true` is CHECKED and `false` is not.
  @Input()
  set checked(newValue) {
    if (this._checked === newValue) {
      return;
    }
    this.setStates({ checked: newValue });
  }

  /// Whether the checkbox can be changed by user interaction.
  @Input()
  readOnly = false;

  private _icon = uncheckedIcon;

  /// Current icon, depends on the state of [checked] and [indeterminate].
  get icon(): string {
    return this._icon;
  }

  /// Label for the checkbox, alternatively use content.
  @Input()
  label?: string;

  private focused = false;
  private isKeyboardEvent = false;

  /// Whether focus should be drawn.
  get showFocus(): boolean {
    return this.focused && this.isKeyboardEvent;
  }

  get checkedStr(): string {
    return this._checkedStr;
  }

  private _checkedStr = uncheckedAriaState;

  private syncAriaChecked(): void {
    if (!this.root) {
      return;
    }
    this.root.nativeElement.setAttribute('aria-checked', this.checkedStr);
    this.changeDetector.markForCheck();
  }

  writeValue(isChecked: unknown): void {
    // Need to ignore the null on init.
    if (typeof isChecked === 'boolean') {
      this.setStates({ checked: isChecked, emitEvent: false });
    } else if (isNil(isChecked)) {
      this.setStates({ checked: false, emitEvent: false });
    } else {
      console.warn(
        `Failed to bind type ${typeof isChecked} on form control ${
          this.cd?.name
        }`,
      );
    }
  }

  registerOnChange(fn: any): void {
    this.checkedChange.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /// Actually update the state variables.
  ///
  /// If both parameters are provided, then set them as presented, otherwise we
  /// will clear the other one if necessary. Events are only fired if there was
  /// a change and [emitEvent] is true.
  private setStates(args: {
    checked?: boolean;
    indeterminate?: boolean;
    emitEvent?: boolean;
  }): void {
    const prevChecked = this._checked;
    const prevIndeterminate = this._indeterminate;
    const prevState = this._checkedStr;

    this._checked = args.checked ?? false;
    this._indeterminate = args.indeterminate ?? false;

    const emitEvent = args.emitEvent ?? true;

    this._checkedStr = this.indeterminate
      ? indeterminateAriaState
      : this._checked
        ? checkedAriaState
        : uncheckedAriaState;

    this._icon = this._indeterminate
      ? indeterminateIcon
      : this._checked
        ? checkedIcon
        : uncheckedIcon;

    if (emitEvent && this._checked !== prevChecked) {
      this.checkedChange.next(this._checked);
    }

    if (emitEvent && this._indeterminate !== prevIndeterminate) {
      this.indeterminateChange.next(this._indeterminate);
    }

    if (this._checkedStr !== prevState) {
      this.syncAriaChecked();
      this.stateChange$.next(this._checkedStr);
    }

    this.changeDetector.markForCheck();
  }

  /// Toggles checkbox via user action.
  ///
  /// When it is indeterminate, toggle can go to checked or unchecked, depending
  /// on state [indeterminateToChecked].
  toggleChecked(): void {
    if (this.disabled || this.readOnly) {
      return;
    }
    if (!this.indeterminate && !this.checked) {
      this.setStates({ checked: true });
    } else if (this.checked) {
      this.setStates({});
    } else {
      this.setStates({ checked: this.indeterminateToChecked });
    }
  }

  focus(): void {
    if (this.disabled) {
      return;
    }

    // Set to true so that the focus indicator is rendered.
    this.isKeyboardEvent = true;

    this.root.nativeElement.focus();
  }

  // Capture keyup when we are the target of event.
  @HostListener('keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent): void {
    if (event.target !== this.root.nativeElement) {
      return;
    }
    this.isKeyboardEvent = true;
  }

  @HostListener('click', ['$event'])
  handleClick(e: MouseEvent): void {
    if (this.disabled) {
      e.preventDefault();
      e.stopImmediatePropagation();
      return;
    }
    this.isKeyboardEvent = false;
    this.toggleChecked();
  }

  @HostListener('mousedown', ['$event'])
  handleMouseDown(mouseEvent: MouseEvent): void {
    // This removes the text selection behavior of mousedown.
    if (this.readOnly) {
      mouseEvent.preventDefault();
    }
  }

  @HostListener('keypress', ['$event'])
  handleKeyPress(event: KeyboardEvent): void {
    if (this.disabled) {
      return;
    }
    if (event.target !== this.root.nativeElement) {
      return;
    }
    if (isSpaceKey(event.code)) {
      // Required to prevent window from scrolling.
      event.preventDefault();
      this.isKeyboardEvent = true;
      this.toggleChecked();
    }
  }

  // Triggered on focus.
  @HostListener('focus')
  handleFocus(): void {
    this.focused = true;
  }

  // Triggered on blur.
  @HostListener('blur')
  handleBlur(): void {
    this.focused = false;
    if (this.onTouched) {
      this.onTouched();
    }
  }

  onDisabledChanged(isDisabled: boolean): void {
    this.setDisabledState(isDisabled);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.changeDetector.markForCheck();
  }
}

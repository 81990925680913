import { InjectionToken, inject } from '@angular/core';
import { PromiseClient, createPromiseClient } from '@connectrpc/connect';
import { LibrarianRegisteredAuthorProductRequests } from '@frontend2/proto/librarian/proto/registered_author_product_requests_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianRegisteredAuthorProductRequestsClient = PromiseClient<
  typeof LibrarianRegisteredAuthorProductRequests
>;

const factory = (): LibrarianRegisteredAuthorProductRequestsClient => {
  return createPromiseClient(
    LibrarianRegisteredAuthorProductRequests,
    inject(GRPC_TRANSPORT),
  );
};

const token = new InjectionToken(
  'LibrarianRegisteredAuthorProductRequestsClient',
  { factory },
);

export function injectLibrarianRegisteredAuthorProductRequestsClient(): LibrarianRegisteredAuthorProductRequestsClient {
  return inject(token);
}

import { Network } from '@frontend2/proto/common/proto/common_pb';
import { NumberRange } from '../lefty-range-input/lefty-range-input.models';
import { LeftyFormat, Networks, isNil } from '@frontend2/core';

export function readablePlaceholder(
  followers: Map<Network, NumberRange>,
): string {
  const placeholderString = [...followers.keys()]
    .map((network) => {
      const range = followers.get(network);
      if (isNil(range)) {
        return '';
      }
      return `${readableNetwork(network)}: ${readableRange(range)}`;
    })
    .join(', ');

  return placeholderString;
}

export function readableNetwork(network: Network): string {
  return Networks.readable(network);
}

export function areFollowersEqual(
  followers1: Map<Network, NumberRange>,
  followers2: Map<Network, NumberRange>,
): boolean {
  if (followers1.size !== followers2.size) {
    return false;
  }

  for (const [key, value] of followers1) {
    if (followers2.has(key)) {
      if (followers2.get(key) !== value) {
        return false;
      }
    } else {
      return false;
    }
  }

  return true;
}

function readableRange(range: NumberRange): string {
  const start =
    isNil(range.start) || range.start === 0
      ? 0
      : LeftyFormat.followers(range.start, {
          compact: true,
        });
  if (isNil(range.end) || range.end === 0) {
    return $localize`${start}`;
  }
  const end = LeftyFormat.followers(range.end, {
    compact: true,
  });

  return $localize`${start} to ${end}`;
}

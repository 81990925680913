/* eslint-disable @typescript-eslint/no-namespace */
import {
  LogicOperator,
  Network,
  PostType,
  SortDirection,
} from '@frontend2/proto/common/proto/common_pb';

import { GenerikInfoCard } from '@frontend2/proto/librarian/proto/creators_pb';
import { LoggedBootstrapping } from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import deepEqual from 'deep-equal';
import { isSuperAdmin } from './proto-helpers/librarian/frontend_misc_pb.helpers';
import { isNil, isNotNil } from './utils/common.helpers';
import { isBlankString, isNotEmptyString } from './utils/strings.helpers';

export class Numbers {
  static clamp(num: number, min: number, max: number): number {
    return num <= min ? min : num >= max ? max : num;
  }
}

export type MapStringString = {
  [key: string]: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getEnumName<T>(enumType: any, value: T): string | undefined {
  for (const name of Object.keys(enumType)) {
    if (enumType[name] === value) {
      return name;
    }
  }
  return;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getEnumValues<T>(enumType: any): T[] {
  const enumValues: T[] = [];
  for (const value in enumType) {
    if (typeof enumType[value] === 'number') {
      enumValues.push(enumType[value] as T);
    }
  }
  return enumValues;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseEnumString<T>(enumType: any, val: string): T | undefined {
  const values = getEnumValues<T>(enumType);
  return values.find((e) => getEnumName<T>(enumType, e) === val);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function parseEnumValue<T>(enumType: any, val: number): T | undefined {
  const values = getEnumValues<T>(enumType);
  return values.find((e) => e === val);
}

export function chunkArray<T>(
  array: T[],
  sizeOfTheChunkedArray: number,
): T[][] {
  const chunked: T[][] = [];
  for (let i = 0; i < array.length; i += sizeOfTheChunkedArray) {
    const chunk = array.slice(i, i + sizeOfTheChunkedArray);
    chunked.push(chunk);
  }
  return chunked;
}

export function removeDuplicateInArray<T>(
  array: T[],
  isEquals?: (a: T, b: T) => boolean,
): T[] {
  if (isNil(isEquals)) {
    return Array.from(new Set(array));
  }

  const uniqueArray: T[] = [];
  for (const form of array) {
    const exist = uniqueArray.find((a) => isEquals(a, form));
    if (isNil(exist)) {
      uniqueArray.push(form);
    }
  }
  return uniqueArray;
}

export function getInfluencerNetworkLink(
  id: string,
  network: Network,
  apiHost: string,
): string | undefined {
  // wechat does not support open in network
  if (network === Network.WECHAT) {
    return;
  }

  const name = getEnumName(Network, network);
  return `${apiHost}/_api/open_creator_in_network?id=${id}&network=${name}`;
}

export abstract class Networks {
  static getIcon(network: Network): string {
    const name = getEnumName(Network, network)?.toLowerCase();
    switch (network) {
      case Network.INSTA:
        return 'lefty-images/icons/colored-insta.png';
      case Network.TIK_TOK:
        return 'lefty-images/icons/tiktok.png';
      case Network.TWEET:
        return 'lefty-images/icons/x.png';
      case Network.YOUTUBE:
      case Network.FACEBOOK:
        return `lefty-images/icons/${name}.png`;
      default:
        return `lefty-images/icons/${name}.svg`;
    }
  }

  static openInMessage(network: Network): string {
    switch (network) {
      case Network.INSTA:
        return $localize`Open in Instagram`;
      case Network.YOUTUBE:
        return $localize`Open in Youtube`;
      case Network.TWEET:
        return $localize`Open in X (Twitter)`;
      case Network.TIK_TOK:
        return $localize`Open in TikTok`;
      case Network.FACEBOOK:
        return $localize`Open in Facebook`;
      case Network.WEIBO:
        return $localize`Open in Weibo`;
      case Network.RED:
        return $localize`Open in Red`;
      case Network.WECHAT:
        return $localize`Open in Wechat`;
      case Network.DOUYIN:
        return $localize`Open in Douyin`;
      case Network.SNAPCHAT:
        return $localize`Open in Snapchat`;
      default:
        return '';
    }
  }

  static readable(network: Network): string {
    switch (network) {
      case Network.INSTA:
        return $localize`Instagram`;
      case Network.YOUTUBE:
        return $localize`Youtube`;
      case Network.TWEET:
        return $localize`X (Twitter)`;
      case Network.TIK_TOK:
        return $localize`TikTok`;
      case Network.FACEBOOK:
        return $localize`Facebook`;
      case Network.WEIBO:
        return $localize`Weibo`;
      case Network.RED:
        return $localize`Red`;
      case Network.WECHAT:
        return $localize`Wechat`;
      case Network.DOUYIN:
        return $localize`Douyin`;
      case Network.SNAPCHAT:
        return $localize`Snapchat`;
      default:
        return $localize`Unknown`;
    }
  }

  static readonly supported = [
    Network.INSTA,
    Network.YOUTUBE,
    Network.TWEET,
    Network.TIK_TOK,
    Network.WEIBO,
    Network.WECHAT,
    Network.RED,
    Network.DOUYIN,
    Network.SNAPCHAT,
  ];

  static readonly supportedAdmin = [
    Network.INSTA,
    Network.YOUTUBE,
    Network.TWEET,
    Network.TIK_TOK,
    Network.WEIBO,
    // Note(hadrien): as of 29/10/2024 wechat is not supported anymore, need to find a new provider
    // Network.WECHAT,
    Network.RED,
    Network.DOUYIN,
    Network.SNAPCHAT,
  ];

  // Note(hadrien): as of 29/10/2024 wechat is not supported anymore, need to find a new provider
  // however we still need to display data we have
  // But user should not be able to add new influencers.
  private static readonly _readOnlyNetworks = [Network.WECHAT];

  static readonly supportedDiscover = [
    Network.INSTA,
    Network.YOUTUBE,
    Network.TWEET,
    Network.TIK_TOK,
    Network.WEIBO,
  ];

  static readonly supportedAudience = [
    Network.INSTA,
    Network.YOUTUBE,
    Network.TIK_TOK,
  ];

  static readonly supportedCW = [
    Network.INSTA,
    Network.WEIBO,
    Network.TIK_TOK,
    Network.YOUTUBE,
  ];

  static isAllowed(bootstrap: LoggedBootstrapping, network: Network): boolean {
    // always allow Insta
    // it's our default network in the APP
    // and it may fail if we don't provide it
    return (
      network === Network.INSTA ||
      isSuperAdmin(bootstrap) ||
      bootstrap.allowedNetworks.includes(network)
    );
  }

  static allowed(bootstrap: LoggedBootstrapping): Network[] {
    return Networks.supported.filter((n) => Networks.isAllowed(bootstrap, n));
  }

  static allowedMerge(bootstrap: LoggedBootstrapping): Network[] {
    return Networks.allowed(bootstrap).filter(
      (network) => this._readOnlyNetworks.includes(network) === false,
    );
  }

  static allowedTracking(bootstrap: LoggedBootstrapping): Network[] {
    return Networks.allowed(bootstrap).filter(
      (network) => this._readOnlyNetworks.includes(network) === false,
    );
  }

  static allowedUpload(bootstrap: LoggedBootstrapping): Network[] {
    return Networks.allowed(bootstrap).filter(
      (network) => this._readOnlyNetworks.includes(network) === false,
    );
  }

  static allowedAutocomplete(bootstrap: LoggedBootstrapping): Network[] {
    return Networks.allowed(bootstrap).filter(
      (network) => this._readOnlyNetworks.includes(network) === false,
    );
  }

  static allowedCPMEdit(bootstrap: LoggedBootstrapping): Network[] {
    return Networks.allowed(bootstrap).filter(
      (network) => this._readOnlyNetworks.includes(network) === false,
    );
  }

  static allowedDiscover(bootstrap: LoggedBootstrapping): Network[] {
    return Networks.allowed(bootstrap).filter((n) =>
      Networks.supportedDiscover.includes(n),
    );
  }

  static allowedAudience(bootstrap: LoggedBootstrapping): Network[] {
    return Networks.allowed(bootstrap).filter((n) =>
      Networks.supportedAudience.includes(n),
    );
  }

  static allowedCW(bootstrap: LoggedBootstrapping): Network[] {
    return Networks.allowed(bootstrap).filter((n) =>
      Networks.supportedCW.includes(n),
    );
  }

  static resharesName(network: Network): string {
    switch (network) {
      case Network.TWEET:
        return $localize`Retweets`;
      default:
        return $localize`Shares`;
    }
  }

  static postsName(network: Network): string {
    switch (network) {
      case Network.YOUTUBE:
        return $localize`Video`;
      case Network.TWEET:
        return $localize`Tweets`;
      default:
        return $localize`Posts`;
    }
  }

  static followersName(network?: Network): string {
    switch (network) {
      case Network.YOUTUBE:
        return $localize`Subscribers`;
      default:
        return $localize`Followers`;
    }
  }
}

export abstract class GenerikInfoCards {
  static getNetworksLinks(
    infos: GenerikInfoCard[],
    influencerId: string,
    apiHost: string,
    options?: {
      readonly isNotIngested?: boolean;
    },
  ): Map<Network, string> {
    const links = new Map<Network, string>();

    infos.forEach((element) => {
      // if profile is not ingested we use link inside GenerikInfoCard
      // otherwise we use /_api/open_creator_in_network
      const link =
        options?.isNotIngested === true
          ? element.networkLink
          : getInfluencerNetworkLink(influencerId, element.network, apiHost);
      if (link) {
        links.set(element.network, link);
      }
    });

    return links;
  }
}

export function getInitialsFromEmail(email: string): string {
  const splitedEmail = email.split('@');

  if (splitedEmail.length < 2) {
    return '';
  }

  let names = splitedEmail[0].split('.');
  if (names.length < 2) {
    names = splitedEmail[0].split('_');
  }
  if (names.length < 2) {
    return splitedEmail[0].charAt(0) + splitedEmail[1].charAt(0);
  }

  return names[0].charAt(0) + names[1].charAt(0);
}

export interface SortBy<T> {
  readonly value: T;
  readonly direction: SortDirection;
}

export namespace SortBy {
  export function create<T>(value: T, direction?: SortDirection): SortBy<T> {
    return {
      value,
      direction: direction ?? SortDirection.ASC,
    };
  }
}

export class LeftyColors {
  static background = '#fafafa';
  static teal050 = '#e6f8fa';
  static teal100 = '#c3ebf0';
  static teal300 = '#70c5ce';
  static teal500 = '#0097a7';
  static teal800 = '#00606a';

  static teal = [
    this.teal050,
    this.teal100,
    this.teal300,
    this.teal500,
    this.teal800,
  ];

  static grey800 = '#333';
  static grey600 = '#666';
  static grey400 = '#999';
  static grey300 = '#b2b2b2';
  static grey180 = '#d1d1d1';
  static grey080 = '#ebebeb';
  static grey050 = '#f5f5f5';
  static grey020 = '#fafafa';

  static grey = [
    this.grey020,
    this.grey050,
    this.grey080,
    this.grey180,
    this.grey300,
    this.grey400,
    this.grey600,
    this.grey800,
  ];

  static yellow050 = '#fff9db';
  static yellow100 = '#ffefa8';
  static yellow300 = '#ffd84e';
  static yellow500 = '#fab005';
  static yellow800 = '#AA7700';

  static yellow = [
    this.yellow050,
    this.yellow100,
    this.yellow300,
    this.yellow500,
    this.yellow800,
  ];

  static indigo050 = '#f0f2ff';
  static indigo100 = '#d7dcfc';
  static indigo300 = '#a4aef0';
  static indigo500 = '#5c6ac4';
  static indigo800 = '#202e78';

  static indigo = [
    this.indigo050,
    this.indigo100,
    this.indigo300,
    this.indigo500,
    this.indigo800,
  ];

  static orange050 = '#fff2e5';
  static orange100 = '#ffe0c2';
  static orange300 = '#ffbf82';
  static orange500 = '#f49342';
  static orange800 = '#b14c0e';

  static orange = [
    this.orange050,
    this.orange100,
    this.orange300,
    this.orange500,
    this.orange800,
  ];

  static red050 = '#fff1f1';
  static red100 = '#ffd3d3';
  static red300 = '#f99292';
  static red400 = '#e25f5f';
  static red600 = '#9d2121';

  static red = [
    this.red050,
    this.red100,
    this.red300,
    this.red400,
    this.red600,
  ];

  static pink050 = '#ffecf3';
  static pink100 = '#ffd0e0';
  static pink300 = '#ef91b2';
  static pink500 = '#c54e77';

  static pink = [this.pink050, this.pink100, this.pink300, this.pink500];

  static green50 = '#e7f9ef';
  static green100 = '#c0eed2';
  static green300 = '#76dd9f';
  static green500 = '#2bc168';
  static green800 = '#008A36';

  static green = [
    this.green50,
    this.green100,
    this.green300,
    this.green500,
    this.green800,
  ];

  static gradient50 = [
    this.background,
    this.pink050,
    this.red050,
    this.orange050,
    this.yellow050,
    this.indigo050,
    this.teal050,
    this.green50,
  ];

  static gradient100 = [
    this.pink100,
    this.red100,
    this.orange100,
    this.yellow100,
    this.indigo100,
    this.teal100,
    this.green100,
  ];

  static gradient300 = [
    this.pink300,
    this.red300,
    this.orange300,
    this.yellow300,
    this.indigo300,
    this.teal300,
    this.green300,
  ];

  static gradient500 = [
    this.pink500,
    this.red400,
    this.orange500,
    this.yellow500,
    this.indigo500,
    this.teal500,
    this.green500,
  ];

  static networks = new Map<Network, string>([
    [Network.INSTA, this.teal800],
    [Network.YOUTUBE, '#EE6768'],
    [Network.TWEET, '#61BDF6'],
    [Network.FACEBOOK, '#4267b2'],
    [Network.TIK_TOK, this.grey800],
    [Network.WEIBO, '#ff8140'],
    [Network.WECHAT, '#07c160'],
    [Network.RED, this.red300],
    [Network.DOUYIN, this.grey300],
    [Network.SNAPCHAT, this.yellow500],
  ]);

  static postTypes = new Map<PostType, string>([
    [PostType.IG_IMAGE, this.teal300],
    [PostType.IG_VIDEO, this.teal100],
    [PostType.IG_CAROUSEL, this.teal050],
    [PostType.IG_STORY, this.teal800],
    [PostType.YT_VIDEO, this.networks.get(Network.YOUTUBE) ?? ''],
    [PostType.TW_TEXT, this.networks.get(Network.TWEET) ?? ''],
    [PostType.TK_VIDEO, this.networks.get(Network.TIK_TOK) ?? ''],
    [PostType.WB_TEXT, this.networks.get(Network.WEIBO) ?? ''],
    [PostType.WC_TEXT, this.networks.get(Network.WECHAT) ?? ''],
    [PostType.FB_TEXT, this.networks.get(Network.FACEBOOK) ?? ''],
    [PostType.RD_TEXT, this.networks.get(Network.RED) ?? ''],
    [PostType.DY_VIDEO, this.networks.get(Network.DOUYIN) ?? ''],
    [PostType.SC_STORY, this.yellow500],
    [PostType.SC_SPOTLIGHT, this.yellow300],
  ]);
}

export function generateRgbFromNumber(
  val: number,
  colors: string[] = LeftyColors.gradient300,
): string {
  return colors[val % colors.length];
}

export function generateRgbFromString(
  str: string,
  colors: string[] = LeftyColors.gradient300,
): string {
  // dumb logic shared with Dart side
  // we just sum all char code valu of the string
  const value = [...str]
    .map((char) => char.charCodeAt(0))
    .reduce((current, previous) => previous + current, 0);

  return generateRgbFromNumber(value, colors);
}

export async function copyToClipboard(link: string): Promise<void> {
  await window.navigator.clipboard.writeText(link);
}

export function integerToHex(color: number | undefined): string {
  return '#' + color?.toString(16);
}

export function hexToInteger(color: string): number {
  return parseInt(color.replaceAll('#', ''), 16);
}

export function isEqual(a: unknown, b: unknown): boolean {
  if (Array.isArray(a) && Array.isArray(b)) {
    if (a.length !== b.length) {
      return false;
    }
    a = a.sort();
    b = b.sort();
  }
  return deepEqual(a, b);
}

export function randomDouble(a: number, b: number): number {
  const lower = Math.min(a, b);
  const upper = Math.max(a, b);
  return lower + Math.random() * (upper - lower);
}

export function randomInt(a: number, b: number): number {
  const lower = Math.ceil(Math.min(a, b));
  const upper = Math.floor(Math.max(a, b));
  return Math.floor(lower + Math.random() * (upper - lower + 1));
}

export function orderBy<T>(
  array: T[],
  item: (v: T) => string | number | bigint,
  direction: 'asc' | 'desc' = 'asc',
): T[] {
  array = [...array];

  array.sort((a, b) => {
    const _a = item(a);
    const _b = item(b);
    if (direction === 'desc') {
      return _b > _a ? 1 : _a > _b ? -1 : 0;
    }
    return _a > _b ? 1 : _b > _a ? -1 : 0;
  });

  return array;
}

export function isDigit(char: string): boolean {
  if (isBlankString(char)) {
    return false;
  }

  const charCode = char.charCodeAt(0);
  return charCode >= '0'.charCodeAt(0) && charCode <= '9'.charCodeAt(0);
}

export function getLocale(): string {
  const locale = document.body.getAttribute('locale');

  if (isNotEmptyString(locale)) {
    return locale;
  }
  return navigator.language;
}

export function toLocaleString(val: number): string {
  return val.toLocaleString(getLocale());
}

export function getLocaleDecimalSeparator(): string {
  return toLocaleString(1.1).substring(1, 2);
}

/// https://stackoverflow.com/questions/11665884/how-can-i-parse-a-string-with-a-comma-thousand-separator-to-a-number
export function parseNumber(value: string, locale?: string): number {
  const example = Intl.NumberFormat(locale ?? getLocale()).format(1.1);
  const cleanPattern = new RegExp(`[^-+0-9${example.charAt(1)}]`, 'g');
  const cleaned = value.replace(cleanPattern, '');
  const normalized = cleaned.replace(example.charAt(1), '.');

  return parseFloat(normalized);
}

export function formatBytes(bytes: number): string {
  if (bytes === 0) {
    return '0b';
  }

  const k = 1000;
  const sizes = ['b', 'kb', 'mb', 'gb', 'tb', 'pb', 'eb', 'zb', 'yb'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = (bytes / Math.pow(k, i)).toFixed(2).replaceAll('.00', '');

  return `${size}${sizes[i]}`;
}

export function fileToUint8Array(
  file: File | undefined,
): Promise<Uint8Array> | Uint8Array {
  if (isNil(file)) {
    return new Uint8Array();
  }
  return new Promise<Uint8Array>((resolve, reject) => {
    const reader: FileReader = new FileReader();

    reader.onload = (e): void => {
      const resultArrayBuffer = e.target?.result as ArrayBuffer;
      const uint8Array = new Uint8Array(resultArrayBuffer);
      resolve(uint8Array);
    };

    reader.onerror = (): void => {
      reject(reader.error);
    };

    reader.readAsArrayBuffer(file);
  });
}

export function isEmptyObject(obj: object): boolean {
  return Object.keys(obj).length < 0;
}

export const AGGREGATED_DISTRIBUTION_NETWORKS: Network[] = [
  Network.TWEET,
  Network.WECHAT,
  Network.WEIBO,
  Network.DOUYIN,
  Network.RED,
];

// some browser does not support crypto
// see https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid/2117523#2117523
export function randomUUID(): string {
  if (isNotNil(crypto)) {
    if (isNotNil(crypto.randomUUID)) {
      return crypto.randomUUID();
    }

    if (isNotNil(crypto.getRandomValues)) {
      return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) =>
        (
          +c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))
        ).toString(16),
      );
    }
  }

  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export function patchObject<T>(
  obj: T,
  updates: Partial<T> | ((value: T) => Partial<T>),
): T {
  if (typeof updates === 'function') {
    updates = updates(obj);
  }

  return { ...obj, ...updates };
}

export function computeRoi(
  cost: number | null,
  totalEmv: number | null,
): number {
  if (isNil(cost) || isNil(totalEmv) || totalEmv === 0) {
    return 0;
  }
  return totalEmv / cost - 1;
}

export const DEFAULT_KEYWORD_OPERATOR = LogicOperator.OR;

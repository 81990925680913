import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Inject,
  Input,
  Optional,
  Output,
  Self,
  Signal,
  TrackByFunction,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  escapeRegExp,
  isNil,
  isNotBlankString,
  isNotEmptyString,
  isNotNil,
  orderBy,
} from '@frontend2/core';
import { Placement } from '@popperjs/core';
import { Observable } from 'rxjs';
import { ComponentFactory } from '../dynamic-component.component';
import { Focusable, FocusableComponent } from '../focus.directive';
import { FORM_DEFAULT_VALUE, LeftyFormValueBase } from '../form';
import { LeftyFormInputComponent } from '../lefty-form-input/lefty-form-input.component';
import { ItemRenderer, defaultItemRenderer } from '../lefty-form-select/utils';
import { AngularUtils, createOutput } from '../utils';
import { IntersectionObserverDirective } from '../intersection-observer.directive';
import { ActiveItemDirective } from '../active-item.directive';
import { LeftySelectDropdownItemComponent } from '../lefty-form-select/lefty-select-dropdown-item.component';
import { LeftyListComponent } from '../lefty-list/lefty-list.component';
import { LeftySpinnerComponent } from '../loading.component';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';
import { LeftyButtonDirective } from '../lefty-button-directive/lefty-button.directive';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

export type FilteringFn<T> = (
  options: T[],
  name: string,
  limit?: number,
) => Promise<T[]> | T[];

// Supports
// - dynamic component to render item
// - item renderer (default is rendering item using `toString()`)
// - auto filtering on `[options]`, using `itemRenderer` ( also support custom `[filteringFn]` function)
// - can delegate filtering to a parent component, `[noFiltering]` must be set to true
// - can use keyboard arrows to navigate on selection
// - Angular form API

// DO NOT Support
// - Multi selection, NOT supported in our design system. PREFER usage of `SearchAndSelectDropdownComponent`
// - Display options in different group (sublist with title). Could be possible in the future
@Component({
  selector: 'lefty-form-autocomplete',
  providers: [
    {
      provide: Focusable,
      useExisting: LeftyFormAutocompleteComponent,
    },
    {
      provide: FocusableComponent,
      useExisting: forwardRef(() => LeftyFormAutocompleteComponent),
    },
  ],
  templateUrl: 'lefty-form-autocomplete.component.html',
  styleUrls: [
    '../lefty-form/lefty-form.component.scss',
    'lefty-form-autocomplete.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyFormComponent,
    LeftyFormInputComponent,
    NgClass,
    NgIf,
    LeftyButtonDirective,
    LeftyPopupComponent,
    LeftySpinnerComponent,
    LeftyListComponent,
    NgFor,
    LeftySelectDropdownItemComponent,
    ActiveItemDirective,
    IntersectionObserverDirective,
  ],
})
export class LeftyFormAutocompleteComponent<T>
  extends LeftyFormValueBase<T | undefined>
  implements Focusable
{
  constructor(@Self() @Optional() ngControl?: NgControl) {
    super(undefined, ngControl);
  }

  private _input?: LeftyFormInputComponent;
  private _focusPending = false;

  private _inputText = '';

  @Input()
  showInvalidStyle = false;

  @Input()
  loading = false;

  @Input()
  trackByFn: TrackByFunction<T> = AngularUtils.trackByIndex;

  /// Function to filter [options] item
  /// if null, it will use [_simpleFilter] function
  @Input()
  filteringFn?: FilteringFn<T>;

  /// Publishes events when input text changes.
  @Output()
  readonly inputTextChange = createOutput<string>();

  @Output()
  readonly scrollEnd$ = createOutput<unknown>();

  // turn this to true if you want to delegate autocompletion to a parent component
  @Input()
  noFiltering = false;

  @Input()
  disableClearSelection = false;

  get canClear(): boolean {
    return this.hasSelection && this.disableClearSelection === false;
  }

  /// How many suggestions to show.
  ///
  /// If the limit is less than 0, it is assumed to be mean no limit.
  /// See filter method in [Filterable]. Defaults to 10.
  @Input()
  limit = 10;

  /// Whether or not the suggestion popup width is at least as wide as the input
  /// width.
  ///
  /// Defaults to false.
  @Input()
  popupMatchInputWidth = false;

  /// Whether to clear the input text once the item is selected from the menu.
  ///
  /// Defaults to false.
  @Input()
  shouldClearInputOnSelection = false;

  // by default, when user select item, popup will close
  @Input()
  keepPopupVisible = false;

  @Input()
  popupClassName = '';

  @Input()
  inputClassName = '';

  @Input()
  popupPlacement: Placement = 'bottom-start';

  @Input()
  placeholder = '';

  @Input()
  name = '';

  /// Text to show if the options list is empty and not loading.
  @Input()
  emptyPlaceholder = $localize`No results found`;

  @Input()
  prefix = '';

  @Input()
  suffix = '';

  @Input()
  trailingGlyph = '';

  @Input()
  leadingGlyph = '';

  @Input()
  openIfNoInput = false;

  get formattedValue(): string {
    if (isNil(this.value)) {
      return this.placeholder;
    }

    return this.itemRenderer(this.value);
  }

  get hasSelection(): boolean {
    return isNotNil(this.selection);
  }

  @Input()
  set selection(val: T | undefined) {
    this.value = val;
  }

  get selection(): T | undefined {
    return this.value;
  }

  @Output()
  get selectionChange(): Observable<T | undefined> {
    return this.valueChange;
  }

  @HostBinding('class.has-value')
  get hasValue(): boolean {
    return isNotNil(this.value);
  }

  @HostBinding('class.empty')
  get isEmpty(): boolean {
    return this.hasValue === false;
  }

  /// Filters suggestion list according to input.
  @Input()
  set inputText(inputText: string) {
    inputText ??= '';
    if (inputText === this._inputText) {
      return;
    }

    this._inputText = inputText;
    this.inputTextChange.next(inputText);
    this._filterSuggestions();
    this.changeDetection.markForCheck();
  }

  get inputText(): string {
    return this._inputText;
  }

  override get value(): T | undefined {
    return super.value;
  }
  override set value(value: T | undefined) {
    super.value = value;
    if (typeof value === 'string') {
      this._inputText = value;
    }
  }

  @Input()
  componentFactory?: ComponentFactory<T>;

  /// Function to convert an option object to string.
  @Input()
  itemRenderer: ItemRenderer<T> = defaultItemRenderer;

  @Output()
  readonly keydown$ = createOutput<KeyboardEvent>();

  @Output()
  readonly popupVisibleChange = createOutput<boolean>();

  private _popupVisible = false;

  get popupVisible(): boolean {
    if (this.openIfNoInput) {
      return this._popupVisible;
    }

    return this._popupVisible && isNotBlankString(this.inputText);
  }

  /// Whether the dropdown is visible.
  set popupVisible(visible: boolean) {
    if (this.popupVisible === visible) {
      return;
    }

    this._popupVisible = visible;
    this.popupVisibleChange.next(visible);
    this.changeDetection.markForCheck();
  }

  private _options: T[] = [];

  get options(): T[] {
    return this._options;
  }

  @Input()
  set options(value: T[]) {
    this._options = value;
    this.activeIndex = 0;

    this._filterSuggestions();
  }

  activeIndex = 0;

  get activeItem(): T | undefined {
    if (this.filteredOptions.length === 0) {
      return;
    }
    return this.filteredOptions[this.activeIndex];
  }

  isActive(item: T): boolean {
    return this.activeItem === item;
  }

  activate(item: T): void {
    this.activeIndex = this.filteredOptions.indexOf(item);
    this.changeDetection.markForCheck();
  }

  isSelected(item: T): boolean {
    return this.value === item;
  }

  override handleValueChange(val: T | undefined): void {
    if (this.selection === val) {
      return;
    }

    if (isNil(val) || this.shouldClearInputOnSelection) {
      this.inputText = '';
    } else {
      this.inputText = this.itemRenderer(val);
    }

    super.handleValueChange(val);
  }

  select(item: T): void {
    this.handleValueChange(item);
  }

  clearSelection(): void {
    this.handleValueChange(undefined);
  }

  private handleNavigationKey(
    event: Event,
    activateFunction: () => void,
  ): void {
    if (this.popupVisible === false || this.disabled) {
      return;
    }

    event.preventDefault();
    activateFunction();
    this.changeDetection.markForCheck();
  }

  handleUpKey(event: Event): void {
    if (this.popupVisible === false || this.disabled) {
      return;
    }

    this.handleNavigationKey(event, () => {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      } else {
        this.activeIndex = this.filteredOptions.length - 1;
      }
    });
  }

  handleDownKey(event: Event): void {
    if (this.popupVisible === false || this.disabled) {
      return;
    }

    this.handleNavigationKey(event, () => {
      if (this.activeIndex < this.filteredOptions.length - 1) {
        this.activeIndex++;
      } else {
        this.activeIndex = 0;
      }
    });
  }

  toggle(): void {
    this.popupVisible = !this.popupVisible;
  }

  open(): void {
    if (this.disabled === false) {
      this.popupVisible = true;
    }
  }

  close(): void {
    this.popupVisible = false;
  }

  handleKeyboardTrigger(event: Event): void {
    // Prevent any scrolling.
    event?.preventDefault();

    if (this.disabled) {
      return;
    }
    if (!this.popupVisible) {
      this.open();
    } else {
      if (this.activeItem) {
        this.select(this.activeItem);
      }

      this.close();
      this._input?.inputElement?.blur();
    }
  }

  override writeValue(obj: unknown): void {
    if (isNil(obj)) {
      this.value = undefined;
    } else {
      // Note(hadrien): no way to check obj instanceof T
      // Typescript does not support it, so we have to asume it is ...
      this.value = obj as T;
    }
  }

  override registerOnChange(fn: (val: T | T[] | undefined) => void): void {
    this.disposer.addStreamSubscription(this.selectionChange.subscribe(fn));
  }

  @ViewChild(LeftyFormInputComponent)
  set input(input: LeftyFormInputComponent) {
    this._input = input;
    if (this._focusPending) {
      this._focusPending = false;
      this._input.focus();
    }
  }

  focus(): void {
    if (isNil(this._input)) {
      /// input component is not there yet, defer the focus.
      this._focusPending = true;
    } else {
      this._input.focus();
    }
  }

  get showEmptyPlaceholder(): boolean {
    return (
      isNotEmptyString(this.emptyPlaceholder) &&
      this.filteredOptions.length === 0 &&
      this.loading === false
    );
  }

  private _filterSuggestions(): void {
    if (this.noFiltering === true) {
      this.filteredOptions = this.options;
      this.changeDetection.markForCheck();
      return;
    }

    this._filterAndUpdateUI(this.inputText, this.limit);
  }

  filteredOptions: T[] = [];

  get showOptions(): boolean {
    return this.loading === false && isNotEmptyString(this.inputText);
  }

  private _filterOptions(name: string, limit: number): Promise<T[]> | T[] {
    if (isNotNil(this.filteringFn)) {
      return this.filteringFn(this.options, name, limit);
    }
    return this._simpleFilter(name, limit);
  }

  private async _filterAndUpdateUI(name: string, limit: number): Promise<void> {
    this.loading = true;
    this.changeDetection.markForCheck();

    this.filteredOptions = await this._filterOptions(name, limit);

    this.loading = false;
    this.changeDetection.markForCheck();
  }

  private _simpleFilter(name: string, limit: number): T[] {
    if (isNotEmptyString(name) === false) {
      return this.options;
    }
    const query = escapeRegExp(name.trim());
    const regExp = new RegExp(query, 'i');

    const options = this.options
      .filter((i) => this.itemRenderer(i).match(regExp))
      .slice(0, limit);
    return orderBy(options, (item) => this.itemRenderer(item).toLowerCase());
  }

  @Input()
  isOptionDisabled: (val: T) => boolean = (_) => false;
}

@Component({
  template: '',
})
export abstract class LeftyFormAutocompleteWrapperComponent<
  T,
> extends LeftyFormValueBase<T> {
  constructor(
    @Inject(FORM_DEFAULT_VALUE) defaultValue: T,
    @Optional() ngControl?: NgControl,
  ) {
    super(defaultValue, ngControl);
  }

  abstract readonly options: Signal<T[]>;

  @Input()
  inputText = '';

  /// Publishes events when input text changes.
  @Output()
  readonly inputTextChange = createOutput<string>();

  @Output()
  readonly scrollEnd$ = createOutput<unknown>();

  @Input()
  filterSuggestions = true;

  /// How many suggestions to show.
  ///
  /// If the limit is less than 0, it is assumed to be mean no limit.
  /// See filter method in [Filterable]. Defaults to 10.
  @Input()
  limit = 10;

  /// Whether or not the suggestion popup width is at least as wide as the input
  /// width.
  ///
  /// Defaults to false.
  @Input()
  popupMatchInputWidth = false;

  /// Whether to clear the input text once the item is selected from the menu.
  ///
  /// Defaults to false.
  @Input()
  shouldClearInputOnSelection = false;

  // by default, when user select item, popup will close
  @Input()
  keepPopupVisible = false;

  @Input()
  popupClassName = '';

  @Input()
  inputClassName = '';

  @Input()
  popupPlacement: Placement = 'bottom-start';

  @Input()
  placeholder = '';

  /// Text to show if the options list is empty and not loading.
  @Input()
  emptyPlaceholder = $localize`No results found`;

  @Input()
  prefix = '';

  @Input()
  suffix = '';

  @Input()
  trailingGlyph = '';

  @Input()
  leadingGlyph = '';

  @Input()
  openIfNoInput = false;

  @Output()
  readonly popupVisibleChange = createOutput<boolean>();

  @Input()
  componentFactory?: ComponentFactory<T>;

  @Input()
  itemRenderer: ItemRenderer<T> = AngularUtils.defaultItemRenderer;

  select(val: T | undefined): void {
    if (isNil(val)) {
      this.handleValueChange(this.defaultValue);
    } else {
      this.handleValueChange(val);
    }
  }
}

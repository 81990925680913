<lefty-form-autocomplete
  [label]="label"
  [required]="required"
  [helpText]="helpText"
  [helpLink]="helpLink"
  [hintText]="hintText"
  [errorMessage]="errorMessage"
  [tooltip]="tooltip"
  (inputTextChange)="searchValue$.next($event)"
  [options]="results"
  [ngModel]="value"
  (selectionChange)="handleValueChange($event)"
  [loading]="loading"
  (focus$)="handleFocus($event)"
  (blur$)="handleBlur($event)"
  [itemRenderer]="locationRenderer"
  #leftyFormAutoComplete
>
</lefty-form-autocomplete>

import { Observable } from 'rxjs';

export abstract class LeftyDialogService {
  abstract get autoDismissable(): boolean;
  abstract set autoDismissable(val: boolean);

  abstract get close$(): Observable<null>;
  abstract get open$(): Observable<null>;
  abstract get visibleChange(): Observable<boolean>;

  abstract toggle(): void;
  abstract close(): void;
  abstract open(): void;

  abstract get visible(): boolean;
  abstract set visible(val: boolean);
}

//
//This file defines the librarian search service.

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/search.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GeoRequest, GeoResponse, SearchRequest } from "../../universal/arkansas/proto/arkansas_pb";
import { EnrichedSearchResponse } from "./search_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * This service powers auxiliary services related to search: building sets, describing geos.
 *
 * The influencer search feature is now in proto.
 *
 * @generated from service librarian.proto.LibrarianSearch
 */
export const LibrarianSearch = {
  typeName: "librarian.proto.LibrarianSearch",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianSearch.SearchAuxiliary
     */
    searchAuxiliary: {
      name: "SearchAuxiliary",
      I: SearchRequest,
      O: EnrichedSearchResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianSearch.SearchGeo
     */
    searchGeo: {
      name: "SearchGeo",
      I: GeoRequest,
      O: GeoResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;


//
//This file defines the librarian main service.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/librarian.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Int32Value, Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { InfluencerStats, InstagramUser, User } from "../../common/proto/user_pb";
import { FavoritesLabels, InfluencerCampaigns } from "../../common/proto/campaign_pb";
import { Network, PostType } from "../../common/proto/common_pb";
import { MetaIdPb } from "../../common/proto/creator_pb";

/**
 * @generated from message librarian.proto.InstagramUserRequest
 */
export class InstagramUserRequest extends Message<InstagramUserRequest> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string user_key = 2;
   */
  userKey = "";

  /**
   * add top mentioning user in the response
   * Finding those users is costly so this is an option
   *
   * @generated from field: bool with_mentioning_users = 3;
   */
  withMentioningUsers = false;

  /**
   * Add profile pics of mentioned ( and possibly mentioning ) users
   *
   * @generated from field: bool with_profile_pics = 4;
   */
  withProfilePics = false;

  constructor(data?: PartialMessage<InstagramUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InstagramUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "with_mentioning_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "with_profile_pics", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InstagramUserRequest {
    return new InstagramUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InstagramUserRequest {
    return new InstagramUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InstagramUserRequest {
    return new InstagramUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InstagramUserRequest | PlainMessage<InstagramUserRequest> | undefined, b: InstagramUserRequest | PlainMessage<InstagramUserRequest> | undefined): boolean {
    return proto3.util.equals(InstagramUserRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.InstagramUserResponse
 */
export class InstagramUserResponse extends Message<InstagramUserResponse> {
  /**
   * @generated from field: common.proto.User user_info = 1;
   */
  userInfo?: User;

  /**
   * @generated from field: common.proto.InfluencerStats influencer_stats = 2;
   */
  influencerStats?: InfluencerStats;

  /**
   * @generated from field: common.proto.InfluencerCampaigns statuses = 3;
   */
  statuses?: InfluencerCampaigns;

  /**
   * @generated from field: common.proto.FavoritesLabels labels = 10;
   */
  labels?: FavoritesLabels;

  /**
   * list of users mentioned by this user.
   *
   * @generated from field: repeated common.proto.User mentioned_users = 4;
   */
  mentionedUsers: User[] = [];

  /**
   * list of users mentioning this user.
   *
   * @generated from field: repeated common.proto.User mentioning_users = 5;
   */
  mentioningUsers: User[] = [];

  /**
   * user info were retrieved from our db or not at all
   *
   * @generated from field: bool user_not_available = 6;
   */
  userNotAvailable = false;

  /**
   * spam info
   *
   * @generated from field: double spam_probability = 7;
   */
  spamProbability = 0;

  /**
   * Number of times each mentioned users is mentioned
   *
   * @generated from field: repeated int32 count_of_mentioned_users = 8;
   */
  countOfMentionedUsers: number[] = [];

  /**
   * Number of times each mentioning users has mentioned
   *
   * @generated from field: repeated int32 count_of_mentioning_users = 9;
   */
  countOfMentioningUsers: number[] = [];

  constructor(data?: PartialMessage<InstagramUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InstagramUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_info", kind: "message", T: User },
    { no: 2, name: "influencer_stats", kind: "message", T: InfluencerStats },
    { no: 3, name: "statuses", kind: "message", T: InfluencerCampaigns },
    { no: 10, name: "labels", kind: "message", T: FavoritesLabels },
    { no: 4, name: "mentioned_users", kind: "message", T: User, repeated: true },
    { no: 5, name: "mentioning_users", kind: "message", T: User, repeated: true },
    { no: 6, name: "user_not_available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "spam_probability", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "count_of_mentioned_users", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 9, name: "count_of_mentioning_users", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InstagramUserResponse {
    return new InstagramUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InstagramUserResponse {
    return new InstagramUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InstagramUserResponse {
    return new InstagramUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InstagramUserResponse | PlainMessage<InstagramUserResponse> | undefined, b: InstagramUserResponse | PlainMessage<InstagramUserResponse> | undefined): boolean {
    return proto3.util.equals(InstagramUserResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.SearchInstagramUsersRequest
 */
export class SearchInstagramUsersRequest extends Message<SearchInstagramUsersRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.Int32Value count = 2;
   */
  count?: number;

  /**
   * @generated from field: string user_key = 3;
   */
  userKey = "";

  /**
   * add top mentioning user in the response
   * Finding those users is costly so this is an option
   *
   * @generated from field: bool with_mentioning_users = 4;
   */
  withMentioningUsers = false;

  constructor(data?: PartialMessage<SearchInstagramUsersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SearchInstagramUsersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "count", kind: "message", T: Int32Value },
    { no: 3, name: "user_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "with_mentioning_users", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchInstagramUsersRequest {
    return new SearchInstagramUsersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchInstagramUsersRequest {
    return new SearchInstagramUsersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchInstagramUsersRequest {
    return new SearchInstagramUsersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SearchInstagramUsersRequest | PlainMessage<SearchInstagramUsersRequest> | undefined, b: SearchInstagramUsersRequest | PlainMessage<SearchInstagramUsersRequest> | undefined): boolean {
    return proto3.util.equals(SearchInstagramUsersRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.SearchInstagramUsersResponse
 */
export class SearchInstagramUsersResponse extends Message<SearchInstagramUsersResponse> {
  /**
   * @generated from field: repeated common.proto.InstagramUser user = 1;
   */
  user: InstagramUser[] = [];

  constructor(data?: PartialMessage<SearchInstagramUsersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SearchInstagramUsersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: InstagramUser, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchInstagramUsersResponse {
    return new SearchInstagramUsersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchInstagramUsersResponse {
    return new SearchInstagramUsersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchInstagramUsersResponse {
    return new SearchInstagramUsersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SearchInstagramUsersResponse | PlainMessage<SearchInstagramUsersResponse> | undefined, b: SearchInstagramUsersResponse | PlainMessage<SearchInstagramUsersResponse> | undefined): boolean {
    return proto3.util.equals(SearchInstagramUsersResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.MentionUser
 */
export class MentionUser extends Message<MentionUser> {
  /**
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: int64 score = 3;
   */
  score = protoInt64.zero;

  /**
   * might help debug
   *
   * @generated from field: repeated librarian.proto.MentionUser.MentionImage mention_image = 4;
   */
  mentionImage: MentionUser_MentionImage[] = [];

  constructor(data?: PartialMessage<MentionUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.MentionUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "score", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "mention_image", kind: "message", T: MentionUser_MentionImage, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MentionUser {
    return new MentionUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MentionUser {
    return new MentionUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MentionUser {
    return new MentionUser().fromJsonString(jsonString, options);
  }

  static equals(a: MentionUser | PlainMessage<MentionUser> | undefined, b: MentionUser | PlainMessage<MentionUser> | undefined): boolean {
    return proto3.util.equals(MentionUser, a, b);
  }
}

/**
 * @generated from message librarian.proto.MentionUser.MentionImage
 */
export class MentionUser_MentionImage extends Message<MentionUser_MentionImage> {
  /**
   * @generated from field: google.protobuf.Timestamp time = 1;
   */
  time?: Timestamp;

  /**
   * @generated from field: int32 likes = 2;
   */
  likes = 0;

  /**
   * @generated from field: string id = 3;
   */
  id = "";

  constructor(data?: PartialMessage<MentionUser_MentionImage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.MentionUser.MentionImage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time", kind: "message", T: Timestamp },
    { no: 2, name: "likes", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MentionUser_MentionImage {
    return new MentionUser_MentionImage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MentionUser_MentionImage {
    return new MentionUser_MentionImage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MentionUser_MentionImage {
    return new MentionUser_MentionImage().fromJsonString(jsonString, options);
  }

  static equals(a: MentionUser_MentionImage | PlainMessage<MentionUser_MentionImage> | undefined, b: MentionUser_MentionImage | PlainMessage<MentionUser_MentionImage> | undefined): boolean {
    return proto3.util.equals(MentionUser_MentionImage, a, b);
  }
}

/**
 * @generated from message librarian.proto.AudienceReportProgress
 */
export class AudienceReportProgress extends Message<AudienceReportProgress> {
  /**
   * Total number of followers to process.
   * We can expect to be done when done == total.
   *
   * -1 means we haven't really started yet (this shall be short lived).
   *
   * @generated from field: int32 total = 1;
   */
  total = 0;

  /**
   * @generated from field: int32 done = 2;
   */
  done = 0;

  /**
   * @generated from field: google.protobuf.Timestamp updated = 3;
   */
  updated?: Timestamp;

  /**
   * @generated from field: bool not_started = 4;
   */
  notStarted = false;

  /**
   * @generated from field: bool processing_ended = 5;
   */
  processingEnded = false;

  /**
   * If true we encountered unexpected exception (we may retry later).
   *
   * @generated from field: bool exception = 6;
   */
  exception = false;

  constructor(data?: PartialMessage<AudienceReportProgress>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AudienceReportProgress";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "done", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "updated", kind: "message", T: Timestamp },
    { no: 4, name: "not_started", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "processing_ended", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "exception", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AudienceReportProgress {
    return new AudienceReportProgress().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AudienceReportProgress {
    return new AudienceReportProgress().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AudienceReportProgress {
    return new AudienceReportProgress().fromJsonString(jsonString, options);
  }

  static equals(a: AudienceReportProgress | PlainMessage<AudienceReportProgress> | undefined, b: AudienceReportProgress | PlainMessage<AudienceReportProgress> | undefined): boolean {
    return proto3.util.equals(AudienceReportProgress, a, b);
  }
}

/**
 * @generated from message librarian.proto.PostTypeUrl
 */
export class PostTypeUrl extends Message<PostTypeUrl> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: common.proto.PostType type = 2;
   */
  type = PostType.POST_TYPE_UNDEFINED;

  constructor(data?: PartialMessage<PostTypeUrl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PostTypeUrl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(PostType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostTypeUrl {
    return new PostTypeUrl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostTypeUrl {
    return new PostTypeUrl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostTypeUrl {
    return new PostTypeUrl().fromJsonString(jsonString, options);
  }

  static equals(a: PostTypeUrl | PlainMessage<PostTypeUrl> | undefined, b: PostTypeUrl | PlainMessage<PostTypeUrl> | undefined): boolean {
    return proto3.util.equals(PostTypeUrl, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorLinkRequest
 */
export class CreatorLinkRequest extends Message<CreatorLinkRequest> {
  /**
   * Front will send master id, but any would work
   *
   * @generated from field: common.proto.MetaIdPb user_id = 1;
   */
  userId?: MetaIdPb;

  /**
   * @generated from field: common.proto.Network network = 2;
   */
  network = Network.NETWORK_UNKNOWN;

  constructor(data?: PartialMessage<CreatorLinkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorLinkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "message", T: MetaIdPb },
    { no: 2, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorLinkRequest {
    return new CreatorLinkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorLinkRequest {
    return new CreatorLinkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorLinkRequest {
    return new CreatorLinkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorLinkRequest | PlainMessage<CreatorLinkRequest> | undefined, b: CreatorLinkRequest | PlainMessage<CreatorLinkRequest> | undefined): boolean {
    return proto3.util.equals(CreatorLinkRequest, a, b);
  }
}


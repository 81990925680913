import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  inject,
} from '@angular/core';

@Component({
  selector: 'lefty-list',
  template: `<ng-content></ng-content>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeftyListComponent {
  readonly elementRef = inject(ElementRef<Element>);

  @HostBinding('class')
  readonly hostClass = 'lefty-list';

  /// Role to apply to the material-list.
  ///
  /// Defaults to 'list'
  @HostBinding('attr.role')
  @Input()
  role = 'list';

  get hasChildren(): boolean {
    // don't use childNodes, it can contains comments
    return this.elementRef.nativeElement.children.length !== 0;
  }
}

import { Component, HostBinding, Input } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Timestamp } from '@bufbuild/protobuf';
import {
  DateFormat,
  ProtoUtils,
  endOfDay,
  endOfWeek,
  getLocale,
  isNil,
  startOfDay,
  startOfWeek,
  subDuration,
} from '@frontend2/core';
import { TimeRestriction } from '@frontend2/proto/librarian/proto/common_pb';
import { AirDatepickerLocale } from 'air-datepicker';
import { LeftyFormValueBase } from '../form';
import { DatePickerRange } from './lefty-date-picker.models';

async function _loadAirDatepickerLocale(
  locale: string,
): Promise<Partial<AirDatepickerLocale>> {
  // can't import files from node_modules
  // must be relative import https://github.com/vitejs/vite/issues/14102
  const module = await import(`./locale/${locale}.js`);
  return module.default;
}

export async function loadAirDatepickerLocale(): Promise<
  Partial<AirDatepickerLocale>
> {
  const locale = getLocale().split('-')[0];
  let datepickerLocale: Partial<AirDatepickerLocale>;

  try {
    datepickerLocale = await _loadAirDatepickerLocale(locale);
  } catch (e) {
    console.warn('default datepicker locale to EN', e);
    return _loadAirDatepickerLocale('en');
  }

  return datepickerLocale;
}

export function datesToTimeRestriction(
  dates: (Date | undefined)[],
): TimeRestriction {
  const range = new TimeRestriction();

  if (dates.length >= 1 && dates[0]) {
    range.start = Timestamp.fromDate(startOfDay(dates[0]));
  }

  if (dates.length === 2 && dates[1]) {
    range.end = Timestamp.fromDate(endOfDay(dates[1]));
  }

  return range;
}

export function timeRestrictionToDates(range: TimeRestriction): Date[] {
  return [range.start?.toDate(), range.end?.toDate()].filter(
    (date) => isNil(date) === false,
  ) as Date[];
}

// prevent popup to autoDismiss when we click on element of the Datepicker
//
// Before click, the Element IS INSIDE the popup.
// But after click, the element get destroy by the datepicker,
// and we can't get is parent so we consider it outside of popup
export function preventAutoDismissForNode(node: Node): boolean {
  if (node instanceof HTMLElement) {
    return (
      // never close popup if we click on day cell
      node.className.includes('air-datepicker-cell -day-')
    );
  }
  return false;
}

const now = new Date();

function lastDays(days: number): Date {
  const last = new Date();

  last.setDate(now.getDate() - days);

  return last;
}

export const predefinedDates: DatePickerRange[] = [
  {
    label: $localize`Today`,
    start: startOfDay(now),
    end: endOfDay(now),
  },
  {
    label: $localize`Yesterday`,
    start: startOfDay(lastDays(1)),
    end: endOfDay(lastDays(1)),
  },
  {
    label: $localize`This week`,
    start: startOfWeek(now),
    end: endOfWeek(now),
  },
  {
    label: $localize`Last 7 days`,
    start: startOfDay(lastDays(7)),
    end: endOfDay(lastDays(1)),
  },
  {
    label: $localize`Last 14 days`,
    start: startOfDay(lastDays(14)),
    end: endOfDay(lastDays(1)),
  },
  {
    label: $localize`Last 30 days`,
    start: startOfDay(lastDays(30)),
    end: endOfDay(lastDays(1)),
  },
  {
    label: $localize`This month`,
    start: new Date(now.getFullYear(), now.getMonth(), 1),
    end: endOfDay(new Date(now.getFullYear(), now.getMonth() + 1, 0)),
  },
  {
    label: $localize`This quarter`,
    start: new Date(now.getFullYear(), Math.floor(now.getMonth() / 3) * 3, 1),
    end: endOfDay(
      new Date(now.getFullYear(), (Math.floor(now.getMonth() / 3) + 1) * 3, 0),
    ),
  },
  {
    label: $localize`This year`,
    start: new Date(now.getFullYear(), 0, 1),
    end: endOfDay(new Date(now.getFullYear(), 11, 31)),
  },
  {
    label: $localize`Last year`,
    start: new Date(now.getFullYear() - 1, 0, 1),
    end: endOfDay(new Date(now.getFullYear() - 1, 11, 31)),
  },
  {
    label: $localize`Last 12 months`,
    start: startOfDay(subDuration(now, 12, 'months')),
    end: endOfDay(now),
  },
];

// Shared logic for LeftyDateRangeInputComponent and LeftyDateRangePickerComponent
@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class TimeRestrictionFormBase extends LeftyFormValueBase<TimeRestriction> {
  constructor(ngControl?: NgControl) {
    super(new TimeRestriction(), ngControl);
  }

  @Input()
  dateFormat: DateFormat = 'mediumDate';

  private _minDate?: Date;

  get minDate(): Date | undefined {
    return this._minDate;
  }

  @Input()
  set minDate(value: Date | undefined) {
    this._minDate = value;
  }

  private _maxDate?: Date;

  get maxDate(): Date | undefined {
    return this._maxDate;
  }

  @Input()
  set maxDate(value: Date | undefined) {
    this._maxDate = value;
  }

  @Input()
  buttonClass = '';

  @Input()
  clearable = true;

  clear(): void {
    this.handleRangeChange([]);
  }

  handleRangeChange(val: Date[]): void {
    const newRange = datesToTimeRestriction(val);

    if (newRange.equals(this.value)) {
      return;
    }

    this.handleValueChange(newRange);
  }

  get hasRange(): boolean {
    return ProtoUtils.isEmpty(this.value) === false;
  }

  @HostBinding('class.empty')
  get isEmpty(): boolean {
    return this.hasRange === false;
  }

  override writeValue(obj: unknown): void {
    if (obj instanceof TimeRestriction) {
      this.value = obj;
    }

    if (obj === undefined) {
      this.value = new TimeRestriction();
    }
  }
}

<lefty-form
  [label]="label"
  [tooltip]="tooltip"
  [helpText]="helpText"
  [errorMessage]="errorMessage"
>
  <div
    class="input-box"
    [class.disabled]="disabled"
    (click)="autocompleteComponent.focus()"
    (keydown)="interceptKeydown($event)"
    [class.focus]="focused"
  >
    @for (chip of chips; track $index) {
      @if (withLogicOperator && $index !== 0) {
        <lefty-operator-chip
          [(value)]="logicOperatorValue"
        ></lefty-operator-chip>
      }

      <lefty-chip
        [chip]="chip"
        [removable]="!disabled"
        (remove$)="deselect(chip.value)"
      >
      </lefty-chip>
    }
    <lefty-form-autocomplete
      class="input"
      [limit]="limit"
      [popupMatchInputWidth]="popupMatchInputWidth"
      [placeholder]="placeholder"
      (selectionChange)="select($event)"
      [options]="options"
      [itemRenderer]="itemRenderer"
      [componentFactory]="componentFactory"
      [loading]="loading"
      [disabled]="inputDisabled"
      (focus$)="handleFocus($event)"
      (blur$)="handleBlur($event)"
      [tabindex]="tabIndex"
      [inputText]="inputText"
      (inputTextChange)="handleInputTextChange($event)"
      [disableClearSelection]="true"
      [noFiltering]="noFiltering"
      [shouldClearInputOnSelection]="true"
      (popupVisibleChange)="popupVisible$.next($event)"
      (scrollEnd$)="scrollEnd$.next($event)"
      (keydown$)="handleInputKeydown($event)"
      [emptyPlaceholder]="emptyPlaceholder"
    >
    </lefty-form-autocomplete>
  </div>
</lefty-form>

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/campaigns.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddOrDeleteVisualOnCampaignsRequest, AddVisualsOnCampaignsRequest, Campaign, CampaignInfluencerRefresh, CampaignMultiReport, CampaignReportSlidesCount, CampaignRequest, Campaigns, CampaignVisualRequest, DownloadAssetsRequest, EditVisualPerfReq, GenerikVisuals, GetCampaignCandidatesRequest, GetCampaignCreatorsRequestV2, GetCampaignCsvRequest, GetReportRequest, GetVisualCreatorsRequest, HaveVisualRequest, InfluencerCampaignsRequest, MultiTimeline, RefreshVisualsReq, ToggleInfluencerTrackingReq, UpdateCampaignResponse, UpdateFavoritesRequest, UpdateInfluencerStateRequest, UploadInfluencersCampaignRequest, UploadPostManuallyReq } from "./campaigns_pb";
import { Empty, Int64Value, MethodKind, StringValue } from "@bufbuild/protobuf";
import { AutocompleteLocationReq, SocialNetworkLocations } from "../../universal/retriever/proto/retriever_pb";
import { CreatorPoolRequestV2, GetVisualsRequest, PostsPerNetworkCount } from "./common_pb";
import { CreatorAutocompleteResponse, GenerikMediaSnippet } from "./users_pb";
import { BoolList, FileProto, NetworkList } from "../../common/proto/common_pb";
import { CreatorCampaignCards } from "./creators_pb";
import { UserTalkJSDetails } from "../../common/proto/talkjs_pb";

/**
 * Service to manage campaigns data
 *
 * @generated from service librarian.proto.LibrarianCampaigns
 */
export const LibrarianCampaigns = {
  typeName: "librarian.proto.LibrarianCampaigns",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetCampaignByNameAPI
     */
    getCampaignByNameAPI: {
      name: "GetCampaignByNameAPI",
      I: CampaignRequest,
      O: Int64Value,
      kind: MethodKind.Unary,
    },
    /**
     * Creates a new campaign. This will error if the campaign's name is already taken.
     * Returns the id of the created campaign.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.CreateCampaignAPI
     */
    createCampaignAPI: {
      name: "CreateCampaignAPI",
      I: CampaignRequest,
      O: Int64Value,
      kind: MethodKind.Unary,
    },
    /**
     * Edits a campaign: any field may be set in the request: dates, tracking keywords, etc...
     * The result is the token for the bronco task of updating pool performances if any
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.EditCampaignAPI
     */
    editCampaignAPI: {
      name: "EditCampaignAPI",
      I: CampaignRequest,
      O: UpdateCampaignResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCampaigns.AutocompleteNetworkLocationAPI
     */
    autocompleteNetworkLocationAPI: {
      name: "AutocompleteNetworkLocationAPI",
      I: AutocompleteLocationReq,
      O: SocialNetworkLocations,
      kind: MethodKind.Unary,
    },
    /**
     * Shares a campaign: it returns the generated sharing token.
     *
     * Beware: calling this repeatedly generates new tokens and invalidates the old ones.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.ShareCampaignAPI
     */
    shareCampaignAPI: {
      name: "ShareCampaignAPI",
      I: Int64Value,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Unshare a campaign (invalidates any sharing token that may have been previously generated).
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.UnshareCampaignAPI
     */
    unshareCampaignAPI: {
      name: "UnshareCampaignAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Pin a campaign at workspace level
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.PinCampaignAPI
     */
    pinCampaignAPI: {
      name: "PinCampaignAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Unpin a campaign at workspace level
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.UnpinCampaignAPI
     */
    unpinCampaignAPI: {
      name: "UnpinCampaignAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * refresh the posts of an influencer in a campaign
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.RefreshInfluencerInCampaign
     */
    refreshInfluencerInCampaign: {
      name: "RefreshInfluencerInCampaign",
      I: CampaignInfluencerRefresh,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Archive a campaign (acts as a soft delete): it is not running and tracked anymore, but it will not be deleted as the content is still
     * available when selecting the archived filter, and may be unarchived if needed.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.ArchiveCampaignAPI
     */
    archiveCampaignAPI: {
      name: "ArchiveCampaignAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Unarchive a campaign. If it was not previously archived, this is noop.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.UnarchiveCampaignAPI
     */
    unarchiveCampaignAPI: {
      name: "UnarchiveCampaignAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Delete a campaign, it is a error to delete an already deleted campaign
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.DeleteCampaignAPI
     */
    deleteCampaignAPI: {
      name: "DeleteCampaignAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Get details of a campaign by id.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetCampaignAPI
     */
    getCampaignAPI: {
      name: "GetCampaignAPI",
      I: Int64Value,
      O: Campaign,
      kind: MethodKind.Unary,
    },
    /**
     * Associate visuals to a campaign.
     *
     * result: - if the string is empty, the request was small enough to be processed inline and we're all done.
     *         - if the string is not empty, it is the progress token of the task of adding all these visuals.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.AddVisualToCampaignAPIV2
     */
    addVisualToCampaignAPIV2: {
      name: "AddVisualToCampaignAPIV2",
      I: CampaignVisualRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Handle association between a visual and many campaigns :
     * - For selected campaigns : add influencer to campaign is not already done, then mark visuals if needed
     * - For others users campaigns : if visual was marked, it will be unmarked.
     * Computation will be made by backend, front just send selected ids.
     *
     * result: - if the string is empty, the request was small enough to be processed inline and we're all done.
     *         - if the string is not empty, it is the progress token of the task of adding all these visuals.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.AddOrDeleteVisualOnCampaignsAPI
     */
    addOrDeleteVisualOnCampaignsAPI: {
      name: "AddOrDeleteVisualOnCampaignsAPI",
      I: AddOrDeleteVisualOnCampaignsRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Mark multiples posts to multiple campaigns
     * Add influencer to campaign is not already done, then mark visuals if needed
     *
     * result: - if the string is empty, the request was small enough to be processed inline and we're all done.
     *         - if the string is not empty, it is the progress token of the task of adding all these visuals.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.AddVisualsOnCampaignsAPI
     */
    addVisualsOnCampaignsAPI: {
      name: "AddVisualsOnCampaignsAPI",
      I: AddVisualsOnCampaignsRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Remove a visual from a campaign.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.DeleteVisualFromCampaignAPIV2
     */
    deleteVisualFromCampaignAPIV2: {
      name: "DeleteVisualFromCampaignAPIV2",
      I: CampaignVisualRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetGenerikVisualsV2
     */
    getGenerikVisualsV2: {
      name: "GetGenerikVisualsV2",
      I: GetVisualsRequest,
      O: GenerikVisuals,
      kind: MethodKind.Unary,
    },
    /**
     * returns all the creators of a campaign, in tiniest card form.
     *
     * CAVEAT 1: This method is wrongly named because it doesn't filter on only the authors who posted.
     *           Historically we had a method that did that, but on new design we decided (after much debate ahah) that the
     *           post filter autocomplete would include all creators, not just posters.
     *
     * CAVEAT 2: This method doesn't autocomplete! It just scrolls all the creators and it was agreed that the autocompletion
     *           is done frontend side. We just reuse a response of type CreatorAutocompleteResponse for convenience.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetVisualCreators
     */
    getVisualCreators: {
      name: "GetVisualCreators",
      I: GetVisualCreatorsRequest,
      O: CreatorAutocompleteResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Checks whether the given influencers in input have at least one visual in the given campaign.
     *
     * HaveVisualRequest.influencers_ids is a list and the result is the answer for each influencer, in the same order
     * as the input.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.InfluencersHaveVisualsInCampaignAPI
     */
    influencersHaveVisualsInCampaignAPI: {
      name: "InfluencersHaveVisualsInCampaignAPI",
      I: HaveVisualRequest,
      O: BoolList,
      kind: MethodKind.Unary,
    },
    /**
     * Refresh the posts of a campaign
     * Takes the campaign id and returns a Firebase refresh token
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.RefreshVisualsAPIV2
     */
    refreshVisualsAPIV2: {
      name: "RefreshVisualsAPIV2",
      I: RefreshVisualsReq,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCampaigns.InitPluginRefresh
     */
    initPluginRefresh: {
      name: "InitPluginRefresh",
      I: Int64Value,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * returns the edited media so if EMV was impacted, it is reflected right away.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.EditVisualPerf
     */
    editVisualPerf: {
      name: "EditVisualPerf",
      I: EditVisualPerfReq,
      O: GenerikMediaSnippet,
      kind: MethodKind.Unary,
    },
    /**
     * Get the list of influencer in a campaign ( the list may be paginated ) with dynamic filters
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetCampaignCreatorsAPI
     * @deprecated
     */
    getCampaignCreatorsAPI: {
      name: "GetCampaignCreatorsAPI",
      I: GetCampaignCreatorsRequestV2,
      O: CreatorCampaignCards,
      kind: MethodKind.Unary,
    },
    /**
     * Get the list of influencer in a campaign ( the list may be paginated ) with dynamic filters
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetCampaignCreators
     */
    getCampaignCreators: {
      name: "GetCampaignCreators",
      I: CreatorPoolRequestV2,
      O: CreatorCampaignCards,
      kind: MethodKind.Unary,
    },
    /**
     * Get the list of candidates in a campaign ( the list may be paginated )
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetCampaignCandidatesAPI
     */
    getCampaignCandidatesAPI: {
      name: "GetCampaignCandidatesAPI",
      I: GetCampaignCandidatesRequest,
      O: CreatorCampaignCards,
      kind: MethodKind.Unary,
    },
    /**
     * Get the list of campaigns for the logged in customer.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetCampaignsAPI
     */
    getCampaignsAPI: {
      name: "GetCampaignsAPI",
      I: Empty,
      O: Campaigns,
      kind: MethodKind.Unary,
    },
    /**
     * Get the list of campaigns with no excluded performances for the logged in customer.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetCampaignsForPerformanceAPI
     */
    getCampaignsForPerformanceAPI: {
      name: "GetCampaignsForPerformanceAPI",
      I: Empty,
      O: Campaigns,
      kind: MethodKind.Unary,
    },
    /**
     * Removes influencers from a campaign. If they had posted visuals, those will be unmarked as well.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.DeleteInfluencersAPIV2
     */
    deleteInfluencersAPIV2: {
      name: "DeleteInfluencersAPIV2",
      I: InfluencerCampaignsRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Updates the state of influencers in campaign.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.UpdateInfluencerStateAPI
     */
    updateInfluencerStateAPI: {
      name: "UpdateInfluencerStateAPI",
      I: UpdateInfluencerStateRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Add influencers to campaign. If the result is empty, this is all done. Else it is the progress handle
     * of the task.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.AddInfluencersAPIV2
     */
    addInfluencersAPIV2: {
      name: "AddInfluencersAPIV2",
      I: InfluencerCampaignsRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * returns the TALKjs info for the given campaign id.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetTalkJSInfo
     */
    getTalkJSInfo: {
      name: "GetTalkJSInfo",
      I: Int64Value,
      O: UserTalkJSDetails,
      kind: MethodKind.Unary,
    },
    /**
     * Download the performance report of a campaign formatted in CSV.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetCampaignCSVReport
     */
    getCampaignCSVReport: {
      name: "GetCampaignCSVReport",
      I: GetCampaignCsvRequest,
      O: FileProto,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetCreatorCampaignPerformancesV2
     */
    getCreatorCampaignPerformancesV2: {
      name: "GetCreatorCampaignPerformancesV2",
      I: GetReportRequest,
      O: CreatorCampaignCards,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetCampaignMultiReportV2
     */
    getCampaignMultiReportV2: {
      name: "GetCampaignMultiReportV2",
      I: GetReportRequest,
      O: CampaignMultiReport,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetCampaignMultiTimelineV2
     */
    getCampaignMultiTimelineV2: {
      name: "GetCampaignMultiTimelineV2",
      I: GetReportRequest,
      O: MultiTimeline,
      kind: MethodKind.Unary,
    },
    /**
     * Requests the generation of the performance report for the campaign in PPT format.
     *
     * This always launches a long running task in archivist and returns the progress handle that can be used to listen
     * for when the powerpoint is actually available.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.RequestCampaignReportPPTGenV2
     */
    requestCampaignReportPPTGenV2: {
      name: "RequestCampaignReportPPTGenV2",
      I: GetReportRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetReportSlidesCount
     */
    getReportSlidesCount: {
      name: "GetReportSlidesCount",
      I: GetReportRequest,
      O: CampaignReportSlidesCount,
      kind: MethodKind.Unary,
    },
    /**
     * Requests a download of the media assets for the visuals of a campaign.
     * May include all visuals, or only the ones that match the request filter if any.
     *
     * An archivist long running task will be launched to build a ZIP file of the assets and the progress token can be
     * used to listen for when the file is available.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.DownloadCampaignVisualsV2
     */
    downloadCampaignVisualsV2: {
      name: "DownloadCampaignVisualsV2",
      I: DownloadAssetsRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * returns a progress token if the request was batched, empty string if it's all done inline.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.UpdateFavorites
     */
    updateFavorites: {
      name: "UpdateFavorites",
      I: UpdateFavoritesRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Batch upload influencers in a campaign.
     * returns progress token.
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.UploadInfluencers
     */
    uploadInfluencers: {
      name: "UploadInfluencers",
      I: UploadInfluencersCampaignRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetCampaignReportHashV2
     */
    getCampaignReportHashV2: {
      name: "GetCampaignReportHashV2",
      I: GetReportRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetCampaignPostsCountPerNetwork
     */
    getCampaignPostsCountPerNetwork: {
      name: "GetCampaignPostsCountPerNetwork",
      I: Int64Value,
      O: PostsPerNetworkCount,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCampaigns.GetCampaignDistinctNetworks
     */
    getCampaignDistinctNetworks: {
      name: "GetCampaignDistinctNetworks",
      I: Int64Value,
      O: NetworkList,
      kind: MethodKind.Unary,
    },
    /**
     * returns the bronco handle
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.RequestCampaignExportExcelTask2
     */
    requestCampaignExportExcelTask2: {
      name: "RequestCampaignExportExcelTask2",
      I: GetReportRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * returns the bronco handle
     *
     * @generated from rpc librarian.proto.LibrarianCampaigns.UploadPostManually
     */
    uploadPostManually: {
      name: "UploadPostManually",
      I: UploadPostManuallyReq,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCampaigns.ToggleInfluencerTracking
     */
    toggleInfluencerTracking: {
      name: "ToggleInfluencerTracking",
      I: ToggleInfluencerTrackingReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;


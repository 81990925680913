@if (loading()) {
  <div class="spinner-container">
    <lefty-spinner></lefty-spinner>
  </div>
} @else {
  <div class="grid">
    <div
      class="col-audience"
      *ngIf="scoreIsVisible()"
    >
      <div class="audience-infos audience-score">
        <div
          class="title"
          i18n
        >
          Audience Score
        </div>

        <div class="score-container">
          <div class="score">{{ score() }}</div>
          <div
            class="score-label"
            i18n
          >
            authentic instagram users
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-audience"
      *ngIf="genderIsVisible()"
    >
      <div class="audience-infos">
        <div
          class="title"
          i18n
        >
          Audience Gender
        </div>

        <div class="info">
          <div class="label">{{ Messages.women }}</div>
          <div class="value">{{ femalePercentage() }}</div>
        </div>
        <div class="info">
          <div class="label">{{ Messages.men }}</div>
          <div class="value">{{ malePercentage() }}</div>
        </div>
      </div>
    </div>

    <div
      class="col-audience"
      *ngIf="ageIsVisible()"
    >
      <div class="audience-infos">
        <div
          class="title"
          i18n
        >
          Audience Age Range
        </div>

        <div
          class="info"
          *ngFor="let item of ages()"
        >
          <div class="label">{{ getAgeBucketName(item) }}</div>
          <div class="value">{{ formatPercentage(item.value) }}</div>
        </div>
      </div>
    </div>

    <div
      class="col-audience"
      *ngIf="geoCountriesIsVisible()"
    >
      <div class="audience-infos">
        <div
          class="title"
          i18n
        >
          Audience Top Countries
        </div>

        <div
          class="info"
          *ngFor="let item of topCountries()"
        >
          <div class="label">{{ item.name }}</div>
          <div class="value">{{ formatPercentage(item.percentage) }}</div>
        </div>
      </div>
    </div>

    <div
      class="col-audience"
      *ngIf="geoCitiesIsVisible()"
    >
      <div class="audience-infos">
        <div
          class="title"
          i18n
        >
          Audience Top Cities
        </div>

        <div
          class="info"
          *ngFor="let item of topCities()"
        >
          <div class="label">{{ item.name }}</div>
          <div class="value">{{ formatPercentage(item.percentage) }}</div>
        </div>
      </div>
    </div>
  </div>
}

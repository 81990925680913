// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/home_dashboard.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { DataPeriodEvolution, TimeRestrictions, UserSort } from "./common_pb";
import { ReportMetric, ReportTimeStep } from "./reports_pb";
import { RfmScoreValue } from "./rfm_scoring_pb";
import { BasicPagination } from "../../common/proto/common_pb";
import { CreatorTiniestCard } from "./creators_pb";
import { GenerikSnippet } from "./users_pb";

/**
 * @generated from enum librarian.proto.HomeDashboardMetric
 */
export enum HomeDashboardMetric {
  /**
   * @generated from enum value: UNKNOWN_METRIC = 0;
   */
  UNKNOWN_METRIC = 0,

  /**
   * @generated from enum value: ACTIVE_INFLUENCERS_METRIC = 1;
   */
  ACTIVE_INFLUENCERS_METRIC = 1,

  /**
   * @generated from enum value: IMPRESSIONS_METRIC = 2;
   */
  IMPRESSIONS_METRIC = 2,

  /**
   * @generated from enum value: EMV_METRIC = 3;
   */
  EMV_METRIC = 3,

  /**
   * @generated from enum value: POSTS_NUMBER_METRIC = 4;
   */
  POSTS_NUMBER_METRIC = 4,

  /**
   * @generated from enum value: ENGAGEMENT_METRIC = 5;
   */
  ENGAGEMENT_METRIC = 5,

  /**
   * @generated from enum value: CLICKS_METRIC = 6;
   */
  CLICKS_METRIC = 6,

  /**
   * @generated from enum value: CONVERSIONS_METRIC = 7;
   */
  CONVERSIONS_METRIC = 7,

  /**
   * @generated from enum value: SALES_METRIC = 8;
   */
  SALES_METRIC = 8,

  /**
   * @generated from enum value: PAID_AMOUNT_METRIC = 9;
   */
  PAID_AMOUNT_METRIC = 9,

  /**
   * @generated from enum value: PAID_INFLUENCERS_METRIC = 10;
   */
  PAID_INFLUENCERS_METRIC = 10,

  /**
   * @generated from enum value: GIFTS_SENT_NUMBER_METRIC = 11;
   */
  GIFTS_SENT_NUMBER_METRIC = 11,

  /**
   * @generated from enum value: GIFTS_AMOUNT_METRIC = 15;
   */
  GIFTS_AMOUNT_METRIC = 15,

  /**
   * @generated from enum value: INFLUENCER_AVERAGE_POST_METRIC = 12;
   */
  INFLUENCER_AVERAGE_POST_METRIC = 12,

  /**
   * @generated from enum value: CAMPAIGNS_NUMBER_METRIC = 13;
   */
  CAMPAIGNS_NUMBER_METRIC = 13,

  /**
   * @generated from enum value: AFFILIATES_NUMBER_METRIC = 14;
   */
  AFFILIATES_NUMBER_METRIC = 14,
}
// Retrieve enum metadata with: proto3.getEnumType(HomeDashboardMetric)
proto3.util.setEnumType(HomeDashboardMetric, "librarian.proto.HomeDashboardMetric", [
  { no: 0, name: "UNKNOWN_METRIC" },
  { no: 1, name: "ACTIVE_INFLUENCERS_METRIC" },
  { no: 2, name: "IMPRESSIONS_METRIC" },
  { no: 3, name: "EMV_METRIC" },
  { no: 4, name: "POSTS_NUMBER_METRIC" },
  { no: 5, name: "ENGAGEMENT_METRIC" },
  { no: 6, name: "CLICKS_METRIC" },
  { no: 7, name: "CONVERSIONS_METRIC" },
  { no: 8, name: "SALES_METRIC" },
  { no: 9, name: "PAID_AMOUNT_METRIC" },
  { no: 10, name: "PAID_INFLUENCERS_METRIC" },
  { no: 11, name: "GIFTS_SENT_NUMBER_METRIC" },
  { no: 15, name: "GIFTS_AMOUNT_METRIC" },
  { no: 12, name: "INFLUENCER_AVERAGE_POST_METRIC" },
  { no: 13, name: "CAMPAIGNS_NUMBER_METRIC" },
  { no: 14, name: "AFFILIATES_NUMBER_METRIC" },
]);

/**
 * @generated from message librarian.proto.HomeDashboardMetrics
 */
export class HomeDashboardMetrics extends Message<HomeDashboardMetrics> {
  /**
   * @generated from field: repeated librarian.proto.HomeDashboardMetric metrics = 1;
   */
  metrics: HomeDashboardMetric[] = [];

  constructor(data?: PartialMessage<HomeDashboardMetrics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardMetrics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metrics", kind: "enum", T: proto3.getEnumType(HomeDashboardMetric), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardMetrics {
    return new HomeDashboardMetrics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardMetrics {
    return new HomeDashboardMetrics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardMetrics {
    return new HomeDashboardMetrics().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardMetrics | PlainMessage<HomeDashboardMetrics> | undefined, b: HomeDashboardMetrics | PlainMessage<HomeDashboardMetrics> | undefined): boolean {
    return proto3.util.equals(HomeDashboardMetrics, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardMetricItems
 */
export class HomeDashboardMetricItems extends Message<HomeDashboardMetricItems> {
  /**
   * @generated from field: repeated librarian.proto.HomeDashboardMetricItem metrics = 1;
   */
  metrics: HomeDashboardMetricItem[] = [];

  constructor(data?: PartialMessage<HomeDashboardMetricItems>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardMetricItems";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metrics", kind: "message", T: HomeDashboardMetricItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardMetricItems {
    return new HomeDashboardMetricItems().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardMetricItems {
    return new HomeDashboardMetricItems().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardMetricItems {
    return new HomeDashboardMetricItems().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardMetricItems | PlainMessage<HomeDashboardMetricItems> | undefined, b: HomeDashboardMetricItems | PlainMessage<HomeDashboardMetricItems> | undefined): boolean {
    return proto3.util.equals(HomeDashboardMetricItems, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardMetricItem
 */
export class HomeDashboardMetricItem extends Message<HomeDashboardMetricItem> {
  /**
   * @generated from field: librarian.proto.HomeDashboardMetric metric = 1;
   */
  metric = HomeDashboardMetric.UNKNOWN_METRIC;

  /**
   * @generated from field: bool hidden = 2;
   */
  hidden = false;

  /**
   * @generated from field: int32 position = 3;
   */
  position = 0;

  /**
   * @generated from field: bool has_graph = 4;
   */
  hasGraph = false;

  constructor(data?: PartialMessage<HomeDashboardMetricItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardMetricItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metric", kind: "enum", T: proto3.getEnumType(HomeDashboardMetric) },
    { no: 2, name: "hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "position", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "has_graph", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardMetricItem {
    return new HomeDashboardMetricItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardMetricItem {
    return new HomeDashboardMetricItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardMetricItem {
    return new HomeDashboardMetricItem().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardMetricItem | PlainMessage<HomeDashboardMetricItem> | undefined, b: HomeDashboardMetricItem | PlainMessage<HomeDashboardMetricItem> | undefined): boolean {
    return proto3.util.equals(HomeDashboardMetricItem, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardFilter
 */
export class HomeDashboardFilter extends Message<HomeDashboardFilter> {
  /**
   * @generated from field: librarian.proto.TimeRestrictions time_restrictions = 1;
   */
  timeRestrictions?: TimeRestrictions;

  /**
   * @generated from field: librarian.proto.ReportTimeStep time_step = 2;
   */
  timeStep = ReportTimeStep.REPORT_STEP_AUTO;

  /**
   * @generated from field: librarian.proto.ReportMetric metric = 3;
   */
  metric = ReportMetric.POSTS;

  /**
   * @generated from field: librarian.proto.UserSort user_sort = 4;
   */
  userSort = UserSort.DEFAULT;

  /**
   * fill by backend for programmatic calls
   *
   * @generated from field: int64 user_id = 5;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<HomeDashboardFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time_restrictions", kind: "message", T: TimeRestrictions },
    { no: 2, name: "time_step", kind: "enum", T: proto3.getEnumType(ReportTimeStep) },
    { no: 3, name: "metric", kind: "enum", T: proto3.getEnumType(ReportMetric) },
    { no: 4, name: "user_sort", kind: "enum", T: proto3.getEnumType(UserSort) },
    { no: 5, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardFilter {
    return new HomeDashboardFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardFilter {
    return new HomeDashboardFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardFilter {
    return new HomeDashboardFilter().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardFilter | PlainMessage<HomeDashboardFilter> | undefined, b: HomeDashboardFilter | PlainMessage<HomeDashboardFilter> | undefined): boolean {
    return proto3.util.equals(HomeDashboardFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardMetricEvolutions
 */
export class HomeDashboardMetricEvolutions extends Message<HomeDashboardMetricEvolutions> {
  /**
   * @generated from field: repeated librarian.proto.HomeDashboardMetricEvolutions.MetricEvolution metric_evolutions = 1;
   */
  metricEvolutions: HomeDashboardMetricEvolutions_MetricEvolution[] = [];

  constructor(data?: PartialMessage<HomeDashboardMetricEvolutions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardMetricEvolutions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metric_evolutions", kind: "message", T: HomeDashboardMetricEvolutions_MetricEvolution, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardMetricEvolutions {
    return new HomeDashboardMetricEvolutions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardMetricEvolutions {
    return new HomeDashboardMetricEvolutions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardMetricEvolutions {
    return new HomeDashboardMetricEvolutions().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardMetricEvolutions | PlainMessage<HomeDashboardMetricEvolutions> | undefined, b: HomeDashboardMetricEvolutions | PlainMessage<HomeDashboardMetricEvolutions> | undefined): boolean {
    return proto3.util.equals(HomeDashboardMetricEvolutions, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardMetricEvolutions.MetricEvolution
 */
export class HomeDashboardMetricEvolutions_MetricEvolution extends Message<HomeDashboardMetricEvolutions_MetricEvolution> {
  /**
   * @generated from field: librarian.proto.HomeDashboardMetricItem metric_item = 1;
   */
  metricItem?: HomeDashboardMetricItem;

  /**
   * @generated from oneof librarian.proto.HomeDashboardMetricEvolutions.MetricEvolution.data
   */
  data: {
    /**
     * @generated from field: librarian.proto.DataPeriodEvolution evolution = 2;
     */
    value: DataPeriodEvolution;
    case: "evolution";
  } | {
    /**
     * @generated from field: int32 number = 3;
     */
    value: number;
    case: "number";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<HomeDashboardMetricEvolutions_MetricEvolution>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardMetricEvolutions.MetricEvolution";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metric_item", kind: "message", T: HomeDashboardMetricItem },
    { no: 2, name: "evolution", kind: "message", T: DataPeriodEvolution, oneof: "data" },
    { no: 3, name: "number", kind: "scalar", T: 5 /* ScalarType.INT32 */, oneof: "data" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardMetricEvolutions_MetricEvolution {
    return new HomeDashboardMetricEvolutions_MetricEvolution().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardMetricEvolutions_MetricEvolution {
    return new HomeDashboardMetricEvolutions_MetricEvolution().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardMetricEvolutions_MetricEvolution {
    return new HomeDashboardMetricEvolutions_MetricEvolution().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardMetricEvolutions_MetricEvolution | PlainMessage<HomeDashboardMetricEvolutions_MetricEvolution> | undefined, b: HomeDashboardMetricEvolutions_MetricEvolution | PlainMessage<HomeDashboardMetricEvolutions_MetricEvolution> | undefined): boolean {
    return proto3.util.equals(HomeDashboardMetricEvolutions_MetricEvolution, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardMetricGraph
 */
export class HomeDashboardMetricGraph extends Message<HomeDashboardMetricGraph> {
  /**
   * @generated from field: librarian.proto.HomeDashboardGraph current = 1;
   */
  current?: HomeDashboardGraph;

  /**
   * @generated from field: librarian.proto.HomeDashboardGraph previous = 2;
   */
  previous?: HomeDashboardGraph;

  /**
   * @generated from field: librarian.proto.ReportTimeStep time_step = 3;
   */
  timeStep = ReportTimeStep.REPORT_STEP_AUTO;

  constructor(data?: PartialMessage<HomeDashboardMetricGraph>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardMetricGraph";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "current", kind: "message", T: HomeDashboardGraph },
    { no: 2, name: "previous", kind: "message", T: HomeDashboardGraph },
    { no: 3, name: "time_step", kind: "enum", T: proto3.getEnumType(ReportTimeStep) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardMetricGraph {
    return new HomeDashboardMetricGraph().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardMetricGraph {
    return new HomeDashboardMetricGraph().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardMetricGraph {
    return new HomeDashboardMetricGraph().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardMetricGraph | PlainMessage<HomeDashboardMetricGraph> | undefined, b: HomeDashboardMetricGraph | PlainMessage<HomeDashboardMetricGraph> | undefined): boolean {
    return proto3.util.equals(HomeDashboardMetricGraph, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardGraph
 */
export class HomeDashboardGraph extends Message<HomeDashboardGraph> {
  /**
   * @generated from field: repeated librarian.proto.HomeDashboardGraph.HomeDashboardGraphPoint points = 1;
   */
  points: HomeDashboardGraph_HomeDashboardGraphPoint[] = [];

  constructor(data?: PartialMessage<HomeDashboardGraph>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardGraph";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "points", kind: "message", T: HomeDashboardGraph_HomeDashboardGraphPoint, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardGraph {
    return new HomeDashboardGraph().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardGraph {
    return new HomeDashboardGraph().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardGraph {
    return new HomeDashboardGraph().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardGraph | PlainMessage<HomeDashboardGraph> | undefined, b: HomeDashboardGraph | PlainMessage<HomeDashboardGraph> | undefined): boolean {
    return proto3.util.equals(HomeDashboardGraph, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardGraph.HomeDashboardGraphPoint
 */
export class HomeDashboardGraph_HomeDashboardGraphPoint extends Message<HomeDashboardGraph_HomeDashboardGraphPoint> {
  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 1;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: float value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<HomeDashboardGraph_HomeDashboardGraphPoint>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardGraph.HomeDashboardGraphPoint";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timestamp", kind: "message", T: Timestamp },
    { no: 2, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardGraph_HomeDashboardGraphPoint {
    return new HomeDashboardGraph_HomeDashboardGraphPoint().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardGraph_HomeDashboardGraphPoint {
    return new HomeDashboardGraph_HomeDashboardGraphPoint().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardGraph_HomeDashboardGraphPoint {
    return new HomeDashboardGraph_HomeDashboardGraphPoint().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardGraph_HomeDashboardGraphPoint | PlainMessage<HomeDashboardGraph_HomeDashboardGraphPoint> | undefined, b: HomeDashboardGraph_HomeDashboardGraphPoint | PlainMessage<HomeDashboardGraph_HomeDashboardGraphPoint> | undefined): boolean {
    return proto3.util.equals(HomeDashboardGraph_HomeDashboardGraphPoint, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardRfmScoringStatistics
 */
export class HomeDashboardRfmScoringStatistics extends Message<HomeDashboardRfmScoringStatistics> {
  /**
   * @generated from field: repeated librarian.proto.HomeDashboardRfmScoringStatistics.HomeDashboardRfmScoring rfm_scoring = 1;
   */
  rfmScoring: HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring[] = [];

  /**
   * @generated from field: librarian.proto.RfmScoreValue average_rfm_score = 2;
   */
  averageRfmScore = RfmScoreValue.UNKNOWN;

  constructor(data?: PartialMessage<HomeDashboardRfmScoringStatistics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardRfmScoringStatistics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "rfm_scoring", kind: "message", T: HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring, repeated: true },
    { no: 2, name: "average_rfm_score", kind: "enum", T: proto3.getEnumType(RfmScoreValue) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardRfmScoringStatistics {
    return new HomeDashboardRfmScoringStatistics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardRfmScoringStatistics {
    return new HomeDashboardRfmScoringStatistics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardRfmScoringStatistics {
    return new HomeDashboardRfmScoringStatistics().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardRfmScoringStatistics | PlainMessage<HomeDashboardRfmScoringStatistics> | undefined, b: HomeDashboardRfmScoringStatistics | PlainMessage<HomeDashboardRfmScoringStatistics> | undefined): boolean {
    return proto3.util.equals(HomeDashboardRfmScoringStatistics, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardRfmScoringStatistics.HomeDashboardRfmScoring
 */
export class HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring extends Message<HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring> {
  /**
   * @generated from field: librarian.proto.RfmScoreValue value = 1;
   */
  value = RfmScoreValue.UNKNOWN;

  /**
   * @generated from field: double percentage = 2;
   */
  percentage = 0;

  constructor(data?: PartialMessage<HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardRfmScoringStatistics.HomeDashboardRfmScoring";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "enum", T: proto3.getEnumType(RfmScoreValue) },
    { no: 2, name: "percentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring {
    return new HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring {
    return new HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring {
    return new HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring | PlainMessage<HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring> | undefined, b: HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring | PlainMessage<HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring> | undefined): boolean {
    return proto3.util.equals(HomeDashboardRfmScoringStatistics_HomeDashboardRfmScoring, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardRetentionStatistics
 */
export class HomeDashboardRetentionStatistics extends Message<HomeDashboardRetentionStatistics> {
  /**
   * @generated from field: librarian.proto.HomeDashboardRetentionStatistics.RetentionStatistics retained = 1;
   */
  retained?: HomeDashboardRetentionStatistics_RetentionStatistics;

  /**
   * @generated from field: librarian.proto.HomeDashboardRetentionStatistics.RetentionStatistics churned = 2;
   */
  churned?: HomeDashboardRetentionStatistics_RetentionStatistics;

  /**
   * @generated from field: librarian.proto.HomeDashboardRetentionStatistics.RetentionStatistics new = 3;
   */
  new?: HomeDashboardRetentionStatistics_RetentionStatistics;

  constructor(data?: PartialMessage<HomeDashboardRetentionStatistics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardRetentionStatistics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "retained", kind: "message", T: HomeDashboardRetentionStatistics_RetentionStatistics },
    { no: 2, name: "churned", kind: "message", T: HomeDashboardRetentionStatistics_RetentionStatistics },
    { no: 3, name: "new", kind: "message", T: HomeDashboardRetentionStatistics_RetentionStatistics },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardRetentionStatistics {
    return new HomeDashboardRetentionStatistics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardRetentionStatistics {
    return new HomeDashboardRetentionStatistics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardRetentionStatistics {
    return new HomeDashboardRetentionStatistics().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardRetentionStatistics | PlainMessage<HomeDashboardRetentionStatistics> | undefined, b: HomeDashboardRetentionStatistics | PlainMessage<HomeDashboardRetentionStatistics> | undefined): boolean {
    return proto3.util.equals(HomeDashboardRetentionStatistics, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardRetentionStatistics.RetentionStatistics
 */
export class HomeDashboardRetentionStatistics_RetentionStatistics extends Message<HomeDashboardRetentionStatistics_RetentionStatistics> {
  /**
   * @generated from field: int32 number = 1;
   */
  number = 0;

  /**
   * @generated from field: double percentage = 2;
   */
  percentage = 0;

  /**
   * @generated from field: repeated string influencer_ids = 3;
   */
  influencerIds: string[] = [];

  constructor(data?: PartialMessage<HomeDashboardRetentionStatistics_RetentionStatistics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardRetentionStatistics.RetentionStatistics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "percentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "influencer_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardRetentionStatistics_RetentionStatistics {
    return new HomeDashboardRetentionStatistics_RetentionStatistics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardRetentionStatistics_RetentionStatistics {
    return new HomeDashboardRetentionStatistics_RetentionStatistics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardRetentionStatistics_RetentionStatistics {
    return new HomeDashboardRetentionStatistics_RetentionStatistics().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardRetentionStatistics_RetentionStatistics | PlainMessage<HomeDashboardRetentionStatistics_RetentionStatistics> | undefined, b: HomeDashboardRetentionStatistics_RetentionStatistics | PlainMessage<HomeDashboardRetentionStatistics_RetentionStatistics> | undefined): boolean {
    return proto3.util.equals(HomeDashboardRetentionStatistics_RetentionStatistics, a, b);
  }
}

/**
 * @generated from message librarian.proto.RetentionDetailsRequest
 */
export class RetentionDetailsRequest extends Message<RetentionDetailsRequest> {
  /**
   * @generated from field: repeated string influencer_ids = 1;
   */
  influencerIds: string[] = [];

  /**
   * @generated from field: common.proto.BasicPagination pagination = 2;
   */
  pagination?: BasicPagination;

  /**
   * @generated from field: string name_prefix = 3;
   */
  namePrefix = "";

  constructor(data?: PartialMessage<RetentionDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RetentionDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: BasicPagination },
    { no: 3, name: "name_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RetentionDetailsRequest {
    return new RetentionDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RetentionDetailsRequest {
    return new RetentionDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RetentionDetailsRequest {
    return new RetentionDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RetentionDetailsRequest | PlainMessage<RetentionDetailsRequest> | undefined, b: RetentionDetailsRequest | PlainMessage<RetentionDetailsRequest> | undefined): boolean {
    return proto3.util.equals(RetentionDetailsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardRetentionStatisticsDetails
 */
export class HomeDashboardRetentionStatisticsDetails extends Message<HomeDashboardRetentionStatisticsDetails> {
  /**
   * @generated from field: repeated librarian.proto.CreatorTiniestCard influencers = 1;
   */
  influencers: CreatorTiniestCard[] = [];

  constructor(data?: PartialMessage<HomeDashboardRetentionStatisticsDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardRetentionStatisticsDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencers", kind: "message", T: CreatorTiniestCard, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardRetentionStatisticsDetails {
    return new HomeDashboardRetentionStatisticsDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardRetentionStatisticsDetails {
    return new HomeDashboardRetentionStatisticsDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardRetentionStatisticsDetails {
    return new HomeDashboardRetentionStatisticsDetails().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardRetentionStatisticsDetails | PlainMessage<HomeDashboardRetentionStatisticsDetails> | undefined, b: HomeDashboardRetentionStatisticsDetails | PlainMessage<HomeDashboardRetentionStatisticsDetails> | undefined): boolean {
    return proto3.util.equals(HomeDashboardRetentionStatisticsDetails, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardTopInfluencers
 */
export class HomeDashboardTopInfluencers extends Message<HomeDashboardTopInfluencers> {
  /**
   * @generated from field: repeated librarian.proto.HomeDashboardTopInfluencers.HomeDashboardTopInfluencer top_influencers = 1;
   */
  topInfluencers: HomeDashboardTopInfluencers_HomeDashboardTopInfluencer[] = [];

  constructor(data?: PartialMessage<HomeDashboardTopInfluencers>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardTopInfluencers";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "top_influencers", kind: "message", T: HomeDashboardTopInfluencers_HomeDashboardTopInfluencer, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardTopInfluencers {
    return new HomeDashboardTopInfluencers().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardTopInfluencers {
    return new HomeDashboardTopInfluencers().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardTopInfluencers {
    return new HomeDashboardTopInfluencers().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardTopInfluencers | PlainMessage<HomeDashboardTopInfluencers> | undefined, b: HomeDashboardTopInfluencers | PlainMessage<HomeDashboardTopInfluencers> | undefined): boolean {
    return proto3.util.equals(HomeDashboardTopInfluencers, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardTopInfluencers.HomeDashboardTopInfluencer
 */
export class HomeDashboardTopInfluencers_HomeDashboardTopInfluencer extends Message<HomeDashboardTopInfluencers_HomeDashboardTopInfluencer> {
  /**
   * @generated from field: librarian.proto.CreatorTiniestCard influencer = 1;
   */
  influencer?: CreatorTiniestCard;

  /**
   * @generated from field: float value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<HomeDashboardTopInfluencers_HomeDashboardTopInfluencer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardTopInfluencers.HomeDashboardTopInfluencer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer", kind: "message", T: CreatorTiniestCard },
    { no: 2, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardTopInfluencers_HomeDashboardTopInfluencer {
    return new HomeDashboardTopInfluencers_HomeDashboardTopInfluencer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardTopInfluencers_HomeDashboardTopInfluencer {
    return new HomeDashboardTopInfluencers_HomeDashboardTopInfluencer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardTopInfluencers_HomeDashboardTopInfluencer {
    return new HomeDashboardTopInfluencers_HomeDashboardTopInfluencer().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardTopInfluencers_HomeDashboardTopInfluencer | PlainMessage<HomeDashboardTopInfluencers_HomeDashboardTopInfluencer> | undefined, b: HomeDashboardTopInfluencers_HomeDashboardTopInfluencer | PlainMessage<HomeDashboardTopInfluencers_HomeDashboardTopInfluencer> | undefined): boolean {
    return proto3.util.equals(HomeDashboardTopInfluencers_HomeDashboardTopInfluencer, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardTopRunningCampaigns
 */
export class HomeDashboardTopRunningCampaigns extends Message<HomeDashboardTopRunningCampaigns> {
  /**
   * @generated from field: repeated librarian.proto.HomeDashboardTopRunningCampaigns.HomeDashboardRunningCampaign campaigns = 1;
   */
  campaigns: HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign[] = [];

  constructor(data?: PartialMessage<HomeDashboardTopRunningCampaigns>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardTopRunningCampaigns";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaigns", kind: "message", T: HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardTopRunningCampaigns {
    return new HomeDashboardTopRunningCampaigns().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardTopRunningCampaigns {
    return new HomeDashboardTopRunningCampaigns().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardTopRunningCampaigns {
    return new HomeDashboardTopRunningCampaigns().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardTopRunningCampaigns | PlainMessage<HomeDashboardTopRunningCampaigns> | undefined, b: HomeDashboardTopRunningCampaigns | PlainMessage<HomeDashboardTopRunningCampaigns> | undefined): boolean {
    return proto3.util.equals(HomeDashboardTopRunningCampaigns, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardTopRunningCampaigns.HomeDashboardRunningCampaign
 */
export class HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign extends Message<HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign> {
  /**
   * @generated from field: int64 campaign_id = 6;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: string campaign_name = 1;
   */
  campaignName = "";

  /**
   * @generated from field: int32 posts_number = 2;
   */
  postsNumber = 0;

  /**
   * @generated from field: int32 has_posted_influencers = 3;
   */
  hasPostedInfluencers = 0;

  /**
   * @generated from field: int64 emv = 4;
   */
  emv = protoInt64.zero;

  /**
   * @generated from field: int64 impressions = 5;
   */
  impressions = protoInt64.zero;

  constructor(data?: PartialMessage<HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardTopRunningCampaigns.HomeDashboardRunningCampaign";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 6, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 1, name: "campaign_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "posts_number", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "has_posted_influencers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "emv", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "impressions", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign {
    return new HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign {
    return new HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign {
    return new HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign | PlainMessage<HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign> | undefined, b: HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign | PlainMessage<HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign> | undefined): boolean {
    return proto3.util.equals(HomeDashboardTopRunningCampaigns_HomeDashboardRunningCampaign, a, b);
  }
}

/**
 * @generated from message librarian.proto.HomeDashboardTopPosts
 */
export class HomeDashboardTopPosts extends Message<HomeDashboardTopPosts> {
  /**
   * @generated from field: repeated librarian.proto.GenerikSnippet posts = 1;
   */
  posts: GenerikSnippet[] = [];

  constructor(data?: PartialMessage<HomeDashboardTopPosts>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HomeDashboardTopPosts";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "posts", kind: "message", T: GenerikSnippet, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HomeDashboardTopPosts {
    return new HomeDashboardTopPosts().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HomeDashboardTopPosts {
    return new HomeDashboardTopPosts().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HomeDashboardTopPosts {
    return new HomeDashboardTopPosts().fromJsonString(jsonString, options);
  }

  static equals(a: HomeDashboardTopPosts | PlainMessage<HomeDashboardTopPosts> | undefined, b: HomeDashboardTopPosts | PlainMessage<HomeDashboardTopPosts> | undefined): boolean {
    return proto3.util.equals(HomeDashboardTopPosts, a, b);
  }
}


// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/landing_pages.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, Int64Value, MethodKind } from "@bufbuild/protobuf";
import { CandidatesStatusRequest, CreateLandingPageRequest, DeleteLandingPageAssetRequest, EditLandingPageRequest, GetLandingAssetRequest, GetLandingAssetResponse, LandingPageCandidatesRequest, LandingPageCandidatesResponse, LandingPagePb, LandingPagesResponse, ProductsPbList, UpdateLandingAssetRequest } from "./landing_pages_pb";

/**
 * @generated from service librarian.proto.LibrarianLandingPages
 */
export const LibrarianLandingPages = {
  typeName: "librarian.proto.LibrarianLandingPages",
  methods: {
    /**
     * User should not have a lot of pages
     * Therefore Frontend will handle filtering
     *
     * @generated from rpc librarian.proto.LibrarianLandingPages.GetAllPagesAPI
     */
    getAllPagesAPI: {
      name: "GetAllPagesAPI",
      I: Empty,
      O: LandingPagesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianLandingPages.GetPageAPI
     */
    getPageAPI: {
      name: "GetPageAPI",
      I: Int64Value,
      O: LandingPagePb,
      kind: MethodKind.Unary,
    },
    /**
     * Return the newly created Landing page
     *
     * @generated from rpc librarian.proto.LibrarianLandingPages.CreatePageAPI
     */
    createPageAPI: {
      name: "CreatePageAPI",
      I: CreateLandingPageRequest,
      O: LandingPagePb,
      kind: MethodKind.Unary,
    },
    /**
     * Note: Use BrandsCompanion to edit logo and picture
     *
     * @generated from rpc librarian.proto.LibrarianLandingPages.UpdatePageAPI
     */
    updatePageAPI: {
      name: "UpdatePageAPI",
      I: EditLandingPageRequest,
      O: LandingPagePb,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianLandingPages.ArchivePageAPI
     */
    archivePageAPI: {
      name: "ArchivePageAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianLandingPages.RestorePageAPI
     */
    restorePageAPI: {
      name: "RestorePageAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianLandingPages.GetCandidatesAPI
     */
    getCandidatesAPI: {
      name: "GetCandidatesAPI",
      I: LandingPageCandidatesRequest,
      O: LandingPageCandidatesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianLandingPages.AcceptCandidateAPI
     */
    acceptCandidateAPI: {
      name: "AcceptCandidateAPI",
      I: CandidatesStatusRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianLandingPages.RejectCandidateAPI
     */
    rejectCandidateAPI: {
      name: "RejectCandidateAPI",
      I: CandidatesStatusRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Restore status of influencer to pending
     *
     * @generated from rpc librarian.proto.LibrarianLandingPages.RestoreCandidateStatusAPI
     */
    restoreCandidateStatusAPI: {
      name: "RestoreCandidateStatusAPI",
      I: CandidatesStatusRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Delete one of the assets of a landing page.
     *
     * @generated from rpc librarian.proto.LibrarianLandingPages.DeleteAssetAPI
     */
    deleteAssetAPI: {
      name: "DeleteAssetAPI",
      I: DeleteLandingPageAssetRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianLandingPages.UpdateAssetAPI
     */
    updateAssetAPI: {
      name: "UpdateAssetAPI",
      I: UpdateLandingAssetRequest,
      O: Int64Value,
      kind: MethodKind.Unary,
    },
    /**
     * Gets the assets or returns empty bytes if no assets.
     *
     * @generated from rpc librarian.proto.LibrarianLandingPages.GetAssetAPI
     */
    getAssetAPI: {
      name: "GetAssetAPI",
      I: GetLandingAssetRequest,
      O: GetLandingAssetResponse,
      kind: MethodKind.Unary,
    },
    /**
     * API to return selected products linked to a landing page
     *
     * @generated from rpc librarian.proto.LibrarianLandingPages.GetLandingPageProductsAPI
     */
    getLandingPageProductsAPI: {
      name: "GetLandingPageProductsAPI",
      I: Int64Value,
      O: ProductsPbList,
      kind: MethodKind.Unary,
    },
  }
} as const;


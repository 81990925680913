// This file defines utilities and error codes for instagram.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/instagram.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Int32Value, Message, proto3, StringValue, Timestamp } from "@bufbuild/protobuf";
import { ImageMetadata } from "./instagram_media_pb";
import { InstagramUser } from "./user_pb";
import { Int32Range, StringCount } from "./common_pb";

/**
 * Note that the names here MUST NOT BE CHANGED, they perfectly match the corresponding
 * facebook name (toLowerCase) in the graph API.
 *
 *   - If you run into namespace or scoping issues, please change the conflicting one instead.
 *   - If for whatever reason renaming those is mandatory, one must fix the InstagramParser parsing logic,
 *     which does a valueOf(name).
 *
 * @generated from enum common.proto.FacebookPermission
 */
export enum FacebookPermission {
  /**
   * @generated from enum value: FACEBOOK_PERMISSION_UNDEFINED = 0;
   */
  FACEBOOK_PERMISSION_UNDEFINED = 0,

  /**
   * @generated from enum value: EMAIL = 1;
   */
  EMAIL = 1,

  /**
   * @generated from enum value: READ_INSIGHTS = 2;
   */
  READ_INSIGHTS = 2,

  /**
   * @generated from enum value: READ_AUDIENCE_NETWORK_INSIGHTS = 3;
   */
  READ_AUDIENCE_NETWORK_INSIGHTS = 3,

  /**
   * @generated from enum value: MANAGE_PAGES = 4;
   */
  MANAGE_PAGES = 4,

  /**
   * @generated from enum value: PAGES_SHOW_LIST = 5;
   */
  PAGES_SHOW_LIST = 5,

  /**
   * @generated from enum value: INSTAGRAM_BASIC = 6;
   */
  INSTAGRAM_BASIC = 6,

  /**
   * @generated from enum value: INSTAGRAM_MANAGE_INSIGHTS = 7;
   */
  INSTAGRAM_MANAGE_INSIGHTS = 7,

  /**
   * @generated from enum value: PUBLIC_PROFILE = 8;
   */
  PUBLIC_PROFILE = 8,

  /**
   * @generated from enum value: PAGES_READ_ENGAGEMENT = 9;
   */
  PAGES_READ_ENGAGEMENT = 9,

  /**
   * @generated from enum value: BUSINESS_MANAGEMENT = 10;
   */
  BUSINESS_MANAGEMENT = 10,
}
// Retrieve enum metadata with: proto3.getEnumType(FacebookPermission)
proto3.util.setEnumType(FacebookPermission, "common.proto.FacebookPermission", [
  { no: 0, name: "FACEBOOK_PERMISSION_UNDEFINED" },
  { no: 1, name: "EMAIL" },
  { no: 2, name: "READ_INSIGHTS" },
  { no: 3, name: "READ_AUDIENCE_NETWORK_INSIGHTS" },
  { no: 4, name: "MANAGE_PAGES" },
  { no: 5, name: "PAGES_SHOW_LIST" },
  { no: 6, name: "INSTAGRAM_BASIC" },
  { no: 7, name: "INSTAGRAM_MANAGE_INSIGHTS" },
  { no: 8, name: "PUBLIC_PROFILE" },
  { no: 9, name: "PAGES_READ_ENGAGEMENT" },
  { no: 10, name: "BUSINESS_MANAGEMENT" },
]);

/**
 * @generated from message common.proto.MediaInsights
 */
export class MediaInsights extends Message<MediaInsights> {
  /**
   * The engagement in facebook graph API linguo is the absolute sum of likes, comments and saved.
   * (not to be confused with Lefty's like / followers).
   *
   * @generated from field: int32 engagement = 1;
   */
  engagement = 0;

  /**
   * @generated from field: int32 impressions = 2;
   */
  impressions = 0;

  /**
   * @generated from field: int32 likes = 6;
   */
  likes = 0;

  /**
   * @generated from field: int32 comments = 7;
   */
  comments = 0;

  /**
   * reach is the number of unique accounts the media was impressed to (eg: reach <= impressions).
   *
   * @generated from field: int32 reach = 3;
   */
  reach = 0;

  /**
   * @generated from field: int32 saved = 4;
   */
  saved = 0;

  /**
   * @generated from field: int32 shares = 5;
   */
  shares = 0;

  constructor(data?: PartialMessage<MediaInsights>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.MediaInsights";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "engagement", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "impressions", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "likes", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "comments", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "reach", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "saved", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "shares", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaInsights {
    return new MediaInsights().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaInsights {
    return new MediaInsights().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaInsights {
    return new MediaInsights().fromJsonString(jsonString, options);
  }

  static equals(a: MediaInsights | PlainMessage<MediaInsights> | undefined, b: MediaInsights | PlainMessage<MediaInsights> | undefined): boolean {
    return proto3.util.equals(MediaInsights, a, b);
  }
}

/**
 * @generated from message common.proto.FacebookGraphImage
 */
export class FacebookGraphImage extends Message<FacebookGraphImage> {
  /**
   * @generated from field: common.proto.ImageMetadata image = 1;
   */
  image?: ImageMetadata;

  /**
   * @generated from field: common.proto.MediaInsights insights = 2;
   */
  insights?: MediaInsights;

  constructor(data?: PartialMessage<FacebookGraphImage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.FacebookGraphImage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "image", kind: "message", T: ImageMetadata },
    { no: 2, name: "insights", kind: "message", T: MediaInsights },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookGraphImage {
    return new FacebookGraphImage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookGraphImage {
    return new FacebookGraphImage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookGraphImage {
    return new FacebookGraphImage().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookGraphImage | PlainMessage<FacebookGraphImage> | undefined, b: FacebookGraphImage | PlainMessage<FacebookGraphImage> | undefined): boolean {
    return proto3.util.equals(FacebookGraphImage, a, b);
  }
}

/**
 * @generated from message common.proto.Portfolio
 */
export class Portfolio extends Message<Portfolio> {
  /**
   * @generated from field: repeated common.proto.FacebookGraphImage portfolio = 1;
   */
  portfolio: FacebookGraphImage[] = [];

  /**
   * @generated from field: common.proto.Portfolio.Paging pagination = 2;
   */
  pagination?: Portfolio_Paging;

  constructor(data?: PartialMessage<Portfolio>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Portfolio";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "portfolio", kind: "message", T: FacebookGraphImage, repeated: true },
    { no: 2, name: "pagination", kind: "message", T: Portfolio_Paging },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Portfolio {
    return new Portfolio().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Portfolio {
    return new Portfolio().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Portfolio {
    return new Portfolio().fromJsonString(jsonString, options);
  }

  static equals(a: Portfolio | PlainMessage<Portfolio> | undefined, b: Portfolio | PlainMessage<Portfolio> | undefined): boolean {
    return proto3.util.equals(Portfolio, a, b);
  }
}

/**
 * @generated from message common.proto.Portfolio.Paging
 */
export class Portfolio_Paging extends Message<Portfolio_Paging> {
  /**
   * @generated from field: common.proto.Portfolio.Paging.Cursors cursors = 1;
   */
  cursors?: Portfolio_Paging_Cursors;

  /**
   * @generated from field: bool has_next = 2;
   */
  hasNext = false;

  constructor(data?: PartialMessage<Portfolio_Paging>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Portfolio.Paging";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cursors", kind: "message", T: Portfolio_Paging_Cursors },
    { no: 2, name: "has_next", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Portfolio_Paging {
    return new Portfolio_Paging().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Portfolio_Paging {
    return new Portfolio_Paging().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Portfolio_Paging {
    return new Portfolio_Paging().fromJsonString(jsonString, options);
  }

  static equals(a: Portfolio_Paging | PlainMessage<Portfolio_Paging> | undefined, b: Portfolio_Paging | PlainMessage<Portfolio_Paging> | undefined): boolean {
    return proto3.util.equals(Portfolio_Paging, a, b);
  }
}

/**
 * @generated from message common.proto.Portfolio.Paging.Cursors
 */
export class Portfolio_Paging_Cursors extends Message<Portfolio_Paging_Cursors> {
  /**
   * @generated from field: google.protobuf.StringValue before = 1;
   */
  before?: string;

  /**
   * @generated from field: google.protobuf.StringValue after = 2;
   */
  after?: string;

  constructor(data?: PartialMessage<Portfolio_Paging_Cursors>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Portfolio.Paging.Cursors";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "before", kind: "message", T: StringValue },
    { no: 2, name: "after", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Portfolio_Paging_Cursors {
    return new Portfolio_Paging_Cursors().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Portfolio_Paging_Cursors {
    return new Portfolio_Paging_Cursors().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Portfolio_Paging_Cursors {
    return new Portfolio_Paging_Cursors().fromJsonString(jsonString, options);
  }

  static equals(a: Portfolio_Paging_Cursors | PlainMessage<Portfolio_Paging_Cursors> | undefined, b: Portfolio_Paging_Cursors | PlainMessage<Portfolio_Paging_Cursors> | undefined): boolean {
    return proto3.util.equals(Portfolio_Paging_Cursors, a, b);
  }
}

/**
 * @generated from message common.proto.InstagramGraphApiResponse
 */
export class InstagramGraphApiResponse extends Message<InstagramGraphApiResponse> {
  /**
   * @generated from field: common.proto.InstagramUser user = 1;
   */
  user?: InstagramUser;

  /**
   * @generated from field: common.proto.Portfolio portfolio = 2;
   */
  portfolio?: Portfolio;

  constructor(data?: PartialMessage<InstagramGraphApiResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.InstagramGraphApiResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: InstagramUser },
    { no: 2, name: "portfolio", kind: "message", T: Portfolio },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InstagramGraphApiResponse {
    return new InstagramGraphApiResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InstagramGraphApiResponse {
    return new InstagramGraphApiResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InstagramGraphApiResponse {
    return new InstagramGraphApiResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InstagramGraphApiResponse | PlainMessage<InstagramGraphApiResponse> | undefined, b: InstagramGraphApiResponse | PlainMessage<InstagramGraphApiResponse> | undefined): boolean {
    return proto3.util.equals(InstagramGraphApiResponse, a, b);
  }
}

/**
 * @generated from message common.proto.InstagramGraphAudience
 */
export class InstagramGraphAudience extends Message<InstagramGraphAudience> {
  /**
   * @generated from field: repeated common.proto.StringCount cities = 1;
   */
  cities: StringCount[] = [];

  /**
   * @generated from field: repeated common.proto.StringCount countries = 2;
   */
  countries: StringCount[] = [];

  /**
   * @generated from field: repeated common.proto.StringCount locales = 3;
   */
  locales: StringCount[] = [];

  /**
   * @generated from field: repeated common.proto.InstagramGraphAudience.AgeBracketCount female_ages = 4;
   */
  femaleAges: InstagramGraphAudience_AgeBracketCount[] = [];

  /**
   * @generated from field: repeated common.proto.InstagramGraphAudience.AgeBracketCount male_ages = 5;
   */
  maleAges: InstagramGraphAudience_AgeBracketCount[] = [];

  constructor(data?: PartialMessage<InstagramGraphAudience>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.InstagramGraphAudience";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cities", kind: "message", T: StringCount, repeated: true },
    { no: 2, name: "countries", kind: "message", T: StringCount, repeated: true },
    { no: 3, name: "locales", kind: "message", T: StringCount, repeated: true },
    { no: 4, name: "female_ages", kind: "message", T: InstagramGraphAudience_AgeBracketCount, repeated: true },
    { no: 5, name: "male_ages", kind: "message", T: InstagramGraphAudience_AgeBracketCount, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InstagramGraphAudience {
    return new InstagramGraphAudience().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InstagramGraphAudience {
    return new InstagramGraphAudience().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InstagramGraphAudience {
    return new InstagramGraphAudience().fromJsonString(jsonString, options);
  }

  static equals(a: InstagramGraphAudience | PlainMessage<InstagramGraphAudience> | undefined, b: InstagramGraphAudience | PlainMessage<InstagramGraphAudience> | undefined): boolean {
    return proto3.util.equals(InstagramGraphAudience, a, b);
  }
}

/**
 * @generated from message common.proto.InstagramGraphAudience.AgeBracketCount
 */
export class InstagramGraphAudience_AgeBracketCount extends Message<InstagramGraphAudience_AgeBracketCount> {
  /**
   * @generated from field: common.proto.Int32Range ages_range = 1;
   */
  agesRange?: Int32Range;

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0;

  constructor(data?: PartialMessage<InstagramGraphAudience_AgeBracketCount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.InstagramGraphAudience.AgeBracketCount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ages_range", kind: "message", T: Int32Range },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InstagramGraphAudience_AgeBracketCount {
    return new InstagramGraphAudience_AgeBracketCount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InstagramGraphAudience_AgeBracketCount {
    return new InstagramGraphAudience_AgeBracketCount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InstagramGraphAudience_AgeBracketCount {
    return new InstagramGraphAudience_AgeBracketCount().fromJsonString(jsonString, options);
  }

  static equals(a: InstagramGraphAudience_AgeBracketCount | PlainMessage<InstagramGraphAudience_AgeBracketCount> | undefined, b: InstagramGraphAudience_AgeBracketCount | PlainMessage<InstagramGraphAudience_AgeBracketCount> | undefined): boolean {
    return proto3.util.equals(InstagramGraphAudience_AgeBracketCount, a, b);
  }
}

/**
 * @generated from message common.proto.InstagramGraphBusinessInsights
 */
export class InstagramGraphBusinessInsights extends Message<InstagramGraphBusinessInsights> {
  /**
   * @generated from field: repeated common.proto.InstagramGraphBusinessInsights.DayInsight insights = 1;
   */
  insights: InstagramGraphBusinessInsights_DayInsight[] = [];

  constructor(data?: PartialMessage<InstagramGraphBusinessInsights>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.InstagramGraphBusinessInsights";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "insights", kind: "message", T: InstagramGraphBusinessInsights_DayInsight, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InstagramGraphBusinessInsights {
    return new InstagramGraphBusinessInsights().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InstagramGraphBusinessInsights {
    return new InstagramGraphBusinessInsights().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InstagramGraphBusinessInsights {
    return new InstagramGraphBusinessInsights().fromJsonString(jsonString, options);
  }

  static equals(a: InstagramGraphBusinessInsights | PlainMessage<InstagramGraphBusinessInsights> | undefined, b: InstagramGraphBusinessInsights | PlainMessage<InstagramGraphBusinessInsights> | undefined): boolean {
    return proto3.util.equals(InstagramGraphBusinessInsights, a, b);
  }
}

/**
 * @generated from message common.proto.InstagramGraphBusinessInsights.DayInsight
 */
export class InstagramGraphBusinessInsights_DayInsight extends Message<InstagramGraphBusinessInsights_DayInsight> {
  /**
   * The count is from between 'end - 1 day' and 'end'.
   *
   * @generated from field: google.protobuf.Timestamp end = 1;
   */
  end?: Timestamp;

  /**
   * Here we want to differentiate unavailable from an actual value 0.
   *
   * @generated from field: google.protobuf.Int32Value followers_count = 2;
   */
  followersCount?: number;

  /**
   * @generated from field: google.protobuf.Int32Value impressions = 3;
   */
  impressions?: number;

  /**
   * @generated from field: google.protobuf.Int32Value profile_views = 4;
   */
  profileViews?: number;

  /**
   * @generated from field: google.protobuf.Int32Value website_clicks = 5;
   */
  websiteClicks?: number;

  constructor(data?: PartialMessage<InstagramGraphBusinessInsights_DayInsight>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.InstagramGraphBusinessInsights.DayInsight";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "end", kind: "message", T: Timestamp },
    { no: 2, name: "followers_count", kind: "message", T: Int32Value },
    { no: 3, name: "impressions", kind: "message", T: Int32Value },
    { no: 4, name: "profile_views", kind: "message", T: Int32Value },
    { no: 5, name: "website_clicks", kind: "message", T: Int32Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InstagramGraphBusinessInsights_DayInsight {
    return new InstagramGraphBusinessInsights_DayInsight().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InstagramGraphBusinessInsights_DayInsight {
    return new InstagramGraphBusinessInsights_DayInsight().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InstagramGraphBusinessInsights_DayInsight {
    return new InstagramGraphBusinessInsights_DayInsight().fromJsonString(jsonString, options);
  }

  static equals(a: InstagramGraphBusinessInsights_DayInsight | PlainMessage<InstagramGraphBusinessInsights_DayInsight> | undefined, b: InstagramGraphBusinessInsights_DayInsight | PlainMessage<InstagramGraphBusinessInsights_DayInsight> | undefined): boolean {
    return proto3.util.equals(InstagramGraphBusinessInsights_DayInsight, a, b);
  }
}

/**
 * encodes an instagram business account linked to a facebook account.
 *
 * @generated from message common.proto.FacebookInstagram
 */
export class FacebookInstagram extends Message<FacebookInstagram> {
  /**
   * @generated from field: string facebook_id = 1;
   */
  facebookId = "";

  /**
   * @generated from field: repeated string emails = 2;
   */
  emails: string[] = [];

  /**
   * @generated from field: common.proto.FacebookInstagram.InstagramBusiness instagram = 3;
   */
  instagram?: FacebookInstagram_InstagramBusiness;

  constructor(data?: PartialMessage<FacebookInstagram>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.FacebookInstagram";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "facebook_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "emails", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "instagram", kind: "message", T: FacebookInstagram_InstagramBusiness },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookInstagram {
    return new FacebookInstagram().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookInstagram {
    return new FacebookInstagram().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookInstagram {
    return new FacebookInstagram().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookInstagram | PlainMessage<FacebookInstagram> | undefined, b: FacebookInstagram | PlainMessage<FacebookInstagram> | undefined): boolean {
    return proto3.util.equals(FacebookInstagram, a, b);
  }
}

/**
 * @generated from message common.proto.FacebookInstagram.InstagramBusiness
 */
export class FacebookInstagram_InstagramBusiness extends Message<FacebookInstagram_InstagramBusiness> {
  /**
   * @generated from field: string instagram_id = 1;
   */
  instagramId = "";

  /**
   * @generated from field: string instagram_user_name = 2;
   */
  instagramUserName = "";

  /**
   * @generated from field: string instagram_profile_pic = 3;
   */
  instagramProfilePic = "";

  constructor(data?: PartialMessage<FacebookInstagram_InstagramBusiness>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.FacebookInstagram.InstagramBusiness";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "instagram_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "instagram_user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "instagram_profile_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookInstagram_InstagramBusiness {
    return new FacebookInstagram_InstagramBusiness().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookInstagram_InstagramBusiness {
    return new FacebookInstagram_InstagramBusiness().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookInstagram_InstagramBusiness {
    return new FacebookInstagram_InstagramBusiness().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookInstagram_InstagramBusiness | PlainMessage<FacebookInstagram_InstagramBusiness> | undefined, b: FacebookInstagram_InstagramBusiness | PlainMessage<FacebookInstagram_InstagramBusiness> | undefined): boolean {
    return proto3.util.equals(FacebookInstagram_InstagramBusiness, a, b);
  }
}

/**
 * @generated from message common.proto.FacebookInstagrams
 */
export class FacebookInstagrams extends Message<FacebookInstagrams> {
  /**
   * @generated from field: repeated common.proto.FacebookInstagram facebook_instagrams = 1;
   */
  facebookInstagrams: FacebookInstagram[] = [];

  constructor(data?: PartialMessage<FacebookInstagrams>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.FacebookInstagrams";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "facebook_instagrams", kind: "message", T: FacebookInstagram, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookInstagrams {
    return new FacebookInstagrams().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookInstagrams {
    return new FacebookInstagrams().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookInstagrams {
    return new FacebookInstagrams().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookInstagrams | PlainMessage<FacebookInstagrams> | undefined, b: FacebookInstagrams | PlainMessage<FacebookInstagrams> | undefined): boolean {
    return proto3.util.equals(FacebookInstagrams, a, b);
  }
}

/**
 * Note(christian) This is just a subset of the present fields (facebook app id, etc...).
 * We didn't parse yet those that we don't believe to ever need.
 *
 * @generated from message common.proto.DebuggedToken
 */
export class DebuggedToken extends Message<DebuggedToken> {
  /**
   * @generated from field: bool is_valid = 1;
   */
  isValid = false;

  /**
   * @generated from field: google.protobuf.Timestamp expires = 2;
   */
  expires?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp issued = 3;
   */
  issued?: Timestamp;

  /**
   * @generated from field: repeated common.proto.FacebookPermission permissions = 4;
   */
  permissions: FacebookPermission[] = [];

  constructor(data?: PartialMessage<DebuggedToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DebuggedToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_valid", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "expires", kind: "message", T: Timestamp },
    { no: 3, name: "issued", kind: "message", T: Timestamp },
    { no: 4, name: "permissions", kind: "enum", T: proto3.getEnumType(FacebookPermission), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DebuggedToken {
    return new DebuggedToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DebuggedToken {
    return new DebuggedToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DebuggedToken {
    return new DebuggedToken().fromJsonString(jsonString, options);
  }

  static equals(a: DebuggedToken | PlainMessage<DebuggedToken> | undefined, b: DebuggedToken | PlainMessage<DebuggedToken> | undefined): boolean {
    return proto3.util.equals(DebuggedToken, a, b);
  }
}

/**
 * @generated from message common.proto.FacebookAccessToken
 */
export class FacebookAccessToken extends Message<FacebookAccessToken> {
  /**
   * @generated from field: string access_token = 1;
   */
  accessToken = "";

  constructor(data?: PartialMessage<FacebookAccessToken>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.FacebookAccessToken";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookAccessToken {
    return new FacebookAccessToken().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookAccessToken {
    return new FacebookAccessToken().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookAccessToken {
    return new FacebookAccessToken().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookAccessToken | PlainMessage<FacebookAccessToken> | undefined, b: FacebookAccessToken | PlainMessage<FacebookAccessToken> | undefined): boolean {
    return proto3.util.equals(FacebookAccessToken, a, b);
  }
}


// protobufs for giovanni.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file universal/retriever/proto/retriever.proto (package universal.retriever.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Int32Value, Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { MediaIdPb, Network } from "../../../common/proto/common_pb";
import { MetaIdPb } from "../../../common/proto/creator_pb";
import { PollNature } from "../../common/proto/universal_pb";
import { ImageMetadata } from "../../../common/proto/instagram_media_pb";
import { TikTokCreator, TikTokMediaMetadata } from "../../../common/proto/tiktok_pb";
import { Status } from "../../../grpc/status/status_pb";
import { GeoPoint } from "../../../common/proto/geo_pb";

/**
 * request to poll the posts of a profile.
 *
 * this message shall be generic enough that it fits any network.
 * some fields that have no meaning in some networks are marked as such.
 *
 * @generated from message universal.retriever.proto.UserPostsReq
 */
export class UserPostsReq extends Message<UserPostsReq> {
  /**
   * which network to poll.
   *
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * id of the creator. Note that the id network is not necessarily the same as the network
   * polled (when profiles are merged it is OK to poll the youtube videos of ig:123).
   *
   * @generated from field: common.proto.MetaIdPb creator_id = 2;
   */
  creatorId?: MetaIdPb;

  /**
   * @generated from field: universal.proto.PollNature nature = 3;
   */
  nature = PollNature.POLL_NATURE_UNKNOWN;

  /**
   * If this is set, we will retrieve at most 'override_max_posts_count' posts.
   *
   * @generated from field: google.protobuf.Int32Value override_max_posts_count = 9;
   */
  overrideMaxPostsCount?: number;

  /**
   * If this is set, we will retrieve posts until they are past this date.
   * If not set, this will be the last time we had polled this creator.
   *
   * Note that override_max_posts_count will always take precedence to the time, even if override_max_posts_count wasn't set
   * and the time was. Thus if one wants to make sure 100% that posts are retrieved until 'override_max_time'
   * they shall set override_max_posts_count = MAX_VALUE too.
   *
   * @generated from field: google.protobuf.Timestamp override_max_time = 10;
   */
  overrideMaxTime?: Timestamp;

  /**
   * If the request is to poll the posts of an audience follower, the behavior will be changed in various ways:
   *     - The ingest request for the images will be affected accordingly (no indexing, no post marking, etc...)
   *     - Max 100 images, always ignore the last polling date + DO NOT UPDATE the last polling date (so in case the
   *       polled follower was a creator we won't create holes in their polling).
   *
   * @generated from field: bool is_for_audience = 6;
   */
  isForAudience = false;

  /**
   * if true, DO NOT UPDATE the last polling date.
   *
   * @generated from field: bool is_probe = 7;
   */
  isProbe = false;

  /**
   * If set this is the bronco task attempt id for the intelligence parent task that launches this post polls. In that
   * case we will update the progress of the parent task with polled media info.
   *
   * @generated from field: google.protobuf.StringValue task_attempt_id = 8;
   */
  taskAttemptId?: string;

  /**
   * Whether we want to poll regardless of PostsLastVisitTime
   *
   * @generated from field: bool force_poll = 11;
   */
  forcePoll = false;

  constructor(data?: PartialMessage<UserPostsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.UserPostsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "creator_id", kind: "message", T: MetaIdPb },
    { no: 3, name: "nature", kind: "enum", T: proto3.getEnumType(PollNature) },
    { no: 9, name: "override_max_posts_count", kind: "message", T: Int32Value },
    { no: 10, name: "override_max_time", kind: "message", T: Timestamp },
    { no: 6, name: "is_for_audience", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "is_probe", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "task_attempt_id", kind: "message", T: StringValue },
    { no: 11, name: "force_poll", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPostsReq {
    return new UserPostsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPostsReq {
    return new UserPostsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPostsReq {
    return new UserPostsReq().fromJsonString(jsonString, options);
  }

  static equals(a: UserPostsReq | PlainMessage<UserPostsReq> | undefined, b: UserPostsReq | PlainMessage<UserPostsReq> | undefined): boolean {
    return proto3.util.equals(UserPostsReq, a, b);
  }
}

/**
 * @generated from message universal.retriever.proto.UserPostsReqMaxId
 */
export class UserPostsReqMaxId extends Message<UserPostsReqMaxId> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string max_id = 2;
   */
  maxId = "";

  constructor(data?: PartialMessage<UserPostsReqMaxId>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.UserPostsReqMaxId";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "max_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPostsReqMaxId {
    return new UserPostsReqMaxId().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPostsReqMaxId {
    return new UserPostsReqMaxId().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPostsReqMaxId {
    return new UserPostsReqMaxId().fromJsonString(jsonString, options);
  }

  static equals(a: UserPostsReqMaxId | PlainMessage<UserPostsReqMaxId> | undefined, b: UserPostsReqMaxId | PlainMessage<UserPostsReqMaxId> | undefined): boolean {
    return proto3.util.equals(UserPostsReqMaxId, a, b);
  }
}

/**
 * @generated from message universal.retriever.proto.UserPostsResp
 */
export class UserPostsResp extends Message<UserPostsResp> {
  /**
   * @generated from field: repeated common.proto.ImageMetadata posts = 1;
   */
  posts: ImageMetadata[] = [];

  /**
   * @generated from field: string max_id = 2;
   */
  maxId = "";

  constructor(data?: PartialMessage<UserPostsResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.UserPostsResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "posts", kind: "message", T: ImageMetadata, repeated: true },
    { no: 2, name: "max_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPostsResp {
    return new UserPostsResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPostsResp {
    return new UserPostsResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPostsResp {
    return new UserPostsResp().fromJsonString(jsonString, options);
  }

  static equals(a: UserPostsResp | PlainMessage<UserPostsResp> | undefined, b: UserPostsResp | PlainMessage<UserPostsResp> | undefined): boolean {
    return proto3.util.equals(UserPostsResp, a, b);
  }
}

/**
 * Same as posts but one can't set forced_last_time or max_retrieved since it always retrieves all the stories of
 * the day.
 *
 * @generated from message universal.retriever.proto.UserStoriesReq
 */
export class UserStoriesReq extends Message<UserStoriesReq> {
  /**
   * @generated from field: string user_id = 2;
   */
  userId = "";

  /**
   * @generated from field: universal.proto.PollNature nature = 3;
   */
  nature = PollNature.POLL_NATURE_UNKNOWN;

  /**
   * If set, we will record on behalf of which team the stories was polled. This is the team owner id.
   * Note: for an unknown reason, it seems the task doesn't get picked when we fill it, so... Since canary is not
   * lived anymore. Ciao.
   *
   * @generated from field: google.protobuf.Int64Value owner_id = 4;
   */
  ownerId?: bigint;

  constructor(data?: PartialMessage<UserStoriesReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.UserStoriesReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "nature", kind: "enum", T: proto3.getEnumType(PollNature) },
    { no: 4, name: "owner_id", kind: "message", T: Int64Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserStoriesReq {
    return new UserStoriesReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserStoriesReq {
    return new UserStoriesReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserStoriesReq {
    return new UserStoriesReq().fromJsonString(jsonString, options);
  }

  static equals(a: UserStoriesReq | PlainMessage<UserStoriesReq> | undefined, b: UserStoriesReq | PlainMessage<UserStoriesReq> | undefined): boolean {
    return proto3.util.equals(UserStoriesReq, a, b);
  }
}

/**
 * @generated from message universal.retriever.proto.GraphStoriesIngestReq
 */
export class GraphStoriesIngestReq extends Message<GraphStoriesIngestReq> {
  /**
   * Influencer lefty id
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string json_data = 2;
   */
  jsonData = "";

  /**
   * This is set if the ingestion of this influencer is as part of a full campaign refresh.
   * As of this writing the string is just the mysql id of the corresponding CampaignPluginRefresh (we made it a string
   * so we can evolve it later if needed).
   *
   * This is not always the case: it could be a standalone plugin poll too.
   *
   * @generated from field: google.protobuf.StringValue campaign_refresh_token = 3;
   */
  campaignRefreshToken?: string;

  /**
   * Filled by backend, who requested ingestion
   *
   * @generated from field: int64 real_user_id = 4;
   */
  realUserId = protoInt64.zero;

  /**
   * @generated from field: bool impersonating = 5;
   */
  impersonating = false;

  constructor(data?: PartialMessage<GraphStoriesIngestReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.GraphStoriesIngestReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "json_data", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "campaign_refresh_token", kind: "message", T: StringValue },
    { no: 4, name: "real_user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "impersonating", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GraphStoriesIngestReq {
    return new GraphStoriesIngestReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GraphStoriesIngestReq {
    return new GraphStoriesIngestReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GraphStoriesIngestReq {
    return new GraphStoriesIngestReq().fromJsonString(jsonString, options);
  }

  static equals(a: GraphStoriesIngestReq | PlainMessage<GraphStoriesIngestReq> | undefined, b: GraphStoriesIngestReq | PlainMessage<GraphStoriesIngestReq> | undefined): boolean {
    return proto3.util.equals(GraphStoriesIngestReq, a, b);
  }
}

/**
 * serialized as pbtext in the Status description of the poll so the stories poller can process the info.
 *
 * @generated from message universal.retriever.proto.StoriesPolledOutput
 */
export class StoriesPolledOutput extends Message<StoriesPolledOutput> {
  /**
   * @generated from field: int32 stories_count = 1;
   */
  storiesCount = 0;

  /**
   * giovanni who processed the poll.
   *
   * @generated from field: int64 giovanni_id = 2;
   */
  giovanniId = protoInt64.zero;

  /**
   * @generated from field: string original_desc = 3;
   */
  originalDesc = "";

  constructor(data?: PartialMessage<StoriesPolledOutput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.StoriesPolledOutput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stories_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "giovanni_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "original_desc", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StoriesPolledOutput {
    return new StoriesPolledOutput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StoriesPolledOutput {
    return new StoriesPolledOutput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StoriesPolledOutput {
    return new StoriesPolledOutput().fromJsonString(jsonString, options);
  }

  static equals(a: StoriesPolledOutput | PlainMessage<StoriesPolledOutput> | undefined, b: StoriesPolledOutput | PlainMessage<StoriesPolledOutput> | undefined): boolean {
    return proto3.util.equals(StoriesPolledOutput, a, b);
  }
}

/**
 * @generated from message universal.retriever.proto.TiktokLuminatiCollectorResponse
 */
export class TiktokLuminatiCollectorResponse extends Message<TiktokLuminatiCollectorResponse> {
  /**
   * @generated from field: string request_arguments = 1;
   */
  requestArguments = "";

  /**
   * @generated from field: common.proto.TikTokCreator creator = 2;
   */
  creator?: TikTokCreator;

  /**
   * @generated from field: repeated common.proto.TikTokMediaMetadata videos = 3;
   */
  videos: TikTokMediaMetadata[] = [];

  /**
   * @generated from field: google.rpc.Status status = 4;
   */
  status?: Status;

  constructor(data?: PartialMessage<TiktokLuminatiCollectorResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.TiktokLuminatiCollectorResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_arguments", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "creator", kind: "message", T: TikTokCreator },
    { no: 3, name: "videos", kind: "message", T: TikTokMediaMetadata, repeated: true },
    { no: 4, name: "status", kind: "message", T: Status },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TiktokLuminatiCollectorResponse {
    return new TiktokLuminatiCollectorResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TiktokLuminatiCollectorResponse {
    return new TiktokLuminatiCollectorResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TiktokLuminatiCollectorResponse {
    return new TiktokLuminatiCollectorResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TiktokLuminatiCollectorResponse | PlainMessage<TiktokLuminatiCollectorResponse> | undefined, b: TiktokLuminatiCollectorResponse | PlainMessage<TiktokLuminatiCollectorResponse> | undefined): boolean {
    return proto3.util.equals(TiktokLuminatiCollectorResponse, a, b);
  }
}

/**
 * @generated from message universal.retriever.proto.PollMediaReq
 */
export class PollMediaReq extends Message<PollMediaReq> {
  /**
   * @generated from field: common.proto.MediaIdPb id = 1;
   */
  id?: MediaIdPb;

  /**
   * @generated from field: universal.proto.PollNature nature = 2;
   */
  nature = PollNature.POLL_NATURE_UNKNOWN;

  /**
   * When ingesting, NEVER send it to antkeeper.
   *
   * This is mostly used when antkeeper is calling PollSingleMedia() in order to refresh the URLs of a media, they
   * set this such that we do not end in an infinite loop...
   *
   * @generated from field: bool never_antkeeper = 3;
   */
  neverAntkeeper = false;

  /**
   * If we know for a fact that this is the refresh (for instance, but not limited to campaign freezer) of an existing
   * post, we can optimize a couple things in the ingester (eg: don't do post marking).
   *
   * @generated from field: bool is_stats_refresh = 4;
   */
  isStatsRefresh = false;

  /**
   * @generated from field: bool download_assets = 5;
   */
  downloadAssets = false;

  /**
   * This request is part of enriching a visual stats, and we shall get as much data as possible. On most networks this
   * makes no difference. However there might be networks with more than one way of scrapping a post data, one returning
   * more information. If this bit is set, universal shall always perform the more expensive, more comprehensive poll
   * possible.
   *
   * At the time of writing this is for Twitter: when calling the qohor scrapper, we get data that is not in the regular
   * API response (#retweets).
   *
   * @generated from field: bool full_visual_stats = 6;
   */
  fullVisualStats = false;

  /**
   * @generated from oneof universal.retriever.proto.PollMediaReq.type_metrics_twitter_retriever
   */
  typeMetricsTwitterRetriever: {
    /**
     * @generated from field: bool qohor = 7;
     */
    value: boolean;
    case: "qohor";
  } | {
    /**
     * @generated from field: bool twitter_api = 8;
     */
    value: boolean;
    case: "twitterApi";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<PollMediaReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.PollMediaReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "message", T: MediaIdPb },
    { no: 2, name: "nature", kind: "enum", T: proto3.getEnumType(PollNature) },
    { no: 3, name: "never_antkeeper", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "is_stats_refresh", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "download_assets", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "full_visual_stats", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "qohor", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "type_metrics_twitter_retriever" },
    { no: 8, name: "twitter_api", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "type_metrics_twitter_retriever" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollMediaReq {
    return new PollMediaReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollMediaReq {
    return new PollMediaReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollMediaReq {
    return new PollMediaReq().fromJsonString(jsonString, options);
  }

  static equals(a: PollMediaReq | PlainMessage<PollMediaReq> | undefined, b: PollMediaReq | PlainMessage<PollMediaReq> | undefined): boolean {
    return proto3.util.equals(PollMediaReq, a, b);
  }
}

/**
 * @generated from message universal.retriever.proto.ProfilePollingRequest
 */
export class ProfilePollingRequest extends Message<ProfilePollingRequest> {
  /**
   * @generated from oneof universal.retriever.proto.ProfilePollingRequest.selector
   */
  selector: {
    /**
     * This is typically used to resolve a user whose id we don't know yet: as a customer is uploading a list
     * to a campaign or so. We can't refresh by id since it might be the very first time that we see this profile.
     *
     * The meaning is kinda network dependant, there are networks where it's fairly easy to get at someone's handle
     * (insta, twitter, etc) and there are networks where it's commonplace to upload an URL that may not even contain
     * the username in a straightforward manner (youtube vanity URLs, youtube.me shortcuts...). In that case we might
     * have to go visit the URL and figure it out.
     *
     * @generated from field: string name_or_url = 2;
     */
    value: string;
    case: "nameOrUrl";
  } | {
    /**
     * @generated from field: common.proto.MetaIdPb user_id = 3;
     */
    value: MetaIdPb;
    case: "userId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: common.proto.Network network = 4;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: universal.proto.PollNature nature = 5;
   */
  nature = PollNature.POLL_NATURE_UNKNOWN;

  /**
   * @generated from field: bool followup_with_intelligence = 7;
   */
  followupWithIntelligence = false;

  constructor(data?: PartialMessage<ProfilePollingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.ProfilePollingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "name_or_url", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "selector" },
    { no: 3, name: "user_id", kind: "message", T: MetaIdPb, oneof: "selector" },
    { no: 4, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 5, name: "nature", kind: "enum", T: proto3.getEnumType(PollNature) },
    { no: 7, name: "followup_with_intelligence", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfilePollingRequest {
    return new ProfilePollingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfilePollingRequest {
    return new ProfilePollingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfilePollingRequest {
    return new ProfilePollingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ProfilePollingRequest | PlainMessage<ProfilePollingRequest> | undefined, b: ProfilePollingRequest | PlainMessage<ProfilePollingRequest> | undefined): boolean {
    return proto3.util.equals(ProfilePollingRequest, a, b);
  }
}

/**
 * @generated from message universal.retriever.proto.PollByNameResp
 */
export class PollByNameResp extends Message<PollByNameResp> {
  /**
   * which id it resolved to.
   *
   * @generated from field: common.proto.MetaIdPb id = 1;
   */
  id?: MetaIdPb;

  /**
   * whether or not we attempted and succeeded at publishing an intelligence refresh (token will then
   * be filled).
   *
   * If intelligence_followup was requested and this is still false, there was an error publishing the message,
   * or we didn't need to intelligence because we already knew that creator.
   *
   * @generated from field: bool intelligence_published = 2;
   */
  intelligencePublished = false;

  /**
   * @generated from field: string intelligence_token = 3;
   */
  intelligenceToken = "";

  constructor(data?: PartialMessage<PollByNameResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.PollByNameResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "message", T: MetaIdPb },
    { no: 2, name: "intelligence_published", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "intelligence_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollByNameResp {
    return new PollByNameResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollByNameResp {
    return new PollByNameResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollByNameResp {
    return new PollByNameResp().fromJsonString(jsonString, options);
  }

  static equals(a: PollByNameResp | PlainMessage<PollByNameResp> | undefined, b: PollByNameResp | PlainMessage<PollByNameResp> | undefined): boolean {
    return proto3.util.equals(PollByNameResp, a, b);
  }
}

/**
 * @generated from message universal.retriever.proto.FastIngestMetaDataMap
 */
export class FastIngestMetaDataMap extends Message<FastIngestMetaDataMap> {
  /**
   * @generated from field: repeated universal.retriever.proto.FastIngestMetaDataMap.FastIngestMetaData fast_ingest_metadata = 1;
   */
  fastIngestMetadata: FastIngestMetaDataMap_FastIngestMetaData[] = [];

  constructor(data?: PartialMessage<FastIngestMetaDataMap>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.FastIngestMetaDataMap";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fast_ingest_metadata", kind: "message", T: FastIngestMetaDataMap_FastIngestMetaData, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FastIngestMetaDataMap {
    return new FastIngestMetaDataMap().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FastIngestMetaDataMap {
    return new FastIngestMetaDataMap().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FastIngestMetaDataMap {
    return new FastIngestMetaDataMap().fromJsonString(jsonString, options);
  }

  static equals(a: FastIngestMetaDataMap | PlainMessage<FastIngestMetaDataMap> | undefined, b: FastIngestMetaDataMap | PlainMessage<FastIngestMetaDataMap> | undefined): boolean {
    return proto3.util.equals(FastIngestMetaDataMap, a, b);
  }
}

/**
 * @generated from message universal.retriever.proto.FastIngestMetaDataMap.FastIngestMetaData
 */
export class FastIngestMetaDataMap_FastIngestMetaData extends Message<FastIngestMetaDataMap_FastIngestMetaData> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0;

  /**
   * @generated from field: bool is_done = 3;
   */
  isDone = false;

  constructor(data?: PartialMessage<FastIngestMetaDataMap_FastIngestMetaData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.FastIngestMetaDataMap.FastIngestMetaData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "is_done", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FastIngestMetaDataMap_FastIngestMetaData {
    return new FastIngestMetaDataMap_FastIngestMetaData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FastIngestMetaDataMap_FastIngestMetaData {
    return new FastIngestMetaDataMap_FastIngestMetaData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FastIngestMetaDataMap_FastIngestMetaData {
    return new FastIngestMetaDataMap_FastIngestMetaData().fromJsonString(jsonString, options);
  }

  static equals(a: FastIngestMetaDataMap_FastIngestMetaData | PlainMessage<FastIngestMetaDataMap_FastIngestMetaData> | undefined, b: FastIngestMetaDataMap_FastIngestMetaData | PlainMessage<FastIngestMetaDataMap_FastIngestMetaData> | undefined): boolean {
    return proto3.util.equals(FastIngestMetaDataMap_FastIngestMetaData, a, b);
  }
}

/**
 * @generated from message universal.retriever.proto.AutocompleteLocationReq
 */
export class AutocompleteLocationReq extends Message<AutocompleteLocationReq> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: string prefix = 2;
   */
  prefix = "";

  constructor(data?: PartialMessage<AutocompleteLocationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.AutocompleteLocationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteLocationReq {
    return new AutocompleteLocationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteLocationReq {
    return new AutocompleteLocationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteLocationReq {
    return new AutocompleteLocationReq().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteLocationReq | PlainMessage<AutocompleteLocationReq> | undefined, b: AutocompleteLocationReq | PlainMessage<AutocompleteLocationReq> | undefined): boolean {
    return proto3.util.equals(AutocompleteLocationReq, a, b);
  }
}

/**
 * @generated from message universal.retriever.proto.SocialNetworkLocation
 */
export class SocialNetworkLocation extends Message<SocialNetworkLocation> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string address = 4;
   */
  address = "";

  /**
   * @generated from field: common.proto.GeoPoint coordinates = 5;
   */
  coordinates?: GeoPoint;

  constructor(data?: PartialMessage<SocialNetworkLocation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.SocialNetworkLocation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "coordinates", kind: "message", T: GeoPoint },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SocialNetworkLocation {
    return new SocialNetworkLocation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SocialNetworkLocation {
    return new SocialNetworkLocation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SocialNetworkLocation {
    return new SocialNetworkLocation().fromJsonString(jsonString, options);
  }

  static equals(a: SocialNetworkLocation | PlainMessage<SocialNetworkLocation> | undefined, b: SocialNetworkLocation | PlainMessage<SocialNetworkLocation> | undefined): boolean {
    return proto3.util.equals(SocialNetworkLocation, a, b);
  }
}

/**
 * @generated from message universal.retriever.proto.SocialNetworkLocations
 */
export class SocialNetworkLocations extends Message<SocialNetworkLocations> {
  /**
   * @generated from field: repeated universal.retriever.proto.SocialNetworkLocation locations = 1;
   */
  locations: SocialNetworkLocation[] = [];

  constructor(data?: PartialMessage<SocialNetworkLocations>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.SocialNetworkLocations";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "locations", kind: "message", T: SocialNetworkLocation, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SocialNetworkLocations {
    return new SocialNetworkLocations().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SocialNetworkLocations {
    return new SocialNetworkLocations().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SocialNetworkLocations {
    return new SocialNetworkLocations().fromJsonString(jsonString, options);
  }

  static equals(a: SocialNetworkLocations | PlainMessage<SocialNetworkLocations> | undefined, b: SocialNetworkLocations | PlainMessage<SocialNetworkLocations> | undefined): boolean {
    return proto3.util.equals(SocialNetworkLocations, a, b);
  }
}

/**
 * @generated from message universal.retriever.proto.GetSingleMediaByUrlReq
 */
export class GetSingleMediaByUrlReq extends Message<GetSingleMediaByUrlReq> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<GetSingleMediaByUrlReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.retriever.proto.GetSingleMediaByUrlReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetSingleMediaByUrlReq {
    return new GetSingleMediaByUrlReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetSingleMediaByUrlReq {
    return new GetSingleMediaByUrlReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetSingleMediaByUrlReq {
    return new GetSingleMediaByUrlReq().fromJsonString(jsonString, options);
  }

  static equals(a: GetSingleMediaByUrlReq | PlainMessage<GetSingleMediaByUrlReq> | undefined, b: GetSingleMediaByUrlReq | PlainMessage<GetSingleMediaByUrlReq> | undefined): boolean {
    return proto3.util.equals(GetSingleMediaByUrlReq, a, b);
  }
}


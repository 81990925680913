// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file universal/arkansas/proto/arkansas.proto (package universal.arkansas.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { BasicPagination, Gender, GenderWeight } from "../../../common/proto/common_pb";
import { MonthlyGrowthRequest } from "../../../librarian/proto/common_pb";

/**
 * These cannot be renamed as matching the API required names.
 *
 * @generated from enum universal.arkansas.proto.AccountType
 */
export enum AccountType {
  /**
   * @generated from enum value: ACCOUNT_UNDEFINED = 0;
   */
  ACCOUNT_UNDEFINED = 0,

  /**
   * @generated from enum value: REGULAR = 1;
   */
  REGULAR = 1,

  /**
   * @generated from enum value: BUSINESS = 2;
   */
  BUSINESS = 2,

  /**
   * @generated from enum value: CREATOR = 3;
   */
  CREATOR = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AccountType)
proto3.util.setEnumType(AccountType, "universal.arkansas.proto.AccountType", [
  { no: 0, name: "ACCOUNT_UNDEFINED" },
  { no: 1, name: "REGULAR" },
  { no: 2, name: "BUSINESS" },
  { no: 3, name: "CREATOR" },
]);

/**
 * @generated from enum universal.arkansas.proto.AudienceSource
 */
export enum AudienceSource {
  /**
   * @generated from enum value: SOURCE_ANY = 0;
   */
  SOURCE_ANY = 0,

  /**
   * @generated from enum value: SOURCE_LIKERS = 1;
   */
  SOURCE_LIKERS = 1,

  /**
   * @generated from enum value: SOURCE_FOLLOWERS = 2;
   */
  SOURCE_FOLLOWERS = 2,

  /**
   * @generated from enum value: SOURCE_COMMENTERS = 3;
   */
  SOURCE_COMMENTERS = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(AudienceSource)
proto3.util.setEnumType(AudienceSource, "universal.arkansas.proto.AudienceSource", [
  { no: 0, name: "SOURCE_ANY" },
  { no: 1, name: "SOURCE_LIKERS" },
  { no: 2, name: "SOURCE_FOLLOWERS" },
  { no: 3, name: "SOURCE_COMMENTERS" },
]);

/**
 * These cannot be renamed as matching the API required names.
 *
 * @generated from enum universal.arkansas.proto.AudienceCredibility
 */
export enum AudienceCredibility {
  /**
   * @generated from enum value: UNDEFINED_CREDIBILITY = 0;
   */
  UNDEFINED_CREDIBILITY = 0,

  /**
   * @generated from enum value: BAD = 1;
   */
  BAD = 1,

  /**
   * @generated from enum value: LOW = 2;
   */
  LOW = 2,

  /**
   * @generated from enum value: NORMAL = 3;
   */
  NORMAL = 3,

  /**
   * @generated from enum value: HIGH = 4;
   */
  HIGH = 4,

  /**
   * @generated from enum value: BEST = 5;
   */
  BEST = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(AudienceCredibility)
proto3.util.setEnumType(AudienceCredibility, "universal.arkansas.proto.AudienceCredibility", [
  { no: 0, name: "UNDEFINED_CREDIBILITY" },
  { no: 1, name: "BAD" },
  { no: 2, name: "LOW" },
  { no: 3, name: "NORMAL" },
  { no: 4, name: "HIGH" },
  { no: 5, name: "BEST" },
]);

/**
 * These cannot be renamed as matching the API required names.
 *
 * @generated from enum universal.arkansas.proto.SortType
 */
export enum SortType {
  /**
   * @generated from enum value: SORT_UNDEFINED = 0;
   */
  SORT_UNDEFINED = 0,

  /**
   * @generated from enum value: FOLLOWERS = 1;
   */
  FOLLOWERS = 1,

  /**
   * @generated from enum value: ENGAGEMENTS = 2;
   */
  ENGAGEMENTS = 2,

  /**
   * @generated from enum value: AUDIENCE_GEO = 3;
   */
  AUDIENCE_GEO = 3,

  /**
   * @generated from enum value: AUDIENCE_LANG = 4;
   */
  AUDIENCE_LANG = 4,

  /**
   * @generated from enum value: AUDIENCE_BRAND = 5;
   */
  AUDIENCE_BRAND = 5,

  /**
   * @generated from enum value: AUDIENCE_BRAND_CATEGORY = 6;
   */
  AUDIENCE_BRAND_CATEGORY = 6,

  /**
   * @generated from enum value: AUDIENCE_GENDER = 7;
   */
  AUDIENCE_GENDER = 7,

  /**
   * @generated from enum value: AUDIENCE_RACE = 8;
   */
  AUDIENCE_RACE = 8,

  /**
   * @generated from enum value: AUDIENCE_AGE = 9;
   */
  AUDIENCE_AGE = 9,

  /**
   * @generated from enum value: RELEVANCE = 10;
   */
  RELEVANCE = 10,

  /**
   * @generated from enum value: AUDIENCE_RELEVANCE = 11;
   */
  AUDIENCE_RELEVANCE = 11,

  /**
   * @generated from enum value: KEYWORDS = 12;
   */
  KEYWORDS = 12,

  /**
   * @generated from enum value: ENGAGEMENT_RATE = 13;
   */
  ENGAGEMENT_RATE = 13,
}
// Retrieve enum metadata with: proto3.getEnumType(SortType)
proto3.util.setEnumType(SortType, "universal.arkansas.proto.SortType", [
  { no: 0, name: "SORT_UNDEFINED" },
  { no: 1, name: "FOLLOWERS" },
  { no: 2, name: "ENGAGEMENTS" },
  { no: 3, name: "AUDIENCE_GEO" },
  { no: 4, name: "AUDIENCE_LANG" },
  { no: 5, name: "AUDIENCE_BRAND" },
  { no: 6, name: "AUDIENCE_BRAND_CATEGORY" },
  { no: 7, name: "AUDIENCE_GENDER" },
  { no: 8, name: "AUDIENCE_RACE" },
  { no: 9, name: "AUDIENCE_AGE" },
  { no: 10, name: "RELEVANCE" },
  { no: 11, name: "AUDIENCE_RELEVANCE" },
  { no: 12, name: "KEYWORDS" },
  { no: 13, name: "ENGAGEMENT_RATE" },
]);

/**
 * These cannot be renamed as matching the API required names.
 *
 * @generated from enum universal.arkansas.proto.SortDirection
 */
export enum SortDirection {
  /**
   * @generated from enum value: DESC = 0;
   */
  DESC = 0,

  /**
   * @generated from enum value: ASC = 1;
   */
  ASC = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(SortDirection)
proto3.util.setEnumType(SortDirection, "universal.arkansas.proto.SortDirection", [
  { no: 0, name: "DESC" },
  { no: 1, name: "ASC" },
]);

/**
 * This cannot be renamed as matching the API required names.
 *
 * @generated from enum universal.arkansas.proto.Platform
 */
export enum Platform {
  /**
   * @generated from enum value: INSTAGRAM = 0;
   */
  INSTAGRAM = 0,

  /**
   * @generated from enum value: YOUTUBE = 1;
   */
  YOUTUBE = 1,

  /**
   * @generated from enum value: TIKTOK = 2;
   */
  TIKTOK = 2,

  /**
   * @generated from enum value: DOUYIN = 6;
   */
  DOUYIN = 6,

  /**
   * These are not covered but can be requested.
   *
   * @generated from enum value: TWITTER = 3;
   */
  TWITTER = 3,

  /**
   * @generated from enum value: FACEBOOK = 4;
   */
  FACEBOOK = 4,

  /**
   * @generated from enum value: SNAPCHAT = 5;
   */
  SNAPCHAT = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(Platform)
proto3.util.setEnumType(Platform, "universal.arkansas.proto.Platform", [
  { no: 0, name: "INSTAGRAM" },
  { no: 1, name: "YOUTUBE" },
  { no: 2, name: "TIKTOK" },
  { no: 6, name: "DOUYIN" },
  { no: 3, name: "TWITTER" },
  { no: 4, name: "FACEBOOK" },
  { no: 5, name: "SNAPCHAT" },
]);

/**
 * This cannot be renamed as matching the API required names.
 *
 * @generated from enum universal.arkansas.proto.GeoType
 */
export enum GeoType {
  /**
   * @generated from enum value: GEO_UNKNOWN = 0;
   */
  GEO_UNKNOWN = 0,

  /**
   * @generated from enum value: CITY = 1;
   */
  CITY = 1,

  /**
   * @generated from enum value: STATE = 2;
   */
  STATE = 2,

  /**
   * @generated from enum value: COUNTRY = 3;
   */
  COUNTRY = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(GeoType)
proto3.util.setEnumType(GeoType, "universal.arkansas.proto.GeoType", [
  { no: 0, name: "GEO_UNKNOWN" },
  { no: 1, name: "CITY" },
  { no: 2, name: "STATE" },
  { no: 3, name: "COUNTRY" },
]);

/**
 * @generated from enum universal.arkansas.proto.AgeGroup
 */
export enum AgeGroup {
  /**
   * @generated from enum value: AGE_UNDEFINED = 0;
   */
  AGE_UNDEFINED = 0,

  /**
   * @generated from enum value: AGE_13_17 = 1;
   */
  AGE_13_17 = 1,

  /**
   * @generated from enum value: AGE_18_24 = 2;
   */
  AGE_18_24 = 2,

  /**
   * @generated from enum value: AGE_25_34 = 3;
   */
  AGE_25_34 = 3,

  /**
   * @generated from enum value: AGE_35_44 = 4;
   */
  AGE_35_44 = 4,

  /**
   * @generated from enum value: AGE_45_64 = 5;
   */
  AGE_45_64 = 5,

  /**
   * @generated from enum value: AGE_65_MORE = 6;
   */
  AGE_65_MORE = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(AgeGroup)
proto3.util.setEnumType(AgeGroup, "universal.arkansas.proto.AgeGroup", [
  { no: 0, name: "AGE_UNDEFINED" },
  { no: 1, name: "AGE_13_17" },
  { no: 2, name: "AGE_18_24" },
  { no: 3, name: "AGE_25_34" },
  { no: 4, name: "AGE_35_44" },
  { no: 5, name: "AGE_45_64" },
  { no: 6, name: "AGE_65_MORE" },
]);

/**
 * @generated from enum universal.arkansas.proto.TextTagType
 */
export enum TextTagType {
  /**
   * @generated from enum value: TEXT_TAG_UNDEFINED = 0;
   */
  TEXT_TAG_UNDEFINED = 0,

  /**
   * @generated from enum value: TEXT_TAG_HASHTAG = 1;
   */
  TEXT_TAG_HASHTAG = 1,

  /**
   * @generated from enum value: TEXT_TAG_MENTION = 2;
   */
  TEXT_TAG_MENTION = 2,

  /**
   * @generated from enum value: TEXT_TAG_TEXT_MENTION = 3;
   */
  TEXT_TAG_TEXT_MENTION = 3,

  /**
   * @generated from enum value: TEXT_TAG_PHOTO_MENTION = 4;
   */
  TEXT_TAG_PHOTO_MENTION = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(TextTagType)
proto3.util.setEnumType(TextTagType, "universal.arkansas.proto.TextTagType", [
  { no: 0, name: "TEXT_TAG_UNDEFINED" },
  { no: 1, name: "TEXT_TAG_HASHTAG" },
  { no: 2, name: "TEXT_TAG_MENTION" },
  { no: 3, name: "TEXT_TAG_TEXT_MENTION" },
  { no: 4, name: "TEXT_TAG_PHOTO_MENTION" },
]);

/**
 * @generated from enum universal.arkansas.proto.ActionType
 */
export enum ActionType {
  /**
   * @generated from enum value: ACTION_TYPE_UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: ACTION_TYPE_MUST = 1;
   */
  MUST = 1,

  /**
   * @generated from enum value: ACTION_TYPE_SHOULD = 2;
   */
  SHOULD = 2,

  /**
   * @generated from enum value: ACTION_TYPE_NOT = 3;
   */
  NOT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ActionType)
proto3.util.setEnumType(ActionType, "universal.arkansas.proto.ActionType", [
  { no: 0, name: "ACTION_TYPE_UNDEFINED" },
  { no: 1, name: "ACTION_TYPE_MUST" },
  { no: 2, name: "ACTION_TYPE_SHOULD" },
  { no: 3, name: "ACTION_TYPE_NOT" },
]);

/**
 * @generated from message universal.arkansas.proto.Language
 */
export class Language extends Message<Language> {
  /**
   * @generated from field: string code = 1;
   */
  code = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<Language>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.Language";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Language {
    return new Language().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Language {
    return new Language().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Language {
    return new Language().fromJsonString(jsonString, options);
  }

  static equals(a: Language | PlainMessage<Language> | undefined, b: Language | PlainMessage<Language> | undefined): boolean {
    return proto3.util.equals(Language, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.Country
 */
export class Country extends Message<Country> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string code = 2;
   */
  code = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  constructor(data?: PartialMessage<Country>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.Country";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Country {
    return new Country().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Country {
    return new Country().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Country {
    return new Country().fromJsonString(jsonString, options);
  }

  static equals(a: Country | PlainMessage<Country> | undefined, b: Country | PlainMessage<Country> | undefined): boolean {
    return proto3.util.equals(Country, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.Geo
 */
export class Geo extends Message<Geo> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: repeated universal.arkansas.proto.GeoType types = 4;
   */
  types: GeoType[] = [];

  /**
   * @generated from field: universal.arkansas.proto.Country country = 5;
   */
  country?: Country;

  constructor(data?: PartialMessage<Geo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.Geo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "types", kind: "enum", T: proto3.getEnumType(GeoType), repeated: true },
    { no: 5, name: "country", kind: "message", T: Country },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Geo {
    return new Geo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Geo {
    return new Geo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Geo {
    return new Geo().fromJsonString(jsonString, options);
  }

  static equals(a: Geo | PlainMessage<Geo> | undefined, b: Geo | PlainMessage<Geo> | undefined): boolean {
    return proto3.util.equals(Geo, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.GeoRequest
 */
export class GeoRequest extends Message<GeoRequest> {
  /**
   * @generated from field: string query = 1;
   */
  query = "";

  /**
   * @generated from field: int32 limit = 2;
   */
  limit = 0;

  /**
   * @generated from field: string country_code = 3;
   */
  countryCode = "";

  /**
   * @generated from field: repeated universal.arkansas.proto.GeoType types = 4;
   */
  types: GeoType[] = [];

  constructor(data?: PartialMessage<GeoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.GeoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "limit", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "types", kind: "enum", T: proto3.getEnumType(GeoType), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeoRequest {
    return new GeoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoRequest {
    return new GeoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeoRequest {
    return new GeoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GeoRequest | PlainMessage<GeoRequest> | undefined, b: GeoRequest | PlainMessage<GeoRequest> | undefined): boolean {
    return proto3.util.equals(GeoRequest, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.GeoResponse
 */
export class GeoResponse extends Message<GeoResponse> {
  /**
   * @generated from field: repeated universal.arkansas.proto.Geo geos = 1;
   */
  geos: Geo[] = [];

  constructor(data?: PartialMessage<GeoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.GeoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "geos", kind: "message", T: Geo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeoResponse {
    return new GeoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoResponse {
    return new GeoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeoResponse {
    return new GeoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GeoResponse | PlainMessage<GeoResponse> | undefined, b: GeoResponse | PlainMessage<GeoResponse> | undefined): boolean {
    return proto3.util.equals(GeoResponse, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.RangeSearch
 */
export class RangeSearch extends Message<RangeSearch> {
  /**
   * @generated from field: int32 min = 1;
   */
  min = 0;

  /**
   * @generated from field: int32 max = 2;
   */
  max = 0;

  /**
   * 0 <= weigth <= 1
   *
   * @generated from field: float weight = 3;
   */
  weight = 0;

  constructor(data?: PartialMessage<RangeSearch>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.RangeSearch";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "max", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "weight", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RangeSearch {
    return new RangeSearch().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RangeSearch {
    return new RangeSearch().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RangeSearch {
    return new RangeSearch().fromJsonString(jsonString, options);
  }

  static equals(a: RangeSearch | PlainMessage<RangeSearch> | undefined, b: RangeSearch | PlainMessage<RangeSearch> | undefined): boolean {
    return proto3.util.equals(RangeSearch, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.IdSearch
 */
export class IdSearch extends Message<IdSearch> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * 0 <= weigth <= 1
   *
   * @generated from field: float weight = 2;
   */
  weight = 0;

  constructor(data?: PartialMessage<IdSearch>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.IdSearch";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "weight", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IdSearch {
    return new IdSearch().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IdSearch {
    return new IdSearch().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IdSearch {
    return new IdSearch().fromJsonString(jsonString, options);
  }

  static equals(a: IdSearch | PlainMessage<IdSearch> | undefined, b: IdSearch | PlainMessage<IdSearch> | undefined): boolean {
    return proto3.util.equals(IdSearch, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.CodeSearch
 */
export class CodeSearch extends Message<CodeSearch> {
  /**
   * @generated from field: string code = 1;
   */
  code = "";

  /**
   * 0 <= weigth <= 1
   *
   * @generated from field: float weight = 2;
   */
  weight = 0;

  constructor(data?: PartialMessage<CodeSearch>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.CodeSearch";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "weight", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CodeSearch {
    return new CodeSearch().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CodeSearch {
    return new CodeSearch().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CodeSearch {
    return new CodeSearch().fromJsonString(jsonString, options);
  }

  static equals(a: CodeSearch | PlainMessage<CodeSearch> | undefined, b: CodeSearch | PlainMessage<CodeSearch> | undefined): boolean {
    return proto3.util.equals(CodeSearch, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.TextTagSearch
 */
export class TextTagSearch extends Message<TextTagSearch> {
  /**
   * @generated from field: universal.arkansas.proto.TextTagType type = 1;
   */
  type = TextTagType.TEXT_TAG_UNDEFINED;

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  /**
   * @generated from field: universal.arkansas.proto.ActionType action = 3;
   */
  action = ActionType.UNDEFINED;

  constructor(data?: PartialMessage<TextTagSearch>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.TextTagSearch";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(TextTagType) },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "action", kind: "enum", T: proto3.getEnumType(ActionType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextTagSearch {
    return new TextTagSearch().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextTagSearch {
    return new TextTagSearch().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextTagSearch {
    return new TextTagSearch().fromJsonString(jsonString, options);
  }

  static equals(a: TextTagSearch | PlainMessage<TextTagSearch> | undefined, b: TextTagSearch | PlainMessage<TextTagSearch> | undefined): boolean {
    return proto3.util.equals(TextTagSearch, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.SearchRequest
 */
export class SearchRequest extends Message<SearchRequest> {
  /**
   * @generated from field: universal.arkansas.proto.RangeSearch followers = 1;
   */
  followers?: RangeSearch;

  /**
   * This is the total number of engagements collected by the influencer ... not very useful.
   *
   * @generated from field: universal.arkansas.proto.RangeSearch engagements = 2;
   */
  engagements?: RangeSearch;

  /**
   * min engagement rate is between 0 and 1.
   *
   * @generated from field: float min_engagement_rate = 14;
   */
  minEngagementRate = 0;

  /**
   * Max number of days since the last post, must be >= 30
   *
   * @generated from field: int32 last_posted = 15;
   */
  lastPosted = 0;

  /**
   * @generated from field: universal.arkansas.proto.RangeSearch age = 3;
   */
  age?: RangeSearch;

  /**
   * @generated from field: string lang = 4;
   */
  lang = "";

  /**
   * @generated from field: repeated int32 geo = 5;
   */
  geo: number[] = [];

  /**
   * @generated from field: float audience_credibility = 6;
   */
  audienceCredibility = 0;

  /**
   * @generated from field: repeated universal.arkansas.proto.AudienceCredibility audience_credibility_class = 16;
   */
  audienceCredibilityClass: AudienceCredibility[] = [];

  /**
   * @generated from field: string bio = 8;
   */
  bio = "";

  /**
   * @generated from field: repeated int32 brand = 9;
   */
  brand: number[] = [];

  /**
   * @generated from field: repeated int32 brand_category = 10;
   */
  brandCategory: number[] = [];

  /**
   * @generated from field: common.proto.Gender gender = 12;
   */
  gender = Gender.GENDER_UNDEFINED;

  /**
   * @generated from field: repeated universal.arkansas.proto.Platform hasSocial = 13;
   */
  hasSocial: Platform[] = [];

  /**
   * Not supported by Instagram
   *
   * @generated from field: universal.arkansas.proto.RangeSearch views = 19;
   */
  views?: RangeSearch;

  /**
   * This means with similar topics in posts than a particular influencer if relevance = @username
   *
   * @generated from field: string relevance = 11;
   */
  relevance = "";

  /**
   * This means with an audience look-alike to a particular influencer if audience_relevance = @username
   *
   * @generated from field: string audience_relevance = 21;
   */
  audienceRelevance = "";

  /**
   * Text tags covers the search of mentions or hashtags.
   *
   * @generated from field: repeated universal.arkansas.proto.TextTagSearch text_tags = 17;
   */
  textTags: TextTagSearch[] = [];

  /**
   * This covers the search of any keyword used in the captions.
   *
   * @generated from field: string keywords = 18;
   */
  keywords = "";

  /**
   * @generated from field: bool has_email = 20;
   */
  hasEmail = false;

  /**
   * @generated from field: universal.arkansas.proto.AudienceSource audience_source = 100;
   */
  audienceSource = AudienceSource.SOURCE_ANY;

  /**
   * @generated from field: universal.arkansas.proto.RangeSearch audience_age = 101;
   */
  audienceAge?: RangeSearch;

  /**
   * @generated from field: common.proto.GenderWeight audience_gender = 102;
   */
  audienceGender?: GenderWeight;

  /**
   * @generated from field: universal.arkansas.proto.CodeSearch audience_lang = 103;
   */
  audienceLang?: CodeSearch;

  /**
   * @generated from field: repeated universal.arkansas.proto.IdSearch audience_geo = 104;
   */
  audienceGeo: IdSearch[] = [];

  /**
   * @generated from field: repeated universal.arkansas.proto.IdSearch audience_brand = 105;
   */
  audienceBrand: IdSearch[] = [];

  /**
   * @generated from field: repeated universal.arkansas.proto.IdSearch audience_brand_category = 106;
   */
  audienceBrandCategory: IdSearch[] = [];

  /**
   * @generated from field: librarian.proto.MonthlyGrowthRequest followers_growth = 107;
   */
  followersGrowth?: MonthlyGrowthRequest;

  /**
   * @generated from field: librarian.proto.MonthlyGrowthRequest total_views_growth = 108;
   */
  totalViewsGrowth?: MonthlyGrowthRequest;

  /**
   * @generated from field: librarian.proto.MonthlyGrowthRequest total_likes_growth = 109;
   */
  totalLikesGrowth?: MonthlyGrowthRequest;

  /**
   * @generated from field: universal.arkansas.proto.RangeSearch reels_plays = 110;
   */
  reelsPlays?: RangeSearch;

  /**
   * @generated from field: universal.arkansas.proto.RangeSearch saves = 111;
   */
  saves?: RangeSearch;

  /**
   * @generated from field: universal.arkansas.proto.RangeSearch shares = 112;
   */
  shares?: RangeSearch;

  /**
   * @generated from field: bool is_verified = 113;
   */
  isVerified = false;

  /**
   * @generated from field: bool has_ads = 114;
   */
  hasAds = false;

  /**
   * @generated from field: bool is_official_artist = 115;
   */
  isOfficialArtist = false;

  /**
   * @generated from field: repeated int32 ads_brands = 116;
   */
  adsBrands: number[] = [];

  /**
   * Sort & Pagination
   *
   * @generated from field: universal.arkansas.proto.SortType sortType = 1000;
   */
  sortType = SortType.SORT_UNDEFINED;

  /**
   * @generated from field: universal.arkansas.proto.SortDirection sortDir = 1001;
   */
  sortDir = SortDirection.DESC;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 1002;
   */
  pagination?: BasicPagination;

  /**
   * @generated from field: repeated universal.arkansas.proto.AccountType account_types = 1003;
   */
  accountTypes: AccountType[] = [];

  /**
   * @generated from field: universal.arkansas.proto.Platform platform = 10000;
   */
  platform = Platform.INSTAGRAM;

  constructor(data?: PartialMessage<SearchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.SearchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "followers", kind: "message", T: RangeSearch },
    { no: 2, name: "engagements", kind: "message", T: RangeSearch },
    { no: 14, name: "min_engagement_rate", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 15, name: "last_posted", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "age", kind: "message", T: RangeSearch },
    { no: 4, name: "lang", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "geo", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 6, name: "audience_credibility", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 16, name: "audience_credibility_class", kind: "enum", T: proto3.getEnumType(AudienceCredibility), repeated: true },
    { no: 8, name: "bio", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "brand", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 10, name: "brand_category", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 12, name: "gender", kind: "enum", T: proto3.getEnumType(Gender) },
    { no: 13, name: "hasSocial", kind: "enum", T: proto3.getEnumType(Platform), repeated: true },
    { no: 19, name: "views", kind: "message", T: RangeSearch },
    { no: 11, name: "relevance", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "audience_relevance", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "text_tags", kind: "message", T: TextTagSearch, repeated: true },
    { no: 18, name: "keywords", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "has_email", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 100, name: "audience_source", kind: "enum", T: proto3.getEnumType(AudienceSource) },
    { no: 101, name: "audience_age", kind: "message", T: RangeSearch },
    { no: 102, name: "audience_gender", kind: "message", T: GenderWeight },
    { no: 103, name: "audience_lang", kind: "message", T: CodeSearch },
    { no: 104, name: "audience_geo", kind: "message", T: IdSearch, repeated: true },
    { no: 105, name: "audience_brand", kind: "message", T: IdSearch, repeated: true },
    { no: 106, name: "audience_brand_category", kind: "message", T: IdSearch, repeated: true },
    { no: 107, name: "followers_growth", kind: "message", T: MonthlyGrowthRequest },
    { no: 108, name: "total_views_growth", kind: "message", T: MonthlyGrowthRequest },
    { no: 109, name: "total_likes_growth", kind: "message", T: MonthlyGrowthRequest },
    { no: 110, name: "reels_plays", kind: "message", T: RangeSearch },
    { no: 111, name: "saves", kind: "message", T: RangeSearch },
    { no: 112, name: "shares", kind: "message", T: RangeSearch },
    { no: 113, name: "is_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 114, name: "has_ads", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 115, name: "is_official_artist", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 116, name: "ads_brands", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 1000, name: "sortType", kind: "enum", T: proto3.getEnumType(SortType) },
    { no: 1001, name: "sortDir", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 1002, name: "pagination", kind: "message", T: BasicPagination },
    { no: 1003, name: "account_types", kind: "enum", T: proto3.getEnumType(AccountType), repeated: true },
    { no: 10000, name: "platform", kind: "enum", T: proto3.getEnumType(Platform) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchRequest {
    return new SearchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchRequest {
    return new SearchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchRequest {
    return new SearchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SearchRequest | PlainMessage<SearchRequest> | undefined, b: SearchRequest | PlainMessage<SearchRequest> | undefined): boolean {
    return proto3.util.equals(SearchRequest, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.Account
 */
export class Account extends Message<Account> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  /**
   * @generated from field: string picture = 4;
   */
  picture = "";

  /**
   * @generated from field: string fullname = 5;
   */
  fullname = "";

  /**
   * @generated from field: int32 followers = 6;
   */
  followers = 0;

  /**
   * @generated from field: int32 engagements = 7;
   */
  engagements = 0;

  /**
   * @generated from field: float engagement_rate = 8;
   */
  engagementRate = 0;

  /**
   * @generated from field: int64 avg_views = 9;
   */
  avgViews = protoInt64.zero;

  /**
   * @generated from field: int32 avg_likes = 10;
   */
  avgLikes = 0;

  /**
   * @generated from field: int32 avg_comments = 11;
   */
  avgComments = 0;

  /**
   * @generated from field: universal.arkansas.proto.Platform platform = 12;
   */
  platform = Platform.INSTAGRAM;

  /**
   * @generated from field: string description = 13;
   */
  description = "";

  /**
   * @generated from field: common.proto.Gender gender = 15;
   */
  gender = Gender.GENDER_UNDEFINED;

  /**
   * @generated from field: universal.arkansas.proto.AgeGroup age_group = 16;
   */
  ageGroup = AgeGroup.AGE_UNDEFINED;

  /**
   * @generated from field: universal.arkansas.proto.Language language = 17;
   */
  language?: Language;

  /**
   * @generated from field: universal.arkansas.proto.AccountType account_type = 20;
   */
  accountType = AccountType.ACCOUNT_UNDEFINED;

  /**
   * @generated from field: universal.arkansas.proto.AudienceSource audience_source = 21;
   */
  audienceSource = AudienceSource.SOURCE_ANY;

  /**
   * @generated from field: bool is_verified = 22;
   */
  isVerified = false;

  constructor(data?: PartialMessage<Account>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.Account";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "picture", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "fullname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "followers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "engagements", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "engagement_rate", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 9, name: "avg_views", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "avg_likes", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "avg_comments", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "platform", kind: "enum", T: proto3.getEnumType(Platform) },
    { no: 13, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "gender", kind: "enum", T: proto3.getEnumType(Gender) },
    { no: 16, name: "age_group", kind: "enum", T: proto3.getEnumType(AgeGroup) },
    { no: 17, name: "language", kind: "message", T: Language },
    { no: 20, name: "account_type", kind: "enum", T: proto3.getEnumType(AccountType) },
    { no: 21, name: "audience_source", kind: "enum", T: proto3.getEnumType(AudienceSource) },
    { no: 22, name: "is_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Account {
    return new Account().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJsonString(jsonString, options);
  }

  static equals(a: Account | PlainMessage<Account> | undefined, b: Account | PlainMessage<Account> | undefined): boolean {
    return proto3.util.equals(Account, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.SearchResponse
 */
export class SearchResponse extends Message<SearchResponse> {
  /**
   * @generated from field: int32 total = 1;
   */
  total = 0;

  /**
   * @generated from field: repeated universal.arkansas.proto.Account account = 2;
   */
  account: Account[] = [];

  constructor(data?: PartialMessage<SearchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.SearchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "account", kind: "message", T: Account, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SearchResponse {
    return new SearchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SearchResponse {
    return new SearchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SearchResponse {
    return new SearchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SearchResponse | PlainMessage<SearchResponse> | undefined, b: SearchResponse | PlainMessage<SearchResponse> | undefined): boolean {
    return proto3.util.equals(SearchResponse, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.AudienceRequest
 */
export class AudienceRequest extends Message<AudienceRequest> {
  /**
   * @generated from field: universal.arkansas.proto.Platform platform = 1;
   */
  platform = Platform.INSTAGRAM;

  /**
   * @generated from oneof universal.arkansas.proto.AudienceRequest.req
   */
  req: {
    /**
     * @generated from field: string id = 2;
     */
    value: string;
    case: "id";
  } | {
    /**
     * @generated from field: string name = 3;
     */
    value: string;
    case: "name";
  } | {
    /**
     * @generated from field: string link = 4;
     */
    value: string;
    case: "link";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<AudienceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.AudienceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "platform", kind: "enum", T: proto3.getEnumType(Platform) },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "req" },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "req" },
    { no: 4, name: "link", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "req" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AudienceRequest {
    return new AudienceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AudienceRequest {
    return new AudienceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AudienceRequest {
    return new AudienceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AudienceRequest | PlainMessage<AudienceRequest> | undefined, b: AudienceRequest | PlainMessage<AudienceRequest> | undefined): boolean {
    return proto3.util.equals(AudienceRequest, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.PicVersion
 */
export class PicVersion extends Message<PicVersion> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: int32 width = 2;
   */
  width = 0;

  /**
   * @generated from field: int32 height = 3;
   */
  height = 0;

  constructor(data?: PartialMessage<PicVersion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.PicVersion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PicVersion {
    return new PicVersion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PicVersion {
    return new PicVersion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PicVersion {
    return new PicVersion().fromJsonString(jsonString, options);
  }

  static equals(a: PicVersion | PlainMessage<PicVersion> | undefined, b: PicVersion | PlainMessage<PicVersion> | undefined): boolean {
    return proto3.util.equals(PicVersion, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserInfoResp
 */
export class UserInfoResp extends Message<UserInfoResp> {
  /**
   * @generated from field: string status = 1;
   */
  status = "";

  /**
   * @generated from field: universal.arkansas.proto.UserInfoResp.User user = 2;
   */
  user?: UserInfoResp_User;

  constructor(data?: PartialMessage<UserInfoResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserInfoResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user", kind: "message", T: UserInfoResp_User },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserInfoResp {
    return new UserInfoResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserInfoResp {
    return new UserInfoResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserInfoResp {
    return new UserInfoResp().fromJsonString(jsonString, options);
  }

  static equals(a: UserInfoResp | PlainMessage<UserInfoResp> | undefined, b: UserInfoResp | PlainMessage<UserInfoResp> | undefined): boolean {
    return proto3.util.equals(UserInfoResp, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserInfoResp.User
 */
export class UserInfoResp_User extends Message<UserInfoResp_User> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * @generated from field: bool has_anonymous_profile_picture = 2;
   */
  hasAnonymousProfilePicture = false;

  /**
   * @generated from field: string biography = 3;
   */
  biography = "";

  /**
   * @generated from field: int32 following_count = 4;
   */
  followingCount = 0;

  /**
   * @generated from field: int64 city_id = 5;
   */
  cityId = protoInt64.zero;

  /**
   * @generated from field: string public_phone_number = 6;
   */
  publicPhoneNumber = "";

  /**
   * @generated from field: string external_lynx_url = 7;
   */
  externalLynxUrl = "";

  /**
   * @generated from field: bool is_favorite = 8;
   */
  isFavorite = false;

  /**
   * @generated from field: string full_name = 9;
   */
  fullName = "";

  /**
   * @generated from field: string address_street = 10;
   */
  addressStreet = "";

  /**
   * @generated from field: string category = 11;
   */
  category = "";

  /**
   * @generated from field: bool has_chaining = 12;
   */
  hasChaining = false;

  /**
   * @generated from field: int32 media_count = 13;
   */
  mediaCount = 0;

  /**
   * @generated from field: string zip = 14;
   */
  zip = "";

  /**
   * @generated from field: string profile_context = 15;
   */
  profileContext = "";

  /**
   * @generated from field: string public_email = 16;
   */
  publicEmail = "";

  /**
   * @generated from field: string business_contact_method = 17;
   */
  businessContactMethod = "";

  /**
   * @generated from field: int32 geo_media_count = 18;
   */
  geoMediaCount = 0;

  /**
   * @generated from field: string profile_pic_url = 19;
   */
  profilePicUrl = "";

  /**
   * @generated from field: string profile_pic_id = 20;
   */
  profilePicId = "";

  /**
   * @generated from field: string city_name = 21;
   */
  cityName = "";

  /**
   * @generated from field: string fb_page_call_to_action_id = 22;
   */
  fbPageCallToActionId = "";

  /**
   * @generated from field: bool is_private = 23;
   */
  isPrivate = false;

  /**
   * @generated from field: bool is_business = 24;
   */
  isBusiness = false;

  /**
   * @generated from field: string direct_messaging = 25;
   */
  directMessaging = "";

  /**
   * @generated from field: float latitude = 26;
   */
  latitude = 0;

  /**
   * @generated from field: string reel_auto_archive = 27;
   */
  reelAutoArchive = "";

  /**
   * @generated from field: float longitude = 28;
   */
  longitude = 0;

  /**
   * @generated from field: int32 following_tag_count = 29;
   */
  followingTagCount = 0;

  /**
   * @generated from field: string public_phone_country_code = 30;
   */
  publicPhoneCountryCode = "";

  /**
   * @generated from field: int32 follower_count = 31;
   */
  followerCount = 0;

  /**
   * @generated from field: int64 pk = 32;
   */
  pk = protoInt64.zero;

  /**
   * @generated from field: bool is_verified = 33;
   */
  isVerified = false;

  /**
   * @generated from field: bool is_call_to_action_enabled = 34;
   */
  isCallToActionEnabled = false;

  /**
   * @generated from field: int32 usertags_count = 35;
   */
  usertagsCount = 0;

  /**
   * @generated from field: string contact_phone_number = 36;
   */
  contactPhoneNumber = "";

  /**
   * @generated from field: string external_url = 37;
   */
  externalUrl = "";

  /**
   * @generated from field: repeated universal.arkansas.proto.PicVersion hd_profile_pic_versions = 38;
   */
  hdProfilePicVersions: PicVersion[] = [];

  /**
   * @generated from field: universal.arkansas.proto.PicVersion hd_profile_pic_url_info = 39;
   */
  hdProfilePicUrlInfo?: PicVersion;

  /**
   * Fields 43 -> 47 seems to be only set on a request by id.
   *
   * @generated from field: bool auto_expand_chaining = 43;
   */
  autoExpandChaining = false;

  /**
   * @generated from field: bool include_direct_blacklist_status = 44;
   */
  includeDirectBlacklistStatus = false;

  /**
   * @generated from field: bool has_highlight_reels = 45;
   */
  hasHighlightReels = false;

  /**
   * @generated from field: bool has_unseen_besties_media = 46;
   */
  hasUnseenBestiesMedia = false;

  /**
   * @generated from field: bool is_bestie = 47;
   */
  isBestie = false;

  constructor(data?: PartialMessage<UserInfoResp_User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserInfoResp.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "has_anonymous_profile_picture", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "biography", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "following_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "city_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "public_phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "external_lynx_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "is_favorite", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "address_street", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "has_chaining", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "media_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "zip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "profile_context", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "public_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "business_contact_method", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "geo_media_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 19, name: "profile_pic_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "profile_pic_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "city_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "fb_page_call_to_action_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "is_private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 24, name: "is_business", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "direct_messaging", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "latitude", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 27, name: "reel_auto_archive", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 28, name: "longitude", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 29, name: "following_tag_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 30, name: "public_phone_country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 31, name: "follower_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 32, name: "pk", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 33, name: "is_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 34, name: "is_call_to_action_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 35, name: "usertags_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 36, name: "contact_phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 37, name: "external_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 38, name: "hd_profile_pic_versions", kind: "message", T: PicVersion, repeated: true },
    { no: 39, name: "hd_profile_pic_url_info", kind: "message", T: PicVersion },
    { no: 43, name: "auto_expand_chaining", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 44, name: "include_direct_blacklist_status", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 45, name: "has_highlight_reels", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 46, name: "has_unseen_besties_media", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 47, name: "is_bestie", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserInfoResp_User {
    return new UserInfoResp_User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserInfoResp_User {
    return new UserInfoResp_User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserInfoResp_User {
    return new UserInfoResp_User().fromJsonString(jsonString, options);
  }

  static equals(a: UserInfoResp_User | PlainMessage<UserInfoResp_User> | undefined, b: UserInfoResp_User | PlainMessage<UserInfoResp_User> | undefined): boolean {
    return proto3.util.equals(UserInfoResp_User, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.FriendshipStatus
 */
export class FriendshipStatus extends Message<FriendshipStatus> {
  /**
   * @generated from field: bool incoming_request = 1;
   */
  incomingRequest = false;

  /**
   * @generated from field: bool is_bestie = 2;
   */
  isBestie = false;

  /**
   * @generated from field: bool muting = 3;
   */
  muting = false;

  /**
   * @generated from field: bool followed_by = 4;
   */
  followedBy = false;

  /**
   * @generated from field: bool outgoing_request = 5;
   */
  outgoingRequest = false;

  /**
   * @generated from field: bool following = 6;
   */
  following = false;

  /**
   * @generated from field: bool blocking = 7;
   */
  blocking = false;

  /**
   * @generated from field: bool is_private = 8;
   */
  isPrivate = false;

  constructor(data?: PartialMessage<FriendshipStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.FriendshipStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "incoming_request", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "is_bestie", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "muting", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "followed_by", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "outgoing_request", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "following", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "blocking", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "is_private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FriendshipStatus {
    return new FriendshipStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FriendshipStatus {
    return new FriendshipStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FriendshipStatus {
    return new FriendshipStatus().fromJsonString(jsonString, options);
  }

  static equals(a: FriendshipStatus | PlainMessage<FriendshipStatus> | undefined, b: FriendshipStatus | PlainMessage<FriendshipStatus> | undefined): boolean {
    return proto3.util.equals(FriendshipStatus, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.ApiUser
 */
export class ApiUser extends Message<ApiUser> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * @generated from field: string profile_pic_url = 2;
   */
  profilePicUrl = "";

  /**
   * @generated from field: string profile_pic_id = 3;
   */
  profilePicId = "";

  /**
   * @generated from field: string full_name = 4;
   */
  fullName = "";

  /**
   * @generated from field: int64 pk = 5;
   */
  pk = protoInt64.zero;

  /**
   * @generated from field: bool is_verified = 6;
   */
  isVerified = false;

  /**
   * @generated from field: bool is_private = 7;
   */
  isPrivate = false;

  /**
   * @generated from field: string reel_auto_archive = 8;
   */
  reelAutoArchive = "";

  /**
   * @generated from field: bool has_anonymous_profile_picture = 9;
   */
  hasAnonymousProfilePicture = false;

  /**
   * @generated from field: bool is_favorite = 10;
   */
  isFavorite = false;

  /**
   * @generated from field: bool is_unpublished = 11;
   */
  isUnpublished = false;

  /**
   * @generated from field: universal.arkansas.proto.FriendshipStatus friendship_status = 12;
   */
  friendshipStatus?: FriendshipStatus;

  constructor(data?: PartialMessage<ApiUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.ApiUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "profile_pic_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_pic_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "pk", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "is_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "is_private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "reel_auto_archive", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "has_anonymous_profile_picture", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "is_favorite", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "is_unpublished", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "friendship_status", kind: "message", T: FriendshipStatus },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ApiUser {
    return new ApiUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ApiUser {
    return new ApiUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ApiUser {
    return new ApiUser().fromJsonString(jsonString, options);
  }

  static equals(a: ApiUser | PlainMessage<ApiUser> | undefined, b: ApiUser | PlainMessage<ApiUser> | undefined): boolean {
    return proto3.util.equals(ApiUser, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.ImageVersions2
 */
export class ImageVersions2 extends Message<ImageVersions2> {
  /**
   * @generated from field: repeated universal.arkansas.proto.PicVersion candidates = 1;
   */
  candidates: PicVersion[] = [];

  constructor(data?: PartialMessage<ImageVersions2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.ImageVersions2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "candidates", kind: "message", T: PicVersion, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImageVersions2 {
    return new ImageVersions2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImageVersions2 {
    return new ImageVersions2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImageVersions2 {
    return new ImageVersions2().fromJsonString(jsonString, options);
  }

  static equals(a: ImageVersions2 | PlainMessage<ImageVersions2> | undefined, b: ImageVersions2 | PlainMessage<ImageVersions2> | undefined): boolean {
    return proto3.util.equals(ImageVersions2, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.Location
 */
export class Location extends Message<Location> {
  /**
   * @generated from field: string external_source = 1;
   */
  externalSource = "";

  /**
   * @generated from field: string city = 2;
   */
  city = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string short_name = 4;
   */
  shortName = "";

  /**
   * @generated from field: int64 facebook_places_id = 5;
   */
  facebookPlacesId = protoInt64.zero;

  /**
   * @generated from field: string address = 6;
   */
  address = "";

  /**
   * @generated from field: float lat = 7;
   */
  lat = 0;

  /**
   * @generated from field: int64 pk = 8;
   */
  pk = protoInt64.zero;

  /**
   * @generated from field: float lng = 9;
   */
  lng = 0;

  constructor(data?: PartialMessage<Location>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.Location";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "external_source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "short_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "facebook_places_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "lat", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "pk", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "lng", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Location {
    return new Location().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Location {
    return new Location().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Location {
    return new Location().fromJsonString(jsonString, options);
  }

  static equals(a: Location | PlainMessage<Location> | undefined, b: Location | PlainMessage<Location> | undefined): boolean {
    return proto3.util.equals(Location, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.Caption
 */
export class Caption extends Message<Caption> {
  /**
   * @generated from field: string status = 1;
   */
  status = "";

  /**
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: int64 created_at_utc = 3;
   */
  createdAtUtc = protoInt64.zero;

  /**
   * @generated from field: int64 created_at = 4;
   */
  createdAt = protoInt64.zero;

  /**
   * @generated from field: int32 bit_flags = 5;
   */
  bitFlags = 0;

  /**
   * @generated from field: bool did_report_as_spam = 6;
   */
  didReportAsSpam = false;

  /**
   * @generated from field: universal.arkansas.proto.ApiUser user = 7;
   */
  user?: ApiUser;

  /**
   * @generated from field: string content_type = 8;
   */
  contentType = "";

  /**
   * @generated from field: string text = 9;
   */
  text = "";

  /**
   * @generated from field: int64 media_id = 10;
   */
  mediaId = protoInt64.zero;

  /**
   * @generated from field: int32 type = 11;
   */
  type = 0;

  /**
   * @generated from field: int64 pk = 12;
   */
  pk = protoInt64.zero;

  /**
   * @generated from field: bool has_translation = 13;
   */
  hasTranslation = false;

  constructor(data?: PartialMessage<Caption>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.Caption";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "created_at_utc", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "created_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "bit_flags", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "did_report_as_spam", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "user", kind: "message", T: ApiUser },
    { no: 8, name: "content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "media_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "pk", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "has_translation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Caption {
    return new Caption().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Caption {
    return new Caption().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Caption {
    return new Caption().fromJsonString(jsonString, options);
  }

  static equals(a: Caption | PlainMessage<Caption> | undefined, b: Caption | PlainMessage<Caption> | undefined): boolean {
    return proto3.util.equals(Caption, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.VideoVersion
 */
export class VideoVersion extends Message<VideoVersion> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: int32 type = 3;
   */
  type = 0;

  /**
   * @generated from field: int32 width = 4;
   */
  width = 0;

  /**
   * @generated from field: int32 height = 5;
   */
  height = 0;

  constructor(data?: PartialMessage<VideoVersion>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.VideoVersion";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VideoVersion {
    return new VideoVersion().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VideoVersion {
    return new VideoVersion().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VideoVersion {
    return new VideoVersion().fromJsonString(jsonString, options);
  }

  static equals(a: VideoVersion | PlainMessage<VideoVersion> | undefined, b: VideoVersion | PlainMessage<VideoVersion> | undefined): boolean {
    return proto3.util.equals(VideoVersion, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserPostsResp
 */
export class UserPostsResp extends Message<UserPostsResp> {
  /**
   * @generated from field: string status = 1;
   */
  status = "";

  /**
   * @generated from field: int32 num_results = 2;
   */
  numResults = 0;

  /**
   * @generated from field: bool auto_load_more_enabled = 3;
   */
  autoLoadMoreEnabled = false;

  /**
   * @generated from field: repeated universal.arkansas.proto.UserPostsResp.Items items = 4;
   */
  items: UserPostsResp_Items[] = [];

  /**
   * @generated from field: bool more_available = 5;
   */
  moreAvailable = false;

  /**
   * @generated from field: string next_max_id = 6;
   */
  nextMaxId = "";

  /**
   * This field sent by Instagram when requesting mentioning posts (usertag)
   *
   * @generated from field: int32 total_count = 7;
   */
  totalCount = 0;

  /**
   * @generated from field: string end_cursor = 8;
   */
  endCursor = "";

  constructor(data?: PartialMessage<UserPostsResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserPostsResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "num_results", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "auto_load_more_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "items", kind: "message", T: UserPostsResp_Items, repeated: true },
    { no: 5, name: "more_available", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "next_max_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "total_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "end_cursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPostsResp {
    return new UserPostsResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPostsResp {
    return new UserPostsResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPostsResp {
    return new UserPostsResp().fromJsonString(jsonString, options);
  }

  static equals(a: UserPostsResp | PlainMessage<UserPostsResp> | undefined, b: UserPostsResp | PlainMessage<UserPostsResp> | undefined): boolean {
    return proto3.util.equals(UserPostsResp, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserPostsResp.Items
 */
export class UserPostsResp_Items extends Message<UserPostsResp_Items> {
  /**
   * @generated from field: string code = 1;
   */
  code = "";

  /**
   * @generated from field: universal.arkansas.proto.UserPostsResp.Items.UserTags usertags = 2;
   */
  usertags?: UserPostsResp_Items_UserTags;

  /**
   * @generated from field: int64 next_max_id = 3;
   */
  nextMaxId = protoInt64.zero;

  /**
   * @generated from field: bool comment_likes_enabled = 4;
   */
  commentLikesEnabled = false;

  /**
   * @generated from field: int32 max_num_visible_preview_comments = 5;
   */
  maxNumVisiblePreviewComments = 0;

  /**
   * @generated from field: int32 like_count = 6;
   */
  likeCount = 0;

  /**
   * @generated from field: bool like_and_view_counts_disabled = 40;
   */
  likeAndViewCountsDisabled = false;

  /**
   * @generated from field: bool can_viewer_save = 7;
   */
  canViewerSave = false;

  /**
   * @generated from field: bool comment_threading_enabled = 8;
   */
  commentThreadingEnabled = false;

  /**
   * @generated from field: float lng = 9;
   */
  lng = 0;

  /**
   * @generated from field: string id = 10;
   */
  id = "";

  /**
   * @generated from field: repeated universal.arkansas.proto.UserPostsResp.Items.PreviewComments preview_comments = 12;
   */
  previewComments: UserPostsResp_Items_PreviewComments[] = [];

  /**
   * @generated from field: string client_cache_key = 13;
   */
  clientCacheKey = "";

  /**
   * @generated from field: int64 device_timestamp = 14;
   */
  deviceTimestamp = protoInt64.zero;

  /**
   * @generated from field: int32 comment_count = 15;
   */
  commentCount = 0;

  /**
   * @generated from field: universal.arkansas.proto.Location location = 16;
   */
  location?: Location;

  /**
   * @generated from field: int32 media_type = 17;
   */
  mediaType = 0;

  /**
   * @generated from field: string organic_tracking_token = 18;
   */
  organicTrackingToken = "";

  /**
   * @generated from field: bool caption_is_edited = 19;
   */
  captionIsEdited = false;

  /**
   * @generated from field: string inline_composer_display_condition = 20;
   */
  inlineComposerDisplayCondition = "";

  /**
   * @generated from field: repeated universal.arkansas.proto.UserPostsResp.Items.CarouselMedia carousel_media = 21;
   */
  carouselMedia: UserPostsResp_Items_CarouselMedia[] = [];

  /**
   * @generated from field: int32 filter_type = 22;
   */
  filterType = 0;

  /**
   * @generated from field: universal.arkansas.proto.ApiUser user = 23;
   */
  user?: ApiUser;

  /**
   * @generated from field: repeated universal.arkansas.proto.ApiUser coauthor_producers = 41;
   */
  coauthorProducers: ApiUser[] = [];

  /**
   * @generated from field: int64 pk = 24;
   */
  pk = protoInt64.zero;

  /**
   * @generated from field: float lat = 25;
   */
  lat = 0;

  /**
   * @generated from field: bool has_liked = 26;
   */
  hasLiked = false;

  /**
   * @generated from field: bool has_more_comments = 27;
   */
  hasMoreComments = false;

  /**
   * @generated from field: bool photo_of_you = 28;
   */
  photoOfYou = false;

  /**
   * @generated from field: universal.arkansas.proto.Caption caption = 29;
   */
  caption?: Caption;

  /**
   * @generated from field: repeated universal.arkansas.proto.UserPostsResp.Items.SponsorTags sponsor_tags = 30;
   */
  sponsorTags: UserPostsResp_Items_SponsorTags[] = [];

  /**
   * @generated from field: int64 taken_at = 31;
   */
  takenAt = protoInt64.zero;

  /**
   * @generated from field: bool can_viewer_reshare = 32;
   */
  canViewerReshare = false;

  /**
   * @generated from field: universal.arkansas.proto.ImageVersions2 image_versions2 = 33;
   */
  imageVersions2?: ImageVersions2;

  /**
   * @generated from field: int32 original_height = 34;
   */
  originalHeight = 0;

  /**
   * @generated from field: int32 original_width = 35;
   */
  originalWidth = 0;

  /**
   * @generated from field: repeated universal.arkansas.proto.VideoVersion video_versions = 36;
   */
  videoVersions: VideoVersion[] = [];

  /**
   * @generated from field: float view_count = 37;
   */
  viewCount = 0;

  /**
   * @generated from field: float play_count = 39;
   */
  playCount = 0;

  /**
   * @generated from field: string video_url = 38;
   */
  videoUrl = "";

  constructor(data?: PartialMessage<UserPostsResp_Items>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserPostsResp.Items";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "usertags", kind: "message", T: UserPostsResp_Items_UserTags },
    { no: 3, name: "next_max_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "comment_likes_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "max_num_visible_preview_comments", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "like_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 40, name: "like_and_view_counts_disabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "can_viewer_save", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "comment_threading_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "lng", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 10, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "preview_comments", kind: "message", T: UserPostsResp_Items_PreviewComments, repeated: true },
    { no: 13, name: "client_cache_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "device_timestamp", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 15, name: "comment_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "location", kind: "message", T: Location },
    { no: 17, name: "media_type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 18, name: "organic_tracking_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "caption_is_edited", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "inline_composer_display_condition", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "carousel_media", kind: "message", T: UserPostsResp_Items_CarouselMedia, repeated: true },
    { no: 22, name: "filter_type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 23, name: "user", kind: "message", T: ApiUser },
    { no: 41, name: "coauthor_producers", kind: "message", T: ApiUser, repeated: true },
    { no: 24, name: "pk", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 25, name: "lat", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 26, name: "has_liked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 27, name: "has_more_comments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 28, name: "photo_of_you", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 29, name: "caption", kind: "message", T: Caption },
    { no: 30, name: "sponsor_tags", kind: "message", T: UserPostsResp_Items_SponsorTags, repeated: true },
    { no: 31, name: "taken_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 32, name: "can_viewer_reshare", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 33, name: "image_versions2", kind: "message", T: ImageVersions2 },
    { no: 34, name: "original_height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 35, name: "original_width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 36, name: "video_versions", kind: "message", T: VideoVersion, repeated: true },
    { no: 37, name: "view_count", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 39, name: "play_count", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 38, name: "video_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPostsResp_Items {
    return new UserPostsResp_Items().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPostsResp_Items {
    return new UserPostsResp_Items().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPostsResp_Items {
    return new UserPostsResp_Items().fromJsonString(jsonString, options);
  }

  static equals(a: UserPostsResp_Items | PlainMessage<UserPostsResp_Items> | undefined, b: UserPostsResp_Items | PlainMessage<UserPostsResp_Items> | undefined): boolean {
    return proto3.util.equals(UserPostsResp_Items, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserPostsResp.Items.UserTags
 */
export class UserPostsResp_Items_UserTags extends Message<UserPostsResp_Items_UserTags> {
  /**
   * @generated from field: repeated universal.arkansas.proto.UserPostsResp.Items.UserTags.In in = 1;
   */
  in: UserPostsResp_Items_UserTags_In[] = [];

  constructor(data?: PartialMessage<UserPostsResp_Items_UserTags>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserPostsResp.Items.UserTags";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "in", kind: "message", T: UserPostsResp_Items_UserTags_In, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPostsResp_Items_UserTags {
    return new UserPostsResp_Items_UserTags().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPostsResp_Items_UserTags {
    return new UserPostsResp_Items_UserTags().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPostsResp_Items_UserTags {
    return new UserPostsResp_Items_UserTags().fromJsonString(jsonString, options);
  }

  static equals(a: UserPostsResp_Items_UserTags | PlainMessage<UserPostsResp_Items_UserTags> | undefined, b: UserPostsResp_Items_UserTags | PlainMessage<UserPostsResp_Items_UserTags> | undefined): boolean {
    return proto3.util.equals(UserPostsResp_Items_UserTags, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserPostsResp.Items.UserTags.In
 */
export class UserPostsResp_Items_UserTags_In extends Message<UserPostsResp_Items_UserTags_In> {
  /**
   * @generated from field: repeated float position = 1;
   */
  position: number[] = [];

  /**
   * @generated from field: string start_time_in_video_in_sec = 2;
   */
  startTimeInVideoInSec = "";

  /**
   * @generated from field: universal.arkansas.proto.ApiUser user = 3;
   */
  user?: ApiUser;

  /**
   * @generated from field: string duration_in_video_in_sec = 4;
   */
  durationInVideoInSec = "";

  constructor(data?: PartialMessage<UserPostsResp_Items_UserTags_In>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserPostsResp.Items.UserTags.In";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "position", kind: "scalar", T: 2 /* ScalarType.FLOAT */, repeated: true },
    { no: 2, name: "start_time_in_video_in_sec", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user", kind: "message", T: ApiUser },
    { no: 4, name: "duration_in_video_in_sec", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPostsResp_Items_UserTags_In {
    return new UserPostsResp_Items_UserTags_In().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPostsResp_Items_UserTags_In {
    return new UserPostsResp_Items_UserTags_In().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPostsResp_Items_UserTags_In {
    return new UserPostsResp_Items_UserTags_In().fromJsonString(jsonString, options);
  }

  static equals(a: UserPostsResp_Items_UserTags_In | PlainMessage<UserPostsResp_Items_UserTags_In> | undefined, b: UserPostsResp_Items_UserTags_In | PlainMessage<UserPostsResp_Items_UserTags_In> | undefined): boolean {
    return proto3.util.equals(UserPostsResp_Items_UserTags_In, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserPostsResp.Items.PreviewComments
 */
export class UserPostsResp_Items_PreviewComments extends Message<UserPostsResp_Items_PreviewComments> {
  /**
   * @generated from field: string status = 1;
   */
  status = "";

  /**
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: int64 created_at_utc = 3;
   */
  createdAtUtc = protoInt64.zero;

  /**
   * @generated from field: int64 created_at = 4;
   */
  createdAt = protoInt64.zero;

  /**
   * @generated from field: int32 bit_flags = 5;
   */
  bitFlags = 0;

  /**
   * @generated from field: bool did_report_as_spam = 6;
   */
  didReportAsSpam = false;

  /**
   * @generated from field: universal.arkansas.proto.ApiUser user = 7;
   */
  user?: ApiUser;

  /**
   * @generated from field: string content_type = 8;
   */
  contentType = "";

  /**
   * @generated from field: string text = 9;
   */
  text = "";

  /**
   * @generated from field: int64 media_id = 10;
   */
  mediaId = protoInt64.zero;

  /**
   * @generated from field: int32 type = 11;
   */
  type = 0;

  /**
   * @generated from field: int64 pk = 12;
   */
  pk = protoInt64.zero;

  /**
   * @generated from field: bool has_translation = 13;
   */
  hasTranslation = false;

  constructor(data?: PartialMessage<UserPostsResp_Items_PreviewComments>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserPostsResp.Items.PreviewComments";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "created_at_utc", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "created_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "bit_flags", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "did_report_as_spam", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "user", kind: "message", T: ApiUser },
    { no: 8, name: "content_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "media_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "pk", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "has_translation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPostsResp_Items_PreviewComments {
    return new UserPostsResp_Items_PreviewComments().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPostsResp_Items_PreviewComments {
    return new UserPostsResp_Items_PreviewComments().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPostsResp_Items_PreviewComments {
    return new UserPostsResp_Items_PreviewComments().fromJsonString(jsonString, options);
  }

  static equals(a: UserPostsResp_Items_PreviewComments | PlainMessage<UserPostsResp_Items_PreviewComments> | undefined, b: UserPostsResp_Items_PreviewComments | PlainMessage<UserPostsResp_Items_PreviewComments> | undefined): boolean {
    return proto3.util.equals(UserPostsResp_Items_PreviewComments, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserPostsResp.Items.CarouselMedia
 */
export class UserPostsResp_Items_CarouselMedia extends Message<UserPostsResp_Items_CarouselMedia> {
  /**
   * @generated from field: int32 original_height = 1;
   */
  originalHeight = 0;

  /**
   * @generated from field: universal.arkansas.proto.UserPostsResp.Items.UserTags usertags = 2;
   */
  usertags?: UserPostsResp_Items_UserTags;

  /**
   * @generated from field: string carousel_parent_id = 3;
   */
  carouselParentId = "";

  /**
   * @generated from field: universal.arkansas.proto.ImageVersions2 image_versions2 = 4;
   */
  imageVersions2?: ImageVersions2;

  /**
   * @generated from field: int64 media_type = 5;
   */
  mediaType = protoInt64.zero;

  /**
   * @generated from field: int64 pk = 6;
   */
  pk = protoInt64.zero;

  /**
   * @generated from field: int32 original_width = 7;
   */
  originalWidth = 0;

  /**
   * @generated from field: string id = 8;
   */
  id = "";

  /**
   * @generated from field: repeated universal.arkansas.proto.VideoVersion video_versions = 9;
   */
  videoVersions: VideoVersion[] = [];

  /**
   * @generated from field: string video_url = 10;
   */
  videoUrl = "";

  constructor(data?: PartialMessage<UserPostsResp_Items_CarouselMedia>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserPostsResp.Items.CarouselMedia";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "original_height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "usertags", kind: "message", T: UserPostsResp_Items_UserTags },
    { no: 3, name: "carousel_parent_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "image_versions2", kind: "message", T: ImageVersions2 },
    { no: 5, name: "media_type", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "pk", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "original_width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "video_versions", kind: "message", T: VideoVersion, repeated: true },
    { no: 10, name: "video_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPostsResp_Items_CarouselMedia {
    return new UserPostsResp_Items_CarouselMedia().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPostsResp_Items_CarouselMedia {
    return new UserPostsResp_Items_CarouselMedia().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPostsResp_Items_CarouselMedia {
    return new UserPostsResp_Items_CarouselMedia().fromJsonString(jsonString, options);
  }

  static equals(a: UserPostsResp_Items_CarouselMedia | PlainMessage<UserPostsResp_Items_CarouselMedia> | undefined, b: UserPostsResp_Items_CarouselMedia | PlainMessage<UserPostsResp_Items_CarouselMedia> | undefined): boolean {
    return proto3.util.equals(UserPostsResp_Items_CarouselMedia, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserPostsResp.Items.SponsorTags
 */
export class UserPostsResp_Items_SponsorTags extends Message<UserPostsResp_Items_SponsorTags> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * This unused field was creating issues with proto parsing given that it could be either an int or a bool.
   * bool permission = 2;
   *
   * @generated from field: string profile_pic_url = 3;
   */
  profilePicUrl = "";

  /**
   * @generated from field: string profile_pic_id = 4;
   */
  profilePicId = "";

  /**
   * @generated from field: string full_name = 5;
   */
  fullName = "";

  /**
   * @generated from field: int64 pk = 6;
   */
  pk = protoInt64.zero;

  /**
   * @generated from field: bool is_verified = 7;
   */
  isVerified = false;

  /**
   * @generated from field: bool is_private = 8;
   */
  isPrivate = false;

  constructor(data?: PartialMessage<UserPostsResp_Items_SponsorTags>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserPostsResp.Items.SponsorTags";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_pic_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "profile_pic_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "pk", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "is_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "is_private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPostsResp_Items_SponsorTags {
    return new UserPostsResp_Items_SponsorTags().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPostsResp_Items_SponsorTags {
    return new UserPostsResp_Items_SponsorTags().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPostsResp_Items_SponsorTags {
    return new UserPostsResp_Items_SponsorTags().fromJsonString(jsonString, options);
  }

  static equals(a: UserPostsResp_Items_SponsorTags | PlainMessage<UserPostsResp_Items_SponsorTags> | undefined, b: UserPostsResp_Items_SponsorTags | PlainMessage<UserPostsResp_Items_SponsorTags> | undefined): boolean {
    return proto3.util.equals(UserPostsResp_Items_SponsorTags, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserStoriesResp
 */
export class UserStoriesResp extends Message<UserStoriesResp> {
  /**
   * @generated from field: string status = 1;
   */
  status = "";

  /**
   * @generated from field: bool can_reply = 2;
   */
  canReply = false;

  /**
   * @generated from field: int64 expiring_at = 3;
   */
  expiringAt = protoInt64.zero;

  /**
   * @generated from field: string reel_type = 4;
   */
  reelType = "";

  /**
   * @generated from field: int64 latest_reel_media = 5;
   */
  latestReelMedia = protoInt64.zero;

  /**
   * @generated from field: bool has_besties_media = 6;
   */
  hasBestiesMedia = false;

  /**
   * @generated from field: bool can_reshare = 7;
   */
  canReshare = false;

  /**
   * @generated from field: int32 prefetch_count = 8;
   */
  prefetchCount = 0;

  /**
   * @generated from field: universal.arkansas.proto.ApiUser user = 9;
   */
  user?: ApiUser;

  /**
   * @generated from field: repeated universal.arkansas.proto.UserStoriesResp.Items items = 10;
   */
  items: UserStoriesResp_Items[] = [];

  /**
   * @generated from field: float seen = 11;
   */
  seen = 0;

  /**
   * @generated from field: int64 id = 12;
   */
  id = protoInt64.zero;

  constructor(data?: PartialMessage<UserStoriesResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserStoriesResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "can_reply", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "expiring_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "reel_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "latest_reel_media", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "has_besties_media", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "can_reshare", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "prefetch_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "user", kind: "message", T: ApiUser },
    { no: 10, name: "items", kind: "message", T: UserStoriesResp_Items, repeated: true },
    { no: 11, name: "seen", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 12, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserStoriesResp {
    return new UserStoriesResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserStoriesResp {
    return new UserStoriesResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserStoriesResp {
    return new UserStoriesResp().fromJsonString(jsonString, options);
  }

  static equals(a: UserStoriesResp | PlainMessage<UserStoriesResp> | undefined, b: UserStoriesResp | PlainMessage<UserStoriesResp> | undefined): boolean {
    return proto3.util.equals(UserStoriesResp, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserStoriesResp.Items
 */
export class UserStoriesResp_Items extends Message<UserStoriesResp_Items> {
  /**
   * @generated from field: string code = 1;
   */
  code = "";

  /**
   * @generated from field: bool can_viewer_save = 2;
   */
  canViewerSave = false;

  /**
   * @generated from field: bool photo_of_you = 5;
   */
  photoOfYou = false;

  /**
   * @generated from field: bool is_reel_media = 6;
   */
  isReelMedia = false;

  /**
   * @generated from field: float caption_position = 7;
   */
  captionPosition = 0;

  /**
   * @generated from field: bool comment_likes_enabled = 8;
   */
  commentLikesEnabled = false;

  /**
   * @generated from field: int32 max_num_visible_preview_comments = 9;
   */
  maxNumVisiblePreviewComments = 0;

  /**
   * @generated from field: int32 like_count = 10;
   */
  likeCount = 0;

  /**
   * @generated from field: universal.arkansas.proto.ImageVersions2 image_versions2 = 11;
   */
  imageVersions2?: ImageVersions2;

  /**
   * @generated from field: bool comment_threading_enabled = 12;
   */
  commentThreadingEnabled = false;

  /**
   * @generated from field: string id = 14;
   */
  id = "";

  /**
   * @generated from field: string client_cache_key = 16;
   */
  clientCacheKey = "";

  /**
   * @generated from field: repeated universal.arkansas.proto.UserStoriesResp.Items.ReelMention reel_mentions = 17;
   */
  reelMentions: UserStoriesResp_Items_ReelMention[] = [];

  /**
   * @generated from field: int32 original_height = 18;
   */
  originalHeight = 0;

  /**
   * @generated from field: int32 comment_count = 21;
   */
  commentCount = 0;

  /**
   * @generated from field: int64 taken_at = 22;
   */
  takenAt = protoInt64.zero;

  /**
   * @generated from field: repeated universal.arkansas.proto.UserStoriesResp.Items.StoryLocations story_locations = 23;
   */
  storyLocations: UserStoriesResp_Items_StoryLocations[] = [];

  /**
   * @generated from field: repeated universal.arkansas.proto.UserStoriesResp.Items.StoryHashtag story_hashtags = 54;
   */
  storyHashtags: UserStoriesResp_Items_StoryHashtag[] = [];

  /**
   * @generated from field: string organic_tracking_token = 25;
   */
  organicTrackingToken = "";

  /**
   * @generated from field: bool caption_is_edited = 26;
   */
  captionIsEdited = false;

  /**
   * @generated from field: int64 device_timestamp = 28;
   */
  deviceTimestamp = protoInt64.zero;

  /**
   * @generated from field: bool show_one_tap_fb_share_tooltip = 31;
   */
  showOneTapFbShareTooltip = false;

  /**
   * @generated from field: int32 filter_type = 33;
   */
  filterType = 0;

  /**
   * @generated from field: int32 media_type = 34;
   */
  mediaType = 0;

  /**
   * @generated from field: bool has_liked = 35;
   */
  hasLiked = false;

  /**
   * @generated from field: int64 pk = 36;
   */
  pk = protoInt64.zero;

  /**
   * @generated from field: int64 expiring_at = 37;
   */
  expiringAt = protoInt64.zero;

  /**
   * @generated from field: bool has_more_comments = 38;
   */
  hasMoreComments = false;

  /**
   * @generated from field: int64 imported_taken_at = 39;
   */
  importedTakenAt = protoInt64.zero;

  /**
   * @generated from field: bool can_reshare = 40;
   */
  canReshare = false;

  /**
   * @generated from field: universal.arkansas.proto.Caption caption = 41;
   */
  caption?: Caption;

  /**
   * warning this is actually an int32 despite the name!
   *
   * @generated from field: int32 has_shared_to_fb = 42;
   */
  hasSharedToFb = 0;

  /**
   * @generated from field: bool supports_reel_reactions = 43;
   */
  supportsReelReactions = false;

  /**
   * @generated from field: universal.arkansas.proto.UserStoriesResp.Items.CreativeConfig creative_config = 44;
   */
  creativeConfig?: UserStoriesResp_Items_CreativeConfig;

  /**
   * @generated from field: int32 original_width = 45;
   */
  originalWidth = 0;

  /**
   * @generated from field: universal.arkansas.proto.ApiUser user = 47;
   */
  user?: ApiUser;

  /**
   * warning: int32 despite the name!
   *
   * @generated from field: int32 is_dash_eligible = 48;
   */
  isDashEligible = 0;

  /**
   * @generated from field: int32 number_of_qualities = 49;
   */
  numberOfQualities = 0;

  /**
   * @generated from field: float video_duration = 50;
   */
  videoDuration = 0;

  /**
   * @generated from field: repeated universal.arkansas.proto.VideoVersion video_versions = 51;
   */
  videoVersions: VideoVersion[] = [];

  /**
   * @generated from field: string video_dash_manifest = 52;
   */
  videoDashManifest = "";

  /**
   * @generated from field: bool has_audio = 53;
   */
  hasAudio = false;

  /**
   * @generated from field: repeated universal.arkansas.proto.UserStoriesResp.Items.StoryBloksSticker story_bloks_stickers = 55;
   */
  storyBloksStickers: UserStoriesResp_Items_StoryBloksSticker[] = [];

  constructor(data?: PartialMessage<UserStoriesResp_Items>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserStoriesResp.Items";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "can_viewer_save", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "photo_of_you", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "is_reel_media", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "caption_position", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "comment_likes_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "max_num_visible_preview_comments", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "like_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "image_versions2", kind: "message", T: ImageVersions2 },
    { no: 12, name: "comment_threading_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 14, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "client_cache_key", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "reel_mentions", kind: "message", T: UserStoriesResp_Items_ReelMention, repeated: true },
    { no: 18, name: "original_height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 21, name: "comment_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 22, name: "taken_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 23, name: "story_locations", kind: "message", T: UserStoriesResp_Items_StoryLocations, repeated: true },
    { no: 54, name: "story_hashtags", kind: "message", T: UserStoriesResp_Items_StoryHashtag, repeated: true },
    { no: 25, name: "organic_tracking_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 26, name: "caption_is_edited", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 28, name: "device_timestamp", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 31, name: "show_one_tap_fb_share_tooltip", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 33, name: "filter_type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 34, name: "media_type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 35, name: "has_liked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 36, name: "pk", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 37, name: "expiring_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 38, name: "has_more_comments", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 39, name: "imported_taken_at", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 40, name: "can_reshare", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 41, name: "caption", kind: "message", T: Caption },
    { no: 42, name: "has_shared_to_fb", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 43, name: "supports_reel_reactions", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 44, name: "creative_config", kind: "message", T: UserStoriesResp_Items_CreativeConfig },
    { no: 45, name: "original_width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 47, name: "user", kind: "message", T: ApiUser },
    { no: 48, name: "is_dash_eligible", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 49, name: "number_of_qualities", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 50, name: "video_duration", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 51, name: "video_versions", kind: "message", T: VideoVersion, repeated: true },
    { no: 52, name: "video_dash_manifest", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 53, name: "has_audio", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 55, name: "story_bloks_stickers", kind: "message", T: UserStoriesResp_Items_StoryBloksSticker, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserStoriesResp_Items {
    return new UserStoriesResp_Items().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserStoriesResp_Items {
    return new UserStoriesResp_Items().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserStoriesResp_Items {
    return new UserStoriesResp_Items().fromJsonString(jsonString, options);
  }

  static equals(a: UserStoriesResp_Items | PlainMessage<UserStoriesResp_Items> | undefined, b: UserStoriesResp_Items | PlainMessage<UserStoriesResp_Items> | undefined): boolean {
    return proto3.util.equals(UserStoriesResp_Items, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserStoriesResp.Items.ReelMention
 */
export class UserStoriesResp_Items_ReelMention extends Message<UserStoriesResp_Items_ReelMention> {
  /**
   * @generated from field: int32 is_pinned = 1;
   */
  isPinned = 0;

  /**
   * @generated from field: double height = 2;
   */
  height = 0;

  /**
   * @generated from field: double width = 3;
   */
  width = 0;

  /**
   * @generated from field: universal.arkansas.proto.ApiUser user = 4;
   */
  user?: ApiUser;

  /**
   * @generated from field: double x = 5;
   */
  x = 0;

  /**
   * @generated from field: double y = 6;
   */
  y = 0;

  /**
   * @generated from field: double rotation = 7;
   */
  rotation = 0;

  /**
   * @generated from field: int32 z = 8;
   */
  z = 0;

  /**
   * @generated from field: int32 is_hidden = 9;
   */
  isHidden = 0;

  constructor(data?: PartialMessage<UserStoriesResp_Items_ReelMention>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserStoriesResp.Items.ReelMention";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_pinned", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "height", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "width", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "user", kind: "message", T: ApiUser },
    { no: 5, name: "x", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 6, name: "y", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 7, name: "rotation", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 8, name: "z", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "is_hidden", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserStoriesResp_Items_ReelMention {
    return new UserStoriesResp_Items_ReelMention().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_ReelMention {
    return new UserStoriesResp_Items_ReelMention().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_ReelMention {
    return new UserStoriesResp_Items_ReelMention().fromJsonString(jsonString, options);
  }

  static equals(a: UserStoriesResp_Items_ReelMention | PlainMessage<UserStoriesResp_Items_ReelMention> | undefined, b: UserStoriesResp_Items_ReelMention | PlainMessage<UserStoriesResp_Items_ReelMention> | undefined): boolean {
    return proto3.util.equals(UserStoriesResp_Items_ReelMention, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserStoriesResp.Items.StoryLocations
 */
export class UserStoriesResp_Items_StoryLocations extends Message<UserStoriesResp_Items_StoryLocations> {
  /**
   * @generated from field: int32 is_pinned = 1;
   */
  isPinned = 0;

  /**
   * @generated from field: float height = 2;
   */
  height = 0;

  /**
   * @generated from field: float width = 3;
   */
  width = 0;

  /**
   * @generated from field: universal.arkansas.proto.Location location = 4;
   */
  location?: Location;

  /**
   * @generated from field: float y = 5;
   */
  y = 0;

  /**
   * @generated from field: float x = 6;
   */
  x = 0;

  /**
   * @generated from field: float rotation = 7;
   */
  rotation = 0;

  /**
   * @generated from field: int32 z = 8;
   */
  z = 0;

  /**
   * @generated from field: int32 is_hidden = 9;
   */
  isHidden = 0;

  constructor(data?: PartialMessage<UserStoriesResp_Items_StoryLocations>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserStoriesResp.Items.StoryLocations";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_pinned", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "height", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "width", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "location", kind: "message", T: Location },
    { no: 5, name: "y", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "x", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "rotation", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "z", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "is_hidden", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserStoriesResp_Items_StoryLocations {
    return new UserStoriesResp_Items_StoryLocations().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_StoryLocations {
    return new UserStoriesResp_Items_StoryLocations().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_StoryLocations {
    return new UserStoriesResp_Items_StoryLocations().fromJsonString(jsonString, options);
  }

  static equals(a: UserStoriesResp_Items_StoryLocations | PlainMessage<UserStoriesResp_Items_StoryLocations> | undefined, b: UserStoriesResp_Items_StoryLocations | PlainMessage<UserStoriesResp_Items_StoryLocations> | undefined): boolean {
    return proto3.util.equals(UserStoriesResp_Items_StoryLocations, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserStoriesResp.Items.StoryHashtag
 */
export class UserStoriesResp_Items_StoryHashtag extends Message<UserStoriesResp_Items_StoryHashtag> {
  /**
   * @generated from field: int32 is_pinned = 1;
   */
  isPinned = 0;

  /**
   * @generated from field: float height = 2;
   */
  height = 0;

  /**
   * @generated from field: float width = 3;
   */
  width = 0;

  /**
   * @generated from field: float x = 4;
   */
  x = 0;

  /**
   * @generated from field: float y = 5;
   */
  y = 0;

  /**
   * @generated from field: float z = 6;
   */
  z = 0;

  /**
   * @generated from field: float rotation = 7;
   */
  rotation = 0;

  /**
   * @generated from field: int32 is_hidden = 8;
   */
  isHidden = 0;

  /**
   * @generated from field: universal.arkansas.proto.UserStoriesResp.Items.StoryHashtag.Hashtag hashtag = 9;
   */
  hashtag?: UserStoriesResp_Items_StoryHashtag_Hashtag;

  constructor(data?: PartialMessage<UserStoriesResp_Items_StoryHashtag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserStoriesResp.Items.StoryHashtag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_pinned", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "height", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "width", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "x", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "y", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "z", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 7, name: "rotation", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "is_hidden", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "hashtag", kind: "message", T: UserStoriesResp_Items_StoryHashtag_Hashtag },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserStoriesResp_Items_StoryHashtag {
    return new UserStoriesResp_Items_StoryHashtag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_StoryHashtag {
    return new UserStoriesResp_Items_StoryHashtag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_StoryHashtag {
    return new UserStoriesResp_Items_StoryHashtag().fromJsonString(jsonString, options);
  }

  static equals(a: UserStoriesResp_Items_StoryHashtag | PlainMessage<UserStoriesResp_Items_StoryHashtag> | undefined, b: UserStoriesResp_Items_StoryHashtag | PlainMessage<UserStoriesResp_Items_StoryHashtag> | undefined): boolean {
    return proto3.util.equals(UserStoriesResp_Items_StoryHashtag, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserStoriesResp.Items.StoryHashtag.Hashtag
 */
export class UserStoriesResp_Items_StoryHashtag_Hashtag extends Message<UserStoriesResp_Items_StoryHashtag_Hashtag> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: int64 id = 2;
   */
  id = protoInt64.zero;

  constructor(data?: PartialMessage<UserStoriesResp_Items_StoryHashtag_Hashtag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserStoriesResp.Items.StoryHashtag.Hashtag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserStoriesResp_Items_StoryHashtag_Hashtag {
    return new UserStoriesResp_Items_StoryHashtag_Hashtag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_StoryHashtag_Hashtag {
    return new UserStoriesResp_Items_StoryHashtag_Hashtag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_StoryHashtag_Hashtag {
    return new UserStoriesResp_Items_StoryHashtag_Hashtag().fromJsonString(jsonString, options);
  }

  static equals(a: UserStoriesResp_Items_StoryHashtag_Hashtag | PlainMessage<UserStoriesResp_Items_StoryHashtag_Hashtag> | undefined, b: UserStoriesResp_Items_StoryHashtag_Hashtag | PlainMessage<UserStoriesResp_Items_StoryHashtag_Hashtag> | undefined): boolean {
    return proto3.util.equals(UserStoriesResp_Items_StoryHashtag_Hashtag, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserStoriesResp.Items.CreativeConfig
 */
export class UserStoriesResp_Items_CreativeConfig extends Message<UserStoriesResp_Items_CreativeConfig> {
  /**
   * @generated from field: string capture_type = 1;
   */
  captureType = "";

  constructor(data?: PartialMessage<UserStoriesResp_Items_CreativeConfig>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserStoriesResp.Items.CreativeConfig";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "capture_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserStoriesResp_Items_CreativeConfig {
    return new UserStoriesResp_Items_CreativeConfig().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_CreativeConfig {
    return new UserStoriesResp_Items_CreativeConfig().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_CreativeConfig {
    return new UserStoriesResp_Items_CreativeConfig().fromJsonString(jsonString, options);
  }

  static equals(a: UserStoriesResp_Items_CreativeConfig | PlainMessage<UserStoriesResp_Items_CreativeConfig> | undefined, b: UserStoriesResp_Items_CreativeConfig | PlainMessage<UserStoriesResp_Items_CreativeConfig> | undefined): boolean {
    return proto3.util.equals(UserStoriesResp_Items_CreativeConfig, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserStoriesResp.Items.StoryBloksSticker
 */
export class UserStoriesResp_Items_StoryBloksSticker extends Message<UserStoriesResp_Items_StoryBloksSticker> {
  /**
   * @generated from field: universal.arkansas.proto.UserStoriesResp.Items.StoryBloksSticker.BloksSticker bloks_sticker = 1;
   */
  bloksSticker?: UserStoriesResp_Items_StoryBloksSticker_BloksSticker;

  constructor(data?: PartialMessage<UserStoriesResp_Items_StoryBloksSticker>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserStoriesResp.Items.StoryBloksSticker";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bloks_sticker", kind: "message", T: UserStoriesResp_Items_StoryBloksSticker_BloksSticker },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserStoriesResp_Items_StoryBloksSticker {
    return new UserStoriesResp_Items_StoryBloksSticker().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_StoryBloksSticker {
    return new UserStoriesResp_Items_StoryBloksSticker().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_StoryBloksSticker {
    return new UserStoriesResp_Items_StoryBloksSticker().fromJsonString(jsonString, options);
  }

  static equals(a: UserStoriesResp_Items_StoryBloksSticker | PlainMessage<UserStoriesResp_Items_StoryBloksSticker> | undefined, b: UserStoriesResp_Items_StoryBloksSticker | PlainMessage<UserStoriesResp_Items_StoryBloksSticker> | undefined): boolean {
    return proto3.util.equals(UserStoriesResp_Items_StoryBloksSticker, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserStoriesResp.Items.StoryBloksSticker.BloksSticker
 */
export class UserStoriesResp_Items_StoryBloksSticker_BloksSticker extends Message<UserStoriesResp_Items_StoryBloksSticker_BloksSticker> {
  /**
   * @generated from field: universal.arkansas.proto.UserStoriesResp.Items.StoryBloksSticker.BloksSticker.StickerData sticker_data = 1;
   */
  stickerData?: UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData;

  constructor(data?: PartialMessage<UserStoriesResp_Items_StoryBloksSticker_BloksSticker>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserStoriesResp.Items.StoryBloksSticker.BloksSticker";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sticker_data", kind: "message", T: UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserStoriesResp_Items_StoryBloksSticker_BloksSticker {
    return new UserStoriesResp_Items_StoryBloksSticker_BloksSticker().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_StoryBloksSticker_BloksSticker {
    return new UserStoriesResp_Items_StoryBloksSticker_BloksSticker().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_StoryBloksSticker_BloksSticker {
    return new UserStoriesResp_Items_StoryBloksSticker_BloksSticker().fromJsonString(jsonString, options);
  }

  static equals(a: UserStoriesResp_Items_StoryBloksSticker_BloksSticker | PlainMessage<UserStoriesResp_Items_StoryBloksSticker_BloksSticker> | undefined, b: UserStoriesResp_Items_StoryBloksSticker_BloksSticker | PlainMessage<UserStoriesResp_Items_StoryBloksSticker_BloksSticker> | undefined): boolean {
    return proto3.util.equals(UserStoriesResp_Items_StoryBloksSticker_BloksSticker, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserStoriesResp.Items.StoryBloksSticker.BloksSticker.StickerData
 */
export class UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData extends Message<UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData> {
  /**
   * @generated from field: universal.arkansas.proto.UserStoriesResp.Items.StoryBloksSticker.BloksSticker.StickerData.StickerIgMention ig_mention = 1;
   */
  igMention?: UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention;

  constructor(data?: PartialMessage<UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserStoriesResp.Items.StoryBloksSticker.BloksSticker.StickerData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ig_mention", kind: "message", T: UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData {
    return new UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData {
    return new UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData {
    return new UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData().fromJsonString(jsonString, options);
  }

  static equals(a: UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData | PlainMessage<UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData> | undefined, b: UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData | PlainMessage<UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData> | undefined): boolean {
    return proto3.util.equals(UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.UserStoriesResp.Items.StoryBloksSticker.BloksSticker.StickerData.StickerIgMention
 */
export class UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention extends Message<UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention> {
  /**
   * @generated from field: string account_id = 1;
   */
  accountId = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * @generated from field: string full_name = 3;
   */
  fullName = "";

  /**
   * @generated from field: string profile_pic_url = 4;
   */
  profilePicUrl = "";

  constructor(data?: PartialMessage<UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.UserStoriesResp.Items.StoryBloksSticker.BloksSticker.StickerData.StickerIgMention";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "profile_pic_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention {
    return new UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention {
    return new UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention {
    return new UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention().fromJsonString(jsonString, options);
  }

  static equals(a: UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention | PlainMessage<UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention> | undefined, b: UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention | PlainMessage<UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention> | undefined): boolean {
    return proto3.util.equals(UserStoriesResp_Items_StoryBloksSticker_BloksSticker_StickerData_StickerIgMention, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.ArkansasInterest
 */
export class ArkansasInterest extends Message<ArkansasInterest> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: int32 count = 3;
   */
  count = 0;

  /**
   * @generated from field: bool deprecated = 4;
   */
  deprecated = false;

  constructor(data?: PartialMessage<ArkansasInterest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.ArkansasInterest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "deprecated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ArkansasInterest {
    return new ArkansasInterest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArkansasInterest {
    return new ArkansasInterest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ArkansasInterest {
    return new ArkansasInterest().fromJsonString(jsonString, options);
  }

  static equals(a: ArkansasInterest | PlainMessage<ArkansasInterest> | undefined, b: ArkansasInterest | PlainMessage<ArkansasInterest> | undefined): boolean {
    return proto3.util.equals(ArkansasInterest, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.ArkansasBrand
 */
export class ArkansasBrand extends Message<ArkansasBrand> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: int32 count = 3;
   */
  count = 0;

  /**
   * @generated from field: bool deprecated = 4;
   */
  deprecated = false;

  /**
   * @generated from field: repeated universal.arkansas.proto.ArkansasBrand.Interest interest = 5;
   */
  interest: ArkansasBrand_Interest[] = [];

  constructor(data?: PartialMessage<ArkansasBrand>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.ArkansasBrand";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "deprecated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "interest", kind: "message", T: ArkansasBrand_Interest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ArkansasBrand {
    return new ArkansasBrand().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArkansasBrand {
    return new ArkansasBrand().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ArkansasBrand {
    return new ArkansasBrand().fromJsonString(jsonString, options);
  }

  static equals(a: ArkansasBrand | PlainMessage<ArkansasBrand> | undefined, b: ArkansasBrand | PlainMessage<ArkansasBrand> | undefined): boolean {
    return proto3.util.equals(ArkansasBrand, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.ArkansasBrand.Interest
 */
export class ArkansasBrand_Interest extends Message<ArkansasBrand_Interest> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  constructor(data?: PartialMessage<ArkansasBrand_Interest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.ArkansasBrand.Interest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ArkansasBrand_Interest {
    return new ArkansasBrand_Interest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArkansasBrand_Interest {
    return new ArkansasBrand_Interest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ArkansasBrand_Interest {
    return new ArkansasBrand_Interest().fromJsonString(jsonString, options);
  }

  static equals(a: ArkansasBrand_Interest | PlainMessage<ArkansasBrand_Interest> | undefined, b: ArkansasBrand_Interest | PlainMessage<ArkansasBrand_Interest> | undefined): boolean {
    return proto3.util.equals(ArkansasBrand_Interest, a, b);
  }
}

/**
 * @generated from message universal.arkansas.proto.ArkansasBrandsAndInterests
 */
export class ArkansasBrandsAndInterests extends Message<ArkansasBrandsAndInterests> {
  /**
   * @generated from field: repeated universal.arkansas.proto.ArkansasInterest interests = 1;
   */
  interests: ArkansasInterest[] = [];

  /**
   * @generated from field: repeated universal.arkansas.proto.ArkansasBrand brands = 2;
   */
  brands: ArkansasBrand[] = [];

  constructor(data?: PartialMessage<ArkansasBrandsAndInterests>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.arkansas.proto.ArkansasBrandsAndInterests";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "interests", kind: "message", T: ArkansasInterest, repeated: true },
    { no: 2, name: "brands", kind: "message", T: ArkansasBrand, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ArkansasBrandsAndInterests {
    return new ArkansasBrandsAndInterests().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ArkansasBrandsAndInterests {
    return new ArkansasBrandsAndInterests().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ArkansasBrandsAndInterests {
    return new ArkansasBrandsAndInterests().fromJsonString(jsonString, options);
  }

  static equals(a: ArkansasBrandsAndInterests | PlainMessage<ArkansasBrandsAndInterests> | undefined, b: ArkansasBrandsAndInterests | PlainMessage<ArkansasBrandsAndInterests> | undefined): boolean {
    return proto3.util.equals(ArkansasBrandsAndInterests, a, b);
  }
}


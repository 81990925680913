import { Signal, WritableSignal, computed, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import {
  RouteParams,
  RouteViewArgs,
  RouteViewState,
  isEqual,
  isNotEmptyString,
  isNotNil,
  patchObject,
} from '@frontend2/core';
import { TokenPagination } from '@frontend2/proto/common/proto/common_pb';
import { LeftyIframeDataSyncable } from 'packages/core/src/lib/iframe/lefty_data_syncable.service';
import {
  Observable,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  map,
} from 'rxjs';

export abstract class Bloc<State> {
  private readonly _state: WritableSignal<State>;

  get state(): Signal<State> {
    return this._state;
  }

  // mainly for legacy usage
  readonly state$: Observable<State>;

  constructor(initialState: State) {
    this._state = signal(initialState);

    this.state$ = toObservable(this.state);
  }

  protected setState(state: State): void {
    this._state.set(state);
  }

  protected updateState(updateFn: (value: State) => State): State {
    this._state.update(updateFn);
    return this._state();
  }

  protected patchState(
    updates: Partial<State> | ((value: State) => Partial<State>),
  ): void {
    this.setState(patchObject(this.state(), updates));
  }
}

export interface RouteBuildArgs {
  // if refresh set to true, the RouteBloc will ignore the previous request
  // and will trigger build method even if the request is the same
  readonly refresh: boolean;

  // if noReload is set to true, the RouteBloc won't reset to initial state
  // when the route is activated again
  // And won't set `isLoading` to true
  readonly noReload: boolean;
}

/// Bloc to implements Logic for an Angular route
/// that trigger a request when url change and update the current view state
///
/// methods to implements
///   - [parseRouteParams]
///   - [build]
///
/// A single entrypoint [activate] taking [RouteParams] as parameters
export abstract class RouteBloc<Request, ViewState> extends Bloc<
  RouteViewState<Request, ViewState>
> {
  /// State use by to reset bloc when reactivating
  /// the route and the request changed
  private readonly initialViewState: ViewState;

  readonly isLoading = computed(() => this.state().loading);
  readonly request = computed(() => this.state().request);
  readonly viewState = computed(() => this.state().viewState);
  readonly isActive = computed(() => this.state().active);

  readonly request$: Observable<Request>;
  readonly viewState$: Observable<ViewState>;

  readonly error$ = this.state$.pipe(
    map((s) => s.error),
    filter(isNotNil),
    distinctUntilChanged(),
  );

  private currentBuildMarker?: unknown;

  /// force rebuild even if request did not change
  /// always mark initial state as need refresh
  private needRefresh = true;

  private _building = false;

  constructor(args: RouteViewArgs<Request, ViewState>) {
    super(args.initialState);

    this.initialViewState = args.initialState.viewState;

    this.request$ = toObservable(this.request);
    this.viewState$ = toObservable(this.viewState);
  }

  protected setLoading(loading: boolean): void {
    this.updateState((currentState) => {
      return {
        ...currentState,
        loading,
      };
    });
  }

  protected setRequest(request: Request): void {
    this.updateState((currentState) => {
      return {
        ...currentState,
        request,
      };
    });
  }

  protected updateRequest(
    updateFn: (value: Request) => Request,
  ): RouteViewState<Request, ViewState> {
    return this.updateState((currentState) => {
      return {
        ...currentState,
        request: updateFn(currentState.request),
      };
    });
  }

  protected setViewState(viewState: ViewState): void {
    this.updateState((currentState) => {
      return {
        ...currentState,
        viewState,
      };
    });
  }

  protected updateViewState(
    updateFn: (value: ViewState) => ViewState,
  ): RouteViewState<Request, ViewState> {
    return this.updateState((currentState) => {
      return {
        ...currentState,
        viewState: updateFn(currentState.viewState),
      };
    });
  }

  protected patchRequest(
    updates: Partial<Request> | ((value: Request) => Partial<Request>),
  ): void {
    this.setRequest(patchObject(this.request(), updates));
  }

  protected patchViewState(
    updates: Partial<ViewState> | ((value: ViewState) => Partial<ViewState>),
  ): void {
    this.setViewState(patchObject(this.viewState(), updates));
  }

  protected sanitizeRequest(req: Request): Request {
    return req;
  }

  /// Can be override to trigger computation or transform request
  /// before [build] call
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected beforeBuild(req: Request): void {
    // noop
  }

  /// Can be override to trigger computation
  /// after [build] call
  protected afterSuccessfulBuild(viewState: ViewState): void {
    this.updateState((currentState) => {
      return {
        ...currentState,
        loading: false,
        viewState,
        error: undefined,
      };
    });
  }

  protected requestChange(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    oldRequest: Request,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    newRequest: Request,
  ): void {
    // noop
  }

  private _maybeReloadInitialState(noReload: boolean): void {
    if (
      noReload === false &&
      isEqual(this.initialViewState, this.state()) === false
    ) {
      // reset to initial state before build
      this.setViewState(this.initialViewState);
    }
  }

  protected onBuildFailed(viewState: ViewState, error?: unknown): void {
    // set loading state to false, and reset view state
    this.updateState((currentState) => {
      return {
        ...currentState,
        viewState,
        error,
        loading: false,
      };
    });
  }

  get isBuilding(): boolean {
    return this._building;
  }

  protected async _build(
    req: Request,
    args?: Partial<RouteBuildArgs>,
  ): Promise<void> {
    const noReload = args?.noReload ?? false;

    try {
      this._building = true;

      // reset refresh state
      this.needRefresh = false;

      req = this.sanitizeRequest(req);

      // update loading state
      this.setLoading(noReload === false);

      this.beforeBuild(req);

      const buildMarker = Object();
      this.currentBuildMarker = buildMarker;

      const newViewState = await this.build(req);

      if (buildMarker !== this.currentBuildMarker) {
        // build marker are different, something triggered new build
        // before the last one was over
        //
        // Cancel build since it does not correspond to current marker
        console.warn('Concurent build request, ignore older one');
        return;
      }

      // update view with new state
      // and stop loading
      this.afterSuccessfulBuild(newViewState);
    } catch (e) {
      this.onBuildFailed(this.viewState(), e);
      throw e;
    } finally {
      this._building = false;
    }
  }

  /// force next activation to refresh even if request did not change
  protected markAsNeedRefresh(): void {
    this.needRefresh = true;
  }

  /// Transform angular route params to consumable Request for API
  protected abstract parseRouteParams(
    params: RouteParams,
  ): Promise<Request> | Request;

  /// Build the view state from current Request
  protected abstract build(req: Request): Promise<ViewState> | ViewState;

  /// Retrigger build function if state is currently active
  ///
  /// If state is not currently active, it will call [markAsNeedRefresh]
  /// so [activate] function will call [build] even if request did not change
  ///
  /// Return true if state was refresh, false if not or just invalidated
  async refresh(args?: Partial<RouteBuildArgs>): Promise<boolean> {
    const currentState = this.state();

    this.markAsNeedRefresh();

    // reactive route only if she is active and if she is not currently building
    if (currentState.active && !this.isBuilding) {
      await this.activate(currentState.routeParams, {
        ...args,
        refresh: true,
      });
      return true;
    }

    return false;
  }

  private _canBuild(
    oldReq: Request,
    newReq: Request,
    forceRefresh: boolean,
  ): boolean {
    return (
      isEqual(newReq, oldReq) === false || forceRefresh || this.needRefresh
    );
  }

  /// Activation should be trigger by angular route [OnActivate] class
  /// and [RouteParams] build from [RouterState]
  async activate(
    params: RouteParams,
    args?: Partial<RouteBuildArgs>,
  ): Promise<ViewState> {
    const forceRefresh = args?.refresh ?? false;
    const noReload = args?.noReload ?? false;

    const currentState = this.state();

    // store current request so we can compare later
    const oldReq = currentState.request;

    let newReq: Request;

    try {
      newReq = await this.parseRouteParams(params);
    } catch (e) {
      this.updateState((currentState) => {
        return {
          ...currentState,
          error: e,
        };
      });
      throw e;
    }

    // update current state with newly parsed request
    // and mark the route as active
    this.updateState((currentState) => {
      return {
        ...currentState,
        request: newReq,
        routeParams: params,
        active: true,
      };
    });

    // trigger build function only if request changed or if the current state
    // need refresh
    if (this._canBuild(oldReq, newReq, forceRefresh)) {
      this._maybeReloadInitialState(noReload);
      this.requestChange(oldReq, newReq);
      await this._build(newReq, args);
    }

    return this.viewState();
  }

  deactivate(): void {
    this.updateState((currentState) => {
      return {
        ...currentState,
        active: false,
      };
    });
  }
}

type PaginatedTokenRouteArgs<Request, ViewState> = RouteViewArgs<
  Request,
  ViewState
> & {
  readonly paginationSize?: number;
};

/**
 * Bloc to handle Pagination to backend API supporting pagination token (not by using index, see RouteWithPaginationBloc).
 * Pagination methods shuld be implemented
 * - applyPagination
 * - hasPagination
 * - getNextPageToken
 *
 * It also handle ghost creation, and list items concactenation.
 * Implementation class only need to implements followding methods (should be 1 line implementation)
 * - getListItems
 * - applyListItems
 * - createGhostItem
 * - isGhostListItem
 */
export abstract class RouteWithPaginationTokenBloc<
  ListItem,
  Request,
  ViewState,
> extends RouteBloc<Request, ViewState> {
  static readonly DEFAULT_PAGE_SIZE = 20;

  readonly paginationSize: number;

  protected abstract applyPagination(
    request: Request,
    pagination: TokenPagination,
  ): Request;

  protected abstract hasPagination(request: Request): boolean;

  protected abstract getNextPageToken(viewState: ViewState): string;

  protected abstract getListItems(viewState: ViewState): ListItem[];

  protected abstract applyListItems(
    viewState: ViewState,
    items: ListItem[],
  ): ViewState;

  protected abstract createGhostItem(): ListItem;

  protected abstract isGhostListItem(item: ListItem): boolean;

  constructor(args: PaginatedTokenRouteArgs<Request, ViewState>) {
    super(args);

    this.paginationSize =
      args.paginationSize ?? RouteWithPaginationTokenBloc.DEFAULT_PAGE_SIZE;
  }

  override sanitizeRequest(request: Request): Request {
    if (this.hasPagination(request)) {
      return request;
    }

    // apply default pagination if nothing set
    return this.applyPagination(
      request,
      new TokenPagination({
        size: this.paginationSize,
      }),
    );
  }

  private _firstPageRequested = false;
  private _nextPageToken = '';

  get hasMorePage(): boolean {
    return (
      this._firstPageRequested === true && isNotEmptyString(this._nextPageToken)
    );
  }

  private _buildPagination(): TokenPagination {
    const pagination = new TokenPagination({
      size: this.paginationSize,
    });

    if (isNotEmptyString(this._nextPageToken)) {
      pagination.pageToken = this._nextPageToken;
    }

    return pagination;
  }

  async nextPage(): Promise<void> {
    if (
      this.isLoading() === true ||
      this.isActive() === false ||
      this.hasMorePage === false
    ) {
      return;
    }

    const pagination = this._buildPagination();
    const request = this.applyPagination(this.request(), pagination);

    // TODO: use noReload true, false was the default value before we introduced this flag
    return this._build(request, { noReload: false });
  }

  override requestChange(oldRequest: Request, newRequest: Request): void {
    // reset pagination state if the request change to start back from first page
    this._firstPageRequested = false;
    this._nextPageToken = '';
    super.requestChange(oldRequest, newRequest);
  }

  /**
   * Before build (page request)
   * we append ghost items (as loading indicator)
   * to the current list
   */
  protected override beforeBuild(req: Request): void {
    super.beforeBuild(req);

    this.updateViewState((currentViewState) => {
      const newListItems = [
        ...this.getListItems(currentViewState),
        ...Array(this.paginationSize).fill(this.createGhostItem()),
      ];

      return this.applyListItems(currentViewState, newListItems);
    });
  }

  /**
   * After build (page requested)
   * we append the new page to the existing list and remove ghost items
   */
  override afterSuccessfulBuild(newPage: ViewState): void {
    this._firstPageRequested = true;
    this._nextPageToken = this.getNextPageToken(newPage);

    const currentViewState = this.viewState();
    const currentItems = this.getListItems(currentViewState);
    const newItems = this.getListItems(newPage);

    const newListItems = [
      ...currentItems.filter((item) => this.isGhostListItem(item) === false),
      ...newItems,
    ];

    newPage = this.applyListItems(newPage, newListItems);

    super.afterSuccessfulBuild(newPage);
  }
}

export abstract class RouteWithPaginationBloc<
  Request,
  ViewState,
> extends RouteBloc<Request, ViewState> {
  protected abstract getTotalHits(
    request: Request,
    viewSate: ViewState,
  ): number;

  /// Size of the requested page
  protected abstract get paginationSize(): number;

  /// Apply the current pagination to the [Request]
  protected abstract applyPagination(request: Request, from: number): Request;

  /// Append the requested page to the current
  protected abstract appendNewPage(
    currentState: ViewState,
    newPage: ViewState,
  ): ViewState;

  /// How many items available
  /// use to determine if we have more page to fetch
  readonly totalHits = computed(() =>
    this.getTotalHits(this.request(), this.viewState()),
  );

  constructor(args: RouteViewArgs<Request, ViewState>) {
    super(args);
  }

  private _firstPageRequested = false;
  get firstPageRequested(): boolean {
    return this._firstPageRequested;
  }

  private _paginationFrom = 0;
  get paginationFrom(): number {
    return this._paginationFrom;
  }

  get hasMorePage(): boolean {
    if (this.firstPageRequested) {
      return this.paginationFrom < this.totalHits();
    }
    return true;
  }

  async nextPage(): Promise<void> {
    if (
      this.isLoading() === true ||
      this.isActive() === false ||
      this.hasMorePage === false
    ) {
      return;
    }

    // TODO: use noReload true, false was the default value before we introduced this flag
    await this._build(
      this.applyPagination(this.request(), this.paginationFrom),
      { noReload: false },
    );
  }

  override requestChange(oldRequest: Request, newRequest: Request): void {
    // reset pagination state if the request change
    this._firstPageRequested = false;
    this._paginationFrom = 0;
    super.requestChange(oldRequest, newRequest);
  }

  override afterSuccessfulBuild(newPage: ViewState): void {
    this._firstPageRequested = true;
    this._paginationFrom += this.paginationSize;

    newPage = this.appendNewPage(this.viewState(), newPage);

    super.afterSuccessfulBuild(newPage);
  }
}

export interface CacheState<T> {
  readonly value: T;
  readonly loading: boolean;
  readonly loaded: boolean;
}

// Helper class to load data that will be available from everywhere in the app
/// and that should not change overtime (atleast not often)
///
/// ex: Categories, Languages list ...
export abstract class CacheBloc<T> extends Bloc<CacheState<T>> {
  constructor(public readonly seedValue: T) {
    super({
      value: seedValue,
      loading: false,
      loaded: false,
    });
  }

  private loader?: Promise<T>;

  readonly cachedData = computed(() => this.state().value);
  readonly isLoading = computed(() => this.state().loading);
  readonly isLoaded = computed(() => this.state().loaded);

  // mainly for legacy usage
  readonly dataChange$ = toObservable(this.cachedData);

  /// Reset cached data
  ///
  /// next call to `load` will retrigger `retrieve` function
  reset(): void {
    this.loader = undefined;
    this.setState({
      value: this.seedValue,
      loading: false,
      loaded: false,
    });
  }

  /// Invalidate cached data
  ///
  /// next call to `load` will retrigger `retrieve` function
  invalidate(): void {
    this.loader = undefined;

    this.updateState((currentState) => {
      return {
        ...currentState,
        loading: false,
        loaded: false,
      };
    });
  }

  private _load(): Promise<T> {
    this.loader ??= this.fetch();
    return this.loader;
  }

  /// Load resources
  ///
  /// return cached resources if already loaded
  async load(): Promise<T> {
    if (this.isLoaded()) {
      return this.cachedData();
    }

    this.updateState((currentState) => {
      return {
        ...currentState,
        loading: true,
      };
    });

    try {
      const value = await this._load();
      this.setState({
        value,
        loading: false,
        loaded: true,
      });
      return value;
    } catch (e) {
      // invalide state if load function failed
      this.invalidate();

      // rethrow error to be catch top level
      // and display toast or report to sentry if necessary
      throw e;
    } finally {
      this.updateState((currentState) => {
        return {
          ...currentState,
          loading: false,
        };
      });
    }
  }

  updateCache(value: T, args?: { markAsLoaded?: boolean }): CacheState<T> {
    return this.updateState((currentState) => {
      return {
        ...currentState,
        value,
        loaded: args?.markAsLoaded ?? currentState.loaded,
      };
    });
  }

  async waitToBeReady(): Promise<T> {
    const s = await firstValueFrom(this.state$.pipe(filter((s) => s.loaded)));
    return s.value;
  }

  abstract fetch(): Promise<T>;
}

export abstract class IframeSyncedCacheBloc<T>
  extends CacheBloc<T>
  implements LeftyIframeDataSyncable<T>
{
  constructor(seedValue: T) {
    super(seedValue);
  }

  readonly dataToSync$ = this.state$.pipe(
    filter((s) => s.loaded),
    map((s) => s.value),
  );

  syncData(data: T): void {
    this.updateCache(data, { markAsLoaded: true });
  }

  abstract get syncName(): string;

  abstract convertToJson(obj: T): string;

  abstract convertFromJson(jsonString: string): T;
}

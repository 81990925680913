// This file defines messages for bronco.
//
// Bronco used to be implemented as a process and the whole interface was grpc based.
// Now bronco is migrated to being a fat client, thus most data structures are just regular classes.
// However, we copied the Task message (the result of findById and findByKey) such that we can serve it to the
// frontend and be certain that we are backwards compatible.

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file common/proto/bronco.proto (package baac.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind, StringValue } from "@bufbuild/protobuf";
import { Task } from "./bronco_pb";

/**
 * @generated from service baac.proto.Bronco
 */
export const Bronco = {
  typeName: "baac.proto.Bronco",
  methods: {
    /**
     * Finds a task (it could be current or historic).
     *
     * @generated from rpc baac.proto.Bronco.FindById
     */
    findById: {
      name: "FindById",
      I: StringValue,
      O: Task,
      kind: MethodKind.Unary,
    },
  }
} as const;


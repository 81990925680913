import { Injectable } from '@angular/core';
import { CampaignKeywordType } from '@frontend2/proto/common/proto/campaign_pb';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  createTrackingKeywordsConfig,
  douyinTagsField,
} from './config.helpers';
import * as TKMessages from './config.messages';
import { DefaultNetworkConfig, FormField } from './config.models';

@Injectable({ providedIn: 'root' })
export class DouyinConfig extends DefaultNetworkConfig {
  override readonly network = Network.DOUYIN;
  override readonly supportReshares = true;
  override readonly uploadPlaceholder = ['PapaJames', '...'].join('\n');

  override readonly keywords = createTrackingKeywordsConfig({
    types: [CampaignKeywordType.DOUYIN_KEYWORD],
    formHelpers: new Map<CampaignKeywordType, FormField>([
      [CampaignKeywordType.DOUYIN_KEYWORD, douyinTagsField],
    ]),
    errorMessage: TKMessages.douyinErrorMessage,
  });
}

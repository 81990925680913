import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianHomeDashboard } from '@frontend2/proto/librarian/proto/home_dashboard_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianHomeDashboardClient = PromiseClient<
  typeof LibrarianHomeDashboard
>;

function factory(): LibrarianHomeDashboardClient {
  return createPromiseClient(LibrarianHomeDashboard, inject(GRPC_TRANSPORT));
}

const provider = new InjectionToken('LibrarianHomeDashboard', {
  factory,
});

export function injectLibrarianHomeDashboardClient(): LibrarianHomeDashboardClient {
  return inject(provider);
}

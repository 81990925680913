import { isNil, isNotNil } from '@frontend2/core';
import { FloatRange } from '@frontend2/proto/common/proto/common_pb';

export interface NumberRange {
  readonly type: 'NumberRange';
  readonly start?: number;
  readonly end?: number;
}

export function createNumberRange(partial?: Partial<NumberRange>): NumberRange {
  return {
    type: 'NumberRange',
    start: partial?.start,
    end: partial?.end,
  };
}

export function createNumberRangeFromFloatRange(
  range: FloatRange,
): NumberRange {
  return createNumberRange({
    start: range.min === 0 || isNil(range.min) ? undefined : range.min,
    end: range.max === 0 || isNil(range.max) ? undefined : range.max,
  });
}

export function isNumberRange(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: any,
): obj is NumberRange {
  return obj && obj.type === 'NumberRange';
}

export function isInvalidRange(range: NumberRange): boolean {
  if (isNotNil(range.start) && isNotNil(range.end)) {
    return range.start >= range.end;
  }
  return false;
}

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  output,
  signal,
} from '@angular/core';

import { NgTemplateOutlet } from '@angular/common';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { LeftyAuthBloc } from '@frontend2/api';
import {
  Networks,
  isEqual,
  isNil,
  removeDuplicateInArray,
} from '@frontend2/core';
import { campaignKeywordTypeIsLocation } from '@frontend2/proto-helpers/common/common_pb.helpers';
import { readableSocialNetworkLocation } from '@frontend2/proto-helpers/universal/retriever_pb.helpers';
import { CampaignKeywordType } from '@frontend2/proto/common/proto/campaign_pb';
import {
  LogicOperator,
  Network,
} from '@frontend2/proto/common/proto/common_pb';
import { SocialNetworkLocation } from '@frontend2/proto/universal/retriever/proto/retriever_pb';
import { debounceTime, filter } from 'rxjs';
import {
  KEYWORDS_DEFAULT_LOGIC_OPERATOR,
  StringOrLocation,
  createCampaignNetworkTrackingForm,
  getDelimitersFromConfig,
  isCampaignNetworkTrackingForm,
  keywordPrefix,
} from '../../config/config.helpers';
import { CampaignNetworkTrackingForm } from '../../config/config.models';
import { NetworkConfigs } from '../../config/config.service';
import { LeftyControlValueAccessor } from '../../form';
import { NetworkIconComponent } from '../../icon/network.component';
import { LeftyButtonDirective } from '../../lefty-button-directive/lefty-button.directive';
import { LeftyChipsAutocompleteComponent } from '../../lefty-chip/lefty-chips-autocomplete.component';
import { LeftyChipsEditorComponent } from '../../lefty-chip/lefty-chips-editor.component';
import { LeftyFeedbackComponent } from '../../lefty-feedback/lefty-feedback.component';
import { LeftyLogicOperatorTreeComponent } from '../../logic-operator-tree/logic-operator-tree.component';
import { NetworkFormSelectComponent } from '../../selector/network-form-select/network-form-select.component';
import { AutocompleteLocationService } from '../../services/autocomplete-location.service';

const LOCATION_QUERY_MIN_LENGTH = 3;

@Component({
  selector: 'network-tracking-input',
  templateUrl: 'network-tracking-input-item.html',
  styleUrls: ['network-tracking-input-item.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyChipsAutocompleteComponent,
    LeftyChipsEditorComponent,
    NetworkIconComponent,
    NetworkFormSelectComponent,
    LeftyButtonDirective,
    LeftyFeedbackComponent,
    LeftyLogicOperatorTreeComponent,
    NgTemplateOutlet,
  ],
})
export class NetworkTrackingInputComponent extends LeftyControlValueAccessor<CampaignNetworkTrackingForm> {
  readonly logicTreeOffset = 7;

  readonly locationService = inject(AutocompleteLocationService);

  readonly auth = inject(LeftyAuthBloc);

  readonly networkConfig = inject(NetworkConfigs);

  readonly availableNetworks = input<Network[]>([]);
  readonly cantEditSelectedNetwork = input(false);
  readonly canRemove = input(false);
  readonly hideNetworks = input(false);
  readonly withLogicOperators = input(false);
  readonly isExcluded = input(false);

  readonly network = computed(
    () => this.value()?.network ?? Network.NETWORK_UNKNOWN,
  );

  readonly settings = computed(
    () => this.networkConfig.of(this.network()).keywords,
  );

  readonly mustHaveLogicOperatorTree = computed(
    () => this.withLogicOperators() && this.settings().types.length > 1,
  );

  readonly clickRemove$ = output<MouseEvent>();

  readonly popupVisible$ = output<boolean>();

  readonly locationQuery = signal('');

  readonly loading = signal(false);

  readonly locationOptions = signal<SocialNetworkLocation[]>([]);

  constructor() {
    super();
    toObservable(this.locationQuery)
      .pipe(takeUntilDestroyed())
      .pipe(debounceTime(300))
      .subscribe((val) => this._autocompleteLocations(val));

    toObservable(this.value)
      .pipe(takeUntilDestroyed())
      .pipe(filter(isNil))
      .subscribe(() => {
        if (this.availableNetworks().length > 0) {
          const defaultNetwork = this.availableNetworks()[0];
          this.setValueAndNotify(
            createCampaignNetworkTrackingForm({
              network: defaultNetwork,
            }),
          );
        }
      });
  }

  private async _autocompleteLocations(query: string): Promise<void> {
    query = query.trim();
    if (query.length < LOCATION_QUERY_MIN_LENGTH) {
      this.locationOptions.set([]);
      return;
    }

    try {
      this.loading.set(true);
      const locations = await this.locationService.autocompleteLocations(
        Network.INSTA,
        query,
      );
      this.locationOptions.set(locations);
    } finally {
      this.loading.set(false);
    }
  }

  readonly locationEmptyPlaceholder = computed(() => {
    const query = this.locationQuery().trim();
    if (query.length <= LOCATION_QUERY_MIN_LENGTH) {
      return '';
    }

    return $localize`No locations found. Need it added? Contact your dedicated account manager for support.`;
  });

  isLocationKeyword(type: CampaignKeywordType): boolean {
    return campaignKeywordTypeIsLocation(type);
  }

  locationRenderer(location: SocialNetworkLocation): string {
    return readableSocialNetworkLocation(location);
  }

  setNetwork(network: Network | Network[]): void {
    if (this.network() === network) {
      return;
    }

    if (!Array.isArray(network)) {
      this.setValueAndNotify(
        createCampaignNetworkTrackingForm({
          network: network,
        }),
      );
    } else {
      if (network.length > 0) {
        this.setValueAndNotify(
          createCampaignNetworkTrackingForm({
            network: network[0],
          }),
        );
      }
    }

    this.ngControl?.control?.markAsPristine();
  }

  get isAdmin(): boolean {
    return this.auth.isAdmin;
  }

  readonly logicOperator = computed(
    () => this.value()?.logicOperator ?? KEYWORDS_DEFAULT_LOGIC_OPERATOR,
  );

  private _setKeywords(
    type: CampaignKeywordType,
    values: StringOrLocation[],
  ): void {
    const currentValue = this.value();

    const newKeywords = new Map(currentValue?.keywords);
    if (values.length === 0) {
      newKeywords.set(type, []);
    } else {
      newKeywords.set(type, values);
    }

    if (isEqual(currentValue?.keywords, newKeywords)) {
      return;
    }

    this.setValueAndNotify(
      createCampaignNetworkTrackingForm({
        ...this.value(),
        keywords: newKeywords,
      }),
    );

    if (this.withLogicOperators()) {
      this._maybeInitializeLogicOperator(type);
    }
  }

  private _setExcludedKeywords(
    type: CampaignKeywordType,
    values: StringOrLocation[],
  ): void {
    const currentValue = this.value();

    const newKeywords = new Map(currentValue?.excludedKeywords);
    if (values.length === 0) {
      newKeywords.set(type, []);
    } else {
      newKeywords.set(type, values);
    }

    if (isEqual(currentValue?.excludedKeywords, newKeywords)) {
      return;
    }

    this.setValueAndNotify(
      createCampaignNetworkTrackingForm({
        ...this.value(),
        excludedKeywords: newKeywords,
      }),
    );

    if (this.withLogicOperators()) {
      this._maybeInitializeLogicOperator(type);
    }
  }

  private _maybeInitializeLogicOperator(type: CampaignKeywordType): void {
    const currentValue = this.value();

    const currentOperator = currentValue?.keywordLogicOperator.get(type);
    if (isNil(currentOperator)) {
      this.setLogicOperatorForKeyword(type, KEYWORDS_DEFAULT_LOGIC_OPERATOR);
    }
  }

  setKeywords(type: CampaignKeywordType, values: string[]): void {
    values = values.map((val) => val.trim().toLowerCase());
    values = removeDuplicateInArray(values);

    if (this.isExcluded()) {
      this._setExcludedKeywords(type, values);
    } else {
      this._setKeywords(type, values);
    }
  }

  setLogicOperator(operator: LogicOperator | undefined): void {
    if (operator === this.logicOperator()) {
      return;
    }

    this.setValueAndNotify(
      createCampaignNetworkTrackingForm({
        ...this.value(),
        logicOperator: operator,
      }),
    );
    this.ngControl?.control?.markAsPristine();
  }

  setLogicOperatorForKeyword(
    type: CampaignKeywordType,
    operator: LogicOperator,
  ): void {
    const currentMap = this.value()?.keywordLogicOperator ?? new Map();

    if (currentMap.get(type) === operator) {
      return;
    }
    const newKeywordLogicOperators = new Map(currentMap);

    newKeywordLogicOperators.set(type, operator);

    this.setValueAndNotify(
      createCampaignNetworkTrackingForm({
        ...this.value(),
        keywordLogicOperator: newKeywordLogicOperators,
      }),
    );
  }

  getKeywords(type: CampaignKeywordType): string[] {
    const keywords = this.isExcluded()
      ? this.value()?.excludedKeywords.get(type)
      : this.value()?.keywords.get(type);

    return keywords?.filter((val) => typeof val === 'string') ?? [];
  }

  setLocationKeywords(
    type: CampaignKeywordType,
    values: SocialNetworkLocation[],
  ): void {
    values = removeDuplicateInArray(values, (a, b) => a.id === b.id);

    if (this.isExcluded()) {
      this._setExcludedKeywords(type, values);
    } else {
      this._setKeywords(type, values);
    }
  }

  getLocations(type: CampaignKeywordType): SocialNetworkLocation[] {
    const keywords = this.isExcluded()
      ? this.value()?.excludedKeywords.get(type)
      : this.value()?.keywords.get(type);

    return (
      keywords?.filter((val) => val instanceof SocialNetworkLocation) ?? []
    );
  }

  getLogicOperatorForKeyword(type: CampaignKeywordType): LogicOperator {
    return (
      this.value()?.keywordLogicOperator.get(type) ??
      KEYWORDS_DEFAULT_LOGIC_OPERATOR
    );
  }

  chipsPrefix(type: CampaignKeywordType): string {
    return keywordPrefix(type);
  }

  getSettingLable(type: CampaignKeywordType): string {
    const label = this.settings().formHelpers.get(type)?.name ?? '';
    return this.isExcluded()
      ? $localize`Excluded ${label.toLowerCase()}`
      : label;
  }

  getSettingPlaceholder(type: CampaignKeywordType): string {
    return this.settings().formHelpers.get(type)?.placeholder ?? '';
  }

  getSettingDelimeters(type: CampaignKeywordType): string {
    return getDelimitersFromConfig(this.settings(), type);
  }

  readonly readableNetwork = Networks.readable;

  override isValidType(obj: unknown): obj is CampaignNetworkTrackingForm {
    return isCampaignNetworkTrackingForm(obj);
  }
}

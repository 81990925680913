import { Component, Input, Optional, Self } from '@angular/core';
import { NgControl } from '@angular/forms';
import { InputSize, LeftyFormValueBase } from '../form';
import {
  NumberRange,
  createNumberRange,
  isInvalidRange,
  isNumberRange,
} from './lefty-range-input.models';
import { NgIf } from '@angular/common';
import { LeftyFormNumberInputComponent } from '../lefty-form-input/lefty-form-number-input.component';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-range-input',
  templateUrl: './lefty-range-input.component.html',
  styleUrls: ['./lefty-range-input.component.scss'],
  standalone: true,
  imports: [LeftyFormComponent, LeftyFormNumberInputComponent, NgIf],
})
export class LeftyRangeInputComponent extends LeftyFormValueBase<NumberRange> {
  @Input()
  allowDouble = false;
  constructor(@Optional() @Self() public override ngControl?: NgControl) {
    super({
      type: 'NumberRange',
    });

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input()
  size: InputSize = 'medium';

  @Input()
  splitMessage = $localize`to`;

  get range(): NumberRange {
    return this.value;
  }

  get isRangeError(): boolean {
    return isInvalidRange(this.range);
  }

  setStart(val: number | undefined): void {
    this.handleValueChange({
      ...this.range,
      start: val,
    });
  }

  setEnd(val: number | undefined): void {
    this.handleValueChange({
      ...this.range,
      end: val,
    });
  }

  override writeValue(obj: unknown): void {
    if (isNumberRange(obj)) {
      this.value = obj;
    } else {
      // reset to default if form value is invalid
      this.value = createNumberRange();
    }
  }
}

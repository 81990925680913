// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/pool.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { FloatValue, Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { AffiliationInfo, CreatorCardSnippet, CreatorRegisteredDetails, GenerikInfoCard } from "./creators_pb";
import { FavoritesLabels, InfluencerCampaigns } from "../../common/proto/campaign_pb";
import { AuthorPermission, BasicPagination, FileProto, Network, NetworkFollowersRange, PostType, StringList } from "../../common/proto/common_pb";
import { AddressFieldColumn, CreatorFieldValuesList } from "./creator_fields_pb";
import { EntityFieldValue } from "./entity_fields_pb";
import { RfmScoreData } from "./rfm_scoring_pb";
import { DynamicFilter, FlexibleCreatorSelector, TimeRestriction } from "./common_pb";
import { Infos } from "./users_pb";
import { ReportMetric, ReportTimeStep } from "./reports_pb";

/**
 * @generated from message librarian.proto.CreatorPoolResponse
 */
export class CreatorPoolResponse extends Message<CreatorPoolResponse> {
  /**
   * @generated from field: librarian.proto.CreatorCardSnippet base_snippet = 1;
   */
  baseSnippet?: CreatorCardSnippet;

  /**
   * @generated from field: librarian.proto.CreatorRegisteredDetails registered_details = 13;
   */
  registeredDetails?: CreatorRegisteredDetails;

  /**
   * @generated from field: common.proto.InfluencerCampaigns statuses = 2;
   */
  statuses?: InfluencerCampaigns;

  /**
   * @generated from field: common.proto.FavoritesLabels labels = 3;
   */
  labels?: FavoritesLabels;

  /**
   * @generated from field: int32 notes_count = 8;
   */
  notesCount = 0;

  /**
   * @generated from field: bool tracked = 15;
   */
  tracked = false;

  /**
   * @generated from field: common.proto.AuthorPermission instagram_insights = 16;
   */
  instagramInsights = AuthorPermission.PERMISSION_UNDEFINED;

  /**
   * @generated from field: repeated librarian.proto.GenerikInfoCard network_info = 9;
   */
  networkInfo: GenerikInfoCard[] = [];

  /**
   * @generated from field: repeated librarian.proto.NetworkCreatorPerformance network_perfs = 12;
   */
  networkPerfs: NetworkCreatorPerformance[] = [];

  /**
   * @generated from field: librarian.proto.CreatorFieldValuesList creator_fields_values = 11 [deprecated = true];
   * @deprecated
   */
  creatorFieldsValues?: CreatorFieldValuesList;

  /**
   * @generated from field: repeated librarian.proto.EntityFieldValue entity_fields_values = 17;
   */
  entityFieldsValues: EntityFieldValue[] = [];

  /**
   * @generated from field: librarian.proto.AffiliationInfo affiliation_info = 10;
   */
  affiliationInfo?: AffiliationInfo;

  /**
   * @generated from field: librarian.proto.RfmScoreData rfm_score_data = 14;
   */
  rfmScoreData?: RfmScoreData;

  /**
   * @generated from field: google.protobuf.Timestamp creation_date = 100;
   */
  creationDate?: Timestamp;

  constructor(data?: PartialMessage<CreatorPoolResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorPoolResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_snippet", kind: "message", T: CreatorCardSnippet },
    { no: 13, name: "registered_details", kind: "message", T: CreatorRegisteredDetails },
    { no: 2, name: "statuses", kind: "message", T: InfluencerCampaigns },
    { no: 3, name: "labels", kind: "message", T: FavoritesLabels },
    { no: 8, name: "notes_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "tracked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "instagram_insights", kind: "enum", T: proto3.getEnumType(AuthorPermission) },
    { no: 9, name: "network_info", kind: "message", T: GenerikInfoCard, repeated: true },
    { no: 12, name: "network_perfs", kind: "message", T: NetworkCreatorPerformance, repeated: true },
    { no: 11, name: "creator_fields_values", kind: "message", T: CreatorFieldValuesList },
    { no: 17, name: "entity_fields_values", kind: "message", T: EntityFieldValue, repeated: true },
    { no: 10, name: "affiliation_info", kind: "message", T: AffiliationInfo },
    { no: 14, name: "rfm_score_data", kind: "message", T: RfmScoreData },
    { no: 100, name: "creation_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorPoolResponse {
    return new CreatorPoolResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorPoolResponse {
    return new CreatorPoolResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorPoolResponse {
    return new CreatorPoolResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorPoolResponse | PlainMessage<CreatorPoolResponse> | undefined, b: CreatorPoolResponse | PlainMessage<CreatorPoolResponse> | undefined): boolean {
    return proto3.util.equals(CreatorPoolResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorPerformance
 */
export class CreatorPerformance extends Message<CreatorPerformance> {
  /**
   * @generated from field: int32 count = 1;
   */
  count = 0;

  /**
   * @generated from field: int64 impressions = 2;
   */
  impressions = protoInt64.zero;

  /**
   * @generated from field: int64 emv = 5;
   */
  emv = protoInt64.zero;

  /**
   * @generated from field: int64 cum_followers = 4;
   */
  cumFollowers = protoInt64.zero;

  /**
   * @generated from field: int64 sum_engagements = 6;
   */
  sumEngagements = protoInt64.zero;

  constructor(data?: PartialMessage<CreatorPerformance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorPerformance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "impressions", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "emv", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "cum_followers", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "sum_engagements", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorPerformance {
    return new CreatorPerformance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorPerformance {
    return new CreatorPerformance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorPerformance {
    return new CreatorPerformance().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorPerformance | PlainMessage<CreatorPerformance> | undefined, b: CreatorPerformance | PlainMessage<CreatorPerformance> | undefined): boolean {
    return proto3.util.equals(CreatorPerformance, a, b);
  }
}

/**
 * @generated from message librarian.proto.NetworkCreatorPerformance
 */
export class NetworkCreatorPerformance extends Message<NetworkCreatorPerformance> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: librarian.proto.CreatorPerformance perf = 2;
   */
  perf?: CreatorPerformance;

  constructor(data?: PartialMessage<NetworkCreatorPerformance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.NetworkCreatorPerformance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "perf", kind: "message", T: CreatorPerformance },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkCreatorPerformance {
    return new NetworkCreatorPerformance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkCreatorPerformance {
    return new NetworkCreatorPerformance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkCreatorPerformance {
    return new NetworkCreatorPerformance().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkCreatorPerformance | PlainMessage<NetworkCreatorPerformance> | undefined, b: NetworkCreatorPerformance | PlainMessage<NetworkCreatorPerformance> | undefined): boolean {
    return proto3.util.equals(NetworkCreatorPerformance, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorPoolResponses
 */
export class CreatorPoolResponses extends Message<CreatorPoolResponses> {
  /**
   * @generated from field: repeated librarian.proto.CreatorPoolResponse responses = 1;
   */
  responses: CreatorPoolResponse[] = [];

  /**
   * @generated from field: int64 total_hits = 2;
   */
  totalHits = protoInt64.zero;

  /**
   * used to know which networks are used for stats filters
   * backend is already computing it, so no need to compute it in the frontend
   *
   * @generated from field: repeated common.proto.Network filtered_networks = 4;
   */
  filteredNetworks: Network[] = [];

  /**
   * Pagination flag - We need to signal if there is a pagination
   * because we might return less results than expected if a user has gone private.
   *
   * @generated from field: bool has_pagination = 3;
   */
  hasPagination = false;

  constructor(data?: PartialMessage<CreatorPoolResponses>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorPoolResponses";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "responses", kind: "message", T: CreatorPoolResponse, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "filtered_networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 3, name: "has_pagination", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorPoolResponses {
    return new CreatorPoolResponses().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorPoolResponses {
    return new CreatorPoolResponses().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorPoolResponses {
    return new CreatorPoolResponses().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorPoolResponses | PlainMessage<CreatorPoolResponses> | undefined, b: CreatorPoolResponses | PlainMessage<CreatorPoolResponses> | undefined): boolean {
    return proto3.util.equals(CreatorPoolResponses, a, b);
  }
}

/**
 * @generated from message librarian.proto.SimplePrefixSearchRequest
 */
export class SimplePrefixSearchRequest extends Message<SimplePrefixSearchRequest> {
  /**
   * @generated from field: string prefix = 1;
   */
  prefix = "";

  /**
   * Default Pagination in of size 20 starting from 0
   *
   * @generated from field: common.proto.BasicPagination pagination = 2;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<SimplePrefixSearchRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SimplePrefixSearchRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SimplePrefixSearchRequest {
    return new SimplePrefixSearchRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SimplePrefixSearchRequest {
    return new SimplePrefixSearchRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SimplePrefixSearchRequest {
    return new SimplePrefixSearchRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SimplePrefixSearchRequest | PlainMessage<SimplePrefixSearchRequest> | undefined, b: SimplePrefixSearchRequest | PlainMessage<SimplePrefixSearchRequest> | undefined): boolean {
    return proto3.util.equals(SimplePrefixSearchRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.SimplePrefixSearchResponse
 */
export class SimplePrefixSearchResponse extends Message<SimplePrefixSearchResponse> {
  /**
   * @generated from field: repeated librarian.proto.SimplePrefixSearchResponse.SimplePrefixSearchCreatorCard responses = 1;
   */
  responses: SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard[] = [];

  /**
   * @generated from field: int64 total_hits = 2;
   */
  totalHits = protoInt64.zero;

  /**
   * Pagination flag - We need to signal if there is a pagination
   * because we might return less results than expected if a user has gone private.
   *
   * @generated from field: bool has_pagination = 3;
   */
  hasPagination = false;

  constructor(data?: PartialMessage<SimplePrefixSearchResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SimplePrefixSearchResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "responses", kind: "message", T: SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "has_pagination", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SimplePrefixSearchResponse {
    return new SimplePrefixSearchResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SimplePrefixSearchResponse {
    return new SimplePrefixSearchResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SimplePrefixSearchResponse {
    return new SimplePrefixSearchResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SimplePrefixSearchResponse | PlainMessage<SimplePrefixSearchResponse> | undefined, b: SimplePrefixSearchResponse | PlainMessage<SimplePrefixSearchResponse> | undefined): boolean {
    return proto3.util.equals(SimplePrefixSearchResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.SimplePrefixSearchResponse.SimplePrefixNetworkInfo
 */
export class SimplePrefixSearchResponse_SimplePrefixNetworkInfo extends Message<SimplePrefixSearchResponse_SimplePrefixNetworkInfo> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * this field is used for complete link, for example Youtube need the channel Id
   * for Twitter it's screen name etc...
   *
   * @generated from field: string network_link = 2;
   */
  networkLink = "";

  /**
   * @generated from field: int64 followers_count = 3;
   */
  followersCount = protoInt64.zero;

  constructor(data?: PartialMessage<SimplePrefixSearchResponse_SimplePrefixNetworkInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SimplePrefixSearchResponse.SimplePrefixNetworkInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "network_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "followers_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SimplePrefixSearchResponse_SimplePrefixNetworkInfo {
    return new SimplePrefixSearchResponse_SimplePrefixNetworkInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SimplePrefixSearchResponse_SimplePrefixNetworkInfo {
    return new SimplePrefixSearchResponse_SimplePrefixNetworkInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SimplePrefixSearchResponse_SimplePrefixNetworkInfo {
    return new SimplePrefixSearchResponse_SimplePrefixNetworkInfo().fromJsonString(jsonString, options);
  }

  static equals(a: SimplePrefixSearchResponse_SimplePrefixNetworkInfo | PlainMessage<SimplePrefixSearchResponse_SimplePrefixNetworkInfo> | undefined, b: SimplePrefixSearchResponse_SimplePrefixNetworkInfo | PlainMessage<SimplePrefixSearchResponse_SimplePrefixNetworkInfo> | undefined): boolean {
    return proto3.util.equals(SimplePrefixSearchResponse_SimplePrefixNetworkInfo, a, b);
  }
}

/**
 * @generated from message librarian.proto.SimplePrefixSearchResponse.SimplePrefixSearchCreatorCard
 */
export class SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard extends Message<SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard> {
  /**
   * @generated from field: librarian.proto.CreatorCardSnippet base_snippet = 1;
   */
  baseSnippet?: CreatorCardSnippet;

  /**
   * @generated from field: repeated librarian.proto.SimplePrefixSearchResponse.SimplePrefixNetworkInfo networks = 2;
   */
  networks: SimplePrefixSearchResponse_SimplePrefixNetworkInfo[] = [];

  constructor(data?: PartialMessage<SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SimplePrefixSearchResponse.SimplePrefixSearchCreatorCard";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_snippet", kind: "message", T: CreatorCardSnippet },
    { no: 2, name: "networks", kind: "message", T: SimplePrefixSearchResponse_SimplePrefixNetworkInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard {
    return new SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard {
    return new SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard {
    return new SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard().fromJsonString(jsonString, options);
  }

  static equals(a: SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard | PlainMessage<SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard> | undefined, b: SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard | PlainMessage<SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard> | undefined): boolean {
    return proto3.util.equals(SimplePrefixSearchResponse_SimplePrefixSearchCreatorCard, a, b);
  }
}

/**
 * @generated from message librarian.proto.PoolExportReq
 */
export class PoolExportReq extends Message<PoolExportReq> {
  /**
   * @generated from field: librarian.proto.FlexibleCreatorSelector selector = 1;
   */
  selector?: FlexibleCreatorSelector;

  /**
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<PoolExportReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PoolExportReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "selector", kind: "message", T: FlexibleCreatorSelector },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoolExportReq {
    return new PoolExportReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoolExportReq {
    return new PoolExportReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoolExportReq {
    return new PoolExportReq().fromJsonString(jsonString, options);
  }

  static equals(a: PoolExportReq | PlainMessage<PoolExportReq> | undefined, b: PoolExportReq | PlainMessage<PoolExportReq> | undefined): boolean {
    return proto3.util.equals(PoolExportReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.CampaignExportReq
 */
export class CampaignExportReq extends Message<CampaignExportReq> {
  /**
   * @generated from field: librarian.proto.FlexibleCreatorSelector selector = 1;
   */
  selector?: FlexibleCreatorSelector;

  /**
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: int64 campaign_id = 3;
   */
  campaignId = protoInt64.zero;

  constructor(data?: PartialMessage<CampaignExportReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignExportReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "selector", kind: "message", T: FlexibleCreatorSelector },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignExportReq {
    return new CampaignExportReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignExportReq {
    return new CampaignExportReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignExportReq {
    return new CampaignExportReq().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignExportReq | PlainMessage<CampaignExportReq> | undefined, b: CampaignExportReq | PlainMessage<CampaignExportReq> | undefined): boolean {
    return proto3.util.equals(CampaignExportReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.FlexibleCampaignCreatorsSelector
 */
export class FlexibleCampaignCreatorsSelector extends Message<FlexibleCampaignCreatorsSelector> {
  /**
   * @generated from oneof librarian.proto.FlexibleCampaignCreatorsSelector.selector
   */
  selector: {
    /**
     * @generated from field: bool retrieve_all_creators = 1;
     */
    value: boolean;
    case: "retrieveAllCreators";
  } | {
    /**
     * handle mass selection in influencers view.
     *
     * @generated from field: common.proto.StringList manual_list = 2;
     */
    value: StringList;
    case: "manualList";
  } | {
    /**
     * @generated from field: librarian.proto.DynamicFilter filter = 3;
     */
    value: DynamicFilter;
    case: "filter";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<FlexibleCampaignCreatorsSelector>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.FlexibleCampaignCreatorsSelector";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "retrieve_all_creators", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "selector" },
    { no: 2, name: "manual_list", kind: "message", T: StringList, oneof: "selector" },
    { no: 3, name: "filter", kind: "message", T: DynamicFilter, oneof: "selector" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FlexibleCampaignCreatorsSelector {
    return new FlexibleCampaignCreatorsSelector().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FlexibleCampaignCreatorsSelector {
    return new FlexibleCampaignCreatorsSelector().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FlexibleCampaignCreatorsSelector {
    return new FlexibleCampaignCreatorsSelector().fromJsonString(jsonString, options);
  }

  static equals(a: FlexibleCampaignCreatorsSelector | PlainMessage<FlexibleCampaignCreatorsSelector> | undefined, b: FlexibleCampaignCreatorsSelector | PlainMessage<FlexibleCampaignCreatorsSelector> | undefined): boolean {
    return proto3.util.equals(FlexibleCampaignCreatorsSelector, a, b);
  }
}

/**
 * @generated from message librarian.proto.CampaignExportReqV2
 */
export class CampaignExportReqV2 extends Message<CampaignExportReqV2> {
  /**
   * @generated from field: librarian.proto.FlexibleCampaignCreatorsSelector selector = 1;
   */
  selector?: FlexibleCampaignCreatorsSelector;

  /**
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: int64 campaign_id = 3;
   */
  campaignId = protoInt64.zero;

  constructor(data?: PartialMessage<CampaignExportReqV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignExportReqV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "selector", kind: "message", T: FlexibleCampaignCreatorsSelector },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignExportReqV2 {
    return new CampaignExportReqV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignExportReqV2 {
    return new CampaignExportReqV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignExportReqV2 {
    return new CampaignExportReqV2().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignExportReqV2 | PlainMessage<CampaignExportReqV2> | undefined, b: CampaignExportReqV2 | PlainMessage<CampaignExportReqV2> | undefined): boolean {
    return proto3.util.equals(CampaignExportReqV2, a, b);
  }
}

/**
 * @generated from message librarian.proto.FlexibleExportReq
 */
export class FlexibleExportReq extends Message<FlexibleExportReq> {
  /**
   * using user_id here to make it easy to set and check it in RequestPoolExportCsvTaskV2 and
   * PreparePoolExportV2
   *
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from oneof librarian.proto.FlexibleExportReq.request
   */
  request: {
    /**
     * @generated from field: librarian.proto.PoolExportReq pool_export_req = 2;
     */
    value: PoolExportReq;
    case: "poolExportReq";
  } | {
    /**
     * @generated from field: librarian.proto.CampaignExportReq campaign_export_req = 3;
     */
    value: CampaignExportReq;
    case: "campaignExportReq";
  } | {
    /**
     * @generated from field: librarian.proto.CampaignExportReqV2 campaign_export_req_v2 = 4;
     */
    value: CampaignExportReqV2;
    case: "campaignExportReqV2";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<FlexibleExportReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.FlexibleExportReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "pool_export_req", kind: "message", T: PoolExportReq, oneof: "request" },
    { no: 3, name: "campaign_export_req", kind: "message", T: CampaignExportReq, oneof: "request" },
    { no: 4, name: "campaign_export_req_v2", kind: "message", T: CampaignExportReqV2, oneof: "request" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FlexibleExportReq {
    return new FlexibleExportReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FlexibleExportReq {
    return new FlexibleExportReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FlexibleExportReq {
    return new FlexibleExportReq().fromJsonString(jsonString, options);
  }

  static equals(a: FlexibleExportReq | PlainMessage<FlexibleExportReq> | undefined, b: FlexibleExportReq | PlainMessage<FlexibleExportReq> | undefined): boolean {
    return proto3.util.equals(FlexibleExportReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.PoolSettings
 */
export class PoolSettings extends Message<PoolSettings> {
  /**
   * @generated from field: librarian.proto.TimeRestriction time_restriction = 4;
   */
  timeRestriction?: TimeRestriction;

  constructor(data?: PartialMessage<PoolSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PoolSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 4, name: "time_restriction", kind: "message", T: TimeRestriction },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoolSettings {
    return new PoolSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoolSettings {
    return new PoolSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoolSettings {
    return new PoolSettings().fromJsonString(jsonString, options);
  }

  static equals(a: PoolSettings | PlainMessage<PoolSettings> | undefined, b: PoolSettings | PlainMessage<PoolSettings> | undefined): boolean {
    return proto3.util.equals(PoolSettings, a, b);
  }
}

/**
 * @generated from message librarian.proto.PoolSettingsResponse
 */
export class PoolSettingsResponse extends Message<PoolSettingsResponse> {
  /**
   * @generated from field: librarian.proto.PoolSettings settings = 1;
   */
  settings?: PoolSettings;

  /**
   * @generated from field: string handle = 2;
   */
  handle = "";

  constructor(data?: PartialMessage<PoolSettingsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PoolSettingsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "settings", kind: "message", T: PoolSettings },
    { no: 2, name: "handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoolSettingsResponse {
    return new PoolSettingsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoolSettingsResponse {
    return new PoolSettingsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoolSettingsResponse {
    return new PoolSettingsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PoolSettingsResponse | PlainMessage<PoolSettingsResponse> | undefined, b: PoolSettingsResponse | PlainMessage<PoolSettingsResponse> | undefined): boolean {
    return proto3.util.equals(PoolSettingsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadPoolRequest
 */
export class UploadPoolRequest extends Message<UploadPoolRequest> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: repeated string names = 2;
   */
  names: string[] = [];

  /**
   * If set, all tags in this list will be automatically added to the uploaded influencers
   *
   * @generated from field: repeated int64 tags_to_add = 3;
   */
  tagsToAdd: bigint[] = [];

  /**
   * @generated from field: common.proto.Network network = 4;
   */
  network = Network.NETWORK_UNKNOWN;

  constructor(data?: PartialMessage<UploadPoolRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadPoolRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "tags_to_add", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadPoolRequest {
    return new UploadPoolRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadPoolRequest {
    return new UploadPoolRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadPoolRequest {
    return new UploadPoolRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadPoolRequest | PlainMessage<UploadPoolRequest> | undefined, b: UploadPoolRequest | PlainMessage<UploadPoolRequest> | undefined): boolean {
    return proto3.util.equals(UploadPoolRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadWithInfosRequest
 */
export class UploadWithInfosRequest extends Message<UploadWithInfosRequest> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: repeated librarian.proto.InfluencerWithInfos influencers = 2;
   */
  influencers: InfluencerWithInfos[] = [];

  constructor(data?: PartialMessage<UploadWithInfosRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadWithInfosRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "influencers", kind: "message", T: InfluencerWithInfos, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadWithInfosRequest {
    return new UploadWithInfosRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadWithInfosRequest {
    return new UploadWithInfosRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadWithInfosRequest {
    return new UploadWithInfosRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadWithInfosRequest | PlainMessage<UploadWithInfosRequest> | undefined, b: UploadWithInfosRequest | PlainMessage<UploadWithInfosRequest> | undefined): boolean {
    return proto3.util.equals(UploadWithInfosRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.InfluencerWithInfos
 */
export class InfluencerWithInfos extends Message<InfluencerWithInfos> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: librarian.proto.Infos infos = 2;
   */
  infos?: Infos;

  /**
   * @generated from field: repeated string labels = 3;
   */
  labels: string[] = [];

  constructor(data?: PartialMessage<InfluencerWithInfos>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InfluencerWithInfos";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "infos", kind: "message", T: Infos },
    { no: 3, name: "labels", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerWithInfos {
    return new InfluencerWithInfos().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerWithInfos {
    return new InfluencerWithInfos().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerWithInfos {
    return new InfluencerWithInfos().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerWithInfos | PlainMessage<InfluencerWithInfos> | undefined, b: InfluencerWithInfos | PlainMessage<InfluencerWithInfos> | undefined): boolean {
    return proto3.util.equals(InfluencerWithInfos, a, b);
  }
}

/**
 * @generated from message librarian.proto.DownloadPoolAssetsRequest
 */
export class DownloadPoolAssetsRequest extends Message<DownloadPoolAssetsRequest> {
  /**
   * @generated from field: string team_name = 1;
   */
  teamName = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: common.proto.StringList curated_select = 3;
   */
  curatedSelect?: StringList;

  constructor(data?: PartialMessage<DownloadPoolAssetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DownloadPoolAssetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "team_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "curated_select", kind: "message", T: StringList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DownloadPoolAssetsRequest {
    return new DownloadPoolAssetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DownloadPoolAssetsRequest {
    return new DownloadPoolAssetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DownloadPoolAssetsRequest {
    return new DownloadPoolAssetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DownloadPoolAssetsRequest | PlainMessage<DownloadPoolAssetsRequest> | undefined, b: DownloadPoolAssetsRequest | PlainMessage<DownloadPoolAssetsRequest> | undefined): boolean {
    return proto3.util.equals(DownloadPoolAssetsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.PoolReportFilter
 */
export class PoolReportFilter extends Message<PoolReportFilter> {
  /**
   * @generated from field: repeated common.proto.Network networks = 1;
   */
  networks: Network[] = [];

  /**
   * @generated from field: librarian.proto.TimeRestriction time_restriction = 2;
   */
  timeRestriction?: TimeRestriction;

  /**
   * @generated from field: repeated common.proto.PostType post_types = 3;
   */
  postTypes: PostType[] = [];

  /**
   * @generated from field: repeated common.proto.NetworkFollowersRange networks_followers_ranges = 5;
   */
  networksFollowersRanges: NetworkFollowersRange[] = [];

  /**
   * @generated from field: repeated int64 label_ids = 6;
   */
  labelIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 exclude_label_ids = 7;
   */
  excludeLabelIds: bigint[] = [];

  /**
   * @generated from field: repeated string influencers_ids = 8;
   */
  influencersIds: string[] = [];

  /**
   * @generated from field: repeated string excluded_influencers_ids = 9;
   */
  excludedInfluencersIds: string[] = [];

  /**
   * @generated from field: repeated int64 campaign_ids = 10;
   */
  campaignIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 exclude_campaign_ids = 11;
   */
  excludeCampaignIds: bigint[] = [];

  /**
   * @generated from field: repeated string keywords = 12;
   */
  keywords: string[] = [];

  /**
   * @generated from field: repeated string mentions = 13;
   */
  mentions: string[] = [];

  constructor(data?: PartialMessage<PoolReportFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PoolReportFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 2, name: "time_restriction", kind: "message", T: TimeRestriction },
    { no: 3, name: "post_types", kind: "enum", T: proto3.getEnumType(PostType), repeated: true },
    { no: 5, name: "networks_followers_ranges", kind: "message", T: NetworkFollowersRange, repeated: true },
    { no: 6, name: "label_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 7, name: "exclude_label_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 8, name: "influencers_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "excluded_influencers_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "campaign_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 11, name: "exclude_campaign_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 12, name: "keywords", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "mentions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoolReportFilter {
    return new PoolReportFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoolReportFilter {
    return new PoolReportFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoolReportFilter {
    return new PoolReportFilter().fromJsonString(jsonString, options);
  }

  static equals(a: PoolReportFilter | PlainMessage<PoolReportFilter> | undefined, b: PoolReportFilter | PlainMessage<PoolReportFilter> | undefined): boolean {
    return proto3.util.equals(PoolReportFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.PoolStatsReqV2
 */
export class PoolStatsReqV2 extends Message<PoolStatsReqV2> {
  /**
   * TODO Yassine: to remove because we will be using the pool settings date range as filter
   *
   * @generated from field: librarian.proto.TimeRestriction time_restriction = 1;
   */
  timeRestriction?: TimeRestriction;

  /**
   * @generated from field: librarian.proto.DynamicFilter filter = 2;
   */
  filter?: DynamicFilter;

  constructor(data?: PartialMessage<PoolStatsReqV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PoolStatsReqV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time_restriction", kind: "message", T: TimeRestriction },
    { no: 2, name: "filter", kind: "message", T: DynamicFilter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoolStatsReqV2 {
    return new PoolStatsReqV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoolStatsReqV2 {
    return new PoolStatsReqV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoolStatsReqV2 {
    return new PoolStatsReqV2().fromJsonString(jsonString, options);
  }

  static equals(a: PoolStatsReqV2 | PlainMessage<PoolStatsReqV2> | undefined, b: PoolStatsReqV2 | PlainMessage<PoolStatsReqV2> | undefined): boolean {
    return proto3.util.equals(PoolStatsReqV2, a, b);
  }
}

/**
 * @generated from message librarian.proto.PoolGraphReqV2
 */
export class PoolGraphReqV2 extends Message<PoolGraphReqV2> {
  /**
   * @generated from field: librarian.proto.TimeRestriction time_restriction = 1;
   */
  timeRestriction?: TimeRestriction;

  /**
   * @generated from field: librarian.proto.DynamicFilter filter = 2;
   */
  filter?: DynamicFilter;

  /**
   * @generated from field: librarian.proto.ReportMetric metric = 3;
   */
  metric = ReportMetric.POSTS;

  /**
   * @generated from field: librarian.proto.ReportTimeStep time_step = 4;
   */
  timeStep = ReportTimeStep.REPORT_STEP_AUTO;

  constructor(data?: PartialMessage<PoolGraphReqV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PoolGraphReqV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time_restriction", kind: "message", T: TimeRestriction },
    { no: 2, name: "filter", kind: "message", T: DynamicFilter },
    { no: 3, name: "metric", kind: "enum", T: proto3.getEnumType(ReportMetric) },
    { no: 4, name: "time_step", kind: "enum", T: proto3.getEnumType(ReportTimeStep) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoolGraphReqV2 {
    return new PoolGraphReqV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoolGraphReqV2 {
    return new PoolGraphReqV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoolGraphReqV2 {
    return new PoolGraphReqV2().fromJsonString(jsonString, options);
  }

  static equals(a: PoolGraphReqV2 | PlainMessage<PoolGraphReqV2> | undefined, b: PoolGraphReqV2 | PlainMessage<PoolGraphReqV2> | undefined): boolean {
    return proto3.util.equals(PoolGraphReqV2, a, b);
  }
}

/**
 * @generated from message librarian.proto.PoolReportStats
 */
export class PoolReportStats extends Message<PoolReportStats> {
  /**
   * @generated from field: int32 active_influencers = 1;
   */
  activeInfluencers = 0;

  /**
   * @generated from field: int32 posts_count = 2;
   */
  postsCount = 0;

  /**
   * @generated from field: int64 emv = 3;
   */
  emv = protoInt64.zero;

  /**
   * @generated from field: int64 total_spend = 4;
   */
  totalSpend = protoInt64.zero;

  /**
   * It's a real ratio, front must x100 to display %. Nothing if not relevant
   *
   * @generated from field: google.protobuf.FloatValue roi = 9;
   */
  roi?: number;

  /**
   * @generated from field: int64 impressions = 6;
   */
  impressions = protoInt64.zero;

  /**
   * It's a real ratio, front must x100 to display %
   *
   * @generated from field: float engagement_rate = 7;
   */
  engagementRate = 0;

  /**
   * @generated from field: int64 engagements = 8;
   */
  engagements = protoInt64.zero;

  constructor(data?: PartialMessage<PoolReportStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PoolReportStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "active_influencers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "posts_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "emv", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "total_spend", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "roi", kind: "message", T: FloatValue },
    { no: 6, name: "impressions", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "engagement_rate", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 8, name: "engagements", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PoolReportStats {
    return new PoolReportStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PoolReportStats {
    return new PoolReportStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PoolReportStats {
    return new PoolReportStats().fromJsonString(jsonString, options);
  }

  static equals(a: PoolReportStats | PlainMessage<PoolReportStats> | undefined, b: PoolReportStats | PlainMessage<PoolReportStats> | undefined): boolean {
    return proto3.util.equals(PoolReportStats, a, b);
  }
}

/**
 *
 * Proto messages for custom fields file upload.
 *
 * @generated from message librarian.proto.UploadDirectoryFilePreview
 */
export class UploadDirectoryFilePreview extends Message<UploadDirectoryFilePreview> {
  /**
   * @generated from field: repeated librarian.proto.UploadDirectoryFilePreview.ColumnPreview columns = 1;
   */
  columns: UploadDirectoryFilePreview_ColumnPreview[] = [];

  /**
   * @generated from field: int32 lines = 2;
   */
  lines = 0;

  constructor(data?: PartialMessage<UploadDirectoryFilePreview>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadDirectoryFilePreview";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "columns", kind: "message", T: UploadDirectoryFilePreview_ColumnPreview, repeated: true },
    { no: 2, name: "lines", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadDirectoryFilePreview {
    return new UploadDirectoryFilePreview().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadDirectoryFilePreview {
    return new UploadDirectoryFilePreview().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadDirectoryFilePreview {
    return new UploadDirectoryFilePreview().fromJsonString(jsonString, options);
  }

  static equals(a: UploadDirectoryFilePreview | PlainMessage<UploadDirectoryFilePreview> | undefined, b: UploadDirectoryFilePreview | PlainMessage<UploadDirectoryFilePreview> | undefined): boolean {
    return proto3.util.equals(UploadDirectoryFilePreview, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadDirectoryFilePreview.ColumnPreview
 */
export class UploadDirectoryFilePreview_ColumnPreview extends Message<UploadDirectoryFilePreview_ColumnPreview> {
  /**
   * @generated from field: string header = 1;
   */
  header = "";

  /**
   * @generated from field: string first_value = 2;
   */
  firstValue = "";

  /**
   * @generated from field: int32 column_index = 3;
   */
  columnIndex = 0;

  /**
   * @generated from field: string column_type = 4;
   */
  columnType = "";

  constructor(data?: PartialMessage<UploadDirectoryFilePreview_ColumnPreview>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadDirectoryFilePreview.ColumnPreview";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "header", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "first_value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "column_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "column_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadDirectoryFilePreview_ColumnPreview {
    return new UploadDirectoryFilePreview_ColumnPreview().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadDirectoryFilePreview_ColumnPreview {
    return new UploadDirectoryFilePreview_ColumnPreview().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadDirectoryFilePreview_ColumnPreview {
    return new UploadDirectoryFilePreview_ColumnPreview().fromJsonString(jsonString, options);
  }

  static equals(a: UploadDirectoryFilePreview_ColumnPreview | PlainMessage<UploadDirectoryFilePreview_ColumnPreview> | undefined, b: UploadDirectoryFilePreview_ColumnPreview | PlainMessage<UploadDirectoryFilePreview_ColumnPreview> | undefined): boolean {
    return proto3.util.equals(UploadDirectoryFilePreview_ColumnPreview, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadDirectoryFile
 */
export class UploadDirectoryFile extends Message<UploadDirectoryFile> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: common.proto.FileProto file = 2;
   */
  file?: FileProto;

  constructor(data?: PartialMessage<UploadDirectoryFile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadDirectoryFile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "file", kind: "message", T: FileProto },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadDirectoryFile {
    return new UploadDirectoryFile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadDirectoryFile {
    return new UploadDirectoryFile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadDirectoryFile {
    return new UploadDirectoryFile().fromJsonString(jsonString, options);
  }

  static equals(a: UploadDirectoryFile | PlainMessage<UploadDirectoryFile> | undefined, b: UploadDirectoryFile | PlainMessage<UploadDirectoryFile> | undefined): boolean {
    return proto3.util.equals(UploadDirectoryFile, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadDirectoryFileRequest
 */
export class UploadDirectoryFileRequest extends Message<UploadDirectoryFileRequest> {
  /**
   * UserId will be filled by the backend, frontend can ignore this in the request.
   *
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: int64 upload_id = 2;
   */
  uploadId = protoInt64.zero;

  /**
   * If network is UNKNOWN then we assume the column is an url.
   *
   * @generated from field: common.proto.Network network = 3;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: int32 creator_column_index = 4;
   */
  creatorColumnIndex = 0;

  /**
   * @generated from field: repeated librarian.proto.UploadDirectoryFileRequest.FieldMapping field_mappings = 5;
   */
  fieldMappings: UploadDirectoryFileRequest_FieldMapping[] = [];

  constructor(data?: PartialMessage<UploadDirectoryFileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadDirectoryFileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "upload_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 4, name: "creator_column_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "field_mappings", kind: "message", T: UploadDirectoryFileRequest_FieldMapping, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadDirectoryFileRequest {
    return new UploadDirectoryFileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadDirectoryFileRequest {
    return new UploadDirectoryFileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadDirectoryFileRequest {
    return new UploadDirectoryFileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadDirectoryFileRequest | PlainMessage<UploadDirectoryFileRequest> | undefined, b: UploadDirectoryFileRequest | PlainMessage<UploadDirectoryFileRequest> | undefined): boolean {
    return proto3.util.equals(UploadDirectoryFileRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadDirectoryFileRequest.FieldMapping
 */
export class UploadDirectoryFileRequest_FieldMapping extends Message<UploadDirectoryFileRequest_FieldMapping> {
  /**
   * @generated from field: int32 column_index = 1;
   */
  columnIndex = 0;

  /**
   * @generated from field: int64 creator_field_id = 2;
   */
  creatorFieldId = protoInt64.zero;

  /**
   * @generated from oneof librarian.proto.UploadDirectoryFileRequest.FieldMapping.creator_field_column
   */
  creatorFieldColumn: {
    /**
     * @generated from field: librarian.proto.AddressFieldColumn address_field_column = 3;
     */
    value: AddressFieldColumn;
    case: "addressFieldColumn";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UploadDirectoryFileRequest_FieldMapping>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadDirectoryFileRequest.FieldMapping";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "column_index", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "creator_field_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "address_field_column", kind: "enum", T: proto3.getEnumType(AddressFieldColumn), oneof: "creator_field_column" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadDirectoryFileRequest_FieldMapping {
    return new UploadDirectoryFileRequest_FieldMapping().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadDirectoryFileRequest_FieldMapping {
    return new UploadDirectoryFileRequest_FieldMapping().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadDirectoryFileRequest_FieldMapping {
    return new UploadDirectoryFileRequest_FieldMapping().fromJsonString(jsonString, options);
  }

  static equals(a: UploadDirectoryFileRequest_FieldMapping | PlainMessage<UploadDirectoryFileRequest_FieldMapping> | undefined, b: UploadDirectoryFileRequest_FieldMapping | PlainMessage<UploadDirectoryFileRequest_FieldMapping> | undefined): boolean {
    return proto3.util.equals(UploadDirectoryFileRequest_FieldMapping, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadDirectoryRequest
 */
export class UploadDirectoryRequest extends Message<UploadDirectoryRequest> {
  /**
   * @generated from oneof librarian.proto.UploadDirectoryRequest.type
   */
  type: {
    /**
     * @generated from field: librarian.proto.UploadDirectoryFileRequest file = 1;
     */
    value: UploadDirectoryFileRequest;
    case: "file";
  } | {
    /**
     * @generated from field: librarian.proto.UploadPoolRequest simple = 2;
     */
    value: UploadPoolRequest;
    case: "simple";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UploadDirectoryRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadDirectoryRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "file", kind: "message", T: UploadDirectoryFileRequest, oneof: "type" },
    { no: 2, name: "simple", kind: "message", T: UploadPoolRequest, oneof: "type" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadDirectoryRequest {
    return new UploadDirectoryRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadDirectoryRequest {
    return new UploadDirectoryRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadDirectoryRequest {
    return new UploadDirectoryRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadDirectoryRequest | PlainMessage<UploadDirectoryRequest> | undefined, b: UploadDirectoryRequest | PlainMessage<UploadDirectoryRequest> | undefined): boolean {
    return proto3.util.equals(UploadDirectoryRequest, a, b);
  }
}


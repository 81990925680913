// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/products.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateProductProgrammaticallyRequest, CreateProductProgrammaticallyResponse, DeleteProductsRequest, ExportProductsRequest, GetManualStoresRequest, GetManualStoresResponse, GetProductsProgrammaticallyRequest, GetProductsProgrammaticallyResponse, GetProductsRequest, GetProductsResponse, GetQuantityByProductVariantRequest, GetQuantityByProductVariantResponse, ImportProductsExcelRequest, InitGetProductsListsResponse, ProductPb, ProductWithInfoPb, UpdateProductProgrammaticallyRequest, UpdateProductProgrammaticallyResponse, UpdateProductVariantsQuantityRequest, UpdateProductVariantsQuantityResponse, UploadProductsExcelRequest, UploadProductsExcelResponse } from "./products_pb";
import { Empty, Int64Value, MethodKind, StringValue } from "@bufbuild/protobuf";
import { Int64List } from "../../common/proto/common_pb";

/**
 * @generated from service librarian.proto.LibrarianProducts
 */
export const LibrarianProducts = {
  typeName: "librarian.proto.LibrarianProducts",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianProducts.CreateProductAPI
     */
    createProductAPI: {
      name: "CreateProductAPI",
      I: ProductPb,
      O: ProductPb,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianProducts.CreateProductProgrammaticallyAPI
     */
    createProductProgrammaticallyAPI: {
      name: "CreateProductProgrammaticallyAPI",
      I: CreateProductProgrammaticallyRequest,
      O: CreateProductProgrammaticallyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianProducts.GetManualStoresAPI
     */
    getManualStoresAPI: {
      name: "GetManualStoresAPI",
      I: GetManualStoresRequest,
      O: GetManualStoresResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianProducts.UpdateProductAPI
     */
    updateProductAPI: {
      name: "UpdateProductAPI",
      I: ProductPb,
      O: ProductPb,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianProducts.UpdateProductProgrammaticallyAPI
     */
    updateProductProgrammaticallyAPI: {
      name: "UpdateProductProgrammaticallyAPI",
      I: UpdateProductProgrammaticallyRequest,
      O: UpdateProductProgrammaticallyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianProducts.GetProduct
     */
    getProduct: {
      name: "GetProduct",
      I: Int64Value,
      O: ProductWithInfoPb,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianProducts.GetProducts
     */
    getProducts: {
      name: "GetProducts",
      I: GetProductsRequest,
      O: GetProductsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianProducts.GetProductsProgrammaticallyAPI
     */
    getProductsProgrammaticallyAPI: {
      name: "GetProductsProgrammaticallyAPI",
      I: GetProductsProgrammaticallyRequest,
      O: GetProductsProgrammaticallyResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianProducts.DeleteProducts
     */
    deleteProducts: {
      name: "DeleteProducts",
      I: DeleteProductsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianProducts.ArchiveProducts
     */
    archiveProducts: {
      name: "ArchiveProducts",
      I: Int64List,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianProducts.UnarchiveProducts
     */
    unarchiveProducts: {
      name: "UnarchiveProducts",
      I: Int64List,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * RPC method to upload the products excel file. It does some partial validation on the file and if valid, it uploads
     * the file to B2 in the `UPLOADS` bucket and returns the id of the file and preview product to show to the user.
     *
     * @generated from rpc librarian.proto.LibrarianProducts.UploadProductsExcel
     */
    uploadProductsExcel: {
      name: "UploadProductsExcel",
      I: UploadProductsExcelRequest,
      O: UploadProductsExcelResponse,
      kind: MethodKind.Unary,
    },
    /**
     * RPC method to import the products from the excel file uploaded by uploadProductsExcel call and return the task handle
     *
     * @generated from rpc librarian.proto.LibrarianProducts.ImportProductsExcel
     */
    importProductsExcel: {
      name: "ImportProductsExcel",
      I: ImportProductsExcelRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Export an excel with products, based on filter
     * Return a task handle that frontend will listen to.
     *
     * @generated from rpc librarian.proto.LibrarianProducts.ExportProducts
     */
    exportProducts: {
      name: "ExportProducts",
      I: ExportProductsRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Get product variants quantity. If the product variants belong to external ecommerce store (Shopify, Salesforce, etc.) then,
     * get the product variants on the external ecommerce store
     *
     * @generated from rpc librarian.proto.LibrarianProducts.GetQuantityByProductVariantAPI
     */
    getQuantityByProductVariantAPI: {
      name: "GetQuantityByProductVariantAPI",
      I: GetQuantityByProductVariantRequest,
      O: GetQuantityByProductVariantResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Update quantity even for external ecommerce store (Shopify, Salesforce, etc.)
     *
     * @generated from rpc librarian.proto.LibrarianProducts.UpdateProductVariantsQuantityAPI
     */
    updateProductVariantsQuantityAPI: {
      name: "UpdateProductVariantsQuantityAPI",
      I: UpdateProductVariantsQuantityRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Update quantity even for external ecommerce store (Shopify, Salesforce, etc.)
     *
     * @generated from rpc librarian.proto.LibrarianProducts.UpdateProductVariantsQuantityAPIV2
     */
    updateProductVariantsQuantityAPIV2: {
      name: "UpdateProductVariantsQuantityAPIV2",
      I: UpdateProductVariantsQuantityRequest,
      O: UpdateProductVariantsQuantityResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianProducts.InitGetProductsListsAPI
     */
    initGetProductsListsAPI: {
      name: "InitGetProductsListsAPI",
      I: Empty,
      O: InitGetProductsListsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;


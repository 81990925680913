import { Injectable } from '@angular/core';
import { CampaignKeywordType } from '@frontend2/proto/common/proto/campaign_pb';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  createTrackingKeywordsConfig,
  tiktokKeywordsField,
  tiktokMentionsField,
  tiktokTagsField,
} from './config.helpers';
import * as TKMessages from './config.messages';
import { DefaultNetworkConfig, FormField } from './config.models';

@Injectable({ providedIn: 'root' })
export class TikTokConfig extends DefaultNetworkConfig {
  override readonly network = Network.TIK_TOK;
  override readonly supportViews = true;
  override readonly supportReshares = true;
  override readonly uploadPlaceholder = [
    'celinedept',
    'https://www.tiktok.com/@tiboinshape',
    '...',
  ].join('\n');

  override readonly keywords = createTrackingKeywordsConfig({
    types: [
      CampaignKeywordType.TIKTOK_TAG,
      CampaignKeywordType.TIKTOK_MENTION,
      CampaignKeywordType.TIKTOK_KEYWORD,
    ],
    formHelpers: new Map<CampaignKeywordType, FormField>([
      [CampaignKeywordType.TIKTOK_TAG, tiktokTagsField],
      [CampaignKeywordType.TIKTOK_MENTION, tiktokMentionsField],
      [CampaignKeywordType.TIKTOK_KEYWORD, tiktokKeywordsField],
    ]),
    errorMessage: TKMessages.tiktokErrorMessage,
  });
}

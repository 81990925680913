export const _instagramErrorMessage: string = $localize`Instagram hashtags, mentions or locations are required to track posts and stories in your campaign`;
export const instagramErrorMessage: string = _instagramErrorMessage;
export const youtubeErrorMessage: string = $localize`Youtube keywords are required to track videos in your campaign`;
export const twitterErrorMessage: string = $localize`X (Twitter) hashtags or mentions are required to track tweets in your campaign`;
export const tiktokErrorMessage: string = $localize`TikTok hashtags or mentions are required to track videos in your campaign`;
export const facebookErrorMessage: string = $localize`Facebook hashtags or mentions are required to track posts in your campaign`;
export const weiboErrorMessage: string = $localize`Weibo hashtags or mentions are required to track posts in your campaign`;
export const redErrorMessage: string = $localize`Red keywords required to track posts in your campaign`;
export const keywordsFieldName: string = $localize`Keywords`;
export const keywordsFieldHelperBody: string = $localize`Posts containing these words will be automatically tracked during the campaign.`;
export const hashtagsFieldName: string = $localize`Hashtags`;
export const hashtagsFieldHelperTitle: string = $localize`Instagram hashtags`;
export const hashtagsFieldHelperBody: string = $localize`Posts containing those hashtags will be automatically tracked during the campaign.`;
export const mentionsFieldName: string = $localize`Mentions`;
export const mentionsFieldHelperTitle: string = $localize`Instagram mentions`;
export const mentionsFieldHelperBody: string = $localize`Posts mentioning those accounts will be automatically tracked during the campaign.`;
export const instaKeywordsFieldHelperTitle: string = $localize`Instagram keywords`;
export const youtubeKeywordsFieldHelperTitle: string = $localize`Youtube keywords`;
export const youtubeKeywordsFieldHelperBody: string = $localize`Youtube videos containing any of these words will be automatically tracked during the campaign.`;
export const youtubeTagsFieldHelperTitle: string = $localize`Youtube hashtags`;
export const youtubeTagsFieldHelperBody: string = $localize`Youtube videos containing those hashtags will be automatically tracked during the campaign.`;
export const twitterTagsFieldHelperTitle: string = $localize`X (Twitter) hashtag`;
export const twitterTagsFieldHelperBody: string = $localize`Tweets containing those hashtags will be automatically tracked during the campaign.`;
export const twitterMentionsFieldHelperTitle: string = $localize`X (Twitter) mentions`;
export const twitterMentionsFieldHelperBody: string = $localize`Tweets mentioning those accounts will be automatically tracked during the campaign.`;
export const tiktokTagsFieldHelperTitle: string = $localize`Tiktok hashtags`;
export const tiktokTagsFieldHelperBody: string = $localize`Tiktok videos containing those hashtags will be automatically tracked during the campaign.`;
export const tiktokMentionsFieldHelperTitle: string = $localize`Tiktok mentions`;
export const tiktokMentionsFieldHelperBody: string = $localize`Tiktok videos mentioning those accounts will be automatically tracked during the campaign.`;
export const tiktokKeywordsFieldHelperTitle: string = $localize`TikTok keywords`;
export const facebookTagsFieldHelperTitle: string = $localize`Facebook hashtags`;
export const facebookMentionsFieldHelperTitle: string = $localize`Facebook mentions`;
export const weiboTagsFieldHelperTitle: string = $localize`Weibo hashtags`;
export const weiboKeywordsFieldHelperTitle: string = $localize`Weibo keywords`;
export const weiboMentionsFieldHelperTitle: string = $localize`Weibo mentions`;
export const redKeywordsFieldHelperTitle: string = $localize`Red keywords`;
export const redKeywordsFieldHelperBody: string = $localize`Posts containing those keywords will be automatically tracked during the campaign.`;
export const redTagsFieldHelperTitle: string = $localize`Red hashtags`;
export const redTagsFieldHelperBody: string = $localize`Posts containing those hashtags will be automatically tracked during the campaign.`;
export const wechatTagsFieldHelperTitle: string = $localize`Wechat keywords`;
export const wechatTagsFieldHelperBody: string = $localize`Posts containing those keywords will be automatically tracked during the campaign.`;
export const wechatErrorMessage: string = $localize`Wechat keywords required to track posts in your campaign`;
export const douyinTagsFieldHelperTitle: string = $localize`Douyin keywords`;
export const douyinTagsFieldHelperBody: string = $localize`Posts containing those keywords will be automatically tracked during the campaign.`;
export const douyinErrorMessage: string = $localize`Douyin keywords required to track posts in your campaign`;
export const snapchatErrorMessage: string = $localize`Snapchat hashtags, mentions or keywords are required to track posts in your campaign`;
export const snapKeywordsFieldHelperTitle: string = $localize`Snapshat keywords`;
export const snapHashtagsFieldHelperTitle: string = $localize`Snapshat hashtags`;
export const snapMentionsFieldHelperTitle: string = $localize`Snapshat mentions`;

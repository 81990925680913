import { Injectable } from '@angular/core';
import { injectLibrarianCampaignsClient } from '@frontend2/api';
import {
  AutocompleteLocationReq,
  SocialNetworkLocation,
} from '@frontend2/proto/universal/retriever/proto/retriever_pb';
import { Network } from '@frontend2/proto/common/proto/common_pb';

@Injectable({ providedIn: 'root' })
export class AutocompleteLocationService {
  readonly _librarianCampaigns = injectLibrarianCampaignsClient();

  async autocompleteLocations(
    network: Network,
    searchValue: string,
  ): Promise<SocialNetworkLocation[]> {
    const response =
      await this._librarianCampaigns.autocompleteNetworkLocationAPI(
        new AutocompleteLocationReq({
          network: network,
          prefix: searchValue,
        }),
      );

    return response.locations;
  }
}

<a
  target="_blank"
  href="https://lefty.io"
  rel="noopener"
  class="logo-container"
>
  <img
    height="58"
    [appLogo]="leftyLogoSrc"
  />
</a>

<h4
  class="welcome-label"
  i18n
>
  Welcome.
</h4>

<h4
  class="page-title"
  *ngIf="hasTitle"
>
  {{ title }}
</h4>

<form
  class="login-card"
  (ngSubmit)="submit()"
  [formGroup]="formModel"
>
  <div class="card-content">
    <lefty-feedback
      type="error"
      *ngIf="hasErrorMessage()"
      [hideIcon]="true"
    >
      {{ errorMessage() }}
    </lefty-feedback>
    <lefty-form-input
      label="Email"
      i18n-label
      autoFocus
      formControlName="email"
      autocomplete="email"
      placeholder="name@company.com"
    >
    </lefty-form-input>
    <lefty-form-input
      type="password"
      autocomplete="password"
      label="Password"
      formControlName="password"
      helpText="Forgot your password?"
      placeholder="password"
      [helpLink]="helpLink"
      [externalHelpLink]="externalHelpLink"
      [tooltip]="false"
      i18n-label
      i18n-helpText
    >
    </lefty-form-input>

    <div class="actions">
      <button
        leftyButton="primary"
        size="large"
        class="action-btn"
        [disabled]="loading()"
        [loading]="loading()"
        i18n
        type="submit"
      >
        Sign In to Lefty
      </button>
      <ng-content select="[action]"></ng-content>
    </div>
  </div>
</form>
<lefty-footer-info></lefty-footer-info>

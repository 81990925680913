export interface PlaceholderIfEmptyOptions {
  readonly emptyValue: string;
}

export interface PluralOptions {
  readonly zero?: string;
  readonly one: string;
  readonly other: string;
}

export function isNil(val: unknown): val is null | undefined {
  return val === undefined || val === null;
}

export function isNotNil<T>(val: T | undefined | null): val is T {
  return isNil(val) === false;
}

export function isObject(val: unknown): val is object {
  return typeof val === 'object';
}

export function getObjectProperty<T>(
  obj: unknown,
  propertyName: string,
): T | undefined {
  if (isObject(obj) === false) {
    return undefined;
  }
  return (obj as Record<string, T>)[propertyName];
}

export function isEmptyArray<T>(arr: T[] | undefined | null): boolean {
  return Array.isArray(arr) && arr.length === 0;
}

export function isNotEmptyArray<T>(arr: T[] | undefined | null): boolean {
  return Array.isArray(arr) && arr.length > 0;
}

export function plural(
  howMany: number | bigint,
  options: PluralOptions,
): string {
  if ((howMany === 0 || howMany === BigInt(0)) && isNotNil(options.zero)) {
    return options.zero;
  }

  if (howMany === 1 || howMany === BigInt(1)) {
    return options.one;
  }

  return options.other;
}

export function normalizeLeftyId(id: string): string {
  if (id.indexOf(':') !== -1) {
    return id;
  }
  return `ig:${id}`;
}

export function joinWithAndComma(val: string[]): string {
  if (isEmptyArray(val)) {
    return '';
  }
  if (val.length === 1) {
    return val[0];
  }
  if (val.length === 2) {
    return val.join(' and ');
  }
  const newVal = [...val];
  const lastElement = newVal.pop();

  return newVal.join(', ') + ' and ' + lastElement;
}

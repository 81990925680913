import { ReportAudienceDataType } from '@frontend2/proto/librarian/proto/audience_pb';
export function ReportAudienceDataTypeToReadable(
  type: ReportAudienceDataType,
): string {
  switch (type) {
    case ReportAudienceDataType.AUDIENCE_DEFAULT:
      return '';
    case ReportAudienceDataType.AUDIENCE_LIKERS:
      return $localize`Likers`;
    case ReportAudienceDataType.AUDIENCE_FOLLOWERS:
      return $localize`Followers`;
  }
}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  inject,
  InjectionToken,
  Input,
  OnDestroy,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { isEqual, isNotEmptyString } from '@frontend2/core';
import { createOutput, ObjectFit } from '../utils';

export const DISABLE_IMAGE_DEFER_VIEWPORT = new InjectionToken<boolean>(
  'DISABLE_IMAGE_DEFER_VIEWPORT',
);

/// Image component that test the given
/// [src] url before showing the image
/// and show placeholder during test
///
/// If image src is not found, it use `fallbackUrl` as src
///
/// Can pass list of image to `urls` @Input if you have multiple image to test
@Component({
  // Keep legacy select, lefty-image (easier during migration)
  selector: 'lefty-image, safe-image',
  template: `<img
    #image
    [style.objectFit]="objectFit"
  />`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['image.scss'],
  standalone: true,
})
export class SafeImageComponent implements AfterViewInit, OnDestroy {
  readonly elementRef = inject(ElementRef<HTMLElement>);

  private readonly _loading = signal(false);

  @Output()
  readonly loading$ = toObservable(this._loading);

  private readonly _visible = signal(false);

  @Output()
  readonly visible$ = toObservable(this._visible);

  constructor() {
    // must use effect,
    // HostBinding does not support signal

    effect(() => {
      return this.elementRef.nativeElement.classList.toggle(
        'ghost',
        this._loading(),
      );
    });

    effect(() => {
      return this.elementRef.nativeElement.classList.toggle(
        'visible',
        this._visible(),
      );
    });
  }

  private timeoutId?: number;
  private _urls: string[] = [];
  private urlIndex = 0;
  private imageLoaded = false;

  @Input()
  objectFit: ObjectFit = 'cover';

  @Output()
  readonly error$ = createOutput<Event | 'timeout'>();

  @Output()
  readonly load$ = createOutput<Event>();

  @Input()
  fallbackUrl?: string;

  @Input()
  set src(url: string | undefined) {
    if (url) {
      this.urls = [url];
    } else {
      this.urls = [];
    }
  }

  @Input()
  set urls(values: string[]) {
    values = values.filter((val) => isNotEmptyString(val));
    // don't update UI if urls did not really change
    if (isEqual(this._urls, values)) {
      return;
    }

    clearTimeout(this.timeoutId);

    this._urls = values;
    this.urlIndex = 0;
    this.imageLoaded = false;

    const url = this.testUrl(this._urls, this.urlIndex);
    if (isNotEmptyString(url)) {
      this.loadUrl(url);
    } else {
      this._visible.set(false);
    }
  }

  @ViewChild('image')
  imgRef?: ElementRef<HTMLImageElement>;

  get img(): HTMLImageElement | undefined {
    return this.imgRef?.nativeElement;
  }

  private _unsubscribeLoadEvent?: () => void;
  private _unsubscribeErrorEvent?: () => void;

  private handleError(event: Event | 'timeout'): void {
    this.urlIndex++;

    const url = this.testUrl(this._urls, this.urlIndex);
    if (isNotEmptyString(url)) {
      this.loadUrl(url);
    } else {
      this._loading.set(false);
      this._visible.set(false);
      this.error$.next(event);
    }
  }

  private handleLoad(event: Event): void {
    this.imageLoaded = true;

    this._loading.set(false);
    this._visible.set(true);

    this.load$.next(event);
  }

  private loadUrl(url: string): void {
    if (this.imageLoaded || !this.img) {
      return;
    }
    this._loading.set(true);
    this._visible.set(true);

    this._unsubscribeLoadEvent?.call(this);
    this._unsubscribeErrorEvent?.call(this);

    clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(() => this.handleError('timeout'), 5000);

    if (!this.img) {
      return;
    }

    const loadCallback = (e: Event): void => {
      this.handleLoad(e);
      clearTimeout(this.timeoutId);
    };

    this.img.addEventListener('load', loadCallback);
    this._unsubscribeLoadEvent = (): void => {
      this.img?.removeEventListener('load', loadCallback);
    };

    const errorCallback = (e: Event): void => {
      this.handleError(e);
      clearTimeout(this.timeoutId);
    };

    this.img.addEventListener('error', errorCallback);
    this._unsubscribeErrorEvent = (): void => {
      this.img?.removeEventListener('error', errorCallback);
    };

    this.img.src = url;
  }

  ngAfterViewInit(): void {
    const url = this.testUrl(this._urls, this.urlIndex);
    if (url) {
      this.loadUrl(url);
    }
  }

  ngOnDestroy(): void {
    this.load$.complete();
    this.error$.complete();
    this._unsubscribeLoadEvent?.call(this);
    this._unsubscribeErrorEvent?.call(this);
    clearTimeout(this.timeoutId);
  }

  private testUrl(urls: string[], index: number): string | undefined {
    return urls.length === 0 || index >= urls.length
      ? this.fallbackUrl
      : urls[index];
  }
}

import { Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { isNotEmptyString, isNotNil } from '@frontend2/core';
import { Subscription, fromEvent } from 'rxjs';
import { injectRouter } from '../inject.helpers';

export type TooltipPlacement = 'is-top' | 'is-bottom' | 'is-left' | 'is-right';
export type TooltipStyle = 'default' | 'warning';
export type TooltipCssClass = string;
export type TooltipSize = 'medium' | 'large';

@Directive({
  selector: '[leftyTooltip]',
  standalone: true,
})
export class LeftyTooltipDirective implements OnDestroy {
  readonly router = injectRouter();

  @Input()
  hideDelay = 300;

  @Input()
  showDelay = 0;

  private hideTimeoutId?: number;
  private showTimeoutId?: number;

  @Input()
  offset = 10;

  private _mouseEnterSourceSubscription: Subscription;
  private _mouseLeaveSourceSubscription: Subscription;
  private _mouseEnterTooltipSubscription?: Subscription;
  private _mouseLeaveTooltipSubscription?: Subscription;

  constructor(protected elementRef: ElementRef<HTMLElement>) {
    this.elementRef = elementRef;

    const el = this.elementRef.nativeElement;

    this._mouseEnterSourceSubscription = fromEvent(el, 'mouseenter')
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.onMouseEnter());

    this._mouseLeaveSourceSubscription = fromEvent(el, 'mouseleave')
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.onMouseLeave());

    fromEvent(el, 'click')
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.onMouseClick());

    this.router.events
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.hide(true));
  }

  private _tooltipEl?: HTMLElement;

  get tooltipEl(): HTMLElement | undefined {
    return this._tooltipEl;
  }

  @Input('leftyTooltip') message = '';

  private _tooltipPlacement: TooltipPlacement = 'is-top';

  public get tooltipPlacement(): TooltipPlacement {
    return this._tooltipPlacement;
  }

  @Input()
  public set tooltipPlacement(value: TooltipPlacement) {
    if (this._tooltipPlacement === value) {
      return;
    }

    if (this._tooltipEl) {
      this._tooltipEl.classList.remove(this._tooltipPlacement);
      this._tooltipEl.classList.add(value);
    }

    this._tooltipPlacement = value;
  }

  @Input() tooltipStyle: TooltipStyle = 'default';

  @Input() tooltipCssClass: TooltipCssClass = '';

  @Input()
  tooltipSize: TooltipSize = 'medium';

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('showTooltipIf')
  canShow = true;

  @Input()
  tooltipLink = '';

  @Input()
  linkLabel = $localize`Learn more`;

  get hasLink(): boolean {
    return isNotEmptyString(this.tooltipLink);
  }

  // By default when mouse leave the source of tooltip
  // we hide the tooltip
  //
  // But if there is a link inside tooltip, it should
  // stay visible when the user move the mouse to the tooltip content
  private keepTooltipVisibleIfLink(tooltipEl: HTMLElement): void {
    if (this.hasLink === false) {
      return;
    }

    this._mouseEnterTooltipSubscription = fromEvent(
      tooltipEl,
      'mouseenter',
    ).subscribe(() => this.onMouseEnter());

    this._mouseLeaveTooltipSubscription = fromEvent(
      tooltipEl,
      'mouseleave',
    ).subscribe(() => this.onMouseLeave());
  }

  onMouseEnter(): void {
    this.show();
  }

  onMouseLeave(): void {
    this.hide();
  }

  onMouseClick(): void {
    this.hide();
  }

  show(): void {
    this.stopScheduledHide();
    if (!this.tooltipEl && this.canShow) {
      const showTooltip = (): void => {
        this._tooltipEl = this.create();
        this.setPosition(this._tooltipEl);
        this.elementRef.nativeElement.classList.add('tooltip-visible');
        this.keepTooltipVisibleIfLink(this._tooltipEl);
      };
      this.showTimeoutId = window.setTimeout(() => {
        showTooltip();
      }, this.showDelay);
    }
  }

  private destroy(): void {
    this._mouseEnterTooltipSubscription?.unsubscribe();
    this._mouseLeaveTooltipSubscription?.unsubscribe();

    this.tooltipEl?.remove();
    this._tooltipEl = undefined;
    this.elementRef.nativeElement.classList.remove('tooltip-visible');
  }

  private stopScheduledHide(): void {
    if (isNotNil(this.hideTimeoutId)) {
      clearTimeout(this.hideTimeoutId);
      this.hideTimeoutId = undefined;
    }
  }

  private stopScheduledShow(): void {
    if (isNotNil(this.showTimeoutId)) {
      clearTimeout(this.showTimeoutId);
      this.showTimeoutId = undefined;
    }
  }

  private scheduleHide(): void {
    this.stopScheduledHide();

    this.hideTimeoutId = window.setTimeout(() => {
      this.destroy();
    }, this.hideDelay);
  }

  hide(noDelay?: boolean): void {
    this.stopScheduledShow();

    if (!this.tooltipEl) {
      return;
    }

    // design choose to hide tooltip without delay
    // if there is no action (link) in it
    if (this.hasLink === false || noDelay) {
      this.destroy();
    } else {
      this.scheduleHide();
    }
  }

  private create(): HTMLSpanElement {
    const el = document.createElement('span');
    let content = `<span class="message">${this.message.trim()}</span>`;

    if (this.hasLink) {
      content += `<a class="underline" href="${this.tooltipLink}" target="_blank">
          ${this.linkLabel}
       </a>`;
    }

    el.innerHTML = content;
    el.className = [
      'lefty-tooltip',
      this.tooltipPlacement,
      this.tooltipStyle,
      this.tooltipSize,
      this.tooltipCssClass,
    ].join(' ');

    document.body.append(el);

    return el;
  }

  private _computeTopPosition(
    hostRect: DOMRect,
    tooltipRect: DOMRect,
  ): DOMRect {
    const top = hostRect.top - tooltipRect.height - this.offset;
    const left = hostRect.left + (hostRect.width - tooltipRect.width) / 2;

    return new DOMRect(left, top, tooltipRect.width, tooltipRect.height);
  }

  private _computeBottomPosition(
    hostRect: DOMRect,
    tooltipRect: DOMRect,
  ): DOMRect {
    const top = hostRect.bottom + this.offset;
    const left = hostRect.left + (hostRect.width - tooltipRect.width) / 2;

    return new DOMRect(left, top, tooltipRect.width, tooltipRect.height);
  }

  private _computeLeftPosition(
    hostRect: DOMRect,
    tooltipRect: DOMRect,
  ): DOMRect {
    const top = hostRect.top + (hostRect.height - tooltipRect.height) / 2;
    const left = hostRect.left - tooltipRect.width - this.offset;

    return new DOMRect(left, top, tooltipRect.width, tooltipRect.height);
  }

  private _computeRightPosition(
    hostRect: DOMRect,
    tooltipRect: DOMRect,
  ): DOMRect {
    const top = hostRect.top + (hostRect.height - tooltipRect.height) / 2;
    const left = hostRect.right + this.offset;

    return new DOMRect(left, top, tooltipRect.width, tooltipRect.height);
  }

  private _autoPositioning(
    tooltipPos: DOMRect,
    hostRect: DOMRect,
    tooltipRect: DOMRect,
    windowHeight: number,
    windowWidth: number,
  ): DOMRect {
    if (tooltipPos.top - tooltipPos.height < 0) {
      tooltipPos = this._computeBottomPosition(hostRect, tooltipRect);
    } else if (tooltipPos.top + tooltipPos.height > windowHeight) {
      tooltipPos = this._computeTopPosition(hostRect, tooltipRect);
    }

    if (tooltipPos.left - tooltipPos.width < 0) {
      tooltipPos = this._computeRightPosition(hostRect, tooltipRect);
    } else if (tooltipPos.left + tooltipPos.width > windowWidth) {
      tooltipPos = this._computeLeftPosition(hostRect, tooltipRect);
    }
    return tooltipPos;
  }

  private setPosition(el: HTMLSpanElement): void {
    el.classList.add('visible');

    const hostRect = this.elementRef.nativeElement.getBoundingClientRect();
    const tooltipRect = el.getBoundingClientRect();

    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;

    const scrollPos =
      document.documentElement.scrollTop ?? document.body.scrollTop ?? 0;

    let tooltipPos = new DOMRect(0, 0, 0, 0);

    if (this.tooltipPlacement === 'is-top') {
      tooltipPos = this._computeTopPosition(hostRect, tooltipRect);
    }

    if (this.tooltipPlacement === 'is-bottom') {
      tooltipPos = this._computeBottomPosition(hostRect, tooltipRect);
    }

    if (this.tooltipPlacement === 'is-left') {
      tooltipPos = this._computeLeftPosition(hostRect, tooltipRect);
    }

    if (this.tooltipPlacement === 'is-right') {
      tooltipPos = this._computeRightPosition(hostRect, tooltipRect);
    }

    tooltipPos = this._autoPositioning(
      tooltipPos,
      hostRect,
      tooltipRect,
      windowHeight,
      windowWidth,
    );

    el.style.top = `${tooltipPos.top + scrollPos}px`;
    el.style.left = `${tooltipPos.left}px`;
  }

  ngOnDestroy(): void {
    this._mouseEnterSourceSubscription.unsubscribe();
    this._mouseLeaveSourceSubscription.unsubscribe();
    this.destroy();
  }
}

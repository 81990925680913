// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/landing_pages.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { BoolValue, BytesValue, DoubleValue, Int32Value, Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { ProductPb, ProductVariantPb } from "./products_pb";
import { UserBrandPb } from "./user_brand_pb";
import { InfluencerRegistrationMethod } from "./campaigns_pb";
import { FlexibleCreatorSelector, LandingPageCandidatesFilter, UserSort } from "./common_pb";
import { AssetMimeType, BasicPagination, FileProto, SortDirection, StringList } from "../../common/proto/common_pb";
import { CreatorCardSnippet, GenerikInfoCard } from "./creators_pb";
import { FavoritesLabels, InfluencerCampaigns, UserStatus } from "../../common/proto/campaign_pb";
import { MetaIdPb } from "../../common/proto/creator_pb";

/**
 * @generated from enum librarian.proto.LandingAssetType
 */
export enum LandingAssetType {
  /**
   * @generated from enum value: LANDING_ASSET_UNKNOWN = 0;
   */
  LANDING_ASSET_UNKNOWN = 0,

  /**
   * @generated from enum value: LANDING_ASSET_LOGO = 1;
   */
  LANDING_ASSET_LOGO = 1,

  /**
   * @generated from enum value: LANDING_ASSET_BACKGROUND = 2;
   */
  LANDING_ASSET_BACKGROUND = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(LandingAssetType)
proto3.util.setEnumType(LandingAssetType, "librarian.proto.LandingAssetType", [
  { no: 0, name: "LANDING_ASSET_UNKNOWN" },
  { no: 1, name: "LANDING_ASSET_LOGO" },
  { no: 2, name: "LANDING_ASSET_BACKGROUND" },
]);

/**
 * @generated from message librarian.proto.LandingPageProductInfoPb
 */
export class LandingPageProductInfoPb extends Message<LandingPageProductInfoPb> {
  /**
   * @generated from field: int64 product_id = 1;
   */
  productId = protoInt64.zero;

  /**
   * @generated from field: string product_name = 2;
   */
  productName = "";

  /**
   * @generated from field: string asset_url = 3;
   */
  assetUrl = "";

  /**
   * @generated from field: librarian.proto.ProductVariantPb product_variants = 4;
   */
  productVariants?: ProductVariantPb;

  constructor(data?: PartialMessage<LandingPageProductInfoPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LandingPageProductInfoPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "product_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "product_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "asset_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "product_variants", kind: "message", T: ProductVariantPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LandingPageProductInfoPb {
    return new LandingPageProductInfoPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LandingPageProductInfoPb {
    return new LandingPageProductInfoPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LandingPageProductInfoPb {
    return new LandingPageProductInfoPb().fromJsonString(jsonString, options);
  }

  static equals(a: LandingPageProductInfoPb | PlainMessage<LandingPageProductInfoPb> | undefined, b: LandingPageProductInfoPb | PlainMessage<LandingPageProductInfoPb> | undefined): boolean {
    return proto3.util.equals(LandingPageProductInfoPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.LandingPageProductSeedingSettingsPb
 */
export class LandingPageProductSeedingSettingsPb extends Message<LandingPageProductSeedingSettingsPb> {
  /**
   * @generated from field: google.protobuf.DoubleValue price_limit = 1;
   */
  priceLimit?: number;

  /**
   * @generated from field: google.protobuf.Int64Value ecommerce_store_id = 2;
   */
  ecommerceStoreId?: bigint;

  /**
   * @generated from field: google.protobuf.Int32Value product_limit = 3;
   */
  productLimit?: number;

  /**
   * @generated from field: bool specific_products_in_store = 4;
   */
  specificProductsInStore = false;

  /**
   * @generated from field: repeated librarian.proto.LandingPageProductInfoPb landing_page_products = 5;
   */
  landingPageProducts: LandingPageProductInfoPb[] = [];

  constructor(data?: PartialMessage<LandingPageProductSeedingSettingsPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LandingPageProductSeedingSettingsPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "price_limit", kind: "message", T: DoubleValue },
    { no: 2, name: "ecommerce_store_id", kind: "message", T: Int64Value },
    { no: 3, name: "product_limit", kind: "message", T: Int32Value },
    { no: 4, name: "specific_products_in_store", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "landing_page_products", kind: "message", T: LandingPageProductInfoPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LandingPageProductSeedingSettingsPb {
    return new LandingPageProductSeedingSettingsPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LandingPageProductSeedingSettingsPb {
    return new LandingPageProductSeedingSettingsPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LandingPageProductSeedingSettingsPb {
    return new LandingPageProductSeedingSettingsPb().fromJsonString(jsonString, options);
  }

  static equals(a: LandingPageProductSeedingSettingsPb | PlainMessage<LandingPageProductSeedingSettingsPb> | undefined, b: LandingPageProductSeedingSettingsPb | PlainMessage<LandingPageProductSeedingSettingsPb> | undefined): boolean {
    return proto3.util.equals(LandingPageProductSeedingSettingsPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProductsPbList
 */
export class ProductsPbList extends Message<ProductsPbList> {
  /**
   * @generated from field: repeated librarian.proto.ProductPb products = 1;
   */
  products: ProductPb[] = [];

  constructor(data?: PartialMessage<ProductsPbList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProductsPbList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products", kind: "message", T: ProductPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProductsPbList {
    return new ProductsPbList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProductsPbList {
    return new ProductsPbList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProductsPbList {
    return new ProductsPbList().fromJsonString(jsonString, options);
  }

  static equals(a: ProductsPbList | PlainMessage<ProductsPbList> | undefined, b: ProductsPbList | PlainMessage<ProductsPbList> | undefined): boolean {
    return proto3.util.equals(ProductsPbList, a, b);
  }
}

/**
 * @generated from message librarian.proto.LandingPagePb
 */
export class LandingPagePb extends Message<LandingPagePb> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string encrypted_id = 2;
   */
  encryptedId = "";

  /**
   * @generated from field: string private_name = 3;
   */
  privateName = "";

  /**
   * @generated from field: string public_name = 4;
   */
  publicName = "";

  /**
   * TODO(mateo) Remove when full migration has been done
   *
   * @generated from field: string brand_name = 5;
   */
  brandName = "";

  /**
   * @generated from field: librarian.proto.UserBrandPb brand = 19;
   */
  brand?: UserBrandPb;

  /**
   * @generated from field: librarian.proto.LandingPageProductSeedingSettingsPb landingPageProductSeedingSettings = 21;
   */
  landingPageProductSeedingSettings?: LandingPageProductSeedingSettingsPb;

  /**
   * @generated from field: string description = 6;
   */
  description = "";

  /**
   * @generated from field: int64 background_color = 7;
   */
  backgroundColor = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.InfluencerRegistrationMethod registration_method = 8;
   */
  registrationMethod = InfluencerRegistrationMethod.NONE;

  /**
   * @generated from field: bool require_instagram_insights = 10;
   */
  requireInstagramInsights = false;

  /**
   * @generated from field: bool require_payment_method = 17;
   */
  requirePaymentMethod = false;

  /**
   * @generated from field: bool require_product_seeding = 20;
   */
  requireProductSeeding = false;

  /**
   * @generated from field: repeated librarian.proto.AssetUrl urls = 9;
   */
  urls: AssetUrl[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp created = 11;
   */
  created?: Timestamp;

  /**
   * @generated from field: int32 pending_candidates_count = 12;
   */
  pendingCandidatesCount = 0;

  /**
   * @generated from field: int32 accepted_candidates_count = 13;
   */
  acceptedCandidatesCount = 0;

  /**
   * @generated from field: int32 rejected_candidates_count = 14;
   */
  rejectedCandidatesCount = 0;

  /**
   * number of visits on landing page
   *
   * @generated from field: int64 page_views = 15;
   */
  pageViews = protoInt64.zero;

  /**
   * @generated from field: bool archived = 16;
   */
  archived = false;

  constructor(data?: PartialMessage<LandingPagePb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LandingPagePb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "encrypted_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "private_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "public_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "brand_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "brand", kind: "message", T: UserBrandPb },
    { no: 21, name: "landingPageProductSeedingSettings", kind: "message", T: LandingPageProductSeedingSettingsPb },
    { no: 6, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "background_color", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "registration_method", kind: "enum", T: proto3.getEnumType(InfluencerRegistrationMethod) },
    { no: 10, name: "require_instagram_insights", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "require_payment_method", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "require_product_seeding", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "urls", kind: "message", T: AssetUrl, repeated: true },
    { no: 11, name: "created", kind: "message", T: Timestamp },
    { no: 12, name: "pending_candidates_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "accepted_candidates_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 14, name: "rejected_candidates_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "page_views", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 16, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LandingPagePb {
    return new LandingPagePb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LandingPagePb {
    return new LandingPagePb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LandingPagePb {
    return new LandingPagePb().fromJsonString(jsonString, options);
  }

  static equals(a: LandingPagePb | PlainMessage<LandingPagePb> | undefined, b: LandingPagePb | PlainMessage<LandingPagePb> | undefined): boolean {
    return proto3.util.equals(LandingPagePb, a, b);
  }
}

/**
 * @generated from message librarian.proto.AssetUrl
 */
export class AssetUrl extends Message<AssetUrl> {
  /**
   * @generated from field: librarian.proto.LandingAssetType asset_type = 1;
   */
  assetType = LandingAssetType.LANDING_ASSET_UNKNOWN;

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  constructor(data?: PartialMessage<AssetUrl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AssetUrl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "asset_type", kind: "enum", T: proto3.getEnumType(LandingAssetType) },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssetUrl {
    return new AssetUrl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssetUrl {
    return new AssetUrl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssetUrl {
    return new AssetUrl().fromJsonString(jsonString, options);
  }

  static equals(a: AssetUrl | PlainMessage<AssetUrl> | undefined, b: AssetUrl | PlainMessage<AssetUrl> | undefined): boolean {
    return proto3.util.equals(AssetUrl, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateLandingPageRequest
 */
export class CreateLandingPageRequest extends Message<CreateLandingPageRequest> {
  /**
   * @generated from field: string private_name = 1;
   */
  privateName = "";

  /**
   * @generated from field: string public_name = 2;
   */
  publicName = "";

  /**
   * TODO(mateo) remove when front has been migrated
   *
   * @generated from field: string brand_name = 3;
   */
  brandName = "";

  /**
   * @generated from field: google.protobuf.Int64Value brand_id = 8;
   */
  brandId?: bigint;

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: int64 background_color = 5;
   */
  backgroundColor = protoInt64.zero;

  /**
   * TODO(mateo) remove when front has been migrated
   *
   * @generated from field: librarian.proto.InfluencerRegistrationMethod registration_method = 6;
   */
  registrationMethod = InfluencerRegistrationMethod.NONE;

  /**
   * @generated from field: repeated int64 asset_ids = 7;
   */
  assetIds: bigint[] = [];

  /**
   * @generated from field: google.protobuf.Int64Value background_asset_id = 10;
   */
  backgroundAssetId?: bigint;

  /**
   * Registration requirements
   *
   * @generated from field: bool require_instagram_insights = 100;
   */
  requireInstagramInsights = false;

  /**
   * @generated from field: bool require_payment_method = 101;
   */
  requirePaymentMethod = false;

  /**
   * @generated from field: bool require_product_seeding = 102;
   */
  requireProductSeeding = false;

  /**
   * @generated from field: librarian.proto.LandingPageProductSeedingSettingsPb product_seeding_settings = 103;
   */
  productSeedingSettings?: LandingPageProductSeedingSettingsPb;

  constructor(data?: PartialMessage<CreateLandingPageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateLandingPageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "private_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "public_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "brand_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "brand_id", kind: "message", T: Int64Value },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "background_color", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "registration_method", kind: "enum", T: proto3.getEnumType(InfluencerRegistrationMethod) },
    { no: 7, name: "asset_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 10, name: "background_asset_id", kind: "message", T: Int64Value },
    { no: 100, name: "require_instagram_insights", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 101, name: "require_payment_method", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 102, name: "require_product_seeding", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 103, name: "product_seeding_settings", kind: "message", T: LandingPageProductSeedingSettingsPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateLandingPageRequest {
    return new CreateLandingPageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateLandingPageRequest {
    return new CreateLandingPageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateLandingPageRequest {
    return new CreateLandingPageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateLandingPageRequest | PlainMessage<CreateLandingPageRequest> | undefined, b: CreateLandingPageRequest | PlainMessage<CreateLandingPageRequest> | undefined): boolean {
    return proto3.util.equals(CreateLandingPageRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditLandingPageRequest
 */
export class EditLandingPageRequest extends Message<EditLandingPageRequest> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.StringValue private_name = 2;
   */
  privateName?: string;

  /**
   * @generated from field: google.protobuf.StringValue public_name = 3;
   */
  publicName?: string;

  /**
   * TODO(mateo) remove when front has been migrated
   *
   * @generated from field: google.protobuf.StringValue brand_name = 4;
   */
  brandName?: string;

  /**
   * @generated from field: google.protobuf.Int64Value brand_id = 8;
   */
  brandId?: bigint;

  /**
   * @generated from field: google.protobuf.StringValue description = 5;
   */
  description?: string;

  /**
   * @generated from field: google.protobuf.Int64Value background_color = 6;
   */
  backgroundColor?: bigint;

  /**
   * TODO(mateo) remove when front has been migrated
   *
   * @generated from field: librarian.proto.InfluencerRegistrationMethod registration_method = 7;
   */
  registrationMethod = InfluencerRegistrationMethod.NONE;

  /**
   * @generated from field: google.protobuf.Int64Value background_asset_id = 10;
   */
  backgroundAssetId?: bigint;

  /**
   * Registration requirements
   *
   * @generated from field: google.protobuf.BoolValue require_instagram_insights = 100;
   */
  requireInstagramInsights?: boolean;

  /**
   * @generated from field: google.protobuf.BoolValue require_payment_method = 101;
   */
  requirePaymentMethod?: boolean;

  /**
   * @generated from field: google.protobuf.BoolValue require_product_seeding = 102;
   */
  requireProductSeeding?: boolean;

  /**
   * @generated from field: librarian.proto.LandingPageProductSeedingSettingsPb product_seeding_settings = 103;
   */
  productSeedingSettings?: LandingPageProductSeedingSettingsPb;

  constructor(data?: PartialMessage<EditLandingPageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditLandingPageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "private_name", kind: "message", T: StringValue },
    { no: 3, name: "public_name", kind: "message", T: StringValue },
    { no: 4, name: "brand_name", kind: "message", T: StringValue },
    { no: 8, name: "brand_id", kind: "message", T: Int64Value },
    { no: 5, name: "description", kind: "message", T: StringValue },
    { no: 6, name: "background_color", kind: "message", T: Int64Value },
    { no: 7, name: "registration_method", kind: "enum", T: proto3.getEnumType(InfluencerRegistrationMethod) },
    { no: 10, name: "background_asset_id", kind: "message", T: Int64Value },
    { no: 100, name: "require_instagram_insights", kind: "message", T: BoolValue },
    { no: 101, name: "require_payment_method", kind: "message", T: BoolValue },
    { no: 102, name: "require_product_seeding", kind: "message", T: BoolValue },
    { no: 103, name: "product_seeding_settings", kind: "message", T: LandingPageProductSeedingSettingsPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditLandingPageRequest {
    return new EditLandingPageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditLandingPageRequest {
    return new EditLandingPageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditLandingPageRequest {
    return new EditLandingPageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditLandingPageRequest | PlainMessage<EditLandingPageRequest> | undefined, b: EditLandingPageRequest | PlainMessage<EditLandingPageRequest> | undefined): boolean {
    return proto3.util.equals(EditLandingPageRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.LandingPagesResponse
 */
export class LandingPagesResponse extends Message<LandingPagesResponse> {
  /**
   * @generated from field: repeated librarian.proto.LandingPagePb pages = 1;
   */
  pages: LandingPagePb[] = [];

  constructor(data?: PartialMessage<LandingPagesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LandingPagesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pages", kind: "message", T: LandingPagePb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LandingPagesResponse {
    return new LandingPagesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LandingPagesResponse {
    return new LandingPagesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LandingPagesResponse {
    return new LandingPagesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LandingPagesResponse | PlainMessage<LandingPagesResponse> | undefined, b: LandingPagesResponse | PlainMessage<LandingPagesResponse> | undefined): boolean {
    return proto3.util.equals(LandingPagesResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.LandingPageCandidatesRequest
 */
export class LandingPageCandidatesRequest extends Message<LandingPageCandidatesRequest> {
  /**
   * @generated from field: int64 landing_page_id = 1;
   */
  landingPageId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.UserSort sort = 2;
   */
  sort = UserSort.DEFAULT;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 5;
   */
  sortDirection = SortDirection.DESC;

  /**
   * @generated from field: librarian.proto.LandingPageCandidatesFilter filter = 3;
   */
  filter?: LandingPageCandidatesFilter;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<LandingPageCandidatesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LandingPageCandidatesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "landing_page_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "sort", kind: "enum", T: proto3.getEnumType(UserSort) },
    { no: 5, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 3, name: "filter", kind: "message", T: LandingPageCandidatesFilter },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LandingPageCandidatesRequest {
    return new LandingPageCandidatesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LandingPageCandidatesRequest {
    return new LandingPageCandidatesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LandingPageCandidatesRequest {
    return new LandingPageCandidatesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LandingPageCandidatesRequest | PlainMessage<LandingPageCandidatesRequest> | undefined, b: LandingPageCandidatesRequest | PlainMessage<LandingPageCandidatesRequest> | undefined): boolean {
    return proto3.util.equals(LandingPageCandidatesRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CandidateCard
 */
export class CandidateCard extends Message<CandidateCard> {
  /**
   * @generated from field: librarian.proto.CreatorCardSnippet base_snippet = 1;
   */
  baseSnippet?: CreatorCardSnippet;

  /**
   * @generated from field: repeated librarian.proto.GenerikInfoCard network_info = 2;
   */
  networkInfo: GenerikInfoCard[] = [];

  /**
   * @generated from field: common.proto.UserStatus user_status = 3;
   */
  userStatus = UserStatus.USER_PENDING;

  /**
   * @generated from field: common.proto.InfluencerCampaigns campaigns = 4;
   */
  campaigns?: InfluencerCampaigns;

  /**
   * @generated from field: common.proto.FavoritesLabels labels = 5;
   */
  labels?: FavoritesLabels;

  /**
   * @generated from field: google.protobuf.Timestamp registration_date = 6;
   */
  registrationDate?: Timestamp;

  constructor(data?: PartialMessage<CandidateCard>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CandidateCard";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_snippet", kind: "message", T: CreatorCardSnippet },
    { no: 2, name: "network_info", kind: "message", T: GenerikInfoCard, repeated: true },
    { no: 3, name: "user_status", kind: "enum", T: proto3.getEnumType(UserStatus) },
    { no: 4, name: "campaigns", kind: "message", T: InfluencerCampaigns },
    { no: 5, name: "labels", kind: "message", T: FavoritesLabels },
    { no: 6, name: "registration_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CandidateCard {
    return new CandidateCard().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CandidateCard {
    return new CandidateCard().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CandidateCard {
    return new CandidateCard().fromJsonString(jsonString, options);
  }

  static equals(a: CandidateCard | PlainMessage<CandidateCard> | undefined, b: CandidateCard | PlainMessage<CandidateCard> | undefined): boolean {
    return proto3.util.equals(CandidateCard, a, b);
  }
}

/**
 * @generated from message librarian.proto.LandingPageCandidatesResponse
 */
export class LandingPageCandidatesResponse extends Message<LandingPageCandidatesResponse> {
  /**
   * @generated from field: repeated librarian.proto.CandidateCard candidates = 1;
   */
  candidates: CandidateCard[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<LandingPageCandidatesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LandingPageCandidatesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "candidates", kind: "message", T: CandidateCard, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LandingPageCandidatesResponse {
    return new LandingPageCandidatesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LandingPageCandidatesResponse {
    return new LandingPageCandidatesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LandingPageCandidatesResponse {
    return new LandingPageCandidatesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LandingPageCandidatesResponse | PlainMessage<LandingPageCandidatesResponse> | undefined, b: LandingPageCandidatesResponse | PlainMessage<LandingPageCandidatesResponse> | undefined): boolean {
    return proto3.util.equals(LandingPageCandidatesResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.LandingPageCandidatesSelector
 */
export class LandingPageCandidatesSelector extends Message<LandingPageCandidatesSelector> {
  /**
   * @generated from oneof librarian.proto.LandingPageCandidatesSelector.influencers
   */
  influencers: {
    /**
     * Use lefty_id, ig:.., yt:...
     *
     * @generated from field: common.proto.StringList manual_ids = 1;
     */
    value: StringList;
    case: "manualIds";
  } | {
    /**
     * @generated from field: librarian.proto.LandingPageCandidatesFilter filter = 2;
     */
    value: LandingPageCandidatesFilter;
    case: "filter";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<LandingPageCandidatesSelector>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LandingPageCandidatesSelector";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "manual_ids", kind: "message", T: StringList, oneof: "influencers" },
    { no: 2, name: "filter", kind: "message", T: LandingPageCandidatesFilter, oneof: "influencers" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LandingPageCandidatesSelector {
    return new LandingPageCandidatesSelector().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LandingPageCandidatesSelector {
    return new LandingPageCandidatesSelector().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LandingPageCandidatesSelector {
    return new LandingPageCandidatesSelector().fromJsonString(jsonString, options);
  }

  static equals(a: LandingPageCandidatesSelector | PlainMessage<LandingPageCandidatesSelector> | undefined, b: LandingPageCandidatesSelector | PlainMessage<LandingPageCandidatesSelector> | undefined): boolean {
    return proto3.util.equals(LandingPageCandidatesSelector, a, b);
  }
}

/**
 * @generated from message librarian.proto.CandidatesStatusRequest
 */
export class CandidatesStatusRequest extends Message<CandidatesStatusRequest> {
  /**
   * @generated from field: int64 landing_page_id = 1;
   */
  landingPageId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.LandingPageCandidatesSelector selector = 2 [deprecated = true];
   * @deprecated
   */
  selector?: LandingPageCandidatesSelector;

  /**
   * @generated from field: librarian.proto.FlexibleCreatorSelector creator_selector = 3;
   */
  creatorSelector?: FlexibleCreatorSelector;

  constructor(data?: PartialMessage<CandidatesStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CandidatesStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "landing_page_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "selector", kind: "message", T: LandingPageCandidatesSelector },
    { no: 3, name: "creator_selector", kind: "message", T: FlexibleCreatorSelector },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CandidatesStatusRequest {
    return new CandidatesStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CandidatesStatusRequest {
    return new CandidatesStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CandidatesStatusRequest {
    return new CandidatesStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CandidatesStatusRequest | PlainMessage<CandidatesStatusRequest> | undefined, b: CandidatesStatusRequest | PlainMessage<CandidatesStatusRequest> | undefined): boolean {
    return proto3.util.equals(CandidatesStatusRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateLandingAssetRequest
 */
export class UpdateLandingAssetRequest extends Message<UpdateLandingAssetRequest> {
  /**
   * User making the request (this is not an HTTP endpoint so we can't use the cookie).
   * This should be the team owner.
   *
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * this can be not set in that case we only create the asset ...
   *
   * @generated from field: int64 landing_page_id = 2;
   */
  landingPageId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.LandingAssetType type = 3;
   */
  type = LandingAssetType.LANDING_ASSET_UNKNOWN;

  /**
   * @generated from field: common.proto.FileProto file = 4;
   */
  file?: FileProto;

  constructor(data?: PartialMessage<UpdateLandingAssetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateLandingAssetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "landing_page_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(LandingAssetType) },
    { no: 4, name: "file", kind: "message", T: FileProto },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateLandingAssetRequest {
    return new UpdateLandingAssetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateLandingAssetRequest {
    return new UpdateLandingAssetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateLandingAssetRequest {
    return new UpdateLandingAssetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateLandingAssetRequest | PlainMessage<UpdateLandingAssetRequest> | undefined, b: UpdateLandingAssetRequest | PlainMessage<UpdateLandingAssetRequest> | undefined): boolean {
    return proto3.util.equals(UpdateLandingAssetRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.DeleteLandingPageAssetRequest
 */
export class DeleteLandingPageAssetRequest extends Message<DeleteLandingPageAssetRequest> {
  /**
   * @generated from field: int64 landing_page_id = 1;
   */
  landingPageId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.LandingAssetType type = 2;
   */
  type = LandingAssetType.LANDING_ASSET_UNKNOWN;

  constructor(data?: PartialMessage<DeleteLandingPageAssetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DeleteLandingPageAssetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "landing_page_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(LandingAssetType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteLandingPageAssetRequest {
    return new DeleteLandingPageAssetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteLandingPageAssetRequest {
    return new DeleteLandingPageAssetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteLandingPageAssetRequest {
    return new DeleteLandingPageAssetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteLandingPageAssetRequest | PlainMessage<DeleteLandingPageAssetRequest> | undefined, b: DeleteLandingPageAssetRequest | PlainMessage<DeleteLandingPageAssetRequest> | undefined): boolean {
    return proto3.util.equals(DeleteLandingPageAssetRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetLandingAssetRequest
 */
export class GetLandingAssetRequest extends Message<GetLandingAssetRequest> {
  /**
   * This should be the team owner.
   *
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: int64 landing_page_id = 2;
   */
  landingPageId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.LandingAssetType type = 3;
   */
  type = LandingAssetType.LANDING_ASSET_UNKNOWN;

  constructor(data?: PartialMessage<GetLandingAssetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetLandingAssetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "landing_page_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(LandingAssetType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLandingAssetRequest {
    return new GetLandingAssetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLandingAssetRequest {
    return new GetLandingAssetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLandingAssetRequest {
    return new GetLandingAssetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetLandingAssetRequest | PlainMessage<GetLandingAssetRequest> | undefined, b: GetLandingAssetRequest | PlainMessage<GetLandingAssetRequest> | undefined): boolean {
    return proto3.util.equals(GetLandingAssetRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetLandingAssetResponse
 */
export class GetLandingAssetResponse extends Message<GetLandingAssetResponse> {
  /**
   * @generated from field: common.proto.AssetMimeType mime_type = 1;
   */
  mimeType = AssetMimeType.ASSET_UNKNOWN;

  /**
   * @generated from field: google.protobuf.BytesValue bytes = 2;
   */
  bytes?: Uint8Array;

  constructor(data?: PartialMessage<GetLandingAssetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetLandingAssetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mime_type", kind: "enum", T: proto3.getEnumType(AssetMimeType) },
    { no: 2, name: "bytes", kind: "message", T: BytesValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLandingAssetResponse {
    return new GetLandingAssetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLandingAssetResponse {
    return new GetLandingAssetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLandingAssetResponse {
    return new GetLandingAssetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetLandingAssetResponse | PlainMessage<GetLandingAssetResponse> | undefined, b: GetLandingAssetResponse | PlainMessage<GetLandingAssetResponse> | undefined): boolean {
    return proto3.util.equals(GetLandingAssetResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.MergeLinkedAuthorNetwork
 */
export class MergeLinkedAuthorNetwork extends Message<MergeLinkedAuthorNetwork> {
  /**
   * @generated from field: common.proto.MetaIdPb source_id = 1;
   */
  sourceId?: MetaIdPb;

  /**
   * @generated from field: common.proto.MetaIdPb dest_id = 2;
   */
  destId?: MetaIdPb;

  constructor(data?: PartialMessage<MergeLinkedAuthorNetwork>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.MergeLinkedAuthorNetwork";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "source_id", kind: "message", T: MetaIdPb },
    { no: 2, name: "dest_id", kind: "message", T: MetaIdPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MergeLinkedAuthorNetwork {
    return new MergeLinkedAuthorNetwork().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MergeLinkedAuthorNetwork {
    return new MergeLinkedAuthorNetwork().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MergeLinkedAuthorNetwork {
    return new MergeLinkedAuthorNetwork().fromJsonString(jsonString, options);
  }

  static equals(a: MergeLinkedAuthorNetwork | PlainMessage<MergeLinkedAuthorNetwork> | undefined, b: MergeLinkedAuthorNetwork | PlainMessage<MergeLinkedAuthorNetwork> | undefined): boolean {
    return proto3.util.equals(MergeLinkedAuthorNetwork, a, b);
  }
}


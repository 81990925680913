import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  signal,
} from '@angular/core';
import { DropdownBase } from '../dropdown';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { NumberRange } from '../lefty-range-input/lefty-range-input.models';
import {
  areFollowersEqual,
  readableNetwork,
  readablePlaceholder,
} from './followers-range-dropdown.helpers';
import { createOutput } from '../utils';
import { isNil } from '@frontend2/core';
import { FormsModule } from '@angular/forms';
import { LeftyRangeInputComponent } from '../lefty-range-input/lefty-range-input.component';
import { NetworkIconComponent } from '../icon/network.component';
import { LeftyButtonDirective } from '../lefty-button-directive/lefty-button.directive';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';
import { LeftyIconComponent } from '../icon/icon.component';
import { NgClass, NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'followers-range-dropdown',
  templateUrl: './followers-range-dropdown.component.html',
  styleUrls: ['./followers-range-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    LeftyIconComponent,
    LeftyPopupComponent,
    NgIf,
    LeftyButtonDirective,
    NgFor,
    NetworkIconComponent,
    LeftyRangeInputComponent,
    FormsModule,
  ],
})
export class FollowersRangeDropdownComponent extends DropdownBase {
  readonly _defaultPlaceholder = $localize`Followers`;

  readonly hasValue = signal(false);

  private _followers = new Map<Network, NumberRange>();

  @Input() buttonClass = '';

  @Input() networks: Network[] = [];

  @Output()
  readonly followersChange = createOutput<Map<Network, NumberRange>>();

  get placeholder(): string {
    if (this.hasValue() && !this.isEmpty) {
      return readablePlaceholder(this.followers);
    }
    return this._defaultPlaceholder;
  }

  get isEmpty(): boolean {
    return [...this.followers.values()].every(
      (range) => range.start === 0 || (isNil(range.start) && isNil(range.end)),
    );
  }

  readableNetwork(network: Network): string {
    return readableNetwork(network).toLowerCase();
  }

  @Input()
  set followers(values: Map<Network, NumberRange>) {
    const newFollower = new Map<Network, NumberRange>(
      this.networks.map((n) => [
        n,
        { ...(values.get(n) ?? ({} as NumberRange)) },
      ]),
    );

    if (!areFollowersEqual(newFollower, this._followers)) {
      this._followers = newFollower;
      this.hasValue.set([...newFollower.values()].some((r) => r.start !== 0));
      this.followersChange.next(newFollower);
    }
  }

  get followers(): Map<Network, NumberRange> {
    return this._followers;
  }

  rangeChange(network: Network, range: NumberRange): void {
    this.followers.set(network, range);
  }

  clearAll(): void {
    const newFollower = new Map<Network, NumberRange>(
      this.networks.map((n) => [n, {} as NumberRange]),
    );
    this.hasValue.set(false);
    this.followers = newFollower;
  }
}

import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianCWAdmin } from '@frontend2/proto/librarian/proto/admin_cw_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianCWAdminClient = PromiseClient<typeof LibrarianCWAdmin>;

export const LibrarianCWAdminClientProvider = new InjectionToken(
  'LibrarianCWAdminClient',
  {
    factory: (): LibrarianCWAdminClient =>
      createPromiseClient(LibrarianCWAdmin, inject(GRPC_TRANSPORT)),
  },
);

export function injectLibrarianCWAdminClient(): LibrarianCWAdminClient {
  return inject(LibrarianCWAdminClientProvider);
}

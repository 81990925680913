import {
  PaymentMethodPb,
  PaymentStatusResponse,
  SubscriptionType,
} from '@frontend2/proto/librarian/proto/payments_pb';
import { isEmptyString } from '../../utils/strings.helpers';

export type PaymentInfo = PaymentStatusResponse;

const _ghost = new PaymentStatusResponse();
export function createGhostPaymentInfo(): PaymentInfo {
  return _ghost;
}

export function isGhostPaymentInfo(item: PaymentInfo): boolean {
  return isEmptyString(item.stripeId);
}

export function isGhostPaymentMethod(item: PaymentMethodPb): boolean {
  return isEmptyString(item.id);
}

export function subscriptionToReadable(sub: SubscriptionType): string {
  switch (sub) {
    case SubscriptionType.SUBSCRIPTION_TYPE_UNKNOWN:
      return 'All Subscriptions';
    case SubscriptionType.FREE:
      return 'Free';
    case SubscriptionType.TRIAL:
      return 'Trial';
    case SubscriptionType.STARTER:
      return 'Starter';
    case SubscriptionType.STANDARD:
      return 'Standard';
    case SubscriptionType.PRO:
      return 'Pro';
    case SubscriptionType.ENTERPRISE:
      return 'Enterprise';
    case SubscriptionType.PREMIUM:
      return 'Premium';
    case SubscriptionType.INACTIVE:
      return 'Inactive';
    default:
      return 'Unknown';
  }
}

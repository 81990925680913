import { Injectable } from '@angular/core';
import { CampaignKeywordType } from '@frontend2/proto/common/proto/campaign_pb';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  createTrackingKeywordsConfig,
  wechatTagsField,
} from './config.helpers';
import * as TKMessages from './config.messages';
import { DefaultNetworkConfig, FormField } from './config.models';

@Injectable({ providedIn: 'root' })
export class WechatConfig extends DefaultNetworkConfig {
  override readonly network = Network.WECHAT;
  override readonly supportFollowers = false;
  override readonly uploadPlaceholder: string = [
    // TODO(hadrien): find example
    '...',
  ].join('\n');

  override readonly keywords = createTrackingKeywordsConfig({
    types: [CampaignKeywordType.WECHAT_KEYWORD],
    formHelpers: new Map<CampaignKeywordType, FormField>([
      [CampaignKeywordType.WECHAT_KEYWORD, wechatTagsField],
    ]),
    errorMessage: TKMessages.wechatErrorMessage,
  });
}

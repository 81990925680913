import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { Numbers } from '@frontend2/core';

@Component({
  selector: 'lefty-progress',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div
      class="progress-container fallback"
      role="progressbar"
      [class.indeterminate]="indeterminate"
    >
      <div
        #secondary
        class="secondary-progress"
        [style.transform]="secondaryTransform"
      ></div>
      <div
        #primary
        class="active-progress"
        [style.transform]="primaryTransform"
      ></div>
    </div>
  `,
  standalone: true,
})
export class LeftyProgressComponent {
  @HostBinding('class')
  readonly className = 'lefty-progress';

  /// The current progress value.
  @Input()
  activeProgress = 0;

  /// The "secondary" progress. Shows up behind the primary progress in a
  /// lighter color.
  @Input()
  secondaryProgress = 0;

  /// The minimum progress value.
  ///
  /// Defaults to 0.
  @Input()
  min = 0;

  /// The maximum progress value.
  ///
  /// Defaults to 100.
  @Input()
  max = 100;

  /// Boolean whether the progress bar is deterministic.
  ///
  /// Defaults to `false`.
  @Input()
  indeterminate = false;

  get primaryTransform(): string {
    return `scaleX(${this.calcRatio(this.activeProgress)})`;
  }

  get secondaryTransform(): string {
    return `scaleX(${this.calcRatio(this.secondaryProgress)})`;
  }

  private calcRatio(value: number): number {
    return Numbers.clamp(value, this.min, this.max) / (this.max - this.min);
  }
}

@Component({
  selector: 'lefty-spinner',
  template: `
    <div class="spinner">
      <div class="circle left"></div>
      <div class="circle right"></div>
      <div class="circle gap"></div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeftySpinnerComponent {
  @HostBinding('class')
  readonly className = 'lefty-spinner';
}

@if (isGhostCreatorAutocompleteMatch()) {
  <span class="search">{{ Messages.search.toLowerCase() }} :</span
  ><span class="search-username">{{ creator().userName }}</span>
} @else {
  <profile-picture [creator]="creator()"> </profile-picture>
  <div class="text-container fill-space">
    <div class="username">{{ creator().userName }}</div>
    <div class="followers">{{ followers() }}</div>
  </div>
  <div class="networks">
    @for (network of networks(); track $index) {
      <network-icon [network]="network"></network-icon>
    }
  </div>
}

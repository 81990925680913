// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/upload_influencer.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { CreatorCardSnippet } from "./creators_pb";
import { StringList } from "../../common/proto/common_pb";
import { NightwatchCtx } from "../../common/proto/nightwatch_pb";
import { UploadInfluencersCampaignRequest } from "./campaigns_pb";
import { UploadDirectoryFileRequest, UploadPoolRequest, UploadWithInfosRequest } from "./pool_pb";
import { UploadInfluencersCastingRequest } from "./casting_pb";
import { UploadAffiliatesRequest } from "./affiliation_pb";
import { UploadExcludedInfluencersToReportRequest, UploadExcludedInfluencersToReportsOfNetworkRequest } from "./admin_cw_pb";
import { UploadInfluencersListTaskRequest } from "./users_pb";

/**
 * @generated from enum librarian.proto.UploadInfluencerResultCode
 */
export enum UploadInfluencerResultCode {
  /**
   * @generated from enum value: UPLOAD_INFLUENCER_RESULT_UNKNOWN = 0;
   */
  UPLOAD_INFLUENCER_RESULT_UNKNOWN = 0,

  /**
   * @generated from enum value: UPLOAD_INFLUENCER_OK = 1;
   */
  UPLOAD_INFLUENCER_OK = 1,

  /**
   * @generated from enum value: UPLOAD_INFLUENCER_ERROR_INTERNAL = 2;
   */
  UPLOAD_INFLUENCER_ERROR_INTERNAL = 2,

  /**
   * @generated from enum value: UPLOAD_INFLUENCER_ERROR_NOT_FOUND = 3;
   */
  UPLOAD_INFLUENCER_ERROR_NOT_FOUND = 3,

  /**
   * @generated from enum value: UPLOAD_INFLUENCER_ERROR_RESTRICTED = 4;
   */
  UPLOAD_INFLUENCER_ERROR_RESTRICTED = 4,

  /**
   * @generated from enum value: UPLOAD_INFLUENCER_ALREADY_ADDED = 5;
   */
  UPLOAD_INFLUENCER_ALREADY_ADDED = 5,

  /**
   * @generated from enum value: UPLOAD_INFLUENCER_BAD_FIELD_FORMAT = 6;
   */
  UPLOAD_INFLUENCER_BAD_FIELD_FORMAT = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(UploadInfluencerResultCode)
proto3.util.setEnumType(UploadInfluencerResultCode, "librarian.proto.UploadInfluencerResultCode", [
  { no: 0, name: "UPLOAD_INFLUENCER_RESULT_UNKNOWN" },
  { no: 1, name: "UPLOAD_INFLUENCER_OK" },
  { no: 2, name: "UPLOAD_INFLUENCER_ERROR_INTERNAL" },
  { no: 3, name: "UPLOAD_INFLUENCER_ERROR_NOT_FOUND" },
  { no: 4, name: "UPLOAD_INFLUENCER_ERROR_RESTRICTED" },
  { no: 5, name: "UPLOAD_INFLUENCER_ALREADY_ADDED" },
  { no: 6, name: "UPLOAD_INFLUENCER_BAD_FIELD_FORMAT" },
]);

/**
 * @generated from message librarian.proto.UploadInfluencersResult
 */
export class UploadInfluencersResult extends Message<UploadInfluencersResult> {
  /**
   * @generated from field: repeated librarian.proto.UploadInfluencersResult.UploadInfluencerResult results = 1;
   */
  results: UploadInfluencersResult_UploadInfluencerResult[] = [];

  constructor(data?: PartialMessage<UploadInfluencersResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadInfluencersResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "results", kind: "message", T: UploadInfluencersResult_UploadInfluencerResult, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadInfluencersResult {
    return new UploadInfluencersResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadInfluencersResult {
    return new UploadInfluencersResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadInfluencersResult {
    return new UploadInfluencersResult().fromJsonString(jsonString, options);
  }

  static equals(a: UploadInfluencersResult | PlainMessage<UploadInfluencersResult> | undefined, b: UploadInfluencersResult | PlainMessage<UploadInfluencersResult> | undefined): boolean {
    return proto3.util.equals(UploadInfluencersResult, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadInfluencersResult.UploadInfluencerResult
 */
export class UploadInfluencersResult_UploadInfluencerResult extends Message<UploadInfluencersResult_UploadInfluencerResult> {
  /**
   * handle
   *
   * @generated from field: string input = 1;
   */
  input = "";

  /**
   * GRPC status code
   *
   * @generated from field: int32 status_code = 2;
   */
  statusCode = 0;

  /**
   * A code that frontend can use for translation.
   *
   * @generated from field: librarian.proto.UploadInfluencerResultCode status_description = 3;
   */
  statusDescription = UploadInfluencerResultCode.UPLOAD_INFLUENCER_RESULT_UNKNOWN;

  /**
   * @generated from field: librarian.proto.CreatorCardSnippet creator_card = 5;
   */
  creatorCard?: CreatorCardSnippet;

  /**
   * @generated from oneof librarian.proto.UploadInfluencersResult.UploadInfluencerResult.details
   */
  details: {
    /**
     * @generated from field: common.proto.StringList bad_format_details = 4;
     */
    value: StringList;
    case: "badFormatDetails";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UploadInfluencersResult_UploadInfluencerResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadInfluencersResult.UploadInfluencerResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "input", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "status_code", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "status_description", kind: "enum", T: proto3.getEnumType(UploadInfluencerResultCode) },
    { no: 5, name: "creator_card", kind: "message", T: CreatorCardSnippet },
    { no: 4, name: "bad_format_details", kind: "message", T: StringList, oneof: "details" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadInfluencersResult_UploadInfluencerResult {
    return new UploadInfluencersResult_UploadInfluencerResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadInfluencersResult_UploadInfluencerResult {
    return new UploadInfluencersResult_UploadInfluencerResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadInfluencersResult_UploadInfluencerResult {
    return new UploadInfluencersResult_UploadInfluencerResult().fromJsonString(jsonString, options);
  }

  static equals(a: UploadInfluencersResult_UploadInfluencerResult | PlainMessage<UploadInfluencersResult_UploadInfluencerResult> | undefined, b: UploadInfluencersResult_UploadInfluencerResult | PlainMessage<UploadInfluencersResult_UploadInfluencerResult> | undefined): boolean {
    return proto3.util.equals(UploadInfluencersResult_UploadInfluencerResult, a, b);
  }
}

/**
 * @generated from message librarian.proto.LibrarianUploadInfluencersRequest
 */
export class LibrarianUploadInfluencersRequest extends Message<LibrarianUploadInfluencersRequest> {
  /**
   * When this message is built, it is assumed to have been already validated that this user is the owner of the campaign/pool/casting below.
   * This field is only carried for the purpose of nightwatch logging.
   *
   * @generated from field: common.proto.NightwatchCtx nightwatch_ctx = 1;
   */
  nightwatchCtx?: NightwatchCtx;

  /**
   * The actual user that started the upload. This user will receive a recap email at the end.
   *
   * @generated from field: int64 user_id = 100;
   */
  userId = protoInt64.zero;

  /**
   * @generated from oneof librarian.proto.LibrarianUploadInfluencersRequest.selector
   */
  selector: {
    /**
     * @generated from field: librarian.proto.UploadInfluencersCampaignRequest campaigns_req = 2;
     */
    value: UploadInfluencersCampaignRequest;
    case: "campaignsReq";
  } | {
    /**
     * @generated from field: librarian.proto.UploadPoolRequest pool_req = 3;
     */
    value: UploadPoolRequest;
    case: "poolReq";
  } | {
    /**
     * @generated from field: librarian.proto.UploadInfluencersCastingRequest casting_req = 4;
     */
    value: UploadInfluencersCastingRequest;
    case: "castingReq";
  } | {
    /**
     * @generated from field: librarian.proto.UploadDirectoryFileRequest directory_file_req = 5;
     */
    value: UploadDirectoryFileRequest;
    case: "directoryFileReq";
  } | {
    /**
     * @generated from field: librarian.proto.UploadAffiliatesRequest aff_req = 6;
     */
    value: UploadAffiliatesRequest;
    case: "affReq";
  } | {
    /**
     * @generated from field: librarian.proto.UploadWithInfosRequest infos_req = 7;
     */
    value: UploadWithInfosRequest;
    case: "infosReq";
  } | {
    /**
     * @generated from field: librarian.proto.UploadExcludedInfluencersToReportRequest excluded_from_report_req = 8;
     */
    value: UploadExcludedInfluencersToReportRequest;
    case: "excludedFromReportReq";
  } | {
    /**
     * @generated from field: librarian.proto.UploadExcludedInfluencersToReportsOfNetworkRequest excluded_from_reports_of_network = 9;
     */
    value: UploadExcludedInfluencersToReportsOfNetworkRequest;
    case: "excludedFromReportsOfNetwork";
  } | {
    /**
     * @generated from field: librarian.proto.UploadInfluencersListTaskRequest upload_influencers_list_req = 10;
     */
    value: UploadInfluencersListTaskRequest;
    case: "uploadInfluencersListReq";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<LibrarianUploadInfluencersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LibrarianUploadInfluencersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "nightwatch_ctx", kind: "message", T: NightwatchCtx },
    { no: 100, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "campaigns_req", kind: "message", T: UploadInfluencersCampaignRequest, oneof: "selector" },
    { no: 3, name: "pool_req", kind: "message", T: UploadPoolRequest, oneof: "selector" },
    { no: 4, name: "casting_req", kind: "message", T: UploadInfluencersCastingRequest, oneof: "selector" },
    { no: 5, name: "directory_file_req", kind: "message", T: UploadDirectoryFileRequest, oneof: "selector" },
    { no: 6, name: "aff_req", kind: "message", T: UploadAffiliatesRequest, oneof: "selector" },
    { no: 7, name: "infos_req", kind: "message", T: UploadWithInfosRequest, oneof: "selector" },
    { no: 8, name: "excluded_from_report_req", kind: "message", T: UploadExcludedInfluencersToReportRequest, oneof: "selector" },
    { no: 9, name: "excluded_from_reports_of_network", kind: "message", T: UploadExcludedInfluencersToReportsOfNetworkRequest, oneof: "selector" },
    { no: 10, name: "upload_influencers_list_req", kind: "message", T: UploadInfluencersListTaskRequest, oneof: "selector" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LibrarianUploadInfluencersRequest {
    return new LibrarianUploadInfluencersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LibrarianUploadInfluencersRequest {
    return new LibrarianUploadInfluencersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LibrarianUploadInfluencersRequest {
    return new LibrarianUploadInfluencersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LibrarianUploadInfluencersRequest | PlainMessage<LibrarianUploadInfluencersRequest> | undefined, b: LibrarianUploadInfluencersRequest | PlainMessage<LibrarianUploadInfluencersRequest> | undefined): boolean {
    return proto3.util.equals(LibrarianUploadInfluencersRequest, a, b);
  }
}


import { Injectable, inject } from '@angular/core';
import { isNil } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { DefaultNetworkConfig, NetworkConfig } from './config.models';
import { DouyinConfig } from './douyin.config';
import { InstagramConfig } from './instagram.config';
import { RedConfig } from './red.config';
import { SnapchatConfig } from './snapchat.config';
import { TikTokConfig } from './tiktok.config';
import { TwitterConfig } from './twitter.config';
import { WechatConfig } from './wechat.config';
import { WeiboConfig } from './weibo.config';
import { YoutubeConfig } from './youtube.config';

// Generik access to NetworkConfig
// ```
// constructor(readonly networkConfig: NetworkConfigs) {}
//
// getConfig(network: Network) : NetworkConfig {
//   return this.networkConfig.of(network);
// }
// ```
@Injectable({
  providedIn: 'root',
})
export class NetworkConfigs {
  private readonly _configs = new Map<Network, NetworkConfig>([
    [Network.NETWORK_UNKNOWN, inject(DefaultNetworkConfig)],
    [Network.INSTA, inject(InstagramConfig)],
    [Network.YOUTUBE, inject(YoutubeConfig)],
    [Network.TWEET, inject(TwitterConfig)],
    [Network.TIK_TOK, inject(TikTokConfig)],
    [Network.DOUYIN, inject(DouyinConfig)],
    [Network.WECHAT, inject(WechatConfig)],
    [Network.WEIBO, inject(WeiboConfig)],
    [Network.RED, inject(RedConfig)],
    [Network.SNAPCHAT, inject(SnapchatConfig)],
  ]);

  of(network: Network): NetworkConfig {
    const cfg = this._configs.get(network);

    if (isNil(cfg)) {
      throw `Network config not found for Network ${network}`;
    }

    return cfg;
  }
}

// This file defines protocol buffers for the snapchat stuff that gets persisted.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/snapchat.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ComputedGenerikStats, PostType } from "./common_pb";
import { PostInteractions } from "./campaign_pb";
import { GenerikMediaFacts } from "./media_pb";

/**
 * @generated from enum common.proto.SnapchatSubcategory
 */
export enum SnapchatSubcategory {
  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_ACTOR = 1;
   */
  ACTOR = 1,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_APPAREL_FASHION = 2;
   */
  APPAREL_FASHION = 2,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_ARTIST = 3;
   */
  ARTIST = 3,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_ATHLETE = 4;
   */
  ATHLETE = 4,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_BRAND = 5;
   */
  BRAND = 5,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_BUSINESS = 6;
   */
  BUSINESS = 6,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_CHARITY_CAUSE = 7;
   */
  CHARITY_CAUSE = 7,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_COACH = 8;
   */
  COACH = 8,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_COLLEGE_UNIVERSITY = 9;
   */
  COLLEGE_UNIVERSITY = 9,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_COMEDIAN = 10;
   */
  COMEDIAN = 10,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_COMMUNITY_ORG = 11;
   */
  COMMUNITY_ORG = 11,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_COMPANY_ENTERPRISE = 12;
   */
  COMPANY_ENTERPRISE = 12,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_CREATOR = 13;
   */
  CREATOR = 13,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_DANCER = 14;
   */
  DANCER = 14,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_ENTREPRENEUR = 15;
   */
  ENTREPRENEUR = 15,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_FASHION_MODEL = 16;
   */
  FASHION_MODEL = 16,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_FILM_DIRECTOR = 17;
   */
  FILM_DIRECTOR = 17,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_FILM_TV = 18;
   */
  FILM_TV = 18,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_FILM_TV_PERSONALITY = 19;
   */
  FILM_TV_PERSONALITY = 19,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_FITNESS_PRO = 20;
   */
  FITNESS_PRO = 20,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_FOOD_PRO = 21;
   */
  FOOD_PRO = 21,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_GOVERNMENT_OFFICIAL = 22;
   */
  GOVERNMENT_OFFICIAL = 22,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_GOVERNMENT_ORG = 23;
   */
  GOVERNMENT_ORG = 23,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_GROUP = 24;
   */
  GROUP = 24,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_HEALTH_BEAUTY = 25;
   */
  HEALTH_BEAUTY = 25,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_INTERNET_COMPANY = 26;
   */
  INTERNET_COMPANY = 26,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_JOURNALIST = 27;
   */
  JOURNALIST = 27,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_LENS_CREATOR = 28;
   */
  LENS_CREATOR = 28,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_LIFESTYLE_INFLUENCER = 29;
   */
  LIFESTYLE_INFLUENCER = 29,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_MEDIA_NEWS_COMPANY = 30;
   */
  MEDIA_NEWS_COMPANY = 30,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_MOTIVATIONAL_SPEAKER = 31;
   */
  MOTIVATIONAL_SPEAKER = 31,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_MOVIE_CHARACTER = 32;
   */
  MOVIE_CHARACTER = 32,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_MUSICIAN_BAND = 33;
   */
  MUSICIAN_BAND = 33,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_MUSIC_FESTIVAL = 34;
   */
  MUSIC_FESTIVAL = 34,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_NEWS_PERSONALITY = 35;
   */
  NEWS_PERSONALITY = 35,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_NONPROFIT_ORG = 36;
   */
  NONPROFIT_ORG = 36,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_PERSON = 37;
   */
  PERSON = 37,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_PHOTOGRAPHER = 38;
   */
  PHOTOGRAPHER = 38,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_POLITICAL_CANDIDATE = 39;
   */
  POLITICAL_CANDIDATE = 39,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_POLITICAL_ORG = 40;
   */
  POLITICAL_ORG = 40,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_POLITICIAN = 41;
   */
  POLITICIAN = 41,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_PRODUCER = 42;
   */
  PRODUCER = 42,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_PRODUCT = 43;
   */
  PRODUCT = 43,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_PROFESSIONAL_GAMER = 44;
   */
  PROFESSIONAL_GAMER = 44,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_PUBLIC_FIGURE = 45;
   */
  PUBLIC_FIGURE = 45,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_PUBLISHER = 46;
   */
  PUBLISHER = 46,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_RECORD_LABEL = 47;
   */
  RECORD_LABEL = 47,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_RELIGIOUS_ORG = 48;
   */
  RELIGIOUS_ORG = 48,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_RESTAURANT_CHAIN = 49;
   */
  RESTAURANT_CHAIN = 49,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_RETAIL_COMPANY = 50;
   */
  RETAIL_COMPANY = 50,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_SCIENTIST = 51;
   */
  SCIENTIST = 51,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_SERVICE = 52;
   */
  SERVICE = 52,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_SHOW = 53;
   */
  SHOW = 53,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_SPORTS_LEAGUE = 54;
   */
  SPORTS_LEAGUE = 54,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_SPORTS_PERSONALITY = 55;
   */
  SPORTS_PERSONALITY = 55,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_SPORTS_TEAM = 56;
   */
  SPORTS_TEAM = 56,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_TRAVELER = 57;
   */
  TRAVELER = 57,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_VIDEO_GAME = 58;
   */
  VIDEO_GAME = 58,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_VIDEO_GAME_CREATOR = 59;
   */
  VIDEO_GAME_CREATOR = 59,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_VLOGGER_BLOGGER = 60;
   */
  VLOGGER_BLOGGER = 60,

  /**
   * @generated from enum value: SNAPCHAT_SUBCATEGORY_WRITER = 61;
   */
  WRITER = 61,
}
// Retrieve enum metadata with: proto3.getEnumType(SnapchatSubcategory)
proto3.util.setEnumType(SnapchatSubcategory, "common.proto.SnapchatSubcategory", [
  { no: 0, name: "SNAPCHAT_SUBCATEGORY_UNKNOWN" },
  { no: 1, name: "SNAPCHAT_SUBCATEGORY_ACTOR" },
  { no: 2, name: "SNAPCHAT_SUBCATEGORY_APPAREL_FASHION" },
  { no: 3, name: "SNAPCHAT_SUBCATEGORY_ARTIST" },
  { no: 4, name: "SNAPCHAT_SUBCATEGORY_ATHLETE" },
  { no: 5, name: "SNAPCHAT_SUBCATEGORY_BRAND" },
  { no: 6, name: "SNAPCHAT_SUBCATEGORY_BUSINESS" },
  { no: 7, name: "SNAPCHAT_SUBCATEGORY_CHARITY_CAUSE" },
  { no: 8, name: "SNAPCHAT_SUBCATEGORY_COACH" },
  { no: 9, name: "SNAPCHAT_SUBCATEGORY_COLLEGE_UNIVERSITY" },
  { no: 10, name: "SNAPCHAT_SUBCATEGORY_COMEDIAN" },
  { no: 11, name: "SNAPCHAT_SUBCATEGORY_COMMUNITY_ORG" },
  { no: 12, name: "SNAPCHAT_SUBCATEGORY_COMPANY_ENTERPRISE" },
  { no: 13, name: "SNAPCHAT_SUBCATEGORY_CREATOR" },
  { no: 14, name: "SNAPCHAT_SUBCATEGORY_DANCER" },
  { no: 15, name: "SNAPCHAT_SUBCATEGORY_ENTREPRENEUR" },
  { no: 16, name: "SNAPCHAT_SUBCATEGORY_FASHION_MODEL" },
  { no: 17, name: "SNAPCHAT_SUBCATEGORY_FILM_DIRECTOR" },
  { no: 18, name: "SNAPCHAT_SUBCATEGORY_FILM_TV" },
  { no: 19, name: "SNAPCHAT_SUBCATEGORY_FILM_TV_PERSONALITY" },
  { no: 20, name: "SNAPCHAT_SUBCATEGORY_FITNESS_PRO" },
  { no: 21, name: "SNAPCHAT_SUBCATEGORY_FOOD_PRO" },
  { no: 22, name: "SNAPCHAT_SUBCATEGORY_GOVERNMENT_OFFICIAL" },
  { no: 23, name: "SNAPCHAT_SUBCATEGORY_GOVERNMENT_ORG" },
  { no: 24, name: "SNAPCHAT_SUBCATEGORY_GROUP" },
  { no: 25, name: "SNAPCHAT_SUBCATEGORY_HEALTH_BEAUTY" },
  { no: 26, name: "SNAPCHAT_SUBCATEGORY_INTERNET_COMPANY" },
  { no: 27, name: "SNAPCHAT_SUBCATEGORY_JOURNALIST" },
  { no: 28, name: "SNAPCHAT_SUBCATEGORY_LENS_CREATOR" },
  { no: 29, name: "SNAPCHAT_SUBCATEGORY_LIFESTYLE_INFLUENCER" },
  { no: 30, name: "SNAPCHAT_SUBCATEGORY_MEDIA_NEWS_COMPANY" },
  { no: 31, name: "SNAPCHAT_SUBCATEGORY_MOTIVATIONAL_SPEAKER" },
  { no: 32, name: "SNAPCHAT_SUBCATEGORY_MOVIE_CHARACTER" },
  { no: 33, name: "SNAPCHAT_SUBCATEGORY_MUSICIAN_BAND" },
  { no: 34, name: "SNAPCHAT_SUBCATEGORY_MUSIC_FESTIVAL" },
  { no: 35, name: "SNAPCHAT_SUBCATEGORY_NEWS_PERSONALITY" },
  { no: 36, name: "SNAPCHAT_SUBCATEGORY_NONPROFIT_ORG" },
  { no: 37, name: "SNAPCHAT_SUBCATEGORY_PERSON" },
  { no: 38, name: "SNAPCHAT_SUBCATEGORY_PHOTOGRAPHER" },
  { no: 39, name: "SNAPCHAT_SUBCATEGORY_POLITICAL_CANDIDATE" },
  { no: 40, name: "SNAPCHAT_SUBCATEGORY_POLITICAL_ORG" },
  { no: 41, name: "SNAPCHAT_SUBCATEGORY_POLITICIAN" },
  { no: 42, name: "SNAPCHAT_SUBCATEGORY_PRODUCER" },
  { no: 43, name: "SNAPCHAT_SUBCATEGORY_PRODUCT" },
  { no: 44, name: "SNAPCHAT_SUBCATEGORY_PROFESSIONAL_GAMER" },
  { no: 45, name: "SNAPCHAT_SUBCATEGORY_PUBLIC_FIGURE" },
  { no: 46, name: "SNAPCHAT_SUBCATEGORY_PUBLISHER" },
  { no: 47, name: "SNAPCHAT_SUBCATEGORY_RECORD_LABEL" },
  { no: 48, name: "SNAPCHAT_SUBCATEGORY_RELIGIOUS_ORG" },
  { no: 49, name: "SNAPCHAT_SUBCATEGORY_RESTAURANT_CHAIN" },
  { no: 50, name: "SNAPCHAT_SUBCATEGORY_RETAIL_COMPANY" },
  { no: 51, name: "SNAPCHAT_SUBCATEGORY_SCIENTIST" },
  { no: 52, name: "SNAPCHAT_SUBCATEGORY_SERVICE" },
  { no: 53, name: "SNAPCHAT_SUBCATEGORY_SHOW" },
  { no: 54, name: "SNAPCHAT_SUBCATEGORY_SPORTS_LEAGUE" },
  { no: 55, name: "SNAPCHAT_SUBCATEGORY_SPORTS_PERSONALITY" },
  { no: 56, name: "SNAPCHAT_SUBCATEGORY_SPORTS_TEAM" },
  { no: 57, name: "SNAPCHAT_SUBCATEGORY_TRAVELER" },
  { no: 58, name: "SNAPCHAT_SUBCATEGORY_VIDEO_GAME" },
  { no: 59, name: "SNAPCHAT_SUBCATEGORY_VIDEO_GAME_CREATOR" },
  { no: 60, name: "SNAPCHAT_SUBCATEGORY_VLOGGER_BLOGGER" },
  { no: 61, name: "SNAPCHAT_SUBCATEGORY_WRITER" },
]);

/**
 * @generated from message common.proto.SnapchatCreator
 */
export class SnapchatCreator extends Message<SnapchatCreator> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Can be changed any time, non unique
   *
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * Can be changed only once a year, unique.
   *
   * @generated from field: string snap_user_name = 3;
   */
  snapUserName = "";

  /**
   * @generated from field: common.proto.SnapchatCreator.LogoUrls logo_urls = 4;
   */
  logoUrls?: SnapchatCreator_LogoUrls;

  /**
   * @generated from field: int64 subscriber_count = 5;
   */
  subscriberCount = protoInt64.zero;

  /**
   * @generated from field: string organization_id = 6;
   */
  organizationId = "";

  /**
   * @generated from field: string profile_type = 7;
   */
  profileType = "";

  /**
   * @generated from field: string profile_tier = 8;
   */
  profileTier = "";

  /**
   * @generated from field: string description = 9;
   */
  description = "";

  /**
   * @generated from field: string category = 10;
   */
  category = "";

  /**
   * @generated from field: string subcategory = 11;
   */
  subcategory = "";

  /**
   * @generated from field: string email = 12;
   */
  email = "";

  /**
   * @generated from field: string phone_number = 13;
   */
  phoneNumber = "";

  /**
   * @generated from field: string website = 14;
   */
  website = "";

  /**
   * @generated from field: string address_line_1 = 15;
   */
  addressLine1 = "";

  /**
   * @generated from field: string address_line_2 = 16;
   */
  addressLine2 = "";

  /**
   * @generated from field: string address_line_3 = 17;
   */
  addressLine3 = "";

  /**
   * @generated from field: string locality = 18;
   */
  locality = "";

  /**
   * @generated from field: string administrative_district_level_1 = 19;
   */
  administrativeDistrictLevel1 = "";

  /**
   * @generated from field: string administrative_district_level_2 = 20;
   */
  administrativeDistrictLevel2 = "";

  /**
   * @generated from field: string postal_code = 21;
   */
  postalCode = "";

  /**
   * @generated from field: string country = 22;
   */
  country = "";

  /**
   * @generated from field: string internal_profile_category = 23;
   */
  internalProfileCategory = "";

  /**
   * @generated from field: bool share_authorized_data_with_api_partners = 24;
   */
  shareAuthorizedDataWithApiPartners = false;

  /**
   * @generated from field: google.protobuf.Timestamp last_polled_story_creation_time = 25;
   */
  lastPolledStoryCreationTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp last_polled_saved_story_creation_time = 26;
   */
  lastPolledSavedStoryCreationTime?: Timestamp;

  /**
   * @generated from field: common.proto.ComputedGenerikStats computed_stats = 100;
   */
  computedStats?: ComputedGenerikStats;

  /**
   * @generated from field: common.proto.SnapchatCreatorStatistics creator_statistics = 101;
   */
  creatorStatistics?: SnapchatCreatorStatistics;

  constructor(data?: PartialMessage<SnapchatCreator>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.SnapchatCreator";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "snap_user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "logo_urls", kind: "message", T: SnapchatCreator_LogoUrls },
    { no: 5, name: "subscriber_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "organization_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "profile_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "profile_tier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "subcategory", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "website", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "address_line_1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "address_line_2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "address_line_3", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 18, name: "locality", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 19, name: "administrative_district_level_1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "administrative_district_level_2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 21, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 23, name: "internal_profile_category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 24, name: "share_authorized_data_with_api_partners", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "last_polled_story_creation_time", kind: "message", T: Timestamp },
    { no: 26, name: "last_polled_saved_story_creation_time", kind: "message", T: Timestamp },
    { no: 100, name: "computed_stats", kind: "message", T: ComputedGenerikStats },
    { no: 101, name: "creator_statistics", kind: "message", T: SnapchatCreatorStatistics },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SnapchatCreator {
    return new SnapchatCreator().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SnapchatCreator {
    return new SnapchatCreator().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SnapchatCreator {
    return new SnapchatCreator().fromJsonString(jsonString, options);
  }

  static equals(a: SnapchatCreator | PlainMessage<SnapchatCreator> | undefined, b: SnapchatCreator | PlainMessage<SnapchatCreator> | undefined): boolean {
    return proto3.util.equals(SnapchatCreator, a, b);
  }
}

/**
 * @generated from message common.proto.SnapchatCreator.LogoUrls
 */
export class SnapchatCreator_LogoUrls extends Message<SnapchatCreator_LogoUrls> {
  /**
   * @generated from field: string original_logo_url = 1;
   */
  originalLogoUrl = "";

  /**
   * @generated from field: string discover_feed_logo_url = 2;
   */
  discoverFeedLogoUrl = "";

  /**
   * @generated from field: string mega_profile_logo_url = 3;
   */
  megaProfileLogoUrl = "";

  /**
   * @generated from field: string manage_profile_logo_url = 4;
   */
  manageProfileLogoUrl = "";

  constructor(data?: PartialMessage<SnapchatCreator_LogoUrls>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.SnapchatCreator.LogoUrls";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "original_logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "discover_feed_logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "mega_profile_logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "manage_profile_logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SnapchatCreator_LogoUrls {
    return new SnapchatCreator_LogoUrls().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SnapchatCreator_LogoUrls {
    return new SnapchatCreator_LogoUrls().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SnapchatCreator_LogoUrls {
    return new SnapchatCreator_LogoUrls().fromJsonString(jsonString, options);
  }

  static equals(a: SnapchatCreator_LogoUrls | PlainMessage<SnapchatCreator_LogoUrls> | undefined, b: SnapchatCreator_LogoUrls | PlainMessage<SnapchatCreator_LogoUrls> | undefined): boolean {
    return proto3.util.equals(SnapchatCreator_LogoUrls, a, b);
  }
}

/**
 * @generated from message common.proto.SnapchatCreatorStatistics
 */
export class SnapchatCreatorStatistics extends Message<SnapchatCreatorStatistics> {
  /**
   * @generated from field: int64 views_count = 1;
   */
  viewsCount = protoInt64.zero;

  constructor(data?: PartialMessage<SnapchatCreatorStatistics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.SnapchatCreatorStatistics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "views_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SnapchatCreatorStatistics {
    return new SnapchatCreatorStatistics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SnapchatCreatorStatistics {
    return new SnapchatCreatorStatistics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SnapchatCreatorStatistics {
    return new SnapchatCreatorStatistics().fromJsonString(jsonString, options);
  }

  static equals(a: SnapchatCreatorStatistics | PlainMessage<SnapchatCreatorStatistics> | undefined, b: SnapchatCreatorStatistics | PlainMessage<SnapchatCreatorStatistics> | undefined): boolean {
    return proto3.util.equals(SnapchatCreatorStatistics, a, b);
  }
}

/**
 * A snap is a story asset (a story can contain multiple images and videos with different captions)
 *
 * @generated from message common.proto.SnapchatSnap
 */
export class SnapchatSnap extends Message<SnapchatSnap> {
  /**
   * @generated from field: string snap_id = 1;
   */
  snapId = "";

  /**
   * @generated from field: common.proto.SnapchatSnap.MediaType media_type = 2;
   */
  mediaType = SnapchatSnap_MediaType.IMAGE;

  /**
   * @generated from field: string media_url = 3;
   */
  mediaUrl = "";

  /**
   * @generated from field: string thumbnail_url = 4;
   */
  thumbnailUrl = "";

  /**
   * @generated from field: google.protobuf.Timestamp creation_time = 5;
   */
  creationTime?: Timestamp;

  /**
   * @generated from field: string caption = 6;
   */
  caption = "";

  constructor(data?: PartialMessage<SnapchatSnap>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.SnapchatSnap";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "snap_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "media_type", kind: "enum", T: proto3.getEnumType(SnapchatSnap_MediaType) },
    { no: 3, name: "media_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "thumbnail_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "creation_time", kind: "message", T: Timestamp },
    { no: 6, name: "caption", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SnapchatSnap {
    return new SnapchatSnap().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SnapchatSnap {
    return new SnapchatSnap().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SnapchatSnap {
    return new SnapchatSnap().fromJsonString(jsonString, options);
  }

  static equals(a: SnapchatSnap | PlainMessage<SnapchatSnap> | undefined, b: SnapchatSnap | PlainMessage<SnapchatSnap> | undefined): boolean {
    return proto3.util.equals(SnapchatSnap, a, b);
  }
}

/**
 * @generated from enum common.proto.SnapchatSnap.MediaType
 */
export enum SnapchatSnap_MediaType {
  /**
   * @generated from enum value: IMAGE = 0;
   */
  IMAGE = 0,

  /**
   * @generated from enum value: VIDEO = 1;
   */
  VIDEO = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(SnapchatSnap_MediaType)
proto3.util.setEnumType(SnapchatSnap_MediaType, "common.proto.SnapchatSnap.MediaType", [
  { no: 0, name: "IMAGE" },
  { no: 1, name: "VIDEO" },
]);

/**
 * @generated from message common.proto.SnapchatMediaMetadata
 */
export class SnapchatMediaMetadata extends Message<SnapchatMediaMetadata> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string creator_id = 2;
   */
  creatorId = "";

  /**
   * @generated from field: common.proto.PostType post_type = 3;
   */
  postType = PostType.POST_TYPE_UNDEFINED;

  /**
   * @generated from field: google.protobuf.Timestamp creation_time = 4;
   */
  creationTime?: Timestamp;

  /**
   * For stories
   *
   * @generated from field: google.protobuf.Timestamp ended_at = 5;
   */
  endedAt?: Timestamp;

  /**
   * @generated from field: string thumbnail_url = 6;
   */
  thumbnailUrl = "";

  /**
   * @generated from field: string media_url = 7;
   */
  mediaUrl = "";

  /**
   * Only for saved stories and stories
   *
   * @generated from field: repeated common.proto.SnapchatSnap snaps = 13;
   */
  snaps: SnapchatSnap[] = [];

  /**
   * @generated from field: string title = 12;
   */
  title = "";

  /**
   * For stories, it is the concatenation of all captions from its snaps
   *
   * @generated from field: string caption = 8;
   */
  caption = "";

  /**
   * Provided for spotlight, taken from caption for stories
   *
   * @generated from field: repeated string hashtags = 9;
   */
  hashtags: string[] = [];

  /**
   * Taken from caption
   *
   * @generated from field: repeated string mentions = 11;
   */
  mentions: string[] = [];

  /**
   * @generated from field: string status = 10;
   */
  status = "";

  /**
   * @generated from field: common.proto.PostInteractions interactions = 100;
   */
  interactions?: PostInteractions;

  /**
   * @generated from field: common.proto.SnapchatMediaStatistics snapchat_statistics = 101;
   */
  snapchatStatistics?: SnapchatMediaStatistics;

  constructor(data?: PartialMessage<SnapchatMediaMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.SnapchatMediaMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "creator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType) },
    { no: 4, name: "creation_time", kind: "message", T: Timestamp },
    { no: 5, name: "ended_at", kind: "message", T: Timestamp },
    { no: 6, name: "thumbnail_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "media_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "snaps", kind: "message", T: SnapchatSnap, repeated: true },
    { no: 12, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "caption", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "hashtags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "mentions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 100, name: "interactions", kind: "message", T: PostInteractions },
    { no: 101, name: "snapchat_statistics", kind: "message", T: SnapchatMediaStatistics },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SnapchatMediaMetadata {
    return new SnapchatMediaMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SnapchatMediaMetadata {
    return new SnapchatMediaMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SnapchatMediaMetadata {
    return new SnapchatMediaMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: SnapchatMediaMetadata | PlainMessage<SnapchatMediaMetadata> | undefined, b: SnapchatMediaMetadata | PlainMessage<SnapchatMediaMetadata> | undefined): boolean {
    return proto3.util.equals(SnapchatMediaMetadata, a, b);
  }
}

/**
 * Other counts
 *
 * @generated from message common.proto.SnapchatMediaStatistics
 */
export class SnapchatMediaStatistics extends Message<SnapchatMediaStatistics> {
  /**
   * @generated from field: int64 viewers_count = 1;
   */
  viewersCount = protoInt64.zero;

  /**
   * @generated from field: int64 unique_sessions_count = 2;
   */
  uniqueSessionsCount = protoInt64.zero;

  /**
   * @generated from field: int64 screenshots_count = 3;
   */
  screenshotsCount = protoInt64.zero;

  /**
   * @generated from field: int64 unique_screenshots_count = 4;
   */
  uniqueScreenshotsCount = protoInt64.zero;

  /**
   * @generated from field: int64 swipe_ups_count = 5;
   */
  swipeUpsCount = protoInt64.zero;

  /**
   * @generated from field: int64 swipe_down_count = 6;
   */
  swipeDownCount = protoInt64.zero;

  /**
   * @generated from field: int64 interactions_count = 7;
   */
  interactionsCount = protoInt64.zero;

  /**
   * @generated from field: int64 avg_view_time_millis = 8;
   */
  avgViewTimeMillis = protoInt64.zero;

  /**
   * @generated from field: int64 tap_back_cunt = 9;
   */
  tapBackCunt = protoInt64.zero;

  /**
   * @generated from field: int64 long_impressions = 10;
   */
  longImpressions = protoInt64.zero;

  constructor(data?: PartialMessage<SnapchatMediaStatistics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.SnapchatMediaStatistics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "viewers_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "unique_sessions_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "screenshots_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "unique_screenshots_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "swipe_ups_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "swipe_down_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "interactions_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "avg_view_time_millis", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "tap_back_cunt", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "long_impressions", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SnapchatMediaStatistics {
    return new SnapchatMediaStatistics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SnapchatMediaStatistics {
    return new SnapchatMediaStatistics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SnapchatMediaStatistics {
    return new SnapchatMediaStatistics().fromJsonString(jsonString, options);
  }

  static equals(a: SnapchatMediaStatistics | PlainMessage<SnapchatMediaStatistics> | undefined, b: SnapchatMediaStatistics | PlainMessage<SnapchatMediaStatistics> | undefined): boolean {
    return proto3.util.equals(SnapchatMediaStatistics, a, b);
  }
}

/**
 * @generated from message common.proto.SnapchatMedia
 */
export class SnapchatMedia extends Message<SnapchatMedia> {
  /**
   * @generated from field: common.proto.SnapchatMediaMetadata metadata = 1;
   */
  metadata?: SnapchatMediaMetadata;

  /**
   * @generated from field: common.proto.GenerikMediaFacts facts = 2;
   */
  facts?: GenerikMediaFacts;

  constructor(data?: PartialMessage<SnapchatMedia>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.SnapchatMedia";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: SnapchatMediaMetadata },
    { no: 2, name: "facts", kind: "message", T: GenerikMediaFacts },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SnapchatMedia {
    return new SnapchatMedia().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SnapchatMedia {
    return new SnapchatMedia().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SnapchatMedia {
    return new SnapchatMedia().fromJsonString(jsonString, options);
  }

  static equals(a: SnapchatMedia | PlainMessage<SnapchatMedia> | undefined, b: SnapchatMedia | PlainMessage<SnapchatMedia> | undefined): boolean {
    return proto3.util.equals(SnapchatMedia, a, b);
  }
}


// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/cdn_assets.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { FileProto } from "../../common/proto/common_pb";

/**
 * @generated from message librarian.proto.UploadAssetRequest
 */
export class UploadAssetRequest extends Message<UploadAssetRequest> {
  /**
   * @generated from field: common.proto.FileProto file = 1;
   */
  file?: FileProto;

  /**
   * Use it if you don't need the full picture to be saved, only a "small" version of it
   *
   * @generated from field: bool thumbnail = 3;
   */
  thumbnail = false;

  /**
   * id of the user who uploaded the asset. The logged cookie first will be checked to get the user, if not found,
   * we will try to get the user using this field.
   *
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<UploadAssetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadAssetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "file", kind: "message", T: FileProto },
    { no: 3, name: "thumbnail", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadAssetRequest {
    return new UploadAssetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadAssetRequest {
    return new UploadAssetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadAssetRequest {
    return new UploadAssetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadAssetRequest | PlainMessage<UploadAssetRequest> | undefined, b: UploadAssetRequest | PlainMessage<UploadAssetRequest> | undefined): boolean {
    return proto3.util.equals(UploadAssetRequest, a, b);
  }
}


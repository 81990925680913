import { Axis, ChartTypes, Data } from 'billboard.js';
import { DataSet, XFormatter, YFormatter } from './charts.models';
import { BarOptions } from 'billboard.js/types/options.shape';
import { isNotEmptyString, isNotNil } from '@frontend2/core';

// default colors for charts
// it may differ from our design system
const CHARTS_COLORS = [
  '#5c6ac4',
  '#70c5ce',
  '#ffbf82',
  '#f2b2b7',
  '#a7a0ef',
  '#fbc397',
  '#666666',
  '#000000',
];

// return CHARTS_COLORS based on index of data
function getColorByIndex(index: number): string {
  if (index >= 0 && index < CHARTS_COLORS.length) {
    return CHARTS_COLORS[index];
  }
  return '';
}

// Build Billboard JS Data type
// based on our DataSets
export function buildBillboardData(type: ChartTypes, data: DataSet[]): Data {
  // this how BillboardJS define data
  // [
  //   [ 'DATA 1', value1, value2, ... ],
  //   [ 'DATA 2', value1, value2, ... ]
  // ]
  const columns: (string | number)[][] = [];

  const colors: { [key: string]: string } = {};
  const types: { [key: string]: ChartTypes } = {};

  for (let i = 0; i < data.length; i++) {
    const ds = data[i];
    columns.push([ds.name, ...ds.values]);

    // use DataSet color or apply default based on index
    colors[ds.name] = ds.color ?? getColorByIndex(i);

    // use DataSety type or use global chart type
    types[ds.name] = ds.type ?? type;
  }

  return {
    columns,
    colors,
    types,
  };
}

// helper to apply basic configuration for bar charts
export function buildBarOptions(options?: {
  readonly width?: number;
  readonly ratio?: number;
  readonly padding?: number;
  readonly radius?: number | { ratio?: number | undefined };
}): BarOptions {
  const cfg: BarOptions = {};

  const { width, ratio, padding, radius } = options ?? {};

  if (isNotNil(width)) {
    cfg.width = width;
  } else if (isNotNil(ratio)) {
    cfg.width = {
      ratio,
    };
  }

  cfg.padding = padding ?? 5;

  cfg.radius = radius;

  return cfg;
}

// Helper to configure Axis of charts
// - how x and y axis should be formatter
// - how many label we should display on axis
export function buildAxisOptions(
  data: DataSet[],
  options: {
    readonly yFormat?: YFormatter;
    readonly xFormat?: XFormatter;
    readonly yCount?: number;
    readonly xCount?: number;
  },
): Axis {
  const yLabel = data.find((d) => isNotEmptyString(d.yLabel) === true)?.yLabel;

  const yCount = options?.yCount ?? 5;
  const xCount = options?.xCount ?? 5;

  const labelConfig = isNotEmptyString(yLabel)
    ? { text: yLabel, position: 'inner-top' }
    : '';

  return {
    y: {
      show: true,
      label: labelConfig,
      tick: {
        count: yCount,
        format: options.yFormat,
      },
    },
    x: {
      tick: {
        count: xCount,
        format: options.xFormat,
        fit: false,
      },
    },
  };
}

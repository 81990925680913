import { NgFor, NgIf, NgStyle } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isNotEmptyString } from '@frontend2/core';
import { DataSet } from '../charts.models';

@Component({
  selector: 'lefty-bar-charts',
  templateUrl: 'lefty-bar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['lefty-bar.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, NgStyle],
})
export class LeftyBarChartsComponent {
  @Input()
  vertical = false;

  @Input()
  valueFormatter?: (val: number) => string;

  @Input()
  nameFormatter?: (name: string) => string;

  @Input()
  title = '';

  private _maxValue = 0;

  get maxValue(): number {
    return this._maxValue;
  }

  private _data: DataSet[] = [];

  get data(): DataSet[] {
    return this._data;
  }

  @Input()
  set data(newData: DataSet[]) {
    this._data = newData;

    this._maxValue = 0;
    for (const d of this._data) {
      const sum = d.values.reduce((a, b) => a + b, 0);
      this._maxValue = Math.max(sum, this._maxValue);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackByIndex(index: number, _: unknown): number {
    return index;
  }

  barStyle(d: DataSet): {
    [key: string]: string;
  } {
    const val = d.values[0];

    if (this.vertical) {
      return {
        width: `${(val / this._maxValue) * 100}%`,
        backgroundColor: isNotEmptyString(d.color) ? d.color : '',
      };
    } else {
      return {
        height: `${(val / this._maxValue) * 100}%`,
        backgroundColor: isNotEmptyString(d.color) ? d.color : '',
      };
    }
  }

  formatValue(val: number): string {
    if (!val) {
      return '';
    }
    return this.valueFormatter ? this.valueFormatter(val) : val.toString();
  }

  formatName(name: string): string {
    if (this.nameFormatter) {
      return this.nameFormatter(name);
    }
    return name;
  }
}

import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianAffiliation } from '@frontend2/proto/librarian/proto/affiliation_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianAffiliationClient = PromiseClient<
  typeof LibrarianAffiliation
>;

const factory = (): LibrarianAffiliationClient => {
  return createPromiseClient(LibrarianAffiliation, inject(GRPC_TRANSPORT));
};

const token = new InjectionToken('LibrarianAffiliationClient', {
  factory,
});

export function injectLibrarianAffiliationClient(): LibrarianAffiliationClient {
  return inject(token);
}

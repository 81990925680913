import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianShopify } from '@frontend2/proto/librarian/proto/shopify_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianShopifyClient = PromiseClient<typeof LibrarianShopify>;

export const LibrarianShopifyClientProvider = new InjectionToken(
  'LibrarianShopifyClient',
  {
    factory: (): LibrarianShopifyClient =>
      createPromiseClient(LibrarianShopify, inject(GRPC_TRANSPORT)),
  },
);

export function injectLibrarianShopifyClient(): LibrarianShopifyClient {
  return inject(LibrarianShopifyClientProvider);
}

import {
  Axis,
  Data,
  Grid,
  LegendOptions,
  TooltipOptions,
  bb,
} from 'billboard.js';
import { LineOptions } from 'billboard.js/types/options.shape';
import {
  buildAxisOptions,
  buildBarOptions,
  buildBillboardData,
} from '../charts.helpers';
import { DataSet } from '../charts.models';
import { buildGroupTooltipHTML } from '../tooltip.helpers';
import { IDataRow } from 'billboard.js/src/ChartInternal/data/IData';
import { formatDate, isEmptyArray, isNil, isNotNil } from '@frontend2/core';
import { LeftyTimeseriesOptions } from './timeseries.models';

function _buildBillboardData(
  dates: Date[],
  data: DataSet[],
  options: LeftyTimeseriesOptions,
): Data {
  const d = buildBillboardData(options.type, data);

  d.x = 'x';

  d.columns = [
    // add dates to charts columns
    ['x', ...dates.map((d) => d.getTime())],
    ...(d.columns ?? []),
  ];

  if (options.stacked) {
    d.groups = [data.map((d) => d.name)];
    d.order = null;
  }

  return d;
}

function _buildGridOptions(): Grid {
  return {
    y: {
      show: true,
    },
  };
}

function _buildLineOptions(): LineOptions {
  return {
    connectNull: true,
  };
}

function _buildLegendOptions(): LegendOptions {
  return {
    show: false,
  };
}

function _buildTooltipOptions(options: LeftyTimeseriesOptions): TooltipOptions {
  return {
    contents: (c) => buildTimeseriesTooltipHTML(c, options),
  };
}

function _buildAxisOptions(
  dataSets: DataSet[],
  options: LeftyTimeseriesOptions,
): Axis {
  //set x axis and y axis tick counts (xCount/yCount) to 0 when dataSet isEmpty (no graph to display)
  const axis = buildAxisOptions(dataSets, {
    xFormat: options.xFormatter,
    yFormat: options.yFormatter,
    xCount: isEmptyArray(dataSets) ? 0 : options?.xCount,
    yCount: isEmptyArray(dataSets) ? 0 : options?.yCount,
  });

  axis.y = {
    ...axis.y,
    padding: 0,
    min: 0,
    max: options.yMax,
  };

  axis.x = {
    type: 'timeseries',
    tick: {
      ...axis.x?.tick,
      culling: {
        max: axis.x?.tick?.count,
      },
      fit: true,
    },
  };

  return axis;
}

function _overrideStyle(element: HTMLElement): void {
  const styles = document.createElement('style');

  // manually change label position in chart
  element
    .querySelector('.bb-axis-y-label')
    ?.setAttribute('transform', 'translate(26,-16)');

  element.parentNode?.append(styles);
}

function defaultValueFormatter(value: number | Date | null): string {
  return `${value}`;
}

function defaultDateFormatter(value: number | Date | null): string {
  if (isNil(value)) {
    return '';
  }

  if (typeof value === 'number') {
    value = new Date(value);
  }

  return formatDate(value);
}

export function buildTimeseriesTooltipHTML(
  data: IDataRow[],
  options: LeftyTimeseriesOptions,
): string {
  const valueFormatter =
    options.yTooltipFormatter ?? options.yFormatter ?? defaultValueFormatter;
  const dateFormatter =
    options.xTooltipFormatter ?? options.xFormatter ?? defaultDateFormatter;

  const date = data[0].x;
  const formattedDate = dateFormatter(new Date(date));

  let dataToUse = data.filter((d) => isNotNil(d.value));
  if (options?.hideZero) {
    dataToUse = dataToUse.filter((d) => d.value !== 0);
  }

  const values = dataToUse.map((d) => valueFormatter(d.value, d.id));
  return buildGroupTooltipHTML(values, formattedDate);
}

export function generateTimeseriesChart(
  bindto: HTMLElement,
  dates: Date[],
  dataSets: DataSet[],
  options: LeftyTimeseriesOptions,
): bb.Chart {
  const data = _buildBillboardData(dates, dataSets, options);
  const axis = _buildAxisOptions(dataSets, options);
  const grid = _buildGridOptions();
  const bar = buildBarOptions({ width: options.barWidth });
  const line = _buildLineOptions();
  const legend = _buildLegendOptions();
  const tooltip = options?.tooltip ?? _buildTooltipOptions(options);

  _overrideStyle(bindto);

  return bb.generate({
    bindto,
    tooltip,
    axis,
    padding: options.padding,
    size: options.size,
    grid,
    bar,
    line,
    legend,
    data,
  });
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import { LeftyComponent } from '../utils';

@Component({
  selector: 'lefty-card',
  template: `<ng-content></ng-content>`,
  styleUrls: ['lefty-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeftyCardComponent extends LeftyComponent {
  @HostBinding('class.with-padding')
  @Input()
  withPadding = false;

  @HostBinding('class.with-border')
  @Input()
  withBorder = false;

  @HostBinding('class.clickable')
  @Input()
  withShadowHover = false;

  @HostBinding('class.selectable')
  @Input()
  selectable = false;

  @HostBinding('class.active')
  @Input()
  isSelected = false;

  @HostBinding('class.compact')
  @Input()
  isCompact = false;
}

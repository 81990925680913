// This file defines services for local APIs:
//     -autocomplete locations queries.
//     -gets details of a place.

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/local.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AutocompleteLocationRequest, AutocompleteLocationsResponse, AutofillAddressRequest, AutofillAddressResponse, GoogleLocation } from "./local_pb";
import { MethodKind, StringValue } from "@bufbuild/protobuf";

/**
 * @generated from service librarian.proto.Local
 */
export const Local = {
  typeName: "librarian.proto.Local",
  methods: {
    /**
     * The request is the string query.
     *
     * @generated from rpc librarian.proto.Local.AutocompleteLocations
     */
    autocompleteLocations: {
      name: "AutocompleteLocations",
      I: AutocompleteLocationRequest,
      O: AutocompleteLocationsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * The request is the location's id.
     *
     * @generated from rpc librarian.proto.Local.LocationDetails
     */
    locationDetails: {
      name: "LocationDetails",
      I: StringValue,
      O: GoogleLocation,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.Local.AutofillAddressAPI
     */
    autofillAddressAPI: {
      name: "AutofillAddressAPI",
      I: AutofillAddressRequest,
      O: AutofillAddressResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;


import { ReportMetric } from '@frontend2/proto/librarian/proto/reports_pb';

export function ReportMetricsToReadable(metric: ReportMetric): string {
  switch (metric) {
    case ReportMetric.POSTS:
      return 'Posts';
    case ReportMetric.EMV:
      return 'EMV';
    case ReportMetric.ENGAGEMENTS:
      return 'Engagements';
    case ReportMetric.IMPRESSIONS:
      return 'Impressions';
    case ReportMetric.REACH:
      return 'Reach';
    default:
      return 'unknown';
  }
}

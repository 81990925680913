import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
  signal,
} from '@angular/core';
import { isNotEmptyString, isNotNil } from '@frontend2/core';
import { AddressFieldProto } from '@frontend2/proto/librarian/proto/creator_fields_pb';
import { DialogBase } from '../lefty-dialog/lefty-dialog.helpers';
import { LeftyButtonDirective } from '../lefty-button-directive/lefty-button.directive';
import { LeftyCheckboxComponent } from '../checkbox/checkbox.component';
import { LeftyAddressFieldFormComponent } from './lefty-address-field-form/lefty-address-field-form.component';
import { LeftyDialogComponent } from '../lefty-dialog/lefty-dialog.component';

@Component({
  selector: 'lefty-address-field-form-dialog',
  templateUrl: './lefty-address-dialog.component.html',
  styleUrls: ['./lefty-address-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyDialogComponent,
    LeftyAddressFieldFormComponent,
    LeftyCheckboxComponent,
    LeftyButtonDirective,
  ],
})
export class LeftyAddressFieldFormDialogComponent extends DialogBase {
  readonly address = signal(new AddressFieldProto());

  readonly isEdit = signal(false);

  readonly isValid = signal(false);

  readonly saveForLater = signal(false);

  readonly userId = signal('');

  readonly isPhoneRequired = input(false);

  readonly dialogTitle = computed(() =>
    this.isEdit() ? $localize`Edit address` : $localize`Add address`,
  );

  readonly isWithUser = computed(() => isNotEmptyString(this.userId()));

  readonly submitButtonText = computed(() =>
    this.saveForLater() ? $localize`Next` : $localize`Save`,
  );

  readonly submit$ = output<AddressFieldProto>();

  readonly submitWithUser$ = output<GiftAddressDialogState>();

  openWith(address?: AddressFieldProto): void {
    this.address.set(address ?? new AddressFieldProto());
    this.saveForLater.set(false);
    this.isEdit.set(isNotNil(address));
    this.open();
  }

  openWithGiftAddressDialogState(state: GiftAddressDialogState): void {
    this.userId.set(state.userId);
    this.address.set(state.address ?? new AddressFieldProto());
    this.saveForLater.set(state.isSaveForLater ?? false);
    this.isEdit.set(isNotNil(state.address));
    this.open();
  }

  submit(): void {
    this.submit$.emit(this.address());

    if (this.isWithUser()) {
      this.submitWithUser$.emit({
        address: this.address(),
        userId: this.userId(),
        isSaveForLater: this.saveForLater(),
      });
    }

    this.close();
  }

  override close(): void {
    this.isEdit.set(false);
    super.close();
  }
}

export interface GiftAddressDialogState {
  userId: string;
  address?: AddressFieldProto;
  isSaveForLater?: boolean;
}

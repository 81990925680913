import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  inject,
  input,
  NgZone,
  OnDestroy,
  viewChild,
} from '@angular/core';
import { LogicOperator } from '@frontend2/proto/common/proto/common_pb';
import { LeftyControlValueAccessor } from '../form';
import { LeftyOperatorChipComponent } from '../lefty-chip/lefty-operator-chip/lefty-operator-chip.component';

@Component({
  selector: 'lefty-logic-operator-tree',
  templateUrl: './logic-operator-tree.component.html',
  styleUrls: ['./logic-operator-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyOperatorChipComponent],
})
export class LeftyLogicOperatorTreeComponent
  extends LeftyControlValueAccessor<LogicOperator>
  implements AfterViewInit, OnDestroy
{
  readonly DEFAULT_TOP_OFFSET = 10;

  readonly editable = input(true);
  readonly compact = input(false);

  // The vertical distance, in pixels, between the top of each branch and the horizontal line
  readonly topOffset = input(this.DEFAULT_TOP_OFFSET);

  override isValidType(obj: unknown): obj is LogicOperator {
    return Object.values(LogicOperator).includes(obj as LogicOperator);
  }

  private ngZone = inject(NgZone);

  readonly elementRef = inject(ElementRef);

  private resizeObserver?: ResizeObserver;

  readonly selectorContainer = viewChild.required('selectorContainer', {
    read: ElementRef,
  });

  readonly branchesContainer = viewChild.required('branchesContainer', {
    read: ElementRef,
  });

  ngAfterViewInit(): void {
    const hostElement = this.elementRef.nativeElement;
    this.resizeObserver = new ResizeObserver(() => {
      this.ngZone.runOutsideAngular(() => {
        this.updateBranchHeights(hostElement);
      });
    });

    this.resizeObserver?.observe(this.branchesContainer().nativeElement);
  }

  updateBranchHeights(hostElement: HTMLElement): void {
    const branches = hostElement.querySelectorAll('[branch]');

    const normalGap = getComputedStyle(hostElement)
      .getPropertyValue('--branch-normal-gap')
      .trim();
    const compactGap = getComputedStyle(hostElement)
      .getPropertyValue('--branch-compact-gap')
      .trim();

    const totalBranchesMinusOne = branches.length - 1;

    if (branches.length > 0) {
      let verticalLineHeight = 0;
      for (let i = 0; i < totalBranchesMinusOne; i++) {
        const branch = branches[i] as HTMLElement;
        verticalLineHeight += branch.clientHeight;
      }

      if (this.compact()) {
        verticalLineHeight += totalBranchesMinusOne * parseInt(compactGap);
      } else {
        verticalLineHeight += totalBranchesMinusOne * parseInt(normalGap);
      }

      this.selectorContainer().nativeElement.style.setProperty(
        '--top-offset',
        `${this.topOffset()}px`,
      );

      this.selectorContainer().nativeElement.style.setProperty(
        '--vertical-line-height',
        `${verticalLineHeight}px`,
      );
    }
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }
}

import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { LEFTY_ICON_PATH, LEFTY_LOGO } from '@frontend2/core';

@Directive({
  selector: 'img[appLogo]',
  standalone: true,
})
export class AppLogoDirective {
  constructor(
    private el: ElementRef<HTMLImageElement>,
    private renderer: Renderer2,
  ) {
    this._buildLogo(this.el, this.renderer);
  }

  // lefty logo is the default one
  private _appLogo = LEFTY_LOGO;

  @Input()
  set appLogo(value: string) {
    if (!value) {
      return;
    }
    this._appLogo = value;
    this._buildLogo(this.el, this.renderer);
  }

  private _buildLogo(
    element: ElementRef<HTMLImageElement>,
    renderer: Renderer2,
  ): void {
    renderer.setProperty(element.nativeElement, 'src', this._appLogo);
    renderer.setStyle(element.nativeElement, 'object-fit', 'contain');
  }
}

@Directive({
  selector: 'img[appIcon]',
  standalone: true,
})
export class AppIconDirective {
  constructor(
    private el: ElementRef<HTMLImageElement>,
    private renderer: Renderer2,
  ) {
    this._buildLogo(this.el, this.renderer);
  }

  // lefty icon is the default one
  private _icon = LEFTY_ICON_PATH;

  @Input()
  set appLogo(value: string) {
    if (!value) {
      return;
    }
    this._icon = value;
    this._buildLogo(this.el, this.renderer);
  }

  private _buildLogo(
    element: ElementRef<HTMLImageElement>,
    renderer: Renderer2,
  ): void {
    renderer.setProperty(element.nativeElement, 'src', this._icon);
    renderer.setStyle(element.nativeElement, 'object-fit', 'contain');
  }
}

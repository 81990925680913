import { inject, Injectable } from '@angular/core';
import { Message } from '@bufbuild/protobuf';
import {
  EventBus,
  isNotNil,
  LeftyEventDataType,
  LeftyParentAppBridge,
} from '@frontend2/core';
import {
  Campaign,
  UploadInfluencersCampaignRequest,
} from '@frontend2/proto/librarian/proto/campaigns_pb';
import { EntityField } from '@frontend2/proto/librarian/proto/common_pb';
import { EntityFieldValue } from '@frontend2/proto/librarian/proto/entity_fields_pb';
import { EntityLabel } from '@frontend2/proto/librarian/proto/entity_labels_pb';
import { UploadInfluencersResult } from '@frontend2/proto/librarian/proto/upload_influencer_pb';

export type LeftyEvents = {
  create_label: EntityLabel;
  edit_label: EntityLabel;
  entity_field_updated: EntityField;
  entity_field_created: EntityField;
  update_entity_field_value: EntityFieldValue;
  upload_influencers_in_campaign: {
    readonly request: UploadInfluencersCampaignRequest;
    readonly result: UploadInfluencersResult;
  };
  remove_campaign: Campaign;
  archive_campaign: Campaign;
  unarchive_campaign: Campaign;
  pin_campaign: Campaign;
  unpin_campaign: Campaign;
  create_campaign: Campaign;
  update_campaign: Campaign;
};

@Injectable()
export class LeftyEventsBus<T extends LeftyEvents> extends EventBus<T> {
  readonly parentAppBridge = inject(LeftyParentAppBridge, {
    optional: true,
  });

  override emit<Name extends keyof T>(
    name: Name,
    event: T[Name],
    args?: { emitter?: unknown; emitToParentApp?: boolean },
  ): void {
    super.emit(name, event, args);

    const eventData = event as LeftyEventDataType;

    if (args?.emitToParentApp && isNotNil(this.parentAppBridge)) {
      this.parentAppBridge.emitEventBus(
        name as string,
        eventData instanceof Message ? eventData.toJson() : eventData,
      );
    }
  }
}

export function injectLeftyEventsBus(): LeftyEventsBus<LeftyEvents> {
  return inject(LeftyEventsBus);
}

import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { LeftyEvents, LeftyEventsBus } from './events.service';

@NgModule({})
export class LeftyEventsModule {
  static forRoot(
    eventBusToken: Type<LeftyEventsBus<LeftyEvents>>,
  ): ModuleWithProviders<LeftyEventsModule> {
    return {
      ngModule: LeftyEventsModule,
      providers: [
        { provide: eventBusToken },
        { provide: LeftyEventsBus, useExisting: eventBusToken },
      ],
    };
  }
}

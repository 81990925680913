// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/shopify.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, MethodKind, StringValue } from "@bufbuild/protobuf";
import { ConnectShopifyStoreRequest, DisconnectShopifyStoreRequest, GetAvailableShopifyProductsRequest, GetAvailableShopifyProductsResponse, GetConnectedShopifyStoresResponse, GetConnectedTeamsRequest, GetConnectedTeamsResponse, GetShopifyProductsRequest, GetShopifyProductsResponse, ImportShopifyProductsRequest } from "./shopify_pb";

/**
 * @generated from service librarian.proto.LibrarianShopify
 */
export const LibrarianShopify = {
  typeName: "librarian.proto.LibrarianShopify",
  methods: {
    /**
     * Get shopify stores connected to the logged user's workspace.
     *
     * @generated from rpc librarian.proto.LibrarianShopify.GetConnectedShopifyStoresAPI
     */
    getConnectedShopifyStoresAPI: {
      name: "GetConnectedShopifyStoresAPI",
      I: Empty,
      O: GetConnectedShopifyStoresResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get workspaces connected to the store.
     *
     * @generated from rpc librarian.proto.LibrarianShopify.GetConnectedTeamsAPI
     */
    getConnectedTeamsAPI: {
      name: "GetConnectedTeamsAPI",
      I: GetConnectedTeamsRequest,
      O: GetConnectedTeamsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Connect a shopify store to the given list of users' workspaces
     *
     * @generated from rpc librarian.proto.LibrarianShopify.ConnectShopifyStoreAPI
     */
    connectShopifyStoreAPI: {
      name: "ConnectShopifyStoreAPI",
      I: ConnectShopifyStoreRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Disconnect a shopify store from logged user workspace
     *
     * @generated from rpc librarian.proto.LibrarianShopify.DisconnectShopifyStoreAPI
     */
    disconnectShopifyStoreAPI: {
      name: "DisconnectShopifyStoreAPI",
      I: DisconnectShopifyStoreRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Create an async task to get the shopify products for the given store
     *
     * @generated from rpc librarian.proto.LibrarianShopify.GetShopifyProductsAPI
     */
    getShopifyProductsAPI: {
      name: "GetShopifyProductsAPI",
      I: GetShopifyProductsRequest,
      O: GetShopifyProductsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get the imported shopify products from DB in the response.
     *
     * @generated from rpc librarian.proto.LibrarianShopify.GetAvailableShopifyProductsAPI
     */
    getAvailableShopifyProductsAPI: {
      name: "GetAvailableShopifyProductsAPI",
      I: GetAvailableShopifyProductsRequest,
      O: GetAvailableShopifyProductsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Create an async task to import the shopify products and return the task handle
     *
     * @generated from rpc librarian.proto.LibrarianShopify.ImportShopifyProductsAPI
     */
    importShopifyProductsAPI: {
      name: "ImportShopifyProductsAPI",
      I: ImportShopifyProductsRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
  }
} as const;


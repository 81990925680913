<div
  class="icon-container checked"
  *ngIf="checked || indeterminate"
  [class.focus]="showFocus"
>
  <lefty-icon
    [icon]="icon"
    class="icon"
    aria-hidden="true"
    size="x-large"
  >
  </lefty-icon>
</div>
<div
  class="icon-container"
  *ngIf="!checked && !indeterminate"
  [class.focus]="showFocus"
></div>
<div class="content">
  <ng-content>{{ label }}</ng-content>
</div>

// This file defines protocol buffers for the youtube stuff that gets persisted.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/youtube_media.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { ComputedGenerikStats, LongStatValue, StatValue, StringCount } from "./common_pb";
import { VideoMetadata, YoutubeChannel } from "./youtube_pb";
import { GenerikMediaFacts } from "./media_pb";

/**
 * stuff that intelligence computes when scanning all videos, and we persist it back to cassandra
 * into the author object.
 *
 * @generated from message common.proto.ComputedYoutubeStats
 */
export class ComputedYoutubeStats extends Message<ComputedYoutubeStats> {
  /**
   * @generated from field: common.proto.LongStatValue views = 1;
   */
  views?: LongStatValue;

  /**
   * @generated from field: common.proto.StatValue likes = 2;
   */
  likes?: StatValue;

  /**
   * @generated from field: common.proto.StatValue dislikes = 3;
   */
  dislikes?: StatValue;

  /**
   * @generated from field: common.proto.StatValue comments_count = 4;
   */
  commentsCount?: StatValue;

  /**
   * In this proto, the values are the number of seconds between 2 posts.
   *
   * @generated from field: common.proto.StatValue posting_frequency = 5;
   */
  postingFrequency?: StatValue;

  /**
   * Top tags used by the creator
   *
   * @generated from field: repeated common.proto.StringCount top_tags = 6;
   */
  topTags: StringCount[] = [];

  /**
   * Last post date ( this is the last post in db, may not be realtime )
   *
   * @generated from field: google.protobuf.Timestamp last_post = 7;
   */
  lastPost?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp stats_compute_time = 8;
   */
  statsComputeTime?: Timestamp;

  constructor(data?: PartialMessage<ComputedYoutubeStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ComputedYoutubeStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "views", kind: "message", T: LongStatValue },
    { no: 2, name: "likes", kind: "message", T: StatValue },
    { no: 3, name: "dislikes", kind: "message", T: StatValue },
    { no: 4, name: "comments_count", kind: "message", T: StatValue },
    { no: 5, name: "posting_frequency", kind: "message", T: StatValue },
    { no: 6, name: "top_tags", kind: "message", T: StringCount, repeated: true },
    { no: 7, name: "last_post", kind: "message", T: Timestamp },
    { no: 8, name: "stats_compute_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComputedYoutubeStats {
    return new ComputedYoutubeStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComputedYoutubeStats {
    return new ComputedYoutubeStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComputedYoutubeStats {
    return new ComputedYoutubeStats().fromJsonString(jsonString, options);
  }

  static equals(a: ComputedYoutubeStats | PlainMessage<ComputedYoutubeStats> | undefined, b: ComputedYoutubeStats | PlainMessage<ComputedYoutubeStats> | undefined): boolean {
    return proto3.util.equals(ComputedYoutubeStats, a, b);
  }
}

/**
 * Fields directly from youtube.
 *
 * @generated from message common.proto.YoutubeCreator
 */
export class YoutubeCreator extends Message<YoutubeCreator> {
  /**
   * @generated from field: common.proto.YoutubeChannel channel = 2;
   */
  channel?: YoutubeChannel;

  /**
   * DEPRECATED: this field is replaced by the generik one below. It is pending deletion until we have re-intelligenced
   * every profile, and both fields have been populated.
   *
   * @generated from field: common.proto.ComputedYoutubeStats stats = 5;
   */
  stats?: ComputedYoutubeStats;

  /**
   * @generated from field: common.proto.ComputedGenerikStats computed_stats = 7;
   */
  computedStats?: ComputedGenerikStats;

  /**
   * @generated from field: common.proto.YoutubeCreator.DebugInfo debug_info = 6;
   */
  debugInfo?: YoutubeCreator_DebugInfo;

  constructor(data?: PartialMessage<YoutubeCreator>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.YoutubeCreator";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "channel", kind: "message", T: YoutubeChannel },
    { no: 5, name: "stats", kind: "message", T: ComputedYoutubeStats },
    { no: 7, name: "computed_stats", kind: "message", T: ComputedGenerikStats },
    { no: 6, name: "debug_info", kind: "message", T: YoutubeCreator_DebugInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): YoutubeCreator {
    return new YoutubeCreator().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): YoutubeCreator {
    return new YoutubeCreator().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): YoutubeCreator {
    return new YoutubeCreator().fromJsonString(jsonString, options);
  }

  static equals(a: YoutubeCreator | PlainMessage<YoutubeCreator> | undefined, b: YoutubeCreator | PlainMessage<YoutubeCreator> | undefined): boolean {
    return proto3.util.equals(YoutubeCreator, a, b);
  }
}

/**
 * Information that may be used to identify when and why a specific link was established.
 *
 * We may also use the version number to identity records that were linked at a time the code
 * was faulty, and repair them.
 *
 * @generated from message common.proto.YoutubeCreator.DebugInfo
 */
export class YoutubeCreator_DebugInfo extends Message<YoutubeCreator_DebugInfo> {
  /**
   * what version the code was at when we first linked this youtube creator.
   * the version number shall change any time something significant evolves in the linking logic.
   *
   * @generated from field: int32 linked_version = 1;
   */
  linkedVersion = 0;

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;

  /**
   * What the social_media.youtube URL was when we linked it.
   *
   * @generated from field: string social_media = 3;
   */
  socialMedia = "";

  /**
   * a linking by admin forcing it.
   *
   * @generated from field: bool was_admin_force = 4;
   */
  wasAdminForce = false;

  constructor(data?: PartialMessage<YoutubeCreator_DebugInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.YoutubeCreator.DebugInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "linked_version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "timestamp", kind: "message", T: Timestamp },
    { no: 3, name: "social_media", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "was_admin_force", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): YoutubeCreator_DebugInfo {
    return new YoutubeCreator_DebugInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): YoutubeCreator_DebugInfo {
    return new YoutubeCreator_DebugInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): YoutubeCreator_DebugInfo {
    return new YoutubeCreator_DebugInfo().fromJsonString(jsonString, options);
  }

  static equals(a: YoutubeCreator_DebugInfo | PlainMessage<YoutubeCreator_DebugInfo> | undefined, b: YoutubeCreator_DebugInfo | PlainMessage<YoutubeCreator_DebugInfo> | undefined): boolean {
    return proto3.util.equals(YoutubeCreator_DebugInfo, a, b);
  }
}

/**
 * @generated from message common.proto.Video
 */
export class Video extends Message<Video> {
  /**
   * @generated from field: common.proto.VideoMetadata video = 1;
   */
  video?: VideoMetadata;

  /**
   * DEPRECATED_FOR_FACTS
   *
   * @generated from field: google.protobuf.Timestamp ingestion_time = 2;
   */
  ingestionTime?: Timestamp;

  /**
   * DEPRECATED_FOR_FACTS
   *
   * @generated from field: google.protobuf.Timestamp last_stats_time = 3;
   */
  lastStatsTime?: Timestamp;

  /**
   * @generated from field: common.proto.GenerikMediaFacts facts = 4;
   */
  facts?: GenerikMediaFacts;

  constructor(data?: PartialMessage<Video>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Video";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "video", kind: "message", T: VideoMetadata },
    { no: 2, name: "ingestion_time", kind: "message", T: Timestamp },
    { no: 3, name: "last_stats_time", kind: "message", T: Timestamp },
    { no: 4, name: "facts", kind: "message", T: GenerikMediaFacts },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Video {
    return new Video().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Video {
    return new Video().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Video {
    return new Video().fromJsonString(jsonString, options);
  }

  static equals(a: Video | PlainMessage<Video> | undefined, b: Video | PlainMessage<Video> | undefined): boolean {
    return proto3.util.equals(Video, a, b);
  }
}


import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'help-icon',
  template: `
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="8"
      />
      <path
        d="M11.3327 5.31946C11.3354 5.37399 11.3367 5.42864 11.3367 5.48336C11.3367 6.36741 10.9855 6.94157 10.3604 7.56669C9.73528 8.19181 8.83671 9.23336 8.83671 9.65003V10.4834H7.17004V9.48336C7.17004 9.06669 7.58671 8.40003 8.00338 7.98336C8.42004 7.56669 8.83671 7.15002 9.18233 6.66232C9.52795 6.17461 9.67067 5.92555 9.67067 5.48336C9.67067 5.46966 9.67051 5.45599 9.67017 5.44233C9.66881 5.38711 9.66471 5.33207 9.65791 5.27741C9.64956 5.21036 9.63715 5.14388 9.62074 5.07836C9.60088 4.99907 9.57518 4.92117 9.54376 4.84531C9.50972 4.76314 9.46925 4.68401 9.4228 4.60864C9.38349 4.54486 9.33991 4.48377 9.29234 4.42579C9.25775 4.38362 9.22104 4.34311 9.18234 4.3044C9.1775 4.29956 9.17263 4.29475 9.16774 4.28998C9.13503 4.25806 9.10106 4.22755 9.06595 4.19851C8.98022 4.12761 8.88761 4.06547 8.78951 4.01302C8.74141 3.98731 8.692 3.96392 8.64143 3.94297C8.57821 3.91679 8.51358 3.89458 8.44791 3.87641C8.3297 3.84371 8.20811 3.82412 8.08536 3.81808C8.05808 3.81673 8.03075 3.81606 8.00338 3.81606C7.78442 3.81606 7.56762 3.85918 7.36533 3.94297C7.34963 3.94948 7.33404 3.95622 7.31856 3.96319C7.13468 4.04602 6.96722 4.16159 6.82442 4.3044C6.6696 4.45922 6.54678 4.64302 6.46299 4.84531C6.3792 5.0476 6.33608 5.26441 6.33608 5.48336L4.67004 5.48336C4.67004 5.04562 4.75626 4.61217 4.92378 4.20775C5.09129 3.80333 5.33683 3.43586 5.64635 3.12634C6.27148 2.50121 7.11932 2.15002 8.00338 2.15002C8.3864 2.15002 8.76614 2.21604 9.12602 2.34476C9.17743 2.36315 9.22844 2.38282 9.27899 2.40376C9.6796 2.5697 10.0478 2.81378 10.3604 3.12634C10.4095 3.17547 10.4571 3.22607 10.5029 3.27805C10.6737 3.47162 10.8213 3.68428 10.9429 3.91169C10.989 3.99793 11.0314 4.08629 11.0699 4.17654C11.0743 4.18692 11.0787 4.19732 11.083 4.20775C11.1039 4.2583 11.1236 4.3093 11.142 4.36072C11.1638 4.42172 11.1838 4.48328 11.202 4.54534C11.2758 4.79712 11.3198 5.05695 11.3327 5.31946Z"
      />
      <path d="M7.17004 12.15H8.83671V13.8167H7.17004V12.15Z" />
    </svg>
  `,
  styleUrls: ['tooltip_icon.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class HelpIconComponent {}

@Component({
  selector: 'info-icon',
  template: `
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="8"
        cy="8"
        r="8"
      />
      <path d="M8.83342 5.50004V3.83337H7.16675V5.50004H8.83342Z" />
      <path d="M8.83342 12.1667V7.16671H7.16675V12.1667H8.83342Z" />
    </svg>
  `,
  styleUrls: ['tooltip_icon.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class InfoIconComponent {}

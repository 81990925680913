import {
  ChangeDetectionStrategy,
  Component,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl, FormsModule } from '@angular/forms';
import { isNil, isNotNil } from '@frontend2/core';
import { GoogleLocation } from '@frontend2/proto/librarian/proto/local_pb';
import { Subject, debounceTime } from 'rxjs';
import { LeftyFormValueBase } from '../form';
import { LeftyFormAutocompleteComponent } from '../lefty-form-autocomplete/lefty-form-autocomplete.component';
import { LocationService } from '../services/location.service';
import { DEFAULT_GOOGLE_LOCATION_VALUE } from '../utils';

@Component({
  selector: 'search-location-autocomplete',
  templateUrl: 'search-location.component.html',
  styleUrls: ['search-location.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormAutocompleteComponent, FormsModule],
})
export class SearchLocationAutocompleteComponent extends LeftyFormValueBase<
  GoogleLocation | undefined
> {
  @ViewChild('leftyFormAutoComplete')
  leftyFormAutoComplete?: LeftyFormAutocompleteComponent<GoogleLocation>;

  constructor(
    @Optional() @Self() ngControl: NgControl,
    private locationService: LocationService,
  ) {
    super(new GoogleLocation(), ngControl);

    this.searchValue$
      .pipe(debounceTime(300))
      .subscribe((val) => this.searchLocation(val));

    this.disposer.add(this.searchValue$);
  }

  results: GoogleLocation[] = [];

  loading = false;

  searchValue$ = new Subject<string>();

  emptyPlaceholder = '';

  static get locationNotFound(): string {
    return $localize`Location not found`;
  }

  async searchLocation(text: string): Promise<void> {
    if (!text || text.length === 0) {
      return;
    }
    this.setState(() => (this.loading = true));
    this.results = [];
    this.results = await this.locationService.autocomplete(text);
    this.setState(() => (this.loading = false));
  }

  locationRenderer(location: GoogleLocation): string {
    return location.locationName;
  }

  override handleValueChange(val: GoogleLocation | undefined): void {
    if (isNil(val)) {
      val = new GoogleLocation();
    }
    super.handleValueChange(val);
  }

  override writeValue(obj: unknown): void {
    if (obj instanceof GoogleLocation) {
      this.value = obj as GoogleLocation;
    } else {
      console.warn(
        `Failed to bind type ${typeof obj} on form control ${
          this.ngControl?.name
        }`,
      );
    }
  }

  override handleBlur(event?: FocusEvent | undefined): void {
    super.handleBlur(event);

    if (
      isNotNil(this.value) &&
      !this.results.includes(this.value) &&
      this.value !== DEFAULT_GOOGLE_LOCATION_VALUE &&
      isNotNil(this.leftyFormAutoComplete)
    ) {
      this.leftyFormAutoComplete.inputText = this.value.locationName;
    }
  }

  openPopUpAndFocus(): void {
    this.leftyFormAutoComplete?.focus();
    this.leftyFormAutoComplete?.open();
  }
}

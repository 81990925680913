import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ComponentRef,
  DoCheck,
  Input,
  OnDestroy,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'dynamic-component',
  template: '<ng-template #marker></ng-template>',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class DynamicComponent<Value>
  implements OnDestroy, DoCheck, AfterViewInit
{
  @ViewChild('marker', { read: ViewContainerRef })
  viewContainerRef?: ViewContainerRef;

  private valueChanged = false;
  private factoryChanged = false;

  private _value?: Value;
  private _componentRef?: ComponentRef<unknown>;
  private _componentFactory?: ComponentFactory<Value>;

  get componentRef(): ComponentRef<unknown> | undefined {
    return this._componentRef;
  }

  @Input()
  set componentFactory(factory: ComponentFactory<Value> | undefined) {
    if (this._componentFactory !== factory) {
      this.factoryChanged = true;
    }
    this._componentFactory = factory;
  }

  /// The value to set on the component if the component implements
  /// [RendersValue]. Optional.
  @Input()
  set value(val: Value | undefined) {
    this._value = val;
    this.valueChanged = true;
  }

  ngOnDestroy(): void {
    this.disposeChildComponent();
  }

  ngAfterViewInit(): void {
    this.loadComponent();
  }

  ngDoCheck(): void {
    if (this.factoryChanged) {
      this.loadComponent();
    } else if (this.valueChanged) {
      // Only update the child if the component was not changed. If the
      // component was changed then it will get initialized with the value.
      this.updateChildComponent();
    }
    this.valueChanged = this.factoryChanged = false;
  }

  private disposeChildComponent(): void {
    this._componentRef?.destroy();
    this._componentRef = undefined;
  }

  private loadComponent(): void {
    if (!this.viewContainerRef) {
      return;
    }

    this.viewContainerRef.clear();
    this.disposeChildComponent();

    if (this._componentFactory) {
      this._componentRef = this.viewContainerRef.createComponent(
        this._componentFactory(),
      );
      this.updateChildComponent();
    }
  }

  private updateChildComponent(): void {
    if (!this._componentRef) {
      return;
    }

    const instance = this._componentRef.instance;
    if (isRendersValue(instance)) {
      instance.setValue(this._value);
      this.componentRef?.changeDetectorRef?.detectChanges();
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function isRendersValue<T>(object: any): object is RendersValue<T> {
  return object.setValue instanceof Function;
}

/// Interface to render a value.
///
/// In particular, if a component is loaded by [DynamicComponent] and it needs
/// to render a value, it must implement this interface to allow
/// DynamicComponent to set the value.
export interface RendersValue<T> {
  setValue(newValue?: T): void;
}

export type ComponentFactory<T> = () => Type<RendersValue<T>> | Type<unknown>;

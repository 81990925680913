import { InjectionToken, inject } from '@angular/core';
import { PromiseClient, createPromiseClient } from '@connectrpc/connect';
import { LibrarianEcommerceStores } from '@frontend2/proto/librarian/proto/ecommerce_stores_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianEcommerceStoresClient = PromiseClient<
  typeof LibrarianEcommerceStores
>;

const factory = (): LibrarianEcommerceStoresClient => {
  return createPromiseClient(LibrarianEcommerceStores, inject(GRPC_TRANSPORT));
};

const token = new InjectionToken('LibrarianEcommerceStoresClient', { factory });

export function injectLibrarianEcommerceStoresClient(): LibrarianEcommerceStoresClient {
  return inject(token);
}

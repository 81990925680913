// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/gifts.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { AddressFieldProto } from "./creator_fields_pb";
import { EcommerceStorePb, ShopifyStorePbV2 } from "./ecommerce_stores_pb";
import { ProductVariantPb } from "./products_pb";
import { BasicPagination, SortDirection } from "../../common/proto/common_pb";
import { CreatorCardSnippet } from "./creators_pb";
import { ShopifyStorePb } from "./shopify_pb";

/**
 * @generated from enum librarian.proto.GiftState
 */
export enum GiftState {
  /**
   * @generated from enum value: GIFT_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: GIFT_STATE_LIVE = 1;
   */
  LIVE = 1,

  /**
   * @generated from enum value: GIFT_STATE_ARCHIVED = 2;
   */
  ARCHIVED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(GiftState)
proto3.util.setEnumType(GiftState, "librarian.proto.GiftState", [
  { no: 0, name: "GIFT_STATE_UNSPECIFIED" },
  { no: 1, name: "GIFT_STATE_LIVE" },
  { no: 2, name: "GIFT_STATE_ARCHIVED" },
]);

/**
 * @generated from enum librarian.proto.GiftStatus
 */
export enum GiftStatus {
  /**
   * @generated from enum value: GIFT_STATUS_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: GIFT_STATUS_PENDING = 1;
   */
  PENDING = 1,

  /**
   * @generated from enum value: GIFT_STATUS_SHIPPED = 2;
   */
  SHIPPED = 2,

  /**
   * @generated from enum value: GIFT_STATUS_IN_TRANSIT = 3;
   */
  IN_TRANSIT = 3,

  /**
   * @generated from enum value: GIFT_STATUS_DELIVERED = 4;
   */
  DELIVERED = 4,

  /**
   * @generated from enum value: GIFT_STATUS_CANCELED = 5;
   */
  CANCELED = 5,

  /**
   * @generated from enum value: GIFT_STATUS_PENDING_INFLUENCER_APPROVAL = 6;
   */
  PENDING_INFLUENCER_APPROVAL = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(GiftStatus)
proto3.util.setEnumType(GiftStatus, "librarian.proto.GiftStatus", [
  { no: 0, name: "GIFT_STATUS_UNSPECIFIED" },
  { no: 1, name: "GIFT_STATUS_PENDING" },
  { no: 2, name: "GIFT_STATUS_SHIPPED" },
  { no: 3, name: "GIFT_STATUS_IN_TRANSIT" },
  { no: 4, name: "GIFT_STATUS_DELIVERED" },
  { no: 5, name: "GIFT_STATUS_CANCELED" },
  { no: 6, name: "GIFT_STATUS_PENDING_INFLUENCER_APPROVAL" },
]);

/**
 * @generated from enum librarian.proto.GiftsSort
 */
export enum GiftsSort {
  /**
   * @generated from enum value: GIFT_SORT_UNKNOWN = 0;
   */
  GIFT_SORT_UNKNOWN = 0,

  /**
   * @generated from enum value: GIFT_SORT_CREATED_AT = 1;
   */
  GIFT_SORT_CREATED_AT = 1,

  /**
   * @generated from enum value: GIFT_SORT_INFLUENCER_NAME = 2;
   */
  GIFT_SORT_INFLUENCER_NAME = 2,

  /**
   * @generated from enum value: GIFT_SORT_SENDER_EMAIL = 3;
   */
  GIFT_SORT_SENDER_EMAIL = 3,

  /**
   * @generated from enum value: GIFT_SORT_STATUS = 4;
   */
  GIFT_SORT_STATUS = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(GiftsSort)
proto3.util.setEnumType(GiftsSort, "librarian.proto.GiftsSort", [
  { no: 0, name: "GIFT_SORT_UNKNOWN" },
  { no: 1, name: "GIFT_SORT_CREATED_AT" },
  { no: 2, name: "GIFT_SORT_INFLUENCER_NAME" },
  { no: 3, name: "GIFT_SORT_SENDER_EMAIL" },
  { no: 4, name: "GIFT_SORT_STATUS" },
]);

/**
 * @generated from enum librarian.proto.DraftGiftsSort
 */
export enum DraftGiftsSort {
  /**
   * @generated from enum value: DRAFT_GIFT_SORT_UNKNOWN = 0;
   */
  DRAFT_GIFT_SORT_UNKNOWN = 0,

  /**
   * @generated from enum value: DRAFT_GIFT_SORT_CREATED_AT = 1;
   */
  DRAFT_GIFT_SORT_CREATED_AT = 1,

  /**
   * @generated from enum value: DRAFT_GIFT_SORT_SENDER_EMAIL = 2;
   */
  DRAFT_GIFT_SORT_SENDER_EMAIL = 2,

  /**
   * @generated from enum value: DRAFT_GIFT_SORT_SHIPPING_METHOD = 3;
   */
  DRAFT_GIFT_SORT_SHIPPING_METHOD = 3,

  /**
   * @generated from enum value: DRAFT_GIFT_SORT_STORE_NAME = 4;
   */
  DRAFT_GIFT_SORT_STORE_NAME = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(DraftGiftsSort)
proto3.util.setEnumType(DraftGiftsSort, "librarian.proto.DraftGiftsSort", [
  { no: 0, name: "DRAFT_GIFT_SORT_UNKNOWN" },
  { no: 1, name: "DRAFT_GIFT_SORT_CREATED_AT" },
  { no: 2, name: "DRAFT_GIFT_SORT_SENDER_EMAIL" },
  { no: 3, name: "DRAFT_GIFT_SORT_SHIPPING_METHOD" },
  { no: 4, name: "DRAFT_GIFT_SORT_STORE_NAME" },
]);

/**
 * @generated from enum librarian.proto.DraftGiftError
 */
export enum DraftGiftError {
  /**
   * @generated from enum value: DRAFT_GIFT_ERROR_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: DRAFT_GIFT_ERROR_MISSING_PRODUCTS = 1;
   */
  MISSING_PRODUCTS = 1,

  /**
   * @generated from enum value: DRAFT_GIFT_ERROR_MISSING_STORE = 2;
   */
  MISSING_STORE = 2,

  /**
   * @generated from enum value: DRAFT_GIFT_ERROR_MISSING_SHIPPING_METHODS = 3;
   */
  MISSING_SHIPPING_METHODS = 3,

  /**
   * @generated from enum value: DRAFT_GIFT_ERROR_MISSING_QUANTITIES = 4;
   */
  MISSING_QUANTITIES = 4,

  /**
   * @generated from enum value: DRAFT_GIFT_ERROR_MISSING_INFLUENCERS = 5;
   */
  MISSING_INFLUENCERS = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(DraftGiftError)
proto3.util.setEnumType(DraftGiftError, "librarian.proto.DraftGiftError", [
  { no: 0, name: "DRAFT_GIFT_ERROR_UNSPECIFIED" },
  { no: 1, name: "DRAFT_GIFT_ERROR_MISSING_PRODUCTS" },
  { no: 2, name: "DRAFT_GIFT_ERROR_MISSING_STORE" },
  { no: 3, name: "DRAFT_GIFT_ERROR_MISSING_SHIPPING_METHODS" },
  { no: 4, name: "DRAFT_GIFT_ERROR_MISSING_QUANTITIES" },
  { no: 5, name: "DRAFT_GIFT_ERROR_MISSING_INFLUENCERS" },
]);

/**
 * @generated from message librarian.proto.GiftPb
 */
export class GiftPb extends Message<GiftPb> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.GiftState state = 2;
   */
  state = GiftState.UNSPECIFIED;

  /**
   * @generated from field: librarian.proto.GiftStatus status = 3;
   */
  status = GiftStatus.UNSPECIFIED;

  /**
   * @generated from field: repeated librarian.proto.GiftPb.GiftProductVariant variants = 4;
   */
  variants: GiftPb_GiftProductVariant[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp sending_date = 5;
   */
  sendingDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.StringValue tracking_link = 6;
   */
  trackingLink?: string;

  /**
   * @generated from field: librarian.proto.GiftPb.GiftInfluencer influencer = 7;
   */
  influencer?: GiftPb_GiftInfluencer;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 8;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.StringValue id_shortcode = 9;
   */
  idShortcode?: string;

  /**
   * If proto has not recipient_address then, the gift is hand delivered
   *
   * @generated from field: librarian.proto.AddressFieldProto recipient_address = 10;
   */
  recipientAddress?: AddressFieldProto;

  /**
   * Status History will always be sorted DESC by updated_at
   *
   * @generated from field: librarian.proto.GiftStatusHistory status_history = 11;
   */
  statusHistory?: GiftStatusHistory;

  /**
   * @generated from field: librarian.proto.GiftPb.GiftShopifyStore shopify_store = 12 [deprecated = true];
   * @deprecated
   */
  shopifyStore?: GiftPb_GiftShopifyStore;

  /**
   * @generated from field: librarian.proto.GiftSender sender = 13;
   */
  sender?: GiftSender;

  /**
   * @generated from field: librarian.proto.EcommerceStorePb store = 14;
   */
  store?: EcommerceStorePb;

  /**
   * @generated from field: google.protobuf.StringValue order_url = 15;
   */
  orderUrl?: string;

  /**
   * @generated from field: string shipping_method = 16;
   */
  shippingMethod = "";

  /**
   * @generated from field: bool is_synced_with_ecommerce_store = 17;
   */
  isSyncedWithEcommerceStore = false;

  /**
   * @generated from field: google.protobuf.StringValue discount_code_reason = 18;
   */
  discountCodeReason?: string;

  constructor(data?: PartialMessage<GiftPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GiftPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(GiftState) },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(GiftStatus) },
    { no: 4, name: "variants", kind: "message", T: GiftPb_GiftProductVariant, repeated: true },
    { no: 5, name: "sending_date", kind: "message", T: Timestamp },
    { no: 6, name: "tracking_link", kind: "message", T: StringValue },
    { no: 7, name: "influencer", kind: "message", T: GiftPb_GiftInfluencer },
    { no: 8, name: "created_at", kind: "message", T: Timestamp },
    { no: 9, name: "id_shortcode", kind: "message", T: StringValue },
    { no: 10, name: "recipient_address", kind: "message", T: AddressFieldProto },
    { no: 11, name: "status_history", kind: "message", T: GiftStatusHistory },
    { no: 12, name: "shopify_store", kind: "message", T: GiftPb_GiftShopifyStore },
    { no: 13, name: "sender", kind: "message", T: GiftSender },
    { no: 14, name: "store", kind: "message", T: EcommerceStorePb },
    { no: 15, name: "order_url", kind: "message", T: StringValue },
    { no: 16, name: "shipping_method", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 17, name: "is_synced_with_ecommerce_store", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "discount_code_reason", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GiftPb {
    return new GiftPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GiftPb {
    return new GiftPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GiftPb {
    return new GiftPb().fromJsonString(jsonString, options);
  }

  static equals(a: GiftPb | PlainMessage<GiftPb> | undefined, b: GiftPb | PlainMessage<GiftPb> | undefined): boolean {
    return proto3.util.equals(GiftPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.GiftPb.GiftProductVariant
 */
export class GiftPb_GiftProductVariant extends Message<GiftPb_GiftProductVariant> {
  /**
   * @generated from field: librarian.proto.ProductVariantPb variant = 1;
   */
  variant?: ProductVariantPb;

  /**
   * @generated from field: int32 quantity = 2;
   */
  quantity = 0;

  /**
   * @generated from field: string asset_url = 3;
   */
  assetUrl = "";

  /**
   * @generated from field: string product_name = 4;
   */
  productName = "";

  constructor(data?: PartialMessage<GiftPb_GiftProductVariant>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GiftPb.GiftProductVariant";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "variant", kind: "message", T: ProductVariantPb },
    { no: 2, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "asset_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "product_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GiftPb_GiftProductVariant {
    return new GiftPb_GiftProductVariant().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GiftPb_GiftProductVariant {
    return new GiftPb_GiftProductVariant().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GiftPb_GiftProductVariant {
    return new GiftPb_GiftProductVariant().fromJsonString(jsonString, options);
  }

  static equals(a: GiftPb_GiftProductVariant | PlainMessage<GiftPb_GiftProductVariant> | undefined, b: GiftPb_GiftProductVariant | PlainMessage<GiftPb_GiftProductVariant> | undefined): boolean {
    return proto3.util.equals(GiftPb_GiftProductVariant, a, b);
  }
}

/**
 * @generated from message librarian.proto.GiftPb.GiftInfluencer
 */
export class GiftPb_GiftInfluencer extends Message<GiftPb_GiftInfluencer> {
  /**
   * @generated from field: string influencer_id = 1;
   */
  influencerId = "";

  /**
   * @generated from field: string influencer_name = 2;
   */
  influencerName = "";

  /**
   * @generated from field: string profile_pic_url = 3;
   */
  profilePicUrl = "";

  /**
   * @generated from field: string influencer_network_url = 4;
   */
  influencerNetworkUrl = "";

  /**
   * @generated from field: string email = 5 [deprecated = true];
   * @deprecated
   */
  email = "";

  constructor(data?: PartialMessage<GiftPb_GiftInfluencer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GiftPb.GiftInfluencer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "influencer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_pic_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "influencer_network_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GiftPb_GiftInfluencer {
    return new GiftPb_GiftInfluencer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GiftPb_GiftInfluencer {
    return new GiftPb_GiftInfluencer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GiftPb_GiftInfluencer {
    return new GiftPb_GiftInfluencer().fromJsonString(jsonString, options);
  }

  static equals(a: GiftPb_GiftInfluencer | PlainMessage<GiftPb_GiftInfluencer> | undefined, b: GiftPb_GiftInfluencer | PlainMessage<GiftPb_GiftInfluencer> | undefined): boolean {
    return proto3.util.equals(GiftPb_GiftInfluencer, a, b);
  }
}

/**
 * @generated from message librarian.proto.GiftPb.GiftShopifyStore
 */
export class GiftPb_GiftShopifyStore extends Message<GiftPb_GiftShopifyStore> {
  /**
   * @generated from field: string domain = 1 [deprecated = true];
   * @deprecated
   */
  domain = "";

  /**
   * @generated from field: string name = 2 [deprecated = true];
   * @deprecated
   */
  name = "";

  /**
   * @generated from field: string order_url = 3;
   */
  orderUrl = "";

  /**
   * @generated from field: librarian.proto.ShopifyStorePbV2 store = 4;
   */
  store?: ShopifyStorePbV2;

  constructor(data?: PartialMessage<GiftPb_GiftShopifyStore>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GiftPb.GiftShopifyStore";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "order_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "store", kind: "message", T: ShopifyStorePbV2 },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GiftPb_GiftShopifyStore {
    return new GiftPb_GiftShopifyStore().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GiftPb_GiftShopifyStore {
    return new GiftPb_GiftShopifyStore().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GiftPb_GiftShopifyStore {
    return new GiftPb_GiftShopifyStore().fromJsonString(jsonString, options);
  }

  static equals(a: GiftPb_GiftShopifyStore | PlainMessage<GiftPb_GiftShopifyStore> | undefined, b: GiftPb_GiftShopifyStore | PlainMessage<GiftPb_GiftShopifyStore> | undefined): boolean {
    return proto3.util.equals(GiftPb_GiftShopifyStore, a, b);
  }
}

/**
 * @generated from message librarian.proto.GiftStatusHistory
 */
export class GiftStatusHistory extends Message<GiftStatusHistory> {
  /**
   * @generated from field: repeated librarian.proto.GiftStatusChange log = 1;
   */
  log: GiftStatusChange[] = [];

  constructor(data?: PartialMessage<GiftStatusHistory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GiftStatusHistory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "log", kind: "message", T: GiftStatusChange, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GiftStatusHistory {
    return new GiftStatusHistory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GiftStatusHistory {
    return new GiftStatusHistory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GiftStatusHistory {
    return new GiftStatusHistory().fromJsonString(jsonString, options);
  }

  static equals(a: GiftStatusHistory | PlainMessage<GiftStatusHistory> | undefined, b: GiftStatusHistory | PlainMessage<GiftStatusHistory> | undefined): boolean {
    return proto3.util.equals(GiftStatusHistory, a, b);
  }
}

/**
 * @generated from message librarian.proto.GiftStatusChange
 */
export class GiftStatusChange extends Message<GiftStatusChange> {
  /**
   * @generated from field: librarian.proto.GiftStatus status = 1;
   */
  status = GiftStatus.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 2;
   */
  updatedAt?: Timestamp;

  constructor(data?: PartialMessage<GiftStatusChange>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GiftStatusChange";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(GiftStatus) },
    { no: 2, name: "updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GiftStatusChange {
    return new GiftStatusChange().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GiftStatusChange {
    return new GiftStatusChange().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GiftStatusChange {
    return new GiftStatusChange().fromJsonString(jsonString, options);
  }

  static equals(a: GiftStatusChange | PlainMessage<GiftStatusChange> | undefined, b: GiftStatusChange | PlainMessage<GiftStatusChange> | undefined): boolean {
    return proto3.util.equals(GiftStatusChange, a, b);
  }
}

/**
 * @generated from message librarian.proto.GiftSender
 */
export class GiftSender extends Message<GiftSender> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  constructor(data?: PartialMessage<GiftSender>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GiftSender";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GiftSender {
    return new GiftSender().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GiftSender {
    return new GiftSender().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GiftSender {
    return new GiftSender().fromJsonString(jsonString, options);
  }

  static equals(a: GiftSender | PlainMessage<GiftSender> | undefined, b: GiftSender | PlainMessage<GiftSender> | undefined): boolean {
    return proto3.util.equals(GiftSender, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateGiftsRequest
 */
export class CreateGiftsRequest extends Message<CreateGiftsRequest> {
  /**
   * @generated from field: repeated librarian.proto.CreateGiftsRequest.CreateGiftsProductVariant variants = 1;
   */
  variants: CreateGiftsRequest_CreateGiftsProductVariant[] = [];

  /**
   * @generated from field: repeated librarian.proto.CreateGiftsRequest.CreateGiftsRecipient recipients = 2;
   */
  recipients: CreateGiftsRequest_CreateGiftsRecipient[] = [];

  /**
   * Used to know if the gift will be synced with any external shop not just shopify
   *
   * @generated from field: bool create_shopify_order = 3;
   */
  createShopifyOrder = false;

  /**
   * @generated from field: int64 ecommerce_store_id = 4;
   */
  ecommerceStoreId = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.StringValue discount_code_reason = 5;
   */
  discountCodeReason?: string;

  /**
   * @generated from field: google.protobuf.StringValue shipping_method = 6;
   */
  shippingMethod?: string;

  /**
   * @generated from field: bool create_gifts_in_archivist = 7;
   */
  createGiftsInArchivist = false;

  /**
   * @generated from field: google.protobuf.Int64Value draft_gift_id = 8;
   */
  draftGiftId?: bigint;

  /**
   * @generated from field: bool hand_delivered = 9;
   */
  handDelivered = false;

  constructor(data?: PartialMessage<CreateGiftsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateGiftsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "variants", kind: "message", T: CreateGiftsRequest_CreateGiftsProductVariant, repeated: true },
    { no: 2, name: "recipients", kind: "message", T: CreateGiftsRequest_CreateGiftsRecipient, repeated: true },
    { no: 3, name: "create_shopify_order", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "ecommerce_store_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "discount_code_reason", kind: "message", T: StringValue },
    { no: 6, name: "shipping_method", kind: "message", T: StringValue },
    { no: 7, name: "create_gifts_in_archivist", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "draft_gift_id", kind: "message", T: Int64Value },
    { no: 9, name: "hand_delivered", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateGiftsRequest {
    return new CreateGiftsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateGiftsRequest {
    return new CreateGiftsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateGiftsRequest {
    return new CreateGiftsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateGiftsRequest | PlainMessage<CreateGiftsRequest> | undefined, b: CreateGiftsRequest | PlainMessage<CreateGiftsRequest> | undefined): boolean {
    return proto3.util.equals(CreateGiftsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateGiftsRequest.CreateGiftsRecipient
 */
export class CreateGiftsRequest_CreateGiftsRecipient extends Message<CreateGiftsRequest_CreateGiftsRecipient> {
  /**
   * @generated from field: google.protobuf.StringValue influencer_id = 1;
   */
  influencerId?: string;

  /**
   * recipient_address will not be set in case the gift is hand delivered
   *
   * @generated from field: librarian.proto.AddressFieldProto recipient_address = 2;
   */
  recipientAddress?: AddressFieldProto;

  /**
   * @generated from field: google.protobuf.StringValue tracking_link = 3;
   */
  trackingLink?: string;

  /**
   * @generated from field: string email = 4 [deprecated = true];
   * @deprecated
   */
  email = "";

  constructor(data?: PartialMessage<CreateGiftsRequest_CreateGiftsRecipient>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateGiftsRequest.CreateGiftsRecipient";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_id", kind: "message", T: StringValue },
    { no: 2, name: "recipient_address", kind: "message", T: AddressFieldProto },
    { no: 3, name: "tracking_link", kind: "message", T: StringValue },
    { no: 4, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateGiftsRequest_CreateGiftsRecipient {
    return new CreateGiftsRequest_CreateGiftsRecipient().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateGiftsRequest_CreateGiftsRecipient {
    return new CreateGiftsRequest_CreateGiftsRecipient().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateGiftsRequest_CreateGiftsRecipient {
    return new CreateGiftsRequest_CreateGiftsRecipient().fromJsonString(jsonString, options);
  }

  static equals(a: CreateGiftsRequest_CreateGiftsRecipient | PlainMessage<CreateGiftsRequest_CreateGiftsRecipient> | undefined, b: CreateGiftsRequest_CreateGiftsRecipient | PlainMessage<CreateGiftsRequest_CreateGiftsRecipient> | undefined): boolean {
    return proto3.util.equals(CreateGiftsRequest_CreateGiftsRecipient, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateGiftsRequest.CreateGiftsProductVariant
 */
export class CreateGiftsRequest_CreateGiftsProductVariant extends Message<CreateGiftsRequest_CreateGiftsProductVariant> {
  /**
   * @generated from field: librarian.proto.ProductVariantPb variant = 1;
   */
  variant?: ProductVariantPb;

  /**
   * @generated from field: int32 quantity = 2;
   */
  quantity = 0;

  constructor(data?: PartialMessage<CreateGiftsRequest_CreateGiftsProductVariant>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateGiftsRequest.CreateGiftsProductVariant";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "variant", kind: "message", T: ProductVariantPb },
    { no: 2, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateGiftsRequest_CreateGiftsProductVariant {
    return new CreateGiftsRequest_CreateGiftsProductVariant().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateGiftsRequest_CreateGiftsProductVariant {
    return new CreateGiftsRequest_CreateGiftsProductVariant().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateGiftsRequest_CreateGiftsProductVariant {
    return new CreateGiftsRequest_CreateGiftsProductVariant().fromJsonString(jsonString, options);
  }

  static equals(a: CreateGiftsRequest_CreateGiftsProductVariant | PlainMessage<CreateGiftsRequest_CreateGiftsProductVariant> | undefined, b: CreateGiftsRequest_CreateGiftsProductVariant | PlainMessage<CreateGiftsRequest_CreateGiftsProductVariant> | undefined): boolean {
    return proto3.util.equals(CreateGiftsRequest_CreateGiftsProductVariant, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateGiftsResponse
 */
export class CreateGiftsResponse extends Message<CreateGiftsResponse> {
  /**
   * @generated from field: repeated librarian.proto.GiftPb gifts = 1;
   */
  gifts: GiftPb[] = [];

  /**
   * @generated from field: string task_handle = 2;
   */
  taskHandle = "";

  constructor(data?: PartialMessage<CreateGiftsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateGiftsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "gifts", kind: "message", T: GiftPb, repeated: true },
    { no: 2, name: "task_handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateGiftsResponse {
    return new CreateGiftsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateGiftsResponse {
    return new CreateGiftsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateGiftsResponse {
    return new CreateGiftsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateGiftsResponse | PlainMessage<CreateGiftsResponse> | undefined, b: CreateGiftsResponse | PlainMessage<CreateGiftsResponse> | undefined): boolean {
    return proto3.util.equals(CreateGiftsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateGiftsTask
 */
export class CreateGiftsTask extends Message<CreateGiftsTask> {
  /**
   * @generated from field: librarian.proto.CreateGiftsRequest request = 1;
   */
  request?: CreateGiftsRequest;

  /**
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<CreateGiftsTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateGiftsTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request", kind: "message", T: CreateGiftsRequest },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateGiftsTask {
    return new CreateGiftsTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateGiftsTask {
    return new CreateGiftsTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateGiftsTask {
    return new CreateGiftsTask().fromJsonString(jsonString, options);
  }

  static equals(a: CreateGiftsTask | PlainMessage<CreateGiftsTask> | undefined, b: CreateGiftsTask | PlainMessage<CreateGiftsTask> | undefined): boolean {
    return proto3.util.equals(CreateGiftsTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateGiftsTaskOutput
 */
export class CreateGiftsTaskOutput extends Message<CreateGiftsTaskOutput> {
  /**
   * @generated from field: repeated librarian.proto.GiftPb gifts = 1;
   */
  gifts: GiftPb[] = [];

  /**
   * @generated from field: repeated string influencer_emails_failed = 2 [deprecated = true];
   * @deprecated
   */
  influencerEmailsFailed: string[] = [];

  /**
   * @generated from field: repeated librarian.proto.CreateGiftsTaskOutput.CreateGiftsTaskError errors = 3;
   */
  errors: CreateGiftsTaskOutput_CreateGiftsTaskError[] = [];

  constructor(data?: PartialMessage<CreateGiftsTaskOutput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateGiftsTaskOutput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "gifts", kind: "message", T: GiftPb, repeated: true },
    { no: 2, name: "influencer_emails_failed", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "errors", kind: "message", T: CreateGiftsTaskOutput_CreateGiftsTaskError, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateGiftsTaskOutput {
    return new CreateGiftsTaskOutput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateGiftsTaskOutput {
    return new CreateGiftsTaskOutput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateGiftsTaskOutput {
    return new CreateGiftsTaskOutput().fromJsonString(jsonString, options);
  }

  static equals(a: CreateGiftsTaskOutput | PlainMessage<CreateGiftsTaskOutput> | undefined, b: CreateGiftsTaskOutput | PlainMessage<CreateGiftsTaskOutput> | undefined): boolean {
    return proto3.util.equals(CreateGiftsTaskOutput, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateGiftsTaskOutput.CreateGiftsTaskError
 */
export class CreateGiftsTaskOutput_CreateGiftsTaskError extends Message<CreateGiftsTaskOutput_CreateGiftsTaskError> {
  /**
   * @generated from field: string influencer_username = 1;
   */
  influencerUsername = "";

  /**
   * @generated from field: string error_message = 2;
   */
  errorMessage = "";

  constructor(data?: PartialMessage<CreateGiftsTaskOutput_CreateGiftsTaskError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateGiftsTaskOutput.CreateGiftsTaskError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "error_message", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateGiftsTaskOutput_CreateGiftsTaskError {
    return new CreateGiftsTaskOutput_CreateGiftsTaskError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateGiftsTaskOutput_CreateGiftsTaskError {
    return new CreateGiftsTaskOutput_CreateGiftsTaskError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateGiftsTaskOutput_CreateGiftsTaskError {
    return new CreateGiftsTaskOutput_CreateGiftsTaskError().fromJsonString(jsonString, options);
  }

  static equals(a: CreateGiftsTaskOutput_CreateGiftsTaskError | PlainMessage<CreateGiftsTaskOutput_CreateGiftsTaskError> | undefined, b: CreateGiftsTaskOutput_CreateGiftsTaskError | PlainMessage<CreateGiftsTaskOutput_CreateGiftsTaskError> | undefined): boolean {
    return proto3.util.equals(CreateGiftsTaskOutput_CreateGiftsTaskError, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetInfluencerAddressesRequest
 */
export class GetInfluencerAddressesRequest extends Message<GetInfluencerAddressesRequest> {
  /**
   * @generated from field: string influencer_id = 1;
   */
  influencerId = "";

  constructor(data?: PartialMessage<GetInfluencerAddressesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetInfluencerAddressesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInfluencerAddressesRequest {
    return new GetInfluencerAddressesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInfluencerAddressesRequest {
    return new GetInfluencerAddressesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInfluencerAddressesRequest {
    return new GetInfluencerAddressesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetInfluencerAddressesRequest | PlainMessage<GetInfluencerAddressesRequest> | undefined, b: GetInfluencerAddressesRequest | PlainMessage<GetInfluencerAddressesRequest> | undefined): boolean {
    return proto3.util.equals(GetInfluencerAddressesRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetInfluencerAddressesResponse
 */
export class GetInfluencerAddressesResponse extends Message<GetInfluencerAddressesResponse> {
  /**
   * @generated from field: repeated librarian.proto.GetInfluencerAddressesResponse.InfluencerAddress influencer_addresses = 1;
   */
  influencerAddresses: GetInfluencerAddressesResponse_InfluencerAddress[] = [];

  /**
   * @generated from field: librarian.proto.GetInfluencerAddressesResponse.InfluencerEmail influencer_email = 2;
   */
  influencerEmail?: GetInfluencerAddressesResponse_InfluencerEmail;

  /**
   * @generated from field: librarian.proto.GetInfluencerAddressesResponse.InfluencerPhone influencer_phone = 3;
   */
  influencerPhone?: GetInfluencerAddressesResponse_InfluencerPhone;

  constructor(data?: PartialMessage<GetInfluencerAddressesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetInfluencerAddressesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_addresses", kind: "message", T: GetInfluencerAddressesResponse_InfluencerAddress, repeated: true },
    { no: 2, name: "influencer_email", kind: "message", T: GetInfluencerAddressesResponse_InfluencerEmail },
    { no: 3, name: "influencer_phone", kind: "message", T: GetInfluencerAddressesResponse_InfluencerPhone },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInfluencerAddressesResponse {
    return new GetInfluencerAddressesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInfluencerAddressesResponse {
    return new GetInfluencerAddressesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInfluencerAddressesResponse {
    return new GetInfluencerAddressesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetInfluencerAddressesResponse | PlainMessage<GetInfluencerAddressesResponse> | undefined, b: GetInfluencerAddressesResponse | PlainMessage<GetInfluencerAddressesResponse> | undefined): boolean {
    return proto3.util.equals(GetInfluencerAddressesResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetInfluencerAddressesResponse.InfluencerAddress
 */
export class GetInfluencerAddressesResponse_InfluencerAddress extends Message<GetInfluencerAddressesResponse_InfluencerAddress> {
  /**
   * @generated from field: librarian.proto.AddressFieldProto addresses = 1;
   */
  addresses?: AddressFieldProto;

  /**
   * @generated from field: string address_creator_field_name = 2;
   */
  addressCreatorFieldName = "";

  /**
   * @generated from field: int64 address_creator_field_id = 3;
   */
  addressCreatorFieldId = protoInt64.zero;

  constructor(data?: PartialMessage<GetInfluencerAddressesResponse_InfluencerAddress>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetInfluencerAddressesResponse.InfluencerAddress";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "addresses", kind: "message", T: AddressFieldProto },
    { no: 2, name: "address_creator_field_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "address_creator_field_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInfluencerAddressesResponse_InfluencerAddress {
    return new GetInfluencerAddressesResponse_InfluencerAddress().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInfluencerAddressesResponse_InfluencerAddress {
    return new GetInfluencerAddressesResponse_InfluencerAddress().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInfluencerAddressesResponse_InfluencerAddress {
    return new GetInfluencerAddressesResponse_InfluencerAddress().fromJsonString(jsonString, options);
  }

  static equals(a: GetInfluencerAddressesResponse_InfluencerAddress | PlainMessage<GetInfluencerAddressesResponse_InfluencerAddress> | undefined, b: GetInfluencerAddressesResponse_InfluencerAddress | PlainMessage<GetInfluencerAddressesResponse_InfluencerAddress> | undefined): boolean {
    return proto3.util.equals(GetInfluencerAddressesResponse_InfluencerAddress, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetInfluencerAddressesResponse.InfluencerPhone
 */
export class GetInfluencerAddressesResponse_InfluencerPhone extends Message<GetInfluencerAddressesResponse_InfluencerPhone> {
  /**
   * @generated from field: int64 phone_creator_field_id = 1;
   */
  phoneCreatorFieldId = protoInt64.zero;

  /**
   * @generated from field: string phone_number = 3;
   */
  phoneNumber = "";

  /**
   * @generated from field: string phone_creator_field_name = 13;
   */
  phoneCreatorFieldName = "";

  constructor(data?: PartialMessage<GetInfluencerAddressesResponse_InfluencerPhone>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetInfluencerAddressesResponse.InfluencerPhone";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "phone_creator_field_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "phone_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "phone_creator_field_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInfluencerAddressesResponse_InfluencerPhone {
    return new GetInfluencerAddressesResponse_InfluencerPhone().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInfluencerAddressesResponse_InfluencerPhone {
    return new GetInfluencerAddressesResponse_InfluencerPhone().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInfluencerAddressesResponse_InfluencerPhone {
    return new GetInfluencerAddressesResponse_InfluencerPhone().fromJsonString(jsonString, options);
  }

  static equals(a: GetInfluencerAddressesResponse_InfluencerPhone | PlainMessage<GetInfluencerAddressesResponse_InfluencerPhone> | undefined, b: GetInfluencerAddressesResponse_InfluencerPhone | PlainMessage<GetInfluencerAddressesResponse_InfluencerPhone> | undefined): boolean {
    return proto3.util.equals(GetInfluencerAddressesResponse_InfluencerPhone, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetInfluencerAddressesResponse.InfluencerEmail
 */
export class GetInfluencerAddressesResponse_InfluencerEmail extends Message<GetInfluencerAddressesResponse_InfluencerEmail> {
  /**
   * @generated from field: int64 email_creator_field_id = 1;
   */
  emailCreatorFieldId = protoInt64.zero;

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string email_creator_field_name = 13;
   */
  emailCreatorFieldName = "";

  constructor(data?: PartialMessage<GetInfluencerAddressesResponse_InfluencerEmail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetInfluencerAddressesResponse.InfluencerEmail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email_creator_field_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "email_creator_field_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetInfluencerAddressesResponse_InfluencerEmail {
    return new GetInfluencerAddressesResponse_InfluencerEmail().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetInfluencerAddressesResponse_InfluencerEmail {
    return new GetInfluencerAddressesResponse_InfluencerEmail().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetInfluencerAddressesResponse_InfluencerEmail {
    return new GetInfluencerAddressesResponse_InfluencerEmail().fromJsonString(jsonString, options);
  }

  static equals(a: GetInfluencerAddressesResponse_InfluencerEmail | PlainMessage<GetInfluencerAddressesResponse_InfluencerEmail> | undefined, b: GetInfluencerAddressesResponse_InfluencerEmail | PlainMessage<GetInfluencerAddressesResponse_InfluencerEmail> | undefined): boolean {
    return proto3.util.equals(GetInfluencerAddressesResponse_InfluencerEmail, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateGiftRequest
 */
export class UpdateGiftRequest extends Message<UpdateGiftRequest> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.GiftState state = 2;
   */
  state = GiftState.UNSPECIFIED;

  /**
   * @generated from field: librarian.proto.GiftStatus status = 3;
   */
  status = GiftStatus.UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp sending_date = 4;
   */
  sendingDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.StringValue tracking_link = 5;
   */
  trackingLink?: string;

  constructor(data?: PartialMessage<UpdateGiftRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateGiftRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(GiftState) },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(GiftStatus) },
    { no: 4, name: "sending_date", kind: "message", T: Timestamp },
    { no: 5, name: "tracking_link", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateGiftRequest {
    return new UpdateGiftRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateGiftRequest {
    return new UpdateGiftRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateGiftRequest {
    return new UpdateGiftRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateGiftRequest | PlainMessage<UpdateGiftRequest> | undefined, b: UpdateGiftRequest | PlainMessage<UpdateGiftRequest> | undefined): boolean {
    return proto3.util.equals(UpdateGiftRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateGiftResponse
 */
export class UpdateGiftResponse extends Message<UpdateGiftResponse> {
  /**
   * @generated from field: librarian.proto.GiftPb gift = 1;
   */
  gift?: GiftPb;

  constructor(data?: PartialMessage<UpdateGiftResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateGiftResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "gift", kind: "message", T: GiftPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateGiftResponse {
    return new UpdateGiftResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateGiftResponse {
    return new UpdateGiftResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateGiftResponse {
    return new UpdateGiftResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateGiftResponse | PlainMessage<UpdateGiftResponse> | undefined, b: UpdateGiftResponse | PlainMessage<UpdateGiftResponse> | undefined): boolean {
    return proto3.util.equals(UpdateGiftResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateGiftsInBulkRequest
 */
export class UpdateGiftsInBulkRequest extends Message<UpdateGiftsInBulkRequest> {
  /**
   * @generated from field: repeated int64 gift_ids = 1;
   */
  giftIds: bigint[] = [];

  /**
   * @generated from oneof librarian.proto.UpdateGiftsInBulkRequest.operation
   */
  operation: {
    /**
     * @generated from field: librarian.proto.GiftState state = 2;
     */
    value: GiftState;
    case: "state";
  } | {
    /**
     * @generated from field: librarian.proto.GiftStatus status = 3;
     */
    value: GiftStatus;
    case: "status";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<UpdateGiftsInBulkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateGiftsInBulkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "gift_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(GiftState), oneof: "operation" },
    { no: 3, name: "status", kind: "enum", T: proto3.getEnumType(GiftStatus), oneof: "operation" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateGiftsInBulkRequest {
    return new UpdateGiftsInBulkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateGiftsInBulkRequest {
    return new UpdateGiftsInBulkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateGiftsInBulkRequest {
    return new UpdateGiftsInBulkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateGiftsInBulkRequest | PlainMessage<UpdateGiftsInBulkRequest> | undefined, b: UpdateGiftsInBulkRequest | PlainMessage<UpdateGiftsInBulkRequest> | undefined): boolean {
    return proto3.util.equals(UpdateGiftsInBulkRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateGiftsInBulkResponse
 */
export class UpdateGiftsInBulkResponse extends Message<UpdateGiftsInBulkResponse> {
  /**
   * @generated from field: repeated librarian.proto.GiftPb gifts = 1;
   */
  gifts: GiftPb[] = [];

  constructor(data?: PartialMessage<UpdateGiftsInBulkResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateGiftsInBulkResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "gifts", kind: "message", T: GiftPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateGiftsInBulkResponse {
    return new UpdateGiftsInBulkResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateGiftsInBulkResponse {
    return new UpdateGiftsInBulkResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateGiftsInBulkResponse {
    return new UpdateGiftsInBulkResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateGiftsInBulkResponse | PlainMessage<UpdateGiftsInBulkResponse> | undefined, b: UpdateGiftsInBulkResponse | PlainMessage<UpdateGiftsInBulkResponse> | undefined): boolean {
    return proto3.util.equals(UpdateGiftsInBulkResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GiftsFilter
 */
export class GiftsFilter extends Message<GiftsFilter> {
  /**
   * @generated from field: repeated string influencer_ids = 1;
   */
  influencerIds: string[] = [];

  /**
   * @generated from field: librarian.proto.GiftState state = 2;
   */
  state = GiftState.UNSPECIFIED;

  /**
   * @generated from field: repeated librarian.proto.GiftStatus statuses = 3;
   */
  statuses: GiftStatus[] = [];

  /**
   * @generated from field: repeated int64 product_ids = 4;
   */
  productIds: bigint[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 5;
   */
  startDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_date = 6;
   */
  endDate?: Timestamp;

  /**
   * @generated from field: repeated int64 store_ids = 7;
   */
  storeIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 sender_user_ids = 8;
   */
  senderUserIds: bigint[] = [];

  /**
   * @generated from field: repeated string shipping_methods = 9;
   */
  shippingMethods: string[] = [];

  constructor(data?: PartialMessage<GiftsFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GiftsFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "state", kind: "enum", T: proto3.getEnumType(GiftState) },
    { no: 3, name: "statuses", kind: "enum", T: proto3.getEnumType(GiftStatus), repeated: true },
    { no: 4, name: "product_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 5, name: "start_date", kind: "message", T: Timestamp },
    { no: 6, name: "end_date", kind: "message", T: Timestamp },
    { no: 7, name: "store_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 8, name: "sender_user_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 9, name: "shipping_methods", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GiftsFilter {
    return new GiftsFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GiftsFilter {
    return new GiftsFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GiftsFilter {
    return new GiftsFilter().fromJsonString(jsonString, options);
  }

  static equals(a: GiftsFilter | PlainMessage<GiftsFilter> | undefined, b: GiftsFilter | PlainMessage<GiftsFilter> | undefined): boolean {
    return proto3.util.equals(GiftsFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.DraftGiftsFilter
 */
export class DraftGiftsFilter extends Message<DraftGiftsFilter> {
  /**
   * @generated from field: repeated string influencer_ids = 1;
   */
  influencerIds: string[] = [];

  /**
   * @generated from field: repeated int64 product_ids = 2;
   */
  productIds: bigint[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 3;
   */
  startDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end_date = 4;
   */
  endDate?: Timestamp;

  /**
   * @generated from field: repeated int64 store_ids = 5;
   */
  storeIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 sender_user_ids = 6;
   */
  senderUserIds: bigint[] = [];

  /**
   * @generated from field: repeated string shipping_methods = 7;
   */
  shippingMethods: string[] = [];

  constructor(data?: PartialMessage<DraftGiftsFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DraftGiftsFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "product_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 3, name: "start_date", kind: "message", T: Timestamp },
    { no: 4, name: "end_date", kind: "message", T: Timestamp },
    { no: 5, name: "store_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 6, name: "sender_user_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 7, name: "shipping_methods", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DraftGiftsFilter {
    return new DraftGiftsFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DraftGiftsFilter {
    return new DraftGiftsFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DraftGiftsFilter {
    return new DraftGiftsFilter().fromJsonString(jsonString, options);
  }

  static equals(a: DraftGiftsFilter | PlainMessage<DraftGiftsFilter> | undefined, b: DraftGiftsFilter | PlainMessage<DraftGiftsFilter> | undefined): boolean {
    return proto3.util.equals(DraftGiftsFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetGiftsRequest
 */
export class GetGiftsRequest extends Message<GetGiftsRequest> {
  /**
   * @generated from field: librarian.proto.GiftsFilter filter = 1;
   */
  filter?: GiftsFilter;

  /**
   * @generated from field: librarian.proto.GiftsSort sort = 2;
   */
  sort = GiftsSort.GIFT_SORT_UNKNOWN;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 3;
   */
  sortDirection = SortDirection.DESC;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<GetGiftsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetGiftsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: GiftsFilter },
    { no: 2, name: "sort", kind: "enum", T: proto3.getEnumType(GiftsSort) },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGiftsRequest {
    return new GetGiftsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGiftsRequest {
    return new GetGiftsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGiftsRequest {
    return new GetGiftsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetGiftsRequest | PlainMessage<GetGiftsRequest> | undefined, b: GetGiftsRequest | PlainMessage<GetGiftsRequest> | undefined): boolean {
    return proto3.util.equals(GetGiftsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetGiftsResponse
 */
export class GetGiftsResponse extends Message<GetGiftsResponse> {
  /**
   * @generated from field: repeated librarian.proto.GiftPb gift = 1;
   */
  gift: GiftPb[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<GetGiftsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetGiftsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "gift", kind: "message", T: GiftPb, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGiftsResponse {
    return new GetGiftsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGiftsResponse {
    return new GetGiftsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGiftsResponse {
    return new GetGiftsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetGiftsResponse | PlainMessage<GetGiftsResponse> | undefined, b: GetGiftsResponse | PlainMessage<GetGiftsResponse> | undefined): boolean {
    return proto3.util.equals(GetGiftsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.InitGetGiftListsResponse
 */
export class InitGetGiftListsResponse extends Message<InitGetGiftListsResponse> {
  /**
   * @generated from field: repeated librarian.proto.CreatorCardSnippet recipients = 1;
   */
  recipients: CreatorCardSnippet[] = [];

  /**
   * @generated from field: repeated librarian.proto.ProductVariantPb product_variants = 2;
   */
  productVariants: ProductVariantPb[] = [];

  /**
   * @generated from field: repeated librarian.proto.GiftSender senders = 3;
   */
  senders: GiftSender[] = [];

  /**
   * @generated from field: repeated librarian.proto.ShopifyStorePb stores = 4 [deprecated = true];
   * @deprecated
   */
  stores: ShopifyStorePb[] = [];

  /**
   * @generated from field: repeated librarian.proto.ShopifyStorePbV2 stores_v2 = 5 [deprecated = true];
   * @deprecated
   */
  storesV2: ShopifyStorePbV2[] = [];

  /**
   * @generated from field: repeated librarian.proto.EcommerceStorePb ecommerce_stores = 6;
   */
  ecommerceStores: EcommerceStorePb[] = [];

  /**
   * @generated from field: repeated string shipping_methods = 7;
   */
  shippingMethods: string[] = [];

  constructor(data?: PartialMessage<InitGetGiftListsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InitGetGiftListsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "recipients", kind: "message", T: CreatorCardSnippet, repeated: true },
    { no: 2, name: "product_variants", kind: "message", T: ProductVariantPb, repeated: true },
    { no: 3, name: "senders", kind: "message", T: GiftSender, repeated: true },
    { no: 4, name: "stores", kind: "message", T: ShopifyStorePb, repeated: true },
    { no: 5, name: "stores_v2", kind: "message", T: ShopifyStorePbV2, repeated: true },
    { no: 6, name: "ecommerce_stores", kind: "message", T: EcommerceStorePb, repeated: true },
    { no: 7, name: "shipping_methods", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitGetGiftListsResponse {
    return new InitGetGiftListsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitGetGiftListsResponse {
    return new InitGetGiftListsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitGetGiftListsResponse {
    return new InitGetGiftListsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitGetGiftListsResponse | PlainMessage<InitGetGiftListsResponse> | undefined, b: InitGetGiftListsResponse | PlainMessage<InitGetGiftListsResponse> | undefined): boolean {
    return proto3.util.equals(InitGetGiftListsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetGiftDetailsResponse
 */
export class GetGiftDetailsResponse extends Message<GetGiftDetailsResponse> {
  /**
   * @generated from field: librarian.proto.GiftPb gift = 1;
   */
  gift?: GiftPb;

  constructor(data?: PartialMessage<GetGiftDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetGiftDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "gift", kind: "message", T: GiftPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetGiftDetailsResponse {
    return new GetGiftDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetGiftDetailsResponse {
    return new GetGiftDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetGiftDetailsResponse {
    return new GetGiftDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetGiftDetailsResponse | PlainMessage<GetGiftDetailsResponse> | undefined, b: GetGiftDetailsResponse | PlainMessage<GetGiftDetailsResponse> | undefined): boolean {
    return proto3.util.equals(GetGiftDetailsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.ExportGiftsRequest
 */
export class ExportGiftsRequest extends Message<ExportGiftsRequest> {
  /**
   * @generated from field: librarian.proto.GiftsFilter filter = 1;
   */
  filter?: GiftsFilter;

  /**
   * Filled by backend
   *
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<ExportGiftsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ExportGiftsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: GiftsFilter },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportGiftsRequest {
    return new ExportGiftsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportGiftsRequest {
    return new ExportGiftsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportGiftsRequest {
    return new ExportGiftsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ExportGiftsRequest | PlainMessage<ExportGiftsRequest> | undefined, b: ExportGiftsRequest | PlainMessage<ExportGiftsRequest> | undefined): boolean {
    return proto3.util.equals(ExportGiftsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.DraftGiftPb
 */
export class DraftGiftPb extends Message<DraftGiftPb> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: repeated librarian.proto.DraftGiftPb.DraftGiftProductVariant variants = 2;
   */
  variants: DraftGiftPb_DraftGiftProductVariant[] = [];

  /**
   * @generated from field: repeated librarian.proto.DraftGiftPb.DraftGiftInfluencer influencers = 3;
   */
  influencers: DraftGiftPb_DraftGiftInfluencer[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 4;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: string id_shortcode = 5;
   */
  idShortcode = "";

  /**
   * @generated from field: librarian.proto.GiftSender sender = 6;
   */
  sender?: GiftSender;

  /**
   * @generated from field: librarian.proto.EcommerceStorePb store = 7;
   */
  store?: EcommerceStorePb;

  /**
   * @generated from field: google.protobuf.StringValue shipping_method = 8;
   */
  shippingMethod?: string;

  /**
   * @generated from field: bool is_synced_with_ecommerce_store = 9;
   */
  isSyncedWithEcommerceStore = false;

  /**
   * @generated from field: repeated librarian.proto.DraftGiftError errors = 10;
   */
  errors: DraftGiftError[] = [];

  /**
   * @generated from field: librarian.proto.DraftGiftPb.DraftGiftErrorsNames errors_names = 11;
   */
  errorsNames?: DraftGiftPb_DraftGiftErrorsNames;

  /**
   * @generated from field: google.protobuf.StringValue discount_code_reason = 12;
   */
  discountCodeReason?: string;

  /**
   * @generated from field: bool is_hand_delivered = 13;
   */
  isHandDelivered = false;

  constructor(data?: PartialMessage<DraftGiftPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DraftGiftPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "variants", kind: "message", T: DraftGiftPb_DraftGiftProductVariant, repeated: true },
    { no: 3, name: "influencers", kind: "message", T: DraftGiftPb_DraftGiftInfluencer, repeated: true },
    { no: 4, name: "created_at", kind: "message", T: Timestamp },
    { no: 5, name: "id_shortcode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "sender", kind: "message", T: GiftSender },
    { no: 7, name: "store", kind: "message", T: EcommerceStorePb },
    { no: 8, name: "shipping_method", kind: "message", T: StringValue },
    { no: 9, name: "is_synced_with_ecommerce_store", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "errors", kind: "enum", T: proto3.getEnumType(DraftGiftError), repeated: true },
    { no: 11, name: "errors_names", kind: "message", T: DraftGiftPb_DraftGiftErrorsNames },
    { no: 12, name: "discount_code_reason", kind: "message", T: StringValue },
    { no: 13, name: "is_hand_delivered", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DraftGiftPb {
    return new DraftGiftPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DraftGiftPb {
    return new DraftGiftPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DraftGiftPb {
    return new DraftGiftPb().fromJsonString(jsonString, options);
  }

  static equals(a: DraftGiftPb | PlainMessage<DraftGiftPb> | undefined, b: DraftGiftPb | PlainMessage<DraftGiftPb> | undefined): boolean {
    return proto3.util.equals(DraftGiftPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.DraftGiftPb.DraftGiftProductVariant
 */
export class DraftGiftPb_DraftGiftProductVariant extends Message<DraftGiftPb_DraftGiftProductVariant> {
  /**
   * @generated from field: librarian.proto.ProductVariantPb variant = 1;
   */
  variant?: ProductVariantPb;

  /**
   * @generated from field: int32 quantity = 2;
   */
  quantity = 0;

  /**
   * @generated from field: string asset_url = 3;
   */
  assetUrl = "";

  /**
   * @generated from field: string product_name = 4;
   */
  productName = "";

  constructor(data?: PartialMessage<DraftGiftPb_DraftGiftProductVariant>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DraftGiftPb.DraftGiftProductVariant";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "variant", kind: "message", T: ProductVariantPb },
    { no: 2, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "asset_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "product_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DraftGiftPb_DraftGiftProductVariant {
    return new DraftGiftPb_DraftGiftProductVariant().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DraftGiftPb_DraftGiftProductVariant {
    return new DraftGiftPb_DraftGiftProductVariant().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DraftGiftPb_DraftGiftProductVariant {
    return new DraftGiftPb_DraftGiftProductVariant().fromJsonString(jsonString, options);
  }

  static equals(a: DraftGiftPb_DraftGiftProductVariant | PlainMessage<DraftGiftPb_DraftGiftProductVariant> | undefined, b: DraftGiftPb_DraftGiftProductVariant | PlainMessage<DraftGiftPb_DraftGiftProductVariant> | undefined): boolean {
    return proto3.util.equals(DraftGiftPb_DraftGiftProductVariant, a, b);
  }
}

/**
 * @generated from message librarian.proto.DraftGiftPb.DraftGiftInfluencer
 */
export class DraftGiftPb_DraftGiftInfluencer extends Message<DraftGiftPb_DraftGiftInfluencer> {
  /**
   * @generated from field: string influencer_id = 1;
   */
  influencerId = "";

  /**
   * @generated from field: string influencer_name = 2;
   */
  influencerName = "";

  /**
   * @generated from field: string profile_pic_url = 3;
   */
  profilePicUrl = "";

  /**
   * @generated from field: librarian.proto.AddressFieldProto address = 4;
   */
  address?: AddressFieldProto;

  /**
   * @generated from field: google.protobuf.StringValue tracking_link = 5;
   */
  trackingLink?: string;

  /**
   * @generated from field: bool in_pool = 6;
   */
  inPool = false;

  constructor(data?: PartialMessage<DraftGiftPb_DraftGiftInfluencer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DraftGiftPb.DraftGiftInfluencer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "influencer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_pic_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "address", kind: "message", T: AddressFieldProto },
    { no: 5, name: "tracking_link", kind: "message", T: StringValue },
    { no: 6, name: "in_pool", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DraftGiftPb_DraftGiftInfluencer {
    return new DraftGiftPb_DraftGiftInfluencer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DraftGiftPb_DraftGiftInfluencer {
    return new DraftGiftPb_DraftGiftInfluencer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DraftGiftPb_DraftGiftInfluencer {
    return new DraftGiftPb_DraftGiftInfluencer().fromJsonString(jsonString, options);
  }

  static equals(a: DraftGiftPb_DraftGiftInfluencer | PlainMessage<DraftGiftPb_DraftGiftInfluencer> | undefined, b: DraftGiftPb_DraftGiftInfluencer | PlainMessage<DraftGiftPb_DraftGiftInfluencer> | undefined): boolean {
    return proto3.util.equals(DraftGiftPb_DraftGiftInfluencer, a, b);
  }
}

/**
 * @generated from message librarian.proto.DraftGiftPb.DraftGiftErrorsNames
 */
export class DraftGiftPb_DraftGiftErrorsNames extends Message<DraftGiftPb_DraftGiftErrorsNames> {
  /**
   * @generated from field: repeated string missing_products_names = 1;
   */
  missingProductsNames: string[] = [];

  /**
   * @generated from field: google.protobuf.StringValue missing_store_name = 2;
   */
  missingStoreName?: string;

  /**
   * @generated from field: google.protobuf.StringValue missing_shipping_method_name = 3;
   */
  missingShippingMethodName?: string;

  /**
   * @generated from field: repeated string missing_quantities_products_names = 4;
   */
  missingQuantitiesProductsNames: string[] = [];

  /**
   * @generated from field: repeated string missing_influencers_names = 5;
   */
  missingInfluencersNames: string[] = [];

  constructor(data?: PartialMessage<DraftGiftPb_DraftGiftErrorsNames>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DraftGiftPb.DraftGiftErrorsNames";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "missing_products_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "missing_store_name", kind: "message", T: StringValue },
    { no: 3, name: "missing_shipping_method_name", kind: "message", T: StringValue },
    { no: 4, name: "missing_quantities_products_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "missing_influencers_names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DraftGiftPb_DraftGiftErrorsNames {
    return new DraftGiftPb_DraftGiftErrorsNames().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DraftGiftPb_DraftGiftErrorsNames {
    return new DraftGiftPb_DraftGiftErrorsNames().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DraftGiftPb_DraftGiftErrorsNames {
    return new DraftGiftPb_DraftGiftErrorsNames().fromJsonString(jsonString, options);
  }

  static equals(a: DraftGiftPb_DraftGiftErrorsNames | PlainMessage<DraftGiftPb_DraftGiftErrorsNames> | undefined, b: DraftGiftPb_DraftGiftErrorsNames | PlainMessage<DraftGiftPb_DraftGiftErrorsNames> | undefined): boolean {
    return proto3.util.equals(DraftGiftPb_DraftGiftErrorsNames, a, b);
  }
}

/**
 * @generated from message librarian.proto.SaveGiftAsDraftRequest
 */
export class SaveGiftAsDraftRequest extends Message<SaveGiftAsDraftRequest> {
  /**
   * @generated from field: librarian.proto.CreateGiftsRequest request = 1;
   */
  request?: CreateGiftsRequest;

  constructor(data?: PartialMessage<SaveGiftAsDraftRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SaveGiftAsDraftRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request", kind: "message", T: CreateGiftsRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveGiftAsDraftRequest {
    return new SaveGiftAsDraftRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveGiftAsDraftRequest {
    return new SaveGiftAsDraftRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveGiftAsDraftRequest {
    return new SaveGiftAsDraftRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SaveGiftAsDraftRequest | PlainMessage<SaveGiftAsDraftRequest> | undefined, b: SaveGiftAsDraftRequest | PlainMessage<SaveGiftAsDraftRequest> | undefined): boolean {
    return proto3.util.equals(SaveGiftAsDraftRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.SaveGiftAsDraftResponse
 */
export class SaveGiftAsDraftResponse extends Message<SaveGiftAsDraftResponse> {
  /**
   * @generated from field: librarian.proto.DraftGiftPb draft_gift = 1;
   */
  draftGift?: DraftGiftPb;

  constructor(data?: PartialMessage<SaveGiftAsDraftResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SaveGiftAsDraftResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "draft_gift", kind: "message", T: DraftGiftPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SaveGiftAsDraftResponse {
    return new SaveGiftAsDraftResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SaveGiftAsDraftResponse {
    return new SaveGiftAsDraftResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SaveGiftAsDraftResponse {
    return new SaveGiftAsDraftResponse().fromJsonString(jsonString, options);
  }

  static equals(a: SaveGiftAsDraftResponse | PlainMessage<SaveGiftAsDraftResponse> | undefined, b: SaveGiftAsDraftResponse | PlainMessage<SaveGiftAsDraftResponse> | undefined): boolean {
    return proto3.util.equals(SaveGiftAsDraftResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateDraftGiftRequest
 */
export class UpdateDraftGiftRequest extends Message<UpdateDraftGiftRequest> {
  /**
   * @generated from field: int64 draft_gift_id = 1;
   */
  draftGiftId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.CreateGiftsRequest request = 2;
   */
  request?: CreateGiftsRequest;

  constructor(data?: PartialMessage<UpdateDraftGiftRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateDraftGiftRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "draft_gift_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "request", kind: "message", T: CreateGiftsRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDraftGiftRequest {
    return new UpdateDraftGiftRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDraftGiftRequest {
    return new UpdateDraftGiftRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDraftGiftRequest {
    return new UpdateDraftGiftRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDraftGiftRequest | PlainMessage<UpdateDraftGiftRequest> | undefined, b: UpdateDraftGiftRequest | PlainMessage<UpdateDraftGiftRequest> | undefined): boolean {
    return proto3.util.equals(UpdateDraftGiftRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateDraftGiftResponse
 */
export class UpdateDraftGiftResponse extends Message<UpdateDraftGiftResponse> {
  /**
   * @generated from field: librarian.proto.DraftGiftPb draft_gift = 1;
   */
  draftGift?: DraftGiftPb;

  constructor(data?: PartialMessage<UpdateDraftGiftResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateDraftGiftResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "draft_gift", kind: "message", T: DraftGiftPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateDraftGiftResponse {
    return new UpdateDraftGiftResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateDraftGiftResponse {
    return new UpdateDraftGiftResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateDraftGiftResponse {
    return new UpdateDraftGiftResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateDraftGiftResponse | PlainMessage<UpdateDraftGiftResponse> | undefined, b: UpdateDraftGiftResponse | PlainMessage<UpdateDraftGiftResponse> | undefined): boolean {
    return proto3.util.equals(UpdateDraftGiftResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.DeleteDraftGiftsInBulkRequest
 */
export class DeleteDraftGiftsInBulkRequest extends Message<DeleteDraftGiftsInBulkRequest> {
  /**
   * @generated from field: repeated int64 draft_gifts_ids = 1;
   */
  draftGiftsIds: bigint[] = [];

  constructor(data?: PartialMessage<DeleteDraftGiftsInBulkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DeleteDraftGiftsInBulkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "draft_gifts_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteDraftGiftsInBulkRequest {
    return new DeleteDraftGiftsInBulkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteDraftGiftsInBulkRequest {
    return new DeleteDraftGiftsInBulkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteDraftGiftsInBulkRequest {
    return new DeleteDraftGiftsInBulkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteDraftGiftsInBulkRequest | PlainMessage<DeleteDraftGiftsInBulkRequest> | undefined, b: DeleteDraftGiftsInBulkRequest | PlainMessage<DeleteDraftGiftsInBulkRequest> | undefined): boolean {
    return proto3.util.equals(DeleteDraftGiftsInBulkRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetDraftGiftsRequest
 */
export class GetDraftGiftsRequest extends Message<GetDraftGiftsRequest> {
  /**
   * @generated from field: librarian.proto.DraftGiftsFilter filter = 1;
   */
  filter?: DraftGiftsFilter;

  /**
   * @generated from field: librarian.proto.DraftGiftsSort sort = 2;
   */
  sort = DraftGiftsSort.DRAFT_GIFT_SORT_UNKNOWN;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 3;
   */
  sortDirection = SortDirection.DESC;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<GetDraftGiftsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetDraftGiftsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "filter", kind: "message", T: DraftGiftsFilter },
    { no: 2, name: "sort", kind: "enum", T: proto3.getEnumType(DraftGiftsSort) },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDraftGiftsRequest {
    return new GetDraftGiftsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDraftGiftsRequest {
    return new GetDraftGiftsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDraftGiftsRequest {
    return new GetDraftGiftsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetDraftGiftsRequest | PlainMessage<GetDraftGiftsRequest> | undefined, b: GetDraftGiftsRequest | PlainMessage<GetDraftGiftsRequest> | undefined): boolean {
    return proto3.util.equals(GetDraftGiftsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetDraftGiftsResponse
 */
export class GetDraftGiftsResponse extends Message<GetDraftGiftsResponse> {
  /**
   * @generated from field: repeated librarian.proto.DraftGiftPb draft_gifts = 1;
   */
  draftGifts: DraftGiftPb[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<GetDraftGiftsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetDraftGiftsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "draft_gifts", kind: "message", T: DraftGiftPb, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDraftGiftsResponse {
    return new GetDraftGiftsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDraftGiftsResponse {
    return new GetDraftGiftsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDraftGiftsResponse {
    return new GetDraftGiftsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetDraftGiftsResponse | PlainMessage<GetDraftGiftsResponse> | undefined, b: GetDraftGiftsResponse | PlainMessage<GetDraftGiftsResponse> | undefined): boolean {
    return proto3.util.equals(GetDraftGiftsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetDraftGiftDetailsResponse
 */
export class GetDraftGiftDetailsResponse extends Message<GetDraftGiftDetailsResponse> {
  /**
   * @generated from field: librarian.proto.DraftGiftPb draft_gift = 1;
   */
  draftGift?: DraftGiftPb;

  constructor(data?: PartialMessage<GetDraftGiftDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetDraftGiftDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "draft_gift", kind: "message", T: DraftGiftPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetDraftGiftDetailsResponse {
    return new GetDraftGiftDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetDraftGiftDetailsResponse {
    return new GetDraftGiftDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetDraftGiftDetailsResponse {
    return new GetDraftGiftDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetDraftGiftDetailsResponse | PlainMessage<GetDraftGiftDetailsResponse> | undefined, b: GetDraftGiftDetailsResponse | PlainMessage<GetDraftGiftDetailsResponse> | undefined): boolean {
    return proto3.util.equals(GetDraftGiftDetailsResponse, a, b);
  }
}


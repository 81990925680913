import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  input,
  Input,
} from '@angular/core';
import {
  isHiddenInMetricSettings,
  isNotEmptyArray,
  isNotNil,
  LeftyFormat,
  ProtoUtils,
} from '@frontend2/core';
import {
  AgesReport_BucketValue,
  AudienceReport,
} from '@frontend2/proto/common/proto/user_pb';
import {
  CastingAudienceMetricEnum,
  CastingAudienceMetricsPb,
} from '@frontend2/proto/librarian/proto/casting_pb';
import { LeftyComponent } from '../../utils';
import { NgIf, NgFor } from '@angular/common';
import { LeftySpinnerComponent } from '../../loading.component';

@Component({
  selector: 'casting-influencer-audience',
  templateUrl: './influencer-audience.component.html',
  styleUrls: ['./influencer-audience.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftySpinnerComponent, NgIf, NgFor],
})
export class CastingInfluencerAudienceComponent extends LeftyComponent {
  readonly loading = input(false);

  readonly audience = input(new AudienceReport());

  readonly castingAudienceOptions = input(new CastingAudienceMetricsPb());

  @HostBinding('class.not-responsive')
  @Input()
  notResponsive = false;

  readonly hasAudience = computed(
    () => ProtoUtils.isEmpty(this.audience()) === false,
  );

  @HostBinding('class.display-none')
  get audienceHidden(): boolean {
    return this.shouldHide();
  }

  readonly shouldHide = computed(
    () =>
      !this.loading() && (!this.hasAudience() || this.allMetricsAreHidden()),
  );

  readonly allMetricsAreHidden = computed(
    () =>
      !this.scoreIsVisible() &&
      !this.ageIsVisible() &&
      !this.genderIsVisible() &&
      !this.geoCitiesIsVisible() &&
      !this.geoCountriesIsVisible(),
  );

  formatPercentage(value: number): string {
    return LeftyFormat.percent(value, { maximumFractionDigits: 0 });
  }

  readonly femalePercentage = computed(() => {
    const percent = this.audience().femalePercentage / 100;
    return this.formatPercentage(percent);
  });

  readonly malePercentage = computed(() => {
    const percent = (100 - this.audience().femalePercentage) / 100;
    return this.formatPercentage(percent);
  });

  getAgeBucketName(bucket: AgesReport_BucketValue): string {
    return `${bucket.low} - ${bucket.high}`;
  }

  readonly ages = computed(() => {
    const buckets = this.audience().agesReport?.ageBucket ?? [];
    buckets.sort((a, b) => b.value - a.value);
    return buckets.slice(0, 3);
  });

  readonly topCountries = computed(() => {
    return this.audience().geographyReports?.report?.slice(0, 3) ?? [];
  });

  readonly topCities = computed(() => {
    return this.audience().topCities?.report?.slice(0, 3) ?? [];
  });

  readonly score = computed(() => {
    const val = this.audience().audienceQuality?.value ?? 0;
    return LeftyFormat.percent(val / 100);
  });

  readonly metrics = computed(
    () => this.castingAudienceOptions().audienceMetrics ?? [],
  );

  readonly scoreIsVisible = computed(
    () =>
      isNotNil(this.audience().audienceQuality?.value) &&
      !isHiddenInMetricSettings(
        CastingAudienceMetricEnum.CASTING_AUDIENCE_METRIC_SCORE,
        this.metrics(),
      ),
  );

  readonly genderIsVisible = computed(
    () =>
      isNotNil(this.audience().femalePercentage) &&
      this.audience().femalePercentage !== 0 &&
      !isHiddenInMetricSettings(
        CastingAudienceMetricEnum.CASTING_AUDIENCE_METRIC_GENDER,
        this.metrics(),
      ),
  );

  readonly ageIsVisible = computed(
    () =>
      isNotEmptyArray(this.audience().agesReport?.ageBucket) &&
      !isHiddenInMetricSettings(
        CastingAudienceMetricEnum.CASTING_AUDIENCE_METRIC_AGE,
        this.metrics(),
      ),
  );

  readonly geoCitiesIsVisible = computed(
    () =>
      isNotEmptyArray(this.audience().topCities?.report) &&
      !isHiddenInMetricSettings(
        CastingAudienceMetricEnum.CASTING_AUDIENCE_METRIC_GEO,
        this.metrics(),
      ),
  );

  readonly geoCountriesIsVisible = computed(
    () =>
      isNotEmptyArray(this.audience().geographyReports?.report) &&
      !isHiddenInMetricSettings(
        CastingAudienceMetricEnum.CASTING_AUDIENCE_METRIC_GEO,
        this.metrics(),
      ),
  );
}

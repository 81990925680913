// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/users.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Note, Notes, PortfolioReqV2 } from "./common_pb";
import { CreatorAutocompleteRequest, CreatorAutocompleteResponse, CreatorId, CreatorStatsReq, ExportInfluencerBrandSafetyRequest, FollowersTimeSeries, GetCreatorBasicResponse, GetCreatorsBasicRequest, GetCreatorsBasicResponse, GetCreatorStatsResponse, InfosResponse, InviteRequest, PortfolioResp, RefreshIntelligenceRequest, ResolveInfluencerReq, ResolveUserNameRespV2, SimilarCreatorsHistogram, UpdatePriceReq, UpdatePriceResp, UploadInfluencersListTaskRequest, UploadInfluencersListTaskResponse } from "./users_pb";
import { Empty, FloatValue, Int64Value, MethodKind, StringValue } from "@bufbuild/protobuf";
import { CreatorCampaignCards } from "./creators_pb";

/**
 * Service to manage influencers data, as well as their portfolio of media.
 *
 * @generated from service librarian.proto.LibrarianUsers
 */
export const LibrarianUsers = {
  typeName: "librarian.proto.LibrarianUsers",
  methods: {
    /**
     * gets the portfolio of an influencer. The request's 'network' field must be set as one may only
     * retrieve one network at a time.
     *
     * We may return all media in order, or a subset if filters are set in the request.
     *
     * @generated from rpc librarian.proto.LibrarianUsers.GetGenerikPortfolioAPIV2
     */
    getGenerikPortfolioAPIV2: {
      name: "GetGenerikPortfolioAPIV2",
      I: PortfolioReqV2,
      O: PortfolioResp,
      kind: MethodKind.Unary,
    },
    /**
     * Autocomplete on influencers names (all).
     *
     * @generated from rpc librarian.proto.LibrarianUsers.AutocompleteCreatorsV2API
     */
    autocompleteCreatorsV2API: {
      name: "AutocompleteCreatorsV2API",
      I: CreatorAutocompleteRequest,
      O: CreatorAutocompleteResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieves the basic profile of an influencer: bio, name, follower counts, etc...
     * The string is the influencer id. Getting by name is not possible.
     *
     * @generated from rpc librarian.proto.LibrarianUsers.GetCreatorBasicAPI
     */
    getCreatorBasicAPI: {
      name: "GetCreatorBasicAPI",
      I: StringValue,
      O: GetCreatorBasicResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieves the CreatorCardSnippet of influencers using the influencers lefty ids
     *
     * @generated from rpc librarian.proto.LibrarianUsers.GetCreatorsBasicAPI
     */
    getCreatorsBasicAPI: {
      name: "GetCreatorsBasicAPI",
      I: GetCreatorsBasicRequest,
      O: GetCreatorsBasicResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Retrieves the advanced stats of an influencer: engagement rates, post frequency, mentions, etc...
     * Powers the "stats" tab of an influencer's profile.
     *
     * The string is the influencer id. Getting by name is not possible.
     *
     * @generated from rpc librarian.proto.LibrarianUsers.GetCreatorStatsAPI
     */
    getCreatorStatsAPI: {
      name: "GetCreatorStatsAPI",
      I: StringValue,
      O: GetCreatorStatsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianUsers.GetCreatorStatsForCollaboratorAPI
     */
    getCreatorStatsForCollaboratorAPI: {
      name: "GetCreatorStatsForCollaboratorAPI",
      I: StringValue,
      O: GetCreatorStatsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianUsers.ResolveInfluencerAPIV2
     */
    resolveInfluencerAPIV2: {
      name: "ResolveInfluencerAPIV2",
      I: ResolveInfluencerReq,
      O: ResolveUserNameRespV2,
      kind: MethodKind.Unary,
    },
    /**
     * Refresh the stats of a user of the given id interactively: their last posts and stats will be retrieved
     * from the appropriate networks, and subsequently the intelligence computation will re-run.
     * returns completion token.
     *
     * The new version of refresh intelligence allows to give a hint of which network is currently displayed to user to
     * it can go first.
     *
     * @generated from rpc librarian.proto.LibrarianUsers.RefreshIntelligenceV2
     */
    refreshIntelligenceV2: {
      name: "RefreshIntelligenceV2",
      I: RefreshIntelligenceRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Returns the influencer's email if any.
     *
     * @generated from rpc librarian.proto.LibrarianUsers.GetInfluencerEmailAPI
     */
    getInfluencerEmailAPI: {
      name: "GetInfluencerEmailAPI",
      I: CreatorId,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * creates a new note on influencer of id 'value'.
     * returns the newly created note.
     *
     * @generated from rpc librarian.proto.LibrarianUsers.CreateNoteAPI
     */
    createNoteAPI: {
      name: "CreateNoteAPI",
      I: StringValue,
      O: Note,
      kind: MethodKind.Unary,
    },
    /**
     * deletes a note by its note id.
     *
     * @generated from rpc librarian.proto.LibrarianUsers.DeleteNoteAPI
     */
    deleteNoteAPI: {
      name: "DeleteNoteAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * update a note.
     *
     * @generated from rpc librarian.proto.LibrarianUsers.UpdateNoteAPI
     */
    updateNoteAPI: {
      name: "UpdateNoteAPI",
      I: Note,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * List notes that the logged in user has on the influencer of the given id.
     *
     * @generated from rpc librarian.proto.LibrarianUsers.ListNotesAPI
     */
    listNotesAPI: {
      name: "ListNotesAPI",
      I: StringValue,
      O: Notes,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianUsers.GetInfos
     */
    getInfos: {
      name: "GetInfos",
      I: StringValue,
      O: InfosResponse,
      kind: MethodKind.Unary,
    },
    /**
     * TODO(mateo) remove when frontend has been migrated
     *
     * @generated from rpc librarian.proto.LibrarianUsers.EditPrice
     * @deprecated
     */
    editPrice: {
      name: "EditPrice",
      I: UpdatePriceReq,
      O: FloatValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianUsers.EditPriceV2
     */
    editPriceV2: {
      name: "EditPriceV2",
      I: UpdatePriceReq,
      O: UpdatePriceResp,
      kind: MethodKind.Unary,
    },
    /**
     * Gets the campaigns that a given influencer is in.
     *
     * @generated from rpc librarian.proto.LibrarianUsers.GetCampaignsv2
     */
    getCampaignsv2: {
      name: "GetCampaignsv2",
      I: StringValue,
      O: CreatorCampaignCards,
      kind: MethodKind.Unary,
    },
    /**
     * Gets the evolution of a creator's followers over time. If the result is empty it means we have no historical data
     * on that influencer. The frontend can take further action to hide the data if there is not enough points to draw
     * a quality graph...
     *
     * Only the instagram network is supported at the moment as we do not have other networks ready.
     *
     * @generated from rpc librarian.proto.LibrarianUsers.GetFollowersSeries
     */
    getFollowersSeries: {
      name: "GetFollowersSeries",
      I: CreatorStatsReq,
      O: FollowersTimeSeries,
      kind: MethodKind.Unary,
    },
    /**
     * Gets the similar creators engagements of a specific creator as a histogram
     * the networks supported for the moment are instagram and tiktok
     * the similar creators are those who have a similar followers count
     *
     * @generated from rpc librarian.proto.LibrarianUsers.GetSimilarCreatorsEngagementsHistogram
     */
    getSimilarCreatorsEngagementsHistogram: {
      name: "GetSimilarCreatorsEngagementsHistogram",
      I: CreatorStatsReq,
      O: SimilarCreatorsHistogram,
      kind: MethodKind.Unary,
    },
    /**
     * Invite influencer to join your community via the dedicated landing page.
     *
     * @generated from rpc librarian.proto.LibrarianUsers.InviteInfluencer
     */
    inviteInfluencer: {
      name: "InviteInfluencer",
      I: InviteRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Trigger influencer profile PDF generation on Epson server
     * Request is lefty influencer id
     * Response is the url to download the PDF
     *
     * @generated from rpc librarian.proto.LibrarianUsers.GeneratePDF
     */
    generatePDF: {
      name: "GeneratePDF",
      I: StringValue,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Export an excel with brand safety results, based on considered sensitive topics selected
     * Return a task handles that frontend will listen to.
     *
     * @generated from rpc librarian.proto.LibrarianUsers.ExportInfluencerBrandSafety
     */
    exportInfluencerBrandSafety: {
      name: "ExportInfluencerBrandSafety",
      I: ExportInfluencerBrandSafetyRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Used to return list of influencers given their names (or URLs) and the network
     * Stores result of type UploadInfluencersListTaskOutput in the task output
     *
     * @generated from rpc librarian.proto.LibrarianUsers.UploadInfluencersListTaskAPI
     */
    uploadInfluencersListTaskAPI: {
      name: "UploadInfluencersListTaskAPI",
      I: UploadInfluencersListTaskRequest,
      O: UploadInfluencersListTaskResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;


import { CampaignKeywordType } from '@frontend2/proto/common/proto/campaign_pb';
import {
  CurrencyEnum,
  GenerikKeyword,
  InfluencerKind,
  KeywordType,
  Network,
  PostType,
} from '@frontend2/proto/common/proto/common_pb';
import { Networks, getEnumName, getEnumValues, getLocale } from '../../utils';

export function getNetworkOfPostType(postType: PostType): Network {
  const name = getEnumName(PostType, postType) ?? '';

  if (name.startsWith('IG_')) {
    return Network.INSTA;
  }
  if (name.startsWith('YT_')) {
    return Network.YOUTUBE;
  }
  if (name.startsWith('TW_')) {
    return Network.TWEET;
  }
  if (name.startsWith('TK_')) {
    return Network.TIK_TOK;
  }
  if (name.startsWith('FB_')) {
    return Network.FACEBOOK;
  }
  if (name.startsWith('WB_')) {
    return Network.WEIBO;
  }
  if (name.startsWith('WC_')) {
    return Network.WECHAT;
  }
  if (name.startsWith('RD_')) {
    return Network.RED;
  }
  if (name.startsWith('DY_')) {
    return Network.DOUYIN;
  }
  if (name.startsWith('SC_')) {
    return Network.SNAPCHAT;
  }

  return Network.NETWORK_UNKNOWN;
}

export function getPostTypesOfNetwork(network: Network): PostType[] {
  return getEnumValues<PostType>(PostType).filter(
    (t) => getNetworkOfPostType(t) === network,
  );
}

// TODO(hadrien): move to NetworkConfigs
export function getFilterablePostTypesOfNetwork(network: Network): PostType[] {
  if (isFoldedNetwork(network)) {
    return [getPostTypesOfNetwork(network)[0]];
  }

  return getPostTypesOfNetwork(network);
}

// TODO(hadrien): move to NetworkConfigs
export function getCpmPostTypesOfNetwork(network: Network): PostType[] {
  // specific case for Instagram. where Image share CPM with carousel
  if (network === Network.INSTA) {
    return [PostType.IG_IMAGE, PostType.IG_VIDEO, PostType.IG_STORY];
  }

  if (isFoldedNetwork(network)) {
    return [getPostTypesOfNetwork(network)[0]];
  }

  return getPostTypesOfNetwork(network);
}

export function readablePostType(postType: PostType): string {
  switch (postType) {
    case PostType.IG_IMAGE:
      return $localize`posts`;
    case PostType.IG_VIDEO:
      return $localize`videos`;
    case PostType.IG_STORY:
      return $localize`stories`;
    case PostType.IG_CAROUSEL:
      return $localize`carousels`;
    case PostType.YT_VIDEO:
      return $localize`videos`;
    case PostType.TW_TEXT:
      return $localize`tweets`;
    case PostType.TK_VIDEO:
      return $localize`posts`;
    case PostType.TK_CAROUSEL:
      return $localize`carousels`;
    case PostType.FB_TEXT:
      return 'text';
    case PostType.FB_IMAGE:
      return 'images';
    case PostType.FB_VIDEO:
      return $localize`videos`;
    case PostType.FB_CAROUSEL:
      return $localize`carousels`;
    case PostType.WB_TEXT:
      return $localize`text`;
    case PostType.WB_IMAGE:
      return $localize`images`;
    case PostType.WB_VIDEO:
      return $localize`videos`;
    case PostType.WB_CAROUSEL:
      return $localize`carousels`;
    case PostType.WB_STORY:
      return $localize`stories`;
    case PostType.WC_TEXT:
      return $localize`posts`;
    case PostType.WC_IMAGE:
      return $localize`images`;
    case PostType.WC_VIDEO:
      return $localize`videos`;
    case PostType.WC_CAROUSEL:
      return $localize`carousels`;
    case PostType.RD_TEXT:
      return $localize`text`;
    case PostType.RD_IMAGE:
      return $localize`images`;
    case PostType.RD_VIDEO:
      return $localize`videos`;
    case PostType.RD_CAROUSEL:
      return $localize`carousels`;
    case PostType.DY_VIDEO:
      return $localize`videos`;
    case PostType.SC_STORY:
      return $localize`stories`;
    case PostType.SC_SPOTLIGHT:
      return $localize`spotlights`;
    default:
      return '';
  }
}

export function getPostTypeIcon(p: PostType): string {
  switch (p) {
    case PostType.IG_IMAGE:
    case PostType.IG_CAROUSEL:
      return 'instagram_post';
    case PostType.IG_VIDEO:
      return 'instagram_video';
    case PostType.IG_STORY:
      return 'instagram_story';
    default:
      return Networks.getIcon(getNetworkOfPostType(p));
  }
}

export function getCurrencySymbolFromCode(code: string): string {
  return (
    Intl.NumberFormat(getLocale(), { style: 'currency', currency: code })
      .formatToParts()
      .find((part) => part.type === 'currency')?.value ?? ''
  );
}

export function getCurrencyCode(currency: CurrencyEnum): string {
  if (currency === CurrencyEnum.NA) {
    return '';
  }
  return getEnumName(CurrencyEnum, currency) ?? '';
}

export function getCurrencySymbol(currency: CurrencyEnum): string {
  const currencyCode = getCurrencyCode(currency);
  return getCurrencySymbolFromCode(currencyCode);
}

export function getCurrencyFullSymbol(currency: CurrencyEnum): string {
  if (currency === CurrencyEnum.NA) {
    return '';
  }
  const currencyCode = getCurrencyCode(currency);
  const currencySymbol = getCurrencySymbolFromCode(currencyCode);
  return `${currencyCode} (${currencySymbol})`;
}

export function allCurrenciesWithSort(): CurrencyEnum[] {
  const primaryCurruencies = [
    CurrencyEnum.USD,
    CurrencyEnum.EUR,
    CurrencyEnum.GBP,
  ];
  const otherCurrencuies = getEnumValues<CurrencyEnum>(CurrencyEnum)
    .splice(1)
    .filter((e) => !primaryCurruencies.includes(e));
  return [...primaryCurruencies, ...otherCurrencuies];
}

export function getNetworkPath(network: Network | undefined): string {
  switch (network) {
    case Network.INSTA:
      return 'instagram';
    case Network.TWEET:
      return 'twitter';
    case Network.TIK_TOK:
      return 'tiktok';
    default:
      return getEnumName(Network, network)?.toLowerCase() ?? '';
  }
}

export function campaignKeywordTypeIsKeyword(
  type: CampaignKeywordType,
): boolean {
  const word = CampaignKeywordType[type];
  return word.endsWith('_WORD') || word.endsWith('_KEYWORD');
}

export function campaignKeywordTypeIsMention(
  type: CampaignKeywordType,
): boolean {
  const word = CampaignKeywordType[type];
  return word.endsWith('_MENTION');
}

export function campaignKeywordTypeIsHashtag(
  type: CampaignKeywordType,
): boolean {
  const word = CampaignKeywordType[type];
  return word.endsWith('_TAG');
}

export function campaignKeywordTypeIsLocation(
  type: CampaignKeywordType,
): boolean {
  const word = CampaignKeywordType[type];
  return word.endsWith('_LOCATION');
}

export function getNetworkFromCampaignKeyword(
  type: CampaignKeywordType,
): Network {
  const name = getEnumName(CampaignKeywordType, type) ?? '';

  if (name.startsWith('INSTA_')) {
    return Network.INSTA;
  }
  if (name.startsWith('YOUTUBE_')) {
    return Network.YOUTUBE;
  }
  if (name.startsWith('TWITTER_')) {
    return Network.TWEET;
  }
  if (name.startsWith('TIKTOK_')) {
    return Network.TIK_TOK;
  }
  if (name.startsWith('WEIBO_')) {
    return Network.WEIBO;
  }
  if (name.startsWith('FACEBOOK_')) {
    return Network.FACEBOOK;
  }
  if (name.startsWith('WECHAT_')) {
    return Network.WECHAT;
  }
  if (name.startsWith('DOUYIN_')) {
    return Network.DOUYIN;
  }
  if (name.startsWith('RED_')) {
    return Network.RED;
  }
  if (name.startsWith('SNAPCHAT_')) {
    return Network.SNAPCHAT;
  }

  return Network.NETWORK_UNKNOWN;
}

export function getGenerikKeywordPrefix(type: KeywordType): string {
  switch (type) {
    case KeywordType.KW_MENTION:
      return '@';
    case KeywordType.KW_HASHTAG:
      return '#';
    default:
      return '';
  }
}

export function parseGenerikKeywordPrefix(str: string): KeywordType {
  switch (str) {
    case '@':
      return KeywordType.KW_MENTION;
    case '#':
      return KeywordType.KW_HASHTAG;
    // Note(hadrien): we should probably support more type
    // but right now we don't support location type
    default:
      return KeywordType.KW_KEYWORD;
  }
}

export function formatGenerikKeyword(opt: GenerikKeyword): string {
  return getGenerikKeywordPrefix(opt.keywordType) + opt.value;
}

export function parseGenerikKeywordFromString(str: string): GenerikKeyword {
  if (str.length < 2) {
    return new GenerikKeyword();
  }

  const prefix = str[0];
  let value = str;

  const keywordType = parseGenerikKeywordPrefix(prefix);
  if (keywordType !== KeywordType.KW_KEYWORD) {
    value = str.slice(1);
  }

  return new GenerikKeyword({
    keywordType,
    value,
  });
}

export function readableInfluencerKind(kind: InfluencerKind): string {
  switch (kind) {
    case InfluencerKind.DIGITAL_NATIVE:
      return $localize`Digital Native`;
    case InfluencerKind.VIP:
      return $localize`VIP`;
    case InfluencerKind.MEDIA:
      return $localize`Media`;
    case InfluencerKind.BUSINESS:
      return $localize`Business`;
    default:
      return '';
  }
}

// TODO(hadrien): move to NetworkConfigs
export function isFoldedNetwork(network: Network): boolean {
  switch (network) {
    case Network.TWEET:
    case Network.WECHAT:
    case Network.TIK_TOK:
    case Network.DOUYIN:
    case Network.YOUTUBE:
      return true;
    default:
      return false;
  }
}

<lefty-form>
  <div class="flex row vCenter input-range-container">
    <div
      class="input-container"
      [class.input-error]="isRangeError"
    >
      <lefty-form-number-input
        class="fill-space"
        placeholder="min"
        [value]="this.value.start"
        [allowFloat]="allowDouble"
        [size]="size"
        (valueChange)="setStart($event)"
      >
      </lefty-form-number-input>
    </div>
    <span
      class="to-label"
      i18n
      >{{ splitMessage }}</span
    >
    <div
      class="input-container"
      [class.input-error]="isRangeError"
    >
      <lefty-form-number-input
        class="fill-space"
        [value]="this.value.end"
        placeholder="max"
        [allowFloat]="allowDouble"
        [size]="size"
        (valueChange)="setEnd($event)"
      >
      </lefty-form-number-input>
    </div>
  </div>
  <span
    *ngIf="isRangeError"
    [class.msg-error]="isRangeError"
    i18n
    >Invalid range, min value must be strictly lower than max value</span
  >
</lefty-form>

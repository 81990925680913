// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/rfm_scoring.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { MetaIdPb } from "../../common/proto/creator_pb";

/**
 * @generated from enum librarian.proto.RfmScoreType
 */
export enum RfmScoreType {
  /**
   * @generated from enum value: RFM_SCORE_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * Time to last post (days)
   *
   * @generated from enum value: RFM_SCORE_TYPE_RECENCY = 1;
   */
  RECENCY = 1,

  /**
   * Nb of posts on the period
   *
   * @generated from enum value: RFM_SCORE_TYPE_FREQUENCY = 2;
   */
  FREQUENCY = 2,

  /**
   * @generated from enum value: RFM_SCORE_TYPE_MONETARY_EMV = 3;
   */
  MONETARY_EMV = 3,

  /**
   * @generated from enum value: RFM_SCORE_TYPE_MONETARY_ER = 4;
   */
  MONETARY_ER = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(RfmScoreType)
proto3.util.setEnumType(RfmScoreType, "librarian.proto.RfmScoreType", [
  { no: 0, name: "RFM_SCORE_TYPE_UNKNOWN" },
  { no: 1, name: "RFM_SCORE_TYPE_RECENCY" },
  { no: 2, name: "RFM_SCORE_TYPE_FREQUENCY" },
  { no: 3, name: "RFM_SCORE_TYPE_MONETARY_EMV" },
  { no: 4, name: "RFM_SCORE_TYPE_MONETARY_ER" },
]);

/**
 * @generated from enum librarian.proto.RfmScoreValue
 */
export enum RfmScoreValue {
  /**
   * If we have no score because there is no data at all or we haven't processed a new influencer yet
   *
   * @generated from enum value: RFM_SCORE_VALUE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: RFM_SCORE_VALUE_A = 1;
   */
  A = 1,

  /**
   * @generated from enum value: RFM_SCORE_VALUE_B = 2;
   */
  B = 2,

  /**
   * @generated from enum value: RFM_SCORE_VALUE_C = 3;
   */
  C = 3,

  /**
   * @generated from enum value: RFM_SCORE_VALUE_D = 4;
   */
  D = 4,

  /**
   * @generated from enum value: RFM_SCORE_VALUE_E = 5;
   */
  E = 5,

  /**
   * If we have no more data but had before cut off (2 years)
   *
   * @generated from enum value: RFM_SCORE_VALUE_INACTIVE = 6;
   */
  INACTIVE = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(RfmScoreValue)
proto3.util.setEnumType(RfmScoreValue, "librarian.proto.RfmScoreValue", [
  { no: 0, name: "RFM_SCORE_VALUE_UNKNOWN" },
  { no: 1, name: "RFM_SCORE_VALUE_A" },
  { no: 2, name: "RFM_SCORE_VALUE_B" },
  { no: 3, name: "RFM_SCORE_VALUE_C" },
  { no: 4, name: "RFM_SCORE_VALUE_D" },
  { no: 5, name: "RFM_SCORE_VALUE_E" },
  { no: 6, name: "RFM_SCORE_VALUE_INACTIVE" },
]);

/**
 * @generated from message librarian.proto.RfmQuantile
 */
export class RfmQuantile extends Message<RfmQuantile> {
  /**
   * @generated from field: float percentile = 1;
   */
  percentile = 0;

  /**
   * Using float as it can store anything we will be using
   *
   * @generated from field: float value = 2;
   */
  value = 0;

  constructor(data?: PartialMessage<RfmQuantile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RfmQuantile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "percentile", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RfmQuantile {
    return new RfmQuantile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RfmQuantile {
    return new RfmQuantile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RfmQuantile {
    return new RfmQuantile().fromJsonString(jsonString, options);
  }

  static equals(a: RfmQuantile | PlainMessage<RfmQuantile> | undefined, b: RfmQuantile | PlainMessage<RfmQuantile> | undefined): boolean {
    return proto3.util.equals(RfmQuantile, a, b);
  }
}

/**
 * @generated from message librarian.proto.RfmQuantilesList
 */
export class RfmQuantilesList extends Message<RfmQuantilesList> {
  /**
   * @generated from field: repeated librarian.proto.RfmQuantile quantiles = 1;
   */
  quantiles: RfmQuantile[] = [];

  constructor(data?: PartialMessage<RfmQuantilesList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RfmQuantilesList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "quantiles", kind: "message", T: RfmQuantile, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RfmQuantilesList {
    return new RfmQuantilesList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RfmQuantilesList {
    return new RfmQuantilesList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RfmQuantilesList {
    return new RfmQuantilesList().fromJsonString(jsonString, options);
  }

  static equals(a: RfmQuantilesList | PlainMessage<RfmQuantilesList> | undefined, b: RfmQuantilesList | PlainMessage<RfmQuantilesList> | undefined): boolean {
    return proto3.util.equals(RfmQuantilesList, a, b);
  }
}

/**
 * @generated from message librarian.proto.RfmScoreData
 */
export class RfmScoreData extends Message<RfmScoreData> {
  /**
   * @generated from field: librarian.proto.RfmScoreValue final_score = 1;
   */
  finalScore = RfmScoreValue.UNKNOWN;

  /**
   * @generated from field: librarian.proto.RfmScoreValue recency_score = 2;
   */
  recencyScore = RfmScoreValue.UNKNOWN;

  /**
   * @generated from field: float recency_value = 5;
   */
  recencyValue = 0;

  /**
   * @generated from field: librarian.proto.RfmScoreValue frequency_score = 3;
   */
  frequencyScore = RfmScoreValue.UNKNOWN;

  /**
   * @generated from field: float frequency_value = 6;
   */
  frequencyValue = 0;

  /**
   * @generated from field: librarian.proto.RfmScoreValue monetary_score = 4;
   */
  monetaryScore = RfmScoreValue.UNKNOWN;

  /**
   * @generated from field: float monetary_value = 7;
   */
  monetaryValue = 0;

  constructor(data?: PartialMessage<RfmScoreData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RfmScoreData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "final_score", kind: "enum", T: proto3.getEnumType(RfmScoreValue) },
    { no: 2, name: "recency_score", kind: "enum", T: proto3.getEnumType(RfmScoreValue) },
    { no: 5, name: "recency_value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "frequency_score", kind: "enum", T: proto3.getEnumType(RfmScoreValue) },
    { no: 6, name: "frequency_value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "monetary_score", kind: "enum", T: proto3.getEnumType(RfmScoreValue) },
    { no: 7, name: "monetary_value", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RfmScoreData {
    return new RfmScoreData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RfmScoreData {
    return new RfmScoreData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RfmScoreData {
    return new RfmScoreData().fromJsonString(jsonString, options);
  }

  static equals(a: RfmScoreData | PlainMessage<RfmScoreData> | undefined, b: RfmScoreData | PlainMessage<RfmScoreData> | undefined): boolean {
    return proto3.util.equals(RfmScoreData, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateRfmScoresReq
 */
export class UpdateRfmScoresReq extends Message<UpdateRfmScoresReq> {
  /**
   * @generated from oneof librarian.proto.UpdateRfmScoresReq.the_id
   */
  theId: {
    /**
     * @generated from field: int64 user_id = 1;
     */
    value: bigint;
    case: "userId";
  } | {
    /**
     * @generated from field: int64 campaign_id = 2;
     */
    value: bigint;
    case: "campaignId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: repeated common.proto.MetaIdPb influencers_ids = 3;
   */
  influencersIds: MetaIdPb[] = [];

  constructor(data?: PartialMessage<UpdateRfmScoresReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateRfmScoresReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "the_id" },
    { no: 2, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "the_id" },
    { no: 3, name: "influencers_ids", kind: "message", T: MetaIdPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateRfmScoresReq {
    return new UpdateRfmScoresReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateRfmScoresReq {
    return new UpdateRfmScoresReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateRfmScoresReq {
    return new UpdateRfmScoresReq().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateRfmScoresReq | PlainMessage<UpdateRfmScoresReq> | undefined, b: UpdateRfmScoresReq | PlainMessage<UpdateRfmScoresReq> | undefined): boolean {
    return proto3.util.equals(UpdateRfmScoresReq, a, b);
  }
}


import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'lefty-footer-info',
  templateUrl: 'lefty-footer-info.component.html',
  styleUrls: ['lefty-footer-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeftyFooterInfoComponent {
  get year(): number {
    return new Date().getFullYear();
  }
}

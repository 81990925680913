<lefty-form
  [label]="label()"
  [required]="required()"
  [optional]="optional()"
  [helpText]="helpText()"
  [helpLink]="helpLink()"
  [hintText]="hintText()"
  [errorMessage]="errorMessage"
  [tooltip]="tooltip()"
  [large]="large()"
  [topHintText]="topHintText()"
>
  <div class="inputs-container">
    <div
      #popupTrigger
      class="dialcode-container"
    >
      <lefty-form-input
        autocomplete="do-not-autofill"
        prefix="+"
        trailingGlyph="arrow_drop_down"
        [size]="size"
        placeholder="1"
        [value]="dialCodeValue()"
        (valueChange)="setDialCodeValue($event)"
        allowedChar="^[0-9]"
        [maxLength]="7"
        class="code-input"
      ></lefty-form-input>
    </div>

    <lefty-form-input
      autocomplete="do-not-autofill"
      [placeholder]="placeholder()"
      [size]="size"
      [value]="phoneNumberValue()"
      (valueChange)="setPhoneNumberValue($event)"
      allowedChar="^[0-9 +-]"
      class="fill-space number-input"
      (blur$)="onTouched()"
    ></lefty-form-input>
  </div>

  <lefty-popup
    #popup
    placement="bottom-start"
    [matchMinSourceWidth]="true"
    [popupTrigger]="popupTrigger"
    (visibleChange)="searchValue.set('')"
  >
    <div class="search-input">
      <lefty-form-input
        autocomplete="do-not-autofill"
        leadingGlyph="search"
        placeholder="Search by country"
        i18n-placeholder
        (valueChange)="searchValue.set($event)"
        [value]="searchValue()"
      ></lefty-form-input>
    </div>
    <lefty-list>
      <!-- FIXME: avoid tracking $index on filterable list-->
      @for (item of filteredOptions(); track $index) {
        <dial-code-list-item
          [countryDialCode]="item"
          (click)="setDialCodeValue(item.dial_code)"
        ></dial-code-list-item>
      }
    </lefty-list>
  </lefty-popup>
</lefty-form>

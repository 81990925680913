// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/user_brand.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Int64Value, Message, proto3, StringValue, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from message librarian.proto.UserBrandPb
 */
export class UserBrandPb extends Message<UserBrandPb> {
  /**
   * @generated from field: google.protobuf.Int64Value id = 1;
   */
  id?: bigint;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: google.protobuf.Int64Value small_logo_asset_id = 4;
   */
  smallLogoAssetId?: bigint;

  /**
   * @generated from field: google.protobuf.StringValue small_logo_asset_url = 8;
   */
  smallLogoAssetUrl?: string;

  /**
   * @generated from field: google.protobuf.Int64Value big_logo_asset_id = 5;
   */
  bigLogoAssetId?: bigint;

  /**
   * @generated from field: google.protobuf.StringValue big_logo_asset_url = 9;
   */
  bigLogoAssetUrl?: string;

  /**
   * @generated from field: bool primary_brand = 6;
   */
  primaryBrand = false;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 7;
   */
  createdAt?: Timestamp;

  constructor(data?: PartialMessage<UserBrandPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UserBrandPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "message", T: Int64Value },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "small_logo_asset_id", kind: "message", T: Int64Value },
    { no: 8, name: "small_logo_asset_url", kind: "message", T: StringValue },
    { no: 5, name: "big_logo_asset_id", kind: "message", T: Int64Value },
    { no: 9, name: "big_logo_asset_url", kind: "message", T: StringValue },
    { no: 6, name: "primary_brand", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "created_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserBrandPb {
    return new UserBrandPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserBrandPb {
    return new UserBrandPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserBrandPb {
    return new UserBrandPb().fromJsonString(jsonString, options);
  }

  static equals(a: UserBrandPb | PlainMessage<UserBrandPb> | undefined, b: UserBrandPb | PlainMessage<UserBrandPb> | undefined): boolean {
    return proto3.util.equals(UserBrandPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetUserBrandsResponse
 */
export class GetUserBrandsResponse extends Message<GetUserBrandsResponse> {
  /**
   * @generated from field: repeated librarian.proto.UserBrandPb user_brands = 1;
   */
  userBrands: UserBrandPb[] = [];

  constructor(data?: PartialMessage<GetUserBrandsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetUserBrandsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_brands", kind: "message", T: UserBrandPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserBrandsResponse {
    return new GetUserBrandsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserBrandsResponse {
    return new GetUserBrandsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserBrandsResponse {
    return new GetUserBrandsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserBrandsResponse | PlainMessage<GetUserBrandsResponse> | undefined, b: GetUserBrandsResponse | PlainMessage<GetUserBrandsResponse> | undefined): boolean {
    return proto3.util.equals(GetUserBrandsResponse, a, b);
  }
}


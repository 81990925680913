// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/chargebee.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, StringValue } from "@bufbuild/protobuf";

/**
 * @generated from message common.proto.Billing
 */
export class Billing extends Message<Billing> {
  /**
   * @generated from field: google.protobuf.StringValue company = 1;
   */
  company?: string;

  /**
   * @generated from field: google.protobuf.StringValue street_line_1 = 2;
   */
  streetLine1?: string;

  /**
   * @generated from field: google.protobuf.StringValue street_line_2 = 3;
   */
  streetLine2?: string;

  /**
   * @generated from field: google.protobuf.StringValue city = 4;
   */
  city?: string;

  /**
   * @generated from field: google.protobuf.StringValue zip = 5;
   */
  zip?: string;

  /**
   * @generated from field: google.protobuf.StringValue country = 6;
   */
  country?: string;

  /**
   * @generated from field: google.protobuf.StringValue state = 7;
   */
  state?: string;

  /**
   * @generated from field: google.protobuf.StringValue vat_number = 8;
   */
  vatNumber?: string;

  constructor(data?: PartialMessage<Billing>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Billing";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "company", kind: "message", T: StringValue },
    { no: 2, name: "street_line_1", kind: "message", T: StringValue },
    { no: 3, name: "street_line_2", kind: "message", T: StringValue },
    { no: 4, name: "city", kind: "message", T: StringValue },
    { no: 5, name: "zip", kind: "message", T: StringValue },
    { no: 6, name: "country", kind: "message", T: StringValue },
    { no: 7, name: "state", kind: "message", T: StringValue },
    { no: 8, name: "vat_number", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Billing {
    return new Billing().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Billing {
    return new Billing().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Billing {
    return new Billing().fromJsonString(jsonString, options);
  }

  static equals(a: Billing | PlainMessage<Billing> | undefined, b: Billing | PlainMessage<Billing> | undefined): boolean {
    return proto3.util.equals(Billing, a, b);
  }
}


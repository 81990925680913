import { Injectable, Inject, computed } from '@angular/core';
import { Empty } from '@bufbuild/protobuf';
import { ListCompaniesResponse } from '@frontend2/proto/librarian/proto/admin_pb';
import { CacheBloc } from '../bloc';
import {
  LibrarianAdminClient,
  LibrarianAdminClientProvider,
} from '@frontend2/api';

@Injectable({ providedIn: 'root' })
export class CompaniesCache extends CacheBloc<ListCompaniesResponse> {
  constructor(
    @Inject(LibrarianAdminClientProvider)
    private librarian: LibrarianAdminClient,
  ) {
    super(new ListCompaniesResponse());
  }

  readonly companiesList = computed(() => this.cachedData().companies);

  override async fetch(): Promise<ListCompaniesResponse> {
    return await this.librarian.listCompaniesAPI(new Empty());
  }
}

import { Inject, inject, Injectable, InjectionToken } from '@angular/core';
import {
  createPromiseClient,
  Interceptor,
  PromiseClient,
  StreamResponse,
  UnaryResponse,
} from '@connectrpc/connect';
import { LibrarianCastingsCollaborators } from '@frontend2/proto/librarian/proto/casting_connect';
import { GRPC_INTERCEPTORS, GRPC_TRANSPORT } from './grpc.service';
import { isNotEmptyString } from '@frontend2/core';

const CASTING_AUTH_HEADER = 'lefty-collaborator-casting-token';

@Injectable({ providedIn: 'root' })
export class LibrarianCastingAuth {
  public token = '';

  constructor(@Inject(GRPC_INTERCEPTORS) interceptors: Interceptor[]) {
    interceptors.push(this.interceptor);
  }

  private readonly interceptor: Interceptor = (next) => {
    return (req): Promise<UnaryResponse | StreamResponse> => {
      if (isNotEmptyString(this.token)) {
        req.header.set(CASTING_AUTH_HEADER, this.token);
      }

      return next(req);
    };
  };
}

export type LibrarianCastingsCollaboratorsClient = PromiseClient<
  typeof LibrarianCastingsCollaborators
>;

export const LibrarianCastingsCollaboratorsClientProvider = new InjectionToken(
  'LibrarianCastingsCollaboratorsClient',
  {
    factory: (): LibrarianCastingsCollaboratorsClient =>
      createPromiseClient(
        LibrarianCastingsCollaborators,
        inject(GRPC_TRANSPORT),
      ),
  },
);

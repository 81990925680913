// This file defines protocol buffers for the RED stuff that gets persisted.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/red.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ComputedGenerikStats, PostType } from "./common_pb";
import { PostInteractions } from "./campaign_pb";
import { GenerikMediaFacts } from "./media_pb";

/**
 * @generated from message common.proto.RedCreatorCounts
 */
export class RedCreatorCounts extends Message<RedCreatorCounts> {
  /**
   * int64: there are a lots of people in China :)
   *
   * @generated from field: int64 fans = 1;
   */
  fans = protoInt64.zero;

  /**
   * @generated from field: int64 follows = 2;
   */
  follows = protoInt64.zero;

  /**
   * @generated from field: int64 liked = 3;
   */
  liked = protoInt64.zero;

  /**
   * @generated from field: int64 collected = 4;
   */
  collected = protoInt64.zero;

  constructor(data?: PartialMessage<RedCreatorCounts>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.RedCreatorCounts";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fans", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "follows", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "liked", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "collected", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RedCreatorCounts {
    return new RedCreatorCounts().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RedCreatorCounts {
    return new RedCreatorCounts().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RedCreatorCounts {
    return new RedCreatorCounts().fromJsonString(jsonString, options);
  }

  static equals(a: RedCreatorCounts | PlainMessage<RedCreatorCounts> | undefined, b: RedCreatorCounts | PlainMessage<RedCreatorCounts> | undefined): boolean {
    return proto3.util.equals(RedCreatorCounts, a, b);
  }
}

/**
 * @generated from message common.proto.RedCreator
 */
export class RedCreator extends Message<RedCreator> {
  /**
   * uuid provided by red
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string nickname = 2;
   */
  nickname = "";

  /**
   * id provided by red which can be used for some API / Urls directly. Different from uuid.
   *
   * @generated from field: string red_id = 3;
   */
  redId = "";

  /**
   * @generated from field: string desc = 4;
   */
  desc = "";

  /**
   * @generated from field: string link = 5;
   */
  link = "";

  /**
   * Avatar
   *
   * @generated from field: string image = 6;
   */
  image = "";

  /**
   * @generated from field: int32 gender = 7;
   */
  gender = 0;

  /**
   * @generated from field: bool official_verified = 8;
   */
  officialVerified = false;

  /**
   * @generated from field: string red_official_verify_content = 12;
   */
  redOfficialVerifyContent = "";

  /**
   * @generated from field: int32 red_official_verify_type = 13;
   */
  redOfficialVerifyType = 0;

  /**
   * @generated from field: string location = 10;
   */
  location = "";

  /**
   * @generated from field: bool blocked = 11;
   */
  blocked = false;

  /**
   * @generated from field: common.proto.RedCreatorCounts counts = 9;
   */
  counts?: RedCreatorCounts;

  /**
   * @generated from field: repeated common.proto.RedCreator.RedCreatorTag tags = 21;
   */
  tags: RedCreator_RedCreatorTag[] = [];

  /**
   * @generated from field: repeated common.proto.RedCreator.RedCreatorInteractions interactions = 22;
   */
  interactions: RedCreator_RedCreatorInteractions[] = [];

  /**
   * @generated from field: common.proto.ComputedGenerikStats computed_stats = 20;
   */
  computedStats?: ComputedGenerikStats;

  constructor(data?: PartialMessage<RedCreator>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.RedCreator";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "red_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "desc", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "image", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "gender", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "official_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "red_official_verify_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "red_official_verify_type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "blocked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "counts", kind: "message", T: RedCreatorCounts },
    { no: 21, name: "tags", kind: "message", T: RedCreator_RedCreatorTag, repeated: true },
    { no: 22, name: "interactions", kind: "message", T: RedCreator_RedCreatorInteractions, repeated: true },
    { no: 20, name: "computed_stats", kind: "message", T: ComputedGenerikStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RedCreator {
    return new RedCreator().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RedCreator {
    return new RedCreator().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RedCreator {
    return new RedCreator().fromJsonString(jsonString, options);
  }

  static equals(a: RedCreator | PlainMessage<RedCreator> | undefined, b: RedCreator | PlainMessage<RedCreator> | undefined): boolean {
    return proto3.util.equals(RedCreator, a, b);
  }
}

/**
 * @generated from message common.proto.RedCreator.RedCreatorTag
 */
export class RedCreator_RedCreatorTag extends Message<RedCreator_RedCreatorTag> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string tag_type = 2;
   */
  tagType = "";

  constructor(data?: PartialMessage<RedCreator_RedCreatorTag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.RedCreator.RedCreatorTag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tag_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RedCreator_RedCreatorTag {
    return new RedCreator_RedCreatorTag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RedCreator_RedCreatorTag {
    return new RedCreator_RedCreatorTag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RedCreator_RedCreatorTag {
    return new RedCreator_RedCreatorTag().fromJsonString(jsonString, options);
  }

  static equals(a: RedCreator_RedCreatorTag | PlainMessage<RedCreator_RedCreatorTag> | undefined, b: RedCreator_RedCreatorTag | PlainMessage<RedCreator_RedCreatorTag> | undefined): boolean {
    return proto3.util.equals(RedCreator_RedCreatorTag, a, b);
  }
}

/**
 * @generated from message common.proto.RedCreator.RedCreatorInteractions
 */
export class RedCreator_RedCreatorInteractions extends Message<RedCreator_RedCreatorInteractions> {
  /**
   * @generated from field: int64 count = 1;
   */
  count = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  constructor(data?: PartialMessage<RedCreator_RedCreatorInteractions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.RedCreator.RedCreatorInteractions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RedCreator_RedCreatorInteractions {
    return new RedCreator_RedCreatorInteractions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RedCreator_RedCreatorInteractions {
    return new RedCreator_RedCreatorInteractions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RedCreator_RedCreatorInteractions {
    return new RedCreator_RedCreatorInteractions().fromJsonString(jsonString, options);
  }

  static equals(a: RedCreator_RedCreatorInteractions | PlainMessage<RedCreator_RedCreatorInteractions> | undefined, b: RedCreator_RedCreatorInteractions | PlainMessage<RedCreator_RedCreatorInteractions> | undefined): boolean {
    return proto3.util.equals(RedCreator_RedCreatorInteractions, a, b);
  }
}

/**
 * @generated from message common.proto.RedMediaMetadata
 */
export class RedMediaMetadata extends Message<RedMediaMetadata> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string creator_id = 2;
   */
  creatorId = "";

  /**
   * @generated from field: common.proto.PostType post_type = 3;
   */
  postType = PostType.POST_TYPE_UNDEFINED;

  /**
   * @generated from field: google.protobuf.Timestamp creation_time = 4;
   */
  creationTime?: Timestamp;

  /**
   * @generated from field: string text = 5;
   */
  text = "";

  /**
   * @generated from field: string title = 6;
   */
  title = "";

  /**
   * normal, video...
   *
   * @generated from field: string type = 7;
   */
  type = "";

  /**
   * Pinned post, can be quite old and need to be ignored for post polling exit conditions
   *
   * @generated from field: bool sticky = 8;
   */
  sticky = false;

  /**
   * @generated from field: google.protobuf.Timestamp last_update_time = 9;
   */
  lastUpdateTime?: Timestamp;

  /**
   * @generated from field: common.proto.RedMediaMetadata.RedMediaPoi poi = 10;
   */
  poi?: RedMediaMetadata_RedMediaPoi;

  /**
   * @generated from field: repeated common.proto.RedMediaMetadata.RedMediaImage images_list = 20;
   */
  imagesList: RedMediaMetadata_RedMediaImage[] = [];

  /**
   * @generated from field: common.proto.RedMediaMetadata.RedMediaVideo video = 21;
   */
  video?: RedMediaMetadata_RedMediaVideo;

  /**
   * @generated from field: repeated string mentions = 30;
   */
  mentions: string[] = [];

  /**
   * @generated from field: repeated string tags = 31;
   */
  tags: string[] = [];

  /**
   * @generated from field: common.proto.PostInteractions interactions = 100;
   */
  interactions?: PostInteractions;

  /**
   * @generated from field: common.proto.RedMediaStatistics statistics = 101;
   */
  statistics?: RedMediaStatistics;

  constructor(data?: PartialMessage<RedMediaMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.RedMediaMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "creator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType) },
    { no: 4, name: "creation_time", kind: "message", T: Timestamp },
    { no: 5, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "sticky", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "last_update_time", kind: "message", T: Timestamp },
    { no: 10, name: "poi", kind: "message", T: RedMediaMetadata_RedMediaPoi },
    { no: 20, name: "images_list", kind: "message", T: RedMediaMetadata_RedMediaImage, repeated: true },
    { no: 21, name: "video", kind: "message", T: RedMediaMetadata_RedMediaVideo },
    { no: 30, name: "mentions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 31, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 100, name: "interactions", kind: "message", T: PostInteractions },
    { no: 101, name: "statistics", kind: "message", T: RedMediaStatistics },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RedMediaMetadata {
    return new RedMediaMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RedMediaMetadata {
    return new RedMediaMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RedMediaMetadata {
    return new RedMediaMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: RedMediaMetadata | PlainMessage<RedMediaMetadata> | undefined, b: RedMediaMetadata | PlainMessage<RedMediaMetadata> | undefined): boolean {
    return proto3.util.equals(RedMediaMetadata, a, b);
  }
}

/**
 * @generated from message common.proto.RedMediaMetadata.RedMediaImage
 */
export class RedMediaMetadata_RedMediaImage extends Message<RedMediaMetadata_RedMediaImage> {
  /**
   * @generated from field: string fileid = 1;
   */
  fileid = "";

  /**
   * @generated from field: int32 height = 2;
   */
  height = 0;

  /**
   * @generated from field: string original = 3;
   */
  original = "";

  /**
   * @generated from field: string trace_id = 4;
   */
  traceId = "";

  /**
   * @generated from field: string url = 5;
   */
  url = "";

  /**
   * @generated from field: string url_size_large = 6;
   */
  urlSizeLarge = "";

  /**
   * @generated from field: int32 width = 7;
   */
  width = 0;

  /**
   * @generated from field: common.proto.RedMediaMetadata.RedMediaImage.RedMediaImageUrlMultiLevel url_multi_level = 8;
   */
  urlMultiLevel?: RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel;

  constructor(data?: PartialMessage<RedMediaMetadata_RedMediaImage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.RedMediaMetadata.RedMediaImage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fileid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "original", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "trace_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "url_size_large", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "url_multi_level", kind: "message", T: RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RedMediaMetadata_RedMediaImage {
    return new RedMediaMetadata_RedMediaImage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RedMediaMetadata_RedMediaImage {
    return new RedMediaMetadata_RedMediaImage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RedMediaMetadata_RedMediaImage {
    return new RedMediaMetadata_RedMediaImage().fromJsonString(jsonString, options);
  }

  static equals(a: RedMediaMetadata_RedMediaImage | PlainMessage<RedMediaMetadata_RedMediaImage> | undefined, b: RedMediaMetadata_RedMediaImage | PlainMessage<RedMediaMetadata_RedMediaImage> | undefined): boolean {
    return proto3.util.equals(RedMediaMetadata_RedMediaImage, a, b);
  }
}

/**
 * @generated from message common.proto.RedMediaMetadata.RedMediaImage.RedMediaImageUrlMultiLevel
 */
export class RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel extends Message<RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel> {
  /**
   * @generated from field: string low = 1;
   */
  low = "";

  /**
   * @generated from field: string medium = 2;
   */
  medium = "";

  /**
   * @generated from field: string high = 3;
   */
  high = "";

  constructor(data?: PartialMessage<RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.RedMediaMetadata.RedMediaImage.RedMediaImageUrlMultiLevel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "low", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "medium", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "high", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel {
    return new RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel {
    return new RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel {
    return new RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel().fromJsonString(jsonString, options);
  }

  static equals(a: RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel | PlainMessage<RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel> | undefined, b: RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel | PlainMessage<RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel> | undefined): boolean {
    return proto3.util.equals(RedMediaMetadata_RedMediaImage_RedMediaImageUrlMultiLevel, a, b);
  }
}

/**
 * @generated from message common.proto.RedMediaMetadata.RedMediaVideo
 */
export class RedMediaMetadata_RedMediaVideo extends Message<RedMediaMetadata_RedMediaVideo> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: repeated common.proto.RedMediaMetadata.RedMediaVideo.RedMediaVideoInfo url_info_list = 2;
   */
  urlInfoList: RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo[] = [];

  /**
   * @generated from field: int32 width = 3;
   */
  width = 0;

  /**
   * @generated from field: int32 height = 4;
   */
  height = 0;

  /**
   * @generated from field: string thumbnail = 5;
   */
  thumbnail = "";

  /**
   * @generated from field: string thumbnail_dim = 6;
   */
  thumbnailDim = "";

  /**
   * @generated from field: int64 played_count = 7;
   */
  playedCount = protoInt64.zero;

  /**
   * @generated from field: bool can_super_resolution = 8;
   */
  canSuperResolution = false;

  /**
   * @generated from field: int64 preload_size = 9;
   */
  preloadSize = protoInt64.zero;

  /**
   * @generated from field: int32 duration = 10;
   */
  duration = 0;

  constructor(data?: PartialMessage<RedMediaMetadata_RedMediaVideo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.RedMediaMetadata.RedMediaVideo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url_info_list", kind: "message", T: RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo, repeated: true },
    { no: 3, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "thumbnail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "thumbnail_dim", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "played_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "can_super_resolution", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "preload_size", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "duration", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RedMediaMetadata_RedMediaVideo {
    return new RedMediaMetadata_RedMediaVideo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RedMediaMetadata_RedMediaVideo {
    return new RedMediaMetadata_RedMediaVideo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RedMediaMetadata_RedMediaVideo {
    return new RedMediaMetadata_RedMediaVideo().fromJsonString(jsonString, options);
  }

  static equals(a: RedMediaMetadata_RedMediaVideo | PlainMessage<RedMediaMetadata_RedMediaVideo> | undefined, b: RedMediaMetadata_RedMediaVideo | PlainMessage<RedMediaMetadata_RedMediaVideo> | undefined): boolean {
    return proto3.util.equals(RedMediaMetadata_RedMediaVideo, a, b);
  }
}

/**
 * @generated from message common.proto.RedMediaMetadata.RedMediaVideo.RedMediaVideoInfo
 */
export class RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo extends Message<RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo> {
  /**
   * @generated from field: string desc = 1;
   */
  desc = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  constructor(data?: PartialMessage<RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.RedMediaMetadata.RedMediaVideo.RedMediaVideoInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "desc", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo {
    return new RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo {
    return new RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo {
    return new RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo().fromJsonString(jsonString, options);
  }

  static equals(a: RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo | PlainMessage<RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo> | undefined, b: RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo | PlainMessage<RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo> | undefined): boolean {
    return proto3.util.equals(RedMediaMetadata_RedMediaVideo_RedMediaVideoInfo, a, b);
  }
}

/**
 * @generated from message common.proto.RedMediaMetadata.RedMediaPoi
 */
export class RedMediaMetadata_RedMediaPoi extends Message<RedMediaMetadata_RedMediaPoi> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<RedMediaMetadata_RedMediaPoi>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.RedMediaMetadata.RedMediaPoi";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RedMediaMetadata_RedMediaPoi {
    return new RedMediaMetadata_RedMediaPoi().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RedMediaMetadata_RedMediaPoi {
    return new RedMediaMetadata_RedMediaPoi().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RedMediaMetadata_RedMediaPoi {
    return new RedMediaMetadata_RedMediaPoi().fromJsonString(jsonString, options);
  }

  static equals(a: RedMediaMetadata_RedMediaPoi | PlainMessage<RedMediaMetadata_RedMediaPoi> | undefined, b: RedMediaMetadata_RedMediaPoi | PlainMessage<RedMediaMetadata_RedMediaPoi> | undefined): boolean {
    return proto3.util.equals(RedMediaMetadata_RedMediaPoi, a, b);
  }
}

/**
 * Other counts
 *
 * @generated from message common.proto.RedMediaStatistics
 */
export class RedMediaStatistics extends Message<RedMediaStatistics> {
  /**
   * @generated from field: int64 collected_count = 1;
   */
  collectedCount = protoInt64.zero;

  constructor(data?: PartialMessage<RedMediaStatistics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.RedMediaStatistics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "collected_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RedMediaStatistics {
    return new RedMediaStatistics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RedMediaStatistics {
    return new RedMediaStatistics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RedMediaStatistics {
    return new RedMediaStatistics().fromJsonString(jsonString, options);
  }

  static equals(a: RedMediaStatistics | PlainMessage<RedMediaStatistics> | undefined, b: RedMediaStatistics | PlainMessage<RedMediaStatistics> | undefined): boolean {
    return proto3.util.equals(RedMediaStatistics, a, b);
  }
}

/**
 * @generated from message common.proto.RedMedia
 */
export class RedMedia extends Message<RedMedia> {
  /**
   * @generated from field: common.proto.RedMediaMetadata metadata = 1;
   */
  metadata?: RedMediaMetadata;

  /**
   * @generated from field: common.proto.GenerikMediaFacts facts = 2;
   */
  facts?: GenerikMediaFacts;

  constructor(data?: PartialMessage<RedMedia>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.RedMedia";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: RedMediaMetadata },
    { no: 2, name: "facts", kind: "message", T: GenerikMediaFacts },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RedMedia {
    return new RedMedia().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RedMedia {
    return new RedMedia().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RedMedia {
    return new RedMedia().fromJsonString(jsonString, options);
  }

  static equals(a: RedMedia | PlainMessage<RedMedia> | undefined, b: RedMedia | PlainMessage<RedMedia> | undefined): boolean {
    return proto3.util.equals(RedMedia, a, b);
  }
}


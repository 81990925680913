import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  input,
} from '@angular/core';
import { createChip, isNil } from '@frontend2/core';
import { LogicOperator } from '@frontend2/proto/common/proto/common_pb';
import { Placement } from '@popperjs/core';
import { LeftyControlValueAccessor } from '../../form';
import { LeftyIconComponent } from '../../icon/icon.component';
import { LeftySelectDropdownItemComponent } from '../../lefty-form-select/lefty-select-dropdown-item.component';
import { LeftyListComponent } from '../../lefty-list/lefty-list.component';
import { readableLogicOperator } from '../../lefty-logic-operator-selector/logic-operator.models';
import { LeftyPopupComponent } from '../../lefty-popup/lefty-popup.component';
import { LeftyChipComponent } from '../lefty-chip.component';

@Component({
  selector: 'lefty-operator-chip',
  templateUrl: 'lefty-operator-chip.component.html',
  styleUrls: ['lefty-operator-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyChipComponent,
    LeftyIconComponent,
    LeftyPopupComponent,
    LeftyListComponent,
    LeftySelectDropdownItemComponent,
  ],
})
export class LeftyOperatorChipComponent extends LeftyControlValueAccessor<LogicOperator> {
  constructor() {
    super();
    effect(
      () => {
        if (
          isNil(this.value()) ||
          this.value() === LogicOperator.UNKNOWN_OPERATOR
        ) {
          this.setValueAndNotify(this.defaultOperator);
        }
      },
      { allowSignalWrites: true },
    );
  }

  readonly dark = input(false);
  readonly ghost = input(false);
  readonly noOverflow = input(false);
  readonly highlight = input(false);
  readonly editable = input(true);

  readonly popupPlacement = input<Placement>('bottom-start');

  readonly defaultOperator = LogicOperator.AND;

  override isValidType(obj: unknown): obj is LogicOperator {
    return Object.values(LogicOperator).includes(obj as LogicOperator);
  }

  readonly operatorOptions = [LogicOperator.AND, LogicOperator.OR];

  readableLogicOperator(operator: LogicOperator): string {
    return readableLogicOperator(operator).toLowerCase();
  }

  readonly valueChip = computed(() =>
    createChip(this.value(), {
      formattedValue: this.readableLogicOperator(
        this.value() ?? this.defaultOperator,
      ),
    }),
  );

  operatorSelected(operator: LogicOperator): void {
    this.setValueAndNotify(operator);
  }

  isSelected(operator: LogicOperator): boolean {
    return this.value() === operator;
  }
}

<lefty-form
  [label]="label"
  [required]="required"
  [optional]="optional"
  [helpText]="helpText"
  [externalHelpLink]="externalHelpLink"
  [helpLink]="helpLink"
  [hintText]="hintText"
  [errorMessage]="errorMessage"
  [tooltip]="tooltip"
  [large]="large"
  [topHintText]="topHintText"
>
  <div
    class="input-container"
    #popupTrigger
    (click)="toggleSuggestionsPopup()"
  >
    <div
      class="leading"
      #leading
      *ngIf="hasLeadingGlyph || hasPrefix"
    >
      <lefty-icon
        class="icon leading-glyph"
        *ngIf="hasLeadingGlyph"
        [icon]="leadingGlyph"
        size="x-large"
      ></lefty-icon>

      <span
        *ngIf="hasPrefix"
        class="text prefix"
        >{{ prefix }}</span
      >
    </div>

    <input
      #input
      focusableElement
      [disabled]="disabled"
      [value]="value"
      (input)="handleValueChange(input.value)"
      (focus)="handleFocus($event)"
      (blur)="handleBlur($event)"
      (invalid)="$event.preventDefault()"
      [required]="required"
      [attr.name]="name"
      [attr.autocomplete]="autocomplete"
      [attr.placeholder]="placeholder"
      [attr.type]="type"
      [attr.tabindex]="tabIndex"
      [attr.maxlength]="maxLength"
      (keypress)="keypress$.emit($event)"
      (keyup)="keyup$.emit($event)"
      (keydown)="keydown$.emit($event)"
      [attr.autocomplete]="autocomplete"
      (paste)="handlePaste($event)"
    />

    <div
      class="trailing"
      #trailing
      *ngIf="hasSuffix || hasTrailingGlyph"
    >
      <span
        *ngIf="hasSuffix"
        class="text suffix"
        >{{ suffix }}</span
      >

      <lefty-icon
        class="icon trailing-glyph"
        *ngIf="hasTrailingGlyph"
        [icon]="trailingGlyph"
        size="x-large"
      >
      </lefty-icon>
    </div>
  </div>

  <lefty-popup
    [placement]="suggestionsPopupPlacement"
    [matchMinSourceWidth]="true"
    [(visible)]="suggestionsPopupVisible"
    [delegateControlToParent]="true"
    [popupTrigger]="popupTrigger"
    *ngIf="suggestions.length !== 0"
  >
    <lefty-list
      (keydown.enter)="handleKeyboardTrigger($event)"
      (keydown.space)="handleKeyboardTrigger($event)"
    >
      <lefty-select-dropdown-item
        *ngFor="
          let item of suggestions | slice: 0 : slicedCount;
          trackBy: trackByFn
        "
        [tabbable]="false"
        [value]="item"
        [itemActive]="isSuggestionActive(item)"
        [selected]="isSuggestionSelected(item)"
        (click)="selectSuggestion(item)"
        [closeOnActivate]="true"
      >
      </lefty-select-dropdown-item>
    </lefty-list>
  </lefty-popup>
</lefty-form>

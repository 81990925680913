import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injectable,
  Input,
  Output,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Timestamp } from '@bufbuild/protobuf';
import {
  capitalize,
  formatDateAgo,
  isEmptyString,
  isNil,
  isNotEmptyString,
} from '@frontend2/core';
import { LoggedBootstrapping } from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import { debounceTime, fromEvent, merge } from 'rxjs';
import { injectDestroyRef } from '../inject.helpers';
import { LeftyComponent, createOutput } from '../utils';
import {
  NoteInfo,
  NoteState,
  createNoteInfo,
  initialNoteState,
} from './note-helpers';
import { FormsModule } from '@angular/forms';
import { LeftyAvatarComponent } from '../lefty-avatar/lefty-avatar.component';
import { LeftyButtonDirective } from '../lefty-button-directive/lefty-button.directive';
import { LeftyFormTextareaComponent } from '../lefty-form-textarea/lefty-form-textarea.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lefty-note-editor',
  templateUrl: 'lefty-note-editor.component.html',
  styleUrls: ['lefty-note-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    LeftyFormTextareaComponent,
    LeftyButtonDirective,
    LeftyAvatarComponent,
    FormsModule,
  ],
})
export class LeftyNoteEditorComponent
  extends LeftyComponent
  implements AfterViewInit
{
  readonly noteService = inject(NoteActionsService);
  readonly destroyRef = injectDestroyRef();

  _note: NoteInfo = createNoteInfo();

  @Input()
  set note(n: NoteInfo) {
    this._note = n;
    this.initializeNoteInfos();
  }

  get note(): NoteInfo {
    return this._note;
  }

  @Input()
  currentUser = new LoggedBootstrapping();

  @Input()
  isEditable = false;

  @Input()
  showEmail = false;

  @Output()
  readonly saved$ = createOutput<string>();

  @Output()
  readonly deleted$ = createOutput<bigint | undefined>();

  currentNoteContent = '';

  noteState = NoteState.noContent;

  loading = signal(false);

  initializeNoteInfos(): void {
    this.noteState = initialNoteState(this.note);
    this.currentNoteContent = this.note.originalContent;
  }

  get noteStateIsNoContent(): boolean {
    return this.noteState === NoteState.noContent;
  }

  get noteStateIsEditing(): boolean {
    return this.noteState === NoteState.editing;
  }

  get noteStateIsSaved(): boolean {
    return this.noteState === NoteState.saved;
  }

  get noteStateIsDeleteConfirmation(): boolean {
    return this.noteState === NoteState.deleteConfirmation;
  }

  get mustShowFooterButtons(): boolean {
    return (
      (this.noteStateIsEditing && isNotEmptyString(this.currentNoteContent)) ||
      (this.noteStateIsNoContent &&
        isNotEmptyString(this.currentNoteContent)) ||
      (this.noteStateIsSaved && this.loading())
    );
  }

  get initial(): string {
    return isNotEmptyString(this.note.initial)
      ? capitalize(this.note.initial)
      : capitalize(this.note.authorName.charAt(0));
  }

  get formattedUpdateDate(): string {
    return formatDateAgo(this.note.updated.toDate());
  }

  @ViewChild('textarea') textarea!: ElementRef;

  private readonly _minRows: number = 1;
  private readonly _lineHeight: number = 19;

  ngAfterViewInit(): void {
    if (isNil(this.textarea)) {
      return;
    }
    merge(
      fromEvent(this.textarea?.nativeElement, 'focus'),
      fromEvent(this.textarea?.nativeElement, 'keydown'),
      fromEvent(this.textarea?.nativeElement, 'input'),
    )
      .pipe(debounceTime(100), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this._resizeTextarea());
  }

  private _resizeTextarea(): void {
    const textarea: HTMLTextAreaElement = this.textarea?.nativeElement;
    textarea.rows = this._minRows;
    const r = Math.floor(textarea.scrollHeight / this._lineHeight);
    textarea.rows = r;
  }

  startEdit(): void {
    this.noteState = NoteState.editing;
    setTimeout(() => {
      const textarea: HTMLTextAreaElement = this.textarea?.nativeElement;
      textarea.focus();

      // set cursor at the end
      textarea.setSelectionRange(textarea.value.length, textarea.value.length);
    }, 300);
  }

  cancel(): void {
    this.noteState = initialNoteState(this.note);
    this.currentNoteContent = this.note.originalContent;
    this.changeDetection.markForCheck();
  }

  async save(): Promise<void> {
    this.loading.set(true);
    try {
      if (isEmptyString(this.note.originalContent)) {
        await this.noteService.addNote(
          this.currentNoteContent,
          this.note.noteId,
        );
      } else {
        await this.noteService.editNote(
          this.currentNoteContent,
          this.note.noteId,
        );
      }
      const name = this.getNamefromUser(this.currentUser);
      this.note = createNoteInfo({
        ...this.note,
        authorName: name,
        initial: name.charAt(0),
        updated: Timestamp.fromDate(new Date()),
        originalContent: this.currentNoteContent,
      });
      this.noteState = NoteState.saved;
      this.saved$.next(this.currentNoteContent);
    } finally {
      this.loading.set(false);
    }
  }

  async confirmDelete(): Promise<void> {
    this.loading.set(true);
    try {
      await this.noteService.deleteNote(this.note.noteId);

      this.note = createNoteInfo({ ...this.note, originalContent: '' });
      this.noteState = NoteState.noContent;
      this.currentNoteContent = '';
      this.deleted$.next(this.note.noteId);
    } finally {
      this.loading.set(false);
    }
  }

  askDelete(): void {
    this.noteState = NoteState.deleteConfirmation;
  }

  cancelDelete(): void {
    this.noteState = NoteState.saved;
  }

  getNamefromUser(user: LoggedBootstrapping): string {
    if (
      (isNotEmptyString(user.firstName) || isNotEmptyString(user.lastName)) &&
      !this.showEmail
    ) {
      return [user.firstName, user.lastName].join(' ');
    } else {
      return user.email;
    }
  }
}

@Injectable()
export abstract class NoteActionsService {
  abstract deleteNote(noteId?: bigint): void;
  abstract editNote(content: string, noteId?: bigint): void;
  abstract addNote(content: string, noteId?: bigint): void;
}

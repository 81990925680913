// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/creator_fields.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { StringList } from "../../common/proto/common_pb";
import { GoogleLocation } from "./local_pb";
import { Notes } from "./common_pb";

/**
 * @generated from enum librarian.proto.CreatorFieldValueSource
 */
export enum CreatorFieldValueSource {
  /**
   * @generated from enum value: FIELD_VALUE_SOURCE_UNKNOWN = 0;
   */
  FIELD_VALUE_SOURCE_UNKNOWN = 0,

  /**
   * @generated from enum value: FIELD_VALUE_SOURCE_MANUAL = 1;
   */
  FIELD_VALUE_SOURCE_MANUAL = 1,

  /**
   * @generated from enum value: FIELD_VALUE_SOURCE_UPLOAD = 2;
   */
  FIELD_VALUE_SOURCE_UPLOAD = 2,

  /**
   * @generated from enum value: FIELD_VALUE_SOURCE_PROGRAMMATIC = 3;
   */
  FIELD_VALUE_SOURCE_PROGRAMMATIC = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(CreatorFieldValueSource)
proto3.util.setEnumType(CreatorFieldValueSource, "librarian.proto.CreatorFieldValueSource", [
  { no: 0, name: "FIELD_VALUE_SOURCE_UNKNOWN" },
  { no: 1, name: "FIELD_VALUE_SOURCE_MANUAL" },
  { no: 2, name: "FIELD_VALUE_SOURCE_UPLOAD" },
  { no: 3, name: "FIELD_VALUE_SOURCE_PROGRAMMATIC" },
]);

/**
 * @generated from enum librarian.proto.AddressFieldColumn
 */
export enum AddressFieldColumn {
  /**
   * @generated from enum value: ADDRESS_FIELD_COLUMN_UNKNOWN = 0;
   */
  ADDRESS_FIELD_COLUMN_UNKNOWN = 0,

  /**
   * @generated from enum value: ADDRESS_FIELD_FULL_NAME = 1;
   */
  ADDRESS_FIELD_FULL_NAME = 1,

  /**
   * @generated from enum value: ADDRESS_FIELD_COMPANY = 2;
   */
  ADDRESS_FIELD_COMPANY = 2,

  /**
   * @generated from enum value: ADDRESS_FIELD_ADDRESS = 3;
   */
  ADDRESS_FIELD_ADDRESS = 3,

  /**
   * @generated from enum value: ADDRESS_FIELD_ADDRESS2 = 4;
   */
  ADDRESS_FIELD_ADDRESS2 = 4,

  /**
   * @generated from enum value: ADDRESS_FIELD_CITY = 5;
   */
  ADDRESS_FIELD_CITY = 5,

  /**
   * @generated from enum value: ADDRESS_FIELD_PROVINCE = 6;
   */
  ADDRESS_FIELD_PROVINCE = 6,

  /**
   * @generated from enum value: ADDRESS_FIELD_POSTAL_CODE = 7;
   */
  ADDRESS_FIELD_POSTAL_CODE = 7,

  /**
   * @generated from enum value: ADDRESS_FIELD_COUNTRY = 8;
   */
  ADDRESS_FIELD_COUNTRY = 8,

  /**
   * @generated from enum value: ADDRESS_FIELD_PHONE = 9;
   */
  ADDRESS_FIELD_PHONE = 9,
}
// Retrieve enum metadata with: proto3.getEnumType(AddressFieldColumn)
proto3.util.setEnumType(AddressFieldColumn, "librarian.proto.AddressFieldColumn", [
  { no: 0, name: "ADDRESS_FIELD_COLUMN_UNKNOWN" },
  { no: 1, name: "ADDRESS_FIELD_FULL_NAME" },
  { no: 2, name: "ADDRESS_FIELD_COMPANY" },
  { no: 3, name: "ADDRESS_FIELD_ADDRESS" },
  { no: 4, name: "ADDRESS_FIELD_ADDRESS2" },
  { no: 5, name: "ADDRESS_FIELD_CITY" },
  { no: 6, name: "ADDRESS_FIELD_PROVINCE" },
  { no: 7, name: "ADDRESS_FIELD_POSTAL_CODE" },
  { no: 8, name: "ADDRESS_FIELD_COUNTRY" },
  { no: 9, name: "ADDRESS_FIELD_PHONE" },
]);

/**
 * @generated from message librarian.proto.AddressFieldProto
 */
export class AddressFieldProto extends Message<AddressFieldProto> {
  /**
   * @generated from field: string full_name = 1;
   */
  fullName = "";

  /**
   * @generated from field: string company = 2;
   */
  company = "";

  /**
   * @generated from field: string address = 3;
   */
  address = "";

  /**
   * Details like apartment, suite...
   *
   * @generated from field: string address2 = 4;
   */
  address2 = "";

  /**
   * @generated from field: string city = 5;
   */
  city = "";

  /**
   * @generated from field: string province = 6;
   */
  province = "";

  /**
   * @generated from field: string postal_code = 7;
   */
  postalCode = "";

  /**
   * @generated from field: string country = 8;
   */
  country = "";

  /**
   * @generated from field: string phone = 9;
   */
  phone = "";

  /**
   * @generated from field: string email = 10;
   */
  email = "";

  constructor(data?: PartialMessage<AddressFieldProto>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AddressFieldProto";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "address2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "province", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddressFieldProto {
    return new AddressFieldProto().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddressFieldProto {
    return new AddressFieldProto().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddressFieldProto {
    return new AddressFieldProto().fromJsonString(jsonString, options);
  }

  static equals(a: AddressFieldProto | PlainMessage<AddressFieldProto> | undefined, b: AddressFieldProto | PlainMessage<AddressFieldProto> | undefined): boolean {
    return proto3.util.equals(AddressFieldProto, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorFieldValue
 */
export class CreatorFieldValue extends Message<CreatorFieldValue> {
  /**
   * @generated from field: int64 creator_field_id = 1;
   */
  creatorFieldId = protoInt64.zero;

  /**
   * @generated from field: string influencer_lefty_id = 2;
   */
  influencerLeftyId = "";

  /**
   * @generated from field: librarian.proto.CreatorFieldValueSource source = 11;
   */
  source = CreatorFieldValueSource.FIELD_VALUE_SOURCE_UNKNOWN;

  /**
   * @generated from oneof librarian.proto.CreatorFieldValue.value
   */
  value: {
    /**
     * @generated from field: string text_value = 3;
     */
    value: string;
    case: "textValue";
  } | {
    /**
     * @generated from field: int64 number_value = 4;
     */
    value: bigint;
    case: "numberValue";
  } | {
    /**
     * @generated from field: float decimal_value = 5;
     */
    value: number;
    case: "decimalValue";
  } | {
    /**
     * @generated from field: google.protobuf.Timestamp date_value = 6;
     */
    value: Timestamp;
    case: "dateValue";
  } | {
    /**
     * @generated from field: bool bool_value = 7;
     */
    value: boolean;
    case: "boolValue";
  } | {
    /**
     * @generated from field: common.proto.StringList enum_value = 8;
     */
    value: StringList;
    case: "enumValue";
  } | {
    /**
     * @generated from field: librarian.proto.GoogleLocation location_value = 9;
     */
    value: GoogleLocation;
    case: "locationValue";
  } | {
    /**
     * @generated from field: librarian.proto.AddressFieldProto address_value = 12;
     */
    value: AddressFieldProto;
    case: "addressValue";
  } | {
    /**
     * @generated from field: librarian.proto.Notes string_values = 10;
     */
    value: Notes;
    case: "stringValues";
  } | {
    /**
     * This is just a marker to set the oneof case, the value is actually ignored (meaning: we will unset even if value if false)
     *
     * @generated from field: bool unset_value = 100;
     */
    value: boolean;
    case: "unsetValue";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: string creator_field_name = 13;
   */
  creatorFieldName = "";

  /**
   * @generated from field: bool is_default_creator_field = 14;
   */
  isDefaultCreatorField = false;

  constructor(data?: PartialMessage<CreatorFieldValue>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorFieldValue";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_field_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "influencer_lefty_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "source", kind: "enum", T: proto3.getEnumType(CreatorFieldValueSource) },
    { no: 3, name: "text_value", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
    { no: 4, name: "number_value", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "value" },
    { no: 5, name: "decimal_value", kind: "scalar", T: 2 /* ScalarType.FLOAT */, oneof: "value" },
    { no: 6, name: "date_value", kind: "message", T: Timestamp, oneof: "value" },
    { no: 7, name: "bool_value", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "value" },
    { no: 8, name: "enum_value", kind: "message", T: StringList, oneof: "value" },
    { no: 9, name: "location_value", kind: "message", T: GoogleLocation, oneof: "value" },
    { no: 12, name: "address_value", kind: "message", T: AddressFieldProto, oneof: "value" },
    { no: 10, name: "string_values", kind: "message", T: Notes, oneof: "value" },
    { no: 100, name: "unset_value", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "value" },
    { no: 13, name: "creator_field_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "is_default_creator_field", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorFieldValue {
    return new CreatorFieldValue().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorFieldValue {
    return new CreatorFieldValue().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorFieldValue {
    return new CreatorFieldValue().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorFieldValue | PlainMessage<CreatorFieldValue> | undefined, b: CreatorFieldValue | PlainMessage<CreatorFieldValue> | undefined): boolean {
    return proto3.util.equals(CreatorFieldValue, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreatorFieldValuesList
 */
export class CreatorFieldValuesList extends Message<CreatorFieldValuesList> {
  /**
   * @generated from field: repeated librarian.proto.CreatorFieldValue creator_field_values = 1;
   */
  creatorFieldValues: CreatorFieldValue[] = [];

  constructor(data?: PartialMessage<CreatorFieldValuesList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreatorFieldValuesList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_field_values", kind: "message", T: CreatorFieldValue, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorFieldValuesList {
    return new CreatorFieldValuesList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorFieldValuesList {
    return new CreatorFieldValuesList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorFieldValuesList {
    return new CreatorFieldValuesList().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorFieldValuesList | PlainMessage<CreatorFieldValuesList> | undefined, b: CreatorFieldValuesList | PlainMessage<CreatorFieldValuesList> | undefined): boolean {
    return proto3.util.equals(CreatorFieldValuesList, a, b);
  }
}


// This file defines protocol buffers for the weibo stuff that gets persisted.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/weibo.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ComputedGenerikStats, PostType, StringList } from "./common_pb";
import { PostInteractions } from "./campaign_pb";
import { GenerikMediaFacts } from "./media_pb";

/**
 * @generated from enum common.proto.WeiboMediaResolution
 */
export enum WeiboMediaResolution {
  /**
   * @generated from enum value: WEIBO_RESOLUTION_UNDEFINED = 0;
   */
  WEIBO_RESOLUTION_UNDEFINED = 0,

  /**
   * @generated from enum value: WEIBO_RESOLUTION_LOW = 1;
   */
  WEIBO_RESOLUTION_LOW = 1,

  /**
   * @generated from enum value: WEIBO_RESOLUTION_STANDARD = 2;
   */
  WEIBO_RESOLUTION_STANDARD = 2,

  /**
   * @generated from enum value: WEIBO_RESOLUTION_HIGH = 3;
   */
  WEIBO_RESOLUTION_HIGH = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(WeiboMediaResolution)
proto3.util.setEnumType(WeiboMediaResolution, "common.proto.WeiboMediaResolution", [
  { no: 0, name: "WEIBO_RESOLUTION_UNDEFINED" },
  { no: 1, name: "WEIBO_RESOLUTION_LOW" },
  { no: 2, name: "WEIBO_RESOLUTION_STANDARD" },
  { no: 3, name: "WEIBO_RESOLUTION_HIGH" },
]);

/**
 * @generated from message common.proto.WeiboCreatorCounts
 */
export class WeiboCreatorCounts extends Message<WeiboCreatorCounts> {
  /**
   * @generated from field: int64 follows = 1;
   */
  follows = protoInt64.zero;

  /**
   * int64: there are a lots of people in China :)
   *
   * @generated from field: int64 followers = 2;
   */
  followers = protoInt64.zero;

  /**
   * @generated from field: int32 statuses_count = 3;
   */
  statusesCount = 0;

  constructor(data?: PartialMessage<WeiboCreatorCounts>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeiboCreatorCounts";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "follows", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "followers", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "statuses_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeiboCreatorCounts {
    return new WeiboCreatorCounts().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeiboCreatorCounts {
    return new WeiboCreatorCounts().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeiboCreatorCounts {
    return new WeiboCreatorCounts().fromJsonString(jsonString, options);
  }

  static equals(a: WeiboCreatorCounts | PlainMessage<WeiboCreatorCounts> | undefined, b: WeiboCreatorCounts | PlainMessage<WeiboCreatorCounts> | undefined): boolean {
    return proto3.util.equals(WeiboCreatorCounts, a, b);
  }
}

/**
 * @generated from message common.proto.WeiboVerified
 */
export class WeiboVerified extends Message<WeiboVerified> {
  /**
   * @generated from field: bool is_verified = 1;
   */
  isVerified = false;

  /**
   * @generated from field: string reason = 2;
   */
  reason = "";

  /**
   * Note(christian) we don't know what these 2 are for now...
   *
   * @generated from field: int32 verified_type = 3;
   */
  verifiedType = 0;

  /**
   * @generated from field: int32 verified_type_ext = 4;
   */
  verifiedTypeExt = 0;

  constructor(data?: PartialMessage<WeiboVerified>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeiboVerified";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "verified_type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "verified_type_ext", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeiboVerified {
    return new WeiboVerified().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeiboVerified {
    return new WeiboVerified().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeiboVerified {
    return new WeiboVerified().fromJsonString(jsonString, options);
  }

  static equals(a: WeiboVerified | PlainMessage<WeiboVerified> | undefined, b: WeiboVerified | PlainMessage<WeiboVerified> | undefined): boolean {
    return proto3.util.equals(WeiboVerified, a, b);
  }
}

/**
 * @generated from message common.proto.WeiboThumbnails
 */
export class WeiboThumbnails extends Message<WeiboThumbnails> {
  /**
   * @generated from field: string avatar_hd = 1;
   */
  avatarHd = "";

  /**
   * @generated from field: string cover_image_phone = 2;
   */
  coverImagePhone = "";

  /**
   * @generated from field: string profile_url = 3;
   */
  profileUrl = "";

  constructor(data?: PartialMessage<WeiboThumbnails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeiboThumbnails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "avatar_hd", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "cover_image_phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeiboThumbnails {
    return new WeiboThumbnails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeiboThumbnails {
    return new WeiboThumbnails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeiboThumbnails {
    return new WeiboThumbnails().fromJsonString(jsonString, options);
  }

  static equals(a: WeiboThumbnails | PlainMessage<WeiboThumbnails> | undefined, b: WeiboThumbnails | PlainMessage<WeiboThumbnails> | undefined): boolean {
    return proto3.util.equals(WeiboThumbnails, a, b);
  }
}

/**
 * @generated from message common.proto.WeiboCreator
 */
export class WeiboCreator extends Message<WeiboCreator> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string user_name = 2;
   */
  userName = "";

  /**
   * @generated from field: string bio = 3;
   */
  bio = "";

  /**
   * Seems to be f/m (maybe there is something for transgenres ?)
   *
   * @generated from field: string gender = 4;
   */
  gender = "";

  /**
   * @generated from field: string profile_url = 5;
   */
  profileUrl = "";

  /**
   * @generated from field: common.proto.WeiboCreatorCounts counts = 6;
   */
  counts?: WeiboCreatorCounts;

  /**
   * @generated from field: common.proto.WeiboThumbnails thumbnails = 7;
   */
  thumbnails?: WeiboThumbnails;

  /**
   * Note(christian) at the time of writing those are obscure.
   *
   * @generated from field: bool close_blue_v = 8;
   */
  closeBlueV = false;

  /**
   * @generated from field: int32 mbrank = 9;
   */
  mbrank = 0;

  /**
   * @generated from field: int32 mbtype = 10;
   */
  mbtype = 0;

  /**
   * @generated from field: int32 urank = 11;
   */
  urank = 0;

  /**
   * This is a chinese string for now (we believe it's the name of a city). In due time we might parse it as location
   * if needed.
   *
   * @generated from field: string location = 12;
   */
  location = "";

  /**
   * @generated from field: string sunshine = 13;
   */
  sunshine = "";

  /**
   * @generated from field: bool verified = 15;
   */
  verified = false;

  /**
   * @generated from field: int32 verified_type = 16;
   */
  verifiedType = 0;

  /**
   * @generated from field: int32 verified_type_ext = 17;
   */
  verifiedTypeExt = 0;

  /**
   * This one seems to hold category
   *
   * @generated from field: string verified_reason = 18;
   */
  verifiedReason = "";

  /**
   * @generated from field: common.proto.ComputedGenerikStats computed_stats = 14;
   */
  computedStats?: ComputedGenerikStats;

  constructor(data?: PartialMessage<WeiboCreator>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeiboCreator";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "bio", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "gender", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "profile_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "counts", kind: "message", T: WeiboCreatorCounts },
    { no: 7, name: "thumbnails", kind: "message", T: WeiboThumbnails },
    { no: 8, name: "close_blue_v", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "mbrank", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "mbtype", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "urank", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "sunshine", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "verified_type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 17, name: "verified_type_ext", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 18, name: "verified_reason", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "computed_stats", kind: "message", T: ComputedGenerikStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeiboCreator {
    return new WeiboCreator().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeiboCreator {
    return new WeiboCreator().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeiboCreator {
    return new WeiboCreator().fromJsonString(jsonString, options);
  }

  static equals(a: WeiboCreator | PlainMessage<WeiboCreator> | undefined, b: WeiboCreator | PlainMessage<WeiboCreator> | undefined): boolean {
    return proto3.util.equals(WeiboCreator, a, b);
  }
}

/**
 * @generated from message common.proto.WeiboMediaMetadata
 */
export class WeiboMediaMetadata extends Message<WeiboMediaMetadata> {
  /**
   * @generated from field: string creator_id = 1;
   */
  creatorId = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: string bid = 3;
   */
  bid = "";

  /**
   * @generated from field: common.proto.PostType post_type = 4;
   */
  postType = PostType.POST_TYPE_UNDEFINED;

  /**
   * @generated from field: string url = 5;
   */
  url = "";

  /**
   * @generated from field: google.protobuf.Timestamp creation_time = 6;
   */
  creationTime?: Timestamp;

  /**
   * Like pinned post
   *
   * @generated from field: bool is_top = 17;
   */
  isTop = false;

  /**
   * @generated from field: int32 mblogtype = 19;
   */
  mblogtype = 0;

  /**
   * @generated from field: string text = 7;
   */
  text = "";

  /**
   * @generated from field: string source = 8;
   */
  source = "";

  /**
   * @generated from field: common.proto.WeiboMediaMetadata.PageInfo page_info = 9;
   */
  pageInfo?: WeiboMediaMetadata_PageInfo;

  /**
   * @generated from field: repeated common.proto.WeiboPic pics = 10;
   */
  pics: WeiboPic[] = [];

  /**
   * @generated from field: string thumbnail_pic = 11;
   */
  thumbnailPic = "";

  /**
   * @generated from field: string bmiddle_pic = 12;
   */
  bmiddlePic = "";

  /**
   * @generated from field: string original_pic = 13;
   */
  originalPic = "";

  /**
   * @generated from field: string status_title = 14;
   */
  statusTitle = "";

  /**
   * @generated from field: repeated string mentions = 15;
   */
  mentions: string[] = [];

  /**
   * @generated from field: repeated string tags = 16;
   */
  tags: string[] = [];

  /**
   * Only for reshares
   *
   * @generated from field: common.proto.WeiboMediaMetadata.RetweetedStatus retweeted_status = 18;
   */
  retweetedStatus?: WeiboMediaMetadata_RetweetedStatus;

  /**
   * @generated from field: common.proto.PostInteractions interactions = 100;
   */
  interactions?: PostInteractions;

  constructor(data?: PartialMessage<WeiboMediaMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeiboMediaMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "bid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType) },
    { no: 5, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "creation_time", kind: "message", T: Timestamp },
    { no: 17, name: "is_top", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "mblogtype", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "source", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "page_info", kind: "message", T: WeiboMediaMetadata_PageInfo },
    { no: 10, name: "pics", kind: "message", T: WeiboPic, repeated: true },
    { no: 11, name: "thumbnail_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "bmiddle_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "original_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "status_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "mentions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 16, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 18, name: "retweeted_status", kind: "message", T: WeiboMediaMetadata_RetweetedStatus },
    { no: 100, name: "interactions", kind: "message", T: PostInteractions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeiboMediaMetadata {
    return new WeiboMediaMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeiboMediaMetadata {
    return new WeiboMediaMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeiboMediaMetadata {
    return new WeiboMediaMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: WeiboMediaMetadata | PlainMessage<WeiboMediaMetadata> | undefined, b: WeiboMediaMetadata | PlainMessage<WeiboMediaMetadata> | undefined): boolean {
    return proto3.util.equals(WeiboMediaMetadata, a, b);
  }
}

/**
 * @generated from message common.proto.WeiboMediaMetadata.PageInfo
 */
export class WeiboMediaMetadata_PageInfo extends Message<WeiboMediaMetadata_PageInfo> {
  /**
   * @generated from field: string type = 1;
   */
  type = "";

  /**
   * @generated from field: string page_url = 2;
   */
  pageUrl = "";

  /**
   * @generated from field: string page_title = 3;
   */
  pageTitle = "";

  /**
   * @generated from field: string content1 = 4;
   */
  content1 = "";

  /**
   * @generated from field: string content2 = 5;
   */
  content2 = "";

  /**
   * @generated from field: common.proto.WeiboMediaMetadata.PageInfo.PagePic page_pic = 6;
   */
  pagePic?: WeiboMediaMetadata_PageInfo_PagePic;

  /**
   * video only ?
   *
   * @generated from field: string url_ori = 7;
   */
  urlOri = "";

  /**
   * @generated from field: string title = 8;
   */
  title = "";

  /**
   * @generated from field: int32 object_type = 9;
   */
  objectType = 0;

  /**
   * @generated from field: string video_orientation = 10;
   */
  videoOrientation = "";

  /**
   * @generated from field: common.proto.WeiboMediaMetadata.PageInfo.MediaInfo media_info = 11;
   */
  mediaInfo?: WeiboMediaMetadata_PageInfo_MediaInfo;

  /**
   * @generated from field: common.proto.StringList urls = 13;
   */
  urls?: StringList;

  /**
   * Stories
   *
   * @generated from field: repeated common.proto.WeiboSlideCover slide_cover = 14;
   */
  slideCover: WeiboSlideCover[] = [];

  constructor(data?: PartialMessage<WeiboMediaMetadata_PageInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeiboMediaMetadata.PageInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "page_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "page_title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "content1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "content2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "page_pic", kind: "message", T: WeiboMediaMetadata_PageInfo_PagePic },
    { no: 7, name: "url_ori", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "object_type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "video_orientation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "media_info", kind: "message", T: WeiboMediaMetadata_PageInfo_MediaInfo },
    { no: 13, name: "urls", kind: "message", T: StringList },
    { no: 14, name: "slide_cover", kind: "message", T: WeiboSlideCover, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeiboMediaMetadata_PageInfo {
    return new WeiboMediaMetadata_PageInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeiboMediaMetadata_PageInfo {
    return new WeiboMediaMetadata_PageInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeiboMediaMetadata_PageInfo {
    return new WeiboMediaMetadata_PageInfo().fromJsonString(jsonString, options);
  }

  static equals(a: WeiboMediaMetadata_PageInfo | PlainMessage<WeiboMediaMetadata_PageInfo> | undefined, b: WeiboMediaMetadata_PageInfo | PlainMessage<WeiboMediaMetadata_PageInfo> | undefined): boolean {
    return proto3.util.equals(WeiboMediaMetadata_PageInfo, a, b);
  }
}

/**
 * @generated from message common.proto.WeiboMediaMetadata.PageInfo.PagePic
 */
export class WeiboMediaMetadata_PageInfo_PagePic extends Message<WeiboMediaMetadata_PageInfo_PagePic> {
  /**
   * @generated from field: int32 width = 1;
   */
  width = 0;

  /**
   * @generated from field: int32 height = 2;
   */
  height = 0;

  /**
   * @generated from field: int32 source = 3;
   */
  source = 0;

  /**
   * @generated from field: int32 type = 4;
   */
  type = 0;

  /**
   * @generated from field: string url = 5;
   */
  url = "";

  /**
   * @generated from field: string pid = 6;
   */
  pid = "";

  constructor(data?: PartialMessage<WeiboMediaMetadata_PageInfo_PagePic>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeiboMediaMetadata.PageInfo.PagePic";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "source", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "pid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeiboMediaMetadata_PageInfo_PagePic {
    return new WeiboMediaMetadata_PageInfo_PagePic().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeiboMediaMetadata_PageInfo_PagePic {
    return new WeiboMediaMetadata_PageInfo_PagePic().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeiboMediaMetadata_PageInfo_PagePic {
    return new WeiboMediaMetadata_PageInfo_PagePic().fromJsonString(jsonString, options);
  }

  static equals(a: WeiboMediaMetadata_PageInfo_PagePic | PlainMessage<WeiboMediaMetadata_PageInfo_PagePic> | undefined, b: WeiboMediaMetadata_PageInfo_PagePic | PlainMessage<WeiboMediaMetadata_PageInfo_PagePic> | undefined): boolean {
    return proto3.util.equals(WeiboMediaMetadata_PageInfo_PagePic, a, b);
  }
}

/**
 * @generated from message common.proto.WeiboMediaMetadata.PageInfo.MediaInfo
 */
export class WeiboMediaMetadata_PageInfo_MediaInfo extends Message<WeiboMediaMetadata_PageInfo_MediaInfo> {
  /**
   * @generated from field: string stream_url = 1;
   */
  streamUrl = "";

  /**
   * @generated from field: string stream_url_hd = 2;
   */
  streamUrlHd = "";

  /**
   * @generated from field: float duration = 3;
   */
  duration = 0;

  constructor(data?: PartialMessage<WeiboMediaMetadata_PageInfo_MediaInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeiboMediaMetadata.PageInfo.MediaInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stream_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "stream_url_hd", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "duration", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeiboMediaMetadata_PageInfo_MediaInfo {
    return new WeiboMediaMetadata_PageInfo_MediaInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeiboMediaMetadata_PageInfo_MediaInfo {
    return new WeiboMediaMetadata_PageInfo_MediaInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeiboMediaMetadata_PageInfo_MediaInfo {
    return new WeiboMediaMetadata_PageInfo_MediaInfo().fromJsonString(jsonString, options);
  }

  static equals(a: WeiboMediaMetadata_PageInfo_MediaInfo | PlainMessage<WeiboMediaMetadata_PageInfo_MediaInfo> | undefined, b: WeiboMediaMetadata_PageInfo_MediaInfo | PlainMessage<WeiboMediaMetadata_PageInfo_MediaInfo> | undefined): boolean {
    return proto3.util.equals(WeiboMediaMetadata_PageInfo_MediaInfo, a, b);
  }
}

/**
 * This is the name coming from API, but it's a reshare
 *
 * @generated from message common.proto.WeiboMediaMetadata.RetweetedStatus
 */
export class WeiboMediaMetadata_RetweetedStatus extends Message<WeiboMediaMetadata_RetweetedStatus> {
  /**
   * @generated from field: string creator_id = 1;
   */
  creatorId = "";

  /**
   * Keeping this as we may not have ingested user in Lefty
   *
   * @generated from field: string creator_screen_name = 2;
   */
  creatorScreenName = "";

  /**
   * @generated from field: string id = 3;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp creation_time = 4;
   */
  creationTime?: Timestamp;

  /**
   * @generated from field: string text = 5;
   */
  text = "";

  /**
   * full urls for various format can be deduced from thumbnail_pic, bmiddle_pic, original_pic which contain urls
   * for pic art index 0
   *
   * @generated from field: repeated string pics_ids = 6;
   */
  picsIds: string[] = [];

  /**
   * @generated from field: string thumbnail_pic = 7;
   */
  thumbnailPic = "";

  /**
   * @generated from field: string bmiddle_pic = 8;
   */
  bmiddlePic = "";

  /**
   * @generated from field: string original_pic = 9;
   */
  originalPic = "";

  constructor(data?: PartialMessage<WeiboMediaMetadata_RetweetedStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeiboMediaMetadata.RetweetedStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "creator_screen_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "creation_time", kind: "message", T: Timestamp },
    { no: 5, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "pics_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "thumbnail_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "bmiddle_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "original_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeiboMediaMetadata_RetweetedStatus {
    return new WeiboMediaMetadata_RetweetedStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeiboMediaMetadata_RetweetedStatus {
    return new WeiboMediaMetadata_RetweetedStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeiboMediaMetadata_RetweetedStatus {
    return new WeiboMediaMetadata_RetweetedStatus().fromJsonString(jsonString, options);
  }

  static equals(a: WeiboMediaMetadata_RetweetedStatus | PlainMessage<WeiboMediaMetadata_RetweetedStatus> | undefined, b: WeiboMediaMetadata_RetweetedStatus | PlainMessage<WeiboMediaMetadata_RetweetedStatus> | undefined): boolean {
    return proto3.util.equals(WeiboMediaMetadata_RetweetedStatus, a, b);
  }
}

/**
 * @generated from message common.proto.WeiboPic
 */
export class WeiboPic extends Message<WeiboPic> {
  /**
   * @generated from field: string pid = 1;
   */
  pid = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * @generated from field: string size = 3;
   */
  size = "";

  /**
   * @generated from field: common.proto.WeiboPic.WeiboPicGeo geo = 4;
   */
  geo?: WeiboPic_WeiboPicGeo;

  /**
   * @generated from field: common.proto.WeiboPic large = 5;
   */
  large?: WeiboPic;

  constructor(data?: PartialMessage<WeiboPic>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeiboPic";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "size", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "geo", kind: "message", T: WeiboPic_WeiboPicGeo },
    { no: 5, name: "large", kind: "message", T: WeiboPic },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeiboPic {
    return new WeiboPic().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeiboPic {
    return new WeiboPic().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeiboPic {
    return new WeiboPic().fromJsonString(jsonString, options);
  }

  static equals(a: WeiboPic | PlainMessage<WeiboPic> | undefined, b: WeiboPic | PlainMessage<WeiboPic> | undefined): boolean {
    return proto3.util.equals(WeiboPic, a, b);
  }
}

/**
 * @generated from message common.proto.WeiboPic.WeiboPicGeo
 */
export class WeiboPic_WeiboPicGeo extends Message<WeiboPic_WeiboPicGeo> {
  /**
   * @generated from field: int32 width = 1;
   */
  width = 0;

  /**
   * @generated from field: int32 height = 2;
   */
  height = 0;

  /**
   * @generated from field: bool croped = 3;
   */
  croped = false;

  constructor(data?: PartialMessage<WeiboPic_WeiboPicGeo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeiboPic.WeiboPicGeo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "croped", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeiboPic_WeiboPicGeo {
    return new WeiboPic_WeiboPicGeo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeiboPic_WeiboPicGeo {
    return new WeiboPic_WeiboPicGeo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeiboPic_WeiboPicGeo {
    return new WeiboPic_WeiboPicGeo().fromJsonString(jsonString, options);
  }

  static equals(a: WeiboPic_WeiboPicGeo | PlainMessage<WeiboPic_WeiboPicGeo> | undefined, b: WeiboPic_WeiboPicGeo | PlainMessage<WeiboPic_WeiboPicGeo> | undefined): boolean {
    return proto3.util.equals(WeiboPic_WeiboPicGeo, a, b);
  }
}

/**
 * @generated from message common.proto.WeiboSlideCover
 */
export class WeiboSlideCover extends Message<WeiboSlideCover> {
  /**
   * @generated from field: string pic = 1;
   */
  pic = "";

  constructor(data?: PartialMessage<WeiboSlideCover>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeiboSlideCover";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeiboSlideCover {
    return new WeiboSlideCover().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeiboSlideCover {
    return new WeiboSlideCover().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeiboSlideCover {
    return new WeiboSlideCover().fromJsonString(jsonString, options);
  }

  static equals(a: WeiboSlideCover | PlainMessage<WeiboSlideCover> | undefined, b: WeiboSlideCover | PlainMessage<WeiboSlideCover> | undefined): boolean {
    return proto3.util.equals(WeiboSlideCover, a, b);
  }
}

/**
 * @generated from message common.proto.WeiboMedia
 */
export class WeiboMedia extends Message<WeiboMedia> {
  /**
   * @generated from field: common.proto.WeiboMediaMetadata metadata = 1;
   */
  metadata?: WeiboMediaMetadata;

  /**
   * @generated from field: common.proto.GenerikMediaFacts facts = 2;
   */
  facts?: GenerikMediaFacts;

  constructor(data?: PartialMessage<WeiboMedia>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeiboMedia";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: WeiboMediaMetadata },
    { no: 2, name: "facts", kind: "message", T: GenerikMediaFacts },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeiboMedia {
    return new WeiboMedia().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeiboMedia {
    return new WeiboMedia().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeiboMedia {
    return new WeiboMedia().fromJsonString(jsonString, options);
  }

  static equals(a: WeiboMedia | PlainMessage<WeiboMedia> | undefined, b: WeiboMedia | PlainMessage<WeiboMedia> | undefined): boolean {
    return proto3.util.equals(WeiboMedia, a, b);
  }
}


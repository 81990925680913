<lefty-checkbox
  [checked]="selected"
  [disabled]="disabled"
  [indeterminate]="indeterminate"
  *ngIf="supportMultiSelect"
></lefty-checkbox>
<span
  *ngIf="hasValueLabel"
  class="label"
  >{{ valueLabel }}</span
>
<dynamic-component
  *ngIf="hasComponentFactory"
  class="dynamic-item"
  [componentFactory]="componentFactory"
  [value]="value"
>
</dynamic-component>
<ng-content></ng-content>

import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  HostBinding,
  inject,
  input,
  Input,
  Output,
  viewChild,
} from '@angular/core';
import {
  Chip,
  createGhostChip,
  isGhostChip,
  isNotEmptyString,
  Observables,
  randomUUID,
} from '@frontend2/core';
import { Subject } from 'rxjs';
import { LeftyComponent } from '../utils';
import { LeftyIconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lefty-chip',
  templateUrl: 'lefty-chip.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LeftyIconComponent],
})
export class LeftyChipComponent<T> extends LeftyComponent {
  constructor() {
    super();

    this.disposer.add(this.remove$);

    const host = inject(ElementRef<HTMLElement>).nativeElement as HTMLElement;
    effect(() => host.classList.toggle('has-action', this.hasAction()));
  }

  readonly customAction = viewChild<ElementRef<HTMLElement>>('customAction');
  readonly children = computed(
    () => this.customAction()?.nativeElement.children ?? [],
  );

  @HostBinding('attr.data-id')
  readonly chipId = randomUUID();

  @HostBinding('class')
  readonly hostClass = 'lefty-chip';

  @Output()
  readonly remove$ = new Subject<Chip<T>>();

  @Input()
  chip: Chip<T> = createGhostChip();

  readonly removable = input(true);

  readonly hasAction = computed(
    () => this.removable() || this.children().length > 0,
  );

  @HostBinding('class.ghost')
  get isGhost(): boolean {
    return isGhostChip(this.chip);
  }

  get icon(): string {
    return this.chip.icon;
  }

  get formattedValue(): string {
    return this.chip.formattedValue;
  }

  @HostBinding('class.has-icon')
  get hasIcon(): boolean {
    return isNotEmptyString(this.chip.icon);
  }

  get hasLabel(): boolean {
    return isNotEmptyString(this.chip.label);
  }

  get hasFormattedValue(): boolean {
    return isNotEmptyString(this.chip.formattedValue);
  }

  get label(): string {
    if (this.hasLabel === false) {
      return '';
    }
    if (this.hasFormattedValue) {
      return `${this.chip.label}:`;
    }
    return this.chip.label;
  }

  removeChip(event: MouseEvent): void {
    event.preventDefault();
    event.stopPropagation();
    Observables.safeNext(this.remove$, this.chip);
  }

  @HostBinding('class.dark')
  @Input()
  dark = false;

  @HostBinding('class.ghost')
  @Input()
  ghost = false;

  @Input()
  @HostBinding('class.no-overflow')
  noOverflow = false;

  @Input()
  @HostBinding('class.highlight')
  highlight = false;
}

import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  Output,
} from '@angular/core';
import { isNotNil } from '@frontend2/core';

@Directive({
  selector: 'intersection-observer, [intersectionObserver]',
  standalone: true,
})
export class IntersectionObserverDirective implements AfterViewInit, OnDestroy {
  constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

  private observer?: IntersectionObserver;

  @Output()
  readonly trigger$ = new EventEmitter<IntersectionObserverEntry>();

  private handleIntersection(
    entries: IntersectionObserverEntry[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    observer: IntersectionObserver,
  ): void {
    const entry = entries.find((entry) => entry.isIntersecting);
    if (isNotNil(entry)) {
      this.trigger$.emit(entry);
    }
  }

  ngAfterViewInit(): void {
    this.elementRef.nativeElement.style.display = 'block';
    this.observer = new IntersectionObserver(
      this.handleIntersection.bind(this),
      {
        // threshold 0 meaning even if 1px of the IntersectionObserverDirective is visible
        // we will trigger event
        threshold: 0,
      },
    );

    // element height must at least be 1px or it will never intersect
    this.elementRef.nativeElement.style.height = '1px';

    this.observer.observe(this.elementRef.nativeElement);
  }

  ngOnDestroy(): void {
    this.observer?.disconnect();
    this.trigger$.complete();
  }
}

import { Injectable } from '@angular/core';
import { CampaignKeywordType } from '@frontend2/proto/common/proto/campaign_pb';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  createTrackingKeywordsConfig,
  youtubeKeywordsField,
  youtubeTagsField,
} from './config.helpers';
import * as TKMessages from './config.messages';
import { DefaultNetworkConfig, FormField } from './config.models';

@Injectable({ providedIn: 'root' })
export class YoutubeConfig extends DefaultNetworkConfig {
  override readonly network = Network.YOUTUBE;
  override readonly supportEngagementRate = false;
  override readonly supportViews = true;
  override readonly uploadPlaceholder = [
    'https://www.youtube.com/user/aMOODIEsqueezie',
    'https://www.youtube.com/channel/UCY-_QmcW09PHAImgVnKxU2g',
    'https://www.youtube.com/@Sananas',
    'Sananas',
    '...',
  ].join('\n');

  override readonly keywords = createTrackingKeywordsConfig({
    types: [CampaignKeywordType.YOUTUBE_WORD, CampaignKeywordType.YOUTUBE_TAG],
    formHelpers: new Map<CampaignKeywordType, FormField>([
      [CampaignKeywordType.YOUTUBE_WORD, youtubeKeywordsField],
      [CampaignKeywordType.YOUTUBE_TAG, youtubeTagsField],
    ]),
    errorMessage: TKMessages.youtubeErrorMessage,
  });
}

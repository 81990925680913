import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianAdmin } from '@frontend2/proto/librarian/proto/admin_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianAdminClient = PromiseClient<typeof LibrarianAdmin>;

export const LibrarianAdminClientProvider = new InjectionToken(
  'LibrarianAdminClient',
  {
    factory: (): LibrarianAdminClient =>
      createPromiseClient(LibrarianAdmin, inject(GRPC_TRANSPORT)),
  },
);

export function injectLibrarianAdminClient(): LibrarianAdminClient {
  return inject(LibrarianAdminClientProvider);
}

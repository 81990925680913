/// Create a new Array with the modified elements when it match predicate
///
/// It will iterate on all elements even if predicate already matched.
/// use stopOnFirst to stop iterate after first predicate match
export function updateElementInArray<T>(
  array: T[],
  args: {
    predicate: (el: T) => boolean;
    modifier: (el: T) => T;
    stopOnFirst?: boolean;
    appendIfNotFound?: T;
    addToBeginning?: boolean;
  },
): T[] {
  array = [...array];

  let found = false;

  for (let i = 0; i < array.length; i++) {
    if (args.predicate(array[i])) {
      found = true;
      array[i] = args.modifier(array[i]);

      if (args.stopOnFirst) {
        return array;
      }
    }
  }

  if (!found && args.appendIfNotFound) {
    if (args.addToBeginning === true) {
      array.unshift(args.appendIfNotFound);
    } else {
      array.push(args.appendIfNotFound);
    }
  }

  return array;
}

export function addOrUpdateInArray<T>(
  array: T[],
  item: T,
  args: {
    predicate: (el: T) => boolean;
    addToBeginning?: boolean;
  },
): T[] {
  return updateElementInArray(array, {
    predicate: args.predicate,
    modifier: () => item,
    stopOnFirst: true,
    appendIfNotFound: item,
    addToBeginning: args.addToBeginning,
  });
}

export function removeFromArray<T>(
  array: T[],
  args: {
    predicate: (el: T) => boolean;
    stopOnFirst?: boolean;
  },
): T[] {
  const newArray = [...array];

  for (let i = newArray.length - 1; i >= 0; i--) {
    if (args.predicate(newArray[i])) {
      newArray.splice(i, 1);
      if (args.stopOnFirst) {
        break;
      }
    }
  }

  return newArray;
}

export function arrayToMap<Key, Value>(
  array: Value[],
  key: (val: Value) => Key,
): Map<Key, Value> {
  const map = new Map<Key, Value>();

  array.forEach((val) => {
    map.set(key(val), val);
  });

  return map;
}

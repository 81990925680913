import { Injectable } from '@angular/core';
import { CampaignKeywordType } from '@frontend2/proto/common/proto/campaign_pb';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { Post } from '../lefty-posts/posts.models';
import {
  createTrackingKeywordsConfig,
  weiboKeywordsField,
  weiboMentionsField,
  weiboTagsField,
} from './config.helpers';
import * as TKMessages from './config.messages';
import { DefaultNetworkConfig, FormField } from './config.models';

@Injectable({ providedIn: 'root' })
export class WeiboConfig extends DefaultNetworkConfig {
  override readonly network = Network.WEIBO;
  override readonly uploadPlaceholder: string = [
    'PapaJames',
    'https://m.weibo.cn/u/1687316021',
    'https://weibo.com/rorohuang',
    '...',
  ].join('\n');

  override readonly keywords = createTrackingKeywordsConfig({
    types: [
      CampaignKeywordType.WEIBO_TAG,
      CampaignKeywordType.WEIBO_MENTION,
      CampaignKeywordType.WEIBO_KEYWORD,
    ],
    formHelpers: new Map<CampaignKeywordType, FormField>([
      [CampaignKeywordType.WEIBO_TAG, weiboTagsField],
      [CampaignKeywordType.WEIBO_MENTION, weiboMentionsField],
      [CampaignKeywordType.WEIBO_KEYWORD, weiboKeywordsField],
    ]),
    delimiters: new Map<CampaignKeywordType, string>([
      [CampaignKeywordType.WEIBO_TAG, ',#@'],
    ]),
    errorMessage: TKMessages.weiboErrorMessage,
  });

  override sanitizeCaptionHref(post: Post, href: string): string {
    if (href.startsWith('/n/')) {
      return 'https://weibo.com$href';
    }
    if (href.startsWith('/status/')) {
      return this.getExternalPostLink(post);
    }
    return href;
  }
}

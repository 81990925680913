import {
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnChanges,
  ViewContainerRef,
} from '@angular/core';
import { isEmptyString, isNil, isNotEmptyString } from '@frontend2/core';
import { LeftyIconComponent } from '../icon/icon.component';
import { LeftySpinnerComponent } from '../loading.component';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'outline'
  | 'caution'
  | 'destructive'
  | 'subtle'
  | 'ghost'
  | 'plain';

export type ButtonSize = 'small' | 'medium' | 'large';

@Directive({
  selector: 'button[leftyButton] , a[leftyButton]',
  standalone: true,
})
export class LeftyButtonDirective implements OnChanges {
  constructor(
    private viewContainerRef: ViewContainerRef,
    private el: ElementRef,
  ) {}

  private _buttonType: ButtonType = 'primary';

  @Input()
  set leftyButton(type: ButtonType | undefined | '') {
    if (isNil(type) || isEmptyString(type)) {
      type = 'primary';
    }

    this._buttonType = type;
  }

  @Input()
  size: ButtonSize = 'medium';

  @Input() icon = '';

  @Input() leadingIcon = '';

  @Input() trailingIcon = '';

  @Input() loading = false;

  @HostBinding('class.icon')
  get hasIcon(): boolean {
    return isNotEmptyString(this.icon);
  }

  get hasLeadingIcon(): boolean {
    return isNotEmptyString(this.leadingIcon) && !this.hasIcon;
  }

  @HostBinding('class.left-icon')
  get leadingIconAndNotLoading(): boolean {
    return this.hasLeadingIcon && !this.loading;
  }

  @HostBinding('class.right-icon')
  get hasTrailingIcon(): boolean {
    return isNotEmptyString(this.trailingIcon) && !this.hasIcon;
  }

  get hasAnyIcon(): boolean {
    return this.hasIcon || this.hasLeadingIcon || this.hasTrailingIcon;
  }

  @HostBinding('class.button')
  get isAnchorlink(): boolean {
    return this.el.nativeElement.tagName.toLowerCase() === 'a';
  }

  @Input()
  dark = false;

  @HostBinding('class')
  get className(): string {
    return `${this._buttonType} ${this.size} ${this.dark ? 'dark' : ''}`;
  }

  iconsAndSpinnerRefs: ElementRef<Element>[] = [];

  private _addIcons(): void {
    if (this.hasIcon) {
      this.el.nativeElement.innerHTML = '';
      if (!this.loading) {
        const iconRef = this._createIconComponent(this.icon);
        this.el.nativeElement.appendChild(iconRef);
      }
    } else {
      if (this.hasLeadingIcon && !this.loading) {
        const leadingIconRef = this._createIconComponent(this.leadingIcon);
        this.el.nativeElement.insertBefore(
          leadingIconRef,
          this.el.nativeElement.firstChild,
        );
      }
      if (this.hasTrailingIcon) {
        const trailingIconRef = this._createIconComponent(this.trailingIcon);
        this.el.nativeElement.appendChild(trailingIconRef);
      }
    }
  }

  private _addSpinners(): void {
    if (this.loading) {
      const spinnerRef =
        this.viewContainerRef.createComponent<LeftySpinnerComponent>(
          LeftySpinnerComponent,
        );

      this.iconsAndSpinnerRefs.push(spinnerRef.location);
      this.el.nativeElement.insertBefore(
        spinnerRef.location.nativeElement,
        this.el.nativeElement.firstChild,
      );
    }
  }

  private _createIconComponent(icon: string): ElementRef<LeftyIconComponent> {
    const comp =
      this.viewContainerRef.createComponent<LeftyIconComponent>(
        LeftyIconComponent,
      );
    comp.instance.icon = icon;
    this.iconsAndSpinnerRefs.push(comp.location);
    return comp.location.nativeElement;
  }

  private _cleanup(): void {
    for (const ref of this.iconsAndSpinnerRefs) {
      ref.nativeElement.remove();
    }
    this.iconsAndSpinnerRefs = [];
  }

  ngOnChanges(): void {
    this._cleanup();
    this._addIcons();
    this._addSpinners();
  }
}

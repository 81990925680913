import { Injectable } from '@angular/core';
import { CampaignKeywordType } from '@frontend2/proto/common/proto/campaign_pb';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  createTrackingKeywordsConfig,
  hashtagsField,
  instaKeywordsField,
  locationsField,
  mentionsField,
} from './config.helpers';
import * as TKMessages from './config.messages';
import { DefaultNetworkConfig, FormField } from './config.models';

@Injectable({ providedIn: 'root' })
export class InstagramConfig extends DefaultNetworkConfig {
  override readonly network = Network.INSTA;
  override readonly uploadPlaceholder = [
    'selenagomez',
    'www.instagram.com/cristiano',
    'instagr.am/arianagrande',
    '...',
  ].join('\n');

  override readonly keywords = createTrackingKeywordsConfig({
    types: [
      CampaignKeywordType.INSTA_TAG,
      CampaignKeywordType.INSTA_MENTION,
      CampaignKeywordType.INSTA_KEYWORD,
      CampaignKeywordType.INSTA_LOCATION,
    ],
    formHelpers: new Map<CampaignKeywordType, FormField>([
      [CampaignKeywordType.INSTA_TAG, hashtagsField],
      [CampaignKeywordType.INSTA_MENTION, mentionsField],
      [CampaignKeywordType.INSTA_KEYWORD, instaKeywordsField],
      [CampaignKeywordType.INSTA_LOCATION, locationsField],
    ]),
    errorMessage: TKMessages.instagramErrorMessage,
  });

  override readonly supportSaturationRate: boolean = true;

  override readonly supportAvgCoverage: boolean = true;
}

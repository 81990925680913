<div class="grid-equalHeight compact">
  <div
    *ngFor="let comment of comments; trackBy: trackBy"
    [class.col-4]="!mobile"
    [class.col-12]="mobile"
  >
    <casting-profile-comment-card
      [comment]="comment"
      [mobile]="mobile"
      [loading]="loading"
      (addComment$)="addComment$.emit($event)"
      (updateComment$)="updateComment$.emit($event)"
      (deleteComment$)="handleDeleteComment($event)"
    >
    </casting-profile-comment-card>
  </div>

  <div
    [class.col-12]="comments.length === 0 || mobile"
    [class.col-4]="comments.length !== 0 && !mobile"
    *ngIf="!editing"
  >
    <button
      class="large add-comment-btn subtle left-icon"
      (click)="startAddComment()"
    >
      <lefty-icon icon="edit_note"></lefty-icon>
      {{ addCommentMsg }}
    </button>
  </div>
</div>

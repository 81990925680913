// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/entity_fields.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BaseEntityTypeReq, EntityField } from "./common_pb";
import { EntityFieldRawValuesRequest, EntityFieldsList, EntityFieldUpdateReq, EntityFieldValue, SetEntityFieldsValuesReq } from "./entity_fields_pb";
import { Empty, Int64Value, MethodKind, StringValue } from "@bufbuild/protobuf";

/**
 * @generated from service librarian.proto.LibrarianEntityFields
 */
export const LibrarianEntityFields = {
  typeName: "librarian.proto.LibrarianEntityFields",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianEntityFields.GetEntityFieldsAPI
     */
    getEntityFieldsAPI: {
      name: "GetEntityFieldsAPI",
      I: BaseEntityTypeReq,
      O: EntityFieldsList,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEntityFields.AddEntityFieldAPI
     */
    addEntityFieldAPI: {
      name: "AddEntityFieldAPI",
      I: EntityField,
      O: Int64Value,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEntityFields.EditEntityFieldAPI
     */
    editEntityFieldAPI: {
      name: "EditEntityFieldAPI",
      I: EntityField,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEntityFields.ArchiveEntityFieldAPI
     */
    archiveEntityFieldAPI: {
      name: "ArchiveEntityFieldAPI",
      I: EntityFieldUpdateReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEntityFields.UnarchiveEntityFieldAPI
     */
    unarchiveEntityFieldAPI: {
      name: "UnarchiveEntityFieldAPI",
      I: EntityFieldUpdateReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEntityFields.SetEntityFieldValueAPI
     */
    setEntityFieldValueAPI: {
      name: "SetEntityFieldValueAPI",
      I: EntityFieldValue,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Return the task handle
     *
     * @generated from rpc librarian.proto.LibrarianEntityFields.SetEntityFieldsValuesAPI
     */
    setEntityFieldsValuesAPI: {
      name: "SetEntityFieldsValuesAPI",
      I: SetEntityFieldsValuesReq,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEntityFields.ImportEntityFieldRawValues
     */
    importEntityFieldRawValues: {
      name: "ImportEntityFieldRawValues",
      I: EntityFieldRawValuesRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
  }
} as const;


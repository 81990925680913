import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianChat } from '@frontend2/proto/librarian/proto/chat_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrariaChatClient = PromiseClient<typeof LibrarianChat>;

export const LibrarianChatClientProvider = new InjectionToken(
  'LibrariaChatClient',
  {
    factory: (): LibrariaChatClient =>
      createPromiseClient(LibrarianChat, inject(GRPC_TRANSPORT)),
  },
);

import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianCastings } from '@frontend2/proto/librarian/proto/casting_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianCastingsClient = PromiseClient<typeof LibrarianCastings>;

const factory = (): LibrarianCastingsClient =>
  createPromiseClient(LibrarianCastings, inject(GRPC_TRANSPORT));

const token = new InjectionToken('LibrarianCastings', {
  factory,
});

export function injectLibrarianCastingsClient(): LibrarianCastingsClient {
  return inject(token);
}

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/profile_merge_requests.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { CreatorTiniestCard, GenerikInfoCard } from "./creators_pb";
import { BasicPagination, Network, SortDirection } from "../../common/proto/common_pb";
import { InfluencerHandle } from "./common_pb";

/**
 * @generated from enum librarian.proto.ProfileMergeRequestErrorType
 */
export enum ProfileMergeRequestErrorType {
  /**
   * if a line contains only one influencerHandle instead of more than 2
   *
   * @generated from enum value: MISSING_PROFILES = 0;
   */
  MISSING_PROFILES = 0,

  /**
   * if profile haves already merged network profiles different from line ones
   *
   * @generated from enum value: PROFILE_ALREADY_MERGED = 1;
   */
  PROFILE_ALREADY_MERGED = 1,

  /**
   * meta creator not found for the given influencer handle, might be a typo in the username/url
   *
   * @generated from enum value: PROFILE_NOT_FOUND = 2;
   */
  PROFILE_NOT_FOUND = 2,

  /**
   * different lines with the same handle and not the same profiles to merge
   *
   * @generated from enum value: PROFILE_MULTIPLE_TIMES = 3;
   */
  PROFILE_MULTIPLE_TIMES = 3,

  /**
   * if trying to unmerge master network or networks that creator doesn't have
   *
   * @generated from enum value: PROFILE_INVALID_UNMERGE = 4;
   */
  PROFILE_INVALID_UNMERGE = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ProfileMergeRequestErrorType)
proto3.util.setEnumType(ProfileMergeRequestErrorType, "librarian.proto.ProfileMergeRequestErrorType", [
  { no: 0, name: "MISSING_PROFILES" },
  { no: 1, name: "PROFILE_ALREADY_MERGED" },
  { no: 2, name: "PROFILE_NOT_FOUND" },
  { no: 3, name: "PROFILE_MULTIPLE_TIMES" },
  { no: 4, name: "PROFILE_INVALID_UNMERGE" },
]);

/**
 * @generated from enum librarian.proto.ProfileMergeRequestStatus
 */
export enum ProfileMergeRequestStatus {
  /**
   * @generated from enum value: PROFILE_MERGE_PENDING = 0;
   */
  PROFILE_MERGE_PENDING = 0,

  /**
   * @generated from enum value: PROFILE_MERGE_APPROVED = 1;
   */
  PROFILE_MERGE_APPROVED = 1,

  /**
   * @generated from enum value: PROFILE_MERGE_REJECTED = 2;
   */
  PROFILE_MERGE_REJECTED = 2,

  /**
   * @generated from enum value: PROFILE_MERGE_DELETED = 3;
   */
  PROFILE_MERGE_DELETED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ProfileMergeRequestStatus)
proto3.util.setEnumType(ProfileMergeRequestStatus, "librarian.proto.ProfileMergeRequestStatus", [
  { no: 0, name: "PROFILE_MERGE_PENDING" },
  { no: 1, name: "PROFILE_MERGE_APPROVED" },
  { no: 2, name: "PROFILE_MERGE_REJECTED" },
  { no: 3, name: "PROFILE_MERGE_DELETED" },
]);

/**
 * @generated from enum librarian.proto.ProfileMergeRequestCompletion
 */
export enum ProfileMergeRequestCompletion {
  /**
   * @generated from enum value: PROFILE_MERGE_COMPLETION_PENDING = 0;
   */
  PROFILE_MERGE_COMPLETION_PENDING = 0,

  /**
   * @generated from enum value: PROFILE_MERGE_COMPLETION_IN_PROGRESS = 1;
   */
  PROFILE_MERGE_COMPLETION_IN_PROGRESS = 1,

  /**
   * @generated from enum value: PROFILE_MERGE_COMPLETION_DONE = 2;
   */
  PROFILE_MERGE_COMPLETION_DONE = 2,

  /**
   * @generated from enum value: PROFILE_MERGE_COMPLETION_FAILED = 3;
   */
  PROFILE_MERGE_COMPLETION_FAILED = 3,

  /**
   * when a merge has been unmerged or vice versa
   *
   * @generated from enum value: PROFILE_MERGE_COMPLETION_REVERSED = 4;
   */
  PROFILE_MERGE_COMPLETION_REVERSED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ProfileMergeRequestCompletion)
proto3.util.setEnumType(ProfileMergeRequestCompletion, "librarian.proto.ProfileMergeRequestCompletion", [
  { no: 0, name: "PROFILE_MERGE_COMPLETION_PENDING" },
  { no: 1, name: "PROFILE_MERGE_COMPLETION_IN_PROGRESS" },
  { no: 2, name: "PROFILE_MERGE_COMPLETION_DONE" },
  { no: 3, name: "PROFILE_MERGE_COMPLETION_FAILED" },
  { no: 4, name: "PROFILE_MERGE_COMPLETION_REVERSED" },
]);

/**
 * @generated from message librarian.proto.ProfileMergeRequestPb
 */
export class ProfileMergeRequestPb extends Message<ProfileMergeRequestPb> {
  /**
   * @generated from field: int64 request_id = 1;
   */
  requestId = protoInt64.zero;

  /**
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: int64 team_id = 9;
   */
  teamId = protoInt64.zero;

  /**
   * @generated from field: string team_name = 10;
   */
  teamName = "";

  /**
   * @generated from field: int64 real_user_id = 3;
   */
  realUserId = protoInt64.zero;

  /**
   * @generated from field: int64 real_user_account_id = 12;
   */
  realUserAccountId = protoInt64.zero;

  /**
   * @generated from field: string real_user_email = 11;
   */
  realUserEmail = "";

  /**
   * @generated from field: repeated librarian.proto.ProfileMergeRequestPb.MergeRequestProfile profiles = 4;
   */
  profiles: ProfileMergeRequestPb_MergeRequestProfile[] = [];

  /**
   * @generated from field: librarian.proto.ProfileMergeRequestStatus status = 5;
   */
  status = ProfileMergeRequestStatus.PROFILE_MERGE_PENDING;

  /**
   * @generated from field: librarian.proto.ProfileMergeRequestCompletion completion = 6;
   */
  completion = ProfileMergeRequestCompletion.PROFILE_MERGE_COMPLETION_PENDING;

  /**
   * @generated from field: google.protobuf.StringValue reason = 7;
   */
  reason?: string;

  /**
   * @generated from field: google.protobuf.Timestamp creation_date = 8;
   */
  creationDate?: Timestamp;

  constructor(data?: PartialMessage<ProfileMergeRequestPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProfileMergeRequestPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "team_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "team_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "real_user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "real_user_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "real_user_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "profiles", kind: "message", T: ProfileMergeRequestPb_MergeRequestProfile, repeated: true },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(ProfileMergeRequestStatus) },
    { no: 6, name: "completion", kind: "enum", T: proto3.getEnumType(ProfileMergeRequestCompletion) },
    { no: 7, name: "reason", kind: "message", T: StringValue },
    { no: 8, name: "creation_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileMergeRequestPb {
    return new ProfileMergeRequestPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileMergeRequestPb {
    return new ProfileMergeRequestPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileMergeRequestPb {
    return new ProfileMergeRequestPb().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileMergeRequestPb | PlainMessage<ProfileMergeRequestPb> | undefined, b: ProfileMergeRequestPb | PlainMessage<ProfileMergeRequestPb> | undefined): boolean {
    return proto3.util.equals(ProfileMergeRequestPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProfileMergeRequestPb.MergeRequestProfile
 */
export class ProfileMergeRequestPb_MergeRequestProfile extends Message<ProfileMergeRequestPb_MergeRequestProfile> {
  /**
   * @generated from field: librarian.proto.CreatorTiniestCard creator = 1;
   */
  creator?: CreatorTiniestCard;

  /**
   * @generated from field: repeated librarian.proto.GenerikInfoCard network_info = 2;
   */
  networkInfo: GenerikInfoCard[] = [];

  /**
   * @generated from field: repeated common.proto.Network networks_to_unmerge = 3;
   */
  networksToUnmerge: Network[] = [];

  constructor(data?: PartialMessage<ProfileMergeRequestPb_MergeRequestProfile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProfileMergeRequestPb.MergeRequestProfile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator", kind: "message", T: CreatorTiniestCard },
    { no: 2, name: "network_info", kind: "message", T: GenerikInfoCard, repeated: true },
    { no: 3, name: "networks_to_unmerge", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileMergeRequestPb_MergeRequestProfile {
    return new ProfileMergeRequestPb_MergeRequestProfile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileMergeRequestPb_MergeRequestProfile {
    return new ProfileMergeRequestPb_MergeRequestProfile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileMergeRequestPb_MergeRequestProfile {
    return new ProfileMergeRequestPb_MergeRequestProfile().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileMergeRequestPb_MergeRequestProfile | PlainMessage<ProfileMergeRequestPb_MergeRequestProfile> | undefined, b: ProfileMergeRequestPb_MergeRequestProfile | PlainMessage<ProfileMergeRequestPb_MergeRequestProfile> | undefined): boolean {
    return proto3.util.equals(ProfileMergeRequestPb_MergeRequestProfile, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProfileMergeRequestsPb
 */
export class ProfileMergeRequestsPb extends Message<ProfileMergeRequestsPb> {
  /**
   * @generated from field: repeated librarian.proto.ProfileMergeRequestPb requests = 1;
   */
  requests: ProfileMergeRequestPb[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<ProfileMergeRequestsPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProfileMergeRequestsPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "requests", kind: "message", T: ProfileMergeRequestPb, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileMergeRequestsPb {
    return new ProfileMergeRequestsPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileMergeRequestsPb {
    return new ProfileMergeRequestsPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileMergeRequestsPb {
    return new ProfileMergeRequestsPb().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileMergeRequestsPb | PlainMessage<ProfileMergeRequestsPb> | undefined, b: ProfileMergeRequestsPb | PlainMessage<ProfileMergeRequestsPb> | undefined): boolean {
    return proto3.util.equals(ProfileMergeRequestsPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProfileMergeManual
 */
export class ProfileMergeManual extends Message<ProfileMergeManual> {
  /**
   * @generated from field: repeated librarian.proto.ProfileMergeManual.MergeRequest merge_requests = 1;
   */
  mergeRequests: ProfileMergeManual_MergeRequest[] = [];

  constructor(data?: PartialMessage<ProfileMergeManual>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProfileMergeManual";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "merge_requests", kind: "message", T: ProfileMergeManual_MergeRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileMergeManual {
    return new ProfileMergeManual().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileMergeManual {
    return new ProfileMergeManual().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileMergeManual {
    return new ProfileMergeManual().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileMergeManual | PlainMessage<ProfileMergeManual> | undefined, b: ProfileMergeManual | PlainMessage<ProfileMergeManual> | undefined): boolean {
    return proto3.util.equals(ProfileMergeManual, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProfileMergeManual.ProfileToMerge
 */
export class ProfileMergeManual_ProfileToMerge extends Message<ProfileMergeManual_ProfileToMerge> {
  /**
   * @generated from field: librarian.proto.InfluencerHandle influencer_handle = 1;
   */
  influencerHandle?: InfluencerHandle;

  /**
   * @generated from field: repeated common.proto.Network networks_to_unmerge = 2;
   */
  networksToUnmerge: Network[] = [];

  constructor(data?: PartialMessage<ProfileMergeManual_ProfileToMerge>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProfileMergeManual.ProfileToMerge";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_handle", kind: "message", T: InfluencerHandle },
    { no: 2, name: "networks_to_unmerge", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileMergeManual_ProfileToMerge {
    return new ProfileMergeManual_ProfileToMerge().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileMergeManual_ProfileToMerge {
    return new ProfileMergeManual_ProfileToMerge().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileMergeManual_ProfileToMerge {
    return new ProfileMergeManual_ProfileToMerge().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileMergeManual_ProfileToMerge | PlainMessage<ProfileMergeManual_ProfileToMerge> | undefined, b: ProfileMergeManual_ProfileToMerge | PlainMessage<ProfileMergeManual_ProfileToMerge> | undefined): boolean {
    return proto3.util.equals(ProfileMergeManual_ProfileToMerge, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProfileMergeManual.MergeRequest
 */
export class ProfileMergeManual_MergeRequest extends Message<ProfileMergeManual_MergeRequest> {
  /**
   * @generated from field: repeated librarian.proto.ProfileMergeManual.ProfileToMerge profiles = 1;
   */
  profiles: ProfileMergeManual_ProfileToMerge[] = [];

  constructor(data?: PartialMessage<ProfileMergeManual_MergeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProfileMergeManual.MergeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profiles", kind: "message", T: ProfileMergeManual_ProfileToMerge, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileMergeManual_MergeRequest {
    return new ProfileMergeManual_MergeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileMergeManual_MergeRequest {
    return new ProfileMergeManual_MergeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileMergeManual_MergeRequest {
    return new ProfileMergeManual_MergeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileMergeManual_MergeRequest | PlainMessage<ProfileMergeManual_MergeRequest> | undefined, b: ProfileMergeManual_MergeRequest | PlainMessage<ProfileMergeManual_MergeRequest> | undefined): boolean {
    return proto3.util.equals(ProfileMergeManual_MergeRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProfileMergeCreateRequest
 */
export class ProfileMergeCreateRequest extends Message<ProfileMergeCreateRequest> {
  /**
   * @generated from oneof librarian.proto.ProfileMergeCreateRequest.request
   */
  request: {
    /**
     * @generated from field: librarian.proto.ProfileMergeManual manual = 1;
     */
    value: ProfileMergeManual;
    case: "manual";
  } | {
    /**
     * @generated from field: string csv = 2;
     */
    value: string;
    case: "csv";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * filled by backend
   *
   * @generated from field: int64 real_user_id = 3;
   */
  realUserId = protoInt64.zero;

  constructor(data?: PartialMessage<ProfileMergeCreateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProfileMergeCreateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "manual", kind: "message", T: ProfileMergeManual, oneof: "request" },
    { no: 2, name: "csv", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "request" },
    { no: 3, name: "real_user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileMergeCreateRequest {
    return new ProfileMergeCreateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileMergeCreateRequest {
    return new ProfileMergeCreateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileMergeCreateRequest {
    return new ProfileMergeCreateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileMergeCreateRequest | PlainMessage<ProfileMergeCreateRequest> | undefined, b: ProfileMergeCreateRequest | PlainMessage<ProfileMergeCreateRequest> | undefined): boolean {
    return proto3.util.equals(ProfileMergeCreateRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProfileMergeEditRequest
 */
export class ProfileMergeEditRequest extends Message<ProfileMergeEditRequest> {
  /**
   * @generated from field: int64 request_id = 1;
   */
  requestId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.ProfileMergeManual manual = 2;
   */
  manual?: ProfileMergeManual;

  constructor(data?: PartialMessage<ProfileMergeEditRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProfileMergeEditRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "manual", kind: "message", T: ProfileMergeManual },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileMergeEditRequest {
    return new ProfileMergeEditRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileMergeEditRequest {
    return new ProfileMergeEditRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileMergeEditRequest {
    return new ProfileMergeEditRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileMergeEditRequest | PlainMessage<ProfileMergeEditRequest> | undefined, b: ProfileMergeEditRequest | PlainMessage<ProfileMergeEditRequest> | undefined): boolean {
    return proto3.util.equals(ProfileMergeEditRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProfileMergeRequestErrors
 */
export class ProfileMergeRequestErrors extends Message<ProfileMergeRequestErrors> {
  /**
   * @generated from field: repeated librarian.proto.ProfileMergeRequestErrors.ProfileMergeRequestError profile_errors = 1;
   */
  profileErrors: ProfileMergeRequestErrors_ProfileMergeRequestError[] = [];

  constructor(data?: PartialMessage<ProfileMergeRequestErrors>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProfileMergeRequestErrors";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "profile_errors", kind: "message", T: ProfileMergeRequestErrors_ProfileMergeRequestError, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileMergeRequestErrors {
    return new ProfileMergeRequestErrors().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileMergeRequestErrors {
    return new ProfileMergeRequestErrors().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileMergeRequestErrors {
    return new ProfileMergeRequestErrors().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileMergeRequestErrors | PlainMessage<ProfileMergeRequestErrors> | undefined, b: ProfileMergeRequestErrors | PlainMessage<ProfileMergeRequestErrors> | undefined): boolean {
    return proto3.util.equals(ProfileMergeRequestErrors, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProfileMergeRequestErrors.ProfileMergeRequestError
 */
export class ProfileMergeRequestErrors_ProfileMergeRequestError extends Message<ProfileMergeRequestErrors_ProfileMergeRequestError> {
  /**
   * @generated from field: librarian.proto.InfluencerHandle influencer_handle = 1;
   */
  influencerHandle?: InfluencerHandle;

  /**
   * @generated from field: repeated librarian.proto.ProfileMergeRequestErrorType error_types = 2;
   */
  errorTypes: ProfileMergeRequestErrorType[] = [];

  /**
   * networks merged with profiles different from request ones, if errors contains PROFILE_ALREADY_MERGED
   *
   * @generated from field: repeated common.proto.Network already_merged_networks = 3;
   */
  alreadyMergedNetworks: Network[] = [];

  constructor(data?: PartialMessage<ProfileMergeRequestErrors_ProfileMergeRequestError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProfileMergeRequestErrors.ProfileMergeRequestError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_handle", kind: "message", T: InfluencerHandle },
    { no: 2, name: "error_types", kind: "enum", T: proto3.getEnumType(ProfileMergeRequestErrorType), repeated: true },
    { no: 3, name: "already_merged_networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileMergeRequestErrors_ProfileMergeRequestError {
    return new ProfileMergeRequestErrors_ProfileMergeRequestError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileMergeRequestErrors_ProfileMergeRequestError {
    return new ProfileMergeRequestErrors_ProfileMergeRequestError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileMergeRequestErrors_ProfileMergeRequestError {
    return new ProfileMergeRequestErrors_ProfileMergeRequestError().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileMergeRequestErrors_ProfileMergeRequestError | PlainMessage<ProfileMergeRequestErrors_ProfileMergeRequestError> | undefined, b: ProfileMergeRequestErrors_ProfileMergeRequestError | PlainMessage<ProfileMergeRequestErrors_ProfileMergeRequestError> | undefined): boolean {
    return proto3.util.equals(ProfileMergeRequestErrors_ProfileMergeRequestError, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProfileMergeRequestsFilter
 */
export class ProfileMergeRequestsFilter extends Message<ProfileMergeRequestsFilter> {
  /**
   * @generated from field: common.proto.BasicPagination pagination = 1;
   */
  pagination?: BasicPagination;

  /**
   * @generated from field: librarian.proto.ProfileMergeRequestFilter filter = 2;
   */
  filter?: ProfileMergeRequestFilter;

  /**
   * for now sort by updated date, which is the creation, the edition or the validation date
   *
   * @generated from field: common.proto.SortDirection sort_direction = 3;
   */
  sortDirection = SortDirection.DESC;

  /**
   * if true return all requests (only for admin), else return workspace requests
   *
   * @generated from field: bool admin = 4;
   */
  admin = false;

  constructor(data?: PartialMessage<ProfileMergeRequestsFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProfileMergeRequestsFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: BasicPagination },
    { no: 2, name: "filter", kind: "message", T: ProfileMergeRequestFilter },
    { no: 3, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 4, name: "admin", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileMergeRequestsFilter {
    return new ProfileMergeRequestsFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileMergeRequestsFilter {
    return new ProfileMergeRequestsFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileMergeRequestsFilter {
    return new ProfileMergeRequestsFilter().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileMergeRequestsFilter | PlainMessage<ProfileMergeRequestsFilter> | undefined, b: ProfileMergeRequestsFilter | PlainMessage<ProfileMergeRequestsFilter> | undefined): boolean {
    return proto3.util.equals(ProfileMergeRequestsFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProfileMergeRequestFilter
 */
export class ProfileMergeRequestFilter extends Message<ProfileMergeRequestFilter> {
  /**
   * @generated from field: int64 request_id = 1;
   */
  requestId = protoInt64.zero;

  /**
   * only for admin (to filter workspace or real user), else it is not used
   *
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: string team_name_or_email = 5;
   */
  teamNameOrEmail = "";

  /**
   * @generated from field: string influencer_lefty_id = 6;
   */
  influencerLeftyId = "";

  /**
   * @generated from field: repeated librarian.proto.ProfileMergeRequestStatus statuses = 4;
   */
  statuses: ProfileMergeRequestStatus[] = [];

  constructor(data?: PartialMessage<ProfileMergeRequestFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProfileMergeRequestFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "team_name_or_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "influencer_lefty_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "statuses", kind: "enum", T: proto3.getEnumType(ProfileMergeRequestStatus), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileMergeRequestFilter {
    return new ProfileMergeRequestFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileMergeRequestFilter {
    return new ProfileMergeRequestFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileMergeRequestFilter {
    return new ProfileMergeRequestFilter().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileMergeRequestFilter | PlainMessage<ProfileMergeRequestFilter> | undefined, b: ProfileMergeRequestFilter | PlainMessage<ProfileMergeRequestFilter> | undefined): boolean {
    return proto3.util.equals(ProfileMergeRequestFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.ProfileMergeValidateRequest
 */
export class ProfileMergeValidateRequest extends Message<ProfileMergeValidateRequest> {
  /**
   * @generated from field: int64 request_id = 1;
   */
  requestId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.ProfileMergeRequestStatus status = 2;
   */
  status = ProfileMergeRequestStatus.PROFILE_MERGE_PENDING;

  /**
   * @generated from field: google.protobuf.StringValue reason = 3;
   */
  reason?: string;

  constructor(data?: PartialMessage<ProfileMergeValidateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ProfileMergeValidateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "status", kind: "enum", T: proto3.getEnumType(ProfileMergeRequestStatus) },
    { no: 3, name: "reason", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProfileMergeValidateRequest {
    return new ProfileMergeValidateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProfileMergeValidateRequest {
    return new ProfileMergeValidateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProfileMergeValidateRequest {
    return new ProfileMergeValidateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ProfileMergeValidateRequest | PlainMessage<ProfileMergeValidateRequest> | undefined, b: ProfileMergeValidateRequest | PlainMessage<ProfileMergeValidateRequest> | undefined): boolean {
    return proto3.util.equals(ProfileMergeValidateRequest, a, b);
  }
}


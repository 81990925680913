import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianForecastings } from '@frontend2/proto/librarian/proto/casting_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianForecastCastingsClient = PromiseClient<
  typeof LibrarianForecastings
>;

const factory = (): LibrarianForecastCastingsClient =>
  createPromiseClient(LibrarianForecastings, inject(GRPC_TRANSPORT));

const token = new InjectionToken('LibrarianForecastings', {
  factory,
});

export function injectLibrarianForecastCastingsClient(): LibrarianForecastCastingsClient {
  return inject(token);
}

import {
  Attribute,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { Focusable } from '../focus.directive';
import {
  focusElement,
  HasDisabled,
  HasTabIndex,
  isEnterKey,
  isSpaceKey,
} from '../utils';

/// ButtonDirective adds all basic required a11y functional for any element,
/// that are designed to work as a button (clickable icon, etc.)
///
/// This does NOT consume the triggering mouse event or keyboard event, so that
/// if a transitive parent wants to listen to all events in it's scope (ex.
/// click on anything to hide popup), it can do so.
///
/// Gives a common event `trigger` for keyboard, and click events.
@Directive({
  selector: '[buttonDecorator]',
  standalone: true,
})
export class ButtonDirective
  implements Focusable, HasTabIndex, HasDisabled, OnDestroy
{
  private element: ElementRef;

  constructor(
    element: ElementRef,
    @Attribute('role') role?: string,
    @Attribute('tabindex') tabIndex?: string,
  ) {
    this.element = element;
    this.role = role ?? 'button';
    this.hostTabIndex = tabIndex ?? '0';
  }

  @Input()
  hostTabIndex = '0';

  @HostBinding('attr.tabindex')
  get tabIndex(): string {
    if (this.disabled || this.tabbable === false) {
      return '-1';
    }
    return this.hostTabIndex;
  }

  @Input()
  shouldHandleSpaceKey = true;

  @HostBinding('class.is-disabled')
  @Input()
  disabled = false;

  /// Role of this component used for a11y.
  @Input()
  @HostBinding('attr.role')
  role = 'button';

  /// Is the component tabbable.
  @Input()
  tabbable = true;

  /// Fired when the button is activated via click, tap, or key press.
  @Output()
  readonly trigger$ = new EventEmitter<UIEvent>();

  focus(): void {
    focusElement(this.element.nativeElement);
  }

  /// String value to be passed to aria-disabled.
  @HostBinding('attr.aria-disabled')
  get disabledStr(): string {
    return this.disabled.toString();
  }

  /// Triggers if not disabled.
  @HostListener('click', ['$event'])
  handleClick(mouseEvent: MouseEvent): void {
    if (this.disabled) {
      mouseEvent.preventDefault();
      mouseEvent.stopPropagation();
      return;
    }
    this.trigger$.emit(mouseEvent);
  }

  /// Triggers on enter and space if not disabled.
  @HostListener('keypress', ['$event'])
  handleKeyPress(keyboardEvent: KeyboardEvent): void {
    if (this.disabled) {
      return;
    }
    if (isSpaceKey(keyboardEvent.key) && !this.shouldHandleSpaceKey) {
      return;
    }
    if (isEnterKey(keyboardEvent.key) || isSpaceKey(keyboardEvent.key)) {
      this.trigger$.emit(keyboardEvent);
      // Required to prevent window from scrolling.
      keyboardEvent.preventDefault();
    }
  }

  ngOnDestroy(): void {
    this.trigger$.complete();
  }
}

import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianUserBrands } from '@frontend2/proto/librarian/proto/user_brand_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianUserBrandsClient = PromiseClient<
  typeof LibrarianUserBrands
>;

export const LibrarianUserBrandsClientProvider = new InjectionToken(
  'LibrarianUserBrandsClient',
  {
    factory: (): LibrarianUserBrandsClient =>
      createPromiseClient(LibrarianUserBrands, inject(GRPC_TRANSPORT)),
  },
);

export function injectLibrarianUserBrandsClient(): LibrarianUserBrandsClient {
  return inject(LibrarianUserBrandsClientProvider);
}

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/payments.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, Int64Value, MethodKind, StringValue } from "@bufbuild/protobuf";
import { AddOrEditNoteRequest, DeleteNoteRequest, FeesRequest, FeesResponse, InvoiceDataRequest, InvoiceDataResponse, Invoices, PaidAmountRequest, PaidAmountResponse, PaidInfluencersResponse, PayingUsersResponse, PaymentMethodsRequest, PaymentMethodsResponse, PaymentRequest, PaymentSourcesResponse, PaymentsRequest, PaymentStatusesResponse, PaymentStatusResponse, SupportedCurrencies } from "./payments_pb";

/**
 * pay for stuff, subscribe, etc ...
 *
 * @generated from service librarian.proto.LibrarianPayments
 */
export const LibrarianPayments = {
  typeName: "librarian.proto.LibrarianPayments",
  methods: {
    /**
     * List invoices for the logged in user.
     *
     * @generated from rpc librarian.proto.LibrarianPayments.ListInvoicesAPI
     */
    listInvoicesAPI: {
      name: "ListInvoicesAPI",
      I: Empty,
      O: Invoices,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPayments.GetInvoiceData
     */
    getInvoiceData: {
      name: "GetInvoiceData",
      I: InvoiceDataRequest,
      O: InvoiceDataResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPayments.ListSupportedCurrencies
     */
    listSupportedCurrencies: {
      name: "ListSupportedCurrencies",
      I: Empty,
      O: SupportedCurrencies,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPayments.GetStripeKey
     */
    getStripeKey: {
      name: "GetStripeKey",
      I: Empty,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Endpoint that returns a Stripe Client Secret as described on
     * https://stripe.com/docs/payments/save-and-reuse?platform=web#web-collect-payment-details
     * so front-end can set-up the payment methods with Stripe.
     *
     * @generated from rpc librarian.proto.LibrarianPayments.GetSetupPaymentIntent
     */
    getSetupPaymentIntent: {
      name: "GetSetupPaymentIntent",
      I: Empty,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPayments.GetPaymentMethods
     */
    getPaymentMethods: {
      name: "GetPaymentMethods",
      I: PaymentMethodsRequest,
      O: PaymentMethodsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * to be removed
     *
     * @generated from rpc librarian.proto.LibrarianPayments.ListPaymentMethodsAPI
     * @deprecated
     */
    listPaymentMethodsAPI: {
      name: "ListPaymentMethodsAPI",
      I: Empty,
      O: PaymentMethodsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPayments.DeletePaymentMethodAPI
     */
    deletePaymentMethodAPI: {
      name: "DeletePaymentMethodAPI",
      I: StringValue,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * This endpoint returns the client secret for the front-end to do the payment confirmation.
     *
     * @generated from rpc librarian.proto.LibrarianPayments.PayAuthorAPI
     */
    payAuthorAPI: {
      name: "PayAuthorAPI",
      I: PaymentRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPayments.GetPayments
     */
    getPayments: {
      name: "GetPayments",
      I: PaymentsRequest,
      O: PaymentStatusesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPayments.GetPaymentDetails
     */
    getPaymentDetails: {
      name: "GetPaymentDetails",
      I: Int64Value,
      O: PaymentStatusResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPayments.GetPaidAmount
     */
    getPaidAmount: {
      name: "GetPaidAmount",
      I: PaidAmountRequest,
      O: PaidAmountResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPayments.GetPaidInfluencers
     */
    getPaidInfluencers: {
      name: "GetPaidInfluencers",
      I: Empty,
      O: PaidInfluencersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPayments.GetPayingUsers
     */
    getPayingUsers: {
      name: "GetPayingUsers",
      I: Empty,
      O: PayingUsersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPayments.GetPaymentSources
     */
    getPaymentSources: {
      name: "GetPaymentSources",
      I: Empty,
      O: PaymentSourcesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPayments.GetFees
     */
    getFees: {
      name: "GetFees",
      I: FeesRequest,
      O: FeesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPayments.AddNote
     */
    addNote: {
      name: "AddNote",
      I: AddOrEditNoteRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPayments.EditNote
     */
    editNote: {
      name: "EditNote",
      I: AddOrEditNoteRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPayments.DeleteNote
     */
    deleteNote: {
      name: "DeleteNote",
      I: DeleteNoteRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;


import { InjectionToken, ValueProvider, inject } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import {
  CampaignKeyword,
  CampaignKeywordType,
  CampaignTrackingSettingsByKeywordTypePb,
  CampaignTrackingSettingsPb,
} from '@frontend2/proto/common/proto/campaign_pb';
import {
  LogicOperator,
  Network,
} from '@frontend2/proto/common/proto/common_pb';

import { isNil } from '@frontend2/core';
import {
  campaignKeywordTypeIsHashtag,
  campaignKeywordTypeIsKeyword,
  campaignKeywordTypeIsLocation,
  campaignKeywordTypeIsMention,
  getNetworkFromCampaignKeyword,
} from '@frontend2/proto-helpers/common/common_pb.helpers';
import { readableSocialNetworkLocation } from '@frontend2/proto-helpers/universal/retriever_pb.helpers';
import { SocialNetworkLocation } from '@frontend2/proto/universal/retriever/proto/retriever_pb';
import * as TKMessages from './config.messages';
import {
  CampaignNetworkTrackingForm,
  FormField,
  TrackingKeywordsConfig,
} from './config.models';

export type StringOrLocation = string | SocialNetworkLocation;

export const KEYWORDS_DEFAULT_LOGIC_OPERATOR = LogicOperator.AND;

export function keywordPrefix(type: CampaignKeywordType): string {
  if (campaignKeywordTypeIsHashtag(type)) {
    return '#';
  }

  if (campaignKeywordTypeIsMention(type)) {
    return '@';
  }

  return '';
}

export function createTrackingKeywordsConfig(
  partial?: Partial<TrackingKeywordsConfig>,
): TrackingKeywordsConfig {
  const conf: TrackingKeywordsConfig = {
    types: partial?.types ?? [],
    errorMessage: partial?.errorMessage ?? '',
    formHelpers:
      partial?.formHelpers ?? new Map<CampaignKeywordType, FormField>(),
    delimiters: partial?.delimiters ?? new Map<CampaignKeywordType, string>(),
  };
  return conf;
}

export function createCampaignNetworkTrackingForm(
  partial?: Partial<CampaignNetworkTrackingForm>,
): CampaignNetworkTrackingForm {
  const form: CampaignNetworkTrackingForm = {
    type: 'CampaignNetworkTrackingForm',
    network: partial?.network ?? Network.NETWORK_UNKNOWN,
    keywords: partial?.keywords ?? new Map(),
    excludedKeywords: partial?.excludedKeywords ?? new Map(),
    keywordLogicOperator: partial?.keywordLogicOperator ?? new Map(),
    logicOperator: partial?.logicOperator ?? KEYWORDS_DEFAULT_LOGIC_OPERATOR,
  };
  return form;
}

function getDelimitersByCampaignKeywordType(type: CampaignKeywordType): string {
  return campaignKeywordTypeIsKeyword(type) ? '#@' : ',#@ ';
}

export function getDelimitersFromConfig(
  config: TrackingKeywordsConfig,
  type: CampaignKeywordType,
): string {
  return (
    config.delimiters.get(type) ?? getDelimitersByCampaignKeywordType(type)
  );
}

export function getCampaignNetworkTrackingFormFromTrackingSettings(
  settings: CampaignTrackingSettingsPb[],
  excludedKeywords: CampaignKeyword[],
): CampaignNetworkTrackingForm[] {
  return settings.map((setting) => {
    const excludedKeywordsMap = new Map<
      CampaignKeywordType,
      StringOrLocation[]
    >();
    excludedKeywords.forEach((keyword) => {
      const network = getNetworkFromCampaignKeyword(keyword.keywordType);
      if (network === setting.network) {
        const values = excludedKeywordsMap.get(keyword.keywordType) ?? [];
        values.push(_toStringOrLocation(keyword));
        excludedKeywordsMap.set(keyword.keywordType, values);
      }
    });

    const keywordsMap = new Map<CampaignKeywordType, StringOrLocation[]>();

    const keywordLogicOperatorMap = new Map<
      CampaignKeywordType,
      LogicOperator
    >();

    setting.settingsByKeywordTypes.forEach((keywordTypeSetting) => {
      keywordsMap.set(
        keywordTypeSetting.keywordType,
        keywordTypeSetting.keywords.map(_toStringOrLocation),
      );

      keywordLogicOperatorMap.set(
        keywordTypeSetting.keywordType,
        keywordTypeSetting.logicOperator,
      );
    });

    return createCampaignNetworkTrackingForm({
      network: setting.network,
      keywords: keywordsMap,
      keywordLogicOperator: keywordLogicOperatorMap,
      logicOperator: setting.logicOperator,
      excludedKeywords: excludedKeywordsMap,
    });
  });
}

export function getTrackingSettingsFromCampaignNetworkTrackingForm(
  forms: CampaignNetworkTrackingForm[],
): CampaignTrackingSettingsPb[] {
  return forms.map((form) => {
    const settingsByKeywordTypes: CampaignTrackingSettingsByKeywordTypePb[] =
      [];

    form.keywords.forEach((keywordsArray, keywordType) => {
      const keywords = keywordsArray.map((keyword) =>
        _toCampaignKeyword(keywordType, keyword),
      );

      const keywordTypeSetting = new CampaignTrackingSettingsByKeywordTypePb({
        keywordType: keywordType,
        logicOperator:
          form.keywordLogicOperator.get(keywordType) ||
          LogicOperator.UNKNOWN_OPERATOR,
        keywords: keywords,
      });

      settingsByKeywordTypes.push(keywordTypeSetting);
    });

    return new CampaignTrackingSettingsPb({
      network: form.network,
      logicOperator: form.logicOperator,
      settingsByKeywordTypes: settingsByKeywordTypes,
    });
  });
}

function createFormField(partial?: Partial<FormField>): FormField {
  const field: FormField = {
    title: partial?.title ?? '',
    body: partial?.body ?? '',
    required: partial?.required ?? false,
    name: partial?.name ?? '',
    placeholder: partial?.placeholder ?? '',
  };
  return field;
}

function _toLocationKeyword(keyword: CampaignKeyword): SocialNetworkLocation {
  return new SocialNetworkLocation({
    id: keyword.value,
    name: keyword.displayName,
  });
}

function _toCampaignKeyword(
  type: CampaignKeywordType,
  keyword: string | SocialNetworkLocation,
): CampaignKeyword {
  if (keyword instanceof SocialNetworkLocation) {
    return new CampaignKeyword({
      value: keyword.id,
      keywordType: type,
      displayName: readableSocialNetworkLocation(keyword),
    });
  }
  return new CampaignKeyword({ value: keyword, keywordType: type });
}

function _toStringOrLocation(keyword: CampaignKeyword): StringOrLocation {
  if (campaignKeywordTypeIsLocation(keyword.keywordType)) {
    return _toLocationKeyword(keyword);
  }
  return keyword.value;
}

export function buildKeywordsList(
  keywords: Map<CampaignKeywordType, StringOrLocation[]>,
): CampaignKeyword[] {
  return Array.from(keywords, ([type, values]) =>
    values.map((keyword) => {
      return _toCampaignKeyword(type, keyword);
    }),
  ).flat();
}

export function buildKeywordsMap(
  keywordsList: CampaignKeyword[],
): Map<CampaignKeywordType, StringOrLocation[]> {
  const keywordsMap = new Map<CampaignKeywordType, StringOrLocation[]>();

  for (const keyword of keywordsList) {
    const { keywordType } = keyword;
    if (!keywordsMap.has(keywordType)) {
      keywordsMap.set(keywordType, []);
    }

    keywordsMap.get(keywordType)?.push(_toStringOrLocation(keyword));
  }
  return keywordsMap;
}

export function isCampaignNetworkTrackingForm(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: any,
): object is CampaignNetworkTrackingForm {
  return object?.type === 'CampaignNetworkTrackingForm';
}

export const hashtagsField: FormField = createFormField({
  name: TKMessages.hashtagsFieldName,
  title: TKMessages.hashtagsFieldHelperTitle,
  body: TKMessages.hashtagsFieldHelperBody,
  placeholder: 'e.g. beauty, fitness',
});

export const mentionsField: FormField = createFormField({
  name: TKMessages.mentionsFieldName,
  title: TKMessages.mentionsFieldHelperTitle,
  body: TKMessages.mentionsFieldHelperBody,
  placeholder: 'e.g. guerlain, chanel',
});

export const instaKeywordsField: FormField = createFormField({
  name: TKMessages.keywordsFieldName,
  title: TKMessages.instaKeywordsFieldHelperTitle,
  body: TKMessages.keywordsFieldHelperBody,
  placeholder: 'e.g. beauty, fitness',
});

export const locationsField: FormField = createFormField({
  name: $localize`Locations`,
  placeholder: 'e.g. Hotel George V, United States, New York',
});

export const youtubeKeywordsField: FormField = createFormField({
  name: TKMessages.keywordsFieldName,
  title: TKMessages.youtubeKeywordsFieldHelperTitle,
  body: TKMessages.youtubeKeywordsFieldHelperBody,
  placeholder: 'e.g. beauty, fitness',
});

export const youtubeTagsField: FormField = createFormField({
  name: TKMessages.hashtagsFieldName,
  title: TKMessages.youtubeTagsFieldHelperTitle,
  body: TKMessages.youtubeTagsFieldHelperBody,
  placeholder: 'e.g. #beauty, #fitness',
});

export const twitterTagsField: FormField = createFormField({
  name: TKMessages.hashtagsFieldName,
  title: TKMessages.twitterTagsFieldHelperTitle,
  body: TKMessages.twitterTagsFieldHelperBody,
  placeholder: 'e.g. beauty, fitness',
});

export const twitterMentionsField: FormField = createFormField({
  name: TKMessages.mentionsFieldName,
  title: TKMessages.twitterMentionsFieldHelperTitle,
  body: TKMessages.twitterMentionsFieldHelperBody,
  placeholder: 'e.g. guerlain, chanel',
});

export const tiktokTagsField: FormField = createFormField({
  name: TKMessages.hashtagsFieldName,
  title: TKMessages.tiktokTagsFieldHelperTitle,
  body: TKMessages.tiktokTagsFieldHelperBody,
  placeholder: 'e.g. beauty, fitness',
});

export const tiktokMentionsField: FormField = createFormField({
  name: TKMessages.mentionsFieldName,
  title: TKMessages.tiktokMentionsFieldHelperTitle,
  body: TKMessages.tiktokMentionsFieldHelperBody,
  placeholder: 'e.g. guerlain, chanel',
});

export const tiktokKeywordsField: FormField = createFormField({
  name: TKMessages.keywordsFieldName,
  title: TKMessages.tiktokKeywordsFieldHelperTitle,
  body: TKMessages.keywordsFieldHelperBody,
  placeholder: 'e.g. beauty, fitness',
});

export const facebookTagsField: FormField = createFormField({
  name: TKMessages.hashtagsFieldName,
  title: TKMessages.facebookTagsFieldHelperTitle,
  body: TKMessages.hashtagsFieldHelperBody,
  placeholder: 'e.g. beauty, fitness',
});

export const facebookMentionsField: FormField = createFormField({
  name: TKMessages.mentionsFieldName,
  title: TKMessages.facebookMentionsFieldHelperTitle,
  body: TKMessages.mentionsFieldHelperBody,
  placeholder: 'e.g. guerlain, chanel',
});

export const weiboTagsField: FormField = createFormField({
  name: TKMessages.hashtagsFieldName,
  title: TKMessages.weiboTagsFieldHelperTitle,
  body: TKMessages.hashtagsFieldHelperBody,
  placeholder: 'e.g. beauty, fitness',
});

export const weiboKeywordsField: FormField = createFormField({
  name: TKMessages.keywordsFieldName,
  title: TKMessages.weiboKeywordsFieldHelperTitle,
  body: TKMessages.keywordsFieldHelperBody,
  placeholder: 'e.g. beauty, fitness',
});

export const weiboMentionsField: FormField = createFormField({
  name: TKMessages.mentionsFieldName,
  title: TKMessages.weiboMentionsFieldHelperTitle,
  body: TKMessages.mentionsFieldHelperBody,
  placeholder: 'e.g. guerlain, chanel',
});

export const redTagsField: FormField = createFormField({
  name: TKMessages.hashtagsFieldName,
  title: TKMessages.redTagsFieldHelperTitle,
  body: TKMessages.redTagsFieldHelperBody,
  placeholder: 'e.g. #beauty, #fitness',
});

export const redKeywordsField: FormField = createFormField({
  name: TKMessages.keywordsFieldName,
  title: TKMessages.redKeywordsFieldHelperTitle,
  body: TKMessages.redKeywordsFieldHelperBody,
  placeholder: 'e.g. beauty, fitness, guerlain, chanel',
});

export const wechatTagsField: FormField = createFormField({
  name: TKMessages.keywordsFieldName,
  title: TKMessages.wechatTagsFieldHelperTitle,
  body: TKMessages.wechatTagsFieldHelperBody,
  placeholder: 'e.g. beauty, fitness, guerlain, chanel',
});

export const douyinTagsField: FormField = createFormField({
  name: TKMessages.keywordsFieldName,
  title: TKMessages.douyinTagsFieldHelperTitle,
  body: TKMessages.douyinTagsFieldHelperBody,
  placeholder: 'e.g. beauty, fitness, guerlain, chanel',
});

export const snapshatTagsField: FormField = createFormField({
  name: TKMessages.hashtagsFieldName,
  title: TKMessages.snapHashtagsFieldHelperTitle,
  body: TKMessages.hashtagsFieldHelperBody,
  placeholder: 'e.g. beauty, fitness',
});

export const snapshatMentionsField: FormField = createFormField({
  name: TKMessages.mentionsFieldName,
  title: TKMessages.snapMentionsFieldHelperTitle,
  body: TKMessages.mentionsFieldHelperBody,
  placeholder: 'e.g. guerlain, chanel',
});

export const snapshatKeywordsField: FormField = createFormField({
  name: TKMessages.keywordsFieldName,
  title: TKMessages.snapKeywordsFieldHelperTitle,
  body: TKMessages.keywordsFieldHelperBody,
  placeholder: 'e.g. beauty, fitness',
});

export function isCampaignNetworkTrackingFormValid(
  form: CampaignNetworkTrackingForm,
): boolean {
  if (isNil(form.keywords) || form.keywords.size === 0) {
    return false;
  }

  const keywordsArray = Array.from(form.keywords.values());
  if (keywordsArray.every((words) => words.length === 0)) {
    return false;
  }

  return true;
}

export function networkTrackingFormRequiredValidator(
  c: AbstractControl,
): ValidationErrors | null {
  if (c.value === null) {
    return null;
  }

  const value = c.value as CampaignNetworkTrackingForm;

  if (isCampaignNetworkTrackingFormValid(value) === false) {
    return { required: value.network };
  }

  return null;
}

export function validateNetworkTrackingForms(
  c: AbstractControl,
): ValidationErrors | null {
  if (isNil(c.value)) {
    return null;
  }

  const networkTrackingForms = c.value as CampaignNetworkTrackingForm[];

  if (!networkTrackingForms || networkTrackingForms.length === 0) {
    return { required: true };
  }

  const isValidFormPresent = networkTrackingForms.some((form) =>
    isCampaignNetworkTrackingFormValid(form),
  );

  if (!isValidFormPresent) {
    return { required: true };
  }

  return null;
}

const READ_ONLY_APP_TOKEN = new InjectionToken<boolean>('READ_ONLY_APP');

export function injectIsReadOnlyApp(): boolean {
  return inject(READ_ONLY_APP_TOKEN, { optional: true }) ?? false;
}

export function provideIsReadOnlyApp(isReadOnly: boolean): ValueProvider {
  return {
    provide: READ_ONLY_APP_TOKEN,
    useValue: isReadOnly,
  };
}

export function readableCampaignKeywordType(type: CampaignKeywordType): string {
  if (campaignKeywordTypeIsLocation(type)) {
    return $localize`Location`;
  }

  if (campaignKeywordTypeIsMention(type)) {
    return $localize`Mention`;
  }

  if (campaignKeywordTypeIsHashtag(type)) {
    return $localize`Hashtag`;
  }

  return $localize`Keyword`;
}

import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianProfileMergeRequests } from '@frontend2/proto/librarian/proto/profile_merge_requests_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianProfileMergeRequestsClient = PromiseClient<
  typeof LibrarianProfileMergeRequests
>;

export const LibrarianProfileMergeRequestsClientProvider = new InjectionToken(
  'LibrarianProfileMergeRequestsClient',
  {
    factory: (): LibrarianProfileMergeRequestsClient =>
      createPromiseClient(
        LibrarianProfileMergeRequests,
        inject(GRPC_TRANSPORT),
      ),
  },
);

export function injectLibrarianProfileMergeRequestsClient(): LibrarianProfileMergeRequestsClient {
  return inject(LibrarianProfileMergeRequestsClientProvider);
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  model,
  NgZone,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  Chip,
  createChip,
  isEmptyString,
  isNotEmptyString,
} from '@frontend2/core';
import { LogicOperator } from '@frontend2/proto/common/proto/common_pb';
import { Subject } from 'rxjs';
import { LeftyFormValueBase } from '../form';
import { isBackspaceKey, isEnterKey, isEscapeKey } from '../utils';
import { LeftyChipComponent } from './lefty-chip.component';
import { LeftyOperatorChipComponent } from './lefty-operator-chip/lefty-operator-chip.component';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-chips-editor',
  templateUrl: 'lefty-chips-editor.component.html',
  styleUrls: ['input-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormComponent, LeftyOperatorChipComponent, LeftyChipComponent],
})
export class LeftyChipsEditorComponent extends LeftyFormValueBase<string[]> {
  constructor(
    readonly zone: NgZone,
    @Self() @Optional() ngControl?: NgControl,
  ) {
    super([], ngControl);

    this.disposer.add(this.inputValueChange);
    this.disposer.add(this.keypress$);
    this.disposer.add(this.keydown$);
    this.disposer.add(this.keyup$);

    this.watch(this.keydown$, {
      next: (event) => this._onKeyDown(event),
    });
  }

  chips: Chip<string>[] = [];

  @Output()
  readonly inputValueChange = new Subject<string>();

  @Output()
  readonly keypress$ = new Subject<KeyboardEvent>();

  @Output()
  readonly keyup$ = new Subject<KeyboardEvent>();

  @Output()
  readonly keydown$ = new Subject<KeyboardEvent>();

  @Input()
  placeholder = '';

  @Input()
  tabIndex?: number;

  @Input()
  inputValue = '';

  @Input()
  // if we accept duplicate value or not
  uniqueValues = true;

  @Input()
  canCreateChip = true;

  @Input()
  canRemoveChip = true;

  @Input()
  delimiters = '';

  @Input()
  withLogicOperator = false;

  readonly logicOperatorValue = model(LogicOperator.AND);

  private _prefix = '';

  @Input()
  set prefix(prefix: string) {
    this._prefix = prefix;
    this.setState(() => {
      this.chips = this._buildChips(this.value, this._prefix);
    });
  }

  override get value(): string[] {
    return super.value;
  }

  override set value(val: string[]) {
    if (this.uniqueValues) {
      super.value = [...new Set(val)];
    } else {
      super.value = val;
    }

    this.setState(() => {
      this.chips = this._buildChips(this.value, this._prefix);
    });
  }

  override writeValue(obj: unknown): void {
    // no efficient way to test if array contains string
    if (Array.isArray(obj)) {
      this.value = obj;
    } else {
      this.value = [];
      console.warn(
        `Failed to bind type ${typeof obj} on form control ${
          this.ngControl?.name
        }`,
      );
    }
  }

  private _buildChips(values: string[], prefix: string): Chip<string>[] {
    return values.map((val) =>
      createChip(val, {
        formattedValue: `${prefix}${val}`,
      }),
    );
  }

  @HostBinding('class.disabled')
  get isDisabled(): boolean {
    return this.disabled;
  }

  @HostBinding('class.has-value')
  get hasValue(): boolean {
    return this.value.length > 0;
  }

  private _createChips(inputValue: string): void {
    if (isNotEmptyString(inputValue)) {
      // handle copy/paste case
      const newValues = inputValue
        .trim()
        .split(RegExp(`[${this.delimiters}]`))
        .map((v) => v.trim())
        .filter((v) => isNotEmptyString(v));

      let newList = [...this.value, ...newValues];

      if (this.uniqueValues) {
        newList = [...new Set(newList)];
      }

      this.handleValueChange(newList);
    }
  }

  deleteChip(chip: Chip<string>): void {
    const newList = [...this.value];
    newList.splice(newList.indexOf(chip.value), 1);

    this.handleValueChange(newList);
  }

  private _deleteLastChip(): void {
    if (this.hasValue === false) {
      return;
    }

    const newList = [...this.value];
    newList.splice(newList.length - 1, 1);

    this.handleValueChange(newList);
  }

  private _onKeyDown(event: KeyboardEvent): void {
    const onEscape = isEscapeKey(event.code);
    const onBackspace = isBackspaceKey(event.code);
    const onEnter = isEnterKey(event.code);
    const input = event.target as HTMLInputElement;
    const valueEmpty = isEmptyString(input.value.trim());

    if (onEscape) {
      this.setState(() => (this.inputValue = ''));
      input.blur();
    } else if (onBackspace && valueEmpty && this.chips.length > 0) {
      this._deleteLastChip();
    } else if (onEnter || this.delimiters.includes(event.key)) {
      if (valueEmpty === false) {
        event.preventDefault();
        event.stopPropagation();

        if (this.canCreateChip) {
          this._createChips(input.value);
          this.setState(() => (this.inputValue = ''));
        }
      }
    }

    this.changeDetection.markForCheck();
  }

  override handleBlur(event?: FocusEvent | undefined): void {
    super.handleBlur(event);

    if (this.canCreateChip) {
      this._createChips(this.inputValue);
      this.setState(() => (this.inputValue = ''));
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackBy(index: number, _: unknown): number {
    return index;
  }

  handleInputChange(val: string): void {
    this.inputValueChange.next(val);
    this.setState(() => (this.inputValue = val));
  }
}

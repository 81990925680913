// This file defines proto for labels.

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/entity_labels.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { BaseEntityTypeReq } from "./common_pb";
import { EntityLabel, EntityLabels, SetEntityLabelsRequest, UpdateEntityLabelsRequest } from "./entity_labels_pb";
import { Empty, Int64Value, MethodKind, StringValue } from "@bufbuild/protobuf";

/**
 * @generated from service librarian.proto.LibrarianEntityLabels
 */
export const LibrarianEntityLabels = {
  typeName: "librarian.proto.LibrarianEntityLabels",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianEntityLabels.GetEntityLabelsAPI
     */
    getEntityLabelsAPI: {
      name: "GetEntityLabelsAPI",
      I: BaseEntityTypeReq,
      O: EntityLabels,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEntityLabels.AddEntityLabelAPI
     */
    addEntityLabelAPI: {
      name: "AddEntityLabelAPI",
      I: EntityLabel,
      O: Int64Value,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEntityLabels.EditEntityLabelAPI
     */
    editEntityLabelAPI: {
      name: "EditEntityLabelAPI",
      I: EntityLabel,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEntityLabels.ArchiveEntityLabelAPI
     */
    archiveEntityLabelAPI: {
      name: "ArchiveEntityLabelAPI",
      I: EntityLabel,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEntityLabels.UnarchiveEntityLabelAPI
     */
    unarchiveEntityLabelAPI: {
      name: "UnarchiveEntityLabelAPI",
      I: EntityLabel,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEntityLabels.SetEntityLabelsAPI
     */
    setEntityLabelsAPI: {
      name: "SetEntityLabelsAPI",
      I: SetEntityLabelsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * returns a progress token if the request was batched, empty string if it's all done inline.
     *
     * @generated from rpc librarian.proto.LibrarianEntityLabels.UpdateEntityLabelsAPI
     */
    updateEntityLabelsAPI: {
      name: "UpdateEntityLabelsAPI",
      I: UpdateEntityLabelsRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
  }
} as const;


import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianCompetitiveWatch } from '@frontend2/proto/librarian/proto/competitive_watch_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianCompetitiveWatchClient = PromiseClient<
  typeof LibrarianCompetitiveWatch
>;

const factory = (): LibrarianCompetitiveWatchClient => {
  return createPromiseClient(LibrarianCompetitiveWatch, inject(GRPC_TRANSPORT));
};

const token = new InjectionToken('LibrarianCompetitiveWatchClient', {
  factory,
});

export function injectLibrarianCompetitiveWatchClient(): LibrarianCompetitiveWatchClient {
  return inject(token);
}

//
//This file defines the registered_author service.
//It is consumed by the hugo/influencers ruby application.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/registered_author.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, StringValue } from "@bufbuild/protobuf";
import { GenerikInfoCard } from "./creators_pb";
import { Address, PaymentMethodPb, StripeRegion } from "./payments_pb";
import { Campaign } from "./campaigns_pb";
import { InfluencerStatus, UserStatus } from "../../common/proto/campaign_pb";
import { AuthorPermission, BasicPagination, Network } from "../../common/proto/common_pb";
import { LandingPagePb } from "./landing_pages_pb";
import { RegisteredAuthorInfluencerRequestPb } from "./registered_author_product_requests_pb";
import { FacebookPermission } from "../../common/proto/instagram_pb";
import { ProductWithInfoPb } from "./products_pb";

/**
 * @generated from enum librarian.proto.StripeStatus
 */
export enum StripeStatus {
  /**
   * @generated from enum value: NO_STRIPE_ACCOUNT = 0;
   */
  NO_STRIPE_ACCOUNT = 0,

  /**
   * @generated from enum value: NO_KYC_STRIPE_ACCOUNT = 1;
   */
  NO_KYC_STRIPE_ACCOUNT = 1,

  /**
   * @generated from enum value: VALID_STRIPE_ACCOUNT = 2;
   */
  VALID_STRIPE_ACCOUNT = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(StripeStatus)
proto3.util.setEnumType(StripeStatus, "librarian.proto.StripeStatus", [
  { no: 0, name: "NO_STRIPE_ACCOUNT" },
  { no: 1, name: "NO_KYC_STRIPE_ACCOUNT" },
  { no: 2, name: "VALID_STRIPE_ACCOUNT" },
]);

/**
 * @generated from message librarian.proto.LoggedAuthorV2
 */
export class LoggedAuthorV2 extends Message<LoggedAuthorV2> {
  /**
   * @generated from field: bool logged = 1;
   */
  logged = false;

  /**
   * @generated from field: int64 author_id = 2;
   */
  authorId = protoInt64.zero;

  /**
   * @generated from field: string email = 6;
   */
  email = "";

  /**
   * @generated from field: repeated librarian.proto.LoggedAuthorV2.AuthorNetworkInfo authors = 3;
   */
  authors: LoggedAuthorV2_AuthorNetworkInfo[] = [];

  /**
   * @generated from field: bool has_valid_token = 4;
   */
  hasValidToken = false;

  /**
   * @generated from field: string firebase_token = 5;
   */
  firebaseToken = "";

  /**
   * @generated from field: librarian.proto.LoggedAuthorV2.ChatInfo chat_info = 7;
   */
  chatInfo?: LoggedAuthorV2_ChatInfo;

  /**
   * @generated from field: librarian.proto.StripeStatus stripe_status = 8 [deprecated = true];
   * @deprecated
   */
  stripeStatus = StripeStatus.NO_STRIPE_ACCOUNT;

  /**
   * @generated from field: repeated librarian.proto.LoggedAuthorV2.StripeRegionStatus stripe_region_statuses = 9;
   */
  stripeRegionStatuses: LoggedAuthorV2_StripeRegionStatus[] = [];

  constructor(data?: PartialMessage<LoggedAuthorV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LoggedAuthorV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "logged", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "author_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "authors", kind: "message", T: LoggedAuthorV2_AuthorNetworkInfo, repeated: true },
    { no: 4, name: "has_valid_token", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "firebase_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "chat_info", kind: "message", T: LoggedAuthorV2_ChatInfo },
    { no: 8, name: "stripe_status", kind: "enum", T: proto3.getEnumType(StripeStatus) },
    { no: 9, name: "stripe_region_statuses", kind: "message", T: LoggedAuthorV2_StripeRegionStatus, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoggedAuthorV2 {
    return new LoggedAuthorV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoggedAuthorV2 {
    return new LoggedAuthorV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoggedAuthorV2 {
    return new LoggedAuthorV2().fromJsonString(jsonString, options);
  }

  static equals(a: LoggedAuthorV2 | PlainMessage<LoggedAuthorV2> | undefined, b: LoggedAuthorV2 | PlainMessage<LoggedAuthorV2> | undefined): boolean {
    return proto3.util.equals(LoggedAuthorV2, a, b);
  }
}

/**
 * @generated from message librarian.proto.LoggedAuthorV2.AuthorNetworkInfo
 */
export class LoggedAuthorV2_AuthorNetworkInfo extends Message<LoggedAuthorV2_AuthorNetworkInfo> {
  /**
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * @generated from field: string profile_picture = 2;
   */
  profilePicture = "";

  /**
   * @generated from field: librarian.proto.GenerikInfoCard network_info = 3;
   */
  networkInfo?: GenerikInfoCard;

  constructor(data?: PartialMessage<LoggedAuthorV2_AuthorNetworkInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LoggedAuthorV2.AuthorNetworkInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "profile_picture", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "network_info", kind: "message", T: GenerikInfoCard },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoggedAuthorV2_AuthorNetworkInfo {
    return new LoggedAuthorV2_AuthorNetworkInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoggedAuthorV2_AuthorNetworkInfo {
    return new LoggedAuthorV2_AuthorNetworkInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoggedAuthorV2_AuthorNetworkInfo {
    return new LoggedAuthorV2_AuthorNetworkInfo().fromJsonString(jsonString, options);
  }

  static equals(a: LoggedAuthorV2_AuthorNetworkInfo | PlainMessage<LoggedAuthorV2_AuthorNetworkInfo> | undefined, b: LoggedAuthorV2_AuthorNetworkInfo | PlainMessage<LoggedAuthorV2_AuthorNetworkInfo> | undefined): boolean {
    return proto3.util.equals(LoggedAuthorV2_AuthorNetworkInfo, a, b);
  }
}

/**
 * @generated from message librarian.proto.LoggedAuthorV2.ChatInfo
 */
export class LoggedAuthorV2_ChatInfo extends Message<LoggedAuthorV2_ChatInfo> {
  /**
   * @generated from field: string author_chat_id = 1;
   */
  authorChatId = "";

  /**
   * @generated from field: string author_chat_signature = 2;
   */
  authorChatSignature = "";

  /**
   * @generated from field: string app_id = 3;
   */
  appId = "";

  constructor(data?: PartialMessage<LoggedAuthorV2_ChatInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LoggedAuthorV2.ChatInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "author_chat_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "author_chat_signature", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "app_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoggedAuthorV2_ChatInfo {
    return new LoggedAuthorV2_ChatInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoggedAuthorV2_ChatInfo {
    return new LoggedAuthorV2_ChatInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoggedAuthorV2_ChatInfo {
    return new LoggedAuthorV2_ChatInfo().fromJsonString(jsonString, options);
  }

  static equals(a: LoggedAuthorV2_ChatInfo | PlainMessage<LoggedAuthorV2_ChatInfo> | undefined, b: LoggedAuthorV2_ChatInfo | PlainMessage<LoggedAuthorV2_ChatInfo> | undefined): boolean {
    return proto3.util.equals(LoggedAuthorV2_ChatInfo, a, b);
  }
}

/**
 * @generated from message librarian.proto.LoggedAuthorV2.StripeRegionStatus
 */
export class LoggedAuthorV2_StripeRegionStatus extends Message<LoggedAuthorV2_StripeRegionStatus> {
  /**
   * @generated from field: librarian.proto.StripeRegion stripe_region = 1;
   */
  stripeRegion = StripeRegion.REGION_UNKNOWN;

  /**
   * @generated from field: librarian.proto.StripeStatus stripe_status = 2;
   */
  stripeStatus = StripeStatus.NO_STRIPE_ACCOUNT;

  constructor(data?: PartialMessage<LoggedAuthorV2_StripeRegionStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LoggedAuthorV2.StripeRegionStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stripe_region", kind: "enum", T: proto3.getEnumType(StripeRegion) },
    { no: 2, name: "stripe_status", kind: "enum", T: proto3.getEnumType(StripeStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoggedAuthorV2_StripeRegionStatus {
    return new LoggedAuthorV2_StripeRegionStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoggedAuthorV2_StripeRegionStatus {
    return new LoggedAuthorV2_StripeRegionStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoggedAuthorV2_StripeRegionStatus {
    return new LoggedAuthorV2_StripeRegionStatus().fromJsonString(jsonString, options);
  }

  static equals(a: LoggedAuthorV2_StripeRegionStatus | PlainMessage<LoggedAuthorV2_StripeRegionStatus> | undefined, b: LoggedAuthorV2_StripeRegionStatus | PlainMessage<LoggedAuthorV2_StripeRegionStatus> | undefined): boolean {
    return proto3.util.equals(LoggedAuthorV2_StripeRegionStatus, a, b);
  }
}

/**
 * @generated from message librarian.proto.CampaignLanding
 */
export class CampaignLanding extends Message<CampaignLanding> {
  /**
   * @generated from field: librarian.proto.Campaign campaign = 1;
   */
  campaign?: Campaign;

  /**
   * these are set only if the author is logged
   *
   * @generated from field: common.proto.UserStatus user_status = 2;
   */
  userStatus = UserStatus.USER_PENDING;

  /**
   * @generated from field: common.proto.InfluencerStatus influencer_status = 3;
   */
  influencerStatus = InfluencerStatus.INFLUENCER_PENDING;

  /**
   * @generated from field: common.proto.AuthorPermission permission = 4;
   */
  permission = AuthorPermission.PERMISSION_UNDEFINED;

  constructor(data?: PartialMessage<CampaignLanding>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignLanding";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign", kind: "message", T: Campaign },
    { no: 2, name: "user_status", kind: "enum", T: proto3.getEnumType(UserStatus) },
    { no: 3, name: "influencer_status", kind: "enum", T: proto3.getEnumType(InfluencerStatus) },
    { no: 4, name: "permission", kind: "enum", T: proto3.getEnumType(AuthorPermission) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignLanding {
    return new CampaignLanding().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignLanding {
    return new CampaignLanding().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignLanding {
    return new CampaignLanding().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignLanding | PlainMessage<CampaignLanding> | undefined, b: CampaignLanding | PlainMessage<CampaignLanding> | undefined): boolean {
    return proto3.util.equals(CampaignLanding, a, b);
  }
}

/**
 * @generated from message librarian.proto.LandingPageInfo
 */
export class LandingPageInfo extends Message<LandingPageInfo> {
  /**
   * @generated from field: librarian.proto.LandingPagePb page = 1;
   */
  page?: LandingPagePb;

  /**
   * @generated from field: librarian.proto.StripeRegion stripe_region = 2;
   */
  stripeRegion = StripeRegion.REGION_UNKNOWN;

  constructor(data?: PartialMessage<LandingPageInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LandingPageInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page", kind: "message", T: LandingPagePb },
    { no: 2, name: "stripe_region", kind: "enum", T: proto3.getEnumType(StripeRegion) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LandingPageInfo {
    return new LandingPageInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LandingPageInfo {
    return new LandingPageInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LandingPageInfo {
    return new LandingPageInfo().fromJsonString(jsonString, options);
  }

  static equals(a: LandingPageInfo | PlainMessage<LandingPageInfo> | undefined, b: LandingPageInfo | PlainMessage<LandingPageInfo> | undefined): boolean {
    return proto3.util.equals(LandingPageInfo, a, b);
  }
}

/**
 * @generated from message librarian.proto.CollaborationDetailsResponse
 */
export class CollaborationDetailsResponse extends Message<CollaborationDetailsResponse> {
  /**
   * page details contain required data for creator onboarding
   *
   * @generated from field: librarian.proto.LandingPagePb page_details = 1;
   */
  pageDetails?: LandingPagePb;

  /**
   * @generated from field: common.proto.InfluencerStatus influencer_status = 5;
   */
  influencerStatus = InfluencerStatus.INFLUENCER_PENDING;

  /**
   * @generated from field: common.proto.UserStatus user_status = 7;
   */
  userStatus = UserStatus.USER_PENDING;

  /**
   * here we find the required data values if creator filled it
   * TODO: (Yassine) remove when front migration is done
   *
   * @generated from field: bool instagram_insights_status = 2 [deprecated = true];
   * @deprecated
   */
  instagramInsightsStatus = false;

  /**
   * @generated from field: librarian.proto.PaymentMethodPb payment_method = 3 [deprecated = true];
   * @deprecated
   */
  paymentMethod?: PaymentMethodPb;

  /**
   * @generated from field: bool has_instagram_insights = 8;
   */
  hasInstagramInsights = false;

  /**
   * @generated from field: bool has_payment_method = 9;
   */
  hasPaymentMethod = false;

  /**
   * @generated from field: librarian.proto.StripeRegion stripe_region = 11;
   */
  stripeRegion = StripeRegion.REGION_UNKNOWN;

  /**
   * @generated from field: librarian.proto.RegisteredAuthorInfluencerRequestPb influencer_request = 10;
   */
  influencerRequest?: RegisteredAuthorInfluencerRequestPb;

  constructor(data?: PartialMessage<CollaborationDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CollaborationDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "page_details", kind: "message", T: LandingPagePb },
    { no: 5, name: "influencer_status", kind: "enum", T: proto3.getEnumType(InfluencerStatus) },
    { no: 7, name: "user_status", kind: "enum", T: proto3.getEnumType(UserStatus) },
    { no: 2, name: "instagram_insights_status", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "payment_method", kind: "message", T: PaymentMethodPb },
    { no: 8, name: "has_instagram_insights", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "has_payment_method", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "stripe_region", kind: "enum", T: proto3.getEnumType(StripeRegion) },
    { no: 10, name: "influencer_request", kind: "message", T: RegisteredAuthorInfluencerRequestPb },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CollaborationDetailsResponse {
    return new CollaborationDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CollaborationDetailsResponse {
    return new CollaborationDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CollaborationDetailsResponse {
    return new CollaborationDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CollaborationDetailsResponse | PlainMessage<CollaborationDetailsResponse> | undefined, b: CollaborationDetailsResponse | PlainMessage<CollaborationDetailsResponse> | undefined): boolean {
    return proto3.util.equals(CollaborationDetailsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.CollaborationListResponse
 */
export class CollaborationListResponse extends Message<CollaborationListResponse> {
  /**
   * @generated from field: repeated librarian.proto.CollaborationDetailsResponse items = 1;
   */
  items: CollaborationDetailsResponse[] = [];

  constructor(data?: PartialMessage<CollaborationListResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CollaborationListResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: CollaborationDetailsResponse, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CollaborationListResponse {
    return new CollaborationListResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CollaborationListResponse {
    return new CollaborationListResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CollaborationListResponse {
    return new CollaborationListResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CollaborationListResponse | PlainMessage<CollaborationListResponse> | undefined, b: CollaborationListResponse | PlainMessage<CollaborationListResponse> | undefined): boolean {
    return proto3.util.equals(CollaborationListResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.AcceptInsightsRequest
 */
export class AcceptInsightsRequest extends Message<AcceptInsightsRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string encrypted_landing_id = 2;
   */
  encryptedLandingId = "";

  constructor(data?: PartialMessage<AcceptInsightsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AcceptInsightsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "encrypted_landing_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptInsightsRequest {
    return new AcceptInsightsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptInsightsRequest {
    return new AcceptInsightsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcceptInsightsRequest {
    return new AcceptInsightsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AcceptInsightsRequest | PlainMessage<AcceptInsightsRequest> | undefined, b: AcceptInsightsRequest | PlainMessage<AcceptInsightsRequest> | undefined): boolean {
    return proto3.util.equals(AcceptInsightsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.FacebookPermissions
 */
export class FacebookPermissions extends Message<FacebookPermissions> {
  /**
   * @generated from field: repeated common.proto.FacebookPermission values = 1;
   */
  values: FacebookPermission[] = [];

  constructor(data?: PartialMessage<FacebookPermissions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.FacebookPermissions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "values", kind: "enum", T: proto3.getEnumType(FacebookPermission), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookPermissions {
    return new FacebookPermissions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookPermissions {
    return new FacebookPermissions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookPermissions {
    return new FacebookPermissions().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookPermissions | PlainMessage<FacebookPermissions> | undefined, b: FacebookPermissions | PlainMessage<FacebookPermissions> | undefined): boolean {
    return proto3.util.equals(FacebookPermissions, a, b);
  }
}

/**
 * @generated from message librarian.proto.AcceptInsightsResponse
 */
export class AcceptInsightsResponse extends Message<AcceptInsightsResponse> {
  /**
   * @generated from oneof librarian.proto.AcceptInsightsResponse.outcome
   */
  outcome: {
    /**
     * set if the facebook doesn't link any instagram business account.
     *
     * @generated from field: bool no_accounts = 1;
     */
    value: boolean;
    case: "noAccounts";
  } | {
    /**
     * @generated from field: librarian.proto.FacebookPermissions missing_permissions = 2;
     */
    value: FacebookPermissions;
    case: "missingPermissions";
  } | {
    /**
     * set if the insights were accepted for another instagram account than the one linked after registration
     *
     * @generated from field: bool incorrect_account = 3;
     */
    value: boolean;
    case: "incorrectAccount";
  } | {
    /**
     * @generated from field: bool succeeded = 4;
     */
    value: boolean;
    case: "succeeded";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<AcceptInsightsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AcceptInsightsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "no_accounts", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "outcome" },
    { no: 2, name: "missing_permissions", kind: "message", T: FacebookPermissions, oneof: "outcome" },
    { no: 3, name: "incorrect_account", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "outcome" },
    { no: 4, name: "succeeded", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "outcome" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptInsightsResponse {
    return new AcceptInsightsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptInsightsResponse {
    return new AcceptInsightsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcceptInsightsResponse {
    return new AcceptInsightsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AcceptInsightsResponse | PlainMessage<AcceptInsightsResponse> | undefined, b: AcceptInsightsResponse | PlainMessage<AcceptInsightsResponse> | undefined): boolean {
    return proto3.util.equals(AcceptInsightsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.IdentifiedProfile
 */
export class IdentifiedProfile extends Message<IdentifiedProfile> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * @generated from field: string profile_pic = 3;
   */
  profilePic = "";

  constructor(data?: PartialMessage<IdentifiedProfile>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.IdentifiedProfile";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "profile_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IdentifiedProfile {
    return new IdentifiedProfile().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IdentifiedProfile {
    return new IdentifiedProfile().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IdentifiedProfile {
    return new IdentifiedProfile().fromJsonString(jsonString, options);
  }

  static equals(a: IdentifiedProfile | PlainMessage<IdentifiedProfile> | undefined, b: IdentifiedProfile | PlainMessage<IdentifiedProfile> | undefined): boolean {
    return proto3.util.equals(IdentifiedProfile, a, b);
  }
}

/**
 * @generated from message librarian.proto.ResetPasswordInfluencerRequest
 */
export class ResetPasswordInfluencerRequest extends Message<ResetPasswordInfluencerRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  constructor(data?: PartialMessage<ResetPasswordInfluencerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ResetPasswordInfluencerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetPasswordInfluencerRequest {
    return new ResetPasswordInfluencerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetPasswordInfluencerRequest {
    return new ResetPasswordInfluencerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetPasswordInfluencerRequest {
    return new ResetPasswordInfluencerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResetPasswordInfluencerRequest | PlainMessage<ResetPasswordInfluencerRequest> | undefined, b: ResetPasswordInfluencerRequest | PlainMessage<ResetPasswordInfluencerRequest> | undefined): boolean {
    return proto3.util.equals(ResetPasswordInfluencerRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.SignupInfluencerRequest
 */
export class SignupInfluencerRequest extends Message<SignupInfluencerRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  constructor(data?: PartialMessage<SignupInfluencerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SignupInfluencerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SignupInfluencerRequest {
    return new SignupInfluencerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SignupInfluencerRequest {
    return new SignupInfluencerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SignupInfluencerRequest {
    return new SignupInfluencerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SignupInfluencerRequest | PlainMessage<SignupInfluencerRequest> | undefined, b: SignupInfluencerRequest | PlainMessage<SignupInfluencerRequest> | undefined): boolean {
    return proto3.util.equals(SignupInfluencerRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.LoginInfluencerRequest
 */
export class LoginInfluencerRequest extends Message<LoginInfluencerRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  constructor(data?: PartialMessage<LoginInfluencerRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LoginInfluencerRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginInfluencerRequest {
    return new LoginInfluencerRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginInfluencerRequest {
    return new LoginInfluencerRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginInfluencerRequest {
    return new LoginInfluencerRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LoginInfluencerRequest | PlainMessage<LoginInfluencerRequest> | undefined, b: LoginInfluencerRequest | PlainMessage<LoginInfluencerRequest> | undefined): boolean {
    return proto3.util.equals(LoginInfluencerRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CompanyPb
 */
export class CompanyPb extends Message<CompanyPb> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string phone = 2;
   */
  phone = "";

  /**
   * @generated from field: librarian.proto.Address address = 3;
   */
  address?: Address;

  /**
   * Legal and taxes
   *
   * @generated from field: string registration_number = 4;
   */
  registrationNumber = "";

  /**
   * @generated from field: string vat_id = 5;
   */
  vatId = "";

  constructor(data?: PartialMessage<CompanyPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CompanyPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "address", kind: "message", T: Address },
    { no: 4, name: "registration_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "vat_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CompanyPb {
    return new CompanyPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CompanyPb {
    return new CompanyPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CompanyPb {
    return new CompanyPb().fromJsonString(jsonString, options);
  }

  static equals(a: CompanyPb | PlainMessage<CompanyPb> | undefined, b: CompanyPb | PlainMessage<CompanyPb> | undefined): boolean {
    return proto3.util.equals(CompanyPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.IndividualPb
 */
export class IndividualPb extends Message<IndividualPb> {
  /**
   * @generated from field: string first_name = 1;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 2;
   */
  lastName = "";

  /**
   * @generated from field: string phone = 3;
   */
  phone = "";

  /**
   * @generated from field: librarian.proto.Address address = 4;
   */
  address?: Address;

  constructor(data?: PartialMessage<IndividualPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.IndividualPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "address", kind: "message", T: Address },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IndividualPb {
    return new IndividualPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IndividualPb {
    return new IndividualPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IndividualPb {
    return new IndividualPb().fromJsonString(jsonString, options);
  }

  static equals(a: IndividualPb | PlainMessage<IndividualPb> | undefined, b: IndividualPb | PlainMessage<IndividualPb> | undefined): boolean {
    return proto3.util.equals(IndividualPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateAccountRequest
 */
export class CreateAccountRequest extends Message<CreateAccountRequest> {
  /**
   * @generated from field: librarian.proto.StripeRegion stripe_region = 3;
   */
  stripeRegion = StripeRegion.REGION_UNKNOWN;

  /**
   * As of today all fields are optional, we just need to know if it s a business or an individual.
   *
   * @generated from oneof librarian.proto.CreateAccountRequest.business
   */
  business: {
    /**
     * @generated from field: librarian.proto.IndividualPb individual = 1;
     */
    value: IndividualPb;
    case: "individual";
  } | {
    /**
     * @generated from field: librarian.proto.CompanyPb company = 2;
     */
    value: CompanyPb;
    case: "company";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CreateAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "stripe_region", kind: "enum", T: proto3.getEnumType(StripeRegion) },
    { no: 1, name: "individual", kind: "message", T: IndividualPb, oneof: "business" },
    { no: 2, name: "company", kind: "message", T: CompanyPb, oneof: "business" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateAccountRequest {
    return new CreateAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateAccountRequest {
    return new CreateAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateAccountRequest {
    return new CreateAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateAccountRequest | PlainMessage<CreateAccountRequest> | undefined, b: CreateAccountRequest | PlainMessage<CreateAccountRequest> | undefined): boolean {
    return proto3.util.equals(CreateAccountRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.RegisterAccountLinkRequest
 */
export class RegisterAccountLinkRequest extends Message<RegisterAccountLinkRequest> {
  /**
   * @generated from field: string stripe_account_id = 1;
   */
  stripeAccountId = "";

  /**
   * @generated from field: string refresh_url = 2;
   */
  refreshUrl = "";

  /**
   * @generated from field: string redirect_url = 3;
   */
  redirectUrl = "";

  /**
   * @generated from field: librarian.proto.StripeRegion stripe_region = 4;
   */
  stripeRegion = StripeRegion.REGION_UNKNOWN;

  constructor(data?: PartialMessage<RegisterAccountLinkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RegisterAccountLinkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stripe_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "refresh_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "redirect_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "stripe_region", kind: "enum", T: proto3.getEnumType(StripeRegion) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RegisterAccountLinkRequest {
    return new RegisterAccountLinkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RegisterAccountLinkRequest {
    return new RegisterAccountLinkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RegisterAccountLinkRequest {
    return new RegisterAccountLinkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RegisterAccountLinkRequest | PlainMessage<RegisterAccountLinkRequest> | undefined, b: RegisterAccountLinkRequest | PlainMessage<RegisterAccountLinkRequest> | undefined): boolean {
    return proto3.util.equals(RegisterAccountLinkRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.LinkNetworkRequest
 */
export class LinkNetworkRequest extends Message<LinkNetworkRequest> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * authorization code that will use to get access token
   *
   * @generated from field: string code = 2;
   */
  code = "";

  constructor(data?: PartialMessage<LinkNetworkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LinkNetworkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LinkNetworkRequest {
    return new LinkNetworkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LinkNetworkRequest {
    return new LinkNetworkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LinkNetworkRequest {
    return new LinkNetworkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LinkNetworkRequest | PlainMessage<LinkNetworkRequest> | undefined, b: LinkNetworkRequest | PlainMessage<LinkNetworkRequest> | undefined): boolean {
    return proto3.util.equals(LinkNetworkRequest, a, b);
  }
}

/**
 * used in archivist to poll and link author network
 *
 * @generated from message librarian.proto.PollAndLinkAuthorNetworkReq
 */
export class PollAndLinkAuthorNetworkReq extends Message<PollAndLinkAuthorNetworkReq> {
  /**
   * @generated from field: int64 author_id = 1;
   */
  authorId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.LinkNetworkRequest link_request = 2;
   */
  linkRequest?: LinkNetworkRequest;

  /**
   * @generated from field: string redirect_uri = 3;
   */
  redirectUri = "";

  constructor(data?: PartialMessage<PollAndLinkAuthorNetworkReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PollAndLinkAuthorNetworkReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "author_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "link_request", kind: "message", T: LinkNetworkRequest },
    { no: 3, name: "redirect_uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PollAndLinkAuthorNetworkReq {
    return new PollAndLinkAuthorNetworkReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PollAndLinkAuthorNetworkReq {
    return new PollAndLinkAuthorNetworkReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PollAndLinkAuthorNetworkReq {
    return new PollAndLinkAuthorNetworkReq().fromJsonString(jsonString, options);
  }

  static equals(a: PollAndLinkAuthorNetworkReq | PlainMessage<PollAndLinkAuthorNetworkReq> | undefined, b: PollAndLinkAuthorNetworkReq | PlainMessage<PollAndLinkAuthorNetworkReq> | undefined): boolean {
    return proto3.util.equals(PollAndLinkAuthorNetworkReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.StripeRequest
 */
export class StripeRequest extends Message<StripeRequest> {
  /**
   * @generated from field: librarian.proto.StripeRegion stripe_region = 1;
   */
  stripeRegion = StripeRegion.REGION_UNKNOWN;

  constructor(data?: PartialMessage<StripeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.StripeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stripe_region", kind: "enum", T: proto3.getEnumType(StripeRegion) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StripeRequest {
    return new StripeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StripeRequest {
    return new StripeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StripeRequest {
    return new StripeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StripeRequest | PlainMessage<StripeRequest> | undefined, b: StripeRequest | PlainMessage<StripeRequest> | undefined): boolean {
    return proto3.util.equals(StripeRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetLandingPageProductsRequest
 */
export class GetLandingPageProductsRequest extends Message<GetLandingPageProductsRequest> {
  /**
   * @generated from field: google.protobuf.StringValue landing_page_encrypt_id = 1;
   */
  landingPageEncryptId?: string;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 2;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<GetLandingPageProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetLandingPageProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "landing_page_encrypt_id", kind: "message", T: StringValue },
    { no: 2, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLandingPageProductsRequest {
    return new GetLandingPageProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLandingPageProductsRequest {
    return new GetLandingPageProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLandingPageProductsRequest {
    return new GetLandingPageProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetLandingPageProductsRequest | PlainMessage<GetLandingPageProductsRequest> | undefined, b: GetLandingPageProductsRequest | PlainMessage<GetLandingPageProductsRequest> | undefined): boolean {
    return proto3.util.equals(GetLandingPageProductsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetLandingPageProductsResponse
 */
export class GetLandingPageProductsResponse extends Message<GetLandingPageProductsResponse> {
  /**
   * @generated from field: repeated librarian.proto.ProductWithInfoPb products = 1;
   */
  products: ProductWithInfoPb[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<GetLandingPageProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetLandingPageProductsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products", kind: "message", T: ProductWithInfoPb, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetLandingPageProductsResponse {
    return new GetLandingPageProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetLandingPageProductsResponse {
    return new GetLandingPageProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetLandingPageProductsResponse {
    return new GetLandingPageProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetLandingPageProductsResponse | PlainMessage<GetLandingPageProductsResponse> | undefined, b: GetLandingPageProductsResponse | PlainMessage<GetLandingPageProductsResponse> | undefined): boolean {
    return proto3.util.equals(GetLandingPageProductsResponse, a, b);
  }
}


import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  ElementRef,
  inject,
  input,
  model,
  signal,
} from '@angular/core';
import { isNotEmptyArray } from '@frontend2/core';
import { NetworkConfigs } from '../../config/config.service';
import { ObjectFit } from '../../utils';
import { createPost } from '../posts.models';
import { LeftyPostMediaComponent } from './post-media.component';

@Component({
  selector: 'lefty-post-media-or-caption',
  standalone: true,
  imports: [LeftyPostMediaComponent],
  template: `
    @if (hasVisibleCaption() === false) {
      <lefty-post-media
        class="media"
        [urls]="urls()"
        [objectFit]="objectFit()"
        [asThumbnail]="asThumbnail()"
        (error$)="handleError()"
        [(imageOnly)]="imageOnly"
      ></lefty-post-media>
    } @else {
      <div class="caption">
        <p class="small">
          {{ caption() }}
        </p>
      </div>
    }
  `,
  styleUrls: ['./post-media-or-caption.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftyPostMediaOrCaptionComponent {
  constructor() {
    const host = inject(ElementRef<HTMLElement>).nativeElement;

    effect(() =>
      host.classList.toggle('visible-caption', this.hasVisibleCaption()),
    );
  }
  readonly post = input(createPost());
  readonly objectFit = input<ObjectFit>('cover');
  readonly asThumbnail = input(false);
  readonly imageOnly = model(false);

  readonly urls = computed(() => this.post().urls);
  readonly hasAssets = computed(() => isNotEmptyArray(this.urls()));

  readonly networkConfigs = inject(NetworkConfigs);

  readonly network = computed(() => this.post().network);
  readonly config = computed(() => this.networkConfigs.of(this.network()));
  readonly caption = computed(() => this.config().getCaption(this.post()));

  readonly hasVisibleCaption = computed(
    () => this.hasAssets() === false || this.hasError(),
  );

  readonly hasError = signal(false);

  handleError(): void {
    this.hasError.set(true);
  }
}

import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Optional,
  Output,
  Self,
  signal,
  TrackByFunction,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { isNil, isNotEmptyString, isNotNil } from '@frontend2/core';
import { Placement } from '@popperjs/core';

import { NgClass, NgFor, NgIf } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, pairwise, Subject } from 'rxjs';
import { ActiveItemDirective } from '../active-item.directive';
import { ComponentFactory } from '../dynamic-component.component';
import { LeftyFormValueBase } from '../form';
import { LeftyIconComponent } from '../icon/icon.component';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';
import { LeftyListComponent } from '../lefty-list/lefty-list.component';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';
import { AngularUtils } from '../utils';
import { LeftySelectDropdownItemComponent } from './lefty-select-dropdown-item.component';
import {
  defaultIsDisabledCheck,
  defaultItemRenderer,
  isDisabledCheck,
  ItemRenderer,
  SelectionModel,
} from './utils';

@Component({
  selector: 'lefty-form-select',
  templateUrl: './lefty-form-select.component.html',
  styleUrls: ['./lefty-form-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyFormComponent,
    NgClass,
    LeftyIconComponent,
    LeftyPopupComponent,
    LeftyListComponent,
    NgFor,
    LeftySelectDropdownItemComponent,
    ActiveItemDirective,
    NgIf,
  ],
})
export class LeftyFormSelectComponent<T>
  extends LeftyFormValueBase<SelectionModel<T>>
  implements AfterContentChecked
{
  constructor(@Self() @Optional() ngControl?: NgControl) {
    super(SelectionModel.single(), ngControl);
    this.disposer.add(this.popupVisibleChange);
    this.disposer.add(this.selectionChange);

    this.popupClose$.pipe(takeUntilDestroyed()).subscribe(() => {
      if (this.emitChangesOnClose) {
        this.selectionChange.next(this.selection);
      }
    });
  }

  @Input()
  emitChangesOnClose = false;

  @ViewChild('buttonContentRef')
  _buttonContentRef?: ElementRef<HTMLElement>;

  private _checkHasButtonContent(): boolean {
    const nativeElement = this._buttonContentRef?.nativeElement;
    if (isNil(nativeElement)) {
      return false;
    }
    return nativeElement.children.length > 0;
  }

  readonly hasButtonContent = signal(false);

  async ngAfterContentChecked(): Promise<void> {
    // Use requestAnimationFrame to defer the check until after the next paint
    // basically, it give time for the button content to be rendered
    requestAnimationFrame(() => {
      this.hasButtonContent.set(this._checkHasButtonContent());
    });
  }

  @Input()
  withRemoveSelection = false;

  @Input()
  buttonClass = '';

  @Input()
  popupClass = '';

  @Input()
  popupPlacement: Placement = 'bottom-start';

  @Input()
  buttonText = '';

  @Input()
  placeholder = '';

  @Input()
  optionsTrackBy: TrackByFunction<T> = AngularUtils.trackByIndex;

  get isPlaceholderVisible(): boolean {
    return this.hasValue === false && isNotEmptyString(this.placeholder);
  }

  get formattedValue(): string {
    if (isNotEmptyString(this.buttonText)) {
      return this.buttonText;
    }

    if (this.itemRenderer && !this.componentFactory) {
      if (this.isMultiSelect) {
        return this.value.selection.map(this.itemRenderer).join(', ');
      }
      return this.itemRenderer(this.value.selection[0]);
    }

    return '';
  }

  get hasFormattedValue(): boolean {
    return isNotEmptyString(this.formattedValue);
  }

  @Input()
  set selection(val: unknown) {
    if (val instanceof SelectionModel === false) {
      if (val instanceof Array) {
        val = SelectionModel.multi(val);
      } else if (isNotNil(val)) {
        val = SelectionModel.single(val as T);
      } else if (this.isMultiSelect) {
        val = SelectionModel.multi([]);
      } else {
        val = SelectionModel.single();
      }
    }

    this.value = val as SelectionModel<T>;
    if (this.isMultiSelect !== this.value.isMulti) {
      this.isMultiSelect = this.value.isMulti;
    }
  }

  get selection(): T | T[] | undefined {
    if (this.isMultiSelect) {
      return this.value.selection;
    }
    if (this.value.selection.length === 1) {
      return this.value.selection[0];
    }

    return;
  }

  // no other choice to use ANY if we want to support
  // T | undefined
  // OR
  // T[]
  //
  // If we use T | T[] | undefined, the analyzer complains
  @Output()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly selectionChange = new Subject<any>();

  @HostBinding('class.active')
  get isPopupVisible(): boolean {
    return this.popupVisible;
  }

  private _isMultiSelect = false;
  get isMultiSelect(): boolean {
    return this._isMultiSelect;
  }

  @Input()
  set isMultiSelect(multi: boolean) {
    this._isMultiSelect = multi;

    if (this.value.isMulti !== multi) {
      this.value = new SelectionModel(multi, this.value.selection);
    }
  }

  get isSingleSelect(): boolean {
    return this.isMultiSelect === false;
  }

  get selectedValues(): T[] {
    return this.value.selection;
  }

  @HostBinding('class.has-value')
  get hasValue(): boolean {
    return this.value.selection.length > 0;
  }

  @HostBinding('class.empty')
  get isEmpty(): boolean {
    return this.hasValue === false;
  }

  @ViewChild('button')
  dropdownButton?: ElementRef<HTMLButtonElement>;

  @Input()
  componentFactory?: ComponentFactory<T>;

  /// Function to convert an option object to string.
  @Input()
  itemRenderer?: ItemRenderer<T> = defaultItemRenderer;

  @Input()
  isDisabledCheck: isDisabledCheck<T> = defaultIsDisabledCheck;

  @Input()
  popupMatchInputWidth = true;

  @Input()
  matchMinSourceWidth = true;

  get isRemoveIcon(): boolean {
    return this.withRemoveSelection && !this.disabled && this.hasValue;
  }

  get iconName(): string {
    return this.isRemoveIcon ? 'close' : 'arrow_drop_down';
  }

  @Output()
  readonly popupVisibleChange = new Subject<boolean>();

  @Output()
  readonly popupOpen$ = this.popupVisibleChange.pipe(
    pairwise(),
    filter(([prev, curr]) => prev === false && curr === true),
  );

  @Output()
  readonly popupClose$ = this.popupVisibleChange.pipe(
    pairwise(),
    filter(([prev, curr]) => prev === true && curr === false),
  );

  private _popupVisible = false;

  get popupVisible(): boolean {
    return this._popupVisible;
  }

  /// Whether the dropdown is visible.
  set popupVisible(value: boolean) {
    if (this._popupVisible === value) {
      return;
    }

    this._popupVisible = value;
    this.popupVisibleChange.next(value);
    this.changeDetection.markForCheck();
  }

  private _options: T[] = [];

  public get options(): T[] {
    return this._options;
  }

  @Input()
  public set options(value: T[]) {
    this._options = value;
    this.activeIndex = 0;
  }

  activeIndex = 0;

  get activeItem(): T | undefined {
    if (this.options.length === 0) {
      return;
    }
    return this.options[this.activeIndex];
  }

  @Input()
  withFooter = false;

  isActive(item: T): boolean {
    return this.activeItem === item;
  }

  activate(item: T): void {
    this.activeIndex = this.options.indexOf(item);
    this.changeDetection.markForCheck();
  }

  isSelected(item: T): boolean {
    return this.value.isSelected(item);
  }

  private emitSelectionChange(): void {
    if (this.isMultiSelect) {
      this.selectionChange.next(this.value.selection);
    } else {
      this.selectionChange.next(
        this.value.selection.length > 0 ? this.value.selection[0] : undefined,
      );
    }
  }

  toggle(item: T): void {
    if (this.isMultiSelect) {
      if (this.isSelected(item)) {
        this.deselect(item);
      } else {
        this.select(item);
      }
    } else {
      if (this.isSelected(item) === false) {
        this.select(item);
      }
    }
  }

  select(item: T): void {
    this.activeIndex = this.options.indexOf(item);
    this.handleValueChange(this.value.select(item));

    if (this.emitChangesOnClose === false) {
      this.emitSelectionChange();
    }
  }

  deselect(item: T): void {
    this.handleValueChange(this.value.deselect(item));
    if (this.emitChangesOnClose === false) {
      this.emitSelectionChange();
    }
  }

  clearSelection(): void {
    this.handleValueChange(this.value.clear());
    if (this.emitChangesOnClose === false) {
      this.emitSelectionChange();
    }
  }

  private handleNavigationKey(
    event: Event,
    activateFunction: () => void,
  ): void {
    if (this.disabled) {
      return;
    }
    event.preventDefault();
    activateFunction();
    this.changeDetection.markForCheck();
  }

  handleUpKey(event: Event): void {
    this.handleNavigationKey(event, () => {
      if (this.activeIndex > 0) {
        this.activeIndex--;
      } else {
        this.activeIndex = this.options.length - 1;
      }
    });
  }

  handleDownKey(event: Event): void {
    this.handleNavigationKey(event, () => {
      if (this.activeIndex < this.options.length - 1) {
        this.activeIndex++;
      } else {
        this.activeIndex = 0;
      }
    });
  }

  activateItemOnKey(event: Event): void {
    if (this.itemRenderer && this.options !== null && !this.disabled) {
      for (const opt of this.options) {
        if (
          this.itemRenderer(opt)[0].toLowerCase() ===
          (event as KeyboardEvent).key.toLowerCase()
        ) {
          this.activeIndex = this.options.indexOf(opt);
          break;
        }
      }
    }
  }

  open(): void {
    this.popupVisible = true;
  }

  close(): void {
    this.popupVisible = false;
  }

  handleKeyboardTrigger(event: Event): void {
    // Prevent any scrolling.
    event?.preventDefault();

    if (this.disabled) {
      return;
    }
    if (!this.popupVisible) {
      this.open();
    } else {
      if (this.activeItem) {
        this.toggle(this.activeItem);
      }

      if (this.isSingleSelect) {
        this.close();
        this.dropdownButton?.nativeElement?.focus();
      }
    }
  }

  handleRemove(event: Event): void {
    if (!this.isRemoveIcon && !this.disabled) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    if (this.disabled) {
      return;
    }
    this.clearSelection();
  }

  onButtonClick(event: Event): void {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  override writeValue(obj: unknown): void {
    if (obj instanceof SelectionModel) {
      this.value = obj;
    } else {
      if (this.isMultiSelect) {
        if (Array.isArray(obj)) {
          this.value = SelectionModel.multi(obj as T[]);
        } else if (isNil(obj)) {
          this.value = SelectionModel.multi([]);
        }
      } else {
        if (isNil(obj)) {
          this.value = SelectionModel.single();
        } else {
          // Note(hadrien): no way to check obj instanceof T
          // Typescript does not support it, so we have to asume it is ...
          this.value = SelectionModel.single(obj as T);
        }
      }
    }
  }

  override registerOnChange(fn: (val: T | T[] | undefined) => void): void {
    this.disposer.addStreamSubscription(this.selectionChange.subscribe(fn));
  }
}

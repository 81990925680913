// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/pool.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreatorPoolRequestV2, PostsPerNetworkCount } from "./common_pb";
import { CreatorPoolResponses, DownloadPoolAssetsRequest, PoolExportReq, PoolGraphReqV2, PoolReportStats, PoolSettings, PoolSettingsResponse, PoolStatsReqV2, SimplePrefixSearchRequest, SimplePrefixSearchResponse, UploadDirectoryFile, UploadDirectoryFilePreview, UploadDirectoryRequest, UploadPoolRequest } from "./pool_pb";
import { Empty, Int64Value, MethodKind, StringValue } from "@bufbuild/protobuf";
import { PostTypeSliceData } from "./reports_pb";
import { NetworkList } from "../../common/proto/common_pb";
import { PrintDirectoryReportFilter } from "./epson_pb";

/**
 * This service deals with the pool of influencers for a customer, eg: their "favorites" directory.
 *
 * @generated from service librarian.proto.LibrarianPool
 */
export const LibrarianPool = {
  typeName: "librarian.proto.LibrarianPool",
  methods: {
    /**
     * search pool API with dynamic filters
     *
     * @generated from rpc librarian.proto.LibrarianPool.SearchV2
     */
    searchV2: {
      name: "SearchV2",
      I: CreatorPoolRequestV2,
      O: CreatorPoolResponses,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPool.SimplePrefixSearchAPI
     */
    simplePrefixSearchAPI: {
      name: "SimplePrefixSearchAPI",
      I: SimplePrefixSearchRequest,
      O: SimplePrefixSearchResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Updates the settings of the pool (which keywords is performance computed against, etc...).
     *
     * @generated from rpc librarian.proto.LibrarianPool.UpdatePoolSettings
     */
    updatePoolSettings: {
      name: "UpdatePoolSettings",
      I: PoolSettings,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Returns the settings of the pool for the currently logged user.
     *
     * @generated from rpc librarian.proto.LibrarianPool.GetPoolSettings
     */
    getPoolSettings: {
      name: "GetPoolSettings",
      I: Empty,
      O: PoolSettingsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DEPRECATED: cleanup once frontend migrated to UploadInfluencersV2
     * Batch upload the influencers in the request into the currently logged user's pool.
     *
     * @generated from rpc librarian.proto.LibrarianPool.UploadInfluencers
     */
    uploadInfluencers: {
      name: "UploadInfluencers",
      I: UploadPoolRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Exports the logged user's pool as a powerpoint and returns the token for the completion of that long running task.
     *
     * @generated from rpc librarian.proto.LibrarianPool.RequestPoolExportPptTask
     */
    requestPoolExportPptTask: {
      name: "RequestPoolExportPptTask",
      I: PoolExportReq,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * This endpoint is only used for pool export for now
     * returns the bronco handle
     *
     * @generated from rpc librarian.proto.LibrarianPool.RequestPoolExportExcelTask
     */
    requestPoolExportExcelTask: {
      name: "RequestPoolExportExcelTask",
      I: PoolExportReq,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPool.DownloadPoolVisuals
     */
    downloadPoolVisuals: {
      name: "DownloadPoolVisuals",
      I: DownloadPoolAssetsRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPool.getReportStatsAPIV2
     */
    getReportStatsAPIV2: {
      name: "getReportStatsAPIV2",
      I: PoolStatsReqV2,
      O: PoolReportStats,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPool.getReportGraphDataAPIV2
     */
    getReportGraphDataAPIV2: {
      name: "getReportGraphDataAPIV2",
      I: PoolGraphReqV2,
      O: PostTypeSliceData,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPool.AutocompleteCreatorsAPIV2
     */
    autocompleteCreatorsAPIV2: {
      name: "AutocompleteCreatorsAPIV2",
      I: CreatorPoolRequestV2,
      O: CreatorPoolResponses,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPool.GetPoolPostsCountPerNetwork
     */
    getPoolPostsCountPerNetwork: {
      name: "GetPoolPostsCountPerNetwork",
      I: Empty,
      O: PostsPerNetworkCount,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPool.ListNetworksInPool
     */
    listNetworksInPool: {
      name: "ListNetworksInPool",
      I: Empty,
      O: NetworkList,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPool.UploadInfluencersFile
     */
    uploadInfluencersFile: {
      name: "UploadInfluencersFile",
      I: UploadDirectoryFile,
      O: Int64Value,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPool.GetInfluencersUploadPreview
     */
    getInfluencersUploadPreview: {
      name: "GetInfluencersUploadPreview",
      I: Int64Value,
      O: UploadDirectoryFilePreview,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPool.UploadInfluencersV2
     */
    uploadInfluencersV2: {
      name: "UploadInfluencersV2",
      I: UploadDirectoryRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Trigger Directory PDF generation on Epson server
     * Response is a Bronco task handle
     * and download url in teh task output
     *
     * @generated from rpc librarian.proto.LibrarianPool.GenerateReportPDF
     */
    generateReportPDF: {
      name: "GenerateReportPDF",
      I: PrintDirectoryReportFilter,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPool.GenerateReportPPT
     */
    generateReportPPT: {
      name: "GenerateReportPPT",
      I: PrintDirectoryReportFilter,
      O: StringValue,
      kind: MethodKind.Unary,
    },
  }
} as const;


<lefty-dialog
  [(visible)]="visible"
  class="small"
  (close$)="close()"
>
  <h3 title>
    {{ dialogTitle() }}
  </h3>
  <lefty-address-field-form
    [(address)]="address"
    (isValid$)="isValid.set($event)"
    [isPhoneRequired]="isPhoneRequired()"
    #addressForm
  ></lefty-address-field-form>

  <div
    class="footer-with-checkbox"
    footer
  >
    <div class="checkbox fill-space">
      @if (isWithUser()) {
        <lefty-checkbox
          label="Save for later"
          i18n-label
          [(checked)]="saveForLater"
        ></lefty-checkbox>
      }
    </div>

    <button (click)="close()">
      {{ Messages.cancel }}
    </button>
    <button
      leftyButton="primary"
      (click)="submit()"
      [disabled]="!isValid()"
      [loading]="addressForm.addressDetailLoading()"
    >
      {{ submitButtonText() }}
    </button>
  </div>
</lefty-dialog>

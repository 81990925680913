import {
  inject,
  Injectable,
  InjectionToken,
  NgModule,
  ValueProvider,
} from '@angular/core';
import {
  AnyMessage,
  Message,
  MethodInfo,
  PartialMessage,
  ServiceType,
} from '@bufbuild/protobuf';
import {
  Code,
  ConnectError,
  ContextValues,
  Interceptor,
  Transport,
  UnaryResponse,
} from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import { retry } from '@frontend2/core';
import { unauthenticatedInterceptor } from './auth/unauthenticated.interceptor';

export const API_HOST = new InjectionToken<string>('API_HOST');

export function provideLeftyApiHost(useValue: string): ValueProvider {
  return { provide: API_HOST, useValue };
}

export const GRPC_INTERCEPTORS = new InjectionToken<Interceptor[]>(
  'GRPC_INTERCEPTORS',
);

export const GRPC_TRANSPORT = new InjectionToken<Transport>('GRPC_TRANSPORT');

export const CONNECT_WEB_DEVTOOLS = new InjectionToken<Interceptor>(
  'CONNECT_WEB_DEVTOOLS',
);

export type ErrorMessageBuilder = () => string;

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    __CONNECT_WEB_DEVTOOLS__: any;
  }
}

export function isInternalServerError(error: ConnectError | number): boolean {
  const code = typeof error === 'number' ? error : error.code;
  return code === Code.Unknown || code === Code.Internal;
}

export function isNetworkError(error: ConnectError): boolean {
  return (
    error.code === Code.Unknown &&
    error.rawMessage === 'Failed to fetch' &&
    navigator.onLine === false
  );
}

// match timeout we have on Envoy
const GRPC_TIMEOUT_MS = 15000;

@Injectable({ providedIn: 'root' })
export class LeftyGrpcWebTransport implements Transport {
  private readonly _client = createGrpcWebTransport({
    baseUrl: inject(API_HOST),
    interceptors: inject(GRPC_INTERCEPTORS, { optional: true }) ?? [],
    credentials: 'include',
    defaultTimeoutMs: GRPC_TIMEOUT_MS,
  });

  unary<I extends Message<I> = AnyMessage, O extends Message<O> = AnyMessage>(
    service: ServiceType,
    method: MethodInfo<I, O>,
    signal: AbortSignal | undefined,
    timeoutMs: number | undefined,
    header: HeadersInit | undefined,
    input: PartialMessage<I>,
    contextValues?: ContextValues | undefined,
  ): Promise<UnaryResponse<I, O>> {
    const call = (): Promise<UnaryResponse<I, O>> =>
      this._client.unary(
        service,
        method,
        signal,
        timeoutMs,
        header,
        input,
        contextValues,
      );

    return retry(call, {
      whenError: (e) => {
        return e instanceof ConnectError && isNetworkError(e);
      },
    });
  }

  // don't need to retry stream call
  readonly stream = this._client.stream;
}

@NgModule({
  providers: [
    {
      provide: GRPC_INTERCEPTORS,
      useFactory: (): Interceptor[] => {
        const interceptors: Interceptor[] = [unauthenticatedInterceptor];

        // __CONNECT_WEB_DEVTOOLS__ is loaded in as a script, so it is not guaranteed to be loaded before your code.
        if (window.__CONNECT_WEB_DEVTOOLS__) {
          interceptors.push(window.__CONNECT_WEB_DEVTOOLS__);
        } else {
          // To get around the fact that __CONNECT_WEB_DEVTOOLS__ might not be loaded, we can listen for a custom event,
          // and then push the interceptor to our array once loaded.
          window.addEventListener('connect-web-dev-tools-ready', () => {
            if (window.__CONNECT_WEB_DEVTOOLS__) {
              interceptors.push(window.__CONNECT_WEB_DEVTOOLS__);
            }
          });
        }

        return interceptors;
      },
    },
    {
      provide: GRPC_TRANSPORT,
      useClass: LeftyGrpcWebTransport,
    },
  ],
})
export class GrpcModule {}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { LEFTY_LOGIN_PATH } from '@frontend2/core';
import { LeftyAuthBloc } from './auth.bloc';

/**
 * Redirect to '/login' page if is_logged is FALSE
 */
export const leftyIsLoggedGuard = (): boolean | UrlTree => {
  const auth = inject(LeftyAuthBloc);
  const router = inject(Router);

  if (auth.isLogged === false) {
    return router.parseUrl(`/${LEFTY_LOGIN_PATH}`);
  }
  return auth.isLogged;
};

/**
 * Redirect to LOGIN page if is_admin is FALSE
 */
export const leftyIsAdminGuard = (): boolean | UrlTree => {
  const auth = inject(LeftyAuthBloc);
  const router = inject(Router);

  if (auth.isAdmin === false) {
    return router.parseUrl(`/${LEFTY_LOGIN_PATH}`);
  }
  return auth.isAdmin;
};

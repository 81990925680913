import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianPool } from '@frontend2/proto/librarian/proto/pool_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianPoolClient = PromiseClient<typeof LibrarianPool>;

export const LibrarianPoolClientProvider = new InjectionToken(
  'LibrarianPoolClient',
  {
    factory: (): LibrarianPoolClient =>
      createPromiseClient(LibrarianPool, inject(GRPC_TRANSPORT)),
  },
);
export function injectLibrarianPoolClient(): LibrarianPoolClient {
  return inject(LibrarianPoolClientProvider);
}

/* eslint-disable @angular-eslint/no-output-native */
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  Input,
  signal,
} from '@angular/core';
import {
  escapeRegExp,
  isEmptyString,
  isNil,
  isNotEmptyString,
} from '@frontend2/core';
import { CountryDialCodeCache } from '../cache/dial-codes.service';
import { InputSize, LeftyControlValueAccessor } from '../form';
import {
  createPhone,
  isPhone,
  parsePhoneString,
  Phone,
} from './lefty-phone-number.helpers';
import { DialCodeListItemComponent } from './dial-code-list-item/dial-code-list-item.component';
import { LeftyListComponent } from '../lefty-list/lefty-list.component';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';
import { LeftyFormInputComponent } from '../lefty-form-input/lefty-form-input.component';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-phone-number-input',
  templateUrl: './lefty-phone-number-input.component.html',
  styleUrls: ['./lefty-phone-number-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyFormComponent,
    LeftyFormInputComponent,
    LeftyPopupComponent,
    LeftyListComponent,
    DialCodeListItemComponent,
  ],
})
export class LeftyPhoneNumberInputComponent extends LeftyControlValueAccessor<Phone> {
  constructor() {
    super();
    this.dialCodesCache.load();
  }

  readonly defaultDialCode = input('');

  readonly dialCodesCache = inject(CountryDialCodeCache);

  readonly options = this.dialCodesCache.dialCodesList;

  readonly dialCodeValue = computed((): string => {
    const code = this.value()?.dialCode;
    return isNil(code) || isEmptyString(code) ? this.defaultDialCode() : code;
  });

  readonly phoneNumberValue = computed(() => this.value()?.phoneNumber ?? '');

  readonly searchValue = signal('');

  readonly filteredOptions = computed(() => {
    const query = escapeRegExp(this.searchValue().trim());
    const regex = new RegExp(query, 'i');

    return this.options().filter((s) => s.name && regex.test(s.name));
  });

  @Input()
  size: InputSize = 'medium';

  override readonly placeholder = input($localize`Phone number`);

  override isValidType(obj: unknown): obj is Phone {
    return isPhone(obj);
  }

  setDialCodeValue(dialCode: string): void {
    this.setValueAndNotify(
      createPhone({
        phoneNumber: this.phoneNumberValue(),
        dialCode,
      }),
    );
  }

  setPhoneNumberValue(phoneNumber: string): void {
    // in case user is copy pasting, we want to catch dial code if any
    const phone = parsePhoneString(phoneNumber);

    this.setValueAndNotify({
      ...phone,
      dialCode: isNotEmptyString(phone.dialCode)
        ? phone.dialCode
        : this.dialCodeValue(),
    });
  }
}

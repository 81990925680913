import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  LeftyColors,
  generateRgbFromString,
  getInitials,
  getInitialsFromEmail,
} from '@frontend2/core';
import { LeftyComponent } from '../utils';

export type AvatarSize = 'small' | 'small-medium' | 'medium' | 'large';
/*  small        : 24px
    small-medium : 30px
    medium       : 32px
    large        : 40px */

@Component({
  selector: 'lefty-avatar',
  templateUrl: './lefty-avatar.component.html',
  styleUrls: ['lefty-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeftyAvatarComponent extends LeftyComponent {
  @Input()
  text = '';

  @Input()
  isDark = false;

  @Input()
  isSquare = false;

  @Input()
  size: AvatarSize = 'small';

  @Input()
  isGhost = false;

  get initial(): string {
    return this.text.charAt(0).toUpperCase();
  }

  get initialColor(): string {
    return generateRgbFromString(this.text, LeftyColors.gradient500);
  }
  get initialBackgroundColor(): string {
    return generateRgbFromString(this.text, LeftyColors.gradient100);
  }
}

@Component({
  selector: 'lefty-email-avatar',
  templateUrl: './lefty-avatar.component.html',
  styleUrls: ['lefty-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeftyEmailAvatarComponent extends LeftyAvatarComponent {
  override get initial(): string {
    return getInitialsFromEmail(this.text).toUpperCase();
  }
}

@Component({
  selector: 'lefty-author-avatar',
  templateUrl: './lefty-avatar.component.html',
  styleUrls: ['lefty-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class LeftyAuthorAvatarComponent extends LeftyAvatarComponent {
  override get initial(): string {
    return getInitials(this.text).toUpperCase();
  }
}

import { isNil } from '@frontend2/core';
import { FavoritesLabel } from '@frontend2/proto/common/proto/campaign_pb';
import { BaseEntityType } from '@frontend2/proto/librarian/proto/common_pb';
import { EntityLabel } from '@frontend2/proto/librarian/proto/entity_labels_pb';

// default label name return by backend, when influencer is added to directory or campaign
// This is legacy, backend should not do that anymore
const _defaultLabelName = '_favorites_';
const _campaignLabelName = '_favorites_campaign_';
///////

export const LABEL_MAX_SIZE = 100;

// label ID -1 will never exist, so we can use it as a temporary id
// that represent a label not created yet
const _tempLabelId = BigInt(-1);

const _ghostLabel = new EntityLabel();
export function createGhostLabel(): EntityLabel {
  return _ghostLabel;
}

export function isGhostLabel(label: EntityLabel): boolean {
  return label === _ghostLabel || label.id === BigInt(0) || isNil(label.id);
}

export function isDefaultLabel(label: EntityLabel): boolean {
  return label.name === _defaultLabelName;
}

export function isTempLabel(label: EntityLabel): boolean {
  return label.id === _tempLabelId;
}

export function isCampaignLabel(label: EntityLabel): boolean {
  return label.name === _campaignLabelName;
}

export function isValidLabel(label: EntityLabel): boolean {
  return label.name.length > 0 && label.name.length <= LABEL_MAX_SIZE;
}

export function isFakeLabel(label: EntityLabel): boolean {
  return isDefaultLabel(label) || isCampaignLabel(label) || isTempLabel(label);
}

export function createTemporaryLabel(name: string): EntityLabel {
  return new EntityLabel({
    name,
    id: _tempLabelId,
  });
}

export function campaignLabelToEntity(label: FavoritesLabel): EntityLabel {
  return new EntityLabel({
    entityType: BaseEntityType.CAMPAIGN,
    name: label.name,
    id: label.id,
    archived: label.archived,
    count: label.count,
    created: label.created,
  });
}

import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianPermissionsManagement } from '@frontend2/proto/librarian/proto/user_role_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianPermissionsManagementClient = PromiseClient<
  typeof LibrarianPermissionsManagement
>;

const factory = (): LibrarianPermissionsManagementClient => {
  return createPromiseClient(
    LibrarianPermissionsManagement,
    inject(GRPC_TRANSPORT),
  );
};

const token = new InjectionToken('UserRolePbClient', { factory });

export function injectLibrarianPermissionsManagementClient(): LibrarianPermissionsManagementClient {
  return inject(token);
}

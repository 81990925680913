<div class="included-keywords">
  <network-tracking-input
    [availableNetworks]="availableNetworks()"
    [canRemove]="canRemove()"
    (clickRemove$)="remove$.emit()"
    [value]="value()"
    (valueChange)="setValueAndNotify($event)"
    [withLogicOperators]="withLogicOperator()"
    [cantEditSelectedNetwork]="cantEditSelectedNetwork()"
    #included
  >
  </network-tracking-input>
</div>
@if (withExcluded()) {
  <div class="excluded-keywords">
    <lefty-checkbox
      label="Exclude content containing any hashtags, mentions or specific keywords"
      i18n-label
      #excludedCheckbox
      (checkedChange)="excludedCheckedChange($event)"
      [(checked)]="excludedCheckBox"
    ></lefty-checkbox>
    @if (excludedCheckbox.checked) {
      <network-tracking-input
        class="excluded-tracking-input"
        [availableNetworks]="[included.network()]"
        [canRemove]="false"
        [hideNetworks]="true"
        [withLogicOperators]="false"
        [isExcluded]="true"
        [value]="value()"
        (valueChange)="setValueAndNotify($event)"
      >
      </network-tracking-input>
    }
  </div>
}

import {
  Observable,
  Subject,
  Subscription,
  from,
  interval,
  switchMap,
  takeWhile,
} from 'rxjs';
import { sleep } from './times';

/// A function, that when called, will cleanup any resources or subscriptions.
export type DisposeFunction = () => void;

/// Help implement the dispose method
/// It also provide a [Disposer] helper to dispose resources
export abstract class Disposable {
  protected readonly disposer = new Disposer();

  private _disposed = false;

  get disposed(): boolean {
    return this._disposed;
  }

  /// MUST CALL SUPER
  dispose(): void {
    if (!this._disposed) {
      this.disposer.dispose();
      this._disposed = true;
    }
  }
}

/// Helper to easily track a subscription or something that need to be disposed
export class Disposer {
  private disposeFunctions: DisposeFunction[] = [];
  private disposeSubs: Subscription[] = [];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private disposeSubjects: Subject<any>[] = [];

  add<T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    obj: DisposeFunction | Subscription | Subject<any> | Disposable,
  ): void {
    if (obj instanceof Subscription) {
      this.addStreamSubscription(obj);
    } else if (obj instanceof Subject) {
      this.addSubject<T>(obj);
    } else if (obj instanceof Disposable) {
      this.addDisposable(obj);
    } else {
      this.addFunction(obj);
    }
  }

  /// Registers [disposable].
  addStreamSubscription(disposable: Subscription): Subscription {
    this.disposeSubs.push(disposable);
    return disposable;
  }

  addDisposable(disposable: Disposable): Disposable {
    this.disposeFunctions.push(disposable.dispose);
    return disposable;
  }

  addSubject<T>(subject: Subject<T>): Subject<T> {
    this.disposeSubjects.push(subject);
    return subject;
  }

  /// Registers [disposable].
  addFunction(disposable: DisposeFunction): DisposeFunction {
    this.disposeFunctions.push(disposable);
    return disposable;
  }

  dispose(): void {
    this.disposeSubs.forEach((sub) => sub.unsubscribe());
    this.disposeSubs = [];

    this.disposeFunctions.forEach((func) => func());
    this.disposeFunctions = [];

    this.disposeSubjects.forEach((sub) => sub.complete());
    this.disposeSubjects = [];
  }
}

export abstract class Observables {
  /// safely add a value to a controller
  /// avoid controller to throw excpetion isClosed
  static safeNext<T>(controller: Subject<T>, data: T): void {
    if (controller.closed === false) {
      controller.next(data);
    }
  }

  static poll<T>(
    fetchFn: () => Promise<T>,
    isSuccessFn: (red: T) => boolean,
    pollInterval: number,
  ): Observable<T> {
    return interval(pollInterval).pipe(
      switchMap(() => from(fetchFn())),
      takeWhile((response) => isSuccessFn(response), true),
    );
  }
}

/**
 * @deprecated Legacy naming, in Dart Observable a call Stream
 * we keep this for easier migration from Dart
 */
export abstract class Streams {
  static safeAdd<T>(controller: Subject<T>, data: T): void {
    Observables.safeNext(controller, data);
  }
}

export class DebounceTimer {
  // milliseconds
  readonly duration: number;

  constructor(duration: number) {
    this.duration = duration;
  }

  private timer?: number;

  trigger(fn: () => void): void {
    this.cancel();

    this.timer = window.setTimeout(() => {
      fn();
      this.timer = undefined;
    }, this.duration);
  }

  cancel(): void {
    if (!this.timer) {
      return;
    }

    clearTimeout(this.timer);
  }
}

export interface PromiseCompleter<R> {
  promise: Promise<R>;
  resolve: (value: R | PromiseLike<R>) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reject: (reason?: any) => void;
}

export class Promises {
  static timeout<T>(
    promise: Promise<T>,
    ms: number,
    timeoutError = new Error('Promise timed out'),
  ): Promise<unknown> {
    // create a promise that rejects in milliseconds
    const timeout = new Promise((_, reject) => {
      setTimeout(() => {
        reject(timeoutError);
      }, ms);
    });

    // returns a race between timeout and the passed promise
    return Promise.race([promise, timeout]);
  }

  static resolve<T>(obj: T): Promise<T> {
    return Promise.resolve(obj);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static reject(obj: unknown, _: unknown): Promise<unknown> {
    return Promise.reject(obj);
  }

  // Note: We can't rename this method into `catch`, as this is not a valid
  // method name in Dart.
  static catchError<T>(
    promise: Promise<T>,
    onError: (error: unknown) => T | PromiseLike<T>,
  ): Promise<T> {
    return promise.catch(onError);
  }

  static all(promises: Promise<unknown>[]): Promise<unknown> {
    if (promises.length === 0) {
      return Promise.resolve([]);
    }
    return Promise.all(promises);
  }

  static then<T, U>(
    promise: Promise<T>,
    success: (value: T) => U | PromiseLike<U>,
    rejection?: (error: unknown, stack?: unknown) => U | PromiseLike<U>,
  ): Promise<U> {
    return promise.then(success, rejection);
  }

  static wrap<T>(computation: () => T): Promise<T> {
    return new Promise((res, rej) => {
      try {
        res(computation());
      } catch (e) {
        rej(e);
      }
    });
  }

  static scheduleMicrotask(computation: () => unknown): void {
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    Promises.then(Promises.resolve(null), computation, (_) => {});
  }

  static isPromise(obj: unknown): boolean {
    return obj instanceof Promise;
  }

  static completer<T>(): PromiseCompleter<T> {
    let resolve: (value: PromiseLike<T> | T) => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let reject: (reason?: any) => void;

    const p = new Promise<T>((res, rej) => {
      resolve = res;
      reject = rej;
    });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return { promise: p, resolve: resolve!, reject: reject! };
  }
}

const _defaultNbRetry = 2;
const _defaultRetryDelay = (retryCount: number): number =>
  1000 * Math.pow(1.5, retryCount);

export async function retry<T>(
  func: () => Promise<T>,
  args: {
    whenError: (error: unknown) => boolean;
    retries?: number;
    delay?: (retryCount: number) => number;
    retryCount?: number;
  },
): Promise<T> {
  const delay = args.delay ?? _defaultRetryDelay;
  const retries = args.retries ?? _defaultNbRetry;
  const retryCount = args.retryCount ?? 0;

  try {
    const result = await func();
    return result;
  } catch (e) {
    if (args.whenError(e) && retries > retryCount) {
      await sleep(delay(retryCount));

      return retry(func, {
        ...args,
        retryCount: retryCount + 1,
      });
    }

    throw e;
  }
}

<div #popupTrigger>
  <lefty-chip
    [chip]="valueChip()"
    [dark]="dark()"
    [highlight]="highlight()"
    [ghost]="ghost()"
    [noOverflow]="noOverflow()"
    [removable]="false"
    class="operator-chip"
    [class.fixed-width]="editable()"
  >
    @if (editable()) {
      <lefty-icon
        icon="arrow_drop_down"
        size="medium"
      ></lefty-icon>
    }
  </lefty-chip>
</div>
@if (editable()) {
  <lefty-popup
    [placement]="popupPlacement()"
    [popupTrigger]="popupTrigger"
  >
    <lefty-list class="operators-list">
      @for (operator of operatorOptions; track $index) {
        <lefty-select-dropdown-item
          [selected]="isSelected(operator)"
          (trigger$)="operatorSelected(operator)"
          >{{ readableLogicOperator(operator) }}</lefty-select-dropdown-item
        >
      }
    </lefty-list></lefty-popup
  >
}

import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  Input,
  OnDestroy,
  Output,
  inject,
  signal,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { FormControl, FormControlDirective, FormsModule } from '@angular/forms';
import { isNil, isNotNil, sleep } from '@frontend2/core';
import { Subscription } from 'rxjs';
import { FocusableComponent } from '../focus.directive';
import { injectChangeDetector } from '../inject.helpers';
import { LeftyButtonDirective } from '../lefty-button-directive/lefty-button.directive';
import { createOutput } from '../utils';
import { LeftyFormComponent } from './lefty-form.component';

@Component({
  selector: 'lefty-edit-field-form',
  templateUrl: 'lefty-edit-field-form.component.html',
  styleUrls: ['lefty-edit-field-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [FormsModule, LeftyFormComponent, NgIf, LeftyButtonDirective],
})
export class LeftyEditFieldFormComponent implements OnDestroy {
  readonly changeDetection = injectChangeDetector();
  readonly elementRef = inject(ElementRef) as ElementRef<HTMLElement>;

  readonly isEditing = signal(false);
  readonly showCancelButton = this.isEditing;

  get isEditionDisabled(): boolean {
    return this.loading || this.disabled;
  }

  @Output()
  readonly editing$ = toObservable(this.isEditing);

  @Output()
  readonly delete$ = createOutput<MouseEvent>();

  @Input()
  optional = false;

  @Input()
  canDelete = false;

  @Input()
  label = '';

  @Input()
  loading = false;

  @Input()
  disabled = false;

  @Input()
  actionsOnBottom = false;

  @Input() actionsOnLeft = false;

  @Input()
  hideLabelOnEdit = false;

  @Input()
  hideCancel = false;

  @Input()
  showRemoveOnReadMode = false;

  @Input()
  preventEditMode = false;

  @Input()
  hideEditButton = false;

  @Input()
  set defaultOnEditMode(val: boolean) {
    if (val === true) {
      this.startEdition();
    }
  }

  get hideLabel(): boolean {
    return this.hideLabelOnEdit && this.isEditing();
  }

  private _statusSubscription?: Subscription;

  private _subscribeToControlStatusChange(control: FormControl): void {
    this._statusSubscription?.unsubscribe();

    // force UI update if form control status change
    this._statusSubscription = control.statusChanges.subscribe(() =>
      this.changeDetection.markForCheck(),
    );
  }

  @ContentChild(FocusableComponent)
  focusableContentComp?: FocusableComponent;

  startEdition(): void {
    this.isEditing.set(true);

    const control = this.formControlDirective?.control;
    if (isNotNil(control)) {
      this._savedFormValue = control.value;
      this._subscribeToControlStatusChange(control);
    } else {
      this._savedFormValue = undefined;
    }

    // wait for editMode to be fully activated
    sleep(100).then(() => {
      this._focusEditMode();
    });
  }

  private _focusEditMode(): void {
    if (isNotNil(this.focusableContentComp)) {
      this.focusableContentComp.focus();
    }
  }

  stopEdition(): void {
    this.isEditing.set(false);
    this._statusSubscription?.unsubscribe();
  }

  private _savedFormValue: unknown;

  @ContentChild(FormControlDirective)
  formControlDirective?: FormControlDirective;

  get isInvalid(): boolean {
    return this.formControlDirective?.control?.invalid ?? false;
  }

  @Output()
  readonly edit$ = createOutput<MouseEvent>();

  edit(event: MouseEvent): void {
    this.edit$.next(event);
    if (!this.preventEditMode) {
      this.startEdition();
    } else {
      this.stopEdition();
    }
  }

  @Output()
  readonly cancel$ = createOutput<MouseEvent>();

  private _resetValue(): void {
    this.formControlDirective?.reset(this._savedFormValue);
  }

  cancel(event: MouseEvent): void {
    this.cancel$.next(event);
    this._resetValue();
    this.stopEdition();
  }

  @Output()
  readonly submit$ = createOutput<FormControl>();

  submit(event: MouseEvent): void {
    const control = this.formControlDirective?.control;

    if (isNil(control) || control.dirty === false) {
      this.cancel(event);
      return;
    }

    if (control.invalid) {
      return;
    }
    this.submit$.next(control);
    this.stopEdition();
  }

  ngOnDestroy(): void {
    this._statusSubscription?.unsubscribe();
  }
}

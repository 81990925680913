import { LeftyFormat, isNotNil } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { CreatorAutocompleteResponse_CreatorAutocompleteMatch } from '@frontend2/proto/librarian/proto/users_pb';
import { CreatorAutocompleteMatch } from './influencer-autocomplete-base';

export interface InfluencerWithNetworkInfoAutocomplete {
  readonly influencer: CreatorAutocompleteMatch;
  readonly selectedNetwork: Network;
}

export function createInfluencerWithNetworkInfoAutocomplete(
  partial: Partial<InfluencerWithNetworkInfoAutocomplete>,
): InfluencerWithNetworkInfoAutocomplete {
  return {
    influencer:
      partial.influencer ??
      new CreatorAutocompleteResponse_CreatorAutocompleteMatch(),
    selectedNetwork: partial.selectedNetwork ?? Network.NETWORK_UNKNOWN,
  };
}

export function getCreatorAutocompleteMatchFollowerCountForNetwork(
  val: InfluencerWithNetworkInfoAutocomplete,
  supportFollowers: boolean,
): string {
  const networkInfo = val.influencer.networkInfo.find(
    (n) => n.network === val.selectedNetwork,
  );
  if (isNotNil(networkInfo) && supportFollowers) {
    return LeftyFormat.followers(networkInfo.count?.followersCount ?? 0, {
      compact: true,
    });
  }
  return '-';
}

// This file defines protocol buffers that wrap the Facebook API.
// They are mostly returned by the FacebookManager.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/facebook.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ComputedGenerikStats, ContentMimeType, PostType } from "./common_pb";
import { GenerikMediaFacts, MediaResolution } from "./media_pb";
import { PostInteractions } from "./campaign_pb";

/**
 * @generated from message common.proto.FacebookCreatorStats
 */
export class FacebookCreatorStats extends Message<FacebookCreatorStats> {
  /**
   * @generated from field: int32 likes = 1;
   */
  likes = 0;

  /**
   * @generated from field: int64 followers = 2;
   */
  followers = protoInt64.zero;

  constructor(data?: PartialMessage<FacebookCreatorStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.FacebookCreatorStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "likes", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "followers", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookCreatorStats {
    return new FacebookCreatorStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookCreatorStats {
    return new FacebookCreatorStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookCreatorStats {
    return new FacebookCreatorStats().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookCreatorStats | PlainMessage<FacebookCreatorStats> | undefined, b: FacebookCreatorStats | PlainMessage<FacebookCreatorStats> | undefined): boolean {
    return proto3.util.equals(FacebookCreatorStats, a, b);
  }
}

/**
 * @generated from message common.proto.FacebookCreator
 */
export class FacebookCreator extends Message<FacebookCreator> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string profile_pic = 2;
   */
  profilePic = "";

  /**
   * @generated from field: string long_name = 3;
   */
  longName = "";

  /**
   * @generated from field: common.proto.FacebookCreatorStats stats = 4;
   */
  stats?: FacebookCreatorStats;

  /**
   * @generated from field: string username = 5;
   */
  username = "";

  /**
   * @generated from field: common.proto.ComputedGenerikStats computed_stats = 6;
   */
  computedStats?: ComputedGenerikStats;

  constructor(data?: PartialMessage<FacebookCreator>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.FacebookCreator";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "profile_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "long_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "stats", kind: "message", T: FacebookCreatorStats },
    { no: 5, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "computed_stats", kind: "message", T: ComputedGenerikStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookCreator {
    return new FacebookCreator().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookCreator {
    return new FacebookCreator().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookCreator {
    return new FacebookCreator().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookCreator | PlainMessage<FacebookCreator> | undefined, b: FacebookCreator | PlainMessage<FacebookCreator> | undefined): boolean {
    return proto3.util.equals(FacebookCreator, a, b);
  }
}

/**
 * @generated from message common.proto.FacebookMediaEntity
 */
export class FacebookMediaEntity extends Message<FacebookMediaEntity> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * @generated from field: common.proto.ContentMimeType type = 2;
   */
  type = ContentMimeType.UNDEFINED;

  /**
   * @generated from field: common.proto.MediaResolution resolution = 3;
   */
  resolution = MediaResolution.LOW;

  /**
   * if FacebookMediaMetadata.postType is FB_CAROUSEL
   *
   * @generated from field: int32 carouselIndex = 4;
   */
  carouselIndex = 0;

  constructor(data?: PartialMessage<FacebookMediaEntity>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.FacebookMediaEntity";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(ContentMimeType) },
    { no: 3, name: "resolution", kind: "enum", T: proto3.getEnumType(MediaResolution) },
    { no: 4, name: "carouselIndex", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookMediaEntity {
    return new FacebookMediaEntity().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookMediaEntity {
    return new FacebookMediaEntity().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookMediaEntity {
    return new FacebookMediaEntity().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookMediaEntity | PlainMessage<FacebookMediaEntity> | undefined, b: FacebookMediaEntity | PlainMessage<FacebookMediaEntity> | undefined): boolean {
    return proto3.util.equals(FacebookMediaEntity, a, b);
  }
}

/**
 * @generated from message common.proto.FacebookMediaMetadata
 */
export class FacebookMediaMetadata extends Message<FacebookMediaMetadata> {
  /**
   * @generated from field: string creator_id = 1;
   */
  creatorId = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  /**
   * @generated from field: string caption = 4;
   */
  caption = "";

  /**
   * @generated from field: common.proto.PostType post_type = 5;
   */
  postType = PostType.POST_TYPE_UNDEFINED;

  /**
   * @generated from field: google.protobuf.Timestamp creation_time = 6;
   */
  creationTime?: Timestamp;

  /**
   * @generated from field: repeated string mentions = 7;
   */
  mentions: string[] = [];

  /**
   * @generated from field: repeated string tags = 8;
   */
  tags: string[] = [];

  /**
   * @generated from field: bool is_sponsored = 9;
   */
  isSponsored = false;

  /**
   * @generated from field: string sponsor_name = 10;
   */
  sponsorName = "";

  /**
   * @generated from field: common.proto.PostInteractions interactions = 11;
   */
  interactions?: PostInteractions;

  /**
   * @generated from field: repeated common.proto.FacebookMediaEntity medias = 12;
   */
  medias: FacebookMediaEntity[] = [];

  constructor(data?: PartialMessage<FacebookMediaMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.FacebookMediaMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "caption", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType) },
    { no: 6, name: "creation_time", kind: "message", T: Timestamp },
    { no: 7, name: "mentions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "is_sponsored", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "sponsor_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "interactions", kind: "message", T: PostInteractions },
    { no: 12, name: "medias", kind: "message", T: FacebookMediaEntity, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookMediaMetadata {
    return new FacebookMediaMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookMediaMetadata {
    return new FacebookMediaMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookMediaMetadata {
    return new FacebookMediaMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookMediaMetadata | PlainMessage<FacebookMediaMetadata> | undefined, b: FacebookMediaMetadata | PlainMessage<FacebookMediaMetadata> | undefined): boolean {
    return proto3.util.equals(FacebookMediaMetadata, a, b);
  }
}

/**
 * @generated from message common.proto.FacebookMedia
 */
export class FacebookMedia extends Message<FacebookMedia> {
  /**
   * @generated from field: common.proto.FacebookMediaMetadata metadata = 1;
   */
  metadata?: FacebookMediaMetadata;

  /**
   * @generated from field: common.proto.GenerikMediaFacts facts = 2;
   */
  facts?: GenerikMediaFacts;

  constructor(data?: PartialMessage<FacebookMedia>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.FacebookMedia";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: FacebookMediaMetadata },
    { no: 2, name: "facts", kind: "message", T: GenerikMediaFacts },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FacebookMedia {
    return new FacebookMedia().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FacebookMedia {
    return new FacebookMedia().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FacebookMedia {
    return new FacebookMedia().fromJsonString(jsonString, options);
  }

  static equals(a: FacebookMedia | PlainMessage<FacebookMedia> | undefined, b: FacebookMedia | PlainMessage<FacebookMedia> | undefined): boolean {
    return proto3.util.equals(FacebookMedia, a, b);
  }
}


<lefty-form-select
  [componentFactory]="networkItemFactory"
  [options]="options"
  [itemRenderer]="readableNetwork"
  [popupPlacement]="popupPlacement"
  [buttonClass]="buttonClass"
  [placeholder]="placeholder"
  [label]="label"
  [disabled]="disabled"
  [withRemoveSelection]="withRemoveSelection"
  [popupClass]="popupClass"
  [selection]="selection"
  (selectionChange)="handleValueChange($event)"
  (popupVisibleChange)="popupVisibleChange.next($event)"
  (focus$)="handleFocus($event)"
  (blur$)="handleBlur($event)"
  [optionsTrackBy]="trackByNetwork"
>
  <span
    *ngIf="hasSelection && isMultiSelect"
    buttonContent
    >{{ formattedSelectedNetworks }}</span
  >

  <network-dropdown-item
    *ngIf="hasSelection && isMultiSelect === false"
    buttonContent
    [network]="selectedNetwork"
  >
  </network-dropdown-item>
</lefty-form-select>

import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
} from '@angular/core';
import { Disposer, isNotEmptyString } from '@frontend2/core';
import { ButtonDirective } from '../lefty-button/button.directive';
import { DropdownHandle } from '../utils';
import { LeftyIconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lefty-list-item',
  template: `
    <lefty-icon
      *ngIf="hasLeadingIcon"
      size="x-large"
      [icon]="leadingIcon"
    ></lefty-icon>
    <ng-content></ng-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LeftyIconComponent],
})
export class LeftyListItemComponent
  extends ButtonDirective
  implements OnDestroy
{
  @HostBinding('class')
  readonly hostClass = 'lefty-list-item';

  readonly disposer = new Disposer();
  private readonly dropdown?: DropdownHandle;

  constructor(
    element: ElementRef,
    @Attribute('role') role?: string,
    @Attribute('tabindex') tabIndex?: string,
    @Optional() dropdown?: DropdownHandle,
  ) {
    super(element, role ?? 'listitem', tabIndex);
    this.dropdown = dropdown;

    if (this.dropdown) {
      this.disposer.addStreamSubscription(
        this.trigger$.subscribe(this.handleActivate.bind(this)),
      );
    }
  }

  /// Whether the encompassing dropdown should close on selection of
  /// this item.
  @Input()
  closeOnActivate = true;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleActivate(_: UIEvent): void {
    if (this.closeOnActivate) {
      this.dropdown?.close();
    }
  }

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.disposer.dispose();
  }

  @HostBinding('class.disabled')
  get isDisabled(): boolean {
    return this.disabled;
  }

  @Input()
  leadingIcon = '';

  @HostBinding('class.leading-icon')
  get hasLeadingIcon(): boolean {
    return isNotEmptyString(this.leadingIcon);
  }
}

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/frontend_misc.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { APIParamsReq, APIParamsResp, ChangePassword, DemoRequest, ImpersonateReq, ImpersonateResult, ImpersonationLink, LoggedBootstrapping, LoginForm, MaterialRequest, PostValueSettingsResp, ReconnectSessionRequest, ResetPasswordRequest, ScreenConfig, ScreenConfigRequest, ScreenConfigs, SharingTokenBootstrapping, SsoAssertion, SsoLoginResponse, SsoLogoutRequest, SsoLogoutResponse, UpdateCurrencyReq, UpdatePostValueSettingsReq, UpdateUserRequest, UserProfile } from "./frontend_misc_pb";
import { BoolValue, Empty, MethodKind, StringValue } from "@bufbuild/protobuf";
import { Forex } from "../../common/proto/common_pb";

/**
 * Misc stuff for frontend development.
 *
 * @generated from service librarian.proto.FrontendMisc
 */
export const FrontendMisc = {
  typeName: "librarian.proto.FrontendMisc",
  methods: {
    /**
     * Main login endpoint.
     *
     * On errors, it throws (eg: HTTP error codes).
     *
     * @generated from rpc librarian.proto.FrontendMisc.LogAPI
     */
    logAPI: {
      name: "LogAPI",
      I: LoginForm,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * it takes the email as a parameter.
     *
     * @generated from rpc librarian.proto.FrontendMisc.SsoLogin
     */
    ssoLogin: {
      name: "SsoLogin",
      I: StringValue,
      O: SsoLoginResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Called by the CompanionRoute's SAML login callback.
     *
     * @generated from rpc librarian.proto.FrontendMisc.SsoAssertionConsumer
     */
    ssoAssertionConsumer: {
      name: "SsoAssertionConsumer",
      I: SsoAssertion,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Called by the CompanionRoute's SAML logout callback
     *
     * @generated from rpc librarian.proto.FrontendMisc.SsoLogout
     */
    ssoLogout: {
      name: "SsoLogout",
      I: SsoLogoutRequest,
      O: SsoLogoutResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Logs out the currently logged in user if any. Invalidates their lefty cookies.
     *
     * @generated from rpc librarian.proto.FrontendMisc.LogoutAPI
     */
    logoutAPI: {
      name: "LogoutAPI",
      I: Empty,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Change the password of a user (if and only if they correctly provided their current password).
     *
     * @generated from rpc librarian.proto.FrontendMisc.ChangePasswordAPI
     */
    changePasswordAPI: {
      name: "ChangePasswordAPI",
      I: ChangePassword,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.FrontendMisc.UserProfileAPI
     */
    userProfileAPI: {
      name: "UserProfileAPI",
      I: Empty,
      O: UserProfile,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.FrontendMisc.UpdateUserAPI
     */
    updateUserAPI: {
      name: "UpdateUserAPI",
      I: UpdateUserRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Returns whether we're logged as admin or not. This can be helpful for single page apps without a
     * bootstrap.
     *
     * @generated from rpc librarian.proto.FrontendMisc.IsAdminAPI
     */
    isAdminAPI: {
      name: "IsAdminAPI",
      I: Empty,
      O: BoolValue,
      kind: MethodKind.Unary,
    },
    /**
     * Same as above for regular logged in as opposed to admin.
     *
     * @generated from rpc librarian.proto.FrontendMisc.IsLoggedAPI
     */
    isLoggedAPI: {
      name: "IsLoggedAPI",
      I: Empty,
      O: LoggedBootstrapping,
      kind: MethodKind.Unary,
    },
    /**
     * Same as above for sharing token.
     *
     * @generated from rpc librarian.proto.FrontendMisc.IsSharingTokenLoggedAPI
     */
    isSharingTokenLoggedAPI: {
      name: "IsSharingTokenLoggedAPI",
      I: Empty,
      O: SharingTokenBootstrapping,
      kind: MethodKind.Unary,
    },
    /**
     * Post a demo request (this request is allowed to be made from the https://lefty.io commercial site).
     *
     * @generated from rpc librarian.proto.FrontendMisc.RequestDemoAPI
     */
    requestDemoAPI: {
      name: "RequestDemoAPI",
      I: DemoRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.FrontendMisc.RequestMaterialAPI
     */
    requestMaterialAPI: {
      name: "RequestMaterialAPI",
      I: MaterialRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.FrontendMisc.GenerateImpersonationLink
     */
    generateImpersonationLink: {
      name: "GenerateImpersonationLink",
      I: ImpersonationLink,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * The input is an encrypted ImpersonationRequest.
     * returns the cookie to set in order to be that user or author.
     *
     * @generated from rpc librarian.proto.FrontendMisc.Impersonate
     */
    impersonate: {
      name: "Impersonate",
      I: StringValue,
      O: ImpersonateResult,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.FrontendMisc.ImpersonateV2
     */
    impersonateV2: {
      name: "ImpersonateV2",
      I: ImpersonateReq,
      O: ImpersonateResult,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.FrontendMisc.ReconnectSession
     */
    reconnectSession: {
      name: "ReconnectSession",
      I: ReconnectSessionRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * The input is the email of the user to send the link to.
     *
     * @generated from rpc librarian.proto.FrontendMisc.SendResetPasswordLinkAPI
     */
    sendResetPasswordLinkAPI: {
      name: "SendResetPasswordLinkAPI",
      I: StringValue,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Checks whether a reset password token is valid.
     *
     * @generated from rpc librarian.proto.FrontendMisc.IsResetPasswordTokenValidAPI
     */
    isResetPasswordTokenValidAPI: {
      name: "IsResetPasswordTokenValidAPI",
      I: StringValue,
      O: BoolValue,
      kind: MethodKind.Unary,
    },
    /**
     * Resets the password of a user. If valid, the user is logged in.
     *
     * @generated from rpc librarian.proto.FrontendMisc.ResetPasswordAPI
     */
    resetPasswordAPI: {
      name: "ResetPasswordAPI",
      I: ResetPasswordRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.FrontendMisc.GetCurrencies
     */
    getCurrencies: {
      name: "GetCurrencies",
      I: Empty,
      O: Forex,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.FrontendMisc.GetPostValueSettings
     */
    getPostValueSettings: {
      name: "GetPostValueSettings",
      I: Empty,
      O: PostValueSettingsResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.FrontendMisc.UpdatePostValueSettings
     */
    updatePostValueSettings: {
      name: "UpdatePostValueSettings",
      I: UpdatePostValueSettingsReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.FrontendMisc.UpdateCurrency
     */
    updateCurrency: {
      name: "UpdateCurrency",
      I: UpdateCurrencyReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.FrontendMisc.UpdateDashboardCurrency
     */
    updateDashboardCurrency: {
      name: "UpdateDashboardCurrency",
      I: UpdateCurrencyReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.FrontendMisc.GetAPIParams
     */
    getAPIParams: {
      name: "GetAPIParams",
      I: Empty,
      O: APIParamsResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.FrontendMisc.UpdateAPIParams
     */
    updateAPIParams: {
      name: "UpdateAPIParams",
      I: APIParamsReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * returns the new token.
     *
     * @generated from rpc librarian.proto.FrontendMisc.RegenBearerToken
     */
    regenBearerToken: {
      name: "RegenBearerToken",
      I: Empty,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * *
     * Update a user's screen config
     *
     * @generated from rpc librarian.proto.FrontendMisc.updateScreenConfig
     */
    updateScreenConfig: {
      name: "updateScreenConfig",
      I: ScreenConfig,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * *
     * get a user screen configs
     *
     * @generated from rpc librarian.proto.FrontendMisc.getScreenConfigs
     */
    getScreenConfigs: {
      name: "getScreenConfigs",
      I: Empty,
      O: ScreenConfigs,
      kind: MethodKind.Unary,
    },
    /**
     * *
     *  get a specific screen config
     *
     * @generated from rpc librarian.proto.FrontendMisc.getScreenConfig
     */
    getScreenConfig: {
      name: "getScreenConfig",
      I: ScreenConfigRequest,
      O: ScreenConfig,
      kind: MethodKind.Unary,
    },
  }
} as const;


// This file defines protocol buffers for persisting author audience/insights in cassandra.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/author_audience_insights.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Int64Value, Message, proto3, StringValue, Timestamp } from "@bufbuild/protobuf";
import { TimestampedInt32 } from "./common_pb";

/**
 * This message is persisted to cassandra to record the fact that a user could access an author's insights at a given
 * time.
 *
 * When this object exists in base, it is guaranteed that there is a corresponding persisted insights.
 *
 * @generated from message common.proto.AuthorAudienceSharing
 */
export class AuthorAudienceSharing extends Message<AuthorAudienceSharing> {
  /**
   * @generated from field: google.protobuf.StringValue author_id = 1;
   */
  authorId?: string;

  /**
   * @generated from field: google.protobuf.Int64Value user_id = 2;
   */
  userId?: bigint;

  /**
   * Always 12 PM UTC.
   *
   * @generated from field: google.protobuf.Timestamp insights_time = 3;
   */
  insightsTime?: Timestamp;

  constructor(data?: PartialMessage<AuthorAudienceSharing>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.AuthorAudienceSharing";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "author_id", kind: "message", T: StringValue },
    { no: 2, name: "user_id", kind: "message", T: Int64Value },
    { no: 3, name: "insights_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuthorAudienceSharing {
    return new AuthorAudienceSharing().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuthorAudienceSharing {
    return new AuthorAudienceSharing().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuthorAudienceSharing {
    return new AuthorAudienceSharing().fromJsonString(jsonString, options);
  }

  static equals(a: AuthorAudienceSharing | PlainMessage<AuthorAudienceSharing> | undefined, b: AuthorAudienceSharing | PlainMessage<AuthorAudienceSharing> | undefined): boolean {
    return proto3.util.equals(AuthorAudienceSharing, a, b);
  }
}

/**
 * @generated from message common.proto.AuthorAudienceInsights
 */
export class AuthorAudienceInsights extends Message<AuthorAudienceInsights> {
  /**
   * Id is of the form: author_id + # insights_time's seconds (always 12 PM UTC).
   *
   * @generated from field: google.protobuf.StringValue author_id = 1;
   */
  authorId?: string;

  /**
   * Always 12 PM UTC.
   *
   * @generated from field: google.protobuf.Timestamp insights_time = 2;
   */
  insightsTime?: Timestamp;

  /**
   * we save json raw data as it came back from Facebook.
   *
   * @generated from field: google.protobuf.StringValue audience_json = 3 [deprecated = true];
   * @deprecated
   */
  audienceJson?: string;

  /**
   * @generated from field: google.protobuf.StringValue audience_age_gender_json = 5;
   */
  audienceAgeGenderJson?: string;

  /**
   * @generated from field: google.protobuf.StringValue audience_city_json = 6;
   */
  audienceCityJson?: string;

  /**
   * @generated from field: google.protobuf.StringValue audience_country_json = 7;
   */
  audienceCountryJson?: string;

  /**
   * @generated from field: google.protobuf.StringValue insights_json = 4;
   */
  insightsJson?: string;

  constructor(data?: PartialMessage<AuthorAudienceInsights>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.AuthorAudienceInsights";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "author_id", kind: "message", T: StringValue },
    { no: 2, name: "insights_time", kind: "message", T: Timestamp },
    { no: 3, name: "audience_json", kind: "message", T: StringValue },
    { no: 5, name: "audience_age_gender_json", kind: "message", T: StringValue },
    { no: 6, name: "audience_city_json", kind: "message", T: StringValue },
    { no: 7, name: "audience_country_json", kind: "message", T: StringValue },
    { no: 4, name: "insights_json", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuthorAudienceInsights {
    return new AuthorAudienceInsights().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuthorAudienceInsights {
    return new AuthorAudienceInsights().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuthorAudienceInsights {
    return new AuthorAudienceInsights().fromJsonString(jsonString, options);
  }

  static equals(a: AuthorAudienceInsights | PlainMessage<AuthorAudienceInsights> | undefined, b: AuthorAudienceInsights | PlainMessage<AuthorAudienceInsights> | undefined): boolean {
    return proto3.util.equals(AuthorAudienceInsights, a, b);
  }
}

/**
 * @generated from message common.proto.CreatorImpressions
 */
export class CreatorImpressions extends Message<CreatorImpressions> {
  /**
   * @generated from field: repeated common.proto.TimestampedInt32 impressions = 2;
   */
  impressions: TimestampedInt32[] = [];

  constructor(data?: PartialMessage<CreatorImpressions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CreatorImpressions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "impressions", kind: "message", T: TimestampedInt32, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorImpressions {
    return new CreatorImpressions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorImpressions {
    return new CreatorImpressions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorImpressions {
    return new CreatorImpressions().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorImpressions | PlainMessage<CreatorImpressions> | undefined, b: CreatorImpressions | PlainMessage<CreatorImpressions> | undefined): boolean {
    return proto3.util.equals(CreatorImpressions, a, b);
  }
}


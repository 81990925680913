import { IArcDataRow } from 'billboard.js/src/ChartInternal/data/IData';
import { YFormatter } from './charts.models';

export function buildBasicTooltipHTML(name: string, value: string): string {
  return `
      <div class="basic-tooltip"><b>${name}</b>${value}</div>
      `;
}

export function buildGroupTooltipHTML(
  values: string[],
  title?: string,
): string {
  const content = values
    .map((value) => `<div class="value">${value}</div>`)
    .join('');

  title = title ? `<div class="title">${title}</div>` : '';

  return `<div class="group-tooltip">${title}${content}</div>`;
}

export function buildPercentageTooltipHTML(
  data: IArcDataRow,
  options?: {
    showValue?: boolean;
    valueFormatter?: YFormatter;
  },
): string {
  const formatter =
    options?.valueFormatter ?? ((val: number | null): string => `${val}`);

  const ratio = (data.ratio * 100).toFixed();

  let content = '';
  if (options?.showValue) {
    const value = formatter(data.value ?? 0);

    content = `${value} (${ratio}%)`;
  } else {
    content = `${ratio}%`;
  }

  return `<div class="basic-tooltip"><b>${data.id}</b>${content}</div>`;
}

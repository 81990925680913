import { isDevMode } from '@angular/core';
import { Observable, Subject, filter, map } from 'rxjs';
import { isNotNil } from '../utils/common.helpers';
import { LeftyEvent, LeftyEventDataType } from './events.models';

type EventsMap = {
  [key: string]: LeftyEventDataType;
};

export abstract class EventBus<Events = EventsMap> {
  private _subject = new Subject<LeftyEvent<string>>();

  emit<Name extends keyof Events>(
    name: Name,
    event: Events[Name],
    args?: {
      emitter?: unknown;
    },
  ): void {
    if (isDevMode()) {
      console.log('EVENT', name, event);
    } else {
      console.log('EVENT', name);
    }

    this._subject.next({
      name: name.toString(),
      event: event as LeftyEventDataType,
      emitter: args?.emitter,
    });
  }

  on<Name extends keyof Events>(
    name: Name,
    args?: {
      filterOutEmitter?: unknown[];
    },
  ): Observable<Events[Name]> {
    let stream = this._subject.pipe(filter((event) => event.name === name));

    if (isNotNil(args?.filterOutEmitter)) {
      stream = stream.pipe(
        filter((e) => args?.filterOutEmitter?.includes(e.emitter) === false),
      );
    }

    return stream.pipe(map((e) => e.event)) as Observable<Events[Name]>;
  }
}

import {
  Axis,
  Data,
  TooltipOptions,
  LegendOptions,
  bb,
  bar,
  Grid,
} from 'billboard.js';
import { BarOptions } from 'billboard.js/types/options.shape';
import {
  buildAxisOptions,
  buildBarOptions,
  buildBillboardData,
} from '../charts.helpers';
import { DataSet } from '../charts.models';
import { buildGroupTooltipHTML } from '../tooltip.helpers';
import { LeftyBarOptions } from './bar.models';

function _buildBillboardData(data: DataSet[], options: LeftyBarOptions): Data {
  const d = buildBillboardData(bar(), data);

  if (options.categories) {
    d.x = 'x';
    d.columns = [['x', ...options.categories]];

    for (const ds of data) {
      d.columns.push([ds.name, ...ds.values]);
    }
  }

  if (options.withLabels) {
    d.labels = {
      format: options.yFormatter,
    };
  }

  return d;
}

function defaultValueFormatter(
  value: number | Date | null,
  name?: string,
): string {
  return `${value} ${name ?? ''}`;
}

function _buildTooltipOptions(options: LeftyBarOptions): TooltipOptions {
  const valueFormatter =
    options.yTooltipFormatter ?? options.yFormatter ?? defaultValueFormatter;

  if (options.categories) {
    return {
      contents: (c): string => {
        const data = c[0];
        const categories = options.categories ?? [];
        return buildGroupTooltipHTML(
          c.map((d) => valueFormatter(d.value, d.id)),
          categories[data.index],
        );
      },
    };
  }

  return {
    contents: (
      c,
      defaultTitleFormat: (x: Date | number | string) => number | string,
    ): string => {
      const titleFormatter =
        options.xTooltipFormatter ?? options.xFormatter ?? defaultTitleFormat;

      const data = c[0];
      return buildGroupTooltipHTML(
        c.map((d) => valueFormatter(d.value, d.name)),
        titleFormatter(data.index, data.name).toString(),
      );
    },
  };
}

function _buildAxisOptions(
  dataSets: DataSet[],
  options: LeftyBarOptions,
): Axis {
  const axis = buildAxisOptions(dataSets, {
    xFormat: options.xFormatter,
    yFormat: options.yFormatter,
  });

  axis.x = {
    ...axis.x,
    type: options.categories ? 'category' : undefined,
  };

  axis.y = {
    ...axis.y,
    show: !options.categories,
  };

  return axis;
}

function _buildBarOptions(data: Data, options: LeftyBarOptions): BarOptions {
  if (options.categories) {
    return buildBarOptions({
      ratio: 0.9,
      width: options.barWidth,
      //space: 0.3,
      padding: options.barPadding,
      radius: options.config?.radius,
    });
  }
  let length = 0;
  if (data.columns?.length) {
    length = data.columns[0].length;
  }

  return buildBarOptions({
    ratio: 1 / length,
    width: options.barWidth,
    padding: options.barPadding,
    radius: options.config?.radius,
  });
}

function _buildLegendOptions(): LegendOptions {
  return {
    show: false,
  };
}

function _buildGridOptions(options: LeftyBarOptions): Grid {
  return {
    y: {
      show: options.withGrid,
    },
  };
}

export function generateBarChart(
  bindto: HTMLElement,
  dataSets: DataSet[],
  options: LeftyBarOptions,
): bb.Chart {
  const data = _buildBillboardData(dataSets, options);
  const axis = _buildAxisOptions(dataSets, options);
  const tooltip = _buildTooltipOptions(options);
  const barCfg = _buildBarOptions(data, options);
  const legend = _buildLegendOptions();
  const grid = _buildGridOptions(options);

  if (!options.withGrid) {
    bindto.classList.add('no-grid');
  }

  return bb.generate({
    bindto,
    tooltip,
    axis,
    grid,
    bar: barCfg,
    padding: options.padding,
    size: options.size,
    data,
    legend,
  });
}

import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianGifts } from '@frontend2/proto/librarian/proto/gifts_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianGiftsClient = PromiseClient<typeof LibrarianGifts>;

export const LibrarianGiftsClientProvider = new InjectionToken(
  'LibrarianGiftsClient',
  {
    factory: (): LibrarianGiftsClient =>
      createPromiseClient(LibrarianGifts, inject(GRPC_TRANSPORT)),
  },
);

export function injectLibrarianGiftsClient(): LibrarianGiftsClient {
  return inject(LibrarianGiftsClientProvider);
}

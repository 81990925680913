<div
  class="selector-container"
  #selectorContainer
>
  <div class="and-or-selector">
    <lefty-operator-chip
      [(value)]="value"
      [dark]="true"
      [editable]="editable()"
    ></lefty-operator-chip>
  </div>

  <div
    #branchesContainer
    class="branch-container"
    [class.compact]="compact()"
  >
    <ng-content select="[branch]"></ng-content>
  </div>
</div>

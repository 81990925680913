import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { API_HOST, GrpcModule, LeftyAuthModule } from '@frontend2/api';
import {
  DISABLE_IMAGE_DEFER_VIEWPORT,
  LeftyEventsBus,
  LeftyEventsModule,
  UiModule,
} from '@frontend2/ui';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    UiModule,
    LeftyEventsModule.forRoot(LeftyEventsBus),
    RouterModule.forRoot(appRoutes),
    GrpcModule,
    LeftyAuthModule,
  ],
  providers: [
    { provide: API_HOST, useValue: environment.apiHost },
    {
      provide: DISABLE_IMAGE_DEFER_VIEWPORT,
      useValue: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class EpsonAppModule {}

import { APP_INITIALIZER, NgModule, inject } from '@angular/core';

import { isNotEmptyString } from '@frontend2/core';
import { LoggedBootstrapping } from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import { GrpcModule } from '../grpc.service';
import { LeftyAuthBloc } from './auth.bloc';
import {
  DEFAULT_HINT,
  SPACE_PATH,
  WorkspaceAuth,
  parseWorkspaceHint,
} from './workspace.interceptor';

function parseInitialWorkspaceHint(): number {
  const url = window.location.hash;
  const segments = url.split('/').filter(isNotEmptyString);

  if (
    segments.length >= 3 &&
    segments[0] === '#' &&
    segments[1] === SPACE_PATH
  ) {
    return parseWorkspaceHint(segments[2]);
  }

  return DEFAULT_HINT;
}

// to execute this correctly, Router module must be configure as follow
// ```TS
// RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledNonBlocking' }),
// ```
//
// Or router will trigger navigation (and Guard) before the app get initilized
function onAppInit(auth: LeftyAuthBloc): () => Promise<LoggedBootstrapping> {
  const workspaceAuth = inject(WorkspaceAuth);

  // Router does not exist during app initialization
  // we need manually parse workspace hint in URL
  // to use it for the first is_logged request
  workspaceAuth.hint = parseInitialWorkspaceHint();

  return () => auth.checkIsLogged();
}

@NgModule({
  imports: [GrpcModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: onAppInit,
      multi: true,
      deps: [LeftyAuthBloc],
    },
  ],
})
export class LeftyAuthModule {}

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  model,
  NgZone,
  Optional,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { DateFormat, formatDate, isNil, isNotNil } from '@frontend2/core';
import { Placement } from '@popperjs/core';
import AirDatepicker from 'air-datepicker';
import { LeftyFormValueBase } from '../form';
import {
  loadAirDatepickerLocale,
  preventAutoDismissForNode,
} from './lefty-date-picker.helpers';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';
import { LeftyIconComponent } from '../icon/icon.component';
import { NgClass, NgIf } from '@angular/common';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-date-picker',
  templateUrl: './lefty-date-picker.component.html',
  styleUrls: ['./shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyFormComponent,
    NgClass,
    NgIf,
    LeftyIconComponent,
    LeftyPopupComponent,
  ],
})
export class LeftyDatePickerComponent
  extends LeftyFormValueBase<Date | undefined>
  implements AfterViewInit
{
  constructor(
    readonly zone: NgZone,
    @Self() @Optional() ngControl?: NgControl,
  ) {
    super(undefined, ngControl);
    this.watch(this.valueChange, {
      next: this._handleExternalChange.bind(this),
    });
  }

  private datepicker?: AirDatepicker;

  readonly autoDismissNodeValidator = preventAutoDismissForNode;

  @ViewChild('calendar')
  calendarElementRef?: ElementRef;

  @Input()
  popupPlacement: Placement = 'bottom-start';

  @Input()
  dateFormat: DateFormat = 'mediumDate';

  @Input()
  placeholder = $localize`Select date`;

  get formattedDate(): string {
    return formatDate(this.date, this.dateFormat);
  }

  private _minDate?: Date | undefined;

  public get minDate(): Date | undefined {
    return this._minDate;
  }

  @Input()
  public set minDate(value: Date | undefined) {
    this._minDate = value;

    this.zone.runOutsideAngular(() =>
      this.datepicker?.update({ minDate: this.minDate }, { silent: true }),
    );
  }

  private _maxDate?: Date | undefined;

  public get maxDate(): Date | undefined {
    return this._maxDate;
  }

  @Input()
  public set maxDate(value: Date | undefined) {
    this._maxDate = value;
    this.zone.runOutsideAngular(() =>
      this.datepicker?.update({ maxDate: this.maxDate }, { silent: true }),
    );
  }

  @Input()
  buttonClass = '';

  @Input()
  clearable = true;

  readonly popupVisible = model(false);

  clear(): void {
    this.handleDateChange(undefined);
  }

  get date(): Date | undefined {
    return this.value;
  }

  handleDateChange(val: Date | undefined): void {
    if (
      isNotNil(val) &&
      isNotNil(this.value) &&
      val.getTime() === this.value.getTime()
    ) {
      return;
    }

    if (this.clearable === false && isNil(val)) {
      this.zone.runOutsideAngular(() => {
        if (isNotNil(this.value)) {
          this.datepicker?.selectDate(this.value);
        }
      });
      return;
    }

    this.handleValueChange(val);
  }

  get hasDate(): boolean {
    return isNotNil(this.date);
  }

  @HostBinding('class.empty')
  get isEmpty(): boolean {
    return this.hasDate === false;
  }

  private initDatepicker(): void {
    this.zone.runOutsideAngular(async () => {
      const locale = await loadAirDatepickerLocale();

      this.datepicker = new AirDatepicker(
        this.calendarElementRef?.nativeElement,
        {
          selectedDates: isNotNil(this.date) ? [this.date] : [],
          inline: true,
          minDate: this.minDate,
          maxDate: this.maxDate,
          locale: locale,
          navTitles: {
            days: 'MMMM, yyyy',
          },
          onSelect: ({ date }): void => {
            this.zone.run(() => this.handleDateChange(date as Date));
          },
        },
      );
    });
  }

  ngAfterViewInit(): void {
    this.initDatepicker();
  }

  private _handleExternalChange(date: Date | undefined): void {
    this.zone.runOutsideAngular(() => {
      if (!this.datepicker) {
        return;
      }

      // silent: true won't trigger `onSelect` callback
      if (date) {
        this.datepicker.selectDate(date, { silent: true });
      } else {
        this.datepicker.clear({ silent: true });
      }
    });
  }

  writeValue(obj: unknown): void {
    if (obj instanceof Date || obj === undefined) {
      this.value = obj;
    }
  }

  onArrowIconClick(event: Event): void {
    if (this.disabled) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
}

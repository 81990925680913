import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export function initDatadog(
  applicationId: string,
  clientToken: string,
  env: string,
  options?: {
    isProduction?: boolean;
    enablePosthog?: boolean;
    release?: string;
  },
): void {
  const site = 'datadoghq.eu';

  datadogRum.init({
    applicationId,
    clientToken,
    site,
    service: window.location.hostname,
    env,
    version: options?.release,
    sessionSampleRate: 100,
    sessionReplaySampleRate: options?.isProduction ? 30 : 100,
    traceSampleRate: options?.isProduction ? 30 : 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [
      'https://api.lefty.io',
      'https://api-staging.lefty.io',
    ],
    trackViewsManually: true,
    trackSessionAcrossSubdomains: true,
  });

  datadogLogs.init({
    clientToken,
    site,
    service: window.location.hostname,
    env,
    version: options?.release,
    forwardErrorsToLogs: false,
    forwardConsoleLogs: 'all',
    sessionSampleRate: 100,
    trackSessionAcrossSubdomains: true,
  });
}

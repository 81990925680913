import {
  Axis,
  Data,
  TooltipOptions,
  LegendOptions,
  bb,
  donut,
} from 'billboard.js';
import { DonutOptions } from 'billboard.js/types/options.shape';
import { buildAxisOptions, buildBillboardData } from '../charts.helpers';
import { DataSet } from '../charts.models';
import { buildPercentageTooltipHTML } from '../tooltip.helpers';
import { LeftyDonutOptions } from './donut.models';

import { IArcDataRow } from 'billboard.js/src/ChartInternal/data/IData';

function _buildBillboardData(data: DataSet[]): Data {
  return buildBillboardData(donut(), data);
}

function _buildTooltipOptions(options?: LeftyDonutOptions): TooltipOptions {
  return {
    contents: (c: unknown[]) =>
      buildPercentageTooltipHTML(c[0] as IArcDataRow, {
        showValue: options?.showValueInTooltip,
        valueFormatter: options?.yTooltipFormatter ?? options?.yFormatter,
      }),
  };
}

function _buildAxisOptions(
  dataSets: DataSet[],
  options?: LeftyDonutOptions,
): Axis {
  const axis = buildAxisOptions(dataSets, {
    yFormat: options?.yFormatter,
  });

  return axis;
}

function _buildDonutOptions(options?: LeftyDonutOptions): DonutOptions {
  return (
    options?.config ?? {
      label: { show: false },
      width: 22,
    }
  );
}

function _buildLegendOptions(): LegendOptions {
  return {
    show: false,
  };
}

export function generateDonutChart(
  bindto: HTMLElement,
  dataSets: DataSet[],
  options?: LeftyDonutOptions,
): bb.Chart {
  const data = _buildBillboardData(dataSets);
  const axis = _buildAxisOptions(dataSets, options);
  const tooltip = _buildTooltipOptions(options);
  const donutCfg = _buildDonutOptions(options);
  const legend = _buildLegendOptions();

  return bb.generate({
    bindto,
    tooltip,
    axis,
    donut: donutCfg,
    padding: options?.padding,
    size: options?.size,
    data,
    legend,
  });
}

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/audience.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AudienceReportRequest, AudienceReportResponse, GetAudienceAsFileRequest, StartAudienceReportRequest, StartAudienceReportResponse } from "./audience_pb";
import { MethodKind } from "@bufbuild/protobuf";
import { FileProto } from "../../common/proto/common_pb";

/**
 * Service to manage reports for influencers profiles.
 *
 * @generated from service librarian.proto.LibrarianAudience
 */
export const LibrarianAudience = {
  typeName: "librarian.proto.LibrarianAudience",
  methods: {
    /**
     * Retrieves the audience report for a given influencer, if any exists. Else the response will signify that a report
     * is not ready yet and the customer is able to request its generation by using the call below.
     *
     * The input is the influencer's id.
     *
     * @generated from rpc librarian.proto.LibrarianAudience.GetAudienceReportV2
     */
    getAudienceReportV2: {
      name: "GetAudienceReportV2",
      I: AudienceReportRequest,
      O: AudienceReportResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAudience.GetAudienceReportForCastingCollaborator
     */
    getAudienceReportForCastingCollaborator: {
      name: "GetAudienceReportForCastingCollaborator",
      I: AudienceReportRequest,
      O: AudienceReportResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAudience.StartPreparingAudienceReport
     */
    startPreparingAudienceReport: {
      name: "StartPreparingAudienceReport",
      I: StartAudienceReportRequest,
      O: StartAudienceReportResponse,
      kind: MethodKind.Unary,
    },
    /**
     * TODO(mateo) remove when front has been migrated
     * Downloads the audience report as a CSV file.
     *
     * @generated from rpc librarian.proto.LibrarianAudience.GetAudienceReportAsFile
     * @deprecated
     */
    getAudienceReportAsFile: {
      name: "GetAudienceReportAsFile",
      I: GetAudienceAsFileRequest,
      O: FileProto,
      kind: MethodKind.Unary,
    },
    /**
     * Downloads the audience report as a XLSX file.
     *
     * @generated from rpc librarian.proto.LibrarianAudience.GetAudienceReportAsExcelFile
     */
    getAudienceReportAsExcelFile: {
      name: "GetAudienceReportAsExcelFile",
      I: GetAudienceAsFileRequest,
      O: FileProto,
      kind: MethodKind.Unary,
    },
  }
} as const;


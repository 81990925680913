// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/user_role.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Permission } from "../../common/proto/authentication_pb";

/**
 * @generated from message librarian.proto.UserRolePb
 */
export class UserRolePb extends Message<UserRolePb> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: repeated Permission permissions = 3;
   */
  permissions: Permission[] = [];

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  constructor(data?: PartialMessage<UserRolePb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UserRolePb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "permissions", kind: "enum", T: proto3.getEnumType(Permission), repeated: true },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserRolePb {
    return new UserRolePb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserRolePb {
    return new UserRolePb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserRolePb {
    return new UserRolePb().fromJsonString(jsonString, options);
  }

  static equals(a: UserRolePb | PlainMessage<UserRolePb> | undefined, b: UserRolePb | PlainMessage<UserRolePb> | undefined): boolean {
    return proto3.util.equals(UserRolePb, a, b);
  }
}

/**
 * @generated from message librarian.proto.UserWithRole
 */
export class UserWithRole extends Message<UserWithRole> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.UserRolePb user_role = 2;
   */
  userRole?: UserRolePb;

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: repeated Permission user_permissions = 4;
   */
  userPermissions: Permission[] = [];

  /**
   * @generated from field: string first_name = 5;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 6;
   */
  lastName = "";

  constructor(data?: PartialMessage<UserWithRole>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UserWithRole";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "user_role", kind: "message", T: UserRolePb },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "user_permissions", kind: "enum", T: proto3.getEnumType(Permission), repeated: true },
    { no: 5, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserWithRole {
    return new UserWithRole().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserWithRole {
    return new UserWithRole().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserWithRole {
    return new UserWithRole().fromJsonString(jsonString, options);
  }

  static equals(a: UserWithRole | PlainMessage<UserWithRole> | undefined, b: UserWithRole | PlainMessage<UserWithRole> | undefined): boolean {
    return proto3.util.equals(UserWithRole, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetAllUserRolesResponse
 */
export class GetAllUserRolesResponse extends Message<GetAllUserRolesResponse> {
  /**
   * @generated from field: repeated librarian.proto.UserRolePb user_roles = 1;
   */
  userRoles: UserRolePb[] = [];

  constructor(data?: PartialMessage<GetAllUserRolesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetAllUserRolesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_roles", kind: "message", T: UserRolePb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAllUserRolesResponse {
    return new GetAllUserRolesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAllUserRolesResponse {
    return new GetAllUserRolesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAllUserRolesResponse {
    return new GetAllUserRolesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAllUserRolesResponse | PlainMessage<GetAllUserRolesResponse> | undefined, b: GetAllUserRolesResponse | PlainMessage<GetAllUserRolesResponse> | undefined): boolean {
    return proto3.util.equals(GetAllUserRolesResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.AssignUserToUserRoleRequest
 */
export class AssignUserToUserRoleRequest extends Message<AssignUserToUserRoleRequest> {
  /**
   * @generated from field: int64 user_role_id = 1;
   */
  userRoleId = protoInt64.zero;

  /**
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<AssignUserToUserRoleRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AssignUserToUserRoleRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_role_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AssignUserToUserRoleRequest {
    return new AssignUserToUserRoleRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AssignUserToUserRoleRequest {
    return new AssignUserToUserRoleRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AssignUserToUserRoleRequest {
    return new AssignUserToUserRoleRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AssignUserToUserRoleRequest | PlainMessage<AssignUserToUserRoleRequest> | undefined, b: AssignUserToUserRoleRequest | PlainMessage<AssignUserToUserRoleRequest> | undefined): boolean {
    return proto3.util.equals(AssignUserToUserRoleRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateUserPermissionsRequest
 */
export class UpdateUserPermissionsRequest extends Message<UpdateUserPermissionsRequest> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: repeated Permission permissions = 2;
   */
  permissions: Permission[] = [];

  constructor(data?: PartialMessage<UpdateUserPermissionsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateUserPermissionsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "permissions", kind: "enum", T: proto3.getEnumType(Permission), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateUserPermissionsRequest {
    return new UpdateUserPermissionsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateUserPermissionsRequest {
    return new UpdateUserPermissionsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateUserPermissionsRequest {
    return new UpdateUserPermissionsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateUserPermissionsRequest | PlainMessage<UpdateUserPermissionsRequest> | undefined, b: UpdateUserPermissionsRequest | PlainMessage<UpdateUserPermissionsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateUserPermissionsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetTeamMembersWithRolesResponse
 */
export class GetTeamMembersWithRolesResponse extends Message<GetTeamMembersWithRolesResponse> {
  /**
   * @generated from field: repeated librarian.proto.UserWithRole team_members = 1;
   */
  teamMembers: UserWithRole[] = [];

  constructor(data?: PartialMessage<GetTeamMembersWithRolesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetTeamMembersWithRolesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "team_members", kind: "message", T: UserWithRole, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetTeamMembersWithRolesResponse {
    return new GetTeamMembersWithRolesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetTeamMembersWithRolesResponse {
    return new GetTeamMembersWithRolesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetTeamMembersWithRolesResponse {
    return new GetTeamMembersWithRolesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetTeamMembersWithRolesResponse | PlainMessage<GetTeamMembersWithRolesResponse> | undefined, b: GetTeamMembersWithRolesResponse | PlainMessage<GetTeamMembersWithRolesResponse> | undefined): boolean {
    return proto3.util.equals(GetTeamMembersWithRolesResponse, a, b);
  }
}


import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd } from '@angular/router';
import {
  LeftyParentAppBridge,
  Observables,
  isNil,
  isNotNil,
  sleep,
} from '@frontend2/core';
import { Observable, Subject, filter, fromEvent } from 'rxjs';
import { injectRouter } from '../inject.helpers';
import { LeftyComponent, createOutput, isEscapeKey } from '../utils';
import {
  DialogContentBorder,
  DialogFooterPadding,
  DialogSize,
  KeyboardEventHandler,
} from './lefty-dialog.helpers';
import { LeftyDialogService } from './lefty-dialog.service';
import { LeftyIconComponent } from '../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lefty-dialog',
  templateUrl: 'lefty-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: LeftyDialogService,
      useExisting: LeftyDialogComponent,
    },
  ],
  standalone: true,
  imports: [NgIf, LeftyIconComponent],
})
export class LeftyDialogComponent
  extends LeftyComponent
  implements LeftyDialogService, AfterContentChecked
{
  readonly parentAppBridge = inject(LeftyParentAppBridge, { optional: true });
  readonly router = injectRouter();

  constructor(private _el: ElementRef<HTMLElement>) {
    super();

    this.escapeHandler = (event: KeyboardEvent): void =>
      this._defaultEscapeHandler(event);

    if (isNotNil(this.parentAppBridge)) {
      this.watch(this.parentAppBridge.autodismiss$, {
        next: () => this.clickBackground(),
      });
    }

    this.disposer.add(() => {
      this.visible = false;
    });

    // close dialog on navigation
    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter((e) => e instanceof NavigationEnd),
      )
      .subscribe(() => this.close());
  }

  readonly _close$ = new Subject<null>();
  readonly _open$ = new Subject<null>();
  readonly _visible$ = new Subject<boolean>();

  private _visible = false;
  private _autoDismissable = false;

  readonly $hasScrollBar = signal(false);

  dialogOffsetLeft = 0;

  @HostBinding('class')
  get hostClass(): string {
    return `lefty-dialog ${this.size}`;
  }

  @Input()
  size: DialogSize = 'medium';

  /// Function to handle escape key events from the dialog. By default it tries
  /// to close the parent modal, if any.
  @Input()
  escapeHandler: KeyboardEventHandler;

  @Input()
  @HostBinding('class.no-padding')
  noPadding = false;

  @Input()
  @HostBinding('class.no-content-padding')
  noContentPadding = false;

  @Input()
  @HostBinding('class.no-fullscreen')
  noFullscreen = false;

  @Input()
  @HostBinding('class.hide-header')
  hideHeader = false;

  @Input()
  @HostBinding('class.hide-footer')
  hideFooter = false;

  @Input()
  @HostBinding('class.align-left-footer')
  alignLeftFooter = false;

  @Input()
  hideCloseButton = false;

  //forces top and bottom border class even if the content is smaller than dialog height
  // DEPRECATED: should use `contentBorder` Input
  @Input()
  set showScrollBorder(value: boolean) {
    this.contentBorder = value ? 'visible' : 'auto';
  }

  // DEPRECATED: should use `contentBorder` Input
  @Input()
  set hideTopBorder(value: boolean) {
    this.contentBorder = value ? 'hidden' : 'auto';
  }

  @Input()
  contentBorder: DialogContentBorder = 'auto';

  // tabindex -1 is some kind of hack
  // to be able to focus a DivElement
  @HostBinding('attr.tabindex')
  tabIndex = '-1';

  @HostBinding('class.has-scroll')
  get hasScrollBar(): boolean {
    return this.$hasScrollBar();
  }

  @Input()
  footerPadding: DialogFooterPadding = 'auto';

  @HostBinding('class.footer-padding-auto')
  get isFooterPaddingAuto(): boolean {
    return this.footerPadding === 'auto';
  }

  @HostBinding('class.footer-padding-compact')
  get isFooterPaddingCompact(): boolean {
    return this.footerPadding === 'compact';
  }

  @HostBinding('class.footer-padding-normal')
  get isFooterPaddingNormal(): boolean {
    return this.footerPadding === 'normal';
  }

  @HostBinding('class.footer-padding-none')
  get isFooterPaddingNone(): boolean {
    return this.footerPadding === 'none';
  }

  @HostBinding('class.has-border')
  get hasBorder(): boolean {
    if (this.contentBorder === 'hidden') {
      return false;
    }

    if (this.contentBorder === 'visible') {
      return true;
    }

    return this.hasScrollBar;
  }

  private _contentEl?: Element;

  @ViewChild('content')
  set contentEl(contentEl: ElementRef<Element>) {
    this._contentEl = contentEl.nativeElement;

    this.disposer.add(
      fromEvent(window, 'resize').subscribe({
        next: () => this._setScrollBarClass(),
      }),
    );

    this.disposer.add(
      this.open$.subscribe({
        next: () => {
          //settimeout because we need all component to be in place before calculating the heights
          setTimeout(() => {
            this._setScrollBarClass();
          });
        },
      }),
    );
  }

  private _setScrollBarClass(): void {
    this.setState(() => {
      const el = this._contentEl;
      if (isNil(el)) {
        return;
      }
      this.$hasScrollBar.set(el.scrollHeight > el.clientHeight);
    });
  }

  private _defaultEscapeHandler(event: KeyboardEvent): void {
    event.preventDefault();
    this.close();
  }

  ngAfterContentChecked(): void {
    // new content maybe not the same size
    this._setScrollBarClass();
  }

  get autoDismissable(): boolean {
    return this._autoDismissable;
  }

  @Input()
  set autoDismissable(val: boolean) {
    this._autoDismissable = val;
  }

  @HostListener('keyup', ['$event'])
  handleKeyUp(event: KeyboardEvent): void {
    if (isEscapeKey(event.key) && this.autoDismissable) {
      this.escapeHandler(event);
    }
  }

  clickBackground(): void {
    if (this.visible && this.autoDismissable) {
      this.closeWithCloseButtonOrDismiss();
    }
  }

  get visible(): boolean {
    return this._visible;
  }

  @Input()
  @HostBinding('class.visible')
  set visible(newVisible: boolean) {
    if (this._visible !== newVisible) {
      if (newVisible === true) {
        // focus dialog element on opening
        sleep(300).then(() => {
          this._el.nativeElement.focus();
        });

        Observables.safeNext(this._open$, null);
      } else {
        Observables.safeNext(this._close$, null);
      }

      Observables.safeNext(this._visible$, newVisible);

      // If the app is inside an iframe
      // we emit event to the parent app so it can correctly display the dialog
      this.parentAppBridge?.emitDialogVisible({ visible: newVisible });
    }

    this.setState(() => (this._visible = newVisible));
  }

  @Output()
  get close$(): Observable<null> {
    return this._close$;
  }

  @Output()
  get open$(): Observable<null> {
    return this._open$;
  }

  @Output()
  get visibleChange(): Observable<boolean> {
    return this._visible$;
  }

  @Output()
  readonly closedWithCloseButtonOrDismiss$ = createOutput<void>();

  // if dialog is open, close it
  // if close, open it
  toggle(): void {
    this.visible = !this.visible;
  }

  close(): void {
    this.setState(() => (this.visible = false));
  }

  open(): void {
    this.setState(() => (this.visible = true));
  }

  closeWithCloseButtonOrDismiss(): void {
    this.close();
    this.closedWithCloseButtonOrDismiss$.next();
  }
}

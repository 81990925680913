// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/affiliation.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AffiliationPixelProto, AffiliationPixelProtoList, CreatorPoolRequestV2, DestinationUrl } from "./common_pb";
import { AddAffiliatesRequest, AddLinksRequest, AffiliationCreatorPoolResponses, AffiliationReportFilter, AffiliationReportProductsRequest, AffiliationReportProductsResponse, AffiliationReportStats, AffiliationReportTimeline, AffiliationReportTimelineRequest, ArchiveAffiliatesRequest, ArchiveLinksRequest, CheckAffiliatesNotInCampaignRequest, CheckAffiliatesNotInCampaignResponse, CheckMatchableRequest, DestinationUrlList, EditAffiliateRequest, EditLinksRequest, GetAffiliatesRequest, GetAffiliatesResponses, GetLinksRequest, GetLinksResponses, GetOrdersRequest, GetOrdersResponses, GetProgramsResponse, Program, TrackLinkRequest, TrackLinkResponse, TrackOrderRequest, UploadAffiliatesRequest } from "./affiliation_pb";
import { BoolValue, Empty, Int64Value, MethodKind, StringValue } from "@bufbuild/protobuf";

/**
 * @generated from service librarian.proto.LibrarianAffiliation
 */
export const LibrarianAffiliation = {
  typeName: "librarian.proto.LibrarianAffiliation",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.AutocompleteCreatorsAPIV2
     */
    autocompleteCreatorsAPIV2: {
      name: "AutocompleteCreatorsAPIV2",
      I: CreatorPoolRequestV2,
      O: AffiliationCreatorPoolResponses,
      kind: MethodKind.Unary,
    },
    /**
     * Batch add affiliates to team
     * returns progress token.
     *
     * @generated from rpc librarian.proto.LibrarianAffiliation.AddAffiliatesAPI
     */
    addAffiliatesAPI: {
      name: "AddAffiliatesAPI",
      I: AddAffiliatesRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.EditAffiliatesAPI
     */
    editAffiliatesAPI: {
      name: "EditAffiliatesAPI",
      I: EditAffiliateRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.ArchiveAffiliateAPI
     */
    archiveAffiliateAPI: {
      name: "ArchiveAffiliateAPI",
      I: ArchiveAffiliatesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.UnarchiveAffiliateAPI
     */
    unarchiveAffiliateAPI: {
      name: "UnarchiveAffiliateAPI",
      I: ArchiveAffiliatesRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * List all affiliates for team
     *
     * @generated from rpc librarian.proto.LibrarianAffiliation.GetAffiliatesAPI
     */
    getAffiliatesAPI: {
      name: "GetAffiliatesAPI",
      I: GetAffiliatesRequest,
      O: GetAffiliatesResponses,
      kind: MethodKind.Unary,
    },
    /**
     * Batch upload affiliates to team
     * returns progress token.
     *
     * @generated from rpc librarian.proto.LibrarianAffiliation.UploadAffiliates
     */
    uploadAffiliates: {
      name: "UploadAffiliates",
      I: UploadAffiliatesRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * List all programs for team, ie default + team's specifics
     *
     * @generated from rpc librarian.proto.LibrarianAffiliation.GetProgramsAPI
     */
    getProgramsAPI: {
      name: "GetProgramsAPI",
      I: Empty,
      O: GetProgramsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Creates a new program for current user team.
     * Returns the id of the created program.
     *
     * @generated from rpc librarian.proto.LibrarianAffiliation.AddProgramAPI
     */
    addProgramAPI: {
      name: "AddProgramAPI",
      I: Program,
      O: Int64Value,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.EditProgramAPI
     */
    editProgramAPI: {
      name: "EditProgramAPI",
      I: Program,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.DeleteProgramAPI
     */
    deleteProgramAPI: {
      name: "DeleteProgramAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Restore a deleted program
     *
     * @generated from rpc librarian.proto.LibrarianAffiliation.RestoreProgramAPI
     */
    restoreProgramAPI: {
      name: "RestoreProgramAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Returns the id of the created pixel.
     *
     * @generated from rpc librarian.proto.LibrarianAffiliation.AddPixelAPI
     */
    addPixelAPI: {
      name: "AddPixelAPI",
      I: AffiliationPixelProto,
      O: AffiliationPixelProto,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.EditPixelAPI
     */
    editPixelAPI: {
      name: "EditPixelAPI",
      I: AffiliationPixelProto,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.ArchivePixelAPI
     */
    archivePixelAPI: {
      name: "ArchivePixelAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.RestorePixelAPI
     */
    restorePixelAPI: {
      name: "RestorePixelAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.GetPixelsAPI
     */
    getPixelsAPI: {
      name: "GetPixelsAPI",
      I: Empty,
      O: AffiliationPixelProtoList,
      kind: MethodKind.Unary,
    },
    /**
     * Add a link for current user team
     *
     * @generated from rpc librarian.proto.LibrarianAffiliation.AddLinksAPI
     */
    addLinksAPI: {
      name: "AddLinksAPI",
      I: AddLinksRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.EditLinksAPI
     */
    editLinksAPI: {
      name: "EditLinksAPI",
      I: EditLinksRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.ArchiveLinksAPI
     */
    archiveLinksAPI: {
      name: "ArchiveLinksAPI",
      I: ArchiveLinksRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.UnarchiveLinksAPI
     */
    unarchiveLinksAPI: {
      name: "UnarchiveLinksAPI",
      I: ArchiveLinksRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.GetLinksAPI
     */
    getLinksAPI: {
      name: "GetLinksAPI",
      I: GetLinksRequest,
      O: GetLinksResponses,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.GetCreatorLinksAPI
     */
    getCreatorLinksAPI: {
      name: "GetCreatorLinksAPI",
      I: GetLinksRequest,
      O: GetLinksResponses,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.AddDestinationUrlAPI
     */
    addDestinationUrlAPI: {
      name: "AddDestinationUrlAPI",
      I: DestinationUrl,
      O: Int64Value,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.EditDestinationUrlAPI
     */
    editDestinationUrlAPI: {
      name: "EditDestinationUrlAPI",
      I: DestinationUrl,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.ArchiveDestinationUrlAPI
     */
    archiveDestinationUrlAPI: {
      name: "ArchiveDestinationUrlAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.UnarchiveDestinationUrlAPI
     */
    unarchiveDestinationUrlAPI: {
      name: "UnarchiveDestinationUrlAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.ListDestinationUrlsAPI
     */
    listDestinationUrlsAPI: {
      name: "ListDestinationUrlsAPI",
      I: Empty,
      O: DestinationUrlList,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.GetOrdersAPI
     */
    getOrdersAPI: {
      name: "GetOrdersAPI",
      I: GetOrdersRequest,
      O: GetOrdersResponses,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.GetReportStats
     */
    getReportStats: {
      name: "GetReportStats",
      I: AffiliationReportFilter,
      O: AffiliationReportStats,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.GetReportTimeLine
     */
    getReportTimeLine: {
      name: "GetReportTimeLine",
      I: AffiliationReportTimelineRequest,
      O: AffiliationReportTimeline,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.GetReportTopProducts
     */
    getReportTopProducts: {
      name: "GetReportTopProducts",
      I: AffiliationReportProductsRequest,
      O: AffiliationReportProductsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAffiliation.CheckAffiliatesNotInCampaignAPI
     */
    checkAffiliatesNotInCampaignAPI: {
      name: "CheckAffiliatesNotInCampaignAPI",
      I: CheckAffiliatesNotInCampaignRequest,
      O: CheckAffiliatesNotInCampaignResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Track when an affiliation is clicked. Called from AffiliationRoutes.
     *
     * @generated from rpc librarian.proto.LibrarianAffiliation.TrackLink
     */
    trackLink: {
      name: "TrackLink",
      I: TrackLinkRequest,
      O: TrackLinkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Track incoming orders (conversion)
     *
     * @generated from rpc librarian.proto.LibrarianAffiliation.TrackOrder
     */
    trackOrder: {
      name: "TrackOrder",
      I: TrackOrderRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Check whether we could match or not a call from this browser for a Lefty conversion
     *
     * @generated from rpc librarian.proto.LibrarianAffiliation.CheckMatchable
     */
    checkMatchable: {
      name: "CheckMatchable",
      I: CheckMatchableRequest,
      O: BoolValue,
      kind: MethodKind.Unary,
    },
    /**
     * returns the bronco handle
     *
     * @generated from rpc librarian.proto.LibrarianAffiliation.RequestExportExcelTask
     */
    requestExportExcelTask: {
      name: "RequestExportExcelTask",
      I: Empty,
      O: StringValue,
      kind: MethodKind.Unary,
    },
  }
} as const;


// This file defines proto for labels.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/entity_labels.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Int32Value, Int64Value, Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { BaseEntityType, CampaignSelector, FlexibleCreatorSelector, VisualSelector } from "./common_pb";

/**
 * @generated from message librarian.proto.EntityLabel
 */
export class EntityLabel extends Message<EntityLabel> {
  /**
   * @generated from field: google.protobuf.Int64Value id = 1;
   */
  id?: bigint;

  /**
   * @generated from field: librarian.proto.BaseEntityType entity_type = 2;
   */
  entityType = BaseEntityType.UNKNOWN;

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * number of times this label has been used for entity
   *
   * @generated from field: google.protobuf.Int32Value count = 4;
   */
  count?: number;

  /**
   * @generated from field: bool archived = 5;
   */
  archived = false;

  /**
   * @generated from field: google.protobuf.Timestamp created = 6;
   */
  created?: Timestamp;

  constructor(data?: PartialMessage<EntityLabel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EntityLabel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "message", T: Int64Value },
    { no: 2, name: "entity_type", kind: "enum", T: proto3.getEnumType(BaseEntityType) },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "count", kind: "message", T: Int32Value },
    { no: 5, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "created", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityLabel {
    return new EntityLabel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityLabel {
    return new EntityLabel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityLabel {
    return new EntityLabel().fromJsonString(jsonString, options);
  }

  static equals(a: EntityLabel | PlainMessage<EntityLabel> | undefined, b: EntityLabel | PlainMessage<EntityLabel> | undefined): boolean {
    return proto3.util.equals(EntityLabel, a, b);
  }
}

/**
 * @generated from message librarian.proto.EntityLabels
 */
export class EntityLabels extends Message<EntityLabels> {
  /**
   * @generated from field: repeated librarian.proto.EntityLabel entity_labels = 1;
   */
  entityLabels: EntityLabel[] = [];

  constructor(data?: PartialMessage<EntityLabels>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EntityLabels";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "entity_labels", kind: "message", T: EntityLabel, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EntityLabels {
    return new EntityLabels().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EntityLabels {
    return new EntityLabels().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EntityLabels {
    return new EntityLabels().fromJsonString(jsonString, options);
  }

  static equals(a: EntityLabels | PlainMessage<EntityLabels> | undefined, b: EntityLabels | PlainMessage<EntityLabels> | undefined): boolean {
    return proto3.util.equals(EntityLabels, a, b);
  }
}

/**
 * @generated from message librarian.proto.SetEntityLabelsRequest
 */
export class SetEntityLabelsRequest extends Message<SetEntityLabelsRequest> {
  /**
   * @generated from oneof librarian.proto.SetEntityLabelsRequest.entity_id
   */
  entityId: {
    /**
     * @generated from field: string influencer_lefty_id = 1;
     */
    value: string;
    case: "influencerLeftyId";
  } | {
    /**
     * @generated from field: int64 campaign_id = 2;
     */
    value: bigint;
    case: "campaignId";
  } | {
    /**
     * @generated from field: string media_id = 3;
     */
    value: string;
    case: "mediaId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * will override the current tags (if it is empty, all labels are deleted)
   *
   * @generated from field: repeated int64 user_tag_ids = 4;
   */
  userTagIds: bigint[] = [];

  constructor(data?: PartialMessage<SetEntityLabelsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SetEntityLabelsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_lefty_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "entity_id" },
    { no: 2, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "entity_id" },
    { no: 3, name: "media_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "entity_id" },
    { no: 4, name: "user_tag_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SetEntityLabelsRequest {
    return new SetEntityLabelsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SetEntityLabelsRequest {
    return new SetEntityLabelsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SetEntityLabelsRequest {
    return new SetEntityLabelsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SetEntityLabelsRequest | PlainMessage<SetEntityLabelsRequest> | undefined, b: SetEntityLabelsRequest | PlainMessage<SetEntityLabelsRequest> | undefined): boolean {
    return proto3.util.equals(SetEntityLabelsRequest, a, b);
  }
}

/**
 * Add entity labels for multiple entities
 *
 * @generated from message librarian.proto.UpdateEntityLabelsRequest
 */
export class UpdateEntityLabelsRequest extends Message<UpdateEntityLabelsRequest> {
  /**
   * @generated from oneof librarian.proto.UpdateEntityLabelsRequest.selector
   */
  selector: {
    /**
     * @generated from field: librarian.proto.FlexibleCreatorSelector creator_selector = 1;
     */
    value: FlexibleCreatorSelector;
    case: "creatorSelector";
  } | {
    /**
     * @generated from field: librarian.proto.CampaignSelector campaign_selector = 2;
     */
    value: CampaignSelector;
    case: "campaignSelector";
  } | {
    /**
     * @generated from field: librarian.proto.VisualSelector visual_selector = 3;
     */
    value: VisualSelector;
    case: "visualSelector";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: repeated int64 user_tag_ids_to_add = 4;
   */
  userTagIdsToAdd: bigint[] = [];

  /**
   * will be filled by backend
   *
   * @generated from field: int64 user_id = 5;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<UpdateEntityLabelsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateEntityLabelsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_selector", kind: "message", T: FlexibleCreatorSelector, oneof: "selector" },
    { no: 2, name: "campaign_selector", kind: "message", T: CampaignSelector, oneof: "selector" },
    { no: 3, name: "visual_selector", kind: "message", T: VisualSelector, oneof: "selector" },
    { no: 4, name: "user_tag_ids_to_add", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 5, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateEntityLabelsRequest {
    return new UpdateEntityLabelsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateEntityLabelsRequest {
    return new UpdateEntityLabelsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateEntityLabelsRequest {
    return new UpdateEntityLabelsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateEntityLabelsRequest | PlainMessage<UpdateEntityLabelsRequest> | undefined, b: UpdateEntityLabelsRequest | PlainMessage<UpdateEntityLabelsRequest> | undefined): boolean {
    return proto3.util.equals(UpdateEntityLabelsRequest, a, b);
  }
}


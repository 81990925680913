// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/reports.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

// Common protos for Reports (histogram, pie charts and the likes)

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { DayDate, PostType } from "../../common/proto/common_pb";

/**
 * @generated from enum librarian.proto.ReportTimeStep
 */
export enum ReportTimeStep {
  /**
   * @generated from enum value: REPORT_STEP_AUTO = 0;
   */
  REPORT_STEP_AUTO = 0,

  /**
   * @generated from enum value: REPORT_STEP_DAY = 1;
   */
  REPORT_STEP_DAY = 1,

  /**
   * @generated from enum value: REPORT_STEP_WEEK = 2;
   */
  REPORT_STEP_WEEK = 2,

  /**
   * @generated from enum value: REPORT_STEP_MONTH = 3;
   */
  REPORT_STEP_MONTH = 3,

  /**
   * @generated from enum value: REPORT_STEP_YEAR = 4;
   */
  REPORT_STEP_YEAR = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ReportTimeStep)
proto3.util.setEnumType(ReportTimeStep, "librarian.proto.ReportTimeStep", [
  { no: 0, name: "REPORT_STEP_AUTO" },
  { no: 1, name: "REPORT_STEP_DAY" },
  { no: 2, name: "REPORT_STEP_WEEK" },
  { no: 3, name: "REPORT_STEP_MONTH" },
  { no: 4, name: "REPORT_STEP_YEAR" },
]);

/**
 * @generated from enum librarian.proto.ReportMetric
 */
export enum ReportMetric {
  /**
   * @generated from enum value: REPORT_METRIC_POSTS = 0;
   */
  POSTS = 0,

  /**
   * @generated from enum value: REPORT_METRIC_EMV = 1;
   */
  EMV = 1,

  /**
   * @generated from enum value: REPORT_METRIC_IMPRESSIONS = 2;
   */
  IMPRESSIONS = 2,

  /**
   * @generated from enum value: REPORT_METRIC_ENGAGEMENTS = 3;
   */
  ENGAGEMENTS = 3,

  /**
   * @generated from enum value: REPORT_METRIC_ENGAGEMENT_RATE = 5;
   */
  ENGAGEMENT_RATE = 5,

  /**
   * @generated from enum value: REPORT_METRIC_REACH = 4;
   */
  REACH = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ReportMetric)
proto3.util.setEnumType(ReportMetric, "librarian.proto.ReportMetric", [
  { no: 0, name: "REPORT_METRIC_POSTS" },
  { no: 1, name: "REPORT_METRIC_EMV" },
  { no: 2, name: "REPORT_METRIC_IMPRESSIONS" },
  { no: 3, name: "REPORT_METRIC_ENGAGEMENTS" },
  { no: 5, name: "REPORT_METRIC_ENGAGEMENT_RATE" },
  { no: 4, name: "REPORT_METRIC_REACH" },
]);

/**
 * @generated from message librarian.proto.ReportTimeline
 */
export class ReportTimeline extends Message<ReportTimeline> {
  /**
   * @generated from field: repeated librarian.proto.ReportTimeline.ReportTimepoint points = 1;
   */
  points: ReportTimeline_ReportTimepoint[] = [];

  /**
   * Optimal step computed by backend
   *
   * @generated from field: librarian.proto.ReportTimeStep time_step = 2;
   */
  timeStep = ReportTimeStep.REPORT_STEP_AUTO;

  constructor(data?: PartialMessage<ReportTimeline>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ReportTimeline";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "points", kind: "message", T: ReportTimeline_ReportTimepoint, repeated: true },
    { no: 2, name: "time_step", kind: "enum", T: proto3.getEnumType(ReportTimeStep) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportTimeline {
    return new ReportTimeline().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportTimeline {
    return new ReportTimeline().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportTimeline {
    return new ReportTimeline().fromJsonString(jsonString, options);
  }

  static equals(a: ReportTimeline | PlainMessage<ReportTimeline> | undefined, b: ReportTimeline | PlainMessage<ReportTimeline> | undefined): boolean {
    return proto3.util.equals(ReportTimeline, a, b);
  }
}

/**
 * @generated from message librarian.proto.ReportTimeline.ReportTimepoint
 */
export class ReportTimeline_ReportTimepoint extends Message<ReportTimeline_ReportTimepoint> {
  /**
   * @generated from field: common.proto.DayDate day_date = 3;
   */
  dayDate?: DayDate;

  /**
   * @generated from field: repeated librarian.proto.ReportTimeline.ReportTimepoint.ReportGenerikPoint generik = 2;
   */
  generik: ReportTimeline_ReportTimepoint_ReportGenerikPoint[] = [];

  constructor(data?: PartialMessage<ReportTimeline_ReportTimepoint>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ReportTimeline.ReportTimepoint";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "day_date", kind: "message", T: DayDate },
    { no: 2, name: "generik", kind: "message", T: ReportTimeline_ReportTimepoint_ReportGenerikPoint, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportTimeline_ReportTimepoint {
    return new ReportTimeline_ReportTimepoint().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportTimeline_ReportTimepoint {
    return new ReportTimeline_ReportTimepoint().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportTimeline_ReportTimepoint {
    return new ReportTimeline_ReportTimepoint().fromJsonString(jsonString, options);
  }

  static equals(a: ReportTimeline_ReportTimepoint | PlainMessage<ReportTimeline_ReportTimepoint> | undefined, b: ReportTimeline_ReportTimepoint | PlainMessage<ReportTimeline_ReportTimepoint> | undefined): boolean {
    return proto3.util.equals(ReportTimeline_ReportTimepoint, a, b);
  }
}

/**
 * @generated from message librarian.proto.ReportTimeline.ReportTimepoint.ReportGenerikPoint
 */
export class ReportTimeline_ReportTimepoint_ReportGenerikPoint extends Message<ReportTimeline_ReportTimepoint_ReportGenerikPoint> {
  /**
   * @generated from field: common.proto.PostType post_type = 1;
   */
  postType = PostType.POST_TYPE_UNDEFINED;

  /**
   * @generated from field: int64 value = 2;
   */
  value = protoInt64.zero;

  constructor(data?: PartialMessage<ReportTimeline_ReportTimepoint_ReportGenerikPoint>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ReportTimeline.ReportTimepoint.ReportGenerikPoint";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType) },
    { no: 2, name: "value", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportTimeline_ReportTimepoint_ReportGenerikPoint {
    return new ReportTimeline_ReportTimepoint_ReportGenerikPoint().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportTimeline_ReportTimepoint_ReportGenerikPoint {
    return new ReportTimeline_ReportTimepoint_ReportGenerikPoint().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportTimeline_ReportTimepoint_ReportGenerikPoint {
    return new ReportTimeline_ReportTimepoint_ReportGenerikPoint().fromJsonString(jsonString, options);
  }

  static equals(a: ReportTimeline_ReportTimepoint_ReportGenerikPoint | PlainMessage<ReportTimeline_ReportTimepoint_ReportGenerikPoint> | undefined, b: ReportTimeline_ReportTimepoint_ReportGenerikPoint | PlainMessage<ReportTimeline_ReportTimepoint_ReportGenerikPoint> | undefined): boolean {
    return proto3.util.equals(ReportTimeline_ReportTimepoint_ReportGenerikPoint, a, b);
  }
}

/**
 * @generated from message librarian.proto.PostTypeSliceData
 */
export class PostTypeSliceData extends Message<PostTypeSliceData> {
  /**
   * Total by period (step) by post type
   *
   * @generated from field: librarian.proto.ReportTimeline time_line = 1;
   */
  timeLine?: ReportTimeline;

  /**
   * @generated from field: repeated librarian.proto.PostTypeSliceData.ReportPostSlice posts_slices = 2;
   */
  postsSlices: PostTypeSliceData_ReportPostSlice[] = [];

  constructor(data?: PartialMessage<PostTypeSliceData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PostTypeSliceData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time_line", kind: "message", T: ReportTimeline },
    { no: 2, name: "posts_slices", kind: "message", T: PostTypeSliceData_ReportPostSlice, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostTypeSliceData {
    return new PostTypeSliceData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostTypeSliceData {
    return new PostTypeSliceData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostTypeSliceData {
    return new PostTypeSliceData().fromJsonString(jsonString, options);
  }

  static equals(a: PostTypeSliceData | PlainMessage<PostTypeSliceData> | undefined, b: PostTypeSliceData | PlainMessage<PostTypeSliceData> | undefined): boolean {
    return proto3.util.equals(PostTypeSliceData, a, b);
  }
}

/**
 * Total by post type
 *
 * @generated from message librarian.proto.PostTypeSliceData.ReportPostSlice
 */
export class PostTypeSliceData_ReportPostSlice extends Message<PostTypeSliceData_ReportPostSlice> {
  /**
   * @generated from field: common.proto.PostType post_type = 1;
   */
  postType = PostType.POST_TYPE_UNDEFINED;

  /**
   * @generated from field: int64 total = 2;
   */
  total = protoInt64.zero;

  constructor(data?: PartialMessage<PostTypeSliceData_ReportPostSlice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PostTypeSliceData.ReportPostSlice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType) },
    { no: 2, name: "total", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostTypeSliceData_ReportPostSlice {
    return new PostTypeSliceData_ReportPostSlice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostTypeSliceData_ReportPostSlice {
    return new PostTypeSliceData_ReportPostSlice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostTypeSliceData_ReportPostSlice {
    return new PostTypeSliceData_ReportPostSlice().fromJsonString(jsonString, options);
  }

  static equals(a: PostTypeSliceData_ReportPostSlice | PlainMessage<PostTypeSliceData_ReportPostSlice> | undefined, b: PostTypeSliceData_ReportPostSlice | PlainMessage<PostTypeSliceData_ReportPostSlice> | undefined): boolean {
    return proto3.util.equals(PostTypeSliceData_ReportPostSlice, a, b);
  }
}


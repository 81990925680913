import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { Disposable } from './async';
import { MapStringString } from './utils';

/**
 * @deprecated
 * @see use `Bloc` from frontend2:ui instead
 */
export class BlocState<State> extends Disposable {
  private readonly _stateSubject: BehaviorSubject<State>;

  /// Helper to listen for a stream
  /// and automatically dispose subscription when Bloc is destroy
  protected watch<T>(
    stream: Observable<T>,
    observer: Partial<Observer<T>>,
  ): void {
    this.disposer.addStreamSubscription(stream.subscribe(observer));
  }

  constructor(initialState: State) {
    super();

    this._stateSubject = new BehaviorSubject<State>(initialState);

    this.disposer.addFunction(() => this._stateSubject.complete());
  }

  get state$(): Observable<State> {
    return this._stateSubject;
  }

  get currentState(): State {
    return this._stateSubject.getValue();
  }

  updateState(newValue: State): State {
    if (!this.disposed) {
      this._stateSubject.next(newValue);
    }

    return this.currentState;
  }

  throwError(error: unknown): void {
    this._stateSubject.error(error);
  }
}

export type RouteParams = {
  readonly parameters: MapStringString;
  readonly queryParameters: MapStringString;
};

/// Minimal state to implements a route
export type RouteViewState<Request, ViewState> = {
  readonly routeParams: RouteParams;

  /// All data necessary to do your request
  readonly request: Request;

  /// Result of you request to the server
  readonly viewState: ViewState;

  /// Is the route currently loading ? doing any request ?
  readonly loading: boolean;

  /// Is the route currently active
  readonly active: boolean;

  /// If the RouteBloc build method throw an error
  /// it will be stored here
  readonly error?: unknown;
};

export function buildDefaultRouteState<Request, ViewState>(
  request: Request,
  viewState: ViewState,
): RouteViewState<Request, ViewState> {
  return {
    request,
    viewState,
    routeParams: {
      parameters: {},
      queryParameters: {},
    },
    loading: false,
    active: false,
  };
}

export type RouteViewArgs<Request, ViewState> = {
  initialState: RouteViewState<Request, ViewState>;
  resetViewOnRequestChange?: boolean;
};

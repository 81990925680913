import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Networks } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { RendersValue } from '../dynamic-component.component';
import { NetworkIconComponent } from '../icon/network.component';

@Component({
  selector: 'network-dropdown-item',
  template: `
    <network-icon [network]="network"></network-icon>
    <span class="label">{{ label }}</span>
  `,
  styleUrls: ['./network-dropdown-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NetworkIconComponent],
})
export class NetworkDropdownItemComponent implements RendersValue<Network> {
  @Input()
  network = Network.NETWORK_UNKNOWN;

  setValue(newValue?: Network): void {
    this.network = newValue ?? Network.NETWORK_UNKNOWN;
  }

  get label(): string {
    return Networks.readable(this.network);
  }
}

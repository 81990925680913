import { Timestamp } from '@bufbuild/protobuf';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { TimeRestriction } from '@frontend2/proto/librarian/proto/common_pb';
import {
  Brand,
  BrandSummary,
  CWSetMinimal,
  ReportDetails,
  ReportPriority,
  ReportSummary,
  ReportType,
  Summary,
  SummaryAndNetwork,
  SummaryAndType,
  SummarySplit,
  SummaryType,
} from '@frontend2/proto/librarian/proto/competitive_watch_pb';
import { isAfterDate } from '../../times';
import { isNil, isNotNil } from '../../utils/common.helpers';

export function readableReportPriority(val: ReportPriority): string {
  switch (val) {
    case ReportPriority.VERY_LOW:
      return $localize`Very low`;
    case ReportPriority.LOW:
      return $localize`Low`;
    case ReportPriority.MEDIUM:
      return $localize`Medium`;
    case ReportPriority.HIGH:
      return $localize`High`;
    case ReportPriority.VERY_HIGH:
      return $localize`Very high`;
    default:
      return $localize`Unknown`;
  }
}

export function isGhostReportDetails(obj: ReportDetails): boolean {
  return obj.reportId === BigInt(0);
}

export function isGhostSummaryAndType(obj: SummaryAndType): boolean {
  return obj.equals(new SummaryAndType());
}

export function isGhostReportSummary(obj: ReportSummary): boolean {
  return obj.reportId === BigInt(0);
}

export function isGhostBrand(obj?: Brand): boolean {
  return isNil(obj) || obj.brandMarketId === BigInt(0);
}

export function isGhostBrandSummary(obj: BrandSummary): boolean {
  return isGhostBrand(obj.brand);
}

export function readableReportType(val: ReportType): string {
  switch (val) {
    case ReportType.REPORT_EMV:
      return $localize`EMV`;
    case ReportType.REPORT_IMPRESSIONS:
      return $localize`Impressions`;
    case ReportType.REPORT_EMV_SPONSORED:
      return $localize`EMV sponsored`;
    case ReportType.REPORT_POST_COUNT:
      return $localize`Posts`;
    case ReportType.REPORT_PROFILE_COUNT:
      return $localize`Nb. Influencers`;
    case ReportType.REPORT_UNIQUE_COUNT:
      return $localize`Unique mentions`;
    case ReportType.REPORT_AMBASSADOR_COUNT:
      return $localize`Ambassadors`;
    default:
      return '';
  }
}

function _computeTotalSummary(a: Summary, b: Summary): Summary {
  return new Summary({
    emv: a.emv + b.emv,
    ambassadorsCount: a.ambassadorsCount + b.ambassadorsCount,
    uniqueCount: a.uniqueCount + b.uniqueCount,
    postCount: a.postCount + b.postCount,
    profileCount: a.profileCount + b.profileCount,
    impressions: a.impressions + b.impressions,
  });
}

export function computeTotalSummary(
  summaries: (Summary | SummaryAndType | SummaryAndNetwork)[],
): Summary {
  const sanitizedSummaries = summaries.map((s) => {
    if (s instanceof Summary) {
      return s;
    }

    return s.summary ?? new Summary();
  });

  return sanitizedSummaries.reduce(_computeTotalSummary, new Summary());
}

export function getSummaryValueFor(summary: Summary, type: ReportType): number {
  switch (type) {
    case ReportType.REPORT_EMV:
      return Number(summary.emv);
    case ReportType.REPORT_IMPRESSIONS:
      return Number(summary.impressions);
    case ReportType.REPORT_AMBASSADOR_COUNT:
      return summary.ambassadorsCount;
    case ReportType.REPORT_POST_COUNT:
      return summary.postCount;
    case ReportType.REPORT_PROFILE_COUNT:
      return summary.profileCount;
    case ReportType.REPORT_UNIQUE_COUNT:
      return summary.uniqueCount;
    default:
      return 0;
  }
}

export function getBrandSummarySplit(brand: BrandSummary): SummarySplit {
  return brand.summarySplit ?? new SummarySplit();
}

export function getBrandSummaryAndTiers(brand: BrandSummary): SummaryAndType[] {
  return getBrandSummarySplit(brand).summaryAndType ?? [];
}

export function getBrandSummaryAndNetworks(
  brand: BrandSummary,
): SummaryAndNetwork[] {
  return getBrandSummarySplit(brand).summaryAndNetwork ?? [];
}

export function getBrandTierSummary(
  brand: BrandSummary,
  tier: SummaryType,
): Summary {
  return (
    getBrandSummaryAndTiers(brand).find((s) => s.summaryType === tier)
      ?.summary ?? new Summary()
  );
}

export function getBrandTotalTiersValueFor(
  brand: BrandSummary,
  reportType: ReportType,
): number {
  const totalSummary = computeTotalSummary(getBrandSummaryAndTiers(brand));
  return getSummaryValueFor(totalSummary, reportType);
}

export function getBrandNetworkSummary(
  brand: BrandSummary,
  network: Network,
): Summary {
  return (
    getBrandSummaryAndNetworks(brand).find((s) => s.network === network)
      ?.summary ?? new Summary()
  );
}

export function getBrandTotalNetworkValueFor(
  brand: BrandSummary,
  reportType: ReportType,
): number {
  const totalSummary = computeTotalSummary(getBrandSummaryAndNetworks(brand));
  return getSummaryValueFor(totalSummary, reportType);
}

export function readableSummaryType(
  val: SummaryType,
  args?: { short?: boolean },
): string {
  if (SummaryType.TOTAL === val) {
    return $localize`Total`;
  }

  if (args?.short) {
    switch (val) {
      case SummaryType.NANO:
        return $localize`Nano`;
      case SummaryType.MICRO:
        return $localize`Micro`;
      case SummaryType.MACRO:
        return $localize`Mid`;
      case SummaryType.TOP:
        return $localize`Macro`;
      case SummaryType.MEGA:
        return $localize`Mega`;
      default:
        return '';
    }
  }

  return $localize`${readableSummaryType(val, { short: true })} Influencers`;
}

export interface InfluencerTierMinMax {
  readonly type: SummaryType;
  readonly min: number;
  readonly max: number;
}

export function createInfluencerTierMinMax(
  args?: Partial<InfluencerTierMinMax>,
): InfluencerTierMinMax {
  return {
    type: args?.type ?? SummaryType.TOTAL,
    min: args?.min ?? 0,
    max: args?.max ?? 0,
  };
}

export function getReportMinExpiryDate(
  report: ReportDetails | ReportSummary,
): Timestamp | undefined {
  const { expiryDate, end } = report;

  if (isNotNil(expiryDate) && isNotNil(end)) {
    return isAfterDate(expiryDate, end) ? end : expiryDate;
  }

  if (isNil(expiryDate) && isNotNil(end)) {
    return end;
  }

  if (isNil(end) && isNotNil(expiryDate)) {
    return expiryDate;
  }

  return;
}

export function getCWReportDateRange(
  report: ReportDetails | ReportSummary,
): TimeRestriction {
  return new TimeRestriction({
    start: report.start,
    end: getReportMinExpiryDate(report),
  });
}

export function isGhostCWSet(obj: CWSetMinimal): boolean {
  return obj.setId === BigInt(0);
}

<lefty-form
  [label]="label"
  [required]="required"
  [optional]="optional"
  [helpText]="helpText"
  [externalHelpLink]="externalHelpLink"
  [helpLink]="helpLink"
  [hintText]="hintText"
  [errorMessage]="errorMessage"
  [tooltip]="tooltip"
  [large]="large"
  [topHintText]="topHintText"
>
  <button
    [ngClass]="buttonClass"
    focusableElement
    [class.active]="popupVisible"
    class="selector-btn"
    type="button"
    [disabled]="disabled"
    (focus)="handleFocus($event)"
    (blur)="handleBlur($event)"
    #button
    (keydown.enter)="handleKeyboardTrigger($event)"
    (keydown.space)="handleKeyboardTrigger($event)"
    (keydown.arrowup)="handleUpKey($event)"
    (keydown.arrowdown)="handleDownKey($event)"
    (keypress)="activateItemOnKey($event)"
    (click)="onButtonClick($event)"
  >
    <div
      class="button-content"
      (click)="onButtonClick($event)"
    >
      <span
        #buttonContentRef
        [hidden]="hasButtonContent() === false"
      >
        <ng-content select="[buttonContent]"> </ng-content>
      </span>

      @if (hasButtonContent() === false) {
        @if (isPlaceholderVisible) {
          <span class="placeholder empty">
            {{ placeholder }}
          </span>
        } @else if (hasFormattedValue) {
          <span class="formatted-value">{{ formattedValue }}</span>
        }
      }
    </div>

    <lefty-icon
      (click)="handleRemove($event)"
      [class.removeIcon]="isRemoveIcon"
      [icon]="iconName"
    ></lefty-icon>
  </button>

  <lefty-popup
    [ngClass]="popupClass"
    [placement]="popupPlacement"
    [matchMinSourceWidth]="matchMinSourceWidth"
    [(visible)]="popupVisible"
    [popupTrigger]="button"
  >
    <lefty-list
      (keydown.enter)="handleKeyboardTrigger($event)"
      (keydown.space)="handleKeyboardTrigger($event)"
      (keydown.arrowup)="handleUpKey($event)"
      (keydown.arrowdown)="handleDownKey($event)"
    >
      <lefty-select-dropdown-item
        *ngFor="let item of options; trackBy: optionsTrackBy"
        [tabbable]="false"
        [itemRenderer]="itemRenderer"
        [componentFactory]="componentFactory"
        [value]="item"
        [itemActive]="isActive(item)"
        [selected]="isSelected(item)"
        (trigger$)="toggle(item)"
        [closeOnActivate]="true"
        [supportMultiSelect]="isMultiSelect"
        data-cy="drop-down-item"
        [disabled]="isDisabledCheck(item)"
      >
      </lefty-select-dropdown-item>
    </lefty-list>
    <div
      footer
      *ngIf="withFooter"
    >
      <ng-content select="[footer]"></ng-content>
    </div>
  </lefty-popup>
</lefty-form>

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/user_brand.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, Int64Value, MethodKind } from "@bufbuild/protobuf";
import { GetUserBrandsResponse, UserBrandPb } from "./user_brand_pb";

/**
 * @generated from service librarian.proto.LibrarianUserBrands
 */
export const LibrarianUserBrands = {
  typeName: "librarian.proto.LibrarianUserBrands",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianUserBrands.GetBrandById
     */
    getBrandById: {
      name: "GetBrandById",
      I: Int64Value,
      O: UserBrandPb,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianUserBrands.CreateUserBrandAPI
     */
    createUserBrandAPI: {
      name: "CreateUserBrandAPI",
      I: UserBrandPb,
      O: UserBrandPb,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianUserBrands.UpdateUserBrandAPI
     */
    updateUserBrandAPI: {
      name: "UpdateUserBrandAPI",
      I: UserBrandPb,
      O: UserBrandPb,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianUserBrands.DeleteUserBrandAPI
     */
    deleteUserBrandAPI: {
      name: "DeleteUserBrandAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianUserBrands.GetUserBrandsAPI
     */
    getUserBrandsAPI: {
      name: "GetUserBrandsAPI",
      I: Empty,
      O: GetUserBrandsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;


// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/shopify.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { DoubleValue, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { ProductState, ProductVariantAttributePb } from "./products_pb";
import { CurrencyEnum } from "../../common/proto/common_pb";
import { ShopifyStorePbV2 } from "./ecommerce_stores_pb";
import { TeamPb } from "./teams_pb";

/**
 * @generated from enum librarian.proto.ShopifyWebhookTopic
 */
export enum ShopifyWebhookTopic {
  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_PRODUCTS_UPDATE = 1;
   */
  PRODUCTS_UPDATE = 1,

  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_SHOP_REDACT = 2;
   */
  SHOP_REDACT = 2,

  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_SHOP_UPDATE = 3;
   */
  SHOP_UPDATE = 3,

  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_ORDERS_UPDATED = 4;
   */
  ORDERS_UPDATED = 4,

  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_CUSTOMERS_DATA_REQUEST = 5;
   */
  CUSTOMERS_DATA_REQUEST = 5,

  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_CUSTOMERS_REDACT = 6;
   */
  CUSTOMERS_REDACT = 6,

  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_ORDERS_CANCELLED = 7;
   */
  ORDERS_CANCELLED = 7,

  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_ORDERS_DELETE = 8;
   */
  ORDERS_DELETE = 8,

  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_ORDERS_FULFILLED = 9;
   */
  ORDERS_FULFILLED = 9,

  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_PRODUCTS_DELETE = 10;
   */
  PRODUCTS_DELETE = 10,

  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_DISCOUNTS_UPDATE = 11;
   */
  DISCOUNTS_UPDATE = 11,

  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_DISCOUNTS_DELETE = 12;
   */
  DISCOUNTS_DELETE = 12,

  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_ORDERS_CREATED = 13;
   */
  ORDERS_CREATED = 13,

  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_ORDERS_PAID = 14;
   */
  ORDERS_PAID = 14,

  /**
   * @generated from enum value: SHOPIFY_WEBHOOK_TOPIC_REDEEM_CODE_REMOVED = 15;
   */
  REDEEM_CODE_REMOVED = 15,
}
// Retrieve enum metadata with: proto3.getEnumType(ShopifyWebhookTopic)
proto3.util.setEnumType(ShopifyWebhookTopic, "librarian.proto.ShopifyWebhookTopic", [
  { no: 0, name: "SHOPIFY_WEBHOOK_TOPIC_UNSPECIFIED" },
  { no: 1, name: "SHOPIFY_WEBHOOK_TOPIC_PRODUCTS_UPDATE" },
  { no: 2, name: "SHOPIFY_WEBHOOK_TOPIC_SHOP_REDACT" },
  { no: 3, name: "SHOPIFY_WEBHOOK_TOPIC_SHOP_UPDATE" },
  { no: 4, name: "SHOPIFY_WEBHOOK_TOPIC_ORDERS_UPDATED" },
  { no: 5, name: "SHOPIFY_WEBHOOK_TOPIC_CUSTOMERS_DATA_REQUEST" },
  { no: 6, name: "SHOPIFY_WEBHOOK_TOPIC_CUSTOMERS_REDACT" },
  { no: 7, name: "SHOPIFY_WEBHOOK_TOPIC_ORDERS_CANCELLED" },
  { no: 8, name: "SHOPIFY_WEBHOOK_TOPIC_ORDERS_DELETE" },
  { no: 9, name: "SHOPIFY_WEBHOOK_TOPIC_ORDERS_FULFILLED" },
  { no: 10, name: "SHOPIFY_WEBHOOK_TOPIC_PRODUCTS_DELETE" },
  { no: 11, name: "SHOPIFY_WEBHOOK_TOPIC_DISCOUNTS_UPDATE" },
  { no: 12, name: "SHOPIFY_WEBHOOK_TOPIC_DISCOUNTS_DELETE" },
  { no: 13, name: "SHOPIFY_WEBHOOK_TOPIC_ORDERS_CREATED" },
  { no: 14, name: "SHOPIFY_WEBHOOK_TOPIC_ORDERS_PAID" },
  { no: 15, name: "SHOPIFY_WEBHOOK_TOPIC_REDEEM_CODE_REMOVED" },
]);

/**
 * @generated from message librarian.proto.ConnectShopifyStoreRequest
 */
export class ConnectShopifyStoreRequest extends Message<ConnectShopifyStoreRequest> {
  /**
   * @generated from field: string store_domain = 1;
   */
  storeDomain = "";

  /**
   * list of team owners ids or the current user ids in the workspaces to be connected to the store
   *
   * @generated from field: repeated int64 user_ids = 2;
   */
  userIds: bigint[] = [];

  constructor(data?: PartialMessage<ConnectShopifyStoreRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ConnectShopifyStoreRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectShopifyStoreRequest {
    return new ConnectShopifyStoreRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectShopifyStoreRequest {
    return new ConnectShopifyStoreRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectShopifyStoreRequest {
    return new ConnectShopifyStoreRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectShopifyStoreRequest | PlainMessage<ConnectShopifyStoreRequest> | undefined, b: ConnectShopifyStoreRequest | PlainMessage<ConnectShopifyStoreRequest> | undefined): boolean {
    return proto3.util.equals(ConnectShopifyStoreRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.DisconnectShopifyStoreRequest
 */
export class DisconnectShopifyStoreRequest extends Message<DisconnectShopifyStoreRequest> {
  /**
   * @generated from field: string store_domain = 1;
   */
  storeDomain = "";

  constructor(data?: PartialMessage<DisconnectShopifyStoreRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DisconnectShopifyStoreRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DisconnectShopifyStoreRequest {
    return new DisconnectShopifyStoreRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DisconnectShopifyStoreRequest {
    return new DisconnectShopifyStoreRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DisconnectShopifyStoreRequest {
    return new DisconnectShopifyStoreRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DisconnectShopifyStoreRequest | PlainMessage<DisconnectShopifyStoreRequest> | undefined, b: DisconnectShopifyStoreRequest | PlainMessage<DisconnectShopifyStoreRequest> | undefined): boolean {
    return proto3.util.equals(DisconnectShopifyStoreRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ShopifyProductPb
 */
export class ShopifyProductPb extends Message<ShopifyProductPb> {
  /**
   * @generated from field: int64 id = 7;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: string handle = 3;
   */
  handle = "";

  /**
   * @generated from field: repeated librarian.proto.ShopifyProductVariantPb variants = 4;
   */
  variants: ShopifyProductVariantPb[] = [];

  /**
   * @generated from field: google.protobuf.StringValue image_url = 5;
   */
  imageUrl?: string;

  /**
   * @generated from field: librarian.proto.ProductState state = 6;
   */
  state = ProductState.STATE_UNSPECIFIED;

  constructor(data?: PartialMessage<ShopifyProductPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ShopifyProductPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 7, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "variants", kind: "message", T: ShopifyProductVariantPb, repeated: true },
    { no: 5, name: "image_url", kind: "message", T: StringValue },
    { no: 6, name: "state", kind: "enum", T: proto3.getEnumType(ProductState) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShopifyProductPb {
    return new ShopifyProductPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShopifyProductPb {
    return new ShopifyProductPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShopifyProductPb {
    return new ShopifyProductPb().fromJsonString(jsonString, options);
  }

  static equals(a: ShopifyProductPb | PlainMessage<ShopifyProductPb> | undefined, b: ShopifyProductPb | PlainMessage<ShopifyProductPb> | undefined): boolean {
    return proto3.util.equals(ShopifyProductPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.ShopifyProductVariantPb
 */
export class ShopifyProductVariantPb extends Message<ShopifyProductVariantPb> {
  /**
   * @generated from field: int64 id = 7;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string sku = 1;
   */
  sku = "";

  /**
   * @generated from field: int32 quantity = 2;
   */
  quantity = 0;

  /**
   * @generated from field: google.protobuf.DoubleValue price = 3;
   */
  price?: number;

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 4;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: repeated librarian.proto.ProductVariantAttributePb attributes = 5;
   */
  attributes: ProductVariantAttributePb[] = [];

  /**
   * @generated from field: bool already_imported = 6;
   */
  alreadyImported = false;

  constructor(data?: PartialMessage<ShopifyProductVariantPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ShopifyProductVariantPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 7, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 1, name: "sku", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "quantity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "price", kind: "message", T: DoubleValue },
    { no: 4, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 5, name: "attributes", kind: "message", T: ProductVariantAttributePb, repeated: true },
    { no: 6, name: "already_imported", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShopifyProductVariantPb {
    return new ShopifyProductVariantPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShopifyProductVariantPb {
    return new ShopifyProductVariantPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShopifyProductVariantPb {
    return new ShopifyProductVariantPb().fromJsonString(jsonString, options);
  }

  static equals(a: ShopifyProductVariantPb | PlainMessage<ShopifyProductVariantPb> | undefined, b: ShopifyProductVariantPb | PlainMessage<ShopifyProductVariantPb> | undefined): boolean {
    return proto3.util.equals(ShopifyProductVariantPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetShopifyProductsRequest
 */
export class GetShopifyProductsRequest extends Message<GetShopifyProductsRequest> {
  /**
   * @generated from field: string shop_domain = 1;
   */
  shopDomain = "";

  /**
   * @generated from field: bool force_refresh = 2;
   */
  forceRefresh = false;

  constructor(data?: PartialMessage<GetShopifyProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetShopifyProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shop_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "force_refresh", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetShopifyProductsRequest {
    return new GetShopifyProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetShopifyProductsRequest {
    return new GetShopifyProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetShopifyProductsRequest {
    return new GetShopifyProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetShopifyProductsRequest | PlainMessage<GetShopifyProductsRequest> | undefined, b: GetShopifyProductsRequest | PlainMessage<GetShopifyProductsRequest> | undefined): boolean {
    return proto3.util.equals(GetShopifyProductsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetShopifyProductsResponse
 */
export class GetShopifyProductsResponse extends Message<GetShopifyProductsResponse> {
  /**
   * @generated from field: repeated librarian.proto.ShopifyProductPb shopify_products = 1 [deprecated = true];
   * @deprecated
   */
  shopifyProducts: ShopifyProductPb[] = [];

  /**
   * shop_domain for the requested shopify products
   *
   * @generated from field: string shop_domain = 2;
   */
  shopDomain = "";

  /**
   * @generated from field: string task_handle = 3;
   */
  taskHandle = "";

  constructor(data?: PartialMessage<GetShopifyProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetShopifyProductsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shopify_products", kind: "message", T: ShopifyProductPb, repeated: true },
    { no: 2, name: "shop_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "task_handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetShopifyProductsResponse {
    return new GetShopifyProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetShopifyProductsResponse {
    return new GetShopifyProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetShopifyProductsResponse {
    return new GetShopifyProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetShopifyProductsResponse | PlainMessage<GetShopifyProductsResponse> | undefined, b: GetShopifyProductsResponse | PlainMessage<GetShopifyProductsResponse> | undefined): boolean {
    return proto3.util.equals(GetShopifyProductsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetAvailableShopifyProductsRequest
 */
export class GetAvailableShopifyProductsRequest extends Message<GetAvailableShopifyProductsRequest> {
  /**
   * @generated from field: string shop_domain = 1;
   */
  shopDomain = "";

  constructor(data?: PartialMessage<GetAvailableShopifyProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetAvailableShopifyProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shop_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAvailableShopifyProductsRequest {
    return new GetAvailableShopifyProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAvailableShopifyProductsRequest {
    return new GetAvailableShopifyProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAvailableShopifyProductsRequest {
    return new GetAvailableShopifyProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAvailableShopifyProductsRequest | PlainMessage<GetAvailableShopifyProductsRequest> | undefined, b: GetAvailableShopifyProductsRequest | PlainMessage<GetAvailableShopifyProductsRequest> | undefined): boolean {
    return proto3.util.equals(GetAvailableShopifyProductsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetAvailableShopifyProductsResponse
 */
export class GetAvailableShopifyProductsResponse extends Message<GetAvailableShopifyProductsResponse> {
  /**
   * @generated from field: repeated librarian.proto.ShopifyProductPb shopify_products = 1;
   */
  shopifyProducts: ShopifyProductPb[] = [];

  /**
   * import date in UTC
   *
   * @generated from field: google.protobuf.Timestamp import_date = 2;
   */
  importDate?: Timestamp;

  constructor(data?: PartialMessage<GetAvailableShopifyProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetAvailableShopifyProductsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shopify_products", kind: "message", T: ShopifyProductPb, repeated: true },
    { no: 2, name: "import_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAvailableShopifyProductsResponse {
    return new GetAvailableShopifyProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAvailableShopifyProductsResponse {
    return new GetAvailableShopifyProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAvailableShopifyProductsResponse {
    return new GetAvailableShopifyProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetAvailableShopifyProductsResponse | PlainMessage<GetAvailableShopifyProductsResponse> | undefined, b: GetAvailableShopifyProductsResponse | PlainMessage<GetAvailableShopifyProductsResponse> | undefined): boolean {
    return proto3.util.equals(GetAvailableShopifyProductsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetShopifyProductsTask
 */
export class GetShopifyProductsTask extends Message<GetShopifyProductsTask> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.GetShopifyProductsRequest request = 2;
   */
  request?: GetShopifyProductsRequest;

  constructor(data?: PartialMessage<GetShopifyProductsTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetShopifyProductsTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "request", kind: "message", T: GetShopifyProductsRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetShopifyProductsTask {
    return new GetShopifyProductsTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetShopifyProductsTask {
    return new GetShopifyProductsTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetShopifyProductsTask {
    return new GetShopifyProductsTask().fromJsonString(jsonString, options);
  }

  static equals(a: GetShopifyProductsTask | PlainMessage<GetShopifyProductsTask> | undefined, b: GetShopifyProductsTask | PlainMessage<GetShopifyProductsTask> | undefined): boolean {
    return proto3.util.equals(GetShopifyProductsTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetShopifyProductsTaskOutput
 */
export class GetShopifyProductsTaskOutput extends Message<GetShopifyProductsTaskOutput> {
  /**
   * @generated from field: repeated librarian.proto.ShopifyProductPb shopify_products = 1;
   */
  shopifyProducts: ShopifyProductPb[] = [];

  /**
   * import date in UTC
   *
   * @generated from field: google.protobuf.Timestamp import_date = 2;
   */
  importDate?: Timestamp;

  constructor(data?: PartialMessage<GetShopifyProductsTaskOutput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetShopifyProductsTaskOutput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shopify_products", kind: "message", T: ShopifyProductPb, repeated: true },
    { no: 2, name: "import_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetShopifyProductsTaskOutput {
    return new GetShopifyProductsTaskOutput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetShopifyProductsTaskOutput {
    return new GetShopifyProductsTaskOutput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetShopifyProductsTaskOutput {
    return new GetShopifyProductsTaskOutput().fromJsonString(jsonString, options);
  }

  static equals(a: GetShopifyProductsTaskOutput | PlainMessage<GetShopifyProductsTaskOutput> | undefined, b: GetShopifyProductsTaskOutput | PlainMessage<GetShopifyProductsTaskOutput> | undefined): boolean {
    return proto3.util.equals(GetShopifyProductsTaskOutput, a, b);
  }
}

/**
 * @generated from message librarian.proto.ImportShopifyProductsRequest
 */
export class ImportShopifyProductsRequest extends Message<ImportShopifyProductsRequest> {
  /**
   * @generated from field: repeated librarian.proto.ShopifyProductPb shopify_products = 1;
   */
  shopifyProducts: ShopifyProductPb[] = [];

  /**
   * @generated from field: string shop_domain = 2;
   */
  shopDomain = "";

  /**
   * @generated from field: bool overwrite_products = 3;
   */
  overwriteProducts = false;

  constructor(data?: PartialMessage<ImportShopifyProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ImportShopifyProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "shopify_products", kind: "message", T: ShopifyProductPb, repeated: true },
    { no: 2, name: "shop_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "overwrite_products", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportShopifyProductsRequest {
    return new ImportShopifyProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportShopifyProductsRequest {
    return new ImportShopifyProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportShopifyProductsRequest {
    return new ImportShopifyProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ImportShopifyProductsRequest | PlainMessage<ImportShopifyProductsRequest> | undefined, b: ImportShopifyProductsRequest | PlainMessage<ImportShopifyProductsRequest> | undefined): boolean {
    return proto3.util.equals(ImportShopifyProductsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ImportShopifyProductsTask
 */
export class ImportShopifyProductsTask extends Message<ImportShopifyProductsTask> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.ImportShopifyProductsRequest request = 2;
   */
  request?: ImportShopifyProductsRequest;

  constructor(data?: PartialMessage<ImportShopifyProductsTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ImportShopifyProductsTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "request", kind: "message", T: ImportShopifyProductsRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportShopifyProductsTask {
    return new ImportShopifyProductsTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportShopifyProductsTask {
    return new ImportShopifyProductsTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportShopifyProductsTask {
    return new ImportShopifyProductsTask().fromJsonString(jsonString, options);
  }

  static equals(a: ImportShopifyProductsTask | PlainMessage<ImportShopifyProductsTask> | undefined, b: ImportShopifyProductsTask | PlainMessage<ImportShopifyProductsTask> | undefined): boolean {
    return proto3.util.equals(ImportShopifyProductsTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.ShopifyWebhookTask
 */
export class ShopifyWebhookTask extends Message<ShopifyWebhookTask> {
  /**
   * @generated from field: librarian.proto.ShopifyWebhookTopic topic = 1;
   */
  topic = ShopifyWebhookTopic.UNSPECIFIED;

  /**
   * @generated from field: string request_body = 2;
   */
  requestBody = "";

  /**
   * @generated from field: string shop_domain = 3;
   */
  shopDomain = "";

  constructor(data?: PartialMessage<ShopifyWebhookTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ShopifyWebhookTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "topic", kind: "enum", T: proto3.getEnumType(ShopifyWebhookTopic) },
    { no: 2, name: "request_body", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "shop_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShopifyWebhookTask {
    return new ShopifyWebhookTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShopifyWebhookTask {
    return new ShopifyWebhookTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShopifyWebhookTask {
    return new ShopifyWebhookTask().fromJsonString(jsonString, options);
  }

  static equals(a: ShopifyWebhookTask | PlainMessage<ShopifyWebhookTask> | undefined, b: ShopifyWebhookTask | PlainMessage<ShopifyWebhookTask> | undefined): boolean {
    return proto3.util.equals(ShopifyWebhookTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.ImportShopifyProductsTaskOutput
 */
export class ImportShopifyProductsTaskOutput extends Message<ImportShopifyProductsTaskOutput> {
  /**
   * @generated from field: repeated librarian.proto.ImportShopifyProductsTaskOutput.ImportShopifyProductsTaskError errors = 1;
   */
  errors: ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError[] = [];

  /**
   * @generated from field: int32 products_imported_count = 2;
   */
  productsImportedCount = 0;

  constructor(data?: PartialMessage<ImportShopifyProductsTaskOutput>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ImportShopifyProductsTaskOutput";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "errors", kind: "message", T: ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError, repeated: true },
    { no: 2, name: "products_imported_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportShopifyProductsTaskOutput {
    return new ImportShopifyProductsTaskOutput().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportShopifyProductsTaskOutput {
    return new ImportShopifyProductsTaskOutput().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportShopifyProductsTaskOutput {
    return new ImportShopifyProductsTaskOutput().fromJsonString(jsonString, options);
  }

  static equals(a: ImportShopifyProductsTaskOutput | PlainMessage<ImportShopifyProductsTaskOutput> | undefined, b: ImportShopifyProductsTaskOutput | PlainMessage<ImportShopifyProductsTaskOutput> | undefined): boolean {
    return proto3.util.equals(ImportShopifyProductsTaskOutput, a, b);
  }
}

/**
 * @generated from message librarian.proto.ImportShopifyProductsTaskOutput.ImportShopifyProductsTaskError
 */
export class ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError extends Message<ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError> {
  /**
   * @generated from field: string external_product_id = 1;
   */
  externalProductId = "";

  /**
   * @generated from field: string error = 2;
   */
  error = "";

  constructor(data?: PartialMessage<ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ImportShopifyProductsTaskOutput.ImportShopifyProductsTaskError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "external_product_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError {
    return new ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError {
    return new ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError {
    return new ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError().fromJsonString(jsonString, options);
  }

  static equals(a: ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError | PlainMessage<ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError> | undefined, b: ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError | PlainMessage<ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError> | undefined): boolean {
    return proto3.util.equals(ImportShopifyProductsTaskOutput_ImportShopifyProductsTaskError, a, b);
  }
}

/**
 * @generated from message librarian.proto.ShopifyStorePb
 */
export class ShopifyStorePb extends Message<ShopifyStorePb> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string domain = 2;
   */
  domain = "";

  /**
   * @generated from field: bool has_access = 3;
   */
  hasAccess = false;

  /**
   * @generated from field: google.protobuf.StringValue name = 4;
   */
  name?: string;

  constructor(data?: PartialMessage<ShopifyStorePb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ShopifyStorePb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "has_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "name", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ShopifyStorePb {
    return new ShopifyStorePb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ShopifyStorePb {
    return new ShopifyStorePb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ShopifyStorePb {
    return new ShopifyStorePb().fromJsonString(jsonString, options);
  }

  static equals(a: ShopifyStorePb | PlainMessage<ShopifyStorePb> | undefined, b: ShopifyStorePb | PlainMessage<ShopifyStorePb> | undefined): boolean {
    return proto3.util.equals(ShopifyStorePb, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetConnectedShopifyStoresResponse
 */
export class GetConnectedShopifyStoresResponse extends Message<GetConnectedShopifyStoresResponse> {
  /**
   * @generated from field: repeated librarian.proto.ShopifyStorePb stores = 1 [deprecated = true];
   * @deprecated
   */
  stores: ShopifyStorePb[] = [];

  /**
   * @generated from field: repeated librarian.proto.ShopifyStorePbV2 stores_v2 = 2;
   */
  storesV2: ShopifyStorePbV2[] = [];

  constructor(data?: PartialMessage<GetConnectedShopifyStoresResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetConnectedShopifyStoresResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "stores", kind: "message", T: ShopifyStorePb, repeated: true },
    { no: 2, name: "stores_v2", kind: "message", T: ShopifyStorePbV2, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConnectedShopifyStoresResponse {
    return new GetConnectedShopifyStoresResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConnectedShopifyStoresResponse {
    return new GetConnectedShopifyStoresResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConnectedShopifyStoresResponse {
    return new GetConnectedShopifyStoresResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetConnectedShopifyStoresResponse | PlainMessage<GetConnectedShopifyStoresResponse> | undefined, b: GetConnectedShopifyStoresResponse | PlainMessage<GetConnectedShopifyStoresResponse> | undefined): boolean {
    return proto3.util.equals(GetConnectedShopifyStoresResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetConnectedTeamsRequest
 */
export class GetConnectedTeamsRequest extends Message<GetConnectedTeamsRequest> {
  /**
   * @generated from field: string store_domain = 1;
   */
  storeDomain = "";

  constructor(data?: PartialMessage<GetConnectedTeamsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetConnectedTeamsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "store_domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConnectedTeamsRequest {
    return new GetConnectedTeamsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConnectedTeamsRequest {
    return new GetConnectedTeamsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConnectedTeamsRequest {
    return new GetConnectedTeamsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetConnectedTeamsRequest | PlainMessage<GetConnectedTeamsRequest> | undefined, b: GetConnectedTeamsRequest | PlainMessage<GetConnectedTeamsRequest> | undefined): boolean {
    return proto3.util.equals(GetConnectedTeamsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetConnectedTeamsResponse
 */
export class GetConnectedTeamsResponse extends Message<GetConnectedTeamsResponse> {
  /**
   * @generated from field: repeated librarian.proto.TeamPb teams = 1;
   */
  teams: TeamPb[] = [];

  constructor(data?: PartialMessage<GetConnectedTeamsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetConnectedTeamsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teams", kind: "message", T: TeamPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetConnectedTeamsResponse {
    return new GetConnectedTeamsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetConnectedTeamsResponse {
    return new GetConnectedTeamsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetConnectedTeamsResponse {
    return new GetConnectedTeamsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetConnectedTeamsResponse | PlainMessage<GetConnectedTeamsResponse> | undefined, b: GetConnectedTeamsResponse | PlainMessage<GetConnectedTeamsResponse> | undefined): boolean {
    return proto3.util.equals(GetConnectedTeamsResponse, a, b);
  }
}


// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/home_dashboard.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, Int32Value, MethodKind } from "@bufbuild/protobuf";
import { HomeDashboardFilter, HomeDashboardMetricEvolutions, HomeDashboardMetricGraph, HomeDashboardMetricItems, HomeDashboardMetrics, HomeDashboardRetentionStatistics, HomeDashboardRetentionStatisticsDetails, HomeDashboardRfmScoringStatistics, HomeDashboardTopInfluencers, HomeDashboardTopPosts, HomeDashboardTopRunningCampaigns, RetentionDetailsRequest } from "./home_dashboard_pb";
import { DataPeriodEvolution } from "./common_pb";
import { PostTypeSliceData } from "./reports_pb";

/**
 * @generated from service librarian.proto.LibrarianHomeDashboard
 */
export const LibrarianHomeDashboard = {
  typeName: "librarian.proto.LibrarianHomeDashboard",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.GetMetricsAPI
     * @deprecated
     */
    getMetricsAPI: {
      name: "GetMetricsAPI",
      I: Empty,
      O: HomeDashboardMetricItems,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.GetMetricsV2API
     */
    getMetricsV2API: {
      name: "GetMetricsV2API",
      I: HomeDashboardFilter,
      O: HomeDashboardMetricEvolutions,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.HideMetricsAPI
     */
    hideMetricsAPI: {
      name: "HideMetricsAPI",
      I: HomeDashboardMetrics,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.ShowMetricsAPI
     */
    showMetricsAPI: {
      name: "ShowMetricsAPI",
      I: HomeDashboardMetrics,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.ReorderMetricsAPI
     */
    reorderMetricsAPI: {
      name: "ReorderMetricsAPI",
      I: HomeDashboardMetricItems,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.ActiveInfluencersMetricAPI
     */
    activeInfluencersMetricAPI: {
      name: "ActiveInfluencersMetricAPI",
      I: HomeDashboardFilter,
      O: DataPeriodEvolution,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.ActiveInfluencersMetricGraphAPI
     */
    activeInfluencersMetricGraphAPI: {
      name: "ActiveInfluencersMetricGraphAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardMetricGraph,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.ImpressionsMetricAPI
     */
    impressionsMetricAPI: {
      name: "ImpressionsMetricAPI",
      I: HomeDashboardFilter,
      O: DataPeriodEvolution,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.ImpressionsMetricGraphAPI
     */
    impressionsMetricGraphAPI: {
      name: "ImpressionsMetricGraphAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardMetricGraph,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.EmvMetricAPI
     */
    emvMetricAPI: {
      name: "EmvMetricAPI",
      I: HomeDashboardFilter,
      O: DataPeriodEvolution,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.EmvMetricGraphAPI
     */
    emvMetricGraphAPI: {
      name: "EmvMetricGraphAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardMetricGraph,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.PostsNumberMetricAPI
     */
    postsNumberMetricAPI: {
      name: "PostsNumberMetricAPI",
      I: HomeDashboardFilter,
      O: DataPeriodEvolution,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.PostsNumberMetricGraphAPI
     */
    postsNumberMetricGraphAPI: {
      name: "PostsNumberMetricGraphAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardMetricGraph,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.EngagementMetricAPI
     */
    engagementMetricAPI: {
      name: "EngagementMetricAPI",
      I: HomeDashboardFilter,
      O: DataPeriodEvolution,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.EngagementMetricGraphAPI
     */
    engagementMetricGraphAPI: {
      name: "EngagementMetricGraphAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardMetricGraph,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.ClicksMetricAPI
     */
    clicksMetricAPI: {
      name: "ClicksMetricAPI",
      I: HomeDashboardFilter,
      O: DataPeriodEvolution,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.ClicksMetricGraphAPI
     */
    clicksMetricGraphAPI: {
      name: "ClicksMetricGraphAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardMetricGraph,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.ConversionsMetricAPI
     */
    conversionsMetricAPI: {
      name: "ConversionsMetricAPI",
      I: HomeDashboardFilter,
      O: DataPeriodEvolution,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.ConversionsMetricGraphAPI
     */
    conversionsMetricGraphAPI: {
      name: "ConversionsMetricGraphAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardMetricGraph,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.SalesMetricAPI
     */
    salesMetricAPI: {
      name: "SalesMetricAPI",
      I: HomeDashboardFilter,
      O: DataPeriodEvolution,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.SalesMetricGraphAPI
     */
    salesMetricGraphAPI: {
      name: "SalesMetricGraphAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardMetricGraph,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.AffiliatesNumberMetricAPI
     */
    affiliatesNumberMetricAPI: {
      name: "AffiliatesNumberMetricAPI",
      I: HomeDashboardFilter,
      O: Int32Value,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.GiftsSentMetricAPI
     */
    giftsSentMetricAPI: {
      name: "GiftsSentMetricAPI",
      I: HomeDashboardFilter,
      O: DataPeriodEvolution,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.GiftsSentMetricGraphAPI
     */
    giftsSentMetricGraphAPI: {
      name: "GiftsSentMetricGraphAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardMetricGraph,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.GiftsAmountMetricAPI
     */
    giftsAmountMetricAPI: {
      name: "GiftsAmountMetricAPI",
      I: HomeDashboardFilter,
      O: DataPeriodEvolution,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.GiftsAmountMetricGraphAPI
     */
    giftsAmountMetricGraphAPI: {
      name: "GiftsAmountMetricGraphAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardMetricGraph,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.PaidInfluencersMetricAPI
     */
    paidInfluencersMetricAPI: {
      name: "PaidInfluencersMetricAPI",
      I: HomeDashboardFilter,
      O: DataPeriodEvolution,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.PaidInfluencersMetricGraphAPI
     */
    paidInfluencersMetricGraphAPI: {
      name: "PaidInfluencersMetricGraphAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardMetricGraph,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.PaidAmountMetricAPI
     */
    paidAmountMetricAPI: {
      name: "PaidAmountMetricAPI",
      I: HomeDashboardFilter,
      O: DataPeriodEvolution,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.PaidAmountMetricGraphAPI
     */
    paidAmountMetricGraphAPI: {
      name: "PaidAmountMetricGraphAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardMetricGraph,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.CampaignsNumberMetricAPI
     */
    campaignsNumberMetricAPI: {
      name: "CampaignsNumberMetricAPI",
      I: HomeDashboardFilter,
      O: DataPeriodEvolution,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.InfluencerAveragePostMetricAPI
     */
    influencerAveragePostMetricAPI: {
      name: "InfluencerAveragePostMetricAPI",
      I: HomeDashboardFilter,
      O: DataPeriodEvolution,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.GetRfmScoringStatisticsAPI
     */
    getRfmScoringStatisticsAPI: {
      name: "GetRfmScoringStatisticsAPI",
      I: Empty,
      O: HomeDashboardRfmScoringStatistics,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.GetRetentionStatisticsAPI
     */
    getRetentionStatisticsAPI: {
      name: "GetRetentionStatisticsAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardRetentionStatistics,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.GetRetentionStatisticsDetailsAPI
     */
    getRetentionStatisticsDetailsAPI: {
      name: "GetRetentionStatisticsDetailsAPI",
      I: RetentionDetailsRequest,
      O: HomeDashboardRetentionStatisticsDetails,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.GetPlatformsSplitAPI
     */
    getPlatformsSplitAPI: {
      name: "GetPlatformsSplitAPI",
      I: HomeDashboardFilter,
      O: PostTypeSliceData,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.GetTopInfluencersAPI
     */
    getTopInfluencersAPI: {
      name: "GetTopInfluencersAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardTopInfluencers,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.GetTopRunningCampaignsAPI
     */
    getTopRunningCampaignsAPI: {
      name: "GetTopRunningCampaignsAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardTopRunningCampaigns,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianHomeDashboard.GetTopPostsAPI
     */
    getTopPostsAPI: {
      name: "GetTopPostsAPI",
      I: HomeDashboardFilter,
      O: HomeDashboardTopPosts,
      kind: MethodKind.Unary,
    },
  }
} as const;


<form
  #form="ngForm"
  (ngSubmit)="submit($event)"
>
  <lefty-form
    [label]="label"
    [optional]="optional"
  >
    <div
      class="floating-actions"
      [class.bottom-actions]="actionsOnBottom"
      [class.left-actions]="actionsOnLeft"
    >
      <button
        leftyButton="plain"
        type="button"
        [disabled]="isEditionDisabled"
        (click)="edit($event)"
        i18n
        *ngIf="isEditing() === false && !hideEditButton"
      >
        Edit
      </button>

      <button
        leftyButton="plain"
        type="button"
        [disabled]="isEditionDisabled"
        (click)="delete$.next($event)"
        i18n
        *ngIf="isEditing() === false && showRemoveOnReadMode"
      >
        Remove
      </button>

      <button
        leftyButton="plain"
        type="button"
        class="cancel-btn cancel"
        i18n
        [disabled]="loading"
        (click)="cancel($event)"
        *ngIf="isEditing() === true && hideCancel === false"
      >
        Cancel
      </button>
    </div>

    <div
      class="read-mode"
      *ngIf="isEditing() === false"
    >
      <ng-content select="[readMode]"></ng-content>
    </div>

    <div
      class="edit-mode-container"
      *ngIf="isEditing() === true"
    >
      <div class="edit-mode">
        <ng-content select="[formControl],[editMode]"></ng-content>
      </div>

      <div class="actions">
        <button
          leftyButton="primary"
          class="save-btn"
          [disabled]="loading || isInvalid"
          [loading]="loading"
          i18n
        >
          Save
        </button>

        <button
          leftyButton="caution"
          type="button"
          [disabled]="loading"
          [loading]="loading"
          (click)="delete$.next($event)"
          *ngIf="canDelete"
          i18n
        >
          Remove
        </button>
      </div>
    </div>
  </lefty-form>
</form>

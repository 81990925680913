// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/campaigns.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { BoolValue, FloatValue, Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { EntityFieldValue } from "./entity_fields_pb";
import { CampaignKeyword, CampaignPerformance, CampaignStatus, CampaignTrackingSettingsPb, FavoritesLabel, InCampaignStatus, InfluencerState, UserStatus, VisualEditedPerf } from "../../common/proto/campaign_pb";
import { NightwatchCtx } from "../../common/proto/nightwatch_pb";
import { BasicPagination, CurrencyEnum, MediaIdPb, Network, NetworkList, PostType, SortDirection, StringList } from "../../common/proto/common_pb";
import { CreatorFieldSort, DynamicFilter, EntityFieldSort, FlexibleCreatorSelector, PortfolioReqV2, PostsPerNetworkCount, UserSort, VisualSelector, VisualSort } from "./common_pb";
import { GenerikMediaSnippet } from "./users_pb";
import { AgesReport, GeographyReports } from "../../common/proto/user_pb";
import { DownloadPoolAssetsRequest } from "./pool_pb";

/**
 * @generated from enum librarian.proto.InfluencerRegistrationMethod
 */
export enum InfluencerRegistrationMethod {
  /**
   * Illegal value, this should not be use
   *
   * @generated from enum value: NONE = 0;
   */
  NONE = 0,

  /**
   * @generated from enum value: INSTAGRAM = 1;
   */
  INSTAGRAM = 1,

  /**
   * @generated from enum value: INSTAGRAM_AND_EMAIL = 2;
   */
  INSTAGRAM_AND_EMAIL = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(InfluencerRegistrationMethod)
proto3.util.setEnumType(InfluencerRegistrationMethod, "librarian.proto.InfluencerRegistrationMethod", [
  { no: 0, name: "NONE" },
  { no: 1, name: "INSTAGRAM" },
  { no: 2, name: "INSTAGRAM_AND_EMAIL" },
]);

/**
 * @generated from message librarian.proto.CampaignReportSlidesCount
 */
export class CampaignReportSlidesCount extends Message<CampaignReportSlidesCount> {
  /**
   * @generated from field: int32 overview = 1;
   */
  overview = 0;

  /**
   * @generated from field: int32 performance = 2;
   */
  performance = 0;

  /**
   * @generated from field: int32 influencers_list = 3;
   */
  influencersList = 0;

  /**
   * @generated from field: int32 influencers_perf = 4;
   */
  influencersPerf = 0;

  /**
   * @generated from field: int32 influencers_posts_per_influencer = 5;
   */
  influencersPostsPerInfluencer = 0;

  /**
   * @generated from field: int32 influencers_posts_top_posts = 6;
   */
  influencersPostsTopPosts = 0;

  /**
   * @generated from field: int32 influencers_posts_per_influencer_large = 7;
   */
  influencersPostsPerInfluencerLarge = 0;

  /**
   * @generated from field: int32 influencers_posts_top_posts_large = 8;
   */
  influencersPostsTopPostsLarge = 0;

  constructor(data?: PartialMessage<CampaignReportSlidesCount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignReportSlidesCount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "overview", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "performance", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "influencers_list", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "influencers_perf", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "influencers_posts_per_influencer", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "influencers_posts_top_posts", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "influencers_posts_per_influencer_large", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "influencers_posts_top_posts_large", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignReportSlidesCount {
    return new CampaignReportSlidesCount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignReportSlidesCount {
    return new CampaignReportSlidesCount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignReportSlidesCount {
    return new CampaignReportSlidesCount().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignReportSlidesCount | PlainMessage<CampaignReportSlidesCount> | undefined, b: CampaignReportSlidesCount | PlainMessage<CampaignReportSlidesCount> | undefined): boolean {
    return proto3.util.equals(CampaignReportSlidesCount, a, b);
  }
}

/**
 * @generated from message librarian.proto.CampaignRequest
 */
export class CampaignRequest extends Message<CampaignRequest> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.StringValue name = 2;
   */
  name?: string;

  /**
   * @generated from field: google.protobuf.Int64Value cost = 14;
   */
  cost?: bigint;

  /**
   * @generated from field: google.protobuf.Timestamp start = 9;
   */
  start?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end = 10;
   */
  end?: Timestamp;

  /**
   * This is only for programmatic calls
   *
   * @generated from field: int64 user_id = 12;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: repeated librarian.proto.EntityFieldValue campaign_field_values = 20;
   */
  campaignFieldValues: EntityFieldValue[] = [];

  /**
   * @generated from field: repeated common.proto.CampaignKeyword keywords = 18;
   */
  keywords: CampaignKeyword[] = [];

  /**
   * @generated from field: repeated common.proto.CampaignTrackingSettingsPb tracking_settings = 22;
   */
  trackingSettings: CampaignTrackingSettingsPb[] = [];

  /**
   * @generated from field: repeated common.proto.CampaignKeyword excluded_keywords = 21;
   */
  excludedKeywords: CampaignKeyword[] = [];

  /**
   * @generated from field: google.protobuf.BoolValue exclude_performance = 19;
   */
  excludePerformance?: boolean;

  /**
   * @generated from field: google.protobuf.Int64Value landing_page_id = 100;
   */
  landingPageId?: bigint;

  /**
   * @generated from field: common.proto.NightwatchCtx nightwatch_ctx = 101;
   */
  nightwatchCtx?: NightwatchCtx;

  constructor(data?: PartialMessage<CampaignRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "message", T: StringValue },
    { no: 14, name: "cost", kind: "message", T: Int64Value },
    { no: 9, name: "start", kind: "message", T: Timestamp },
    { no: 10, name: "end", kind: "message", T: Timestamp },
    { no: 12, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 20, name: "campaign_field_values", kind: "message", T: EntityFieldValue, repeated: true },
    { no: 18, name: "keywords", kind: "message", T: CampaignKeyword, repeated: true },
    { no: 22, name: "tracking_settings", kind: "message", T: CampaignTrackingSettingsPb, repeated: true },
    { no: 21, name: "excluded_keywords", kind: "message", T: CampaignKeyword, repeated: true },
    { no: 19, name: "exclude_performance", kind: "message", T: BoolValue },
    { no: 100, name: "landing_page_id", kind: "message", T: Int64Value },
    { no: 101, name: "nightwatch_ctx", kind: "message", T: NightwatchCtx },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignRequest {
    return new CampaignRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignRequest {
    return new CampaignRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignRequest {
    return new CampaignRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignRequest | PlainMessage<CampaignRequest> | undefined, b: CampaignRequest | PlainMessage<CampaignRequest> | undefined): boolean {
    return proto3.util.equals(CampaignRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CampaignVisualRequest
 */
export class CampaignVisualRequest extends Message<CampaignVisualRequest> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: common.proto.StringList media_ids = 7 [deprecated = true];
   * @deprecated
   */
  mediaIds?: StringList;

  /**
   * @generated from oneof librarian.proto.CampaignVisualRequest.the_selector
   */
  theSelector: {
    /**
     * @generated from field: librarian.proto.VisualSelector visual_selector = 9;
     */
    value: VisualSelector;
    case: "visualSelector";
  } | {
    /**
     * @generated from field: librarian.proto.CampaignPostsSelector campaign_posts_selector = 10;
     */
    value: CampaignPostsSelector;
    case: "campaignPostsSelector";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: bool is_last_in_batch = 5;
   */
  isLastInBatch = false;

  /**
   * @generated from field: common.proto.NightwatchCtx nightwatch_ctx = 8;
   */
  nightwatchCtx?: NightwatchCtx;

  constructor(data?: PartialMessage<CampaignVisualRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignVisualRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "media_ids", kind: "message", T: StringList },
    { no: 9, name: "visual_selector", kind: "message", T: VisualSelector, oneof: "the_selector" },
    { no: 10, name: "campaign_posts_selector", kind: "message", T: CampaignPostsSelector, oneof: "the_selector" },
    { no: 5, name: "is_last_in_batch", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "nightwatch_ctx", kind: "message", T: NightwatchCtx },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignVisualRequest {
    return new CampaignVisualRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignVisualRequest {
    return new CampaignVisualRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignVisualRequest {
    return new CampaignVisualRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignVisualRequest | PlainMessage<CampaignVisualRequest> | undefined, b: CampaignVisualRequest | PlainMessage<CampaignVisualRequest> | undefined): boolean {
    return proto3.util.equals(CampaignVisualRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GenerikVisuals
 */
export class GenerikVisuals extends Message<GenerikVisuals> {
  /**
   * @generated from field: repeated librarian.proto.GenerikMediaSnippet visual = 1;
   */
  visual: GenerikMediaSnippet[] = [];

  /**
   * @generated from field: int32 total = 2;
   */
  total = 0;

  /**
   * If set, there is a refresh visuals operation going on, and the value is that operation's firestore
   * token. This is only set on requests for a campaign.
   *
   * @generated from field: google.protobuf.StringValue refreshing_token = 100;
   */
  refreshingToken?: string;

  constructor(data?: PartialMessage<GenerikVisuals>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GenerikVisuals";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "visual", kind: "message", T: GenerikMediaSnippet, repeated: true },
    { no: 2, name: "total", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 100, name: "refreshing_token", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikVisuals {
    return new GenerikVisuals().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikVisuals {
    return new GenerikVisuals().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikVisuals {
    return new GenerikVisuals().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikVisuals | PlainMessage<GenerikVisuals> | undefined, b: GenerikVisuals | PlainMessage<GenerikVisuals> | undefined): boolean {
    return proto3.util.equals(GenerikVisuals, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetCampaignCreatorsRequestV2
 */
export class GetCampaignCreatorsRequestV2 extends Message<GetCampaignCreatorsRequestV2> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.DynamicFilter filter = 2;
   */
  filter?: DynamicFilter;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 3;
   */
  pagination?: BasicPagination;

  /**
   * @generated from field: librarian.proto.UserSort usort = 4;
   */
  usort = UserSort.DEFAULT;

  /**
   * @generated from field: common.proto.SortDirection direction = 5;
   */
  direction = SortDirection.DESC;

  /**
   * @generated from field: librarian.proto.CreatorFieldSort creator_sort = 6 [deprecated = true];
   * @deprecated
   */
  creatorSort?: CreatorFieldSort;

  /**
   * @generated from field: librarian.proto.EntityFieldSort creator_field_sort = 7;
   */
  creatorFieldSort?: EntityFieldSort;

  constructor(data?: PartialMessage<GetCampaignCreatorsRequestV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetCampaignCreatorsRequestV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "filter", kind: "message", T: DynamicFilter },
    { no: 3, name: "pagination", kind: "message", T: BasicPagination },
    { no: 4, name: "usort", kind: "enum", T: proto3.getEnumType(UserSort) },
    { no: 5, name: "direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 6, name: "creator_sort", kind: "message", T: CreatorFieldSort },
    { no: 7, name: "creator_field_sort", kind: "message", T: EntityFieldSort },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCampaignCreatorsRequestV2 {
    return new GetCampaignCreatorsRequestV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCampaignCreatorsRequestV2 {
    return new GetCampaignCreatorsRequestV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCampaignCreatorsRequestV2 {
    return new GetCampaignCreatorsRequestV2().fromJsonString(jsonString, options);
  }

  static equals(a: GetCampaignCreatorsRequestV2 | PlainMessage<GetCampaignCreatorsRequestV2> | undefined, b: GetCampaignCreatorsRequestV2 | PlainMessage<GetCampaignCreatorsRequestV2> | undefined): boolean {
    return proto3.util.equals(GetCampaignCreatorsRequestV2, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetCampaignCandidatesRequest
 */
export class GetCampaignCandidatesRequest extends Message<GetCampaignCandidatesRequest> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: string username_prefix = 2;
   */
  usernamePrefix = "";

  /**
   * @generated from field: repeated common.proto.UserStatus user_status = 3;
   */
  userStatus: UserStatus[] = [];

  /**
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<GetCampaignCandidatesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetCampaignCandidatesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "username_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_status", kind: "enum", T: proto3.getEnumType(UserStatus), repeated: true },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCampaignCandidatesRequest {
    return new GetCampaignCandidatesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCampaignCandidatesRequest {
    return new GetCampaignCandidatesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCampaignCandidatesRequest {
    return new GetCampaignCandidatesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCampaignCandidatesRequest | PlainMessage<GetCampaignCandidatesRequest> | undefined, b: GetCampaignCandidatesRequest | PlainMessage<GetCampaignCandidatesRequest> | undefined): boolean {
    return proto3.util.equals(GetCampaignCandidatesRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.InfluencerContextInfo
 */
export class InfluencerContextInfo extends Message<InfluencerContextInfo> {
  /**
   * @generated from field: common.proto.InfluencerState state = 1;
   */
  state = InfluencerState.STATE_UNDEFINED;

  /**
   * @generated from field: int64 number_of_visuals = 2;
   */
  numberOfVisuals = protoInt64.zero;

  constructor(data?: PartialMessage<InfluencerContextInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InfluencerContextInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(InfluencerState) },
    { no: 2, name: "number_of_visuals", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerContextInfo {
    return new InfluencerContextInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerContextInfo {
    return new InfluencerContextInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerContextInfo {
    return new InfluencerContextInfo().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerContextInfo | PlainMessage<InfluencerContextInfo> | undefined, b: InfluencerContextInfo | PlainMessage<InfluencerContextInfo> | undefined): boolean {
    return proto3.util.equals(InfluencerContextInfo, a, b);
  }
}

/**
 * Performance of the campaign for displaying in the my Campaigns list.
 * Much less detailed than the report one (in particular no breakdown by network).
 *
 * @generated from message librarian.proto.CampaignPerformanceSummary
 */
export class CampaignPerformanceSummary extends Message<CampaignPerformanceSummary> {
  /**
   * @generated from field: int32 active_creators = 1;
   */
  activeCreators = 0;

  /**
   * @generated from field: int32 visuals_count = 2;
   */
  visualsCount = 0;

  /**
   * @generated from field: int64 impressions = 3;
   */
  impressions = protoInt64.zero;

  /**
   * @generated from field: int64 emv = 4;
   */
  emv = protoInt64.zero;

  constructor(data?: PartialMessage<CampaignPerformanceSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignPerformanceSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "active_creators", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "visuals_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "impressions", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "emv", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignPerformanceSummary {
    return new CampaignPerformanceSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignPerformanceSummary {
    return new CampaignPerformanceSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignPerformanceSummary {
    return new CampaignPerformanceSummary().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignPerformanceSummary | PlainMessage<CampaignPerformanceSummary> | undefined, b: CampaignPerformanceSummary | PlainMessage<CampaignPerformanceSummary> | undefined): boolean {
    return proto3.util.equals(CampaignPerformanceSummary, a, b);
  }
}

/**
 * @generated from message librarian.proto.Campaign
 */
export class Campaign extends Message<Campaign> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string encrypted_id = 22;
   */
  encryptedId = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string public_name = 3;
   */
  publicName = "";

  /**
   * @generated from field: string description = 5;
   */
  description = "";

  /**
   * @generated from field: common.proto.CampaignStatus status = 7;
   */
  status = CampaignStatus.CAMPAIGN_UNDEFINED;

  /**
   * @generated from field: bool pinned = 42;
   */
  pinned = false;

  /**
   * @generated from field: int64 cost = 24;
   */
  cost = protoInt64.zero;

  /**
   * @generated from field: int64 influencers_total_cost = 33;
   */
  influencersTotalCost = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp created = 8;
   */
  created?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated = 9;
   */
  updated?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp start = 12;
   */
  start?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end = 13;
   */
  end?: Timestamp;

  /**
   * @generated from field: int64 number_of_influencers = 15;
   */
  numberOfInfluencers = protoInt64.zero;

  /**
   * @generated from field: repeated librarian.proto.Campaign.InfluencersStateCount influencers_state = 16;
   */
  influencersState: Campaign_InfluencersStateCount[] = [];

  /**
   * Additional information set if in an influencer's context
   *
   * @generated from field: librarian.proto.InfluencerContextInfo influencer_info = 18;
   */
  influencerInfo?: InfluencerContextInfo;

  /**
   * @generated from field: repeated librarian.proto.Campaign.InfluencersInCampaignStatusCount in_campaign_statuses = 20;
   */
  inCampaignStatuses: Campaign_InfluencersInCampaignStatusCount[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp posts_last_refreshed = 23;
   */
  postsLastRefreshed?: Timestamp;

  /**
   * @generated from field: librarian.proto.CampaignPerformanceSummary perf_summary = 34;
   */
  perfSummary?: CampaignPerformanceSummary;

  /**
   * @generated from field: google.protobuf.StringValue sharing_token = 29;
   */
  sharingToken?: string;

  /**
   * @generated from field: repeated common.proto.CampaignKeyword keywords = 30;
   */
  keywords: CampaignKeyword[] = [];

  /**
   * @generated from field: repeated common.proto.CampaignTrackingSettingsPb tracking_settings = 44;
   */
  trackingSettings: CampaignTrackingSettingsPb[] = [];

  /**
   * @generated from field: repeated librarian.proto.EntityFieldValue campaign_field_values = 43;
   */
  campaignFieldValues: EntityFieldValue[] = [];

  /**
   * @generated from field: bool exclude_performance = 31;
   */
  excludePerformance = false;

  /**
   * @generated from field: int64 landing_page_id = 38;
   */
  landingPageId = protoInt64.zero;

  /**
   * Currency of workspace associated to the campaign
   * Use by PPT generator with endpoint GetCampaignAPI
   *
   * @generated from field: common.proto.CurrencyEnum currency = 32;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: librarian.proto.PostsPerNetworkCount posts_per_network_count = 36;
   */
  postsPerNetworkCount?: PostsPerNetworkCount;

  /**
   * @generated from field: common.proto.NetworkList distinct_creators_networks = 37;
   */
  distinctCreatorsNetworks?: NetworkList;

  /**
   * @generated from field: repeated common.proto.FavoritesLabel labels = 41;
   */
  labels: FavoritesLabel[] = [];

  /**
   * @generated from field: bool exclude_from_story_polling = 39;
   */
  excludeFromStoryPolling = false;

  /**
   * @generated from field: int32 stories_priority = 40;
   */
  storiesPriority = 0;

  /**
   * @generated from field: repeated common.proto.CampaignKeyword excluded_keywords = 45;
   */
  excludedKeywords: CampaignKeyword[] = [];

  constructor(data?: PartialMessage<Campaign>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Campaign";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 22, name: "encrypted_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "public_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(CampaignStatus) },
    { no: 42, name: "pinned", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 24, name: "cost", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 33, name: "influencers_total_cost", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "created", kind: "message", T: Timestamp },
    { no: 9, name: "updated", kind: "message", T: Timestamp },
    { no: 12, name: "start", kind: "message", T: Timestamp },
    { no: 13, name: "end", kind: "message", T: Timestamp },
    { no: 15, name: "number_of_influencers", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 16, name: "influencers_state", kind: "message", T: Campaign_InfluencersStateCount, repeated: true },
    { no: 18, name: "influencer_info", kind: "message", T: InfluencerContextInfo },
    { no: 20, name: "in_campaign_statuses", kind: "message", T: Campaign_InfluencersInCampaignStatusCount, repeated: true },
    { no: 23, name: "posts_last_refreshed", kind: "message", T: Timestamp },
    { no: 34, name: "perf_summary", kind: "message", T: CampaignPerformanceSummary },
    { no: 29, name: "sharing_token", kind: "message", T: StringValue },
    { no: 30, name: "keywords", kind: "message", T: CampaignKeyword, repeated: true },
    { no: 44, name: "tracking_settings", kind: "message", T: CampaignTrackingSettingsPb, repeated: true },
    { no: 43, name: "campaign_field_values", kind: "message", T: EntityFieldValue, repeated: true },
    { no: 31, name: "exclude_performance", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 38, name: "landing_page_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 32, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 36, name: "posts_per_network_count", kind: "message", T: PostsPerNetworkCount },
    { no: 37, name: "distinct_creators_networks", kind: "message", T: NetworkList },
    { no: 41, name: "labels", kind: "message", T: FavoritesLabel, repeated: true },
    { no: 39, name: "exclude_from_story_polling", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 40, name: "stories_priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 45, name: "excluded_keywords", kind: "message", T: CampaignKeyword, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Campaign {
    return new Campaign().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Campaign {
    return new Campaign().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Campaign {
    return new Campaign().fromJsonString(jsonString, options);
  }

  static equals(a: Campaign | PlainMessage<Campaign> | undefined, b: Campaign | PlainMessage<Campaign> | undefined): boolean {
    return proto3.util.equals(Campaign, a, b);
  }
}

/**
 * @generated from message librarian.proto.Campaign.InfluencersStateCount
 */
export class Campaign_InfluencersStateCount extends Message<Campaign_InfluencersStateCount> {
  /**
   * @generated from field: common.proto.InfluencerState state = 1;
   */
  state = InfluencerState.STATE_UNDEFINED;

  /**
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  constructor(data?: PartialMessage<Campaign_InfluencersStateCount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Campaign.InfluencersStateCount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "state", kind: "enum", T: proto3.getEnumType(InfluencerState) },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Campaign_InfluencersStateCount {
    return new Campaign_InfluencersStateCount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Campaign_InfluencersStateCount {
    return new Campaign_InfluencersStateCount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Campaign_InfluencersStateCount {
    return new Campaign_InfluencersStateCount().fromJsonString(jsonString, options);
  }

  static equals(a: Campaign_InfluencersStateCount | PlainMessage<Campaign_InfluencersStateCount> | undefined, b: Campaign_InfluencersStateCount | PlainMessage<Campaign_InfluencersStateCount> | undefined): boolean {
    return proto3.util.equals(Campaign_InfluencersStateCount, a, b);
  }
}

/**
 * @generated from message librarian.proto.Campaign.InfluencersInCampaignStatusCount
 */
export class Campaign_InfluencersInCampaignStatusCount extends Message<Campaign_InfluencersInCampaignStatusCount> {
  /**
   * @generated from field: common.proto.InCampaignStatus status = 1;
   */
  status = InCampaignStatus.STATUS_UNDEFINED;

  /**
   * @generated from field: int64 count = 2;
   */
  count = protoInt64.zero;

  constructor(data?: PartialMessage<Campaign_InfluencersInCampaignStatusCount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Campaign.InfluencersInCampaignStatusCount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "enum", T: proto3.getEnumType(InCampaignStatus) },
    { no: 2, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Campaign_InfluencersInCampaignStatusCount {
    return new Campaign_InfluencersInCampaignStatusCount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Campaign_InfluencersInCampaignStatusCount {
    return new Campaign_InfluencersInCampaignStatusCount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Campaign_InfluencersInCampaignStatusCount {
    return new Campaign_InfluencersInCampaignStatusCount().fromJsonString(jsonString, options);
  }

  static equals(a: Campaign_InfluencersInCampaignStatusCount | PlainMessage<Campaign_InfluencersInCampaignStatusCount> | undefined, b: Campaign_InfluencersInCampaignStatusCount | PlainMessage<Campaign_InfluencersInCampaignStatusCount> | undefined): boolean {
    return proto3.util.equals(Campaign_InfluencersInCampaignStatusCount, a, b);
  }
}

/**
 * @generated from message librarian.proto.Campaigns
 */
export class Campaigns extends Message<Campaigns> {
  /**
   * @generated from field: repeated librarian.proto.Campaign campaigns = 1;
   */
  campaigns: Campaign[] = [];

  constructor(data?: PartialMessage<Campaigns>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Campaigns";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaigns", kind: "message", T: Campaign, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Campaigns {
    return new Campaigns().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Campaigns {
    return new Campaigns().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Campaigns {
    return new Campaigns().fromJsonString(jsonString, options);
  }

  static equals(a: Campaigns | PlainMessage<Campaigns> | undefined, b: Campaigns | PlainMessage<Campaigns> | undefined): boolean {
    return proto3.util.equals(Campaigns, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateInfluencerStateRequest
 */
export class UpdateInfluencerStateRequest extends Message<UpdateInfluencerStateRequest> {
  /**
   * @generated from field: repeated librarian.proto.UpdateInfluencerStateRequest.UpdateStateMessage updates = 1;
   */
  updates: UpdateInfluencerStateRequest_UpdateStateMessage[] = [];

  constructor(data?: PartialMessage<UpdateInfluencerStateRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateInfluencerStateRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "updates", kind: "message", T: UpdateInfluencerStateRequest_UpdateStateMessage, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateInfluencerStateRequest {
    return new UpdateInfluencerStateRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateInfluencerStateRequest {
    return new UpdateInfluencerStateRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateInfluencerStateRequest {
    return new UpdateInfluencerStateRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateInfluencerStateRequest | PlainMessage<UpdateInfluencerStateRequest> | undefined, b: UpdateInfluencerStateRequest | PlainMessage<UpdateInfluencerStateRequest> | undefined): boolean {
    return proto3.util.equals(UpdateInfluencerStateRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateInfluencerStateRequest.UpdateStateMessage
 */
export class UpdateInfluencerStateRequest_UpdateStateMessage extends Message<UpdateInfluencerStateRequest_UpdateStateMessage> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: string influencer_id = 2;
   */
  influencerId = "";

  /**
   * @generated from field: common.proto.InfluencerState state = 3;
   */
  state = InfluencerState.STATE_UNDEFINED;

  constructor(data?: PartialMessage<UpdateInfluencerStateRequest_UpdateStateMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateInfluencerStateRequest.UpdateStateMessage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "influencer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "state", kind: "enum", T: proto3.getEnumType(InfluencerState) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateInfluencerStateRequest_UpdateStateMessage {
    return new UpdateInfluencerStateRequest_UpdateStateMessage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateInfluencerStateRequest_UpdateStateMessage {
    return new UpdateInfluencerStateRequest_UpdateStateMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateInfluencerStateRequest_UpdateStateMessage {
    return new UpdateInfluencerStateRequest_UpdateStateMessage().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateInfluencerStateRequest_UpdateStateMessage | PlainMessage<UpdateInfluencerStateRequest_UpdateStateMessage> | undefined, b: UpdateInfluencerStateRequest_UpdateStateMessage | PlainMessage<UpdateInfluencerStateRequest_UpdateStateMessage> | undefined): boolean {
    return proto3.util.equals(UpdateInfluencerStateRequest_UpdateStateMessage, a, b);
  }
}

/**
 * @generated from message librarian.proto.InfluencerCampaignsRequest
 */
export class InfluencerCampaignsRequest extends Message<InfluencerCampaignsRequest> {
  /**
   * @generated from field: int64 campaign_id = 2;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.FlexibleCreatorSelector selector = 9;
   */
  selector?: FlexibleCreatorSelector;

  /**
   * @generated from field: bool is_last_in_batch = 5;
   */
  isLastInBatch = false;

  /**
   * @generated from field: repeated int64 tags_to_add = 6;
   */
  tagsToAdd: bigint[] = [];

  /**
   * This message is used both programmatically and in REST apis. Obviously the user_id in this ctx is only to be considered
   * for programmatic requests. REST will grab it from the cookie.
   *
   * @generated from field: common.proto.NightwatchCtx nightwatch_ctx = 1;
   */
  nightwatchCtx?: NightwatchCtx;

  /**
   * used to add influencer without taking on consideration the limit number of influencers in a campaign (used only in unmerge now)
   *
   * @generated from field: bool bypass_restriction_limit_influencer_campaign = 8;
   */
  bypassRestrictionLimitInfluencerCampaign = false;

  constructor(data?: PartialMessage<InfluencerCampaignsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InfluencerCampaignsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "selector", kind: "message", T: FlexibleCreatorSelector },
    { no: 5, name: "is_last_in_batch", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "tags_to_add", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 1, name: "nightwatch_ctx", kind: "message", T: NightwatchCtx },
    { no: 8, name: "bypass_restriction_limit_influencer_campaign", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerCampaignsRequest {
    return new InfluencerCampaignsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerCampaignsRequest {
    return new InfluencerCampaignsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerCampaignsRequest {
    return new InfluencerCampaignsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerCampaignsRequest | PlainMessage<InfluencerCampaignsRequest> | undefined, b: InfluencerCampaignsRequest | PlainMessage<InfluencerCampaignsRequest> | undefined): boolean {
    return proto3.util.equals(InfluencerCampaignsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetCampaignCsvRequest
 */
export class GetCampaignCsvRequest extends Message<GetCampaignCsvRequest> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * Id of the user making the request.
   *
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<GetCampaignCsvRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetCampaignCsvRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCampaignCsvRequest {
    return new GetCampaignCsvRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCampaignCsvRequest {
    return new GetCampaignCsvRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCampaignCsvRequest {
    return new GetCampaignCsvRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCampaignCsvRequest | PlainMessage<GetCampaignCsvRequest> | undefined, b: GetCampaignCsvRequest | PlainMessage<GetCampaignCsvRequest> | undefined): boolean {
    return proto3.util.equals(GetCampaignCsvRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UserCampaignRequest
 */
export class UserCampaignRequest extends Message<UserCampaignRequest> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<UserCampaignRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UserCampaignRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserCampaignRequest {
    return new UserCampaignRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserCampaignRequest {
    return new UserCampaignRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserCampaignRequest {
    return new UserCampaignRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UserCampaignRequest | PlainMessage<UserCampaignRequest> | undefined, b: UserCampaignRequest | PlainMessage<UserCampaignRequest> | undefined): boolean {
    return proto3.util.equals(UserCampaignRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.HaveVisualRequest
 */
export class HaveVisualRequest extends Message<HaveVisualRequest> {
  /**
   * @generated from field: repeated string influencer_ids = 1 [deprecated = true];
   * @deprecated
   */
  influencerIds: string[] = [];

  /**
   * @generated from field: librarian.proto.FlexibleCreatorSelector selector = 3;
   */
  selector?: FlexibleCreatorSelector;

  /**
   * @generated from field: int64 campaign_id = 2;
   */
  campaignId = protoInt64.zero;

  constructor(data?: PartialMessage<HaveVisualRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.HaveVisualRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "selector", kind: "message", T: FlexibleCreatorSelector },
    { no: 2, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): HaveVisualRequest {
    return new HaveVisualRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): HaveVisualRequest {
    return new HaveVisualRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): HaveVisualRequest {
    return new HaveVisualRequest().fromJsonString(jsonString, options);
  }

  static equals(a: HaveVisualRequest | PlainMessage<HaveVisualRequest> | undefined, b: HaveVisualRequest | PlainMessage<HaveVisualRequest> | undefined): boolean {
    return proto3.util.equals(HaveVisualRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetVisualCreatorsRequest
 */
export class GetVisualCreatorsRequest extends Message<GetVisualCreatorsRequest> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: repeated int64 campaign_ids = 2;
   */
  campaignIds: bigint[] = [];

  constructor(data?: PartialMessage<GetVisualCreatorsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetVisualCreatorsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "campaign_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetVisualCreatorsRequest {
    return new GetVisualCreatorsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetVisualCreatorsRequest {
    return new GetVisualCreatorsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetVisualCreatorsRequest {
    return new GetVisualCreatorsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetVisualCreatorsRequest | PlainMessage<GetVisualCreatorsRequest> | undefined, b: GetVisualCreatorsRequest | PlainMessage<GetVisualCreatorsRequest> | undefined): boolean {
    return proto3.util.equals(GetVisualCreatorsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GenerikCampaignAudience
 */
export class GenerikCampaignAudience extends Message<GenerikCampaignAudience> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * percentage of females in the audience.
   *
   * @generated from field: double female_percentage = 2;
   */
  femalePercentage = 0;

  /**
   * Top countries in the audience
   *
   * Note(hadrien) GeographyReport.name == countryCode
   * Already the case for Influencer audience report
   * maybe we should have a dedicated proto
   *
   * @generated from field: common.proto.GeographyReports countries = 3;
   */
  countries?: GeographyReports;

  /**
   * Distribution of ages in the female audience.
   *
   * @generated from field: common.proto.AgesReport ages_report_female = 4;
   */
  agesReportFemale?: AgesReport;

  /**
   * Distribution of ages in the male audience.
   *
   * @generated from field: common.proto.AgesReport ages_report_male = 5;
   */
  agesReportMale?: AgesReport;

  constructor(data?: PartialMessage<GenerikCampaignAudience>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GenerikCampaignAudience";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "female_percentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "countries", kind: "message", T: GeographyReports },
    { no: 4, name: "ages_report_female", kind: "message", T: AgesReport },
    { no: 5, name: "ages_report_male", kind: "message", T: AgesReport },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikCampaignAudience {
    return new GenerikCampaignAudience().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikCampaignAudience {
    return new GenerikCampaignAudience().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikCampaignAudience {
    return new GenerikCampaignAudience().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikCampaignAudience | PlainMessage<GenerikCampaignAudience> | undefined, b: GenerikCampaignAudience | PlainMessage<GenerikCampaignAudience> | undefined): boolean {
    return proto3.util.equals(GenerikCampaignAudience, a, b);
  }
}

/**
 * @generated from message librarian.proto.CampaignMultiReport
 */
export class CampaignMultiReport extends Message<CampaignMultiReport> {
  /**
   * @generated from field: common.proto.CampaignPerformance performance = 3;
   */
  performance?: CampaignPerformance;

  /**
   * @generated from field: repeated librarian.proto.GenerikCampaignAudience audience = 4;
   */
  audience: GenerikCampaignAudience[] = [];

  /**
   * The overall count of active creators all social networks included.
   * If a creator has several accounts (that we have successfully identified), it should be counted only once.
   *
   * @generated from field: int32 active_creators = 100;
   */
  activeCreators = 0;

  /**
   * The sum of the following base on the networks where creators have posted.
   *
   * @generated from field: int32 campaign_reach = 101;
   */
  campaignReach = 0;

  /**
   * Cost of filtered influencers
   *
   * @generated from field: int64 influencers_total_cost = 102;
   */
  influencersTotalCost = protoInt64.zero;

  /**
   * @generated from field: int64 total_emv = 107;
   */
  totalEmv = protoInt64.zero;

  /**
   * @generated from field: int64 total_engagements = 108;
   */
  totalEngagements = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.FloatValue roi = 105;
   */
  roi?: number;

  /**
   * @generated from field: google.protobuf.FloatValue cpe = 106;
   */
  cpe?: number;

  constructor(data?: PartialMessage<CampaignMultiReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignMultiReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "performance", kind: "message", T: CampaignPerformance },
    { no: 4, name: "audience", kind: "message", T: GenerikCampaignAudience, repeated: true },
    { no: 100, name: "active_creators", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 101, name: "campaign_reach", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 102, name: "influencers_total_cost", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 107, name: "total_emv", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 108, name: "total_engagements", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 105, name: "roi", kind: "message", T: FloatValue },
    { no: 106, name: "cpe", kind: "message", T: FloatValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignMultiReport {
    return new CampaignMultiReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignMultiReport {
    return new CampaignMultiReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignMultiReport {
    return new CampaignMultiReport().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignMultiReport | PlainMessage<CampaignMultiReport> | undefined, b: CampaignMultiReport | PlainMessage<CampaignMultiReport> | undefined): boolean {
    return proto3.util.equals(CampaignMultiReport, a, b);
  }
}

/**
 * @generated from message librarian.proto.MultiTimeline
 */
export class MultiTimeline extends Message<MultiTimeline> {
  /**
   * @generated from field: repeated librarian.proto.MultiTimepoint points = 2;
   */
  points: MultiTimepoint[] = [];

  constructor(data?: PartialMessage<MultiTimeline>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.MultiTimeline";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "points", kind: "message", T: MultiTimepoint, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MultiTimeline {
    return new MultiTimeline().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MultiTimeline {
    return new MultiTimeline().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MultiTimeline {
    return new MultiTimeline().fromJsonString(jsonString, options);
  }

  static equals(a: MultiTimeline | PlainMessage<MultiTimeline> | undefined, b: MultiTimeline | PlainMessage<MultiTimeline> | undefined): boolean {
    return proto3.util.equals(MultiTimeline, a, b);
  }
}

/**
 * @generated from message librarian.proto.MultiTimepoint
 */
export class MultiTimepoint extends Message<MultiTimepoint> {
  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 1;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: repeated librarian.proto.GenerikPoint generik = 6;
   */
  generik: GenerikPoint[] = [];

  constructor(data?: PartialMessage<MultiTimepoint>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.MultiTimepoint";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "timestamp", kind: "message", T: Timestamp },
    { no: 6, name: "generik", kind: "message", T: GenerikPoint, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MultiTimepoint {
    return new MultiTimepoint().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MultiTimepoint {
    return new MultiTimepoint().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MultiTimepoint {
    return new MultiTimepoint().fromJsonString(jsonString, options);
  }

  static equals(a: MultiTimepoint | PlainMessage<MultiTimepoint> | undefined, b: MultiTimepoint | PlainMessage<MultiTimepoint> | undefined): boolean {
    return proto3.util.equals(MultiTimepoint, a, b);
  }
}

/**
 * @generated from message librarian.proto.GenerikPoint
 */
export class GenerikPoint extends Message<GenerikPoint> {
  /**
   * We group some stuff together: all TWITTER are together, INSTA images, carousels and videos are together.
   * It shall be guaranteed in this list that all types are for the same network.
   *
   * @generated from field: repeated common.proto.PostType post_type = 1;
   */
  postType: PostType[] = [];

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0;

  constructor(data?: PartialMessage<GenerikPoint>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GenerikPoint";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType), repeated: true },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikPoint {
    return new GenerikPoint().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikPoint {
    return new GenerikPoint().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikPoint {
    return new GenerikPoint().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikPoint | PlainMessage<GenerikPoint> | undefined, b: GenerikPoint | PlainMessage<GenerikPoint> | undefined): boolean {
    return proto3.util.equals(GenerikPoint, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateFavoritesRequest
 */
export class UpdateFavoritesRequest extends Message<UpdateFavoritesRequest> {
  /**
   * if this is true we delete all tags associated to this influencer_id (default tag included)
   *
   * @generated from field: bool is_delete = 2;
   */
  isDelete = false;

  /**
   * @generated from field: repeated int64 tags_to_add = 3;
   */
  tagsToAdd: bigint[] = [];

  /**
   * @generated from field: repeated int64 tags_to_delete = 4;
   */
  tagsToDelete: bigint[] = [];

  /**
   * only used on programmatic calls from tortuga
   *
   * @generated from field: int64 user_id = 5;
   */
  userId = protoInt64.zero;

  /**
   * Who to add/remove etc labels to.
   *
   * @generated from field: librarian.proto.FlexibleCreatorSelector selector = 6;
   */
  selector?: FlexibleCreatorSelector;

  /**
   * @generated from field: common.proto.NightwatchCtx nightwatch_ctx = 7;
   */
  nightwatchCtx?: NightwatchCtx;

  constructor(data?: PartialMessage<UpdateFavoritesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateFavoritesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "is_delete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "tags_to_add", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "tags_to_delete", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 5, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "selector", kind: "message", T: FlexibleCreatorSelector },
    { no: 7, name: "nightwatch_ctx", kind: "message", T: NightwatchCtx },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFavoritesRequest {
    return new UpdateFavoritesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFavoritesRequest {
    return new UpdateFavoritesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFavoritesRequest {
    return new UpdateFavoritesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFavoritesRequest | PlainMessage<UpdateFavoritesRequest> | undefined, b: UpdateFavoritesRequest | PlainMessage<UpdateFavoritesRequest> | undefined): boolean {
    return proto3.util.equals(UpdateFavoritesRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadInfluencersCampaignRequest
 */
export class UploadInfluencersCampaignRequest extends Message<UploadInfluencersCampaignRequest> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: repeated string names = 2;
   */
  names: string[] = [];

  /**
   * For backwards compatibility and safety reasons, any illegal value (unset, 0, -1, etc)
   * will be treated as INSTA.
   *
   * @generated from field: common.proto.Network network = 3;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: repeated int64 tags_to_add = 4;
   */
  tagsToAdd: bigint[] = [];

  constructor(data?: PartialMessage<UploadInfluencersCampaignRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadInfluencersCampaignRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "names", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 4, name: "tags_to_add", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadInfluencersCampaignRequest {
    return new UploadInfluencersCampaignRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadInfluencersCampaignRequest {
    return new UploadInfluencersCampaignRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadInfluencersCampaignRequest {
    return new UploadInfluencersCampaignRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadInfluencersCampaignRequest | PlainMessage<UploadInfluencersCampaignRequest> | undefined, b: UploadInfluencersCampaignRequest | PlainMessage<UploadInfluencersCampaignRequest> | undefined): boolean {
    return proto3.util.equals(UploadInfluencersCampaignRequest, a, b);
  }
}

/**
 * Note(christian) The endpoint is now generik. It shall work automatically for all networks that it could make sense
 * to download.
 *
 * For instance youtube videos cannot be downloaded, they are always played from youtube. Tweets cannot be downloaded
 * because it is most likely text (twitter images and videos are not antkept).
 *
 * There is no network in this request but the frontend can populate filter.post_types to determine the network being
 * downloaded.
 *
 * @generated from message librarian.proto.DownloadAssetsRequest
 */
export class DownloadAssetsRequest extends Message<DownloadAssetsRequest> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: librarian.proto.VisualSelector visual_selector = 8;
   */
  visualSelector?: VisualSelector;

  /**
   * @generated from oneof librarian.proto.DownloadAssetsRequest.selector
   */
  selector: {
    /**
     * Download all the assets in this list (note that they must also be in the campaign).
     *
     * @generated from field: common.proto.StringList curated_select = 4 [deprecated = true];
     * @deprecated
     */
    value: StringList;
    case: "curatedSelect";
  } | {
    /**
     * Download all the visuals matching this filter (note that they must also be in the campaign).
     *
     * @generated from field: librarian.proto.DynamicFilter dynamic_filter = 7 [deprecated = true];
     * @deprecated
     */
    value: DynamicFilter;
    case: "dynamicFilter";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * Will be filled by backend
   *
   * @generated from field: int64 user_id = 6;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<DownloadAssetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DownloadAssetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "visual_selector", kind: "message", T: VisualSelector },
    { no: 4, name: "curated_select", kind: "message", T: StringList, oneof: "selector" },
    { no: 7, name: "dynamic_filter", kind: "message", T: DynamicFilter, oneof: "selector" },
    { no: 6, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DownloadAssetsRequest {
    return new DownloadAssetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DownloadAssetsRequest {
    return new DownloadAssetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DownloadAssetsRequest {
    return new DownloadAssetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DownloadAssetsRequest | PlainMessage<DownloadAssetsRequest> | undefined, b: DownloadAssetsRequest | PlainMessage<DownloadAssetsRequest> | undefined): boolean {
    return proto3.util.equals(DownloadAssetsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.LibrarianDownloadAssetsRequest
 */
export class LibrarianDownloadAssetsRequest extends Message<LibrarianDownloadAssetsRequest> {
  /**
   * @generated from oneof librarian.proto.LibrarianDownloadAssetsRequest.selector
   */
  selector: {
    /**
     * @generated from field: librarian.proto.DownloadAssetsRequest campaigns_req = 1;
     */
    value: DownloadAssetsRequest;
    case: "campaignsReq";
  } | {
    /**
     * @generated from field: librarian.proto.DownloadPoolAssetsRequest pool_req = 2;
     */
    value: DownloadPoolAssetsRequest;
    case: "poolReq";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<LibrarianDownloadAssetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LibrarianDownloadAssetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaigns_req", kind: "message", T: DownloadAssetsRequest, oneof: "selector" },
    { no: 2, name: "pool_req", kind: "message", T: DownloadPoolAssetsRequest, oneof: "selector" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LibrarianDownloadAssetsRequest {
    return new LibrarianDownloadAssetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LibrarianDownloadAssetsRequest {
    return new LibrarianDownloadAssetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LibrarianDownloadAssetsRequest {
    return new LibrarianDownloadAssetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LibrarianDownloadAssetsRequest | PlainMessage<LibrarianDownloadAssetsRequest> | undefined, b: LibrarianDownloadAssetsRequest | PlainMessage<LibrarianDownloadAssetsRequest> | undefined): boolean {
    return proto3.util.equals(LibrarianDownloadAssetsRequest, a, b);
  }
}

/**
 * use to generate a hash of a campaign
 *
 * @generated from message librarian.proto.CampaignReportHasher
 */
export class CampaignReportHasher extends Message<CampaignReportHasher> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.Timestamp updated = 2;
   */
  updated?: Timestamp;

  /**
   * @generated from field: repeated int64 influencers = 3;
   */
  influencers: bigint[] = [];

  /**
   * @generated from field: repeated librarian.proto.CampaignReportHasher.HashedVisual hashed_visuals = 4;
   */
  hashedVisuals: CampaignReportHasher_HashedVisual[] = [];

  constructor(data?: PartialMessage<CampaignReportHasher>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignReportHasher";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updated", kind: "message", T: Timestamp },
    { no: 3, name: "influencers", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "hashed_visuals", kind: "message", T: CampaignReportHasher_HashedVisual, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignReportHasher {
    return new CampaignReportHasher().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignReportHasher {
    return new CampaignReportHasher().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignReportHasher {
    return new CampaignReportHasher().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignReportHasher | PlainMessage<CampaignReportHasher> | undefined, b: CampaignReportHasher | PlainMessage<CampaignReportHasher> | undefined): boolean {
    return proto3.util.equals(CampaignReportHasher, a, b);
  }
}

/**
 * @generated from message librarian.proto.CampaignReportHasher.HashedVisual
 */
export class CampaignReportHasher_HashedVisual extends Message<CampaignReportHasher_HashedVisual> {
  /**
   * @generated from field: common.proto.MediaIdPb id = 1;
   */
  id?: MediaIdPb;

  /**
   * depending on the network this might be likes, views, etc...
   * the only thing that matters is that it changes when we refresh a visual...
   *
   * @generated from field: int64 interactions_hash = 2;
   */
  interactionsHash = protoInt64.zero;

  constructor(data?: PartialMessage<CampaignReportHasher_HashedVisual>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignReportHasher.HashedVisual";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "message", T: MediaIdPb },
    { no: 2, name: "interactions_hash", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignReportHasher_HashedVisual {
    return new CampaignReportHasher_HashedVisual().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignReportHasher_HashedVisual {
    return new CampaignReportHasher_HashedVisual().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignReportHasher_HashedVisual {
    return new CampaignReportHasher_HashedVisual().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignReportHasher_HashedVisual | PlainMessage<CampaignReportHasher_HashedVisual> | undefined, b: CampaignReportHasher_HashedVisual | PlainMessage<CampaignReportHasher_HashedVisual> | undefined): boolean {
    return proto3.util.equals(CampaignReportHasher_HashedVisual, a, b);
  }
}

/**
 * @generated from message librarian.proto.ExportOptions
 */
export class ExportOptions extends Message<ExportOptions> {
  /**
   * @generated from field: bool summary = 1;
   */
  summary = false;

  /**
   * @generated from field: bool performance = 2;
   */
  performance = false;

  /**
   * @generated from field: bool trombinoscope = 3;
   */
  trombinoscope = false;

  /**
   * @generated from field: bool profiles = 4;
   */
  profiles = false;

  /**
   * Deprecated: use visuals_section field
   *
   * @generated from field: bool visuals = 5 [deprecated = true];
   * @deprecated
   */
  visuals = false;

  /**
   * @generated from field: bool active_influencers_only = 7;
   */
  activeInfluencersOnly = false;

  /**
   * @generated from field: librarian.proto.ExportOptions.VisualsDensity visuals_density = 8;
   */
  visualsDensity = ExportOptions_VisualsDensity.DEFAULT;

  /**
   * @generated from field: librarian.proto.UserSort influencers_sort = 9;
   */
  influencersSort = UserSort.DEFAULT;

  /**
   * default to LIKES
   *
   * @generated from field: librarian.proto.VisualSort visual_sort = 11;
   */
  visualSort = VisualSort.NO_SORT;

  /**
   * @generated from field: librarian.proto.ExportOptions.VisualsSection visuals_section = 12;
   */
  visualsSection = ExportOptions_VisualsSection.DEFAULT;

  /**
   * networks we want to show in PPT
   *
   * @generated from field: repeated common.proto.Network networks = 10;
   */
  networks: Network[] = [];

  constructor(data?: PartialMessage<ExportOptions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ExportOptions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "summary", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "performance", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "trombinoscope", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "profiles", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "visuals", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "active_influencers_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "visuals_density", kind: "enum", T: proto3.getEnumType(ExportOptions_VisualsDensity) },
    { no: 9, name: "influencers_sort", kind: "enum", T: proto3.getEnumType(UserSort) },
    { no: 11, name: "visual_sort", kind: "enum", T: proto3.getEnumType(VisualSort) },
    { no: 12, name: "visuals_section", kind: "enum", T: proto3.getEnumType(ExportOptions_VisualsSection) },
    { no: 10, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportOptions {
    return new ExportOptions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportOptions {
    return new ExportOptions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportOptions {
    return new ExportOptions().fromJsonString(jsonString, options);
  }

  static equals(a: ExportOptions | PlainMessage<ExportOptions> | undefined, b: ExportOptions | PlainMessage<ExportOptions> | undefined): boolean {
    return proto3.util.equals(ExportOptions, a, b);
  }
}

/**
 * @generated from enum librarian.proto.ExportOptions.VisualsDensity
 */
export enum ExportOptions_VisualsDensity {
  /**
   * @generated from enum value: DEFAULT = 0;
   */
  DEFAULT = 0,

  /**
   * @generated from enum value: COMPACT = 1;
   */
  COMPACT = 1,

  /**
   * @generated from enum value: LARGE = 2;
   */
  LARGE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ExportOptions_VisualsDensity)
proto3.util.setEnumType(ExportOptions_VisualsDensity, "librarian.proto.ExportOptions.VisualsDensity", [
  { no: 0, name: "DEFAULT" },
  { no: 1, name: "COMPACT" },
  { no: 2, name: "LARGE" },
]);

/**
 * @generated from enum librarian.proto.ExportOptions.VisualsSection
 */
export enum ExportOptions_VisualsSection {
  /**
   * @generated from enum value: VISUALS_SECTION_DEFAULT = 0;
   */
  DEFAULT = 0,

  /**
   * @generated from enum value: VISUALS_SECTION_NONE = 1;
   */
  NONE = 1,

  /**
   * @generated from enum value: VISUALS_SECTION_PER_INFLUENCER = 2;
   */
  PER_INFLUENCER = 2,

  /**
   * @generated from enum value: VISUALS_SECTION_TOP = 3;
   */
  TOP = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ExportOptions_VisualsSection)
proto3.util.setEnumType(ExportOptions_VisualsSection, "librarian.proto.ExportOptions.VisualsSection", [
  { no: 0, name: "VISUALS_SECTION_DEFAULT" },
  { no: 1, name: "VISUALS_SECTION_NONE" },
  { no: 2, name: "VISUALS_SECTION_PER_INFLUENCER" },
  { no: 3, name: "VISUALS_SECTION_TOP" },
]);

/**
 * @generated from message librarian.proto.GetReportRequest
 */
export class GetReportRequest extends Message<GetReportRequest> {
  /**
   * Report for this campaign.
   *
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.DynamicFilter dynamic_filter = 4;
   */
  dynamicFilter?: DynamicFilter;

  /**
   * For PPT
   *
   * @generated from field: librarian.proto.ExportOptions export_options = 3;
   */
  exportOptions?: ExportOptions;

  constructor(data?: PartialMessage<GetReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "dynamic_filter", kind: "message", T: DynamicFilter },
    { no: 3, name: "export_options", kind: "message", T: ExportOptions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetReportRequest {
    return new GetReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetReportRequest {
    return new GetReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetReportRequest {
    return new GetReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetReportRequest | PlainMessage<GetReportRequest> | undefined, b: GetReportRequest | PlainMessage<GetReportRequest> | undefined): boolean {
    return proto3.util.equals(GetReportRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.RefreshVisualsReq
 */
export class RefreshVisualsReq extends Message<RefreshVisualsReq> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: bool admin_is_forcing_stories_and_reels = 4;
   */
  adminIsForcingStoriesAndReels = false;

  /**
   * @generated from field: google.protobuf.StringValue influencer_id = 3;
   */
  influencerId?: string;

  constructor(data?: PartialMessage<RefreshVisualsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RefreshVisualsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "admin_is_forcing_stories_and_reels", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "influencer_id", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefreshVisualsReq {
    return new RefreshVisualsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefreshVisualsReq {
    return new RefreshVisualsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefreshVisualsReq {
    return new RefreshVisualsReq().fromJsonString(jsonString, options);
  }

  static equals(a: RefreshVisualsReq | PlainMessage<RefreshVisualsReq> | undefined, b: RefreshVisualsReq | PlainMessage<RefreshVisualsReq> | undefined): boolean {
    return proto3.util.equals(RefreshVisualsReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditVisualPerfReq
 */
export class EditVisualPerfReq extends Message<EditVisualPerfReq> {
  /**
   * @generated from field: string media_id = 1;
   */
  mediaId = "";

  /**
   * @generated from field: common.proto.VisualEditedPerf edited_perf = 2;
   */
  editedPerf?: VisualEditedPerf;

  constructor(data?: PartialMessage<EditVisualPerfReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditVisualPerfReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "edited_perf", kind: "message", T: VisualEditedPerf },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditVisualPerfReq {
    return new EditVisualPerfReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditVisualPerfReq {
    return new EditVisualPerfReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditVisualPerfReq {
    return new EditVisualPerfReq().fromJsonString(jsonString, options);
  }

  static equals(a: EditVisualPerfReq | PlainMessage<EditVisualPerfReq> | undefined, b: EditVisualPerfReq | PlainMessage<EditVisualPerfReq> | undefined): boolean {
    return proto3.util.equals(EditVisualPerfReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateCampaignResponse
 */
export class UpdateCampaignResponse extends Message<UpdateCampaignResponse> {
  /**
   * @generated from field: string task_token = 1;
   */
  taskToken = "";

  constructor(data?: PartialMessage<UpdateCampaignResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateCampaignResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "task_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCampaignResponse {
    return new UpdateCampaignResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCampaignResponse {
    return new UpdateCampaignResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCampaignResponse {
    return new UpdateCampaignResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCampaignResponse | PlainMessage<UpdateCampaignResponse> | undefined, b: UpdateCampaignResponse | PlainMessage<UpdateCampaignResponse> | undefined): boolean {
    return proto3.util.equals(UpdateCampaignResponse, a, b);
  }
}

/**
 * Use to mark/unmark a post to many campaigns
 *
 * @generated from message librarian.proto.AddOrDeleteVisualOnCampaignsRequest
 */
export class AddOrDeleteVisualOnCampaignsRequest extends Message<AddOrDeleteVisualOnCampaignsRequest> {
  /**
   * @generated from field: string media_id = 1;
   */
  mediaId = "";

  /**
   * @generated from field: repeated int64 add_media_to_campaigns_ids = 2;
   */
  addMediaToCampaignsIds: bigint[] = [];

  /**
   * @generated from field: common.proto.NightwatchCtx nightwatch_ctx = 3;
   */
  nightwatchCtx?: NightwatchCtx;

  constructor(data?: PartialMessage<AddOrDeleteVisualOnCampaignsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AddOrDeleteVisualOnCampaignsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "add_media_to_campaigns_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 3, name: "nightwatch_ctx", kind: "message", T: NightwatchCtx },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddOrDeleteVisualOnCampaignsRequest {
    return new AddOrDeleteVisualOnCampaignsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddOrDeleteVisualOnCampaignsRequest {
    return new AddOrDeleteVisualOnCampaignsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddOrDeleteVisualOnCampaignsRequest {
    return new AddOrDeleteVisualOnCampaignsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddOrDeleteVisualOnCampaignsRequest | PlainMessage<AddOrDeleteVisualOnCampaignsRequest> | undefined, b: AddOrDeleteVisualOnCampaignsRequest | PlainMessage<AddOrDeleteVisualOnCampaignsRequest> | undefined): boolean {
    return proto3.util.equals(AddOrDeleteVisualOnCampaignsRequest, a, b);
  }
}

/**
 * Posts selected from campaign All Posts section
 *
 * @generated from message librarian.proto.CampaignPostsSelector
 */
export class CampaignPostsSelector extends Message<CampaignPostsSelector> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.PortfolioReqV2 portfolio_request = 2;
   */
  portfolioRequest?: PortfolioReqV2;

  constructor(data?: PartialMessage<CampaignPostsSelector>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignPostsSelector";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "portfolio_request", kind: "message", T: PortfolioReqV2 },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignPostsSelector {
    return new CampaignPostsSelector().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignPostsSelector {
    return new CampaignPostsSelector().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignPostsSelector {
    return new CampaignPostsSelector().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignPostsSelector | PlainMessage<CampaignPostsSelector> | undefined, b: CampaignPostsSelector | PlainMessage<CampaignPostsSelector> | undefined): boolean {
    return proto3.util.equals(CampaignPostsSelector, a, b);
  }
}

/**
 * Use to mark many posts to many campaigns
 *
 * @generated from message librarian.proto.AddVisualsOnCampaignsRequest
 */
export class AddVisualsOnCampaignsRequest extends Message<AddVisualsOnCampaignsRequest> {
  /**
   * TODO(mateo) (emilie) remove
   *
   * @generated from field: repeated string media_ids = 1 [deprecated = true];
   * @deprecated
   */
  mediaIds: string[] = [];

  /**
   * @generated from oneof librarian.proto.AddVisualsOnCampaignsRequest.the_selector
   */
  theSelector: {
    /**
     * @generated from field: librarian.proto.VisualSelector visual_selector = 6;
     */
    value: VisualSelector;
    case: "visualSelector";
  } | {
    /**
     * @generated from field: librarian.proto.CampaignPostsSelector campaign_posts_selector = 7;
     */
    value: CampaignPostsSelector;
    case: "campaignPostsSelector";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: repeated int64 to_campaigns_ids = 2;
   */
  toCampaignsIds: bigint[] = [];

  /**
   * @generated from field: int64 user_id = 5;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: bool is_last_in_batch = 3;
   */
  isLastInBatch = false;

  /**
   * @generated from field: common.proto.NightwatchCtx nightwatch_ctx = 4;
   */
  nightwatchCtx?: NightwatchCtx;

  constructor(data?: PartialMessage<AddVisualsOnCampaignsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AddVisualsOnCampaignsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "visual_selector", kind: "message", T: VisualSelector, oneof: "the_selector" },
    { no: 7, name: "campaign_posts_selector", kind: "message", T: CampaignPostsSelector, oneof: "the_selector" },
    { no: 2, name: "to_campaigns_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 5, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "is_last_in_batch", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "nightwatch_ctx", kind: "message", T: NightwatchCtx },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddVisualsOnCampaignsRequest {
    return new AddVisualsOnCampaignsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddVisualsOnCampaignsRequest {
    return new AddVisualsOnCampaignsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddVisualsOnCampaignsRequest {
    return new AddVisualsOnCampaignsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddVisualsOnCampaignsRequest | PlainMessage<AddVisualsOnCampaignsRequest> | undefined, b: AddVisualsOnCampaignsRequest | PlainMessage<AddVisualsOnCampaignsRequest> | undefined): boolean {
    return proto3.util.equals(AddVisualsOnCampaignsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditFavoritesLabelReq
 */
export class EditFavoritesLabelReq extends Message<EditFavoritesLabelReq> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.StringValue name = 2;
   */
  name?: string;

  /**
   * @generated from field: google.protobuf.BoolValue archive = 3;
   */
  archive?: boolean;

  constructor(data?: PartialMessage<EditFavoritesLabelReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditFavoritesLabelReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "message", T: StringValue },
    { no: 3, name: "archive", kind: "message", T: BoolValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditFavoritesLabelReq {
    return new EditFavoritesLabelReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditFavoritesLabelReq {
    return new EditFavoritesLabelReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditFavoritesLabelReq {
    return new EditFavoritesLabelReq().fromJsonString(jsonString, options);
  }

  static equals(a: EditFavoritesLabelReq | PlainMessage<EditFavoritesLabelReq> | undefined, b: EditFavoritesLabelReq | PlainMessage<EditFavoritesLabelReq> | undefined): boolean {
    return proto3.util.equals(EditFavoritesLabelReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateCampaignsPerformancesRequest
 */
export class UpdateCampaignsPerformancesRequest extends Message<UpdateCampaignsPerformancesRequest> {
  /**
   * @generated from field: repeated int64 campaigns_ids = 1;
   */
  campaignsIds: bigint[] = [];

  /**
   * @generated from field: repeated string creators_ids = 2;
   */
  creatorsIds: string[] = [];

  constructor(data?: PartialMessage<UpdateCampaignsPerformancesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateCampaignsPerformancesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaigns_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 2, name: "creators_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCampaignsPerformancesRequest {
    return new UpdateCampaignsPerformancesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCampaignsPerformancesRequest {
    return new UpdateCampaignsPerformancesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCampaignsPerformancesRequest {
    return new UpdateCampaignsPerformancesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCampaignsPerformancesRequest | PlainMessage<UpdateCampaignsPerformancesRequest> | undefined, b: UpdateCampaignsPerformancesRequest | PlainMessage<UpdateCampaignsPerformancesRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCampaignsPerformancesRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CampaignInfluencerRefresh
 */
export class CampaignInfluencerRefresh extends Message<CampaignInfluencerRefresh> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: string influencer_id = 2;
   */
  influencerId = "";

  constructor(data?: PartialMessage<CampaignInfluencerRefresh>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CampaignInfluencerRefresh";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "influencer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignInfluencerRefresh {
    return new CampaignInfluencerRefresh().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignInfluencerRefresh {
    return new CampaignInfluencerRefresh().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignInfluencerRefresh {
    return new CampaignInfluencerRefresh().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignInfluencerRefresh | PlainMessage<CampaignInfluencerRefresh> | undefined, b: CampaignInfluencerRefresh | PlainMessage<CampaignInfluencerRefresh> | undefined): boolean {
    return proto3.util.equals(CampaignInfluencerRefresh, a, b);
  }
}

/**
 * @generated from message librarian.proto.ExportCampaignReq
 */
export class ExportCampaignReq extends Message<ExportCampaignReq> {
  /**
   * @generated from field: librarian.proto.GetReportRequest export_req = 1;
   */
  exportReq?: GetReportRequest;

  /**
   * Filled by backend
   *
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<ExportCampaignReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ExportCampaignReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "export_req", kind: "message", T: GetReportRequest },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportCampaignReq {
    return new ExportCampaignReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportCampaignReq {
    return new ExportCampaignReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportCampaignReq {
    return new ExportCampaignReq().fromJsonString(jsonString, options);
  }

  static equals(a: ExportCampaignReq | PlainMessage<ExportCampaignReq> | undefined, b: ExportCampaignReq | PlainMessage<ExportCampaignReq> | undefined): boolean {
    return proto3.util.equals(ExportCampaignReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.UploadPostManuallyReq
 */
export class UploadPostManuallyReq extends Message<UploadPostManuallyReq> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * Filled by backend
   *
   * @generated from field: common.proto.Network network = 3;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: common.proto.NightwatchCtx nightwatch_ctx = 100;
   */
  nightwatchCtx?: NightwatchCtx;

  constructor(data?: PartialMessage<UploadPostManuallyReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadPostManuallyReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 100, name: "nightwatch_ctx", kind: "message", T: NightwatchCtx },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadPostManuallyReq {
    return new UploadPostManuallyReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadPostManuallyReq {
    return new UploadPostManuallyReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadPostManuallyReq {
    return new UploadPostManuallyReq().fromJsonString(jsonString, options);
  }

  static equals(a: UploadPostManuallyReq | PlainMessage<UploadPostManuallyReq> | undefined, b: UploadPostManuallyReq | PlainMessage<UploadPostManuallyReq> | undefined): boolean {
    return proto3.util.equals(UploadPostManuallyReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.ToggleInfluencerTrackingReq
 */
export class ToggleInfluencerTrackingReq extends Message<ToggleInfluencerTrackingReq> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: string influencer_id = 2;
   */
  influencerId = "";

  /**
   * @generated from field: bool disable = 3;
   */
  disable = false;

  constructor(data?: PartialMessage<ToggleInfluencerTrackingReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ToggleInfluencerTrackingReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "influencer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "disable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ToggleInfluencerTrackingReq {
    return new ToggleInfluencerTrackingReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ToggleInfluencerTrackingReq {
    return new ToggleInfluencerTrackingReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ToggleInfluencerTrackingReq {
    return new ToggleInfluencerTrackingReq().fromJsonString(jsonString, options);
  }

  static equals(a: ToggleInfluencerTrackingReq | PlainMessage<ToggleInfluencerTrackingReq> | undefined, b: ToggleInfluencerTrackingReq | PlainMessage<ToggleInfluencerTrackingReq> | undefined): boolean {
    return proto3.util.equals(ToggleInfluencerTrackingReq, a, b);
  }
}


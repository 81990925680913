export const DEFAULT_PROFILE_PIC =
  '/lefty-images/default_instagram_profile.png';
export const DEFAULT_PRODUCT_PIC = '/lefty-images/default_product_picture.png';
export const DEFAULT_COLLABORATION_ILLUSTRATION =
  '/lefty-images/default_collaboration_illustration.png';
export const LEFTY_SSO_REDIRECTION_COOKIE = 'leftyssoredirection';
export const CHANEL_LOGO = '/assets/chanel-logo.svg';
export const LEFTY_LOGO = 'lefty-images/lefty-logo.svg';
export const LEFTY_ICON_PATH = 'lefty-images/logo-sidebar.png';
export const LEFTY_BLACK_LOGO = '/lefty-images/lefty-logo-black.png';
export const LEFTY_LOGIN_PATH = 'login';
export const LEFTY_LEGAL_URL =
  'https://help.lefty.io/en/articles/1361159-legal-notice';
export const LEFTY_PRIVACY_POLICY_URL =
  'https://help.lefty.io/en/articles/2070888-lefty-privacy-policy';
export const LEFTY_CREATORS_PRIVACY_POLICY_URL =
  'https://help.lefty.io/en/articles/2069003-lefty-privacy-policy-creators';

// same as product for now
export const DEFAULT_BRAND_LOGO_PIC =
  '/lefty-images/default_product_picture.png';
export const SHOPIFY_LOGO = '/lefty-images/shopify-logo.svg';
export const SHOPIFY_FULL_LOGO = '/lefty-images/shopify-full-logo.png';
export const SALESFORCE_FULL_LOGO = '/lefty-images/salesforce-full-logo.png';
export const SALESFORCE_LOGO = '/lefty-images/salesforce-logo.svg';
export const LEFTY_WORKSPACE_HINT_COOKIE = 'shop-setup-space-hint';
export const REDIRECT_AFTER_SHOPIFY_COOKIE = 'redirect-after-shopify';
export const NO_ACCESS_ILLUSTRATION_PATH = 'lefty-images/illus-noaccess.png';

import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Optional,
} from '@angular/core';
import { DropdownHandle } from './utils';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[itemActive]',
  standalone: true,
})
export class ActiveItemDirective implements AfterViewInit, OnDestroy {
  constructor(
    private _element: ElementRef<HTMLElement>,
    @Optional() private _dropdownHandle?: DropdownHandle,
  ) {}

  private _active = false;

  private _initialized = false;

  /// Whether the element has hover.
  private _hasHover = false;

  private _visibilitySubscription?: Subscription;

  /// Whether the element is active.
  @HostBinding('class.active')
  get isActive(): boolean {
    return this._active;
  }

  ngOnDestroy(): void {
    this._visibilitySubscription?.unsubscribe();
    this._visibilitySubscription = undefined;
  }

  ngAfterViewInit(): void {
    this._initialized = true;
    this._scrollIntoViewIfNecessary();
  }

  private get _shouldScrollIntoView(): boolean {
    return this._initialized && this._active && this._hasHover === false;
  }

  private _scrollIntoViewIfNecessary(): void {
    this._visibilitySubscription?.unsubscribe();

    if (this._shouldScrollIntoView) {
      const isVisible = this._dropdownHandle?.isVisible ?? true;

      if (isVisible) {
        this._scrollIntoView();
      } else {
        this._visibilitySubscription = this._dropdownHandle?.visible$.subscribe(
          (visible) => {
            if (visible) {
              this._visibilitySubscription?.unsubscribe();
              if (this._shouldScrollIntoView) {
                this._scrollIntoView();
              }
            }
          },
        );
      }
    }
  }

  private _scrollIntoView(): void {
    try {
      this._element.nativeElement?.scrollIntoView({
        block: 'nearest',
        inline: 'nearest',
      });
    } catch (e) {
      console.warn(e);
    }
  }

  /// Marks item as active from keyboard selection.
  @Input()
  set itemActive(value: boolean) {
    if (value === this._active) {
      return;
    }
    this._active = value;
    this._scrollIntoViewIfNecessary();
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this._hasHover = true;
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this._hasHover = false;
  }
}

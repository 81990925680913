import { LogicOperator } from '@frontend2/proto/common/proto/common_pb';

export function readableLogicOperator(operator: LogicOperator): string {
  switch (operator) {
    case LogicOperator.OR:
      return $localize`Or`;
    case LogicOperator.AND:
      return $localize`And`;
    default:
      return $localize`Unknown`;
  }
}

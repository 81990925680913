/* eslint-disable @angular-eslint/component-class-suffix */
import { Component, Input, Output, effect, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { LeftyComponent, createOutput } from '../utils';

/// Helper class to implements a dialog using [LeftyDialogComponent]
///
/// Handle visibility with [visible] input and [visibleChange] output
/// And provide [open], [close] and [toggle] function
///
/// ex:
/// ```dart
/// @Component(
///   selector: 'my-awesome-dialog',
///   template: '''
///   <lefty-dialog [(visible)]="visible" [autoDismissable]="true" (dismiss)="close()">
///   </lefty-dialog>
///   ''',
///   styleUrls: ['awesome_dialog.css'],
///   directives: [
///     AutoDismissDirective,
///     LeftyDialogComponent,
///   ],
///   changeDetection: ChangeDetectionStrategy.OnPush,
/// )
/// class MyAwesomeDialogComponent extends DialogBase {
///      constructor(cdRef: ChangeDetectorRef) {
///        super(cdRef);
///      }
/// }

@Component({
  template: '',
})
export abstract class DialogBase extends LeftyComponent {
  readonly isVisible = signal(false);

  constructor() {
    super();

    let previousState = this.isVisible();
    effect(
      () => {
        const newState = this.isVisible();
        if (newState !== previousState) {
          this._visibilityChange(previousState);
          previousState = newState;
        }
      },
      {
        allowSignalWrites: true,
      },
    );
  }

  @Output()
  readonly visibleChange = toObservable(this.isVisible);

  @Output()
  readonly close$ = createOutput<unknown>();

  @Output()
  readonly open$ = createOutput<unknown>();

  private _visibilityChange(previousState: boolean): void {
    if (previousState === true) {
      this.close$.next(null);
    } else {
      this.open$.next(null);
    }
  }

  get visible(): boolean {
    return this.isVisible();
  }

  @Input()
  set visible(val: boolean) {
    this.isVisible.set(val);
  }

  // if dialog is open, close it
  // if close, open it
  toggle(): void {
    this.isVisible.update((state) => !state);
  }

  close(): void {
    this.isVisible.set(false);
  }

  open(): void {
    this.isVisible.set(true);
  }
}

/// Callback to handle a keyboard event
export type KeyboardEventHandler = (event: KeyboardEvent) => void;

export type DialogSize = 'x-small' | 'small' | 'medium' | 'large' | 'x-large';

export type DialogFooterPadding = 'auto' | 'compact' | 'normal' | 'none';
export type DialogContentBorder = 'auto' | 'hidden' | 'visible';

<lefty-form
  [label]="label"
  [required]="required"
  [optional]="optional"
  [helpText]="helpText"
  [externalHelpLink]="externalHelpLink"
  [helpLink]="helpLink"
  [hintText]="hintText"
  [errorMessage]="errorMessage"
  [tooltip]="tooltip"
  [large]="large"
  [topHintText]="topHintText"
>
  <div class="input-container">
    <button
      leftyButton="subtle"
      icon="minus"
      [size]="buttonSize"
      *ngIf="withButtons"
      [disabled]="disableDecrement || disabled || reachedMinValue"
      (click)="decrement()"
    ></button>

    <div
      class="leading"
      #leading
      *ngIf="(hasLeadingGlyph || hasPrefix) && withButtons === false"
    >
      <lefty-icon
        class="icon leading-glyph"
        *ngIf="hasLeadingGlyph"
        [icon]="leadingGlyph"
        size="x-large"
      ></lefty-icon>

      <span
        *ngIf="hasPrefix"
        class="text prefix"
      >
        {{ prefix }}
      </span>
    </div>

    <input
      #input
      focusableElement
      [disabled]="disabled"
      [value]="formattedValue"
      (input)="handleInputChange($event)"
      (focus)="handleFocus($event)"
      (blur)="handleBlur($event)"
      (invalid)="$event.preventDefault()"
      (keypress)="handleKeypress($event)"
      [required]="required"
      [attr.name]="name"
      [attr.autocomplete]="autocomplete"
      [attr.placeholder]="placeholder"
      [attr.tabindex]="tabIndex"
    />

    <div
      class="trailing"
      #trailing
      *ngIf="(hasSuffix || hasTrailingGlyph) && withButtons === false"
    >
      <span
        *ngIf="hasSuffix"
        class="text suffix"
      >
        {{ suffix }}
      </span>

      <lefty-icon
        class="icon trailing-glyph"
        *ngIf="hasTrailingGlyph"
        [icon]="trailingGlyph"
        size="x-large"
      ></lefty-icon>
    </div>

    <button
      *ngIf="withButtons"
      [disabled]="disableIncrement || disabled || reachedMaxValue"
      leftyButton="subtle"
      icon="plus"
      [size]="buttonSize"
      (click)="increment()"
    ></button>
  </div>
</lefty-form>

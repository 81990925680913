import { createInfluencer, Influencer } from '@frontend2/core';
import { NetworkCreatorPerformance } from '@frontend2/proto/common/proto/campaign_pb';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { CreatorCampaignCard } from '@frontend2/proto/librarian/proto/creators_pb';
import { Row } from '../lefty-data-table/lefty-data-table.models';

export interface CampaignInfluencer extends Influencer {
  readonly networkPerfs: Map<Network, NetworkCreatorPerformance>;
  readonly campaignId: bigint;
  readonly filteredNetworks: Network[];
  readonly totalInCampaignEngagement: number;
  readonly totalAvgEngagement: number;
  readonly totalEmv: number;
  readonly totalPostsCount: number;
  readonly totalImpressions: number;
  readonly totalReach: number;
}

export interface CampaignInfluencersTableState {
  readonly tableRows: Row<CampaignInfluencer>[];
  readonly totalHits: number;
  readonly refreshToken: string;
}

export function createCampaignInfluencersTableState(
  partial?: Partial<CampaignInfluencersTableState>,
): CampaignInfluencersTableState {
  return {
    tableRows: partial?.tableRows ?? [],
    totalHits: partial?.totalHits ?? 0,
    refreshToken: partial?.refreshToken ?? '',
  };
}

export function buildCampaignInfluencer(
  creator: CreatorCampaignCard,
  campaignId: bigint,
  filteredNetworks: Network[],
): CampaignInfluencer {
  const networkPerfs = new Map<Network, NetworkCreatorPerformance>();
  const perfsArray = creator.perf?.networkPerfs ?? [];

  for (const performance of perfsArray) {
    networkPerfs.set(performance.network, performance);
  }

  let inCampaignEngagement = 0;
  let avgEngagement = 0;
  let emv = 0;
  let postsCount = 0;
  let impressions = 0;
  let reach = 0;

  for (const network of filteredNetworks) {
    const networkPerf = networkPerfs.get(network);

    postsCount += networkPerf?.postsCount ?? 0;
    inCampaignEngagement += networkPerf?.inCampaignEngagement ?? 0;
    avgEngagement += networkPerf?.avgEngagement ?? 0;
    reach += Number(networkPerf?.reach ?? 0);
    impressions += Number(networkPerf?.networkStats?.impressions ?? 0);
    emv += Number(networkPerf?.networkStats?.emv ?? 0);
  }

  return {
    ...createInfluencer(creator.baseSnippet ?? ''),
    networkPerfs: networkPerfs,
    campaignId: campaignId,
    filteredNetworks: filteredNetworks,
    totalInCampaignEngagement: inCampaignEngagement,
    totalAvgEngagement: avgEngagement,
    totalEmv: emv,
    totalPostsCount: postsCount,
    totalImpressions: impressions,
    totalReach: reach,
  };
}

import { Injectable, computed, effect } from '@angular/core';
import { asyncScheduler, fromEvent, throttleTime } from 'rxjs';
import { Bloc } from './bloc';

interface Dimensions {
  readonly width: number;
  readonly height: number;
}

export enum LayoutHeight {
  small,
  medium,
  large,
}

@Injectable({ providedIn: 'root' })
export class LeftyLayoutObserverService extends Bloc<Dimensions> {
  private readonly tabletBreakpoint = 1023;
  private readonly mobileBreakpoint = 767;
  private readonly mediumHeightBreakpoint = 640;
  private readonly largeHeightBreakpoint = 740;

  constructor() {
    super({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });

    fromEvent(window, 'resize')
      .pipe(throttleTime(100, asyncScheduler, { trailing: true }))
      .subscribe(() => {
        this.setState({
          width: document.documentElement.clientWidth,
          height: document.documentElement.clientHeight,
        });
      });
  }

  readonly size = this.state;
  readonly documentWidth = computed(() => this.size().width);
  readonly documentHeight = computed(() => this.size().height);

  // if larger than tablet
  readonly isLaptop = computed(
    () => this.documentWidth() >= this.tabletBreakpoint,
  );

  // if between mobile and tablet breakpoint
  readonly isTablet = computed(
    () =>
      this.documentWidth() >= this.mobileBreakpoint &&
      this.documentWidth() < this.tabletBreakpoint,
  );

  // if lower than mobileBreakpoint
  readonly isMobile = computed(
    () => this.documentWidth() < this.mobileBreakpoint,
  );

  readonly isSmallScreen = computed(() => this.isMobile() || this.isTablet());

  readonly isLargeHeight = computed(
    () => this.documentHeight() >= this.largeHeightBreakpoint,
  );

  readonly isMediumHeight = computed(
    () => !this.isLargeHeight() && !this.isSmallHeight(),
  );

  readonly isSmallHeight = computed(
    () => this.documentHeight() < this.mediumHeightBreakpoint,
  );
}

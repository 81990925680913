import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  Optional,
  Self,
  forwardRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgControl } from '@angular/forms';
import { cleanupStringToMatch, isNil, isNotEmptyString } from '@frontend2/core';
import { Placement } from '@popperjs/core';
import { FocusableComponent } from '../focus.directive';
import { NativeInputWrapper } from '../form';
import { AngularUtils } from '../utils';
import { ActiveItemDirective } from '../active-item.directive';
import { LeftySelectDropdownItemComponent } from '../lefty-form-select/lefty-select-dropdown-item.component';
import { LeftyListComponent } from '../lefty-list/lefty-list.component';
import { LeftyPopupComponent } from '../lefty-popup/lefty-popup.component';
import { LeftyIconComponent } from '../icon/icon.component';
import { NgIf, NgFor, SlicePipe } from '@angular/common';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-form-input:not([type="number"])',
  templateUrl: './lefty-form-input.component.html',
  styleUrls: ['./lefty-form-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FocusableComponent,
      useExisting: forwardRef(() => LeftyFormInputComponent),
    },
  ],
  standalone: true,
  imports: [
    LeftyFormComponent,
    NgIf,
    LeftyIconComponent,
    LeftyPopupComponent,
    LeftyListComponent,
    NgFor,
    LeftySelectDropdownItemComponent,
    ActiveItemDirective,
    SlicePipe,
  ],
})
export class LeftyFormInputComponent extends NativeInputWrapper<string> {
  constructor(
    elementRef: ElementRef,
    @Self() @Optional() ngControl?: NgControl,
  ) {
    super(elementRef, '', ngControl);

    this.keypress$.pipe(takeUntilDestroyed()).subscribe((e) => {
      this.handleKeypress(e);
    });
  }

  @Input()
  @HostBinding('class.invalid')
  showInvalidStyle = false;

  @Input()
  suggestions: string[] = [];

  //-1 means no limit
  @Input()
  suggestionsMaxCount = -1;

  @Input()
  suggestionsPopupPlacement: Placement = 'bottom-start';

  @Input()
  allowedChar = '';

  suggestionsPopupVisible = false;

  get slicedCount(): number | undefined {
    if (this.suggestionsMaxCount > 0) {
      return this.suggestionsMaxCount;
    } else {
      // undefined in slice pipe means no limit
      return undefined;
    }
  }

  trackByFn = AngularUtils.trackByIndex;

  @HostBinding('class.has-value')
  get hasValue(): boolean {
    return isNotEmptyString(this.value);
  }

  activeSuggestionIndex = 0;

  get activeSuggestionItem(): string | undefined {
    if (this.suggestions.length === 0) {
      return;
    }
    return this.suggestions[this.activeSuggestionIndex];
  }

  isSuggestionActive(item: string): boolean {
    return this.activeSuggestionItem === item;
  }

  activateSuggestion(item: string): void {
    this.activeSuggestionIndex = this.suggestions.indexOf(item);
    this.changeDetection.markForCheck();
  }

  isSuggestionSelected(item: string): boolean {
    return this.value === item;
  }

  openSuggestionsPopup(): void {
    this.suggestionsPopupVisible = true;
    this.changeDetection.markForCheck();
  }

  closeSuggestionsPopup(): void {
    this.suggestionsPopupVisible = false;
    this.changeDetection.markForCheck();
  }

  toggleSuggestionsPopup(): void {
    if (this.disabled && this.suggestionsPopupVisible === false) {
      return;
    }
    this.suggestionsPopupVisible = !this.suggestionsPopupVisible;
  }

  handleKeyboardTrigger(event: Event): void {
    event?.preventDefault();

    if (this.disabled) {
      return;
    }
    if (!this.suggestionsPopupVisible) {
      this.openSuggestionsPopup();
    } else {
      if (this.activeSuggestionItem) {
        this.selectSuggestion(this.activeSuggestionItem);
      }

      this.closeSuggestionsPopup();
      this.handleBlur();
    }
  }

  selectSuggestion(item: string): void {
    this.handleValueChange(item);
    this.changeDetection.markForCheck();
  }

  private _isValidChar(char: string): boolean {
    if (isNotEmptyString(this.allowedChar)) {
      const query = new RegExp(this.allowedChar);
      return query.test(char);
    } else {
      return true;
    }
  }

  handleKeypress(event: KeyboardEvent): void {
    if (this._isValidChar(event.key) === false) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  handlePaste(event: ClipboardEvent): void {
    let str = event.clipboardData?.getData('Text') ?? '';
    if (isNotEmptyString(this.allowedChar)) {
      event.preventDefault();
      str = cleanupStringToMatch(str, new RegExp(this.allowedChar));
      this.handleValueChange(str);
    }
  }

  override writeValue(obj: unknown): void {
    if (typeof obj === 'string') {
      this.value = obj;
    } else if (isNil(obj)) {
      this.value = '';
    } else {
      console.warn(
        `Failed to bind type ${typeof obj} on form control ${
          this.ngControl?.name
        }`,
      );
    }
  }
}

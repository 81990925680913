import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { RegisteredAuthor } from '@frontend2/proto/librarian/proto/registered_author_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type RegisteredAuthorClient = PromiseClient<typeof RegisteredAuthor>;

export const RegisteredAuthorClientProvider = new InjectionToken(
  'RegisteredAuthorClient',
  {
    factory: (): RegisteredAuthorClient =>
      createPromiseClient(RegisteredAuthor, inject(GRPC_TRANSPORT)),
  },
);

export function injectRegisteredAuthorClient(): RegisteredAuthorClient {
  return inject(RegisteredAuthorClientProvider);
}

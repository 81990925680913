// This file defines protocol buffers for the twitter stuff that gets persisted.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/twitter_media.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ComputedGenerikStats, MediaIdPb } from "./common_pb";
import { GeoPoint } from "./geo_pb";
import { GenerikMediaFacts } from "./media_pb";

/**
 * @generated from message common.proto.MediaEntities
 */
export class MediaEntities extends Message<MediaEntities> {
  /**
   * @generated from field: common.proto.MediaEntities.Resolution resolution = 1;
   */
  resolution = MediaEntities_Resolution.RESOLUTION_UNDEFINED;

  /**
   * @generated from field: int32 width = 2;
   */
  width = 0;

  /**
   * @generated from field: int32 height = 3;
   */
  height = 0;

  /**
   * @generated from field: string url = 4;
   */
  url = "";

  constructor(data?: PartialMessage<MediaEntities>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.MediaEntities";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resolution", kind: "enum", T: proto3.getEnumType(MediaEntities_Resolution) },
    { no: 2, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaEntities {
    return new MediaEntities().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaEntities {
    return new MediaEntities().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaEntities {
    return new MediaEntities().fromJsonString(jsonString, options);
  }

  static equals(a: MediaEntities | PlainMessage<MediaEntities> | undefined, b: MediaEntities | PlainMessage<MediaEntities> | undefined): boolean {
    return proto3.util.equals(MediaEntities, a, b);
  }
}

/**
 * @generated from enum common.proto.MediaEntities.Resolution
 */
export enum MediaEntities_Resolution {
  /**
   * @generated from enum value: RESOLUTION_UNDEFINED = 0;
   */
  RESOLUTION_UNDEFINED = 0,

  /**
   * @generated from enum value: THUMB = 1;
   */
  THUMB = 1,

  /**
   * @generated from enum value: SMALL = 2;
   */
  SMALL = 2,

  /**
   * @generated from enum value: MEDIUM = 3;
   */
  MEDIUM = 3,

  /**
   * @generated from enum value: ANIMATED = 4;
   */
  ANIMATED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(MediaEntities_Resolution)
proto3.util.setEnumType(MediaEntities_Resolution, "common.proto.MediaEntities.Resolution", [
  { no: 0, name: "RESOLUTION_UNDEFINED" },
  { no: 1, name: "THUMB" },
  { no: 2, name: "SMALL" },
  { no: 3, name: "MEDIUM" },
  { no: 4, name: "ANIMATED" },
]);

/**
 * @generated from message common.proto.TwitterThumbnail
 */
export class TwitterThumbnail extends Message<TwitterThumbnail> {
  /**
   * @generated from field: common.proto.TwitterThumbnail.MediaType type = 1;
   */
  type = TwitterThumbnail_MediaType.MEDIA_UNDEFINED;

  /**
   * @generated from field: repeated common.proto.MediaEntities media_entities = 2;
   */
  mediaEntities: MediaEntities[] = [];

  constructor(data?: PartialMessage<TwitterThumbnail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TwitterThumbnail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(TwitterThumbnail_MediaType) },
    { no: 2, name: "media_entities", kind: "message", T: MediaEntities, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TwitterThumbnail {
    return new TwitterThumbnail().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TwitterThumbnail {
    return new TwitterThumbnail().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TwitterThumbnail {
    return new TwitterThumbnail().fromJsonString(jsonString, options);
  }

  static equals(a: TwitterThumbnail | PlainMessage<TwitterThumbnail> | undefined, b: TwitterThumbnail | PlainMessage<TwitterThumbnail> | undefined): boolean {
    return proto3.util.equals(TwitterThumbnail, a, b);
  }
}

/**
 * @generated from enum common.proto.TwitterThumbnail.MediaType
 */
export enum TwitterThumbnail_MediaType {
  /**
   * @generated from enum value: MEDIA_UNDEFINED = 0;
   */
  MEDIA_UNDEFINED = 0,

  /**
   * @generated from enum value: ANIMATED_GIF = 1;
   */
  ANIMATED_GIF = 1,

  /**
   * @generated from enum value: PHOTO = 2;
   */
  PHOTO = 2,

  /**
   * @generated from enum value: VIDEO = 3;
   */
  VIDEO = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(TwitterThumbnail_MediaType)
proto3.util.setEnumType(TwitterThumbnail_MediaType, "common.proto.TwitterThumbnail.MediaType", [
  { no: 0, name: "MEDIA_UNDEFINED" },
  { no: 1, name: "ANIMATED_GIF" },
  { no: 2, name: "PHOTO" },
  { no: 3, name: "VIDEO" },
]);

/**
 * @generated from message common.proto.TwitterCreator
 */
export class TwitterCreator extends Message<TwitterCreator> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string screen_name = 3;
   */
  screenName = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: int64 number_of_followers = 5;
   */
  numberOfFollowers = protoInt64.zero;

  /**
   * @generated from field: int64 listed_count = 6;
   */
  listedCount = protoInt64.zero;

  /**
   * @generated from field: int64 friends_count = 7;
   */
  friendsCount = protoInt64.zero;

  /**
   * @generated from field: string email = 8;
   */
  email = "";

  /**
   * Facebook, Instagram, website, Youtube etc...
   *
   * @generated from field: string url = 9;
   */
  url = "";

  /**
   * @generated from field: string location = 10;
   */
  location = "";

  /**
   * @generated from field: string lang = 11;
   */
  lang = "";

  /**
   *  Profile image use for lefty
   *
   * @generated from field: string mini_profile_url = 12;
   */
  miniProfileUrl = "";

  /**
   * @generated from field: string time_zone = 13;
   */
  timeZone = "";

  /**
   * @generated from field: int32 tweet_count = 14;
   */
  tweetCount = 0;

  /**
   * Receive time in Milliseconds
   *
   * @generated from field: google.protobuf.Timestamp created_at = 15;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: bool is_enable_contributor = 16;
   */
  isEnableContributor = false;

  /**
   * @generated from field: bool is_default_profile = 17;
   */
  isDefaultProfile = false;

  /**
   * @generated from field: bool is_default_profile_image = 18;
   */
  isDefaultProfileImage = false;

  /**
   * @generated from field: bool is_profile_background_tiled = 19;
   */
  isProfileBackgroundTiled = false;

  /**
   * @generated from field: bool is_verified = 20;
   */
  isVerified = false;

  /**
   * @generated from field: bool is_translator = 21;
   */
  isTranslator = false;

  /**
   * @generated from field: bool is_protected = 22;
   */
  isProtected = false;

  /**
   * @generated from field: bool is_geo_enabled = 23;
   */
  isGeoEnabled = false;

  /**
   * @generated from field: common.proto.ComputedGenerikStats computed_stats = 25;
   */
  computedStats?: ComputedGenerikStats;

  constructor(data?: PartialMessage<TwitterCreator>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TwitterCreator";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "screen_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "number_of_followers", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "listed_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "friends_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "location", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "lang", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "mini_profile_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "time_zone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "tweet_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 15, name: "created_at", kind: "message", T: Timestamp },
    { no: 16, name: "is_enable_contributor", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "is_default_profile", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "is_default_profile_image", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "is_profile_background_tiled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "is_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "is_translator", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "is_protected", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 23, name: "is_geo_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "computed_stats", kind: "message", T: ComputedGenerikStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TwitterCreator {
    return new TwitterCreator().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TwitterCreator {
    return new TwitterCreator().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TwitterCreator {
    return new TwitterCreator().fromJsonString(jsonString, options);
  }

  static equals(a: TwitterCreator | PlainMessage<TwitterCreator> | undefined, b: TwitterCreator | PlainMessage<TwitterCreator> | undefined): boolean {
    return proto3.util.equals(TwitterCreator, a, b);
  }
}

/**
 * @generated from message common.proto.Place
 */
export class Place extends Message<Place> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string country = 2;
   */
  country = "";

  /**
   * @generated from field: string country_code = 3;
   */
  countryCode = "";

  /**
   * @generated from field: string full_name = 4;
   */
  fullName = "";

  /**
   * @generated from field: string geometry_type = 5;
   */
  geometryType = "";

  /**
   * @generated from field: string name = 6;
   */
  name = "";

  /**
   * @generated from field: string place_type = 7;
   */
  placeType = "";

  /**
   * @generated from field: string street_addr = 8;
   */
  streetAddr = "";

  /**
   * @generated from field: string url = 9;
   */
  url = "";

  constructor(data?: PartialMessage<Place>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Place";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "full_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "geometry_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "place_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "street_addr", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Place {
    return new Place().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Place {
    return new Place().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Place {
    return new Place().fromJsonString(jsonString, options);
  }

  static equals(a: Place | PlainMessage<Place> | undefined, b: Place | PlainMessage<Place> | undefined): boolean {
    return proto3.util.equals(Place, a, b);
  }
}

/**
 * @generated from message common.proto.ReqTweetComment
 */
export class ReqTweetComment extends Message<ReqTweetComment> {
  /**
   * @generated from field: common.proto.MediaIdPb id = 1;
   */
  id?: MediaIdPb;

  /**
   * @generated from field: string screen_name = 2;
   */
  screenName = "";

  constructor(data?: PartialMessage<ReqTweetComment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ReqTweetComment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "message", T: MediaIdPb },
    { no: 2, name: "screen_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReqTweetComment {
    return new ReqTweetComment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReqTweetComment {
    return new ReqTweetComment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReqTweetComment {
    return new ReqTweetComment().fromJsonString(jsonString, options);
  }

  static equals(a: ReqTweetComment | PlainMessage<ReqTweetComment> | undefined, b: ReqTweetComment | PlainMessage<ReqTweetComment> | undefined): boolean {
    return proto3.util.equals(ReqTweetComment, a, b);
  }
}

/**
 * @generated from message common.proto.TwitterMention
 */
export class TwitterMention extends Message<TwitterMention> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string screen_name = 3;
   */
  screenName = "";

  constructor(data?: PartialMessage<TwitterMention>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TwitterMention";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "screen_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TwitterMention {
    return new TwitterMention().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TwitterMention {
    return new TwitterMention().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TwitterMention {
    return new TwitterMention().fromJsonString(jsonString, options);
  }

  static equals(a: TwitterMention | PlainMessage<TwitterMention> | undefined, b: TwitterMention | PlainMessage<TwitterMention> | undefined): boolean {
    return proto3.util.equals(TwitterMention, a, b);
  }
}

/**
 * @generated from message common.proto.RetweetInfo
 */
export class RetweetInfo extends Message<RetweetInfo> {
  /**
   * @generated from field: string tweet_id = 1;
   */
  tweetId = "";

  /**
   * @generated from field: string text = 2;
   */
  text = "";

  /**
   * @generated from field: repeated common.proto.TwitterMention mentions = 3;
   */
  mentions: TwitterMention[] = [];

  /**
   * @generated from field: repeated string tags = 4;
   */
  tags: string[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp published_at = 5;
   */
  publishedAt?: Timestamp;

  /**
   * @generated from field: repeated common.proto.TwitterThumbnail thumbnails = 6;
   */
  thumbnails: TwitterThumbnail[] = [];

  constructor(data?: PartialMessage<RetweetInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.RetweetInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "tweet_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "mentions", kind: "message", T: TwitterMention, repeated: true },
    { no: 4, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "published_at", kind: "message", T: Timestamp },
    { no: 6, name: "thumbnails", kind: "message", T: TwitterThumbnail, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RetweetInfo {
    return new RetweetInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RetweetInfo {
    return new RetweetInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RetweetInfo {
    return new RetweetInfo().fromJsonString(jsonString, options);
  }

  static equals(a: RetweetInfo | PlainMessage<RetweetInfo> | undefined, b: RetweetInfo | PlainMessage<RetweetInfo> | undefined): boolean {
    return proto3.util.equals(RetweetInfo, a, b);
  }
}

/**
 * @generated from message common.proto.RetweetUser
 */
export class RetweetUser extends Message<RetweetUser> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string screen_name = 2;
   */
  screenName = "";

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: int32 tweet_count = 4;
   */
  tweetCount = 0;

  /**
   * @generated from field: int64 friends_count = 5;
   */
  friendsCount = protoInt64.zero;

  /**
   * @generated from field: int64 number_of_followers = 6;
   */
  numberOfFollowers = protoInt64.zero;

  /**
   * @generated from field: string profile_pic = 7;
   */
  profilePic = "";

  /**
   * @generated from field: common.proto.RetweetInfo retweet_info = 8;
   */
  retweetInfo?: RetweetInfo;

  constructor(data?: PartialMessage<RetweetUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.RetweetUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "screen_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "tweet_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "friends_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "number_of_followers", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "profile_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "retweet_info", kind: "message", T: RetweetInfo },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RetweetUser {
    return new RetweetUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RetweetUser {
    return new RetweetUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RetweetUser {
    return new RetweetUser().fromJsonString(jsonString, options);
  }

  static equals(a: RetweetUser | PlainMessage<RetweetUser> | undefined, b: RetweetUser | PlainMessage<RetweetUser> | undefined): boolean {
    return proto3.util.equals(RetweetUser, a, b);
  }
}

/**
 * @generated from message common.proto.TwitterPost
 */
export class TwitterPost extends Message<TwitterPost> {
  /**
   *  User id
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   *  All information that contain a tweet
   *
   * @generated from field: string tweet_id = 2;
   */
  tweetId = "";

  /**
   * @generated from field: int32 text_range_start = 3;
   */
  textRangeStart = 0;

  /**
   * @generated from field: int32 text_range_end = 4;
   */
  textRangeEnd = 0;

  /**
   * @generated from field: int32 favorite_count = 5;
   */
  favoriteCount = 0;

  /**
   * @generated from field: int64 nb_like = 6;
   */
  nbLike = protoInt64.zero;

  /**
   * @generated from field: int64 nb_retweet = 7;
   */
  nbRetweet = protoInt64.zero;

  /**
   * @generated from field: int64 current_user_retweet_id = 8;
   */
  currentUserRetweetId = protoInt64.zero;

  /**
   * @generated from field: int64 in_reply_to_status_id = 9;
   */
  inReplyToStatusId = protoInt64.zero;

  /**
   * @generated from field: int64 in_reply_to_user_id = 10;
   */
  inReplyToUserId = protoInt64.zero;

  /**
   * @generated from field: int64 quote_status_id = 11;
   */
  quoteStatusId = protoInt64.zero;

  /**
   * @generated from field: string text = 12;
   */
  text = "";

  /**
   * @generated from field: string in_reply_to_screen_name = 13;
   */
  inReplyToScreenName = "";

  /**
   * @generated from field: string lang = 14;
   */
  lang = "";

  /**
   * @generated from field: common.proto.GeoPoint geoPoint = 15;
   */
  geoPoint?: GeoPoint;

  /**
   * @generated from field: common.proto.Place place = 16;
   */
  place?: Place;

  /**
   * @generated from field: repeated common.proto.TwitterThumbnail thumbnails = 31;
   */
  thumbnails: TwitterThumbnail[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 17;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: bool is_favorited = 18;
   */
  isFavorited = false;

  /**
   * @generated from field: bool is_possibly_sensitive = 19;
   */
  isPossiblySensitive = false;

  /**
   * @generated from field: bool is_retweet = 20;
   */
  isRetweet = false;

  /**
   * @generated from field: bool is_retweeted = 21;
   */
  isRetweeted = false;

  /**
   * @generated from field: bool is_retweeted_by_me = 22;
   */
  isRetweetedByMe = false;

  /**
   * @generated from field: bool is_truncated = 23;
   */
  isTruncated = false;

  /**
   * @generated from field: bool is_geolocated = 24;
   */
  isGeolocated = false;

  /**
   * @generated from field: bool is_placed = 25;
   */
  isPlaced = false;

  /**
   * @generated from field: bool has_media = 30;
   */
  hasMedia = false;

  /**
   *  lefty metadata fields
   *
   * @generated from field: repeated string tags = 27;
   */
  tags: string[] = [];

  /**
   * @generated from field: repeated common.proto.TwitterMention mentions = 28;
   */
  mentions: TwitterMention[] = [];

  /**
   * @generated from field: common.proto.RetweetUser retweet_user = 29;
   */
  retweetUser?: RetweetUser;

  /**
   * @generated from field: int32 nb_comment = 32;
   */
  nbComment = 0;

  /**
   * @generated from field: common.proto.GenerikMediaFacts facts = 33;
   */
  facts?: GenerikMediaFacts;

  /**
   * @generated from field: int32 nb_quote = 34;
   */
  nbQuote = 0;

  /**
   * @generated from field: int32 nb_view = 35;
   */
  nbView = 0;

  constructor(data?: PartialMessage<TwitterPost>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TwitterPost";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tweet_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "text_range_start", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "text_range_end", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "favorite_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "nb_like", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "nb_retweet", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "current_user_retweet_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "in_reply_to_status_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "in_reply_to_user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "quote_status_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "in_reply_to_screen_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "lang", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "geoPoint", kind: "message", T: GeoPoint },
    { no: 16, name: "place", kind: "message", T: Place },
    { no: 31, name: "thumbnails", kind: "message", T: TwitterThumbnail, repeated: true },
    { no: 17, name: "created_at", kind: "message", T: Timestamp },
    { no: 18, name: "is_favorited", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "is_possibly_sensitive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 20, name: "is_retweet", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 21, name: "is_retweeted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "is_retweeted_by_me", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 23, name: "is_truncated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 24, name: "is_geolocated", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "is_placed", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 30, name: "has_media", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 27, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 28, name: "mentions", kind: "message", T: TwitterMention, repeated: true },
    { no: 29, name: "retweet_user", kind: "message", T: RetweetUser },
    { no: 32, name: "nb_comment", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 33, name: "facts", kind: "message", T: GenerikMediaFacts },
    { no: 34, name: "nb_quote", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 35, name: "nb_view", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TwitterPost {
    return new TwitterPost().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TwitterPost {
    return new TwitterPost().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TwitterPost {
    return new TwitterPost().fromJsonString(jsonString, options);
  }

  static equals(a: TwitterPost | PlainMessage<TwitterPost> | undefined, b: TwitterPost | PlainMessage<TwitterPost> | undefined): boolean {
    return proto3.util.equals(TwitterPost, a, b);
  }
}


<lefty-form
  [label]="label"
  [required]="required"
  [optional]="optional"
  [helpText]="helpText"
  [helpLink]="helpLink"
  [hintText]="hintText"
  [errorMessage]="errorMessage"
  [tooltip]="tooltip"
  [large]="large"
  [topHintText]="topHintText"
>
  <div
    class="input-container"
    [class.can-clear]="canClear"
    #popupTrigger
    (click)="open()"
  >
    <lefty-form-input
      autocomplete="off"
      [name]="name"
      [ngClass]="inputClassName"
      [placeholder]="placeholder"
      [(value)]="inputText"
      (focus$)="handleFocus($event)"
      (blur$)="handleBlur($event)"
      [disabled]="disabled"
      [prefix]="prefix"
      [leadingGlyph]="leadingGlyph"
      [trailingGlyph]="trailingGlyph"
      [suffix]="suffix"
      (keydown)="keydown$.next($event)"
      (keydown.enter)="handleKeyboardTrigger($event)"
      (keydown.arrowup)="handleUpKey($event)"
      (keydown.arrowdown)="handleDownKey($event)"
      [class.ng-dirty]="ngDirty"
      [class.ng-touched]="ngTouched"
      [class.ng-invalid]="ngInvalid"
      [showInvalidStyle]="showInvalidStyle"
    >
    </lefty-form-input>

    <div
      *ngIf="canClear"
      class="clear-icon"
    >
      <button
        leftyButton="plain"
        type="button"
        (click)="clearSelection()"
        icon="clear"
      ></button>
    </div>
  </div>

  <lefty-popup
    [ngClass]="popupClassName"
    [placement]="popupPlacement"
    [matchMinSourceWidth]="popupMatchInputWidth"
    [(visible)]="popupVisible"
    [delegateControlToParent]="true"
    [popupTrigger]="popupTrigger"
  >
    <div header>
      <ng-content select="[header]"></ng-content>
    </div>

    <div
      *ngIf="loading"
      class="loading-state"
    >
      <lefty-spinner></lefty-spinner>
    </div>

    <div
      *ngIf="showEmptyPlaceholder"
      class="empty-state"
    >
      {{ emptyPlaceholder }}
    </div>

    <lefty-list
      (keydown.enter)="handleKeyboardTrigger($event)"
      (keydown.space)="handleKeyboardTrigger($event)"
      (keydown.arrowup)="handleUpKey($event)"
      (keydown.arrowdown)="handleDownKey($event)"
      *ngIf="filteredOptions.length !== 0 && loading === false"
    >
      <lefty-select-dropdown-item
        *ngFor="let item of filteredOptions; trackBy: trackByFn"
        [tabbable]="false"
        [itemRenderer]="itemRenderer"
        [componentFactory]="componentFactory"
        [value]="item"
        [itemActive]="isActive(item)"
        [selected]="isSelected(item)"
        (trigger$)="select(item)"
        [closeOnActivate]="keepPopupVisible === false"
        [disabled]="isOptionDisabled(item)"
      >
      </lefty-select-dropdown-item>

      <intersection-observer
        (trigger$)="scrollEnd$.next($event)"
      ></intersection-observer>
    </lefty-list>

    <div footer>
      <ng-content select="[footer]"></ng-content>
    </div>
  </lefty-popup>
</lefty-form>

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/admin_cw.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { BoolValue, Int32Value, Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { CampaignKeyword, CampaignSnippet } from "../../common/proto/campaign_pb";
import { BasicPagination, Network, SortDirection } from "../../common/proto/common_pb";
import { CWBrandPb, CWIndustryPb, CWMarket, ReportPriority, SourceRequest } from "./competitive_watch_pb";
import { TeamDetails } from "./admin_pb";
import { MetaIdPb } from "../../common/proto/creator_pb";

/**
 * @generated from enum librarian.proto.ReportRefreshStep
 */
export enum ReportRefreshStep {
  /**
   * @generated from enum value: REPORT_REFRESH_STEP_UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: REPORT_REFRESH_STEP_POLL = 1;
   */
  POLL = 1,

  /**
   * @generated from enum value: REPORT_REFRESH_STEP_TRACK = 2;
   */
  TRACK = 2,

  /**
   * @generated from enum value: REPORT_REFRESH_STEP_AUDIT = 3;
   */
  AUDIT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ReportRefreshStep)
proto3.util.setEnumType(ReportRefreshStep, "librarian.proto.ReportRefreshStep", [
  { no: 0, name: "REPORT_REFRESH_STEP_UNDEFINED" },
  { no: 1, name: "REPORT_REFRESH_STEP_POLL" },
  { no: 2, name: "REPORT_REFRESH_STEP_TRACK" },
  { no: 3, name: "REPORT_REFRESH_STEP_AUDIT" },
]);

/**
 * @generated from enum librarian.proto.CWSort
 */
export enum CWSort {
  /**
   * @generated from enum value: CW_SORT_UNDEFINED = 0;
   */
  CW_SORT_UNDEFINED = 0,

  /**
   * @generated from enum value: CW_SORT_CREATION_DATE = 1;
   */
  CW_SORT_CREATION_DATE = 1,

  /**
   * @generated from enum value: CW_SORT_NAME = 2;
   */
  CW_SORT_NAME = 2,

  /**
   * @generated from enum value: CW_SORT_MARKET_NAME = 3;
   */
  CW_SORT_MARKET_NAME = 3,

  /**
   * @generated from enum value: CW_SORT_INDUSTRY_NAME = 4;
   */
  CW_SORT_INDUSTRY_NAME = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(CWSort)
proto3.util.setEnumType(CWSort, "librarian.proto.CWSort", [
  { no: 0, name: "CW_SORT_UNDEFINED" },
  { no: 1, name: "CW_SORT_CREATION_DATE" },
  { no: 2, name: "CW_SORT_NAME" },
  { no: 3, name: "CW_SORT_MARKET_NAME" },
  { no: 4, name: "CW_SORT_INDUSTRY_NAME" },
]);

/**
 * @generated from message librarian.proto.AutocompleteCampaignsRequest
 */
export class AutocompleteCampaignsRequest extends Message<AutocompleteCampaignsRequest> {
  /**
   * @generated from field: string value = 1;
   */
  value = "";

  /**
   * list of ids you want to exclude from the result
   *
   * @generated from field: repeated int64 exclude_ids = 2;
   */
  excludeIds: bigint[] = [];

  constructor(data?: PartialMessage<AutocompleteCampaignsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AutocompleteCampaignsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "exclude_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteCampaignsRequest {
    return new AutocompleteCampaignsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteCampaignsRequest {
    return new AutocompleteCampaignsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteCampaignsRequest {
    return new AutocompleteCampaignsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteCampaignsRequest | PlainMessage<AutocompleteCampaignsRequest> | undefined, b: AutocompleteCampaignsRequest | PlainMessage<AutocompleteCampaignsRequest> | undefined): boolean {
    return proto3.util.equals(AutocompleteCampaignsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.AutocompleteCampaignsResponse
 */
export class AutocompleteCampaignsResponse extends Message<AutocompleteCampaignsResponse> {
  /**
   * @generated from field: repeated common.proto.CampaignSnippet campaigns = 1;
   */
  campaigns: CampaignSnippet[] = [];

  constructor(data?: PartialMessage<AutocompleteCampaignsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AutocompleteCampaignsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaigns", kind: "message", T: CampaignSnippet, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteCampaignsResponse {
    return new AutocompleteCampaignsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteCampaignsResponse {
    return new AutocompleteCampaignsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteCampaignsResponse {
    return new AutocompleteCampaignsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteCampaignsResponse | PlainMessage<AutocompleteCampaignsResponse> | undefined, b: AutocompleteCampaignsResponse | PlainMessage<AutocompleteCampaignsResponse> | undefined): boolean {
    return proto3.util.equals(AutocompleteCampaignsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.AutocompleteCwBrandsRequest
 */
export class AutocompleteCwBrandsRequest extends Message<AutocompleteCwBrandsRequest> {
  /**
   * @generated from field: string value = 1;
   */
  value = "";

  /**
   * @generated from field: repeated common.proto.Network networks = 4;
   */
  networks: Network[] = [];

  /**
   * list of ids you want to exclude from the result
   *
   * @generated from field: repeated int64 exclude_ids = 3;
   */
  excludeIds: bigint[] = [];

  constructor(data?: PartialMessage<AutocompleteCwBrandsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AutocompleteCwBrandsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 3, name: "exclude_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteCwBrandsRequest {
    return new AutocompleteCwBrandsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteCwBrandsRequest {
    return new AutocompleteCwBrandsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteCwBrandsRequest {
    return new AutocompleteCwBrandsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteCwBrandsRequest | PlainMessage<AutocompleteCwBrandsRequest> | undefined, b: AutocompleteCwBrandsRequest | PlainMessage<AutocompleteCwBrandsRequest> | undefined): boolean {
    return proto3.util.equals(AutocompleteCwBrandsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.BrandMarketSpec
 */
export class BrandMarketSpec extends Message<BrandMarketSpec> {
  /**
   * @generated from field: librarian.proto.CWBrandPb brand = 1;
   */
  brand?: CWBrandPb;

  /**
   * @generated from field: librarian.proto.CWMarket market = 2;
   */
  market?: CWMarket;

  /**
   * @generated from field: repeated common.proto.CampaignKeyword keywords = 4;
   */
  keywords: CampaignKeyword[] = [];

  /**
   * @generated from field: repeated common.proto.CampaignKeyword keyword_filters = 5;
   */
  keywordFilters: CampaignKeyword[] = [];

  constructor(data?: PartialMessage<BrandMarketSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.BrandMarketSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brand", kind: "message", T: CWBrandPb },
    { no: 2, name: "market", kind: "message", T: CWMarket },
    { no: 4, name: "keywords", kind: "message", T: CampaignKeyword, repeated: true },
    { no: 5, name: "keyword_filters", kind: "message", T: CampaignKeyword, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BrandMarketSpec {
    return new BrandMarketSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BrandMarketSpec {
    return new BrandMarketSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BrandMarketSpec {
    return new BrandMarketSpec().fromJsonString(jsonString, options);
  }

  static equals(a: BrandMarketSpec | PlainMessage<BrandMarketSpec> | undefined, b: BrandMarketSpec | PlainMessage<BrandMarketSpec> | undefined): boolean {
    return proto3.util.equals(BrandMarketSpec, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWCreateBrandRequest
 */
export class CWCreateBrandRequest extends Message<CWCreateBrandRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: repeated librarian.proto.CWMarketMentions markets = 2;
   */
  markets: CWMarketMentions[] = [];

  constructor(data?: PartialMessage<CWCreateBrandRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWCreateBrandRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "markets", kind: "message", T: CWMarketMentions, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWCreateBrandRequest {
    return new CWCreateBrandRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWCreateBrandRequest {
    return new CWCreateBrandRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWCreateBrandRequest {
    return new CWCreateBrandRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWCreateBrandRequest | PlainMessage<CWCreateBrandRequest> | undefined, b: CWCreateBrandRequest | PlainMessage<CWCreateBrandRequest> | undefined): boolean {
    return proto3.util.equals(CWCreateBrandRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWBrandMarkets
 */
export class CWBrandMarkets extends Message<CWBrandMarkets> {
  /**
   * @generated from field: librarian.proto.CWBrandPb brand = 1;
   */
  brand?: CWBrandPb;

  /**
   * @generated from field: repeated librarian.proto.CWMarketMentions markets = 2;
   */
  markets: CWMarketMentions[] = [];

  constructor(data?: PartialMessage<CWBrandMarkets>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWBrandMarkets";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brand", kind: "message", T: CWBrandPb },
    { no: 2, name: "markets", kind: "message", T: CWMarketMentions, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWBrandMarkets {
    return new CWBrandMarkets().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWBrandMarkets {
    return new CWBrandMarkets().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWBrandMarkets {
    return new CWBrandMarkets().fromJsonString(jsonString, options);
  }

  static equals(a: CWBrandMarkets | PlainMessage<CWBrandMarkets> | undefined, b: CWBrandMarkets | PlainMessage<CWBrandMarkets> | undefined): boolean {
    return proto3.util.equals(CWBrandMarkets, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWMarketMentions
 */
export class CWMarketMentions extends Message<CWMarketMentions> {
  /**
   * @generated from field: librarian.proto.CWMarket market = 2;
   */
  market?: CWMarket;

  /**
   * @generated from field: repeated common.proto.CampaignKeyword keywords = 7;
   */
  keywords: CampaignKeyword[] = [];

  /**
   * @generated from field: repeated common.proto.CampaignKeyword keyword_filters = 8;
   */
  keywordFilters: CampaignKeyword[] = [];

  /**
   * @generated from field: int32 reports = 4;
   */
  reports = 0;

  /**
   * @generated from field: google.protobuf.Int64Value brand_market_id = 5;
   */
  brandMarketId?: bigint;

  /**
   * @generated from field: google.protobuf.BoolValue archived = 6;
   */
  archived?: boolean;

  constructor(data?: PartialMessage<CWMarketMentions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWMarketMentions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "market", kind: "message", T: CWMarket },
    { no: 7, name: "keywords", kind: "message", T: CampaignKeyword, repeated: true },
    { no: 8, name: "keyword_filters", kind: "message", T: CampaignKeyword, repeated: true },
    { no: 4, name: "reports", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "brand_market_id", kind: "message", T: Int64Value },
    { no: 6, name: "archived", kind: "message", T: BoolValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWMarketMentions {
    return new CWMarketMentions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWMarketMentions {
    return new CWMarketMentions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWMarketMentions {
    return new CWMarketMentions().fromJsonString(jsonString, options);
  }

  static equals(a: CWMarketMentions | PlainMessage<CWMarketMentions> | undefined, b: CWMarketMentions | PlainMessage<CWMarketMentions> | undefined): boolean {
    return proto3.util.equals(CWMarketMentions, a, b);
  }
}

/**
 * @generated from message librarian.proto.BrandResponse
 */
export class BrandResponse extends Message<BrandResponse> {
  /**
   * @generated from field: repeated librarian.proto.CWBrandMarkets brand_markets = 1;
   */
  brandMarkets: CWBrandMarkets[] = [];

  constructor(data?: PartialMessage<BrandResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.BrandResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brand_markets", kind: "message", T: CWBrandMarkets, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BrandResponse {
    return new BrandResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BrandResponse {
    return new BrandResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BrandResponse {
    return new BrandResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BrandResponse | PlainMessage<BrandResponse> | undefined, b: BrandResponse | PlainMessage<BrandResponse> | undefined): boolean {
    return proto3.util.equals(BrandResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWReportRequest
 */
export class CWReportRequest extends Message<CWReportRequest> {
  /**
   * if this is present then it is an edit request otherwise it is a create request
   *
   * @generated from field: google.protobuf.Int64Value report_id = 1;
   */
  reportId?: bigint;

  /**
   * @generated from field: google.protobuf.Int64Value user_id = 2 [deprecated = true];
   * @deprecated
   */
  userId?: bigint;

  /**
   * @generated from field: repeated int64 user_ids = 18;
   */
  userIds: bigint[] = [];

  /**
   * @generated from field: google.protobuf.StringValue name = 3;
   */
  name?: string;

  /**
   * @generated from field: repeated int64 campaign_ids = 11;
   */
  campaignIds: bigint[] = [];

  /**
   * @generated from field: google.protobuf.Int64Value reference_brand = 5;
   */
  referenceBrand?: bigint;

  /**
   * @generated from field: repeated int64 brand_competitors = 17;
   */
  brandCompetitors: bigint[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp start_date = 6;
   */
  startDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp expiry_date = 14;
   */
  expiryDate?: Timestamp;

  /**
   * Min followers for influencers
   *
   * @generated from oneof librarian.proto.CWReportRequest.min_followers_of
   */
  minFollowersOf: {
    /**
     * @generated from field: google.protobuf.Int32Value min_followers = 12;
     */
    value: Int32Value;
    case: "minFollowers";
  } | {
    /**
     * @generated from field: bool clear_min_followers = 13;
     */
    value: boolean;
    case: "clearMinFollowers";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: repeated common.proto.Network networks = 16;
   */
  networks: Network[] = [];

  /**
   * @generated from field: librarian.proto.ReportPriority priority = 15;
   */
  priority = ReportPriority.UNDEFINED;

  constructor(data?: PartialMessage<CWReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_id", kind: "message", T: Int64Value },
    { no: 2, name: "user_id", kind: "message", T: Int64Value },
    { no: 18, name: "user_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 3, name: "name", kind: "message", T: StringValue },
    { no: 11, name: "campaign_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 5, name: "reference_brand", kind: "message", T: Int64Value },
    { no: 17, name: "brand_competitors", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 6, name: "start_date", kind: "message", T: Timestamp },
    { no: 14, name: "expiry_date", kind: "message", T: Timestamp },
    { no: 12, name: "min_followers", kind: "message", T: Int32Value, oneof: "min_followers_of" },
    { no: 13, name: "clear_min_followers", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "min_followers_of" },
    { no: 16, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 15, name: "priority", kind: "enum", T: proto3.getEnumType(ReportPriority) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWReportRequest {
    return new CWReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWReportRequest {
    return new CWReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWReportRequest {
    return new CWReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWReportRequest | PlainMessage<CWReportRequest> | undefined, b: CWReportRequest | PlainMessage<CWReportRequest> | undefined): boolean {
    return proto3.util.equals(CWReportRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWBrandMarket
 */
export class CWBrandMarket extends Message<CWBrandMarket> {
  /**
   * @generated from field: librarian.proto.CWBrandPb brand = 1;
   */
  brand?: CWBrandPb;

  /**
   * @generated from field: librarian.proto.CWMarket market = 2 [deprecated = true];
   * @deprecated
   */
  market?: CWMarket;

  constructor(data?: PartialMessage<CWBrandMarket>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWBrandMarket";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brand", kind: "message", T: CWBrandPb },
    { no: 2, name: "market", kind: "message", T: CWMarket },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWBrandMarket {
    return new CWBrandMarket().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWBrandMarket {
    return new CWBrandMarket().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWBrandMarket {
    return new CWBrandMarket().fromJsonString(jsonString, options);
  }

  static equals(a: CWBrandMarket | PlainMessage<CWBrandMarket> | undefined, b: CWBrandMarket | PlainMessage<CWBrandMarket> | undefined): boolean {
    return proto3.util.equals(CWBrandMarket, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWReportDetails
 */
export class CWReportDetails extends Message<CWReportDetails> {
  /**
   * @generated from field: librarian.proto.TeamDetails team = 1 [deprecated = true];
   * @deprecated
   */
  team?: TeamDetails;

  /**
   * @generated from field: repeated librarian.proto.TeamDetails teams = 30;
   */
  teams: TeamDetails[] = [];

  /**
   * @generated from field: int64 report_id = 2;
   */
  reportId = protoInt64.zero;

  /**
   * @generated from field: string report_name = 3;
   */
  reportName = "";

  /**
   * @generated from field: google.protobuf.Timestamp start = 4;
   */
  start?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp created = 5;
   */
  created?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp expiry_date = 26;
   */
  expiryDate?: Timestamp;

  /**
   * @generated from field: repeated common.proto.CampaignSnippet campaigns = 12;
   */
  campaigns: CampaignSnippet[] = [];

  /**
   * @generated from field: librarian.proto.CWBrandPb reference_brand = 7;
   */
  referenceBrand?: CWBrandPb;

  /**
   * @generated from field: repeated librarian.proto.CWBrandMarket brand_markets = 8 [deprecated = true];
   * @deprecated
   */
  brandMarkets: CWBrandMarket[] = [];

  /**
   * don't contain reference brand
   *
   * @generated from field: repeated librarian.proto.CWBrandPb brand_competitors = 31;
   */
  brandCompetitors: CWBrandPb[] = [];

  /**
   * @generated from field: int32 brands_count = 25;
   */
  brandsCount = 0;

  /**
   * @generated from field: bool archived = 9;
   */
  archived = false;

  /**
   * @generated from field: repeated common.proto.Network networks = 28;
   */
  networks: Network[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp refreshed = 20;
   */
  refreshed?: Timestamp;

  /**
   * How many influencers in this report's sources have been categorized with a raw_category_kind (the category scrapped
   * from INSTA). Value between 0 and 100.
   *
   * @generated from field: float with_insta_category_percentage = 21;
   */
  withInstaCategoryPercentage = 0;

  /**
   * @generated from field: bool categorize_influencers = 22;
   */
  categorizeInfluencers = false;

  /**
   * @generated from field: google.protobuf.Int32Value min_followers = 23;
   */
  minFollowers?: number;

  /**
   * @generated from field: librarian.proto.ReportPriority priority = 27;
   */
  priority = ReportPriority.UNDEFINED;

  /**
   * @generated from field: repeated string excluded_influencers_usernames = 24 [deprecated = true];
   * @deprecated
   */
  excludedInfluencersUsernames: string[] = [];

  /**
   * @generated from field: repeated librarian.proto.CWReportDetails.NetworkExcludedInfluencer excluded_influencers = 29;
   */
  excludedInfluencers: CWReportDetails_NetworkExcludedInfluencer[] = [];

  /**
   * @generated from oneof librarian.proto.CWReportDetails.refresh_at_step_token
   */
  refreshAtStepToken: {
    /**
     * @generated from field: string poll_refresh_token = 103;
     */
    value: string;
    case: "pollRefreshToken";
  } | {
    /**
     * @generated from field: string track_refresh_token = 104;
     */
    value: string;
    case: "trackRefreshToken";
  } | {
    /**
     * @generated from field: string audit_refresh_token = 105;
     */
    value: string;
    case: "auditRefreshToken";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CWReportDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWReportDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "team", kind: "message", T: TeamDetails },
    { no: 30, name: "teams", kind: "message", T: TeamDetails, repeated: true },
    { no: 2, name: "report_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "report_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "start", kind: "message", T: Timestamp },
    { no: 5, name: "created", kind: "message", T: Timestamp },
    { no: 26, name: "expiry_date", kind: "message", T: Timestamp },
    { no: 12, name: "campaigns", kind: "message", T: CampaignSnippet, repeated: true },
    { no: 7, name: "reference_brand", kind: "message", T: CWBrandPb },
    { no: 8, name: "brand_markets", kind: "message", T: CWBrandMarket, repeated: true },
    { no: 31, name: "brand_competitors", kind: "message", T: CWBrandPb, repeated: true },
    { no: 25, name: "brands_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 28, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 20, name: "refreshed", kind: "message", T: Timestamp },
    { no: 21, name: "with_insta_category_percentage", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 22, name: "categorize_influencers", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 23, name: "min_followers", kind: "message", T: Int32Value },
    { no: 27, name: "priority", kind: "enum", T: proto3.getEnumType(ReportPriority) },
    { no: 24, name: "excluded_influencers_usernames", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 29, name: "excluded_influencers", kind: "message", T: CWReportDetails_NetworkExcludedInfluencer, repeated: true },
    { no: 103, name: "poll_refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "refresh_at_step_token" },
    { no: 104, name: "track_refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "refresh_at_step_token" },
    { no: 105, name: "audit_refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "refresh_at_step_token" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWReportDetails {
    return new CWReportDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWReportDetails {
    return new CWReportDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWReportDetails {
    return new CWReportDetails().fromJsonString(jsonString, options);
  }

  static equals(a: CWReportDetails | PlainMessage<CWReportDetails> | undefined, b: CWReportDetails | PlainMessage<CWReportDetails> | undefined): boolean {
    return proto3.util.equals(CWReportDetails, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWReportDetails.NetworkExcludedInfluencer
 */
export class CWReportDetails_NetworkExcludedInfluencer extends Message<CWReportDetails_NetworkExcludedInfluencer> {
  /**
   * @generated from field: repeated string usernames = 1;
   */
  usernames: string[] = [];

  /**
   * @generated from field: common.proto.Network network = 2;
   */
  network = Network.NETWORK_UNKNOWN;

  constructor(data?: PartialMessage<CWReportDetails_NetworkExcludedInfluencer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWReportDetails.NetworkExcludedInfluencer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "usernames", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWReportDetails_NetworkExcludedInfluencer {
    return new CWReportDetails_NetworkExcludedInfluencer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWReportDetails_NetworkExcludedInfluencer {
    return new CWReportDetails_NetworkExcludedInfluencer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWReportDetails_NetworkExcludedInfluencer {
    return new CWReportDetails_NetworkExcludedInfluencer().fromJsonString(jsonString, options);
  }

  static equals(a: CWReportDetails_NetworkExcludedInfluencer | PlainMessage<CWReportDetails_NetworkExcludedInfluencer> | undefined, b: CWReportDetails_NetworkExcludedInfluencer | PlainMessage<CWReportDetails_NetworkExcludedInfluencer> | undefined): boolean {
    return proto3.util.equals(CWReportDetails_NetworkExcludedInfluencer, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWReportRefreshAtStepRequest
 */
export class CWReportRefreshAtStepRequest extends Message<CWReportRefreshAtStepRequest> {
  /**
   * @generated from field: int64 report_id = 1;
   */
  reportId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.ReportRefreshStep start_step = 2;
   */
  startStep = ReportRefreshStep.UNDEFINED;

  /**
   * if not set, the cutoff is yesterday
   *
   * @generated from field: google.protobuf.Timestamp cut_off = 3;
   */
  cutOff?: Timestamp;

  constructor(data?: PartialMessage<CWReportRefreshAtStepRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWReportRefreshAtStepRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "start_step", kind: "enum", T: proto3.getEnumType(ReportRefreshStep) },
    { no: 3, name: "cut_off", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWReportRefreshAtStepRequest {
    return new CWReportRefreshAtStepRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWReportRefreshAtStepRequest {
    return new CWReportRefreshAtStepRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWReportRefreshAtStepRequest {
    return new CWReportRefreshAtStepRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWReportRefreshAtStepRequest | PlainMessage<CWReportRefreshAtStepRequest> | undefined, b: CWReportRefreshAtStepRequest | PlainMessage<CWReportRefreshAtStepRequest> | undefined): boolean {
    return proto3.util.equals(CWReportRefreshAtStepRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWReportsResponse
 */
export class CWReportsResponse extends Message<CWReportsResponse> {
  /**
   * not all fields are filled in this proto.
   *
   * @generated from field: repeated librarian.proto.CWReportDetails reports = 1;
   */
  reports: CWReportDetails[] = [];

  constructor(data?: PartialMessage<CWReportsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWReportsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "reports", kind: "message", T: CWReportDetails, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWReportsResponse {
    return new CWReportsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWReportsResponse {
    return new CWReportsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWReportsResponse {
    return new CWReportsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CWReportsResponse | PlainMessage<CWReportsResponse> | undefined, b: CWReportsResponse | PlainMessage<CWReportsResponse> | undefined): boolean {
    return proto3.util.equals(CWReportsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWReportAddBrandRequest
 */
export class CWReportAddBrandRequest extends Message<CWReportAddBrandRequest> {
  /**
   * @generated from field: int64 report_id = 1;
   */
  reportId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.CWBrandMarket brand_market = 2;
   */
  brandMarket?: CWBrandMarket;

  constructor(data?: PartialMessage<CWReportAddBrandRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWReportAddBrandRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "brand_market", kind: "message", T: CWBrandMarket },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWReportAddBrandRequest {
    return new CWReportAddBrandRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWReportAddBrandRequest {
    return new CWReportAddBrandRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWReportAddBrandRequest {
    return new CWReportAddBrandRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWReportAddBrandRequest | PlainMessage<CWReportAddBrandRequest> | undefined, b: CWReportAddBrandRequest | PlainMessage<CWReportAddBrandRequest> | undefined): boolean {
    return proto3.util.equals(CWReportAddBrandRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWReportRemoveBrandRequest
 */
export class CWReportRemoveBrandRequest extends Message<CWReportRemoveBrandRequest> {
  /**
   * @generated from field: int64 report_id = 1;
   */
  reportId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.CWBrandMarket brand_market = 2;
   */
  brandMarket?: CWBrandMarket;

  constructor(data?: PartialMessage<CWReportRemoveBrandRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWReportRemoveBrandRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "brand_market", kind: "message", T: CWBrandMarket },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWReportRemoveBrandRequest {
    return new CWReportRemoveBrandRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWReportRemoveBrandRequest {
    return new CWReportRemoveBrandRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWReportRemoveBrandRequest {
    return new CWReportRemoveBrandRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWReportRemoveBrandRequest | PlainMessage<CWReportRemoveBrandRequest> | undefined, b: CWReportRemoveBrandRequest | PlainMessage<CWReportRemoveBrandRequest> | undefined): boolean {
    return proto3.util.equals(CWReportRemoveBrandRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.NetworkSourceRequest
 */
export class NetworkSourceRequest extends Message<NetworkSourceRequest> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: librarian.proto.SourceRequest request = 2;
   */
  request?: SourceRequest;

  constructor(data?: PartialMessage<NetworkSourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.NetworkSourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "request", kind: "message", T: SourceRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkSourceRequest {
    return new NetworkSourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkSourceRequest {
    return new NetworkSourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkSourceRequest {
    return new NetworkSourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkSourceRequest | PlainMessage<NetworkSourceRequest> | undefined, b: NetworkSourceRequest | PlainMessage<NetworkSourceRequest> | undefined): boolean {
    return proto3.util.equals(NetworkSourceRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.NetworkSource
 */
export class NetworkSource extends Message<NetworkSource> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: librarian.proto.SourceRequest request = 2;
   */
  request?: SourceRequest;

  /**
   * Optional, only if source has been built
   *
   * @generated from field: google.protobuf.Int64Value count = 3;
   */
  count?: bigint;

  /**
   * @generated from field: int64 source_id = 4;
   */
  sourceId = protoInt64.zero;

  constructor(data?: PartialMessage<NetworkSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.NetworkSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "request", kind: "message", T: SourceRequest },
    { no: 3, name: "count", kind: "message", T: Int64Value },
    { no: 4, name: "source_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkSource {
    return new NetworkSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkSource {
    return new NetworkSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkSource {
    return new NetworkSource().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkSource | PlainMessage<NetworkSource> | undefined, b: NetworkSource | PlainMessage<NetworkSource> | undefined): boolean {
    return proto3.util.equals(NetworkSource, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWCreateSetRequest
 */
export class CWCreateSetRequest extends Message<CWCreateSetRequest> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: repeated librarian.proto.NetworkSourceRequest sources = 4;
   */
  sources: NetworkSourceRequest[] = [];

  constructor(data?: PartialMessage<CWCreateSetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWCreateSetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "sources", kind: "message", T: NetworkSourceRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWCreateSetRequest {
    return new CWCreateSetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWCreateSetRequest {
    return new CWCreateSetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWCreateSetRequest {
    return new CWCreateSetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWCreateSetRequest | PlainMessage<CWCreateSetRequest> | undefined, b: CWCreateSetRequest | PlainMessage<CWCreateSetRequest> | undefined): boolean {
    return proto3.util.equals(CWCreateSetRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWEditSetRequest
 */
export class CWEditSetRequest extends Message<CWEditSetRequest> {
  /**
   * @generated from field: int64 set_id = 1;
   */
  setId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: google.protobuf.Int64Value market_id = 4;
   */
  marketId?: bigint;

  /**
   * @generated from field: google.protobuf.Int64Value industry_id = 5;
   */
  industryId?: bigint;

  constructor(data?: PartialMessage<CWEditSetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWEditSetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "set_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "market_id", kind: "message", T: Int64Value },
    { no: 5, name: "industry_id", kind: "message", T: Int64Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWEditSetRequest {
    return new CWEditSetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWEditSetRequest {
    return new CWEditSetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWEditSetRequest {
    return new CWEditSetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWEditSetRequest | PlainMessage<CWEditSetRequest> | undefined, b: CWEditSetRequest | PlainMessage<CWEditSetRequest> | undefined): boolean {
    return proto3.util.equals(CWEditSetRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWAddSourcesRequest
 */
export class CWAddSourcesRequest extends Message<CWAddSourcesRequest> {
  /**
   * @generated from field: int64 set_id = 1;
   */
  setId = protoInt64.zero;

  /**
   * @generated from field: repeated librarian.proto.NetworkSourceRequest sources = 3;
   */
  sources: NetworkSourceRequest[] = [];

  constructor(data?: PartialMessage<CWAddSourcesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWAddSourcesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "set_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "sources", kind: "message", T: NetworkSourceRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWAddSourcesRequest {
    return new CWAddSourcesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWAddSourcesRequest {
    return new CWAddSourcesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWAddSourcesRequest {
    return new CWAddSourcesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWAddSourcesRequest | PlainMessage<CWAddSourcesRequest> | undefined, b: CWAddSourcesRequest | PlainMessage<CWAddSourcesRequest> | undefined): boolean {
    return proto3.util.equals(CWAddSourcesRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWGetSetResponse
 */
export class CWGetSetResponse extends Message<CWGetSetResponse> {
  /**
   * @generated from field: int64 set_id = 1;
   */
  setId = protoInt64.zero;

  /**
   * @generated from field: int64 campaign_id = 2;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp campaign_date = 7;
   */
  campaignDate?: Timestamp;

  /**
   * @generated from field: string name = 3;
   */
  name = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: repeated common.proto.Network networks = 13;
   */
  networks: Network[] = [];

  /**
   * @generated from field: repeated librarian.proto.NetworkSource sources = 12;
   */
  sources: NetworkSource[] = [];

  /**
   * @generated from field: librarian.proto.CWMarket market = 14;
   */
  market?: CWMarket;

  /**
   * @generated from field: librarian.proto.CWIndustryPb industry = 15;
   */
  industry?: CWIndustryPb;

  /**
   * @generated from field: int64 count = 6;
   */
  count = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp refresh_date = 10;
   */
  refreshDate?: Timestamp;

  /**
   * @generated from field: bool archived = 11;
   */
  archived = false;

  /**
   * This is the token associated with the refresh campaign task (post marking)
   *
   * @generated from field: string refresh_token = 100;
   */
  refreshToken = "";

  constructor(data?: PartialMessage<CWGetSetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWGetSetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "set_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "campaign_date", kind: "message", T: Timestamp },
    { no: 3, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 12, name: "sources", kind: "message", T: NetworkSource, repeated: true },
    { no: 14, name: "market", kind: "message", T: CWMarket },
    { no: 15, name: "industry", kind: "message", T: CWIndustryPb },
    { no: 6, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "refresh_date", kind: "message", T: Timestamp },
    { no: 11, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 100, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWGetSetResponse {
    return new CWGetSetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWGetSetResponse {
    return new CWGetSetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWGetSetResponse {
    return new CWGetSetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CWGetSetResponse | PlainMessage<CWGetSetResponse> | undefined, b: CWGetSetResponse | PlainMessage<CWGetSetResponse> | undefined): boolean {
    return proto3.util.equals(CWGetSetResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWGetSetsResponse
 */
export class CWGetSetsResponse extends Message<CWGetSetsResponse> {
  /**
   * @generated from field: repeated librarian.proto.CWGetSetResponse set = 1;
   */
  set: CWGetSetResponse[] = [];

  /**
   * @generated from field: int64 total_hits = 2;
   */
  totalHits = protoInt64.zero;

  constructor(data?: PartialMessage<CWGetSetsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWGetSetsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "set", kind: "message", T: CWGetSetResponse, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWGetSetsResponse {
    return new CWGetSetsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWGetSetsResponse {
    return new CWGetSetsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWGetSetsResponse {
    return new CWGetSetsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CWGetSetsResponse | PlainMessage<CWGetSetsResponse> | undefined, b: CWGetSetsResponse | PlainMessage<CWGetSetsResponse> | undefined): boolean {
    return proto3.util.equals(CWGetSetsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWSetGroupPb
 */
export class CWSetGroupPb extends Message<CWSetGroupPb> {
  /**
   * @generated from field: int64 set_group_id = 1;
   */
  setGroupId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.StringValue description = 3;
   */
  description?: string;

  /**
   * @generated from field: repeated librarian.proto.CWGetSetResponse sets = 4;
   */
  sets: CWGetSetResponse[] = [];

  /**
   * @generated from field: librarian.proto.CWMarket market = 5;
   */
  market?: CWMarket;

  /**
   * @generated from field: librarian.proto.CWIndustryPb industry = 6;
   */
  industry?: CWIndustryPb;

  /**
   * @generated from field: int64 count = 7;
   */
  count = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp creation_date = 8;
   */
  creationDate?: Timestamp;

  /**
   * @generated from field: bool archived = 9;
   */
  archived = false;

  constructor(data?: PartialMessage<CWSetGroupPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWSetGroupPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "set_group_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "message", T: StringValue },
    { no: 4, name: "sets", kind: "message", T: CWGetSetResponse, repeated: true },
    { no: 5, name: "market", kind: "message", T: CWMarket },
    { no: 6, name: "industry", kind: "message", T: CWIndustryPb },
    { no: 7, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "creation_date", kind: "message", T: Timestamp },
    { no: 9, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWSetGroupPb {
    return new CWSetGroupPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWSetGroupPb {
    return new CWSetGroupPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWSetGroupPb {
    return new CWSetGroupPb().fromJsonString(jsonString, options);
  }

  static equals(a: CWSetGroupPb | PlainMessage<CWSetGroupPb> | undefined, b: CWSetGroupPb | PlainMessage<CWSetGroupPb> | undefined): boolean {
    return proto3.util.equals(CWSetGroupPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWSetGroups
 */
export class CWSetGroups extends Message<CWSetGroups> {
  /**
   * @generated from field: repeated librarian.proto.CWSetGroupPb set_groups = 1;
   */
  setGroups: CWSetGroupPb[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<CWSetGroups>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWSetGroups";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "set_groups", kind: "message", T: CWSetGroupPb, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWSetGroups {
    return new CWSetGroups().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWSetGroups {
    return new CWSetGroups().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWSetGroups {
    return new CWSetGroups().fromJsonString(jsonString, options);
  }

  static equals(a: CWSetGroups | PlainMessage<CWSetGroups> | undefined, b: CWSetGroups | PlainMessage<CWSetGroups> | undefined): boolean {
    return proto3.util.equals(CWSetGroups, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWCreateOrEditSetGroupRequest
 */
export class CWCreateOrEditSetGroupRequest extends Message<CWCreateOrEditSetGroupRequest> {
  /**
   * @generated from field: google.protobuf.Int64Value set_group_id = 1;
   */
  setGroupId?: bigint;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * for edit, if not set, do nothing
   *
   * @generated from field: google.protobuf.StringValue description = 3;
   */
  description?: string;

  /**
   * @generated from field: int64 market_id = 4;
   */
  marketId = protoInt64.zero;

  /**
   * @generated from field: int64 industry_id = 5;
   */
  industryId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 set_ids = 6;
   */
  setIds: bigint[] = [];

  constructor(data?: PartialMessage<CWCreateOrEditSetGroupRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWCreateOrEditSetGroupRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "set_group_id", kind: "message", T: Int64Value },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "message", T: StringValue },
    { no: 4, name: "market_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "industry_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "set_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWCreateOrEditSetGroupRequest {
    return new CWCreateOrEditSetGroupRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWCreateOrEditSetGroupRequest {
    return new CWCreateOrEditSetGroupRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWCreateOrEditSetGroupRequest {
    return new CWCreateOrEditSetGroupRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWCreateOrEditSetGroupRequest | PlainMessage<CWCreateOrEditSetGroupRequest> | undefined, b: CWCreateOrEditSetGroupRequest | PlainMessage<CWCreateOrEditSetGroupRequest> | undefined): boolean {
    return proto3.util.equals(CWCreateOrEditSetGroupRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWGetSetGroupsRequest
 */
export class CWGetSetGroupsRequest extends Message<CWGetSetGroupsRequest> {
  /**
   * @generated from field: common.proto.BasicPagination pagination = 1;
   */
  pagination?: BasicPagination;

  /**
   * @generated from field: repeated int64 market_ids = 2;
   */
  marketIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 industry_ids = 3;
   */
  industryIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 set_ids = 4;
   */
  setIds: bigint[] = [];

  /**
   * @generated from field: string name_prefix = 5;
   */
  namePrefix = "";

  /**
   * @generated from field: bool archived = 6;
   */
  archived = false;

  /**
   * by default creation date
   *
   * @generated from field: librarian.proto.CWSort sort = 7;
   */
  sort = CWSort.CW_SORT_UNDEFINED;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 8;
   */
  sortDirection = SortDirection.DESC;

  constructor(data?: PartialMessage<CWGetSetGroupsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWGetSetGroupsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: BasicPagination },
    { no: 2, name: "market_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 3, name: "industry_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "set_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 5, name: "name_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "sort", kind: "enum", T: proto3.getEnumType(CWSort) },
    { no: 8, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWGetSetGroupsRequest {
    return new CWGetSetGroupsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWGetSetGroupsRequest {
    return new CWGetSetGroupsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWGetSetGroupsRequest {
    return new CWGetSetGroupsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWGetSetGroupsRequest | PlainMessage<CWGetSetGroupsRequest> | undefined, b: CWGetSetGroupsRequest | PlainMessage<CWGetSetGroupsRequest> | undefined): boolean {
    return proto3.util.equals(CWGetSetGroupsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWEditSetGroupsRequest
 */
export class CWEditSetGroupsRequest extends Message<CWEditSetGroupsRequest> {
  /**
   * @generated from field: repeated int64 set_group_ids = 1;
   */
  setGroupIds: bigint[] = [];

  /**
   * if not set, do nothing
   *
   * @generated from field: google.protobuf.StringValue description = 2;
   */
  description?: string;

  /**
   * @generated from field: google.protobuf.Int64Value market_id = 3;
   */
  marketId?: bigint;

  /**
   * @generated from field: google.protobuf.Int64Value industry_id = 4;
   */
  industryId?: bigint;

  /**
   * @generated from field: google.protobuf.BoolValue archived = 5;
   */
  archived?: boolean;

  /**
   * @generated from field: repeated int64 set_ids_to_add = 6;
   */
  setIdsToAdd: bigint[] = [];

  constructor(data?: PartialMessage<CWEditSetGroupsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWEditSetGroupsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "set_group_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 2, name: "description", kind: "message", T: StringValue },
    { no: 3, name: "market_id", kind: "message", T: Int64Value },
    { no: 4, name: "industry_id", kind: "message", T: Int64Value },
    { no: 5, name: "archived", kind: "message", T: BoolValue },
    { no: 6, name: "set_ids_to_add", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWEditSetGroupsRequest {
    return new CWEditSetGroupsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWEditSetGroupsRequest {
    return new CWEditSetGroupsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWEditSetGroupsRequest {
    return new CWEditSetGroupsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWEditSetGroupsRequest | PlainMessage<CWEditSetGroupsRequest> | undefined, b: CWEditSetGroupsRequest | PlainMessage<CWEditSetGroupsRequest> | undefined): boolean {
    return proto3.util.equals(CWEditSetGroupsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWCreateOrEditReportResponse
 */
export class CWCreateOrEditReportResponse extends Message<CWCreateOrEditReportResponse> {
  /**
   * @generated from field: librarian.proto.CWReportDetails report_details = 1;
   */
  reportDetails?: CWReportDetails;

  /**
   * @generated from field: string token = 2;
   */
  token = "";

  constructor(data?: PartialMessage<CWCreateOrEditReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWCreateOrEditReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_details", kind: "message", T: CWReportDetails },
    { no: 2, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWCreateOrEditReportResponse {
    return new CWCreateOrEditReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWCreateOrEditReportResponse {
    return new CWCreateOrEditReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWCreateOrEditReportResponse {
    return new CWCreateOrEditReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CWCreateOrEditReportResponse | PlainMessage<CWCreateOrEditReportResponse> | undefined, b: CWCreateOrEditReportResponse | PlainMessage<CWCreateOrEditReportResponse> | undefined): boolean {
    return proto3.util.equals(CWCreateOrEditReportResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.DownloadReportRequest
 */
export class DownloadReportRequest extends Message<DownloadReportRequest> {
  /**
   * @generated from field: int64 report_id = 1;
   */
  reportId = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp period_start = 2;
   */
  periodStart?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp period_end = 3;
   */
  periodEnd?: Timestamp;

  /**
   * Filled by backend for RPC calls between librarian and archivist
   *
   * @generated from field: int64 user_id = 4;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<DownloadReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DownloadReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "period_start", kind: "message", T: Timestamp },
    { no: 3, name: "period_end", kind: "message", T: Timestamp },
    { no: 4, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DownloadReportRequest {
    return new DownloadReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DownloadReportRequest {
    return new DownloadReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DownloadReportRequest {
    return new DownloadReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DownloadReportRequest | PlainMessage<DownloadReportRequest> | undefined, b: DownloadReportRequest | PlainMessage<DownloadReportRequest> | undefined): boolean {
    return proto3.util.equals(DownloadReportRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWGetSetsRequest
 */
export class CWGetSetsRequest extends Message<CWGetSetsRequest> {
  /**
   * if not set, returns all
   *
   * @generated from field: common.proto.BasicPagination pagination = 2;
   */
  pagination?: BasicPagination;

  /**
   * @generated from field: repeated int64 market_ids = 3;
   */
  marketIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 industry_ids = 4;
   */
  industryIds: bigint[] = [];

  /**
   * @generated from field: string name_prefix = 5;
   */
  namePrefix = "";

  /**
   * @generated from field: bool archived = 1;
   */
  archived = false;

  /**
   * by default creation date
   *
   * @generated from field: librarian.proto.CWSort sort = 6;
   */
  sort = CWSort.CW_SORT_UNDEFINED;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 7;
   */
  sortDirection = SortDirection.DESC;

  constructor(data?: PartialMessage<CWGetSetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWGetSetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "pagination", kind: "message", T: BasicPagination },
    { no: 3, name: "market_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "industry_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 5, name: "name_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 1, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "sort", kind: "enum", T: proto3.getEnumType(CWSort) },
    { no: 7, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWGetSetsRequest {
    return new CWGetSetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWGetSetsRequest {
    return new CWGetSetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWGetSetsRequest {
    return new CWGetSetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWGetSetsRequest | PlainMessage<CWGetSetsRequest> | undefined, b: CWGetSetsRequest | PlainMessage<CWGetSetsRequest> | undefined): boolean {
    return proto3.util.equals(CWGetSetsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWCountSourceRequest
 */
export class CWCountSourceRequest extends Message<CWCountSourceRequest> {
  /**
   * @generated from oneof librarian.proto.CWCountSourceRequest.input
   */
  input: {
    /**
     * @generated from field: librarian.proto.SourceRequest request = 1;
     */
    value: SourceRequest;
    case: "request";
  } | {
    /**
     * @generated from field: int64 source_id = 2;
     */
    value: bigint;
    case: "sourceId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<CWCountSourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWCountSourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request", kind: "message", T: SourceRequest, oneof: "input" },
    { no: 2, name: "source_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "input" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWCountSourceRequest {
    return new CWCountSourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWCountSourceRequest {
    return new CWCountSourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWCountSourceRequest {
    return new CWCountSourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWCountSourceRequest | PlainMessage<CWCountSourceRequest> | undefined, b: CWCountSourceRequest | PlainMessage<CWCountSourceRequest> | undefined): boolean {
    return proto3.util.equals(CWCountSourceRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWUpdateCategorizeReportReq
 */
export class CWUpdateCategorizeReportReq extends Message<CWUpdateCategorizeReportReq> {
  /**
   * @generated from field: int64 report_id = 1;
   */
  reportId = protoInt64.zero;

  /**
   * @generated from field: bool do_categorize = 2;
   */
  doCategorize = false;

  constructor(data?: PartialMessage<CWUpdateCategorizeReportReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWUpdateCategorizeReportReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "do_categorize", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWUpdateCategorizeReportReq {
    return new CWUpdateCategorizeReportReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWUpdateCategorizeReportReq {
    return new CWUpdateCategorizeReportReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWUpdateCategorizeReportReq {
    return new CWUpdateCategorizeReportReq().fromJsonString(jsonString, options);
  }

  static equals(a: CWUpdateCategorizeReportReq | PlainMessage<CWUpdateCategorizeReportReq> | undefined, b: CWUpdateCategorizeReportReq | PlainMessage<CWUpdateCategorizeReportReq> | undefined): boolean {
    return proto3.util.equals(CWUpdateCategorizeReportReq, a, b);
  }
}

/**
 * Message sent by frontend
 *
 * @generated from message librarian.proto.UploadExcludedInfluencersToReportRequest
 */
export class UploadExcludedInfluencersToReportRequest extends Message<UploadExcludedInfluencersToReportRequest> {
  /**
   * @generated from field: int64 report_id = 1;
   */
  reportId = protoInt64.zero;

  /**
   * @generated from field: repeated string influencer_handles = 2;
   */
  influencerHandles: string[] = [];

  /**
   * @generated from field: common.proto.Network network = 3;
   */
  network = Network.NETWORK_UNKNOWN;

  constructor(data?: PartialMessage<UploadExcludedInfluencersToReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadExcludedInfluencersToReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "influencer_handles", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadExcludedInfluencersToReportRequest {
    return new UploadExcludedInfluencersToReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadExcludedInfluencersToReportRequest {
    return new UploadExcludedInfluencersToReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadExcludedInfluencersToReportRequest {
    return new UploadExcludedInfluencersToReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadExcludedInfluencersToReportRequest | PlainMessage<UploadExcludedInfluencersToReportRequest> | undefined, b: UploadExcludedInfluencersToReportRequest | PlainMessage<UploadExcludedInfluencersToReportRequest> | undefined): boolean {
    return proto3.util.equals(UploadExcludedInfluencersToReportRequest, a, b);
  }
}

/**
 * Only used by server to server programmatic rpc calls
 *
 * @generated from message librarian.proto.AddExcludedInfluencersToReportRequest
 */
export class AddExcludedInfluencersToReportRequest extends Message<AddExcludedInfluencersToReportRequest> {
  /**
   * @generated from field: int64 report_id = 1;
   */
  reportId = protoInt64.zero;

  /**
   * @generated from field: common.proto.MetaIdPb influencer_id = 2;
   */
  influencerId?: MetaIdPb;

  /**
   * @generated from field: common.proto.Network network = 3;
   */
  network = Network.NETWORK_UNKNOWN;

  constructor(data?: PartialMessage<AddExcludedInfluencersToReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AddExcludedInfluencersToReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "influencer_id", kind: "message", T: MetaIdPb },
    { no: 3, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddExcludedInfluencersToReportRequest {
    return new AddExcludedInfluencersToReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddExcludedInfluencersToReportRequest {
    return new AddExcludedInfluencersToReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddExcludedInfluencersToReportRequest {
    return new AddExcludedInfluencersToReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddExcludedInfluencersToReportRequest | PlainMessage<AddExcludedInfluencersToReportRequest> | undefined, b: AddExcludedInfluencersToReportRequest | PlainMessage<AddExcludedInfluencersToReportRequest> | undefined): boolean {
    return proto3.util.equals(AddExcludedInfluencersToReportRequest, a, b);
  }
}

/**
 * Message sent by frontend
 *
 * @generated from message librarian.proto.UploadExcludedInfluencersToReportsOfNetworkRequest
 */
export class UploadExcludedInfluencersToReportsOfNetworkRequest extends Message<UploadExcludedInfluencersToReportsOfNetworkRequest> {
  /**
   * @generated from field: repeated string influencer_handles = 1;
   */
  influencerHandles: string[] = [];

  /**
   * @generated from field: common.proto.Network network = 2;
   */
  network = Network.NETWORK_UNKNOWN;

  constructor(data?: PartialMessage<UploadExcludedInfluencersToReportsOfNetworkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UploadExcludedInfluencersToReportsOfNetworkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_handles", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UploadExcludedInfluencersToReportsOfNetworkRequest {
    return new UploadExcludedInfluencersToReportsOfNetworkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UploadExcludedInfluencersToReportsOfNetworkRequest {
    return new UploadExcludedInfluencersToReportsOfNetworkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UploadExcludedInfluencersToReportsOfNetworkRequest {
    return new UploadExcludedInfluencersToReportsOfNetworkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UploadExcludedInfluencersToReportsOfNetworkRequest | PlainMessage<UploadExcludedInfluencersToReportsOfNetworkRequest> | undefined, b: UploadExcludedInfluencersToReportsOfNetworkRequest | PlainMessage<UploadExcludedInfluencersToReportsOfNetworkRequest> | undefined): boolean {
    return proto3.util.equals(UploadExcludedInfluencersToReportsOfNetworkRequest, a, b);
  }
}

/**
 * Only used by server to server programmatic rpc calls
 *
 * @generated from message librarian.proto.AddExcludedInfluencersToReportsOfNetworkRequest
 */
export class AddExcludedInfluencersToReportsOfNetworkRequest extends Message<AddExcludedInfluencersToReportsOfNetworkRequest> {
  /**
   * @generated from field: common.proto.MetaIdPb influencer_id = 1;
   */
  influencerId?: MetaIdPb;

  /**
   * @generated from field: common.proto.Network network = 2;
   */
  network = Network.NETWORK_UNKNOWN;

  constructor(data?: PartialMessage<AddExcludedInfluencersToReportsOfNetworkRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AddExcludedInfluencersToReportsOfNetworkRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_id", kind: "message", T: MetaIdPb },
    { no: 2, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddExcludedInfluencersToReportsOfNetworkRequest {
    return new AddExcludedInfluencersToReportsOfNetworkRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddExcludedInfluencersToReportsOfNetworkRequest {
    return new AddExcludedInfluencersToReportsOfNetworkRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddExcludedInfluencersToReportsOfNetworkRequest {
    return new AddExcludedInfluencersToReportsOfNetworkRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddExcludedInfluencersToReportsOfNetworkRequest | PlainMessage<AddExcludedInfluencersToReportsOfNetworkRequest> | undefined, b: AddExcludedInfluencersToReportsOfNetworkRequest | PlainMessage<AddExcludedInfluencersToReportsOfNetworkRequest> | undefined): boolean {
    return proto3.util.equals(AddExcludedInfluencersToReportsOfNetworkRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetExcludedInfluencersFromReportsResponse
 */
export class GetExcludedInfluencersFromReportsResponse extends Message<GetExcludedInfluencersFromReportsResponse> {
  /**
   * @generated from field: repeated librarian.proto.GetExcludedInfluencersFromReportsResponse.ExcludedInfluencersFromReportsOfNetwork excluded_influencers_of_network = 1;
   */
  excludedInfluencersOfNetwork: GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork[] = [];

  constructor(data?: PartialMessage<GetExcludedInfluencersFromReportsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetExcludedInfluencersFromReportsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "excluded_influencers_of_network", kind: "message", T: GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetExcludedInfluencersFromReportsResponse {
    return new GetExcludedInfluencersFromReportsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetExcludedInfluencersFromReportsResponse {
    return new GetExcludedInfluencersFromReportsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetExcludedInfluencersFromReportsResponse {
    return new GetExcludedInfluencersFromReportsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetExcludedInfluencersFromReportsResponse | PlainMessage<GetExcludedInfluencersFromReportsResponse> | undefined, b: GetExcludedInfluencersFromReportsResponse | PlainMessage<GetExcludedInfluencersFromReportsResponse> | undefined): boolean {
    return proto3.util.equals(GetExcludedInfluencersFromReportsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetExcludedInfluencersFromReportsResponse.ExcludedInfluencersFromReportsOfNetwork
 */
export class GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork extends Message<GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: repeated string influencer_handles = 2;
   */
  influencerHandles: string[] = [];

  constructor(data?: PartialMessage<GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetExcludedInfluencersFromReportsResponse.ExcludedInfluencersFromReportsOfNetwork";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "influencer_handles", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork {
    return new GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork {
    return new GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork {
    return new GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork().fromJsonString(jsonString, options);
  }

  static equals(a: GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork | PlainMessage<GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork> | undefined, b: GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork | PlainMessage<GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork> | undefined): boolean {
    return proto3.util.equals(GetExcludedInfluencersFromReportsResponse_ExcludedInfluencersFromReportsOfNetwork, a, b);
  }
}


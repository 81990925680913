import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  output,
} from '@angular/core';
import { isNotEmptyString } from '@frontend2/core';
import { ContentMimeType } from '@frontend2/proto/common/proto/common_pb';
import { GenerikSnippet_GenerikUrl } from '@frontend2/proto/librarian/proto/users_pb';
import { SafeImageComponent } from '../../image/image';
import { ObjectFit } from '../../utils';
import {
  getUrlsByPriority,
  getUrlsByType,
  POST_DEFAULT_RESOLUTIONS,
  POST_THUMBNAIL_RESOLUTIONS,
} from '../posts.models';

@Component({
  selector: 'lefty-post-image',
  template: `<safe-image
    [urls]="sanitizedUrls()"
    [objectFit]="objectFit()"
    (error$)="error$.emit($event)"
    (loading$)="loading$.emit($event)"
    (visible$)="visible$.emit($event)"
  ></safe-image> `,
  styleUrls: ['./post-image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [SafeImageComponent],
})
export class LeftyPostImageComponent {
  readonly urls = input<GenerikSnippet_GenerikUrl[]>([]);
  readonly objectFit = input<ObjectFit>('cover');
  readonly asThumbnail = input(false);

  readonly error$ = output<Event | 'timeout'>();

  readonly loading$ = output<boolean>();
  readonly visible$ = output<boolean>();

  readonly sanitizedUrls = computed(() => {
    const urls = getUrlsByType(this.urls(), ContentMimeType.IMAGE);

    const resolutions = this.asThumbnail()
      ? POST_THUMBNAIL_RESOLUTIONS
      : POST_DEFAULT_RESOLUTIONS;

    return getUrlsByPriority(urls, resolutions)
      .map((u) => u.url)
      .filter(isNotEmptyString);
  });
}

import { isEqual } from '../utils';
import { isNil, isNotNil, PlaceholderIfEmptyOptions } from './common.helpers';

export function isBlankString(value: string): boolean {
  return value === '';
}

export function isNotBlankString(value: string): boolean {
  return value !== '';
}

/**
 * Return true if value is not null|undefined AND not blank
 *
 * Using type predicate to make analyzer happy
 */
export function isNotEmptyString(
  value: string | undefined | null,
): value is string {
  return isNotNil<string>(value) && isNotBlankString(value);
}

export function isEmptyString(
  value: string | undefined | null,
): value is string {
  return isNil(value) || isBlankString(value);
}

export function replaceStringIfMatch(
  text: string,
  regexp: RegExp,
  replacer: (val?: string) => string,
): string {
  return text.replace(regexp, replacer);
}

export function cleanupStringToMatch(text: string, regexp: RegExp): string {
  return text
    .split('')
    .map((char) => {
      return regexp.test(char) ? char : '';
    })
    .join('');
}

/// transform the first letter of a word into uppercase
export function capitalize(s: string): string {
  if (isBlankString(s)) {
    return '';
  }
  return `${s[0].toUpperCase()}${s.substring(1).toLowerCase()}`;
}

/// return initials for a group of word separated by space
/// return first letters of the 2 first words
export function getInitials(words: string): string {
  return words
    .replace(/'/g, '')
    .split(' ')
    .filter((w) => w.length > 0)
    .slice(0, 2)
    .map((s) => s.substring(0, 1))
    .join('')
    .toUpperCase();
}

/// Convert lefty id such as 'ig:342422234324' into an id supported by Html DOM
/// => 'ig-342422234324'
export function sanitizeLeftyId(id: string): string {
  return id.replace(':', '-');
}

export function placeHolderIfEmpty(
  value: string,
  args: PlaceholderIfEmptyOptions = {
    emptyValue: '-',
  },
): string {
  return isNotEmptyString(value) ? value : args.emptyValue;
}

export function truncString(text: string, limit: number): string {
  if (isNotEmptyString(text) && text.length > limit) {
    return `${text.substring(0, limit)}...`;
  }
  return text;
}

export function removeDiacritics(str = ''): string {
  const withDia =
    'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖØòóôõöøÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜùúûüÑñŠšŸÿýŽž';
  const withoutDia =
    'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUuuuuNnSsYyyZz';

  for (let i = 0; i < withDia.length; i++) {
    str = str.replaceAll(withDia[i], withoutDia[i]);
  }
  return str;
}

export function isSameStringArray(array1: string[], array2: string[]): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  const sortedArray1 = array1.slice().sort();
  const sortedArray2 = array2.slice().sort();

  return isEqual(sortedArray1, sortedArray2);
}

export function escapeRegExp(text: string): string {
  return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

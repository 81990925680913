<button
  #popupTrigger
  class="selector-btn"
  [ngClass]="buttonClass"
  focusableElement
  [class.active]="popup.visible"
>
  <div class="button-content">
    <span
      class="placeholder"
      [class.empty]="!hasValue() || isEmpty"
    >
      {{ placeholder }}</span
    >
  </div>
  <lefty-icon icon="arrow_drop_down"></lefty-icon>
</button>
<lefty-popup
  [placement]="popupPlacement"
  [popupTrigger]="popupTrigger"
  [matchMinSourceWidth]="true"
  #popup
>
  <div class="popup-content">
    <div
      class="header"
      *ngIf="networks.length > 1"
    >
      <button
        leftyButton="plain"
        class="button-clear"
        (click)="clearAll()"
        [class.active]="hasValue() && !isEmpty"
        i18n
      >
        Clear All
      </button>
    </div>
    <div class="networks-list">
      <div
        class="networks"
        *ngFor="let network of networks"
        [ngClass]="readableNetwork(network)"
      >
        <network-icon [network]="network"></network-icon>
        <lefty-range-input
          [allowDouble]="true"
          [ngModel]="followers.get(network)"
          (ngModelChange)="rangeChange(network, $event)"
        ></lefty-range-input>
      </div>
    </div>
  </div>
</lefty-popup>

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/discover.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { CreatorCardSnippet, GenerikInfoCard } from "./creators_pb";
import { FavoritesLabels, InfluencerCampaigns } from "../../common/proto/campaign_pb";
import { DoubleRange, Gender, GenderWeight, Int32Range, Int32RangeWeight, LogicOperator, Network, SortDirection, StringWeight, TokenPagination } from "../../common/proto/common_pb";
import { SnapchatSubcategory } from "../../common/proto/snapchat_pb";
import { AccountType, AudienceSource, Geo } from "../../universal/arkansas/proto/arkansas_pb";
import { MonthlyGrowthRequest, UserSort } from "./common_pb";

/**
 * @generated from message librarian.proto.DiscoverCreatorResponse
 */
export class DiscoverCreatorResponse extends Message<DiscoverCreatorResponse> {
  /**
   * @generated from field: librarian.proto.CreatorCardSnippet base_snippet = 1;
   */
  baseSnippet?: CreatorCardSnippet;

  /**
   * @generated from field: common.proto.InfluencerCampaigns statuses = 2;
   */
  statuses?: InfluencerCampaigns;

  /**
   * @generated from field: common.proto.FavoritesLabels labels = 3;
   */
  labels?: FavoritesLabels;

  /**
   * @generated from field: repeated librarian.proto.GenerikInfoCard network_info = 4;
   */
  networkInfo: GenerikInfoCard[] = [];

  /**
   * Frontend need to know if the influencer is in Lefty Database
   * so it can visit the profile directly
   *
   * Or trigger a fast ingest if profile is not in DB
   *
   * @generated from field: bool lefty_ingested = 5;
   */
  leftyIngested = false;

  /**
   * @generated from field: bool in_pool = 6;
   */
  inPool = false;

  constructor(data?: PartialMessage<DiscoverCreatorResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DiscoverCreatorResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_snippet", kind: "message", T: CreatorCardSnippet },
    { no: 2, name: "statuses", kind: "message", T: InfluencerCampaigns },
    { no: 3, name: "labels", kind: "message", T: FavoritesLabels },
    { no: 4, name: "network_info", kind: "message", T: GenerikInfoCard, repeated: true },
    { no: 5, name: "lefty_ingested", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "in_pool", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DiscoverCreatorResponse {
    return new DiscoverCreatorResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DiscoverCreatorResponse {
    return new DiscoverCreatorResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DiscoverCreatorResponse {
    return new DiscoverCreatorResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DiscoverCreatorResponse | PlainMessage<DiscoverCreatorResponse> | undefined, b: DiscoverCreatorResponse | PlainMessage<DiscoverCreatorResponse> | undefined): boolean {
    return proto3.util.equals(DiscoverCreatorResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.DiscoverRequest
 */
export class DiscoverRequest extends Message<DiscoverRequest> {
  /**
   * Required
   * Search for influencera with that network
   *
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: common.proto.Int32Range followers = 2;
   */
  followers?: Int32Range;

  /**
   * @generated from field: common.proto.DoubleRange engagement_rates = 3;
   */
  engagementRates?: DoubleRange;

  /**
   * @generated from field: common.proto.Int32Range age = 4;
   */
  age?: Int32Range;

  /**
   * Not supported by Instagram
   *
   * @generated from field: common.proto.Int32Range views = 17;
   */
  views?: Int32Range;

  /**
   * Using Language ISO639-1 code (FR, UK, US ...)
   * depending on the source, we may support passing only 1 language
   *
   * @generated from field: repeated string languages = 5;
   */
  languages: string[] = [];

  /**
   * @generated from field: common.proto.Gender gender = 6;
   */
  gender = Gender.GENDER_UNDEFINED;

  /**
   * TODO : delete once migration is done
   *
   * @generated from field: repeated string hashtags = 7;
   */
  hashtags: string[] = [];

  /**
   * @generated from field: repeated string mentions = 8;
   */
  mentions: string[] = [];

  /**
   * This covers the search of any keyword used in the captions.
   *
   * @generated from field: repeated string keywords = 9;
   */
  keywords: string[] = [];

  /**
   * @generated from field: librarian.proto.DiscoverRequest.TextTags text_tags = 30;
   */
  textTags?: DiscoverRequest_TextTags;

  /**
   * @generated from field: repeated string bio = 10;
   */
  bio: string[] = [];

  /**
   * defined here frontend/core/lib/assetsaudience_categories.json
   *
   * You can get a valid interest (brand category) ID from our dictionary. No more than 20 values.
   * If multiple values provided, then the user must be matched to at least one of them.
   *
   * @generated from field: repeated string categories = 11;
   */
  categories: string[] = [];

  /**
   * @generated from field: common.proto.SnapchatSubcategory snapchat_subcategory = 37;
   */
  snapchatSubcategory = SnapchatSubcategory.UNKNOWN;

  /**
   * Note : locations v1 to v3 are deprecated but we need to keep them because some searches using it are stored for
   * CW set builders ...
   *
   * @generated from field: repeated string deprecated_locations_v1 = 12;
   */
  deprecatedLocationsV1: string[] = [];

  /**
   * @generated from field: repeated librarian.proto.AuxiliaryLocation deprecated_locations_v2 = 20;
   */
  deprecatedLocationsV2: AuxiliaryLocation[] = [];

  /**
   * @generated from field: repeated universal.arkansas.proto.Geo deprecated_locations_v3 = 21;
   */
  deprecatedLocationsV3: Geo[] = [];

  /**
   * @generated from field: repeated universal.arkansas.proto.Geo auxiliary_locations = 22;
   */
  auxiliaryLocations: Geo[] = [];

  /**
   * @generated from field: bool has_email = 18;
   */
  hasEmail = false;

  /**
   * @generated from field: librarian.proto.DiscoverAudienceRequest audience = 13;
   */
  audience?: DiscoverAudienceRequest;

  /**
   * @generated from field: librarian.proto.Lookalike lookalike = 19;
   */
  lookalike?: Lookalike;

  /**
   * @generated from field: common.proto.TokenPagination pagination = 14;
   */
  pagination?: TokenPagination;

  /**
   * @generated from field: librarian.proto.UserSort sort = 15;
   */
  sort = UserSort.DEFAULT;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 16;
   */
  sortDirection = SortDirection.DESC;

  /**
   * @generated from field: librarian.proto.MonthlyGrowthRequest followers_growth = 31;
   */
  followersGrowth?: MonthlyGrowthRequest;

  /**
   * @generated from field: librarian.proto.MonthlyGrowthRequest total_views_growth = 32;
   */
  totalViewsGrowth?: MonthlyGrowthRequest;

  /**
   * @generated from field: librarian.proto.MonthlyGrowthRequest total_likes_growth = 33;
   */
  totalLikesGrowth?: MonthlyGrowthRequest;

  /**
   * @generated from field: common.proto.Int32Range reels_plays = 34;
   */
  reelsPlays?: Int32Range;

  /**
   * @generated from field: common.proto.Int32Range saves = 35;
   */
  saves?: Int32Range;

  /**
   * @generated from field: common.proto.Int32Range shares = 36;
   */
  shares?: Int32Range;

  /**
   * You can get a valid brand ID from our dictionary. No more than 20 values.
   * If multiple values provided, then the user must be matched to at least one of them.
   *
   * @generated from field: repeated int32 brands = 38;
   */
  brands: number[] = [];

  /**
   * Has sponsored posts with a least one of these brands
   *
   * @generated from field: repeated int32 ads_brands = 39;
   */
  adsBrands: number[] = [];

  /**
   * @generated from field: repeated universal.arkansas.proto.AccountType account_types = 40;
   */
  accountTypes: AccountType[] = [];

  /**
   * @generated from field: bool is_verified = 41;
   */
  isVerified = false;

  /**
   * Show Youtube channels with Official artist label
   *
   * @generated from field: bool is_official_artist = 42;
   */
  isOfficialArtist = false;

  /**
   * shows accounts only having sponsored posts
   *
   * @generated from field: bool has_ads = 43;
   */
  hasAds = false;

  /**
   * Max number of days since the last post, must be >= 30
   *
   * @generated from field: int32 last_posted = 44;
   */
  lastPosted = 0;

  constructor(data?: PartialMessage<DiscoverRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DiscoverRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "followers", kind: "message", T: Int32Range },
    { no: 3, name: "engagement_rates", kind: "message", T: DoubleRange },
    { no: 4, name: "age", kind: "message", T: Int32Range },
    { no: 17, name: "views", kind: "message", T: Int32Range },
    { no: 5, name: "languages", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 6, name: "gender", kind: "enum", T: proto3.getEnumType(Gender) },
    { no: 7, name: "hashtags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "mentions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "keywords", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 30, name: "text_tags", kind: "message", T: DiscoverRequest_TextTags },
    { no: 10, name: "bio", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 11, name: "categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 37, name: "snapchat_subcategory", kind: "enum", T: proto3.getEnumType(SnapchatSubcategory) },
    { no: 12, name: "deprecated_locations_v1", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 20, name: "deprecated_locations_v2", kind: "message", T: AuxiliaryLocation, repeated: true },
    { no: 21, name: "deprecated_locations_v3", kind: "message", T: Geo, repeated: true },
    { no: 22, name: "auxiliary_locations", kind: "message", T: Geo, repeated: true },
    { no: 18, name: "has_email", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "audience", kind: "message", T: DiscoverAudienceRequest },
    { no: 19, name: "lookalike", kind: "message", T: Lookalike },
    { no: 14, name: "pagination", kind: "message", T: TokenPagination },
    { no: 15, name: "sort", kind: "enum", T: proto3.getEnumType(UserSort) },
    { no: 16, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
    { no: 31, name: "followers_growth", kind: "message", T: MonthlyGrowthRequest },
    { no: 32, name: "total_views_growth", kind: "message", T: MonthlyGrowthRequest },
    { no: 33, name: "total_likes_growth", kind: "message", T: MonthlyGrowthRequest },
    { no: 34, name: "reels_plays", kind: "message", T: Int32Range },
    { no: 35, name: "saves", kind: "message", T: Int32Range },
    { no: 36, name: "shares", kind: "message", T: Int32Range },
    { no: 38, name: "brands", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 39, name: "ads_brands", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 40, name: "account_types", kind: "enum", T: proto3.getEnumType(AccountType), repeated: true },
    { no: 41, name: "is_verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 42, name: "is_official_artist", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 43, name: "has_ads", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 44, name: "last_posted", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DiscoverRequest {
    return new DiscoverRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DiscoverRequest {
    return new DiscoverRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DiscoverRequest {
    return new DiscoverRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DiscoverRequest | PlainMessage<DiscoverRequest> | undefined, b: DiscoverRequest | PlainMessage<DiscoverRequest> | undefined): boolean {
    return proto3.util.equals(DiscoverRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.DiscoverRequest.TextTags
 */
export class DiscoverRequest_TextTags extends Message<DiscoverRequest_TextTags> {
  /**
   * @generated from field: repeated string hashtags = 1;
   */
  hashtags: string[] = [];

  /**
   * @generated from field: repeated string mentions = 2;
   */
  mentions: string[] = [];

  /**
   * This covers the search of any keyword used in the captions.
   *
   * @generated from field: repeated string keywords = 3;
   */
  keywords: string[] = [];

  /**
   * @generated from field: common.proto.LogicOperator operator = 4;
   */
  operator = LogicOperator.UNKNOWN_OPERATOR;

  constructor(data?: PartialMessage<DiscoverRequest_TextTags>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DiscoverRequest.TextTags";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hashtags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "mentions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 3, name: "keywords", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "operator", kind: "enum", T: proto3.getEnumType(LogicOperator) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DiscoverRequest_TextTags {
    return new DiscoverRequest_TextTags().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DiscoverRequest_TextTags {
    return new DiscoverRequest_TextTags().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DiscoverRequest_TextTags {
    return new DiscoverRequest_TextTags().fromJsonString(jsonString, options);
  }

  static equals(a: DiscoverRequest_TextTags | PlainMessage<DiscoverRequest_TextTags> | undefined, b: DiscoverRequest_TextTags | PlainMessage<DiscoverRequest_TextTags> | undefined): boolean {
    return proto3.util.equals(DiscoverRequest_TextTags, a, b);
  }
}

/**
 * @generated from message librarian.proto.LookalikeRequest
 */
export class LookalikeRequest extends Message<LookalikeRequest> {
  /**
   * Required
   * Search for influencera with that network
   *
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: librarian.proto.Lookalike lookalike = 2;
   */
  lookalike?: Lookalike;

  /**
   * @generated from field: common.proto.Int32Range followers = 3;
   */
  followers?: Int32Range;

  /**
   * @generated from field: repeated universal.arkansas.proto.Geo auxiliary_locations = 4;
   */
  auxiliaryLocations: Geo[] = [];

  /**
   * Pagination items
   *
   * @generated from field: common.proto.TokenPagination pagination = 10;
   */
  pagination?: TokenPagination;

  /**
   * @generated from field: librarian.proto.UserSort sort = 11;
   */
  sort = UserSort.DEFAULT;

  /**
   * @generated from field: common.proto.SortDirection sort_direction = 12;
   */
  sortDirection = SortDirection.DESC;

  constructor(data?: PartialMessage<LookalikeRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LookalikeRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "lookalike", kind: "message", T: Lookalike },
    { no: 3, name: "followers", kind: "message", T: Int32Range },
    { no: 4, name: "auxiliary_locations", kind: "message", T: Geo, repeated: true },
    { no: 10, name: "pagination", kind: "message", T: TokenPagination },
    { no: 11, name: "sort", kind: "enum", T: proto3.getEnumType(UserSort) },
    { no: 12, name: "sort_direction", kind: "enum", T: proto3.getEnumType(SortDirection) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LookalikeRequest {
    return new LookalikeRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LookalikeRequest {
    return new LookalikeRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LookalikeRequest {
    return new LookalikeRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LookalikeRequest | PlainMessage<LookalikeRequest> | undefined, b: LookalikeRequest | PlainMessage<LookalikeRequest> | undefined): boolean {
    return proto3.util.equals(LookalikeRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.Lookalike
 */
export class Lookalike extends Message<Lookalike> {
  /**
   * @generated from field: string creator_master_id = 3;
   */
  creatorMasterId = "";

  /**
   * @generated from field: librarian.proto.Lookalike.LookalikeType type = 2;
   */
  type = Lookalike_LookalikeType.LOOKALIKE_UNKNOWN;

  constructor(data?: PartialMessage<Lookalike>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Lookalike";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "creator_master_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "enum", T: proto3.getEnumType(Lookalike_LookalikeType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Lookalike {
    return new Lookalike().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Lookalike {
    return new Lookalike().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Lookalike {
    return new Lookalike().fromJsonString(jsonString, options);
  }

  static equals(a: Lookalike | PlainMessage<Lookalike> | undefined, b: Lookalike | PlainMessage<Lookalike> | undefined): boolean {
    return proto3.util.equals(Lookalike, a, b);
  }
}

/**
 * @generated from enum librarian.proto.Lookalike.LookalikeType
 */
export enum Lookalike_LookalikeType {
  /**
   * @generated from enum value: LOOKALIKE_UNKNOWN = 0;
   */
  LOOKALIKE_UNKNOWN = 0,

  /**
   * @generated from enum value: LOOKALIKE_TOPICS = 1;
   */
  LOOKALIKE_TOPICS = 1,

  /**
   * @generated from enum value: LOOKALIKE_AUDIENCE = 2;
   */
  LOOKALIKE_AUDIENCE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(Lookalike_LookalikeType)
proto3.util.setEnumType(Lookalike_LookalikeType, "librarian.proto.Lookalike.LookalikeType", [
  { no: 0, name: "LOOKALIKE_UNKNOWN" },
  { no: 1, name: "LOOKALIKE_TOPICS" },
  { no: 2, name: "LOOKALIKE_AUDIENCE" },
]);

/**
 * @generated from message librarian.proto.AuxiliaryLocation
 */
export class AuxiliaryLocation extends Message<AuxiliaryLocation> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string query = 2;
   */
  query = "";

  constructor(data?: PartialMessage<AuxiliaryLocation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AuxiliaryLocation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuxiliaryLocation {
    return new AuxiliaryLocation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuxiliaryLocation {
    return new AuxiliaryLocation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuxiliaryLocation {
    return new AuxiliaryLocation().fromJsonString(jsonString, options);
  }

  static equals(a: AuxiliaryLocation | PlainMessage<AuxiliaryLocation> | undefined, b: AuxiliaryLocation | PlainMessage<AuxiliaryLocation> | undefined): boolean {
    return proto3.util.equals(AuxiliaryLocation, a, b);
  }
}

/**
 * @generated from message librarian.proto.MergedPagination
 */
export class MergedPagination extends Message<MergedPagination> {
  /**
   * @generated from field: int32 lefty_from = 1;
   */
  leftyFrom = 0;

  /**
   * @generated from field: int32 auxiliary_from = 2;
   */
  auxiliaryFrom = 0;

  /**
   * @generated from field: int32 lefty_total_hits = 3;
   */
  leftyTotalHits = 0;

  /**
   * @generated from field: int32 auxiliary_total_hits = 4;
   */
  auxiliaryTotalHits = 0;

  constructor(data?: PartialMessage<MergedPagination>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.MergedPagination";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lefty_from", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "auxiliary_from", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "lefty_total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "auxiliary_total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MergedPagination {
    return new MergedPagination().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MergedPagination {
    return new MergedPagination().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MergedPagination {
    return new MergedPagination().fromJsonString(jsonString, options);
  }

  static equals(a: MergedPagination | PlainMessage<MergedPagination> | undefined, b: MergedPagination | PlainMessage<MergedPagination> | undefined): boolean {
    return proto3.util.equals(MergedPagination, a, b);
  }
}

/**
 * @generated from message librarian.proto.DiscoverAudienceRequest
 */
export class DiscoverAudienceRequest extends Message<DiscoverAudienceRequest> {
  /**
   * @generated from field: common.proto.Int32RangeWeight age = 1;
   */
  age?: Int32RangeWeight;

  /**
   * @generated from field: common.proto.GenderWeight gender = 2;
   */
  gender?: GenderWeight;

  /**
   * @generated from field: repeated common.proto.StringWeight languages = 3;
   */
  languages: StringWeight[] = [];

  /**
   * @generated from field: repeated common.proto.StringWeight locations = 4;
   */
  locations: StringWeight[] = [];

  /**
   * What audience to use for filters - likers, followers, commenters or any matched to the filter.
   * By default filters by first audience report we have in this order - followers, likers, commenters.
   * Meaning that if influencer has followers data then filter by followers only.
   * If there are no followers data then by likers. By commenters if there are no followers and likers.
   *
   * @generated from field: universal.arkansas.proto.AudienceSource source = 5;
   */
  source = AudienceSource.SOURCE_ANY;

  /**
   * No more than 20 values. If multiple values provided, then the user must be matched to at least one of them.
   *
   * @generated from field: repeated common.proto.StringWeight brands = 6;
   */
  brands: StringWeight[] = [];

  /**
   * No more than 20 values. If multiple values provided, then the user must be matched to at least one of them.
   *
   * @generated from field: repeated common.proto.StringWeight brand_categories = 7;
   */
  brandCategories: StringWeight[] = [];

  /**
   * Minimum audience credibility for audience of the influencer
   *
   * @generated from field: float credibility = 8;
   */
  credibility = 0;

  constructor(data?: PartialMessage<DiscoverAudienceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DiscoverAudienceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "age", kind: "message", T: Int32RangeWeight },
    { no: 2, name: "gender", kind: "message", T: GenderWeight },
    { no: 3, name: "languages", kind: "message", T: StringWeight, repeated: true },
    { no: 4, name: "locations", kind: "message", T: StringWeight, repeated: true },
    { no: 5, name: "source", kind: "enum", T: proto3.getEnumType(AudienceSource) },
    { no: 6, name: "brands", kind: "message", T: StringWeight, repeated: true },
    { no: 7, name: "brand_categories", kind: "message", T: StringWeight, repeated: true },
    { no: 8, name: "credibility", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DiscoverAudienceRequest {
    return new DiscoverAudienceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DiscoverAudienceRequest {
    return new DiscoverAudienceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DiscoverAudienceRequest {
    return new DiscoverAudienceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DiscoverAudienceRequest | PlainMessage<DiscoverAudienceRequest> | undefined, b: DiscoverAudienceRequest | PlainMessage<DiscoverAudienceRequest> | undefined): boolean {
    return proto3.util.equals(DiscoverAudienceRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.DiscoverResponse
 */
export class DiscoverResponse extends Message<DiscoverResponse> {
  /**
   * @generated from field: int64 total_hits = 1;
   */
  totalHits = protoInt64.zero;

  /**
   * @generated from field: repeated librarian.proto.DiscoverCreatorResponse creators = 2;
   */
  creators: DiscoverCreatorResponse[] = [];

  /**
   * @generated from field: string next_page_token = 3;
   */
  nextPageToken = "";

  constructor(data?: PartialMessage<DiscoverResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DiscoverResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "total_hits", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "creators", kind: "message", T: DiscoverCreatorResponse, repeated: true },
    { no: 3, name: "next_page_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DiscoverResponse {
    return new DiscoverResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DiscoverResponse {
    return new DiscoverResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DiscoverResponse {
    return new DiscoverResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DiscoverResponse | PlainMessage<DiscoverResponse> | undefined, b: DiscoverResponse | PlainMessage<DiscoverResponse> | undefined): boolean {
    return proto3.util.equals(DiscoverResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.AuxiliaryBrands
 */
export class AuxiliaryBrands extends Message<AuxiliaryBrands> {
  /**
   * @generated from field: repeated librarian.proto.AuxiliaryBrands.Brand brands = 1;
   */
  brands: AuxiliaryBrands_Brand[] = [];

  constructor(data?: PartialMessage<AuxiliaryBrands>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AuxiliaryBrands";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brands", kind: "message", T: AuxiliaryBrands_Brand, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuxiliaryBrands {
    return new AuxiliaryBrands().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuxiliaryBrands {
    return new AuxiliaryBrands().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuxiliaryBrands {
    return new AuxiliaryBrands().fromJsonString(jsonString, options);
  }

  static equals(a: AuxiliaryBrands | PlainMessage<AuxiliaryBrands> | undefined, b: AuxiliaryBrands | PlainMessage<AuxiliaryBrands> | undefined): boolean {
    return proto3.util.equals(AuxiliaryBrands, a, b);
  }
}

/**
 * @generated from message librarian.proto.AuxiliaryBrands.Brand
 */
export class AuxiliaryBrands_Brand extends Message<AuxiliaryBrands_Brand> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<AuxiliaryBrands_Brand>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AuxiliaryBrands.Brand";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuxiliaryBrands_Brand {
    return new AuxiliaryBrands_Brand().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuxiliaryBrands_Brand {
    return new AuxiliaryBrands_Brand().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuxiliaryBrands_Brand {
    return new AuxiliaryBrands_Brand().fromJsonString(jsonString, options);
  }

  static equals(a: AuxiliaryBrands_Brand | PlainMessage<AuxiliaryBrands_Brand> | undefined, b: AuxiliaryBrands_Brand | PlainMessage<AuxiliaryBrands_Brand> | undefined): boolean {
    return proto3.util.equals(AuxiliaryBrands_Brand, a, b);
  }
}

/**
 * @generated from message librarian.proto.AuxiliaryCategories
 */
export class AuxiliaryCategories extends Message<AuxiliaryCategories> {
  /**
   * @generated from field: repeated librarian.proto.AuxiliaryCategories.Category categories = 1;
   */
  categories: AuxiliaryCategories_Category[] = [];

  constructor(data?: PartialMessage<AuxiliaryCategories>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AuxiliaryCategories";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "categories", kind: "message", T: AuxiliaryCategories_Category, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuxiliaryCategories {
    return new AuxiliaryCategories().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuxiliaryCategories {
    return new AuxiliaryCategories().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuxiliaryCategories {
    return new AuxiliaryCategories().fromJsonString(jsonString, options);
  }

  static equals(a: AuxiliaryCategories | PlainMessage<AuxiliaryCategories> | undefined, b: AuxiliaryCategories | PlainMessage<AuxiliaryCategories> | undefined): boolean {
    return proto3.util.equals(AuxiliaryCategories, a, b);
  }
}

/**
 * @generated from message librarian.proto.AuxiliaryCategories.Category
 */
export class AuxiliaryCategories_Category extends Message<AuxiliaryCategories_Category> {
  /**
   * @generated from field: int32 id = 1;
   */
  id = 0;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  constructor(data?: PartialMessage<AuxiliaryCategories_Category>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AuxiliaryCategories.Category";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuxiliaryCategories_Category {
    return new AuxiliaryCategories_Category().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuxiliaryCategories_Category {
    return new AuxiliaryCategories_Category().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuxiliaryCategories_Category {
    return new AuxiliaryCategories_Category().fromJsonString(jsonString, options);
  }

  static equals(a: AuxiliaryCategories_Category | PlainMessage<AuxiliaryCategories_Category> | undefined, b: AuxiliaryCategories_Category | PlainMessage<AuxiliaryCategories_Category> | undefined): boolean {
    return proto3.util.equals(AuxiliaryCategories_Category, a, b);
  }
}


import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';

import { isEmptyArray, isNotNil } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  createCampaignNetworkTrackingForm,
  networkTrackingFormRequiredValidator,
} from '../config/config.helpers';
import { CampaignNetworkTrackingForm } from '../config/config.models';
import { NetworkConfigs } from '../config/config.service';
import { LeftyControlValueAccessor } from '../form';
import { LeftyButtonDirective } from '../lefty-button-directive/lefty-button.directive';
import { LeftyCardComponent } from '../lefty-card/lefty-card.component';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';
import { NetworksTrackingFormV2Component } from '../tracking-keywords-input/network-tracking-input.component/network-tracking-input-v2.component';

@Component({
  selector: 'networks-tracking-form',
  templateUrl: './networks-tracking-form.html',
  styleUrls: ['./networks-tracking-form.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyFormComponent,
    LeftyCardComponent,
    NetworksTrackingFormV2Component,
    FormsModule,
    ReactiveFormsModule,
    LeftyButtonDirective,
  ],
})
export class NetworksTrackingFormComponent
  extends LeftyControlValueAccessor<CampaignNetworkTrackingForm[]>
  implements AfterViewInit
{
  constructor() {
    super();
    this.subscribeToFormModelChanges();
  }

  readonly networkConfig = inject(NetworkConfigs);

  readonly availableNetworks = input<Network[]>([]);
  readonly withLogicOperator = input(false);
  readonly withExcluded = input(false);
  readonly hideAddNetwork = input(false);

  readonly fb = inject(FormBuilder);

  readonly formModel = new FormArray<
    FormControl<CampaignNetworkTrackingForm | null>
  >([]);

  subscribeToFormModelChanges(): void {
    this.formModel.valueChanges
      .pipe(takeUntilDestroyed())
      .subscribe((value) => {
        const newValue = isNotNil(value) ? value.filter(isNotNil) : [];
        this.setValueAndNotify(newValue);
      });
  }

  readonly networks = computed(() => this.value()?.map((e) => e.network) ?? []);

  readonly unusedNetworks = computed(() =>
    this.availableNetworks().filter(
      (network) =>
        !this.networks().includes(network) &&
        isNotNil(this.networkConfig.of(network)),
    ),
  );

  remove(i: number): void {
    this.formModel.removeAt(i);
  }

  addNetwork(): void {
    if (isEmptyArray(this.unusedNetworks())) {
      return;
    }

    const network = this.unusedNetworks()[0];
    this.formModel.push(
      new FormControl(createCampaignNetworkTrackingForm({ network }), [
        networkTrackingFormRequiredValidator,
      ]),
    );
  }

  override isValidType(obj: unknown): obj is CampaignNetworkTrackingForm[] {
    return (
      Array.isArray(obj) &&
      obj.every((o) => o.type === 'CampaignNetworkTrackingForm')
    );
  }

  ngAfterViewInit(): void {
    this.addNetwork();
  }
}

// This file defines core data structures for a creator.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/creator.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Int32Value, Int64Value, Message, proto3, StringValue, Timestamp } from "@bufbuild/protobuf";
import { InfluencerKind, Mention, Network } from "./common_pb";
import { PostCategories } from "../../galois/proto/topico_pb";
import { User } from "./user_pb";
import { YoutubeCreator } from "./youtube_media_pb";
import { TwitterCreator } from "./twitter_media_pb";
import { TikTokCreator } from "./tiktok_pb";
import { FacebookCreator } from "./facebook_pb";
import { WeiboCreator } from "./weibo_pb";
import { WeChatCreator } from "./wechat_pb";
import { RedCreator } from "./red_pb";
import { DouyinCreator } from "./douyin_pb";
import { SnapchatCreator } from "./snapchat_pb";

/**
 * @generated from enum common.proto.CreatorGeographySource
 */
export enum CreatorGeographySource {
  /**
   * @generated from enum value: GEOGRAPHY_SOURCE_UNKNOWN = 0;
   */
  GEOGRAPHY_SOURCE_UNKNOWN = 0,

  /**
   * we queried Google knowledge graph for the user name and "stole" the geo from there.
   *
   * @generated from enum value: KNOWLEDGE_GRAPH = 1;
   */
  KNOWLEDGE_GRAPH = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(CreatorGeographySource)
proto3.util.setEnumType(CreatorGeographySource, "common.proto.CreatorGeographySource", [
  { no: 0, name: "GEOGRAPHY_SOURCE_UNKNOWN" },
  { no: 1, name: "KNOWLEDGE_GRAPH" },
]);

/**
 * @generated from enum common.proto.InfluencerKindSource
 */
export enum InfluencerKindSource {
  /**
   * Computed by the first version of the galois in-house algo.
   *
   * @generated from enum value: GALOIS_COMPUTED = 0;
   */
  GALOIS_COMPUTED = 0,

  /**
   * The kind was manually determined by mechanical turk;
   *
   * @generated from enum value: MANUAL = 1;
   */
  MANUAL = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(InfluencerKindSource)
proto3.util.setEnumType(InfluencerKindSource, "common.proto.InfluencerKindSource", [
  { no: 0, name: "GALOIS_COMPUTED" },
  { no: 1, name: "MANUAL" },
]);

/**
 * @generated from message common.proto.MetaIdPb
 */
export class MetaIdPb extends Message<MetaIdPb> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: string raw_part = 2;
   */
  rawPart = "";

  constructor(data?: PartialMessage<MetaIdPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.MetaIdPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "raw_part", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MetaIdPb {
    return new MetaIdPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MetaIdPb {
    return new MetaIdPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MetaIdPb {
    return new MetaIdPb().fromJsonString(jsonString, options);
  }

  static equals(a: MetaIdPb | PlainMessage<MetaIdPb> | undefined, b: MetaIdPb | PlainMessage<MetaIdPb> | undefined): boolean {
    return proto3.util.equals(MetaIdPb, a, b);
  }
}

/**
 * @generated from message common.proto.NetworkPrivateInfo
 */
export class NetworkPrivateInfo extends Message<NetworkPrivateInfo> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: bool is_private = 2;
   */
  isPrivate = false;

  /**
   * deleted is stronger than private because they are fully deleted from the network, eg no need for polling
   * from time to time to see if they switched back to public.
   *
   * There might be networks on which we are not able to make the distinction.
   *
   * state as of now:
   *
   *    - INSTA: private VS deleted is fully supported on instagram. we are able to get some info on private profiles.
   *    - YOUTUBE: NOT_FOUND maps to deletion, TODO(christian) Find a private channel to test private.
   *    - TWITTER: Profiles that don't exist map to deleted correctly. Not sure there is such a thing as private twittos (you
   *               can only hide your timeline).
   *    - TIK_TOK: private and deleted fully supported. We can get most info on private (even followers count), just not
   *               the timeline.
   *
   *    - SINO networks: TODO(christian) no investigation done so far.
   *
   * @generated from field: bool is_deleted = 3;
   */
  isDeleted = false;

  /**
   * Note(christian) This field is confusingly named and represents the last time we've seen it either private or deleted.
   *
   * @generated from field: google.protobuf.Timestamp last_seen_private = 4;
   */
  lastSeenPrivate?: Timestamp;

  constructor(data?: PartialMessage<NetworkPrivateInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.NetworkPrivateInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "is_private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "is_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "last_seen_private", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkPrivateInfo {
    return new NetworkPrivateInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkPrivateInfo {
    return new NetworkPrivateInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkPrivateInfo {
    return new NetworkPrivateInfo().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkPrivateInfo | PlainMessage<NetworkPrivateInfo> | undefined, b: NetworkPrivateInfo | PlainMessage<NetworkPrivateInfo> | undefined): boolean {
    return proto3.util.equals(NetworkPrivateInfo, a, b);
  }
}

/**
 * @generated from message common.proto.GenerikPrivateInfo
 */
export class GenerikPrivateInfo extends Message<GenerikPrivateInfo> {
  /**
   * @generated from field: repeated common.proto.NetworkPrivateInfo networks = 1;
   */
  networks: NetworkPrivateInfo[] = [];

  constructor(data?: PartialMessage<GenerikPrivateInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.GenerikPrivateInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "networks", kind: "message", T: NetworkPrivateInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikPrivateInfo {
    return new GenerikPrivateInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikPrivateInfo {
    return new GenerikPrivateInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikPrivateInfo {
    return new GenerikPrivateInfo().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikPrivateInfo | PlainMessage<GenerikPrivateInfo> | undefined, b: GenerikPrivateInfo | PlainMessage<GenerikPrivateInfo> | undefined): boolean {
    return proto3.util.equals(GenerikPrivateInfo, a, b);
  }
}

/**
 * @generated from message common.proto.NetworkPollingTimes
 */
export class NetworkPollingTimes extends Message<NetworkPollingTimes> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: google.protobuf.Timestamp last_stats_visit = 2;
   */
  lastStatsVisit?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp last_posts_visit = 3;
   */
  lastPostsVisit?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp oldest_media_retrieved = 4;
   */
  oldestMediaRetrieved?: Timestamp;

  constructor(data?: PartialMessage<NetworkPollingTimes>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.NetworkPollingTimes";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "last_stats_visit", kind: "message", T: Timestamp },
    { no: 3, name: "last_posts_visit", kind: "message", T: Timestamp },
    { no: 4, name: "oldest_media_retrieved", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkPollingTimes {
    return new NetworkPollingTimes().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkPollingTimes {
    return new NetworkPollingTimes().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkPollingTimes {
    return new NetworkPollingTimes().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkPollingTimes | PlainMessage<NetworkPollingTimes> | undefined, b: NetworkPollingTimes | PlainMessage<NetworkPollingTimes> | undefined): boolean {
    return proto3.util.equals(NetworkPollingTimes, a, b);
  }
}

/**
 * @generated from message common.proto.GenerikPollingTimes
 */
export class GenerikPollingTimes extends Message<GenerikPollingTimes> {
  /**
   * @generated from field: repeated common.proto.NetworkPollingTimes networks = 1;
   */
  networks: NetworkPollingTimes[] = [];

  constructor(data?: PartialMessage<GenerikPollingTimes>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.GenerikPollingTimes";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "networks", kind: "message", T: NetworkPollingTimes, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikPollingTimes {
    return new GenerikPollingTimes().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikPollingTimes {
    return new GenerikPollingTimes().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikPollingTimes {
    return new GenerikPollingTimes().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikPollingTimes | PlainMessage<GenerikPollingTimes> | undefined, b: GenerikPollingTimes | PlainMessage<GenerikPollingTimes> | undefined): boolean {
    return proto3.util.equals(GenerikPollingTimes, a, b);
  }
}

/**
 * @generated from message common.proto.CreatorGeography
 */
export class CreatorGeography extends Message<CreatorGeography> {
  /**
   * @generated from field: common.proto.CreatorGeographySource source = 1;
   */
  source = CreatorGeographySource.GEOGRAPHY_SOURCE_UNKNOWN;

  /**
   * @generated from field: google.protobuf.StringValue country_code = 2;
   */
  countryCode?: string;

  /**
   * @generated from field: google.protobuf.Timestamp compute_time = 3;
   */
  computeTime?: Timestamp;

  /**
   * version of the algorithm that computed it.
   *
   * @generated from field: int32 version = 4;
   */
  version = 0;

  constructor(data?: PartialMessage<CreatorGeography>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CreatorGeography";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "source", kind: "enum", T: proto3.getEnumType(CreatorGeographySource) },
    { no: 2, name: "country_code", kind: "message", T: StringValue },
    { no: 3, name: "compute_time", kind: "message", T: Timestamp },
    { no: 4, name: "version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorGeography {
    return new CreatorGeography().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorGeography {
    return new CreatorGeography().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorGeography {
    return new CreatorGeography().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorGeography | PlainMessage<CreatorGeography> | undefined, b: CreatorGeography | PlainMessage<CreatorGeography> | undefined): boolean {
    return proto3.util.equals(CreatorGeography, a, b);
  }
}

/**
 * @generated from message common.proto.CreatorScoredCategory
 */
export class CreatorScoredCategory extends Message<CreatorScoredCategory> {
  /**
   * Note(christian) see the comment on PostCategories: it is named post for historical reasons but it is really
   * also a creator categories once aggregated over many posts.
   *
   * Note(christian) not super elegant to have common inherit from galois. But for now, we are afraid to move around
   * python protos. Someday one could clean this up, eg move PostCategories into common (perhaps rename it too).
   *
   * @generated from field: galois.proto.PostCategories category = 1;
   */
  category = PostCategories.UNKNOWN;

  /**
   * @generated from field: float score = 2;
   */
  score = 0;

  constructor(data?: PartialMessage<CreatorScoredCategory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CreatorScoredCategory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category", kind: "enum", T: proto3.getEnumType(PostCategories) },
    { no: 2, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorScoredCategory {
    return new CreatorScoredCategory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorScoredCategory {
    return new CreatorScoredCategory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorScoredCategory {
    return new CreatorScoredCategory().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorScoredCategory | PlainMessage<CreatorScoredCategory> | undefined, b: CreatorScoredCategory | PlainMessage<CreatorScoredCategory> | undefined): boolean {
    return proto3.util.equals(CreatorScoredCategory, a, b);
  }
}

/**
 * @generated from message common.proto.CreatorCategories
 */
export class CreatorCategories extends Message<CreatorCategories> {
  /**
   * @generated from field: repeated common.proto.CreatorScoredCategory categories = 1;
   */
  categories: CreatorScoredCategory[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp compute_time = 2;
   */
  computeTime?: Timestamp;

  /**
   * version of the algorithm that computed it.
   *
   * @generated from field: int32 topico_version = 3;
   */
  topicoVersion = 0;

  constructor(data?: PartialMessage<CreatorCategories>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CreatorCategories";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "categories", kind: "message", T: CreatorScoredCategory, repeated: true },
    { no: 2, name: "compute_time", kind: "message", T: Timestamp },
    { no: 3, name: "topico_version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorCategories {
    return new CreatorCategories().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorCategories {
    return new CreatorCategories().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorCategories {
    return new CreatorCategories().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorCategories | PlainMessage<CreatorCategories> | undefined, b: CreatorCategories | PlainMessage<CreatorCategories> | undefined): boolean {
    return proto3.util.equals(CreatorCategories, a, b);
  }
}

/**
 * @generated from message common.proto.TopMentions
 */
export class TopMentions extends Message<TopMentions> {
  /**
   * @generated from field: repeated common.proto.TopMentions.MentionCount mentions = 1;
   */
  mentions: TopMentions_MentionCount[] = [];

  constructor(data?: PartialMessage<TopMentions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TopMentions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mentions", kind: "message", T: TopMentions_MentionCount, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopMentions {
    return new TopMentions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopMentions {
    return new TopMentions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopMentions {
    return new TopMentions().fromJsonString(jsonString, options);
  }

  static equals(a: TopMentions | PlainMessage<TopMentions> | undefined, b: TopMentions | PlainMessage<TopMentions> | undefined): boolean {
    return proto3.util.equals(TopMentions, a, b);
  }
}

/**
 * @generated from message common.proto.TopMentions.MentionCount
 */
export class TopMentions_MentionCount extends Message<TopMentions_MentionCount> {
  /**
   * @generated from field: common.proto.Mention mention = 1;
   */
  mention?: Mention;

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0;

  constructor(data?: PartialMessage<TopMentions_MentionCount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TopMentions.MentionCount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mention", kind: "message", T: Mention },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopMentions_MentionCount {
    return new TopMentions_MentionCount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopMentions_MentionCount {
    return new TopMentions_MentionCount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopMentions_MentionCount {
    return new TopMentions_MentionCount().fromJsonString(jsonString, options);
  }

  static equals(a: TopMentions_MentionCount | PlainMessage<TopMentions_MentionCount> | undefined, b: TopMentions_MentionCount | PlainMessage<TopMentions_MentionCount> | undefined): boolean {
    return proto3.util.equals(TopMentions_MentionCount, a, b);
  }
}

/**
 * @generated from message common.proto.MetaFacts
 */
export class MetaFacts extends Message<MetaFacts> {
  /**
   * @generated from field: common.proto.CreatorGeography geography = 1;
   */
  geography?: CreatorGeography;

  /**
   * @generated from field: common.proto.GenerikPrivateInfo private_info = 2;
   */
  privateInfo?: GenerikPrivateInfo;

  /**
   * @generated from field: common.proto.GenerikPollingTimes polling_times = 3;
   */
  pollingTimes?: GenerikPollingTimes;

  /**
   * @generated from field: common.proto.CreatorCategories categories = 4;
   */
  categories?: CreatorCategories;

  /**
   * There is a single object because mentions are mixed together for all networks.
   * The field MentionCount.mention.user_id is just a MetaId string (prefixed).
   *
   * @generated from field: common.proto.TopMentions top_mentions = 5;
   */
  topMentions?: TopMentions;

  /**
   * @generated from field: common.proto.ComputedCreatorKind creator_kind = 6;
   */
  creatorKind?: ComputedCreatorKind;

  constructor(data?: PartialMessage<MetaFacts>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.MetaFacts";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "geography", kind: "message", T: CreatorGeography },
    { no: 2, name: "private_info", kind: "message", T: GenerikPrivateInfo },
    { no: 3, name: "polling_times", kind: "message", T: GenerikPollingTimes },
    { no: 4, name: "categories", kind: "message", T: CreatorCategories },
    { no: 5, name: "top_mentions", kind: "message", T: TopMentions },
    { no: 6, name: "creator_kind", kind: "message", T: ComputedCreatorKind },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MetaFacts {
    return new MetaFacts().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MetaFacts {
    return new MetaFacts().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MetaFacts {
    return new MetaFacts().fromJsonString(jsonString, options);
  }

  static equals(a: MetaFacts | PlainMessage<MetaFacts> | undefined, b: MetaFacts | PlainMessage<MetaFacts> | undefined): boolean {
    return proto3.util.equals(MetaFacts, a, b);
  }
}

/**
 * @generated from message common.proto.ComputedCreatorKind
 */
export class ComputedCreatorKind extends Message<ComputedCreatorKind> {
  /**
   * @generated from field: common.proto.InfluencerKind creator_kind = 1;
   */
  creatorKind = InfluencerKind.UNKNOWN;

  /**
   * version of the algorithm used to compute it
   * first version released in october 2022
   *
   * We need both version and source to assess a creator kind, for instance even when the algo version increases, we
   * probably wouldn't want to erase a manual assessment.
   *
   * Despite algo being in the name, the version may also be increased if we even want to update the manual mappings, for
   * instance if there is a new kind, or a new set of instructions for assigning them.
   *
   * @generated from field: int32 computation_algo_version = 2;
   */
  computationAlgoVersion = 0;

  /**
   * @generated from field: common.proto.InfluencerKindSource source = 3;
   */
  source = InfluencerKindSource.GALOIS_COMPUTED;

  /**
   * @generated from field: google.protobuf.Timestamp computed = 4;
   */
  computed?: Timestamp;

  /**
   * at version 1+ we are asking a GPT classifier to split a kind between 17 or so options and then we map it to one
   * of the InfluencerKind. It is persisted here for the record if we ever need it.
   *
   * @generated from field: string gpt_raw_kind = 5;
   */
  gptRawKind = "";

  constructor(data?: PartialMessage<ComputedCreatorKind>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ComputedCreatorKind";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_kind", kind: "enum", T: proto3.getEnumType(InfluencerKind) },
    { no: 2, name: "computation_algo_version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "source", kind: "enum", T: proto3.getEnumType(InfluencerKindSource) },
    { no: 4, name: "computed", kind: "message", T: Timestamp },
    { no: 5, name: "gpt_raw_kind", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComputedCreatorKind {
    return new ComputedCreatorKind().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComputedCreatorKind {
    return new ComputedCreatorKind().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComputedCreatorKind {
    return new ComputedCreatorKind().fromJsonString(jsonString, options);
  }

  static equals(a: ComputedCreatorKind | PlainMessage<ComputedCreatorKind> | undefined, b: ComputedCreatorKind | PlainMessage<ComputedCreatorKind> | undefined): boolean {
    return proto3.util.equals(ComputedCreatorKind, a, b);
  }
}

/**
 * creator unites all our social networks.
 *
 * Not all fields must be set: for instance you could have an instagramer with a youtuber,
 * a twitter alone, a youtuber with a twitter etc...
 *
 * The rules is that when multiple networks are present, the id will always be that of the stronger network.
 *
 * Merge: what gets interesting is when we merge profiles. The lesser network will always get merged in the stronger one.
 * Then the one that gets abandoned will remain, and all its creator profiles must be cleared and there must only remain
 * the ghost info. The ghost info points into the new id.
 *
 * @generated from message common.proto.CreatorModel
 */
export class CreatorModel extends Message<CreatorModel> {
  /**
   * id is prefixed:
   *   - Insta: ig:
   *   - Youtube: yt:
   *   - Twitter: tw:
   *   - Tiktok: tk:
   *   - Facebook: fb:
   *   - Weibo: wb:
   *   - WeChat: wc:
   *   - Red: rd:
   *   - Snapchat: sc:
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: common.proto.User insta = 2;
   */
  insta?: User;

  /**
   * @generated from field: common.proto.YoutubeCreator youtube = 3;
   */
  youtube?: YoutubeCreator;

  /**
   * @generated from field: common.proto.TwitterCreator twitter = 4;
   */
  twitter?: TwitterCreator;

  /**
   * @generated from field: common.proto.TikTokCreator tiktok = 5;
   */
  tiktok?: TikTokCreator;

  /**
   * @generated from field: common.proto.FacebookCreator facebook = 6;
   */
  facebook?: FacebookCreator;

  /**
   * @generated from field: common.proto.WeiboCreator weibo = 7;
   */
  weibo?: WeiboCreator;

  /**
   * @generated from field: common.proto.WeChatCreator wechat = 8;
   */
  wechat?: WeChatCreator;

  /**
   * @generated from field: common.proto.RedCreator red = 9;
   */
  red?: RedCreator;

  /**
   * @generated from field: common.proto.DouyinCreator douyin = 10;
   */
  douyin?: DouyinCreator;

  /**
   * @generated from field: common.proto.SnapchatCreator snapchat = 11;
   */
  snapchat?: SnapchatCreator;

  /**
   * This must point into a valid creator.id, that now has the creators set.
   *
   * @generated from field: common.proto.CreatorModel.GhostInfo ghost = 100;
   */
  ghost?: CreatorModel_GhostInfo;

  /**
   * @generated from field: common.proto.MetaFacts meta = 101;
   */
  meta?: MetaFacts;

  /**
   * This is a fingerprint of state of 'meta' field when we last computed intelligence.
   * Since that meta field contains all last stats visits and posts visits, if nothing changed we don't need to
   * recompute, it would yield the same result.
   *
   * @generated from field: string facts_fingerprint = 102;
   */
  factsFingerprint = "";

  constructor(data?: PartialMessage<CreatorModel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CreatorModel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "insta", kind: "message", T: User },
    { no: 3, name: "youtube", kind: "message", T: YoutubeCreator },
    { no: 4, name: "twitter", kind: "message", T: TwitterCreator },
    { no: 5, name: "tiktok", kind: "message", T: TikTokCreator },
    { no: 6, name: "facebook", kind: "message", T: FacebookCreator },
    { no: 7, name: "weibo", kind: "message", T: WeiboCreator },
    { no: 8, name: "wechat", kind: "message", T: WeChatCreator },
    { no: 9, name: "red", kind: "message", T: RedCreator },
    { no: 10, name: "douyin", kind: "message", T: DouyinCreator },
    { no: 11, name: "snapchat", kind: "message", T: SnapchatCreator },
    { no: 100, name: "ghost", kind: "message", T: CreatorModel_GhostInfo },
    { no: 101, name: "meta", kind: "message", T: MetaFacts },
    { no: 102, name: "facts_fingerprint", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorModel {
    return new CreatorModel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorModel {
    return new CreatorModel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorModel {
    return new CreatorModel().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorModel | PlainMessage<CreatorModel> | undefined, b: CreatorModel | PlainMessage<CreatorModel> | undefined): boolean {
    return proto3.util.equals(CreatorModel, a, b);
  }
}

/**
 * If a creator is merged into a stronger one, this will become a ghost.
 *
 * @generated from message common.proto.CreatorModel.GhostInfo
 */
export class CreatorModel_GhostInfo extends Message<CreatorModel_GhostInfo> {
  /**
   * @generated from field: google.protobuf.Timestamp ghosted_time = 1;
   */
  ghostedTime?: Timestamp;

  /**
   * @generated from field: string ghost_pointer = 2;
   */
  ghostPointer = "";

  constructor(data?: PartialMessage<CreatorModel_GhostInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CreatorModel.GhostInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ghosted_time", kind: "message", T: Timestamp },
    { no: 2, name: "ghost_pointer", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorModel_GhostInfo {
    return new CreatorModel_GhostInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorModel_GhostInfo {
    return new CreatorModel_GhostInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorModel_GhostInfo {
    return new CreatorModel_GhostInfo().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorModel_GhostInfo | PlainMessage<CreatorModel_GhostInfo> | undefined, b: CreatorModel_GhostInfo | PlainMessage<CreatorModel_GhostInfo> | undefined): boolean {
    return proto3.util.equals(CreatorModel_GhostInfo, a, b);
  }
}

/**
 * reverse index from creator name to creator id.
 *
 * @generated from message common.proto.CreatorNameIndex
 */
export class CreatorNameIndex extends Message<CreatorNameIndex> {
  /**
   * This user name is prefixed, eg: yt:gims, ig:cristiano, etc...
   *
   * It is the main key in the cassandra table.
   *
   * @generated from field: string user_name = 1;
   */
  userName = "";

  /**
   * id is always prefixed (toIdString()).
   *
   * We will set the master id (strongest network), but we won't bother to update it during a merge. This won't matter
   * as the creator will be loaded anyways (and associations would be then resolved).
   *
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * Last time we saw this name -> id association. If loading all matches by username there might not be unicity
   * (many users had the name and changed) so the most recent association shall win.
   *
   * @generated from field: google.protobuf.Timestamp last_update_time = 3;
   */
  lastUpdateTime?: Timestamp;

  constructor(data?: PartialMessage<CreatorNameIndex>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CreatorNameIndex";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "last_update_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorNameIndex {
    return new CreatorNameIndex().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorNameIndex {
    return new CreatorNameIndex().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorNameIndex {
    return new CreatorNameIndex().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorNameIndex | PlainMessage<CreatorNameIndex> | undefined, b: CreatorNameIndex | PlainMessage<CreatorNameIndex> | undefined): boolean {
    return proto3.util.equals(CreatorNameIndex, a, b);
  }
}

/**
 * Points that are inserted for each creator, and can later be used for:
 *   - time series of followers progress.
 *   - retrieve holes in polling.
 *
 * a points will be typically of type counts, in which case followers_count, follows_count and posts_count
 * will be set.
 *
 * Else it is a point of type image polling in which case the 3 other timestamps could be set.
 *
 * We could have defined 2 tables to be more clean, but we don't want an explosion of cassandra tables, so they will be
 * intermixed.
 *
 * We can add new types of points in the future too (for instance if we wanna track stats over time, etc...)
 *
 * @generated from message common.proto.CreatorHistoricPoint
 */
export class CreatorHistoricPoint extends Message<CreatorHistoricPoint> {
  /**
   * always the network id.
   *
   * @generated from field: string creator_id = 1;
   */
  creatorId = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 2;
   */
  created?: Timestamp;

  /**
   * we don't thing that anyone will ever have 2B+ followers, but it's a long in most network creators
   * so avoid all the ugly casts.
   *
   * @generated from field: google.protobuf.Int64Value followers_count = 3;
   */
  followersCount?: bigint;

  /**
   * @generated from field: google.protobuf.Int64Value follows_count = 4;
   */
  followsCount?: bigint;

  /**
   * @generated from field: google.protobuf.Int32Value posts_count = 5;
   */
  postsCount?: number;

  /**
   * @generated from field: google.protobuf.Timestamp oldest_media_last_polling = 6;
   */
  oldestMediaLastPolling?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp media_last_visit_time = 7;
   */
  mediaLastVisitTime?: Timestamp;

  /**
   * we might have reached oldest media because we had a time limit, or a max media limit. In which case there could
   * still be some media past this point.
   *
   * However if this bool is true, we know for a fact that we had reached the end of the feed.
   *
   * This will allow tools such as CW to not repoll someone's last N years, even though their oldest
   * 'oldest_media_last_polling' was not beyond that date.
   *
   * @generated from field: bool oldest_media_was_last_one = 8;
   */
  oldestMediaWasLastOne = false;

  constructor(data?: PartialMessage<CreatorHistoricPoint>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CreatorHistoricPoint";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created", kind: "message", T: Timestamp },
    { no: 3, name: "followers_count", kind: "message", T: Int64Value },
    { no: 4, name: "follows_count", kind: "message", T: Int64Value },
    { no: 5, name: "posts_count", kind: "message", T: Int32Value },
    { no: 6, name: "oldest_media_last_polling", kind: "message", T: Timestamp },
    { no: 7, name: "media_last_visit_time", kind: "message", T: Timestamp },
    { no: 8, name: "oldest_media_was_last_one", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorHistoricPoint {
    return new CreatorHistoricPoint().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorHistoricPoint {
    return new CreatorHistoricPoint().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorHistoricPoint {
    return new CreatorHistoricPoint().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorHistoricPoint | PlainMessage<CreatorHistoricPoint> | undefined, b: CreatorHistoricPoint | PlainMessage<CreatorHistoricPoint> | undefined): boolean {
    return proto3.util.equals(CreatorHistoricPoint, a, b);
  }
}


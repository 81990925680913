import { Timestamp } from '@bufbuild/protobuf';
import {
  addDuration,
  escapeRegExp,
  isEmptyArray,
  isEmptyString,
  isNil,
  isNotNil,
  plural,
} from '@frontend2/core';
import {
  AuthorPermission,
  LogicOperator,
  Network,
  NetworkEngagementRangeV2,
  NetworkFollowersRange,
  StringList,
} from '@frontend2/proto/common/proto/common_pb';
import {
  CampaignFieldType,
  CommonFilter,
  CreatorFieldType,
  DecimalNumberFilter,
  DynamicFilter,
  DynamicFilterGroup,
  EntityField,
  ListFilter,
  NativeFieldFilter,
  NumberFilter,
  TextFilter,
  TimeRestriction,
  TimestampFilter,
} from '@frontend2/proto/librarian/proto/common_pb';
import { EntityFieldValue } from '@frontend2/proto/librarian/proto/entity_fields_pb';
import { GoogleLocation } from '@frontend2/proto/librarian/proto/local_pb';
import {
  getCustomFieldIcon,
  getCustomFieldLabel,
  isAddressCustomField,
  isBooleanCustomField,
  isDateCustomField,
  isDecimalNumberCustomField,
  isListCustomField,
  isLocationCustomField,
  isNumberCustomField,
  isTextCustomField,
} from '../custom-fields/custom-fields.helpers';
import { readableNetwork } from '../followers-range-dropdown/followers-range-dropdown.helpers';
import { DynamicFilterConfig } from './dynamic-filters-config.bloc';
import { formattedBoolValue } from './filters/boolean/boolean.helpers';
import { formattedTrackedStatusValue } from './filters/boolean/tracked-status/tracked-status.helpers';
import { formattedTimestampFilterValue } from './filters/date/date.helpers';
import { formattedInstaInsightValue } from './filters/insta-insight/insta-insight.helpers';
import {
  postTypeFilterFormattedValue,
  readableListOptions,
} from './filters/list/list.helpers';
import {
  formattedDecimalNumberValue,
  formattedNumberValue,
} from './filters/number/number.helpers';

interface FrontendListFilterConfig {
  readonly case: 'listFilter';
  readonly listValues: string[];
  readonly multiSelection: boolean;
  readonly filterBuilder: () => ListFilter;
  readonly category: DynamicFilterCategory;
}

export interface FrontendNativeFilter {
  // unique ID to identify the filter
  readonly id: string;

  // readable label whe show to the user
  readonly label: string;

  // TODO(hadrien): support more type of filter, we only support listFitler at the moment
  readonly config: FrontendListFilterConfig;
}

export type NativeFilterOption = FrontendNativeFilter | NativeFieldFilter;

export function isFrontendNativeFilter(
  nativeFilter: NativeFilterOption,
): nativeFilter is FrontendNativeFilter {
  return typeof nativeFilter !== 'number';
}

export const TEXT_NATIVE_FILTERS: NativeFieldFilter[] = [
  NativeFieldFilter.INFLUENCER_KEYWORDS_FILTER,
  NativeFieldFilter.INFLUENCER_MENTIONS_FILTER,
  NativeFieldFilter.INFLUENCER_HASHTAGS_FILTER,
  NativeFieldFilter.VISUAL_KEYWORDS_FILTER,
  NativeFieldFilter.VISUAL_MENTIONS_FILTER,
  NativeFieldFilter.VISUAL_HASHTAGS_FILTER,
];

export const LIST_NATIVE_FILTERS: NativeFieldFilter[] = [
  NativeFieldFilter.CAMPAIGNS_FILTER,
  NativeFieldFilter.LABELS_FILTER,
  NativeFieldFilter.CAMPAIGNS_LABELS_FILTER,
  NativeFieldFilter.VISUALS_LABELS_FILTER,
  NativeFieldFilter.VISUAL_NETWORKS_FILTER,
  NativeFieldFilter.INFLUENCER_NETWORKS_FILTER,
  NativeFieldFilter.CREATORS_FILTER,
  NativeFieldFilter.INFLUENCER_STATUS_FILTER,
  NativeFieldFilter.INFLUENCER_STATE_FILTER,
  NativeFieldFilter.INFLUENCER_RFM_SCORE_FILTER,
  NativeFieldFilter.POST_TYPE_FILTER,
];

export const NATIVE_FILTERS_WITH_NETWORK: NativeFieldFilter[] = [
  NativeFieldFilter.ENGAGEMENT_FILTER,
  NativeFieldFilter.FOLLOWERS_FILTER,
];

export const POST_CATEGORY_NATIVE_FILTERS: NativeFieldFilter[] = [
  NativeFieldFilter.DATE_FILTER,
  NativeFieldFilter.VISUAL_HASHTAGS_FILTER,
  NativeFieldFilter.VISUAL_KEYWORDS_FILTER,
  NativeFieldFilter.VISUAL_MENTIONS_FILTER,
  NativeFieldFilter.VISUALS_LABELS_FILTER,
  NativeFieldFilter.POST_TYPE_FILTER,
  //TODO: add other native filters if exist
];

export const CREATOR_CATEGORY_NATIVE_FILTERS: NativeFieldFilter[] = [
  NativeFieldFilter.CREATORS_FILTER,
  NativeFieldFilter.FOLLOWERS_FILTER,
  NativeFieldFilter.INFLUENCER_STATUS_FILTER,
  NativeFieldFilter.INFLUENCER_STATE_FILTER,
  NativeFieldFilter.LABELS_FILTER,
  NativeFieldFilter.INFLUENCER_NETWORKS_FILTER,
  NativeFieldFilter.CAMPAIGNS_FILTER,
  NativeFieldFilter.ENGAGEMENT_FILTER,
  NativeFieldFilter.INFLUENCER_MENTIONS_FILTER,
  NativeFieldFilter.INFLUENCER_HASHTAGS_FILTER,
  NativeFieldFilter.INFLUENCER_KEYWORDS_FILTER,
  NativeFieldFilter.CAMPAIGNS_LABELS_FILTER,
  NativeFieldFilter.INFLUENCER_RFM_SCORE_FILTER,
  NativeFieldFilter.INFLUENCER_TRACKED_FILTER,
  //TODO: add other native filters if exist
];

export const CAMPAIGN_CATEGORY_NATIVE_FILTERS: NativeFieldFilter[] = [
  NativeFieldFilter.CAMPAIGNS_FILTER,
  NativeFieldFilter.CAMPAIGNS_LABELS_FILTER,
  //TODO: add other native filters if exist
];

export const CAMPAIGN_INFLUENCER_FILTERS = [
  NativeFieldFilter.INFLUENCER_STATUS_FILTER,
  NativeFieldFilter.INFLUENCER_STATE_FILTER,
  NativeFieldFilter.FOLLOWERS_FILTER,
  NativeFieldFilter.LABELS_FILTER,
  NativeFieldFilter.INFLUENCER_NETWORKS_FILTER,
];

// this is to be used when there is no min or max in range and should be replaces by 0 when sent as request to backend
export const INFINITY_RANGE = Number.MIN_SAFE_INTEGER;

// this is to be used when there is no start or end in timestamp and should be removed when sent as request to backend
export const INFINITY_DATE = Timestamp.fromDate(
  addDuration(new Date(), 50, 'years'),
);

export enum DynamicFilterCategory {
  unknown,
  post,
  influencer,
  campaigns,
}

export function dynamicFilterCategoryToReadable(
  category: DynamicFilterCategory,
): string {
  switch (category) {
    case DynamicFilterCategory.post:
      return $localize`Post Filters`;
    case DynamicFilterCategory.influencer:
      return $localize`Influencer Filters`;
    case DynamicFilterCategory.campaigns:
      return $localize`Campaign Filters`;
  }
  return '';
}

export function getNativeFieldFilterCategory(
  nativeFieldFilter: NativeFilterOption,
): DynamicFilterCategory {
  if (isFrontendNativeFilter(nativeFieldFilter)) {
    return nativeFieldFilter.config.category;
  }

  if (POST_CATEGORY_NATIVE_FILTERS.includes(nativeFieldFilter)) {
    return DynamicFilterCategory.post;
  }

  if (CREATOR_CATEGORY_NATIVE_FILTERS.includes(nativeFieldFilter)) {
    return DynamicFilterCategory.influencer;
  }

  if (CAMPAIGN_CATEGORY_NATIVE_FILTERS.includes(nativeFieldFilter)) {
    return DynamicFilterCategory.campaigns;
  }

  return DynamicFilterCategory.influencer;
}

export function isTextDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;

  if (field.case === 'nativeFieldFilter') {
    return isNativeTextDynamicFilter(filter);
  }
  if (field.case === 'entityField') {
    return isTextCustomField(field.value);
  }
  if (field.case === 'creatorField') {
    return field.value.type === CreatorFieldType.TEXT;
  }

  return false;
}

export function isTextNotSet(filter: TextFilter): boolean {
  return isEmptyString(filter.value.value);
}

export function isListNotSet(filter: ListFilter): boolean {
  return isEmptyArray(filter.value.value?.values);
}

export function isDateNotSet(filter: TimestampFilter): boolean {
  return isNil(filter.is);
}

export function isListDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;

  if (field.case === 'nativeFieldFilter') {
    return isNativeListDynamicFilter(filter);
  }
  if (field.case === 'entityField') {
    return isListCustomField(field.value);
  }
  if (field.case === 'creatorField') {
    return field.value.type === CreatorFieldType.LIST;
  }

  return false;
}

export function isNumberDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;

  if (field.case === 'entityField') {
    return isNumberCustomField(field.value);
  }
  if (field.case === 'creatorField') {
    return field.value.type === CreatorFieldType.NUMBER;
  }

  return false;
}

export function isDecimalNumberDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;

  if (field.case === 'entityField') {
    return isDecimalNumberCustomField(field.value);
  }
  if (field.case === 'creatorField') {
    return field.value.type === CreatorFieldType.DECIMAL_NUMBER;
  }

  return false;
}

export function isFollowersRange(filter: CommonFilter): boolean {
  const field = filter.field;
  return (
    field.case === 'nativeFieldFilter' &&
    field.value === NativeFieldFilter.FOLLOWERS_FILTER
  );
}

export function isEngagementRange(filter: CommonFilter): boolean {
  const field = filter.field;
  return (
    field.case === 'nativeFieldFilter' &&
    field.value === NativeFieldFilter.ENGAGEMENT_FILTER
  );
}

export function isAddressDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;

  if (field.case === 'entityField') {
    return isAddressCustomField(field.value);
  }

  if (field.case === 'creatorField') {
    return field.value.type === CreatorFieldType.ADDRESS;
  }

  return false;
}

export function isKeywordsDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;

  if (field.case === 'nativeFieldFilter') {
    return (
      field.value === NativeFieldFilter.INFLUENCER_KEYWORDS_FILTER ||
      field.value === NativeFieldFilter.VISUAL_KEYWORDS_FILTER
    );
  }

  return false;
}

export function isMentionsDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;

  if (field.case === 'nativeFieldFilter') {
    return (
      field.value === NativeFieldFilter.INFLUENCER_MENTIONS_FILTER ||
      field.value === NativeFieldFilter.VISUAL_MENTIONS_FILTER
    );
  }

  return false;
}

export function isHashtagsDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;

  if (field.case === 'nativeFieldFilter') {
    return (
      field.value === NativeFieldFilter.INFLUENCER_HASHTAGS_FILTER ||
      field.value === NativeFieldFilter.VISUAL_HASHTAGS_FILTER
    );
  }

  return false;
}

export function isBooleanDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;

  if (field.case === 'entityField') {
    return isBooleanCustomField(field.value);
  }

  if (field.case === 'creatorField') {
    return field.value.type === CreatorFieldType.BOOLEAN;
  }

  return false;
}

export function isDateDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;

  if (field.case === 'entityField') {
    return isDateCustomField(field.value);
  }

  if (field.case === 'creatorField') {
    return field.value.type === CreatorFieldType.DATE;
  }

  if (field.case === 'nativeFieldFilter') {
    return isNativeDateDynamicFilter(filter);
  }

  return false;
}

export function isTrackedStatusDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;

  if (field.case === 'nativeFieldFilter') {
    return field.value === NativeFieldFilter.INFLUENCER_TRACKED_FILTER;
  }
  return false;
}

export function isNetworkDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;
  return (
    field.case === 'nativeFieldFilter' &&
    (field.value === NativeFieldFilter.INFLUENCER_NETWORKS_FILTER ||
      field.value === NativeFieldFilter.VISUAL_NETWORKS_FILTER)
  );
}

export function isInfluencerDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;
  return (
    isListDynamicFilter(filter) &&
    field.case === 'nativeFieldFilter' &&
    field.value === NativeFieldFilter.CREATORS_FILTER
  );
}

export function isPostTypeDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;
  return (
    field.case === 'nativeFieldFilter' &&
    field.value === NativeFieldFilter.POST_TYPE_FILTER
  );
}

//the filter options must be directory influencers, not campaing influencers
export function isDirectoryInfluencerDynamicFilter(
  filter: CommonFilter,
  config: DynamicFilterConfig,
): boolean {
  return isInfluencerDynamicFilter(filter) && config.campaignId === BigInt(0);
}

export function isNativeDateDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;
  return (
    field.case === 'nativeFieldFilter' &&
    field.value === NativeFieldFilter.DATE_FILTER
  );
}

export function isNativeTextDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;
  return (
    field.case === 'nativeFieldFilter' &&
    TEXT_NATIVE_FILTERS.includes(field.value)
  );
}

export function isNativeListDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;
  return (
    field.case === 'nativeFieldFilter' &&
    LIST_NATIVE_FILTERS.includes(field.value)
  );
}

export function isInfluencerStateDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;
  return (
    field.case === 'nativeFieldFilter' &&
    field.value === NativeFieldFilter.INFLUENCER_STATE_FILTER
  );
}

export function isInfluencerStatusDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;
  return (
    isListDynamicFilter(filter) &&
    field.case === 'nativeFieldFilter' &&
    field.value === NativeFieldFilter.INFLUENCER_STATUS_FILTER
  );
}

export function isRfmScoreDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;
  return (
    isListDynamicFilter(filter) &&
    field.value === NativeFieldFilter.INFLUENCER_RFM_SCORE_FILTER
  );
}

export function isCampaignDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;
  return (
    isListDynamicFilter(filter) &&
    field.value === NativeFieldFilter.CAMPAIGNS_FILTER
  );
}

export function isLabelDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;
  return (
    isListDynamicFilter(filter) &&
    field.value === NativeFieldFilter.LABELS_FILTER
  );
}

export function isCampaignLabelDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;
  return (
    isListDynamicFilter(filter) &&
    field.value === NativeFieldFilter.CAMPAIGNS_LABELS_FILTER
  );
}

export function isPostLabelDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;
  return (
    isListDynamicFilter(filter) &&
    field.value === NativeFieldFilter.VISUALS_LABELS_FILTER
  );
}

export function isInstaInsightDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;

  if (field.case === 'nativeFieldFilter') {
    return field.value === NativeFieldFilter.INSTAGRAM_INSIGHTS_FILTER;
  }
  return false;
}

export function isAnyLabelNativeFilter(
  nativeFieldFilter: NativeFieldFilter,
): boolean {
  return [
    NativeFieldFilter.LABELS_FILTER,
    NativeFieldFilter.CAMPAIGNS_LABELS_FILTER,
    NativeFieldFilter.VISUALS_LABELS_FILTER,
  ].includes(nativeFieldFilter);
}

export function isLocationDynamicFilter(filter: CommonFilter): boolean {
  const field = filter.field;

  if (field.case === 'creatorField') {
    return field.value.type === CreatorFieldType.LOCATION;
  }

  if (field.case === 'entityField') {
    return isLocationCustomField(field.value);
  }

  return false;
}

export function isCreatorFieldDynamicFilter(filter: CommonFilter): boolean {
  return filter.field.case === 'creatorField';
}

export function getDynamicTextFilterValue(filter: CommonFilter): TextFilter {
  const val = filter.filter;

  if (val.case === 'textFilter') {
    return val.value;
  }

  return new TextFilter();
}

export function getNumberFilterValue(filter: CommonFilter): NumberFilter {
  const val = filter.filter;

  if (val.case === 'numberFilter') {
    return val.value;
  }

  return new NumberFilter();
}

export function getDecimalFilterValue(
  filter: CommonFilter,
): DecimalNumberFilter {
  const val = filter.filter;

  if (val.case === 'decimalFilter') {
    return val.value;
  }

  return new DecimalNumberFilter();
}

export function getDynamicListFilterValue(filter: CommonFilter): ListFilter {
  const val = filter.filter;

  if (val.case === 'listFilter') {
    return val.value;
  }

  return new ListFilter();
}

export function getDynamicLocationFilterValue(
  filter: CommonFilter,
): GoogleLocation {
  const val = filter.filter;

  if (val.case === 'locationFilter') {
    return val.value;
  }

  return new GoogleLocation();
}

export function getDynamicNumberFilterValue(
  filter: CommonFilter,
): NumberFilter {
  const val = filter.filter;

  if (val.case === 'numberFilter') {
    return val.value;
  }

  return new NumberFilter();
}

export function getDynamicDecimalNumberFilterValue(
  filter: CommonFilter,
): DecimalNumberFilter {
  const val = filter.filter;

  if (val.case === 'decimalFilter') {
    return val.value;
  }

  return new DecimalNumberFilter();
}

export function getDynamicBooleanFilterValue(filter: CommonFilter): boolean {
  const val = filter.filter;

  if (val.case === 'boolFilter') {
    return val.value;
  }

  return false;
}

export function getDynamicDateFilterValue(
  filter: CommonFilter,
): TimestampFilter {
  const val = filter.filter;

  if (val.case === 'dateFilter') {
    return val.value;
  }

  return new TimestampFilter();
}

export function getTimestampFilterValue(filter: CommonFilter): TimestampFilter {
  const val = filter.filter;

  if (val.case === 'dateFilter') {
    return val.value;
  }

  return new TimestampFilter();
}

export function getAuthorPermissionFilterValue(
  filter: CommonFilter,
): AuthorPermission {
  const val = filter.filter;

  if (val.case === 'authorPermissionFilter') {
    return val.value;
  }

  return AuthorPermission.PERMISSION_UNDEFINED;
}

export function getDynamicFilterLabel(filter: CommonFilter): string {
  const field = filter.field;
  if (field.case === 'nativeFieldFilter') {
    return getNativeFilterLabel(filter);
  }

  if (field.case === 'entityField') {
    return getCustomFieldLabel(field.value);
  }

  return field.value?.name ?? '';
}

export function getDynamicFilterIcon(filter: CommonFilter): string {
  const field = filter.field;

  if (field.case === 'entityField') {
    return getCustomFieldIcon(field.value);
  }

  return '';
}

export function getDynamicFilterFormattedValue(
  filter: CommonFilter,
  config: DynamicFilterConfig,
): string {
  if (isTextDynamicFilter(filter)) {
    return getDynamicTextFilterValue(filter).value.value ?? '';
  }

  if (isNumberDynamicFilter(filter) || isFollowersRange(filter)) {
    return formattedNumberValue(getNumberFilterValue(filter));
  }

  if (isDecimalNumberDynamicFilter(filter) || isEngagementRange(filter)) {
    return formattedDecimalNumberValue(getDecimalFilterValue(filter));
  }

  if (isBooleanDynamicFilter(filter)) {
    return formattedBoolValue(filter);
  }

  if (isTrackedStatusDynamicFilter(filter)) {
    return formattedTrackedStatusValue(filter);
  }

  if (isDateDynamicFilter(filter)) {
    return formattedTimestampFilterValue(getTimestampFilterValue(filter));
  }

  if (isLocationDynamicFilter(filter)) {
    return getDynamicLocationFilterValue(filter).locationName;
  }

  if (isListDynamicFilter(filter)) {
    if (isPostTypeDynamicFilter(filter)) {
      return postTypeFilterFormattedValue(filter);
    }

    const selection =
      getDynamicListFilterValue(filter).value.value?.values ?? [];
    const count = selection.length;

    return plural(count, {
      zero: '',
      one: readableListOptions(selection[0], filter, config),
      other: $localize`${readableListOptions(selection[0], filter, config)} and ${count - 1} more`,
    });
  }

  if (isInstaInsightDynamicFilter(filter)) {
    return formattedInstaInsightValue(filter);
  }

  return '';
}

export function isEmptyDynamicFilter(filter: CommonFilter): boolean {
  const filterValue = filter.filter;
  return filterValue.case === 'isEmpty';
}

export function getNativeFilterLabel(filter: CommonFilter): string {
  if (filter.field.case === 'nativeFieldFilter') {
    if (filterHasNetwork(filter)) {
      if (
        filter.filter.case === 'decimalFilter' &&
        filter.filter.value.value.case === 'engagementRange'
      ) {
        return $localize`${readableNetwork(filter.filter.value.value.value.network)} ${nativeFieldFilterToReadable(filter.field.value)}`;
      }
    }

    if (
      filter.filter.case === 'numberFilter' &&
      filter.filter.value.value.case === 'followersRange'
    ) {
      return $localize`${readableNetwork(filter.filter.value.value.value.network)} ${nativeFieldFilterToReadable(filter.field.value)}`;
    }

    return nativeFieldFilterToReadable(filter.field.value);
  }

  return '';
}

export function filterHasNetwork(filter: CommonFilter): boolean {
  if (filter.field.case === 'nativeFieldFilter') {
    return NATIVE_FILTERS_WITH_NETWORK.includes(filter.field.value);
  }
  return false;
}

export function nativeFieldFilterToReadable(
  nativeFieldFilter: NativeFilterOption,
): string {
  if (isFrontendNativeFilter(nativeFieldFilter)) {
    return nativeFieldFilter.label;
  }

  switch (nativeFieldFilter) {
    case NativeFieldFilter.ENGAGEMENT_FILTER: {
      return $localize` Engagement Rate`;
    }
    case NativeFieldFilter.FOLLOWERS_FILTER: {
      return $localize`Number of Followers`;
    }
    case NativeFieldFilter.INFLUENCER_TRACKED_FILTER:
      return $localize`Tracking status`;

    case NativeFieldFilter.CAMPAIGNS_FILTER:
      return $localize`Campaigns`;

    case NativeFieldFilter.CAMPAIGNS_LABELS_FILTER:
      return $localize`Campaign labels`;

    case NativeFieldFilter.DATE_FILTER:
      return $localize`Dates`;

    case NativeFieldFilter.CREATORS_FILTER:
      return $localize`Influencer`;

    case NativeFieldFilter.INFLUENCER_HASHTAGS_FILTER:
      return $localize`Hashtags`;

    case NativeFieldFilter.INFLUENCER_KEYWORDS_FILTER:
      return $localize`Keywords`;

    case NativeFieldFilter.INFLUENCER_MENTIONS_FILTER:
      return $localize`Mentions`;

    case NativeFieldFilter.VISUAL_NETWORKS_FILTER:
    case NativeFieldFilter.INFLUENCER_NETWORKS_FILTER:
      return $localize`Networks`;

    case NativeFieldFilter.INFLUENCER_RFM_SCORE_FILTER:
      return $localize`RFM Score`;

    case NativeFieldFilter.LABELS_FILTER:
      return $localize`Influencer labels`;

    case NativeFieldFilter.INFLUENCER_STATUS_FILTER:
      return $localize`Invitation status`;

    case NativeFieldFilter.INFLUENCER_STATE_FILTER:
      return $localize`Campaign status`;

    case NativeFieldFilter.VISUALS_LABELS_FILTER:
      return $localize`Post labels`;

    case NativeFieldFilter.VISUAL_HASHTAGS_FILTER:
      return $localize`Hashtags`;

    case NativeFieldFilter.VISUAL_KEYWORDS_FILTER:
      return $localize`Keywords`;

    case NativeFieldFilter.VISUAL_LOCATIONS_FILTER:
      return $localize`Location`;

    case NativeFieldFilter.VISUAL_MENTIONS_FILTER:
      return $localize`Mentions`;

    case NativeFieldFilter.POST_TYPE_FILTER:
      return $localize`Post types`;

    case NativeFieldFilter.INSTAGRAM_INSIGHTS_FILTER:
      return $localize`Instagram Insights`;

    default:
      return 'unknown';
  }
}

export function isValidRangeValue(v: bigint | number | undefined): boolean {
  return isNotNil(v) && Number(v) !== INFINITY_RANGE;
}

export function filterHasNoEmpty(filter: CommonFilter): boolean {
  return (
    isNetworkDynamicFilter(filter) ||
    isInfluencerDynamicFilter(filter) ||
    isNativeDateDynamicFilter(filter) ||
    isInfluencerStateDynamicFilter(filter) ||
    isInfluencerStatusDynamicFilter(filter) ||
    isRfmScoreDynamicFilter(filter)
  );
}

export function isCommonFilterNotSet(commonFilter: CommonFilter): boolean {
  const filter = commonFilter.filter;

  switch (filter.case) {
    case 'textFilter':
      return isTextNotSet(filter.value);
    case 'listFilter':
      return isListNotSet(filter.value);
    case 'dateFilter':
      return isDateNotSet(filter.value);
    case 'locationFilter':
      // for location we just need to check if it has one,
      //since it is a GoogleLocation and not a string
      return isNil(filter.value);
  }

  // other types of filters have a default value
  return false;
}

export function nativeFieldWithNetworkToCommonFilter(
  nativeFieldFilter: NativeFilterOption,
  network: Network,
): CommonFilter {
  if (isFrontendNativeFilter(nativeFieldFilter)) {
    return nativeFrontendFilterToCommonFilter(nativeFieldFilter);
  }

  const filter = new CommonFilter({
    field: { case: 'nativeFieldFilter', value: nativeFieldFilter },
  });
  if (nativeFieldFilter === NativeFieldFilter.ENGAGEMENT_FILTER) {
    filter.filter = {
      case: 'decimalFilter',
      value: new DecimalNumberFilter({
        value: {
          case: 'engagementRange',
          value: new NetworkEngagementRangeV2({
            network: network,
          }),
        },
      }),
    };
  } else if (nativeFieldFilter === NativeFieldFilter.FOLLOWERS_FILTER) {
    filter.filter = {
      case: 'numberFilter',
      value: new NumberFilter({
        value: {
          case: 'followersRange',
          value: new NetworkFollowersRange({
            network: network,
          }),
        },
      }),
    };
  }
  return filter;
}

export function entityFieldToCommonFilter(
  enitityField: EntityField,
): CommonFilter {
  const filter = new CommonFilter({
    field: { case: 'entityField', value: enitityField },
  });
  if (isTextCustomField(enitityField) || isAddressCustomField(enitityField)) {
    filter.filter = {
      case: 'textFilter',
      value: new TextFilter({
        value: {
          case: 'contains',
          value: '',
        },
      }),
    };
  } else if (isListCustomField(enitityField)) {
    filter.filter = {
      case: 'listFilter',
      value: new ListFilter({
        value: {
          case: 'anyOf',
          value: new StringList(),
        },
      }),
    };
  } else if (isNumberCustomField(enitityField)) {
    filter.filter = {
      case: 'numberFilter',
      value: new NumberFilter({
        value: {
          case: 'is',
          value: BigInt(0),
        },
      }),
    };
  } else if (isDecimalNumberCustomField(enitityField)) {
    filter.filter = {
      case: 'decimalFilter',
      value: new DecimalNumberFilter({
        value: {
          case: 'is',
          value: 0,
        },
      }),
    };
  } else if (isDateCustomField(enitityField)) {
    filter.filter = {
      case: 'dateFilter',
      value: new TimestampFilter({
        is: new TimeRestriction({
          start: Timestamp.fromDate(new Date()),
          end: Timestamp.fromDate(new Date()),
        }),
      }),
    };
  } else if (isBooleanCustomField(enitityField)) {
    filter.filter = {
      case: 'boolFilter',
      value: true,
    };
  } else if (isLocationCustomField(enitityField)) {
    filter.filter = {
      case: 'locationFilter',
      value: new GoogleLocation(),
    };
  }

  return filter;
}

export function nativeFieldToCommonFilter(
  nativeFieldFilter: NativeFilterOption,
): CommonFilter {
  if (isFrontendNativeFilter(nativeFieldFilter)) {
    return nativeFrontendFilterToCommonFilter(nativeFieldFilter);
  }

  const filter = new CommonFilter({
    field: { case: 'nativeFieldFilter', value: nativeFieldFilter },
  });

  if (TEXT_NATIVE_FILTERS.includes(nativeFieldFilter)) {
    filter.filter = {
      case: 'textFilter',
      value: new TextFilter({
        value: {
          case: 'contains',
          value: '',
        },
      }),
    };
  } else if (LIST_NATIVE_FILTERS.includes(nativeFieldFilter)) {
    filter.filter = {
      case: 'listFilter',
      value: new ListFilter({
        value: {
          case: 'anyOf',
          value: new StringList(),
        },
      }),
    };
  } else if (nativeFieldFilter === NativeFieldFilter.DATE_FILTER) {
    filter.filter = {
      case: 'dateFilter',
      value: new TimestampFilter({
        is: new TimeRestriction({
          start: Timestamp.fromDate(new Date()),
          end: Timestamp.fromDate(new Date()),
        }),
      }),
    };
  } else if (
    nativeFieldFilter === NativeFieldFilter.INSTAGRAM_INSIGHTS_FILTER
  ) {
    filter.filter = {
      case: 'authorPermissionFilter',
      value: AuthorPermission.AUTHORIZED,
    };
  }
  return filter;
}

export function addSearchTextFilter(
  dynamicFilter: DynamicFilter,
  caseVal: 'contains' | 'notContains',
  searchText: string,
): DynamicFilter {
  const newDynamicFilter = dynamicFilter.clone();
  const filter = new DynamicFilterGroup({
    filters: [
      new CommonFilter({
        field: {
          case: 'nativeFieldFilter',
          value: NativeFieldFilter.SEARCH_TEXT_FILTER,
        },
        filter: {
          case: 'textFilter',
          value: new TextFilter({
            value: {
              case: caseVal,
              value: searchText,
            },
          }),
        },
      }),
    ],
  });
  newDynamicFilter.filterGroup.push(filter);
  return newDynamicFilter;
}

export function addInfluencerLabelsFilter(
  dynamicFilter: DynamicFilter,
  caseVal: 'anyOf' | 'noneOf',
  labelsFilter: string[],
): DynamicFilter {
  const newDynamicFilter = dynamicFilter.clone();
  const filter = new DynamicFilterGroup({
    filters: [
      new CommonFilter({
        field: {
          case: 'nativeFieldFilter',
          value: NativeFieldFilter.LABELS_FILTER,
        },
        filter: {
          case: 'listFilter',
          value: new ListFilter({
            value: {
              case: caseVal,
              value: new StringList({
                values: labelsFilter,
              }),
            },
          }),
        },
      }),
    ],
  });
  newDynamicFilter.filterGroup.push(filter);
  return newDynamicFilter;
}

export function addCampaignsFilter(
  dynamicFilter: DynamicFilter,
  caseVal: 'anyOf' | 'noneOf',
  campaignsFilter: string[],
): DynamicFilter {
  const newDynamicFilter = dynamicFilter.clone();
  const filter = new DynamicFilterGroup({
    filters: [
      new CommonFilter({
        field: {
          case: 'nativeFieldFilter',
          value: NativeFieldFilter.CAMPAIGNS_FILTER,
        },
        filter: {
          case: 'listFilter',
          value: new ListFilter({
            value: {
              case: caseVal,
              value: new StringList({
                values: campaignsFilter,
              }),
            },
          }),
        },
      }),
    ],
  });
  newDynamicFilter.filterGroup.push(filter);
  return newDynamicFilter;
}

export type ApplyCommonFilterFn<T> = (filter: CommonFilter, item: T) => boolean;

// Apply a dynamic filter to an array of items
// Will handle AND and OR logic and group filters
//
// This it's a Frontend filter, you must pass filter function that implements
// all filters you want to apply
export function filterWithDynamicFilter<T>(
  dynamicFilter: DynamicFilter,
  items: T[],
  filterFn: ApplyCommonFilterFn<T>,
): T[] {
  if (isEmptyArray(dynamicFilter.filterGroup)) {
    return items;
  }

  return items.filter((item) => {
    const groups = dynamicFilter.filterGroup;
    if (dynamicFilter.operator === LogicOperator.AND) {
      return groups.every((group) => applyFilterGroup(group, item, filterFn));
    }
    return groups.some((group) => applyFilterGroup(group, item, filterFn));
  });
}

function applyFilterGroup<T>(
  group: DynamicFilterGroup,
  item: T,
  filterFn: ApplyCommonFilterFn<T>,
): boolean {
  if (group.operator === LogicOperator.AND) {
    return group.filters.every((filter) => filterFn(filter, item));
  }
  return group.filters.some((filter) => filterFn(filter, item));
}

function getListFilterValues(filter: ListFilter): string[] {
  return filter.value?.value?.values ?? [];
}

function getEntityFieldListValues(
  entityFieldValue: EntityFieldValue,
): string[] {
  if (entityFieldValue.value?.case === 'enumValue') {
    return entityFieldValue.value?.value?.values ?? [];
  }
  return [];
}

export function matchListFilter<T = string>(
  filterCase: 'anyOf' | 'noneOf' | undefined,
  filterValues: T[],
  items: T[],
): boolean {
  switch (filterCase) {
    case 'anyOf':
      return items.some((i) => filterValues.includes(i));
    case 'noneOf':
      return items.every((i) => filterValues.includes(i) === false);
    // empty case
    case undefined:
      return isEmptyArray(items);
  }
}

export function matchTextFilter(
  filterCase: 'contains' | 'notContains' | undefined,
  filterQuery: string,
  item: string,
): boolean {
  filterQuery = filterQuery.trim();
  filterQuery = escapeRegExp(filterQuery);

  const regexp = new RegExp(filterQuery, 'i');
  switch (filterCase) {
    case 'contains':
      return regexp.test(item);
    case 'notContains':
      return regexp.test(item) === false;
    // empty case
    case undefined:
      return isEmptyString(item);
  }
}

export function matchEntityField(
  filter: CommonFilter,
  field: EntityField,
  fieldValues: EntityFieldValue[],
): boolean {
  const fieldId = field.entityFieldId;
  const fieldValue = fieldValues.find((f) => f.entityFieldId === fieldId);

  const filterCase = filter.filter.case;
  const fieldValueCase = fieldValue?.value?.case;

  if (isNotNil(fieldValue)) {
    if (filterCase === 'listFilter' && fieldValueCase === 'enumValue') {
      const listFilter = filter.filter.value;
      return matchListFilter(
        listFilter.value.case,
        getListFilterValues(listFilter),
        getEntityFieldListValues(fieldValue),
      );
    }

    if (filterCase === 'textFilter' && fieldValueCase === 'textValue') {
      const textFilter = filter.filter.value;
      return matchTextFilter(
        textFilter.value.case,
        textFilter.value.value ?? '',
        fieldValue.value.value,
      );
    }
  }

  return false;
}

export function addCommonFilter(
  dynamicFilter: DynamicFilter,
  filter: CommonFilter,
): DynamicFilter {
  const newDynamicFilter = dynamicFilter.clone();
  const group = new DynamicFilterGroup({
    filters: [filter],
  });
  newDynamicFilter.filterGroup.push(group);

  if (newDynamicFilter.filterGroup.length === 1) {
    newDynamicFilter.operator = LogicOperator.AND;
  }

  return newDynamicFilter;
}

// Helper function to create Frontend dynamic filter
// that can be pass to `nativeFilterOptions` of `DynamicFiltersConfigBloc`
//
// MUST ONLY be use when dynamic filter are execute by Frontend
// with the `filterWithDynamicFilter` function
export function createFrontendNativeFilter(
  required: { id: string; label: string },
  config: FrontendListFilterConfig,
): FrontendNativeFilter {
  return {
    ...required,
    config,
  };
}

type FieldType =
  | {
      value: CreatorFieldType;
      case: 'creatorFieldType';
    }
  | {
      value: CampaignFieldType;
      case: 'campaignFieldType';
    };

// This is a hack to support creation of Frontend dynamic filters
// We use a fake EntityField (with id -1) to represent the filter
// And store the Frontend filter ID into the description of the EntityField.
//
// We also pass all necessary info like listValue and multiSelection
function createFakeFrontendFieldFilter(
  nativeFilter: FrontendNativeFilter,
): EntityField {
  const fieldType: FieldType =
    nativeFilter.config.category === DynamicFilterCategory.influencer
      ? {
          case: 'creatorFieldType',
          value: CreatorFieldType.LIST,
        }
      : {
          case: 'campaignFieldType',
          value: CampaignFieldType.LIST,
        };

  return new EntityField({
    name: nativeFilter.label,
    description: nativeFilter.id,
    entityFieldId: -1n,
    fieldType,
    listValues: nativeFilter.config.listValues,
    multiSelection: nativeFilter.config.multiSelection,
  });
}

export function isFrontendNativeCommonFilter(filter: CommonFilter): boolean {
  return (
    filter.field.case === 'entityField' &&
    filter.field.value.entityFieldId === -1n
  );
}

export function getFrontendNativeFilterId(filter: CommonFilter): string {
  if (filter.field.case === 'entityField') {
    return filter.field.value.description;
  }
  return '';
}

export function nativeFrontendFilterToCommonFilter(
  nativeFieldFilter: FrontendNativeFilter,
): CommonFilter {
  return new CommonFilter({
    field: {
      case: 'entityField',
      value: createFakeFrontendFieldFilter(nativeFieldFilter),
    },
    filter: {
      case: nativeFieldFilter.config.case,
      value: nativeFieldFilter.config.filterBuilder(),
    },
  });
}

export function getTimeRestrictionsFromFilter(
  filters: CommonFilter[],
): TimeRestriction[] {
  return filters
    .filter((f) => isDateDynamicFilter(f))
    .map((e) => {
      if (e.filter.case === 'dateFilter') {
        return e.filter.value.is;
      }
      return;
    })
    .filter(isNotNil);
}

import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianLandingPages } from '@frontend2/proto/librarian/proto/landing_pages_connect';
import { GRPC_TRANSPORT } from './grpc.service';

type Client = PromiseClient<typeof LibrarianLandingPages>;

function factory(): Client {
  return createPromiseClient(LibrarianLandingPages, inject(GRPC_TRANSPORT));
}

const provider = new InjectionToken('LibrarianLandingPages', {
  factory,
});

export function injectLibrarianLandingPagesClient(): Client {
  return inject(provider);
}

import { NgModule } from '@angular/core';
import { LeftyPostMediaOrCaptionComponent } from '../lefty-posts/media/post-media-or-caption.component';
import { LeftyPostMediaComponent } from '../lefty-posts/media/post-media.component';
import { CastingInfluencerAudienceComponent } from './influencer-audience/influencer-audience.component';
import { CastingInfluencerPostsComponent } from './influencer-posts/influencer-posts.component';
import { CastingInfluencerStatsComponent } from './influencer-stats/influencer-stats.component';
import { CastingProfileCommentCardComponent } from './profile-comments/comment-card.component';
import { CastingProfileCommentsComponent } from './profile-comments/profile-comments.component';

@NgModule({
  imports: [
    LeftyPostMediaComponent,
    LeftyPostMediaOrCaptionComponent,
    CastingInfluencerStatsComponent,
    CastingProfileCommentsComponent,
    CastingProfileCommentCardComponent,
    CastingInfluencerAudienceComponent,
    CastingInfluencerPostsComponent,
  ],
  exports: [
    CastingInfluencerStatsComponent,
    CastingProfileCommentsComponent,
    CastingProfileCommentCardComponent,
    CastingInfluencerAudienceComponent,
    CastingInfluencerPostsComponent,
  ],
})
export class LeftyCastingModule {}

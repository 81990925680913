<lefty-icon
  *ngIf="hideIcon === false"
  class="icon feedback"
  [icon]="icon"
>
</lefty-icon>

<div class="container">
  <div class="content">
    <div
      #titleContainer
      class="title"
    >
      <h4 *ngIf="isNotEmptyTitle">
        {{ title }}
      </h4>

      <ng-content select="h4, h5"></ng-content>
    </div>

    <p #content>
      <ng-content></ng-content>
    </p>
  </div>

  <div class="actions">
    <ng-content select="[action]"></ng-content>
  </div>
</div>

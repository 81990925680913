import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianSearch } from '@frontend2/proto/librarian/proto/search_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianSearchClient = PromiseClient<typeof LibrarianSearch>;

export const LibrarianSearchClientProvider = new InjectionToken(
  'LibrarianSearchClient',
  {
    factory: (): LibrarianSearchClient =>
      createPromiseClient(LibrarianSearch, inject(GRPC_TRANSPORT)),
  },
);

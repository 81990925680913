// This file defines messages common to media, regardless of their network.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/media.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";

/**
 * The possible resolution for a MEDIA, be it an image or a video.
 *
 * It is shared for all networks so it shall contain the union of all possible values accross networks, but please reuse
 * the existing ones as much as possible, more often than not the resolutions shall map to already existing ones.
 *
 * @generated from enum common.proto.MediaResolution
 */
export enum MediaResolution {
  /**
   * @generated from enum value: LOW = 0;
   */
  LOW = 0,

  /**
   * @generated from enum value: STANDARD = 1;
   */
  STANDARD = 1,

  /**
   * @generated from enum value: HIGH = 2;
   */
  HIGH = 2,

  /**
   * @generated from enum value: THUMBNAIL = 3;
   */
  THUMBNAIL = 3,

  /**
   * @generated from enum value: VIDEO_LOW_BANDWIDTH = 4;
   */
  VIDEO_LOW_BANDWIDTH = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(MediaResolution)
proto3.util.setEnumType(MediaResolution, "common.proto.MediaResolution", [
  { no: 0, name: "LOW" },
  { no: 1, name: "STANDARD" },
  { no: 2, name: "HIGH" },
  { no: 3, name: "THUMBNAIL" },
  { no: 4, name: "VIDEO_LOW_BANDWIDTH" },
]);

/**
 * @generated from enum common.proto.MediaSourceType
 */
export enum MediaSourceType {
  /**
   * @generated from enum value: MEDIA_SOURCE_UNKNOWN = 0;
   */
  MEDIA_SOURCE_UNKNOWN = 0,

  /**
   * @generated from enum value: MEDIA_SOURCE_RETRIEVED = 1;
   */
  MEDIA_SOURCE_RETRIEVED = 1,

  /**
   * From the great Chrome Extension
   *
   * @generated from enum value: MEDIA_SOURCE_PLUGIN = 2;
   */
  MEDIA_SOURCE_PLUGIN = 2,

  /**
   * From FB permissions with a valid token
   *
   * @generated from enum value: MEDIA_SOURCE_OPTED_IN = 3;
   */
  MEDIA_SOURCE_OPTED_IN = 3,

  /**
   * Uploaded manually through admin
   *
   * @generated from enum value: MEDIA_SOURCE_UPLOADED = 4;
   */
  MEDIA_SOURCE_UPLOADED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(MediaSourceType)
proto3.util.setEnumType(MediaSourceType, "common.proto.MediaSourceType", [
  { no: 0, name: "MEDIA_SOURCE_UNKNOWN" },
  { no: 1, name: "MEDIA_SOURCE_RETRIEVED" },
  { no: 2, name: "MEDIA_SOURCE_PLUGIN" },
  { no: 3, name: "MEDIA_SOURCE_OPTED_IN" },
  { no: 4, name: "MEDIA_SOURCE_UPLOADED" },
]);

/**
 * The media was seen deleted from its source network.
 *
 * @generated from message common.proto.DeletedInfo
 */
export class DeletedInfo extends Message<DeletedInfo> {
  /**
   * @generated from field: bool is_deleted = 1;
   */
  isDeleted = false;

  /**
   * @generated from field: google.protobuf.Timestamp seen_deleted = 2;
   */
  seenDeleted?: Timestamp;

  constructor(data?: PartialMessage<DeletedInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DeletedInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "is_deleted", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "seen_deleted", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeletedInfo {
    return new DeletedInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeletedInfo {
    return new DeletedInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeletedInfo {
    return new DeletedInfo().fromJsonString(jsonString, options);
  }

  static equals(a: DeletedInfo | PlainMessage<DeletedInfo> | undefined, b: DeletedInfo | PlainMessage<DeletedInfo> | undefined): boolean {
    return proto3.util.equals(DeletedInfo, a, b);
  }
}

/**
 * @generated from message common.proto.MediaSourceInfo
 */
export class MediaSourceInfo extends Message<MediaSourceInfo> {
  /**
   * @generated from field: common.proto.MediaSourceType source_type = 1;
   */
  sourceType = MediaSourceType.MEDIA_SOURCE_UNKNOWN;

  /**
   * teams owners ids of teams who collected media
   *
   * @generated from field: repeated int64 users_ids = 2;
   */
  usersIds: bigint[] = [];

  constructor(data?: PartialMessage<MediaSourceInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.MediaSourceInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "source_type", kind: "enum", T: proto3.getEnumType(MediaSourceType) },
    { no: 2, name: "users_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaSourceInfo {
    return new MediaSourceInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaSourceInfo {
    return new MediaSourceInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaSourceInfo {
    return new MediaSourceInfo().fromJsonString(jsonString, options);
  }

  static equals(a: MediaSourceInfo | PlainMessage<MediaSourceInfo> | undefined, b: MediaSourceInfo | PlainMessage<MediaSourceInfo> | undefined): boolean {
    return proto3.util.equals(MediaSourceInfo, a, b);
  }
}

/**
 * This message contains all the common facts to media and has been added to each top level database media (Image, Video,
 * TwitterPost, etc...).
 *
 * It will start being populated on 25/04/2021. Any media persisted before that date must still retain compatibility with
 * the old fields, pending writing a migration job.
 *
 * Older fields that are superseeded by this have been annotated with label "DEPRECATED_FOR_FACTS". We shall not use the
 * official deprecated warning since it's not clear whether we'll ever run the migration or not.
 *
 * @generated from message common.proto.GenerikMediaFacts
 */
export class GenerikMediaFacts extends Message<GenerikMediaFacts> {
  /**
   * @generated from field: common.proto.DeletedInfo deleted_info = 1;
   */
  deletedInfo?: DeletedInfo;

  /**
   * When the media was first ingested in our systems.
   *
   * @generated from field: google.protobuf.Timestamp ingestion_time = 2;
   */
  ingestionTime?: Timestamp;

  /**
   * Last time we updated the stats of that media in our systems.
   *
   * @generated from field: google.protobuf.Timestamp last_stats_time = 3;
   */
  lastStatsTime?: Timestamp;

  /**
   * @generated from field: repeated common.proto.MediaSourceInfo media_source_infos = 4;
   */
  mediaSourceInfos: MediaSourceInfo[] = [];

  constructor(data?: PartialMessage<GenerikMediaFacts>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.GenerikMediaFacts";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "deleted_info", kind: "message", T: DeletedInfo },
    { no: 2, name: "ingestion_time", kind: "message", T: Timestamp },
    { no: 3, name: "last_stats_time", kind: "message", T: Timestamp },
    { no: 4, name: "media_source_infos", kind: "message", T: MediaSourceInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikMediaFacts {
    return new GenerikMediaFacts().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikMediaFacts {
    return new GenerikMediaFacts().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikMediaFacts {
    return new GenerikMediaFacts().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikMediaFacts | PlainMessage<GenerikMediaFacts> | undefined, b: GenerikMediaFacts | PlainMessage<GenerikMediaFacts> | undefined): boolean {
    return proto3.util.equals(GenerikMediaFacts, a, b);
  }
}


import { Inject, Injectable } from '@angular/core';
import { StringValue } from '@bufbuild/protobuf';
import { LocalClient, LocalClientProvider } from '@frontend2/api';
import { isNotEmptyString } from '@frontend2/core';
import {
  AutocompleteLocationRequest,
  GoogleLocation,
} from '@frontend2/proto/librarian/proto/local_pb';
import { showToastException } from '../error-handler';
import { ToastManager } from '../toast/toast.service';

@Injectable({ providedIn: 'root' })
export class LocationService {
  constructor(
    @Inject(LocalClientProvider) private librarian: LocalClient,
    private toastManager: ToastManager,
  ) {}

  async autocomplete(query: string): Promise<GoogleLocation[]> {
    query = query.trim();
    if (!isNotEmptyString(query)) {
      return [];
    }

    try {
      const response = await this.librarian.autocompleteLocations(
        new AutocompleteLocationRequest({ query }),
      );
      return response.location;
    } catch (e) {
      showToastException(this.toastManager, e);
      return [];
    }
  }

  async getLocationDetails(id: string): Promise<GoogleLocation> {
    try {
      return await this.librarian.locationDetails(
        new StringValue({ value: id }),
      );
    } catch (e) {
      showToastException(this.toastManager, e);
      return new GoogleLocation();
    }
  }
}

import { Injectable } from '@angular/core';
import { CampaignKeywordType } from '@frontend2/proto/common/proto/campaign_pb';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  createTrackingKeywordsConfig,
  twitterMentionsField,
  twitterTagsField,
} from './config.helpers';
import * as TKMessages from './config.messages';
import { DefaultNetworkConfig, FormField } from './config.models';

@Injectable({ providedIn: 'root' })
export class TwitterConfig extends DefaultNetworkConfig {
  override readonly network = Network.TWEET;
  override readonly supportReshares = true;
  override readonly uploadPlaceholder = [
    'Beyonce',
    'AntoGriezmann',
    'https://twitter.com/ArianaGrande',
    '...',
  ].join('\n');

  override readonly keywords = createTrackingKeywordsConfig({
    types: [
      CampaignKeywordType.TWITTER_TAG,
      CampaignKeywordType.TWITTER_MENTION,
    ],
    formHelpers: new Map<CampaignKeywordType, FormField>([
      [CampaignKeywordType.TWITTER_TAG, twitterTagsField],
      [CampaignKeywordType.TWITTER_MENTION, twitterMentionsField],
    ]),
    errorMessage: TKMessages.twitterErrorMessage,
  });
}

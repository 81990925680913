import {
  CustomizableFloat,
  Network,
  PostType,
} from '@frontend2/proto/common/proto/common_pb';
import { Feature } from '@frontend2/proto/librarian/proto/common_pb';
import {
  LoggedBootstrapping,
  PostValueSettingsResp,
  PostValueSettingsResp_PostValueSetting,
  ScreenConfig,
} from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import { SubscriptionType } from '@frontend2/proto/librarian/proto/payments_pb';
import { isEmptyArray, isNil, isNotNil } from '../../utils/common.helpers';
import { getNetworkOfPostType } from '../common/common_pb.helpers';

export function userHasFeatureAccess(
  user: LoggedBootstrapping,
  feature: Feature,
): boolean {
  // no feature limit, access to everything
  if (user.limitations?.features.length === 0) {
    return true;
  }

  return user.limitations?.features.includes(feature) ?? false;
}

export function userHasCampaignAccess(user: LoggedBootstrapping): boolean {
  return userHasFeatureAccess(user, Feature.CAMPAIGNS_FEATURE);
}

export function userHasAffiliationAccess(user: LoggedBootstrapping): boolean {
  return userHasFeatureAccess(user, Feature.AFFILIATION_FEATURE);
}

export function userHasDirectoryAccess(user: LoggedBootstrapping): boolean {
  return userHasFeatureAccess(user, Feature.DIRECTORY_FEATURE);
}

export function userHasChatAccess(user: LoggedBootstrapping): boolean {
  return userHasFeatureAccess(user, Feature.MESSAGES_FEATURE);
}

export function userHasPaymentsAccess(user: LoggedBootstrapping): boolean {
  return userHasFeatureAccess(user, Feature.PAYMENTS_FEATURE);
}

export function userHasCWAccess(user: LoggedBootstrapping): boolean {
  return userHasFeatureAccess(user, Feature.ANALYTICS_FEATURE);
}

export function userHasDiscoverAccess(user: LoggedBootstrapping): boolean {
  return userHasFeatureAccess(user, Feature.DISCOVER_FEATURE);
}

export function userHasLandingPageAccess(user: LoggedBootstrapping): boolean {
  return userHasFeatureAccess(user, Feature.LANDING_PAGE_FEATURE);
}

export function userHasCastingAccess(user: LoggedBootstrapping): boolean {
  return userHasFeatureAccess(user, Feature.CASTING_FEATURE);
}

export function userHasProductSeedingAccess(
  user: LoggedBootstrapping,
): boolean {
  return userHasFeatureAccess(user, Feature.PRODUCTS_SEEDING_FEATURE);
}

export function getValueOfPostType(
  settings: PostValueSettingsResp,
  type: PostType,
): PostValueSettingsResp_PostValueSetting {
  const setting = settings.cpms.find(
    (cpm) => cpm.theTarget.case === 'postType' && cpm.theTarget.value === type,
  );
  return isNotNil(setting)
    ? setting
    : new PostValueSettingsResp_PostValueSetting({
        theTarget: { case: 'network', value: getNetworkOfPostType(type) },
        cpm: getDefaultPostValueOfNetwork(settings, getNetworkOfPostType(type)),
      });
}

export function getDefaultPostValueOfNetwork(
  settings: PostValueSettingsResp,
  network: Network,
): CustomizableFloat {
  const setting = settings.cpms.filter(
    (cpm) =>
      cpm.theTarget.case === 'network' && cpm.theTarget.value === network,
  );

  return (
    setting.map((item) => item.cpm).find((item) => item?.isDefault === true) ??
    new CustomizableFloat({ isDefault: true })
  );
}

export function hintIsValid(
  bootstrap: LoggedBootstrapping,
  hint: number,
): boolean {
  // Hint 0 is always valid if not multi workspaces
  if (isEmptyArray(bootstrap.customerSpaces) && hint === 0) {
    return true;
  }

  const spaces = bootstrap.customerSpaces.map((s) => s.idxHint);

  return spaces.includes(hint);
}

export function userMustPay(bootstrap: LoggedBootstrapping): boolean {
  return [
    SubscriptionType.FREE,
    SubscriptionType.INACTIVE,
    SubscriptionType.SUBSCRIPTION_TYPE_UNKNOWN,
  ].includes(bootstrap.subscriptionType);
}

export function defaultSpaceIdxFromBootstrap(
  bootstrap: LoggedBootstrapping,
): number {
  if (bootstrap.customerSpaces.length > 1) {
    return bootstrap.customerSpaces[0].idxHint;
  }
  return 0;
}

export function createGhostScreenConfig(): ScreenConfig {
  return new ScreenConfig({
    screenName: undefined,
  });
}

export function isGhostScreenConfig(config: ScreenConfig): boolean {
  return isNil(config.screenName);
}

const SUPER_ADMINS = [
  'roman@lefty.io',
  'mateo@lefty.io',
  'hadrien@lefty.io',
  'parisa@lefty.io',
  'aly@lefty.io',
  'selma@lefty.io',
  'emilie@lefty.io',
];

export function isSuperAdmin(user: LoggedBootstrapping): boolean {
  return user.isAdmin && SUPER_ADMINS.includes(user.email);
}

import {
  Attribute,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Injectable,
  Input,
  NgZone,
  OnDestroy,
} from '@angular/core';
import { Disposer, Numbers } from '@frontend2/core';
import { Observable } from 'rxjs';
import {
  focusElement,
  isDownKey,
  isEndKey,
  isHomeKey,
  isNextKey,
  isPrevKey,
  isUpKey,
  attributeToBool,
} from './utils';

/// An event to trigger moving focus to another item in a list.
export class FocusMoveEvent {
  /// The component which published this event.
  readonly focusItem: FocusableItem;

  /// The position, relative the item, of where to set focus.
  readonly offset: number;

  /// Home key was pressed.
  readonly home: boolean;

  /// End key was pressed.
  readonly end: boolean;

  /// Up or down arrow key was pressed.
  readonly upDown: boolean;

  private preventDefaultDelegate?: () => void;

  constructor(
    focusItem: FocusableItem,
    offset: number,
    preventDefaultDelegate?: () => void,
    home?: boolean,
    end?: boolean,
    upDown?: boolean,
  ) {
    this.focusItem = focusItem;
    (this.offset = offset),
      (this.preventDefaultDelegate = preventDefaultDelegate);
    this.home = home ?? false;
    this.end = end ?? false;
    this.upDown = upDown ?? false;
  }

  static homeKey(
    focusItem: FocusableItem,
    preventDefaultDelegate?: () => void,
  ): FocusMoveEvent {
    return new FocusMoveEvent(focusItem, 0, preventDefaultDelegate, true);
  }

  static endKey(
    focusItem: FocusableItem,
    preventDefaultDelegate?: () => void,
  ): FocusMoveEvent {
    return new FocusMoveEvent(
      focusItem,
      0,
      preventDefaultDelegate,
      false,
      true,
    );
  }

  static upDownKey(
    focusItem: FocusableItem,
    offset: number,
    preventDefaultDelegate?: () => void,
  ): FocusMoveEvent {
    return new FocusMoveEvent(
      focusItem,
      offset,
      preventDefaultDelegate,
      false,
      false,
      true,
    );
  }

  static fromKeyboardEvent(
    item: FocusableItem,
    kbEvent: KeyboardEvent,
  ): FocusMoveEvent | undefined {
    const keyCode = kbEvent.key;

    const preventDefaultFn = (): void => kbEvent.preventDefault();

    if (isHomeKey(keyCode)) {
      return FocusMoveEvent.homeKey(item, preventDefaultFn);
    }
    if (isEndKey(keyCode)) {
      return FocusMoveEvent.endKey(item, preventDefaultFn);
    }
    if (!isNextKey(keyCode) && !isPrevKey(keyCode)) {
      return undefined;
    }

    const offset = isNextKey(keyCode) ? 1 : -1;
    if (isUpKey(keyCode) || isDownKey(keyCode)) {
      return FocusMoveEvent.upDownKey(item, offset, preventDefaultFn);
    }

    return new FocusMoveEvent(item, offset, preventDefaultFn);
  }

  /// Prevent Default action for occuring. When the `FocusMoveEvent` is created
  /// from a KeyboardEvent, this method delegates to the `preventDefault` method
  /// of the `KeyboardEvent`, allowing consumers of this event to control the
  /// underlying DOM event.
  preventDefault(): void {
    if (this.preventDefaultDelegate) {
      this.preventDefaultDelegate();
    }
  }
}

/// A component or directive that can be programmatically focused.
///
/// Directive can manage if it means to put focus on root of itself
/// or meaningful component inside.
export abstract class Focusable {
  /// Item/component focuses itself
  abstract focus(): void;
}

@Injectable()
export abstract class FocusableComponent extends Focusable {}

/// A focusable component that can publish to the
/// `focusmove` stream in order to move focus to another element in the list.
export abstract class FocusableItem extends Focusable {
  /// Moves focus item into (tabIndex='0') or out of (tabIndex='-1') tab order.
  abstract set tabbable(value: boolean);

  /// The item publishes to this stream in order to move focus to another item.
  abstract get focusmove$(): Observable<FocusMoveEvent>;
}

/// `FocusItemDirective`, used in conjunction with [FocusListDirective],
/// provides a means to move focus between a list of components (or elements)
/// by way of keyboard interaction.
@Directive({
  selector: '[focusItem]',
  providers: [
    {
      provide: FocusableItem,
      useExisting: FocusItemDirective,
    },
  ],
  standalone: true,
})
export class FocusItemDirective implements FocusableItem, OnDestroy {
  private readonly element: ElementRef;
  private readonly changeDetectorRef: ChangeDetectorRef;
  private readonly focusMoveEmitter = new EventEmitter<FocusMoveEvent>();

  @HostBinding('attr.role')
  role: string;

  @HostBinding('attr.tabindex')
  tabIndex = '0';

  constructor(
    element: ElementRef,
    changeDetectorRef: ChangeDetectorRef,
    @Attribute('role') role?: string,
  ) {
    this.element = element;
    this.role = role ?? 'listitem';
    this.changeDetectorRef = changeDetectorRef;
  }

  ngOnDestroy(): void {
    this.focusMoveEmitter.complete();
  }

  set tabbable(value: boolean) {
    this.tabIndex = value ? '0' : '-1';
    this.changeDetectorRef.markForCheck();
  }

  get focusmove$(): Observable<FocusMoveEvent> {
    return this.focusMoveEmitter;
  }

  focus(): void {
    focusElement(this.element.nativeElement);
  }

  @HostListener('keydown', ['$event'])
  keydown(event: KeyboardEvent): void {
    const focusEvent = FocusMoveEvent.fromKeyboardEvent(this, event);
    if (focusEvent) {
      this.focusMoveEmitter.emit(focusEvent);
    }
  }
}

/// Used in conjunction with [FocusItemDirective] or
/// other directive implementing [FocusableItem], to provide a means to move
/// focus between a list of components (or elements) by way of keyboard
/// interaction.
///
/// The arrow keys move focus forward and backward in the list.
///
/// Tab order: Focus list represents single interactible element in tab order,
/// tab will land to the first element in the list by default
/// and then will move out of the list.
///
/// If user moved focus in the list, then tabs out and then tabs in back again
/// the last focused element in the list will be focused.
///
/// This leads to better navigation both in and between the lists.
@Directive({
  selector: '[focusList]',
  standalone: true,
})
export class FocusListDirective implements OnDestroy {
  private readonly ngZone: NgZone;

  @HostBinding('attr.role')
  readonly role: string;

  @HostBinding('attr.ignoreUpAndDown')
  readonly ignoreUpAndDown: boolean;

  readonly disposer = new Disposer();

  constructor(
    ngZone: NgZone,
    @Attribute('role') role?: string,
    @Attribute('ignoreUpAndDown') ignoreUpAndDown?: string,
  ) {
    this.ngZone = ngZone;
    this.role = role ?? 'list';
    this.ignoreUpAndDown = attributeToBool(ignoreUpAndDown);
  }

  private children: FocusableItem[] = [];
  get length(): number {
    return this.children.length;
  }

  /// Whether focus movement loops from the end of the list to the beginning of
  /// the list. Default is `false`.
  @Input()
  loop = false;

  /// Index of the element to focus on when the list appears.
  ///
  /// If null, focus will not be changed automatically.
  @Input()
  autoFocusIndex?: number;

  private moveFocus(event: FocusMoveEvent): void {
    if (event.home) {
      this.focus(0);
    } else if (event.end) {
      this.focus(this.length - 1);
    } else if (!this.ignoreUpAndDown || !event.upDown) {
      const i = this.children.indexOf(event.focusItem);
      if (i !== -1) {
        this.focus(i + event.offset);
      }
    }
    event.preventDefault();
  }

  focus(index: number): void {
    if (this.length === 0) {
      return;
    }
    let newIndex: number;
    if (this.loop) {
      newIndex = index % this.length;
    } else {
      newIndex = Numbers.clamp(index, 0, this.length - 1);
    }
    this.children[newIndex].focus();
    this.setTabbable(newIndex);
  }

  /// Makes the [index] tab focusable and makes all other tabs unfocusable.
  setTabbable(index: number): void {
    if (index < 0 || index >= this.length) {
      return;
    }
    this.children.forEach((i) => {
      i.tabbable = false;
    });
    this.children[index].tabbable = true;
  }

  @ContentChildren(FocusableItem)
  set listItems(listItems: FocusableItem[]) {
    this.children = [];
    this.disposer.dispose();
    listItems.forEach((i) => {
      this.children.push(i);
      this.disposer.addStreamSubscription(
        i.focusmove$.subscribe(this.moveFocus.bind(this)),
      );
    });

    // Since this is updating children that were already dirty-checked,
    // need to delay this change until next angular cycle.
    this.ngZone.runTask(() => {
      this.children.forEach((c) => {
        c.tabbable = false;
      });
      if (this.children.length > 0) {
        if (this.autoFocusIndex) {
          this.focus(this.autoFocusIndex); // This will also make the item tabbable.
        } else {
          this.children[0].tabbable = true;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.disposer.dispose();
  }
}

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/audience.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Network } from "../../common/proto/common_pb";
import { AgesReport, AudienceReport, GeographyReports } from "../../common/proto/user_pb";
import { MetaIdPb } from "../../common/proto/creator_pb";

/**
 * @generated from enum librarian.proto.ReportState
 */
export enum ReportState {
  /**
   * @generated from enum value: REPORT_STATE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: REPORT_STATE_REQUESTED = 1;
   */
  REQUESTED = 1,

  /**
   * @generated from enum value: REPORT_STATE_BEING_WORKED_ON = 2;
   */
  BEING_WORKED_ON = 2,

  /**
   * @generated from enum value: REPORT_STATE_SENT_TO_TORTUGA = 7;
   */
  SENT_TO_TORTUGA = 7,

  /**
   * @generated from enum value: REPORT_STATE_DONE = 3;
   */
  DONE = 3,

  /**
   * This report has been deduped after another report successfully generated less than 3 months prior to the request.
   *
   * @generated from enum value: REPORT_STATE_FROM_CACHE = 8;
   */
  FROM_CACHE = 8,

  /**
   * @generated from enum value: REPORT_STATE_CANCELLED = 4;
   */
  CANCELLED = 4,

  /**
   * This is a report that was done and we did notice that there was a newer one
   * overriding it.
   *
   * @generated from enum value: REPORT_STATE_DONE_OVERRIDDEN = 5;
   */
  DONE_OVERRIDDEN = 5,

  /**
   * Report that is not available. We record it so we don't retry over and over again but
   * we won't count it towards usage.
   *
   * @generated from enum value: REPORT_STATE_DONE_UNAVAILABLE = 6;
   */
  DONE_UNAVAILABLE = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(ReportState)
proto3.util.setEnumType(ReportState, "librarian.proto.ReportState", [
  { no: 0, name: "REPORT_STATE_UNKNOWN" },
  { no: 1, name: "REPORT_STATE_REQUESTED" },
  { no: 2, name: "REPORT_STATE_BEING_WORKED_ON" },
  { no: 7, name: "REPORT_STATE_SENT_TO_TORTUGA" },
  { no: 3, name: "REPORT_STATE_DONE" },
  { no: 8, name: "REPORT_STATE_FROM_CACHE" },
  { no: 4, name: "REPORT_STATE_CANCELLED" },
  { no: 5, name: "REPORT_STATE_DONE_OVERRIDDEN" },
  { no: 6, name: "REPORT_STATE_DONE_UNAVAILABLE" },
]);

/**
 * @generated from enum librarian.proto.ReportAudienceDataType
 */
export enum ReportAudienceDataType {
  /**
   * @generated from enum value: AUDIENCE_DEFAULT = 0;
   */
  AUDIENCE_DEFAULT = 0,

  /**
   * @generated from enum value: AUDIENCE_LIKERS = 1;
   */
  AUDIENCE_LIKERS = 1,

  /**
   * @generated from enum value: AUDIENCE_FOLLOWERS = 2;
   */
  AUDIENCE_FOLLOWERS = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(ReportAudienceDataType)
proto3.util.setEnumType(ReportAudienceDataType, "librarian.proto.ReportAudienceDataType", [
  { no: 0, name: "AUDIENCE_DEFAULT" },
  { no: 1, name: "AUDIENCE_LIKERS" },
  { no: 2, name: "AUDIENCE_FOLLOWERS" },
]);

/**
 * @generated from message librarian.proto.AudienceReportRequest
 */
export class AudienceReportRequest extends Message<AudienceReportRequest> {
  /**
   * Id of the user we're requesting the report for
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: common.proto.Network network = 2;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * Request report based on likers or followers (default: it will return by likers if available, else by followers)
   *
   * @generated from field: librarian.proto.ReportAudienceDataType report_data_type = 3;
   */
  reportDataType = ReportAudienceDataType.AUDIENCE_DEFAULT;

  constructor(data?: PartialMessage<AudienceReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AudienceReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 3, name: "report_data_type", kind: "enum", T: proto3.getEnumType(ReportAudienceDataType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AudienceReportRequest {
    return new AudienceReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AudienceReportRequest {
    return new AudienceReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AudienceReportRequest {
    return new AudienceReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AudienceReportRequest | PlainMessage<AudienceReportRequest> | undefined, b: AudienceReportRequest | PlainMessage<AudienceReportRequest> | undefined): boolean {
    return proto3.util.equals(AudienceReportRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.AudienceReportResponse
 */
export class AudienceReportResponse extends Message<AudienceReportResponse> {
  /**
   * @generated from field: librarian.proto.AudienceReportResponse.AudienceReportResponseType type = 9;
   */
  type = AudienceReportResponse_AudienceReportResponseType.UNDEFINED;

  /**
   * @generated from field: common.proto.AudienceReport audience_report = 1;
   */
  audienceReport?: AudienceReport;

  /**
   * The report is done but unfortunately we don't have data and there is no point in retrying (that creator
   * is not known to deep social, most likely because they have too little followers).
   * If true, other fields are not set / meaningless.
   *
   * @generated from field: bool is_unavailable = 8;
   */
  isUnavailable = false;

  /**
   * True if the report is of type hype auditor, but the creator could be invited in order to get
   * better stats. If false, there is no point in inviting the user to have a invite button.
   *
   * @generated from field: bool could_invite = 10;
   */
  couldInvite = false;

  /**
   * If it's from facebook, last time it was shared. Else from outsourced report, last time it was computed.
   *
   * @generated from field: google.protobuf.Timestamp computed = 11;
   */
  computed?: Timestamp;

  /**
   * If set, BRONCO handle for the report currently being computed or recomputed in queue.
   *
   * @generated from field: string bronco_handle = 12;
   */
  broncoHandle = "";

  /**
   * Field to indicate if the report is by likers or followers
   *
   * @generated from field: librarian.proto.ReportAudienceDataType report_data_type = 13;
   */
  reportDataType = ReportAudienceDataType.AUDIENCE_DEFAULT;

  constructor(data?: PartialMessage<AudienceReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AudienceReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 9, name: "type", kind: "enum", T: proto3.getEnumType(AudienceReportResponse_AudienceReportResponseType) },
    { no: 1, name: "audience_report", kind: "message", T: AudienceReport },
    { no: 8, name: "is_unavailable", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "could_invite", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "computed", kind: "message", T: Timestamp },
    { no: 12, name: "bronco_handle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "report_data_type", kind: "enum", T: proto3.getEnumType(ReportAudienceDataType) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AudienceReportResponse {
    return new AudienceReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AudienceReportResponse {
    return new AudienceReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AudienceReportResponse {
    return new AudienceReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AudienceReportResponse | PlainMessage<AudienceReportResponse> | undefined, b: AudienceReportResponse | PlainMessage<AudienceReportResponse> | undefined): boolean {
    return proto3.util.equals(AudienceReportResponse, a, b);
  }
}

/**
 * @generated from enum librarian.proto.AudienceReportResponse.AudienceReportResponseType
 */
export enum AudienceReportResponse_AudienceReportResponseType {
  /**
   * never use this.
   *
   * @generated from enum value: UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * stats are not shared and no statistical report was asked yet.
   *
   * @generated from enum value: NONE = 1;
   */
  NONE = 1,

  /**
   * Facebook insights are shared from the creator that logged in on lefty and accepted to share.
   *
   * @generated from enum value: FACEBOOK_INSIGHTS_SHARED = 2;
   */
  FACEBOOK_INSIGHTS_SHARED = 2,

  /**
   * statistical estimates report (eg: HypeAuditor) has been requested but not done yet.
   *
   * @generated from enum value: STATISTICAL_REPORT_REQUESTED = 3;
   */
  STATISTICAL_REPORT_REQUESTED = 3,

  /**
   * statistical estimates report done.
   *
   * @generated from enum value: STATISTICAL_REPORT_DONE = 4;
   */
  STATISTICAL_REPORT_DONE = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(AudienceReportResponse_AudienceReportResponseType)
proto3.util.setEnumType(AudienceReportResponse_AudienceReportResponseType, "librarian.proto.AudienceReportResponse.AudienceReportResponseType", [
  { no: 0, name: "UNDEFINED" },
  { no: 1, name: "NONE" },
  { no: 2, name: "FACEBOOK_INSIGHTS_SHARED" },
  { no: 3, name: "STATISTICAL_REPORT_REQUESTED" },
  { no: 4, name: "STATISTICAL_REPORT_DONE" },
]);

/**
 * @generated from message librarian.proto.StartAudienceReportRequest
 */
export class StartAudienceReportRequest extends Message<StartAudienceReportRequest> {
  /**
   * TODO aly: this field should be renamed to influencer id using proper proto field deletion mechanism
   *          See this link for explanation https://protobuf.dev/programming-guides/proto3/#deleting
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * Force refresh to have a brand new report
   *
   * @generated from field: bool force_refresh = 2;
   */
  forceRefresh = false;

  /**
   * @generated from field: common.proto.Network network = 3;
   */
  network = Network.NETWORK_UNKNOWN;

  constructor(data?: PartialMessage<StartAudienceReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.StartAudienceReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "force_refresh", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartAudienceReportRequest {
    return new StartAudienceReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartAudienceReportRequest {
    return new StartAudienceReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartAudienceReportRequest {
    return new StartAudienceReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: StartAudienceReportRequest | PlainMessage<StartAudienceReportRequest> | undefined, b: StartAudienceReportRequest | PlainMessage<StartAudienceReportRequest> | undefined): boolean {
    return proto3.util.equals(StartAudienceReportRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.StartAudienceReportResponse
 */
export class StartAudienceReportResponse extends Message<StartAudienceReportResponse> {
  /**
   * @generated from field: string task_token = 3;
   */
  taskToken = "";

  constructor(data?: PartialMessage<StartAudienceReportResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.StartAudienceReportResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 3, name: "task_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StartAudienceReportResponse {
    return new StartAudienceReportResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StartAudienceReportResponse {
    return new StartAudienceReportResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StartAudienceReportResponse {
    return new StartAudienceReportResponse().fromJsonString(jsonString, options);
  }

  static equals(a: StartAudienceReportResponse | PlainMessage<StartAudienceReportResponse> | undefined, b: StartAudienceReportResponse | PlainMessage<StartAudienceReportResponse> | undefined): boolean {
    return proto3.util.equals(StartAudienceReportResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.RetrieveAudienceRequest
 */
export class RetrieveAudienceRequest extends Message<RetrieveAudienceRequest> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * This id also allows us to determine the network being requested so it is always the id of the network that is being
   * generated (for instance a merged INSTA-TIKTOKER will have their 'tk:' id here when a tiktok report is requested).
   *
   * @generated from field: common.proto.MetaIdPb influencer_network_id = 3;
   */
  influencerNetworkId?: MetaIdPb;

  /**
   * @generated from oneof librarian.proto.RetrieveAudienceRequest.source
   */
  source: {
    /**
     * @generated from field: int64 campaign_id = 4;
     */
    value: bigint;
    case: "campaignId";
  } | {
    /**
     * @generated from field: int64 casting_id = 5;
     */
    value: bigint;
    case: "castingId";
  } | {
    /**
     * @generated from field: bool manual = 6;
     */
    value: boolean;
    case: "manual";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * Whether it's a request to refresh an outdated report or not
   *
   * @generated from field: bool is_refresh = 7;
   */
  isRefresh = false;

  constructor(data?: PartialMessage<RetrieveAudienceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RetrieveAudienceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "influencer_network_id", kind: "message", T: MetaIdPb },
    { no: 4, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "source" },
    { no: 5, name: "casting_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "source" },
    { no: 6, name: "manual", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "source" },
    { no: 7, name: "is_refresh", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RetrieveAudienceRequest {
    return new RetrieveAudienceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RetrieveAudienceRequest {
    return new RetrieveAudienceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RetrieveAudienceRequest {
    return new RetrieveAudienceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RetrieveAudienceRequest | PlainMessage<RetrieveAudienceRequest> | undefined, b: RetrieveAudienceRequest | PlainMessage<RetrieveAudienceRequest> | undefined): boolean {
    return proto3.util.equals(RetrieveAudienceRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetAudienceAsFileRequest
 */
export class GetAudienceAsFileRequest extends Message<GetAudienceAsFileRequest> {
  /**
   * @generated from field: string creator_id = 1;
   */
  creatorId = "";

  /**
   * Id of the team owner.
   *
   * @generated from field: int64 customer_id = 2;
   */
  customerId = protoInt64.zero;

  /**
   * @generated from field: common.proto.Network network = 3;
   */
  network = Network.NETWORK_UNKNOWN;

  constructor(data?: PartialMessage<GetAudienceAsFileRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetAudienceAsFileRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "customer_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetAudienceAsFileRequest {
    return new GetAudienceAsFileRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetAudienceAsFileRequest {
    return new GetAudienceAsFileRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetAudienceAsFileRequest {
    return new GetAudienceAsFileRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetAudienceAsFileRequest | PlainMessage<GetAudienceAsFileRequest> | undefined, b: GetAudienceAsFileRequest | PlainMessage<GetAudienceAsFileRequest> | undefined): boolean {
    return proto3.util.equals(GetAudienceAsFileRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.GenerikAudienceData
 */
export class GenerikAudienceData extends Message<GenerikAudienceData> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * percentage of females in the audience.
   *
   * @generated from field: double female_percentage = 2;
   */
  femalePercentage = 0;

  /**
   * Top countries in the audience
   *
   * Note(hadrien) GeographyReport.name == countryCode
   * Already the case for Influencer audience report
   * maybe we should have a dedicated proto
   *
   * @generated from field: common.proto.GeographyReports countries = 3;
   */
  countries?: GeographyReports;

  /**
   * Distribution of ages in the female audience.
   *
   * @generated from field: common.proto.AgesReport ages_report_female = 4;
   */
  agesReportFemale?: AgesReport;

  /**
   * Distribution of ages in the male audience.
   *
   * @generated from field: common.proto.AgesReport ages_report_male = 5;
   */
  agesReportMale?: AgesReport;

  constructor(data?: PartialMessage<GenerikAudienceData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GenerikAudienceData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "female_percentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "countries", kind: "message", T: GeographyReports },
    { no: 4, name: "ages_report_female", kind: "message", T: AgesReport },
    { no: 5, name: "ages_report_male", kind: "message", T: AgesReport },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerikAudienceData {
    return new GenerikAudienceData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerikAudienceData {
    return new GenerikAudienceData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerikAudienceData {
    return new GenerikAudienceData().fromJsonString(jsonString, options);
  }

  static equals(a: GenerikAudienceData | PlainMessage<GenerikAudienceData> | undefined, b: GenerikAudienceData | PlainMessage<GenerikAudienceData> | undefined): boolean {
    return proto3.util.equals(GenerikAudienceData, a, b);
  }
}


import { InjectionToken, inject } from '@angular/core';
import { PromiseClient, createPromiseClient } from '@connectrpc/connect';
import { LibrarianCdnAssets } from '@frontend2/proto/librarian/proto/cdn_assets_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianCdnAssetsClient = PromiseClient<typeof LibrarianCdnAssets>;

const factory = (): LibrarianCdnAssetsClient => {
  return createPromiseClient(LibrarianCdnAssets, inject(GRPC_TRANSPORT));
};

const token = new InjectionToken('LibrarianCdnAssetClient', { factory });

export function injectLibrarianCdnAssetsClient(): LibrarianCdnAssetsClient {
  return inject(token);
}

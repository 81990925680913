import { isNil } from '@frontend2/core';

export type ItemRenderer<T> = (item: T) => string;

export const defaultItemRenderer = (value: unknown): string => `${value}`;

export class SelectionModel<T> {
  constructor(
    public readonly isMulti: boolean,
    public readonly selection: T[],
  ) {}

  static single<T>(selection?: T): SelectionModel<T> {
    return new SelectionModel<T>(
      false,
      isNil(selection) === false ? [selection as T] : [],
    );
  }

  static multi<T>(selections?: T[]): SelectionModel<T> {
    return new SelectionModel<T>(true, selections ?? []);
  }

  get isEmpty(): boolean {
    return this.selection.length === 0;
  }

  get isNotEmpty(): boolean {
    return this.isEmpty === false;
  }

  isSelected(item: T): boolean {
    return this.selection.indexOf(item) !== -1;
  }

  select(item: T): SelectionModel<T> {
    if (this.isSelected(item)) {
      return this;
    }

    if (this.isMulti) {
      return new SelectionModel<T>(this.isMulti, [...this.selection, item]);
    } else {
      return new SelectionModel<T>(this.isMulti, [item]);
    }
  }

  selectAll(items: T[]): SelectionModel<T> {
    if (this.isMulti === false) {
      return this;
    }

    const newValues = new Set<T>([...this.selection, ...items]);
    return new SelectionModel<T>(this.isMulti, [...newValues]);
  }

  deselect(item: T): SelectionModel<T> {
    if (this.isSelected(item) === false) {
      return this;
    }

    if (this.isMulti) {
      return new SelectionModel<T>(
        this.isMulti,
        this.selection.filter((i) => i !== item),
      );
    } else {
      return new SelectionModel<T>(this.isMulti, []);
    }
  }

  clear(): SelectionModel<T> {
    return new SelectionModel<T>(this.isMulti, []);
  }
}

export type isDisabledCheck<T> = (item: T) => boolean;

export const defaultIsDisabledCheck: isDisabledCheck<unknown> = (): boolean =>
  false;

import { isNil } from '@frontend2/core';
import { AgesReport } from '@frontend2/proto/common/proto/user_pb';
import { DataSet } from '../charts/charts.models';
export interface ChartWithDataSet {
  readonly type: 'age_range';
  readonly title: string;
  readonly data: DataSet[];
}

export function createAgeBarDateSet(
  ageRepMale?: AgesReport,
  ageRepFemale?: AgesReport,
): DataSet[] | undefined {
  if (isNil(ageRepMale) || isNil(ageRepFemale)) {
    return;
  }
  const data: DataSet[] = [];
  for (const agebucket of ageRepMale.ageBucket) {
    data.push({
      name: `${agebucket.low} - ${agebucket.high}`,
      values: [agebucket.value],
    });
  }
  for (const agebucket of ageRepFemale.ageBucket) {
    const index = data
      .find((d) => d.name === `${agebucket.low} - ${agebucket.high}`)
      ?.values.push(agebucket.value);
    if (isNil(index)) {
      data.push({
        name: `${agebucket.low} - ${agebucket.high}`,
        values: [agebucket.value],
      });
    }
  }
  const lastBucket = data.pop();
  data.push({
    name: lastBucket?.name.split('-')[0] + '+',
    values: lastBucket?.values ?? [],
  });
  return data;
}

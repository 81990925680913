<ng-template [ngIf]="posts.length > 0">
  <div
    class="image-style-container"
    *ngIf="isImage"
  >
    <lefty-post-media-or-caption
      *ngFor="let post of posts; trackBy: trackBy"
      [asThumbnail]="true"
      [post]="post"
      [imageOnly]="true"
    >
    </lefty-post-media-or-caption>
  </div>

  <div
    class="story-style-container"
    *ngIf="isStory"
  >
    <lefty-post-media
      *ngFor="let post of posts; trackBy: trackBy"
      [asThumbnail]="true"
      [urls]="post.urls"
      [imageOnly]="true"
    >
    </lefty-post-media>
  </div>

  <div
    class="text-style-container al-card with-border"
    *ngIf="isText"
  >
    <div class="text-container">
      <div class="published-date">
        {{ formatePublishedDate(posts[0].publishedDate) }}
      </div>
      <p
        class="caption"
        [innerHTML]="posts[0].caption"
      ></p>
    </div>

    <lefty-post-media
      [asThumbnail]="true"
      [urls]="posts[0].urls"
      [imageOnly]="true"
    >
    </lefty-post-media>
  </div>

  <div
    class="youtube-style-container"
    *ngIf="isYoutube"
  >
    <lefty-post-media
      *ngFor="let post of posts; trackBy: trackBy"
      [asThumbnail]="true"
      [urls]="post.urls"
      [imageOnly]="true"
    >
    </lefty-post-media>
  </div>

  <a
    target="_blank"
    class="underline"
    [href]="openInNetworkLink"
  >
    {{ openInNetworkMsg }}
  </a>
</ng-template>

// This file defines protocol buffers for wrapping the youtube API.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/youtube.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Duration, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum common.proto.PlaylistItemKind
 */
export enum PlaylistItemKind {
  /**
   * @generated from enum value: PLAYLIST_ITEM_KIND_UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: PLAYLIST_ITEM_KIND_VIDEO = 1;
   */
  VIDEO = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(PlaylistItemKind)
proto3.util.setEnumType(PlaylistItemKind, "common.proto.PlaylistItemKind", [
  { no: 0, name: "PLAYLIST_ITEM_KIND_UNDEFINED" },
  { no: 1, name: "PLAYLIST_ITEM_KIND_VIDEO" },
]);

/**
 * @generated from enum common.proto.PrivacyStatus
 */
export enum PrivacyStatus {
  /**
   * @generated from enum value: YOUTUBE_PRIVACY_STATUS_UNDEFINED = 0;
   */
  YOUTUBE_PRIVACY_STATUS_UNDEFINED = 0,

  /**
   * @generated from enum value: YOUTUBE_PRIVACY_STATUS_PUBLIC = 1;
   */
  YOUTUBE_PRIVACY_STATUS_PUBLIC = 1,

  /**
   * @generated from enum value: YOUTUBE_PRIVACY_STATUS_PRIVATE = 2;
   */
  YOUTUBE_PRIVACY_STATUS_PRIVATE = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(PrivacyStatus)
proto3.util.setEnumType(PrivacyStatus, "common.proto.PrivacyStatus", [
  { no: 0, name: "YOUTUBE_PRIVACY_STATUS_UNDEFINED" },
  { no: 1, name: "YOUTUBE_PRIVACY_STATUS_PUBLIC" },
  { no: 2, name: "YOUTUBE_PRIVACY_STATUS_PRIVATE" },
]);

/**
 * Wraps the result of an oauth token exchange as described here.
 * https://developers.google.com/youtube/v3/guides/auth/server-side-web-apps
 *
 * We don't wrap 'token_type' since it is always 'Bearer' and is useless at this point.
 *
 * @generated from message common.proto.OauthResponse
 */
export class OauthResponse extends Message<OauthResponse> {
  /**
   * @generated from field: string access_token = 1;
   */
  accessToken = "";

  /**
   * @generated from field: google.protobuf.Timestamp expiration_date = 2;
   */
  expirationDate?: Timestamp;

  /**
   * @generated from field: string refresh_token = 3;
   */
  refreshToken = "";

  /**
   * @generated from field: string google_id = 4;
   */
  googleId = "";

  /**
   * @generated from field: string email = 5;
   */
  email = "";

  constructor(data?: PartialMessage<OauthResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.OauthResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "access_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "expiration_date", kind: "message", T: Timestamp },
    { no: 3, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "google_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OauthResponse {
    return new OauthResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OauthResponse {
    return new OauthResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OauthResponse {
    return new OauthResponse().fromJsonString(jsonString, options);
  }

  static equals(a: OauthResponse | PlainMessage<OauthResponse> | undefined, b: OauthResponse | PlainMessage<OauthResponse> | undefined): boolean {
    return proto3.util.equals(OauthResponse, a, b);
  }
}

/**
 * @generated from message common.proto.YoutubeThumbnail
 */
export class YoutubeThumbnail extends Message<YoutubeThumbnail> {
  /**
   * @generated from field: common.proto.YoutubeThumbnail.Resolution res = 1;
   */
  res = YoutubeThumbnail_Resolution.RESOLUTION_UNDEFINED;

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * @generated from field: int32 width = 3;
   */
  width = 0;

  /**
   * @generated from field: int32 height = 4;
   */
  height = 0;

  constructor(data?: PartialMessage<YoutubeThumbnail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.YoutubeThumbnail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "res", kind: "enum", T: proto3.getEnumType(YoutubeThumbnail_Resolution) },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): YoutubeThumbnail {
    return new YoutubeThumbnail().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): YoutubeThumbnail {
    return new YoutubeThumbnail().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): YoutubeThumbnail {
    return new YoutubeThumbnail().fromJsonString(jsonString, options);
  }

  static equals(a: YoutubeThumbnail | PlainMessage<YoutubeThumbnail> | undefined, b: YoutubeThumbnail | PlainMessage<YoutubeThumbnail> | undefined): boolean {
    return proto3.util.equals(YoutubeThumbnail, a, b);
  }
}

/**
 * @generated from enum common.proto.YoutubeThumbnail.Resolution
 */
export enum YoutubeThumbnail_Resolution {
  /**
   * @generated from enum value: RESOLUTION_UNDEFINED = 0;
   */
  RESOLUTION_UNDEFINED = 0,

  /**
   * @generated from enum value: HIGH = 1;
   */
  HIGH = 1,

  /**
   * @generated from enum value: MEDIUM = 2;
   */
  MEDIUM = 2,

  /**
   * @generated from enum value: DEFAULT = 3;
   */
  DEFAULT = 3,

  /**
   * @generated from enum value: STANDARD = 4;
   */
  STANDARD = 4,

  /**
   * @generated from enum value: MAX = 5;
   */
  MAX = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(YoutubeThumbnail_Resolution)
proto3.util.setEnumType(YoutubeThumbnail_Resolution, "common.proto.YoutubeThumbnail.Resolution", [
  { no: 0, name: "RESOLUTION_UNDEFINED" },
  { no: 1, name: "HIGH" },
  { no: 2, name: "MEDIUM" },
  { no: 3, name: "DEFAULT" },
  { no: 4, name: "STANDARD" },
  { no: 5, name: "MAX" },
]);

/**
 * @generated from message common.proto.YoutubeSnippet
 */
export class YoutubeSnippet extends Message<YoutubeSnippet> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: google.protobuf.Timestamp published_at = 3;
   */
  publishedAt?: Timestamp;

  /**
   * @generated from field: repeated common.proto.YoutubeThumbnail thumbnails = 4;
   */
  thumbnails: YoutubeThumbnail[] = [];

  constructor(data?: PartialMessage<YoutubeSnippet>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.YoutubeSnippet";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "published_at", kind: "message", T: Timestamp },
    { no: 4, name: "thumbnails", kind: "message", T: YoutubeThumbnail, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): YoutubeSnippet {
    return new YoutubeSnippet().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): YoutubeSnippet {
    return new YoutubeSnippet().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): YoutubeSnippet {
    return new YoutubeSnippet().fromJsonString(jsonString, options);
  }

  static equals(a: YoutubeSnippet | PlainMessage<YoutubeSnippet> | undefined, b: YoutubeSnippet | PlainMessage<YoutubeSnippet> | undefined): boolean {
    return proto3.util.equals(YoutubeSnippet, a, b);
  }
}

/**
 * @generated from message common.proto.ChannelStats
 */
export class ChannelStats extends Message<ChannelStats> {
  /**
   * @generated from field: int64 view_count = 1;
   */
  viewCount = protoInt64.zero;

  /**
   * @generated from field: int64 comment_count = 2;
   */
  commentCount = protoInt64.zero;

  /**
   * @generated from field: int64 subscriber_count = 3;
   */
  subscriberCount = protoInt64.zero;

  /**
   * @generated from field: bool hidden_subscriber_count = 4;
   */
  hiddenSubscriberCount = false;

  /**
   * @generated from field: int32 video_count = 5;
   */
  videoCount = 0;

  constructor(data?: PartialMessage<ChannelStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ChannelStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "view_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "comment_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "subscriber_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "hidden_subscriber_count", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "video_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChannelStats {
    return new ChannelStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChannelStats {
    return new ChannelStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChannelStats {
    return new ChannelStats().fromJsonString(jsonString, options);
  }

  static equals(a: ChannelStats | PlainMessage<ChannelStats> | undefined, b: ChannelStats | PlainMessage<ChannelStats> | undefined): boolean {
    return proto3.util.equals(ChannelStats, a, b);
  }
}

/**
 * @generated from message common.proto.YoutubeChannel
 */
export class YoutubeChannel extends Message<YoutubeChannel> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Id of the uploads playlist. This is very important as it allows to retrieve videos.
   *
   * @generated from field: google.protobuf.StringValue uploads_id = 2;
   */
  uploadsId?: string;

  /**
   * @generated from field: common.proto.YoutubeSnippet snippet = 3;
   */
  snippet?: YoutubeSnippet;

  /**
   * @generated from field: common.proto.ChannelStats stats = 4;
   */
  stats?: ChannelStats;

  constructor(data?: PartialMessage<YoutubeChannel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.YoutubeChannel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "uploads_id", kind: "message", T: StringValue },
    { no: 3, name: "snippet", kind: "message", T: YoutubeSnippet },
    { no: 4, name: "stats", kind: "message", T: ChannelStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): YoutubeChannel {
    return new YoutubeChannel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): YoutubeChannel {
    return new YoutubeChannel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): YoutubeChannel {
    return new YoutubeChannel().fromJsonString(jsonString, options);
  }

  static equals(a: YoutubeChannel | PlainMessage<YoutubeChannel> | undefined, b: YoutubeChannel | PlainMessage<YoutubeChannel> | undefined): boolean {
    return proto3.util.equals(YoutubeChannel, a, b);
  }
}

/**
 * @generated from message common.proto.PlaylistItem
 */
export class PlaylistItem extends Message<PlaylistItem> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string channel_id = 2;
   */
  channelId = "";

  /**
   * @generated from field: google.protobuf.Timestamp published_at = 3;
   */
  publishedAt?: Timestamp;

  /**
   * @generated from field: string title = 4;
   */
  title = "";

  /**
   * @generated from field: string description = 5;
   */
  description = "";

  /**
   * @generated from field: repeated common.proto.YoutubeThumbnail thumbnails = 6;
   */
  thumbnails: YoutubeThumbnail[] = [];

  /**
   * @generated from field: string playlist_id = 7;
   */
  playlistId = "";

  /**
   * @generated from field: common.proto.PlaylistItemKind kind = 8;
   */
  kind = PlaylistItemKind.UNDEFINED;

  /**
   * @generated from field: string video_id = 9;
   */
  videoId = "";

  /**
   * @generated from field: common.proto.PrivacyStatus privacy_status = 10;
   */
  privacyStatus = PrivacyStatus.YOUTUBE_PRIVACY_STATUS_UNDEFINED;

  constructor(data?: PartialMessage<PlaylistItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.PlaylistItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "channel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "published_at", kind: "message", T: Timestamp },
    { no: 4, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "thumbnails", kind: "message", T: YoutubeThumbnail, repeated: true },
    { no: 7, name: "playlist_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "kind", kind: "enum", T: proto3.getEnumType(PlaylistItemKind) },
    { no: 9, name: "video_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "privacy_status", kind: "enum", T: proto3.getEnumType(PrivacyStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PlaylistItem {
    return new PlaylistItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PlaylistItem {
    return new PlaylistItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PlaylistItem {
    return new PlaylistItem().fromJsonString(jsonString, options);
  }

  static equals(a: PlaylistItem | PlainMessage<PlaylistItem> | undefined, b: PlaylistItem | PlainMessage<PlaylistItem> | undefined): boolean {
    return proto3.util.equals(PlaylistItem, a, b);
  }
}

/**
 * @generated from message common.proto.PlaylistItems
 */
export class PlaylistItems extends Message<PlaylistItems> {
  /**
   * @generated from field: repeated common.proto.PlaylistItem items = 1;
   */
  items: PlaylistItem[] = [];

  /**
   * @generated from field: google.protobuf.StringValue next_page_token = 2;
   */
  nextPageToken?: string;

  /**
   * @generated from field: int32 total_items_count = 3;
   */
  totalItemsCount = 0;

  constructor(data?: PartialMessage<PlaylistItems>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.PlaylistItems";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: PlaylistItem, repeated: true },
    { no: 2, name: "next_page_token", kind: "message", T: StringValue },
    { no: 3, name: "total_items_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PlaylistItems {
    return new PlaylistItems().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PlaylistItems {
    return new PlaylistItems().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PlaylistItems {
    return new PlaylistItems().fromJsonString(jsonString, options);
  }

  static equals(a: PlaylistItems | PlainMessage<PlaylistItems> | undefined, b: PlaylistItems | PlainMessage<PlaylistItems> | undefined): boolean {
    return proto3.util.equals(PlaylistItems, a, b);
  }
}

/**
 * @generated from message common.proto.VideoContentDetails
 */
export class VideoContentDetails extends Message<VideoContentDetails> {
  /**
   * @generated from field: google.protobuf.Duration duration = 1;
   */
  duration?: Duration;

  /**
   * @generated from field: string dimension = 2;
   */
  dimension = "";

  /**
   * @generated from field: string definition = 3;
   */
  definition = "";

  /**
   * @generated from field: string caption = 4;
   */
  caption = "";

  /**
   * @generated from field: bool licensed_content = 5;
   */
  licensedContent = false;

  /**
   * @generated from field: string projection = 6;
   */
  projection = "";

  constructor(data?: PartialMessage<VideoContentDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.VideoContentDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "duration", kind: "message", T: Duration },
    { no: 2, name: "dimension", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "definition", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "caption", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "licensed_content", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "projection", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VideoContentDetails {
    return new VideoContentDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VideoContentDetails {
    return new VideoContentDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VideoContentDetails {
    return new VideoContentDetails().fromJsonString(jsonString, options);
  }

  static equals(a: VideoContentDetails | PlainMessage<VideoContentDetails> | undefined, b: VideoContentDetails | PlainMessage<VideoContentDetails> | undefined): boolean {
    return proto3.util.equals(VideoContentDetails, a, b);
  }
}

/**
 * @generated from message common.proto.VideoStats
 */
export class VideoStats extends Message<VideoStats> {
  /**
   * int32 breaks on despacito :)
   *
   * @generated from field: int64 view_count = 1;
   */
  viewCount = protoInt64.zero;

  /**
   * @generated from field: int32 like_count = 2;
   */
  likeCount = 0;

  /**
   * @generated from field: int32 dislike_count = 3;
   */
  dislikeCount = 0;

  /**
   * @generated from field: int32 favorite_count = 4;
   */
  favoriteCount = 0;

  /**
   * @generated from field: int32 comment_count = 5;
   */
  commentCount = 0;

  constructor(data?: PartialMessage<VideoStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.VideoStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "view_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "like_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "dislike_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "favorite_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "comment_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VideoStats {
    return new VideoStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VideoStats {
    return new VideoStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VideoStats {
    return new VideoStats().fromJsonString(jsonString, options);
  }

  static equals(a: VideoStats | PlainMessage<VideoStats> | undefined, b: VideoStats | PlainMessage<VideoStats> | undefined): boolean {
    return proto3.util.equals(VideoStats, a, b);
  }
}

/**
 * @generated from message common.proto.VideoMetadata
 */
export class VideoMetadata extends Message<VideoMetadata> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string channel_id = 2;
   */
  channelId = "";

  /**
   * @generated from field: google.protobuf.Timestamp published_at = 3;
   */
  publishedAt?: Timestamp;

  /**
   * @generated from field: string title = 4;
   */
  title = "";

  /**
   * @generated from field: string description = 5;
   */
  description = "";

  /**
   * @generated from field: repeated common.proto.YoutubeThumbnail thumbnails = 6;
   */
  thumbnails: YoutubeThumbnail[] = [];

  /**
   * @generated from field: repeated string tags = 7;
   */
  tags: string[] = [];

  /**
   * @generated from field: string category_id = 8;
   */
  categoryId = "";

  /**
   * @generated from field: string live_broadcast_content = 9;
   */
  liveBroadcastContent = "";

  /**
   * @generated from field: string default_language = 10;
   */
  defaultLanguage = "";

  /**
   * @generated from field: string default_audio_language = 11;
   */
  defaultAudioLanguage = "";

  /**
   * @generated from field: common.proto.VideoContentDetails content_details = 12;
   */
  contentDetails?: VideoContentDetails;

  /**
   * @generated from field: common.proto.VideoStats stats = 13;
   */
  stats?: VideoStats;

  constructor(data?: PartialMessage<VideoMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.VideoMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "channel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "published_at", kind: "message", T: Timestamp },
    { no: 4, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "thumbnails", kind: "message", T: YoutubeThumbnail, repeated: true },
    { no: 7, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "category_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "live_broadcast_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "default_language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "default_audio_language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "content_details", kind: "message", T: VideoContentDetails },
    { no: 13, name: "stats", kind: "message", T: VideoStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VideoMetadata {
    return new VideoMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VideoMetadata {
    return new VideoMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VideoMetadata {
    return new VideoMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: VideoMetadata | PlainMessage<VideoMetadata> | undefined, b: VideoMetadata | PlainMessage<VideoMetadata> | undefined): boolean {
    return proto3.util.equals(VideoMetadata, a, b);
  }
}

/**
 * @generated from message common.proto.VideoMetadatas
 */
export class VideoMetadatas extends Message<VideoMetadatas> {
  /**
   * @generated from field: repeated common.proto.VideoMetadata videos = 1;
   */
  videos: VideoMetadata[] = [];

  constructor(data?: PartialMessage<VideoMetadatas>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.VideoMetadatas";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "videos", kind: "message", T: VideoMetadata, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VideoMetadatas {
    return new VideoMetadatas().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VideoMetadatas {
    return new VideoMetadatas().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VideoMetadatas {
    return new VideoMetadatas().fromJsonString(jsonString, options);
  }

  static equals(a: VideoMetadatas | PlainMessage<VideoMetadatas> | undefined, b: VideoMetadatas | PlainMessage<VideoMetadatas> | undefined): boolean {
    return proto3.util.equals(VideoMetadatas, a, b);
  }
}


// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/cdn_assets.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { UploadAssetRequest } from "./cdn_assets_pb";
import { Int64Value, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service librarian.proto.LibrarianCdnAssets
 */
export const LibrarianCdnAssets = {
  typeName: "librarian.proto.LibrarianCdnAssets",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianCdnAssets.UploadAsset
     */
    uploadAsset: {
      name: "UploadAsset",
      I: UploadAssetRequest,
      O: Int64Value,
      kind: MethodKind.Unary,
    },
  }
} as const;


import { Timestamp } from '@bufbuild/protobuf';
import {
  formatDate,
  isEmptyArray,
  isEmptyString,
  isNil,
  isNotEmptyArray,
  isNotEmptyString,
  isNotNil,
} from '@frontend2/core';
import { addressFielProtoToReadableString } from '@frontend2/proto-helpers/librarian/creator_fields_pb.helpers';
import { StringList } from '@frontend2/proto/common/proto/common_pb';
import {
  CampaignFieldType,
  CreatorFieldType,
  EntityField,
} from '@frontend2/proto/librarian/proto/common_pb';
import { AddressFieldProto } from '@frontend2/proto/librarian/proto/creator_fields_pb';
import { EntityFieldValue } from '@frontend2/proto/librarian/proto/entity_fields_pb';
import { GoogleLocation } from '@frontend2/proto/librarian/proto/local_pb';
import { DEFAULT_GOOGLE_LOCATION_VALUE } from '../utils';

interface FieldItemInfo {
  readonly name: string;
  readonly icon: string;
}

export function createFieldItem(args?: Partial<FieldItemInfo>): FieldItemInfo {
  return {
    name: args?.name ?? '',
    icon: args?.icon ?? '',
  };
}

export const EMAIL_FIELD_ID = BigInt(25);
export const COMPLETE_ADDRESS_FIELD_ID = BigInt(1318);
const _countriesFieldId = BigInt(31);
const _notesFieldId = BigInt(29);
const _firstNameFieldId = BigInt(26);
const _lastNameFieldId = BigInt(28);
const _countryFieldId = BigInt(31);
const _phoneFieldId = BigInt(19);
const _websiteFieldId = BigInt(20);
const _instaFieldId = BigInt(14);
const _youtubeFieldId = BigInt(12);
const _twitterFieldId = BigInt(22);
const _facebookFieldId = BigInt(11);
const _snapchatFieldId = BigInt(17);
const _agentFirstNameFieldId = BigInt(13);
const _agentLastNameFieldId = BigInt(24);
const _agentEmailFieldId = BigInt(23);
const _agentPhoneFieldId = BigInt(18);
const _cityFieldId = BigInt(16);
const _addressFieldId = BigInt(21);
const _zipFieldId = BigInt(15);

const DEPRECATED_FIELD_IDS = [_cityFieldId, _addressFieldId, _zipFieldId];

const customFieldIcons: Map<bigint, FieldItemInfo> = new Map([
  [
    _firstNameFieldId,
    createFieldItem({
      name: $localize`first name`,
      icon: 'profile',
    }),
  ],
  [
    _lastNameFieldId,
    createFieldItem({ name: $localize`last name`, icon: 'profile' }),
  ],
  [
    EMAIL_FIELD_ID,
    createFieldItem({ name: $localize`email`, icon: 'at_sign' }),
  ],
  [
    _countryFieldId,
    createFieldItem({ name: $localize`country`, icon: 'globe' }),
  ],
  [_phoneFieldId, createFieldItem({ name: $localize`phone`, icon: 'phone' })],
  [
    _websiteFieldId,
    createFieldItem({ name: $localize`website`, icon: 'blog' }),
  ],
  [
    _instaFieldId,
    createFieldItem({ name: $localize`insta`, icon: 'instagram' }),
  ],
  [
    _youtubeFieldId,
    createFieldItem({ name: $localize`youtube`, icon: 'youtube' }),
  ],
  [
    _twitterFieldId,
    createFieldItem({ name: $localize`x (twitter)`, icon: 'x_twitter' }),
  ],
  [
    _facebookFieldId,
    createFieldItem({ name: $localize`facebook`, icon: 'facebook' }),
  ],
  [
    _snapchatFieldId,
    createFieldItem({ name: $localize`snapchat`, icon: 'snapchat' }),
  ],
  [
    _agentFirstNameFieldId,
    createFieldItem({
      name: $localize`agent first name`,
      icon: 'profile',
    }),
  ],
  [
    _agentLastNameFieldId,
    createFieldItem({
      name: $localize`agent last name`,
      icon: 'profile',
    }),
  ],
  [
    _agentEmailFieldId,
    createFieldItem({
      name: $localize`agent email`,
      icon: 'at_sign',
    }),
  ],
  [
    _agentPhoneFieldId,
    createFieldItem({ name: $localize`agent phone`, icon: 'phone' }),
  ],
  [
    COMPLETE_ADDRESS_FIELD_ID,
    createFieldItem({ name: $localize`address`, icon: 'location' }),
  ],
]);

export function isDeprecatedInfosField(id: bigint): boolean {
  return DEPRECATED_FIELD_IDS.includes(id);
}

export function getCustomFieldIcon(field: EntityField): string {
  if (field.default && isNotNil(field.entityFieldId)) {
    // handle default field Icon
    return customFieldIcons.get(field.entityFieldId)?.icon ?? '';
  }
  // only default field has icon
  return '';
}

export function getCustomFieldLabel(field: EntityField): string {
  if (field.default && isNotNil(field.entityFieldId)) {
    return customFieldIcons.get(field.entityFieldId)?.name ?? field.name;
  }
  return field.name;
}

export function getCustomFieldLabelById(id: bigint): string {
  return customFieldIcons.get(id)?.name ?? '';
}

export function isEmptyCustomFieldValue(
  field: EntityField,
  value: EntityFieldValue,
): boolean {
  const fieldValue = value.value;
  switch (fieldValue.case) {
    case 'textValue':
      return isNotEmptyString(fieldValue.value);
    case 'decimalValue':
    case 'numberValue':
    case 'boolValue':
    case 'dateValue':
    case 'addressValue':
      return isNotNil(fieldValue.value);
    case 'locationValue':
      return isNotEmptyString(fieldValue.value.locationName);
    case 'enumValue':
      return isNotEmptyArray(fieldValue.value.values);
    default:
      return false;
  }
}

export function isTextCustomField(field: EntityField): boolean {
  const type = field.fieldType;
  if (type.case === 'campaignFieldType') {
    return type.value === CampaignFieldType.TEXT;
  }
  if (type.case === 'creatorFieldType') {
    return type.value === CreatorFieldType.TEXT;
  }
  return false;
}

export function isListCustomField(field: EntityField): boolean {
  const type = field.fieldType;
  if (type.case === 'campaignFieldType') {
    return type.value === CampaignFieldType.LIST;
  }
  if (type.case === 'creatorFieldType') {
    return type.value === CreatorFieldType.LIST;
  }
  return false;
}

export function isNumberCustomField(field: EntityField): boolean {
  const type = field.fieldType;
  if (type.case === 'creatorFieldType') {
    return type.value === CreatorFieldType.NUMBER;
  }
  return false;
}

export function isDecimalNumberCustomField(field: EntityField): boolean {
  const type = field.fieldType;
  if (type.case === 'creatorFieldType') {
    return type.value === CreatorFieldType.DECIMAL_NUMBER;
  }
  return false;
}

export function isAddressCustomField(field: EntityField): boolean {
  const type = field.fieldType;
  if (type.case === 'creatorFieldType') {
    return type.value === CreatorFieldType.ADDRESS;
  }
  return false;
}

export function isDateCustomField(field: EntityField): boolean {
  const type = field.fieldType;
  if (type.case === 'creatorFieldType') {
    return type.value === CreatorFieldType.DATE;
  }
  return false;
}

export function isBooleanCustomField(field: EntityField): boolean {
  return (
    field.fieldType.case === 'creatorFieldType' &&
    field.fieldType.value === CreatorFieldType.BOOLEAN
  );
}

export function isLocationCustomField(field: EntityField): boolean {
  return (
    field.fieldType.case === 'creatorFieldType' &&
    field.fieldType.value === CreatorFieldType.LOCATION
  );
}

export function unsetCustomFieldValue(
  fieldValue: EntityFieldValue,
): EntityFieldValue {
  const newFieldValue = fieldValue.clone();

  newFieldValue.value = {
    case: 'unsetValue',
    value: true,
  };

  return newFieldValue;
}

export function updateEntityFieldValue(
  field: EntityField,
  fieldValue: EntityFieldValue,
): EntityFieldValue {
  const newFieldValue = fieldValue.clone();
  if (isNil(field.entityFieldId)) {
    return new EntityFieldValue();
  }
  newFieldValue.entityFieldId = field.entityFieldId;
  newFieldValue.entityFieldName = field.name;
  newFieldValue.entityType = field.entityType;
  return newFieldValue;
}

export function setCustomFieldTextValue(
  fieldValue: EntityFieldValue,
  text: string,
): EntityFieldValue {
  if (isEmptyString(text)) {
    return unsetCustomFieldValue(fieldValue);
  }

  const newFieldValue = fieldValue.clone();
  newFieldValue.value = {
    case: 'textValue',
    value: text,
  };

  return newFieldValue;
}

export function setCustomFieldNumberValue(
  fieldValue: EntityFieldValue,
  val: bigint,
): EntityFieldValue {
  const newFieldValue = fieldValue.clone();

  newFieldValue.value = {
    case: 'numberValue',
    value: val,
  };

  return newFieldValue;
}

export function setCustomFieldDecimalNumberValue(
  fieldValue: EntityFieldValue,
  val: number,
): EntityFieldValue {
  const newFieldValue = fieldValue.clone();

  newFieldValue.value = {
    case: 'decimalValue',
    value: val,
  };

  return newFieldValue;
}

export function setCustomFieldBooleanValue(
  fieldValue: EntityFieldValue,
  val: boolean | undefined,
): EntityFieldValue {
  if (isNil(val)) {
    return unsetCustomFieldValue(fieldValue);
  } else {
    const newFieldValue = fieldValue.clone();

    newFieldValue.value = {
      case: 'boolValue',
      value: val,
    };
    return newFieldValue;
  }
}

export function isUnsetCustomField(fieldValue: EntityFieldValue): boolean {
  return (
    fieldValue.value.case === 'unsetValue' ||
    fieldValue.value.case === undefined
  );
}

export function setCustomFieldDateValue(
  fieldValue: EntityFieldValue,
  val: Date | undefined,
): EntityFieldValue {
  if (isNil(val)) {
    return unsetCustomFieldValue(fieldValue);
  } else {
    const newFieldValue = fieldValue.clone();

    newFieldValue.value = {
      case: 'dateValue',
      value: Timestamp.fromDate(val),
    };
    return newFieldValue;
  }
}

export function setCustomFieldLocationValue(
  fieldValue: EntityFieldValue,
  val: GoogleLocation,
): EntityFieldValue {
  if (val.equals(DEFAULT_GOOGLE_LOCATION_VALUE)) {
    return unsetCustomFieldValue(fieldValue);
  } else {
    const newFieldValue = fieldValue.clone();

    newFieldValue.value = {
      case: 'locationValue',
      value: val,
    };
    return newFieldValue;
  }
}

export function setCustomFieldEnumValue(
  fieldValue: EntityFieldValue,
  val: string[],
): EntityFieldValue {
  if (isEmptyArray(val)) {
    return unsetCustomFieldValue(fieldValue);
  } else {
    const newFieldValue = fieldValue.clone();

    newFieldValue.value = {
      case: 'enumValue',
      value: new StringList({ values: val }),
    };
    return newFieldValue;
  }
}

export function getCustomFieldFormattedValue(
  fieldValue: EntityFieldValue,
): string {
  switch (fieldValue?.value?.case) {
    case 'boolValue':
      return fieldValue.value.value ? $localize`Yes` : $localize`No`;
    case 'dateValue':
      return formatDate(fieldValue.value.value.toDate());
    case 'textValue':
      return fieldValue.value.value;
    case 'numberValue':
    case 'decimalValue':
      return fieldValue.value.value.toString();
    case 'locationValue':
      return fieldValue.value.value.locationName;
    case 'enumValue':
      return fieldValue.value.value?.values?.join(', ');
    case 'addressValue':
      return addressFielProtoToReadableString(fieldValue.value.value);
    default:
      return '';
  }
}

export function setCustomFieldAddressValue(
  fieldValue: EntityFieldValue,
  val: AddressFieldProto | undefined,
): EntityFieldValue {
  if (isNil(val)) {
    return unsetCustomFieldValue(fieldValue);
  } else {
    const newFieldValue = fieldValue.clone();

    newFieldValue.value = {
      case: 'addressValue',
      value: val,
    };
    return newFieldValue;
  }
}

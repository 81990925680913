import { Injectable, computed } from '@angular/core';
import { CacheBloc } from '../bloc';

export interface Language {
  readonly code: string;
  readonly name: string;
}

export interface LanguagesCacheState {
  [key: string]: Language;
}

@Injectable({ providedIn: 'root' })
export class LanguagesCache extends CacheBloc<LanguagesCacheState> {
  constructor() {
    super({});
  }

  readonly languagesList = computed(() => Object.values(this.cachedData()));

  static getLanguage(values: Language[], code: string): Language | undefined {
    return values.find(
      (lang) => lang.code.toLowerCase() === code.toLowerCase(),
    );
  }

  static getLanguageName(values: Language[], code: string): string {
    return this.getLanguage(values, code)?.name ?? code;
  }

  getLanguageName(code: string): string {
    return this.cachedData()[code].name;
  }

  async fetch(): Promise<LanguagesCacheState> {
    const response = (
      await import('packages/lefty-assets/src/lib/db/languages.json')
    ).default;
    const languages: LanguagesCacheState = {};
    response.forEach((l) => {
      languages[l.code] = l;
    });

    return languages;
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  createCampaignNetworkTrackingForm,
  isCampaignNetworkTrackingForm,
} from '../../config/config.helpers';
import { CampaignNetworkTrackingForm } from '../../config/config.models';
import { LeftyControlValueAccessor } from '../../form';
import { LeftyCheckboxComponent } from '../../checkbox/checkbox.component';
import { NetworkTrackingInputComponent } from '../network-tracking-input-item/network-tracking-input-item';

@Component({
  selector: 'network-tracking-input-v2',
  templateUrl: './network-tracking-input-v2.component.html',
  styleUrls: ['./network-tracking-input-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NetworkTrackingInputComponent, LeftyCheckboxComponent],
})
export class NetworksTrackingFormV2Component extends LeftyControlValueAccessor<CampaignNetworkTrackingForm> {
  constructor() {
    super();
    const host = inject(ElementRef<HTMLElement>).nativeElement as HTMLElement;
    effect(() => host.classList.toggle('compact', this.compact()));

    effect(
      () => {
        if (this.value()?.excludedKeywords.size ?? 0 > 0) {
          this.excludedCheckBox.set(true);
        }
      },
      { allowSignalWrites: true },
    );
  }
  readonly withLogicOperator = input(false);
  readonly withExcluded = input(false);
  readonly canRemove = input(false);
  readonly compact = input(false);
  readonly cantEditSelectedNetwork = input(false);
  readonly availableNetworks = input<Network[]>([]);

  readonly remove$ = output<void>();
  readonly edit$ = output<void>();

  readonly excludedCheckBox = signal(false);

  excludedCheckedChange(checked: boolean): void {
    if (checked) {
      return;
    }
    const value = createCampaignNetworkTrackingForm({
      ...this.value(),
      excludedKeywords: new Map(),
    });
    this.setValueAndNotify(value);
  }

  override isValidType(obj: unknown): obj is CampaignNetworkTrackingForm {
    return isCampaignNetworkTrackingForm(obj);
  }
}

import { enableProdMode, Type } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import {
  initDatadog,
  initPosthog,
  isNotEmptyString,
  isNotNil,
} from '@frontend2/core';
import { PostHogConfig } from 'posthog-js';

interface Environment {
  readonly name: string;
  readonly version: string;

  readonly isProduction?: boolean;
  readonly enableAngularProdMode?: boolean;

  readonly sentryDsn?: string;
  readonly posthogToken?: string;
  readonly posthogHost?: string;

  readonly datadog?: {
    readonly applicationId: string;
    readonly clientToken: string;
  };
}

export function initLeftyApp<Module>(
  moduleType: Type<Module>,
  environment: Environment,
  options?: {
    readonly posthogConfig?: Partial<PostHogConfig>;
  },
): void {
  console.log('Init Lefty', environment.name, environment.version);
  const posthogToken = environment.posthogToken;
  const enablePosthog = isNotEmptyString(posthogToken);

  if (environment.isProduction || environment.enableAngularProdMode) {
    enableProdMode();
  }

  if (enablePosthog) {
    setTimeout(() => {
      console.log('Init Posthog');
      initPosthog(posthogToken, environment.version, {
        ...options?.posthogConfig,
        api_host: environment.posthogHost,
      });
    }, 0);
  }

  // Note(hadrien): disable Sentry while testing Datadog
  //
  // const enableSentry = isNotEmptyString(sentryDsn);
  // if (enableSentry) {
  //   console.log('Init Sentry');
  //   initSentry(environment.name, sentryDsn, {
  //     enablePosthog,
  //     isProduction: environment.isProduction,
  //     release: environment.version,
  //   });
  // }

  const datadog = environment.datadog;
  if (isNotNil(datadog)) {
    console.log('Init Datadog');
    initDatadog(datadog.applicationId, datadog.clientToken, environment.name, {
      isProduction: environment.isProduction,
      release: environment.version,
    });
  }

  platformBrowserDynamic()
    .bootstrapModule(moduleType)
    .catch((err) => console.error(err));
}

import { Component, computed, input, model, signal } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { injectLibrarianUsersClient } from '@frontend2/api';
import { isNil, isNotEmptyString, isNotNil } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { CreatorTiniestCard } from '@frontend2/proto/librarian/proto/creators_pb';
import {
  CreatorAutocompleteRequest,
  CreatorAutocompleteResponse_CreatorAutocompleteMatch,
} from '@frontend2/proto/librarian/proto/users_pb';
import { debounceTime, map } from 'rxjs';
import { showToastException } from '../error-handler';
import { injectToastManager } from '../toast/toast.service';
import { LeftyComponent } from '../utils';
import { createInfluencerWithNetworkInfoAutocomplete } from './influencer-autocomplete.helpers';

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class InfluencerAutoCompleteBase extends LeftyComponent {
  constructor() {
    super();
    toObservable(this.searchValue)
      .pipe(
        debounceTime(300),
        takeUntilDestroyed(),
        map((query) => query.trim()),
      )
      .subscribe((val) =>
        this.searchInfluencers(this.network(), val, this.hideSearch()),
      );
  }
  private readonly _toastManager = injectToastManager();

  private readonly librarian = injectLibrarianUsersClient();

  readonly OPTIONS_SIZE = 10;

  readonly hideSearch = signal(false);

  readonly network = model(Network.INSTA);

  readonly excludedIfluencerIds = input<string[]>([]);

  readonly searchValue = signal('');

  readonly loading = signal(false);

  readonly influencersRawOptions = signal<CreatorAutocompleteMatch[]>([]);

  readonly influencersOptionsWithNetwork = computed(() =>
    this.influencersRawOptions().map((i) =>
      createInfluencerWithNetworkInfoAutocomplete({
        influencer: i,
        selectedNetwork: this.network(),
      }),
    ),
  );

  readonly influencerOptions = computed(() =>
    this.influencersRawOptions()
      .map((inf) => inf.creator)
      .filter(isNotNil),
  );

  async searchInfluencers(
    network: Network,
    searchValue: string,
    hideSearch?: boolean,
  ): Promise<void> {
    try {
      this.loading.set(true);

      if (isNotEmptyString(searchValue)) {
        const request = new CreatorAutocompleteRequest({
          namePrefix: searchValue,
          count: this.OPTIONS_SIZE,
          network: network,
          withNetworkInfo: true,
        });

        const response =
          await this.librarian.autocompleteCreatorsV2API(request);

        const filteredCreators = this.filterCreators(response.creators);
        if (hideSearch === true) {
          this.influencersRawOptions.set(filteredCreators);
        } else {
          this.influencersRawOptions.set(
            this._processOptions(searchValue, filteredCreators),
          );
        }
      } else {
        this.influencersRawOptions.set([]);
      }
    } catch (e) {
      this.influencersRawOptions.set([]);

      showToastException(this._toastManager, e);
    } finally {
      this.loading.set(false);
    }
  }

  filterCreators(val: CreatorAutocompleteMatch[]): CreatorAutocompleteMatch[] {
    return val.filter((i) => {
      if (isNotEmptyString(i.creator?.userId)) {
        return !this.excludedIfluencerIds().includes(i.creator.userId);
      }
      return true;
    });
  }

  //adds an extra ghost item that will be used to search instead of selection
  private _processOptions(
    searchValue: string,
    response: CreatorAutocompleteMatch[],
  ): CreatorAutocompleteMatch[] {
    if (isNotEmptyString(searchValue)) {
      const exist = response.find(
        (u) => u.creator?.userName.toLowerCase() === searchValue.toLowerCase(),
      );
      if (isNil(exist)) {
        return [
          new CreatorAutocompleteResponse_CreatorAutocompleteMatch({
            creator: new CreatorTiniestCard({ userName: searchValue }),
          }),
          ...response,
        ];
      }
    }

    return response;
  }

  cleanupSearch(): void {
    this.searchValue.set('');
    this.changeDetection.markForCheck();
  }
}

export type CreatorAutocompleteMatch =
  CreatorAutocompleteResponse_CreatorAutocompleteMatch;

import { injectLibrarianCampaignsClient } from '@frontend2/api';
import {
  buildDefaultRouteState,
  createInfluencer,
  Influencer,
  isNil,
  ProtoUtils,
  RouteParams,
} from '@frontend2/core';
import { BasicPagination } from '@frontend2/proto/common/proto/common_pb';
import {
  CreatorPoolRequestV2,
  NativeFieldFilter,
  UserSort,
} from '@frontend2/proto/librarian/proto/common_pb';
import { RouteWithPaginationBloc } from '../bloc';
import { createRow } from '../lefty-data-table/lefty-data-table.helpers';
import { Row } from '../lefty-data-table/lefty-data-table.models';
import { CAMPAIGN_INFLUENCER_FILTERS } from '../lefty-dynamic-filters/dynamic-filters.helpers';
import {
  buildCampaignInfluencer,
  CampaignInfluencer,
  CampaignInfluencersTableState,
  createCampaignInfluencersTableState,
} from './campaign-recruited-influencers.models';

export abstract class CampaignRecruitedInfluencersBase extends RouteWithPaginationBloc<
  CreatorPoolRequestV2,
  CampaignInfluencersTableState
> {
  constructor() {
    super({
      initialState: buildDefaultRouteState(
        new CreatorPoolRequestV2(),
        createCampaignInfluencersTableState(),
      ),
    });
  }

  private readonly campaignLibrarian = injectLibrarianCampaignsClient();

  readonly DEFAULT_SORT = UserSort.EMV;

  static readonly QUERY_KEY = 'q';

  override get paginationSize(): number {
    return 20;
  }

  static readonly _validFilterTypes = [
    ...CAMPAIGN_INFLUENCER_FILTERS,
    // not part dynamic filters, it's a dedicated input
    NativeFieldFilter.SEARCH_TEXT_FILTER,
  ];

  override beforeBuild(): void {
    const currentRows = this.viewState().tableRows;
    const ghostRows = Array(this.paginationSize).fill(this._buildGhostRow());

    const rowsWithGhost = [...currentRows, ...ghostRows];

    this.setViewState({
      ...this.viewState(),
      tableRows: rowsWithGhost,
    });
  }

  private _buildGhostRow(): Row<Influencer> {
    return createRow('ghost', {
      data: createInfluencer(''),
    });
  }

  private _sanitizeRequest(req: CreatorPoolRequestV2): CreatorPoolRequestV2 {
    if (isNil(req.pagination) || req.pagination.equals(new BasicPagination())) {
      req = this.applyPagination(req, 0);
    }

    if (req.sort === UserSort.DEFAULT) {
      req = new CreatorPoolRequestV2({ ...req, sort: this.DEFAULT_SORT });
    }

    return req;
  }

  override async build(
    req: CreatorPoolRequestV2,
  ): Promise<CampaignInfluencersTableState> {
    req = this._sanitizeRequest(req);

    const campaignId =
      req.entityId.case === 'campaignId' ? req.entityId.value : BigInt(0);

    const response = await this.campaignLibrarian.getCampaignCreators(req);
    const influencers = response.creators
      .map((creator) =>
        buildCampaignInfluencer(creator, campaignId, response.filteredNetworks),
      )
      .map(this.buildRow.bind(this));

    return createCampaignInfluencersTableState({
      totalHits: response.totalHits,
      tableRows: influencers,
    });
  }

  override parseRouteParams(params: RouteParams): CreatorPoolRequestV2 {
    return ProtoUtils.protoFromRouteParams(
      CreatorPoolRequestV2.fromBinary,
      params,
      CampaignRecruitedInfluencersBase.QUERY_KEY,
    );
  }

  override appendNewPage(
    currentState: CampaignInfluencersTableState,
    newPage: CampaignInfluencersTableState,
  ): CampaignInfluencersTableState {
    const newRows = [
      ...currentState.tableRows.filter((row) => !row.isGhost),
      ...newPage.tableRows,
    ];

    return createCampaignInfluencersTableState({
      ...currentState,
      tableRows: newRows,
    });
  }

  override applyPagination(
    req: CreatorPoolRequestV2,
    from: number,
  ): CreatorPoolRequestV2 {
    return new CreatorPoolRequestV2({
      ...req,
      pagination: new BasicPagination({
        size: this.paginationSize,
        from: from,
      }),
    });
  }

  protected override getTotalHits(
    request: CreatorPoolRequestV2,
    viewSate: CampaignInfluencersTableState,
  ): number {
    return viewSate.totalHits;
  }

  abstract buildRow(influencer: CampaignInfluencer): Row<CampaignInfluencer>;
}

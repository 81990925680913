import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  Messages,
  isEmptyString,
  isNil,
  isNotEmptyString,
} from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  EntityField,
  TimeRestriction,
} from '@frontend2/proto/librarian/proto/common_pb';
import { isUnsetCustomField } from './custom-fields/custom-fields.helpers';
import {
  isPhone,
  toPhoneNumberString,
} from './lefty-phone-number-input/lefty-phone-number.helpers';

export class LeftyValidators {
  static requiredTimeRestriction(
    control: AbstractControl,
  ): ValidationErrors | null {
    if (control.value instanceof TimeRestriction === false || !control.value) {
      return { required: Messages.requiredError };
    }

    const time = control.value as TimeRestriction;
    if (!time.start) {
      return { required: $localize`Start date is missing` };
    }

    if (!time.end) {
      return { required: $localize`End date is missing` };
    }

    return null;
  }

  static requiredIterable(control: AbstractControl): ValidationErrors | null {
    if (
      control.value === null ||
      control.value === undefined ||
      control.value.length === 0
    ) {
      return { required: true };
    }
    return null;
  }

  static lowerBound(value: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value === null || isNaN(control.value)) {
        return null;
      }
      if (control.value < value) {
        return { 'lower-bound-number': 'number is too small' };
      }
      return null;
    };
  }

  static requiredNetwork(control: AbstractControl): ValidationErrors | null {
    return control.value === null || control.value === Network.NETWORK_UNKNOWN
      ? { required: true }
      : null;
  }

  static requiredStringFormArray(
    control: AbstractControl,
  ): ValidationErrors | null {
    const options = control as FormArray<FormControl<string>>;
    const optionValues = options.controls.map((option) => option.value);
    const hasValue = optionValues.some((value) => isNotEmptyString(value));
    return hasValue ? null : { noOptionValue: true };
  }

  static atLeastOne =
    (validator: ValidatorFn, controls: string[]) =>
    (group: FormGroup): ValidationErrors | null => {
      if (!controls) {
        controls = Object.keys(group.controls);
      }

      const hasAtLeastOne =
        group &&
        group.controls &&
        controls.some((k) => !validator(group.controls[k]));

      return hasAtLeastOne
        ? null
        : {
            atLeastOne: true,
          };
    };

  private static _checkPattern(
    toCheck: string,
    pattern: string,
    errorMessage: string,
  ): ValidationErrors | null {
    const regex = new RegExp(`^${pattern}$`);
    const value = toCheck as string;
    return regex.test(value) ? null : { invalid: errorMessage };
  }

  private static _patternValidator(
    pattern: string,
    errorMessage: string,
  ): ValidatorFn {
    return (control: AbstractControl) => {
      if (Validators.required(control) !== null) {
        return null;
      }
      return LeftyValidators._checkPattern(
        control.value as string,
        pattern,
        errorMessage,
      );
    };
  }

  static emailValidator(control: AbstractControl): ValidationErrors | null {
    const pattern = EMAIL_REGEXP_PATTERN;
    return LeftyValidators._patternValidator(
      pattern,
      $localize`Invalid email address`,
    )(control);
  }

  static phoneNumberValidator(
    control: AbstractControl,
  ): ValidationErrors | null {
    const value = control.value;

    if (isPhone(value) === false || isEmptyString(value.phoneNumber)) {
      return null;
    }

    return LeftyValidators._checkPattern(
      toPhoneNumberString(value),
      PHONE_NUMBER_REGEXP_PATTERN,
      $localize`Invalid phone number`,
    );
  }

  static phoneRequiredValidator(
    control: AbstractControl,
  ): ValidationErrors | null {
    const value = control.value;

    if (isPhone(value) === false || isEmptyString(value.phoneNumber)) {
      return { required: true };
    }

    return null;
  }

  static conditionalRequired(
    conditionFormControl?: string,
    condition?: boolean,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const conditionVal = isNotEmptyString(conditionFormControl)
        ? control.parent?.get(conditionFormControl)?.value
        : condition;
      if (isNil(control.value) && conditionVal) {
        return { required: true };
      }

      return null;
    };
  }

  static conditionalRequiredIterable(
    conditionFormControl?: string,
    condition?: boolean,
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const conditionVal = isNotEmptyString(conditionFormControl)
        ? control.parent?.get(conditionFormControl)?.value
        : condition;
      if (
        (control.value === null ||
          control.value === undefined ||
          control.value.length === 0) &&
        conditionVal
      ) {
        return { required: true };
      }

      return null;
    };
  }

  static richTextMaxLengthValidator(maxLength: number): ValidatorFn {
    return (control: AbstractControl) => {
      if (!control.value) {
        return null;
      }

      const plainText = control.value.replace(/<\/?[^>]+(>|$)/g, '');

      if (plainText.length > maxLength) {
        return {
          maxlength: {
            actualLength: plainText.length,
            requiredLength: maxLength,
          },
        };
      }

      return null;
    };
  }
}
const EMAIL_REGEXP_PATTERN =
  "(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*";

const PHONE_NUMBER_REGEXP_PATTERN = '(\\+|00)[0-9\\-]{1,7}\\s?[0-9]{5,14}';

export function customFieldValidator(field: EntityField): ValidatorFn {
  return (c: AbstractControl) => {
    const isEmpty = isNil(c.value) || isUnsetCustomField(c.value);

    if (field?.mandatory === true && isEmpty) {
      return { required: true };
    }
    return null;
  };
}

<!-- tabindex="-1" avoid triggering focus on parent element like Anchor-->
<div
  class="lefty-popup-content"
  [ngClass]="popupClassName"
  [style.min-width]="popupMinWidth"
  tabindex="-1"
>
  <ng-container *ngIf="visible">
    <ng-content></ng-content>
  </ng-container>
</div>

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/admin.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Account, AddTeamImpersonatorsRequest, AddTeamMembersRequest, AppSetting, AppSettingsList, AutocompleteCustomersResponse, CreateTeamReq, CreateUserRequest, DebugUserResponse, EditCalculatorMethodReq, EditSubscriptionReq, EditUserRequest, GetCustomersRequest, GetUserAccountResp, GetUserAccountsResponse, GrantSubscriptionReq, IncludeExcludeCampaignFromStoryPollingReq, IncludeExcludeCampaignsFromStoryPollingReq, ListCompaniesResponse, RecoverStoriesReq, RemoveTeamImpersonatorsReq, RemoveTeamMembersReq, TeamDetails, TeamsResponse, UnmergeReq, UpdateCustomerEmailReq, UpdateStoryPollingPriorityReq, UpdateTeamReq } from "./admin_pb";
import { Empty, Int64Value, MethodKind, StringValue } from "@bufbuild/protobuf";
import { Campaigns } from "./campaigns_pb";
import { InfluencerPollPoints, InfluencerPollReq } from "../../universal/stories/proto/poller_pb";
import { FileProto } from "../../common/proto/common_pb";
import { SsoProvidersList } from "./frontend_misc_pb";
import { NightwatchDebugReq, NightwatchDebugResp } from "../../common/proto/nightwatch_pb";

/**
 * @generated from service librarian.proto.LibrarianAdmin
 */
export const LibrarianAdmin = {
  typeName: "librarian.proto.LibrarianAdmin",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.GetUserAccountsAPI
     */
    getUserAccountsAPI: {
      name: "GetUserAccountsAPI",
      I: GetCustomersRequest,
      O: GetUserAccountsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * TODO(mateo) remove when frontend has been migrated
     *
     * @generated from rpc librarian.proto.LibrarianAdmin.GetUserAccountByIdAPI
     * @deprecated
     */
    getUserAccountByIdAPI: {
      name: "GetUserAccountByIdAPI",
      I: Int64Value,
      O: Account,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.GetUserAccountAPI
     */
    getUserAccountAPI: {
      name: "GetUserAccountAPI",
      I: Int64Value,
      O: GetUserAccountResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.ListCompaniesAPI
     */
    listCompaniesAPI: {
      name: "ListCompaniesAPI",
      I: Empty,
      O: ListCompaniesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Same as signup but it's a lefty admin creating a user.
     * This allows to customize the trial period.
     *
     * @generated from rpc librarian.proto.LibrarianAdmin.CreateUserAPI
     */
    createUserAPI: {
      name: "CreateUserAPI",
      I: CreateUserRequest,
      O: Account,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.EditUserAPI
     */
    editUserAPI: {
      name: "EditUserAPI",
      I: EditUserRequest,
      O: Account,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.AddTeamMembersAPI
     */
    addTeamMembersAPI: {
      name: "AddTeamMembersAPI",
      I: AddTeamMembersRequest,
      O: TeamDetails,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.RemoveTeamMembersAPI
     */
    removeTeamMembersAPI: {
      name: "RemoveTeamMembersAPI",
      I: RemoveTeamMembersReq,
      O: TeamDetails,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.AddTeamImpersonatorsAPI
     */
    addTeamImpersonatorsAPI: {
      name: "AddTeamImpersonatorsAPI",
      I: AddTeamImpersonatorsRequest,
      O: TeamDetails,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.RemoveTeamImpersonatorsAPI
     */
    removeTeamImpersonatorsAPI: {
      name: "RemoveTeamImpersonatorsAPI",
      I: RemoveTeamImpersonatorsReq,
      O: TeamDetails,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.AutocompleteCustomersAPIV2
     */
    autocompleteCustomersAPIV2: {
      name: "AutocompleteCustomersAPIV2",
      I: StringValue,
      O: AutocompleteCustomersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.UpdateTeamAPI
     */
    updateTeamAPI: {
      name: "UpdateTeamAPI",
      I: UpdateTeamReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.CreateTeamAPI
     */
    createTeamAPI: {
      name: "CreateTeamAPI",
      I: CreateTeamReq,
      O: TeamDetails,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.GetTeamByIdAPI
     */
    getTeamByIdAPI: {
      name: "GetTeamByIdAPI",
      I: Int64Value,
      O: TeamDetails,
      kind: MethodKind.Unary,
    },
    /**
     * Return the task handle
     *
     * @generated from rpc librarian.proto.LibrarianAdmin.ExportTeamMembers
     */
    exportTeamMembers: {
      name: "ExportTeamMembers",
      I: Int64Value,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.GetTeamByUserIdAPI
     */
    getTeamByUserIdAPI: {
      name: "GetTeamByUserIdAPI",
      I: Int64Value,
      O: TeamDetails,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.GetTeamActiveCampaignsAPI
     */
    getTeamActiveCampaignsAPI: {
      name: "GetTeamActiveCampaignsAPI",
      I: Int64Value,
      O: Campaigns,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.IncludeExcludeCampaignFromStoryPollingAPI
     */
    includeExcludeCampaignFromStoryPollingAPI: {
      name: "IncludeExcludeCampaignFromStoryPollingAPI",
      I: IncludeExcludeCampaignFromStoryPollingReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.IncludeExcludeCampaignsFromStoryPollingAPI
     */
    includeExcludeCampaignsFromStoryPollingAPI: {
      name: "IncludeExcludeCampaignsFromStoryPollingAPI",
      I: IncludeExcludeCampaignsFromStoryPollingReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.UpdateStoryPollingPriorityAPI
     */
    updateStoryPollingPriorityAPI: {
      name: "UpdateStoryPollingPriorityAPI",
      I: UpdateStoryPollingPriorityReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.GetTeamsAPI
     */
    getTeamsAPI: {
      name: "GetTeamsAPI",
      I: Empty,
      O: TeamsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * returns a debug representation of this creator. Generik enabled, the input shall be a MetaId.
     *
     * @generated from rpc librarian.proto.LibrarianAdmin.DebugUserAPI
     */
    debugUserAPI: {
      name: "DebugUserAPI",
      I: StringValue,
      O: DebugUserResponse,
      kind: MethodKind.Unary,
    },
    /**
     * returns the JSON for everything in our cassandra (generik enabled).
     * input must be a MediaId.
     *
     * @generated from rpc librarian.proto.LibrarianAdmin.DebugMediaAPI
     */
    debugMediaAPI: {
      name: "DebugMediaAPI",
      I: StringValue,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * triggers the category computing of a post
     *
     * @generated from rpc librarian.proto.LibrarianAdmin.DebugCategorizePost
     */
    debugCategorizePost: {
      name: "DebugCategorizePost",
      I: StringValue,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.UpdateCustomerEmailAPI
     */
    updateCustomerEmailAPI: {
      name: "UpdateCustomerEmailAPI",
      I: UpdateCustomerEmailReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.GrantSubscriptionAPI
     */
    grantSubscriptionAPI: {
      name: "GrantSubscriptionAPI",
      I: GrantSubscriptionReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.EditSubscriptionAPI
     */
    editSubscriptionAPI: {
      name: "EditSubscriptionAPI",
      I: EditSubscriptionReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.EditCalculatorMethodAPI
     */
    editCalculatorMethodAPI: {
      name: "EditCalculatorMethodAPI",
      I: EditCalculatorMethodReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Internal backdoor that a Librarian exposes to flush the subscription cache for a specific user. It can be used by
     * actions taken on admin to ensure changes to a subscription are reflected directly.
     *
     * @generated from rpc librarian.proto.LibrarianAdmin.FlushUserSubscriptionCache
     */
    flushUserSubscriptionCache: {
      name: "FlushUserSubscriptionCache",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.GetInfluencerPolls
     */
    getInfluencerPolls: {
      name: "GetInfluencerPolls",
      I: InfluencerPollReq,
      O: InfluencerPollPoints,
      kind: MethodKind.Unary,
    },
    /**
     * Uploads an asset that can be later used in a recover story operation, returns the id that must be used in the later
     * call to commit the result with given author, mentions, and timestamp.
     *
     * If the asset is image, it will be converted to jpg.
     * If the asset is video, it will be converted to mp4, and we will also generate a screenshot at id_screenshot.jpg.
     *
     * @generated from rpc librarian.proto.LibrarianAdmin.UploadStoryAssetAPI
     */
    uploadStoryAssetAPI: {
      name: "UploadStoryAssetAPI",
      I: FileProto,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Returns task token
     *
     * @generated from rpc librarian.proto.LibrarianAdmin.RecoverStoriesAPI
     */
    recoverStoriesAPI: {
      name: "RecoverStoriesAPI",
      I: RecoverStoriesReq,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * launches a task to sync chargebee subscriptions to our database and returns bronco handle
     *
     * @generated from rpc librarian.proto.LibrarianAdmin.SyncChargebeeAPI
     */
    syncChargebeeAPI: {
      name: "SyncChargebeeAPI",
      I: Empty,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.UpdateAppSetting
     */
    updateAppSetting: {
      name: "UpdateAppSetting",
      I: AppSetting,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.GetAppSettings
     */
    getAppSettings: {
      name: "GetAppSettings",
      I: Empty,
      O: AppSettingsList,
      kind: MethodKind.Unary,
    },
    /**
     * Bulk upload a list of profiles to merge together in CSV format. There must be no CSV header and the format is:
     * INSTA_USER_name, Youtube channel id, Twitter username, TikTok username,
     *
     * There must be empty placeholders for networks that we don't wanna merge, for instance:
     * bettyautier,,, bettyTikTok
     *
     * This is illegal: bettyautier, bettyTikTok
     *
     * Names that are unknown will be ingested in the same manner as the bulk upload to a campaign.
     *
     * First we will check preconditions on the input, that the merge may be submitted, etc... Then will return a bronco
     * handle for the actual merging. This handle supports progress percentage, and final output (will list which merges
     * failed).
     *
     * The firestore output is a JSON of this form:
     *
     * {
     *   "successes": 20,
     *   "failures": [
     *     "@bettyautier_tiktok couldn't be merged into @toto because @toto doesn't exist on instagram",
     *     "Tiktoker @jeanclaude_vandamme" couldn't be merged into instagrammer @vandamme because it is already merged with another tiktoker",
     *     "another human readable error message",
     *     "etc",
     *   ]
     * }
     *
     * The frontend shall take proper displaying of this firestore output in its entirety.
     *
     * @generated from rpc librarian.proto.LibrarianAdmin.MergeBulkUpload
     */
    mergeBulkUpload: {
      name: "MergeBulkUpload",
      I: StringValue,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * If there is no merge ongoing it returns "".
     *
     * Else the bronco handle for the bulk merge.
     *
     * @generated from rpc librarian.proto.LibrarianAdmin.IsMergeOngoing
     */
    isMergeOngoing: {
      name: "IsMergeOngoing",
      I: Empty,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.Unmerge
     */
    unmerge: {
      name: "Unmerge",
      I: UnmergeReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.UnmergeV2
     */
    unmergeV2: {
      name: "UnmergeV2",
      I: UnmergeReq,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.ResolveMergeErrors
     */
    resolveMergeErrors: {
      name: "ResolveMergeErrors",
      I: StringValue,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.GetSsoProvidersAPI
     */
    getSsoProvidersAPI: {
      name: "GetSsoProvidersAPI",
      I: Empty,
      O: SsoProvidersList,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianAdmin.NightwatchDebugAPI
     */
    nightwatchDebugAPI: {
      name: "NightwatchDebugAPI",
      I: NightwatchDebugReq,
      O: NightwatchDebugResp,
      kind: MethodKind.Unary,
    },
  }
} as const;


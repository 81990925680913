import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { ActiveItemDirective } from './active-item.directive';
import { AppIconDirective, AppLogoDirective } from './app-logo.directive';
import { CampaignReportPerformanceComponent } from './campaign-report/campaign-report-performance/campaign-report-performance.component';
import { ReportPerformanceComponent } from './campaign-report/campaign-report-performance/report-performance/report-performance.component';
import { CampaignReportStatsComponent } from './campaign-report/campaign-report-stats/campaign-report-stats.component';
import { CampaignReportSummaryComponent } from './campaign-report/campaign-report-summary/campaign-report-summary.component';
import { LeftyBarChartsComponent } from './charts/bar/lefty-bar.component';
import { LeftyCheckboxComponent } from './checkbox/checkbox.component';
import { LeftyCalendarPickerComponent } from './date/lefty-calendar-picker/lefty-calendar-picker.component';
import { LeftyDatePickerComponent } from './date/lefty-date-picker.component';
import { LeftyDateRangeInputComponent } from './date/lefty-date-range-input.component';
import { LeftyDateRangePickerComponent } from './date/lefty-date-range-picker.component';
import { LeftyDateTimePickerComponent } from './date/lefty-date-time-picker/lefty-date-time-picker.component';
import { LeftyTimePickerComponent } from './date/lefty-time-picker/lefty-time-picker.component';
import { LeftyDateRangeValidatorDirective } from './date/validators.directive';
import { DirectoryInfluencerSearchAndSelectComponent } from './directory-influencer-search-and-select/directory-influencer-search-and-select.component';
import {
  DirectoryInfluencerListItemComponent,
  DirectoryInfluencerWithNetworkListItemComponent,
} from './directory-influencer-search-and-select/influencer-item/directory-influencer-list-item.component';
import { AutoDismissDirective } from './dismiss.directive';
import { DynamicComponent } from './dynamic-component.component';
import { FocusItemDirective, FocusListDirective } from './focus.directive';
import { FollowersRangeDropdownComponent } from './followers-range-dropdown/followers-range-dropdown.component';
import { HelpIconComponent, InfoIconComponent } from './icon/help.component';
import {
  LeftyBrandIconComponent,
  LeftyIconComponent,
} from './icon/icon.component';
import { NetworkIconComponent } from './icon/network.component';
import { NetworksSlicedIconsComponent } from './icon/networks-sliced-icons.component';
import {
  CampaignNetworkIconsComponent,
  InfluencerNetworksButtonsComponent,
  InfluencerNetworksIconsComponent,
  NetworkButtonsComponent,
  NetworkIconsComponent,
} from './icon/networks.component';
import { StoreIconComponent } from './icon/store-icon/store-icon.component';
import { SafeImageComponent } from './image/image';
import { ProfilePictureComponent } from './image/profile-pic.component';
import { BasicInfluencerAutocompleteComponent } from './influencer-autocomplete/basic-influencer-autocomplete.component';
import { LeftyInfluencerSearchComponent } from './influencer-autocomplete/creator-search-with-network/creator-search-with-network.component';
import { DirectoryInfluencerAutocompleteComponent } from './influencer-autocomplete/directory-influencer-autocomplete.component';
import { InfluencerAutocompleteItemComponent } from './influencer-autocomplete/influencer-autocomplete-item/influencer-autocomplete-item.component';
import { InfluencerWithNetworkInfoItemComponent } from './influencer-autocomplete/influencer-with-network-info-item.component/influencer-with-network-info-item.component';
import { IntersectionObserverDirective } from './intersection-observer.directive';
import {
  LeftyKeyNumberCardComponent,
  LeftyKeyNumberComponent,
} from './key-number/key-number.component';
import { LeftyKeywordsSelectorDropdownComponent } from './keywords-selector/keywords-selector.component';
import {
  LeftyLabelCampaignItemCompactComponent,
  LeftyLabelCampaignItemComponent,
  LeftyLabelCampaignItemHiddenCountComponent,
  LeftyLabelInfluencerItemCompactComponent,
  LeftyLabelInfluencerItemComponent,
  LeftyLabelInfluencerItemHiddenCountComponent,
} from './labels/label-item.component';
import {
  LeftyCampaignLabelsAutocompleteComponent,
  LeftyInfluencerLabelsAutocompleteComponent,
} from './labels/labels-autocomplete.component';
import {
  LeftyCampaignLabelsSelectorComponent,
  LeftyInfluencerLabelsSelectorComponent,
} from './labels/labels-selector.component';
import { LeftyLayoutObserverService } from './layout-observer.service';
import { LeftyAccordionDirective } from './lefty-accordion/lefty-accordion.directive';
import { LeftyExpansionPanelComponent } from './lefty-accordion/lefty-expansion-panel.component';
import { LeftyAddressFieldFormDialogComponent } from './lefty-address-dialog/lefty-address-dialog.component';
import { LeftyAddressFieldFormComponent } from './lefty-address-dialog/lefty-address-field-form/lefty-address-field-form.component';
import { LeftyAgeRangeComponent } from './lefty-age-range/lefty-age-range.component';
import {
  LeftyAuthorAvatarComponent,
  LeftyAvatarComponent,
  LeftyEmailAvatarComponent,
} from './lefty-avatar/lefty-avatar.component';
import { LeftyBadgeComponent } from './lefty-badge.component';
import { LeftyButtonDirective } from './lefty-button-directive/lefty-button.directive';
import { ButtonDirective } from './lefty-button/button.directive';
import { ExpandableLeftyCardComponent } from './lefty-card/expandable-lefty-card/expandable-lefty-card.component';
import { LeftyCardWithToggleComponent } from './lefty-card/lefty-card-with-toggle/lefty-card-with-toggle.component';
import { LeftyCardComponent } from './lefty-card/lefty-card.component';
import { LeftyChipComponent } from './lefty-chip/lefty-chip.component';
import { LeftyChipsAutocompleteComponent } from './lefty-chip/lefty-chips-autocomplete.component';
import { LeftyChipsEditorComponent } from './lefty-chip/lefty-chips-editor.component';
import { LeftyOperatorChipComponent } from './lefty-chip/lefty-operator-chip/lefty-operator-chip.component';
import { LeftyColorSelectorComponent } from './lefty-color-selector/lefty-color-selector.component';
import { LeftyContactUsDialogComponent } from './lefty-contact-us-dialog/lefty-contact-us-dialog.component';
import { LeftyCurrencySearchAndSelectComponent } from './lefty-currency-selector/lefty-currency-search-and-select.component';
import { LeftyCurrencySelectorComponent } from './lefty-currency-selector/lefty-currency-selector.component';
import { LeftyDataTableComponent } from './lefty-data-table/lefty-data-table.component';
import { LeftyDialogComponent } from './lefty-dialog/lefty-dialog.component';
import { LeftyYesNoDialogComponent } from './lefty-dialog/lefty-yes-no-dialog/lefty-yes-no-dialog.component';
import { LeftyDirectoryFiltersButtonComponent } from './lefty-directory-filters-button/directory-filters.component';
import { LeftyDropdownButtonComponent } from './lefty-dropdown-button/lefty-dropdown-button.component';
import { LeftyEmptyStateComponent } from './lefty-empty-state/lefty-empty-state.component';
import { LeftyFeedbackComponent } from './lefty-feedback/lefty-feedback.component';
import { LeftyFileInputComponent } from './lefty-file-input/lefty-file-input.component';
import { LeftyLogoInputComponent } from './lefty-file-input/lefty-logo-logo-input/lefty-logo-input.component';
import { LeftyMediaInputComponent } from './lefty-file-input/lefty-media-input.component';
import { LeftyFiltersButtonComponent } from './lefty-filters/lefty-filters-button.component';
import { LeftyFiltersGridComponent } from './lefty-filters/lefty-filters-grid.component';
import { LeftyFooterInfoComponent } from './lefty-footer-info/lefty-footer-info.component';
import { LeftyFormAutocompleteComponent } from './lefty-form-autocomplete/lefty-form-autocomplete.component';
import { LeftyFormInputComponent } from './lefty-form-input/lefty-form-input.component';
import {
  LeftyFloatNumberInputDirective,
  LeftyFormNumberInputComponent,
  LeftyPercentInputDirective,
} from './lefty-form-input/lefty-form-number-input.component';
import { LeftyFormSelectComponent } from './lefty-form-select/lefty-form-select.component';
import { LeftySelectDropdownItemComponent } from './lefty-form-select/lefty-select-dropdown-item.component';
import { LeftySortSelectComponent } from './lefty-form-select/lefty-sort-select.component';
import { LeftyFormTextareaComponent } from './lefty-form-textarea/lefty-form-textarea.component';
import { LeftyEditFieldFormComponent } from './lefty-form/lefty-edit-field-form.component';
import { LeftyFormComponent } from './lefty-form/lefty-form.component';
import { LeftyFooterComponent } from './lefty-layout/lefty-footer.component';
import { LeftyFormPageComponent } from './lefty-layout/lefty-form-page.component';
import { LeftyLayoutComponent } from './lefty-layout/lefty-layout.component';
import { LeftyMassSelectFooterComponent } from './lefty-layout/lefty-mass-select-footer.component';
import { LeftyPageComponent } from './lefty-layout/page/lefty-page.component';
import { LeftySectionHeaderComponent } from './lefty-layout/page/lefty-section-header.component';
import { LeftySectionComponent } from './lefty-layout/page/lefty-section.component';
import { LeftyListItemComponent } from './lefty-list/lefty-list-item.component';
import { LeftyListComponent } from './lefty-list/lefty-list.component';
import { LeftyLogicOperatorComponent } from './lefty-logic-operator-selector/logic-operator.component';
import { LeftyLoginFormComponent } from './lefty-login-form/lefty-login-form.component';
import { LeftyMenuIconComponent } from './lefty-menu-icon/lefty-menu-icon.component';
import { LeftyNavigationLinkComponent } from './lefty-navigation/lefty-navigation-link.component';
import { LeftyNavigationMoreComponent } from './lefty-navigation/lefty-navigation-more.component';
import { LeftyCategoriesGridComponent } from './lefty-nested-category-selector/categories-grid/lefty-categories-grid.component';
import { LeftyNestedCategorySelectorComponent } from './lefty-nested-category-selector/lefty-nested-category-selector.component';
import { LeftyNoteEditorComponent } from './lefty-note-editor/lefty-note-editor.component';
import { DialCodeListItemComponent } from './lefty-phone-number-input/dial-code-list-item/dial-code-list-item.component';
import { LeftyPhoneNumberInputComponent } from './lefty-phone-number-input/lefty-phone-number-input.component';
import { LeftyPopupComponent } from './lefty-popup/lefty-popup.component';
import { LeftyRadioGroupComponent } from './lefty-radio/lefty-radio-group.component';
import { LeftyRadioComponent } from './lefty-radio/lefty-radio.component';
import { LeftyRangeInputDropdownComponent } from './lefty-range-input-dropdown/lefty-range-input-dropdown.component';
import { LeftyRangeInputComponent } from './lefty-range-input/lefty-range-input.component';
import { LeftySidebarLayoutComponent } from './lefty-sidebar-layout/lefty-sidebar-layout.component';
import { LeftySidebarComponent } from './lefty-sidebar/lefty-sidebar.component';
import { NavigationMenuGroupComponent } from './lefty-sidebar/menu_group.component';
import { NavigationSubMenuGroupComponent } from './lefty-sidebar/submenu.component';
import { LeftyStatusDropdownItemComponent } from './lefty-status-select-dropdown/lefty-status-dropdown-item.component';
import { LeftyStatusSelectComponent } from './lefty-status-select-dropdown/lefty-status-select-dropdown.component';
import { LeftyTableColumnsSelectorComponent } from './lefty-table-columns-selector/lefty-table-columns-selector.component';
import { LeftyTabsComponent } from './lefty-tabs/lefty-tabs.component';
import { LeftyTimelineComponent } from './lefty-timeline/lefty-timeline.component';
import { LeftyFormToggleComponent } from './lefty-toggle/lefty-form-toggle.component';
import { LeftyToggleComponent } from './lefty-toggle/lefty-toggle.component';
import { LeftyTooltipDirective } from './lefty-tooltip/lefty-tooltip.directive';
import { TooltipIfEllipsisDirective } from './lefty-tooltip/tooltip-if-ellipsis.directive';
import {
  LeftyProgressComponent,
  LeftySpinnerComponent,
} from './loading.component';
import { LeftyLogicOperatorTreeComponent } from './logic-operator-tree/logic-operator-tree.component';
import { NetworkWithNameComponent } from './network-with-name/network-with-name.component';
import { NetworksTrackingFormComponent } from './networks-tracking-form/networks-tracking-form';
import { NetworksWithPostsTypeListComponent } from './networks-with-posts-type/network-with-post-type-list/network-with-post-type-list.component';
import { NetworksWithPostsTypeSelectorDropdownComponent } from './networks-with-posts-type/network-with-post-type-selector.component';
import { NetworkDropdownItemComponent } from './networks/network-dropdown-item.component';
import { LeftyQuillFormEditorComponent } from './quill/lefty-quill-editor.component';
import { ReportAudienceAgeComponent } from './report-audience/report-audience-age/report-audience-age.route.component';
import { ReportAudienceCountryComponent } from './report-audience/report-audience-country/report-audience-country.route.component';
import { ReportAudienceComponent } from './report-audience/report-audience.route.component';
import { LeftyReportPerformanceComponent } from './report-performance/report-performance.route.component';
import {
  LeftyRouteReuseStrategy,
  LeftyRouterLifecycleDirective,
  LeftyRouterLinkActiveDirective,
  LeftyRouterLinkDirective,
} from './router-utils';
import { SearchLocationAutocompleteComponent } from './search-location-autocomplete/search-location.component';
import { NetworkFormSelectComponent } from './selector/network-form-select/network-form-select.component';
import { NetworksSelectorDropdownComponent } from './selector/networks-selector-dropdown/networks-selector-dropdown.component';
import { SearchAndSelectDropdownComponent } from './selector/search-and-select-dropdown/search-and-select-dropdown.component';
import { SortItemComponent } from './sort-item/sort-item.component';
import { LeftyToastManagerComponent } from './toast/toast-manager.component';
import {
  LeftyToastComponent,
  LeftyToastContentComponent,
} from './toast/toast.component';
import { NetworkTrackingInputComponent } from './tracking-keywords-input/network-tracking-input-item/network-tracking-input-item';
import { NetworksTrackingFormV2Component } from './tracking-keywords-input/network-tracking-input.component/network-tracking-input-v2.component';
import { LeftySafeVideoComponent } from './video/video.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    LeftyLogicOperatorComponent,
    LeftyKeyNumberCardComponent,
    NetworksWithPostsTypeSelectorDropdownComponent,
    FollowersRangeDropdownComponent,
    LeftyRangeInputComponent,
    LeftyLayoutComponent,
    LeftyPageComponent,
    LeftySectionComponent,
    LeftySectionHeaderComponent,
    LeftySidebarLayoutComponent,
    LeftySidebarComponent,
    LeftyIconComponent,
    LeftyBrandIconComponent,
    ButtonDirective,
    AutoDismissDirective,
    FocusItemDirective,
    FocusListDirective,
    LeftyListComponent,
    LeftyListItemComponent,
    LeftyPopupComponent,
    LeftyDropdownButtonComponent,
    LeftyFormInputComponent,
    LeftyFormNumberInputComponent,
    LeftyPercentInputDirective,
    LeftyFloatNumberInputDirective,
    LeftyFormTextareaComponent,
    LeftyFormSelectComponent,
    LeftySelectDropdownItemComponent,
    LeftyFormComponent,
    HelpIconComponent,
    InfoIconComponent,
    LeftyTooltipDirective,
    LeftyRouterLifecycleDirective,
    IntersectionObserverDirective,
    DynamicComponent,
    LeftyProgressComponent,
    LeftySpinnerComponent,
    LeftyToastContentComponent,
    LeftyToastComponent,
    LeftyToastManagerComponent,
    SafeImageComponent,
    ProfilePictureComponent,
    NetworkIconComponent,
    NetworkIconsComponent,
    NetworksSlicedIconsComponent,
    InfluencerNetworksIconsComponent,
    LeftyBadgeComponent,
    NetworkDropdownItemComponent,
    SortItemComponent,
    NetworkButtonsComponent,
    InfluencerNetworksButtonsComponent,
    LeftyCheckboxComponent,
    NetworksSelectorDropdownComponent,
    SearchAndSelectDropdownComponent,
    LeftyMenuIconComponent,
    LeftyDatePickerComponent,
    LeftyDateRangePickerComponent,
    LeftyDateRangeInputComponent,
    LeftyDateRangeValidatorDirective,
    LeftyDataTableComponent,
    LeftyFeedbackComponent,
    LeftyDialogComponent,
    LeftyRadioGroupComponent,
    LeftyRadioComponent,
    LeftyFormAutocompleteComponent,
    NavigationSubMenuGroupComponent,
    NavigationMenuGroupComponent,
    ActiveItemDirective,
    LeftySortSelectComponent,
    AppLogoDirective,
    LeftyBarChartsComponent,
    LeftyChipComponent,
    LeftyChipsEditorComponent,
    LeftyChipsAutocompleteComponent,
    LeftyAccordionDirective,
    LeftyExpansionPanelComponent,
    NetworkFormSelectComponent,
    LeftyToggleComponent,
    AppIconDirective,
    NetworkTrackingInputComponent,
    SearchLocationAutocompleteComponent,
    TooltipIfEllipsisDirective,
    LeftyFiltersButtonComponent,
    LeftyFiltersGridComponent,
    LeftyFormPageComponent,
    LeftyFooterComponent,
    LeftyFileInputComponent,
    LeftyMediaInputComponent,
    LeftyMassSelectFooterComponent,
    InfluencerAutocompleteItemComponent,
    LeftyNavigationLinkComponent,
    LeftyNavigationMoreComponent,
    LeftyButtonDirective,
    LeftyCardComponent,
    BasicInfluencerAutocompleteComponent,
    LeftyLogoInputComponent,
    LeftyDateTimePickerComponent,
    LeftyEditFieldFormComponent,
    LeftyContactUsDialogComponent,
    LeftyFormToggleComponent,
    LeftyColorSelectorComponent,
    LeftyCurrencySelectorComponent,
    CampaignNetworkIconsComponent,
    LeftyTimelineComponent,
    LeftyFormComponent,
    DirectoryInfluencerAutocompleteComponent,
    LeftyStatusSelectComponent,
    LeftyStatusDropdownItemComponent,
    LeftyKeyNumberComponent,
    LeftyQuillFormEditorComponent,
    LeftyInfluencerLabelsSelectorComponent,
    LeftyCampaignLabelsSelectorComponent,
    LeftyLabelInfluencerItemComponent,
    LeftyLabelInfluencerItemCompactComponent,
    LeftyLabelInfluencerItemHiddenCountComponent,
    LeftyLabelCampaignItemComponent,
    LeftyLabelCampaignItemCompactComponent,
    LeftyLabelCampaignItemHiddenCountComponent,
    LeftyPopupComponent,
    LeftyInfluencerLabelsAutocompleteComponent,
    LeftyCampaignLabelsAutocompleteComponent,
    LeftyEmptyStateComponent,
    NetworksWithPostsTypeSelectorDropdownComponent,
    NetworksTrackingFormComponent,
    LeftyLoginFormComponent,
    LeftyRouterLinkDirective,
    LeftyRouterLinkActiveDirective,
    LeftyYesNoDialogComponent,
    LeftyCalendarPickerComponent,
    LeftyAddressFieldFormDialogComponent,
    LeftyNoteEditorComponent,
    LeftyCardWithToggleComponent,
    LeftyAvatarComponent,
    LeftyEmailAvatarComponent,
    LeftyAuthorAvatarComponent,
    DirectoryInfluencerSearchAndSelectComponent,
    LeftyCurrencySearchAndSelectComponent,
    LeftyRangeInputDropdownComponent,
    DirectoryInfluencerListItemComponent,
    DirectoryInfluencerWithNetworkListItemComponent,
    ExpandableLeftyCardComponent,
    NetworksWithPostsTypeListComponent,
    LeftyPhoneNumberInputComponent,
    DialCodeListItemComponent,
    LeftyFooterInfoComponent,
    LeftySafeVideoComponent,
    LeftyTableColumnsSelectorComponent,
    LeftyAddressFieldFormComponent,
    StoreIconComponent,
    LeftyNestedCategorySelectorComponent,
    LeftyTabsComponent,
    LeftyCategoriesGridComponent,
    LeftyInfluencerSearchComponent,
    InfluencerWithNetworkInfoItemComponent,
    LeftyKeywordsSelectorDropdownComponent,
    LeftyDirectoryFiltersButtonComponent,
    LeftyTimePickerComponent,
    LeftyOperatorChipComponent,
    LeftyLogicOperatorTreeComponent,
    NetworksTrackingFormV2Component,
    CampaignReportStatsComponent,
    NetworkWithNameComponent,
    LeftyReportPerformanceComponent,
    CampaignReportSummaryComponent,
    ReportAudienceComponent,
    ReportAudienceCountryComponent,
    LeftyAgeRangeComponent,
    ReportAudienceAgeComponent,
    ReportPerformanceComponent,
    CampaignReportPerformanceComponent,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: LeftyRouteReuseStrategy },
    LeftyLayoutObserverService,
  ],
  exports: [
    NetworksTrackingFormComponent,
    LeftyLogicOperatorComponent,
    LeftyKeyNumberCardComponent,
    NetworksWithPostsTypeSelectorDropdownComponent,
    FollowersRangeDropdownComponent,
    LeftyRangeInputComponent,
    CampaignNetworkIconsComponent,
    LeftyLayoutComponent,
    LeftyPageComponent,
    LeftySectionComponent,
    LeftySectionHeaderComponent,
    LeftySidebarLayoutComponent,
    LeftySidebarComponent,
    LeftyIconComponent,
    LeftyBrandIconComponent,
    ButtonDirective,
    AutoDismissDirective,
    FocusItemDirective,
    FocusListDirective,
    LeftyListComponent,
    LeftyListItemComponent,
    LeftyPopupComponent,
    LeftyDropdownButtonComponent,
    LeftyFormInputComponent,
    LeftyFormNumberInputComponent,
    LeftyPercentInputDirective,
    LeftyFloatNumberInputDirective,
    LeftyFormTextareaComponent,
    LeftyFormSelectComponent,
    LeftySelectDropdownItemComponent,
    HelpIconComponent,
    InfoIconComponent,
    LeftyTooltipDirective,
    LeftyRouterLifecycleDirective,
    IntersectionObserverDirective,
    DynamicComponent,
    LeftyProgressComponent,
    LeftySpinnerComponent,
    LeftyToastManagerComponent,
    LeftyToastComponent,
    SafeImageComponent,
    LeftySafeVideoComponent,
    ProfilePictureComponent,
    NetworkIconComponent,
    NetworkIconsComponent,
    NetworksSlicedIconsComponent,
    InfluencerNetworksIconsComponent,
    NetworkButtonsComponent,
    InfluencerNetworksButtonsComponent,
    LeftyBadgeComponent,
    NetworkDropdownItemComponent,
    SortItemComponent,
    NetworksSelectorDropdownComponent,
    SearchAndSelectDropdownComponent,
    LeftyCheckboxComponent,
    LeftyMenuIconComponent,
    LeftyDatePickerComponent,
    LeftyDateRangePickerComponent,
    LeftyDateRangeInputComponent,
    LeftyDateRangeValidatorDirective,
    LeftyDataTableComponent,
    LeftyFeedbackComponent,
    LeftyDialogComponent,
    LeftyRadioGroupComponent,
    LeftyRadioComponent,
    LeftyFormAutocompleteComponent,
    NavigationSubMenuGroupComponent,
    NavigationMenuGroupComponent,
    ActiveItemDirective,
    LeftySortSelectComponent,
    AppLogoDirective,
    LeftyBarChartsComponent,
    LeftyChipComponent,
    LeftyChipsEditorComponent,
    LeftyChipsAutocompleteComponent,
    LeftyAccordionDirective,
    LeftyExpansionPanelComponent,
    NetworkFormSelectComponent,
    LeftyToggleComponent,
    AppIconDirective,
    NetworkTrackingInputComponent,
    SearchLocationAutocompleteComponent,
    TooltipIfEllipsisDirective,
    LeftyFiltersButtonComponent,
    LeftyFiltersGridComponent,
    LeftyFormPageComponent,
    LeftyFooterComponent,
    LeftyFileInputComponent,
    LeftyMediaInputComponent,
    LeftyMassSelectFooterComponent,
    InfluencerAutocompleteItemComponent,
    LeftyNavigationLinkComponent,
    LeftyNavigationMoreComponent,
    LeftyButtonDirective,
    LeftyCardComponent,
    BasicInfluencerAutocompleteComponent,
    LeftyLogoInputComponent,
    LeftyDateTimePickerComponent,
    LeftyEditFieldFormComponent,
    LeftyContactUsDialogComponent,
    LeftyFormToggleComponent,
    LeftyColorSelectorComponent,
    LeftyCurrencySelectorComponent,
    LeftyTimelineComponent,
    LeftyFormComponent,
    DirectoryInfluencerAutocompleteComponent,
    LeftyStatusSelectComponent,
    LeftyStatusDropdownItemComponent,
    LeftyKeyNumberComponent,
    LeftyQuillFormEditorComponent,
    LeftyInfluencerLabelsSelectorComponent,
    LeftyCampaignLabelsSelectorComponent,
    LeftyLabelInfluencerItemComponent,
    LeftyLabelInfluencerItemCompactComponent,
    LeftyLabelInfluencerItemHiddenCountComponent,
    LeftyLabelCampaignItemComponent,
    LeftyLabelCampaignItemCompactComponent,
    LeftyLabelCampaignItemHiddenCountComponent,
    LeftyPopupComponent,
    LeftyInfluencerLabelsAutocompleteComponent,
    LeftyCampaignLabelsAutocompleteComponent,
    LeftyEmptyStateComponent,
    LeftyLoginFormComponent,
    LeftyRouterLinkDirective,
    LeftyRouterLinkActiveDirective,
    LeftyYesNoDialogComponent,
    LeftyCalendarPickerComponent,
    LeftyAddressFieldFormDialogComponent,
    LeftyNoteEditorComponent,
    LeftyCardWithToggleComponent,
    LeftyAvatarComponent,
    LeftyEmailAvatarComponent,
    LeftyAuthorAvatarComponent,
    DirectoryInfluencerSearchAndSelectComponent,
    LeftyCurrencySearchAndSelectComponent,
    LeftyRangeInputDropdownComponent,
    ExpandableLeftyCardComponent,
    NetworksWithPostsTypeListComponent,
    LeftyPhoneNumberInputComponent,
    LeftyFooterInfoComponent,
    LeftyTableColumnsSelectorComponent,
    LeftyAddressFieldFormComponent,
    StoreIconComponent,
    LeftyNestedCategorySelectorComponent,
    LeftyTabsComponent,
    LeftyCategoriesGridComponent,
    LeftyInfluencerSearchComponent,
    InfluencerWithNetworkInfoItemComponent,
    LeftyKeywordsSelectorDropdownComponent,
    LeftyDirectoryFiltersButtonComponent,
    LeftyTimePickerComponent,
    LeftyOperatorChipComponent,
    LeftyLogicOperatorTreeComponent,
    NetworksTrackingFormV2Component,
    CampaignReportStatsComponent,
    NetworkWithNameComponent,
    LeftyReportPerformanceComponent,
    CampaignReportSummaryComponent,
    ReportAudienceComponent,
    ReportAudienceCountryComponent,
    LeftyAgeRangeComponent,
    ReportAudienceAgeComponent,
    ReportPerformanceComponent,
    CampaignReportPerformanceComponent,
  ],
})
export class UiModule {}

// This file defines proto for influencer campagne.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/campaign.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Int64Value, Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { CurrencyEnum, Int32Range, LogicOperator, Network, PostType } from "./common_pb";
import { AgesReport, GeographyReports } from "./user_pb";

/**
 * Note(christian) The values are somewhat organized by order of importance and the merging code
 * (class InfluencerMergedStatuses) relies on that.
 *
 * @generated from enum common.proto.UserStatus
 */
export enum UserStatus {
  /**
   * @generated from enum value: USER_PENDING = 0;
   */
  USER_PENDING = 0,

  /**
   * @generated from enum value: USER_REJECTED = 1;
   */
  USER_REJECTED = 1,

  /**
   * @generated from enum value: USER_ACCEPTED = 2;
   */
  USER_ACCEPTED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(UserStatus)
proto3.util.setEnumType(UserStatus, "common.proto.UserStatus", [
  { no: 0, name: "USER_PENDING" },
  { no: 1, name: "USER_REJECTED" },
  { no: 2, name: "USER_ACCEPTED" },
]);

/**
 * Note(christian)  same remark as above for UserStatus regarding merging.
 *
 * @generated from enum common.proto.InfluencerStatus
 */
export enum InfluencerStatus {
  /**
   * @generated from enum value: INFLUENCER_PENDING = 0;
   */
  INFLUENCER_PENDING = 0,

  /**
   * @generated from enum value: INFLUENCER_INVITED = 1;
   */
  INFLUENCER_INVITED = 1,

  /**
   * @generated from enum value: INFLUENCER_REJECTED = 2;
   */
  INFLUENCER_REJECTED = 2,

  /**
   * @generated from enum value: INFLUENCER_ACCEPTED = 3;
   */
  INFLUENCER_ACCEPTED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(InfluencerStatus)
proto3.util.setEnumType(InfluencerStatus, "common.proto.InfluencerStatus", [
  { no: 0, name: "INFLUENCER_PENDING" },
  { no: 1, name: "INFLUENCER_INVITED" },
  { no: 2, name: "INFLUENCER_REJECTED" },
  { no: 3, name: "INFLUENCER_ACCEPTED" },
]);

/**
 * Note(christian) If adding values, please update the code in FlexibleExporter that maps those values
 * to customer facing friendly names.
 *
 * @generated from enum common.proto.InfluencerState
 */
export enum InfluencerState {
  /**
   * @generated from enum value: STATE_UNDEFINED = 0;
   */
  STATE_UNDEFINED = 0,

  /**
   * @generated from enum value: STATE_WAITING = 1;
   */
  STATE_WAITING = 1,

  /**
   * @generated from enum value: STATE_CONTACTED = 2;
   */
  STATE_CONTACTED = 2,

  /**
   * @generated from enum value: STATE_IN_DISCUSSION = 3;
   */
  STATE_IN_DISCUSSION = 3,

  /**
   * @generated from enum value: STATE_REJECTED = 4;
   */
  STATE_REJECTED = 4,

  /**
   * @generated from enum value: STATE_ACCEPTED = 5;
   */
  STATE_ACCEPTED = 5,

  /**
   * @generated from enum value: STATE_PAID = 6;
   */
  STATE_PAID = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(InfluencerState)
proto3.util.setEnumType(InfluencerState, "common.proto.InfluencerState", [
  { no: 0, name: "STATE_UNDEFINED" },
  { no: 1, name: "STATE_WAITING" },
  { no: 2, name: "STATE_CONTACTED" },
  { no: 3, name: "STATE_IN_DISCUSSION" },
  { no: 4, name: "STATE_REJECTED" },
  { no: 5, name: "STATE_ACCEPTED" },
  { no: 6, name: "STATE_PAID" },
]);

/**
 * @generated from enum common.proto.InCampaignStatus
 */
export enum InCampaignStatus {
  /**
   * @generated from enum value: STATUS_UNDEFINED = 0;
   */
  STATUS_UNDEFINED = 0,

  /**
   * @generated from enum value: STATUS_RECRUITED = 1;
   */
  STATUS_RECRUITED = 1,

  /**
   * @generated from enum value: STATUS_PENDING = 2;
   */
  STATUS_PENDING = 2,

  /**
   * @generated from enum value: STATUS_CANDIDATES = 3;
   */
  STATUS_CANDIDATES = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(InCampaignStatus)
proto3.util.setEnumType(InCampaignStatus, "common.proto.InCampaignStatus", [
  { no: 0, name: "STATUS_UNDEFINED" },
  { no: 1, name: "STATUS_RECRUITED" },
  { no: 2, name: "STATUS_PENDING" },
  { no: 3, name: "STATUS_CANDIDATES" },
]);

/**
 * @generated from enum common.proto.InvitationStatus
 */
export enum InvitationStatus {
  /**
   * @generated from enum value: INVITATION_UNKNOWN = 0;
   */
  INVITATION_UNKNOWN = 0,

  /**
   * @generated from enum value: INVITATION_NOT_SENT = 1;
   */
  INVITATION_NOT_SENT = 1,

  /**
   * @generated from enum value: INVITATION_SENT = 2;
   */
  INVITATION_SENT = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(InvitationStatus)
proto3.util.setEnumType(InvitationStatus, "common.proto.InvitationStatus", [
  { no: 0, name: "INVITATION_UNKNOWN" },
  { no: 1, name: "INVITATION_NOT_SENT" },
  { no: 2, name: "INVITATION_SENT" },
]);

/**
 * @generated from enum common.proto.CampaignStatus
 */
export enum CampaignStatus {
  /**
   * @generated from enum value: CAMPAIGN_UNDEFINED = 0;
   */
  CAMPAIGN_UNDEFINED = 0,

  /**
   * @generated from enum value: CAMPAIGN_ACTIVE = 1;
   */
  CAMPAIGN_ACTIVE = 1,

  /**
   * @generated from enum value: CAMPAIGN_ARCHIVED = 2;
   */
  CAMPAIGN_ARCHIVED = 2,

  /**
   * @generated from enum value: CAMPAIGN_DELETED = 3;
   */
  CAMPAIGN_DELETED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(CampaignStatus)
proto3.util.setEnumType(CampaignStatus, "common.proto.CampaignStatus", [
  { no: 0, name: "CAMPAIGN_UNDEFINED" },
  { no: 1, name: "CAMPAIGN_ACTIVE" },
  { no: 2, name: "CAMPAIGN_ARCHIVED" },
  { no: 3, name: "CAMPAIGN_DELETED" },
]);

/**
 * TODO (roman) - If anyone has the courage, we should migrate to a much more flexible definition.
 * Just KEYWORD, MENTION, HASHTAG ...
 *
 * @generated from enum common.proto.CampaignKeywordType
 */
export enum CampaignKeywordType {
  /**
   * @generated from enum value: CAMPAIGN_KEYWORD_TYPE_UNKNOWN = 0;
   */
  CAMPAIGN_KEYWORD_TYPE_UNKNOWN = 0,

  /**
   * @generated from enum value: INSTA_TAG = 1;
   */
  INSTA_TAG = 1,

  /**
   * @generated from enum value: INSTA_MENTION = 2;
   */
  INSTA_MENTION = 2,

  /**
   * @generated from enum value: INSTA_KEYWORD = 17;
   */
  INSTA_KEYWORD = 17,

  /**
   * @generated from enum value: INSTA_LOCATION = 21;
   */
  INSTA_LOCATION = 21,

  /**
   * @generated from enum value: YOUTUBE_TAG = 20;
   */
  YOUTUBE_TAG = 20,

  /**
   * @generated from enum value: YOUTUBE_WORD = 3;
   */
  YOUTUBE_WORD = 3,

  /**
   * @generated from enum value: TWITTER_TAG = 4;
   */
  TWITTER_TAG = 4,

  /**
   * @generated from enum value: TWITTER_MENTION = 5;
   */
  TWITTER_MENTION = 5,

  /**
   * @generated from enum value: TIKTOK_TAG = 6;
   */
  TIKTOK_TAG = 6,

  /**
   * @generated from enum value: TIKTOK_MENTION = 7;
   */
  TIKTOK_MENTION = 7,

  /**
   * @generated from enum value: TIKTOK_KEYWORD = 18;
   */
  TIKTOK_KEYWORD = 18,

  /**
   * @generated from enum value: FACEBOOK_TAG = 8;
   */
  FACEBOOK_TAG = 8,

  /**
   * @generated from enum value: FACEBOOK_MENTION = 9;
   */
  FACEBOOK_MENTION = 9,

  /**
   * @generated from enum value: WEIBO_TAG = 10;
   */
  WEIBO_TAG = 10,

  /**
   * @generated from enum value: WEIBO_MENTION = 11;
   */
  WEIBO_MENTION = 11,

  /**
   * @generated from enum value: WEIBO_KEYWORD = 15;
   */
  WEIBO_KEYWORD = 15,

  /**
   * @generated from enum value: WECHAT_KEYWORD = 13;
   */
  WECHAT_KEYWORD = 13,

  /**
   * @generated from enum value: RED_TAG = 19;
   */
  RED_TAG = 19,

  /**
   * @generated from enum value: RED_KEYWORD = 14;
   */
  RED_KEYWORD = 14,

  /**
   * @generated from enum value: DOUYIN_KEYWORD = 16;
   */
  DOUYIN_KEYWORD = 16,

  /**
   * @generated from enum value: SNAPCHAT_TAG = 22;
   */
  SNAPCHAT_TAG = 22,

  /**
   * @generated from enum value: SNAPCHAT_MENTION = 23;
   */
  SNAPCHAT_MENTION = 23,

  /**
   * @generated from enum value: SNAPCHAT_KEYWORD = 24;
   */
  SNAPCHAT_KEYWORD = 24,
}
// Retrieve enum metadata with: proto3.getEnumType(CampaignKeywordType)
proto3.util.setEnumType(CampaignKeywordType, "common.proto.CampaignKeywordType", [
  { no: 0, name: "CAMPAIGN_KEYWORD_TYPE_UNKNOWN" },
  { no: 1, name: "INSTA_TAG" },
  { no: 2, name: "INSTA_MENTION" },
  { no: 17, name: "INSTA_KEYWORD" },
  { no: 21, name: "INSTA_LOCATION" },
  { no: 20, name: "YOUTUBE_TAG" },
  { no: 3, name: "YOUTUBE_WORD" },
  { no: 4, name: "TWITTER_TAG" },
  { no: 5, name: "TWITTER_MENTION" },
  { no: 6, name: "TIKTOK_TAG" },
  { no: 7, name: "TIKTOK_MENTION" },
  { no: 18, name: "TIKTOK_KEYWORD" },
  { no: 8, name: "FACEBOOK_TAG" },
  { no: 9, name: "FACEBOOK_MENTION" },
  { no: 10, name: "WEIBO_TAG" },
  { no: 11, name: "WEIBO_MENTION" },
  { no: 15, name: "WEIBO_KEYWORD" },
  { no: 13, name: "WECHAT_KEYWORD" },
  { no: 19, name: "RED_TAG" },
  { no: 14, name: "RED_KEYWORD" },
  { no: 16, name: "DOUYIN_KEYWORD" },
  { no: 22, name: "SNAPCHAT_TAG" },
  { no: 23, name: "SNAPCHAT_MENTION" },
  { no: 24, name: "SNAPCHAT_KEYWORD" },
]);

/**
 * This message is shared for all networks to model the counts of interactions that a post can have.
 * Not all fields have meaning on all networks, for instance views is only for videos, instagram has no clicks etc...
 * This is why all fields are optional.
 *
 * @generated from message common.proto.PostInteractions
 */
export class PostInteractions extends Message<PostInteractions> {
  /**
   * we put int64 everywhere for consistency. In real life for instance a youtube video can pass 2b views. On the
   * other hand it is fairly unlikely that anything reaches 2b comments count...
   *
   * @generated from field: google.protobuf.Int64Value likes = 1;
   */
  likes?: bigint;

  /**
   * @generated from field: google.protobuf.Int64Value comments = 2;
   */
  comments?: bigint;

  /**
   * @generated from field: google.protobuf.Int64Value views = 3;
   */
  views?: bigint;

  /**
   * @generated from field: google.protobuf.Int64Value shares = 4;
   */
  shares?: bigint;

  /**
   * @generated from field: google.protobuf.Int64Value clicks = 5;
   */
  clicks?: bigint;

  /**
   * @generated from field: google.protobuf.Int64Value wows = 7;
   */
  wows?: bigint;

  /**
   * @generated from field: google.protobuf.Int64Value favorites = 8;
   */
  favorites?: bigint;

  /**
   * Note(christian) Fields used internally for stats computing but not indexed in ES, visual or media
   * (not yet, or ever if not needed).
   *
   * @generated from field: google.protobuf.Int64Value dislikes = 6;
   */
  dislikes?: bigint;

  constructor(data?: PartialMessage<PostInteractions>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.PostInteractions";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "likes", kind: "message", T: Int64Value },
    { no: 2, name: "comments", kind: "message", T: Int64Value },
    { no: 3, name: "views", kind: "message", T: Int64Value },
    { no: 4, name: "shares", kind: "message", T: Int64Value },
    { no: 5, name: "clicks", kind: "message", T: Int64Value },
    { no: 7, name: "wows", kind: "message", T: Int64Value },
    { no: 8, name: "favorites", kind: "message", T: Int64Value },
    { no: 6, name: "dislikes", kind: "message", T: Int64Value },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostInteractions {
    return new PostInteractions().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostInteractions {
    return new PostInteractions().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostInteractions {
    return new PostInteractions().fromJsonString(jsonString, options);
  }

  static equals(a: PostInteractions | PlainMessage<PostInteractions> | undefined, b: PostInteractions | PlainMessage<PostInteractions> | undefined): boolean {
    return proto3.util.equals(PostInteractions, a, b);
  }
}

/**
 * stats of a post or a group of posts.
 *
 * @generated from message common.proto.PostStats
 */
export class PostStats extends Message<PostStats> {
  /**
   * impressions (estimated or real) of that post type. All networks.
   *
   * @generated from field: int64 impressions = 1;
   */
  impressions = protoInt64.zero;

  /**
   * emv of that post type. All networks.
   *
   * @generated from field: int64 emv = 2;
   */
  emv = protoInt64.zero;

  /**
   * reach of that post type.
   *
   * @generated from field: int64 reach = 4;
   */
  reach = protoInt64.zero;

  /**
   * @generated from field: int64 posts_reach = 5;
   */
  postsReach = protoInt64.zero;

  /**
   * @generated from field: common.proto.PostInteractions interactions = 3;
   */
  interactions?: PostInteractions;

  constructor(data?: PartialMessage<PostStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.PostStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "impressions", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "emv", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "reach", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "posts_reach", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "interactions", kind: "message", T: PostInteractions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostStats {
    return new PostStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostStats {
    return new PostStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostStats {
    return new PostStats().fromJsonString(jsonString, options);
  }

  static equals(a: PostStats | PlainMessage<PostStats> | undefined, b: PostStats | PlainMessage<PostStats> | undefined): boolean {
    return proto3.util.equals(PostStats, a, b);
  }
}

/**
 * @generated from message common.proto.PostTypePerformance
 */
export class PostTypePerformance extends Message<PostTypePerformance> {
  /**
   * @generated from field: common.proto.PostType post_type = 1;
   */
  postType = PostType.POST_TYPE_UNDEFINED;

  /**
   * counts of posts of that post type. All networks.
   *
   * @generated from field: int32 count = 2;
   */
  count = 0;

  /**
   * aggregated stats for this post type.
   *
   * @generated from field: common.proto.PostStats stats = 3;
   */
  stats?: PostStats;

  /**
   * The cumulated followers there were on this post type.
   *
   * Only populated in creator performance, this field is used in order to compute EMV by the special Linkfluence Media
   * calculator.
   *
   * TODO(christian)(mateo) investigate if we can get rid of it since EMV already cached in 'stats' ???
   *
   * @generated from field: int64 cum_followers = 4;
   */
  cumFollowers = protoInt64.zero;

  /**
   * TODO(christian)(mateo) investigate if we can get rid of it since EMV already cached in 'stats' ???
   *
   * @generated from field: int64 sum_engagements = 5;
   */
  sumEngagements = protoInt64.zero;

  constructor(data?: PartialMessage<PostTypePerformance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.PostTypePerformance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType) },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "stats", kind: "message", T: PostStats },
    { no: 4, name: "cum_followers", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "sum_engagements", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostTypePerformance {
    return new PostTypePerformance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostTypePerformance {
    return new PostTypePerformance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostTypePerformance {
    return new PostTypePerformance().fromJsonString(jsonString, options);
  }

  static equals(a: PostTypePerformance | PlainMessage<PostTypePerformance> | undefined, b: PostTypePerformance | PlainMessage<PostTypePerformance> | undefined): boolean {
    return proto3.util.equals(PostTypePerformance, a, b);
  }
}

/**
 * @generated from message common.proto.NetworkCampaignPerformance
 */
export class NetworkCampaignPerformance extends Message<NetworkCampaignPerformance> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * it is guaranteed in here that all performance_by_type.post_type must be for this network.
   *
   * @generated from field: repeated common.proto.PostTypePerformance performance_by_type = 2;
   */
  performanceByType: PostTypePerformance[] = [];

  /**
   * aggregated performance over all this network's types.
   * PS: this field is possible to deduce from summing the post type performence but we include them
   * for convenience.
   *
   * @generated from field: common.proto.PostStats network_stats = 3;
   */
  networkStats?: PostStats;

  /**
   * @generated from field: float in_campaign_engagement = 4;
   */
  inCampaignEngagement = 0;

  /**
   * @generated from field: float avg_engagement = 5;
   */
  avgEngagement = 0;

  /**
   * number of creators in the campaign with at least one post in this network.
   *
   * @generated from field: int32 active_creators = 6;
   */
  activeCreators = 0;

  /**
   * @generated from field: common.proto.FollowersHistogram histogram = 7;
   */
  histogram?: FollowersHistogram;

  /**
   * This is the potential cumulated reach (the sum of their followers) of active creators.
   *
   * @generated from field: int64 potential_reach = 8;
   */
  potentialReach = protoInt64.zero;

  /**
   * This is some ratio (0.7) times the max impressions of a user (summed over users).
   * For instance if cristiano made 2 posts, one has 100k impressions and the other has 200k impressions, we'd take
   * 0.7 * 200k. And then we'll sum this reach for each influencer that posted.
   * It's taking into account edited perfs for reach if any.
   *
   * @generated from field: int64 reach = 9;
   */
  reach = protoInt64.zero;

  /**
   * This is the sum of all posts reach
   *
   * @generated from field: int64 posts_reach = 10;
   */
  postsReach = protoInt64.zero;

  constructor(data?: PartialMessage<NetworkCampaignPerformance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.NetworkCampaignPerformance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "performance_by_type", kind: "message", T: PostTypePerformance, repeated: true },
    { no: 3, name: "network_stats", kind: "message", T: PostStats },
    { no: 4, name: "in_campaign_engagement", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "avg_engagement", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "active_creators", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 7, name: "histogram", kind: "message", T: FollowersHistogram },
    { no: 8, name: "potential_reach", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "reach", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "posts_reach", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkCampaignPerformance {
    return new NetworkCampaignPerformance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkCampaignPerformance {
    return new NetworkCampaignPerformance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkCampaignPerformance {
    return new NetworkCampaignPerformance().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkCampaignPerformance | PlainMessage<NetworkCampaignPerformance> | undefined, b: NetworkCampaignPerformance | PlainMessage<NetworkCampaignPerformance> | undefined): boolean {
    return proto3.util.equals(NetworkCampaignPerformance, a, b);
  }
}

/**
 * @generated from message common.proto.CampaignPerformance
 */
export class CampaignPerformance extends Message<CampaignPerformance> {
  /**
   * @generated from field: repeated common.proto.NetworkCampaignPerformance networks_perf = 4;
   */
  networksPerf: NetworkCampaignPerformance[] = [];

  /**
   * This is not the same as insta.active_creators + youtube.active_creators etc...
   * Because if a creator is multi network linked, we deduped it.
   *
   * @generated from field: int32 active_creators = 20;
   */
  activeCreators = 0;

  /**
   * Sum of max reach per influencer
   *
   * @generated from field: int64 total_reach = 5;
   */
  totalReach = protoInt64.zero;

  /**
   * Sum of all posts reach
   *
   * @generated from field: int64 total_posts_reach = 6;
   */
  totalPostsReach = protoInt64.zero;

  constructor(data?: PartialMessage<CampaignPerformance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CampaignPerformance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 4, name: "networks_perf", kind: "message", T: NetworkCampaignPerformance, repeated: true },
    { no: 20, name: "active_creators", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "total_reach", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "total_posts_reach", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignPerformance {
    return new CampaignPerformance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignPerformance {
    return new CampaignPerformance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignPerformance {
    return new CampaignPerformance().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignPerformance | PlainMessage<CampaignPerformance> | undefined, b: CampaignPerformance | PlainMessage<CampaignPerformance> | undefined): boolean {
    return proto3.util.equals(CampaignPerformance, a, b);
  }
}

/**
 * @generated from message common.proto.NetworkCreatorPerformance
 */
export class NetworkCreatorPerformance extends Message<NetworkCreatorPerformance> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * it is guaranteed in here that all performance_by_type.post_type must be for this network.
   *
   * @generated from field: repeated common.proto.PostTypePerformance performance_by_type = 2;
   */
  performanceByType: PostTypePerformance[] = [];

  /**
   * aggregated performance over all this network's types.
   * PS: this field is possible to deduce from summing the post type performence but we include them
   * for convenience.
   *
   * @generated from field: common.proto.PostStats network_stats = 3;
   */
  networkStats?: PostStats;

  /**
   * If posts types are not folded, this is the sum of performance_by_type.count, else we need a place to hold
   * the network's posts count.
   *
   * @generated from field: int32 posts_count = 8;
   */
  postsCount = 0;

  /**
   * @generated from field: float in_campaign_engagement = 4;
   */
  inCampaignEngagement = 0;

  /**
   * @generated from field: float avg_engagement = 5;
   */
  avgEngagement = 0;

  /**
   * By default, this is some ratio (0.7) times the max impressions of a user (summed over users).
   * For instance if cristiano made 2 posts, one has 100k impressions and the other has 200k impressions, we'd take
   * 0.7 * 200k. And then we'll sum this reach for each influencer that posted.
   * If any edited reach is present and surpasses 0.7 the max impressions, or if there is edited reach for all posts,
   * it will use the max edited reach we have.
   *
   * @generated from field: int64 reach = 6;
   */
  reach = protoInt64.zero;

  /**
   * Sum of all posts reach
   *
   * @generated from field: int64 posts_reach = 9;
   */
  postsReach = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp last_post_date = 7;
   */
  lastPostDate?: Timestamp;

  constructor(data?: PartialMessage<NetworkCreatorPerformance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.NetworkCreatorPerformance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "performance_by_type", kind: "message", T: PostTypePerformance, repeated: true },
    { no: 3, name: "network_stats", kind: "message", T: PostStats },
    { no: 8, name: "posts_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "in_campaign_engagement", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "avg_engagement", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 6, name: "reach", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "posts_reach", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "last_post_date", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): NetworkCreatorPerformance {
    return new NetworkCreatorPerformance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): NetworkCreatorPerformance {
    return new NetworkCreatorPerformance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): NetworkCreatorPerformance {
    return new NetworkCreatorPerformance().fromJsonString(jsonString, options);
  }

  static equals(a: NetworkCreatorPerformance | PlainMessage<NetworkCreatorPerformance> | undefined, b: NetworkCreatorPerformance | PlainMessage<NetworkCreatorPerformance> | undefined): boolean {
    return proto3.util.equals(NetworkCreatorPerformance, a, b);
  }
}

/**
 * @generated from message common.proto.CreatorPerformance
 */
export class CreatorPerformance extends Message<CreatorPerformance> {
  /**
   * @generated from field: repeated common.proto.NetworkCreatorPerformance network_perfs = 4;
   */
  networkPerfs: NetworkCreatorPerformance[] = [];

  constructor(data?: PartialMessage<CreatorPerformance>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CreatorPerformance";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 4, name: "network_perfs", kind: "message", T: NetworkCreatorPerformance, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreatorPerformance {
    return new CreatorPerformance().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreatorPerformance {
    return new CreatorPerformance().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreatorPerformance {
    return new CreatorPerformance().fromJsonString(jsonString, options);
  }

  static equals(a: CreatorPerformance | PlainMessage<CreatorPerformance> | undefined, b: CreatorPerformance | PlainMessage<CreatorPerformance> | undefined): boolean {
    return proto3.util.equals(CreatorPerformance, a, b);
  }
}

/**
 * @generated from message common.proto.FollowersHistogram
 */
export class FollowersHistogram extends Message<FollowersHistogram> {
  /**
   * @generated from field: repeated common.proto.CountFollowers count = 1;
   */
  count: CountFollowers[] = [];

  constructor(data?: PartialMessage<FollowersHistogram>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.FollowersHistogram";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "count", kind: "message", T: CountFollowers, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FollowersHistogram {
    return new FollowersHistogram().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FollowersHistogram {
    return new FollowersHistogram().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FollowersHistogram {
    return new FollowersHistogram().fromJsonString(jsonString, options);
  }

  static equals(a: FollowersHistogram | PlainMessage<FollowersHistogram> | undefined, b: FollowersHistogram | PlainMessage<FollowersHistogram> | undefined): boolean {
    return proto3.util.equals(FollowersHistogram, a, b);
  }
}

/**
 * @generated from message common.proto.CountFollowers
 */
export class CountFollowers extends Message<CountFollowers> {
  /**
   * @generated from field: common.proto.Int32Range followers = 1;
   */
  followers?: Int32Range;

  /**
   * @generated from field: int32 count = 2;
   */
  count = 0;

  constructor(data?: PartialMessage<CountFollowers>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CountFollowers";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "followers", kind: "message", T: Int32Range },
    { no: 2, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CountFollowers {
    return new CountFollowers().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CountFollowers {
    return new CountFollowers().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CountFollowers {
    return new CountFollowers().fromJsonString(jsonString, options);
  }

  static equals(a: CountFollowers | PlainMessage<CountFollowers> | undefined, b: CountFollowers | PlainMessage<CountFollowers> | undefined): boolean {
    return proto3.util.equals(CountFollowers, a, b);
  }
}

/**
 * 2, 5, 11, 12, 13, 14, 15, 16 are reserved until next release + redis flush (was old influencer status).
 *
 * @generated from message common.proto.InfluencerCampaign
 */
export class InfluencerCampaign extends Message<InfluencerCampaign> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: string campaign_name = 4;
   */
  campaignName = "";

  /**
   * @generated from field: common.proto.InfluencerState influencer_state = 3;
   */
  influencerState = InfluencerState.STATE_UNDEFINED;

  /**
   * @generated from field: common.proto.InfluencerStatus influencer_status = 7;
   */
  influencerStatus = InfluencerStatus.INFLUENCER_PENDING;

  /**
   * @generated from field: common.proto.UserStatus user_status = 8;
   */
  userStatus = UserStatus.USER_PENDING;

  /**
   * @generated from field: common.proto.InCampaignStatus in_campaign_status = 10;
   */
  inCampaignStatus = InCampaignStatus.STATUS_UNDEFINED;

  constructor(data?: PartialMessage<InfluencerCampaign>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.InfluencerCampaign";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "campaign_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "influencer_state", kind: "enum", T: proto3.getEnumType(InfluencerState) },
    { no: 7, name: "influencer_status", kind: "enum", T: proto3.getEnumType(InfluencerStatus) },
    { no: 8, name: "user_status", kind: "enum", T: proto3.getEnumType(UserStatus) },
    { no: 10, name: "in_campaign_status", kind: "enum", T: proto3.getEnumType(InCampaignStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerCampaign {
    return new InfluencerCampaign().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerCampaign {
    return new InfluencerCampaign().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerCampaign {
    return new InfluencerCampaign().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerCampaign | PlainMessage<InfluencerCampaign> | undefined, b: InfluencerCampaign | PlainMessage<InfluencerCampaign> | undefined): boolean {
    return proto3.util.equals(InfluencerCampaign, a, b);
  }
}

/**
 * @generated from message common.proto.InfluencerPrice
 */
export class InfluencerPrice extends Message<InfluencerPrice> {
  /**
   * @generated from field: int32 price = 11;
   */
  price = 0;

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 12;
   */
  currency = CurrencyEnum.NA;

  constructor(data?: PartialMessage<InfluencerPrice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.InfluencerPrice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 11, name: "price", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerPrice {
    return new InfluencerPrice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerPrice {
    return new InfluencerPrice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerPrice {
    return new InfluencerPrice().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerPrice | PlainMessage<InfluencerPrice> | undefined, b: InfluencerPrice | PlainMessage<InfluencerPrice> | undefined): boolean {
    return proto3.util.equals(InfluencerPrice, a, b);
  }
}

/**
 * @generated from message common.proto.InfluencerCampaigns
 */
export class InfluencerCampaigns extends Message<InfluencerCampaigns> {
  /**
   * @generated from field: repeated common.proto.InfluencerCampaign influencer_campaigns = 1;
   */
  influencerCampaigns: InfluencerCampaign[] = [];

  constructor(data?: PartialMessage<InfluencerCampaigns>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.InfluencerCampaigns";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_campaigns", kind: "message", T: InfluencerCampaign, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerCampaigns {
    return new InfluencerCampaigns().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerCampaigns {
    return new InfluencerCampaigns().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerCampaigns {
    return new InfluencerCampaigns().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerCampaigns | PlainMessage<InfluencerCampaigns> | undefined, b: InfluencerCampaigns | PlainMessage<InfluencerCampaigns> | undefined): boolean {
    return proto3.util.equals(InfluencerCampaigns, a, b);
  }
}

/**
 * @generated from message common.proto.CampaignSnippet
 */
export class CampaignSnippet extends Message<CampaignSnippet> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 3;
   */
  created?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp start = 4;
   */
  start?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end = 5;
   */
  end?: Timestamp;

  /**
   * @generated from field: bool exclude_from_story_polling = 6;
   */
  excludeFromStoryPolling = false;

  /**
   * @generated from field: int32 stories_priority = 7;
   */
  storiesPriority = 0;

  /**
   * @generated from field: common.proto.CampaignStatus status = 8;
   */
  status = CampaignStatus.CAMPAIGN_UNDEFINED;

  constructor(data?: PartialMessage<CampaignSnippet>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CampaignSnippet";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "created", kind: "message", T: Timestamp },
    { no: 4, name: "start", kind: "message", T: Timestamp },
    { no: 5, name: "end", kind: "message", T: Timestamp },
    { no: 6, name: "exclude_from_story_polling", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "stories_priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 8, name: "status", kind: "enum", T: proto3.getEnumType(CampaignStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignSnippet {
    return new CampaignSnippet().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignSnippet {
    return new CampaignSnippet().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignSnippet {
    return new CampaignSnippet().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignSnippet | PlainMessage<CampaignSnippet> | undefined, b: CampaignSnippet | PlainMessage<CampaignSnippet> | undefined): boolean {
    return proto3.util.equals(CampaignSnippet, a, b);
  }
}

/**
 * @generated from message common.proto.FavoritesLabel
 */
export class FavoritesLabel extends Message<FavoritesLabel> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * number of times this label has been used for influencer or campaign
   *
   * @generated from field: int32 count = 3;
   */
  count = 0;

  /**
   * DEPRECATED - number of times this label has been used for campaigns
   *
   * @generated from field: int32 count_in_campaigns = 6;
   */
  countInCampaigns = 0;

  /**
   * @generated from field: bool archived = 4;
   */
  archived = false;

  /**
   * @generated from field: google.protobuf.Timestamp created = 5;
   */
  created?: Timestamp;

  constructor(data?: PartialMessage<FavoritesLabel>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.FavoritesLabel";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "count_in_campaigns", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "created", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FavoritesLabel {
    return new FavoritesLabel().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FavoritesLabel {
    return new FavoritesLabel().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FavoritesLabel {
    return new FavoritesLabel().fromJsonString(jsonString, options);
  }

  static equals(a: FavoritesLabel | PlainMessage<FavoritesLabel> | undefined, b: FavoritesLabel | PlainMessage<FavoritesLabel> | undefined): boolean {
    return proto3.util.equals(FavoritesLabel, a, b);
  }
}

/**
 * @generated from message common.proto.FavoritesLabels
 */
export class FavoritesLabels extends Message<FavoritesLabels> {
  /**
   * @generated from field: repeated common.proto.FavoritesLabel favorites_labels = 1;
   */
  favoritesLabels: FavoritesLabel[] = [];

  constructor(data?: PartialMessage<FavoritesLabels>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.FavoritesLabels";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "favorites_labels", kind: "message", T: FavoritesLabel, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FavoritesLabels {
    return new FavoritesLabels().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FavoritesLabels {
    return new FavoritesLabels().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FavoritesLabels {
    return new FavoritesLabels().fromJsonString(jsonString, options);
  }

  static equals(a: FavoritesLabels | PlainMessage<FavoritesLabels> | undefined, b: FavoritesLabels | PlainMessage<FavoritesLabels> | undefined): boolean {
    return proto3.util.equals(FavoritesLabels, a, b);
  }
}

/**
 * @generated from message common.proto.CampaignKeyword
 */
export class CampaignKeyword extends Message<CampaignKeyword> {
  /**
   * @generated from field: common.proto.CampaignKeywordType keyword_type = 1;
   */
  keywordType = CampaignKeywordType.CAMPAIGN_KEYWORD_TYPE_UNKNOWN;

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  /**
   * This is used for type INSTA_LOCATION only. In which case 'value' above is the location id and this field is the
   * user-friendly name.
   *
   * @generated from field: string display_name = 3;
   */
  displayName = "";

  constructor(data?: PartialMessage<CampaignKeyword>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CampaignKeyword";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword_type", kind: "enum", T: proto3.getEnumType(CampaignKeywordType) },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignKeyword {
    return new CampaignKeyword().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignKeyword {
    return new CampaignKeyword().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignKeyword {
    return new CampaignKeyword().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignKeyword | PlainMessage<CampaignKeyword> | undefined, b: CampaignKeyword | PlainMessage<CampaignKeyword> | undefined): boolean {
    return proto3.util.equals(CampaignKeyword, a, b);
  }
}

/**
 * This message contains the audience part of a campaign report. It is cached to scylla on each computation so we can
 * service it immediately.
 *
 * There is one entry per campaign and per network so the full result can be assembled with a scan.
 *
 * @generated from message common.proto.ComputedCampaignAudience
 */
export class ComputedCampaignAudience extends Message<ComputedCampaignAudience> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: common.proto.Network network = 2;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * percentage of females in the audience.
   *
   * @generated from field: double female_percentage = 3;
   */
  femalePercentage = 0;

  /**
   * @generated from field: common.proto.GeographyReports countries = 4;
   */
  countries?: GeographyReports;

  /**
   * @generated from field: common.proto.AgesReport ages_report_female = 5;
   */
  agesReportFemale?: AgesReport;

  /**
   * @generated from field: common.proto.AgesReport ages_report_male = 6;
   */
  agesReportMale?: AgesReport;

  /**
   * @generated from field: google.protobuf.Timestamp updated = 7;
   */
  updated?: Timestamp;

  /**
   * How many total posts there were in the campaign for that network, at the time of 'updated'.
   *
   * @generated from field: int32 total_posts = 8;
   */
  totalPosts = 0;

  /**
   * How many posts we had an audience report for the author, at the time of 'updated'.
   * If the ratio of this field/total is too low, we might consider the report unconclusive for now.
   *
   * @generated from field: int32 posts_with_report = 9;
   */
  postsWithReport = 0;

  constructor(data?: PartialMessage<ComputedCampaignAudience>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ComputedCampaignAudience";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 3, name: "female_percentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "countries", kind: "message", T: GeographyReports },
    { no: 5, name: "ages_report_female", kind: "message", T: AgesReport },
    { no: 6, name: "ages_report_male", kind: "message", T: AgesReport },
    { no: 7, name: "updated", kind: "message", T: Timestamp },
    { no: 8, name: "total_posts", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "posts_with_report", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ComputedCampaignAudience {
    return new ComputedCampaignAudience().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ComputedCampaignAudience {
    return new ComputedCampaignAudience().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ComputedCampaignAudience {
    return new ComputedCampaignAudience().fromJsonString(jsonString, options);
  }

  static equals(a: ComputedCampaignAudience | PlainMessage<ComputedCampaignAudience> | undefined, b: ComputedCampaignAudience | PlainMessage<ComputedCampaignAudience> | undefined): boolean {
    return proto3.util.equals(ComputedCampaignAudience, a, b);
  }
}

/**
 * when a user edits the perfs of a visual manually
 *
 * @generated from message common.proto.VisualEditedPerf
 */
export class VisualEditedPerf extends Message<VisualEditedPerf> {
  /**
   * @generated from field: google.protobuf.Int64Value impressions = 1;
   */
  impressions?: bigint;

  /**
   * @generated from field: google.protobuf.Int64Value reach = 3;
   */
  reach?: bigint;

  /**
   * @generated from field: common.proto.PostInteractions counts = 2;
   */
  counts?: PostInteractions;

  constructor(data?: PartialMessage<VisualEditedPerf>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.VisualEditedPerf";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "impressions", kind: "message", T: Int64Value },
    { no: 3, name: "reach", kind: "message", T: Int64Value },
    { no: 2, name: "counts", kind: "message", T: PostInteractions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VisualEditedPerf {
    return new VisualEditedPerf().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VisualEditedPerf {
    return new VisualEditedPerf().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VisualEditedPerf {
    return new VisualEditedPerf().fromJsonString(jsonString, options);
  }

  static equals(a: VisualEditedPerf | PlainMessage<VisualEditedPerf> | undefined, b: VisualEditedPerf | PlainMessage<VisualEditedPerf> | undefined): boolean {
    return proto3.util.equals(VisualEditedPerf, a, b);
  }
}

/**
 * @generated from message common.proto.CampaignTrackingSettingsPb
 */
export class CampaignTrackingSettingsPb extends Message<CampaignTrackingSettingsPb> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: common.proto.LogicOperator logic_operator = 2;
   */
  logicOperator = LogicOperator.UNKNOWN_OPERATOR;

  /**
   * @generated from field: repeated common.proto.CampaignTrackingSettingsByKeywordTypePb settings_by_keyword_types = 3;
   */
  settingsByKeywordTypes: CampaignTrackingSettingsByKeywordTypePb[] = [];

  constructor(data?: PartialMessage<CampaignTrackingSettingsPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CampaignTrackingSettingsPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "logic_operator", kind: "enum", T: proto3.getEnumType(LogicOperator) },
    { no: 3, name: "settings_by_keyword_types", kind: "message", T: CampaignTrackingSettingsByKeywordTypePb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignTrackingSettingsPb {
    return new CampaignTrackingSettingsPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignTrackingSettingsPb {
    return new CampaignTrackingSettingsPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignTrackingSettingsPb {
    return new CampaignTrackingSettingsPb().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignTrackingSettingsPb | PlainMessage<CampaignTrackingSettingsPb> | undefined, b: CampaignTrackingSettingsPb | PlainMessage<CampaignTrackingSettingsPb> | undefined): boolean {
    return proto3.util.equals(CampaignTrackingSettingsPb, a, b);
  }
}

/**
 * @generated from message common.proto.CampaignTrackingSettingsByKeywordTypePb
 */
export class CampaignTrackingSettingsByKeywordTypePb extends Message<CampaignTrackingSettingsByKeywordTypePb> {
  /**
   * @generated from field: common.proto.CampaignKeywordType keyword_type = 1;
   */
  keywordType = CampaignKeywordType.CAMPAIGN_KEYWORD_TYPE_UNKNOWN;

  /**
   * @generated from field: common.proto.LogicOperator logic_operator = 2;
   */
  logicOperator = LogicOperator.UNKNOWN_OPERATOR;

  /**
   * @generated from field: repeated common.proto.CampaignKeyword keywords = 3;
   */
  keywords: CampaignKeyword[] = [];

  constructor(data?: PartialMessage<CampaignTrackingSettingsByKeywordTypePb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CampaignTrackingSettingsByKeywordTypePb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "keyword_type", kind: "enum", T: proto3.getEnumType(CampaignKeywordType) },
    { no: 2, name: "logic_operator", kind: "enum", T: proto3.getEnumType(LogicOperator) },
    { no: 3, name: "keywords", kind: "message", T: CampaignKeyword, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CampaignTrackingSettingsByKeywordTypePb {
    return new CampaignTrackingSettingsByKeywordTypePb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CampaignTrackingSettingsByKeywordTypePb {
    return new CampaignTrackingSettingsByKeywordTypePb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CampaignTrackingSettingsByKeywordTypePb {
    return new CampaignTrackingSettingsByKeywordTypePb().fromJsonString(jsonString, options);
  }

  static equals(a: CampaignTrackingSettingsByKeywordTypePb | PlainMessage<CampaignTrackingSettingsByKeywordTypePb> | undefined, b: CampaignTrackingSettingsByKeywordTypePb | PlainMessage<CampaignTrackingSettingsByKeywordTypePb> | undefined): boolean {
    return proto3.util.equals(CampaignTrackingSettingsByKeywordTypePb, a, b);
  }
}


import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  input,
  model,
  output,
} from '@angular/core';
import { ContentMimeType } from '@frontend2/proto/common/proto/common_pb';
import { GenerikSnippet_GenerikUrl } from '@frontend2/proto/librarian/proto/users_pb';
import { DISABLE_IMAGE_DEFER_VIEWPORT } from '../../image/image';
import { ObjectFit } from '../../utils';
import { LeftyPostImageComponent } from './post-image.component';
import { LeftyPostVideoComponent } from './post-video.component';

@Component({
  selector: 'lefty-post-media',
  standalone: true,
  imports: [LeftyPostVideoComponent, LeftyPostImageComponent],
  template: `
    @if (imageIsVisible()) {
      <lefty-post-image
        class="media"
        [urls]="urls()"
        [asThumbnail]="asThumbnail()"
        [objectFit]="objectFit()"
        (error$)="this.error$.emit($event)"
      ></lefty-post-image>
    }

    @if (videoIsVisible()) {
      <lefty-post-video
        class="media"
        [urls]="urls()"
        [asThumbnail]="asThumbnail()"
        [objectFit]="objectFit()"
        (error$)="handleVideoError($event)"
        [muted]="mutedVideo()"
        [playOnClick]="playVideoOnClick()"
      ></lefty-post-video>
    }
  `,
  styleUrls: ['./post-media.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LeftyPostMediaComponent {
  readonly disableDeferLoading = inject(DISABLE_IMAGE_DEFER_VIEWPORT, {
    optional: true,
  });

  readonly urls = input<GenerikSnippet_GenerikUrl[]>([]);
  readonly objectFit = input<ObjectFit>('cover');
  readonly asThumbnail = input(false);
  readonly fallbackOnCaption = input(false);
  readonly mutedVideo = input(false);
  readonly playVideoOnClick = input(false);

  readonly imageOnly = model(false);

  readonly hasVideo = computed(() =>
    this.urls().some((u) => u.mimeType === ContentMimeType.VIDEO),
  );

  readonly imageIsVisible = computed(
    () => this.imageOnly() || this.hasVideo() === false,
  );

  readonly videoIsVisible = computed(
    () => this.imageOnly() === false && this.hasVideo(),
  );

  readonly error$ = output<Event | 'timeout'>();

  handleVideoError(event: Event | 'timeout'): void {
    if (this.imageOnly() === false) {
      this.imageOnly.set(true);
      return;
    }
    this.error$.emit(event);
  }
}

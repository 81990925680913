//
//This file defines the registered_author service.
//It is consumed by the hugo/influencers ruby application.

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/registered_author.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AcceptInsightsRequest, AcceptInsightsResponse, CollaborationDetailsResponse, CollaborationListResponse, CreateAccountRequest, GetLandingPageProductsRequest, GetLandingPageProductsResponse, LandingPageInfo, LinkNetworkRequest, LoggedAuthorV2, LoginInfluencerRequest, RegisterAccountLinkRequest, ResetPasswordInfluencerRequest, SignupInfluencerRequest, StripeRequest } from "./registered_author_pb";
import { BoolValue, Empty, Int64Value, MethodKind, StringValue } from "@bufbuild/protobuf";
import { ResolveUserNameRespV2 } from "./users_pb";
import { CreatorTiniestCard } from "./creators_pb";
import { RegisteredAuthorProductRequestPb } from "./registered_author_product_requests_pb";

/**
 * The service definition.
 *
 * @generated from service librarian.proto.RegisteredAuthor
 */
export const RegisteredAuthor = {
  typeName: "librarian.proto.RegisteredAuthor",
  methods: {
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.AcceptInstagramInsights
     */
    acceptInstagramInsights: {
      name: "AcceptInstagramInsights",
      I: AcceptInsightsRequest,
      O: AcceptInsightsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.EmailSignupV2
     */
    emailSignupV2: {
      name: "EmailSignupV2",
      I: SignupInfluencerRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.EmailLoginV2
     */
    emailLoginV2: {
      name: "EmailLoginV2",
      I: LoginInfluencerRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.IsAuthorLoggedV2
     */
    isAuthorLoggedV2: {
      name: "IsAuthorLoggedV2",
      I: Empty,
      O: LoggedAuthorV2,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.Logout
     */
    logout: {
      name: "Logout",
      I: Empty,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * request contains landing page id
     *
     * @generated from rpc librarian.proto.RegisteredAuthor.AcceptInvitationOrApply
     */
    acceptInvitationOrApply: {
      name: "AcceptInvitationOrApply",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * request contains landing page id
     *
     * @generated from rpc librarian.proto.RegisteredAuthor.RejectInvitation
     */
    rejectInvitation: {
      name: "RejectInvitation",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Get landing page public infos from encrypted id.
     *
     * @generated from rpc librarian.proto.RegisteredAuthor.GetLandingPagePublicInfo
     */
    getLandingPagePublicInfo: {
      name: "GetLandingPagePublicInfo",
      I: StringValue,
      O: LandingPageInfo,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.GetCollaborationDetails
     */
    getCollaborationDetails: {
      name: "GetCollaborationDetails",
      I: StringValue,
      O: CollaborationDetailsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.GetCollaborationList
     */
    getCollaborationList: {
      name: "GetCollaborationList",
      I: Empty,
      O: CollaborationListResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.SendResetPasswordLinkAPI
     */
    sendResetPasswordLinkAPI: {
      name: "SendResetPasswordLinkAPI",
      I: StringValue,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.IsResetPasswordTokenValidAPI
     */
    isResetPasswordTokenValidAPI: {
      name: "IsResetPasswordTokenValidAPI",
      I: StringValue,
      O: BoolValue,
      kind: MethodKind.Unary,
    },
    /**
     * it returns a token to subscribe
     *
     * @generated from rpc librarian.proto.RegisteredAuthor.LinkNetwork
     */
    linkNetwork: {
      name: "LinkNetwork",
      I: LinkNetworkRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.SignInWithInstagram
     */
    signInWithInstagram: {
      name: "SignInWithInstagram",
      I: StringValue,
      O: ResolveUserNameRespV2,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.GetInstagramProfileInfo
     */
    getInstagramProfileInfo: {
      name: "GetInstagramProfileInfo",
      I: StringValue,
      O: CreatorTiniestCard,
      kind: MethodKind.Unary,
    },
    /**
     * request contains creator leftyId
     *
     * @generated from rpc librarian.proto.RegisteredAuthor.ConfirmSignInWithInstagram
     */
    confirmSignInWithInstagram: {
      name: "ConfirmSignInWithInstagram",
      I: StringValue,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.ResetPasswordAPI
     */
    resetPasswordAPI: {
      name: "ResetPasswordAPI",
      I: ResetPasswordInfluencerRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.CreateAccount
     */
    createAccount: {
      name: "CreateAccount",
      I: CreateAccountRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * This endpoint returns the Stripe account link for the creator's Express on-boarding via Stripe.
     * More details at https://stripe.com/docs/connect/express-accounts
     *
     * @generated from rpc librarian.proto.RegisteredAuthor.GetRegisterAccountLink
     */
    getRegisterAccountLink: {
      name: "GetRegisterAccountLink",
      I: RegisterAccountLinkRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Returns True if Author KYC was successful on Stripe, and updates KYC status on SQL.
     *
     * @generated from rpc librarian.proto.RegisteredAuthor.VerifyAuthorStripe
     */
    verifyAuthorStripe: {
      name: "VerifyAuthorStripe",
      I: StripeRequest,
      O: BoolValue,
      kind: MethodKind.Unary,
    },
    /**
     * Updates payment permission on AuthorPermission (only once per author - user)
     *
     * @generated from rpc librarian.proto.RegisteredAuthor.GrantPaymentPermission
     */
    grantPaymentPermission: {
      name: "GrantPaymentPermission",
      I: StringValue,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Return the author login Link for the Stripe dashboard
     *
     * @generated from rpc librarian.proto.RegisteredAuthor.GetStripeDashboardLink
     */
    getStripeDashboardLink: {
      name: "GetStripeDashboardLink",
      I: StripeRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.AddOrUpdateProductSeedingRequest
     */
    addOrUpdateProductSeedingRequest: {
      name: "AddOrUpdateProductSeedingRequest",
      I: RegisteredAuthorProductRequestPb,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.GetLandingPageProductsAPI
     */
    getLandingPageProductsAPI: {
      name: "GetLandingPageProductsAPI",
      I: GetLandingPageProductsRequest,
      O: GetLandingPageProductsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.RegisteredAuthor.MarkCollaborationAsDraft
     */
    markCollaborationAsDraft: {
      name: "MarkCollaborationAsDraft",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;


import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { Local } from '@frontend2/proto/librarian/proto/local_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LocalClient = PromiseClient<typeof Local>;

export const LocalClientProvider = new InjectionToken('LocalClient', {
  factory: (): LocalClient =>
    createPromiseClient(Local, inject(GRPC_TRANSPORT)),
});

export function injectLibrarianLocalClient(): LocalClient {
  return inject(LocalClientProvider);
}

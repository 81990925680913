// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/registered_author_product_requests.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetRegisteredAuthorsProductRequestsRequest, GetRegisteredAuthorsProductRequestsResponse, InitRegisteredAuthorProductRequestsResponse, RegisteredAuthorProductRequestPb, SendRegisteredAuthorProductRequestRequest, SendRegisteredAuthorProductRequestResponse, UpdateRegisteredAuthorProductRequestStatusRequest } from "./registered_author_product_requests_pb";
import { Empty, Int64Value, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service librarian.proto.LibrarianRegisteredAuthorProductRequests
 */
export const LibrarianRegisteredAuthorProductRequests = {
  typeName: "librarian.proto.LibrarianRegisteredAuthorProductRequests",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianRegisteredAuthorProductRequests.GetRegisteredAuthorsProductsRequestsAPI
     */
    getRegisteredAuthorsProductsRequestsAPI: {
      name: "GetRegisteredAuthorsProductsRequestsAPI",
      I: GetRegisteredAuthorsProductRequestsRequest,
      O: GetRegisteredAuthorsProductRequestsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianRegisteredAuthorProductRequests.GetRegisteredAuthorsProductRequestAPI
     */
    getRegisteredAuthorsProductRequestAPI: {
      name: "GetRegisteredAuthorsProductRequestAPI",
      I: Int64Value,
      O: RegisteredAuthorProductRequestPb,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianRegisteredAuthorProductRequests.InitDataOfProductRequestsAPI
     */
    initDataOfProductRequestsAPI: {
      name: "InitDataOfProductRequestsAPI",
      I: Empty,
      O: InitRegisteredAuthorProductRequestsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianRegisteredAuthorProductRequests.UpdateRegisteredAuthorProductRequestStatusRequestAPI
     */
    updateRegisteredAuthorProductRequestStatusRequestAPI: {
      name: "UpdateRegisteredAuthorProductRequestStatusRequestAPI",
      I: UpdateRegisteredAuthorProductRequestStatusRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianRegisteredAuthorProductRequests.SendRegisteredAuthorProductRequestAPI
     */
    sendRegisteredAuthorProductRequestAPI: {
      name: "SendRegisteredAuthorProductRequestAPI",
      I: SendRegisteredAuthorProductRequestRequest,
      O: SendRegisteredAuthorProductRequestResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;


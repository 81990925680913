<div
  class="initial"
  [class.dark]="isDark"
  [class.square]="isSquare"
  [class]="size"
  [style.background]="!isDark ? initialBackgroundColor : ''"
  [style.color]="!isDark ? initialColor : ''"
  [class.ghost]="isGhost"
>
  {{ initial }}
</div>

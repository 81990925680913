import { LeftyFormat, Messages } from '@frontend2/core';
import { GenerikInfoCard } from '@frontend2/proto/librarian/proto/creators_pb';
import { InfluencerStatsNumber } from './influencer-stats-number.models';

const engagementWarningMessage = $localize`There are not enough recent posts to compute Engagement Rate`;

const avgViewsWarningMessage = $localize`There are not enough recent posts to compute Average Views`;

export function createFollowersStatsNumber(
  info: GenerikInfoCard,
): InfluencerStatsNumber {
  return {
    value: LeftyFormat.followers(info.count?.followersCount ?? 0, {
      compact: true,
    }),
    label: $localize`Followers`,
  };
}

export function createAvgViewsStatsNumber(
  info: GenerikInfoCard,
): InfluencerStatsNumber {
  return {
    value: LeftyFormat.followers(info.stats?.avgViews ?? 0, { compact: true }),
    label: Messages.avgViews,
    warningMessage:
      info.stats?.avgViews === 0 ? avgViewsWarningMessage : undefined,
  };
}

export function createEngagementStatsNumber(
  info: GenerikInfoCard,
): InfluencerStatsNumber {
  return {
    value: LeftyFormat.percent(info.stats?.engagement ?? 0),
    label: Messages.engRate,
    warningMessage:
      info.stats?.engagement === 0 ? engagementWarningMessage : undefined,
  };
}

import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  Input,
} from '@angular/core';
import {
  createGhostInfluencerCasting,
  getInfluencerNetworkInfo,
  isHiddenInMetricSettings,
  isNotEmptyArray,
  LeftyFormat,
  Messages,
} from '@frontend2/core';
import {
  ComputedGenerikStats,
  Network,
} from '@frontend2/proto/common/proto/common_pb';
import {
  CastingOptionsPb,
  CastingStatisticsMetricEnum,
} from '@frontend2/proto/librarian/proto/casting_pb';
import {
  GenerikInfoCard,
  GenerikInfoCard_GenerikCount,
  NetworkComputedGenerikStats,
} from '@frontend2/proto/librarian/proto/creators_pb';
import { NetworkConfig } from '../../config/config.models';
import { NetworkConfigs } from '../../config/config.service';
import { LeftyComponent } from '../../utils';
import { NgIf, NgFor } from '@angular/common';

const MAX_PRIMARY_STATS = 2;

@Component({
  selector: 'casting-influencer-stats',
  templateUrl: './influencer-stats.component.html',
  styleUrls: ['./influencer-stats.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, NgFor],
})
export class CastingInfluencerStatsComponent extends LeftyComponent {
  readonly networkConfigs = inject(NetworkConfigs);

  @Input()
  castingOptions = new CastingOptionsPb();

  @Input()
  loading = false;

  @Input()
  statsMap = new Map<Network, NetworkComputedGenerikStats>();

  @Input()
  influencer = createGhostInfluencerCasting();

  @Input()
  network = Network.NETWORK_UNKNOWN;

  get config(): NetworkConfig {
    return this.networkConfigs.of(this.network);
  }

  @Input()
  @HostBinding('class.mobile')
  mobileLayout = false;

  getInfluencerStats(network: Network): ComputedGenerikStats {
    return this.statsMap.get(network)?.stats ?? new ComputedGenerikStats();
  }

  getInfluencerEstReach(network: Network): bigint {
    return this.statsMap.get(network)?.estimatedReach ?? BigInt(0);
  }

  getInfluencerAvgReach(network: Network): bigint {
    return this.statsMap.get(network)?.averageReach ?? BigInt(0);
  }

  // we want to display the biggest avg impressions of network post types
  getInfluencerAvgImpressions(network: Network): number {
    const avgImpressions =
      this.statsMap
        .get(network)
        ?.postTypesImpressions.map((e) => Number(e.impressions)) ?? [];

    return isNotEmptyArray(avgImpressions) ? Math.max(...avgImpressions) : 0;
  }

  getInfluencerAverageCoverage(network: Network): number {
    return this.statsMap.get(network)?.averageCoverage ?? 0;
  }

  get info(): GenerikInfoCard {
    return getInfluencerNetworkInfo(this.influencer, this.network);
  }

  get count(): GenerikInfoCard_GenerikCount {
    return this.info.count ?? new GenerikInfoCard_GenerikCount();
  }

  get stats(): ComputedGenerikStats {
    return this.getInfluencerStats(this.network);
  }

  private get engagementScore(): number {
    return this.stats.engagementScore ?? 0;
  }

  private get avgViews(): number {
    return this.stats.views?.recentAvg ?? 0;
  }

  private get avgReshares(): number {
    return this.stats.shares?.recentAvg ?? 0;
  }

  private get saturationRate(): number {
    return this.stats.saturationRate ?? 0;
  }

  get engagementRate(): string {
    return LeftyFormat.engagementRate(this.engagementScore);
  }

  get isFollowersVisible(): boolean {
    return this.isVisibleInMetricSettings(
      CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_FOLLOWERS,
    );
  }

  get isEngagementRateVisible(): boolean {
    return this.isVisibleInMetricSettings(
      CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_ENGAGEMENT_RATE,
    );
  }

  get isAverageViewsVisible(): boolean {
    return this.isVisibleInMetricSettings(
      CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_AVERAGE_VIEWS,
    );
  }

  get isAverageImpressionsVisible(): boolean {
    return this.isVisibleInMetricSettings(
      CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_AVERAGE_IMPRESSIONS,
    );
  }

  get isAverageReachVisible(): boolean {
    return this.isVisibleInMetricSettings(
      CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_AVERAGE_REACH,
    );
  }

  get isAverageCoverageVisible(): boolean {
    return this.isVisibleInMetricSettings(
      CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_AVERAGE_COVERAGE,
    );
  }

  get isSaturationRateVisible(): boolean {
    return this.isVisibleInMetricSettings(
      CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_SATURATION_RATE,
    );
  }

  get isAverageResharesVisible(): boolean {
    return this.isVisibleInMetricSettings(
      CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_AVERAGE_RESHARES,
    );
  }

  get allStats(): ValueLabel[] {
    const stats: ValueLabel[] = [];

    if (this.isFollowersVisible) {
      stats.push({
        label: Messages.followersLabel(this.count.followersCount),
        value: LeftyFormat.followers(this.count.followersCount, {
          compact: true,
        }),
      });
    }

    if (this.config.supportEngagementRate && this.isEngagementRateVisible) {
      stats.push({
        label: Messages.engagementRate,
        value: LeftyFormat.engagementRate(this.engagementScore),
      });
    }

    if (this.config.supportViews && this.isAverageViewsVisible) {
      stats.push({
        label: $localize`Average Views`,
        value: LeftyFormat.views(this.avgViews),
      });
    } else if (
      this.config.supportViews === false &&
      this.isAverageImpressionsVisible
    ) {
      stats.push({
        label: $localize`Average Impressions`,
        value: LeftyFormat.impressions(
          this.getInfluencerAvgImpressions(this.network),
        ),
      });
    }

    if (this.isAverageReachVisible) {
      stats.push({
        label: Messages.averageReach,
        value: LeftyFormat.avgReach(this.getInfluencerAvgReach(this.network)),
      });
    }

    if (this.config.supportAvgCoverage && this.isAverageCoverageVisible) {
      stats.push({
        label: Messages.averageCoverage,
        value: LeftyFormat.avgCoverage(
          this.getInfluencerAverageCoverage(this.network),
        ),
      });
    }

    if (this.config.supportSaturationRate && this.isSaturationRateVisible) {
      stats.push({
        label: Messages.saturationRate,
        value: LeftyFormat.saturationRate(this.saturationRate),
      });
    }

    if (this.config.supportReshares && this.isAverageResharesVisible) {
      stats.push({
        label: Messages.averageReshares,
        value: LeftyFormat.reshares(this.avgReshares),
      });
    }

    return stats;
  }

  get primaryStats(): ValueLabel[] {
    return this.allStats.slice(0, MAX_PRIMARY_STATS);
  }

  get secondaryStats(): ValueLabel[] {
    return this.allStats.splice(MAX_PRIMARY_STATS);
  }

  private isVisibleInMetricSettings(
    metric: CastingStatisticsMetricEnum,
  ): boolean {
    return !isHiddenInMetricSettings(
      metric,
      this.castingOptions.statistics?.statisticsMetrics ?? [],
    );
  }

  get hasStats(): boolean {
    return isNotEmptyArray(this.allStats);
  }
}

interface ValueLabel {
  readonly value: string;
  readonly label: string;
}

// This file defines data required to initialize a talkJS discussion.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/talkjs.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";

/**
 * @generated from enum common.proto.TalkJSConfiguration
 */
export enum TalkJSConfiguration {
  /**
   * @generated from enum value: NOT_SET = 0;
   */
  NOT_SET = 0,

  /**
   * @generated from enum value: CLIENT = 1;
   */
  CLIENT = 1,

  /**
   * @generated from enum value: CLIENT_V2 = 3;
   */
  CLIENT_V2 = 3,

  /**
   * @generated from enum value: INFLUENCER = 2;
   */
  INFLUENCER = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(TalkJSConfiguration)
proto3.util.setEnumType(TalkJSConfiguration, "common.proto.TalkJSConfiguration", [
  { no: 0, name: "NOT_SET" },
  { no: 1, name: "CLIENT" },
  { no: 3, name: "CLIENT_V2" },
  { no: 2, name: "INFLUENCER" },
]);

/**
 * @generated from enum common.proto.WebhookType
 */
export enum WebhookType {
  /**
   * @generated from enum value: WEBHOOK_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: WEBHOOK_TYPE_NOTIFICATION_TRIGGERED = 1;
   */
  NOTIFICATION_TRIGGERED = 1,

  /**
   * @generated from enum value: WEBHOOK_TYPE_MESSAGE_READ = 2;
   */
  MESSAGE_READ = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(WebhookType)
proto3.util.setEnumType(WebhookType, "common.proto.WebhookType", [
  { no: 0, name: "WEBHOOK_TYPE_UNKNOWN" },
  { no: 1, name: "WEBHOOK_TYPE_NOTIFICATION_TRIGGERED" },
  { no: 2, name: "WEBHOOK_TYPE_MESSAGE_READ" },
]);

/**
 * The conversation id between a client and an influencer is the base64 encoded version of this proto.
 * This ensures we can construct it quickly and it is safe to embed in JSON etc...
 *
 * @generated from message common.proto.ConversationIdEncoded
 */
export class ConversationIdEncoded extends Message<ConversationIdEncoded> {
  /**
   * @generated from field: int64 user_chatter_id = 1;
   */
  userChatterId = protoInt64.zero;

  /**
   * @generated from field: int64 influencer_chatter_id = 2;
   */
  influencerChatterId = protoInt64.zero;

  constructor(data?: PartialMessage<ConversationIdEncoded>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ConversationIdEncoded";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_chatter_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "influencer_chatter_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConversationIdEncoded {
    return new ConversationIdEncoded().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConversationIdEncoded {
    return new ConversationIdEncoded().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConversationIdEncoded {
    return new ConversationIdEncoded().fromJsonString(jsonString, options);
  }

  static equals(a: ConversationIdEncoded | PlainMessage<ConversationIdEncoded> | undefined, b: ConversationIdEncoded | PlainMessage<ConversationIdEncoded> | undefined): boolean {
    return proto3.util.equals(ConversationIdEncoded, a, b);
  }
}

/**
 * @generated from message common.proto.UserTalkJSConversation
 */
export class UserTalkJSConversation extends Message<UserTalkJSConversation> {
  /**
   * @generated from field: common.proto.UserTalkJSDetails creator = 1;
   */
  creator?: UserTalkJSDetails;

  /**
   * @generated from field: common.proto.UserTalkJSDetails client = 2;
   */
  client?: UserTalkJSDetails;

  /**
   * @generated from field: string conversation_id = 3;
   */
  conversationId = "";

  constructor(data?: PartialMessage<UserTalkJSConversation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.UserTalkJSConversation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator", kind: "message", T: UserTalkJSDetails },
    { no: 2, name: "client", kind: "message", T: UserTalkJSDetails },
    { no: 3, name: "conversation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserTalkJSConversation {
    return new UserTalkJSConversation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserTalkJSConversation {
    return new UserTalkJSConversation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserTalkJSConversation {
    return new UserTalkJSConversation().fromJsonString(jsonString, options);
  }

  static equals(a: UserTalkJSConversation | PlainMessage<UserTalkJSConversation> | undefined, b: UserTalkJSConversation | PlainMessage<UserTalkJSConversation> | undefined): boolean {
    return proto3.util.equals(UserTalkJSConversation, a, b);
  }
}

/**
 * @generated from message common.proto.UserTalkJSConversations
 */
export class UserTalkJSConversations extends Message<UserTalkJSConversations> {
  /**
   * @generated from field: repeated common.proto.UserTalkJSConversation talkjs_conversation = 1;
   */
  talkjsConversation: UserTalkJSConversation[] = [];

  constructor(data?: PartialMessage<UserTalkJSConversations>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.UserTalkJSConversations";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "talkjs_conversation", kind: "message", T: UserTalkJSConversation, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserTalkJSConversations {
    return new UserTalkJSConversations().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserTalkJSConversations {
    return new UserTalkJSConversations().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserTalkJSConversations {
    return new UserTalkJSConversations().fromJsonString(jsonString, options);
  }

  static equals(a: UserTalkJSConversations | PlainMessage<UserTalkJSConversations> | undefined, b: UserTalkJSConversations | PlainMessage<UserTalkJSConversations> | undefined): boolean {
    return proto3.util.equals(UserTalkJSConversations, a, b);
  }
}

/**
 * @generated from message common.proto.UserTalkJSDetails
 */
export class UserTalkJSDetails extends Message<UserTalkJSDetails> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string display_name = 2;
   */
  displayName = "";

  /**
   * @generated from field: google.protobuf.StringValue email = 3;
   */
  email?: string;

  /**
   * @generated from field: common.proto.TalkJSConfiguration configuration = 4;
   */
  configuration = TalkJSConfiguration.NOT_SET;

  /**
   * @generated from field: google.protobuf.StringValue photo_url = 5;
   */
  photoUrl?: string;

  /**
   * This is a server side signature of the id so you can't just go and change 'id' in your
   * browser...
   *
   * @generated from field: string signature = 6;
   */
  signature = "";

  /**
   * Note that this could be bootstrapped only once or even coded in the frontend, but it
   * doesn't cost much to include it on every proto, so we do it for convenience.
   *
   * @generated from field: string app_id = 7;
   */
  appId = "";

  /**
   * If this is false, we know there are no messages in talkJS so we can save loading their expensive
   * code and we can display our beautiful empty state rather than their ugly one :)
   *
   * @generated from field: bool has_conversations = 8;
   */
  hasConversations = false;

  /**
   * @generated from field: int64 campaign_id = 9;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: string campaign_name = 10;
   */
  campaignName = "";

  constructor(data?: PartialMessage<UserTalkJSDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.UserTalkJSDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "display_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "email", kind: "message", T: StringValue },
    { no: 4, name: "configuration", kind: "enum", T: proto3.getEnumType(TalkJSConfiguration) },
    { no: 5, name: "photo_url", kind: "message", T: StringValue },
    { no: 6, name: "signature", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "app_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "has_conversations", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "campaign_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserTalkJSDetails {
    return new UserTalkJSDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserTalkJSDetails {
    return new UserTalkJSDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserTalkJSDetails {
    return new UserTalkJSDetails().fromJsonString(jsonString, options);
  }

  static equals(a: UserTalkJSDetails | PlainMessage<UserTalkJSDetails> | undefined, b: UserTalkJSDetails | PlainMessage<UserTalkJSDetails> | undefined): boolean {
    return proto3.util.equals(UserTalkJSDetails, a, b);
  }
}

/**
 * @generated from message common.proto.Webhook
 */
export class Webhook extends Message<Webhook> {
  /**
   * @generated from field: common.proto.WebhookType type = 1;
   */
  type = WebhookType.UNKNOWN;

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: common.proto.Webhook.User sender = 3;
   */
  sender?: Webhook_User;

  /**
   * @generated from field: common.proto.Webhook.User recipient = 4;
   */
  recipient?: Webhook_User;

  /**
   * @generated from field: common.proto.Webhook.Conversation conversation = 5;
   */
  conversation?: Webhook_Conversation;

  /**
   * only for notification.triggered
   *
   * @generated from field: string notification_id = 7;
   */
  notificationId = "";

  /**
   * @generated from field: repeated common.proto.Webhook.NotifiedMessage messages = 8;
   */
  messages: Webhook_NotifiedMessage[] = [];

  constructor(data?: PartialMessage<Webhook>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Webhook";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(WebhookType) },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sender", kind: "message", T: Webhook_User },
    { no: 4, name: "recipient", kind: "message", T: Webhook_User },
    { no: 5, name: "conversation", kind: "message", T: Webhook_Conversation },
    { no: 7, name: "notification_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "messages", kind: "message", T: Webhook_NotifiedMessage, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Webhook {
    return new Webhook().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Webhook {
    return new Webhook().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Webhook {
    return new Webhook().fromJsonString(jsonString, options);
  }

  static equals(a: Webhook | PlainMessage<Webhook> | undefined, b: Webhook | PlainMessage<Webhook> | undefined): boolean {
    return proto3.util.equals(Webhook, a, b);
  }
}

/**
 * @generated from message common.proto.Webhook.User
 */
export class Webhook_User extends Message<Webhook_User> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: common.proto.TalkJSConfiguration role = 2;
   */
  role = TalkJSConfiguration.NOT_SET;

  constructor(data?: PartialMessage<Webhook_User>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Webhook.User";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "role", kind: "enum", T: proto3.getEnumType(TalkJSConfiguration) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Webhook_User {
    return new Webhook_User().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Webhook_User {
    return new Webhook_User().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Webhook_User {
    return new Webhook_User().fromJsonString(jsonString, options);
  }

  static equals(a: Webhook_User | PlainMessage<Webhook_User> | undefined, b: Webhook_User | PlainMessage<Webhook_User> | undefined): boolean {
    return proto3.util.equals(Webhook_User, a, b);
  }
}

/**
 * @generated from message common.proto.Webhook.Conversation
 */
export class Webhook_Conversation extends Message<Webhook_Conversation> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  constructor(data?: PartialMessage<Webhook_Conversation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Webhook.Conversation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Webhook_Conversation {
    return new Webhook_Conversation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Webhook_Conversation {
    return new Webhook_Conversation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Webhook_Conversation {
    return new Webhook_Conversation().fromJsonString(jsonString, options);
  }

  static equals(a: Webhook_Conversation | PlainMessage<Webhook_Conversation> | undefined, b: Webhook_Conversation | PlainMessage<Webhook_Conversation> | undefined): boolean {
    return proto3.util.equals(Webhook_Conversation, a, b);
  }
}

/**
 * @generated from message common.proto.Webhook.NotifiedMessage
 */
export class Webhook_NotifiedMessage extends Message<Webhook_NotifiedMessage> {
  /**
   * @generated from field: string content = 1;
   */
  content = "";

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 2;
   */
  timestamp?: Timestamp;

  constructor(data?: PartialMessage<Webhook_NotifiedMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Webhook.NotifiedMessage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "timestamp", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Webhook_NotifiedMessage {
    return new Webhook_NotifiedMessage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Webhook_NotifiedMessage {
    return new Webhook_NotifiedMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Webhook_NotifiedMessage {
    return new Webhook_NotifiedMessage().fromJsonString(jsonString, options);
  }

  static equals(a: Webhook_NotifiedMessage | PlainMessage<Webhook_NotifiedMessage> | undefined, b: Webhook_NotifiedMessage | PlainMessage<Webhook_NotifiedMessage> | undefined): boolean {
    return proto3.util.equals(Webhook_NotifiedMessage, a, b);
  }
}


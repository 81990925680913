<lefty-form
  [label]="label"
  [required]="required"
  [helpText]="helpText"
  [helpLink]="helpLink"
  [hintText]="hintText"
  [errorMessage]="errorMessage"
  [tooltip]="tooltip"
>
  <div
    class="input-box"
    (click)="input.focus()"
  >
    @for (chip of chips; track $index) {
      @if (withLogicOperator && $index !== 0) {
        <lefty-operator-chip
          [(value)]="logicOperatorValue"
        ></lefty-operator-chip>
      }
      <lefty-chip
        [chip]="chip"
        [removable]="canRemoveChip"
        (remove$)="deleteChip(chip)"
      >
      </lefty-chip>
    }
    <input
      #input
      class="input"
      focusableElement
      [value]="inputValue"
      (invalid)="$event.preventDefault()"
      [required]="required"
      [disabled]="disabled"
      [attr.placeholder]="placeholder"
      [attr.tabindex]="tabIndex"
      (focus)="handleFocus($event)"
      (blur)="handleBlur($event)"
      (input)="handleInputChange(input.value)"
      (keypress)="keypress$.next($event)"
      (keydown)="keydown$.next($event)"
      (keyup)="keyup$.next($event)"
    />
  </div>
</lefty-form>

<ng-content select="[leading-content]"></ng-content>

<div
  class="icon-container"
  *ngIf="hasIcon"
>
  <lefty-icon
    class="icon"
    [icon]="icon"
    size="medium"
  ></lefty-icon>
</div>

<div
  *ngIf="hasLabel"
  class="name"
>
  {{ label }}&nbsp;
</div>

<div
  *ngIf="hasFormattedValue"
  class="value"
>
  {{ formattedValue }}
</div>

@if (!isGhost) {
  @if (removable()) {
    <!-- tabindex="-1" to support focus on div -->
    <div
      class="action"
      *ngIf="removable() && !isGhost"
      tabindex="-1"
      (click)="removeChip($event)"
    >
      <lefty-icon
        icon="clear"
        size="medium"
      >
      </lefty-icon>
    </div>
  } @else {
    <div
      class="action custom-action"
      tabindex="-1"
      #customAction
    >
      <ng-content select="lefty-icon"></ng-content>
    </div>
  }
}

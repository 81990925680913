import { datadogRum } from '@datadog/browser-rum';
import { LoggedBootstrapping } from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import { SubscriptionType } from '@frontend2/proto/librarian/proto/payments_pb';
import {
  LoggedAuthorV2,
  StripeStatus,
} from '@frontend2/proto/librarian/proto/registered_author_pb';
import * as Sentry from '@sentry/browser';
import { getEnumName } from '../utils';

export function setupTracingUserContext(
  leftyUser: LoggedBootstrapping | LoggedAuthorV2,
): void {
  const user =
    leftyUser instanceof LoggedBootstrapping
      ? {
          email: leftyUser.email,
          subscription: getEnumName(
            SubscriptionType,
            leftyUser.subscriptionType,
          ),
          is_logged: leftyUser.isLogged,
          is_admin: leftyUser.isAdmin,
          is_impersonating: leftyUser.isImpersonating,
          team_owner_email: leftyUser.teamOwnerEmail,
          space_idx_hint: leftyUser.spaceIdxHint,
        }
      : {
          id: `${leftyUser.authorId}`,
          email: leftyUser.email,
          is_logged: leftyUser.logged,
          stripe_status: getEnumName(StripeStatus, leftyUser.stripeStatus),
        };

  datadogRum.setUser(user);
  Sentry.setUser(user);
}

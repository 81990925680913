import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Directive,
  ElementRef,
  Injectable,
  Input,
  OnInit,
  Provider,
  Renderer2,
  Self,
  Type,
  inject,
} from '@angular/core';

import { LocationStrategy } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  ActivationEnd,
  ChildrenOutletContexts,
  DetachedRouteHandle,
  EventType,
  IsActiveMatchOptions,
  NavigationBehaviorOptions,
  NavigationExtras,
  OutletContext,
  RouteReuseStrategy,
  Router,
  RouterLink,
  RouterLinkActive,
  RouterOutlet,
  RoutesRecognized,
  UrlTree,
} from '@angular/router';
import {
  IS_IN_IFRAME,
  LeftyParentAppBridge,
  MapStringString,
  RouteParams,
  isNil,
  isNotEmptyString,
  isNotNil,
} from '@frontend2/core';
import {
  Observable,
  filter,
  firstValueFrom,
  map,
  pairwise,
  startWith,
} from 'rxjs';
import { RouteBloc } from './bloc';
import { injectActivatedRoute, injectRouter } from './inject.helpers';
import { LayoutHeight } from './layout-observer.service';
import { LeftyComponent } from './utils';

export type RouteCommand = string | number | bigint;

export interface NavItem {
  readonly label: string;
  readonly icon: string;
  readonly link: string | RouteCommand[];
  readonly isHidden: boolean;
  readonly children: NavItem[];
  readonly isActive: boolean;
  readonly isExternalLink: boolean;
  readonly hideOnBreakpoint?: LayoutHeight;
  readonly activeForRoutes?: string[];
}

export function buildNavItem(
  label: string,
  link: string | RouteCommand[],
  options?: {
    icon?: string;
    isHidden?: boolean;
    children?: NavItem[];
    isActive?: boolean;
    hideOnBreakpoint?: LayoutHeight;
    isExternalLink?: boolean;
    activeForRoutes?: string[];
  },
): NavItem {
  const nav: NavItem = {
    label: label,
    icon: options?.icon ?? '',
    link: link,
    isHidden: options?.isHidden ?? false,
    children: options?.children ?? [],
    isActive: options?.isActive ?? false,
    isExternalLink: options?.isExternalLink ?? false,
    hideOnBreakpoint: options?.hideOnBreakpoint,
    activeForRoutes: options?.activeForRoutes,
  };

  return nav;
}

export function getRouteParams(snapshot: ActivatedRouteSnapshot): RouteParams {
  let parameters: MapStringString = snapshot.params;
  let queryParameters: MapStringString = snapshot.queryParams;

  let parent = snapshot.parent;
  while (parent) {
    parameters = {
      ...parameters,
      ...parent.params,
    };

    queryParameters = {
      ...queryParameters,
      ...parent.queryParams,
    };

    parent = parent.parent;
  }

  return {
    parameters,
    queryParameters,
  };
}

@Injectable()
export class LeftyRouteReuseStrategy implements RouteReuseStrategy {
  private cache = new Map<Type<unknown> | string | null, DetachedRouteHandle>();

  private _shouldCacheComponent(route: ActivatedRouteSnapshot): boolean {
    return route.routeConfig?.data?.['cacheComponent'] === true;
  }

  public shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return this._shouldCacheComponent(route);
  }

  public store(
    route: ActivatedRouteSnapshot,
    handle: DetachedRouteHandle | null,
  ): void {
    const key = route.component;
    if (isNil(handle)) {
      this.cache.delete(key);
    } else {
      this.cache.set(key, handle);
    }
  }

  public shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return this._shouldCacheComponent(route) && this.cache.has(route.component);
  }

  public retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return this._shouldCacheComponent(route)
      ? (this.cache.get(route.component) ?? null)
      : null;
  }

  public shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    current: ActivatedRouteSnapshot,
  ): boolean {
    return future.routeConfig === current.routeConfig;
  }
}

export function observeRouteActivation$(
  router: Router,
  route: ActivatedRoute,
): Observable<RouteParams> {
  return router.events.pipe(
    filter((ev) => {
      return (
        ev.type === EventType.ActivationEnd &&
        ev.snapshot.component === route.component
      );
    }),
    map((ev) => (ev as ActivationEnd).snapshot),
    startWith(route.snapshot),
    map(getRouteParams),
  );
}

export function observePreviousRoutesRecognized$(
  router: Router,
): Observable<RoutesRecognized> {
  return router.events.pipe(
    filter((evt) => evt.type === EventType.RoutesRecognized),
    pairwise(),
    map((routes) => routes[0] as RoutesRecognized),
  );
}

/// Try to reproduce `onActivate` feature of old Dart router
///
/// trigger onActivate method each time a router parameters or query parameters change
@Component({
  template: '',
})
export abstract class LeftyRouteComponent
  extends LeftyComponent
  implements OnInit
{
  readonly route = injectActivatedRoute();
  readonly destroyRef = inject(DestroyRef);
  readonly router = injectRouter();

  ngOnInit(): void {
    observeRouteActivation$(this.router, this.route)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((routeParams) => this.onActivate(routeParams));
  }

  abstract onActivate(params: RouteParams): void | Promise<void>;
}

/// Base class to implements a Route component link to a single [RouteBloc]
///
/// Automatically call activate/deactivate method
/// And trigger change detection when state change
@Component({
  template: '',
})
export abstract class RouteBlocComponent<Request, ViewState>
  extends LeftyRouteComponent
  implements OnDeactivate
{
  constructor(readonly routeViewBloc: RouteBloc<Request, ViewState>) {
    super();
  }

  readonly state = this.routeViewBloc.state;
  readonly routeLoading = this.routeViewBloc.isLoading;
  readonly routeActive = this.routeViewBloc.isActive;
  readonly request = this.routeViewBloc.request;
  readonly viewState = this.routeViewBloc.viewState;

  async onActivate(params: RouteParams): Promise<void> {
    await this.routeViewBloc.activate(params);
  }

  onDeactivate(): void {
    this.routeViewBloc.deactivate();
  }
}

@Directive({
  selector: 'router-outlet[leftyRouterLifecycle]',
  standalone: true,
})
export class LeftyRouterLifecycleDirective {
  constructor(private routerOutlet: RouterOutlet) {
    // Methods need to be patched before content init
    this.patchMethods();
  }

  /**
   * This method patches both onDetach method to call the relevant
   * hooks in the child component before or after running the existing logic
   * @private
   */
  private patchMethods(): void {
    // Save the original detach method
    const originalDetach = this.routerOutlet.detach;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.routerOutlet.detach = (): OmitThisParameter<any> => {
      const instance = this.routerOutlet.component as OnDeactivate;
      if (instance && typeof instance.onDeactivate === 'function') {
        instance.onDeactivate();
      }
      // return the detached component with the original method
      return originalDetach.bind(this.routerOutlet)();
    };
  }
}

export interface CanDeactivateComponent {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

export interface OnDeactivate {
  onDeactivate?: () => void;
}

export function canDeactivateGuard(
  component: CanDeactivateComponent,
): Observable<boolean> | Promise<boolean> | boolean {
  // if app is in iframe, the canDeactivate check is handle by LeftyRouter
  if (IS_IN_IFRAME) {
    return true;
  }
  return component.canDeactivate ? component.canDeactivate() : true;
}

// Override current location strategy when app is in iframe
// so we correctly build external url
//
// see prepareExternalUrl function override
@Injectable()
export class LeftyIframeLocationStrategy implements LocationStrategy {
  readonly parentAppBridge = inject(LeftyParentAppBridge);

  readonly locationStrategy = inject(LocationStrategy, { skipSelf: true });

  readonly path = this.locationStrategy.path;
  readonly getState = this.locationStrategy.getState;
  readonly pushState = this.locationStrategy.pushState;
  readonly replaceState = this.locationStrategy.replaceState;
  readonly forward = this.locationStrategy.forward;
  readonly back = this.locationStrategy.back;
  readonly historyGo = this.locationStrategy.historyGo;
  readonly onPopState = this.locationStrategy.onPopState;
  readonly getBaseHref = this.locationStrategy.getBaseHref;

  private _joinWithSlash(a: string, b: string): string {
    if (a.endsWith('/') || b.startsWith('/')) {
      return a + b;
    }

    return a + '/' + b;
  }

  prepareExternalUrl(internal: string): string {
    const parentAppOrigin = this.parentAppBridge.locationOrigin;

    if (isNotEmptyString(parentAppOrigin)) {
      return this._joinWithSlash(parentAppOrigin, internal);
    }

    return this.locationStrategy.prepareExternalUrl(internal);
  }
}

/**
 * @deprecated
 * This class is deprecated and will be removed soon.
 * Use `RouterLink` and `LocationStrategy` instead.
 */
@Directive({
  selector: '[leftyRouterLink]',
  standalone: true,
})
export class LeftyRouterLinkDirective extends RouterLink {
  @Input()
  // use any, this is how ANgular define the API
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  set leftyRouterLink(commands: any[] | string | null | undefined) {
    this.routerLink = commands;
  }
}

/**
 * @deprecated
 * This class is deprecated and will be removed soon.
 * Use `RouterLinkActive` instead.
 */
@Directive({
  selector: '[leftyRouterLink][leftyRouterLinkActive]',
  exportAs: 'leftyRouterLinkActive',
  standalone: true,
})
export class LeftyRouterLinkActiveDirective extends RouterLinkActive {
  constructor(
    router: Router,
    element: ElementRef,
    renderer: Renderer2,
    cdr: ChangeDetectorRef,
    @Self() routerLink: LeftyRouterLinkDirective,
  ) {
    super(router, element, renderer, cdr, routerLink);
  }

  @Input()
  set leftyRouterLinkActive(data: string[] | string) {
    this.routerLinkActive = data;
  }

  @Input()
  set leftyRouterLinkActiveOptions(
    options: IsActiveMatchOptions | { exact: boolean },
  ) {
    this.routerLinkActiveOptions = options;
  }
}

// The LeftyRouter is meant to be use when the app is in Iframe
// it override every navigation function, sending 'navigate' event to the parent app
@Injectable()
export class LeftyRouter extends Router {
  readonly parentAppBridge = inject(LeftyParentAppBridge);
  readonly outlets = inject(ChildrenOutletContexts);

  constructor() {
    super();
  }

  override async navigate(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    commands: any[],
    extras?: NavigationExtras,
  ): Promise<boolean> {
    this.navigateByUrl(this.createUrlTree(commands, extras).toString(), extras);
    return true;
  }

  private async _canDeactivate(
    outletContext: OutletContext | null,
  ): Promise<boolean> {
    const outlet = outletContext?.outlet;
    if (
      isNil(outlet) ||
      outlet.isActivated === false ||
      isNil(outlet.component)
    ) {
      return true;
    }

    const child = outletContext?.children?.getContext('primary');

    // probably don't need to support multiple child, we don't use multiple router-outlet
    if (isNotNil(child)) {
      if ((await this._canDeactivate(child)) === false) {
        return false;
      }
    }

    const comp = outlet.component as CanDeactivateComponent;

    const can = comp.canDeactivate ? comp.canDeactivate() : true;
    if (can instanceof Observable) {
      return firstValueFrom(can);
    }

    return can;
  }

  override async navigateByUrl(
    url: string | UrlTree,
    extras?: NavigationBehaviorOptions,
  ): Promise<boolean> {
    const canDeactivate = await this._canDeactivate(
      this.outlets.getContext('primary'),
    );

    if (canDeactivate === false) {
      return false;
    }

    if (url instanceof UrlTree) {
      url = url.toString();
    }

    this.parentAppBridge.navigate({
      url,
      replace: extras?.replaceUrl ?? false,
      reload: extras?.onSameUrlNavigation === 'reload',
      updateUrl: extras?.skipLocationChange !== true,
    });
    return true;
  }
}

export function maybeProvideLeftyRouter(): Provider[] {
  if (IS_IN_IFRAME) {
    return [{ provide: Router, useClass: LeftyRouter }];
  }

  return [];
}

export function maybeProvideLeftyIframeLocationStrategy(): Provider[] {
  if (IS_IN_IFRAME) {
    return [
      { provide: LocationStrategy, useClass: LeftyIframeLocationStrategy },
    ];
  }

  return [];
}

import { Injectable } from '@angular/core';
import { CampaignKeywordType } from '@frontend2/proto/common/proto/campaign_pb';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  createTrackingKeywordsConfig,
  redKeywordsField,
  redTagsField,
} from './config.helpers';
import * as TKMessages from './config.messages';
import { DefaultNetworkConfig, FormField } from './config.models';

@Injectable({ providedIn: 'root' })
export class RedConfig extends DefaultNetworkConfig {
  override readonly network = Network.RED;
  override readonly uploadPlaceholder: string = ['PapaJames', '...'].join('\n');

  override readonly keywords = createTrackingKeywordsConfig({
    types: [CampaignKeywordType.RED_KEYWORD, CampaignKeywordType.RED_TAG],
    formHelpers: new Map<CampaignKeywordType, FormField>([
      [CampaignKeywordType.RED_KEYWORD, redKeywordsField],
      [CampaignKeywordType.RED_TAG, redTagsField],
    ]),
    errorMessage: TKMessages.redErrorMessage,
  });
}

// This file defines protocol buffers for TIKTOK.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/tiktok.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ComputedGenerikStats, PostType } from "./common_pb";
import { GenerikMediaFacts } from "./media_pb";

/**
 * @generated from enum common.proto.WordType
 */
export enum WordType {
  /**
   * @generated from enum value: MENTION = 0;
   */
  MENTION = 0,

  /**
   * @generated from enum value: HASHTAG = 1;
   */
  HASHTAG = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(WordType)
proto3.util.setEnumType(WordType, "common.proto.WordType", [
  { no: 0, name: "MENTION" },
  { no: 1, name: "HASHTAG" },
]);

/**
 * @generated from message common.proto.TikTokCounts
 */
export class TikTokCounts extends Message<TikTokCounts> {
  /**
   * @generated from field: int32 following = 1;
   */
  following = 0;

  /**
   * @generated from field: int32 fans = 2;
   */
  fans = 0;

  /**
   * @generated from field: int32 heart = 3;
   */
  heart = 0;

  /**
   * @generated from field: int32 video = 4;
   */
  video = 0;

  /**
   * @generated from field: int32 digg = 5;
   */
  digg = 0;

  constructor(data?: PartialMessage<TikTokCounts>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TikTokCounts";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "following", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "fans", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "heart", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "video", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "digg", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TikTokCounts {
    return new TikTokCounts().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TikTokCounts {
    return new TikTokCounts().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TikTokCounts {
    return new TikTokCounts().fromJsonString(jsonString, options);
  }

  static equals(a: TikTokCounts | PlainMessage<TikTokCounts> | undefined, b: TikTokCounts | PlainMessage<TikTokCounts> | undefined): boolean {
    return proto3.util.equals(TikTokCounts, a, b);
  }
}

/**
 * @generated from message common.proto.TikTokCreator
 */
export class TikTokCreator extends Message<TikTokCreator> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string user_name = 2;
   */
  userName = "";

  /**
   * @generated from field: string nick_name = 3;
   */
  nickName = "";

  /**
   * @generated from field: string bio = 4;
   */
  bio = "";

  /**
   * @generated from field: common.proto.TikTokCounts counts = 5;
   */
  counts?: TikTokCounts;

  /**
   * @generated from field: bool verified = 6;
   */
  verified = false;

  /**
   * @generated from field: repeated string covers = 7;
   */
  covers: string[] = [];

  /**
   * @generated from field: repeated string covers_medium = 8;
   */
  coversMedium: string[] = [];

  /**
   * @generated from field: common.proto.TikTokCreator.BioLink bio_link = 9;
   */
  bioLink?: TikTokCreator_BioLink;

  /**
   * @generated from field: common.proto.ComputedGenerikStats stats = 20;
   */
  stats?: ComputedGenerikStats;

  constructor(data?: PartialMessage<TikTokCreator>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TikTokCreator";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "nick_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "bio", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "counts", kind: "message", T: TikTokCounts },
    { no: 6, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "covers", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "covers_medium", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 9, name: "bio_link", kind: "message", T: TikTokCreator_BioLink },
    { no: 20, name: "stats", kind: "message", T: ComputedGenerikStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TikTokCreator {
    return new TikTokCreator().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TikTokCreator {
    return new TikTokCreator().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TikTokCreator {
    return new TikTokCreator().fromJsonString(jsonString, options);
  }

  static equals(a: TikTokCreator | PlainMessage<TikTokCreator> | undefined, b: TikTokCreator | PlainMessage<TikTokCreator> | undefined): boolean {
    return proto3.util.equals(TikTokCreator, a, b);
  }
}

/**
 * @generated from message common.proto.TikTokCreator.BioLink
 */
export class TikTokCreator_BioLink extends Message<TikTokCreator_BioLink> {
  /**
   * @generated from field: string link = 1;
   */
  link = "";

  /**
   * url risk scoring determine by TikTok
   *
   * @generated from field: int32 risk = 2;
   */
  risk = 0;

  constructor(data?: PartialMessage<TikTokCreator_BioLink>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TikTokCreator.BioLink";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "risk", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TikTokCreator_BioLink {
    return new TikTokCreator_BioLink().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TikTokCreator_BioLink {
    return new TikTokCreator_BioLink().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TikTokCreator_BioLink {
    return new TikTokCreator_BioLink().fromJsonString(jsonString, options);
  }

  static equals(a: TikTokCreator_BioLink | PlainMessage<TikTokCreator_BioLink> | undefined, b: TikTokCreator_BioLink | PlainMessage<TikTokCreator_BioLink> | undefined): boolean {
    return proto3.util.equals(TikTokCreator_BioLink, a, b);
  }
}

/**
 * @generated from message common.proto.UrlFormats
 */
export class UrlFormats extends Message<UrlFormats> {
  /**
   * @generated from field: string thumb = 1;
   */
  thumb = "";

  /**
   * @generated from field: string medium = 2;
   */
  medium = "";

  /**
   * @generated from field: string larger = 3;
   */
  larger = "";

  /**
   * @generated from field: string basic = 4;
   */
  basic = "";

  constructor(data?: PartialMessage<UrlFormats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.UrlFormats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "thumb", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "medium", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "larger", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "basic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UrlFormats {
    return new UrlFormats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UrlFormats {
    return new UrlFormats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UrlFormats {
    return new UrlFormats().fromJsonString(jsonString, options);
  }

  static equals(a: UrlFormats | PlainMessage<UrlFormats> | undefined, b: UrlFormats | PlainMessage<UrlFormats> | undefined): boolean {
    return proto3.util.equals(UrlFormats, a, b);
  }
}

/**
 * @generated from message common.proto.MediaUrls
 */
export class MediaUrls extends Message<MediaUrls> {
  /**
   * @generated from field: string cover = 1;
   */
  cover = "";

  /**
   * @generated from field: string origin_cover = 2;
   */
  originCover = "";

  /**
   * @generated from field: string dynamic_cover = 3;
   */
  dynamicCover = "";

  /**
   * @generated from field: string play_url = 4;
   */
  playUrl = "";

  constructor(data?: PartialMessage<MediaUrls>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.MediaUrls";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "cover", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "origin_cover", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "dynamic_cover", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "play_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaUrls {
    return new MediaUrls().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaUrls {
    return new MediaUrls().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaUrls {
    return new MediaUrls().fromJsonString(jsonString, options);
  }

  static equals(a: MediaUrls | PlainMessage<MediaUrls> | undefined, b: MediaUrls | PlainMessage<MediaUrls> | undefined): boolean {
    return proto3.util.equals(MediaUrls, a, b);
  }
}

/**
 * @generated from message common.proto.VideoInfo
 */
export class VideoInfo extends Message<VideoInfo> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int32 width = 2;
   */
  width = 0;

  /**
   * @generated from field: int32 height = 3;
   */
  height = 0;

  /**
   * @generated from field: int32 duration = 5;
   */
  duration = 0;

  /**
   * @generated from field: common.proto.MediaUrls urls = 6;
   */
  urls?: MediaUrls;

  /**
   * @generated from field: string ratio = 7;
   */
  ratio = "";

  constructor(data?: PartialMessage<VideoInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.VideoInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "duration", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "urls", kind: "message", T: MediaUrls },
    { no: 7, name: "ratio", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): VideoInfo {
    return new VideoInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): VideoInfo {
    return new VideoInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): VideoInfo {
    return new VideoInfo().fromJsonString(jsonString, options);
  }

  static equals(a: VideoInfo | PlainMessage<VideoInfo> | undefined, b: VideoInfo | PlainMessage<VideoInfo> | undefined): boolean {
    return proto3.util.equals(VideoInfo, a, b);
  }
}

/**
 * @generated from message common.proto.TikTokCarouselItem
 */
export class TikTokCarouselItem extends Message<TikTokCarouselItem> {
  /**
   * @generated from field: repeated string urls = 1;
   */
  urls: string[] = [];

  /**
   * @generated from field: int32 width = 2;
   */
  width = 0;

  /**
   * @generated from field: int32 height = 3;
   */
  height = 0;

  constructor(data?: PartialMessage<TikTokCarouselItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TikTokCarouselItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "urls", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TikTokCarouselItem {
    return new TikTokCarouselItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TikTokCarouselItem {
    return new TikTokCarouselItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TikTokCarouselItem {
    return new TikTokCarouselItem().fromJsonString(jsonString, options);
  }

  static equals(a: TikTokCarouselItem | PlainMessage<TikTokCarouselItem> | undefined, b: TikTokCarouselItem | PlainMessage<TikTokCarouselItem> | undefined): boolean {
    return proto3.util.equals(TikTokCarouselItem, a, b);
  }
}

/**
 * @generated from message common.proto.MusicInfos
 */
export class MusicInfos extends Message<MusicInfos> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string author_name = 3;
   */
  authorName = "";

  /**
   * @generated from field: bool original = 4;
   */
  original = false;

  /**
   * @generated from field: common.proto.UrlFormats cover = 5;
   */
  cover?: UrlFormats;

  /**
   * @generated from field: common.proto.MediaUrls urls = 6;
   */
  urls?: MediaUrls;

  constructor(data?: PartialMessage<MusicInfos>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.MusicInfos";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "author_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "original", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "cover", kind: "message", T: UrlFormats },
    { no: 6, name: "urls", kind: "message", T: MediaUrls },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MusicInfos {
    return new MusicInfos().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MusicInfos {
    return new MusicInfos().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MusicInfos {
    return new MusicInfos().fromJsonString(jsonString, options);
  }

  static equals(a: MusicInfos | PlainMessage<MusicInfos> | undefined, b: MusicInfos | PlainMessage<MusicInfos> | undefined): boolean {
    return proto3.util.equals(MusicInfos, a, b);
  }
}

/**
 * @generated from message common.proto.ChallengeInfo
 */
export class ChallengeInfo extends Message<ChallengeInfo> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string caption = 3;
   */
  caption = "";

  /**
   * @generated from field: common.proto.UrlFormats cover = 4;
   */
  cover?: UrlFormats;

  /**
   * @generated from field: common.proto.UrlFormats profile = 5;
   */
  profile?: UrlFormats;

  constructor(data?: PartialMessage<ChallengeInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ChallengeInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "caption", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "cover", kind: "message", T: UrlFormats },
    { no: 5, name: "profile", kind: "message", T: UrlFormats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChallengeInfo {
    return new ChallengeInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChallengeInfo {
    return new ChallengeInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChallengeInfo {
    return new ChallengeInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ChallengeInfo | PlainMessage<ChallengeInfo> | undefined, b: ChallengeInfo | PlainMessage<ChallengeInfo> | undefined): boolean {
    return proto3.util.equals(ChallengeInfo, a, b);
  }
}

/**
 * @generated from message common.proto.TextExtra
 */
export class TextExtra extends Message<TextExtra> {
  /**
   * @generated from field: string aweme_id = 1;
   */
  awemeId = "";

  /**
   * @generated from field: bool is_commerce = 2;
   */
  isCommerce = false;

  /**
   * @generated from field: common.proto.WordType type = 3;
   */
  type = WordType.MENTION;

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * @generated from field: string id = 5;
   */
  id = "";

  constructor(data?: PartialMessage<TextExtra>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TextExtra";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "aweme_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "is_commerce", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "type", kind: "enum", T: proto3.getEnumType(WordType) },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextExtra {
    return new TextExtra().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextExtra {
    return new TextExtra().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextExtra {
    return new TextExtra().fromJsonString(jsonString, options);
  }

  static equals(a: TextExtra | PlainMessage<TextExtra> | undefined, b: TextExtra | PlainMessage<TextExtra> | undefined): boolean {
    return proto3.util.equals(TextExtra, a, b);
  }
}

/**
 * @generated from message common.proto.MediaStats
 */
export class MediaStats extends Message<MediaStats> {
  /**
   * @generated from field: int32 digg_count = 1;
   */
  diggCount = 0;

  /**
   * @generated from field: int32 share_count = 2;
   */
  shareCount = 0;

  /**
   * @generated from field: int32 comment_count = 3;
   */
  commentCount = 0;

  /**
   * @generated from field: int32 play_count = 4;
   */
  playCount = 0;

  /**
   * @generated from field: int32 collect_count = 5;
   */
  collectCount = 0;

  constructor(data?: PartialMessage<MediaStats>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.MediaStats";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "digg_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "share_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "comment_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "play_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "collect_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaStats {
    return new MediaStats().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaStats {
    return new MediaStats().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaStats {
    return new MediaStats().fromJsonString(jsonString, options);
  }

  static equals(a: MediaStats | PlainMessage<MediaStats> | undefined, b: MediaStats | PlainMessage<MediaStats> | undefined): boolean {
    return proto3.util.equals(MediaStats, a, b);
  }
}

/**
 * @generated from message common.proto.MediaStatsV2
 */
export class MediaStatsV2 extends Message<MediaStatsV2> {
  /**
   * @generated from field: int64 digg_count = 1;
   */
  diggCount = protoInt64.zero;

  /**
   * @generated from field: int64 share_count = 2;
   */
  shareCount = protoInt64.zero;

  /**
   * @generated from field: int64 comment_count = 3;
   */
  commentCount = protoInt64.zero;

  /**
   * @generated from field: int64 play_count = 4;
   */
  playCount = protoInt64.zero;

  /**
   * @generated from field: int64 collect_count = 5;
   */
  collectCount = protoInt64.zero;

  /**
   * @generated from field: int64 repost_count = 6;
   */
  repostCount = protoInt64.zero;

  constructor(data?: PartialMessage<MediaStatsV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.MediaStatsV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "digg_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "share_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "comment_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "play_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "collect_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "repost_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaStatsV2 {
    return new MediaStatsV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaStatsV2 {
    return new MediaStatsV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaStatsV2 {
    return new MediaStatsV2().fromJsonString(jsonString, options);
  }

  static equals(a: MediaStatsV2 | PlainMessage<MediaStatsV2> | undefined, b: MediaStatsV2 | PlainMessage<MediaStatsV2> | undefined): boolean {
    return proto3.util.equals(MediaStatsV2, a, b);
  }
}

/**
 * @generated from message common.proto.TikTokMediaMetadata
 */
export class TikTokMediaMetadata extends Message<TikTokMediaMetadata> {
  /**
   * @generated from field: string creator_id = 1;
   */
  creatorId = "";

  /**
   * The id will be of the form: tk: author id_media_id
   * It creates a duplication for the fact that we also have author_id up above, but it makes various things
   * easier (it is the same that we do for instagram).
   *
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: string caption = 3;
   */
  caption = "";

  /**
   * @generated from field: google.protobuf.Timestamp creation_time = 4;
   */
  creationTime?: Timestamp;

  /**
   * @generated from field: common.proto.PostType post_type = 14;
   */
  postType = PostType.POST_TYPE_UNDEFINED;

  /**
   * @generated from field: common.proto.VideoInfo video_info = 5;
   */
  videoInfo?: VideoInfo;

  /**
   * @generated from field: common.proto.MusicInfos music_infos = 6;
   */
  musicInfos?: MusicInfos;

  /**
   * @generated from field: repeated common.proto.ChallengeInfo challenge_infos = 7;
   */
  challengeInfos: ChallengeInfo[] = [];

  /**
   * @generated from field: repeated common.proto.TextExtra extra = 8;
   */
  extra: TextExtra[] = [];

  /**
   * @generated from field: repeated common.proto.TikTokCarouselItem carousel_items = 12;
   */
  carouselItems: TikTokCarouselItem[] = [];

  /**
   * DEPRECATED: use stats_v2 instead supporting int64
   * TODO: cleanup after migration
   *
   * @generated from field: common.proto.MediaStats stats = 9;
   */
  stats?: MediaStats;

  /**
   * @generated from field: common.proto.MediaStatsV2 stats_v2 = 15;
   */
  statsV2?: MediaStatsV2;

  /**
   * @generated from field: bool is_original = 10;
   */
  isOriginal = false;

  /**
   * @generated from field: bool is_official = 11;
   */
  isOfficial = false;

  /**
   * @generated from field: bool is_top = 13;
   */
  isTop = false;

  constructor(data?: PartialMessage<TikTokMediaMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TikTokMediaMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "caption", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "creation_time", kind: "message", T: Timestamp },
    { no: 14, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType) },
    { no: 5, name: "video_info", kind: "message", T: VideoInfo },
    { no: 6, name: "music_infos", kind: "message", T: MusicInfos },
    { no: 7, name: "challenge_infos", kind: "message", T: ChallengeInfo, repeated: true },
    { no: 8, name: "extra", kind: "message", T: TextExtra, repeated: true },
    { no: 12, name: "carousel_items", kind: "message", T: TikTokCarouselItem, repeated: true },
    { no: 9, name: "stats", kind: "message", T: MediaStats },
    { no: 15, name: "stats_v2", kind: "message", T: MediaStatsV2 },
    { no: 10, name: "is_original", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 11, name: "is_official", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 13, name: "is_top", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TikTokMediaMetadata {
    return new TikTokMediaMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TikTokMediaMetadata {
    return new TikTokMediaMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TikTokMediaMetadata {
    return new TikTokMediaMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: TikTokMediaMetadata | PlainMessage<TikTokMediaMetadata> | undefined, b: TikTokMediaMetadata | PlainMessage<TikTokMediaMetadata> | undefined): boolean {
    return proto3.util.equals(TikTokMediaMetadata, a, b);
  }
}

/**
 * @generated from message common.proto.TikTokMedia
 */
export class TikTokMedia extends Message<TikTokMedia> {
  /**
   * @generated from field: common.proto.TikTokMediaMetadata media = 1;
   */
  media?: TikTokMediaMetadata;

  /**
   * DEPRECATED_FOR_FACTS
   *
   * @generated from field: google.protobuf.Timestamp ingestion_time = 2;
   */
  ingestionTime?: Timestamp;

  /**
   * DEPRECATED_FOR_FACTS
   *
   * @generated from field: google.protobuf.Timestamp last_stats_time = 3;
   */
  lastStatsTime?: Timestamp;

  /**
   * @generated from field: common.proto.GenerikMediaFacts facts = 4;
   */
  facts?: GenerikMediaFacts;

  constructor(data?: PartialMessage<TikTokMedia>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.TikTokMedia";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media", kind: "message", T: TikTokMediaMetadata },
    { no: 2, name: "ingestion_time", kind: "message", T: Timestamp },
    { no: 3, name: "last_stats_time", kind: "message", T: Timestamp },
    { no: 4, name: "facts", kind: "message", T: GenerikMediaFacts },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TikTokMedia {
    return new TikTokMedia().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TikTokMedia {
    return new TikTokMedia().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TikTokMedia {
    return new TikTokMedia().fromJsonString(jsonString, options);
  }

  static equals(a: TikTokMedia | PlainMessage<TikTokMedia> | undefined, b: TikTokMedia | PlainMessage<TikTokMedia> | undefined): boolean {
    return proto3.util.equals(TikTokMedia, a, b);
  }
}


import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianSalesforce } from '@frontend2/proto/librarian/proto/salesforce_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianSalesforceClient = PromiseClient<
  typeof LibrarianSalesforce
>;

export const LibrarianSalesforceClientProvider = new InjectionToken(
  'LibrarianSalesforceClient',
  {
    factory: (): LibrarianSalesforceClient =>
      createPromiseClient(LibrarianSalesforce, inject(GRPC_TRANSPORT)),
  },
);

export function injectLibrarianSalesforceClient(): LibrarianSalesforceClient {
  return inject(LibrarianSalesforceClientProvider);
}

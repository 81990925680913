// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/ecommerce_stores.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateManualStoreRequest, CreateManualStoreResponse, CreateSalesforceStoresRequest, CreateSalesforceStoresResponse, EcommerceStoreWithInfoPb, EditEcommerceStoreSettingsRequest, EditEcommerceStoreSettingsResponse, EditManualStoreRequest, EditManualStoreResponse, GetEcommerceStoresRequest, GetEcommerceStoresResponse, GetSalesforceSlasAdminUILinkRequest, GetSalesforceSlasAdminUILinkResponse, ValidateCreateSalesforceStoresAPIClientRequest, ValidateCreateSalesforceStoresAPIClientResponse, ValidateCreateSalesforceStoresSlasAdminTokenRequest, ValidateCreateSalesforceStoresSlasAdminTokenResponse } from "./ecommerce_stores_pb";
import { Empty, Int64Value, MethodKind } from "@bufbuild/protobuf";
import { Int64List } from "../../common/proto/common_pb";

/**
 * @generated from service librarian.proto.LibrarianEcommerceStores
 */
export const LibrarianEcommerceStores = {
  typeName: "librarian.proto.LibrarianEcommerceStores",
  methods: {
    /**
     * Get ecommerce stores of the logged user's workspace.
     *
     * @generated from rpc librarian.proto.LibrarianEcommerceStores.GetEcommerceStoresAPI
     */
    getEcommerceStoresAPI: {
      name: "GetEcommerceStoresAPI",
      I: GetEcommerceStoresRequest,
      O: GetEcommerceStoresResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEcommerceStores.GetEcommerceStoreAPI
     */
    getEcommerceStoreAPI: {
      name: "GetEcommerceStoreAPI",
      I: Int64Value,
      O: EcommerceStoreWithInfoPb,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEcommerceStores.CreateManualStoreAPI
     */
    createManualStoreAPI: {
      name: "CreateManualStoreAPI",
      I: CreateManualStoreRequest,
      O: CreateManualStoreResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEcommerceStores.EditManualStoreAPI
     */
    editManualStoreAPI: {
      name: "EditManualStoreAPI",
      I: EditManualStoreRequest,
      O: EditManualStoreResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEcommerceStores.ArchiveManualStoreAPI
     */
    archiveManualStoreAPI: {
      name: "ArchiveManualStoreAPI",
      I: Int64List,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEcommerceStores.UnarchiveManualStoreAPI
     */
    unarchiveManualStoreAPI: {
      name: "UnarchiveManualStoreAPI",
      I: Int64List,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEcommerceStores.ArchiveEcommerceStoresAPI
     */
    archiveEcommerceStoresAPI: {
      name: "ArchiveEcommerceStoresAPI",
      I: Int64List,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEcommerceStores.UnarchiveEcommerceStoresAPI
     */
    unarchiveEcommerceStoresAPI: {
      name: "UnarchiveEcommerceStoresAPI",
      I: Int64List,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEcommerceStores.CreateSalesforceStoresAPI
     */
    createSalesforceStoresAPI: {
      name: "CreateSalesforceStoresAPI",
      I: CreateSalesforceStoresRequest,
      O: CreateSalesforceStoresResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEcommerceStores.ValidateCreateSalesforceStoresAPIClientAPI
     */
    validateCreateSalesforceStoresAPIClientAPI: {
      name: "ValidateCreateSalesforceStoresAPIClientAPI",
      I: ValidateCreateSalesforceStoresAPIClientRequest,
      O: ValidateCreateSalesforceStoresAPIClientResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEcommerceStores.ValidateCreateSalesforceStoresSlasAdminTokenAPI
     */
    validateCreateSalesforceStoresSlasAdminTokenAPI: {
      name: "ValidateCreateSalesforceStoresSlasAdminTokenAPI",
      I: ValidateCreateSalesforceStoresSlasAdminTokenRequest,
      O: ValidateCreateSalesforceStoresSlasAdminTokenResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEcommerceStores.GetSalesforceSlasAdminUILinkAPI
     */
    getSalesforceSlasAdminUILinkAPI: {
      name: "GetSalesforceSlasAdminUILinkAPI",
      I: GetSalesforceSlasAdminUILinkRequest,
      O: GetSalesforceSlasAdminUILinkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianEcommerceStores.EditEcommerceStoreSettingsAPI
     */
    editEcommerceStoreSettingsAPI: {
      name: "EditEcommerceStoreSettingsAPI",
      I: EditEcommerceStoreSettingsRequest,
      O: EditEcommerceStoreSettingsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;


import { isNil } from '@frontend2/core';
import { Cell, Column, Row } from './lefty-data-table.models';

export function createCell<T>(cell?: Partial<Cell<T>>): Cell<T> {
  return {
    formattedValue: cell?.formattedValue ?? '',
    icon: cell?.icon ?? '',
    link: cell?.link ?? '',
    copyToolTip: cell?.copyToolTip ?? false,
    className: cell?.className ?? '',
    data: cell?.data,
  };
}

export function createRow<T>(id: string, row?: Partial<Row<T>>): Row<T> {
  return {
    id: id,
    cells: row?.cells ?? [],
    className: row?.className ?? '',
    isGhost: row?.isGhost ?? false,
    data: row?.data,
  };
}

export function isRowGhost(row: Row<unknown>): boolean {
  return row.cells.length === 0;
}

// Factory constructor will try to use `sortValue` as `id` of the column
//
// If you don't want the column to be sortable
// use [LeftyDataTable.Column.noSort] factory
//
// **Warning**: `sortValue.toString()` must produce a non minify string
// this could happen with Enum, you should provide an hard-coded string
export function createColumn<Sort>(
  sortValue: Sort,
  col?: Partial<Column<Sort>>,
): Column<Sort> {
  return {
    id: `${sortValue}`,
    sortValue,
    label: col?.label ?? '',
    className: col?.className ?? '',
    helper: col?.helper ?? '',
    group: col?.group ?? '',
    alignRight: col?.alignRight ?? false,
    labelFactory: col?.labelFactory,
  };
}

export function createColumnNoSort(
  id: string,
  col: Partial<Column<unknown>>,
): Column<unknown> {
  return {
    id,
    label: col.label ?? '',
    sortValue: col.sortValue,
    className: col.className ?? '',
    helper: col.helper ?? '',
    group: col.group ?? '',
    alignRight: col?.alignRight ?? false,
    labelFactory: col?.labelFactory,
  };
}

export function isColumnSortable(col: Column<unknown>): boolean {
  return isNil(col.sortValue) === false;
}

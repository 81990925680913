// This defines a service to load in app chat conversations: 1:1 to an influencer, all of a campaign, all of a workspace.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/chat.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Int64Value, Message, proto3, protoInt64, StringValue } from "@bufbuild/protobuf";
import { CreatorCardSnippet } from "./creators_pb";
import { FlexibleCreatorSelector } from "./common_pb";

/**
 * We have designed a poor man's templating system: a message is made up of a list of fragments.
 *
 * Each fragment is either free text, or an enum that the system will automatically replace by its
 * corresponding value. For now we can only interpolate the influencer's destination username, but
 * we can add stuff later as needed.
 *
 * The advantage of this system is that it's simple, and it's also fairly safe (it's actually hard to find
 * a templating system that doesn't allow for arbitrary code execution and is 100% safe for parsing user supplied
 * strings).
 *
 * @generated from enum librarian.proto.ChatReplaceableFragment
 */
export enum ChatReplaceableFragment {
  /**
   * @generated from enum value: CHAT_REPLACEABLE_FRAGMENT_UNKNOWN = 0;
   */
  CHAT_REPLACEABLE_FRAGMENT_UNKNOWN = 0,

  /**
   * @generated from enum value: CHAT_REPLACEABLE_INFLUENCER_NAME = 1;
   */
  CHAT_REPLACEABLE_INFLUENCER_NAME = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(ChatReplaceableFragment)
proto3.util.setEnumType(ChatReplaceableFragment, "librarian.proto.ChatReplaceableFragment", [
  { no: 0, name: "CHAT_REPLACEABLE_FRAGMENT_UNKNOWN" },
  { no: 1, name: "CHAT_REPLACEABLE_INFLUENCER_NAME" },
]);

/**
 * @generated from message librarian.proto.InfluencerConversationResp
 */
export class InfluencerConversationResp extends Message<InfluencerConversationResp> {
  /**
   * If false, the influencer is not invited/accepted yet and conversation_id is empty.
   *
   * @generated from field: bool has_conversation = 1;
   */
  hasConversation = false;

  /**
   * @generated from field: string conversation_id = 2;
   */
  conversationId = "";

  /**
   * @generated from field: string user_id = 3;
   */
  userId = "";

  /**
   * Since the user was created with a dedicated talkJS call it seems that this shall not be necessary on each frontend
   * session but it is.
   *
   * @generated from field: string user_name = 4;
   */
  userName = "";

  /**
   * @generated from field: string user_signature = 5;
   */
  userSignature = "";

  /**
   * @generated from field: string app_id = 6;
   */
  appId = "";

  constructor(data?: PartialMessage<InfluencerConversationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InfluencerConversationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "has_conversation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "conversation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "user_signature", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "app_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerConversationResp {
    return new InfluencerConversationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerConversationResp {
    return new InfluencerConversationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerConversationResp {
    return new InfluencerConversationResp().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerConversationResp | PlainMessage<InfluencerConversationResp> | undefined, b: InfluencerConversationResp | PlainMessage<InfluencerConversationResp> | undefined): boolean {
    return proto3.util.equals(InfluencerConversationResp, a, b);
  }
}

/**
 * @generated from message librarian.proto.WorkspaceConversationResp
 */
export class WorkspaceConversationResp extends Message<WorkspaceConversationResp> {
  /**
   * Note: if 'conversations' is empty then the user has no ongoing conversations, thus we didn't bother to fill those fields.
   *
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * Since the user was created with a dedicated talkJS call it seems that this shall not be necessary on each frontend
   * session but it is.
   *
   * @generated from field: string user_name = 2;
   */
  userName = "";

  /**
   * @generated from field: string user_signature = 3;
   */
  userSignature = "";

  /**
   * @generated from field: string app_id = 4;
   */
  appId = "";

  /**
   * @generated from field: repeated librarian.proto.WorkspaceConversationResp.WorkspaceConversation conversations = 5;
   */
  conversations: WorkspaceConversationResp_WorkspaceConversation[] = [];

  /**
   * @generated from field: string pagination_token = 6;
   */
  paginationToken = "";

  constructor(data?: PartialMessage<WorkspaceConversationResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.WorkspaceConversationResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_signature", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "app_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "conversations", kind: "message", T: WorkspaceConversationResp_WorkspaceConversation, repeated: true },
    { no: 6, name: "pagination_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WorkspaceConversationResp {
    return new WorkspaceConversationResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WorkspaceConversationResp {
    return new WorkspaceConversationResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WorkspaceConversationResp {
    return new WorkspaceConversationResp().fromJsonString(jsonString, options);
  }

  static equals(a: WorkspaceConversationResp | PlainMessage<WorkspaceConversationResp> | undefined, b: WorkspaceConversationResp | PlainMessage<WorkspaceConversationResp> | undefined): boolean {
    return proto3.util.equals(WorkspaceConversationResp, a, b);
  }
}

/**
 * @generated from message librarian.proto.WorkspaceConversationResp.WorkspaceConversation
 */
export class WorkspaceConversationResp_WorkspaceConversation extends Message<WorkspaceConversationResp_WorkspaceConversation> {
  /**
   * @generated from field: string conversation_id = 1;
   */
  conversationId = "";

  /**
   * deprecated
   *
   * @generated from field: string influencer_name = 2;
   */
  influencerName = "";

  /**
   * deprecated
   *
   * @generated from field: string influencer_profile_pic = 3;
   */
  influencerProfilePic = "";

  /**
   * @generated from field: librarian.proto.CreatorCardSnippet creator = 7;
   */
  creator?: CreatorCardSnippet;

  /**
   * @generated from field: google.protobuf.StringValue last_message = 4;
   */
  lastMessage?: string;

  constructor(data?: PartialMessage<WorkspaceConversationResp_WorkspaceConversation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.WorkspaceConversationResp.WorkspaceConversation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "conversation_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "influencer_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "influencer_profile_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "creator", kind: "message", T: CreatorCardSnippet },
    { no: 4, name: "last_message", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WorkspaceConversationResp_WorkspaceConversation {
    return new WorkspaceConversationResp_WorkspaceConversation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WorkspaceConversationResp_WorkspaceConversation {
    return new WorkspaceConversationResp_WorkspaceConversation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WorkspaceConversationResp_WorkspaceConversation {
    return new WorkspaceConversationResp_WorkspaceConversation().fromJsonString(jsonString, options);
  }

  static equals(a: WorkspaceConversationResp_WorkspaceConversation | PlainMessage<WorkspaceConversationResp_WorkspaceConversation> | undefined, b: WorkspaceConversationResp_WorkspaceConversation | PlainMessage<WorkspaceConversationResp_WorkspaceConversation> | undefined): boolean {
    return proto3.util.equals(WorkspaceConversationResp_WorkspaceConversation, a, b);
  }
}

/**
 * @generated from message librarian.proto.MultiConversationReq
 */
export class MultiConversationReq extends Message<MultiConversationReq> {
  /**
   * @generated from field: google.protobuf.StringValue user_name_filter = 1;
   */
  userNameFilter?: string;

  /**
   * @generated from field: google.protobuf.Int64Value in_campaign_id = 2;
   */
  inCampaignId?: bigint;

  /**
   * @generated from field: google.protobuf.StringValue pagination_token = 3;
   */
  paginationToken?: string;

  constructor(data?: PartialMessage<MultiConversationReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.MultiConversationReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_name_filter", kind: "message", T: StringValue },
    { no: 2, name: "in_campaign_id", kind: "message", T: Int64Value },
    { no: 3, name: "pagination_token", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MultiConversationReq {
    return new MultiConversationReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MultiConversationReq {
    return new MultiConversationReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MultiConversationReq {
    return new MultiConversationReq().fromJsonString(jsonString, options);
  }

  static equals(a: MultiConversationReq | PlainMessage<MultiConversationReq> | undefined, b: MultiConversationReq | PlainMessage<MultiConversationReq> | undefined): boolean {
    return proto3.util.equals(MultiConversationReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.ChatMessageFragment
 */
export class ChatMessageFragment extends Message<ChatMessageFragment> {
  /**
   * @generated from oneof librarian.proto.ChatMessageFragment.value
   */
  value: {
    /**
     * @generated from field: librarian.proto.ChatReplaceableFragment replaceable_fragment = 1;
     */
    value: ChatReplaceableFragment;
    case: "replaceableFragment";
  } | {
    /**
     * @generated from field: string text = 2;
     */
    value: string;
    case: "text";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<ChatMessageFragment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ChatMessageFragment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "replaceable_fragment", kind: "enum", T: proto3.getEnumType(ChatReplaceableFragment), oneof: "value" },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "value" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChatMessageFragment {
    return new ChatMessageFragment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChatMessageFragment {
    return new ChatMessageFragment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChatMessageFragment {
    return new ChatMessageFragment().fromJsonString(jsonString, options);
  }

  static equals(a: ChatMessageFragment | PlainMessage<ChatMessageFragment> | undefined, b: ChatMessageFragment | PlainMessage<ChatMessageFragment> | undefined): boolean {
    return proto3.util.equals(ChatMessageFragment, a, b);
  }
}

/**
 * @generated from message librarian.proto.ChatMessage
 */
export class ChatMessage extends Message<ChatMessage> {
  /**
   * @generated from field: repeated librarian.proto.ChatMessageFragment fragments = 1;
   */
  fragments: ChatMessageFragment[] = [];

  constructor(data?: PartialMessage<ChatMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ChatMessage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fragments", kind: "message", T: ChatMessageFragment, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChatMessage {
    return new ChatMessage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChatMessage {
    return new ChatMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChatMessage {
    return new ChatMessage().fromJsonString(jsonString, options);
  }

  static equals(a: ChatMessage | PlainMessage<ChatMessage> | undefined, b: ChatMessage | PlainMessage<ChatMessage> | undefined): boolean {
    return proto3.util.equals(ChatMessage, a, b);
  }
}

/**
 * @generated from message librarian.proto.BulkMessageReq
 */
export class BulkMessageReq extends Message<BulkMessageReq> {
  /**
   * We are taking a creator selector in case we ever want to grow more flexible, however at the moment only manual_ids
   * is supported.
   *
   * It is expected that the list contains only ids of valid authors that one can chat with (the user clicks them from
   * the chat widget anyways). Those that are not will be silently ignored.
   *
   * @generated from field: librarian.proto.FlexibleCreatorSelector creator_selector = 1;
   */
  creatorSelector?: FlexibleCreatorSelector;

  /**
   * Shall be valid mustache template.
   * The only variable available at the moment will be {{ username }}.
   *
   * @generated from field: librarian.proto.ChatMessage message = 2;
   */
  message?: ChatMessage;

  constructor(data?: PartialMessage<BulkMessageReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.BulkMessageReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_selector", kind: "message", T: FlexibleCreatorSelector },
    { no: 2, name: "message", kind: "message", T: ChatMessage },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BulkMessageReq {
    return new BulkMessageReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BulkMessageReq {
    return new BulkMessageReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BulkMessageReq {
    return new BulkMessageReq().fromJsonString(jsonString, options);
  }

  static equals(a: BulkMessageReq | PlainMessage<BulkMessageReq> | undefined, b: BulkMessageReq | PlainMessage<BulkMessageReq> | undefined): boolean {
    return proto3.util.equals(BulkMessageReq, a, b);
  }
}

/**
 * Enqueued in the archivist tube...
 *
 * @generated from message librarian.proto.BulkMessageTask
 */
export class BulkMessageTask extends Message<BulkMessageTask> {
  /**
   * Acting user sending the bulk message.
   *
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: repeated librarian.proto.Chatter target_chatters = 2;
   */
  targetChatters: Chatter[] = [];

  /**
   * @generated from field: librarian.proto.ChatMessage message = 3;
   */
  message?: ChatMessage;

  /**
   * @generated from field: string uuid = 4;
   */
  uuid = "";

  constructor(data?: PartialMessage<BulkMessageTask>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.BulkMessageTask";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "target_chatters", kind: "message", T: Chatter, repeated: true },
    { no: 3, name: "message", kind: "message", T: ChatMessage },
    { no: 4, name: "uuid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BulkMessageTask {
    return new BulkMessageTask().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BulkMessageTask {
    return new BulkMessageTask().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BulkMessageTask {
    return new BulkMessageTask().fromJsonString(jsonString, options);
  }

  static equals(a: BulkMessageTask | PlainMessage<BulkMessageTask> | undefined, b: BulkMessageTask | PlainMessage<BulkMessageTask> | undefined): boolean {
    return proto3.util.equals(BulkMessageTask, a, b);
  }
}

/**
 * @generated from message librarian.proto.BulkMessageErrors
 */
export class BulkMessageErrors extends Message<BulkMessageErrors> {
  /**
   * @generated from field: repeated librarian.proto.Chatter failed_target_chatters = 1;
   */
  failedTargetChatters: Chatter[] = [];

  constructor(data?: PartialMessage<BulkMessageErrors>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.BulkMessageErrors";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "failed_target_chatters", kind: "message", T: Chatter, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BulkMessageErrors {
    return new BulkMessageErrors().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BulkMessageErrors {
    return new BulkMessageErrors().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BulkMessageErrors {
    return new BulkMessageErrors().fromJsonString(jsonString, options);
  }

  static equals(a: BulkMessageErrors | PlainMessage<BulkMessageErrors> | undefined, b: BulkMessageErrors | PlainMessage<BulkMessageErrors> | undefined): boolean {
    return proto3.util.equals(BulkMessageErrors, a, b);
  }
}

/**
 * @generated from message librarian.proto.Chatter
 */
export class Chatter extends Message<Chatter> {
  /**
   * @generated from field: int64 chatter_id = 1;
   */
  chatterId = protoInt64.zero;

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * @generated from field: string user_id = 3;
   */
  userId = "";

  /**
   * @generated from field: string profile_pic = 4;
   */
  profilePic = "";

  constructor(data?: PartialMessage<Chatter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Chatter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "chatter_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "profile_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Chatter {
    return new Chatter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Chatter {
    return new Chatter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Chatter {
    return new Chatter().fromJsonString(jsonString, options);
  }

  static equals(a: Chatter | PlainMessage<Chatter> | undefined, b: Chatter | PlainMessage<Chatter> | undefined): boolean {
    return proto3.util.equals(Chatter, a, b);
  }
}


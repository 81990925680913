import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
} from '@angular/core';
import {
  createGhostInfluencerCasting,
  InfluencerCasting,
} from '@frontend2/core';
import { NoteCastingProto } from '@frontend2/proto/librarian/proto/casting_pb';
import { CastingProfileCommentCardComponent } from './comment-card.component';
import { LeftyComponent } from '../../utils';
import { LeftyIconComponent } from '../../icon/icon.component';
import { NgFor, NgIf } from '@angular/common';

@Component({
  selector: 'casting-profile-comments',
  templateUrl: './profile-comments.component.html',
  styleUrls: ['./profile-comments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    CastingProfileCommentCardComponent,
    NgIf,
    LeftyIconComponent,
  ],
})
export class CastingProfileCommentsComponent extends LeftyComponent {
  constructor() {
    super();

    this.disposer.add(this.addComment$);
    this.disposer.add(this.updateComment$);
    this.disposer.add(this.deleteComment$);
  }

  @Input()
  mobile = false;

  @Output()
  readonly addComment$ = new EventEmitter<string>();

  @Output()
  readonly updateComment$ = new EventEmitter<NoteCastingProto>();

  @Output()
  readonly deleteComment$ = new EventEmitter<bigint>();

  editing = false;
  comments: NoteCastingProto[] = [];

  private _influencer = createGhostInfluencerCasting();

  @Input()
  public set influencer(value: InfluencerCasting) {
    this._influencer = value;
    this.comments = value.comments;

    this.setState(() => {
      this.editing = false;
    });
  }

  @Input()
  loading = false;

  @ViewChildren(CastingProfileCommentCardComponent)
  commentsCard: CastingProfileCommentCardComponent[] = [];

  addCommentMsg = $localize`Add comment`;

  trackBy(index: number, comment: NoteCastingProto): bigint {
    return comment.noteId;
  }

  handleDeleteComment(id: bigint): void {
    if (id !== BigInt(0)) {
      // delete an existing comment
      this.deleteComment$.emit(id);
    } else {
      // comment does not exist
      // it happen after a startAddComment() call and when user cancel
      //
      // we reset comments list
      this.setState(() => {
        this.comments = this._influencer.comments;
      });
    }

    this.setState(() => {
      this.editing = false;
    });
  }

  startAddComment(): void {
    this.setState(() => {
      // add an empty comment proto
      // so CastingProfileCommentCardComponent can display a textfield
      this.comments = [...this.comments, new NoteCastingProto()];
      this.editing = true;
    });
  }
}

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LeftyFormBase } from '../form';
import { LeftyIconComponent } from '../icon/icon.component';
import { LeftyTooltipDirective } from '../lefty-tooltip/lefty-tooltip.directive';
import { HelpIconComponent } from '../icon/help.component';
import { LeftyRouterLinkDirective } from '../router-utils';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lefty-form',
  templateUrl: './lefty-form.component.html',
  styleUrls: ['./lefty-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    LeftyRouterLinkDirective,
    HelpIconComponent,
    LeftyTooltipDirective,
    LeftyIconComponent,
  ],
})
export class LeftyFormComponent extends LeftyFormBase {
  constructor() {
    super(undefined);
  }
}

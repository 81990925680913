import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostBinding,
  inject,
  signal,
} from '@angular/core';
import { isNotNil } from '@frontend2/core';
import { isGhostCreator } from '@frontend2/proto-helpers/librarian/creator-pb.helpers';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { CreatorTiniestCard } from '@frontend2/proto/librarian/proto/creators_pb';
import { NetworkConfigs } from '../../config/config.service';
import { RendersValue } from '../../dynamic-component.component';
import { LeftySelectDropdownItemComponent } from '../../lefty-form-select/lefty-select-dropdown-item.component';
import { LeftyComponent } from '../../utils';
import {
  createInfluencerWithNetworkInfoAutocomplete,
  getCreatorAutocompleteMatchFollowerCountForNetwork,
  InfluencerWithNetworkInfoAutocomplete,
} from '../influencer-autocomplete.helpers';
import { NetworkIconComponent } from '../../icon/network.component';
import { ProfilePictureComponent } from '../../image/profile-pic.component';

@Component({
  selector: 'influencer-with-network-info-item',
  templateUrl: 'influencer-with-network-info-item.component.html',
  styleUrls: ['influencer-with-network-info-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ProfilePictureComponent, NetworkIconComponent],
})
export class InfluencerWithNetworkInfoItemComponent
  extends LeftyComponent
  implements RendersValue<InfluencerWithNetworkInfoAutocomplete>
{
  readonly selectDropdownItem = inject(LeftySelectDropdownItemComponent, {
    optional: true,
  });

  @HostBinding('class.disabled')
  get isDisabled(): boolean {
    return this.selectDropdownItem?.isDisabled ?? false;
  }

  setValue(newValue?: InfluencerWithNetworkInfoAutocomplete): void {
    if (isNotNil(newValue)) {
      this.influencerWithNetworkInfo.set(newValue);
    }
  }

  readonly networkConfigs = inject(NetworkConfigs);

  readonly influencerWithNetworkInfo = signal(
    createInfluencerWithNetworkInfoAutocomplete({}),
  );

  readonly influencer = computed(
    () => this.influencerWithNetworkInfo().influencer,
  );

  readonly creator = computed(
    () => this.influencer().creator ?? new CreatorTiniestCard(),
  );

  readonly followers = computed(() => {
    const networkConfig = this.networkConfigs.of(
      this.influencerWithNetworkInfo().selectedNetwork,
    );
    return getCreatorAutocompleteMatchFollowerCountForNetwork(
      this.influencerWithNetworkInfo(),
      networkConfig.supportFollowers,
    );
  });

  readonly networks = computed(() =>
    this.influencer()
      .networkInfo.map((n) => n.network)
      .filter((n) => n !== Network.NETWORK_UNKNOWN),
  );

  readonly isGhostCreatorAutocompleteMatch = computed(() =>
    isGhostCreator(this.creator()),
  );
}

// This file defines messages for bronco.
//
// Bronco used to be implemented as a process and the whole interface was grpc based.
// Now bronco is migrated to being a fat client, thus most data structures are just regular classes.
// However, we copied the Task message (the result of findById and findByKey) such that we can serve it to the
// frontend and be certain that we are backwards compatible.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/bronco.proto (package baac.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, StringValue, Timestamp } from "@bufbuild/protobuf";
import { Status } from "../../grpc/status/status_pb";

/**
 * @generated from message baac.proto.TaskProgress
 */
export class TaskProgress extends Message<TaskProgress> {
  /**
   * a float from 0 to 100.0F.
   *
   * @generated from field: float progress = 1;
   */
  progress = 0;

  /**
   * progress message if any.
   *
   * @generated from field: google.protobuf.StringValue message = 2;
   */
  message?: string;

  /**
   * progress metadata if any.
   *
   * @generated from field: google.protobuf.StringValue metadata = 3;
   */
  metadata?: string;

  constructor(data?: PartialMessage<TaskProgress>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "baac.proto.TaskProgress";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "progress", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "message", kind: "message", T: StringValue },
    { no: 3, name: "metadata", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskProgress {
    return new TaskProgress().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskProgress {
    return new TaskProgress().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskProgress {
    return new TaskProgress().fromJsonString(jsonString, options);
  }

  static equals(a: TaskProgress | PlainMessage<TaskProgress> | undefined, b: TaskProgress | PlainMessage<TaskProgress> | undefined): boolean {
    return proto3.util.equals(TaskProgress, a, b);
  }
}

/**
 * @generated from message baac.proto.TaskAttempt
 */
export class TaskAttempt extends Message<TaskAttempt> {
  /**
   * not hasStatus() means it's still being worked on...
   *
   * @generated from field: google.rpc.Status status = 1;
   */
  status?: Status;

  /**
   * @generated from field: baac.proto.TaskProgress progress = 4;
   */
  progress?: TaskProgress;

  /**
   * output if any.
   *
   * @generated from field: google.protobuf.StringValue output = 5;
   */
  output?: string;

  /**
   * @generated from field: google.protobuf.Timestamp created = 6;
   */
  created?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated = 7;
   */
  updated?: Timestamp;

  constructor(data?: PartialMessage<TaskAttempt>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "baac.proto.TaskAttempt";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "message", T: Status },
    { no: 4, name: "progress", kind: "message", T: TaskProgress },
    { no: 5, name: "output", kind: "message", T: StringValue },
    { no: 6, name: "created", kind: "message", T: Timestamp },
    { no: 7, name: "updated", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskAttempt {
    return new TaskAttempt().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskAttempt {
    return new TaskAttempt().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskAttempt {
    return new TaskAttempt().fromJsonString(jsonString, options);
  }

  static equals(a: TaskAttempt | PlainMessage<TaskAttempt> | undefined, b: TaskAttempt | PlainMessage<TaskAttempt> | undefined): boolean {
    return proto3.util.equals(TaskAttempt, a, b);
  }
}

/**
 * @generated from message baac.proto.Task
 */
export class Task extends Message<Task> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.StringValue key = 2;
   */
  key?: string;

  /**
   * @generated from field: string realm_name = 3;
   */
  realmName = "";

  /**
   * @generated from field: string tube_name = 4;
   */
  tubeName = "";

  /**
   * @generated from field: int32 max_attempts = 5;
   */
  maxAttempts = 0;

  /**
   * They will be ordered by time desc.
   *
   * @generated from field: repeated baac.proto.TaskAttempt attempts = 6;
   */
  attempts: TaskAttempt[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp created = 7;
   */
  created?: Timestamp;

  /**
   * @generated from field: bool is_done = 8;
   */
  isDone = false;

  constructor(data?: PartialMessage<Task>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "baac.proto.Task";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "key", kind: "message", T: StringValue },
    { no: 3, name: "realm_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "tube_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "max_attempts", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "attempts", kind: "message", T: TaskAttempt, repeated: true },
    { no: 7, name: "created", kind: "message", T: Timestamp },
    { no: 8, name: "is_done", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Task {
    return new Task().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Task {
    return new Task().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Task {
    return new Task().fromJsonString(jsonString, options);
  }

  static equals(a: Task | PlainMessage<Task> | undefined, b: Task | PlainMessage<Task> | undefined): boolean {
    return proto3.util.equals(Task, a, b);
  }
}


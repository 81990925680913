// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/payments.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { BasicPagination, CurrencyEnum, FloatRange } from "../../common/proto/common_pb";
import { TimeRestriction } from "./common_pb";
import { CreatorTiniestCard } from "./creators_pb";
import { CampaignSnippet } from "../../common/proto/campaign_pb";
import { UserWithRole } from "./user_role_pb";

/**
 * This is the subscription type for the frontend and for computing
 * limits etc...
 *
 * The paying ones map to a ChargeBee, plan using prefix patterns in Chargebee
 *
 * @generated from enum librarian.proto.SubscriptionType
 */
export enum SubscriptionType {
  /**
   * shouldn't be used, but might be found on legacy values.
   * treated as FREE everywhere.
   *
   * @generated from enum value: SUBSCRIPTION_TYPE_UNKNOWN = 0;
   */
  SUBSCRIPTION_TYPE_UNKNOWN = 0,

  /**
   * @generated from enum value: FREE = 1;
   */
  FREE = 1,

  /**
   * @generated from enum value: STANDARD = 2;
   */
  STANDARD = 2,

  /**
   * @generated from enum value: PRO = 3;
   */
  PRO = 3,

  /**
   * @generated from enum value: PREMIUM = 4;
   */
  PREMIUM = 4,

  /**
   * @generated from enum value: ENTERPRISE = 20;
   */
  ENTERPRISE = 20,

  /**
   * @generated from enum value: STARTER = 6;
   */
  STARTER = 6,

  /**
   * @generated from enum value: TRIAL = 7;
   */
  TRIAL = 7,

  /**
   * @generated from enum value: INACTIVE = 100;
   */
  INACTIVE = 100,
}
// Retrieve enum metadata with: proto3.getEnumType(SubscriptionType)
proto3.util.setEnumType(SubscriptionType, "librarian.proto.SubscriptionType", [
  { no: 0, name: "SUBSCRIPTION_TYPE_UNKNOWN" },
  { no: 1, name: "FREE" },
  { no: 2, name: "STANDARD" },
  { no: 3, name: "PRO" },
  { no: 4, name: "PREMIUM" },
  { no: 20, name: "ENTERPRISE" },
  { no: 6, name: "STARTER" },
  { no: 7, name: "TRIAL" },
  { no: 100, name: "INACTIVE" },
]);

/**
 * This encodes the region we are using to process payments on Stripe.
 * As of Feb 2024, Lefty has 2 different accounts with different conditions : USA and Europe.
 * We will use USA payment region for now on.
 *
 * @generated from enum librarian.proto.StripeRegion
 */
export enum StripeRegion {
  /**
   * @generated from enum value: REGION_UNKNOWN = 0;
   */
  REGION_UNKNOWN = 0,

  /**
   * @generated from enum value: REGION_EU = 1;
   */
  REGION_EU = 1,

  /**
   * @generated from enum value: REGION_US = 2;
   */
  REGION_US = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(StripeRegion)
proto3.util.setEnumType(StripeRegion, "librarian.proto.StripeRegion", [
  { no: 0, name: "REGION_UNKNOWN" },
  { no: 1, name: "REGION_EU" },
  { no: 2, name: "REGION_US" },
]);

/**
 * @generated from enum librarian.proto.PaymentType
 */
export enum PaymentType {
  /**
   * @generated from enum value: PAYMENT_TYPE_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: PAYMENT_TYPE_CARD = 1;
   */
  CARD = 1,

  /**
   * @generated from enum value: PAYMENT_TYPE_CARD_PREMIUM = 2;
   */
  CARD_PREMIUM = 2,

  /**
   * @generated from enum value: PAYMENT_TYPE_SEPA = 3;
   */
  SEPA = 3,

  /**
   * @generated from enum value: PAYMENT_TYPE_US_BANK = 4;
   */
  US_BANK = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(PaymentType)
proto3.util.setEnumType(PaymentType, "librarian.proto.PaymentType", [
  { no: 0, name: "PAYMENT_TYPE_UNKNOWN" },
  { no: 1, name: "PAYMENT_TYPE_CARD" },
  { no: 2, name: "PAYMENT_TYPE_CARD_PREMIUM" },
  { no: 3, name: "PAYMENT_TYPE_SEPA" },
  { no: 4, name: "PAYMENT_TYPE_US_BANK" },
]);

/**
 * @generated from enum librarian.proto.PaymentMethodStatus
 */
export enum PaymentMethodStatus {
  /**
   * @generated from enum value: PAYMENT_METHOD_STATUS_CREATED = 0;
   */
  CREATED = 0,

  /**
   * @generated from enum value: PAYMENT_METHOD_STATUS_DELETED = 1;
   */
  DELETED = 1,

  /**
   * @generated from enum value: PAYMENT_METHOD_STATUS_PENDING = 2;
   */
  PENDING = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(PaymentMethodStatus)
proto3.util.setEnumType(PaymentMethodStatus, "librarian.proto.PaymentMethodStatus", [
  { no: 0, name: "PAYMENT_METHOD_STATUS_CREATED" },
  { no: 1, name: "PAYMENT_METHOD_STATUS_DELETED" },
  { no: 2, name: "PAYMENT_METHOD_STATUS_PENDING" },
]);

/**
 * https://stripe.com/docs/payments/paymentintents/lifecycle#intent-statuses
 *
 * @generated from enum librarian.proto.PaymentStatus
 */
export enum PaymentStatus {
  /**
   * @generated from enum value: PAYMENT_UNKNOWN = 0;
   */
  PAYMENT_UNKNOWN = 0,

  /**
   * @generated from enum value: PAYMENT_PENDING = 1;
   */
  PAYMENT_PENDING = 1,

  /**
   * @generated from enum value: PAYMENT_CANCELED = 2;
   */
  PAYMENT_CANCELED = 2,

  /**
   * @generated from enum value: PAYMENT_REQUIRES_PAYMENT_METHOD = 3;
   */
  PAYMENT_REQUIRES_PAYMENT_METHOD = 3,

  /**
   * @generated from enum value: PAYMENT_SUCCESS = 4;
   */
  PAYMENT_SUCCESS = 4,

  /**
   * @generated from enum value: PAYMENT_REQUIRES_CONFIRMATION = 5;
   */
  PAYMENT_REQUIRES_CONFIRMATION = 5,

  /**
   * @generated from enum value: PAYMENT_REQUIRES_ACTION = 6;
   */
  PAYMENT_REQUIRES_ACTION = 6,

  /**
   * @generated from enum value: PAYMENT_FAILED = 7;
   */
  PAYMENT_FAILED = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(PaymentStatus)
proto3.util.setEnumType(PaymentStatus, "librarian.proto.PaymentStatus", [
  { no: 0, name: "PAYMENT_UNKNOWN" },
  { no: 1, name: "PAYMENT_PENDING" },
  { no: 2, name: "PAYMENT_CANCELED" },
  { no: 3, name: "PAYMENT_REQUIRES_PAYMENT_METHOD" },
  { no: 4, name: "PAYMENT_SUCCESS" },
  { no: 5, name: "PAYMENT_REQUIRES_CONFIRMATION" },
  { no: 6, name: "PAYMENT_REQUIRES_ACTION" },
  { no: 7, name: "PAYMENT_FAILED" },
]);

/**
 * @generated from enum librarian.proto.FeesPaymentOption
 */
export enum FeesPaymentOption {
  /**
   * @generated from enum value: FEES_PAYMENT_OPTION_UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: FEES_PAYMENT_OPTION_CUSTOMER_SIDE = 1;
   */
  CUSTOMER_SIDE = 1,

  /**
   * @generated from enum value: FEES_PAYMENT_OPTION_INFLUENCER_SIDE = 2;
   */
  INFLUENCER_SIDE = 2,

  /**
   * @generated from enum value: FEES_PAYMENT_OPTION_SPLIT = 3;
   */
  SPLIT = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(FeesPaymentOption)
proto3.util.setEnumType(FeesPaymentOption, "librarian.proto.FeesPaymentOption", [
  { no: 0, name: "FEES_PAYMENT_OPTION_UNKNOWN" },
  { no: 1, name: "FEES_PAYMENT_OPTION_CUSTOMER_SIDE" },
  { no: 2, name: "FEES_PAYMENT_OPTION_INFLUENCER_SIDE" },
  { no: 3, name: "FEES_PAYMENT_OPTION_SPLIT" },
]);

/**
 * Note that all possibilities MUST correspond to an existing plan in
 * braintree.
 * The plans ids are named like this in braintree (uppercase):
 *
 * {kind}_{currency}_{yearly if yearly else nothing}_{vat if vat else nothing}
 *
 *  For instance:
 *      - STANDARD_EUROS
 *      - STANDARD_USD
 *      - PRO_USD_YEARLY
 *
 * etc...
 *
 *
 * @generated from message librarian.proto.ChargebeePlan
 */
export class ChargebeePlan extends Message<ChargebeePlan> {
  /**
   * @generated from field: librarian.proto.ChargebeePlan.PlanKind kind = 1;
   */
  kind = ChargebeePlan_PlanKind.PLAN_KIND_UNKNOWN;

  /**
   * @generated from field: librarian.proto.ChargebeePlan.PlanCurrency currency = 2;
   */
  currency = ChargebeePlan_PlanCurrency.PLAN_CURRENCY_UNKNOWN;

  /**
   * @generated from field: bool is_yearly = 3;
   */
  isYearly = false;

  constructor(data?: PartialMessage<ChargebeePlan>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ChargebeePlan";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "kind", kind: "enum", T: proto3.getEnumType(ChargebeePlan_PlanKind) },
    { no: 2, name: "currency", kind: "enum", T: proto3.getEnumType(ChargebeePlan_PlanCurrency) },
    { no: 3, name: "is_yearly", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ChargebeePlan {
    return new ChargebeePlan().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ChargebeePlan {
    return new ChargebeePlan().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ChargebeePlan {
    return new ChargebeePlan().fromJsonString(jsonString, options);
  }

  static equals(a: ChargebeePlan | PlainMessage<ChargebeePlan> | undefined, b: ChargebeePlan | PlainMessage<ChargebeePlan> | undefined): boolean {
    return proto3.util.equals(ChargebeePlan, a, b);
  }
}

/**
 * @generated from enum librarian.proto.ChargebeePlan.PlanKind
 */
export enum ChargebeePlan_PlanKind {
  /**
   * @generated from enum value: PLAN_KIND_UNKNOWN = 0;
   */
  PLAN_KIND_UNKNOWN = 0,

  /**
   * @generated from enum value: STANDARD = 1;
   */
  STANDARD = 1,

  /**
   * @generated from enum value: PRO = 2;
   */
  PRO = 2,

  /**
   * @generated from enum value: STARTER = 3;
   */
  STARTER = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(ChargebeePlan_PlanKind)
proto3.util.setEnumType(ChargebeePlan_PlanKind, "librarian.proto.ChargebeePlan.PlanKind", [
  { no: 0, name: "PLAN_KIND_UNKNOWN" },
  { no: 1, name: "STANDARD" },
  { no: 2, name: "PRO" },
  { no: 3, name: "STARTER" },
]);

/**
 * @generated from enum librarian.proto.ChargebeePlan.PlanCurrency
 */
export enum ChargebeePlan_PlanCurrency {
  /**
   * @generated from enum value: PLAN_CURRENCY_UNKNOWN = 0;
   */
  PLAN_CURRENCY_UNKNOWN = 0,

  /**
   * @generated from enum value: EUROS = 1;
   */
  EUROS = 1,

  /**
   * @generated from enum value: USD = 2;
   */
  USD = 2,

  /**
   * @generated from enum value: BTC = 3;
   */
  BTC = 3,

  /**
   * @generated from enum value: ETH = 4;
   */
  ETH = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(ChargebeePlan_PlanCurrency)
proto3.util.setEnumType(ChargebeePlan_PlanCurrency, "librarian.proto.ChargebeePlan.PlanCurrency", [
  { no: 0, name: "PLAN_CURRENCY_UNKNOWN" },
  { no: 1, name: "EUROS" },
  { no: 2, name: "USD" },
  { no: 3, name: "BTC" },
  { no: 4, name: "ETH" },
]);

/**
 * @generated from message librarian.proto.Invoices
 */
export class Invoices extends Message<Invoices> {
  /**
   * we group the invoices by year to be nice to the frontend.
   *
   * @generated from field: repeated librarian.proto.Invoices.YearlyInvoices invoices = 1;
   */
  invoices: Invoices_YearlyInvoices[] = [];

  /**
   * If this is true and the list is empty, show the offline billing empty.
   *
   * @generated from field: bool is_billed_offline = 2;
   */
  isBilledOffline = false;

  constructor(data?: PartialMessage<Invoices>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Invoices";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "invoices", kind: "message", T: Invoices_YearlyInvoices, repeated: true },
    { no: 2, name: "is_billed_offline", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Invoices {
    return new Invoices().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Invoices {
    return new Invoices().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Invoices {
    return new Invoices().fromJsonString(jsonString, options);
  }

  static equals(a: Invoices | PlainMessage<Invoices> | undefined, b: Invoices | PlainMessage<Invoices> | undefined): boolean {
    return proto3.util.equals(Invoices, a, b);
  }
}

/**
 * @generated from message librarian.proto.Invoices.Invoice
 */
export class Invoices_Invoice extends Message<Invoices_Invoice> {
  /**
   * @generated from field: google.protobuf.Timestamp date = 1;
   */
  date?: Timestamp;

  /**
   * @generated from field: string pdf_link = 2;
   */
  pdfLink = "";

  /**
   * be prepared for empty (in case the invoice is for a one off item).
   *
   * @generated from field: string plan_name = 3;
   */
  planName = "";

  /**
   * @generated from field: string amount = 4;
   */
  amount = "";

  constructor(data?: PartialMessage<Invoices_Invoice>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Invoices.Invoice";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "date", kind: "message", T: Timestamp },
    { no: 2, name: "pdf_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "plan_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "amount", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Invoices_Invoice {
    return new Invoices_Invoice().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Invoices_Invoice {
    return new Invoices_Invoice().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Invoices_Invoice {
    return new Invoices_Invoice().fromJsonString(jsonString, options);
  }

  static equals(a: Invoices_Invoice | PlainMessage<Invoices_Invoice> | undefined, b: Invoices_Invoice | PlainMessage<Invoices_Invoice> | undefined): boolean {
    return proto3.util.equals(Invoices_Invoice, a, b);
  }
}

/**
 * @generated from message librarian.proto.Invoices.YearlyInvoices
 */
export class Invoices_YearlyInvoices extends Message<Invoices_YearlyInvoices> {
  /**
   * @generated from field: int32 year = 1;
   */
  year = 0;

  /**
   * @generated from field: repeated librarian.proto.Invoices.Invoice invoices = 2;
   */
  invoices: Invoices_Invoice[] = [];

  constructor(data?: PartialMessage<Invoices_YearlyInvoices>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Invoices.YearlyInvoices";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "year", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "invoices", kind: "message", T: Invoices_Invoice, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Invoices_YearlyInvoices {
    return new Invoices_YearlyInvoices().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Invoices_YearlyInvoices {
    return new Invoices_YearlyInvoices().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Invoices_YearlyInvoices {
    return new Invoices_YearlyInvoices().fromJsonString(jsonString, options);
  }

  static equals(a: Invoices_YearlyInvoices | PlainMessage<Invoices_YearlyInvoices> | undefined, b: Invoices_YearlyInvoices | PlainMessage<Invoices_YearlyInvoices> | undefined): boolean {
    return proto3.util.equals(Invoices_YearlyInvoices, a, b);
  }
}

/**
 * @generated from message librarian.proto.InvoiceUrlSpec
 */
export class InvoiceUrlSpec extends Message<InvoiceUrlSpec> {
  /**
   * @generated from oneof librarian.proto.InvoiceUrlSpec.id
   */
  id: {
    /**
     * @generated from field: int64 legacy = 1;
     */
    value: bigint;
    case: "legacy";
  } | {
    /**
     * @generated from field: string chargebee = 2;
     */
    value: string;
    case: "chargebee";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<InvoiceUrlSpec>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InvoiceUrlSpec";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "legacy", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "id" },
    { no: 2, name: "chargebee", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "id" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InvoiceUrlSpec {
    return new InvoiceUrlSpec().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InvoiceUrlSpec {
    return new InvoiceUrlSpec().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InvoiceUrlSpec {
    return new InvoiceUrlSpec().fromJsonString(jsonString, options);
  }

  static equals(a: InvoiceUrlSpec | PlainMessage<InvoiceUrlSpec> | undefined, b: InvoiceUrlSpec | PlainMessage<InvoiceUrlSpec> | undefined): boolean {
    return proto3.util.equals(InvoiceUrlSpec, a, b);
  }
}

/**
 * @generated from message librarian.proto.InvoiceDataRequest
 */
export class InvoiceDataRequest extends Message<InvoiceDataRequest> {
  /**
   * @generated from field: librarian.proto.InvoiceUrlSpec spec = 1;
   */
  spec?: InvoiceUrlSpec;

  /**
   * @generated from field: int64 team_owner_id = 2;
   */
  teamOwnerId = protoInt64.zero;

  constructor(data?: PartialMessage<InvoiceDataRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InvoiceDataRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "spec", kind: "message", T: InvoiceUrlSpec },
    { no: 2, name: "team_owner_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InvoiceDataRequest {
    return new InvoiceDataRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InvoiceDataRequest {
    return new InvoiceDataRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InvoiceDataRequest {
    return new InvoiceDataRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InvoiceDataRequest | PlainMessage<InvoiceDataRequest> | undefined, b: InvoiceDataRequest | PlainMessage<InvoiceDataRequest> | undefined): boolean {
    return proto3.util.equals(InvoiceDataRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.InvoiceDataResponse
 */
export class InvoiceDataResponse extends Message<InvoiceDataResponse> {
  /**
   * @generated from oneof librarian.proto.InvoiceDataResponse.data_response
   */
  dataResponse: {
    /**
     * If set, this is PDF data.
     *
     * @generated from field: bytes pdf_data_direct = 1;
     */
    value: Uint8Array;
    case: "pdfDataDirect";
  } | {
    /**
     * If set, the data is at that url.
     *
     * @generated from field: string pdf_data_url = 2;
     */
    value: string;
    case: "pdfDataUrl";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<InvoiceDataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InvoiceDataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pdf_data_direct", kind: "scalar", T: 12 /* ScalarType.BYTES */, oneof: "data_response" },
    { no: 2, name: "pdf_data_url", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "data_response" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InvoiceDataResponse {
    return new InvoiceDataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InvoiceDataResponse {
    return new InvoiceDataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InvoiceDataResponse {
    return new InvoiceDataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InvoiceDataResponse | PlainMessage<InvoiceDataResponse> | undefined, b: InvoiceDataResponse | PlainMessage<InvoiceDataResponse> | undefined): boolean {
    return proto3.util.equals(InvoiceDataResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.Address
 */
export class Address extends Message<Address> {
  /**
   * @generated from field: string city = 1;
   */
  city = "";

  /**
   * @generated from field: string country = 2;
   */
  country = "";

  /**
   * @generated from field: string line1 = 3;
   */
  line1 = "";

  /**
   * @generated from field: string line2 = 4;
   */
  line2 = "";

  /**
   * @generated from field: string postal_code = 5;
   */
  postalCode = "";

  /**
   * @generated from field: string state = 6;
   */
  state = "";

  constructor(data?: PartialMessage<Address>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Address";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "line1", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "line2", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Address {
    return new Address().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Address {
    return new Address().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Address {
    return new Address().fromJsonString(jsonString, options);
  }

  static equals(a: Address | PlainMessage<Address> | undefined, b: Address | PlainMessage<Address> | undefined): boolean {
    return proto3.util.equals(Address, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaymentBillingDetails
 */
export class PaymentBillingDetails extends Message<PaymentBillingDetails> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string phone = 3;
   */
  phone = "";

  /**
   * @generated from field: librarian.proto.Address address = 4;
   */
  address?: Address;

  constructor(data?: PartialMessage<PaymentBillingDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaymentBillingDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "address", kind: "message", T: Address },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentBillingDetails {
    return new PaymentBillingDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentBillingDetails {
    return new PaymentBillingDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentBillingDetails {
    return new PaymentBillingDetails().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentBillingDetails | PlainMessage<PaymentBillingDetails> | undefined, b: PaymentBillingDetails | PlainMessage<PaymentBillingDetails> | undefined): boolean {
    return proto3.util.equals(PaymentBillingDetails, a, b);
  }
}

/**
 * @generated from message librarian.proto.SupportedCurrencies
 */
export class SupportedCurrencies extends Message<SupportedCurrencies> {
  /**
   * @generated from field: repeated common.proto.CurrencyEnum currencies = 1;
   */
  currencies: CurrencyEnum[] = [];

  constructor(data?: PartialMessage<SupportedCurrencies>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SupportedCurrencies";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "currencies", kind: "enum", T: proto3.getEnumType(CurrencyEnum), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SupportedCurrencies {
    return new SupportedCurrencies().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SupportedCurrencies {
    return new SupportedCurrencies().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SupportedCurrencies {
    return new SupportedCurrencies().fromJsonString(jsonString, options);
  }

  static equals(a: SupportedCurrencies | PlainMessage<SupportedCurrencies> | undefined, b: SupportedCurrencies | PlainMessage<SupportedCurrencies> | undefined): boolean {
    return proto3.util.equals(SupportedCurrencies, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaymentMethodPb
 */
export class PaymentMethodPb extends Message<PaymentMethodPb> {
  /**
   * @generated from field: int64 payment_method_id = 6;
   */
  paymentMethodId = protoInt64.zero;

  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: librarian.proto.PaymentBillingDetails billing_details = 2;
   */
  billingDetails?: PaymentBillingDetails;

  /**
   * @generated from field: librarian.proto.PaymentMethodStatus status = 7;
   */
  status = PaymentMethodStatus.CREATED;

  /**
   * @generated from field: google.protobuf.StringValue verification_url = 8;
   */
  verificationUrl?: string;

  /**
   * @generated from field: double fees_percentage = 9;
   */
  feesPercentage = 0;

  /**
   * @generated from oneof librarian.proto.PaymentMethodPb.type
   */
  type: {
    /**
     * @generated from field: librarian.proto.PaymentMethodPb.CardPb card = 3;
     */
    value: PaymentMethodPb_CardPb;
    case: "card";
  } | {
    /**
     * @generated from field: librarian.proto.PaymentMethodPb.SepaPb sepa = 4;
     */
    value: PaymentMethodPb_SepaPb;
    case: "sepa";
  } | {
    /**
     * @generated from field: librarian.proto.PaymentMethodPb.USBankPb us_bank = 5;
     */
    value: PaymentMethodPb_USBankPb;
    case: "usBank";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: repeated common.proto.CurrencyEnum supported_currencies = 100;
   */
  supportedCurrencies: CurrencyEnum[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp created = 101;
   */
  created?: Timestamp;

  constructor(data?: PartialMessage<PaymentMethodPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaymentMethodPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 6, name: "payment_method_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "billing_details", kind: "message", T: PaymentBillingDetails },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(PaymentMethodStatus) },
    { no: 8, name: "verification_url", kind: "message", T: StringValue },
    { no: 9, name: "fees_percentage", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "card", kind: "message", T: PaymentMethodPb_CardPb, oneof: "type" },
    { no: 4, name: "sepa", kind: "message", T: PaymentMethodPb_SepaPb, oneof: "type" },
    { no: 5, name: "us_bank", kind: "message", T: PaymentMethodPb_USBankPb, oneof: "type" },
    { no: 100, name: "supported_currencies", kind: "enum", T: proto3.getEnumType(CurrencyEnum), repeated: true },
    { no: 101, name: "created", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentMethodPb {
    return new PaymentMethodPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentMethodPb {
    return new PaymentMethodPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentMethodPb {
    return new PaymentMethodPb().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentMethodPb | PlainMessage<PaymentMethodPb> | undefined, b: PaymentMethodPb | PlainMessage<PaymentMethodPb> | undefined): boolean {
    return proto3.util.equals(PaymentMethodPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaymentMethodPb.CardPb
 */
export class PaymentMethodPb_CardPb extends Message<PaymentMethodPb_CardPb> {
  /**
   * VISA, MasterCard ...
   *
   * @generated from field: string brand = 1;
   */
  brand = "";

  /**
   * @generated from field: int64 exp_month = 2;
   */
  expMonth = protoInt64.zero;

  /**
   * @generated from field: int64 exp_year = 3;
   */
  expYear = protoInt64.zero;

  /**
   * The last four digits of the card.
   *
   * @generated from field: string last4 = 4;
   */
  last4 = "";

  constructor(data?: PartialMessage<PaymentMethodPb_CardPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaymentMethodPb.CardPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brand", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "exp_month", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "exp_year", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "last4", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentMethodPb_CardPb {
    return new PaymentMethodPb_CardPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentMethodPb_CardPb {
    return new PaymentMethodPb_CardPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentMethodPb_CardPb {
    return new PaymentMethodPb_CardPb().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentMethodPb_CardPb | PlainMessage<PaymentMethodPb_CardPb> | undefined, b: PaymentMethodPb_CardPb | PlainMessage<PaymentMethodPb_CardPb> | undefined): boolean {
    return proto3.util.equals(PaymentMethodPb_CardPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaymentMethodPb.SepaPb
 */
export class PaymentMethodPb_SepaPb extends Message<PaymentMethodPb_SepaPb> {
  /**
   * Bank code of bank associated with the bank account.
   *
   * @generated from field: string bank_code = 1;
   */
  bankCode = "";

  /**
   * Branch code of bank associated with the bank account.
   *
   * @generated from field: string branch_code = 2;
   */
  branchCode = "";

  /**
   * Two-letter ISO code representing the country the bank account is located in.
   *
   * @generated from field: string country = 3;
   */
  country = "";

  /**
   * Last four characters of the IBAN.
   *
   * @generated from field: string last4 = 4;
   */
  last4 = "";

  constructor(data?: PartialMessage<PaymentMethodPb_SepaPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaymentMethodPb.SepaPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bank_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "branch_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last4", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentMethodPb_SepaPb {
    return new PaymentMethodPb_SepaPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentMethodPb_SepaPb {
    return new PaymentMethodPb_SepaPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentMethodPb_SepaPb {
    return new PaymentMethodPb_SepaPb().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentMethodPb_SepaPb | PlainMessage<PaymentMethodPb_SepaPb> | undefined, b: PaymentMethodPb_SepaPb | PlainMessage<PaymentMethodPb_SepaPb> | undefined): boolean {
    return proto3.util.equals(PaymentMethodPb_SepaPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaymentMethodPb.USBankPb
 */
export class PaymentMethodPb_USBankPb extends Message<PaymentMethodPb_USBankPb> {
  /**
   * @generated from field: string bank_name = 1;
   */
  bankName = "";

  /**
   * Last four digits of the bank account number.
   *
   * @generated from field: string last4 = 2;
   */
  last4 = "";

  /**
   * Routing number of the bank account.
   *
   * @generated from field: string routing_number = 3;
   */
  routingNumber = "";

  constructor(data?: PartialMessage<PaymentMethodPb_USBankPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaymentMethodPb.USBankPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "bank_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "last4", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "routing_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentMethodPb_USBankPb {
    return new PaymentMethodPb_USBankPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentMethodPb_USBankPb {
    return new PaymentMethodPb_USBankPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentMethodPb_USBankPb {
    return new PaymentMethodPb_USBankPb().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentMethodPb_USBankPb | PlainMessage<PaymentMethodPb_USBankPb> | undefined, b: PaymentMethodPb_USBankPb | PlainMessage<PaymentMethodPb_USBankPb> | undefined): boolean {
    return proto3.util.equals(PaymentMethodPb_USBankPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaymentMethodsRequest
 */
export class PaymentMethodsRequest extends Message<PaymentMethodsRequest> {
  /**
   * if true, return all already used payment methods, else return current payment methods to use to make payments
   * (pending and created payment methods)
   *
   * @generated from field: bool already_used = 1;
   */
  alreadyUsed = false;

  constructor(data?: PartialMessage<PaymentMethodsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaymentMethodsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "already_used", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentMethodsRequest {
    return new PaymentMethodsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentMethodsRequest {
    return new PaymentMethodsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentMethodsRequest {
    return new PaymentMethodsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentMethodsRequest | PlainMessage<PaymentMethodsRequest> | undefined, b: PaymentMethodsRequest | PlainMessage<PaymentMethodsRequest> | undefined): boolean {
    return proto3.util.equals(PaymentMethodsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaymentMethodsResponse
 */
export class PaymentMethodsResponse extends Message<PaymentMethodsResponse> {
  /**
   * @generated from field: librarian.proto.StripeRegion stripe_region = 2;
   */
  stripeRegion = StripeRegion.REGION_UNKNOWN;

  /**
   * @generated from field: repeated librarian.proto.PaymentMethodPb payment_methods = 1;
   */
  paymentMethods: PaymentMethodPb[] = [];

  constructor(data?: PartialMessage<PaymentMethodsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaymentMethodsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "stripe_region", kind: "enum", T: proto3.getEnumType(StripeRegion) },
    { no: 1, name: "payment_methods", kind: "message", T: PaymentMethodPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentMethodsResponse {
    return new PaymentMethodsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentMethodsResponse {
    return new PaymentMethodsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentMethodsResponse {
    return new PaymentMethodsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentMethodsResponse | PlainMessage<PaymentMethodsResponse> | undefined, b: PaymentMethodsResponse | PlainMessage<PaymentMethodsResponse> | undefined): boolean {
    return proto3.util.equals(PaymentMethodsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaymentRequest
 */
export class PaymentRequest extends Message<PaymentRequest> {
  /**
   * @generated from field: string creator_id = 1;
   */
  creatorId = "";

  /**
   * @generated from field: string payment_method_id = 2;
   */
  paymentMethodId = "";

  /**
   * @generated from field: double amount = 3;
   */
  amount = 0;

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 4;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: google.protobuf.Int64Value from_campaign_id = 5;
   */
  fromCampaignId?: bigint;

  /**
   * @generated from field: google.protobuf.StringValue note = 6;
   */
  note?: string;

  /**
   * @generated from field: librarian.proto.FeesPaymentOption fees_payment_option = 7;
   */
  feesPaymentOption = FeesPaymentOption.UNKNOWN;

  constructor(data?: PartialMessage<PaymentRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaymentRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "payment_method_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 5, name: "from_campaign_id", kind: "message", T: Int64Value },
    { no: 6, name: "note", kind: "message", T: StringValue },
    { no: 7, name: "fees_payment_option", kind: "enum", T: proto3.getEnumType(FeesPaymentOption) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentRequest {
    return new PaymentRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentRequest {
    return new PaymentRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentRequest {
    return new PaymentRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentRequest | PlainMessage<PaymentRequest> | undefined, b: PaymentRequest | PlainMessage<PaymentRequest> | undefined): boolean {
    return proto3.util.equals(PaymentRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaymentFilter
 */
export class PaymentFilter extends Message<PaymentFilter> {
  /**
   * @generated from field: librarian.proto.TimeRestriction time_restriction = 1;
   */
  timeRestriction?: TimeRestriction;

  /**
   * @generated from field: repeated librarian.proto.PaymentStatus statuses = 2;
   */
  statuses: PaymentStatus[] = [];

  /**
   * @generated from field: repeated int64 paid_author_ids = 3;
   */
  paidAuthorIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 paying_user_ids = 4;
   */
  payingUserIds: bigint[] = [];

  /**
   * @generated from field: librarian.proto.PaymentSourcesFilter sources = 5;
   */
  sources?: PaymentSourcesFilter;

  /**
   * @generated from field: repeated librarian.proto.PaymentMethodPb payment_methods = 6 [deprecated = true];
   * @deprecated
   */
  paymentMethods: PaymentMethodPb[] = [];

  /**
   * @generated from field: repeated int64 payment_method_ids = 10;
   */
  paymentMethodIds: bigint[] = [];

  /**
   * @generated from field: repeated common.proto.CurrencyEnum currencies = 7;
   */
  currencies: CurrencyEnum[] = [];

  /**
   * @generated from field: common.proto.FloatRange amount = 8;
   */
  amount?: FloatRange;

  /**
   * @generated from field: string paid_author_username_prefix = 9;
   */
  paidAuthorUsernamePrefix = "";

  constructor(data?: PartialMessage<PaymentFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaymentFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "time_restriction", kind: "message", T: TimeRestriction },
    { no: 2, name: "statuses", kind: "enum", T: proto3.getEnumType(PaymentStatus), repeated: true },
    { no: 3, name: "paid_author_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "paying_user_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 5, name: "sources", kind: "message", T: PaymentSourcesFilter },
    { no: 6, name: "payment_methods", kind: "message", T: PaymentMethodPb, repeated: true },
    { no: 10, name: "payment_method_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 7, name: "currencies", kind: "enum", T: proto3.getEnumType(CurrencyEnum), repeated: true },
    { no: 8, name: "amount", kind: "message", T: FloatRange },
    { no: 9, name: "paid_author_username_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentFilter {
    return new PaymentFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentFilter {
    return new PaymentFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentFilter {
    return new PaymentFilter().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentFilter | PlainMessage<PaymentFilter> | undefined, b: PaymentFilter | PlainMessage<PaymentFilter> | undefined): boolean {
    return proto3.util.equals(PaymentFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaymentsRequest
 */
export class PaymentsRequest extends Message<PaymentsRequest> {
  /**
   * @generated from field: common.proto.BasicPagination pagination = 1;
   */
  pagination?: BasicPagination;

  /**
   * @generated from field: librarian.proto.PaymentFilter filter = 2;
   */
  filter?: PaymentFilter;

  constructor(data?: PartialMessage<PaymentsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaymentsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: BasicPagination },
    { no: 2, name: "filter", kind: "message", T: PaymentFilter },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentsRequest {
    return new PaymentsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentsRequest {
    return new PaymentsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentsRequest {
    return new PaymentsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentsRequest | PlainMessage<PaymentsRequest> | undefined, b: PaymentsRequest | PlainMessage<PaymentsRequest> | undefined): boolean {
    return proto3.util.equals(PaymentsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaymentStatusResponse
 */
export class PaymentStatusResponse extends Message<PaymentStatusResponse> {
  /**
   * @generated from field: librarian.proto.CreatorTiniestCard creator = 1;
   */
  creator?: CreatorTiniestCard;

  /**
   * the amount without fees
   *
   * @generated from field: double amount = 2;
   */
  amount = 0;

  /**
   * fees in percent
   *
   * @generated from field: double fee = 3;
   */
  fee = 0;

  /**
   * @generated from field: librarian.proto.FeesPaymentOption fees_payment_option = 15;
   */
  feesPaymentOption = FeesPaymentOption.UNKNOWN;

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 4;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: common.proto.CampaignSnippet campaign = 5;
   */
  campaign?: CampaignSnippet;

  /**
   * @generated from field: librarian.proto.PaymentStatus status = 6;
   */
  status = PaymentStatus.PAYMENT_UNKNOWN;

  /**
   * @generated from field: google.protobuf.Timestamp timestamp = 7;
   */
  timestamp?: Timestamp;

  /**
   * @generated from field: string real_user_email = 8;
   */
  realUserEmail = "";

  /**
   * @generated from field: librarian.proto.PaymentMethodPb payment_method = 9;
   */
  paymentMethod?: PaymentMethodPb;

  /**
   * @generated from field: string receipt_url = 11;
   */
  receiptUrl = "";

  /**
   * @generated from field: librarian.proto.StripePaymentNotePb note = 12;
   */
  note?: StripePaymentNotePb;

  /**
   * @generated from field: string stripe_id = 13;
   */
  stripeId = "";

  /**
   * @generated from field: int64 id = 14;
   */
  id = protoInt64.zero;

  constructor(data?: PartialMessage<PaymentStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaymentStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator", kind: "message", T: CreatorTiniestCard },
    { no: 2, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "fee", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 15, name: "fees_payment_option", kind: "enum", T: proto3.getEnumType(FeesPaymentOption) },
    { no: 4, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 5, name: "campaign", kind: "message", T: CampaignSnippet },
    { no: 6, name: "status", kind: "enum", T: proto3.getEnumType(PaymentStatus) },
    { no: 7, name: "timestamp", kind: "message", T: Timestamp },
    { no: 8, name: "real_user_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "payment_method", kind: "message", T: PaymentMethodPb },
    { no: 11, name: "receipt_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "note", kind: "message", T: StripePaymentNotePb },
    { no: 13, name: "stripe_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentStatusResponse {
    return new PaymentStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentStatusResponse {
    return new PaymentStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentStatusResponse {
    return new PaymentStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentStatusResponse | PlainMessage<PaymentStatusResponse> | undefined, b: PaymentStatusResponse | PlainMessage<PaymentStatusResponse> | undefined): boolean {
    return proto3.util.equals(PaymentStatusResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaymentStatusesResponse
 */
export class PaymentStatusesResponse extends Message<PaymentStatusesResponse> {
  /**
   * @generated from field: repeated librarian.proto.PaymentStatusResponse payment_statuses = 1;
   */
  paymentStatuses: PaymentStatusResponse[] = [];

  /**
   * @generated from field: google.protobuf.StringValue next_page_token = 2;
   */
  nextPageToken?: string;

  /**
   * @generated from field: int32 total_hits = 3;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<PaymentStatusesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaymentStatusesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "payment_statuses", kind: "message", T: PaymentStatusResponse, repeated: true },
    { no: 2, name: "next_page_token", kind: "message", T: StringValue },
    { no: 3, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentStatusesResponse {
    return new PaymentStatusesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentStatusesResponse {
    return new PaymentStatusesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentStatusesResponse {
    return new PaymentStatusesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentStatusesResponse | PlainMessage<PaymentStatusesResponse> | undefined, b: PaymentStatusesResponse | PlainMessage<PaymentStatusesResponse> | undefined): boolean {
    return proto3.util.equals(PaymentStatusesResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.FeesRequest
 */
export class FeesRequest extends Message<FeesRequest> {
  /**
   * @generated from field: string payment_method_id = 1;
   */
  paymentMethodId = "";

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 2;
   */
  currency = CurrencyEnum.NA;

  /**
   * @generated from field: double amount = 3;
   */
  amount = 0;

  constructor(data?: PartialMessage<FeesRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.FeesRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "payment_method_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
    { no: 3, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FeesRequest {
    return new FeesRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FeesRequest {
    return new FeesRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FeesRequest {
    return new FeesRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FeesRequest | PlainMessage<FeesRequest> | undefined, b: FeesRequest | PlainMessage<FeesRequest> | undefined): boolean {
    return proto3.util.equals(FeesRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.FeesResponse
 */
export class FeesResponse extends Message<FeesResponse> {
  /**
   * @generated from field: double fee = 1;
   */
  fee = 0;

  constructor(data?: PartialMessage<FeesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.FeesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "fee", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FeesResponse {
    return new FeesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FeesResponse {
    return new FeesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FeesResponse {
    return new FeesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FeesResponse | PlainMessage<FeesResponse> | undefined, b: FeesResponse | PlainMessage<FeesResponse> | undefined): boolean {
    return proto3.util.equals(FeesResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaymentSource
 */
export class PaymentSource extends Message<PaymentSource> {
  /**
   * @generated from oneof librarian.proto.PaymentSource.source
   */
  source: {
    /**
     * @generated from field: int64 campaign_id = 1;
     */
    value: bigint;
    case: "campaignId";
  } | {
    /**
     * @generated from field: bool is_affiliation = 2;
     */
    value: boolean;
    case: "isAffiliation";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<PaymentSource>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaymentSource";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "source" },
    { no: 2, name: "is_affiliation", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "source" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentSource {
    return new PaymentSource().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentSource {
    return new PaymentSource().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentSource {
    return new PaymentSource().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentSource | PlainMessage<PaymentSource> | undefined, b: PaymentSource | PlainMessage<PaymentSource> | undefined): boolean {
    return proto3.util.equals(PaymentSource, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaymentSourcesFilter
 */
export class PaymentSourcesFilter extends Message<PaymentSourcesFilter> {
  /**
   * @generated from field: repeated int64 campaign_ids = 1;
   */
  campaignIds: bigint[] = [];

  /**
   * @generated from field: bool affiliation = 2;
   */
  affiliation = false;

  constructor(data?: PartialMessage<PaymentSourcesFilter>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaymentSourcesFilter";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 2, name: "affiliation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentSourcesFilter {
    return new PaymentSourcesFilter().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentSourcesFilter {
    return new PaymentSourcesFilter().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentSourcesFilter {
    return new PaymentSourcesFilter().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentSourcesFilter | PlainMessage<PaymentSourcesFilter> | undefined, b: PaymentSourcesFilter | PlainMessage<PaymentSourcesFilter> | undefined): boolean {
    return proto3.util.equals(PaymentSourcesFilter, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaidAmountRequest
 */
export class PaidAmountRequest extends Message<PaidAmountRequest> {
  /**
   * @generated from oneof librarian.proto.PaidAmountRequest.id
   */
  id: {
    /**
     * @generated from field: string influencer_id = 1;
     */
    value: string;
    case: "influencerId";
  } | {
    /**
     * @generated from field: int64 author_id = 2;
     */
    value: bigint;
    case: "authorId";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: librarian.proto.PaymentSource source = 3;
   */
  source?: PaymentSource;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<PaidAmountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaidAmountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer_id", kind: "scalar", T: 9 /* ScalarType.STRING */, oneof: "id" },
    { no: 2, name: "author_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, oneof: "id" },
    { no: 3, name: "source", kind: "message", T: PaymentSource },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaidAmountRequest {
    return new PaidAmountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaidAmountRequest {
    return new PaidAmountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaidAmountRequest {
    return new PaidAmountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PaidAmountRequest | PlainMessage<PaidAmountRequest> | undefined, b: PaidAmountRequest | PlainMessage<PaidAmountRequest> | undefined): boolean {
    return proto3.util.equals(PaidAmountRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaidAmountResponse
 */
export class PaidAmountResponse extends Message<PaidAmountResponse> {
  /**
   * @generated from field: repeated librarian.proto.PaidAmountResponse.PaidAmount paid_amounts = 1;
   */
  paidAmounts: PaidAmountResponse_PaidAmount[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<PaidAmountResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaidAmountResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "paid_amounts", kind: "message", T: PaidAmountResponse_PaidAmount, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaidAmountResponse {
    return new PaidAmountResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaidAmountResponse {
    return new PaidAmountResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaidAmountResponse {
    return new PaidAmountResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PaidAmountResponse | PlainMessage<PaidAmountResponse> | undefined, b: PaidAmountResponse | PlainMessage<PaidAmountResponse> | undefined): boolean {
    return proto3.util.equals(PaidAmountResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaidAmountResponse.PaidAmount
 */
export class PaidAmountResponse_PaidAmount extends Message<PaidAmountResponse_PaidAmount> {
  /**
   * @generated from field: double amount = 1;
   */
  amount = 0;

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 2;
   */
  currency = CurrencyEnum.NA;

  constructor(data?: PartialMessage<PaidAmountResponse_PaidAmount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaidAmountResponse.PaidAmount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "amount", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaidAmountResponse_PaidAmount {
    return new PaidAmountResponse_PaidAmount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaidAmountResponse_PaidAmount {
    return new PaidAmountResponse_PaidAmount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaidAmountResponse_PaidAmount {
    return new PaidAmountResponse_PaidAmount().fromJsonString(jsonString, options);
  }

  static equals(a: PaidAmountResponse_PaidAmount | PlainMessage<PaidAmountResponse_PaidAmount> | undefined, b: PaidAmountResponse_PaidAmount | PlainMessage<PaidAmountResponse_PaidAmount> | undefined): boolean {
    return proto3.util.equals(PaidAmountResponse_PaidAmount, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaidInfluencersResponse
 */
export class PaidInfluencersResponse extends Message<PaidInfluencersResponse> {
  /**
   * @generated from field: repeated librarian.proto.PaidInfluencersResponse.PaidInfluencer influencer = 1;
   */
  influencer: PaidInfluencersResponse_PaidInfluencer[] = [];

  constructor(data?: PartialMessage<PaidInfluencersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaidInfluencersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencer", kind: "message", T: PaidInfluencersResponse_PaidInfluencer, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaidInfluencersResponse {
    return new PaidInfluencersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaidInfluencersResponse {
    return new PaidInfluencersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaidInfluencersResponse {
    return new PaidInfluencersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PaidInfluencersResponse | PlainMessage<PaidInfluencersResponse> | undefined, b: PaidInfluencersResponse | PlainMessage<PaidInfluencersResponse> | undefined): boolean {
    return proto3.util.equals(PaidInfluencersResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaidInfluencersResponse.PaidInfluencer
 */
export class PaidInfluencersResponse_PaidInfluencer extends Message<PaidInfluencersResponse_PaidInfluencer> {
  /**
   * @generated from field: librarian.proto.CreatorTiniestCard creator = 1;
   */
  creator?: CreatorTiniestCard;

  /**
   * any paid influencer has an author id.
   *
   * @generated from field: int64 author_id = 2;
   */
  authorId = protoInt64.zero;

  constructor(data?: PartialMessage<PaidInfluencersResponse_PaidInfluencer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaidInfluencersResponse.PaidInfluencer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator", kind: "message", T: CreatorTiniestCard },
    { no: 2, name: "author_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaidInfluencersResponse_PaidInfluencer {
    return new PaidInfluencersResponse_PaidInfluencer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaidInfluencersResponse_PaidInfluencer {
    return new PaidInfluencersResponse_PaidInfluencer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaidInfluencersResponse_PaidInfluencer {
    return new PaidInfluencersResponse_PaidInfluencer().fromJsonString(jsonString, options);
  }

  static equals(a: PaidInfluencersResponse_PaidInfluencer | PlainMessage<PaidInfluencersResponse_PaidInfluencer> | undefined, b: PaidInfluencersResponse_PaidInfluencer | PlainMessage<PaidInfluencersResponse_PaidInfluencer> | undefined): boolean {
    return proto3.util.equals(PaidInfluencersResponse_PaidInfluencer, a, b);
  }
}

/**
 * @generated from message librarian.proto.PayingUsersResponse
 */
export class PayingUsersResponse extends Message<PayingUsersResponse> {
  /**
   * @generated from field: repeated librarian.proto.UserWithRole users = 1;
   */
  users: UserWithRole[] = [];

  constructor(data?: PartialMessage<PayingUsersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PayingUsersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "users", kind: "message", T: UserWithRole, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PayingUsersResponse {
    return new PayingUsersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PayingUsersResponse {
    return new PayingUsersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PayingUsersResponse {
    return new PayingUsersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PayingUsersResponse | PlainMessage<PayingUsersResponse> | undefined, b: PayingUsersResponse | PlainMessage<PayingUsersResponse> | undefined): boolean {
    return proto3.util.equals(PayingUsersResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.PaymentSourcesResponse
 */
export class PaymentSourcesResponse extends Message<PaymentSourcesResponse> {
  /**
   * @generated from field: repeated common.proto.CampaignSnippet campaigns = 1;
   */
  campaigns: CampaignSnippet[] = [];

  /**
   * is true if there are payments from affiliation
   *
   * @generated from field: bool affiliation = 2;
   */
  affiliation = false;

  constructor(data?: PartialMessage<PaymentSourcesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PaymentSourcesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaigns", kind: "message", T: CampaignSnippet, repeated: true },
    { no: 2, name: "affiliation", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PaymentSourcesResponse {
    return new PaymentSourcesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PaymentSourcesResponse {
    return new PaymentSourcesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PaymentSourcesResponse {
    return new PaymentSourcesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PaymentSourcesResponse | PlainMessage<PaymentSourcesResponse> | undefined, b: PaymentSourcesResponse | PlainMessage<PaymentSourcesResponse> | undefined): boolean {
    return proto3.util.equals(PaymentSourcesResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.StripePaymentNotePb
 */
export class StripePaymentNotePb extends Message<StripePaymentNotePb> {
  /**
   * @generated from field: string note_content = 1;
   */
  noteContent = "";

  /**
   * @generated from field: google.protobuf.Timestamp note_created_at = 2;
   */
  noteCreatedAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp note_updated_at = 3;
   */
  noteUpdatedAt?: Timestamp;

  constructor(data?: PartialMessage<StripePaymentNotePb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.StripePaymentNotePb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "note_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "note_created_at", kind: "message", T: Timestamp },
    { no: 3, name: "note_updated_at", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): StripePaymentNotePb {
    return new StripePaymentNotePb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): StripePaymentNotePb {
    return new StripePaymentNotePb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): StripePaymentNotePb {
    return new StripePaymentNotePb().fromJsonString(jsonString, options);
  }

  static equals(a: StripePaymentNotePb | PlainMessage<StripePaymentNotePb> | undefined, b: StripePaymentNotePb | PlainMessage<StripePaymentNotePb> | undefined): boolean {
    return proto3.util.equals(StripePaymentNotePb, a, b);
  }
}

/**
 * @generated from message librarian.proto.AddOrEditNoteRequest
 */
export class AddOrEditNoteRequest extends Message<AddOrEditNoteRequest> {
  /**
   * @generated from field: int64 payment_id = 1;
   */
  paymentId = protoInt64.zero;

  /**
   * @generated from field: string note_content = 2;
   */
  noteContent = "";

  constructor(data?: PartialMessage<AddOrEditNoteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AddOrEditNoteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "payment_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "note_content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddOrEditNoteRequest {
    return new AddOrEditNoteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddOrEditNoteRequest {
    return new AddOrEditNoteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddOrEditNoteRequest {
    return new AddOrEditNoteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddOrEditNoteRequest | PlainMessage<AddOrEditNoteRequest> | undefined, b: AddOrEditNoteRequest | PlainMessage<AddOrEditNoteRequest> | undefined): boolean {
    return proto3.util.equals(AddOrEditNoteRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.DeleteNoteRequest
 */
export class DeleteNoteRequest extends Message<DeleteNoteRequest> {
  /**
   * @generated from field: int64 payment_id = 1;
   */
  paymentId = protoInt64.zero;

  constructor(data?: PartialMessage<DeleteNoteRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DeleteNoteRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "payment_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteNoteRequest {
    return new DeleteNoteRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteNoteRequest {
    return new DeleteNoteRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteNoteRequest {
    return new DeleteNoteRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteNoteRequest | PlainMessage<DeleteNoteRequest> | undefined, b: DeleteNoteRequest | PlainMessage<DeleteNoteRequest> | undefined): boolean {
    return proto3.util.equals(DeleteNoteRequest, a, b);
  }
}


// This file defines protocol buffers for the wechat stuff that gets persisted.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/wechat.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ComputedGenerikStats, PostType, StringList } from "./common_pb";
import { PostInteractions } from "./campaign_pb";
import { GenerikMediaFacts } from "./media_pb";

/**
 * @generated from message common.proto.WeChatCreator
 */
export class WeChatCreator extends Message<WeChatCreator> {
  /**
   * Note : we're using biz after all and not account id as account id can be changed
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string nickname = 2;
   */
  nickname = "";

  /**
   * @generated from field: string account_id = 7;
   */
  accountId = "";

  /**
   * @generated from field: string biz = 3;
   */
  biz = "";

  /**
   * @generated from field: string biz_uin = 8;
   */
  bizUin = "";

  /**
   * @generated from field: string code = 9;
   */
  code = "";

  /**
   * @generated from field: string account_type = 4;
   */
  accountType = "";

  /**
   * Like bio
   *
   * @generated from field: string profile = 5;
   */
  profile = "";

  /**
   * @generated from field: string profile_pic = 6;
   */
  profilePic = "";

  /**
   * @generated from field: common.proto.ComputedGenerikStats computed_stats = 20;
   */
  computedStats?: ComputedGenerikStats;

  constructor(data?: PartialMessage<WeChatCreator>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeChatCreator";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "biz", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "biz_uin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "account_type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "profile", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "profile_pic", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "computed_stats", kind: "message", T: ComputedGenerikStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeChatCreator {
    return new WeChatCreator().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeChatCreator {
    return new WeChatCreator().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeChatCreator {
    return new WeChatCreator().fromJsonString(jsonString, options);
  }

  static equals(a: WeChatCreator | PlainMessage<WeChatCreator> | undefined, b: WeChatCreator | PlainMessage<WeChatCreator> | undefined): boolean {
    return proto3.util.equals(WeChatCreator, a, b);
  }
}

/**
 * @generated from message common.proto.WeChatVideoPageInfo
 */
export class WeChatVideoPageInfo extends Message<WeChatVideoPageInfo> {
  /**
   * @generated from field: string video_id = 1;
   */
  videoId = "";

  /**
   * @generated from field: string source_bizuin = 2;
   */
  sourceBizuin = "";

  /**
   * @generated from field: string content_noencode = 3;
   */
  contentNoencode = "";

  /**
   * @generated from field: string cover_url = 4;
   */
  coverUrl = "";

  /**
   * @generated from field: repeated common.proto.WeChatVideoPageInfo.WeChatMpVideoTransInfo mp_video_trans_info = 5;
   */
  mpVideoTransInfo: WeChatVideoPageInfo_WeChatMpVideoTransInfo[] = [];

  constructor(data?: PartialMessage<WeChatVideoPageInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeChatVideoPageInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "video_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "source_bizuin", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "content_noencode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "cover_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "mp_video_trans_info", kind: "message", T: WeChatVideoPageInfo_WeChatMpVideoTransInfo, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeChatVideoPageInfo {
    return new WeChatVideoPageInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeChatVideoPageInfo {
    return new WeChatVideoPageInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeChatVideoPageInfo {
    return new WeChatVideoPageInfo().fromJsonString(jsonString, options);
  }

  static equals(a: WeChatVideoPageInfo | PlainMessage<WeChatVideoPageInfo> | undefined, b: WeChatVideoPageInfo | PlainMessage<WeChatVideoPageInfo> | undefined): boolean {
    return proto3.util.equals(WeChatVideoPageInfo, a, b);
  }
}

/**
 * @generated from message common.proto.WeChatVideoPageInfo.WeChatMpVideoTransInfo
 */
export class WeChatVideoPageInfo_WeChatMpVideoTransInfo extends Message<WeChatVideoPageInfo_WeChatMpVideoTransInfo> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  /**
   * in SECONDS
   *
   * @generated from field: float duration = 2;
   */
  duration = 0;

  /**
   * in bytes
   *
   * @generated from field: int64 filesize = 3;
   */
  filesize = protoInt64.zero;

  /**
   * @generated from field: int32 width = 4;
   */
  width = 0;

  /**
   * @generated from field: int32 height = 5;
   */
  height = 0;

  /**
   * @generated from field: int32 video_quality_level = 6;
   */
  videoQualityLevel = 0;

  constructor(data?: PartialMessage<WeChatVideoPageInfo_WeChatMpVideoTransInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeChatVideoPageInfo.WeChatMpVideoTransInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "duration", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 3, name: "filesize", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "video_quality_level", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeChatVideoPageInfo_WeChatMpVideoTransInfo {
    return new WeChatVideoPageInfo_WeChatMpVideoTransInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeChatVideoPageInfo_WeChatMpVideoTransInfo {
    return new WeChatVideoPageInfo_WeChatMpVideoTransInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeChatVideoPageInfo_WeChatMpVideoTransInfo {
    return new WeChatVideoPageInfo_WeChatMpVideoTransInfo().fromJsonString(jsonString, options);
  }

  static equals(a: WeChatVideoPageInfo_WeChatMpVideoTransInfo | PlainMessage<WeChatVideoPageInfo_WeChatMpVideoTransInfo> | undefined, b: WeChatVideoPageInfo_WeChatMpVideoTransInfo | PlainMessage<WeChatVideoPageInfo_WeChatMpVideoTransInfo> | undefined): boolean {
    return proto3.util.equals(WeChatVideoPageInfo_WeChatMpVideoTransInfo, a, b);
  }
}

/**
 * @generated from message common.proto.WeChatMediaMetadata
 */
export class WeChatMediaMetadata extends Message<WeChatMediaMetadata> {
  /**
   * bizuin
   *
   * @generated from field: string creator_id = 1;
   */
  creatorId = "";

  /**
   * mid
   *
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: common.proto.PostType post_type = 3;
   */
  postType = PostType.POST_TYPE_UNDEFINED;

  /**
   * @generated from field: string link = 4;
   */
  link = "";

  /**
   * @generated from field: google.protobuf.Timestamp creation_time = 5;
   */
  creationTime?: Timestamp;

  /**
   * @generated from field: string text = 6;
   */
  text = "";

  /**
   * @generated from field: string content_noencode = 7;
   */
  contentNoencode = "";

  /**
   * @generated from field: string desc = 8;
   */
  desc = "";

  /**
   * @generated from field: string title = 9;
   */
  title = "";

  /**
   * @generated from field: string signature = 10;
   */
  signature = "";

  /**
   * @generated from field: string lang = 11;
   */
  lang = "";

  /**
   * @generated from field: string sn = 12;
   */
  sn = "";

  /**
   * @generated from field: common.proto.StringList images = 13;
   */
  images?: StringList;

  /**
   * @generated from field: string cdn_url = 23;
   */
  cdnUrl = "";

  /**
   * @generated from field: string ori_head_img_url = 14;
   */
  oriHeadImgUrl = "";

  /**
   * @generated from field: repeated string video_ids = 15;
   */
  videoIds: string[] = [];

  /**
   * @generated from field: repeated common.proto.WeChatVideoPageInfo video_page_infos = 18;
   */
  videoPageInfos: WeChatVideoPageInfo[] = [];

  /**
   * @generated from field: repeated string video_in_article = 16;
   */
  videoInArticle: string[] = [];

  /**
   * Native type
   *
   * @generated from field: int32 type = 17;
   */
  type = 0;

  /**
   * @generated from field: repeated string mentions = 21;
   */
  mentions: string[] = [];

  /**
   * @generated from field: repeated string tags = 22;
   */
  tags: string[] = [];

  /**
   * @generated from field: common.proto.PostInteractions interactions = 100;
   */
  interactions?: PostInteractions;

  constructor(data?: PartialMessage<WeChatMediaMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeChatMediaMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType) },
    { no: 4, name: "link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "creation_time", kind: "message", T: Timestamp },
    { no: 6, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "content_noencode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "desc", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "signature", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "lang", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "sn", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "images", kind: "message", T: StringList },
    { no: 23, name: "cdn_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "ori_head_img_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "video_ids", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 18, name: "video_page_infos", kind: "message", T: WeChatVideoPageInfo, repeated: true },
    { no: 16, name: "video_in_article", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 17, name: "type", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 21, name: "mentions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 22, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 100, name: "interactions", kind: "message", T: PostInteractions },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeChatMediaMetadata {
    return new WeChatMediaMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeChatMediaMetadata {
    return new WeChatMediaMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeChatMediaMetadata {
    return new WeChatMediaMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: WeChatMediaMetadata | PlainMessage<WeChatMediaMetadata> | undefined, b: WeChatMediaMetadata | PlainMessage<WeChatMediaMetadata> | undefined): boolean {
    return proto3.util.equals(WeChatMediaMetadata, a, b);
  }
}

/**
 * @generated from message common.proto.WeChatMedia
 */
export class WeChatMedia extends Message<WeChatMedia> {
  /**
   * @generated from field: common.proto.WeChatMediaMetadata metadata = 1;
   */
  metadata?: WeChatMediaMetadata;

  /**
   * @generated from field: common.proto.GenerikMediaFacts facts = 2;
   */
  facts?: GenerikMediaFacts;

  constructor(data?: PartialMessage<WeChatMedia>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.WeChatMedia";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: WeChatMediaMetadata },
    { no: 2, name: "facts", kind: "message", T: GenerikMediaFacts },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): WeChatMedia {
    return new WeChatMedia().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): WeChatMedia {
    return new WeChatMedia().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): WeChatMedia {
    return new WeChatMedia().fromJsonString(jsonString, options);
  }

  static equals(a: WeChatMedia | PlainMessage<WeChatMedia> | undefined, b: WeChatMedia | PlainMessage<WeChatMedia> | undefined): boolean {
    return proto3.util.equals(WeChatMedia, a, b);
  }
}


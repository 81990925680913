// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/casting.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AddingCollaboratorCastingRequest, AddingInfluencersCastingRequest, AddNoteRequest, BulkVoteUserOnInfluencerRequest, Casting, CastingExportReq, CastingRequest, Castings, CastingVoteRequest, CollaboratorCastingInfluencerItem, CollaboratorCastingInfluencersRequest, CollaboratorCastingInfluencersResponse, CollaboratorCastingResponse, CollaboratorItems, ConvertCastingToCampaignRequest, ConvertCastingToCampaignResponse, DeleteCollaboratorCastingRequest, DeleteInfluencersCastingRequest, GenerateCastingPDFReq, GenerateCastingPPTReq, GetCollaboratorForecastingExpectedPostsRequest, GetCollaboratorForecastingExpectedPostsResponse, GetEstimatedAudienceReq, GetForecastingStatsAndPerformancesResp, GetInfluencersCastingRequest, InfluencerCastingItems, NoteCastingProto, ResendEmailToCollaboratorsRequest, UpdateAccessCollaboratorToCastingRequest, UpdateCastingMetricsRequest, UpdateCastingRequest, UpdateInfluencersExpectedPostsReq, UpdateNoteRequest, UploadInfluencersCastingRequest } from "./casting_pb";
import { Empty, Int64Value, MethodKind, StringValue } from "@bufbuild/protobuf";
import { PortfolioReqV2 } from "./common_pb";
import { PortfolioResp } from "./users_pb";
import { GenerikAudienceData } from "./audience_pb";

/**
 * @generated from service librarian.proto.LibrarianCastings
 */
export const LibrarianCastings = {
  typeName: "librarian.proto.LibrarianCastings",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.CreateCasting
     */
    createCasting: {
      name: "CreateCasting",
      I: CastingRequest,
      O: Int64Value,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.GetCastings
     */
    getCastings: {
      name: "GetCastings",
      I: Empty,
      O: Castings,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.GetCastingById
     */
    getCastingById: {
      name: "GetCastingById",
      I: Int64Value,
      O: Casting,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.UpdateCasting
     */
    updateCasting: {
      name: "UpdateCasting",
      I: UpdateCastingRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.UpdateCastingMetrics
     * @deprecated
     */
    updateCastingMetrics: {
      name: "UpdateCastingMetrics",
      I: UpdateCastingMetricsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.ArchiveCasting
     */
    archiveCasting: {
      name: "ArchiveCasting",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.UnarchiveCasting
     */
    unarchiveCasting: {
      name: "UnarchiveCasting",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.UploadInfluencersToCasting
     */
    uploadInfluencersToCasting: {
      name: "UploadInfluencersToCasting",
      I: UploadInfluencersCastingRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.AddCollaboratorToCasting
     */
    addCollaboratorToCasting: {
      name: "AddCollaboratorToCasting",
      I: AddingCollaboratorCastingRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.AddInfluencersToCasting
     */
    addInfluencersToCasting: {
      name: "AddInfluencersToCasting",
      I: AddingInfluencersCastingRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.GetAllCollaboratorInCasting
     */
    getAllCollaboratorInCasting: {
      name: "GetAllCollaboratorInCasting",
      I: Int64Value,
      O: CollaboratorItems,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.UpdateAccessCollaboratorToCasting
     */
    updateAccessCollaboratorToCasting: {
      name: "UpdateAccessCollaboratorToCasting",
      I: UpdateAccessCollaboratorToCastingRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.DeleteCollaboratorFromCasting
     */
    deleteCollaboratorFromCasting: {
      name: "DeleteCollaboratorFromCasting",
      I: DeleteCollaboratorCastingRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.ResendEmailToCollaborators
     */
    resendEmailToCollaborators: {
      name: "ResendEmailToCollaborators",
      I: ResendEmailToCollaboratorsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.GetInfluencersCasting
     */
    getInfluencersCasting: {
      name: "GetInfluencersCasting",
      I: GetInfluencersCastingRequest,
      O: InfluencerCastingItems,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.VoteUserOnInfluencerCasting
     */
    voteUserOnInfluencerCasting: {
      name: "VoteUserOnInfluencerCasting",
      I: CastingVoteRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.BulkVoteUserOnInfluencerCasting
     */
    bulkVoteUserOnInfluencerCasting: {
      name: "BulkVoteUserOnInfluencerCasting",
      I: BulkVoteUserOnInfluencerRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.DeleteInfluencerCasting
     */
    deleteInfluencerCasting: {
      name: "DeleteInfluencerCasting",
      I: DeleteInfluencersCastingRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.ConvertCastingToCampaign
     */
    convertCastingToCampaign: {
      name: "ConvertCastingToCampaign",
      I: ConvertCastingToCampaignRequest,
      O: ConvertCastingToCampaignResponse,
      kind: MethodKind.Unary,
    },
    /**
     * returns the bronco handle
     *
     * @generated from rpc librarian.proto.LibrarianCastings.RequestExportExcelTask
     */
    requestExportExcelTask: {
      name: "RequestExportExcelTask",
      I: CastingExportReq,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.AddNoteOnInfluencerCastingV2
     */
    addNoteOnInfluencerCastingV2: {
      name: "AddNoteOnInfluencerCastingV2",
      I: AddNoteRequest,
      O: NoteCastingProto,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.DeleteNoteOnInfluencerCasting
     */
    deleteNoteOnInfluencerCasting: {
      name: "DeleteNoteOnInfluencerCasting",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.UpdateNoteOnInfluencerCastingV2
     */
    updateNoteOnInfluencerCastingV2: {
      name: "UpdateNoteOnInfluencerCastingV2",
      I: UpdateNoteRequest,
      O: NoteCastingProto,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastings.GetLinkCollaboratorForUser
     */
    getLinkCollaboratorForUser: {
      name: "GetLinkCollaboratorForUser",
      I: Int64Value,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Trigger Casting PDF generation on Epson server
     * Response is a Bronco task handle
     * and download url in the task output
     *
     * @generated from rpc librarian.proto.LibrarianCastings.GenerateCastingPDF
     */
    generateCastingPDF: {
      name: "GenerateCastingPDF",
      I: GenerateCastingPDFReq,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Trigger Casting PPT generation on Epson server
     * Response is a Bronco task handle
     * and download url in the task output
     *
     * @generated from rpc librarian.proto.LibrarianCastings.GenerateCastingPPT
     */
    generateCastingPPT: {
      name: "GenerateCastingPPT",
      I: GenerateCastingPPTReq,
      O: StringValue,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * @generated from service librarian.proto.LibrarianCastingsCollaborators
 */
export const LibrarianCastingsCollaborators = {
  typeName: "librarian.proto.LibrarianCastingsCollaborators",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianCastingsCollaborators.GetCollaboratorCastingData
     */
    getCollaboratorCastingData: {
      name: "GetCollaboratorCastingData",
      I: Empty,
      O: CollaboratorCastingResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastingsCollaborators.GetInfluencersForCollaboratorInCasting
     */
    getInfluencersForCollaboratorInCasting: {
      name: "GetInfluencersForCollaboratorInCasting",
      I: CollaboratorCastingInfluencersRequest,
      O: CollaboratorCastingInfluencersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * request is an influencerCastingId
     *
     * @generated from rpc librarian.proto.LibrarianCastingsCollaborators.GetInfluencerCastingById
     */
    getInfluencerCastingById: {
      name: "GetInfluencerCastingById",
      I: Int64Value,
      O: CollaboratorCastingInfluencerItem,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastingsCollaborators.GetGenerikPortfolioForCollaboratorAPI
     */
    getGenerikPortfolioForCollaboratorAPI: {
      name: "GetGenerikPortfolioForCollaboratorAPI",
      I: PortfolioReqV2,
      O: PortfolioResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastingsCollaborators.VoteCollaboratorOnInfluencer
     */
    voteCollaboratorOnInfluencer: {
      name: "VoteCollaboratorOnInfluencer",
      I: CastingVoteRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * collaborator comments
     *
     * @generated from rpc librarian.proto.LibrarianCastingsCollaborators.AddNote
     */
    addNote: {
      name: "AddNote",
      I: AddNoteRequest,
      O: NoteCastingProto,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastingsCollaborators.UpdateNote
     */
    updateNote: {
      name: "UpdateNote",
      I: UpdateNoteRequest,
      O: NoteCastingProto,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastingsCollaborators.DeleteNote
     */
    deleteNote: {
      name: "DeleteNote",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastingsCollaborators.GetForecastingStatsAndPerformances
     */
    getForecastingStatsAndPerformances: {
      name: "GetForecastingStatsAndPerformances",
      I: Empty,
      O: GetForecastingStatsAndPerformancesResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastingsCollaborators.GetEstimatedAudience
     */
    getEstimatedAudience: {
      name: "GetEstimatedAudience",
      I: GetEstimatedAudienceReq,
      O: GenerikAudienceData,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCastingsCollaborators.GetCollaboratorForecastingExpectedPosts
     */
    getCollaboratorForecastingExpectedPosts: {
      name: "GetCollaboratorForecastingExpectedPosts",
      I: GetCollaboratorForecastingExpectedPostsRequest,
      O: GetCollaboratorForecastingExpectedPostsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * @generated from service librarian.proto.LibrarianForecastings
 */
export const LibrarianForecastings = {
  typeName: "librarian.proto.LibrarianForecastings",
  methods: {
    /**
     * Influencers with expected posts with be retrieved first
     *
     * @generated from rpc librarian.proto.LibrarianForecastings.GetInfluencersCasting
     */
    getInfluencersCasting: {
      name: "GetInfluencersCasting",
      I: GetInfluencersCastingRequest,
      O: InfluencerCastingItems,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianForecastings.UpdateInfluencersExpectedPosts
     */
    updateInfluencersExpectedPosts: {
      name: "UpdateInfluencersExpectedPosts",
      I: UpdateInfluencersExpectedPostsReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianForecastings.GetForecastingStatsAndPerformances
     */
    getForecastingStatsAndPerformances: {
      name: "GetForecastingStatsAndPerformances",
      I: Int64Value,
      O: GetForecastingStatsAndPerformancesResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianForecastings.GetEstimatedAudience
     */
    getEstimatedAudience: {
      name: "GetEstimatedAudience",
      I: GetEstimatedAudienceReq,
      O: GenerikAudienceData,
      kind: MethodKind.Unary,
    },
    /**
     * returns the bronco handle
     *
     * @generated from rpc librarian.proto.LibrarianForecastings.RequestForecastingExportTask
     */
    requestForecastingExportTask: {
      name: "RequestForecastingExportTask",
      I: Int64Value,
      O: StringValue,
      kind: MethodKind.Unary,
    },
  }
} as const;


/* eslint-disable @angular-eslint/no-output-native */
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  InjectionToken,
  Input,
  NgZone,
  Output,
  ViewChild,
  effect,
  inject,
  input,
  model,
  signal,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NgControl,
} from '@angular/forms';
import { Messages, isNotEmptyString, isNotNil } from '@frontend2/core';
import { noop } from 'rxjs';
import { Focusable } from './focus.directive';
import { ButtonSize } from './lefty-button-directive/lefty-button.directive';
import { LeftyComponent } from './utils';

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class LeftyFormBase extends LeftyComponent implements DoCheck {
  constructor(readonly ngControl?: NgControl | null) {
    super();
  }

  ngDoCheck(): void {
    if (isNotNil(this.ngControl) && this.showErrorMessage) {
      this.changeDetection.markForCheck();
    }
  }

  @Input() label = '';

  @Input() large = false;
  @Input() required = false;
  @Input() optional = false;
  @Input() topHintText = false;
  @Input() tooltip = false;

  @Input() helpText = '';
  @Input() helpLink = '';
  @Input() externalHelpLink = '';
  @Input() hintText = '';

  @HostBinding('class.disabled')
  @Input()
  disabled = false;

  get ngInvalid(): boolean {
    return this.ngControl?.invalid === true;
  }

  get ngDirty(): boolean {
    return this.ngControl?.dirty === true;
  }

  get ngTouched(): boolean {
    return this.ngControl?.touched === true;
  }

  private _errorMessage = '';

  @Input()
  set errorMessage(val: string) {
    this._errorMessage = val;
  }

  get errorMessage(): string {
    if (isNotNil(this.ngControl) && this.invalid) {
      return buildFormErrorMessage(this.ngControl) ?? this._errorMessage;
    }
    return this._errorMessage;
  }

  get hasHintText(): boolean {
    return isNotEmptyString(this.hintText);
  }

  get invalid(): boolean {
    if (isNotNil(this.ngControl)) {
      return shouldShowInvalidStyle(this.ngControl);
    }

    return false;
  }

  get showErrorMessage(): boolean {
    if (isNotNil(this.ngControl)) {
      return this.invalid && isNotEmptyString(this.errorMessage);
    }
    return isNotEmptyString(this.errorMessage);
  }

  get showBottomHint(): boolean {
    return (
      this.showErrorMessage === false &&
      this.large === false &&
      this.topHintText === false &&
      this.hasHintText
    );
  }

  get showTopHint(): boolean {
    return (this.large || this.topHintText) && this.hasHintText;
  }

  get showLabelContainer(): boolean {
    return (
      isNotEmptyString(this.label) || this.showTooltip || this.showHelpLink
    );
  }

  get showHelpLink(): boolean {
    return (
      isNotEmptyString(this.helpLink) || isNotEmptyString(this.externalHelpLink)
    );
  }

  get showTooltip(): boolean {
    return (
      this.tooltip && isNotEmptyString(this.helpText) && !this.showHelpLink
    );
  }
}

export const FORM_DEFAULT_VALUE = new InjectionToken(' FORM_DEFAULT_VALUE');

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class LeftyFormValueBase<Value>
  extends LeftyFormBase
  implements ControlValueAccessor
{
  constructor(
    @Inject(FORM_DEFAULT_VALUE) readonly defaultValue: Value,
    ngControl?: NgControl | null,
  ) {
    super(ngControl);

    this.disposer.addFunction(() => this.focus$.complete());
    this.disposer.addFunction(() => this.blur$.complete());
    this.disposer.addFunction(() => this.valueChange.complete());
    this._value = defaultValue;

    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  @HostBinding('class.focus')
  focused = false;

  /// Event when the element is focused.
  @Output()
  readonly focus$ = new EventEmitter<FocusEvent>();

  @Output()
  readonly blur$ = new EventEmitter<FocusEvent>();

  private _value: Value;

  public get value(): Value {
    return this._value;
  }

  @Input()
  public set value(value: Value) {
    this._value = value;
    this.changeDetection.markForCheck();
  }

  @Output()
  readonly valueChange = new EventEmitter<Value>();

  handleValueChange(val: Value): void {
    if (this.value === val) {
      return;
    }

    this.valueChange.emit(val);
    this.setState(() => (this.value = val));
  }

  handleFocus(event?: FocusEvent): void {
    this.setState(() => (this.focused = true));
    this.focus$.emit(event);
  }

  handleBlur(event?: FocusEvent): void {
    this.setState(() => (this.focused = false));
    this.blur$.emit(event);
  }

  abstract writeValue(obj: unknown): void;

  registerOnChange(fn: (val: unknown) => void): void {
    this.disposer.addStreamSubscription(this.valueChange.subscribe(fn));
  }

  registerOnTouched(fn: () => void): void {
    this.disposer.addStreamSubscription(this.blur$.subscribe(() => fn()));
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}

export type InputSize = 'medium' | 'small';

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class NativeInputWrapper<Value>
  extends LeftyFormValueBase<Value>
  implements Focusable, AfterViewChecked
{
  readonly zone = inject(NgZone);

  constructor(
    public elementRef: ElementRef,
    @Inject(FORM_DEFAULT_VALUE) defaultValue: Value,
    ngControl?: NgControl,
  ) {
    super(defaultValue, ngControl);
    this.elementRef = elementRef;

    this.disposer.addFunction(() => this.keydown$.complete());
    this.disposer.addFunction(() => this.keyup$.complete());
    this.disposer.addFunction(() => this.keypress$.complete());
  }

  @Input() type = '';
  @Input() leadingGlyph = '';
  @Input() trailingGlyph = '';
  @Input() prefix = '';
  @Input() suffix = '';
  @Input() tabIndex?: number;
  @Input() placeholder?: string;
  @Input() autocomplete?: string;
  @Input() name?: string;
  @Input() maxLength = -1;

  @Input()
  size: InputSize = 'medium';

  @Input()
  rounded = false;

  @Input()
  @HostBinding('class.minified')
  minified = false;

  @HostBinding('class')
  get inputClass(): string {
    return `${this.size} ${this.rounded ? 'rounded' : ''}`;
  }

  @Output()
  readonly keypress$ = new EventEmitter<KeyboardEvent>();

  @Output()
  readonly keyup$ = new EventEmitter<KeyboardEvent>();

  @Output()
  readonly keydown$ = new EventEmitter<KeyboardEvent>();

  @ViewChild('input')
  inputElementRef?: ElementRef;

  get inputElement(): HTMLInputElement | undefined {
    return this.inputElementRef?.nativeElement;
  }

  get nativeElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  get buttonSize(): ButtonSize {
    return this.size;
  }

  get isSmall(): boolean {
    return this.nativeElement.classList.contains('small');
  }

  // setting a manual focus without event, (used for dynamic filters)
  focus(): void {
    if (this.inputElement) {
      this.inputElement.focus();
      this.changeDetection.markForCheck();
    }
  }

  select(): void {
    if (this.inputElement) {
      this.inputElement.select();
    }
  }

  get textValue(): string {
    return this.inputElement?.value ?? '';
  }

  @HostBinding('class.has-leading-glyph')
  get hasLeadingGlyph(): boolean {
    return isNotEmptyString(this.leadingGlyph);
  }

  @HostBinding('class.has-trailing-glyph')
  get hasTrailingGlyph(): boolean {
    return isNotEmptyString(this.trailingGlyph);
  }

  @HostBinding('class.has-prefix')
  get hasPrefix(): boolean {
    return isNotEmptyString(this.prefix);
  }

  @HostBinding('class.has-suffix')
  get hasSuffix(): boolean {
    return isNotEmptyString(this.suffix);
  }

  override set errorMessage(val: string) {
    super.errorMessage = val;
  }

  override get errorMessage(): string {
    if (this.ngControl && this.invalid) {
      const message =
        buildFormErrorMessage(this.ngControl) ?? super.errorMessage;
      if (isNotEmptyString(message)) {
        return message;
      }

      return this.inputElement?.validationMessage ?? '';
    }

    return super.errorMessage;
  }

  @ViewChild('leading')
  leadingEl?: ElementRef<Element>;

  private _inputPaddingLeft = 0;

  private _computeInputPaddingLeft(leadingWidth: number): void {
    if (!this.inputElement) {
      return;
    }

    this._inputPaddingLeft = leadingWidth;
    if (this._inputPaddingLeft === 0) {
      this.inputElement.style.paddingLeft = '';
    } else {
      this.inputElement.style.paddingLeft = `${this._inputPaddingLeft}px`;
    }
  }

  @ViewChild('trailing')
  trailingEl?: ElementRef<Element>;

  private _inputPaddingRight = 0;

  private _computeInputPaddingRight(trailingWidth: number): void {
    if (!this.inputElement) {
      return;
    }

    this._inputPaddingRight = trailingWidth;

    if (this._inputPaddingRight === 0) {
      this.inputElement.style.paddingRight = '';
    } else {
      this.inputElement.style.paddingRight = `${this._inputPaddingRight}px`;
    }
  }

  ngAfterViewChecked(): void {
    // we must avoid high frequency usage of clientWidth
    // it force browser to recalculate the style and layout
    //
    // Howerver to access clientWidth, the element must be visible, or clientWidth will be 0
    // that's why check during ngAfterViewChecked
    this._computeInputPaddings();
  }

  private _computeInputPaddings(): void {
    this.zone.runOutsideAngular(() => {
      // outside of angular zone
      // we compute input padding,
      // only if padding is not already set to element width

      const leadingElementWidth =
        this.leadingEl?.nativeElement.clientWidth ?? 0;

      if (this._inputPaddingLeft !== leadingElementWidth) {
        this._computeInputPaddingLeft(leadingElementWidth);
      }

      const trailingElementWidth =
        this.trailingEl?.nativeElement.clientWidth ?? 0;
      if (this._inputPaddingRight !== trailingElementWidth) {
        this._computeInputPaddingRight(trailingElementWidth);
      }
    });
  }
}

export const buildFormErrorMessage = (
  control: NgControl,
): string | undefined => {
  if (control.hasError('required')) {
    return Messages.requiredError;
  }
  if (control.hasError('minlength')) {
    return Messages.minLengthError(
      control.getError('minlength').requiredLength,
    );
  }
  if (control.hasError('maxlength')) {
    return Messages.maxLengthError(
      control.getError('maxlength').requiredLength,
    );
  }
  if (control.hasError('invalid')) {
    return control.getError('invalid');
  }
  return;
};

export function shouldShowInvalidStyle(
  control: AbstractControl | NgControl,
): boolean {
  let ctrl: AbstractControl | NgControl | null = control;

  if (ctrl instanceof NgControl) {
    ctrl = ctrl.control;
  }

  if (isNotNil(ctrl)) {
    return ctrl.invalid && (ctrl.touched || ctrl.dirty);
  }
  return false;
}

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class LeftyControlValueAccessor<Value>
  implements ControlValueAccessor
{
  readonly label = input('');
  readonly placeholder = input('');
  readonly large = input(false);
  readonly required = input(false);
  readonly optional = input(false);
  readonly topHintText = input(false);
  readonly tooltip = input(false);

  readonly helpText = input('');
  readonly helpLink = input('');
  readonly externalHelpLink = input('');
  readonly hintText = input('');

  readonly disabled = input(false);

  private _onChange: (_: Value | undefined) => void = noop;

  readonly ngControl = inject(NgControl, { self: true, optional: true });
  readonly cdRef = inject(ChangeDetectorRef);

  constructor() {
    if (isNotNil(this.ngControl)) {
      this.ngControl.valueAccessor = this;
    }

    const host = inject(ElementRef<HTMLElement>).nativeElement as HTMLElement;
    effect(() => host.classList.toggle('disabled', this.disabled()));
  }

  registerOnChange(fn: (_: Value | undefined) => void): void {
    this._onChange = fn;
  }

  onTouched: () => void = noop;

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  get errorMessage(): string {
    if (isNotNil(this.ngControl) && this.showInvalidStyle) {
      return buildFormErrorMessage(this.ngControl) ?? '';
    }
    return '';
  }

  get showInvalidStyle(): boolean {
    if (isNotNil(this.ngControl)) {
      return shouldShowInvalidStyle(this.ngControl);
    }
    return false;
  }

  readonly $disabled = signal(false);

  setDisabledState(isDisabled: boolean): void {
    this.$disabled.set(isDisabled);
  }

  readonly value = model<Value | undefined>(undefined);

  setValueAndNotify(val: Value | undefined): void {
    this.value.set(val);
    this._onChange(val);
  }

  abstract isValidType(obj: unknown): obj is Value;

  writeValue(obj: unknown): void {
    if (this.isValidType(obj)) {
      this.value.set(obj);
    }
  }
}

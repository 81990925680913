// This file defines protobufs commonly used in any universal project.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file universal/common/proto/universal.proto (package universal.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, StringValue } from "@bufbuild/protobuf";
import { InstagramUser, UserScrappedInfo } from "../../../common/proto/user_pb";
import { PrivateInfo } from "../../../common/proto/common_pb";
import { ImageMetadata } from "../../../common/proto/instagram_media_pb";

/**
 * Nature of the request. They are in the order of importance.
 *
 * For some processes (for instance post polling) we have completely different processes and keys
 * for a given nature, so they are completely isolated from one another.
 *
 * For other processes that are stable (for instance ingestion) we just use priorties.
 *
 * @generated from enum universal.proto.PollNature
 */
export enum PollNature {
  /**
   * @generated from enum value: POLL_NATURE_UNKNOWN = 0;
   */
  POLL_NATURE_UNKNOWN = 0,

  /**
   * This request is in response to a customer clicking some refresh in the APP.
   * Such requests should be prioritized and/or have dedicated keys.
   *
   * @generated from enum value: CUSTOMER_INTERACTIVE = 1;
   */
  CUSTOMER_INTERACTIVE = 1,

  /**
   * Campaign or CW posts refreshing.
   *
   * @generated from enum value: CAMPAIGN_POLLERS = 2;
   */
  CAMPAIGN_POLLERS = 2,

  /**
   * Request in order to enrich or refresh the kernel of interest (creators that are )
   *
   * @generated from enum value: DISCOVERY_POOL = 3;
   */
  DISCOVERY_POOL = 3,

  /**
   * The request is for processing a follower.
   *
   * @generated from enum value: FOR_AUDIENCE = 4;
   */
  FOR_AUDIENCE = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(PollNature)
proto3.util.setEnumType(PollNature, "universal.proto.PollNature", [
  { no: 0, name: "POLL_NATURE_UNKNOWN" },
  { no: 1, name: "CUSTOMER_INTERACTIVE" },
  { no: 2, name: "CAMPAIGN_POLLERS" },
  { no: 3, name: "DISCOVERY_POOL" },
  { no: 4, name: "FOR_AUDIENCE" },
]);

/**
 * @generated from message universal.proto.UniversalRespHeader
 */
export class UniversalRespHeader extends Message<UniversalRespHeader> {
  /**
   * @generated from field: bool up_to_date = 1;
   */
  upToDate = false;

  /**
   * @generated from field: string refresh_token = 2;
   */
  refreshToken = "";

  constructor(data?: PartialMessage<UniversalRespHeader>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.proto.UniversalRespHeader";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "up_to_date", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "refresh_token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UniversalRespHeader {
    return new UniversalRespHeader().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UniversalRespHeader {
    return new UniversalRespHeader().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UniversalRespHeader {
    return new UniversalRespHeader().fromJsonString(jsonString, options);
  }

  static equals(a: UniversalRespHeader | PlainMessage<UniversalRespHeader> | undefined, b: UniversalRespHeader | PlainMessage<UniversalRespHeader> | undefined): boolean {
    return proto3.util.equals(UniversalRespHeader, a, b);
  }
}

/**
 * For the various polls that can happen (especially when passing the poll to a sidecar), poll with the given proxy.
 *
 * @generated from message universal.proto.ProxyInfo
 */
export class ProxyInfo extends Message<ProxyInfo> {
  /**
   * @generated from field: string ip = 1;
   */
  ip = "";

  /**
   * @generated from field: int32 port = 2;
   */
  port = 0;

  /**
   * If empty, the proxy is without authentication (it might be protected by a whitelist of IP that can access it).
   *
   * @generated from field: string username = 3;
   */
  username = "";

  /**
   * @generated from field: string password = 4;
   */
  password = "";

  /**
   * If set, this tag shall be used to tag user metrics, such as error rate of the proxy and timers.
   * It will typically be either the proxy provider name or name + product line.
   *
   * @generated from field: google.protobuf.StringValue log_tag = 5;
   */
  logTag?: string;

  /**
   * If the proxy is of type scrapper bot, requests have to be made slightly differently.
   *
   * @generated from field: bool is_scrapper_bot = 6;
   */
  isScrapperBot = false;

  constructor(data?: PartialMessage<ProxyInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.proto.ProxyInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ip", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "port", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "log_tag", kind: "message", T: StringValue },
    { no: 6, name: "is_scrapper_bot", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ProxyInfo {
    return new ProxyInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ProxyInfo {
    return new ProxyInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ProxyInfo {
    return new ProxyInfo().fromJsonString(jsonString, options);
  }

  static equals(a: ProxyInfo | PlainMessage<ProxyInfo> | undefined, b: ProxyInfo | PlainMessage<ProxyInfo> | undefined): boolean {
    return proto3.util.equals(ProxyInfo, a, b);
  }
}

/**
 * @generated from message universal.proto.PolledInstaCreator
 */
export class PolledInstaCreator extends Message<PolledInstaCreator> {
  /**
   * @generated from field: common.proto.InstagramUser user = 1;
   */
  user?: InstagramUser;

  /**
   * @generated from field: common.proto.PrivateInfo private_info = 2;
   */
  privateInfo?: PrivateInfo;

  /**
   * @generated from field: common.proto.UserScrappedInfo scrapped_info = 3;
   */
  scrappedInfo?: UserScrappedInfo;

  /**
   * Free media
   *
   * @generated from field: repeated common.proto.ImageMetadata images = 4;
   */
  images: ImageMetadata[] = [];

  /**
   * Token to use on a subsequent posts graphQL URL
   *
   * @generated from field: google.protobuf.StringValue next_token = 5;
   */
  nextToken?: string;

  constructor(data?: PartialMessage<PolledInstaCreator>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.proto.PolledInstaCreator";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: InstagramUser },
    { no: 2, name: "private_info", kind: "message", T: PrivateInfo },
    { no: 3, name: "scrapped_info", kind: "message", T: UserScrappedInfo },
    { no: 4, name: "images", kind: "message", T: ImageMetadata, repeated: true },
    { no: 5, name: "next_token", kind: "message", T: StringValue },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PolledInstaCreator {
    return new PolledInstaCreator().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PolledInstaCreator {
    return new PolledInstaCreator().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PolledInstaCreator {
    return new PolledInstaCreator().fromJsonString(jsonString, options);
  }

  static equals(a: PolledInstaCreator | PlainMessage<PolledInstaCreator> | undefined, b: PolledInstaCreator | PlainMessage<PolledInstaCreator> | undefined): boolean {
    return proto3.util.equals(PolledInstaCreator, a, b);
  }
}


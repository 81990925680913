import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianAffiliationPromoCodes } from '@frontend2/proto/librarian/proto/affiliation_promo_codes_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianAffiliationPromoCodesClient = PromiseClient<
  typeof LibrarianAffiliationPromoCodes
>;

const factory = (): LibrarianAffiliationPromoCodesClient => {
  return createPromiseClient(
    LibrarianAffiliationPromoCodes,
    inject(GRPC_TRANSPORT),
  );
};

const token = new InjectionToken('LibrarianAffiliationPromoCodesClient', {
  factory,
});

export function injectLibrarianAffiliationPromoCodesClient(): LibrarianAffiliationPromoCodesClient {
  return inject(token);
}

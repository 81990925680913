import { Inject, Injectable } from '@angular/core';
import {
  Interceptor,
  StreamResponse,
  UnaryResponse,
} from '@connectrpc/connect';
import { isNil } from '@frontend2/core';
import { GRPC_INTERCEPTORS } from '../grpc.service';

export const DEFAULT_HINT = 0;

export const SPACE_HINT_KEY = 'hint';
export const SPACE_PATH = 's';

export function parseWorkspaceHint(str: string): number {
  const hint = parseInt(str);

  if (isNil(hint) || isNaN(hint)) {
    return DEFAULT_HINT;
  }

  return hint;
}

@Injectable({ providedIn: 'root' })
export class WorkspaceAuth {
  static readonly HEADER = 'lefty-space-hint';

  public hint = 0;

  constructor(@Inject(GRPC_INTERCEPTORS) interceptors: Interceptor[]) {
    interceptors.push(this.interceptor);
  }

  private readonly interceptor: Interceptor = (next) => {
    return async (req): Promise<UnaryResponse | StreamResponse> => {
      req.header.set(WorkspaceAuth.HEADER, this.hint.toString());

      return next(req);
    };
  };
}

import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { LeftyValidators } from '../form-validators.helpers';

@Directive({
  selector: `
     lefty-date-range-input[required],
     lefty-date-range-picker[required]
     `,
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: LeftyDateRangeValidatorDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class LeftyDateRangeValidatorDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return LeftyValidators.requiredTimeRestriction(control);
  }
}

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  capitalize,
  formatDateAgo,
  generateRgbFromString,
  isBlankString,
} from '@frontend2/core';
import { NoteCastingProto } from '@frontend2/proto/librarian/proto/casting_pb';
import { LeftyColors } from '@frontend2/core';
import { Timestamp } from '@bufbuild/protobuf';
import { LeftyComponent } from '../../utils';
import { LeftySpinnerComponent } from '../../loading.component';
import { LeftyFormTextareaComponent } from '../../lefty-form-textarea/lefty-form-textarea.component';
import { LeftyIconComponent } from '../../icon/icon.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'casting-profile-comment-card',
  templateUrl: './comment-card.component.html',
  styleUrls: ['./comment-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    LeftyIconComponent,
    LeftyFormTextareaComponent,
    LeftySpinnerComponent,
  ],
})
export class CastingProfileCommentCardComponent extends LeftyComponent {
  constructor() {
    super();

    this.disposer.add(this.addComment$);
    this.disposer.add(this.updateComment$);
    this.disposer.add(this.deleteComment$);
  }

  private _comment = new NoteCastingProto();

  get comment(): NoteCastingProto {
    return this._comment;
  }

  @Input()
  set comment(value: NoteCastingProto) {
    this._comment = value;
    this.editingText = value.noteContent;

    // if comment does not exist (when user adding a comment)
    // we force editing to true
    this.editing = value.noteId === 0n;
  }

  @Input()
  mobile = false;

  @Input()
  loading = false;

  @Output()
  readonly addComment$ = new EventEmitter<string>();

  @Output()
  readonly updateComment$ = new EventEmitter<NoteCastingProto>();

  @Output()
  readonly deleteComment$ = new EventEmitter<bigint>();

  editing = false;
  editingText = '';

  startEdit(): void {
    this.setState(() => {
      this.editing = true;
    });
  }

  stopEdit(): void {
    this.setState(() => {
      this.editing = false;
      this.editingText = this.comment.noteContent;
    });

    // if comment does not exist, user was trying to add a comment
    // but user cancel, we force deletinon
    if (this.comment.noteId === BigInt(0)) {
      this.deleteComment$.emit(this.comment.noteId);
    }
  }

  submit(): void {
    if (!this.editing) {
      return;
    }

    const id = this.comment.noteId;

    this.editingText = this.editingText.trim();
    if (isBlankString(this.editingText)) {
      this.deleteComment$.emit(id);
      return;
    }

    if (id !== BigInt(0)) {
      this.updateComment$.emit(
        new NoteCastingProto({
          noteId: id,
          noteContent: this.editingText,
          updated: Timestamp.fromDate(new Date()),
        }),
      );
    } else {
      this.addComment$.emit(this.editingText);
    }
  }

  get formattedDate(): string {
    const date = this.comment.updated?.toDate();
    if (!date) {
      return '';
    }
    return formatDateAgo(date);
  }

  get email(): string {
    if (this.comment.emailAuthor.case === 'emailCollaborator') {
      return this.comment.emailAuthor.value;
    }
    return '';
  }

  get initial(): string {
    return capitalize(this.email.substring(0, 1));
  }

  get initialColor(): string {
    return generateRgbFromString(this.initial, LeftyColors.gradient500);
  }

  get initialBackgroundColor(): string {
    return generateRgbFromString(this.initial, LeftyColors.gradient100);
  }

  get isUser(): boolean {
    return this.comment.fromWorkspaceUser;
  }
}

// This file defines protocol buffers for the Douyin stuff that gets persisted.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/douyin.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { ComputedGenerikStats, PostType, StringList } from "./common_pb";
import { PostInteractions } from "./campaign_pb";
import { GenerikMediaFacts } from "./media_pb";

/**
 * @generated from enum common.proto.DouyinTextExtraType
 */
export enum DouyinTextExtraType {
  /**
   * @generated from enum value: DOUYIN_TEXT_EXTRA_MENTION = 0;
   */
  DOUYIN_TEXT_EXTRA_MENTION = 0,

  /**
   * @generated from enum value: DOUYIN_TEXT_EXTRA_HASHTAG = 1;
   */
  DOUYIN_TEXT_EXTRA_HASHTAG = 1,
}
// Retrieve enum metadata with: proto3.getEnumType(DouyinTextExtraType)
proto3.util.setEnumType(DouyinTextExtraType, "common.proto.DouyinTextExtraType", [
  { no: 0, name: "DOUYIN_TEXT_EXTRA_MENTION" },
  { no: 1, name: "DOUYIN_TEXT_EXTRA_HASHTAG" },
]);

/**
 * @generated from message common.proto.DouyinCreator
 */
export class DouyinCreator extends Message<DouyinCreator> {
  /**
   * uid
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string nickname = 2;
   */
  nickname = "";

  /**
   * @generated from field: string sec_uid = 3;
   */
  secUid = "";

  /**
   * @generated from field: string short_id = 4;
   */
  shortId = "";

  /**
   * can be changed
   *
   * @generated from field: string unique_id = 5;
   */
  uniqueId = "";

  /**
   * @generated from field: string introduction = 6;
   */
  introduction = "";

  /**
   * Note: we used to have different versions. We now have only one.
   *
   * @generated from field: repeated string avatars = 7;
   */
  avatars: string[] = [];

  /**
   * Not in new API
   *
   * @generated from field: string birthday = 8;
   */
  birthday = "";

  /**
   * Not in new API
   *
   * @generated from field: int32 gender = 9;
   */
  gender = 0;

  /**
   * Not in new API
   *
   * @generated from field: string language = 10;
   */
  language = "";

  /**
   * Not in new API
   *
   * @generated from field: string region = 11;
   */
  region = "";

  /**
   * Not in new API
   *
   * @generated from field: string city = 12;
   */
  city = "";

  /**
   * Not in new API
   *
   * @generated from field: string district = 13;
   */
  district = "";

  /**
   * Not in new API
   *
   * @generated from field: string province = 14;
   */
  province = "";

  /**
   * @generated from field: bool is_ban = 15;
   */
  isBan = false;

  /**
   * @generated from field: int32 secret = 16;
   */
  secret = 0;

  /**
   * Not in new API
   *
   * @generated from field: common.proto.DouyinLinkedAccounts linked_accounts = 50;
   */
  linkedAccounts?: DouyinLinkedAccounts;

  /**
   * @generated from field: common.proto.DouyinCreatorCounts counts = 51;
   */
  counts?: DouyinCreatorCounts;

  /**
   * @generated from field: common.proto.ComputedGenerikStats computed_stats = 52;
   */
  computedStats?: ComputedGenerikStats;

  constructor(data?: PartialMessage<DouyinCreator>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DouyinCreator";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "nickname", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "sec_uid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "short_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "unique_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "introduction", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "avatars", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 8, name: "birthday", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "gender", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "district", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "province", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "is_ban", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "secret", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 50, name: "linked_accounts", kind: "message", T: DouyinLinkedAccounts },
    { no: 51, name: "counts", kind: "message", T: DouyinCreatorCounts },
    { no: 52, name: "computed_stats", kind: "message", T: ComputedGenerikStats },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DouyinCreator {
    return new DouyinCreator().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DouyinCreator {
    return new DouyinCreator().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DouyinCreator {
    return new DouyinCreator().fromJsonString(jsonString, options);
  }

  static equals(a: DouyinCreator | PlainMessage<DouyinCreator> | undefined, b: DouyinCreator | PlainMessage<DouyinCreator> | undefined): boolean {
    return proto3.util.equals(DouyinCreator, a, b);
  }
}

/**
 * @generated from message common.proto.DouyinLinkedAccounts
 */
export class DouyinLinkedAccounts extends Message<DouyinLinkedAccounts> {
  /**
   * @generated from field: string weibo_url = 1;
   */
  weiboUrl = "";

  /**
   * @generated from field: string weibo_name = 2;
   */
  weiboName = "";

  /**
   * @generated from field: string twitter_id = 3;
   */
  twitterId = "";

  /**
   * @generated from field: string twitter_name = 4;
   */
  twitterName = "";

  /**
   * @generated from field: string youtube_channel_id = 5;
   */
  youtubeChannelId = "";

  /**
   * @generated from field: string google_account = 6;
   */
  googleAccount = "";

  constructor(data?: PartialMessage<DouyinLinkedAccounts>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DouyinLinkedAccounts";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "weibo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "weibo_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "twitter_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "twitter_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "youtube_channel_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "google_account", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DouyinLinkedAccounts {
    return new DouyinLinkedAccounts().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DouyinLinkedAccounts {
    return new DouyinLinkedAccounts().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DouyinLinkedAccounts {
    return new DouyinLinkedAccounts().fromJsonString(jsonString, options);
  }

  static equals(a: DouyinLinkedAccounts | PlainMessage<DouyinLinkedAccounts> | undefined, b: DouyinLinkedAccounts | PlainMessage<DouyinLinkedAccounts> | undefined): boolean {
    return proto3.util.equals(DouyinLinkedAccounts, a, b);
  }
}

/**
 * @generated from message common.proto.DouyinCreatorCounts
 */
export class DouyinCreatorCounts extends Message<DouyinCreatorCounts> {
  /**
   * @generated from field: int64 following_count = 1;
   */
  followingCount = protoInt64.zero;

  /**
   * int64: there are a lots of people in China :)
   *
   * @generated from field: int64 follower_count = 2;
   */
  followerCount = protoInt64.zero;

  /**
   * @generated from field: int64 aweme_count = 3;
   */
  awemeCount = protoInt64.zero;

  /**
   * @generated from field: int32 story_count = 4;
   */
  storyCount = 0;

  /**
   * @generated from field: int32 total_favorited = 5;
   */
  totalFavorited = 0;

  constructor(data?: PartialMessage<DouyinCreatorCounts>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DouyinCreatorCounts";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "following_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "follower_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "aweme_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "story_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "total_favorited", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DouyinCreatorCounts {
    return new DouyinCreatorCounts().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DouyinCreatorCounts {
    return new DouyinCreatorCounts().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DouyinCreatorCounts {
    return new DouyinCreatorCounts().fromJsonString(jsonString, options);
  }

  static equals(a: DouyinCreatorCounts | PlainMessage<DouyinCreatorCounts> | undefined, b: DouyinCreatorCounts | PlainMessage<DouyinCreatorCounts> | undefined): boolean {
    return proto3.util.equals(DouyinCreatorCounts, a, b);
  }
}

/**
 * @generated from message common.proto.DouyinMediaHastag
 */
export class DouyinMediaHastag extends Message<DouyinMediaHastag> {
  /**
   * @generated from field: string hashtag_id = 1;
   */
  hashtagId = "";

  /**
   * @generated from field: string hashtag_name = 2;
   */
  hashtagName = "";

  /**
   * @generated from field: bool is_commerce = 3;
   */
  isCommerce = false;

  constructor(data?: PartialMessage<DouyinMediaHastag>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DouyinMediaHastag";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hashtag_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "hashtag_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "is_commerce", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DouyinMediaHastag {
    return new DouyinMediaHastag().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DouyinMediaHastag {
    return new DouyinMediaHastag().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DouyinMediaHastag {
    return new DouyinMediaHastag().fromJsonString(jsonString, options);
  }

  static equals(a: DouyinMediaHastag | PlainMessage<DouyinMediaHastag> | undefined, b: DouyinMediaHastag | PlainMessage<DouyinMediaHastag> | undefined): boolean {
    return proto3.util.equals(DouyinMediaHastag, a, b);
  }
}

/**
 * Note, only ids, no text
 *
 * @generated from message common.proto.DouyinMediaMention
 */
export class DouyinMediaMention extends Message<DouyinMediaMention> {
  /**
   * @generated from field: string user_id = 1;
   */
  userId = "";

  /**
   * @generated from field: string sec_uid = 2;
   */
  secUid = "";

  constructor(data?: PartialMessage<DouyinMediaMention>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DouyinMediaMention";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "sec_uid", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DouyinMediaMention {
    return new DouyinMediaMention().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DouyinMediaMention {
    return new DouyinMediaMention().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DouyinMediaMention {
    return new DouyinMediaMention().fromJsonString(jsonString, options);
  }

  static equals(a: DouyinMediaMention | PlainMessage<DouyinMediaMention> | undefined, b: DouyinMediaMention | PlainMessage<DouyinMediaMention> | undefined): boolean {
    return proto3.util.equals(DouyinMediaMention, a, b);
  }
}

/**
 * @generated from message common.proto.DouyinTextExtra
 */
export class DouyinTextExtra extends Message<DouyinTextExtra> {
  /**
   * @generated from field: common.proto.DouyinTextExtraType type = 1;
   */
  type = DouyinTextExtraType.DOUYIN_TEXT_EXTRA_MENTION;

  /**
   * @generated from oneof common.proto.DouyinTextExtra.text_extra
   */
  textExtra: {
    /**
     * @generated from field: common.proto.DouyinMediaHastag tag = 2;
     */
    value: DouyinMediaHastag;
    case: "tag";
  } | {
    /**
     * @generated from field: common.proto.DouyinMediaMention mention = 3;
     */
    value: DouyinMediaMention;
    case: "mention";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<DouyinTextExtra>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DouyinTextExtra";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "type", kind: "enum", T: proto3.getEnumType(DouyinTextExtraType) },
    { no: 2, name: "tag", kind: "message", T: DouyinMediaHastag, oneof: "text_extra" },
    { no: 3, name: "mention", kind: "message", T: DouyinMediaMention, oneof: "text_extra" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DouyinTextExtra {
    return new DouyinTextExtra().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DouyinTextExtra {
    return new DouyinTextExtra().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DouyinTextExtra {
    return new DouyinTextExtra().fromJsonString(jsonString, options);
  }

  static equals(a: DouyinTextExtra | PlainMessage<DouyinTextExtra> | undefined, b: DouyinTextExtra | PlainMessage<DouyinTextExtra> | undefined): boolean {
    return proto3.util.equals(DouyinTextExtra, a, b);
  }
}

/**
 * @generated from message common.proto.DouyinMediaMetadata
 */
export class DouyinMediaMetadata extends Message<DouyinMediaMetadata> {
  /**
   * @generated from field: string creator_id = 1;
   */
  creatorId = "";

  /**
   * @generated from field: string id = 2;
   */
  id = "";

  /**
   * @generated from field: common.proto.PostType post_type = 3;
   */
  postType = PostType.POST_TYPE_UNDEFINED;

  /**
   * @generated from field: google.protobuf.Timestamp creation_time = 4;
   */
  creationTime?: Timestamp;

  /**
   * @generated from field: string desc = 6;
   */
  desc = "";

  /**
   * @generated from field: bool prevent_download = 7;
   */
  preventDownload = false;

  /**
   * @generated from field: int32 duration = 8;
   */
  duration = 0;

  /**
   * @generated from field: bool is_story = 16;
   */
  isStory = false;

  /**
   * @generated from field: bool is_top = 17;
   */
  isTop = false;

  /**
   * @generated from field: bool is_vr = 18;
   */
  isVr = false;

  /**
   * @generated from field: string region = 19;
   */
  region = "";

  /**
   * @generated from field: string city = 20;
   */
  city = "";

  /**
   * @generated from field: common.proto.DouyinMediaMetadata.DouyinMediaVideo video = 22;
   */
  video?: DouyinMediaMetadata_DouyinMediaVideo;

  /**
   * @generated from field: common.proto.DouyinMediaMetadata.DouyinMediaSeriesPaidInfo series_paid_info = 23;
   */
  seriesPaidInfo?: DouyinMediaMetadata_DouyinMediaSeriesPaidInfo;

  /**
   * @generated from field: bool is_first_video = 24;
   */
  isFirstVideo = false;

  /**
   * @generated from field: bool is_delete = 25;
   */
  isDelete = false;

  /**
   * @generated from field: bool is_private = 26;
   */
  isPrivate = false;

  /**
   * @generated from field: bool is_prohibited = 27;
   */
  isProhibited = false;

  /**
   * @generated from field: int32 private_status = 28;
   */
  privateStatus = 0;

  /**
   * Store douyin structure for debug purpose
   *
   * @generated from field: repeated common.proto.DouyinTextExtra text_extra = 29;
   */
  textExtra: DouyinTextExtra[] = [];

  /**
   * Mentions
   *
   * @generated from field: repeated string mentions = 50;
   */
  mentions: string[] = [];

  /**
   * @generated from field: repeated string tags = 51;
   */
  tags: string[] = [];

  /**
   * @generated from field: common.proto.PostInteractions interactions = 100;
   */
  interactions?: PostInteractions;

  /**
   * @generated from field: common.proto.DouyinMediaStatistics statistics = 101;
   */
  statistics?: DouyinMediaStatistics;

  constructor(data?: PartialMessage<DouyinMediaMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DouyinMediaMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "post_type", kind: "enum", T: proto3.getEnumType(PostType) },
    { no: 4, name: "creation_time", kind: "message", T: Timestamp },
    { no: 6, name: "desc", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "prevent_download", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "duration", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 16, name: "is_story", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 17, name: "is_top", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "is_vr", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 19, name: "region", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 22, name: "video", kind: "message", T: DouyinMediaMetadata_DouyinMediaVideo },
    { no: 23, name: "series_paid_info", kind: "message", T: DouyinMediaMetadata_DouyinMediaSeriesPaidInfo },
    { no: 24, name: "is_first_video", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 25, name: "is_delete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 26, name: "is_private", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 27, name: "is_prohibited", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 28, name: "private_status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 29, name: "text_extra", kind: "message", T: DouyinTextExtra, repeated: true },
    { no: 50, name: "mentions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 51, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 100, name: "interactions", kind: "message", T: PostInteractions },
    { no: 101, name: "statistics", kind: "message", T: DouyinMediaStatistics },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DouyinMediaMetadata {
    return new DouyinMediaMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DouyinMediaMetadata {
    return new DouyinMediaMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DouyinMediaMetadata {
    return new DouyinMediaMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: DouyinMediaMetadata | PlainMessage<DouyinMediaMetadata> | undefined, b: DouyinMediaMetadata | PlainMessage<DouyinMediaMetadata> | undefined): boolean {
    return proto3.util.equals(DouyinMediaMetadata, a, b);
  }
}

/**
 * @generated from message common.proto.DouyinMediaMetadata.DouyinMediaVideo
 */
export class DouyinMediaMetadata_DouyinMediaVideo extends Message<DouyinMediaMetadata_DouyinMediaVideo> {
  /**
   * url with watermark=1
   *
   * @generated from field: common.proto.DouyinMediaMetadata.DouyinMediaVideo.DouyinMediaVideoAddr download_addr = 1;
   */
  downloadAddr?: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr;

  /**
   * @generated from field: common.proto.DouyinMediaMetadata.DouyinMediaVideo.DouyinMediaVideoAddr download_suffix_logo_addr = 2;
   */
  downloadSuffixLogoAddr?: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr;

  /**
   * @generated from field: common.proto.DouyinMediaMetadata.DouyinMediaVideo.DouyinMediaVideoAddr play_addr = 3;
   */
  playAddr?: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr;

  /**
   * @generated from field: common.proto.DouyinMediaMetadata.DouyinMediaVideo.DouyinMediaVideoAddr play_addr_h264 = 6;
   */
  playAddrH264?: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr;

  /**
   * @generated from field: common.proto.DouyinMediaMetadata.DouyinMediaVideo.DouyinMediaVideoAddr play_addr_lowbr = 4;
   */
  playAddrLowbr?: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr;

  /**
   * @generated from field: common.proto.DouyinMediaMetadata.DouyinMediaVideo.DouyinMediaVideoCoverUrl cover = 5;
   */
  cover?: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl;

  constructor(data?: PartialMessage<DouyinMediaMetadata_DouyinMediaVideo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DouyinMediaMetadata.DouyinMediaVideo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "download_addr", kind: "message", T: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr },
    { no: 2, name: "download_suffix_logo_addr", kind: "message", T: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr },
    { no: 3, name: "play_addr", kind: "message", T: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr },
    { no: 6, name: "play_addr_h264", kind: "message", T: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr },
    { no: 4, name: "play_addr_lowbr", kind: "message", T: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr },
    { no: 5, name: "cover", kind: "message", T: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DouyinMediaMetadata_DouyinMediaVideo {
    return new DouyinMediaMetadata_DouyinMediaVideo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DouyinMediaMetadata_DouyinMediaVideo {
    return new DouyinMediaMetadata_DouyinMediaVideo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DouyinMediaMetadata_DouyinMediaVideo {
    return new DouyinMediaMetadata_DouyinMediaVideo().fromJsonString(jsonString, options);
  }

  static equals(a: DouyinMediaMetadata_DouyinMediaVideo | PlainMessage<DouyinMediaMetadata_DouyinMediaVideo> | undefined, b: DouyinMediaMetadata_DouyinMediaVideo | PlainMessage<DouyinMediaMetadata_DouyinMediaVideo> | undefined): boolean {
    return proto3.util.equals(DouyinMediaMetadata_DouyinMediaVideo, a, b);
  }
}

/**
 * @generated from message common.proto.DouyinMediaMetadata.DouyinMediaVideo.DouyinMediaVideoAddr
 */
export class DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr extends Message<DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr> {
  /**
   * @generated from field: common.proto.StringList url_list = 1;
   */
  urlList?: StringList;

  constructor(data?: PartialMessage<DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DouyinMediaMetadata.DouyinMediaVideo.DouyinMediaVideoAddr";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url_list", kind: "message", T: StringList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr {
    return new DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr {
    return new DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr {
    return new DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr().fromJsonString(jsonString, options);
  }

  static equals(a: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr | PlainMessage<DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr> | undefined, b: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr | PlainMessage<DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr> | undefined): boolean {
    return proto3.util.equals(DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoAddr, a, b);
  }
}

/**
 * @generated from message common.proto.DouyinMediaMetadata.DouyinMediaVideo.DouyinMediaVideoCoverUrl
 */
export class DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl extends Message<DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl> {
  /**
   * @generated from field: common.proto.StringList url_list = 1;
   */
  urlList?: StringList;

  /**
   * @generated from field: int32 height = 2;
   */
  height = 0;

  /**
   * @generated from field: int32 width = 3;
   */
  width = 0;

  /**
   * @generated from field: string uri = 4;
   */
  uri = "";

  constructor(data?: PartialMessage<DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DouyinMediaMetadata.DouyinMediaVideo.DouyinMediaVideoCoverUrl";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url_list", kind: "message", T: StringList },
    { no: 2, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "uri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl {
    return new DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl {
    return new DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl {
    return new DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl().fromJsonString(jsonString, options);
  }

  static equals(a: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl | PlainMessage<DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl> | undefined, b: DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl | PlainMessage<DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl> | undefined): boolean {
    return proto3.util.equals(DouyinMediaMetadata_DouyinMediaVideo_DouyinMediaVideoCoverUrl, a, b);
  }
}

/**
 * @generated from message common.proto.DouyinMediaMetadata.DouyinMediaSeriesPaidInfo
 */
export class DouyinMediaMetadata_DouyinMediaSeriesPaidInfo extends Message<DouyinMediaMetadata_DouyinMediaSeriesPaidInfo> {
  /**
   * @generated from field: float item_price = 1;
   */
  itemPrice = 0;

  /**
   * @generated from field: int32 series_paid_status = 2;
   */
  seriesPaidStatus = 0;

  constructor(data?: PartialMessage<DouyinMediaMetadata_DouyinMediaSeriesPaidInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DouyinMediaMetadata.DouyinMediaSeriesPaidInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "item_price", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 2, name: "series_paid_status", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DouyinMediaMetadata_DouyinMediaSeriesPaidInfo {
    return new DouyinMediaMetadata_DouyinMediaSeriesPaidInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DouyinMediaMetadata_DouyinMediaSeriesPaidInfo {
    return new DouyinMediaMetadata_DouyinMediaSeriesPaidInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DouyinMediaMetadata_DouyinMediaSeriesPaidInfo {
    return new DouyinMediaMetadata_DouyinMediaSeriesPaidInfo().fromJsonString(jsonString, options);
  }

  static equals(a: DouyinMediaMetadata_DouyinMediaSeriesPaidInfo | PlainMessage<DouyinMediaMetadata_DouyinMediaSeriesPaidInfo> | undefined, b: DouyinMediaMetadata_DouyinMediaSeriesPaidInfo | PlainMessage<DouyinMediaMetadata_DouyinMediaSeriesPaidInfo> | undefined): boolean {
    return proto3.util.equals(DouyinMediaMetadata_DouyinMediaSeriesPaidInfo, a, b);
  }
}

/**
 * Additional counts
 *
 * @generated from message common.proto.DouyinMediaStatistics
 */
export class DouyinMediaStatistics extends Message<DouyinMediaStatistics> {
  /**
   * will be used for likes as well
   *
   * @generated from field: int64 digg_count = 10;
   */
  diggCount = protoInt64.zero;

  /**
   * kind of superlikes ?
   *
   * @generated from field: int64 admire_count = 1;
   */
  admireCount = protoInt64.zero;

  /**
   * @generated from field: int64 collect_count = 2;
   */
  collectCount = protoInt64.zero;

  /**
   * @generated from field: int64 download_count = 3;
   */
  downloadCount = protoInt64.zero;

  /**
   * @generated from field: int64 exposure_count = 4;
   */
  exposureCount = protoInt64.zero;

  /**
   * will be used for reshares as well
   *
   * @generated from field: int64 forward_count = 5;
   */
  forwardCount = protoInt64.zero;

  /**
   * @generated from field: int64 live_watch_count = 6;
   */
  liveWatchCount = protoInt64.zero;

  /**
   * @generated from field: int64 lose_count = 7;
   */
  loseCount = protoInt64.zero;

  /**
   * will be used as views as well
   *
   * @generated from field: int64 play_count = 8;
   */
  playCount = protoInt64.zero;

  /**
   * @generated from field: int64 whatsapp_share_count = 9;
   */
  whatsappShareCount = protoInt64.zero;

  /**
   * @generated from field: int64 comment_count = 11;
   */
  commentCount = protoInt64.zero;

  constructor(data?: PartialMessage<DouyinMediaStatistics>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DouyinMediaStatistics";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 10, name: "digg_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 1, name: "admire_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "collect_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "download_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "exposure_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "forward_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "live_watch_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "lose_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "play_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "whatsapp_share_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 11, name: "comment_count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DouyinMediaStatistics {
    return new DouyinMediaStatistics().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DouyinMediaStatistics {
    return new DouyinMediaStatistics().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DouyinMediaStatistics {
    return new DouyinMediaStatistics().fromJsonString(jsonString, options);
  }

  static equals(a: DouyinMediaStatistics | PlainMessage<DouyinMediaStatistics> | undefined, b: DouyinMediaStatistics | PlainMessage<DouyinMediaStatistics> | undefined): boolean {
    return proto3.util.equals(DouyinMediaStatistics, a, b);
  }
}

/**
 * @generated from message common.proto.DouyinMedia
 */
export class DouyinMedia extends Message<DouyinMedia> {
  /**
   * @generated from field: common.proto.DouyinMediaMetadata metadata = 1;
   */
  metadata?: DouyinMediaMetadata;

  /**
   * @generated from field: common.proto.GenerikMediaFacts facts = 2;
   */
  facts?: GenerikMediaFacts;

  constructor(data?: PartialMessage<DouyinMedia>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.DouyinMedia";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "metadata", kind: "message", T: DouyinMediaMetadata },
    { no: 2, name: "facts", kind: "message", T: GenerikMediaFacts },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DouyinMedia {
    return new DouyinMedia().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DouyinMedia {
    return new DouyinMedia().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DouyinMedia {
    return new DouyinMedia().fromJsonString(jsonString, options);
  }

  static equals(a: DouyinMedia | PlainMessage<DouyinMedia> | undefined, b: DouyinMedia | PlainMessage<DouyinMedia> | undefined): boolean {
    return proto3.util.equals(DouyinMedia, a, b);
  }
}


import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import {
  DEFAULT_PROFILE_PIC,
  Influencer,
  isEmptyString,
  isNil,
} from '@frontend2/core';
import {
  CreatorCardSnippet,
  CreatorTiniestCard,
} from '@frontend2/proto/librarian/proto/creators_pb';
import { ObjectFit } from '../utils';
import { SafeImageComponent } from './image';

@Component({
  selector: 'profile-picture',
  template: `
    <safe-image
      class="img"
      [objectFit]="objectFit"
      [fallbackUrl]="fallbackUrl"
      [urls]="urls"
    ></safe-image>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['profile-pic.component.scss'],
  standalone: true,
  imports: [SafeImageComponent],
})
export class ProfilePictureComponent {
  readonly fallbackUrl = DEFAULT_PROFILE_PIC;

  @Input()
  objectFit: ObjectFit = 'cover';

  @Input()
  urls: string[] = [];

  @Input()
  @HostBinding('class.squared')
  squared = false;

  @Input()
  set src(val: string | undefined) {
    this.urls = isNil(val) || isEmptyString(val) ? [] : [val];
  }

  @Input()
  set creator(
    value: CreatorCardSnippet | CreatorTiniestCard | Influencer | undefined,
  ) {
    if (isNil(value)) {
      this.urls = [];
    } else if (value instanceof CreatorTiniestCard) {
      this.urls = [value.profilePicKept];
    } else if (value instanceof CreatorCardSnippet) {
      this.urls = [value.profilePicKept, value.profilePic];
    } else {
      this.creator = value.baseSnippet;
    }
  }
}

import {
  Casting,
  CastingAudienceMetricEnum,
  CastingAudienceMetricPb,
  CastingStatisticsMetricEnum,
  CastingStatisticsMetricPb,
  GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer,
} from '@frontend2/proto/librarian/proto/casting_pb';
import { isNil } from '../../utils/common.helpers';
import { isEmptyString } from '../../utils/strings.helpers';

export function isHiddenInMetricSettings(
  metric: CastingAudienceMetricEnum | CastingStatisticsMetricEnum,
  metricsArray: CastingAudienceMetricPb[] | CastingStatisticsMetricPb[],
): boolean {
  for (const m of metricsArray) {
    if (m.metric === metric) {
      return !m.display;
    }
  }
  return false;
}

export function readableCastingStatisticsMetric(
  castingMetric: CastingStatisticsMetricEnum,
): string {
  switch (castingMetric) {
    case CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_FOLLOWERS:
      return $localize`Followers`;
    case CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_AVERAGE_IMPRESSIONS:
      return $localize`Average impressions`;
    case CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_ENGAGEMENT_RATE:
      return $localize`Engagement rate`;
    case CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_AVERAGE_REACH:
      return $localize`Average reach`;
    case CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_AVERAGE_COVERAGE:
      return $localize`Average coverage`;
    case CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_SATURATION_RATE:
      return $localize`Saturation rate`;
    case CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_AVERAGE_VIEWS:
      return $localize`Average views`;
    case CastingStatisticsMetricEnum.CASTING_STATISTICS_METRIC_AVERAGE_RESHARES:
      return $localize`Average reshares`;
    default:
      return $localize`Unknown`;
  }
}

export function readableCastingAudienceMetric(
  castingMetric: CastingAudienceMetricEnum,
): string {
  switch (castingMetric) {
    case CastingAudienceMetricEnum.CASTING_AUDIENCE_METRIC_GENDER:
      return $localize`Gender`;
    case CastingAudienceMetricEnum.CASTING_AUDIENCE_METRIC_GEO:
      return $localize`Countries and Cities`;
    case CastingAudienceMetricEnum.CASTING_AUDIENCE_METRIC_AGE:
      return $localize`Age`;
    case CastingAudienceMetricEnum.CASTING_AUDIENCE_METRIC_SCORE:
      return $localize`Score`;
    default:
      return $localize`Unknown`;
  }
}

export function isGhostCasting(casting: Casting): boolean {
  return isNil(casting.id) || casting.id === BigInt(0);
}

export function isGhostInfluencerCasting(
  influencer: GetCollaboratorForecastingExpectedPostsResponse_CollaboratorForecastingExpectedPostsInfluencer,
): boolean {
  return isNil(influencer) || isEmptyString(influencer.baseSnippet?.userId);
}

<lefty-form
  [label]="label()"
  [required]="required()"
  [helpText]="helpText()"
  [externalHelpLink]="externalHelpLink()"
  [helpLink]="helpLink()"
  [hintText]="hintText()"
  [tooltip]="tooltip()"
  [large]="large()"
  [topHintText]="topHintText()"
>
  @for (ctrl of formModel.controls; track $index) {
    <lefty-card [withBorder]="true">
      <network-tracking-input-v2
        [availableNetworks]="unusedNetworks()"
        [canRemove]="networks().length > 1"
        (remove$)="remove($index)"
        [formControl]="ctrl"
        [withLogicOperator]="withLogicOperator()"
        [withExcluded]="withExcluded()"
        [compact]="true"
      ></network-tracking-input-v2>
    </lefty-card>
  }

  @if (unusedNetworks().length !== 0 && !hideAddNetwork()) {
    <button
      (click)="addNetwork()"
      leftyButton="outline"
      leadingIcon="add"
      class="add-button"
      type="button"
    >
      Add network
    </button>
  }
</lefty-form>

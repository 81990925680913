import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import {
  Networks,
  createGhostInfluencerCasting,
  formatDate,
  getInfluencerNetworkInfo,
} from '@frontend2/core';
import { getPostTypesOfNetwork } from '@frontend2/proto-helpers/common/common_pb.helpers';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import {
  Post,
  PostStyle,
  getStyleOfPostType,
} from '../../lefty-posts/posts.models';
import { LeftyComponent } from '../../utils';
import { LeftyPostMediaComponent } from '../../lefty-posts/media/post-media.component';
import { LeftyPostMediaOrCaptionComponent } from '../../lefty-posts/media/post-media-or-caption.component';
import { NgIf, NgFor } from '@angular/common';

@Component({
  selector: 'casting-influencer-posts',
  templateUrl: './influencer-posts.component.html',
  styleUrls: ['./influencer-posts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    LeftyPostMediaOrCaptionComponent,
    LeftyPostMediaComponent,
  ],
})
export class CastingInfluencerPostsComponent extends LeftyComponent {
  @Input()
  influencer = createGhostInfluencerCasting();

  @Input()
  network = Network.NETWORK_UNKNOWN;

  @Input()
  posts: Post[] = [];

  @Input()
  @HostBinding('class.print')
  isPrint = false;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  trackBy(index: number, _: Post): number {
    return index;
  }

  get openInNetworkLink(): string {
    return getInfluencerNetworkInfo(this.influencer, this.network).networkLink;
  }

  get openInNetworkMsg(): string {
    return Networks.openInMessage(this.network);
  }

  get postStyle(): PostStyle {
    if (this.network === Network.NETWORK_UNKNOWN) {
      return PostStyle.image;
    }
    const postTypes = getPostTypesOfNetwork(this.network);
    return getStyleOfPostType(postTypes[0]);
  }

  get isImage(): boolean {
    return this.postStyle === PostStyle.image;
  }

  get isStory(): boolean {
    return this.postStyle === PostStyle.story;
  }

  get isText(): boolean {
    return this.postStyle === PostStyle.text;
  }

  get isYoutube(): boolean {
    return this.postStyle === PostStyle.youtube;
  }

  formatePublishedDate(date: Date): string {
    return formatDate(date);
  }
}

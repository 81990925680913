// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/authentication.proto (syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, MethodOptions, proto3 } from "@bufbuild/protobuf";

/**
 * WARNING: Adding new permissions and using them immediately is dangerous
 * To add a new permission, follow these steps:
 * step 1: add the new permission to the enum here and then deploy to prod
 * step 2: add it to the EDITOR and ADMIN roles in DB (tables brands_user_roles and brands_user_roles_permissions)
 * step 3: start using the new permission to protect your APIs and then deploy to prod
 * Note: DO NOT CHANGE ENUMS NAMES, they are persisted in DB, instead of index (legacy mistake)
 *
 * @generated from enum Permission
 */
export enum Permission {
  /**
   * @generated from enum value: VIEWER = 0;
   */
  VIEWER = 0,

  /**
   * @generated from enum value: SETTINGS = 1;
   */
  SETTINGS = 1,

  /**
   * @generated from enum value: PERMISSIONS = 2;
   */
  PERMISSIONS = 2,

  /**
   * @generated from enum value: CAMPAIGNS = 3;
   */
  CAMPAIGNS = 3,

  /**
   * @generated from enum value: AFFILIATIONS = 4;
   */
  AFFILIATIONS = 4,

  /**
   * @generated from enum value: LANDING_PAGES = 5;
   */
  LANDING_PAGES = 5,

  /**
   * @generated from enum value: PAYMENTS = 6;
   */
  PAYMENTS = 6,

  /**
   * @generated from enum value: CASTINGS = 7;
   */
  CASTINGS = 7,

  /**
   * @generated from enum value: DIRECTORY = 8;
   */
  DIRECTORY = 8,

  /**
   * @generated from enum value: AUDIENCE = 9;
   */
  AUDIENCE = 9,

  /**
   * @generated from enum value: CHAT = 10;
   */
  CHAT = 10,

  /**
   * @generated from enum value: PRODUCTS = 11;
   */
  PRODUCTS = 11,

  /**
   * @generated from enum value: ANALYTICS = 12;
   */
  ANALYTICS = 12,

  /**
   * @generated from enum value: CONTRACTS = 13;
   */
  CONTRACTS = 13,
}
// Retrieve enum metadata with: proto3.getEnumType(Permission)
proto3.util.setEnumType(Permission, "Permission", [
  { no: 0, name: "VIEWER" },
  { no: 1, name: "SETTINGS" },
  { no: 2, name: "PERMISSIONS" },
  { no: 3, name: "CAMPAIGNS" },
  { no: 4, name: "AFFILIATIONS" },
  { no: 5, name: "LANDING_PAGES" },
  { no: 6, name: "PAYMENTS" },
  { no: 7, name: "CASTINGS" },
  { no: 8, name: "DIRECTORY" },
  { no: 9, name: "AUDIENCE" },
  { no: 10, name: "CHAT" },
  { no: 11, name: "PRODUCTS" },
  { no: 12, name: "ANALYTICS" },
  { no: 13, name: "CONTRACTS" },
]);

/**
 * @generated from message Authentication
 */
export class Authentication extends Message<Authentication> {
  /**
   * @generated from field: repeated Permission permissions = 1;
   */
  permissions: Permission[] = [];

  /**
   * @generated from field: bool is_sharing_token_enabled = 2;
   */
  isSharingTokenEnabled = false;

  /**
   * @generated from field: bool is_lefty_admin_only = 3;
   */
  isLeftyAdminOnly = false;

  /**
   * @generated from field: bool is_lefty_super_admin_only = 5;
   */
  isLeftySuperAdminOnly = false;

  /**
   * @generated from field: bool is_logged_author = 4;
   */
  isLoggedAuthor = false;

  constructor(data?: PartialMessage<Authentication>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "Authentication";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "permissions", kind: "enum", T: proto3.getEnumType(Permission), repeated: true },
    { no: 2, name: "is_sharing_token_enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "is_lefty_admin_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "is_lefty_super_admin_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "is_logged_author", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Authentication {
    return new Authentication().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Authentication {
    return new Authentication().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Authentication {
    return new Authentication().fromJsonString(jsonString, options);
  }

  static equals(a: Authentication | PlainMessage<Authentication> | undefined, b: Authentication | PlainMessage<Authentication> | undefined): boolean {
    return proto3.util.equals(Authentication, a, b);
  }
}

/**
 * @generated from extension: Authentication authentication = 52344;
 */
export const authentication = proto3.makeExtension<MethodOptions, Authentication>(
  "authentication", 
  MethodOptions, 
  () => ({ no: 52344, kind: "message", T: Authentication }),
);


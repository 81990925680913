import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianUsers } from '@frontend2/proto/librarian/proto/users_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianUsersClient = PromiseClient<typeof LibrarianUsers>;

export const LibrarianUsersClientProvider = new InjectionToken(
  'LibrarianUsersClient',
  {
    factory: (): LibrarianUsersClient =>
      createPromiseClient(LibrarianUsers, inject(GRPC_TRANSPORT)),
  },
);

export function injectLibrarianUsersClient(): LibrarianUsersClient {
  return inject(LibrarianUsersClientProvider);
}

<lefty-form-select
  [(ngModel)]="network"
  [componentFactory]="networkItemFactory"
  [options]="availableNetworks()"
  [optionsTrackBy]="trackByNetwork"
>
  <network-icon
    buttonContent
    [network]="network()"
  ></network-icon>
</lefty-form-select>
<div class="fill-space">
  <lefty-form-autocomplete
    [placeholder]="placeholder()"
    leadingGlyph="search"
    [(value)]="value"
    [inputText]="valueUsername()"
    (inputTextChange)="this.searchValue.set($event)"
    [options]="influencersOptionsWithNetwork()"
    [noFiltering]="true"
    [componentFactory]="influencerItemFactory"
    [itemRenderer]="influencerRenderer"
    [popupMatchInputWidth]="true"
    [loading]="loading()"
    [disableClearSelection]="true"
    [isOptionDisabled]="isOptionDisabled"
  ></lefty-form-autocomplete>
</div>

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/competitive_watch.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, Int64Value, MethodKind, StringValue } from "@bufbuild/protobuf";
import { BrandInfluencersResponse, BrandSummary, CWBrands, CWCreateOrEditReportRequest, CWDirectorySets, CWGetBrandsRequest, CWGetDirectorySetsRequest, CWGetPredefinedSetsRequest, CWIndustriesByCategory, CWMarkets, CWSetsMinimal, FullReport, MonthlyEmvResponse, PostsRequest, ReportBrandsSummariesByTypeResponse, ReportDetailResponse, ReportDetails, ReportDetailsRequest, ReportsResponse, ReportStatsResponse, Summary, SummarySplit } from "./competitive_watch_pb";
import { CWBrandDetailsRequest, CWInfluencersRequest } from "./common_pb";
import { GenerikVisuals } from "./campaigns_pb";

/**
 * This service handles everything related to competitive watch.
 *
 * @generated from service librarian.proto.LibrarianCompetitiveWatch
 */
export const LibrarianCompetitiveWatch = {
  typeName: "librarian.proto.LibrarianCompetitiveWatch",
  methods: {
    /**
     * The argument is a user id. It is only considered in programmatic access, as the REST api will supply the reports of
     * the logged used.
     *
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetReports
     */
    getReports: {
      name: "GetReports",
      I: Int64Value,
      O: ReportsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.ArchiveReport
     */
    archiveReport: {
      name: "ArchiveReport",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.UnarchiveReport
     */
    unarchiveReport: {
      name: "UnarchiveReport",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetReportV2
     * @deprecated
     */
    getReportV2: {
      name: "GetReportV2",
      I: ReportDetailsRequest,
      O: ReportDetailResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Summary by brands and type
     *
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetReportBrandsSummmariesByType
     */
    getReportBrandsSummmariesByType: {
      name: "GetReportBrandsSummmariesByType",
      I: ReportDetailsRequest,
      O: ReportBrandsSummariesByTypeResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Summary by type for the whole report (for percentage purpose)
     *
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetReportSummmariesByType
     */
    getReportSummmariesByType: {
      name: "GetReportSummmariesByType",
      I: ReportDetailsRequest,
      O: SummarySplit,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetBrands
     */
    getBrands: {
      name: "GetBrands",
      I: CWGetBrandsRequest,
      O: CWBrands,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetPredefinedSets
     */
    getPredefinedSets: {
      name: "GetPredefinedSets",
      I: CWGetPredefinedSetsRequest,
      O: CWSetsMinimal,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetDirectorySets
     */
    getDirectorySets: {
      name: "GetDirectorySets",
      I: CWGetDirectorySetsRequest,
      O: CWDirectorySets,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.CreateOrEditReport
     */
    createOrEditReport: {
      name: "CreateOrEditReport",
      I: CWCreateOrEditReportRequest,
      O: Int64Value,
      kind: MethodKind.Unary,
    },
    /**
     * get all distinct markets of report sets
     *
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetReportMarkets
     */
    getReportMarkets: {
      name: "GetReportMarkets",
      I: Int64Value,
      O: CWMarkets,
      kind: MethodKind.Unary,
    },
    /**
     * get all distinct industries of report sets
     *
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetReportIndustriesByCategory
     */
    getReportIndustriesByCategory: {
      name: "GetReportIndustriesByCategory",
      I: Int64Value,
      O: CWIndustriesByCategory,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetReportDetails
     */
    getReportDetails: {
      name: "GetReportDetails",
      I: Int64Value,
      O: ReportDetails,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetReportSummary
     */
    getReportSummary: {
      name: "GetReportSummary",
      I: ReportDetailsRequest,
      O: Summary,
      kind: MethodKind.Unary,
    },
    /**
     * This one uses a task as some fat report loading might timeout
     *
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetReportSummaryV2
     */
    getReportSummaryV2: {
      name: "GetReportSummaryV2",
      I: ReportDetailsRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetBrandDetailsV2
     */
    getBrandDetailsV2: {
      name: "GetBrandDetailsV2",
      I: CWBrandDetailsRequest,
      O: BrandSummary,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetReportTopPosts
     */
    getReportTopPosts: {
      name: "GetReportTopPosts",
      I: PostsRequest,
      O: GenerikVisuals,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetReportTopInfluencers
     */
    getReportTopInfluencers: {
      name: "GetReportTopInfluencers",
      I: CWInfluencersRequest,
      O: BrandInfluencersResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetReportStatisticsV2
     */
    getReportStatisticsV2: {
      name: "GetReportStatisticsV2",
      I: CWBrandDetailsRequest,
      O: ReportStatsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetReportMonthlyEmvV2
     */
    getReportMonthlyEmvV2: {
      name: "GetReportMonthlyEmvV2",
      I: CWBrandDetailsRequest,
      O: MonthlyEmvResponse,
      kind: MethodKind.Unary,
    },
    /**
     * this is only for PPT generation
     *
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.GetFullReport
     */
    getFullReport: {
      name: "GetFullReport",
      I: ReportDetailsRequest,
      O: FullReport,
      kind: MethodKind.Unary,
    },
    /**
     * returns the task handle.
     *
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.RequestReportPPTGen
     */
    requestReportPPTGen: {
      name: "RequestReportPPTGen",
      I: ReportDetailsRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * returns the bronco handle
     *
     * @generated from rpc librarian.proto.LibrarianCompetitiveWatch.RequestExportExcelTask
     */
    requestExportExcelTask: {
      name: "RequestExportExcelTask",
      I: ReportDetailsRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
  }
} as const;


/* eslint-disable @angular-eslint/no-output-native */
import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  Optional,
  Output,
  Self,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { NgControl, FormsModule } from '@angular/forms';
import { isNil, isNotEmptyString } from '@frontend2/core';
import { Focusable, FocusableComponent } from '../focus.directive';
import { LeftyFormValueBase, buildFormErrorMessage } from '../form';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-form-textarea',
  templateUrl: './lefty-form-textarea.component.html',
  styleUrls: ['./lefty-form-textarea.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FocusableComponent,
      useExisting: forwardRef(() => LeftyFormTextareaComponent),
    },
  ],
  standalone: true,
  imports: [LeftyFormComponent, FormsModule],
})
export class LeftyFormTextareaComponent
  extends LeftyFormValueBase<string>
  implements Focusable, AfterContentChecked
{
  constructor(
    private elementRef: ElementRef,
    @Self() @Optional() ngControl?: NgControl,
  ) {
    super('', ngControl);
    this.elementRef = elementRef;

    this.disposer.add(this.keydown$);
    this.disposer.add(this.keyup$);
    this.disposer.add(this.keypress$);
  }

  @HostBinding('class.has-value')
  get hasValue(): boolean {
    return isNotEmptyString(this.value);
  }

  @Input() rows = 5;
  @Input() tabIndex?: number;
  @Input() placeholder?: string;
  @Input() autocomplete?: string;
  @Input() name?: string;
  @Input() autoresize = false;
  @Input() maxLength = -1;

  @Output()
  readonly keypress$ = new EventEmitter<KeyboardEvent>();

  @Output()
  readonly keyup$ = new EventEmitter<KeyboardEvent>();

  @Output()
  readonly keydown$ = new EventEmitter<KeyboardEvent>();

  @ViewChild('textarea')
  textareaElementRef?: ElementRef<HTMLTextAreaElement>;

  get textareaElement(): HTMLTextAreaElement | undefined {
    return this.textareaElementRef?.nativeElement;
  }

  get nativeElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  // setting a manual focus without event, (used for dynamic filters)
  focus(): void {
    if (this.textareaElement) {
      this.textareaElement.focus();
      this.changeDetection.markForCheck();
    }
  }

  select(): void {
    if (this.textareaElement) {
      this.textareaElement.select();
    }
  }

  get textValue(): string {
    return this.textareaElement?.value ?? '';
  }

  override get errorMessage(): string {
    if (this.ngControl && this.invalid) {
      const message =
        buildFormErrorMessage(this.ngControl) ?? super.errorMessage;
      if (isNotEmptyString(message)) {
        return message;
      }

      return this.textareaElement?.validationMessage ?? '';
    }

    return super.errorMessage;
  }

  resize(): void {
    if (this.textareaElement) {
      // add 2px for border top and bottom
      this.textareaElement.style.height =
        this.textareaElement.scrollHeight + 2 + 'px';
    }
  }

  override writeValue(obj: unknown): void {
    if (typeof obj === 'string') {
      this.value = obj;
    } else if (isNil(obj)) {
      this.value = '';
    } else {
      console.warn(
        `Failed to bind type ${typeof obj} on form control ${
          this.ngControl?.name
        }`,
      );
    }
  }

  ngAfterContentChecked(): void {
    if (this.autoresize) {
      this.resize();
    }
  }
}

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/competitive_watch.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { BoolValue, Int32Value, Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { BasicPagination, CurrencyEnum, InfluencerKind, Network, PostType, StringCount, StringList, Timeseries } from "../../common/proto/common_pb";
import { CreatorCardSnippet, CreatorCWCardSnippet, CreatorRegisteredDetails, CreatorTiniestCard, GenerikInfoCard } from "./creators_pb";
import { MetaIdPb } from "../../common/proto/creator_pb";
import { CWBrandDetailsRequest, GenerikKeywordsFilter, TimeRestriction, VisualSort } from "./common_pb";
import { CampaignKeyword } from "../../common/proto/campaign_pb";
import { GenerikSnippet } from "./users_pb";
import { SearchRequest } from "../../universal/arkansas/proto/arkansas_pb";
import { DiscoverRequest } from "./discover_pb";

/**
 * @generated from enum librarian.proto.SummaryType
 */
export enum SummaryType {
  /**
   * @generated from enum value: TOTAL = 0;
   */
  TOTAL = 0,

  /**
   * @generated from enum value: NANO = 1;
   */
  NANO = 1,

  /**
   * @generated from enum value: MICRO = 2;
   */
  MICRO = 2,

  /**
   * @generated from enum value: MACRO = 3;
   */
  MACRO = 3,

  /**
   * @generated from enum value: TOP = 4;
   */
  TOP = 4,

  /**
   * @generated from enum value: MEGA = 5;
   */
  MEGA = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(SummaryType)
proto3.util.setEnumType(SummaryType, "librarian.proto.SummaryType", [
  { no: 0, name: "TOTAL" },
  { no: 1, name: "NANO" },
  { no: 2, name: "MICRO" },
  { no: 3, name: "MACRO" },
  { no: 4, name: "TOP" },
  { no: 5, name: "MEGA" },
]);

/**
 * @generated from enum librarian.proto.ReportType
 */
export enum ReportType {
  /**
   * @generated from enum value: REPORT_UNDEFINED = 0;
   */
  REPORT_UNDEFINED = 0,

  /**
   * @generated from enum value: REPORT_EMV = 1;
   */
  REPORT_EMV = 1,

  /**
   * @generated from enum value: REPORT_EMV_SPONSORED = 2;
   */
  REPORT_EMV_SPONSORED = 2,

  /**
   * @generated from enum value: REPORT_POST_COUNT = 3;
   */
  REPORT_POST_COUNT = 3,

  /**
   * @generated from enum value: REPORT_UNIQUE_COUNT = 4;
   */
  REPORT_UNIQUE_COUNT = 4,

  /**
   * @generated from enum value: REPORT_PROFILE_COUNT = 5;
   */
  REPORT_PROFILE_COUNT = 5,

  /**
   * @generated from enum value: REPORT_AMBASSADOR_COUNT = 6;
   */
  REPORT_AMBASSADOR_COUNT = 6,

  /**
   * @generated from enum value: REPORT_IMPRESSIONS = 7;
   */
  REPORT_IMPRESSIONS = 7,
}
// Retrieve enum metadata with: proto3.getEnumType(ReportType)
proto3.util.setEnumType(ReportType, "librarian.proto.ReportType", [
  { no: 0, name: "REPORT_UNDEFINED" },
  { no: 1, name: "REPORT_EMV" },
  { no: 2, name: "REPORT_EMV_SPONSORED" },
  { no: 3, name: "REPORT_POST_COUNT" },
  { no: 4, name: "REPORT_UNIQUE_COUNT" },
  { no: 5, name: "REPORT_PROFILE_COUNT" },
  { no: 6, name: "REPORT_AMBASSADOR_COUNT" },
  { no: 7, name: "REPORT_IMPRESSIONS" },
]);

/**
 * @generated from enum librarian.proto.ReportPriority
 */
export enum ReportPriority {
  /**
   * @generated from enum value: REPORT_PRIORITY_UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: REPORT_PRIORITY_VERY_LOW = 1;
   */
  VERY_LOW = 1,

  /**
   * @generated from enum value: REPORT_PRIORITY_LOW = 2;
   */
  LOW = 2,

  /**
   * @generated from enum value: REPORT_PRIORITY_MEDIUM = 3;
   */
  MEDIUM = 3,

  /**
   * @generated from enum value: REPORT_PRIORITY_HIGH = 4;
   */
  HIGH = 4,

  /**
   * @generated from enum value: REPORT_PRIORITY_VERY_HIGH = 5;
   */
  VERY_HIGH = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(ReportPriority)
proto3.util.setEnumType(ReportPriority, "librarian.proto.ReportPriority", [
  { no: 0, name: "REPORT_PRIORITY_UNDEFINED" },
  { no: 1, name: "REPORT_PRIORITY_VERY_LOW" },
  { no: 2, name: "REPORT_PRIORITY_LOW" },
  { no: 3, name: "REPORT_PRIORITY_MEDIUM" },
  { no: 4, name: "REPORT_PRIORITY_HIGH" },
  { no: 5, name: "REPORT_PRIORITY_VERY_HIGH" },
]);

/**
 * @generated from enum librarian.proto.SummarySplitType
 */
export enum SummarySplitType {
  /**
   * @generated from enum value: SUMMARY_SPLIT_TYPE_UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: SUMMARY_SPLIT_TYPE_INFLUENCER_TIERS = 1;
   */
  INFLUENCER_TIERS = 1,

  /**
   * @generated from enum value: SUMMARY_SPLIT_TYPE_NETWORKS = 2;
   */
  NETWORKS = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(SummarySplitType)
proto3.util.setEnumType(SummarySplitType, "librarian.proto.SummarySplitType", [
  { no: 0, name: "SUMMARY_SPLIT_TYPE_UNDEFINED" },
  { no: 1, name: "SUMMARY_SPLIT_TYPE_INFLUENCER_TIERS" },
  { no: 2, name: "SUMMARY_SPLIT_TYPE_NETWORKS" },
]);

/**
 * @generated from message librarian.proto.CWCustomReportsConfiguration
 */
export class CWCustomReportsConfiguration extends Message<CWCustomReportsConfiguration> {
  /**
   * @generated from field: repeated librarian.proto.CWSetMinimal sets = 1;
   */
  sets: CWSetMinimal[] = [];

  /**
   * @generated from field: repeated librarian.proto.CWSetGroupMinimal set_groups = 2;
   */
  setGroups: CWSetGroupMinimal[] = [];

  /**
   * @generated from field: repeated librarian.proto.CWBrandPb brands = 3;
   */
  brands: CWBrandPb[] = [];

  constructor(data?: PartialMessage<CWCustomReportsConfiguration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWCustomReportsConfiguration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sets", kind: "message", T: CWSetMinimal, repeated: true },
    { no: 2, name: "set_groups", kind: "message", T: CWSetGroupMinimal, repeated: true },
    { no: 3, name: "brands", kind: "message", T: CWBrandPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWCustomReportsConfiguration {
    return new CWCustomReportsConfiguration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWCustomReportsConfiguration {
    return new CWCustomReportsConfiguration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWCustomReportsConfiguration {
    return new CWCustomReportsConfiguration().fromJsonString(jsonString, options);
  }

  static equals(a: CWCustomReportsConfiguration | PlainMessage<CWCustomReportsConfiguration> | undefined, b: CWCustomReportsConfiguration | PlainMessage<CWCustomReportsConfiguration> | undefined): boolean {
    return proto3.util.equals(CWCustomReportsConfiguration, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWBrandPb
 */
export class CWBrandPb extends Message<CWBrandPb> {
  /**
   * @generated from field: int64 brand_id = 1;
   */
  brandId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: bool archived = 3;
   */
  archived = false;

  constructor(data?: PartialMessage<CWBrandPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWBrandPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brand_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWBrandPb {
    return new CWBrandPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWBrandPb {
    return new CWBrandPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWBrandPb {
    return new CWBrandPb().fromJsonString(jsonString, options);
  }

  static equals(a: CWBrandPb | PlainMessage<CWBrandPb> | undefined, b: CWBrandPb | PlainMessage<CWBrandPb> | undefined): boolean {
    return proto3.util.equals(CWBrandPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWBrands
 */
export class CWBrands extends Message<CWBrands> {
  /**
   * @generated from field: repeated librarian.proto.CWBrandPb brands = 1;
   */
  brands: CWBrandPb[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<CWBrands>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWBrands";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brands", kind: "message", T: CWBrandPb, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWBrands {
    return new CWBrands().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWBrands {
    return new CWBrands().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWBrands {
    return new CWBrands().fromJsonString(jsonString, options);
  }

  static equals(a: CWBrands | PlainMessage<CWBrands> | undefined, b: CWBrands | PlainMessage<CWBrands> | undefined): boolean {
    return proto3.util.equals(CWBrands, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWMarket
 */
export class CWMarket extends Message<CWMarket> {
  /**
   * @generated from field: int64 market_id = 1;
   */
  marketId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: bool archived = 3;
   */
  archived = false;

  constructor(data?: PartialMessage<CWMarket>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWMarket";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "market_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWMarket {
    return new CWMarket().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWMarket {
    return new CWMarket().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWMarket {
    return new CWMarket().fromJsonString(jsonString, options);
  }

  static equals(a: CWMarket | PlainMessage<CWMarket> | undefined, b: CWMarket | PlainMessage<CWMarket> | undefined): boolean {
    return proto3.util.equals(CWMarket, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWMarkets
 */
export class CWMarkets extends Message<CWMarkets> {
  /**
   * @generated from field: repeated librarian.proto.CWMarket markets = 1;
   */
  markets: CWMarket[] = [];

  constructor(data?: PartialMessage<CWMarkets>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWMarkets";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "markets", kind: "message", T: CWMarket, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWMarkets {
    return new CWMarkets().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWMarkets {
    return new CWMarkets().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWMarkets {
    return new CWMarkets().fromJsonString(jsonString, options);
  }

  static equals(a: CWMarkets | PlainMessage<CWMarkets> | undefined, b: CWMarkets | PlainMessage<CWMarkets> | undefined): boolean {
    return proto3.util.equals(CWMarkets, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWIndustryPb
 */
export class CWIndustryPb extends Message<CWIndustryPb> {
  /**
   * @generated from field: int64 industry_id = 1;
   */
  industryId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: bool archived = 3;
   */
  archived = false;

  constructor(data?: PartialMessage<CWIndustryPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWIndustryPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "industry_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWIndustryPb {
    return new CWIndustryPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWIndustryPb {
    return new CWIndustryPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWIndustryPb {
    return new CWIndustryPb().fromJsonString(jsonString, options);
  }

  static equals(a: CWIndustryPb | PlainMessage<CWIndustryPb> | undefined, b: CWIndustryPb | PlainMessage<CWIndustryPb> | undefined): boolean {
    return proto3.util.equals(CWIndustryPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWIndustryCategoryPb
 */
export class CWIndustryCategoryPb extends Message<CWIndustryCategoryPb> {
  /**
   * @generated from field: int64 industry_category_id = 1;
   */
  industryCategoryId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: bool archived = 3;
   */
  archived = false;

  constructor(data?: PartialMessage<CWIndustryCategoryPb>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWIndustryCategoryPb";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "industry_category_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWIndustryCategoryPb {
    return new CWIndustryCategoryPb().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWIndustryCategoryPb {
    return new CWIndustryCategoryPb().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWIndustryCategoryPb {
    return new CWIndustryCategoryPb().fromJsonString(jsonString, options);
  }

  static equals(a: CWIndustryCategoryPb | PlainMessage<CWIndustryCategoryPb> | undefined, b: CWIndustryCategoryPb | PlainMessage<CWIndustryCategoryPb> | undefined): boolean {
    return proto3.util.equals(CWIndustryCategoryPb, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWCategoryIndustries
 */
export class CWCategoryIndustries extends Message<CWCategoryIndustries> {
  /**
   * @generated from field: librarian.proto.CWIndustryCategoryPb industry_category = 1;
   */
  industryCategory?: CWIndustryCategoryPb;

  /**
   * @generated from field: repeated librarian.proto.CWIndustryPb industries = 2;
   */
  industries: CWIndustryPb[] = [];

  constructor(data?: PartialMessage<CWCategoryIndustries>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWCategoryIndustries";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "industry_category", kind: "message", T: CWIndustryCategoryPb },
    { no: 2, name: "industries", kind: "message", T: CWIndustryPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWCategoryIndustries {
    return new CWCategoryIndustries().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWCategoryIndustries {
    return new CWCategoryIndustries().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWCategoryIndustries {
    return new CWCategoryIndustries().fromJsonString(jsonString, options);
  }

  static equals(a: CWCategoryIndustries | PlainMessage<CWCategoryIndustries> | undefined, b: CWCategoryIndustries | PlainMessage<CWCategoryIndustries> | undefined): boolean {
    return proto3.util.equals(CWCategoryIndustries, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWIndustriesByCategory
 */
export class CWIndustriesByCategory extends Message<CWIndustriesByCategory> {
  /**
   * @generated from field: repeated librarian.proto.CWCategoryIndustries category_industries = 1;
   */
  categoryIndustries: CWCategoryIndustries[] = [];

  constructor(data?: PartialMessage<CWIndustriesByCategory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWIndustriesByCategory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category_industries", kind: "message", T: CWCategoryIndustries, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWIndustriesByCategory {
    return new CWIndustriesByCategory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWIndustriesByCategory {
    return new CWIndustriesByCategory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWIndustriesByCategory {
    return new CWIndustriesByCategory().fromJsonString(jsonString, options);
  }

  static equals(a: CWIndustriesByCategory | PlainMessage<CWIndustriesByCategory> | undefined, b: CWIndustriesByCategory | PlainMessage<CWIndustriesByCategory> | undefined): boolean {
    return proto3.util.equals(CWIndustriesByCategory, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWSetGroupMinimal
 */
export class CWSetGroupMinimal extends Message<CWSetGroupMinimal> {
  /**
   * @generated from field: int64 set_group_id = 1;
   */
  setGroupId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: google.protobuf.StringValue description = 3;
   */
  description?: string;

  /**
   * @generated from field: repeated librarian.proto.CWSetMinimal sets = 4;
   */
  sets: CWSetMinimal[] = [];

  /**
   * @generated from field: librarian.proto.CWMarket market = 5;
   */
  market?: CWMarket;

  /**
   * @generated from field: librarian.proto.CWIndustryPb industry = 6;
   */
  industry?: CWIndustryPb;

  /**
   * @generated from field: int64 count = 7;
   */
  count = protoInt64.zero;

  /**
   * @generated from field: bool archived = 8;
   */
  archived = false;

  constructor(data?: PartialMessage<CWSetGroupMinimal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWSetGroupMinimal";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "set_group_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "message", T: StringValue },
    { no: 4, name: "sets", kind: "message", T: CWSetMinimal, repeated: true },
    { no: 5, name: "market", kind: "message", T: CWMarket },
    { no: 6, name: "industry", kind: "message", T: CWIndustryPb },
    { no: 7, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWSetGroupMinimal {
    return new CWSetGroupMinimal().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWSetGroupMinimal {
    return new CWSetGroupMinimal().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWSetGroupMinimal {
    return new CWSetGroupMinimal().fromJsonString(jsonString, options);
  }

  static equals(a: CWSetGroupMinimal | PlainMessage<CWSetGroupMinimal> | undefined, b: CWSetGroupMinimal | PlainMessage<CWSetGroupMinimal> | undefined): boolean {
    return proto3.util.equals(CWSetGroupMinimal, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWSetsMinimal
 */
export class CWSetsMinimal extends Message<CWSetsMinimal> {
  /**
   * @generated from field: repeated librarian.proto.CWSetMinimal sets = 1;
   */
  sets: CWSetMinimal[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<CWSetsMinimal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWSetsMinimal";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "sets", kind: "message", T: CWSetMinimal, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWSetsMinimal {
    return new CWSetsMinimal().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWSetsMinimal {
    return new CWSetsMinimal().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWSetsMinimal {
    return new CWSetsMinimal().fromJsonString(jsonString, options);
  }

  static equals(a: CWSetsMinimal | PlainMessage<CWSetsMinimal> | undefined, b: CWSetsMinimal | PlainMessage<CWSetsMinimal> | undefined): boolean {
    return proto3.util.equals(CWSetsMinimal, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWSetMinimal
 */
export class CWSetMinimal extends Message<CWSetMinimal> {
  /**
   * @generated from field: int64 set_id = 1;
   */
  setId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string description = 3;
   */
  description = "";

  /**
   * @generated from field: repeated common.proto.Network networks = 4;
   */
  networks: Network[] = [];

  /**
   * @generated from field: librarian.proto.CWMarket market = 5;
   */
  market?: CWMarket;

  /**
   * @generated from field: librarian.proto.CWIndustryPb industry = 6;
   */
  industry?: CWIndustryPb;

  /**
   * @generated from field: int64 count = 7;
   */
  count = protoInt64.zero;

  /**
   * @generated from field: bool archived = 8;
   */
  archived = false;

  constructor(data?: PartialMessage<CWSetMinimal>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWSetMinimal";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "set_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 5, name: "market", kind: "message", T: CWMarket },
    { no: 6, name: "industry", kind: "message", T: CWIndustryPb },
    { no: 7, name: "count", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 8, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWSetMinimal {
    return new CWSetMinimal().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWSetMinimal {
    return new CWSetMinimal().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWSetMinimal {
    return new CWSetMinimal().fromJsonString(jsonString, options);
  }

  static equals(a: CWSetMinimal | PlainMessage<CWSetMinimal> | undefined, b: CWSetMinimal | PlainMessage<CWSetMinimal> | undefined): boolean {
    return proto3.util.equals(CWSetMinimal, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWDirectorySets
 */
export class CWDirectorySets extends Message<CWDirectorySets> {
  /**
   * @generated from field: repeated librarian.proto.CWDirectorySet directory_sets = 1;
   */
  directorySets: CWDirectorySet[] = [];

  constructor(data?: PartialMessage<CWDirectorySets>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWDirectorySets";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "directory_sets", kind: "message", T: CWDirectorySet, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWDirectorySets {
    return new CWDirectorySets().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWDirectorySets {
    return new CWDirectorySets().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWDirectorySets {
    return new CWDirectorySets().fromJsonString(jsonString, options);
  }

  static equals(a: CWDirectorySets | PlainMessage<CWDirectorySets> | undefined, b: CWDirectorySets | PlainMessage<CWDirectorySets> | undefined): boolean {
    return proto3.util.equals(CWDirectorySets, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWDirectorySet
 */
export class CWDirectorySet extends Message<CWDirectorySet> {
  /**
   * @generated from field: string set_name = 1;
   */
  setName = "";

  /**
   * not all creators will be returned, only few for display
   *
   * @generated from field: repeated librarian.proto.CreatorTiniestCard creators = 2;
   */
  creators: CreatorTiniestCard[] = [];

  /**
   * @generated from field: int32 total_hits = 3;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<CWDirectorySet>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWDirectorySet";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "set_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "creators", kind: "message", T: CreatorTiniestCard, repeated: true },
    { no: 3, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWDirectorySet {
    return new CWDirectorySet().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWDirectorySet {
    return new CWDirectorySet().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWDirectorySet {
    return new CWDirectorySet().fromJsonString(jsonString, options);
  }

  static equals(a: CWDirectorySet | PlainMessage<CWDirectorySet> | undefined, b: CWDirectorySet | PlainMessage<CWDirectorySet> | undefined): boolean {
    return proto3.util.equals(CWDirectorySet, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWGetBrandsRequest
 */
export class CWGetBrandsRequest extends Message<CWGetBrandsRequest> {
  /**
   * @generated from field: common.proto.BasicPagination pagination = 1;
   */
  pagination?: BasicPagination;

  /**
   * @generated from field: string name_prefix = 2;
   */
  namePrefix = "";

  /**
   * if true, return brands to use to create custom reports, else return used brands in current reports
   *
   * @generated from field: bool for_custom_reports = 3;
   */
  forCustomReports = false;

  constructor(data?: PartialMessage<CWGetBrandsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWGetBrandsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: BasicPagination },
    { no: 2, name: "name_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "for_custom_reports", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWGetBrandsRequest {
    return new CWGetBrandsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWGetBrandsRequest {
    return new CWGetBrandsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWGetBrandsRequest {
    return new CWGetBrandsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWGetBrandsRequest | PlainMessage<CWGetBrandsRequest> | undefined, b: CWGetBrandsRequest | PlainMessage<CWGetBrandsRequest> | undefined): boolean {
    return proto3.util.equals(CWGetBrandsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWDirectorySetRequest
 */
export class CWDirectorySetRequest extends Message<CWDirectorySetRequest> {
  /**
   * if empty, the created set will not be reusable by user
   *
   * @generated from field: google.protobuf.StringValue set_name = 1;
   */
  setName?: string;

  /**
   * @generated from field: repeated common.proto.MetaIdPb meta_ids = 2;
   */
  metaIds: MetaIdPb[] = [];

  constructor(data?: PartialMessage<CWDirectorySetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWDirectorySetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "set_name", kind: "message", T: StringValue },
    { no: 2, name: "meta_ids", kind: "message", T: MetaIdPb, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWDirectorySetRequest {
    return new CWDirectorySetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWDirectorySetRequest {
    return new CWDirectorySetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWDirectorySetRequest {
    return new CWDirectorySetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWDirectorySetRequest | PlainMessage<CWDirectorySetRequest> | undefined, b: CWDirectorySetRequest | PlainMessage<CWDirectorySetRequest> | undefined): boolean {
    return proto3.util.equals(CWDirectorySetRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWGetDirectorySetsRequest
 */
export class CWGetDirectorySetsRequest extends Message<CWGetDirectorySetsRequest> {
  /**
   * @generated from field: common.proto.BasicPagination pagination = 1;
   */
  pagination?: BasicPagination;

  /**
   * @generated from field: string name_prefix = 2;
   */
  namePrefix = "";

  constructor(data?: PartialMessage<CWGetDirectorySetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWGetDirectorySetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: BasicPagination },
    { no: 2, name: "name_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWGetDirectorySetsRequest {
    return new CWGetDirectorySetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWGetDirectorySetsRequest {
    return new CWGetDirectorySetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWGetDirectorySetsRequest {
    return new CWGetDirectorySetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWGetDirectorySetsRequest | PlainMessage<CWGetDirectorySetsRequest> | undefined, b: CWGetDirectorySetsRequest | PlainMessage<CWGetDirectorySetsRequest> | undefined): boolean {
    return proto3.util.equals(CWGetDirectorySetsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWGetPredefinedSetsRequest
 */
export class CWGetPredefinedSetsRequest extends Message<CWGetPredefinedSetsRequest> {
  /**
   * @generated from field: common.proto.BasicPagination pagination = 1;
   */
  pagination?: BasicPagination;

  /**
   * @generated from field: string name_prefix = 2;
   */
  namePrefix = "";

  /**
   * @generated from field: repeated int64 market_ids = 3;
   */
  marketIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 industry_ids = 4;
   */
  industryIds: bigint[] = [];

  constructor(data?: PartialMessage<CWGetPredefinedSetsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWGetPredefinedSetsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: BasicPagination },
    { no: 2, name: "name_prefix", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "market_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "industry_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWGetPredefinedSetsRequest {
    return new CWGetPredefinedSetsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWGetPredefinedSetsRequest {
    return new CWGetPredefinedSetsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWGetPredefinedSetsRequest {
    return new CWGetPredefinedSetsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWGetPredefinedSetsRequest | PlainMessage<CWGetPredefinedSetsRequest> | undefined, b: CWGetPredefinedSetsRequest | PlainMessage<CWGetPredefinedSetsRequest> | undefined): boolean {
    return proto3.util.equals(CWGetPredefinedSetsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.CWCreateOrEditReportRequest
 */
export class CWCreateOrEditReportRequest extends Message<CWCreateOrEditReportRequest> {
  /**
   * if this is present then it is an edit request otherwise it is a create request
   *
   * @generated from field: google.protobuf.Int64Value report_id = 1;
   */
  reportId?: bigint;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: repeated common.proto.Network networks = 3;
   */
  networks: Network[] = [];

  /**
   * @generated from field: librarian.proto.TimeRestriction time_restriction = 4;
   */
  timeRestriction?: TimeRestriction;

  /**
   * @generated from field: int64 reference_brand = 5;
   */
  referenceBrand = protoInt64.zero;

  /**
   * for edit, if not set, it will clear value if any
   *
   * @generated from field: google.protobuf.Int32Value min_followers = 6;
   */
  minFollowers?: number;

  /**
   * @generated from field: repeated int64 set_ids = 7;
   */
  setIds: bigint[] = [];

  /**
   * sets to be created for single profiles, influencers will be tracked for all report networks
   *
   * @generated from field: repeated librarian.proto.CWDirectorySetRequest directory_sets = 8;
   */
  directorySets: CWDirectorySetRequest[] = [];

  /**
   * @generated from field: repeated int64 brand_competitors = 9;
   */
  brandCompetitors: bigint[] = [];

  constructor(data?: PartialMessage<CWCreateOrEditReportRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CWCreateOrEditReportRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_id", kind: "message", T: Int64Value },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 4, name: "time_restriction", kind: "message", T: TimeRestriction },
    { no: 5, name: "reference_brand", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "min_followers", kind: "message", T: Int32Value },
    { no: 7, name: "set_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 8, name: "directory_sets", kind: "message", T: CWDirectorySetRequest, repeated: true },
    { no: 9, name: "brand_competitors", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CWCreateOrEditReportRequest {
    return new CWCreateOrEditReportRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CWCreateOrEditReportRequest {
    return new CWCreateOrEditReportRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CWCreateOrEditReportRequest {
    return new CWCreateOrEditReportRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CWCreateOrEditReportRequest | PlainMessage<CWCreateOrEditReportRequest> | undefined, b: CWCreateOrEditReportRequest | PlainMessage<CWCreateOrEditReportRequest> | undefined): boolean {
    return proto3.util.equals(CWCreateOrEditReportRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.Summary
 */
export class Summary extends Message<Summary> {
  /**
   * @generated from field: int64 emv = 8;
   */
  emv = protoInt64.zero;

  /**
   * @generated from field: int64 impressions = 7;
   */
  impressions = protoInt64.zero;

  /**
   * @generated from field: int32 post_count = 2;
   */
  postCount = 0;

  /**
   * @generated from field: int32 profile_count = 3;
   */
  profileCount = 0;

  /**
   * number of posts with a unique mention
   *
   * @generated from field: int32 unique_count = 5;
   */
  uniqueCount = 0;

  /**
   * @generated from field: int32 ambassadors_count = 6;
   */
  ambassadorsCount = 0;

  constructor(data?: PartialMessage<Summary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Summary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 8, name: "emv", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 7, name: "impressions", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "post_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "profile_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "unique_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "ambassadors_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Summary {
    return new Summary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Summary {
    return new Summary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Summary {
    return new Summary().fromJsonString(jsonString, options);
  }

  static equals(a: Summary | PlainMessage<Summary> | undefined, b: Summary | PlainMessage<Summary> | undefined): boolean {
    return proto3.util.equals(Summary, a, b);
  }
}

/**
 * @generated from message librarian.proto.ReportsResponse
 */
export class ReportsResponse extends Message<ReportsResponse> {
  /**
   * @generated from field: repeated librarian.proto.ReportSummary summary = 1;
   */
  summary: ReportSummary[] = [];

  constructor(data?: PartialMessage<ReportsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ReportsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "summary", kind: "message", T: ReportSummary, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportsResponse {
    return new ReportsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportsResponse {
    return new ReportsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportsResponse {
    return new ReportsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ReportsResponse | PlainMessage<ReportsResponse> | undefined, b: ReportsResponse | PlainMessage<ReportsResponse> | undefined): boolean {
    return proto3.util.equals(ReportsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.ReportDetails
 */
export class ReportDetails extends Message<ReportDetails> {
  /**
   * @generated from field: int64 report_id = 1;
   */
  reportId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: librarian.proto.Brand main_brand = 3 [deprecated = true];
   * @deprecated
   */
  mainBrand?: Brand;

  /**
   * @generated from field: repeated librarian.proto.Brand brands = 7 [deprecated = true];
   * @deprecated
   */
  brands: Brand[] = [];

  /**
   * @generated from field: librarian.proto.CWBrandPb reference_brand = 10;
   */
  referenceBrand?: CWBrandPb;

  /**
   * @generated from field: repeated librarian.proto.CWBrandPb brand_competitors = 11;
   */
  brandCompetitors: CWBrandPb[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp start = 4;
   */
  start?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end = 5;
   */
  end?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp expiry_date = 9;
   */
  expiryDate?: Timestamp;

  /**
   * @generated from field: bool has_influencers_categories = 8;
   */
  hasInfluencersCategories = false;

  /**
   * @generated from field: google.protobuf.Int32Value min_followers = 12;
   */
  minFollowers?: number;

  /**
   * @generated from field: repeated librarian.proto.CWSetMinimal sets = 13;
   */
  sets: CWSetMinimal[] = [];

  /**
   * can be not set or empty because legacy sets don't have market
   *
   * @generated from field: repeated librarian.proto.CWMarket markets = 14;
   */
  markets: CWMarket[] = [];

  /**
   * @generated from field: common.proto.Network network = 100 [deprecated = true];
   * @deprecated
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: repeated common.proto.Network networks = 101;
   */
  networks: Network[] = [];

  constructor(data?: PartialMessage<ReportDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ReportDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "main_brand", kind: "message", T: Brand },
    { no: 7, name: "brands", kind: "message", T: Brand, repeated: true },
    { no: 10, name: "reference_brand", kind: "message", T: CWBrandPb },
    { no: 11, name: "brand_competitors", kind: "message", T: CWBrandPb, repeated: true },
    { no: 4, name: "start", kind: "message", T: Timestamp },
    { no: 5, name: "end", kind: "message", T: Timestamp },
    { no: 9, name: "expiry_date", kind: "message", T: Timestamp },
    { no: 8, name: "has_influencers_categories", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 12, name: "min_followers", kind: "message", T: Int32Value },
    { no: 13, name: "sets", kind: "message", T: CWSetMinimal, repeated: true },
    { no: 14, name: "markets", kind: "message", T: CWMarket, repeated: true },
    { no: 100, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 101, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportDetails {
    return new ReportDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportDetails {
    return new ReportDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportDetails {
    return new ReportDetails().fromJsonString(jsonString, options);
  }

  static equals(a: ReportDetails | PlainMessage<ReportDetails> | undefined, b: ReportDetails | PlainMessage<ReportDetails> | undefined): boolean {
    return proto3.util.equals(ReportDetails, a, b);
  }
}

/**
 * @generated from message librarian.proto.ReportSummary
 */
export class ReportSummary extends Message<ReportSummary> {
  /**
   * @generated from field: int64 report_id = 1;
   */
  reportId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: librarian.proto.Brand main_brand = 3 [deprecated = true];
   * @deprecated
   */
  mainBrand?: Brand;

  /**
   * @generated from field: librarian.proto.CWBrandPb reference_brand = 11;
   */
  referenceBrand?: CWBrandPb;

  /**
   * @generated from field: repeated librarian.proto.CWBrandPb brand_competitors = 12;
   */
  brandCompetitors: CWBrandPb[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp start = 4;
   */
  start?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end = 5;
   */
  end?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp expiry_date = 7;
   */
  expiryDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp creation_date = 8;
   */
  creationDate?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp last_refresh_date = 13;
   */
  lastRefreshDate?: Timestamp;

  /**
   * @generated from field: librarian.proto.Summary summary = 6;
   */
  summary?: Summary;

  /**
   * true if it was created by Lefty team
   *
   * @generated from field: bool from_lefty = 9;
   */
  fromLefty = false;

  /**
   * @generated from field: bool archived = 10;
   */
  archived = false;

  /**
   * @generated from field: common.proto.Network network = 100 [deprecated = true];
   * @deprecated
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: repeated common.proto.Network networks = 101;
   */
  networks: Network[] = [];

  constructor(data?: PartialMessage<ReportSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ReportSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "main_brand", kind: "message", T: Brand },
    { no: 11, name: "reference_brand", kind: "message", T: CWBrandPb },
    { no: 12, name: "brand_competitors", kind: "message", T: CWBrandPb, repeated: true },
    { no: 4, name: "start", kind: "message", T: Timestamp },
    { no: 5, name: "end", kind: "message", T: Timestamp },
    { no: 7, name: "expiry_date", kind: "message", T: Timestamp },
    { no: 8, name: "creation_date", kind: "message", T: Timestamp },
    { no: 13, name: "last_refresh_date", kind: "message", T: Timestamp },
    { no: 6, name: "summary", kind: "message", T: Summary },
    { no: 9, name: "from_lefty", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "archived", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 100, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 101, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportSummary {
    return new ReportSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportSummary {
    return new ReportSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportSummary {
    return new ReportSummary().fromJsonString(jsonString, options);
  }

  static equals(a: ReportSummary | PlainMessage<ReportSummary> | undefined, b: ReportSummary | PlainMessage<ReportSummary> | undefined): boolean {
    return proto3.util.equals(ReportSummary, a, b);
  }
}

/**
 * @generated from message librarian.proto.ReportDetailsRequest
 */
export class ReportDetailsRequest extends Message<ReportDetailsRequest> {
  /**
   * @generated from field: int64 report_id = 1;
   */
  reportId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 brands_markets_ids = 9 [deprecated = true];
   * @deprecated
   */
  brandsMarketsIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 brand_ids = 15;
   */
  brandIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 market_ids = 12;
   */
  marketIds: bigint[] = [];

  /**
   * @generated from field: repeated int64 industry_ids = 13;
   */
  industryIds: bigint[] = [];

  /**
   * @generated from field: repeated common.proto.Network networks = 10;
   */
  networks: Network[] = [];

  /**
   * @generated from field: google.protobuf.Timestamp start = 2;
   */
  start?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp end = 3;
   */
  end?: Timestamp;

  /**
   * @generated from field: librarian.proto.ReportType report_type = 4;
   */
  reportType = ReportType.REPORT_UNDEFINED;

  /**
   * @generated from field: librarian.proto.SummarySplitType split_type = 14;
   */
  splitType = SummarySplitType.UNDEFINED;

  /**
   * @generated from field: repeated common.proto.CampaignKeyword keywords = 5 [deprecated = true];
   * @deprecated
   */
  keywords: CampaignKeyword[] = [];

  /**
   * @generated from field: librarian.proto.GenerikKeywordsFilter keywords_filter = 11;
   */
  keywordsFilter?: GenerikKeywordsFilter;

  /**
   * @generated from field: repeated common.proto.InfluencerKind influencers_kinds = 7;
   */
  influencersKinds: InfluencerKind[] = [];

  /**
   * @generated from field: google.protobuf.BoolValue sponsored_posts = 8;
   */
  sponsoredPosts?: boolean;

  /**
   * Filled by backend for RPC calls between librarian and archivist
   *
   * @generated from field: int64 user_id = 6;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<ReportDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ReportDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 9, name: "brands_markets_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 15, name: "brand_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 12, name: "market_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 13, name: "industry_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 10, name: "networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 2, name: "start", kind: "message", T: Timestamp },
    { no: 3, name: "end", kind: "message", T: Timestamp },
    { no: 4, name: "report_type", kind: "enum", T: proto3.getEnumType(ReportType) },
    { no: 14, name: "split_type", kind: "enum", T: proto3.getEnumType(SummarySplitType) },
    { no: 5, name: "keywords", kind: "message", T: CampaignKeyword, repeated: true },
    { no: 11, name: "keywords_filter", kind: "message", T: GenerikKeywordsFilter },
    { no: 7, name: "influencers_kinds", kind: "enum", T: proto3.getEnumType(InfluencerKind), repeated: true },
    { no: 8, name: "sponsored_posts", kind: "message", T: BoolValue },
    { no: 6, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportDetailsRequest {
    return new ReportDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportDetailsRequest {
    return new ReportDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportDetailsRequest {
    return new ReportDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ReportDetailsRequest | PlainMessage<ReportDetailsRequest> | undefined, b: ReportDetailsRequest | PlainMessage<ReportDetailsRequest> | undefined): boolean {
    return proto3.util.equals(ReportDetailsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.Brand
 */
export class Brand extends Message<Brand> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: int64 brand_market_id = 2;
   */
  brandMarketId = protoInt64.zero;

  constructor(data?: PartialMessage<Brand>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Brand";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "brand_market_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Brand {
    return new Brand().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Brand {
    return new Brand().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Brand {
    return new Brand().fromJsonString(jsonString, options);
  }

  static equals(a: Brand | PlainMessage<Brand> | undefined, b: Brand | PlainMessage<Brand> | undefined): boolean {
    return proto3.util.equals(Brand, a, b);
  }
}

/**
 * @generated from message librarian.proto.SummaryAndType
 */
export class SummaryAndType extends Message<SummaryAndType> {
  /**
   * @generated from field: librarian.proto.SummaryType summary_type = 1;
   */
  summaryType = SummaryType.TOTAL;

  /**
   * @generated from field: librarian.proto.Summary summary = 2;
   */
  summary?: Summary;

  constructor(data?: PartialMessage<SummaryAndType>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SummaryAndType";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "summary_type", kind: "enum", T: proto3.getEnumType(SummaryType) },
    { no: 2, name: "summary", kind: "message", T: Summary },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SummaryAndType {
    return new SummaryAndType().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SummaryAndType {
    return new SummaryAndType().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SummaryAndType {
    return new SummaryAndType().fromJsonString(jsonString, options);
  }

  static equals(a: SummaryAndType | PlainMessage<SummaryAndType> | undefined, b: SummaryAndType | PlainMessage<SummaryAndType> | undefined): boolean {
    return proto3.util.equals(SummaryAndType, a, b);
  }
}

/**
 * @generated from message librarian.proto.SummaryAndNetwork
 */
export class SummaryAndNetwork extends Message<SummaryAndNetwork> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: librarian.proto.Summary summary = 2;
   */
  summary?: Summary;

  constructor(data?: PartialMessage<SummaryAndNetwork>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SummaryAndNetwork";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "summary", kind: "message", T: Summary },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SummaryAndNetwork {
    return new SummaryAndNetwork().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SummaryAndNetwork {
    return new SummaryAndNetwork().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SummaryAndNetwork {
    return new SummaryAndNetwork().fromJsonString(jsonString, options);
  }

  static equals(a: SummaryAndNetwork | PlainMessage<SummaryAndNetwork> | undefined, b: SummaryAndNetwork | PlainMessage<SummaryAndNetwork> | undefined): boolean {
    return proto3.util.equals(SummaryAndNetwork, a, b);
  }
}

/**
 * @generated from message librarian.proto.BrandSummary
 */
export class BrandSummary extends Message<BrandSummary> {
  /**
   * @generated from field: librarian.proto.Brand brand = 1 [deprecated = true];
   * @deprecated
   */
  brand?: Brand;

  /**
   * @generated from field: librarian.proto.CWBrandPb report_brand = 4;
   */
  reportBrand?: CWBrandPb;

  /**
   * @generated from field: repeated librarian.proto.SummaryAndType summary_and_type = 2 [deprecated = true];
   * @deprecated
   */
  summaryAndType: SummaryAndType[] = [];

  /**
   * @generated from field: librarian.proto.SummarySplit summary_split = 3;
   */
  summarySplit?: SummarySplit;

  constructor(data?: PartialMessage<BrandSummary>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.BrandSummary";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brand", kind: "message", T: Brand },
    { no: 4, name: "report_brand", kind: "message", T: CWBrandPb },
    { no: 2, name: "summary_and_type", kind: "message", T: SummaryAndType, repeated: true },
    { no: 3, name: "summary_split", kind: "message", T: SummarySplit },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BrandSummary {
    return new BrandSummary().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BrandSummary {
    return new BrandSummary().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BrandSummary {
    return new BrandSummary().fromJsonString(jsonString, options);
  }

  static equals(a: BrandSummary | PlainMessage<BrandSummary> | undefined, b: BrandSummary | PlainMessage<BrandSummary> | undefined): boolean {
    return proto3.util.equals(BrandSummary, a, b);
  }
}

/**
 * @generated from message librarian.proto.SummarySplit
 */
export class SummarySplit extends Message<SummarySplit> {
  /**
   * @generated from field: repeated librarian.proto.SummaryAndType summary_and_type = 2;
   */
  summaryAndType: SummaryAndType[] = [];

  /**
   * @generated from field: repeated librarian.proto.SummaryAndNetwork summary_and_network = 3;
   */
  summaryAndNetwork: SummaryAndNetwork[] = [];

  constructor(data?: PartialMessage<SummarySplit>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SummarySplit";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "summary_and_type", kind: "message", T: SummaryAndType, repeated: true },
    { no: 3, name: "summary_and_network", kind: "message", T: SummaryAndNetwork, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SummarySplit {
    return new SummarySplit().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SummarySplit {
    return new SummarySplit().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SummarySplit {
    return new SummarySplit().fromJsonString(jsonString, options);
  }

  static equals(a: SummarySplit | PlainMessage<SummarySplit> | undefined, b: SummarySplit | PlainMessage<SummarySplit> | undefined): boolean {
    return proto3.util.equals(SummarySplit, a, b);
  }
}

/**
 * @generated from message librarian.proto.ReportDetailResponse
 */
export class ReportDetailResponse extends Message<ReportDetailResponse> {
  /**
   * @generated from field: repeated librarian.proto.BrandSummary brand_summary = 1;
   */
  brandSummary: BrandSummary[] = [];

  /**
   * @generated from field: repeated librarian.proto.SummaryAndType total_summary = 2 [deprecated = true];
   * @deprecated
   */
  totalSummary: SummaryAndType[] = [];

  /**
   * @generated from field: librarian.proto.SummarySplit report_summary = 3;
   */
  reportSummary?: SummarySplit;

  constructor(data?: PartialMessage<ReportDetailResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ReportDetailResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brand_summary", kind: "message", T: BrandSummary, repeated: true },
    { no: 2, name: "total_summary", kind: "message", T: SummaryAndType, repeated: true },
    { no: 3, name: "report_summary", kind: "message", T: SummarySplit },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportDetailResponse {
    return new ReportDetailResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportDetailResponse {
    return new ReportDetailResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportDetailResponse {
    return new ReportDetailResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ReportDetailResponse | PlainMessage<ReportDetailResponse> | undefined, b: ReportDetailResponse | PlainMessage<ReportDetailResponse> | undefined): boolean {
    return proto3.util.equals(ReportDetailResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.ReportBrandsSummariesByTypeResponse
 */
export class ReportBrandsSummariesByTypeResponse extends Message<ReportBrandsSummariesByTypeResponse> {
  /**
   * @generated from field: repeated librarian.proto.BrandSummary brand_summary = 1;
   */
  brandSummary: BrandSummary[] = [];

  constructor(data?: PartialMessage<ReportBrandsSummariesByTypeResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ReportBrandsSummariesByTypeResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brand_summary", kind: "message", T: BrandSummary, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportBrandsSummariesByTypeResponse {
    return new ReportBrandsSummariesByTypeResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportBrandsSummariesByTypeResponse {
    return new ReportBrandsSummariesByTypeResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportBrandsSummariesByTypeResponse {
    return new ReportBrandsSummariesByTypeResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ReportBrandsSummariesByTypeResponse | PlainMessage<ReportBrandsSummariesByTypeResponse> | undefined, b: ReportBrandsSummariesByTypeResponse | PlainMessage<ReportBrandsSummariesByTypeResponse> | undefined): boolean {
    return proto3.util.equals(ReportBrandsSummariesByTypeResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.PostsRequest
 */
export class PostsRequest extends Message<PostsRequest> {
  /**
   * @generated from field: librarian.proto.CWBrandDetailsRequest request = 1;
   */
  request?: CWBrandDetailsRequest;

  /**
   * @generated from field: librarian.proto.VisualSort sort = 2;
   */
  sort = VisualSort.NO_SORT;

  /**
   * @generated from field: common.proto.BasicPagination pagination = 3;
   */
  pagination?: BasicPagination;

  /**
   * @generated from field: repeated common.proto.PostType post_types = 4;
   */
  postTypes: PostType[] = [];

  constructor(data?: PartialMessage<PostsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.PostsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "request", kind: "message", T: CWBrandDetailsRequest },
    { no: 2, name: "sort", kind: "enum", T: proto3.getEnumType(VisualSort) },
    { no: 3, name: "pagination", kind: "message", T: BasicPagination },
    { no: 4, name: "post_types", kind: "enum", T: proto3.getEnumType(PostType), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostsRequest {
    return new PostsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostsRequest {
    return new PostsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostsRequest {
    return new PostsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PostsRequest | PlainMessage<PostsRequest> | undefined, b: PostsRequest | PlainMessage<PostsRequest> | undefined): boolean {
    return proto3.util.equals(PostsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.BrandInfluencer
 */
export class BrandInfluencer extends Message<BrandInfluencer> {
  /**
   * @generated from field: librarian.proto.CreatorCardSnippet base_snippet = 1;
   */
  baseSnippet?: CreatorCardSnippet;

  /**
   * @generated from field: librarian.proto.GenerikInfoCard all_networks_info = 4;
   */
  allNetworksInfo?: GenerikInfoCard;

  /**
   * @generated from field: repeated librarian.proto.GenerikInfoCard network_info = 2;
   */
  networkInfo: GenerikInfoCard[] = [];

  /**
   * @generated from field: bool in_pool = 3;
   */
  inPool = false;

  /**
   * @generated from field: librarian.proto.CreatorRegisteredDetails registered_details = 100;
   */
  registeredDetails?: CreatorRegisteredDetails;

  constructor(data?: PartialMessage<BrandInfluencer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.BrandInfluencer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "base_snippet", kind: "message", T: CreatorCardSnippet },
    { no: 4, name: "all_networks_info", kind: "message", T: GenerikInfoCard },
    { no: 2, name: "network_info", kind: "message", T: GenerikInfoCard, repeated: true },
    { no: 3, name: "in_pool", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 100, name: "registered_details", kind: "message", T: CreatorRegisteredDetails },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BrandInfluencer {
    return new BrandInfluencer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BrandInfluencer {
    return new BrandInfluencer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BrandInfluencer {
    return new BrandInfluencer().fromJsonString(jsonString, options);
  }

  static equals(a: BrandInfluencer | PlainMessage<BrandInfluencer> | undefined, b: BrandInfluencer | PlainMessage<BrandInfluencer> | undefined): boolean {
    return proto3.util.equals(BrandInfluencer, a, b);
  }
}

/**
 * @generated from message librarian.proto.BrandInfluencersResponse
 */
export class BrandInfluencersResponse extends Message<BrandInfluencersResponse> {
  /**
   * @generated from field: repeated librarian.proto.BrandInfluencer influencers = 1;
   */
  influencers: BrandInfluencer[] = [];

  constructor(data?: PartialMessage<BrandInfluencersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.BrandInfluencersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencers", kind: "message", T: BrandInfluencer, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BrandInfluencersResponse {
    return new BrandInfluencersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BrandInfluencersResponse {
    return new BrandInfluencersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BrandInfluencersResponse {
    return new BrandInfluencersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BrandInfluencersResponse | PlainMessage<BrandInfluencersResponse> | undefined, b: BrandInfluencersResponse | PlainMessage<BrandInfluencersResponse> | undefined): boolean {
    return proto3.util.equals(BrandInfluencersResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.InfluencersResponse
 */
export class InfluencersResponse extends Message<InfluencersResponse> {
  /**
   * @generated from field: repeated librarian.proto.CreatorCWCardSnippet influencers = 1;
   */
  influencers: CreatorCWCardSnippet[] = [];

  constructor(data?: PartialMessage<InfluencersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InfluencersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "influencers", kind: "message", T: CreatorCWCardSnippet, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencersResponse {
    return new InfluencersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencersResponse {
    return new InfluencersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencersResponse {
    return new InfluencersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencersResponse | PlainMessage<InfluencersResponse> | undefined, b: InfluencersResponse | PlainMessage<InfluencersResponse> | undefined): boolean {
    return proto3.util.equals(InfluencersResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.MonthlyEmvResponse
 */
export class MonthlyEmvResponse extends Message<MonthlyEmvResponse> {
  /**
   * @generated from field: repeated common.proto.Timeseries emvs = 1;
   */
  emvs: Timeseries[] = [];

  constructor(data?: PartialMessage<MonthlyEmvResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.MonthlyEmvResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "emvs", kind: "message", T: Timeseries, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MonthlyEmvResponse {
    return new MonthlyEmvResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MonthlyEmvResponse {
    return new MonthlyEmvResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MonthlyEmvResponse {
    return new MonthlyEmvResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MonthlyEmvResponse | PlainMessage<MonthlyEmvResponse> | undefined, b: MonthlyEmvResponse | PlainMessage<MonthlyEmvResponse> | undefined): boolean {
    return proto3.util.equals(MonthlyEmvResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.ReportStatsResponse
 */
export class ReportStatsResponse extends Message<ReportStatsResponse> {
  /**
   * @generated from field: repeated librarian.proto.SummaryAndType summaries = 1 [deprecated = true];
   * @deprecated
   */
  summaries: SummaryAndType[] = [];

  /**
   * @generated from field: librarian.proto.SummarySplit summary_split = 4;
   */
  summarySplit?: SummarySplit;

  /**
   * @generated from field: repeated common.proto.StringCount top_mentions = 2;
   */
  topMentions: StringCount[] = [];

  /**
   * @generated from field: repeated common.proto.StringCount top_tags = 3;
   */
  topTags: StringCount[] = [];

  constructor(data?: PartialMessage<ReportStatsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ReportStatsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "summaries", kind: "message", T: SummaryAndType, repeated: true },
    { no: 4, name: "summary_split", kind: "message", T: SummarySplit },
    { no: 2, name: "top_mentions", kind: "message", T: StringCount, repeated: true },
    { no: 3, name: "top_tags", kind: "message", T: StringCount, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ReportStatsResponse {
    return new ReportStatsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ReportStatsResponse {
    return new ReportStatsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ReportStatsResponse {
    return new ReportStatsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ReportStatsResponse | PlainMessage<ReportStatsResponse> | undefined, b: ReportStatsResponse | PlainMessage<ReportStatsResponse> | undefined): boolean {
    return proto3.util.equals(ReportStatsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.InfluencersTier
 */
export class InfluencersTier extends Message<InfluencersTier> {
  /**
   * @generated from field: librarian.proto.SummaryType summary_type = 1;
   */
  summaryType = SummaryType.TOTAL;

  /**
   * @generated from field: int32 min = 2;
   */
  min = 0;

  constructor(data?: PartialMessage<InfluencersTier>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.InfluencersTier";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "summary_type", kind: "enum", T: proto3.getEnumType(SummaryType) },
    { no: 2, name: "min", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencersTier {
    return new InfluencersTier().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencersTier {
    return new InfluencersTier().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencersTier {
    return new InfluencersTier().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencersTier | PlainMessage<InfluencersTier> | undefined, b: InfluencersTier | PlainMessage<InfluencersTier> | undefined): boolean {
    return proto3.util.equals(InfluencersTier, a, b);
  }
}

/**
 * This is only used for the exported reports
 *
 * @generated from message librarian.proto.FullReport
 */
export class FullReport extends Message<FullReport> {
  /**
   * @generated from field: librarian.proto.ReportDetails report_details = 1;
   */
  reportDetails?: ReportDetails;

  /**
   * @generated from field: repeated librarian.proto.SummaryAndType total_report = 2 [deprecated = true];
   * @deprecated
   */
  totalReport: SummaryAndType[] = [];

  /**
   * @generated from field: librarian.proto.SummarySplit report_summary_split = 7;
   */
  reportSummarySplit?: SummarySplit;

  /**
   * @generated from field: repeated librarian.proto.FullReport.BrandFullReport brand_report = 3;
   */
  brandReport: FullReport_BrandFullReport[] = [];

  /**
   * @generated from field: repeated common.proto.Timeseries emvs = 4;
   */
  emvs: Timeseries[] = [];

  /**
   * @generated from field: repeated librarian.proto.InfluencersTier influencers_tiers = 5;
   */
  influencersTiers: InfluencersTier[] = [];

  /**
   * @generated from field: common.proto.CurrencyEnum currency = 6;
   */
  currency = CurrencyEnum.NA;

  constructor(data?: PartialMessage<FullReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.FullReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "report_details", kind: "message", T: ReportDetails },
    { no: 2, name: "total_report", kind: "message", T: SummaryAndType, repeated: true },
    { no: 7, name: "report_summary_split", kind: "message", T: SummarySplit },
    { no: 3, name: "brand_report", kind: "message", T: FullReport_BrandFullReport, repeated: true },
    { no: 4, name: "emvs", kind: "message", T: Timeseries, repeated: true },
    { no: 5, name: "influencers_tiers", kind: "message", T: InfluencersTier, repeated: true },
    { no: 6, name: "currency", kind: "enum", T: proto3.getEnumType(CurrencyEnum) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FullReport {
    return new FullReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FullReport {
    return new FullReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FullReport {
    return new FullReport().fromJsonString(jsonString, options);
  }

  static equals(a: FullReport | PlainMessage<FullReport> | undefined, b: FullReport | PlainMessage<FullReport> | undefined): boolean {
    return proto3.util.equals(FullReport, a, b);
  }
}

/**
 * @generated from message librarian.proto.FullReport.BrandFullReport
 */
export class FullReport_BrandFullReport extends Message<FullReport_BrandFullReport> {
  /**
   * @generated from field: librarian.proto.Brand brand = 1 [deprecated = true];
   * @deprecated
   */
  brand?: Brand;

  /**
   * @generated from field: librarian.proto.CWBrandPb report_brand = 18;
   */
  reportBrand?: CWBrandPb;

  /**
   * @generated from field: repeated librarian.proto.SummaryAndType summaries = 2 [deprecated = true];
   * @deprecated
   */
  summaries: SummaryAndType[] = [];

  /**
   * @generated from field: librarian.proto.SummarySplit summary_split = 17;
   */
  summarySplit?: SummarySplit;

  /**
   * @generated from field: repeated common.proto.StringCount top_mentions = 3;
   */
  topMentions: StringCount[] = [];

  /**
   * @generated from field: repeated common.proto.StringCount top_tags = 4;
   */
  topTags: StringCount[] = [];

  /**
   * @generated from field: repeated librarian.proto.CreatorCWCardSnippet top_by_emv = 5;
   */
  topByEmv: CreatorCWCardSnippet[] = [];

  /**
   * @generated from field: repeated librarian.proto.CreatorCWCardSnippet top_by_impressions = 15;
   */
  topByImpressions: CreatorCWCardSnippet[] = [];

  /**
   * @generated from field: repeated librarian.proto.CreatorCWCardSnippet top_by_posts = 6;
   */
  topByPosts: CreatorCWCardSnippet[] = [];

  /**
   * @generated from field: repeated librarian.proto.CreatorCWCardSnippet top_by_egmnt = 7;
   */
  topByEgmnt: CreatorCWCardSnippet[] = [];

  /**
   * @generated from field: repeated librarian.proto.CreatorCWCardSnippet top_by_size = 8;
   */
  topBySize: CreatorCWCardSnippet[] = [];

  /**
   * @generated from field: repeated librarian.proto.GenerikSnippet top_visuals_by_like = 11;
   */
  topVisualsByLike: GenerikSnippet[] = [];

  /**
   * @generated from field: repeated librarian.proto.GenerikSnippet top_visuals_by_egmt = 12;
   */
  topVisualsByEgmt: GenerikSnippet[] = [];

  /**
   * @generated from field: repeated librarian.proto.GenerikSnippet top_visuals_by_emv = 16;
   */
  topVisualsByEmv: GenerikSnippet[] = [];

  /**
   * @generated from field: float avg_engagement_rate = 13;
   */
  avgEngagementRate = 0;

  /**
   * @generated from field: int64 total_engagements = 19;
   */
  totalEngagements = protoInt64.zero;

  /**
   * @generated from field: int64 total_reach = 14;
   */
  totalReach = protoInt64.zero;

  constructor(data?: PartialMessage<FullReport_BrandFullReport>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.FullReport.BrandFullReport";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "brand", kind: "message", T: Brand },
    { no: 18, name: "report_brand", kind: "message", T: CWBrandPb },
    { no: 2, name: "summaries", kind: "message", T: SummaryAndType, repeated: true },
    { no: 17, name: "summary_split", kind: "message", T: SummarySplit },
    { no: 3, name: "top_mentions", kind: "message", T: StringCount, repeated: true },
    { no: 4, name: "top_tags", kind: "message", T: StringCount, repeated: true },
    { no: 5, name: "top_by_emv", kind: "message", T: CreatorCWCardSnippet, repeated: true },
    { no: 15, name: "top_by_impressions", kind: "message", T: CreatorCWCardSnippet, repeated: true },
    { no: 6, name: "top_by_posts", kind: "message", T: CreatorCWCardSnippet, repeated: true },
    { no: 7, name: "top_by_egmnt", kind: "message", T: CreatorCWCardSnippet, repeated: true },
    { no: 8, name: "top_by_size", kind: "message", T: CreatorCWCardSnippet, repeated: true },
    { no: 11, name: "top_visuals_by_like", kind: "message", T: GenerikSnippet, repeated: true },
    { no: 12, name: "top_visuals_by_egmt", kind: "message", T: GenerikSnippet, repeated: true },
    { no: 16, name: "top_visuals_by_emv", kind: "message", T: GenerikSnippet, repeated: true },
    { no: 13, name: "avg_engagement_rate", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 19, name: "total_engagements", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 14, name: "total_reach", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FullReport_BrandFullReport {
    return new FullReport_BrandFullReport().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FullReport_BrandFullReport {
    return new FullReport_BrandFullReport().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FullReport_BrandFullReport {
    return new FullReport_BrandFullReport().fromJsonString(jsonString, options);
  }

  static equals(a: FullReport_BrandFullReport | PlainMessage<FullReport_BrandFullReport> | undefined, b: FullReport_BrandFullReport | PlainMessage<FullReport_BrandFullReport> | undefined): boolean {
    return proto3.util.equals(FullReport_BrandFullReport, a, b);
  }
}

/**
 * @generated from message librarian.proto.LeftySourceRequest
 */
export class LeftySourceRequest extends Message<LeftySourceRequest> {
  /**
   * @generated from field: common.proto.StringList mentions = 1;
   */
  mentions?: StringList;

  /**
   * @generated from field: common.proto.StringList hashtags = 2;
   */
  hashtags?: StringList;

  /**
   * @generated from field: string language = 3;
   */
  language = "";

  /**
   * @generated from field: float language_percentage = 4;
   */
  languagePercentage = 0;

  /**
   * @generated from field: string location_id = 5;
   */
  locationId = "";

  /**
   * @generated from field: int32 min_followers = 6;
   */
  minFollowers = 0;

  constructor(data?: PartialMessage<LeftySourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LeftySourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "mentions", kind: "message", T: StringList },
    { no: 2, name: "hashtags", kind: "message", T: StringList },
    { no: 3, name: "language", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "language_percentage", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 5, name: "location_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "min_followers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LeftySourceRequest {
    return new LeftySourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LeftySourceRequest {
    return new LeftySourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LeftySourceRequest {
    return new LeftySourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LeftySourceRequest | PlainMessage<LeftySourceRequest> | undefined, b: LeftySourceRequest | PlainMessage<LeftySourceRequest> | undefined): boolean {
    return proto3.util.equals(LeftySourceRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.AuxiliarySourceRequest
 */
export class AuxiliarySourceRequest extends Message<AuxiliarySourceRequest> {
  /**
   * @generated from field: universal.arkansas.proto.SearchRequest searchRequest = 1;
   */
  searchRequest?: SearchRequest;

  constructor(data?: PartialMessage<AuxiliarySourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AuxiliarySourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "searchRequest", kind: "message", T: SearchRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuxiliarySourceRequest {
    return new AuxiliarySourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuxiliarySourceRequest {
    return new AuxiliarySourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuxiliarySourceRequest {
    return new AuxiliarySourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AuxiliarySourceRequest | PlainMessage<AuxiliarySourceRequest> | undefined, b: AuxiliarySourceRequest | PlainMessage<AuxiliarySourceRequest> | undefined): boolean {
    return proto3.util.equals(AuxiliarySourceRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.DirectorySourceRequest
 */
export class DirectorySourceRequest extends Message<DirectorySourceRequest> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<DirectorySourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DirectorySourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DirectorySourceRequest {
    return new DirectorySourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DirectorySourceRequest {
    return new DirectorySourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DirectorySourceRequest {
    return new DirectorySourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DirectorySourceRequest | PlainMessage<DirectorySourceRequest> | undefined, b: DirectorySourceRequest | PlainMessage<DirectorySourceRequest> | undefined): boolean {
    return proto3.util.equals(DirectorySourceRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.DiscoverSourceRequest
 */
export class DiscoverSourceRequest extends Message<DiscoverSourceRequest> {
  /**
   * @generated from field: librarian.proto.DiscoverRequest discoverRequest = 1;
   */
  discoverRequest?: DiscoverRequest;

  constructor(data?: PartialMessage<DiscoverSourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DiscoverSourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "discoverRequest", kind: "message", T: DiscoverRequest },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DiscoverSourceRequest {
    return new DiscoverSourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DiscoverSourceRequest {
    return new DiscoverSourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DiscoverSourceRequest {
    return new DiscoverSourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DiscoverSourceRequest | PlainMessage<DiscoverSourceRequest> | undefined, b: DiscoverSourceRequest | PlainMessage<DiscoverSourceRequest> | undefined): boolean {
    return proto3.util.equals(DiscoverSourceRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.ManualSourceRequest
 */
export class ManualSourceRequest extends Message<ManualSourceRequest> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: repeated string handles = 2;
   */
  handles: string[] = [];

  constructor(data?: PartialMessage<ManualSourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ManualSourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "handles", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ManualSourceRequest {
    return new ManualSourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ManualSourceRequest {
    return new ManualSourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ManualSourceRequest {
    return new ManualSourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ManualSourceRequest | PlainMessage<ManualSourceRequest> | undefined, b: ManualSourceRequest | PlainMessage<ManualSourceRequest> | undefined): boolean {
    return proto3.util.equals(ManualSourceRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.SourceRequest
 */
export class SourceRequest extends Message<SourceRequest> {
  /**
   * @generated from oneof librarian.proto.SourceRequest.request
   */
  request: {
    /**
     * deprecated but kept for compatibility as we store this as a blob in DB and is still used by front in admin
     *
     * @generated from field: librarian.proto.LeftySourceRequest lefty = 1;
     */
    value: LeftySourceRequest;
    case: "lefty";
  } | {
    /**
     * @generated from field: librarian.proto.AuxiliarySourceRequest auxiliary = 2;
     */
    value: AuxiliarySourceRequest;
    case: "auxiliary";
  } | {
    /**
     * @generated from field: librarian.proto.DirectorySourceRequest directory = 3;
     */
    value: DirectorySourceRequest;
    case: "directory";
  } | {
    /**
     * @generated from field: librarian.proto.DiscoverSourceRequest discover = 4;
     */
    value: DiscoverSourceRequest;
    case: "discover";
  } | {
    /**
     * @generated from field: librarian.proto.ManualSourceRequest manual = 5;
     */
    value: ManualSourceRequest;
    case: "manual";
  } | { case: undefined; value?: undefined } = { case: undefined };

  constructor(data?: PartialMessage<SourceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.SourceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lefty", kind: "message", T: LeftySourceRequest, oneof: "request" },
    { no: 2, name: "auxiliary", kind: "message", T: AuxiliarySourceRequest, oneof: "request" },
    { no: 3, name: "directory", kind: "message", T: DirectorySourceRequest, oneof: "request" },
    { no: 4, name: "discover", kind: "message", T: DiscoverSourceRequest, oneof: "request" },
    { no: 5, name: "manual", kind: "message", T: ManualSourceRequest, oneof: "request" },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): SourceRequest {
    return new SourceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): SourceRequest {
    return new SourceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): SourceRequest {
    return new SourceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: SourceRequest | PlainMessage<SourceRequest> | undefined, b: SourceRequest | PlainMessage<SourceRequest> | undefined): boolean {
    return proto3.util.equals(SourceRequest, a, b);
  }
}


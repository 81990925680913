import {
  Component,
  computed,
  DestroyRef,
  effect,
  ErrorHandler,
  inject,
  OnDestroy,
  Signal,
  signal,
} from '@angular/core';

import { toObservable } from '@angular/core/rxjs-interop';
import {
  ArgumentError,
  DebounceTimer,
  Disposer,
  getLocaleDecimalSeparator,
  Messages,
} from '@frontend2/core';
import { GoogleLocation } from '@frontend2/proto/librarian/proto/local_pb';
import { debounceTime, Observable, Observer, skip, Subject } from 'rxjs';
import { injectChangeDetector } from './inject.helpers';

// Helper class to implement a component that need to listen Stream and trigger change detection
///
/// Also provide a [Disposer] to the component
@Component({
  template: '',
})
export abstract class LeftyComponent implements OnDestroy {
  disposer = new Disposer();

  public readonly Messages: typeof Messages = Messages;

  changeDetection = injectChangeDetector();

  /// API similar to Flutter
  /// Trigger change detection
  ///
  /// ex:
  /// ```dart
  /// setState(() {
  ///   loading = true;
  /// })
  /// ```
  setState(apply: () => void): void {
    apply();
    this.changeDetection.markForCheck();
  }

  /// Helper to listen for a stream
  /// and automatically dispose subscription when Bloc is destroy
  ///
  /// It also automatically trigeer change detection on data change
  protected watch<T>(
    stream: Observable<T>,
    observer?: Partial<Observer<T>>,
  ): void {
    this.disposer.addStreamSubscription(
      stream.subscribe({
        ...observer,
        next: (val) => {
          if (observer?.next) {
            observer.next(val);
          }
          this.changeDetection.markForCheck();
        },
      }),
    );
  }

  ngOnDestroy(): void {
    this.disposer.dispose();
  }
}

export function isSpaceKey(keyCode: string): boolean {
  return keyCode === ' ';
}

export function isEnterKey(keyCode: string): boolean {
  return keyCode === 'Enter';
}

export function isUpKey(keyCode: string): boolean {
  return keyCode === 'ArrowUp';
}

export function isDownKey(keyCode: string): boolean {
  return keyCode === 'ArrowDown';
}

export function isRightKey(keyCode: string): boolean {
  return keyCode === 'ArrowRight';
}

export function isLeftKey(keyCode: string): boolean {
  return keyCode === 'ArrowLeft';
}
// TODO(google): account for RTL.
export function isNextKey(keyCode: string): boolean {
  return isRightKey(keyCode) || isDownKey(keyCode);
}

export function isPrevKey(keyCode: string): boolean {
  return isLeftKey(keyCode) || isUpKey(keyCode);
}

export function isHomeKey(keyCode: string): boolean {
  return keyCode === 'Home';
}

export function isEndKey(keyCode: string): boolean {
  return keyCode === 'End';
}

export function isEscapeKey(keyCode: string): boolean {
  return keyCode === 'Escape';
}

export function isBackspaceKey(keyCode: string): boolean {
  return keyCode === 'Backspace';
}

export function isNumberKey(event: KeyboardEvent, double: boolean): boolean {
  const charCode = event.charCode;
  return (
    (charCode >= '0'.charCodeAt(0) && charCode <= '9'.charCodeAt(0)) ||
    (double && event.code === getLocaleDecimalSeparator())
  );
}

export function focusElement(element: HTMLElement): void {
  // if element does not have positive tab index attribute already specified
  // or is native element.
  // NOTE: even for elements with tab index unspecified it will return
  // tabIndex as "-1" and we have to set it to "-1"
  // to actually make it focusable.
  if (element.tabIndex < 0) {
    element.tabIndex = -1;
  }
  element.focus();
}

/// Parses [strValue] into a [bool].
///
/// Only the following values are acceptable as `strValue`:
///  '' = true
///  'true' = true
///  'false' = false
///
/// **NOTE**: If [strValue] is an empty string (''), it is always true. This is
/// because when you declare something like:
///     <material-button disabled></material-button>
///
/// ... The value of "disabled" is ''.
function parseBool(strValue: string): boolean {
  switch (strValue) {
    case '':
      return true;
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      throw new ArgumentError(
        'Only "", "true", and "false" are acceptable values for parseBool. ',
      );
  }
}

/// Parses HTML attribute [String] to a [bool].
///
/// Should be used to parse values passed to @Attribute constructor argument.
///
/// This does not follow the HTML boolean attribute definition
/// (https://stackoverflow.com/a/4139805), as 'false' String will be parsed
/// to false value.
///
/// When no attribute is present [defaultValue] value is returned.
///
/// NOTE: no attribute is not the same as no value for attribute:
///
/// * <my-component foo> - foo attribute is present but has no value, which
///                        is parsed to *true*.
/// * <my-component> - no attribute is present, parsed to [defaultValue].
export function attributeToBool(
  inputValue?: string,
  defaultValue = false,
): boolean {
  if (inputValue === '') {
    return true;
  }

  if (!inputValue) {
    return defaultValue;
  }
  return parseBool(inputValue);
}

/// A simple handle for Dropdown components.
/// Components wishing to control an ancestral dropdown can have this interface
/// injected:
///     @Optional() DropdownHandle dropdown
export abstract class DropdownHandle {
  abstract open(): void;
  abstract close(): void;
  abstract toggle(): void;
  abstract autoDismiss: boolean;

  abstract visible$: Observable<boolean>;
  abstract get isVisible(): boolean;
}

/// Interface for classes accepting a disabled setting.
export abstract class HasDisabled {
  abstract get disabled(): boolean;
}

export abstract class HasTabIndex {
  abstract get tabIndex(): string | undefined;
}

export abstract class AngularUtils {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  static trackByIndex<T>(index: number, _: T): number {
    return index;
  }

  /// Default renderer call `toString` method of object
  static defaultItemRenderer<T>(object: T): string {
    return `${object}`;
  }
}

export function createSubject<T>(destroyRef?: DestroyRef): Subject<T> {
  destroyRef = destroyRef ?? inject(DestroyRef);
  const subject = new Subject<T>();

  destroyRef.onDestroy(() => subject.complete());

  return subject;
}

// Create a Subject that should be use for @Output
//
// If no DestroyRef pass in argument
// It throws if called outside of a supported context. (constructor, attributes definition)
export function createOutput<T>(destroyRef?: DestroyRef): Subject<T> {
  return createSubject(destroyRef);
}

// Create a Disposer
//
// If no DestroyRef pass in argument
// It throws if called outside of a supported context. (constructor, attributes definition)
export function createDisposer(destroyRef?: DestroyRef): Disposer {
  destroyRef = destroyRef ?? inject(DestroyRef);
  const disposer = new Disposer();

  destroyRef.onDestroy(() => disposer.dispose());

  return disposer;
}

export interface AnchorLink {
  readonly label: string;
  readonly href: string;
}

export class Context<T> {
  constructor(readonly value: Signal<T>) {}

  readonly value$ = toObservable(this.value);
}

export const DEFAULT_GOOGLE_LOCATION_VALUE = new GoogleLocation();

export type ObjectFit = 'cover' | 'contain' | 'scale-down';

interface _AsyncTask<T> {
  readonly isRunning: boolean;

  readonly value?: T;
  readonly error?: unknown;
}

export type AsyncTaskSignal<T> = {
  readonly $isRunning: Signal<boolean>;
  readonly $value: Signal<T | undefined>;
  readonly $error: Signal<unknown>;
};

export function computedAsync<T>(
  computation: () => Promise<T>,
): AsyncTaskSignal<T> {
  const errorHandler = inject(ErrorHandler);

  // The signal that will hold the current state of the computation
  // The state is an object with 3 properties:
  // - isRunning: a boolean that indicates if the computation is running
  // - value: the value produced by the computation
  // - error: the error that occurred during the computation
  const $task = signal<_AsyncTask<T>>({
    isRunning: false,
  });

  // The key is used to cancel the computation if it's no longer needed
  // before the computation has finished.
  let currentKey: unknown;

  // The effect will run the computation and update the signal
  // when the computation is finished.
  effect(
    () => {
      // Set the isRunning property to true
      // so that we can render a loading indicator
      // or disable buttons or whatever
      $task.update((s) => {
        return { ...s, isRunning: true };
      });

      // Generate a new key that will be used to cancel the computation
      // if it's no longer needed.
      const key = Object();
      currentKey = key;

      // Run the computation and handle the result
      computation()
        .then((value) => {
          // If the key has changed, it means that the computation has been cancelled
          // and we should not update the signal with the new value.
          // Otherwise, set the value to the result of the computation and
          // set isRunning to false.
          if (currentKey !== key) {
            return;
          }
          $task.set({ value, isRunning: false });
        })
        .catch((error) => {
          // If an error occurred during the computation, handle it
          // with the error handler and set the error property of the signal
          // and set isRunning to false.
          errorHandler.handleError(error);
          $task.update((s) => {
            return { ...s, error, isRunning: false };
          });
        });
    },
    { allowSignalWrites: true },
  );

  return {
    $isRunning: computed(() => $task().isRunning),
    $value: computed(() => $task().value),
    $error: computed(() => $task().error),
  };
}

const _DEFAULT_DEBOUNCE_DURATION = 300;

export function debouncedSignal<T>(
  $source: Signal<T>,
  args?: {
    debounceDuration?: number;
  },
): Signal<T> {
  const debounceDuration = args?.debounceDuration ?? _DEFAULT_DEBOUNCE_DURATION;

  const debounceSignal = signal($source());
  const timer = new DebounceTimer(debounceDuration);

  effect(
    (onCleanup) => {
      const value = $source();
      timer.trigger(() => debounceSignal.set(value));

      onCleanup(timer.cancel);
    },
    { allowSignalWrites: true },
  );
  return debounceSignal;
}

export function toDebouncedObservable<T>(
  signal: Signal<T>,
  args?: {
    skipFirst?: boolean;
    debounceDuration?: number;
  },
): Observable<T> {
  const debounceDuration = args?.debounceDuration ?? _DEFAULT_DEBOUNCE_DURATION;
  const skipFirst = args?.skipFirst ?? false;

  let observable$ = toObservable(signal);

  if (skipFirst) {
    observable$ = observable$.pipe(skip(1));
  }

  observable$ = observable$.pipe(debounceTime(debounceDuration));

  return observable$;
}

import { Injectable } from '@angular/core';
import { CacheBloc } from '../bloc';

export interface Country {
  readonly country: string;
  readonly alpha2: string;
  readonly alpha3: string;
  readonly numeric: string;
}

export interface CountriesCacheState {
  [key: string]: Country;
}

@Injectable({ providedIn: 'root' })
export class CountriesCache extends CacheBloc<CountriesCacheState> {
  constructor() {
    super({});
  }

  async fetch(): Promise<CountriesCacheState> {
    const assets: Country[] = (
      await import('packages/lefty-assets/src/lib/db/countries.json')
    ).default;

    const countries: CountriesCacheState = {};
    assets.forEach((c) => {
      countries[c.alpha2] = c;
    });

    return countries;
  }

  getCountry(code: string): Country | undefined {
    return this.cachedData()[code];
  }

  getCountryName(code: string): string {
    return this.getCountry(code)?.country ?? code;
  }

  /// For legacy report coming from HypeAuditor
  /// GeographyReport does not contain countryCode
  /// So we need to use the country name to match correct countryCode
  /// coming from our assets `countries.json`
  resolveCountryCode(name: string): string {
    const original = name;

    name = name.toLowerCase();
    if (this.isLoaded() === false) {
      return original;
    }

    return (
      Object.values(this.cachedData).find(
        (c) =>
          c.country.toLowerCase() === name || c.alpha2.toLowerCase() === name,
      )?.alpha2 ?? original
    );
  }
}

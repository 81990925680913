import {
  CWBrandMarket,
  CWGetSetResponse,
  CWReportDetails,
  NetworkSource,
  NetworkSourceRequest,
  ReportRefreshStep,
} from '@frontend2/proto/librarian/proto/admin_cw_pb';
import { isNil } from '../../utils/common.helpers';

export function isGhostReport(report: CWReportDetails): boolean {
  return report.reportId !== BigInt(0) ? false : true;
}

export function isGhostBrandMarket(brand: CWBrandMarket): boolean {
  if (isNil(brand.brand) && isNil(brand.market)) {
    return true;
  }
  return brand.brand?.brandId !== BigInt(0) ? false : true;
}

export function isGhostSet(set: CWGetSetResponse): boolean {
  return set.setId !== BigInt(0) ? false : true;
}

export function networkSourceToRequest(
  source: NetworkSource,
): NetworkSourceRequest {
  return new NetworkSourceRequest({
    network: source.network,
    request: source.request,
  });
}

export const INTERNATIONAL_MARKET_ID = BigInt(20);

export function reportRefreshStepToReadable(step: ReportRefreshStep): string {
  switch (step) {
    case ReportRefreshStep.POLL:
      return 'Refresh';
    case ReportRefreshStep.TRACK:
      return 'Track';
    case ReportRefreshStep.AUDIT:
      return 'Audit';
    default:
      return '';
  }
}

import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  inject,
} from '@angular/core';
import { DebounceTimer, isNil, isNotNil } from '@frontend2/core';
import { Instance, Placement, createPopper } from '@popperjs/core';
import { Observable, Subject, Subscription, fromEvent, merge } from 'rxjs';
import { injectZone } from '../inject.helpers';
import { DropdownHandle, LeftyComponent, createOutput } from '../utils';
import { NgClass, NgIf } from '@angular/common';

// use PopperJS to implements popup
// it handle placement and auto positionning when scrolling
//
// https://popper.js.org/
@Component({
  selector: 'lefty-popup',
  templateUrl: 'lefty-popup.component.html',
  styleUrls: ['lefty-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DropdownHandle,
      useExisting: LeftyPopupComponent,
    },
  ],
  standalone: true,
  imports: [NgClass, NgIf],
})
export class LeftyPopupComponent
  extends LeftyComponent
  implements OnDestroy, DropdownHandle, AfterViewInit, AfterContentChecked
{
  private _documentClickSubscription?: Subscription;
  private _triggerClickSubscription?: Subscription;
  private _mouseEnterSubscription?: Subscription;
  private _mouseLeaveSubscription?: Subscription;
  readonly zone = injectZone();
  readonly ref = inject(ElementRef);

  private _isFocus = false;

  constructor() {
    super();

    this.watch(fromEvent<MouseEvent>(this.ref.nativeElement, 'click'), {
      next: (event) => this._handleContentClick(event),
    });
  }

  @Input()
  hideDelay = 100;

  @Input()
  delegateControlToParent = false;

  // Function to validate a node
  // if node is valid, it won't trigger autoDismiss event
  @Input()
  autoDismissNodeValidator?: (node: Node) => boolean;

  _autoDismissable = true;

  get autoDismissable(): boolean {
    return this._autoDismissable;
  }

  @Input()
  set autoDismissable(autoDismiss: boolean) {
    this._autoDismissable = autoDismiss;
    this._subscribeDocumentClick();
  }

  /// Event which is published when a focus, mouseup, or click occurs outside of
  /// the element.
  @Output()
  readonly dismiss$ = createOutput<Event | null>();

  @Output()
  readonly visibleChange = createOutput<boolean>();

  @HostBinding('class')
  readonly hostClass = 'lefty-popup';

  private popup?: Instance;
  private _visible = false;

  get visible(): boolean {
    return this._visible;
  }

  @Input()
  set visible(isVisible: boolean) {
    if (this.visible === isVisible) {
      return;
    }

    this._visible = isVisible;
    this.visibleChange.next(isVisible);

    if (isVisible) {
      this._createOrUseCachedPopup();
      this._subscribeDocumentClick();
    } else {
      this._unsubscribeDocumentClick();
      this._isFocus = false;
    }

    this.changeDetection.markForCheck();
  }

  // use PopperJS placement enum
  // https://popper.js.org
  _placement: Placement = 'bottom-start';

  get placement(): Placement {
    return this._placement;
  }

  @Input()
  set placement(value: Placement) {
    this._placement = value;

    if (isNotNil(this.popup)) {
      this.popup.setOptions({
        placement: value,
      });
    }
  }

  private _matchMinSourceWidth = false;

  get matchMinSourceWidth(): boolean {
    return this._matchMinSourceWidth;
  }

  @Input()
  set matchMinSourceWidth(value) {
    this._matchMinSourceWidth = value;
    this._triggerElementWidth = undefined;
  }

  private _showOnHover = false;

  get showOnHover(): boolean {
    return this._showOnHover;
  }

  @Input()
  set showOnHover(value) {
    this._showOnHover = value;
    this._subscribeTriggerEvent();
  }

  private _popupTrigger?: HTMLElement;

  public get popupTrigger(): HTMLElement | undefined {
    return this._popupTrigger;
  }

  @Input()
  set popupTrigger(value: HTMLElement | ElementRef | undefined) {
    if (value instanceof ElementRef) {
      this._popupTrigger = value.nativeElement;
    } else if (value instanceof HTMLElement) {
      this._popupTrigger = value;
    } else {
      this._popupTrigger = undefined;
    }
  }

  get hasOutsideTrigger(): boolean {
    return isNotNil(this.popupTrigger);
  }

  private _triggerElementWidth?: number;

  get popupMinWidth(): string {
    if (
      this.visible &&
      this.matchMinSourceWidth &&
      isNotNil(this._popupTrigger)
    ) {
      // clientWidth call trigger Layout reflow
      // and cause performance issue
      //
      // it's better to cache it
      // and call it only if popup is visible
      //
      // Also set matchMinSourceWidth to false by default,
      // to prevent this by default
      this._triggerElementWidth ??= this._popupTrigger.clientWidth;
      return `${this._triggerElementWidth}px`;
    }
    return '';
  }

  open(): void {
    this._scheduleOpen();
  }

  close(): void {
    this._scheduleClose();
  }

  toggle(): void {
    if (this.visible) {
      this._scheduleClose();
    } else {
      this._scheduleOpen();
    }
  }

  get autoDismiss(): boolean {
    return this.autoDismissable;
  }

  set autoDismiss(autoDismiss: boolean) {
    this.autoDismissable = autoDismiss;
  }

  private destroyPopup(): void {
    this.zone.runOutsideAngular(() => {
      this.popup?.destroy();
      this.popup = undefined;
    });
  }

  private _createOrUseCachedPopup(): void {
    this.zone.runOutsideAngular(() => {
      if (this._popupTrigger) {
        this.popup ??= createPopper(
          this._popupTrigger,
          this.ref.nativeElement,
          {
            placement: this.placement,
            strategy: 'fixed',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [this.offsetSkidding, this.offsetDistance],
                },
              },
            ],
          },
        );
      }
    });
  }

  ngAfterViewInit(): void {
    this._subscribeDocumentClick();
    this._subscribeTriggerEvent();
  }

  // DropdownHandle override
  get visible$(): Observable<boolean> {
    return this.visibleChange;
  }

  // DropdownHandle override
  @HostBinding('class.visible')
  get isVisible(): boolean {
    return this._visible && this.disabled === false;
  }

  private _isInsideContent(elementToCheck: HTMLElement): boolean {
    return (
      elementToCheck === this.ref.nativeElement ||
      this.ref.nativeElement.contains(elementToCheck)
    );
  }

  private _isInsideTrigger(elementToCheck: HTMLElement): boolean {
    return (
      elementToCheck === this._popupTrigger ||
      this._popupTrigger?.contains(elementToCheck) === true
    );
  }

  @Input()
  disabled = false;

  @Input()
  popupClassName = '';

  /// see https://popper.js.org/docs/v2/modifiers/offset/
  @Input()
  offsetDistance = 0;

  /// see https://popper.js.org/docs/v2/modifiers/offset/
  @Input()
  offsetSkidding = 0;

  override ngOnDestroy(): void {
    super.ngOnDestroy();
    this.destroyPopup();
    this._closeTimer?.cancel();
  }

  private _handleTriggerClickOutside(event: Event): void {
    const node = event.target as HTMLElement;

    // ignore FocusEvent
    // LeftyPopup may be used inside an Anchor Element
    // and there is an issue on Firefox when you click inside popup
    // it trigger focus event on the link, causing popup to close.
    //
    // Hopefully we do not really care about focus event here,
    // we just want the popup to close on Click event
    if (event instanceof FocusEvent) {
      return;
    }

    if (isNotNil(this.autoDismissNodeValidator)) {
      const validator = this.autoDismissNodeValidator;

      if (validator(event.target as Node)) {
        return;
      }
    }

    if (
      this._isInsideContent(node) === false &&
      this._isInsideTrigger(node) === false
    ) {
      this.close();
    }
  }

  private _unsubscribeDocumentClick(): void {
    this._documentClickSubscription?.unsubscribe();
    this._documentClickSubscription = undefined;
  }

  private _subscribeDocumentClick(): void {
    // we want to attach DOM click listener only if popup is visible and if autodismissable
    // some screens may contains a lot of popup, it would cause low perf issues
    if (this.autoDismissable && this.visible) {
      this._documentClickSubscription ??= merge(
        LeftyPopupComponent._globalDismiss$,
        fromEvent(document, 'click'),
      ).subscribe({
        next: (event) => this._handleTriggerClickOutside(event),
      });
    } else {
      this._unsubscribeDocumentClick();
    }
  }

  ngAfterContentChecked(): void {
    // force popup repositioning if content size change
    this._updatePosition();
  }

  private _scheduleOpen(): void {
    // if HIDE was schedule, we cancel it
    this._closeTimer?.cancel();

    this.visible = true;
  }

  _closeTimer?: DebounceTimer;

  // Use a timer to close the popup
  // Close action may be cancel by an OPEN action, this is mainly the case
  // when popup use showOnHover
  private _scheduleClose(): void {
    this._closeTimer?.cancel();

    this._closeTimer = new DebounceTimer(this.hideDelay);
    this._closeTimer.trigger(() => (this.visible = false));
  }

  private _handleMouseEnter(): void {
    this._scheduleOpen();
  }

  private _handleMouseLeave(): void {
    // autodismissable can also be disable on a `showOnHover` popup
    if (this.autoDismissable === false) {
      return;
    }

    // if the user has no focus on the popup (no action like click in it)
    // we can safely close the popup
    //
    // if the user clicked in the popup, the autodismiss
    // will work only if user click outside of popup
    if (this._isFocus === false) {
      this._scheduleClose();
    }
  }

  private static readonly _globalDismiss$ = new Subject<Event>();

  private _handleTriggerClick(event: MouseEvent): void {
    // prevent click to bubble up
    // we may use poppup inside an Anchor and we don't want to trigger link
    event.stopPropagation();
    event.preventDefault();

    // we stopped event propagation
    //
    // So if any other popup was open
    // it won't catch `document.onClick` event to close itself
    //
    // We pass the Event to a global Stream so it can be catch by other popup
    // `_handleTriggerClickOutside`
    LeftyPopupComponent._globalDismiss$.next(event);

    this.toggle();
  }

  private _handleContentClick(event: MouseEvent): void {
    // prevent click to bubble up
    // we may use popup inside an Anchor and we don't want to trigger link

    // However if user click on AnchorElement that is INSIDE the popup
    // we want the link to work
    // In that case we don't care about the event bubbling up,
    // since user will be redirect to a new page
    //
    // We also check the `href` attribute setup by `RouterLink`
    const target = event.target as HTMLElement;
    if (target instanceof HTMLAnchorElement || target.hasAttribute('href')) {
      return;
    }

    this._isFocus = true;

    event.stopPropagation();
    event.preventDefault();
  }

  private _subscribeTriggerEvent(): void {
    this._triggerElementWidth = undefined;
    this._mouseEnterSubscription?.unsubscribe();
    this._mouseLeaveSubscription?.unsubscribe();
    this._triggerClickSubscription?.unsubscribe();

    const trigger = this.popupTrigger;

    if (isNil(trigger) || this.delegateControlToParent) {
      return;
    }

    if (this.showOnHover) {
      // popup content and popup trigger are not in the same container
      // when user is moving mouse from trigger (ex: a button) to the popup content
      // it may trigger `popupTrigger.onMouseLeave` (that trigger _scheduleClose)
      // and retrigger `element.onMouseEnter` (that trigger _scheduleOpen)

      this._mouseEnterSubscription = merge(
        fromEvent(trigger, 'mouseenter'),
        fromEvent(this.ref.nativeElement, 'mouseenter'),
      ).subscribe({
        next: () => this._handleMouseEnter(),
      });

      this._mouseLeaveSubscription = merge(
        fromEvent(trigger, 'mouseleave'),
        fromEvent(this.ref.nativeElement, 'mouseleave'),
      ).subscribe({
        next: () => this._handleMouseLeave(),
      });
    } else {
      this._triggerClickSubscription = fromEvent(trigger, 'click').subscribe({
        next: (event) => this._handleTriggerClick(event as MouseEvent),
      });
    }
  }

  private _updatePosition(): void {
    this.zone.runOutsideAngular(() => this.popup?.update());
  }
}

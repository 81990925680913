<lefty-form
  [label]="label"
  [required]="required"
  [optional]="optional"
  [helpText]="helpText"
  [helpLink]="helpLink"
  [hintText]="hintText"
  [errorMessage]="errorMessage"
  [tooltip]="tooltip"
  [large]="large"
  [topHintText]="topHintText"
>
  <textarea
    #textarea
    focusableElement
    [rows]="rows"
    [disabled]="disabled"
    [ngModel]="value"
    (ngModelChange)="handleValueChange($event)"
    (focus)="handleFocus($event)"
    (blur)="handleBlur($event)"
    (invalid)="$event.preventDefault()"
    [required]="required"
    (keypress)="keypress$.emit($event)"
    (keyup)="keyup$.emit($event)"
    (keydown)="keydown$.emit($event)"
    [attr.name]="name"
    [attr.autocomplete]="autocomplete"
    [attr.placeholder]="placeholder"
    [attr.tabindex]="tabIndex"
    [attr.maxlength]="maxLength"
  >
  </textarea>
</lefty-form>

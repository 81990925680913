<div
  class="chart"
  [class.vertical]="vertical"
>
  <div
    class="data"
    *ngFor="let d of data; trackBy: trackByIndex"
  >
    <div
      *ngIf="vertical"
      class="label caption-1"
      [innerHTML]="formatName(d.name)"
    ></div>
    <div class="bar-container">
      <div
        class="bar"
        [class.first]="d === data[0]"
        [ngStyle]="barStyle(d)"
      >
        <div class="value caption-1">
          {{ formatValue(d.values[0]) }}
        </div>
      </div>
    </div>
    <div
      *ngIf="!vertical"
      class="label caption-1"
      [innerHTML]="d.name"
    ></div>
  </div>
</div>
<div
  *ngIf="title"
  class="body-1 title"
>
  {{ title }}
</div>

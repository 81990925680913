import { InjectionToken, inject } from '@angular/core';
import { Code, PromiseClient, createPromiseClient } from '@connectrpc/connect';
import {
  TaskProgressUpdateEvent,
  getEnumName,
  isNotEmptyString,
} from '@frontend2/core';
import { Bronco } from '@frontend2/proto/common/proto/bronco_connect';
import { GRPC_TRANSPORT, isInternalServerError } from './grpc.service';

/**
 * Interface use to parse JSON response
 * of the Bronco HTTP API and Firebase
 */
export interface BroncoTask {
  readonly handle: string;
  readonly worked_on: boolean;
  readonly status_description?: string;
  readonly status?: number;
  readonly done: boolean;
  readonly progress?: number;
  readonly progress_msg?: string;
  readonly progress_metadata?: string;
  readonly output?: string;
}

export function broncoTaskToProgressEvent(
  task: BroncoTask,
): TaskProgressUpdateEvent {
  return {
    ...task,
    id: task.handle,
  };
}

export type BroncoClient = PromiseClient<typeof Bronco>;

export const BroncoClientProvider = new InjectionToken<BroncoClient>(
  'BroncoClient',
  {
    factory: (): BroncoClient =>
      createPromiseClient(Bronco, inject(GRPC_TRANSPORT)),
  },
);

export class BroncoTaskError extends Error {
  constructor(public readonly task: BroncoTask) {
    const status = getEnumName(Code, task.status) ?? 'Unknown';

    let message = `BroncoTaskError\ntask: ${task.handle}\nstatus: ${status}`;

    const description = task.status_description;
    if (isNotEmptyString(description)) {
      message += `\n${description}`;
    }

    super(message);
  }

  get isInternalServerError(): boolean {
    return isInternalServerError(this.task.status ?? Code.Unknown);
  }
}

/**
 * Keep current naming during migration
 * @deprecated Use BroncoTaskError instead
 */
export type BroncoTaskException = BroncoTaskError;

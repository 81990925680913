import { Duration, Timestamp } from '@bufbuild/protobuf';
import { TimeRestriction } from '@frontend2/proto/librarian/proto/common_pb';
import { isNil, isNotNil, plural } from './utils/common.helpers';

type DateValue = Date | Timestamp | string | number;

export function toDate(date: DateValue): Date {
  if (date instanceof Date) {
    return new Date(date);
  }

  if (date instanceof Timestamp) {
    return date.toDate();
  }

  return new Date(date);
}

export type DateFormatter = (date: Date, format?: DateFormat) => string;

export async function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => window.setTimeout(resolve, ms));
}

export const MINUTES_IN_A_DAY = 60 * 24;
export const SECONDS_IN_AN_HOUR = 60 * 60;
export const SECONDS_IN_A_DAY = 60 * 60 * 24;

export type DateFormat =
  | 'longDate'
  | 'shortDate'
  | 'mediumDate'
  | Intl.DateTimeFormatOptions;

function dateFormatToFormatOptions(
  format: DateFormat,
): 'long' | 'numeric' | '2-digit' | 'short' | 'narrow' {
  switch (format) {
    case 'longDate':
      return 'long';

    case 'mediumDate':
      return 'short';

    case 'shortDate':
      return 'numeric';

    default:
      return 'short';
  }
}

export function formatDate(
  date?: DateValue,
  format: DateFormat = 'mediumDate',
): string {
  if (isNil(date)) {
    return '';
  }

  let dateFormatOptions: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
  };

  if (typeof format === 'string') {
    dateFormatOptions.month = dateFormatToFormatOptions(format);
  } else {
    dateFormatOptions = format;
  }

  return toDate(date).toLocaleDateString('en', dateFormatOptions);
}

export function isAfterNow(date?: DateValue): boolean {
  if (isNil(date)) {
    return false;
  }
  date = toDate(date);
  return isAfterDate(new Date(), date);
}

export function isBeforeNow(date?: DateValue): boolean {
  if (isNil(date)) {
    return false;
  }
  date = toDate(date);
  return isBeforeDate(new Date(), date);
}

export function formatDateTime(
  date: DateValue,
  format: DateFormat = 'longDate',
): string {
  return toDate(date).toLocaleDateString('en', {
    month: dateFormatToFormatOptions(format),
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
}

export function formatMonthYear(date: DateValue): string {
  return toDate(date).toLocaleDateString('en', {
    month: 'short',
    year: 'numeric',
  });
}

export function formatDateAgo(date: DateValue): string {
  const rtf = new Intl.RelativeTimeFormat('en');

  date = toDate(date).getTime();
  const now = new Date().getTime();

  const seconds = Math.floor((now - date) / 1000);

  // determine what is the best unit to display
  let unit: Intl.RelativeTimeFormatUnit;

  let value = Math.floor(seconds / 31536000);
  if (value >= 1) {
    unit = 'year';
  } else {
    value = Math.floor(seconds / 2592000);
    if (value >= 1) {
      unit = 'month';
    } else {
      value = Math.floor(seconds / 86400);
      if (value >= 1) {
        unit = 'day';
      } else {
        value = Math.floor(seconds / 3600);
        if (value >= 1) {
          unit = 'hour';
        } else {
          value = Math.floor(seconds / 60);
          if (value >= 1) {
            unit = 'minute';
          } else {
            value = seconds;
            unit = 'second';
          }
        }
      }
    }
  }

  return rtf.format(-value, unit);
}

export function formatDateAgoIfRecent(date: DateValue): string {
  const yesterday = subDuration(new Date(), 1, 'days');
  date = toDate(date);

  if (isAfterDate(date, yesterday)) {
    return formatDateAgo(date);
  }

  return formatDate(date);
}

export function formatPostDate(date: DateValue): string {
  return formatDateAgoIfRecent(date);
}

export function isAfterDate(date: DateValue, compare: DateValue): boolean {
  return toDate(date) > toDate(compare);
}

export function isBeforeDate(date: DateValue, compare: DateValue): boolean {
  return toDate(date) < toDate(compare);
}

export function isSameDate(a: DateValue, b: DateValue): boolean {
  return toDate(a).valueOf() === toDate(b).valueOf();
}

export function isSameDay(a: DateValue, b: DateValue): boolean {
  return isBetweenDates(a, {
    start: startOfDay(b),
    end: endOfDay(b),
  });
}

export function isBetweenDates(
  date: DateValue,
  interval: {
    start: DateValue;
    end: DateValue;
  },
): boolean {
  return (
    isSameDate(date, interval.start) ||
    isSameDate(date, interval.end) ||
    (isAfterDate(date, interval.start) && isBeforeDate(date, interval.end))
  );
}

export type DurationUnit =
  | 'milliseconds'
  | 'seconds'
  | 'minutes'
  | 'hours'
  | 'days'
  | 'months'
  | 'years';

export function addDuration(
  date: DateValue,
  count: number,
  unit: DurationUnit,
): Date {
  date = toDate(date);
  const newDate = new Date(date);

  switch (unit) {
    case 'milliseconds':
      return new Date(date.valueOf() + count);
    case 'seconds':
      return new Date(date.valueOf() + count * 1000);
    case 'minutes':
      return new Date(date.valueOf() + count * 1000 * 60);
    case 'hours':
      return new Date(date.valueOf() + count * 1000 * 60 * 60);
    case 'days':
      return new Date(date.valueOf() + count * 1000 * 60 * 60 * 24);
    case 'months':
      newDate.setMonth(date.getMonth() + count);
      break;
    case 'years':
      newDate.setFullYear(date.getFullYear() + count);
      break;
  }

  return newDate;
}

export function subDuration(
  date: DateValue,
  count: number,
  unit: DurationUnit,
): Date {
  date = toDate(date);
  const newDate = new Date(date);

  switch (unit) {
    case 'milliseconds':
      return new Date(date.valueOf() - count);
    case 'seconds':
      return new Date(date.valueOf() - count * 1000);
    case 'minutes':
      return new Date(date.valueOf() - count * 1000 * 60);
    case 'hours':
      return new Date(date.valueOf() - count * 1000 * 60 * 60);
    case 'days':
      return new Date(date.valueOf() - count * 1000 * 60 * 60 * 24);
    case 'months':
      newDate.setMonth(date.getMonth() - count);
      break;
    case 'years':
      newDate.setFullYear(date.getFullYear() - count);
      break;
  }

  return newDate;
}

export function startOfDay(date: DateValue): Date {
  date = toDate(date);
  date.setHours(0, 0, 0, 0);
  return date;
}

export function endOfDay(date: DateValue): Date {
  date = toDate(date);
  date.setHours(23, 59, 59, 999);
  return date;
}

export function startOfWeek(date: DateValue): Date {
  date = toDate(date);

  const weekDay = date.getDay();
  const monthDay = date.getDate();
  date.setDate(monthDay - weekDay);

  return startOfDay(date);
}

export function endOfWeek(date: DateValue): Date {
  date = startOfWeek(date);
  date.setDate(date.getDate() + 6);

  return endOfDay(date);
}

export function startOfMonth(date: DateValue): Date {
  date = toDate(date);

  const start = new Date(date.getFullYear(), date.getMonth(), 1);

  return startOfDay(start);
}

export function formatDuration(
  durationOrSeconds: Duration | number,
  longFormat = false,
): string {
  const secondsNumber =
    durationOrSeconds instanceof Duration
      ? Number(durationOrSeconds.seconds)
      : durationOrSeconds;

  const time = {
    day: Math.floor(secondsNumber / 86400),
    h: Math.floor(secondsNumber / 3600) % 24,
    min: Math.floor(secondsNumber / 60) % 60,
  };
  if (longFormat === false) {
    return Object.entries(time)
      .filter((val) => val[1] !== 0)
      .map(([key, val]) => `${val}${key}`)
      .join(', ');
  } else {
    const parts: string[] = [];
    if (time.day > 0) {
      parts.push(
        `${time.day} ${plural(time.day, { one: $localize`day`, other: $localize`days` })}`,
      );
    }
    if (time.h > 0) {
      parts.push(
        `${time.h} ${plural(time.h, { one: $localize`hour`, other: $localize`hours` })}`,
      );
    }
    if (time.min > 0) {
      parts.push(
        `${time.min} ${plural(time.min, { one: $localize`minute`, other: $localize`minutes` })}`,
      );
    }
    return parts.join(', ');
  }
}

export function getDuration(start: DateValue, end: DateValue): Duration {
  start = toDate(start);
  end = toDate(end);

  const diffInMilliseconds = Math.abs(end.getTime() - start.getTime());
  const seconds = Math.floor(diffInMilliseconds / 1000);
  const nanoseconds = (diffInMilliseconds % 1000) * 1e6;

  return new Duration({
    seconds: BigInt(seconds),
    nanos: nanoseconds,
  });
}

export function getTimeRestrictionDuration(time: TimeRestriction): Duration {
  const start = time.start;
  const end = time.end;

  if (isNotNil(start) && isNotNil(end)) {
    return getDuration(start, end);
  }

  return new Duration();
}

export const SECOND_IN_DAY = 86400;

export function getDurationInDays(duration: Duration): number {
  const seconds = Number(duration.seconds);
  return Math.floor(seconds / SECOND_IN_DAY);
}

export function isEmptyTimeRestriction(range: TimeRestriction): boolean {
  return isNil(range.start) && isNil(range.end);
}

export function roundToNearest30(dateValue: DateValue): Date {
  const date = toDate(dateValue);
  const minutes = date.getMinutes();
  const roundedMinutes = minutes < 15 ? 0 : minutes < 45 ? 30 : 60;

  if (roundedMinutes === 60) {
    date.setHours(date.getHours() + 1);
    date.setMinutes(0);
  } else {
    date.setMinutes(roundedMinutes);
  }

  return date;
}

// Removes the local offset from a date, converting 12AM local time to 12AM UTC
export function removeLocalOffset(date: DateValue): Date {
  const dateVal = toDate(date);
  const dateWithoutOffset = new Date(
    dateVal.getTime() - dateVal.getTimezoneOffset() * 60000,
  );
  return dateWithoutOffset;
}

// Applies the local offset to a date, converting 12AM UTC to 12AM local time
export function applyLocalOffset(date: DateValue): Date {
  const dateVal = toDate(date);
  const dateWithOffset = new Date(
    dateVal.getTime() + dateVal.getTimezoneOffset() * 60000,
  );
  return dateWithOffset;
}

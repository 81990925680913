// This file defines services for local APIs:
//     -autocomplete locations queries.
//     -gets details of a place.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/local.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { GeoPoint } from "../../common/proto/geo_pb";

/**
 * @generated from message librarian.proto.GoogleLocation
 */
export class GoogleLocation extends Message<GoogleLocation> {
  /**
   * @generated from field: string location_name = 1;
   */
  locationName = "";

  /**
   * @generated from field: string google_id = 2;
   */
  googleId = "";

  /**
   * @generated from field: common.proto.GeoPoint center = 3;
   */
  center?: GeoPoint;

  /**
   * Bounds of the zone.
   *
   * @generated from field: common.proto.GeoPoint north_east = 4;
   */
  northEast?: GeoPoint;

  /**
   * @generated from field: common.proto.GeoPoint south_west = 5;
   */
  southWest?: GeoPoint;

  /**
   * @generated from field: string country_code = 6;
   */
  countryCode = "";

  /**
   * @generated from field: bool is_country = 7;
   */
  isCountry = false;

  constructor(data?: PartialMessage<GoogleLocation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GoogleLocation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "location_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "google_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "center", kind: "message", T: GeoPoint },
    { no: 4, name: "north_east", kind: "message", T: GeoPoint },
    { no: 5, name: "south_west", kind: "message", T: GeoPoint },
    { no: 6, name: "country_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "is_country", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GoogleLocation {
    return new GoogleLocation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GoogleLocation {
    return new GoogleLocation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GoogleLocation {
    return new GoogleLocation().fromJsonString(jsonString, options);
  }

  static equals(a: GoogleLocation | PlainMessage<GoogleLocation> | undefined, b: GoogleLocation | PlainMessage<GoogleLocation> | undefined): boolean {
    return proto3.util.equals(GoogleLocation, a, b);
  }
}

/**
 * @generated from message librarian.proto.GoogleLocationList
 */
export class GoogleLocationList extends Message<GoogleLocationList> {
  /**
   * @generated from field: repeated librarian.proto.GoogleLocation location = 1;
   */
  location: GoogleLocation[] = [];

  constructor(data?: PartialMessage<GoogleLocationList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GoogleLocationList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "location", kind: "message", T: GoogleLocation, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GoogleLocationList {
    return new GoogleLocationList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GoogleLocationList {
    return new GoogleLocationList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GoogleLocationList {
    return new GoogleLocationList().fromJsonString(jsonString, options);
  }

  static equals(a: GoogleLocationList | PlainMessage<GoogleLocationList> | undefined, b: GoogleLocationList | PlainMessage<GoogleLocationList> | undefined): boolean {
    return proto3.util.equals(GoogleLocationList, a, b);
  }
}

/**
 * @generated from message librarian.proto.AutocompleteLocationRequest
 */
export class AutocompleteLocationRequest extends Message<AutocompleteLocationRequest> {
  /**
   * @generated from field: string query = 1;
   */
  query = "";

  /**
   * @generated from field: bool get_full_addresses_suggestions = 2;
   */
  getFullAddressesSuggestions = false;

  constructor(data?: PartialMessage<AutocompleteLocationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AutocompleteLocationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "query", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "get_full_addresses_suggestions", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteLocationRequest {
    return new AutocompleteLocationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteLocationRequest {
    return new AutocompleteLocationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteLocationRequest {
    return new AutocompleteLocationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteLocationRequest | PlainMessage<AutocompleteLocationRequest> | undefined, b: AutocompleteLocationRequest | PlainMessage<AutocompleteLocationRequest> | undefined): boolean {
    return proto3.util.equals(AutocompleteLocationRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.AutocompleteLocationsResponse
 */
export class AutocompleteLocationsResponse extends Message<AutocompleteLocationsResponse> {
  /**
   * @generated from field: repeated librarian.proto.GoogleLocation location = 1;
   */
  location: GoogleLocation[] = [];

  constructor(data?: PartialMessage<AutocompleteLocationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AutocompleteLocationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "location", kind: "message", T: GoogleLocation, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteLocationsResponse {
    return new AutocompleteLocationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteLocationsResponse {
    return new AutocompleteLocationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteLocationsResponse {
    return new AutocompleteLocationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteLocationsResponse | PlainMessage<AutocompleteLocationsResponse> | undefined, b: AutocompleteLocationsResponse | PlainMessage<AutocompleteLocationsResponse> | undefined): boolean {
    return proto3.util.equals(AutocompleteLocationsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.LocalAddressFieldProto
 */
export class LocalAddressFieldProto extends Message<LocalAddressFieldProto> {
  /**
   * @generated from field: string address = 1;
   */
  address = "";

  /**
   * @generated from field: string city = 2;
   */
  city = "";

  /**
   * @generated from field: string province = 3;
   */
  province = "";

  /**
   * @generated from field: string postal_code = 4;
   */
  postalCode = "";

  /**
   * @generated from field: string country = 5;
   */
  country = "";

  constructor(data?: PartialMessage<LocalAddressFieldProto>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.LocalAddressFieldProto";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "city", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "province", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "postal_code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LocalAddressFieldProto {
    return new LocalAddressFieldProto().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LocalAddressFieldProto {
    return new LocalAddressFieldProto().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LocalAddressFieldProto {
    return new LocalAddressFieldProto().fromJsonString(jsonString, options);
  }

  static equals(a: LocalAddressFieldProto | PlainMessage<LocalAddressFieldProto> | undefined, b: LocalAddressFieldProto | PlainMessage<LocalAddressFieldProto> | undefined): boolean {
    return proto3.util.equals(LocalAddressFieldProto, a, b);
  }
}

/**
 * @generated from message librarian.proto.AutofillAddressRequest
 */
export class AutofillAddressRequest extends Message<AutofillAddressRequest> {
  /**
   * @generated from field: string google_location_id = 1;
   */
  googleLocationId = "";

  constructor(data?: PartialMessage<AutofillAddressRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AutofillAddressRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "google_location_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutofillAddressRequest {
    return new AutofillAddressRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutofillAddressRequest {
    return new AutofillAddressRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutofillAddressRequest {
    return new AutofillAddressRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AutofillAddressRequest | PlainMessage<AutofillAddressRequest> | undefined, b: AutofillAddressRequest | PlainMessage<AutofillAddressRequest> | undefined): boolean {
    return proto3.util.equals(AutofillAddressRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.AutofillAddressResponse
 */
export class AutofillAddressResponse extends Message<AutofillAddressResponse> {
  /**
   * @generated from field: librarian.proto.LocalAddressFieldProto address = 1;
   */
  address?: LocalAddressFieldProto;

  constructor(data?: PartialMessage<AutofillAddressResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AutofillAddressResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "address", kind: "message", T: LocalAddressFieldProto },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutofillAddressResponse {
    return new AutofillAddressResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutofillAddressResponse {
    return new AutofillAddressResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutofillAddressResponse {
    return new AutofillAddressResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AutofillAddressResponse | PlainMessage<AutofillAddressResponse> | undefined, b: AutofillAddressResponse | PlainMessage<AutofillAddressResponse> | undefined): boolean {
    return proto3.util.equals(AutofillAddressResponse, a, b);
  }
}


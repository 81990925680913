import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
} from '@angular/core';
import { CountryDialCode } from '../../cache/dial-codes.service';
import { LeftyComponent, createOutput } from '../../utils';
import { LeftyListItemComponent } from '../../lefty-list/lefty-list-item.component';

@Component({
  selector: 'dial-code-list-item',
  templateUrl: 'dial-code-list-item.component.html',
  styleUrls: ['dial-code-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyListItemComponent],
})
export class DialCodeListItemComponent extends LeftyComponent {
  constructor() {
    super();
  }

  private readonly GHOST: CountryDialCode = {
    name: '',
    dial_code: '',
    code: '',
  };

  @Input()
  countryDialCode = this.GHOST;

  @Output()
  readonly triggers$ = createOutput<void>();

  get flagItemClass(): string {
    return `fi fi-${this.countryDialCode?.code.toLowerCase()}`;
  }

  get label(): string {
    return `${this.countryDialCode.name} (+${this.countryDialCode.dial_code})`;
  }
}

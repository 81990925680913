// This file defines common protocol buffers (typically, used in more than one project).

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/instagram_media.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, Timestamp } from "@bufbuild/protobuf";
import { DeletedInfo, GenerikMediaFacts, MediaResolution } from "./media_pb";
import { InstagramUser } from "./user_pb";
import { StringList } from "./common_pb";

/**
 * @generated from enum common.proto.MediaType
 */
export enum MediaType {
  /**
   * do not rename (we use them by .name() in the search index).
   *
   * @generated from enum value: IMAGE = 0;
   */
  IMAGE = 0,

  /**
   * @generated from enum value: VIDEO = 1;
   */
  VIDEO = 1,

  /**
   * @generated from enum value: BOTH = 2;
   */
  BOTH = 2,

  /**
   * @generated from enum value: STORY = 3;
   */
  STORY = 3,

  /**
   * @generated from enum value: CAROUSEL = 4;
   */
  CAROUSEL = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(MediaType)
proto3.util.setEnumType(MediaType, "common.proto.MediaType", [
  { no: 0, name: "IMAGE" },
  { no: 1, name: "VIDEO" },
  { no: 2, name: "BOTH" },
  { no: 3, name: "STORY" },
  { no: 4, name: "CAROUSEL" },
]);

/**
 * @generated from message common.proto.MediaLocation
 */
export class MediaLocation extends Message<MediaLocation> {
  /**
   * @generated from field: double latitude = 2;
   */
  latitude = 0;

  /**
   * @generated from field: double longitude = 3;
   */
  longitude = 0;

  /**
   * @generated from field: string id = 4;
   */
  id = "";

  /**
   * @generated from field: string name = 5;
   */
  name = "";

  constructor(data?: PartialMessage<MediaLocation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.MediaLocation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "latitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "longitude", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 4, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaLocation {
    return new MediaLocation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaLocation {
    return new MediaLocation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaLocation {
    return new MediaLocation().fromJsonString(jsonString, options);
  }

  static equals(a: MediaLocation | PlainMessage<MediaLocation> | undefined, b: MediaLocation | PlainMessage<MediaLocation> | undefined): boolean {
    return proto3.util.equals(MediaLocation, a, b);
  }
}

/**
 * @generated from message common.proto.Media
 */
export class Media extends Message<Media> {
  /**
   * @generated from field: common.proto.MediaResolution resolution = 1;
   */
  resolution = MediaResolution.LOW;

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * @generated from field: int32 width = 3;
   */
  width = 0;

  /**
   * @generated from field: int32 height = 4;
   */
  height = 0;

  constructor(data?: PartialMessage<Media>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Media";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "resolution", kind: "enum", T: proto3.getEnumType(MediaResolution) },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "width", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "height", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Media {
    return new Media().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Media {
    return new Media().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Media {
    return new Media().fromJsonString(jsonString, options);
  }

  static equals(a: Media | PlainMessage<Media> | undefined, b: Media | PlainMessage<Media> | undefined): boolean {
    return proto3.util.equals(Media, a, b);
  }
}

/**
 * @generated from message common.proto.MediaCaption
 */
export class MediaCaption extends Message<MediaCaption> {
  /**
   * @generated from field: google.protobuf.Timestamp creation_time = 1;
   */
  creationTime?: Timestamp;

  /**
   * @generated from field: string text = 2;
   */
  text = "";

  /**
   * @generated from field: string id = 3;
   */
  id = "";

  constructor(data?: PartialMessage<MediaCaption>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.MediaCaption";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creation_time", kind: "message", T: Timestamp },
    { no: 2, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaCaption {
    return new MediaCaption().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaCaption {
    return new MediaCaption().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaCaption {
    return new MediaCaption().fromJsonString(jsonString, options);
  }

  static equals(a: MediaCaption | PlainMessage<MediaCaption> | undefined, b: MediaCaption | PlainMessage<MediaCaption> | undefined): boolean {
    return proto3.util.equals(MediaCaption, a, b);
  }
}

/**
 * Comment on an image.
 *
 * @generated from message common.proto.MediaComment
 */
export class MediaComment extends Message<MediaComment> {
  /**
   * @generated from field: string comment_id = 1;
   */
  commentId = "";

  /**
   * @generated from field: google.protobuf.Timestamp creation_time = 2;
   */
  creationTime?: Timestamp;

  /**
   * @generated from field: string text = 3;
   */
  text = "";

  /**
   * Fields 4, 5 and 6, 8 are deprecated and should not be used again in this proto.
   *
   * @generated from field: common.proto.InstagramUser from = 7;
   */
  from?: InstagramUser;

  constructor(data?: PartialMessage<MediaComment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.MediaComment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "comment_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "creation_time", kind: "message", T: Timestamp },
    { no: 3, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "from", kind: "message", T: InstagramUser },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MediaComment {
    return new MediaComment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MediaComment {
    return new MediaComment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MediaComment {
    return new MediaComment().fromJsonString(jsonString, options);
  }

  static equals(a: MediaComment | PlainMessage<MediaComment> | undefined, b: MediaComment | PlainMessage<MediaComment> | undefined): boolean {
    return proto3.util.equals(MediaComment, a, b);
  }
}

/**
 * @generated from message common.proto.CarouselItem
 */
export class CarouselItem extends Message<CarouselItem> {
  /**
   * one and only one list will be non empty (in carousels, videos don't have images
   * like they do in regular media).
   *
   * @generated from field: common.proto.MediaType media_type = 1;
   */
  mediaType = MediaType.IMAGE;

  /**
   * @generated from field: repeated common.proto.Media image_media = 2;
   */
  imageMedia: Media[] = [];

  /**
   * @generated from field: repeated common.proto.Media video_media = 3;
   */
  videoMedia: Media[] = [];

  /**
   * @generated from field: repeated common.proto.InstagramUser users_in_photo = 4;
   */
  usersInPhoto: InstagramUser[] = [];

  /**
   * who in the list above was a sponsored (see more expensive comment on the same field of ImageMetadata);
   *
   * @generated from field: repeated int32 indices_of_sponsored = 5;
   */
  indicesOfSponsored: number[] = [];

  /**
   * This started being populated with anonymous poll.
   *
   * @generated from field: int32 video_view_count = 6;
   */
  videoViewCount = 0;

  constructor(data?: PartialMessage<CarouselItem>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CarouselItem";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "media_type", kind: "enum", T: proto3.getEnumType(MediaType) },
    { no: 2, name: "image_media", kind: "message", T: Media, repeated: true },
    { no: 3, name: "video_media", kind: "message", T: Media, repeated: true },
    { no: 4, name: "users_in_photo", kind: "message", T: InstagramUser, repeated: true },
    { no: 5, name: "indices_of_sponsored", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 6, name: "video_view_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CarouselItem {
    return new CarouselItem().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CarouselItem {
    return new CarouselItem().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CarouselItem {
    return new CarouselItem().fromJsonString(jsonString, options);
  }

  static equals(a: CarouselItem | PlainMessage<CarouselItem> | undefined, b: CarouselItem | PlainMessage<CarouselItem> | undefined): boolean {
    return proto3.util.equals(CarouselItem, a, b);
  }
}

/**
 * @generated from message common.proto.CarouselMedia
 */
export class CarouselMedia extends Message<CarouselMedia> {
  /**
   * @generated from field: repeated common.proto.CarouselItem items = 1;
   */
  items: CarouselItem[] = [];

  constructor(data?: PartialMessage<CarouselMedia>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.CarouselMedia";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "items", kind: "message", T: CarouselItem, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CarouselMedia {
    return new CarouselMedia().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CarouselMedia {
    return new CarouselMedia().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CarouselMedia {
    return new CarouselMedia().fromJsonString(jsonString, options);
  }

  static equals(a: CarouselMedia | PlainMessage<CarouselMedia> | undefined, b: CarouselMedia | PlainMessage<CarouselMedia> | undefined): boolean {
    return proto3.util.equals(CarouselMedia, a, b);
  }
}

/**
 * Instagram data for an media (image, video, story etc).
 *
 * This message models exactly what we get from insta. It is persisted as-is (except we clear comments because they take
 * up too much space) everytime we poll and ingest an image.
 *
 * If there are meta fields to be populated, put them at the Image message level.
 *
 * The only remaining exceptions are the field ingestion_time, so we set it everytime we poll a media, and pinned post
 * to avoid storing a multi level nested object
 *
 * @generated from message common.proto.ImageMetadata
 */
export class ImageMetadata extends Message<ImageMetadata> {
  /**
   * Unique id for the image.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * Media type.
   *
   * @generated from field: common.proto.MediaType media_type = 3;
   */
  mediaType = MediaType.IMAGE;

  /**
   * List of social tags if any,
   *
   * @generated from field: repeated string tags = 4;
   */
  tags: string[] = [];

  /**
   * Creation timestamp.
   *
   * @generated from field: google.protobuf.Timestamp creation_time = 5;
   */
  creationTime?: Timestamp;

  /**
   * Location where the media was taken.
   *
   * @generated from field: common.proto.MediaLocation location = 6;
   */
  location?: MediaLocation;

  /**
   * Media
   *
   * @generated from field: repeated common.proto.Media media = 7;
   */
  media: Media[] = [];

  /**
   * Video media (media above is also filled: it's the first image of the video).
   *
   * @generated from field: repeated common.proto.Media video_media = 17;
   */
  videoMedia: Media[] = [];

  /**
   * Caption if any.
   *
   * @generated from field: common.proto.MediaCaption caption = 8;
   */
  caption?: MediaCaption;

  /**
   * Used filter.
   *
   * @generated from field: string filter = 9;
   */
  filter = "";

  /**
   * Author of the media.
   *
   * @generated from field: common.proto.InstagramUser user = 10;
   */
  user?: InstagramUser;

  /**
   * Number of likes.
   *
   * @generated from field: int32 likes_count = 11;
   */
  likesCount = 0;

  /**
   * Number of comments.
   *
   * @generated from field: int32 comments_count = 12;
   */
  commentsCount = 0;

  /**
   * Instagram Link, Twitter Link ...  as original in source media
   *
   * @generated from field: string link = 13;
   */
  link = "";

  /**
   * Comments on the image.
   *
   * @generated from field: repeated common.proto.MediaComment comment = 16;
   */
  comment: MediaComment[] = [];

  /**
   * @generated from field: repeated common.proto.InstagramUser users_in_photo = 19;
   */
  usersInPhoto: InstagramUser[] = [];

  /**
   * @generated from field: repeated common.proto.InstagramUser coauthor_producers = 26;
   */
  coauthorProducers: InstagramUser[] = [];

  /**
   * @generated from field: repeated common.proto.InstagramUser invited_coauthor_producers = 27;
   */
  invitedCoauthorProducers: InstagramUser[] = [];

  /**
   * Whose items in the list above were a sponsor_user
   *
   * Historically instagram only added users tagged in photos. Later they added sponsor users,
   * we did parse it and merged it into 'users_in_photo'. This was fine but the precious information that this was sponsored
   * was lost.
   *
   * In the new anonymous polling, we will still merge the lists into 'users_in_photo' (so everything else works as expected)
   * but we will also populate this field, which would then allow to reconstruct who was a sponsor.
   *
   * @generated from field: repeated int32 indices_of_sponsored = 21;
   */
  indicesOfSponsored: number[] = [];

  /**
   * @generated from field: common.proto.CarouselMedia carousel_media = 20;
   */
  carouselMedia?: CarouselMedia;

  /**
   * This has obviously only meaning if video.
   *
   * @generated from field: int32 video_view_count = 22;
   */
  videoViewCount = 0;

  /**
   * This has only meaning if reel.
   *
   * @generated from field: int32 video_play_count = 25;
   */
  videoPlayCount = 0;

  /**
   * Whether the post is pinned on the creator feed or not. We get the info from INSTA in nested objects but persist it
   * on the top level to avoid storing fat list that we would have to parse and filter everytime time we poll posts.
   * Since 26/09/2022
   *
   * @generated from field: bool pinned_post = 23;
   */
  pinnedPost = false;

  /**
   * @generated from field: bool sponsored_post = 24;
   */
  sponsoredPost = false;

  /**
   * DEPRECATED_FOR_FACTS
   *
   * @generated from field: google.protobuf.Timestamp ingestion_time = 101;
   */
  ingestionTime?: Timestamp;

  /**
   * Since 09/06/2023
   * For now, it's similar to having video_play_count
   *
   * @generated from field: bool reel = 105;
   */
  reel = false;

  constructor(data?: PartialMessage<ImageMetadata>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ImageMetadata";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "media_type", kind: "enum", T: proto3.getEnumType(MediaType) },
    { no: 4, name: "tags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "creation_time", kind: "message", T: Timestamp },
    { no: 6, name: "location", kind: "message", T: MediaLocation },
    { no: 7, name: "media", kind: "message", T: Media, repeated: true },
    { no: 17, name: "video_media", kind: "message", T: Media, repeated: true },
    { no: 8, name: "caption", kind: "message", T: MediaCaption },
    { no: 9, name: "filter", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "user", kind: "message", T: InstagramUser },
    { no: 11, name: "likes_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "comments_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 13, name: "link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "comment", kind: "message", T: MediaComment, repeated: true },
    { no: 19, name: "users_in_photo", kind: "message", T: InstagramUser, repeated: true },
    { no: 26, name: "coauthor_producers", kind: "message", T: InstagramUser, repeated: true },
    { no: 27, name: "invited_coauthor_producers", kind: "message", T: InstagramUser, repeated: true },
    { no: 21, name: "indices_of_sponsored", kind: "scalar", T: 5 /* ScalarType.INT32 */, repeated: true },
    { no: 20, name: "carousel_media", kind: "message", T: CarouselMedia },
    { no: 22, name: "video_view_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 25, name: "video_play_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 23, name: "pinned_post", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 24, name: "sponsored_post", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 101, name: "ingestion_time", kind: "message", T: Timestamp },
    { no: 105, name: "reel", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImageMetadata {
    return new ImageMetadata().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImageMetadata {
    return new ImageMetadata().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImageMetadata {
    return new ImageMetadata().fromJsonString(jsonString, options);
  }

  static equals(a: ImageMetadata | PlainMessage<ImageMetadata> | undefined, b: ImageMetadata | PlainMessage<ImageMetadata> | undefined): boolean {
    return proto3.util.equals(ImageMetadata, a, b);
  }
}

/**
 * @generated from message common.proto.ImageMetadataResponse
 */
export class ImageMetadataResponse extends Message<ImageMetadataResponse> {
  /**
   * @generated from field: repeated common.proto.ImageMetadata posts = 1;
   */
  posts: ImageMetadata[] = [];

  /**
   * @generated from field: string max_id = 2;
   */
  maxId = "";

  constructor(data?: PartialMessage<ImageMetadataResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ImageMetadataResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "posts", kind: "message", T: ImageMetadata, repeated: true },
    { no: 2, name: "max_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImageMetadataResponse {
    return new ImageMetadataResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImageMetadataResponse {
    return new ImageMetadataResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImageMetadataResponse {
    return new ImageMetadataResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ImageMetadataResponse | PlainMessage<ImageMetadataResponse> | undefined, b: ImageMetadataResponse | PlainMessage<ImageMetadataResponse> | undefined): boolean {
    return proto3.util.equals(ImageMetadataResponse, a, b);
  }
}

/**
 * @generated from message common.proto.Story
 */
export class Story extends Message<Story> {
  /**
   * @generated from field: common.proto.InstagramUser user = 1;
   */
  user?: InstagramUser;

  /**
   * @generated from field: repeated common.proto.ImageMetadata story_element = 2;
   */
  storyElement: ImageMetadata[] = [];

  constructor(data?: PartialMessage<Story>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Story";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user", kind: "message", T: InstagramUser },
    { no: 2, name: "story_element", kind: "message", T: ImageMetadata, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Story {
    return new Story().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Story {
    return new Story().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Story {
    return new Story().fromJsonString(jsonString, options);
  }

  static equals(a: Story | PlainMessage<Story> | undefined, b: Story | PlainMessage<Story> | undefined): boolean {
    return proto3.util.equals(Story, a, b);
  }
}

/**
 * @generated from message common.proto.Stories
 */
export class Stories extends Message<Stories> {
  /**
   * @generated from field: repeated common.proto.Story story = 1;
   */
  story: Story[] = [];

  constructor(data?: PartialMessage<Stories>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Stories";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "story", kind: "message", T: Story, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Stories {
    return new Stories().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Stories {
    return new Stories().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Stories {
    return new Stories().fromJsonString(jsonString, options);
  }

  static equals(a: Stories | PlainMessage<Stories> | undefined, b: Stories | PlainMessage<Stories> | undefined): boolean {
    return proto3.util.equals(Stories, a, b);
  }
}

/**
 * @generated from message common.proto.ScoredCategory
 */
export class ScoredCategory extends Message<ScoredCategory> {
  /**
   * @generated from field: string category = 1;
   */
  category = "";

  /**
   * @generated from field: float score = 2;
   */
  score = 0;

  constructor(data?: PartialMessage<ScoredCategory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ScoredCategory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ScoredCategory {
    return new ScoredCategory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ScoredCategory {
    return new ScoredCategory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ScoredCategory {
    return new ScoredCategory().fromJsonString(jsonString, options);
  }

  static equals(a: ScoredCategory | PlainMessage<ScoredCategory> | undefined, b: ScoredCategory | PlainMessage<ScoredCategory> | undefined): boolean {
    return proto3.util.equals(ScoredCategory, a, b);
  }
}

/**
 * This message mirrors the cassandra IMAGES table (each field is a column).
 *
 * @generated from message common.proto.Image
 */
export class Image extends Message<Image> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: common.proto.ImageMetadata metadata = 2;
   */
  metadata?: ImageMetadata;

  /**
   * DEPRECATED_FOR_FACTS
   *
   * @generated from field: common.proto.DeletedInfo deleted_info = 20;
   */
  deletedInfo?: DeletedInfo;

  /**
   * categories generated with Topico
   *
   * @generated from field: common.proto.StringList lefty_categories = 30;
   */
  leftyCategories?: StringList;

  /**
   * categories generated by the GoogleVision API
   *
   * @generated from field: repeated common.proto.ScoredCategory google_categories = 31;
   */
  googleCategories: ScoredCategory[] = [];

  /**
   * version of the post topico algorithm at the time of post categorization
   *
   * @generated from field: int32 lefty_categories_version = 32;
   */
  leftyCategoriesVersion = 0;

  /**
   * @generated from field: common.proto.GenerikMediaFacts facts = 33;
   */
  facts?: GenerikMediaFacts;

  /**
   * @generated from field: common.proto.StringList co_authors_ids = 34;
   */
  coAuthorsIds?: StringList;

  constructor(data?: PartialMessage<Image>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Image";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "metadata", kind: "message", T: ImageMetadata },
    { no: 20, name: "deleted_info", kind: "message", T: DeletedInfo },
    { no: 30, name: "lefty_categories", kind: "message", T: StringList },
    { no: 31, name: "google_categories", kind: "message", T: ScoredCategory, repeated: true },
    { no: 32, name: "lefty_categories_version", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 33, name: "facts", kind: "message", T: GenerikMediaFacts },
    { no: 34, name: "co_authors_ids", kind: "message", T: StringList },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Image {
    return new Image().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Image {
    return new Image().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Image {
    return new Image().fromJsonString(jsonString, options);
  }

  static equals(a: Image | PlainMessage<Image> | undefined, b: Image | PlainMessage<Image> | undefined): boolean {
    return proto3.util.equals(Image, a, b);
  }
}

/**
 * This message  mirrors the hbase IMAGES_DATA table  (each field is a column).
 *
 * @generated from message common.proto.ImageData
 */
export class ImageData extends Message<ImageData> {
  /**
   * For images, only data is set (and it's the image).
   * For videos, data contains the video's first image, and
   * video_data contains the full video.
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: bytes data = 2;
   */
  data = new Uint8Array(0);

  /**
   * @generated from field: bytes video_data = 3;
   */
  videoData = new Uint8Array(0);

  /**
   * Those are preferred in lists where we want loading to be fast.
   * PS: This is especially important for stories since their thumbnails disappear
   * from insta after 24h (just like the main data).
   *
   * @generated from field: bytes data_thumbnail = 4;
   */
  dataThumbnail = new Uint8Array(0);

  /**
   * @generated from field: bytes video_data_thumbnail = 5;
   */
  videoDataThumbnail = new Uint8Array(0);

  constructor(data?: PartialMessage<ImageData>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.ImageData";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 3, name: "video_data", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 4, name: "data_thumbnail", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
    { no: 5, name: "video_data_thumbnail", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImageData {
    return new ImageData().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImageData {
    return new ImageData().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImageData {
    return new ImageData().fromJsonString(jsonString, options);
  }

  static equals(a: ImageData | PlainMessage<ImageData> | undefined, b: ImageData | PlainMessage<ImageData> | undefined): boolean {
    return proto3.util.equals(ImageData, a, b);
  }
}


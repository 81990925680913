import {
  Code,
  ConnectError,
  Interceptor,
  StreamRequest,
  StreamResponse,
  UnaryRequest,
  UnaryResponse,
} from '@connectrpc/connect';
import { FrontendMisc } from '@frontend2/proto/librarian/proto/frontend_misc_connect';

function _isLoginCall(req: UnaryRequest | StreamRequest): boolean {
  return (
    req.service.typeName === FrontendMisc.typeName &&
    req.method.name === FrontendMisc.methods.logAPI.name
  );
}

export const unauthenticatedInterceptor: Interceptor = (next) => {
  return async (req): Promise<UnaryResponse | StreamResponse> => {
    try {
      const res = await next(req);
      return res;
    } catch (e) {
      if (e instanceof ConnectError) {
        if (e.code === Code.Unauthenticated && _isLoginCall(req) === false) {
          // reload the app
          window.location.href = window.location.origin;
        }
      }
      throw e;
    }
  };
};

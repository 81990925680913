<div class="form-input">
  <span
    class="form-label"
    [class.large]="large"
    *ngIf="showLabelContainer"
  >
    {{ label }}
    <span
      *ngIf="optional"
      class="optional"
      >(optional)</span
    >
    <span class="fill-space"></span>
    <a
      class="help-text"
      *ngIf="helpLink"
      tabindex="-1"
      [leftyRouterLink]="helpLink"
      >{{ helpText ? helpText : helpLink }}</a
    >
    <a
      class="help-text"
      *ngIf="externalHelpLink"
      tabindex="-1"
      target="_blank"
      [attr.href]="externalHelpLink"
      >{{ helpText ? helpText : externalHelpLink }}</a
    >
    <help-icon
      *ngIf="showTooltip"
      [leftyTooltip]="helpText"
    >
    </help-icon>
    <ng-content select="[action]"></ng-content>
  </span>

  <div
    *ngIf="showTopHint"
    class="message hint top"
    [class.large]="large"
  >
    <span>{{ hintText }}</span>
  </div>

  <ng-content></ng-content>

  <div
    class="message error"
    *ngIf="showErrorMessage"
  >
    <lefty-icon
      class="error-icon"
      icon="error"
      size="x-large"
    ></lefty-icon>
    <span>{{ errorMessage }}</span>
  </div>

  <div
    *ngIf="showBottomHint"
    class="message hint"
  >
    <span>{{ hintText }}</span>
  </div>
</div>

import { Injectable, inject } from '@angular/core';
import { updateElementInArray } from '@frontend2/core';
import { Bloc } from '../bloc';
import { injectDestroyRef } from '../inject.helpers';
import {
  DEFAULT_TOAST_DURATION,
  TOAST_UNLIMITED_DURATION,
  Toast,
  ToastOptions,
  ToastUpdate,
} from './toast.models';

@Injectable({
  providedIn: 'root',
})
export class ToastManager extends Bloc<Toast[]> {
  constructor() {
    super([]);

    injectDestroyRef().onDestroy(() => this.dispose());
  }

  private idCounter = 0;

  private timers: {
    [key: string]: number;
  } = {};

  // use a prefix to be sure we never collide
  // with manually passed id
  private nextId(): string {
    return `toast:${this.idCounter++}`;
  }

  private startTimer(toastId: string, duration: number): void {
    clearTimeout(this.timers[toastId]);

    if (duration !== TOAST_UNLIMITED_DURATION) {
      this.timers[toastId] = window.setTimeout(
        () => this.close(toastId),
        duration,
      );
    }
  }

  private stopTimer(toastId: string): void {
    clearTimeout(this.timers[toastId]);
    delete this.timers[toastId];
  }

  show(toast: Toast): Toast {
    this.updateState((state) => [...state, toast]);
    this.startTimer(toast.id, toast.duration ?? DEFAULT_TOAST_DURATION);
    return toast;
  }

  showSuccess(text: string, options?: ToastOptions): Toast {
    return this.show(Toast.success(this.nextId(), text, options));
  }

  showError(text: string, options?: ToastOptions): Toast {
    return this.show(Toast.error(this.nextId(), text, options));
  }

  showLoading(text: string, options?: ToastOptions): Toast {
    return this.show(Toast.loading(this.nextId(), text, options));
  }

  showInfo(text: string, options?: ToastOptions): Toast {
    return this.show(Toast.info(this.nextId(), text, options));
  }

  update(id: string, toastUpdate: ToastUpdate): void {
    const newToasts = updateElementInArray(this.state(), {
      predicate: (t) => t.id === id,
      modifier: (t) => {
        return {
          ...t,
          text: toastUpdate.text ?? t?.text,
          type: toastUpdate.type ?? t?.type,
          progress: toastUpdate.progress ?? t.progress,
          duration: toastUpdate.duration ?? DEFAULT_TOAST_DURATION,
        };
      },
    });

    this.setState(newToasts);
  }

  close(toastId: string): void {
    this.setState(this.state().filter((t) => t.id !== toastId));
    this.stopTimer(toastId);
  }

  dispose(): void {
    Object.keys(this.timers).forEach((id) => this.stopTimer(id));
  }
}

export function injectToastManager(): ToastManager {
  return inject(ToastManager);
}

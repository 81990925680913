import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Output,
  computed,
  inject,
  input,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { isNil, isNotEmptyString } from '@frontend2/core';
import { ObjectFit } from '../utils';

/// Video component that test the given
/// [src] url before showing it
/// and show placeholder during test
///
/// If video src is not found, it use `fallbackUrl` as src
///
/// Can pass list of video to `urls` @Input if you have multiple video to test
@Component({
  selector: 'lefty-safe-video',
  template: `<video
    #video
    preload="metadata"
    [style.objectFit]="objectFit()"
    [muted]="muted()"
  ></video>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['video.component.scss'],
  standalone: true,
})
export class LeftySafeVideoComponent implements AfterViewInit, OnDestroy {
  readonly elementRef = inject(ElementRef<HTMLElement>);

  private readonly _loading = signal(false);

  @Output()
  readonly loading$ = toObservable(this._loading);

  private readonly _visible = signal(false);

  @Output()
  readonly visible$ = toObservable(this._visible);

  @HostBinding('class.visible')
  get isVisible(): boolean {
    return this._visible();
  }

  @HostBinding('class.ghost')
  get isGhost(): boolean {
    return this._loading();
  }

  private timeoutId?: number;
  private urlIndex = 0;
  private videoLoaded = false;

  readonly error$ = output<Event | 'timeout'>();
  readonly load$ = output<Event>();
  readonly fallbackUrl = input<string | undefined>();

  readonly muted = input(false);

  readonly objectFit = input<ObjectFit>('cover');

  readonly videoElRef = viewChild<ElementRef<HTMLVideoElement>>('video');
  readonly videoEl = computed(() => this.videoElRef()?.nativeElement);

  private _urls: string[] = [];

  @Input()
  set src(url: string | undefined) {
    if (url) {
      this.urls = [url];
    } else {
      this.urls = [];
    }
  }

  @Input()
  set urls(values: string[]) {
    this._loadUrls(values);
  }

  private _loadUrls(urls: string[]): void {
    this._urls = urls.filter(isNotEmptyString);

    clearTimeout(this.timeoutId);

    this.urlIndex = 0;
    this.videoLoaded = false;

    const url = this._getUrlToTest(this.urlIndex);
    if (isNotEmptyString(url)) {
      this.loadUrl(url);
    } else {
      this._visible.set(false);
    }
  }

  private _unsubscribeLoadEvent?: () => void;
  private _unsubscribeErrorEvent?: () => void;

  private handleError(event: Event | 'timeout'): void {
    this.urlIndex++;

    const url = this._getUrlToTest(this.urlIndex);
    if (isNotEmptyString(url)) {
      this.loadUrl(url);
    } else {
      this._loading.set(false);
      this._visible.set(false);
      this.error$.emit(event);
    }
  }

  private handleLoad(event: Event): void {
    this.videoLoaded = true;

    this._loading.set(false);
    this._visible.set(true);

    this.load$.emit(event);
  }

  private loadUrl(url: string): void {
    const videoEl = this.videoEl();

    if (this.videoLoaded || isNil(videoEl)) {
      return;
    }

    this._loading.set(true);
    this._visible.set(true);

    this._unsubscribeLoadEvent?.call(this);
    this._unsubscribeErrorEvent?.call(this);

    clearTimeout(this.timeoutId);
    this.timeoutId = window.setTimeout(() => this.handleError('timeout'), 5000);

    const loadCallback = (e: Event): void => {
      this.handleLoad(e);
      clearTimeout(this.timeoutId);
    };

    videoEl.addEventListener('loadedmetadata', loadCallback);
    this._unsubscribeLoadEvent = (): void => {
      videoEl.removeEventListener('loadedmetadata', loadCallback);
    };

    const errorCallback = (e: Event): void => {
      this.handleError(e);
      clearTimeout(this.timeoutId);
    };

    videoEl.addEventListener('error', errorCallback);
    this._unsubscribeErrorEvent = (): void => {
      videoEl.removeEventListener('error', errorCallback);
    };

    videoEl.src = url;
  }

  ngAfterViewInit(): void {
    const url = this._getUrlToTest(this.urlIndex);
    if (isNotEmptyString(url)) {
      this.loadUrl(url);
    }
  }

  ngOnDestroy(): void {
    this._unsubscribeLoadEvent?.call(this);
    this._unsubscribeErrorEvent?.call(this);
    clearTimeout(this.timeoutId);
  }

  private _getUrlToTest(index: number): string | undefined {
    const urls = this._urls;
    return urls.length === 0 || index >= urls.length
      ? this.fallbackUrl()
      : urls[index];
  }

  isPlaying(): boolean {
    return this.videoEl()?.paused === false;
  }

  play(): void {
    this.videoEl()?.play();
  }

  pause(): void {
    this.videoEl()?.pause();
  }

  togglePlay(): void {
    if (this.isPlaying()) {
      this.pause();
    } else {
      this.play();
    }
  }
}

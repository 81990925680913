<div
  *ngIf="!editing"
  class="al-card comment-card"
  [class.with-border]="mobile"
  [class.mobile]="mobile"
>
  <div class="header">
    <div
      *ngIf="isUser"
      class="initial"
      [style.background]="initialBackgroundColor"
      [style.color]="initialColor"
    >
      {{ initial }}
    </div>
    <span class="author">
      {{ email }}
    </span>
    <span class="time">
      {{ formattedDate }}
    </span>

    <div
      class="actions"
      *ngIf="!loading && comment.editable"
    >
      <button
        class="icon ghost"
        (click)="startEdit()"
      >
        <lefty-icon icon="edit"></lefty-icon>
      </button>
      <button
        class="icon ghost"
        (click)="deleteComment$.emit(comment.noteId)"
      >
        <lefty-icon icon="delete"></lefty-icon>
      </button>
    </div>
  </div>
  <p>{{ comment.noteContent }}</p>
</div>

<div
  *ngIf="editing"
  class="comment-text-field-container"
>
  <lefty-form-textarea
    class="textarea"
    [(value)]="editingText"
    [autoresize]="true"
    [rows]="3"
  ></lefty-form-textarea>
  <div class="actions">
    <button
      class="small"
      [disabled]="loading"
      (click)="stopEdit()"
    >
      {{ Messages.cancel }}
    </button>
    <button
      class="primary small"
      [disabled]="loading"
      (click)="submit()"
    >
      <lefty-spinner *ngIf="loading"></lefty-spinner>
      {{ Messages.save }}
    </button>
  </div>
</div>

import { isNil, isNotEmptyString } from '@frontend2/core';

function extractNumber(val: string | undefined): string {
  if (isNil(val)) {
    return '';
  }
  return val.replaceAll(/\D/g, '');
}

function normalizePhoneNumber(val: string): string {
  if (val.startsWith('0')) {
    return val.substring(1);
  }
  return val;
}

export interface Phone {
  readonly dialCode: string;
  readonly phoneNumber: string;
}

export function createPhone(args?: Partial<Phone>): Phone {
  return {
    dialCode: args?.dialCode ?? '',
    phoneNumber: args?.phoneNumber ?? '',
  };
}

export function isPhone(obj: unknown): obj is Phone {
  if (typeof obj !== 'object' || isNil(obj)) {
    return false;
  }
  return 'dialCode' in obj && 'phoneNumber' in obj;
}

export function parsePhoneString(phone: string): Phone {
  phone = phone.replaceAll('-', ' ');

  if (phone.startsWith('+') === false) {
    return createPhone({
      phoneNumber: phone.replaceAll(' ', ''),
    });
  }

  const phoneParts = phone.split(' ').map(extractNumber);

  if (phoneParts.length === 1) {
    return createPhone({
      dialCode: phoneParts[0],
    });
  }

  if (phoneParts.length >= 2) {
    return createPhone({
      dialCode: phoneParts[0],
      phoneNumber: normalizePhoneNumber(phoneParts.slice(1).join('')),
    });
  }

  return createPhone();
}

export function toPhoneNumberString(phone: Phone): string {
  const { dialCode, phoneNumber } = phone;
  const parts = [];

  if (isNotEmptyString(phoneNumber)) {
    if (isNotEmptyString(dialCode)) {
      parts.push(`+${dialCode}`);
    }

    parts.push(`${phoneNumber}`);
  }

  return parts.join(' ');
}

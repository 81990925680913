// protobufs for giovanni.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file universal/stories/proto/poller.proto (package universal.stories.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Duration, Int32Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { BasicPagination } from "../../../common/proto/common_pb";

/**
 * @generated from message universal.stories.proto.InfluencerPollReq
 */
export class InfluencerPollReq extends Message<InfluencerPollReq> {
  /**
   * @generated from field: string meta_id = 1;
   */
  metaId = "";

  /**
   * Those can be used to restrict the request between 2 given dates but they are not mandatory.
   *
   * @generated from field: google.protobuf.Timestamp after = 2;
   */
  after?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp before = 3;
   */
  before?: Timestamp;

  /**
   * TODO(mateo) remove when front has been migrated
   *
   * @generated from field: common.proto.BasicPagination pagination = 4;
   */
  pagination?: BasicPagination;

  constructor(data?: PartialMessage<InfluencerPollReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.stories.proto.InfluencerPollReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "meta_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "after", kind: "message", T: Timestamp },
    { no: 3, name: "before", kind: "message", T: Timestamp },
    { no: 4, name: "pagination", kind: "message", T: BasicPagination },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerPollReq {
    return new InfluencerPollReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerPollReq {
    return new InfluencerPollReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerPollReq {
    return new InfluencerPollReq().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerPollReq | PlainMessage<InfluencerPollReq> | undefined, b: InfluencerPollReq | PlainMessage<InfluencerPollReq> | undefined): boolean {
    return proto3.util.equals(InfluencerPollReq, a, b);
  }
}

/**
 * @generated from message universal.stories.proto.InfluencerPollPoint
 */
export class InfluencerPollPoint extends Message<InfluencerPollPoint> {
  /**
   * @generated from field: google.protobuf.Timestamp created = 1;
   */
  created?: Timestamp;

  /**
   * @generated from field: bool done = 2;
   */
  done = false;

  /**
   * @generated from field: google.protobuf.Int32Value status_code = 3;
   */
  statusCode?: number;

  /**
   * @generated from field: google.protobuf.StringValue status_message = 4;
   */
  statusMessage?: string;

  /**
   * @generated from field: int32 stories_retrieved = 5;
   */
  storiesRetrieved = 0;

  /**
   * @generated from field: google.protobuf.Timestamp done_time = 6;
   */
  doneTime?: Timestamp;

  /**
   * @generated from field: repeated universal.stories.proto.InfluencerPollPoint.CampaignOfPoll polled_on_behalf_of_campaigns = 7;
   */
  polledOnBehalfOfCampaigns: InfluencerPollPoint_CampaignOfPoll[] = [];

  /**
   * @generated from field: universal.stories.proto.InfluencerPollPoint.PollerRun polling_run = 8;
   */
  pollingRun?: InfluencerPollPoint_PollerRun;

  constructor(data?: PartialMessage<InfluencerPollPoint>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.stories.proto.InfluencerPollPoint";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "created", kind: "message", T: Timestamp },
    { no: 2, name: "done", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "status_code", kind: "message", T: Int32Value },
    { no: 4, name: "status_message", kind: "message", T: StringValue },
    { no: 5, name: "stories_retrieved", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 6, name: "done_time", kind: "message", T: Timestamp },
    { no: 7, name: "polled_on_behalf_of_campaigns", kind: "message", T: InfluencerPollPoint_CampaignOfPoll, repeated: true },
    { no: 8, name: "polling_run", kind: "message", T: InfluencerPollPoint_PollerRun },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerPollPoint {
    return new InfluencerPollPoint().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerPollPoint {
    return new InfluencerPollPoint().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerPollPoint {
    return new InfluencerPollPoint().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerPollPoint | PlainMessage<InfluencerPollPoint> | undefined, b: InfluencerPollPoint | PlainMessage<InfluencerPollPoint> | undefined): boolean {
    return proto3.util.equals(InfluencerPollPoint, a, b);
  }
}

/**
 * @generated from message universal.stories.proto.InfluencerPollPoint.CampaignOfPoll
 */
export class InfluencerPollPoint_CampaignOfPoll extends Message<InfluencerPollPoint_CampaignOfPoll> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string customer_email = 3;
   */
  customerEmail = "";

  constructor(data?: PartialMessage<InfluencerPollPoint_CampaignOfPoll>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.stories.proto.InfluencerPollPoint.CampaignOfPoll";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "customer_email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerPollPoint_CampaignOfPoll {
    return new InfluencerPollPoint_CampaignOfPoll().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerPollPoint_CampaignOfPoll {
    return new InfluencerPollPoint_CampaignOfPoll().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerPollPoint_CampaignOfPoll {
    return new InfluencerPollPoint_CampaignOfPoll().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerPollPoint_CampaignOfPoll | PlainMessage<InfluencerPollPoint_CampaignOfPoll> | undefined, b: InfluencerPollPoint_CampaignOfPoll | PlainMessage<InfluencerPollPoint_CampaignOfPoll> | undefined): boolean {
    return proto3.util.equals(InfluencerPollPoint_CampaignOfPoll, a, b);
  }
}

/**
 * @generated from message universal.stories.proto.InfluencerPollPoint.PollerRun
 */
export class InfluencerPollPoint_PollerRun extends Message<InfluencerPollPoint_PollerRun> {
  /**
   * @generated from field: int64 run_id = 1;
   */
  runId = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.Timestamp started_time = 2;
   */
  startedTime?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp done_time = 3;
   */
  doneTime?: Timestamp;

  constructor(data?: PartialMessage<InfluencerPollPoint_PollerRun>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.stories.proto.InfluencerPollPoint.PollerRun";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "run_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "started_time", kind: "message", T: Timestamp },
    { no: 3, name: "done_time", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerPollPoint_PollerRun {
    return new InfluencerPollPoint_PollerRun().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerPollPoint_PollerRun {
    return new InfluencerPollPoint_PollerRun().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerPollPoint_PollerRun {
    return new InfluencerPollPoint_PollerRun().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerPollPoint_PollerRun | PlainMessage<InfluencerPollPoint_PollerRun> | undefined, b: InfluencerPollPoint_PollerRun | PlainMessage<InfluencerPollPoint_PollerRun> | undefined): boolean {
    return proto3.util.equals(InfluencerPollPoint_PollerRun, a, b);
  }
}

/**
 * @generated from message universal.stories.proto.InfluencerPollPoints
 */
export class InfluencerPollPoints extends Message<InfluencerPollPoints> {
  /**
   * @generated from field: repeated universal.stories.proto.InfluencerPollPoint points = 1;
   */
  points: InfluencerPollPoint[] = [];

  /**
   * Next time we expect to poll this influencer. Estimation is based on our position in the list, our current polling
   * rate and the assumption that such rate will remain constant.
   *
   * Note that this doesn't look at whether the influencer will still be in active campaigns at that time, so we may predict
   * a next polling time in 20 hours but if that's tomorrow and they this is their last day of polling, it won't really
   * happen.
   *
   * Caller should check hasExpectedNextPoll() as this will not necessarily be set (for instance if the influencer is not
   * being polled).
   *
   * Viewers of this should also be aware that if the warning of polling rate is not stable yet, those will suffer from
   * the same issues.
   *
   * @generated from field: google.protobuf.Timestamp expected_next_poll = 2;
   */
  expectedNextPoll?: Timestamp;

  constructor(data?: PartialMessage<InfluencerPollPoints>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.stories.proto.InfluencerPollPoints";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "points", kind: "message", T: InfluencerPollPoint, repeated: true },
    { no: 2, name: "expected_next_poll", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InfluencerPollPoints {
    return new InfluencerPollPoints().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InfluencerPollPoints {
    return new InfluencerPollPoints().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InfluencerPollPoints {
    return new InfluencerPollPoints().fromJsonString(jsonString, options);
  }

  static equals(a: InfluencerPollPoints | PlainMessage<InfluencerPollPoints> | undefined, b: InfluencerPollPoints | PlainMessage<InfluencerPollPoints> | undefined): boolean {
    return proto3.util.equals(InfluencerPollPoints, a, b);
  }
}

/**
 * @generated from message universal.stories.proto.AuditGiovannisResp
 */
export class AuditGiovannisResp extends Message<AuditGiovannisResp> {
  /**
   * How many unique giovannis have been used in the past minutes.
   *
   * @generated from field: int32 giovannis_used_recently = 1;
   */
  giovannisUsedRecently = 0;

  /**
   * How many were expected (the frontend shall display a warning if giovannis_used_recently < expected).
   *
   * @generated from field: int32 expected = 2;
   */
  expected = 0;

  /**
   * @generated from field: google.protobuf.Duration sleep_average = 3;
   */
  sleepAverage?: Duration;

  /**
   * @generated from field: google.protobuf.Duration grounding_time = 5;
   */
  groundingTime?: Duration;

  /**
   * @generated from field: int32 theoretical_daily_capacity = 4;
   */
  theoreticalDailyCapacity = 0;

  constructor(data?: PartialMessage<AuditGiovannisResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "universal.stories.proto.AuditGiovannisResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "giovannis_used_recently", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "expected", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "sleep_average", kind: "message", T: Duration },
    { no: 5, name: "grounding_time", kind: "message", T: Duration },
    { no: 4, name: "theoretical_daily_capacity", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AuditGiovannisResp {
    return new AuditGiovannisResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AuditGiovannisResp {
    return new AuditGiovannisResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AuditGiovannisResp {
    return new AuditGiovannisResp().fromJsonString(jsonString, options);
  }

  static equals(a: AuditGiovannisResp | PlainMessage<AuditGiovannisResp> | undefined, b: AuditGiovannisResp | PlainMessage<AuditGiovannisResp> | undefined): boolean {
    return proto3.util.equals(AuditGiovannisResp, a, b);
  }
}


import { Injectable, computed } from '@angular/core';
import { CacheBloc } from '../bloc';

export interface CountryDialCode {
  readonly name: string;
  readonly dial_code: string;
  readonly code: string;
}

export interface CountryDialCodeCacheState {
  [key: string]: CountryDialCode;
}

@Injectable({ providedIn: 'root' })
export class CountryDialCodeCache extends CacheBloc<CountryDialCodeCacheState> {
  constructor() {
    super({});
  }

  readonly dialCodesList = computed(() => Object.values(this.cachedData()));

  getPhonePrefixByCountryCode(code: string): CountryDialCode | undefined {
    return this.cachedData()[code];
  }

  getPhonePrefixByDialCode(dialCode: string): CountryDialCode | undefined {
    return this.dialCodesList().find(
      (countryDialCode) =>
        countryDialCode.dial_code.toLowerCase() === dialCode.toLowerCase(),
    );
  }

  async fetch(): Promise<CountryDialCodeCacheState> {
    const response = (
      await import('packages/lefty-assets/src/lib/db/dial-codes.json')
    ).default;
    const dialCodes: CountryDialCodeCacheState = {};
    response.forEach((l) => {
      dialCodes[l.code] = l;
    });

    return dialCodes;
  }
}

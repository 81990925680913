// This defines a service to load in app chat conversations: 1:1 to an influencer, all of a campaign, all of a workspace.

// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/chat.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { MethodKind, StringValue } from "@bufbuild/protobuf";
import { BulkMessageReq, InfluencerConversationResp, MultiConversationReq, WorkspaceConversationResp } from "./chat_pb";

/**
 * @generated from service librarian.proto.LibrarianChat
 */
export const LibrarianChat = {
  typeName: "librarian.proto.LibrarianChat",
  methods: {
    /**
     * Endpoint for the 1:1 conversation between the lefty client and an influencer.
     *
     * The argument is the influencer's lefty_id.
     *
     * @generated from rpc librarian.proto.LibrarianChat.LoadSingleInfluencerConversation
     */
    loadSingleInfluencerConversation: {
      name: "LoadSingleInfluencerConversation",
      I: StringValue,
      O: InfluencerConversationResp,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianChat.LoadWorkspaceConversations
     */
    loadWorkspaceConversations: {
      name: "LoadWorkspaceConversations",
      I: MultiConversationReq,
      O: WorkspaceConversationResp,
      kind: MethodKind.Unary,
    },
    /**
     * returns bronco handle.
     *
     * @generated from rpc librarian.proto.LibrarianChat.BulkMessage
     */
    bulkMessage: {
      name: "BulkMessage",
      I: BulkMessageReq,
      O: StringValue,
      kind: MethodKind.Unary,
    },
  }
} as const;


// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/admin_cw.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { Empty, Int64Value, MethodKind, StringValue } from "@bufbuild/protobuf";
import { CWBrandPb, CWBrands, CWIndustriesByCategory, CWMarket } from "./competitive_watch_pb";
import { AddExcludedInfluencersToReportRequest, AddExcludedInfluencersToReportsOfNetworkRequest, AutocompleteCampaignsRequest, AutocompleteCampaignsResponse, AutocompleteCwBrandsRequest, BrandMarketSpec, BrandResponse, CWAddSourcesRequest, CWCountSourceRequest, CWCreateBrandRequest, CWCreateOrEditReportResponse, CWCreateOrEditSetGroupRequest, CWCreateSetRequest, CWEditSetGroupsRequest, CWEditSetRequest, CWGetSetGroupsRequest, CWGetSetResponse, CWGetSetsRequest, CWGetSetsResponse, CWReportAddBrandRequest, CWReportDetails, CWReportRefreshAtStepRequest, CWReportRemoveBrandRequest, CWReportRequest, CWReportsResponse, CWSetGroups, CWUpdateCategorizeReportReq, DownloadReportRequest, GetExcludedInfluencersFromReportsResponse, UploadExcludedInfluencersToReportRequest, UploadExcludedInfluencersToReportsOfNetworkRequest } from "./admin_cw_pb";

/**
 * @generated from service librarian.proto.LibrarianCWAdmin
 */
export const LibrarianCWAdmin = {
  typeName: "librarian.proto.LibrarianCWAdmin",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWCreateMarket
     */
    cWCreateMarket: {
      name: "CWCreateMarket",
      I: StringValue,
      O: CWMarket,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWEditMarket
     */
    cWEditMarket: {
      name: "CWEditMarket",
      I: CWMarket,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.ArchiveMarket
     */
    archiveMarket: {
      name: "ArchiveMarket",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.UnarchiveMarket
     */
    unarchiveMarket: {
      name: "UnarchiveMarket",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.GetIndustriesByCategory
     */
    getIndustriesByCategory: {
      name: "GetIndustriesByCategory",
      I: Empty,
      O: CWIndustriesByCategory,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWListBrands
     */
    cWListBrands: {
      name: "CWListBrands",
      I: Empty,
      O: BrandResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWCreateBrand
     */
    cWCreateBrand: {
      name: "CWCreateBrand",
      I: CWCreateBrandRequest,
      O: Int64Value,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWEditBrandName
     */
    cWEditBrandName: {
      name: "CWEditBrandName",
      I: CWBrandPb,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWArchiveBrand
     */
    cWArchiveBrand: {
      name: "CWArchiveBrand",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWUnarchiveBrand
     */
    cWUnarchiveBrand: {
      name: "CWUnarchiveBrand",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWCreateOrUpdateBrandMarket
     */
    cWCreateOrUpdateBrandMarket: {
      name: "CWCreateOrUpdateBrandMarket",
      I: BrandMarketSpec,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWListReports
     */
    cWListReports: {
      name: "CWListReports",
      I: Empty,
      O: CWReportsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWGetReportDetails
     */
    cWGetReportDetails: {
      name: "CWGetReportDetails",
      I: Int64Value,
      O: CWReportDetails,
      kind: MethodKind.Unary,
    },
    /**
     * The result is the token for the bronco task if any
     * Should be followed by a call to cWGetReportDetails
     *
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWCreateOrEditReport2
     */
    cWCreateOrEditReport2: {
      name: "CWCreateOrEditReport2",
      I: CWReportRequest,
      O: CWCreateOrEditReportResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.ArchiveReport
     */
    archiveReport: {
      name: "ArchiveReport",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.UnarchiveReport
     */
    unarchiveReport: {
      name: "UnarchiveReport",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Updates the 'categorize_influencers' bit in a report. This has no other effect, in particular it doesn't launch
     * a categorize task. The bit will eventually be picked up by the cron job. The task can take up to weeks so there
     * is little use in making a bronco (most likely admin would be restarted > 3 times and the task would fail).
     *
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWUpdateCategorizeInfluencersInReport
     */
    cWUpdateCategorizeInfluencersInReport: {
      name: "CWUpdateCategorizeInfluencersInReport",
      I: CWUpdateCategorizeReportReq,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWDuplicateReport
     */
    cWDuplicateReport: {
      name: "CWDuplicateReport",
      I: Int64Value,
      O: CWCreateOrEditReportResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWReportAddBrand
     */
    cWReportAddBrand: {
      name: "CWReportAddBrand",
      I: CWReportAddBrandRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWReportRemoveBrand
     */
    cWReportRemoveBrand: {
      name: "CWReportRemoveBrand",
      I: CWReportRemoveBrandRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * Public endpoint to upload a list of influencers handles we will exclude from a report. It will then call
     * AddExcludedInfluencersToReport for each resolved ones internally.
     * Returns the task handle
     *
     * @generated from rpc librarian.proto.LibrarianCWAdmin.UploadExcludedInfluencersToReport
     */
    uploadExcludedInfluencersToReport: {
      name: "UploadExcludedInfluencersToReport",
      I: UploadExcludedInfluencersToReportRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * RPC server to server endpoint to exclude a specific resolved influencer from a report
     *
     * @generated from rpc librarian.proto.LibrarianCWAdmin.AddExcludedInfluencersToReport
     */
    addExcludedInfluencersToReport: {
      name: "AddExcludedInfluencersToReport",
      I: AddExcludedInfluencersToReportRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * Public endpoint to upload a list of influencers handles we will exclude from all reports of a given network.
     * It will then call AddExcludedInfluencersToReportsOfNetwork for each resolved ones internally.
     * Returns the task handle
     *
     * @generated from rpc librarian.proto.LibrarianCWAdmin.UploadExcludedInfluencersToReportsOfNetwork
     */
    uploadExcludedInfluencersToReportsOfNetwork: {
      name: "UploadExcludedInfluencersToReportsOfNetwork",
      I: UploadExcludedInfluencersToReportsOfNetworkRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * RPC server to server endpoint to exclude a specific resolved influencer from all reports of a given network
     *
     * @generated from rpc librarian.proto.LibrarianCWAdmin.AddExcludedInfluencersToReportsOfNetwork
     */
    addExcludedInfluencersToReportsOfNetwork: {
      name: "AddExcludedInfluencersToReportsOfNetwork",
      I: AddExcludedInfluencersToReportsOfNetworkRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.GetExcludedInfluencersFromAllReports
     */
    getExcludedInfluencersFromAllReports: {
      name: "GetExcludedInfluencersFromAllReports",
      I: Empty,
      O: GetExcludedInfluencersFromReportsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * DEPRECATED: wait for frontend migration to AutocompleteCWCampaigns
     *
     * @generated from rpc librarian.proto.LibrarianCWAdmin.AutocompleteCampaigns
     * @deprecated
     */
    autocompleteCampaigns: {
      name: "AutocompleteCampaigns",
      I: StringValue,
      O: AutocompleteCampaignsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.AutocompleteCWCampaigns
     */
    autocompleteCWCampaigns: {
      name: "AutocompleteCWCampaigns",
      I: AutocompleteCampaignsRequest,
      O: AutocompleteCampaignsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.AutocompleteCWBrands
     */
    autocompleteCWBrands: {
      name: "AutocompleteCWBrands",
      I: AutocompleteCwBrandsRequest,
      O: CWBrands,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CreateOrEditSetGroup
     */
    createOrEditSetGroup: {
      name: "CreateOrEditSetGroup",
      I: CWCreateOrEditSetGroupRequest,
      O: Int64Value,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.GetSetGroups
     */
    getSetGroups: {
      name: "GetSetGroups",
      I: CWGetSetGroupsRequest,
      O: CWSetGroups,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.EditSetGroups
     */
    editSetGroups: {
      name: "EditSetGroups",
      I: CWEditSetGroupsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWGetSetsV2
     */
    cWGetSetsV2: {
      name: "CWGetSetsV2",
      I: CWGetSetsRequest,
      O: CWGetSetsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * List all sets, with minimal information, to be as fast as possible
     *
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWListSets
     * @deprecated
     */
    cWListSets: {
      name: "CWListSets",
      I: Empty,
      O: CWGetSetsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWGetSet
     */
    cWGetSet: {
      name: "CWGetSet",
      I: Int64Value,
      O: CWGetSetResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWCreateSet
     */
    cWCreateSet: {
      name: "CWCreateSet",
      I: CWCreateSetRequest,
      O: CWGetSetResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWEditSet
     */
    cWEditSet: {
      name: "CWEditSet",
      I: CWEditSetRequest,
      O: CWGetSetResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWAddSources
     */
    cWAddSources: {
      name: "CWAddSources",
      I: CWAddSourcesRequest,
      O: CWGetSetResponse,
      kind: MethodKind.Unary,
    },
    /**
     * This endpoint will be used for source not already successfully built
     *
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWCountSourceV2
     */
    cWCountSourceV2: {
      name: "CWCountSourceV2",
      I: CWCountSourceRequest,
      O: Int64Value,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWRefreshSet
     */
    cWRefreshSet: {
      name: "CWRefreshSet",
      I: Int64Value,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWArchiveSet
     */
    cWArchiveSet: {
      name: "CWArchiveSet",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWUnarchiveSet
     */
    cWUnarchiveSet: {
      name: "CWUnarchiveSet",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWRefreshReportAtStep
     */
    cWRefreshReportAtStep: {
      name: "CWRefreshReportAtStep",
      I: CWReportRefreshAtStepRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianCWAdmin.CWDownloadReport
     */
    cWDownloadReport: {
      name: "CWDownloadReport",
      I: DownloadReportRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
  }
} as const;

/**
 * @generated from service librarian.proto.ArchivistCWAdmin
 */
export const ArchivistCWAdmin = {
  typeName: "librarian.proto.ArchivistCWAdmin",
  methods: {
    /**
     * These endpoints have a counterpart in the librarian API. They will be delegated to bronco.
     *
     * @generated from rpc librarian.proto.ArchivistCWAdmin.DownloadReport
     */
    downloadReport: {
      name: "DownloadReport",
      I: DownloadReportRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;


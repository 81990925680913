import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
  model,
  NgZone,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  Chip,
  createChip,
  isEmptyString,
  isNil,
  isNotNil,
} from '@frontend2/core';
import { LogicOperator } from '@frontend2/proto/common/proto/common_pb';
import { Subject } from 'rxjs';
import { ComponentFactory } from '../dynamic-component.component';
import { LeftyFormValueBase } from '../form';
import { LeftyFormAutocompleteComponent } from '../lefty-form-autocomplete/lefty-form-autocomplete.component';
import { defaultItemRenderer, ItemRenderer } from '../lefty-form-select/utils';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';
import { isBackspaceKey, isEnterKey } from '../utils';
import { LeftyChipComponent } from './lefty-chip.component';
import { LeftyOperatorChipComponent } from './lefty-operator-chip/lefty-operator-chip.component';

@Component({
  selector: 'lefty-chips-autocomplete',
  templateUrl: 'lefty-chips-autocomplete.component.html',
  styleUrls: ['lefty-chips-autocomplete.component.scss', 'input-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    LeftyFormComponent,
    LeftyOperatorChipComponent,
    LeftyChipComponent,
    LeftyFormAutocompleteComponent,
  ],
})
export class LeftyChipsAutocompleteComponent<T> extends LeftyFormValueBase<
  T[]
> {
  constructor(
    readonly zone: NgZone,
    @Self() @Optional() ngControl?: NgControl,
  ) {
    super([], ngControl);

    this.disposer.add(this.inputTextChange);
    this.disposer.add(this.popupVisible$);
    this.disposer.add(this.scrollEnd$);
  }

  chips: Chip<T>[] = [];

  @Input()
  placeholder = '';

  @Input()
  limit = 10;

  /// if we want the dropdown to match the width of the input
  @Input()
  popupMatchInputWidth = false;

  @Input()
  single = false;

  /// show loading spinner inside dropdown
  @Input()
  loading = false;

  /// see [LeftyFormAutocompleteComponent.selectionOptions]
  @Input()
  options: T[] = [];

  /// see [LeftyFormAutocompleteComponent.componentFactory]
  @Input()
  componentFactory?: ComponentFactory<T>;

  /// see [LeftyFormAutocompleteComponent.itemRenderer]
  @Input()
  itemRenderer: ItemRenderer<T> = defaultItemRenderer;

  /// message to show when no result found
  @Input()
  emptyPlaceholder = '';

  // turn this to true if you want to delegate autocompletion to a parent component
  @Input()
  noFiltering = false;

  @Input()
  inputText = '';

  @Input()
  canCreateChip = true;

  @Input()
  tabIndex?: number;

  @Input()
  withLogicOperator = false;

  readonly logicOperatorValue = model(LogicOperator.AND);

  override get value(): T[] {
    return super.value;
  }

  override set value(val: T[]) {
    super.value = val;

    this.setState(() => {
      this.chips = this._buildChips(this.value);
    });
  }

  @Output()
  readonly inputTextChange = new Subject<string>();

  @Output()
  readonly popupVisible$ = new Subject<boolean>();

  @Output()
  readonly scrollEnd$ = new Subject<unknown>();

  @ViewChild(LeftyFormAutocompleteComponent)
  autocompleteComponent!: LeftyFormAutocompleteComponent<T>;

  handleInputTextChange(val: string): void {
    if (this.inputText === val) {
      return;
    }
    this.setState(() => (this.inputText = val));
    this.inputTextChange.next(val);
  }

  @HostBinding('class.disabled')
  get isDisabled(): boolean {
    return this.disabled;
  }

  @HostBinding('class.has-value')
  get hasValue(): boolean {
    return this.value.length > 0;
  }

  @Input()
  renderChipValue: (value: T) => string = (value) => {
    if (isNotNil(this.itemRenderer)) {
      return this.itemRenderer(value);
    }
    return `${value}`;
  };

  override writeValue(obj: unknown): void {
    // no efficient way to test if array contains correct type
    if (Array.isArray(obj)) {
      this.value = obj;
    } else {
      this.value = [];
      console.warn(
        `Failed to bind type ${typeof obj} on form control ${
          this.ngControl?.name
        }`,
      );
    }
  }

  private _buildChips(values: T[]): Chip<T>[] {
    return values.map((val) =>
      createChip(val, {
        formattedValue: this.renderChipValue(val),
      }),
    );
  }

  deselect(item: T): void {
    this.handleValueChange(this.value.filter((element) => element !== item));
  }

  private _deleteLastChip(): void {
    if (this.value.length === 0) {
      return;
    }
    this.deselect(this.value[this.value.length - 1]);
  }

  select(selection?: T): void {
    if (this.canCreateChip === false || isNil(selection)) {
      return;
    }

    if (this.single) {
      this.handleValueChange([selection]);
    } else {
      this.handleValueChange([...new Set([...this.value, selection])]);
    }

    this.setState(() => (this.inputText = ''));
  }

  get inputDisabled(): boolean {
    return this.disabled || (this.single && this.value.length > 0);
  }

  selectFirstResult(): T | undefined {
    if (this.autocompleteComponent.options.length === 0) {
      return;
    }

    const item = this.autocompleteComponent.options[0];
    this.select(item);
    return item;
  }

  handleInputKeydown(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    const valueEmpty = isEmptyString(input.value.trim());

    if (isBackspaceKey(event.code) && this.focused && valueEmpty) {
      this._deleteLastChip();
    }
  }

  /// Prevent form submission if select a result
  /// by pressiong ENTER
  interceptKeydown(event: KeyboardEvent): void {
    if (isEnterKey(event.code)) {
      if (this.autocompleteComponent.popupVisible) {
        // prevent form submission
        event.stopPropagation();
      } else {
        // prevent opening the dropdown
        event.preventDefault();
      }
    }
  }
}

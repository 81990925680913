import {
  ContentMimeType,
  MediaPerformance,
  Network,
  PostType,
} from '@frontend2/proto/common/proto/common_pb';
import { MediaResolution } from '@frontend2/proto/common/proto/media_pb';
import {
  CampaignTrackedReason,
  GenerikMediaSnippet,
  GenerikSnippet,
  GenerikSnippet_GenerikUrl,
} from '@frontend2/proto/librarian/proto/users_pb';

import {
  isNil,
  isNotEmptyArray,
  isNotEmptyString,
  isNotNil,
} from '@frontend2/core';
import {
  campaignKeywordTypeIsHashtag,
  campaignKeywordTypeIsKeyword,
  campaignKeywordTypeIsMention,
  getNetworkOfPostType,
} from '@frontend2/proto-helpers/common/common_pb.helpers';
import {
  CampaignKeyword,
  PostInteractions,
  VisualEditedPerf,
} from '@frontend2/proto/common/proto/campaign_pb';
import { MediaLocation } from '@frontend2/proto/common/proto/instagram_media_pb';
import { EntityLabel } from '@frontend2/proto/librarian/proto/entity_labels_pb';

export const POST_DEFAULT_RESOLUTIONS = [
  MediaResolution.HIGH,
  MediaResolution.STANDARD,
  MediaResolution.THUMBNAIL,
  MediaResolution.LOW,
];

// LOW resolution always last, it's a really too LOW to be use
export const POST_THUMBNAIL_RESOLUTIONS = [
  MediaResolution.THUMBNAIL,
  MediaResolution.STANDARD,
  MediaResolution.HIGH,
  MediaResolution.LOW,
];

export enum PostStyle {
  image, // insta regular post
  youtube, // youtube iframe
  story, // insta story, tiktok, douyin,
  text, // twitter, weibo, wechat
}

export interface Post {
  readonly id: string;
  readonly snippet: GenerikSnippet;
  readonly interactions: PostInteractions;
  readonly computedPerf: MediaPerformance;
  readonly editedPerf: VisualEditedPerf;
  readonly type: PostType;
  readonly network: Network;

  // we allow number because of storybook issue with bigint
  // https://github.com/storybookjs/storybook/issues/22452
  readonly campaignIds: (bigint | number)[];
  readonly publishedDate: Date;
  readonly caption: string;
  readonly description: string;
  readonly urls: GenerikSnippet_GenerikUrl[];
  readonly campaignTrackedReasons: CampaignTrackedReason[];
  readonly mentions: string[];
  readonly hashtags: string[];
  readonly labels: EntityLabel[];
  readonly location: MediaLocation;
}

export function createPost(obj?: Partial<Post>): Post {
  obj = obj ?? {};

  const id = obj.id ?? '';
  const snippet = obj.snippet ?? new GenerikSnippet();

  const computedPerf =
    obj.computedPerf ?? snippet.perf ?? new MediaPerformance();

  const editedPerf =
    obj.editedPerf ?? snippet.editedPerf ?? new VisualEditedPerf();

  const interactions =
    obj.interactions ?? snippet.counts ?? new PostInteractions();

  const campaignTrackedReasons = obj.campaignTrackedReasons ?? [];
  const mentions = obj.mentions ?? snippet.mentions;
  const hashtags = obj.hashtags ?? snippet.tags;
  const labels = obj.labels ?? [];
  const location = obj.location ?? snippet.location ?? new MediaLocation();

  const type = obj.type ?? snippet.postType;

  return {
    id,
    snippet,
    computedPerf,
    editedPerf,
    interactions,
    type,
    network: getNetworkOfPostType(type),
    campaignIds: obj.campaignIds ?? [],
    publishedDate:
      obj.publishedDate ?? snippet.publishedAt?.toDate() ?? new Date(),
    caption: obj.caption ?? snippet.caption,
    description: obj.description ?? snippet.description,
    urls: obj.urls ?? snippet.urls,
    campaignTrackedReasons,
    mentions,
    hashtags,
    labels,
    location,
  };
}

export function createPostFromGenerikSnippet(
  mediaSnippet: GenerikMediaSnippet,
): Post {
  const snippet = mediaSnippet.generik ?? new GenerikSnippet();

  return createPost({
    id: mediaSnippet.mediaId,
    snippet,
    campaignIds: mediaSnippet.campaignsIds ?? [],
    campaignTrackedReasons: mediaSnippet.campaignTrackedReasons,
    labels: mediaSnippet.labels,
  });
}

const _ghost = createPost();

export function createGhostPost(): Post {
  return _ghost;
}

export function isGhostPost(post: Post): boolean {
  return post.id === '';
}

export function getUrlsByType(
  urls: GenerikSnippet_GenerikUrl[],
  mimeType: ContentMimeType,
): GenerikSnippet_GenerikUrl[] {
  return urls.filter((u) => u.mimeType === mimeType);
}

export function getUrlsByPriority(
  urls: GenerikSnippet_GenerikUrl[],
  priorities: MediaResolution[],
): GenerikSnippet_GenerikUrl[] {
  let filtered: GenerikSnippet_GenerikUrl[] = [];

  for (const prio of priorities) {
    filtered = [...filtered, ...urls.filter((u) => u.resolution === prio)];
  }

  return filtered;
}

export function getStyleOfPostType(postType: PostType): PostStyle {
  const network = getNetworkOfPostType(postType);

  switch (network) {
    case Network.TIK_TOK:
    case Network.DOUYIN:
    case Network.SNAPCHAT:
      return PostStyle.story;
    case Network.INSTA:
      return postType === PostType.IG_STORY ? PostStyle.story : PostStyle.image;
    case Network.YOUTUBE:
      return PostStyle.youtube;
    case Network.NETWORK_UNKNOWN:
    case Network.TWEET:
    case Network.FACEBOOK:
    case Network.WEIBO:
    case Network.WECHAT:
    case Network.RED:
      return PostStyle.image;
  }
}

export function postDebugLink(post: Post): string {
  return `https://admin.lefty.io/debug?type=image&id=${post.id}`;
}

export function shouldShowPostInteractions(post: Post): boolean {
  switch (post.network) {
    case Network.INSTA:
      return post.type !== PostType.IG_STORY;
    case Network.NETWORK_UNKNOWN:
    case Network.YOUTUBE:
    case Network.TWEET:
    case Network.TIK_TOK:
    case Network.FACEBOOK:
    case Network.WEIBO:
    case Network.WECHAT:
    case Network.RED:
    case Network.DOUYIN:
    case Network.SNAPCHAT:
      return true;
  }
}

export function shouldShowPostEngagementRate(post: Post): boolean {
  // TODO: Snapchat check has engagement rate
  switch (post.network) {
    case Network.INSTA:
      return post.type !== PostType.IG_STORY;
    case Network.NETWORK_UNKNOWN:
    case Network.YOUTUBE:
    case Network.TWEET:
    case Network.TIK_TOK:
    case Network.FACEBOOK:
    case Network.WEIBO:
    case Network.WECHAT:
    case Network.RED:
    case Network.DOUYIN:
    case Network.SNAPCHAT:
      return false;
  }
}

export function shouldShowPostViews(post: Post): boolean {
  switch (post.network) {
    case Network.TIK_TOK:
    case Network.YOUTUBE:
    case Network.SNAPCHAT:
      return true;
    case Network.WEIBO:
      return post.type === PostType.WB_VIDEO;
    case Network.NETWORK_UNKNOWN:
    case Network.INSTA:
    case Network.TWEET:
    case Network.FACEBOOK:
    case Network.WECHAT:
    case Network.RED:
    case Network.DOUYIN:
      return false;
  }
}

export function shouldShowPostReshares(post: Post): boolean {
  switch (post.network) {
    case Network.TWEET:
    case Network.WEIBO:
    case Network.SNAPCHAT:
      return true;
    case Network.NETWORK_UNKNOWN:
    case Network.INSTA:
    case Network.YOUTUBE:
    case Network.TIK_TOK:
    case Network.FACEBOOK:
    case Network.WECHAT:
    case Network.RED:
    case Network.DOUYIN:
      return false;
  }
}

export function shouldShowPostReads(post: Post): boolean {
  switch (post.network) {
    case Network.WECHAT:
      return true;
    case Network.NETWORK_UNKNOWN:
    case Network.INSTA:
    case Network.YOUTUBE:
    case Network.TIK_TOK:
    case Network.FACEBOOK:
    case Network.WEIBO:
    case Network.TWEET:
    case Network.RED:
    case Network.DOUYIN:
    case Network.SNAPCHAT:
      return false;
  }
}

export function shouldShowPostWows(post: Post): boolean {
  switch (post.network) {
    case Network.WECHAT:
    case Network.DOUYIN:
      return true;
    case Network.NETWORK_UNKNOWN:
    case Network.INSTA:
    case Network.YOUTUBE:
    case Network.TIK_TOK:
    case Network.FACEBOOK:
    case Network.WEIBO:
    case Network.TWEET:
    case Network.RED:
    case Network.SNAPCHAT:
      return false;
  }
}

export function shouldShowPostFavorites(post: Post): boolean {
  switch (post.network) {
    case Network.RED:
    case Network.DOUYIN:
      return true;
    case Network.NETWORK_UNKNOWN:
    case Network.INSTA:
    case Network.YOUTUBE:
    case Network.TIK_TOK:
    case Network.FACEBOOK:
    case Network.WEIBO:
    case Network.TWEET:
    case Network.WECHAT:
    case Network.SNAPCHAT:
      return false;
  }
}

export function shouldShowPostDislikes(post: Post): boolean {
  switch (post.network) {
    case Network.YOUTUBE:
      return true;
    case Network.NETWORK_UNKNOWN:
    case Network.INSTA:
    case Network.RED:
    case Network.DOUYIN:
    case Network.TIK_TOK:
    case Network.FACEBOOK:
    case Network.WEIBO:
    case Network.TWEET:
    case Network.WECHAT:
    case Network.SNAPCHAT:
      return false;
  }
}

export function getPostEngagementRate(post: Post): number {
  return post.computedPerf.engagementRate;
}

export function getPostEmv(post: Post): bigint {
  return post.computedPerf.emv;
}

export function postHasEditedLikes(post: Post): boolean {
  return isNotNil(post.editedPerf.counts?.likes);
}

export function getPostLikes(post: Post): bigint {
  return post.editedPerf.counts?.likes ?? post.interactions.likes ?? BigInt(0);
}

export function postHasEditedDislikes(post: Post): boolean {
  return isNotNil(post.editedPerf.counts?.dislikes);
}

export function getPostDislikes(post: Post): bigint {
  return (
    post.editedPerf.counts?.dislikes ?? post.interactions.dislikes ?? BigInt(0)
  );
}

export function postHasEditedImpressions(post: Post): boolean {
  return isNotNil(post.editedPerf.impressions);
}

export function getPostImpressions(post: Post): bigint {
  return (
    post.editedPerf.impressions ?? post.computedPerf.impressions ?? BigInt(0)
  );
}

export function postHasEditedViews(post: Post): boolean {
  return isNotNil(post.editedPerf.counts?.views);
}

export function getPostViews(post: Post): bigint {
  return post.editedPerf.counts?.views ?? post.interactions.views ?? BigInt(0);
}

export function postHasEditedComments(post: Post): boolean {
  return isNotNil(post.editedPerf.counts?.comments);
}

export function getPostComments(post: Post): bigint {
  return (
    post.editedPerf.counts?.comments ?? post.interactions.comments ?? BigInt(0)
  );
}

export function postHasEditedWows(post: Post): boolean {
  return isNotNil(post.editedPerf.counts?.wows);
}

export function getPostWows(post: Post): bigint {
  return post.editedPerf.counts?.wows ?? post.interactions.wows ?? BigInt(0);
}

export function postHasEditedShares(post: Post): boolean {
  return isNotNil(post.editedPerf.counts?.shares);
}

export function getPostShares(post: Post): bigint {
  return (
    post.editedPerf.counts?.shares ?? post.interactions.shares ?? BigInt(0)
  );
}

export function postHasEditedReads(post: Post): boolean {
  return isNotNil(post.editedPerf.counts?.clicks);
}

export function getPostReads(post: Post): bigint {
  return (
    post.editedPerf.counts?.clicks ?? post.interactions.clicks ?? BigInt(0)
  );
}

export function postHasEditedReach(post: Post): boolean {
  return isNotNil(post.editedPerf.reach);
}

export function getPostReach(post: Post): bigint {
  return post.editedPerf.reach ?? post.computedPerf.reach ?? BigInt(0);
}

export interface KeywordAndTrackedCampaignIds {
  readonly campaignIds: bigint[];
  readonly keyword: CampaignKeyword;
}

export function createKeywordAndTrackedCampaignIds(
  args?: Partial<KeywordAndTrackedCampaignIds>,
): KeywordAndTrackedCampaignIds {
  return {
    campaignIds: args?.campaignIds ?? [],
    keyword: args?.keyword ?? new CampaignKeyword(),
  };
}

function _groupCampaignIdsByKeywordType(
  post: Post,
): KeywordAndTrackedCampaignIds[] {
  const mapping = new Map<CampaignKeyword, Set<bigint>>();

  for (const campaignTrackedReason of post.campaignTrackedReasons) {
    const campaignId = campaignTrackedReason.campaignId;
    const trackedReason = campaignTrackedReason.trackedReason;
    const reason = trackedReason?.reason;

    if (reason?.case === 'trackedReasonKeywords') {
      for (const trackedKeywords of reason.value.trackedKeywords) {
        const keywords = trackedKeywords.keywords.map(
          (keyword) =>
            new CampaignKeyword({
              keywordType: trackedKeywords.keywordType,
              value: keyword,
            }),
        );

        for (const keyword of keywords) {
          if (isNil(mapping.get(keyword))) {
            mapping.set(keyword, new Set());
          }
          mapping.get(keyword)?.add(campaignId);
        }
      }
    }
  }

  return [...mapping.entries()].map((entry) => {
    return {
      keyword: entry[0],
      campaignIds: Array.from(entry[1]),
    };
  });
}

export function getHashtagsWithCampaignIds(
  post: Post,
): KeywordAndTrackedCampaignIds[] {
  return _groupCampaignIdsByKeywordType(post).filter((element) =>
    campaignKeywordTypeIsHashtag(element.keyword.keywordType),
  );
}

export function getMentionsWithCampaignIds(
  post: Post,
): KeywordAndTrackedCampaignIds[] {
  return _groupCampaignIdsByKeywordType(post).filter((element) =>
    campaignKeywordTypeIsMention(element.keyword.keywordType),
  );
}

export function getKeywordsWithCampaignIds(
  post: Post,
): KeywordAndTrackedCampaignIds[] {
  return _groupCampaignIdsByKeywordType(post).filter((element) =>
    campaignKeywordTypeIsKeyword(element.keyword.keywordType),
  );
}

export function getCampaignIdsForTrackedHashtag(
  post: Post,
  tag: string,
): bigint[] {
  if (isGhostPost(post)) {
    return [];
  }

  return Array.from(
    new Set(
      getHashtagsWithCampaignIds(post)
        .filter((element) => element.keyword.value === tag)
        .flatMap((e) => e.campaignIds),
    ),
  );
}

export function getCampaignIdsForTrackedMention(
  post: Post,
  mention: string,
): bigint[] {
  if (isGhostPost(post)) {
    return [];
  }

  return Array.from(
    new Set(
      getMentionsWithCampaignIds(post)
        .filter((element) => element.keyword.value === mention)
        .flatMap((e) => e.campaignIds),
    ),
  );
}

export function getCampaignIdsForTrackedKeyword(
  post: Post,
  keyword: string,
): bigint[] {
  if (isGhostPost(post)) {
    return [];
  }

  return Array.from(
    new Set(
      getKeywordsWithCampaignIds(post)
        .filter((element) => element.keyword.value === keyword)
        .flatMap((e) => e.campaignIds),
    ),
  );
}

export function getManuallyMarkedCampaignIds(post: Post): bigint[] {
  return post.campaignTrackedReasons
    .filter((reason) => reason.trackedReason?.reason.value === true)
    .map((reason) => reason.campaignId);
}

export function isPostManuallyMarked(post: Post): boolean {
  return isNotEmptyArray(getManuallyMarkedCampaignIds(post));
}

export function isPostManuallyMarkedInCampaign(
  post: Post,
  campaignId: bigint,
): boolean {
  return getManuallyMarkedCampaignIds(post).includes(campaignId);
}

export function hasLocation(post: Post): boolean {
  return isNotEmptyString(post.location.id);
}

import { InjectionToken, inject } from '@angular/core';
import { PromiseClient, createPromiseClient } from '@connectrpc/connect';
import { LibrarianCampaigns } from '@frontend2/proto/librarian/proto/campaigns_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianCampaignsClient = PromiseClient<typeof LibrarianCampaigns>;

export const LibrarianCampaignsProvider = new InjectionToken(
  'LibrarianCampaignsClient',
  {
    factory: (): LibrarianCampaignsClient =>
      createPromiseClient(LibrarianCampaigns, inject(GRPC_TRANSPORT)),
  },
);

export function injectLibrarianCampaignsClient(): LibrarianCampaignsClient {
  return inject(LibrarianCampaignsProvider);
}

// This file defines basic geo messages such as a geopoint and a geocell.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file common/proto/geo.proto (package common.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";

/**
 * @generated from message common.proto.GeoPoint
 */
export class GeoPoint extends Message<GeoPoint> {
  /**
   * latitude.
   *
   * @generated from field: double lat = 1;
   */
  lat = 0;

  /**
   * longitude.
   *
   * @generated from field: double lng = 2;
   */
  lng = 0;

  constructor(data?: PartialMessage<GeoPoint>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.GeoPoint";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "lat", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 2, name: "lng", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GeoPoint {
    return new GeoPoint().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GeoPoint {
    return new GeoPoint().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GeoPoint {
    return new GeoPoint().fromJsonString(jsonString, options);
  }

  static equals(a: GeoPoint | PlainMessage<GeoPoint> | undefined, b: GeoPoint | PlainMessage<GeoPoint> | undefined): boolean {
    return proto3.util.equals(GeoPoint, a, b);
  }
}

/**
 * defines a region with names.
 *
 * @generated from message common.proto.Location
 */
export class Location extends Message<Location> {
  /**
   * @generated from field: common.proto.GeoPoint center = 1;
   */
  center?: GeoPoint;

  /**
   * @generated from field: double radius = 2;
   */
  radius = 0;

  /**
   * @generated from field: string country = 3;
   */
  country = "";

  /**
   * @generated from field: string state = 4;
   */
  state = "";

  /**
   * @generated from field: int32 number_of_points = 5;
   */
  numberOfPoints = 0;

  constructor(data?: PartialMessage<Location>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "common.proto.Location";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "center", kind: "message", T: GeoPoint },
    { no: 2, name: "radius", kind: "scalar", T: 1 /* ScalarType.DOUBLE */ },
    { no: 3, name: "country", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "state", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "number_of_points", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Location {
    return new Location().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Location {
    return new Location().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Location {
    return new Location().fromJsonString(jsonString, options);
  }

  static equals(a: Location | PlainMessage<Location> | undefined, b: Location | PlainMessage<Location> | undefined): boolean {
    return proto3.util.equals(Location, a, b);
  }
}


<div *ngIf="noteStateIsNoContent && isEditable">
  <lefty-form-textarea
    placeholder="Add note"
    i18n-placeholder
    [rows]="1"
    [(value)]="currentNoteContent"
  ></lefty-form-textarea>
</div>

<div
  *ngIf="!noteStateIsNoContent"
  class="note-card"
  [class.editing]="noteStateIsEditing"
>
  <div
    *ngIf="isEditable"
    class="actions"
  >
    <button
      leftyButton="ghost"
      icon="edit"
      (click)="startEdit()"
    ></button>
    <button
      leftyButton="ghost"
      icon="delete"
      (click)="askDelete()"
    ></button>
  </div>

  <div class="header">
    <lefty-avatar
      [text]="initial"
      class="initial"
    >
    </lefty-avatar>
    <p class="name">{{ note.authorName }}</p>
    <p class="updated caption-1">{{ formattedUpdateDate }}</p>
  </div>
  <div class="textarea-wrapper">
    <textarea
      class="lefty-input"
      rows="1"
      #textarea
      [disabled]="!noteStateIsEditing || isEditable === false"
      placeholder="Your note"
      i18n-placeholder
      [(ngModel)]="currentNoteContent"
    >
    </textarea>
  </div>

  <div
    class="delete-box"
    *ngIf="noteStateIsDeleteConfirmation"
  >
    <span
      class="question"
      i18n
    >
      Delete this note?
    </span>
    <div class="buttons">
      <button
        leftyButton="secondary"
        size="small"
        i18n
        (click)="cancelDelete()"
        [disabled]="loading()"
      >
        Cancel
      </button>
      <button
        leftyButton="primary"
        size="small"
        i18n
        (click)="confirmDelete()"
        [loading]="loading()"
        [disabled]="loading()"
      >
        Yes, delete
      </button>
    </div>
  </div>
</div>

<div
  class="footer-buttons"
  *ngIf="mustShowFooterButtons"
>
  <button
    leftyButton="secondary"
    size="small"
    i18n
    (click)="cancel()"
    [disabled]="loading()"
  >
    Cancel
  </button>
  <button
    leftyButton="primary"
    size="small"
    i18n
    (click)="save()"
    [loading]="loading()"
    [disabled]="loading()"
  >
    Save
  </button>
</div>

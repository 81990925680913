import {
  Attribute,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
} from '@angular/core';
import { isNil, isNotEmptyString } from '@frontend2/core';
import {
  ComponentFactory,
  DynamicComponent,
} from '../dynamic-component.component';
import { LeftyListItemComponent } from '../lefty-list/lefty-list-item.component';
import { DropdownHandle } from '../utils';
import { ItemRenderer, defaultItemRenderer } from './utils';
import { LeftyCheckboxComponent } from '../checkbox/checkbox.component';
import { NgIf } from '@angular/common';

@Component({
  selector: 'lefty-select-dropdown-item',
  templateUrl: `lefty-select-dropdown-item.component.html`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, LeftyCheckboxComponent, DynamicComponent],
})
export class LeftySelectDropdownItemComponent<T>
  extends LeftyListItemComponent
  implements OnDestroy
{
  constructor(
    element: ElementRef,
    @Attribute('role') role?: string,
    @Attribute('tabindex') tabIndex?: string,
    @Optional() dropdown?: DropdownHandle,
  ) {
    super(element, role, tabIndex, dropdown);
  }

  @HostBinding('class')
  readonly dropdownHostClass = 'selectable-item dropdown-select-item';

  @Input()
  supportMultiSelect = false;

  @Input()
  @HostBinding('class.selected')
  selected = false;

  @Input()
  @HostBinding('class.indeterminate')
  indeterminate = false;

  @Input()
  componentFactory?: ComponentFactory<T>;

  /// Function to convert an option object to string.
  @Input()
  itemRenderer?: ItemRenderer<T>;

  @Input()
  value?: T;

  get hasComponentFactory(): boolean {
    return isNil(this.componentFactory) === false;
  }

  get hasValueLabel(): boolean {
    return isNotEmptyString(this.valueLabel);
  }

  get valueLabel(): string {
    if (!isNil(this.value) && !this.componentFactory) {
      if (this.itemRenderer) {
        return this.itemRenderer(this.value);
      }
      return defaultItemRenderer(this.value);
    }
    return '';
  }

  override handleActivate(e: UIEvent): void {
    if (this.supportMultiSelect) {
      return;
    }

    super.handleActivate(e);
  }
}

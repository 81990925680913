// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/gifts.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { CreateGiftsRequest, CreateGiftsResponse, DeleteDraftGiftsInBulkRequest, ExportGiftsRequest, GetDraftGiftDetailsResponse, GetDraftGiftsRequest, GetDraftGiftsResponse, GetGiftDetailsResponse, GetGiftsRequest, GetGiftsResponse, GetInfluencerAddressesRequest, GetInfluencerAddressesResponse, InitGetGiftListsResponse, SaveGiftAsDraftRequest, SaveGiftAsDraftResponse, UpdateDraftGiftRequest, UpdateDraftGiftResponse, UpdateGiftRequest, UpdateGiftResponse, UpdateGiftsInBulkRequest, UpdateGiftsInBulkResponse } from "./gifts_pb";
import { Empty, Int64Value, MethodKind, StringValue } from "@bufbuild/protobuf";

/**
 * @generated from service librarian.proto.LibrarianGifts
 */
export const LibrarianGifts = {
  typeName: "librarian.proto.LibrarianGifts",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianGifts.CreateGiftsAPI
     */
    createGiftsAPI: {
      name: "CreateGiftsAPI",
      I: CreateGiftsRequest,
      O: CreateGiftsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianGifts.SaveGiftAsDraftAPI
     */
    saveGiftAsDraftAPI: {
      name: "SaveGiftAsDraftAPI",
      I: SaveGiftAsDraftRequest,
      O: SaveGiftAsDraftResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianGifts.GetInfluencerAddressesAPI
     */
    getInfluencerAddressesAPI: {
      name: "GetInfluencerAddressesAPI",
      I: GetInfluencerAddressesRequest,
      O: GetInfluencerAddressesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianGifts.UpdateGiftAPI
     */
    updateGiftAPI: {
      name: "UpdateGiftAPI",
      I: UpdateGiftRequest,
      O: UpdateGiftResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianGifts.UpdateDraftGiftAPI
     */
    updateDraftGiftAPI: {
      name: "UpdateDraftGiftAPI",
      I: UpdateDraftGiftRequest,
      O: UpdateDraftGiftResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianGifts.DeleteDraftGiftAPI
     */
    deleteDraftGiftAPI: {
      name: "DeleteDraftGiftAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianGifts.DeleteDraftGiftsInBulkAPI
     */
    deleteDraftGiftsInBulkAPI: {
      name: "DeleteDraftGiftsInBulkAPI",
      I: DeleteDraftGiftsInBulkRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianGifts.UpdateGiftsInBulkAPI
     */
    updateGiftsInBulkAPI: {
      name: "UpdateGiftsInBulkAPI",
      I: UpdateGiftsInBulkRequest,
      O: UpdateGiftsInBulkResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianGifts.GetGiftsAPI
     */
    getGiftsAPI: {
      name: "GetGiftsAPI",
      I: GetGiftsRequest,
      O: GetGiftsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianGifts.GetDraftGiftsAPI
     */
    getDraftGiftsAPI: {
      name: "GetDraftGiftsAPI",
      I: GetDraftGiftsRequest,
      O: GetDraftGiftsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianGifts.InitGetGiftListsAPI
     */
    initGetGiftListsAPI: {
      name: "InitGetGiftListsAPI",
      I: Empty,
      O: InitGetGiftListsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianGifts.GetGiftDetailsAPI
     */
    getGiftDetailsAPI: {
      name: "GetGiftDetailsAPI",
      I: Int64Value,
      O: GetGiftDetailsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianGifts.GetDraftGiftDetailsAPI
     */
    getDraftGiftDetailsAPI: {
      name: "GetDraftGiftDetailsAPI",
      I: Int64Value,
      O: GetDraftGiftDetailsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Export an excel with gifts, based on filter
     * Return a task handle that frontend will listen to.
     *
     * @generated from rpc librarian.proto.LibrarianGifts.ExportGifts
     */
    exportGifts: {
      name: "ExportGifts",
      I: ExportGiftsRequest,
      O: StringValue,
      kind: MethodKind.Unary,
    },
  }
} as const;


import { Feature } from '@frontend2/proto/librarian/proto/common_pb';
import { LoggedBootstrapping } from '@frontend2/proto/librarian/proto/frontend_misc_pb';

import { isDevMode } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { LoggedAuthorV2 } from '@frontend2/proto/librarian/proto/registered_author_pb';
import posthog, { PostHogConfig } from 'posthog-js';
import { filter } from 'rxjs';
import { getEnumName } from '../utils';
import { isEmptyString } from '../utils/strings.helpers';

export abstract class AnalyticsSender {
  send(
    feature: Feature,
    action: string,
    source: AnalyticsSource,
    data?: AnalyticsEvent,
  ): void {
    // not ready for production yet, we are testing posthog
    if (isDevMode()) {
      analytics(action, {
        ...data,
        source,
        feature: getEnumName(Feature, feature),
      });
    }
  }
}

function dataLayerPush(val: unknown): void {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (<any>window).dataLayer.push(val);
}

// encrypt user email so we can send it to Google Analytics and be GDPR compliant
async function encryptEmail(email: string): Promise<string> {
  const hashSalt = 'lefty_ga_salt';

  // Make sure that the browser has the crypto.subtle library first (might not work in IE)
  if (crypto && crypto.subtle) {
    // Use the SubtleCrypto library to create a SHA-256 digest from the email + salt
    const hashed = await crypto.subtle.digest(
      'SHA-256',
      new TextEncoder().encode(email.toLowerCase() + hashSalt),
    );

    // The digest is returned in a wonky format so we need to convert it to a hex string
    const encryptedEmail = [].map
      .call(new Uint8Array(hashed), (b: number) =>
        ('00' + b.toString(16)).slice(-2),
      )
      .join('');

    return encryptedEmail;
  }

  return '';
}

export function analytics(
  eventName: string,
  properties?: Record<string, unknown>,
): void {
  posthog.capture(eventName, properties);
}

export function analyticsAppVersion(version: string): void {
  dataLayerPush({ version });
}

export async function analyticsBootstrap(
  user: LoggedBootstrapping,
): Promise<void> {
  if (user.isLogged === false || user.isImpersonating) {
    return;
  }

  const userId = await encryptEmail(user.email);

  analytics('bootstrap', {
    userId,
  });

  posthogIdentify(user);
}

export async function posthogIdentify(
  user: LoggedBootstrapping | LoggedAuthorV2,
): Promise<void> {
  const email = user.email;

  // user must be logged to have email
  if (isEmptyString(email)) {
    return;
  }

  const userId = await encryptEmail(email);

  if (user instanceof LoggedBootstrapping) {
    if (user.isImpersonating) {
      return;
    }

    posthog.identify(userId, {
      email: user.email,
      teamOwnerEmail: user.teamOwnerEmail,
      subscriptionType: user.subscriptionType,
      firstName: user.firstName,
      lastName: user.lastName,
      isTeamOwner: user.isTeamOwner,
    });
  } else {
    posthog.identify(userId, {
      email: user.email,
      authorId: user.authorId.toString(),
      hasValidToken: user.hasValidToken,
      tripeRegionStatuses: user.stripeRegionStatuses,
    });
  }
}

export function initPosthog(
  token: string,
  appVersion: string,
  config?: Partial<PostHogConfig>,
): void {
  posthog.init(token, {
    ...config,
    api_host: config?.api_host ?? 'https://us.posthog.com',
    capture_pageview: config?.capture_pageview ?? false,
  });

  posthog.setPersonProperties({
    appVersion,
  });
}

export function posthogTrackPageviews(router: Router): void {
  router.events
    .pipe(
      takeUntilDestroyed(),
      filter((event) => event instanceof NavigationEnd),
    )
    .subscribe(() => posthog.capture('$pageview'));
}

export type AnalyticsSource =
  | 'campaign'
  | 'campaign_sidebar'
  | 'campaigns_list'
  | 'settings'
  | 'casting';

export type AnalyticsEvent = Record<string, unknown>;

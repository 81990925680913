import {
  ChangeDetectionStrategy,
  Component,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  Optional,
  Self,
  forwardRef,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import {
  getLocale,
  getLocaleDecimalSeparator,
  isDigit,
  isNil,
  isNotNil,
  parseNumber,
  toLocaleString,
} from '@frontend2/core';
import { FocusableComponent } from '../focus.directive';
import { NativeInputWrapper } from '../form';
import { LeftyIconComponent } from '../icon/icon.component';
import { LeftyButtonDirective } from '../lefty-button-directive/lefty-button.directive';
import { NgIf } from '@angular/common';
import { LeftyFormComponent } from '../lefty-form/lefty-form.component';

@Component({
  selector: 'lefty-form-number-input, lefty-form-input[type="number"]',
  templateUrl: './lefty-form-number-input.component.html',
  styleUrls: ['./lefty-form-number-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: FocusableComponent,
      useExisting: forwardRef(() => LeftyFormNumberInputComponent),
    },
  ],
  standalone: true,
  imports: [LeftyFormComponent, NgIf, LeftyButtonDirective, LeftyIconComponent],
})
export class LeftyFormNumberInputComponent extends NativeInputWrapper<
  number | undefined
> {
  constructor(
    elementRef: ElementRef,
    @Self() @Optional() ngControl?: NgControl,
  ) {
    super(elementRef, undefined, ngControl);
  }

  private _allowFloat = false;
  private _formattedValue = '';

  private _getFloatFormatter(): Intl.NumberFormat {
    return new Intl.NumberFormat(getLocale(), {
      maximumFractionDigits: 2,
    });
  }

  private _getDefaultFormatter(): Intl.NumberFormat {
    return new Intl.NumberFormat(getLocale(), {
      maximumFractionDigits: 0,
    });
  }

  private _formatter = this._getDefaultFormatter();

  get allowFloat(): boolean {
    return this._allowFloat;
  }

  @Input()
  set allowFloat(value: boolean) {
    this._allowFloat = value;
    this._formatter = this._getFormatter(this._allowFloat);
    this._formattedValue = this._formatValue(this.value, this._formatter);
  }

  @Input()
  forceDecimal = false;

  // option to allow unsigned number only
  @Input()
  disallowSignedValue = false;

  @Input()
  @HostBinding('class.with-buttons')
  withButtons = false;

  @Input()
  disableIncrement = false;

  @Input()
  disableDecrement = false;

  @Input()
  maxValue?: number;

  @Input()
  minValue?: number;

  override get value(): number | undefined {
    return super.value;
  }

  override set value(val: number | undefined) {
    super.value = val;
    this._formattedValue = this._formatValue(this.value, this._formatter);
  }

  @HostBinding('class.has-value')
  get hasValue(): boolean {
    return isNotNil(this.value);
  }

  override writeValue(obj: unknown): void {
    if (typeof obj === 'number') {
      this.value = obj;
    } else if (isNil(obj)) {
      this.value = undefined;
    } else {
      console.warn(
        `Failed to bind type ${typeof obj} on form control ${
          this.ngControl?.name
        }`,
      );
    }
  }

  private _getFormatter(isFloat: boolean): Intl.NumberFormat {
    if (isFloat) {
      return this._getFloatFormatter();
    }
    return this._getDefaultFormatter();
  }

  get formatter(): Intl.NumberFormat {
    return this._formatter;
  }

  private _formatValue(
    val: number | undefined,
    formatter: Intl.NumberFormat,
  ): string {
    if (isNil(val)) {
      return '';
    }

    return formatter.format(val);
  }

  get formattedValue(): string {
    return this._formattedValue;
  }

  // it determine number of digits after decimal point
  private _determinePrecision(value: number): number {
    const s = toLocaleString(value);
    const decimalSeparator = getLocaleDecimalSeparator();
    if (s.includes(decimalSeparator)) {
      const parts = s.split(decimalSeparator);
      return parts[parts.length - 1].length;
    }
    return 0;
  }

  private _determineBestStep(value: number | undefined): number {
    // use 1 by default, if value not yet define
    if (isNil(value)) {
      return 1;
    }

    const precision = this._determinePrecision(value);
    if (precision !== 0) {
      return 1 / Math.pow(10, precision);
    }
    return 1;
  }

  private _isValidChar(char: string): boolean {
    return (
      isDigit(char) ||
      (this.allowFloat === true && char === getLocaleDecimalSeparator()) ||
      (this.disallowSignedValue === false && char === '-')
    );
  }

  handleKeypress(event: KeyboardEvent): void {
    if (this._isValidChar(event.key) === false) {
      event.stopPropagation();
      event.preventDefault();
    }
  }

  handleInputChange(event: Event): void {
    const inputElement = event.target as HTMLInputElement;

    const newValue = parseNumber(inputElement.value);
    this.handleValueChange(isNaN(newValue) ? undefined : newValue);
  }

  private _toPrecision(val: number, precision: number): number {
    if (this.allowFloat) {
      return parseFloat(val.toFixed(precision));
    }
    return parseInt(val.toFixed(precision));
  }

  decrement(): void {
    const step = this.allowFloat ? this._determineBestStep(this.value) : 1;
    const precision = this._determinePrecision(step);

    let val = this.value ?? 0;
    val = this._toPrecision(val - step, precision);
    if (
      (this.disallowSignedValue === true && val < 0) ||
      (isNotNil(this.minValue) && val < this.minValue)
    ) {
      return;
    }
    this.handleValueChange(val);
  }

  increment(): void {
    const step = this.allowFloat ? this._determineBestStep(this.value) : 1;
    const precision = this._determinePrecision(step);

    let val = this.value ?? 0;
    val = this._toPrecision(val + step, precision);
    if (isNil(this.maxValue) || val <= this.maxValue) {
      this.handleValueChange(val);
    }
  }

  override handleBlur(event?: FocusEvent | undefined): void {
    super.handleBlur(event);
    if (this.allowFloat && this.forceDecimal && isNotNil(this.value)) {
      this._formattedValue = Intl.NumberFormat(getLocale(), {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(this.value);
    }
    if (isNotNil(this.value)) {
      if (isNotNil(this.maxValue) && this.maxValue < this.value) {
        this.handleValueChange(this.maxValue);
      } else if (isNotNil(this.minValue) && this.minValue > this.value) {
        this.handleValueChange(this.minValue);
      }
    }
  }

  get reachedMaxValue(): boolean {
    return (
      isNotNil(this.value) &&
      isNotNil(this.maxValue) &&
      this.maxValue <= this.value
    );
  }

  get reachedMinValue(): boolean {
    return (
      isNotNil(this.value) &&
      isNotNil(this.minValue) &&
      this.minValue >= this.value
    );
  }
}

@Directive({
  selector: 'lefty-form-number-input[type="percent"]',
  standalone: true,
})
export class LeftyPercentInputDirective {
  constructor(inputComponent: LeftyFormNumberInputComponent) {
    inputComponent.allowFloat = true;
    inputComponent.suffix = '%';
  }
}

@Directive({
  selector: 'lefty-form-number-input[type="float"]',
  standalone: true,
})
export class LeftyFloatNumberInputDirective {
  constructor(inputComponent: LeftyFormNumberInputComponent) {
    inputComponent.allowFloat = true;
  }
}

export const DEFAULT_TOAST_DURATION = 6000;
export const TOAST_UNLIMITED_DURATION = -1;

export const TOAST_TYPE_SUCCESS = 'success';
export const TOAST_TYPE_ERROR = 'error';
export const TOAST_TYPE_LOADING = 'loading';
export const TOAST_TYPE_INFO = 'info';

export type ToastType =
  | typeof TOAST_TYPE_SUCCESS
  | typeof TOAST_TYPE_ERROR
  | typeof TOAST_TYPE_LOADING
  | typeof TOAST_TYPE_INFO;

export type ClickToast = (toast: Toast) => Promise<void> | void;

export interface ToastOptions {
  readonly duration?: number;
  readonly onClick?: ClickToast;

  // when toast type is 'loading'
  readonly progress?: number;
}

export interface ToastUpdate {
  readonly text?: string;
  readonly type?: ToastType;
  readonly progress?: number;
  readonly duration?: number;
}

export interface Toast extends ToastOptions {
  readonly id: string;
  readonly text: string;
  readonly type: ToastType;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Toast {
  export function success(
    id: string,
    text: string,
    options?: ToastOptions,
  ): Toast {
    return {
      ...options,
      id,
      text,
      type: TOAST_TYPE_SUCCESS,
      duration: options?.duration ?? DEFAULT_TOAST_DURATION,
    };
  }

  export function error(
    id: string,
    text: string,
    options?: ToastOptions,
  ): Toast {
    return {
      ...options,
      id,
      text,
      type: TOAST_TYPE_ERROR,
      duration: options?.duration ?? DEFAULT_TOAST_DURATION,
    };
  }

  export function loading(
    id: string,
    text: string,
    options?: ToastOptions,
  ): Toast {
    return {
      ...options,
      id,
      text,
      type: TOAST_TYPE_LOADING,
      duration: options?.duration ?? TOAST_UNLIMITED_DURATION,
    };
  }

  export function info(
    id: string,
    text: string,
    options?: ToastOptions,
  ): Toast {
    return {
      ...options,
      id,
      text,
      type: TOAST_TYPE_INFO,
      duration: options?.duration ?? DEFAULT_TOAST_DURATION,
    };
  }
}

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file librarian/proto/admin.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Int64Value, Message, proto3, protoInt64, StringValue, Timestamp } from "@bufbuild/protobuf";
import { StripeRegion, SubscriptionType } from "./payments_pb";
import { SignupRequest } from "./frontend_misc_pb";
import { BasicPagination, MediaValueCalculatorMethod, Network } from "../../common/proto/common_pb";
import { Limitations, UserSettingKind } from "./common_pb";
import { CampaignSnippet } from "../../common/proto/campaign_pb";
import { CWCustomReportsConfiguration } from "./competitive_watch_pb";

/**
 * @generated from enum librarian.proto.CustomerSort
 */
export enum CustomerSort {
  /**
   * @generated from enum value: CUSTOMER_SORT_UNDEFINED = 0;
   */
  UNDEFINED = 0,

  /**
   * @generated from enum value: CUSTOMER_SORT_DATE = 1;
   */
  DATE = 1,

  /**
   * @generated from enum value: CUSTOMER_SORT_EMAIL = 2;
   */
  EMAIL = 2,

  /**
   * @generated from enum value: CUSTOMER_SORT_COMPANY = 3;
   */
  COMPANY = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(CustomerSort)
proto3.util.setEnumType(CustomerSort, "librarian.proto.CustomerSort", [
  { no: 0, name: "CUSTOMER_SORT_UNDEFINED" },
  { no: 1, name: "CUSTOMER_SORT_DATE" },
  { no: 2, name: "CUSTOMER_SORT_EMAIL" },
  { no: 3, name: "CUSTOMER_SORT_COMPANY" },
]);

/**
 * @generated from enum librarian.proto.MergeStatus
 */
export enum MergeStatus {
  /**
   * @generated from enum value: NOT_ALREADY_STARTED = 0;
   */
  NOT_ALREADY_STARTED = 0,

  /**
   * @generated from enum value: MERGE_IN_PROGRESS = 1;
   */
  MERGE_IN_PROGRESS = 1,

  /**
   * @generated from enum value: MERGE_COMPLETED = 2;
   */
  MERGE_COMPLETED = 2,

  /**
   * @generated from enum value: UNMERGE_IN_PROGRESS = 3;
   */
  UNMERGE_IN_PROGRESS = 3,

  /**
   * @generated from enum value: UNMERGE_COMPLETED = 4;
   */
  UNMERGE_COMPLETED = 4,

  /**
   * @generated from enum value: UNMERGE_INCOMPLETED = 5;
   */
  UNMERGE_INCOMPLETED = 5,

  /**
   * @generated from enum value: MERGE_INCOMPLETED = 6;
   */
  MERGE_INCOMPLETED = 6,
}
// Retrieve enum metadata with: proto3.getEnumType(MergeStatus)
proto3.util.setEnumType(MergeStatus, "librarian.proto.MergeStatus", [
  { no: 0, name: "NOT_ALREADY_STARTED" },
  { no: 1, name: "MERGE_IN_PROGRESS" },
  { no: 2, name: "MERGE_COMPLETED" },
  { no: 3, name: "UNMERGE_IN_PROGRESS" },
  { no: 4, name: "UNMERGE_COMPLETED" },
  { no: 5, name: "UNMERGE_INCOMPLETED" },
  { no: 6, name: "MERGE_INCOMPLETED" },
]);

/**
 * @generated from enum librarian.proto.UnmergeActionsAdminView
 */
export enum UnmergeActionsAdminView {
  /**
   * @generated from enum value: NOTHING_TO_DO = 0;
   */
  NOTHING_TO_DO = 0,

  /**
   * means we can't apply any unmerge, because the masterCreator is in a merge/unmerge process or the master was merged, but the merge didn't finish with success
   *
   * @generated from enum value: UNMERGE_BLOCKED = 1;
   */
  UNMERGE_BLOCKED = 1,

  /**
   * means that there is an unmerge incomplete so we have to repair this merge before doing another action
   *
   * @generated from enum value: RETRY_UNMERGE_AUTHORIZED = 2;
   */
  RETRY_UNMERGE_AUTHORIZED = 2,

  /**
   * means that we can unmerge any account from the master account
   *
   * @generated from enum value: UNMERGE_AUTHORIZED = 3;
   */
  UNMERGE_AUTHORIZED = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(UnmergeActionsAdminView)
proto3.util.setEnumType(UnmergeActionsAdminView, "librarian.proto.UnmergeActionsAdminView", [
  { no: 0, name: "NOTHING_TO_DO" },
  { no: 1, name: "UNMERGE_BLOCKED" },
  { no: 2, name: "RETRY_UNMERGE_AUTHORIZED" },
  { no: 3, name: "UNMERGE_AUTHORIZED" },
]);

/**
 * @generated from message librarian.proto.AutocompleteCustomer
 */
export class AutocompleteCustomer extends Message<AutocompleteCustomer> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  constructor(data?: PartialMessage<AutocompleteCustomer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AutocompleteCustomer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteCustomer {
    return new AutocompleteCustomer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteCustomer {
    return new AutocompleteCustomer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteCustomer {
    return new AutocompleteCustomer().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteCustomer | PlainMessage<AutocompleteCustomer> | undefined, b: AutocompleteCustomer | PlainMessage<AutocompleteCustomer> | undefined): boolean {
    return proto3.util.equals(AutocompleteCustomer, a, b);
  }
}

/**
 * @generated from message librarian.proto.AutocompleteCustomersResponse
 */
export class AutocompleteCustomersResponse extends Message<AutocompleteCustomersResponse> {
  /**
   * @generated from field: repeated librarian.proto.AutocompleteCustomer autocomplete_customers = 1;
   */
  autocompleteCustomers: AutocompleteCustomer[] = [];

  constructor(data?: PartialMessage<AutocompleteCustomersResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AutocompleteCustomersResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "autocomplete_customers", kind: "message", T: AutocompleteCustomer, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AutocompleteCustomersResponse {
    return new AutocompleteCustomersResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AutocompleteCustomersResponse {
    return new AutocompleteCustomersResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AutocompleteCustomersResponse {
    return new AutocompleteCustomersResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AutocompleteCustomersResponse | PlainMessage<AutocompleteCustomersResponse> | undefined, b: AutocompleteCustomersResponse | PlainMessage<AutocompleteCustomersResponse> | undefined): boolean {
    return proto3.util.equals(AutocompleteCustomersResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.TeamUser
 */
export class TeamUser extends Message<TeamUser> {
  /**
   * @generated from field: string team_name = 1;
   */
  teamName = "";

  /**
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: int64 team_id = 3;
   */
  teamId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.TeamUser owner = 10;
   */
  owner?: TeamUser;

  /**
   * @generated from field: google.protobuf.Timestamp created = 5;
   */
  created?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp team_creation = 6;
   */
  teamCreation?: Timestamp;

  /**
   * @generated from field: bool team_is_chargebee = 7;
   */
  teamIsChargebee = false;

  /**
   * @generated from field: librarian.proto.SubscriptionType team_subscription = 8;
   */
  teamSubscription = SubscriptionType.SUBSCRIPTION_TYPE_UNKNOWN;

  /**
   * @generated from field: string company = 9;
   */
  company = "";

  /**
   * @generated from field: string first_name = 12;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 13;
   */
  lastName = "";

  /**
   * @generated from field: int64 user_account_id = 14;
   */
  userAccountId = protoInt64.zero;

  /**
   * @generated from field: string email = 15;
   */
  email = "";

  /**
   * @generated from field: int32 team_members_count = 16;
   */
  teamMembersCount = 0;

  constructor(data?: PartialMessage<TeamUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.TeamUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "team_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "team_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 10, name: "owner", kind: "message", T: TeamUser },
    { no: 5, name: "created", kind: "message", T: Timestamp },
    { no: 6, name: "team_creation", kind: "message", T: Timestamp },
    { no: 7, name: "team_is_chargebee", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "team_subscription", kind: "enum", T: proto3.getEnumType(SubscriptionType) },
    { no: 9, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 13, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "user_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 15, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "team_members_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeamUser {
    return new TeamUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeamUser {
    return new TeamUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeamUser {
    return new TeamUser().fromJsonString(jsonString, options);
  }

  static equals(a: TeamUser | PlainMessage<TeamUser> | undefined, b: TeamUser | PlainMessage<TeamUser> | undefined): boolean {
    return proto3.util.equals(TeamUser, a, b);
  }
}

/**
 * @generated from message librarian.proto.Account
 */
export class Account extends Message<Account> {
  /**
   * @generated from field: int64 user_account_id = 1;
   */
  userAccountId = protoInt64.zero;

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: string first_name = 3;
   */
  firstName = "";

  /**
   * @generated from field: string last_name = 4;
   */
  lastName = "";

  /**
   * @generated from field: string company = 12;
   */
  company = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 5;
   */
  created?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated = 6;
   */
  updated?: Timestamp;

  /**
   * @generated from field: repeated librarian.proto.TeamUser users = 7;
   */
  users: TeamUser[] = [];

  /**
   * @generated from field: bool dashboard_access = 8;
   */
  dashboardAccess = false;

  /**
   * @generated from field: string sso_provider_name = 10;
   */
  ssoProviderName = "";

  /**
   * @generated from field: bool admin_access = 11;
   */
  adminAccess = false;

  constructor(data?: PartialMessage<Account>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.Account";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "first_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "last_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "company", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "created", kind: "message", T: Timestamp },
    { no: 6, name: "updated", kind: "message", T: Timestamp },
    { no: 7, name: "users", kind: "message", T: TeamUser, repeated: true },
    { no: 8, name: "dashboard_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 10, name: "sso_provider_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 11, name: "admin_access", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Account {
    return new Account().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Account {
    return new Account().fromJsonString(jsonString, options);
  }

  static equals(a: Account | PlainMessage<Account> | undefined, b: Account | PlainMessage<Account> | undefined): boolean {
    return proto3.util.equals(Account, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetUserAccountsResponse
 */
export class GetUserAccountsResponse extends Message<GetUserAccountsResponse> {
  /**
   * @generated from field: repeated librarian.proto.Account user_accounts = 1;
   */
  userAccounts: Account[] = [];

  /**
   * @generated from field: int32 total_hits = 2;
   */
  totalHits = 0;

  constructor(data?: PartialMessage<GetUserAccountsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetUserAccountsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_accounts", kind: "message", T: Account, repeated: true },
    { no: 2, name: "total_hits", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserAccountsResponse {
    return new GetUserAccountsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserAccountsResponse {
    return new GetUserAccountsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserAccountsResponse {
    return new GetUserAccountsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserAccountsResponse | PlainMessage<GetUserAccountsResponse> | undefined, b: GetUserAccountsResponse | PlainMessage<GetUserAccountsResponse> | undefined): boolean {
    return proto3.util.equals(GetUserAccountsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateUserRequest
 */
export class CreateUserRequest extends Message<CreateUserRequest> {
  /**
   * @generated from field: librarian.proto.SignupRequest signup = 1;
   */
  signup?: SignupRequest;

  /**
   * @generated from field: int32 trial_days = 2;
   */
  trialDays = 0;

  constructor(data?: PartialMessage<CreateUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "signup", kind: "message", T: SignupRequest },
    { no: 2, name: "trial_days", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateUserRequest {
    return new CreateUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateUserRequest {
    return new CreateUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateUserRequest {
    return new CreateUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateUserRequest | PlainMessage<CreateUserRequest> | undefined, b: CreateUserRequest | PlainMessage<CreateUserRequest> | undefined): boolean {
    return proto3.util.equals(CreateUserRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditUserRequest
 */
export class EditUserRequest extends Message<EditUserRequest> {
  /**
   * @generated from field: librarian.proto.SignupRequest signup = 1;
   */
  signup?: SignupRequest;

  /**
   * @generated from field: int64 user_account_id = 2;
   */
  userAccountId = protoInt64.zero;

  constructor(data?: PartialMessage<EditUserRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditUserRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "signup", kind: "message", T: SignupRequest },
    { no: 2, name: "user_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditUserRequest {
    return new EditUserRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditUserRequest {
    return new EditUserRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditUserRequest {
    return new EditUserRequest().fromJsonString(jsonString, options);
  }

  static equals(a: EditUserRequest | PlainMessage<EditUserRequest> | undefined, b: EditUserRequest | PlainMessage<EditUserRequest> | undefined): boolean {
    return proto3.util.equals(EditUserRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.RemoveTeamMembersReq
 */
export class RemoveTeamMembersReq extends Message<RemoveTeamMembersReq> {
  /**
   * @generated from field: int64 team_id = 1;
   */
  teamId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 users_id = 2;
   */
  usersId: bigint[] = [];

  constructor(data?: PartialMessage<RemoveTeamMembersReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RemoveTeamMembersReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "team_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "users_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveTeamMembersReq {
    return new RemoveTeamMembersReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveTeamMembersReq {
    return new RemoveTeamMembersReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveTeamMembersReq {
    return new RemoveTeamMembersReq().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveTeamMembersReq | PlainMessage<RemoveTeamMembersReq> | undefined, b: RemoveTeamMembersReq | PlainMessage<RemoveTeamMembersReq> | undefined): boolean {
    return proto3.util.equals(RemoveTeamMembersReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.RemoveTeamImpersonatorsReq
 */
export class RemoveTeamImpersonatorsReq extends Message<RemoveTeamImpersonatorsReq> {
  /**
   * @generated from field: int64 team_id = 1;
   */
  teamId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 account_ids = 2;
   */
  accountIds: bigint[] = [];

  constructor(data?: PartialMessage<RemoveTeamImpersonatorsReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RemoveTeamImpersonatorsReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "team_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "account_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RemoveTeamImpersonatorsReq {
    return new RemoveTeamImpersonatorsReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RemoveTeamImpersonatorsReq {
    return new RemoveTeamImpersonatorsReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RemoveTeamImpersonatorsReq {
    return new RemoveTeamImpersonatorsReq().fromJsonString(jsonString, options);
  }

  static equals(a: RemoveTeamImpersonatorsReq | PlainMessage<RemoveTeamImpersonatorsReq> | undefined, b: RemoveTeamImpersonatorsReq | PlainMessage<RemoveTeamImpersonatorsReq> | undefined): boolean {
    return proto3.util.equals(RemoveTeamImpersonatorsReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetCustomersRequest
 */
export class GetCustomersRequest extends Message<GetCustomersRequest> {
  /**
   * @generated from field: common.proto.BasicPagination pagination = 1;
   */
  pagination?: BasicPagination;

  /**
   * / filtering
   *
   * @generated from field: bool active = 2;
   */
  active = false;

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: librarian.proto.SubscriptionType subscription_type = 4;
   */
  subscriptionType = SubscriptionType.SUBSCRIPTION_TYPE_UNKNOWN;

  /**
   * @generated from field: bool chargebee_only = 5;
   */
  chargebeeOnly = false;

  /**
   * @generated from field: repeated string companies = 6;
   */
  companies: string[] = [];

  /**
   * default to DATE
   *
   * @generated from field: librarian.proto.CustomerSort sort = 10;
   */
  sort = CustomerSort.UNDEFINED;

  constructor(data?: PartialMessage<GetCustomersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetCustomersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "pagination", kind: "message", T: BasicPagination },
    { no: 2, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subscription_type", kind: "enum", T: proto3.getEnumType(SubscriptionType) },
    { no: 5, name: "chargebee_only", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "companies", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "sort", kind: "enum", T: proto3.getEnumType(CustomerSort) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCustomersRequest {
    return new GetCustomersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCustomersRequest {
    return new GetCustomersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCustomersRequest {
    return new GetCustomersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCustomersRequest | PlainMessage<GetCustomersRequest> | undefined, b: GetCustomersRequest | PlainMessage<GetCustomersRequest> | undefined): boolean {
    return proto3.util.equals(GetCustomersRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.AddTeamMembersRequest
 */
export class AddTeamMembersRequest extends Message<AddTeamMembersRequest> {
  /**
   * @generated from field: int64 team_id = 1;
   */
  teamId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 account_ids = 2;
   */
  accountIds: bigint[] = [];

  constructor(data?: PartialMessage<AddTeamMembersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AddTeamMembersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "team_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "account_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddTeamMembersRequest {
    return new AddTeamMembersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddTeamMembersRequest {
    return new AddTeamMembersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddTeamMembersRequest {
    return new AddTeamMembersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddTeamMembersRequest | PlainMessage<AddTeamMembersRequest> | undefined, b: AddTeamMembersRequest | PlainMessage<AddTeamMembersRequest> | undefined): boolean {
    return proto3.util.equals(AddTeamMembersRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.AddTeamImpersonatorsRequest
 */
export class AddTeamImpersonatorsRequest extends Message<AddTeamImpersonatorsRequest> {
  /**
   * @generated from field: int64 team_id = 1;
   */
  teamId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 account_ids = 2;
   */
  accountIds: bigint[] = [];

  constructor(data?: PartialMessage<AddTeamImpersonatorsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AddTeamImpersonatorsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "team_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "account_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AddTeamImpersonatorsRequest {
    return new AddTeamImpersonatorsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AddTeamImpersonatorsRequest {
    return new AddTeamImpersonatorsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AddTeamImpersonatorsRequest {
    return new AddTeamImpersonatorsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AddTeamImpersonatorsRequest | PlainMessage<AddTeamImpersonatorsRequest> | undefined, b: AddTeamImpersonatorsRequest | PlainMessage<AddTeamImpersonatorsRequest> | undefined): boolean {
    return proto3.util.equals(AddTeamImpersonatorsRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.TeamDetails
 */
export class TeamDetails extends Message<TeamDetails> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.TeamUser owner = 2;
   */
  owner?: TeamUser;

  /**
   * @generated from field: repeated librarian.proto.TeamUser members = 3;
   */
  members: TeamUser[] = [];

  /**
   * @generated from field: repeated librarian.proto.Account impersonators = 18;
   */
  impersonators: Account[] = [];

  /**
   * @generated from field: string name = 4;
   */
  name = "";

  /**
   * @generated from field: librarian.proto.UserSubscription subscription = 5;
   */
  subscription?: UserSubscription;

  /**
   * @generated from field: common.proto.MediaValueCalculatorMethod media_value_calculator_method = 6;
   */
  mediaValueCalculatorMethod = MediaValueCalculatorMethod.DEFAULT;

  /**
   * @generated from field: librarian.proto.Limitations limitations = 7;
   */
  limitations?: Limitations;

  /**
   * @generated from field: google.protobuf.Timestamp created = 9;
   */
  created?: Timestamp;

  /**
   * @generated from field: repeated librarian.proto.UserSettingKind user_settings = 10;
   */
  userSettings: UserSettingKind[] = [];

  /**
   * @generated from field: librarian.proto.StripeRegion stripe_region = 17;
   */
  stripeRegion = StripeRegion.REGION_UNKNOWN;

  /**
   * This is at the user account level.
   * At this moment we consider canary to be defined by the team owner for the whole team.
   *
   * @generated from field: bool is_canary = 8;
   */
  isCanary = false;

  constructor(data?: PartialMessage<TeamDetails>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.TeamDetails";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "owner", kind: "message", T: TeamUser },
    { no: 3, name: "members", kind: "message", T: TeamUser, repeated: true },
    { no: 18, name: "impersonators", kind: "message", T: Account, repeated: true },
    { no: 4, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "subscription", kind: "message", T: UserSubscription },
    { no: 6, name: "media_value_calculator_method", kind: "enum", T: proto3.getEnumType(MediaValueCalculatorMethod) },
    { no: 7, name: "limitations", kind: "message", T: Limitations },
    { no: 9, name: "created", kind: "message", T: Timestamp },
    { no: 10, name: "user_settings", kind: "enum", T: proto3.getEnumType(UserSettingKind), repeated: true },
    { no: 17, name: "stripe_region", kind: "enum", T: proto3.getEnumType(StripeRegion) },
    { no: 8, name: "is_canary", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeamDetails {
    return new TeamDetails().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeamDetails {
    return new TeamDetails().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeamDetails {
    return new TeamDetails().fromJsonString(jsonString, options);
  }

  static equals(a: TeamDetails | PlainMessage<TeamDetails> | undefined, b: TeamDetails | PlainMessage<TeamDetails> | undefined): boolean {
    return proto3.util.equals(TeamDetails, a, b);
  }
}

/**
 * @generated from message librarian.proto.TeamsResponse
 */
export class TeamsResponse extends Message<TeamsResponse> {
  /**
   * @generated from field: repeated librarian.proto.TeamsResponse.TeamLight teams = 1;
   */
  teams: TeamsResponse_TeamLight[] = [];

  constructor(data?: PartialMessage<TeamsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.TeamsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "teams", kind: "message", T: TeamsResponse_TeamLight, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeamsResponse {
    return new TeamsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeamsResponse {
    return new TeamsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeamsResponse {
    return new TeamsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TeamsResponse | PlainMessage<TeamsResponse> | undefined, b: TeamsResponse | PlainMessage<TeamsResponse> | undefined): boolean {
    return proto3.util.equals(TeamsResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.TeamsResponse.TeamLight
 */
export class TeamsResponse_TeamLight extends Message<TeamsResponse_TeamLight> {
  /**
   * @generated from field: int64 id = 1;
   */
  id = protoInt64.zero;

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: int32 members_count = 3;
   */
  membersCount = 0;

  /**
   * @generated from field: librarian.proto.TeamUser owner = 4;
   */
  owner?: TeamUser;

  /**
   * @generated from field: google.protobuf.Timestamp created = 5;
   */
  created?: Timestamp;

  /**
   * @generated from field: librarian.proto.UserSubscription subscription = 6;
   */
  subscription?: UserSubscription;

  constructor(data?: PartialMessage<TeamsResponse_TeamLight>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.TeamsResponse.TeamLight";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "members_count", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "owner", kind: "message", T: TeamUser },
    { no: 5, name: "created", kind: "message", T: Timestamp },
    { no: 6, name: "subscription", kind: "message", T: UserSubscription },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TeamsResponse_TeamLight {
    return new TeamsResponse_TeamLight().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TeamsResponse_TeamLight {
    return new TeamsResponse_TeamLight().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TeamsResponse_TeamLight {
    return new TeamsResponse_TeamLight().fromJsonString(jsonString, options);
  }

  static equals(a: TeamsResponse_TeamLight | PlainMessage<TeamsResponse_TeamLight> | undefined, b: TeamsResponse_TeamLight | PlainMessage<TeamsResponse_TeamLight> | undefined): boolean {
    return proto3.util.equals(TeamsResponse_TeamLight, a, b);
  }
}

/**
 * @generated from message librarian.proto.DebuggedAntKept
 */
export class DebuggedAntKept extends Message<DebuggedAntKept> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  constructor(data?: PartialMessage<DebuggedAntKept>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DebuggedAntKept";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DebuggedAntKept {
    return new DebuggedAntKept().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DebuggedAntKept {
    return new DebuggedAntKept().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DebuggedAntKept {
    return new DebuggedAntKept().fromJsonString(jsonString, options);
  }

  static equals(a: DebuggedAntKept | PlainMessage<DebuggedAntKept> | undefined, b: DebuggedAntKept | PlainMessage<DebuggedAntKept> | undefined): boolean {
    return proto3.util.equals(DebuggedAntKept, a, b);
  }
}

/**
 * @generated from message librarian.proto.DebugNetworkCreator
 */
export class DebugNetworkCreator extends Message<DebugNetworkCreator> {
  /**
   * @generated from field: common.proto.Network network = 1;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: string json = 2;
   */
  json = "";

  /**
   * Obviously not relevant for INSTA, status of merge / unmerge
   *
   * @generated from field: librarian.proto.MergeStatus merge_status = 3;
   */
  mergeStatus = MergeStatus.NOT_ALREADY_STARTED;

  constructor(data?: PartialMessage<DebugNetworkCreator>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DebugNetworkCreator";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 2, name: "json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "merge_status", kind: "enum", T: proto3.getEnumType(MergeStatus) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DebugNetworkCreator {
    return new DebugNetworkCreator().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DebugNetworkCreator {
    return new DebugNetworkCreator().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DebugNetworkCreator {
    return new DebugNetworkCreator().fromJsonString(jsonString, options);
  }

  static equals(a: DebugNetworkCreator | PlainMessage<DebugNetworkCreator> | undefined, b: DebugNetworkCreator | PlainMessage<DebugNetworkCreator> | undefined): boolean {
    return proto3.util.equals(DebugNetworkCreator, a, b);
  }
}

/**
 * @generated from message librarian.proto.DebugUserResponse
 */
export class DebugUserResponse extends Message<DebugUserResponse> {
  /**
   * @generated from field: repeated librarian.proto.DebugNetworkCreator networks = 1;
   */
  networks: DebugNetworkCreator[] = [];

  /**
   * @generated from field: string elastic_link = 3;
   */
  elasticLink = "";

  /**
   * @generated from field: repeated librarian.proto.DebuggedAntKept antkept = 4;
   */
  antkept: DebuggedAntKept[] = [];

  /**
   * set if and only if the user has a computed audience (insta).
   *
   * @generated from field: google.protobuf.StringValue audience_json = 5;
   */
  audienceJson?: string;

  /**
   * @generated from field: string facts_json = 6;
   */
  factsJson = "";

  /**
   * If they are not the same, it means the creator will be updated in next intelligence run...
   *
   * @generated from field: string persisted_fingerprint = 7;
   */
  persistedFingerprint = "";

  /**
   * @generated from field: string current_fingerprint = 8;
   */
  currentFingerprint = "";

  /**
   * Whether there is a unmerge incompleted, which can be on a network already unlinked
   *
   * @generated from field: librarian.proto.UnmergeActionsAdminView unmerge_status_admin = 9;
   */
  unmergeStatusAdmin = UnmergeActionsAdminView.NOTHING_TO_DO;

  /**
   * Running campaigns of user
   *
   * @generated from field: repeated common.proto.CampaignSnippet running_campaigns = 10;
   */
  runningCampaigns: CampaignSnippet[] = [];

  constructor(data?: PartialMessage<DebugUserResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.DebugUserResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "networks", kind: "message", T: DebugNetworkCreator, repeated: true },
    { no: 3, name: "elastic_link", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "antkept", kind: "message", T: DebuggedAntKept, repeated: true },
    { no: 5, name: "audience_json", kind: "message", T: StringValue },
    { no: 6, name: "facts_json", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "persisted_fingerprint", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "current_fingerprint", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "unmerge_status_admin", kind: "enum", T: proto3.getEnumType(UnmergeActionsAdminView) },
    { no: 10, name: "running_campaigns", kind: "message", T: CampaignSnippet, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DebugUserResponse {
    return new DebugUserResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DebugUserResponse {
    return new DebugUserResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DebugUserResponse {
    return new DebugUserResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DebugUserResponse | PlainMessage<DebugUserResponse> | undefined, b: DebugUserResponse | PlainMessage<DebugUserResponse> | undefined): boolean {
    return proto3.util.equals(DebugUserResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateTeamMembersRequest
 */
export class UpdateTeamMembersRequest extends Message<UpdateTeamMembersRequest> {
  /**
   * @generated from field: int64 team_id = 1;
   */
  teamId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 users_to_add = 2;
   */
  usersToAdd: bigint[] = [];

  /**
   * @generated from field: repeated int64 users_to_remove = 3;
   */
  usersToRemove: bigint[] = [];

  constructor(data?: PartialMessage<UpdateTeamMembersRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateTeamMembersRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "team_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "users_to_add", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 3, name: "users_to_remove", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTeamMembersRequest {
    return new UpdateTeamMembersRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTeamMembersRequest {
    return new UpdateTeamMembersRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTeamMembersRequest {
    return new UpdateTeamMembersRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTeamMembersRequest | PlainMessage<UpdateTeamMembersRequest> | undefined, b: UpdateTeamMembersRequest | PlainMessage<UpdateTeamMembersRequest> | undefined): boolean {
    return proto3.util.equals(UpdateTeamMembersRequest, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateCustomerEmailReq
 */
export class UpdateCustomerEmailReq extends Message<UpdateCustomerEmailReq> {
  /**
   * @generated from field: int64 user_account_id = 1;
   */
  userAccountId = protoInt64.zero;

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  constructor(data?: PartialMessage<UpdateCustomerEmailReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateCustomerEmailReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_account_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCustomerEmailReq {
    return new UpdateCustomerEmailReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCustomerEmailReq {
    return new UpdateCustomerEmailReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCustomerEmailReq {
    return new UpdateCustomerEmailReq().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCustomerEmailReq | PlainMessage<UpdateCustomerEmailReq> | undefined, b: UpdateCustomerEmailReq | PlainMessage<UpdateCustomerEmailReq> | undefined): boolean {
    return proto3.util.equals(UpdateCustomerEmailReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.CreateTeamReq
 */
export class CreateTeamReq extends Message<CreateTeamReq> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: int64 owner_id = 2;
   */
  ownerId = protoInt64.zero;

  /**
   * @generated from field: repeated int64 members_id = 3;
   */
  membersId: bigint[] = [];

  /**
   * @generated from field: bool is_canary = 4;
   */
  isCanary = false;

  /**
   * @generated from field: librarian.proto.StripeRegion stripe_region = 5;
   */
  stripeRegion = StripeRegion.REGION_UNKNOWN;

  constructor(data?: PartialMessage<CreateTeamReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.CreateTeamReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "owner_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "members_id", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 4, name: "is_canary", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "stripe_region", kind: "enum", T: proto3.getEnumType(StripeRegion) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateTeamReq {
    return new CreateTeamReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateTeamReq {
    return new CreateTeamReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateTeamReq {
    return new CreateTeamReq().fromJsonString(jsonString, options);
  }

  static equals(a: CreateTeamReq | PlainMessage<CreateTeamReq> | undefined, b: CreateTeamReq | PlainMessage<CreateTeamReq> | undefined): boolean {
    return proto3.util.equals(CreateTeamReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateTeamReq
 */
export class UpdateTeamReq extends Message<UpdateTeamReq> {
  /**
   * @generated from field: int64 team_id = 1;
   */
  teamId = protoInt64.zero;

  /**
   * @generated from field: google.protobuf.StringValue new_name = 2;
   */
  newName?: string;

  /**
   * @generated from field: google.protobuf.Int64Value new_owner_id = 3;
   */
  newOwnerId?: bigint;

  /**
   * @generated from field: bool is_canary = 4;
   */
  isCanary = false;

  /**
   * @generated from field: repeated librarian.proto.UserSettingKind user_settings = 5;
   */
  userSettings: UserSettingKind[] = [];

  /**
   * @generated from field: librarian.proto.StripeRegion stripe_region = 6;
   */
  stripeRegion = StripeRegion.REGION_UNKNOWN;

  constructor(data?: PartialMessage<UpdateTeamReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateTeamReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "team_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "new_name", kind: "message", T: StringValue },
    { no: 3, name: "new_owner_id", kind: "message", T: Int64Value },
    { no: 4, name: "is_canary", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "user_settings", kind: "enum", T: proto3.getEnumType(UserSettingKind), repeated: true },
    { no: 6, name: "stripe_region", kind: "enum", T: proto3.getEnumType(StripeRegion) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateTeamReq {
    return new UpdateTeamReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateTeamReq {
    return new UpdateTeamReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateTeamReq {
    return new UpdateTeamReq().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateTeamReq | PlainMessage<UpdateTeamReq> | undefined, b: UpdateTeamReq | PlainMessage<UpdateTeamReq> | undefined): boolean {
    return proto3.util.equals(UpdateTeamReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.RecoverStoriesReq
 */
export class RecoverStoriesReq extends Message<RecoverStoriesReq> {
  /**
   * @generated from field: string creator_id = 1;
   */
  creatorId = "";

  /**
   * Time at which to retrofit the story. Most often the customer just gives us a day so the admin operator can chose a
   * random hour like 4PM or so.
   *
   * @generated from field: google.protobuf.Timestamp creation_time = 2;
   */
  creationTime?: Timestamp;

  /**
   * hashtags and mentions to add to the stories. PS: the customers most often asks that we upload stories to a given
   * campaign because that's how they think. But that's not how our backend works we won't hikack a markAsPost call when
   * we ingest those stories so the easiest and cleaner way is just to retrofit one of the mentions they track.
   *
   * @generated from field: repeated string hashtags = 3;
   */
  hashtags: string[] = [];

  /**
   * @generated from field: repeated string mentions = 4;
   */
  mentions: string[] = [];

  /**
   * Those tokens must have been obtained via a valid upload of the assets using UploadStoryAssetAPI.
   * There must be at least 1. If there are more, we create several stories at the same time using the same author, time
   * and keywords. Useful to implement a user friendly frontend since this is most always the case.
   *
   * @generated from field: repeated string asset_tokens = 5;
   */
  assetTokens: string[] = [];

  /**
   * for now, only Instagram and TikTok
   *
   * @generated from field: common.proto.Network network = 7;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * Workspace team owner id
   *
   * @generated from field: int64 owner_id = 6;
   */
  ownerId = protoInt64.zero;

  constructor(data?: PartialMessage<RecoverStoriesReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.RecoverStoriesReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "creation_time", kind: "message", T: Timestamp },
    { no: 3, name: "hashtags", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "mentions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "asset_tokens", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 7, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 6, name: "owner_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RecoverStoriesReq {
    return new RecoverStoriesReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RecoverStoriesReq {
    return new RecoverStoriesReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RecoverStoriesReq {
    return new RecoverStoriesReq().fromJsonString(jsonString, options);
  }

  static equals(a: RecoverStoriesReq | PlainMessage<RecoverStoriesReq> | undefined, b: RecoverStoriesReq | PlainMessage<RecoverStoriesReq> | undefined): boolean {
    return proto3.util.equals(RecoverStoriesReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.UserSubscription
 */
export class UserSubscription extends Message<UserSubscription> {
  /**
   * @generated from field: librarian.proto.SubscriptionType subscription_type = 1;
   */
  subscriptionType = SubscriptionType.SUBSCRIPTION_TYPE_UNKNOWN;

  /**
   * @generated from field: int32 additionalReports = 2;
   */
  additionalReports = 0;

  /**
   * @generated from field: int32 additionalCampaigns = 3;
   */
  additionalCampaigns = 0;

  /**
   * @generated from field: int32 additionalRunningCampaigns = 4;
   */
  additionalRunningCampaigns = 0;

  /**
   * @generated from field: int32 additionalInfluencersInCampaign = 5;
   */
  additionalInfluencersInCampaign = 0;

  /**
   * @generated from field: int32 additionalInfluencersTracked = 9;
   */
  additionalInfluencersTracked = 0;

  /**
   * @generated from field: int32 additionalAffiliationInfluencers = 10;
   */
  additionalAffiliationInfluencers = 0;

  /**
   * @generated from field: repeated common.proto.Network allowed_networks = 11;
   */
  allowedNetworks: Network[] = [];

  /**
   * @generated from oneof librarian.proto.UserSubscription.cw_custom_reports
   */
  cwCustomReports: {
    /**
     * used to only know if we have access to this feature or to remove access if set to false when editing subscription
     *
     * @generated from field: bool has_cw_custom_reports = 13;
     */
    value: boolean;
    case: "hasCwCustomReports";
  } | {
    /**
     * only need set/set group/brand ids when editing subscription
     *
     * @generated from field: librarian.proto.CWCustomReportsConfiguration cw_custom_reports_configuration = 14;
     */
    value: CWCustomReportsConfiguration;
    case: "cwCustomReportsConfiguration";
  } | { case: undefined; value?: undefined } = { case: undefined };

  /**
   * @generated from field: google.protobuf.Timestamp expires = 6;
   */
  expires?: Timestamp;

  /**
   * @generated from field: bool is_chargebee = 7;
   */
  isChargebee = false;

  /**
   * set only if chargebee is false
   *
   * @generated from field: int64 id = 8;
   */
  id = protoInt64.zero;

  /**
   * If subscription is managed in chargebee but now expired.
   *
   * @generated from field: bool was_chargeebee = 12;
   */
  wasChargeebee = false;

  constructor(data?: PartialMessage<UserSubscription>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UserSubscription";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subscription_type", kind: "enum", T: proto3.getEnumType(SubscriptionType) },
    { no: 2, name: "additionalReports", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "additionalCampaigns", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 4, name: "additionalRunningCampaigns", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 5, name: "additionalInfluencersInCampaign", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 9, name: "additionalInfluencersTracked", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 10, name: "additionalAffiliationInfluencers", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 11, name: "allowed_networks", kind: "enum", T: proto3.getEnumType(Network), repeated: true },
    { no: 13, name: "has_cw_custom_reports", kind: "scalar", T: 8 /* ScalarType.BOOL */, oneof: "cw_custom_reports" },
    { no: 14, name: "cw_custom_reports_configuration", kind: "message", T: CWCustomReportsConfiguration, oneof: "cw_custom_reports" },
    { no: 6, name: "expires", kind: "message", T: Timestamp },
    { no: 7, name: "is_chargebee", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "was_chargeebee", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserSubscription {
    return new UserSubscription().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserSubscription {
    return new UserSubscription().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserSubscription {
    return new UserSubscription().fromJsonString(jsonString, options);
  }

  static equals(a: UserSubscription | PlainMessage<UserSubscription> | undefined, b: UserSubscription | PlainMessage<UserSubscription> | undefined): boolean {
    return proto3.util.equals(UserSubscription, a, b);
  }
}

/**
 * @generated from message librarian.proto.GrantSubscriptionReq
 */
export class GrantSubscriptionReq extends Message<GrantSubscriptionReq> {
  /**
   * @generated from field: int64 user_id = 1;
   */
  userId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.UserSubscription subscription = 2;
   */
  subscription?: UserSubscription;

  constructor(data?: PartialMessage<GrantSubscriptionReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GrantSubscriptionReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subscription", kind: "message", T: UserSubscription },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GrantSubscriptionReq {
    return new GrantSubscriptionReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GrantSubscriptionReq {
    return new GrantSubscriptionReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GrantSubscriptionReq {
    return new GrantSubscriptionReq().fromJsonString(jsonString, options);
  }

  static equals(a: GrantSubscriptionReq | PlainMessage<GrantSubscriptionReq> | undefined, b: GrantSubscriptionReq | PlainMessage<GrantSubscriptionReq> | undefined): boolean {
    return proto3.util.equals(GrantSubscriptionReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditSubscriptionReq
 */
export class EditSubscriptionReq extends Message<EditSubscriptionReq> {
  /**
   * @generated from field: int64 subscription_id = 1;
   */
  subscriptionId = protoInt64.zero;

  /**
   * @generated from field: librarian.proto.UserSubscription subscription = 2;
   */
  subscription?: UserSubscription;

  constructor(data?: PartialMessage<EditSubscriptionReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditSubscriptionReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "subscription_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "subscription", kind: "message", T: UserSubscription },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditSubscriptionReq {
    return new EditSubscriptionReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditSubscriptionReq {
    return new EditSubscriptionReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditSubscriptionReq {
    return new EditSubscriptionReq().fromJsonString(jsonString, options);
  }

  static equals(a: EditSubscriptionReq | PlainMessage<EditSubscriptionReq> | undefined, b: EditSubscriptionReq | PlainMessage<EditSubscriptionReq> | undefined): boolean {
    return proto3.util.equals(EditSubscriptionReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.EditCalculatorMethodReq
 */
export class EditCalculatorMethodReq extends Message<EditCalculatorMethodReq> {
  /**
   * Calculator is saved on the owner settings
   *
   * @generated from field: int64 owner_id = 1;
   */
  ownerId = protoInt64.zero;

  /**
   * @generated from field: common.proto.MediaValueCalculatorMethod media_value_calculator_method = 6;
   */
  mediaValueCalculatorMethod = MediaValueCalculatorMethod.DEFAULT;

  constructor(data?: PartialMessage<EditCalculatorMethodReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.EditCalculatorMethodReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "owner_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "media_value_calculator_method", kind: "enum", T: proto3.getEnumType(MediaValueCalculatorMethod) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EditCalculatorMethodReq {
    return new EditCalculatorMethodReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EditCalculatorMethodReq {
    return new EditCalculatorMethodReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EditCalculatorMethodReq {
    return new EditCalculatorMethodReq().fromJsonString(jsonString, options);
  }

  static equals(a: EditCalculatorMethodReq | PlainMessage<EditCalculatorMethodReq> | undefined, b: EditCalculatorMethodReq | PlainMessage<EditCalculatorMethodReq> | undefined): boolean {
    return proto3.util.equals(EditCalculatorMethodReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.AppSetting
 */
export class AppSetting extends Message<AppSetting> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string value = 2;
   */
  value = "";

  constructor(data?: PartialMessage<AppSetting>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AppSetting";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "value", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppSetting {
    return new AppSetting().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppSetting {
    return new AppSetting().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppSetting {
    return new AppSetting().fromJsonString(jsonString, options);
  }

  static equals(a: AppSetting | PlainMessage<AppSetting> | undefined, b: AppSetting | PlainMessage<AppSetting> | undefined): boolean {
    return proto3.util.equals(AppSetting, a, b);
  }
}

/**
 * @generated from message librarian.proto.AppSettingsList
 */
export class AppSettingsList extends Message<AppSettingsList> {
  /**
   * @generated from field: repeated librarian.proto.AppSetting settings = 1;
   */
  settings: AppSetting[] = [];

  constructor(data?: PartialMessage<AppSettingsList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.AppSettingsList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "settings", kind: "message", T: AppSetting, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AppSettingsList {
    return new AppSettingsList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AppSettingsList {
    return new AppSettingsList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AppSettingsList {
    return new AppSettingsList().fromJsonString(jsonString, options);
  }

  static equals(a: AppSettingsList | PlainMessage<AppSettingsList> | undefined, b: AppSettingsList | PlainMessage<AppSettingsList> | undefined): boolean {
    return proto3.util.equals(AppSettingsList, a, b);
  }
}

/**
 * @generated from message librarian.proto.UnmergeReq
 */
export class UnmergeReq extends Message<UnmergeReq> {
  /**
   * @generated from field: string creator_id = 1;
   */
  creatorId = "";

  /**
   * Network to unmerge.
   *
   * @generated from field: common.proto.Network network = 2;
   */
  network = Network.NETWORK_UNKNOWN;

  /**
   * @generated from field: bool retry_after_error = 3;
   */
  retryAfterError = false;

  constructor(data?: PartialMessage<UnmergeReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UnmergeReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "creator_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "network", kind: "enum", T: proto3.getEnumType(Network) },
    { no: 3, name: "retry_after_error", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UnmergeReq {
    return new UnmergeReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UnmergeReq {
    return new UnmergeReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UnmergeReq {
    return new UnmergeReq().fromJsonString(jsonString, options);
  }

  static equals(a: UnmergeReq | PlainMessage<UnmergeReq> | undefined, b: UnmergeReq | PlainMessage<UnmergeReq> | undefined): boolean {
    return proto3.util.equals(UnmergeReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.IncludeExcludeCampaignFromStoryPollingReq
 */
export class IncludeExcludeCampaignFromStoryPollingReq extends Message<IncludeExcludeCampaignFromStoryPollingReq> {
  /**
   * @generated from field: int64 campaign_id = 1;
   */
  campaignId = protoInt64.zero;

  /**
   * @generated from field: bool exclude = 2;
   */
  exclude = false;

  constructor(data?: PartialMessage<IncludeExcludeCampaignFromStoryPollingReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.IncludeExcludeCampaignFromStoryPollingReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaign_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "exclude", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IncludeExcludeCampaignFromStoryPollingReq {
    return new IncludeExcludeCampaignFromStoryPollingReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IncludeExcludeCampaignFromStoryPollingReq {
    return new IncludeExcludeCampaignFromStoryPollingReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IncludeExcludeCampaignFromStoryPollingReq {
    return new IncludeExcludeCampaignFromStoryPollingReq().fromJsonString(jsonString, options);
  }

  static equals(a: IncludeExcludeCampaignFromStoryPollingReq | PlainMessage<IncludeExcludeCampaignFromStoryPollingReq> | undefined, b: IncludeExcludeCampaignFromStoryPollingReq | PlainMessage<IncludeExcludeCampaignFromStoryPollingReq> | undefined): boolean {
    return proto3.util.equals(IncludeExcludeCampaignFromStoryPollingReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.IncludeExcludeCampaignsFromStoryPollingReq
 */
export class IncludeExcludeCampaignsFromStoryPollingReq extends Message<IncludeExcludeCampaignsFromStoryPollingReq> {
  /**
   * @generated from field: repeated int64 campaigns_ids = 1;
   */
  campaignsIds: bigint[] = [];

  /**
   * @generated from field: bool exclude = 2;
   */
  exclude = false;

  constructor(data?: PartialMessage<IncludeExcludeCampaignsFromStoryPollingReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.IncludeExcludeCampaignsFromStoryPollingReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaigns_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 2, name: "exclude", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): IncludeExcludeCampaignsFromStoryPollingReq {
    return new IncludeExcludeCampaignsFromStoryPollingReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): IncludeExcludeCampaignsFromStoryPollingReq {
    return new IncludeExcludeCampaignsFromStoryPollingReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): IncludeExcludeCampaignsFromStoryPollingReq {
    return new IncludeExcludeCampaignsFromStoryPollingReq().fromJsonString(jsonString, options);
  }

  static equals(a: IncludeExcludeCampaignsFromStoryPollingReq | PlainMessage<IncludeExcludeCampaignsFromStoryPollingReq> | undefined, b: IncludeExcludeCampaignsFromStoryPollingReq | PlainMessage<IncludeExcludeCampaignsFromStoryPollingReq> | undefined): boolean {
    return proto3.util.equals(IncludeExcludeCampaignsFromStoryPollingReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.UpdateStoryPollingPriorityReq
 */
export class UpdateStoryPollingPriorityReq extends Message<UpdateStoryPollingPriorityReq> {
  /**
   * @generated from field: repeated int64 campaigns_ids = 1;
   */
  campaignsIds: bigint[] = [];

  /**
   * @generated from field: int32 priority = 2;
   */
  priority = 0;

  constructor(data?: PartialMessage<UpdateStoryPollingPriorityReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.UpdateStoryPollingPriorityReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "campaigns_ids", kind: "scalar", T: 3 /* ScalarType.INT64 */, repeated: true },
    { no: 2, name: "priority", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateStoryPollingPriorityReq {
    return new UpdateStoryPollingPriorityReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateStoryPollingPriorityReq {
    return new UpdateStoryPollingPriorityReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateStoryPollingPriorityReq {
    return new UpdateStoryPollingPriorityReq().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateStoryPollingPriorityReq | PlainMessage<UpdateStoryPollingPriorityReq> | undefined, b: UpdateStoryPollingPriorityReq | PlainMessage<UpdateStoryPollingPriorityReq> | undefined): boolean {
    return proto3.util.equals(UpdateStoryPollingPriorityReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.ListCompaniesResponse
 */
export class ListCompaniesResponse extends Message<ListCompaniesResponse> {
  /**
   * @generated from field: repeated string companies = 1;
   */
  companies: string[] = [];

  constructor(data?: PartialMessage<ListCompaniesResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ListCompaniesResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "companies", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCompaniesResponse {
    return new ListCompaniesResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCompaniesResponse {
    return new ListCompaniesResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCompaniesResponse {
    return new ListCompaniesResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListCompaniesResponse | PlainMessage<ListCompaniesResponse> | undefined, b: ListCompaniesResponse | PlainMessage<ListCompaniesResponse> | undefined): boolean {
    return proto3.util.equals(ListCompaniesResponse, a, b);
  }
}

/**
 * @generated from message librarian.proto.ExportTeamMembersReq
 */
export class ExportTeamMembersReq extends Message<ExportTeamMembersReq> {
  /**
   * @generated from field: int64 team_id = 1;
   */
  teamId = protoInt64.zero;

  /**
   * @generated from field: int64 user_id = 2;
   */
  userId = protoInt64.zero;

  constructor(data?: PartialMessage<ExportTeamMembersReq>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.ExportTeamMembersReq";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "team_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "user_id", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ExportTeamMembersReq {
    return new ExportTeamMembersReq().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ExportTeamMembersReq {
    return new ExportTeamMembersReq().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ExportTeamMembersReq {
    return new ExportTeamMembersReq().fromJsonString(jsonString, options);
  }

  static equals(a: ExportTeamMembersReq | PlainMessage<ExportTeamMembersReq> | undefined, b: ExportTeamMembersReq | PlainMessage<ExportTeamMembersReq> | undefined): boolean {
    return proto3.util.equals(ExportTeamMembersReq, a, b);
  }
}

/**
 * @generated from message librarian.proto.GetUserAccountResp
 */
export class GetUserAccountResp extends Message<GetUserAccountResp> {
  /**
   * @generated from field: librarian.proto.Account account = 1;
   */
  account?: Account;

  /**
   * Can impersonate only if can impersonate in any workspace where the account if member
   *
   * @generated from field: bool can_impersonate = 2;
   */
  canImpersonate = false;

  constructor(data?: PartialMessage<GetUserAccountResp>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "librarian.proto.GetUserAccountResp";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "message", T: Account },
    { no: 2, name: "can_impersonate", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetUserAccountResp {
    return new GetUserAccountResp().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetUserAccountResp {
    return new GetUserAccountResp().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetUserAccountResp {
    return new GetUserAccountResp().fromJsonString(jsonString, options);
  }

  static equals(a: GetUserAccountResp | PlainMessage<GetUserAccountResp> | undefined, b: GetUserAccountResp | PlainMessage<GetUserAccountResp> | undefined): boolean {
    return proto3.util.equals(GetUserAccountResp, a, b);
  }
}


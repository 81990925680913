import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { LibrarianPayments } from '@frontend2/proto/librarian/proto/payments_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianPaymentsClient = PromiseClient<typeof LibrarianPayments>;

const factory = (): LibrarianPaymentsClient => {
  return createPromiseClient(LibrarianPayments, inject(GRPC_TRANSPORT));
};

const token = new InjectionToken('LibrarianPaymentsClient', { factory });

export function injectLibrarianPaymentsClient(): LibrarianPaymentsClient {
  return inject(token);
}

import { inject, Injectable } from '@angular/core';
import { API_HOST } from '@frontend2/api';
import { CampaignKeywordType } from '@frontend2/proto/common/proto/campaign_pb';
import {
  LogicOperator,
  Network,
} from '@frontend2/proto/common/proto/common_pb';
import { Post } from '../lefty-posts/posts.models';
import {
  createTrackingKeywordsConfig,
  StringOrLocation,
} from './config.helpers';

export interface TrackingKeywordsConfig {
  readonly types: CampaignKeywordType[];
  readonly errorMessage: string;
  readonly formHelpers: Map<CampaignKeywordType, FormField>;
  readonly delimiters: Map<CampaignKeywordType, string>;
}

export interface FormField {
  readonly title: string;
  readonly body: string;
  readonly required: boolean;
  readonly name: string;
  readonly placeholder: string;
}

export interface CampaignNetworkTrackingForm {
  readonly type: 'CampaignNetworkTrackingForm';
  readonly network: Network;
  readonly keywords: Map<CampaignKeywordType, StringOrLocation[]>;
  readonly excludedKeywords: Map<CampaignKeywordType, StringOrLocation[]>;
  readonly keywordLogicOperator: Map<CampaignKeywordType, LogicOperator>;
  readonly logicOperator: LogicOperator;
}

export interface NetworkConfig {
  readonly network: Network;
  readonly keywords: TrackingKeywordsConfig;
  readonly supportEngagementRate: boolean;
  readonly supportFollowers: boolean;
  readonly supportSaturationRate: boolean;
  readonly supportAvgCoverage: boolean;
  readonly supportViews: boolean;
  readonly supportReshares: boolean;
  readonly uploadPlaceholder: string;

  /// Build Lefty Discover search tag url
  hashtagDiscoverPath(tag: string): string | undefined;

  /// Build Lefty Discover search mention url
  mentionDiscoverPath(mention: string): string | undefined;

  /// Build external tag link for the network
  /// ex: https://weibo.com/n/$tag
  externalTagUrl(tag: string): string | undefined;

  /// Build external mention link for the network
  /// ex: https://weibo.com/n/$mention
  externalMentionUrl(mention: string): string | undefined;

  sanitizeCaptionHref(post: Post, href: string): string;

  getCaption(post: Post): string;

  getExternalPostLink(post: Post): string;

  // TODO: add more config
}

@Injectable({ providedIn: 'root' })
export class DefaultNetworkConfig implements NetworkConfig {
  readonly apiHost = inject(API_HOST);

  readonly network: Network = Network.NETWORK_UNKNOWN;
  readonly keywords = createTrackingKeywordsConfig();
  readonly supportEngagementRate: boolean = true;
  readonly supportFollowers: boolean = true;
  readonly supportSaturationRate: boolean = false;
  readonly supportAvgCoverage: boolean = false;
  readonly supportViews: boolean = false;
  readonly supportReshares: boolean = false;
  readonly uploadPlaceholder: string = '';

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  hashtagDiscoverPath(tag: string): string | undefined {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  mentionDiscoverPath(mention: string): string | undefined {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  externalTagUrl(tag: string): string | undefined {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  externalMentionUrl(mention: string): string | undefined {
    return;
  }

  sanitizeCaptionHref(post: Post, href: string): string {
    return href;
  }

  getCaption(post: Post): string {
    return post.caption;
  }

  getExternalPostLink(post: Post): string {
    return `${this.apiHost}/_api/open_in_network?id=${post.id}`;
  }
}

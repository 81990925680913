// Defines the interface for the topic modelling server Topico.

// @generated by protoc-gen-es v1.10.0 with parameter "target=ts"
// @generated from file galois/proto/topico.proto (package galois.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { ImageMetadata } from "../../common/proto/instagram_media_pb";
import { CategoryV2 } from "../../common/proto/user_pb";

/**
 * Note(christian) Those are just categories either for a post or for a creator
 * (when it is aggregated over many posts of a creator it becomes a category for that creator).
 * We are not going to rename it for now as it could break python and java code.
 *
 * @generated from enum galois.proto.PostCategories
 */
export enum PostCategories {
  /**
   * @generated from enum value: UNKNOWN = 0;
   */
  UNKNOWN = 0,

  /**
   * @generated from enum value: BEAUTY = 1;
   */
  BEAUTY = 1,

  /**
   * @generated from enum value: FASHION = 2;
   */
  FASHION = 2,

  /**
   * @generated from enum value: BEAUTY_MAKEUP = 3;
   */
  BEAUTY_MAKEUP = 3,

  /**
   * @generated from enum value: BEAUTY_SKINCARE = 4;
   */
  BEAUTY_SKINCARE = 4,

  /**
   * @generated from enum value: BEAUTY_PERFUME = 5;
   */
  BEAUTY_PERFUME = 5,

  /**
   * @generated from enum value: BEAUTY_HAIR = 6;
   */
  BEAUTY_HAIR = 6,

  /**
   * @generated from enum value: FASHION_HANDBAG = 7;
   */
  FASHION_HANDBAG = 7,

  /**
   * @generated from enum value: FASHION_SHOES = 8;
   */
  FASHION_SHOES = 8,

  /**
   * @generated from enum value: FASHION_JEWELRY = 9;
   */
  FASHION_JEWELRY = 9,

  /**
   * @generated from enum value: FASHION_WATCHES = 10;
   */
  FASHION_WATCHES = 10,

  /**
   * @generated from enum value: FASHION_CLOTHES = 11;
   */
  FASHION_CLOTHES = 11,

  /**
   * @generated from enum value: PARENTING_PREGNANCY = 12;
   */
  PARENTING_PREGNANCY = 12,

  /**
   * @generated from enum value: SPORT = 13;
   */
  SPORT = 13,

  /**
   * @generated from enum value: FOOD_DRINKS = 14;
   */
  FOOD_DRINKS = 14,

  /**
   * @generated from enum value: TRAVEL_OUTDOORS = 15;
   */
  TRAVEL_OUTDOORS = 15,
}
// Retrieve enum metadata with: proto3.getEnumType(PostCategories)
proto3.util.setEnumType(PostCategories, "galois.proto.PostCategories", [
  { no: 0, name: "UNKNOWN" },
  { no: 1, name: "BEAUTY" },
  { no: 2, name: "FASHION" },
  { no: 3, name: "BEAUTY_MAKEUP" },
  { no: 4, name: "BEAUTY_SKINCARE" },
  { no: 5, name: "BEAUTY_PERFUME" },
  { no: 6, name: "BEAUTY_HAIR" },
  { no: 7, name: "FASHION_HANDBAG" },
  { no: 8, name: "FASHION_SHOES" },
  { no: 9, name: "FASHION_JEWELRY" },
  { no: 10, name: "FASHION_WATCHES" },
  { no: 11, name: "FASHION_CLOTHES" },
  { no: 12, name: "PARENTING_PREGNANCY" },
  { no: 13, name: "SPORT" },
  { no: 14, name: "FOOD_DRINKS" },
  { no: 15, name: "TRAVEL_OUTDOORS" },
]);

/**
 * @generated from message galois.proto.TopicoRequest
 */
export class TopicoRequest extends Message<TopicoRequest> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * @generated from field: string bio = 2;
   */
  bio = "";

  /**
   * We shall typically pass the last 100 captions.
   *
   * @generated from field: repeated string captions = 3;
   */
  captions: string[] = [];

  constructor(data?: PartialMessage<TopicoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.TopicoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bio", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "captions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicoRequest {
    return new TopicoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicoRequest {
    return new TopicoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicoRequest {
    return new TopicoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TopicoRequest | PlainMessage<TopicoRequest> | undefined, b: TopicoRequest | PlainMessage<TopicoRequest> | undefined): boolean {
    return proto3.util.equals(TopicoRequest, a, b);
  }
}

/**
 * @generated from message galois.proto.ImageWithContent
 */
export class ImageWithContent extends Message<ImageWithContent> {
  /**
   * @generated from field: common.proto.ImageMetadata image = 1;
   */
  image?: ImageMetadata;

  /**
   * The actual image content (eg: the jpg).
   *
   * @generated from field: bytes content = 2;
   */
  content = new Uint8Array(0);

  constructor(data?: PartialMessage<ImageWithContent>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.ImageWithContent";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "image", kind: "message", T: ImageMetadata },
    { no: 2, name: "content", kind: "scalar", T: 12 /* ScalarType.BYTES */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ImageWithContent {
    return new ImageWithContent().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ImageWithContent {
    return new ImageWithContent().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ImageWithContent {
    return new ImageWithContent().fromJsonString(jsonString, options);
  }

  static equals(a: ImageWithContent | PlainMessage<ImageWithContent> | undefined, b: ImageWithContent | PlainMessage<ImageWithContent> | undefined): boolean {
    return proto3.util.equals(ImageWithContent, a, b);
  }
}

/**
 * @generated from message galois.proto.TopicoRequestV2
 */
export class TopicoRequestV2 extends Message<TopicoRequestV2> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * @generated from field: string bio = 2;
   */
  bio = "";

  /**
   * We typically try to pass 50 images but we might have less, if the creators doesn't post enough images, etc...
   * The server can throw an exception if there is not enough images in the list to do its job.
   *
   * @generated from field: repeated galois.proto.ImageWithContent images = 3;
   */
  images: ImageWithContent[] = [];

  constructor(data?: PartialMessage<TopicoRequestV2>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.TopicoRequestV2";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "bio", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "images", kind: "message", T: ImageWithContent, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicoRequestV2 {
    return new TopicoRequestV2().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicoRequestV2 {
    return new TopicoRequestV2().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicoRequestV2 {
    return new TopicoRequestV2().fromJsonString(jsonString, options);
  }

  static equals(a: TopicoRequestV2 | PlainMessage<TopicoRequestV2> | undefined, b: TopicoRequestV2 | PlainMessage<TopicoRequestV2> | undefined): boolean {
    return proto3.util.equals(TopicoRequestV2, a, b);
  }
}

/**
 * @generated from message galois.proto.PostTopicoImageRequest
 */
export class PostTopicoImageRequest extends Message<PostTopicoImageRequest> {
  /**
   * @generated from field: string post_id = 1;
   */
  postId = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  constructor(data?: PartialMessage<PostTopicoImageRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.PostTopicoImageRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostTopicoImageRequest {
    return new PostTopicoImageRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostTopicoImageRequest {
    return new PostTopicoImageRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostTopicoImageRequest {
    return new PostTopicoImageRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PostTopicoImageRequest | PlainMessage<PostTopicoImageRequest> | undefined, b: PostTopicoImageRequest | PlainMessage<PostTopicoImageRequest> | undefined): boolean {
    return proto3.util.equals(PostTopicoImageRequest, a, b);
  }
}

/**
 * @generated from message galois.proto.PostTopicoCaptionRequest
 */
export class PostTopicoCaptionRequest extends Message<PostTopicoCaptionRequest> {
  /**
   * @generated from field: string caption = 1;
   */
  caption = "";

  constructor(data?: PartialMessage<PostTopicoCaptionRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.PostTopicoCaptionRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "caption", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostTopicoCaptionRequest {
    return new PostTopicoCaptionRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostTopicoCaptionRequest {
    return new PostTopicoCaptionRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostTopicoCaptionRequest {
    return new PostTopicoCaptionRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PostTopicoCaptionRequest | PlainMessage<PostTopicoCaptionRequest> | undefined, b: PostTopicoCaptionRequest | PlainMessage<PostTopicoCaptionRequest> | undefined): boolean {
    return proto3.util.equals(PostTopicoCaptionRequest, a, b);
  }
}

/**
 * @generated from message galois.proto.PostTopicoCombineRequest
 */
export class PostTopicoCombineRequest extends Message<PostTopicoCombineRequest> {
  /**
   * @generated from field: string post_id = 1;
   */
  postId = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * @generated from field: string caption = 3;
   */
  caption = "";

  /**
   * @generated from field: repeated string mentions = 4;
   */
  mentions: string[] = [];

  constructor(data?: PartialMessage<PostTopicoCombineRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.PostTopicoCombineRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "caption", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "mentions", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostTopicoCombineRequest {
    return new PostTopicoCombineRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostTopicoCombineRequest {
    return new PostTopicoCombineRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostTopicoCombineRequest {
    return new PostTopicoCombineRequest().fromJsonString(jsonString, options);
  }

  static equals(a: PostTopicoCombineRequest | PlainMessage<PostTopicoCombineRequest> | undefined, b: PostTopicoCombineRequest | PlainMessage<PostTopicoCombineRequest> | undefined): boolean {
    return proto3.util.equals(PostTopicoCombineRequest, a, b);
  }
}

/**
 * @generated from message galois.proto.BatchTopicoCombineRequest
 */
export class BatchTopicoCombineRequest extends Message<BatchTopicoCombineRequest> {
  /**
   * @generated from field: repeated galois.proto.PostTopicoCombineRequest requests = 1;
   */
  requests: PostTopicoCombineRequest[] = [];

  constructor(data?: PartialMessage<BatchTopicoCombineRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.BatchTopicoCombineRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "requests", kind: "message", T: PostTopicoCombineRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchTopicoCombineRequest {
    return new BatchTopicoCombineRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchTopicoCombineRequest {
    return new BatchTopicoCombineRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchTopicoCombineRequest {
    return new BatchTopicoCombineRequest().fromJsonString(jsonString, options);
  }

  static equals(a: BatchTopicoCombineRequest | PlainMessage<BatchTopicoCombineRequest> | undefined, b: BatchTopicoCombineRequest | PlainMessage<BatchTopicoCombineRequest> | undefined): boolean {
    return proto3.util.equals(BatchTopicoCombineRequest, a, b);
  }
}

/**
 * @generated from message galois.proto.UserPostsTopicoRequest
 */
export class UserPostsTopicoRequest extends Message<UserPostsTopicoRequest> {
  /**
   * @generated from field: repeated galois.proto.PostTopicoCombineRequest requests = 1;
   */
  requests: PostTopicoCombineRequest[] = [];

  constructor(data?: PartialMessage<UserPostsTopicoRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.UserPostsTopicoRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "requests", kind: "message", T: PostTopicoCombineRequest, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPostsTopicoRequest {
    return new UserPostsTopicoRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPostsTopicoRequest {
    return new UserPostsTopicoRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPostsTopicoRequest {
    return new UserPostsTopicoRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UserPostsTopicoRequest | PlainMessage<UserPostsTopicoRequest> | undefined, b: UserPostsTopicoRequest | PlainMessage<UserPostsTopicoRequest> | undefined): boolean {
    return proto3.util.equals(UserPostsTopicoRequest, a, b);
  }
}

/**
 * @generated from message galois.proto.TopicoResponse
 */
export class TopicoResponse extends Message<TopicoResponse> {
  /**
   * @generated from field: repeated common.proto.CategoryV2 categories_detected = 1;
   */
  categoriesDetected: CategoryV2[] = [];

  constructor(data?: PartialMessage<TopicoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.TopicoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "categories_detected", kind: "enum", T: proto3.getEnumType(CategoryV2), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicoResponse {
    return new TopicoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicoResponse {
    return new TopicoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicoResponse {
    return new TopicoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TopicoResponse | PlainMessage<TopicoResponse> | undefined, b: TopicoResponse | PlainMessage<TopicoResponse> | undefined): boolean {
    return proto3.util.equals(TopicoResponse, a, b);
  }
}

/**
 * @generated from message galois.proto.CategoryScore
 */
export class CategoryScore extends Message<CategoryScore> {
  /**
   * @generated from field: string category = 1;
   */
  category = "";

  /**
   * @generated from field: float score = 2;
   */
  score = 0;

  constructor(data?: PartialMessage<CategoryScore>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.CategoryScore";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "score", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CategoryScore {
    return new CategoryScore().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CategoryScore {
    return new CategoryScore().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CategoryScore {
    return new CategoryScore().fromJsonString(jsonString, options);
  }

  static equals(a: CategoryScore | PlainMessage<CategoryScore> | undefined, b: CategoryScore | PlainMessage<CategoryScore> | undefined): boolean {
    return proto3.util.equals(CategoryScore, a, b);
  }
}

/**
 * @generated from message galois.proto.PostTopicoImageResponse
 */
export class PostTopicoImageResponse extends Message<PostTopicoImageResponse> {
  /**
   * @generated from field: repeated galois.proto.CategoryScore categories = 1;
   */
  categories: CategoryScore[] = [];

  constructor(data?: PartialMessage<PostTopicoImageResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.PostTopicoImageResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "categories", kind: "message", T: CategoryScore, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostTopicoImageResponse {
    return new PostTopicoImageResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostTopicoImageResponse {
    return new PostTopicoImageResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostTopicoImageResponse {
    return new PostTopicoImageResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PostTopicoImageResponse | PlainMessage<PostTopicoImageResponse> | undefined, b: PostTopicoImageResponse | PlainMessage<PostTopicoImageResponse> | undefined): boolean {
    return proto3.util.equals(PostTopicoImageResponse, a, b);
  }
}

/**
 * @generated from message galois.proto.PostTopicoCaptionResponse
 */
export class PostTopicoCaptionResponse extends Message<PostTopicoCaptionResponse> {
  /**
   * @generated from field: repeated string categories = 1;
   */
  categories: string[] = [];

  constructor(data?: PartialMessage<PostTopicoCaptionResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.PostTopicoCaptionResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "categories", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostTopicoCaptionResponse {
    return new PostTopicoCaptionResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostTopicoCaptionResponse {
    return new PostTopicoCaptionResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostTopicoCaptionResponse {
    return new PostTopicoCaptionResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PostTopicoCaptionResponse | PlainMessage<PostTopicoCaptionResponse> | undefined, b: PostTopicoCaptionResponse | PlainMessage<PostTopicoCaptionResponse> | undefined): boolean {
    return proto3.util.equals(PostTopicoCaptionResponse, a, b);
  }
}

/**
 * @generated from message galois.proto.PostTopicoCombineResponse
 */
export class PostTopicoCombineResponse extends Message<PostTopicoCombineResponse> {
  /**
   * repeated string categories = 1;
   *
   * @generated from field: repeated galois.proto.PostCategories categories = 1;
   */
  categories: PostCategories[] = [];

  constructor(data?: PartialMessage<PostTopicoCombineResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.PostTopicoCombineResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "categories", kind: "enum", T: proto3.getEnumType(PostCategories), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PostTopicoCombineResponse {
    return new PostTopicoCombineResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PostTopicoCombineResponse {
    return new PostTopicoCombineResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PostTopicoCombineResponse {
    return new PostTopicoCombineResponse().fromJsonString(jsonString, options);
  }

  static equals(a: PostTopicoCombineResponse | PlainMessage<PostTopicoCombineResponse> | undefined, b: PostTopicoCombineResponse | PlainMessage<PostTopicoCombineResponse> | undefined): boolean {
    return proto3.util.equals(PostTopicoCombineResponse, a, b);
  }
}

/**
 * @generated from message galois.proto.BatchTopicoCombineResponseSingular
 */
export class BatchTopicoCombineResponseSingular extends Message<BatchTopicoCombineResponseSingular> {
  /**
   * @generated from field: string post_id = 1;
   */
  postId = "";

  /**
   * @generated from field: repeated galois.proto.PostCategories categories = 2;
   */
  categories: PostCategories[] = [];

  constructor(data?: PartialMessage<BatchTopicoCombineResponseSingular>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.BatchTopicoCombineResponseSingular";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "categories", kind: "enum", T: proto3.getEnumType(PostCategories), repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchTopicoCombineResponseSingular {
    return new BatchTopicoCombineResponseSingular().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchTopicoCombineResponseSingular {
    return new BatchTopicoCombineResponseSingular().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchTopicoCombineResponseSingular {
    return new BatchTopicoCombineResponseSingular().fromJsonString(jsonString, options);
  }

  static equals(a: BatchTopicoCombineResponseSingular | PlainMessage<BatchTopicoCombineResponseSingular> | undefined, b: BatchTopicoCombineResponseSingular | PlainMessage<BatchTopicoCombineResponseSingular> | undefined): boolean {
    return proto3.util.equals(BatchTopicoCombineResponseSingular, a, b);
  }
}

/**
 * @generated from message galois.proto.UserTopicoProbabilityCategory
 */
export class UserTopicoProbabilityCategory extends Message<UserTopicoProbabilityCategory> {
  /**
   * @generated from field: string category_name = 1;
   */
  categoryName = "";

  /**
   * @generated from field: float probability = 2;
   */
  probability = 0;

  constructor(data?: PartialMessage<UserTopicoProbabilityCategory>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.UserTopicoProbabilityCategory";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "category_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "probability", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserTopicoProbabilityCategory {
    return new UserTopicoProbabilityCategory().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserTopicoProbabilityCategory {
    return new UserTopicoProbabilityCategory().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserTopicoProbabilityCategory {
    return new UserTopicoProbabilityCategory().fromJsonString(jsonString, options);
  }

  static equals(a: UserTopicoProbabilityCategory | PlainMessage<UserTopicoProbabilityCategory> | undefined, b: UserTopicoProbabilityCategory | PlainMessage<UserTopicoProbabilityCategory> | undefined): boolean {
    return proto3.util.equals(UserTopicoProbabilityCategory, a, b);
  }
}

/**
 * @generated from message galois.proto.TopicoCombineResponseError
 */
export class TopicoCombineResponseError extends Message<TopicoCombineResponseError> {
  /**
   * @generated from field: string post_id = 1;
   */
  postId = "";

  /**
   * @generated from field: string error = 2;
   */
  error = "";

  constructor(data?: PartialMessage<TopicoCombineResponseError>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.TopicoCombineResponseError";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "post_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "error", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TopicoCombineResponseError {
    return new TopicoCombineResponseError().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TopicoCombineResponseError {
    return new TopicoCombineResponseError().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TopicoCombineResponseError {
    return new TopicoCombineResponseError().fromJsonString(jsonString, options);
  }

  static equals(a: TopicoCombineResponseError | PlainMessage<TopicoCombineResponseError> | undefined, b: TopicoCombineResponseError | PlainMessage<TopicoCombineResponseError> | undefined): boolean {
    return proto3.util.equals(TopicoCombineResponseError, a, b);
  }
}

/**
 * @generated from message galois.proto.BatchTopicoCombineResponse
 */
export class BatchTopicoCombineResponse extends Message<BatchTopicoCombineResponse> {
  /**
   * @generated from field: repeated galois.proto.BatchTopicoCombineResponseSingular responses = 1;
   */
  responses: BatchTopicoCombineResponseSingular[] = [];

  /**
   * @generated from field: repeated galois.proto.TopicoCombineResponseError errors = 2;
   */
  errors: TopicoCombineResponseError[] = [];

  constructor(data?: PartialMessage<BatchTopicoCombineResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.BatchTopicoCombineResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "responses", kind: "message", T: BatchTopicoCombineResponseSingular, repeated: true },
    { no: 2, name: "errors", kind: "message", T: TopicoCombineResponseError, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): BatchTopicoCombineResponse {
    return new BatchTopicoCombineResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): BatchTopicoCombineResponse {
    return new BatchTopicoCombineResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): BatchTopicoCombineResponse {
    return new BatchTopicoCombineResponse().fromJsonString(jsonString, options);
  }

  static equals(a: BatchTopicoCombineResponse | PlainMessage<BatchTopicoCombineResponse> | undefined, b: BatchTopicoCombineResponse | PlainMessage<BatchTopicoCombineResponse> | undefined): boolean {
    return proto3.util.equals(BatchTopicoCombineResponse, a, b);
  }
}

/**
 * @generated from message galois.proto.UserPostsTopicoResponse
 */
export class UserPostsTopicoResponse extends Message<UserPostsTopicoResponse> {
  /**
   * @generated from field: repeated galois.proto.UserTopicoProbabilityCategory probabilities = 1;
   */
  probabilities: UserTopicoProbabilityCategory[] = [];

  constructor(data?: PartialMessage<UserPostsTopicoResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "galois.proto.UserPostsTopicoResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "probabilities", kind: "message", T: UserTopicoProbabilityCategory, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UserPostsTopicoResponse {
    return new UserPostsTopicoResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UserPostsTopicoResponse {
    return new UserPostsTopicoResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UserPostsTopicoResponse {
    return new UserPostsTopicoResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UserPostsTopicoResponse | PlainMessage<UserPostsTopicoResponse> | undefined, b: UserPostsTopicoResponse | PlainMessage<UserPostsTopicoResponse> | undefined): boolean {
    return proto3.util.equals(UserPostsTopicoResponse, a, b);
  }
}


import { SafeResourceUrl } from '@angular/platform-browser';
import { Casting } from '@frontend2/proto/librarian/proto/casting_pb';
import { FlexibleCreatorSelector } from '@frontend2/proto/librarian/proto/common_pb';
import {
  CreatorCardSnippet,
  CreatorProfileCard,
} from '@frontend2/proto/librarian/proto/creators_pb';

export interface LeftyIframeMessage<T> {
  readonly type: 'lefty';
  readonly name: string;

  readonly data: T;
  readonly metadata: {
    readonly windowId?: string;
    readonly requestId?: number;
  };
}

export interface LeftyDialogVisibleEvent {
  readonly visible: boolean;
}

export interface LeftyBindIframeEvent {
  readonly windowId: string;
  readonly locationOrigin: string;
}

export interface LeftySyncDataEvent {
  readonly syncName: string;
  readonly jsonData: string;
}

export interface LeftyGetDataRequest {
  readonly syncName: string;
}

export interface LeftyRouteNotFoundEvent {
  readonly path: string;
}

export interface LeftyNavigateEvent {
  readonly url: string;
  readonly replace: boolean;
  readonly reload: boolean;
  readonly updateUrl: boolean;
}

export interface LeftyReplaceUrlEvent {
  readonly url: string;
}

export interface LeftyIframeState {
  readonly visible: boolean;
  readonly initialized: boolean;
  readonly dialogVisible: boolean;
  readonly url?: SafeResourceUrl;
}

// Note(hadrien): this is made from a legacy API that don't use camelCase.
// properties MUST stay in snake_case.
export interface TaskProgressUpdateEvent {
  readonly id: string;
  readonly done: boolean;
  readonly worked_on: boolean;
  readonly progress?: number;
  readonly progress_msg?: string;
  readonly progress_metadata?: string;
  readonly output?: string;
  readonly status?: number;
  readonly status_description?: string;
}

export const IFRAME_WINDOW_ID_KEY = '_wid';

export interface PreselectedInfluencersForGift {
  readonly influencers: CreatorCardSnippet[];
}

export type ManagementType =
  | 'Campaign'
  | 'Casting'
  | 'Affiliation'
  | 'Directory'
  | 'LandingPage';

export interface ManageInfluencerIframeEvent {
  readonly entityId: string;
  readonly entityName: string;

  readonly influencers: CreatorCardSnippet[];
  readonly selector: FlexibleCreatorSelector;
}

export function createManageInfluencerEvent(
  args: Partial<ManageInfluencerIframeEvent>,
): ManageInfluencerIframeEvent {
  return {
    entityId: args.entityId ?? '',
    entityName: args.entityName ?? '',
    influencers: args.influencers ?? [],
    selector: args.selector ?? new FlexibleCreatorSelector(),
  };
}

export type IframeEvents = {
  LeftyInitialized: undefined;
  LeftyDialogVisible: LeftyDialogVisibleEvent;
  LeftyRouteNotFound: LeftyRouteNotFoundEvent;
  LeftyNavigate: LeftyNavigateEvent;
  LeftyAutoDismiss: undefined;
  LeftyBindIframe: LeftyBindIframeEvent;
  LeftySyncData: LeftySyncDataEvent;
  LeftyReplaceUrl: LeftyReplaceUrlEvent;
  PreselectInfluencersForGifts: PreselectedInfluencersForGift;
};

export type IframeProtoEvents = {
  ConvertCastingToCampaign: Casting;
  RequestInfluencerMergeNetworks: CreatorProfileCard;
};

export interface LeftyIframePositionEvent {
  readonly offsetLeft: number;
}

export type IframeRequests = {
  LeftyIframePosition: {
    request: undefined;
    response: LeftyIframePositionEvent;
  };
  LeftyGetData: {
    request: LeftyGetDataRequest;
    response: LeftySyncDataEvent;
  };
};

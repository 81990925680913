import { inject, InjectionToken } from '@angular/core';
import { createPromiseClient, PromiseClient } from '@connectrpc/connect';
import { FrontendMisc } from '@frontend2/proto/librarian/proto/frontend_misc_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type FrontendMiscClient = PromiseClient<typeof FrontendMisc>;

export const FrontendMiscClientProvider = new InjectionToken(
  'FrontendMiscClient',
  {
    factory: (): FrontendMiscClient =>
      createPromiseClient(FrontendMisc, inject(GRPC_TRANSPORT)),
  },
);

export function injectLibrarianFrontendMiscClient(): FrontendMiscClient {
  return inject(FrontendMiscClientProvider);
}

<ng-container *ngIf="!mobileLayout && hasStats">
  <div class="stats-column">
    <div
      class="stats-item large"
      *ngFor="let stats of primaryStats"
    >
      <div class="value">{{ stats.value }}</div>
      <div class="body-1 label">{{ stats.label }}</div>
    </div>
  </div>

  <div class="stats-column">
    <div
      class="stats-item"
      *ngFor="let stats of secondaryStats"
    >
      <div class="value">{{ stats.value }}</div>
      <div class="body-1 label">{{ stats.label }}</div>
    </div>
  </div>
</ng-container>

<div
  class="mobile-grid"
  *ngIf="mobileLayout && hasStats"
>
  <div
    *ngFor="let stats of allStats"
    class="stats-item large"
  >
    <div class="value">{{ stats.value }}</div>
    <div class="body-1 label">{{ stats.label }}</div>
  </div>
</div>

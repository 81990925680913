// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/user_role.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { AssignUserToUserRoleRequest, GetAllUserRolesResponse, GetTeamMembersWithRolesResponse, UpdateUserPermissionsRequest, UserRolePb } from "./user_role_pb";
import { Empty, Int64Value, MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service librarian.proto.LibrarianPermissionsManagement
 */
export const LibrarianPermissionsManagement = {
  typeName: "librarian.proto.LibrarianPermissionsManagement",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianPermissionsManagement.CreateDefaultUserRoleAPI
     */
    createDefaultUserRoleAPI: {
      name: "CreateDefaultUserRoleAPI",
      I: UserRolePb,
      O: UserRolePb,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPermissionsManagement.GetAllUserRolesAPI
     */
    getAllUserRolesAPI: {
      name: "GetAllUserRolesAPI",
      I: Empty,
      O: GetAllUserRolesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPermissionsManagement.UpdateUserRoleAPI
     */
    updateUserRoleAPI: {
      name: "UpdateUserRoleAPI",
      I: UserRolePb,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPermissionsManagement.DeleteUserRoleAPI
     */
    deleteUserRoleAPI: {
      name: "DeleteUserRoleAPI",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPermissionsManagement.AssignUserToUserRoleAPI
     */
    assignUserToUserRoleAPI: {
      name: "AssignUserToUserRoleAPI",
      I: AssignUserToUserRoleRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPermissionsManagement.UpdateUserPermissionsAPI
     */
    updateUserPermissionsAPI: {
      name: "UpdateUserPermissionsAPI",
      I: UpdateUserPermissionsRequest,
      O: Empty,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPermissionsManagement.GetTeamMembersWithRoles
     */
    getTeamMembersWithRoles: {
      name: "GetTeamMembersWithRoles",
      I: Empty,
      O: GetTeamMembersWithRolesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianPermissionsManagement.EditTeamOwner
     */
    editTeamOwner: {
      name: "EditTeamOwner",
      I: Int64Value,
      O: Empty,
      kind: MethodKind.Unary,
    },
  }
} as const;


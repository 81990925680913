import { NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Optional,
  Self,
  Type,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { isNil, Messages, Networks } from '@frontend2/core';
import { Network } from '@frontend2/proto/common/proto/common_pb';
import { LoggedBootstrapping } from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import { Placement } from '@popperjs/core';
import { Subject } from 'rxjs';
import { LeftyFormValueBase } from '../../form';
import { LeftyFormSelectComponent } from '../../lefty-form-select/lefty-form-select.component';
import { SelectionModel } from '../../lefty-form-select/utils';
import { NetworkDropdownItemComponent } from '../../networks/network-dropdown-item.component';

@Component({
  selector: 'network-form-select',
  templateUrl: './network-form-select.component.html',
  styleUrls: ['./network-form-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftyFormSelectComponent, NgIf, NetworkDropdownItemComponent],
})
export class NetworkFormSelectComponent
  extends LeftyFormValueBase<Network | Network[]>
  implements OnInit
{
  constructor(@Self() @Optional() ngControl?: NgControl) {
    super(Network.NETWORK_UNKNOWN, ngControl);
    this.label = Messages.networks;

    this.disposer.add(this.popupVisibleChange);
  }

  private readonly _selectMsg = $localize`Select Network`;

  private _placeholder = this._selectMsg;

  readonly popupVisibleChange = new Subject<boolean>();

  @Input()
  isMultiSelect = false;

  selection = SelectionModel.single<Network>();

  @Input()
  withRemoveSelection = false;

  @Input()
  buttonClass = '';

  @Input()
  popupClass = '';

  @Input()
  popupPlacement: Placement = 'bottom-start';

  @Input()
  buttonText = '';

  @Input()
  user = new LoggedBootstrapping();

  @Input()
  networks: Network[] = [];

  get hasSelection(): boolean {
    return this.selection.isNotEmpty;
  }

  get placeholder(): string {
    if (this.hasSelection) {
      return '';
    }
    return this._placeholder;
  }

  networkItemFactory(): Type<unknown> {
    return NetworkDropdownItemComponent;
  }

  get options(): Network[] {
    return this.networks;
  }

  readableNetwork(network: Network): string {
    return Networks.readable(network);
  }

  override writeValue(obj: unknown): void {
    if (this.isMultiSelect) {
      this.handleMultiSelect(obj);
    } else {
      this.handleSingleSelect(obj);
    }
  }

  private handleSingleSelect(obj: unknown): void {
    if (typeof obj === 'number') {
      this.value = obj as Network;
    } else {
      this.value = Network.NETWORK_UNKNOWN;
    }
  }

  private handleMultiSelect(obj: unknown): void {
    if (Array.isArray(obj)) {
      this.value = obj as Network[];
    } else {
      this.value = [];
    }
  }

  override get value(): Network | Network[] {
    return super.value;
  }

  override set value(val: Network | Network[]) {
    super.value = val;
    this.selection = this._buildSelection(this.value);
  }

  ngOnInit(): void {
    this.selection = this._buildSelection(this.value);
  }

  protected _buildSelection(val: Network | Network[]): SelectionModel<Network> {
    if (this.isMultiSelect) {
      return Array.isArray(val)
        ? SelectionModel.multi(val)
        : SelectionModel.multi([]);
    } else {
      return Array.isArray(val) || val === Network.NETWORK_UNKNOWN
        ? SelectionModel.single()
        : SelectionModel.single(val);
    }
  }

  get selectedNetwork(): Network {
    if (Array.isArray(this.value) || isNil(this.value)) {
      return Network.NETWORK_UNKNOWN;
    }

    return this.value;
  }

  get selectedNetworks(): Network[] {
    if (Array.isArray(this.value)) {
      return this.value;
    }
    return [];
  }

  get formattedSelectedNetworks(): string {
    if (this.selectedNetworks.length === 0) {
      return '';
    }
    return this.selectedNetworks.map((n) => this.readableNetwork(n)).join(', ');
  }

  trackByNetwork(index: number, network: Network): Network {
    return network;
  }
}

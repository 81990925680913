// @generated by protoc-gen-connect-es v1.6.1 with parameter "target=ts"
// @generated from file librarian/proto/salesforce.proto (package librarian.proto, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import { GetAvailableSalesforceProductsRequest, GetAvailableSalesforceProductsResponse, GetSalesforceCatalogsRequest, GetSalesforceCatalogsResponse, GetSalesforceCategoriesRequest, GetSalesforceCategoriesResponse, GetSalesforceProductsRequest, GetSalesforceProductsResponse, ImportSalesforceProductsRequest, ImportSalesforceProductsResponse } from "./salesforce_pb";
import { MethodKind } from "@bufbuild/protobuf";

/**
 * @generated from service librarian.proto.LibrarianSalesforce
 */
export const LibrarianSalesforce = {
  typeName: "librarian.proto.LibrarianSalesforce",
  methods: {
    /**
     * @generated from rpc librarian.proto.LibrarianSalesforce.GetSalesforceStoreCatalogsAPI
     */
    getSalesforceStoreCatalogsAPI: {
      name: "GetSalesforceStoreCatalogsAPI",
      I: GetSalesforceCatalogsRequest,
      O: GetSalesforceCatalogsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianSalesforce.GetSalesforceStoreCategoriesAPI
     */
    getSalesforceStoreCategoriesAPI: {
      name: "GetSalesforceStoreCategoriesAPI",
      I: GetSalesforceCategoriesRequest,
      O: GetSalesforceCategoriesResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianSalesforce.GetSalesforceStoreProductsAPI
     */
    getSalesforceStoreProductsAPI: {
      name: "GetSalesforceStoreProductsAPI",
      I: GetSalesforceProductsRequest,
      O: GetSalesforceProductsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * Get the imported salesforce products from DB in the response.
     *
     * @generated from rpc librarian.proto.LibrarianSalesforce.GetAvailableSalesforceProductsAPI
     */
    getAvailableSalesforceProductsAPI: {
      name: "GetAvailableSalesforceProductsAPI",
      I: GetAvailableSalesforceProductsRequest,
      O: GetAvailableSalesforceProductsResponse,
      kind: MethodKind.Unary,
    },
    /**
     * @generated from rpc librarian.proto.LibrarianSalesforce.ImportSalesforceProductsAPI
     */
    importSalesforceProductsAPI: {
      name: "ImportSalesforceProductsAPI",
      I: ImportSalesforceProductsRequest,
      O: ImportSalesforceProductsResponse,
      kind: MethodKind.Unary,
    },
  }
} as const;


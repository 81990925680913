import {
  ChangeDetectionStrategy,
  Component,
  computed,
  HostListener,
  input,
  Output,
  output,
  signal,
  viewChild,
} from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { isNotEmptyString } from '@frontend2/core';
import { ContentMimeType } from '@frontend2/proto/common/proto/common_pb';
import { GenerikSnippet_GenerikUrl } from '@frontend2/proto/librarian/proto/users_pb';
import { LeftyIconComponent } from '../../icon/icon.component';
import { ObjectFit } from '../../utils';
import { LeftySafeVideoComponent } from '../../video/video.component';
import {
  createPost,
  getUrlsByPriority,
  getUrlsByType,
  POST_DEFAULT_RESOLUTIONS,
  POST_THUMBNAIL_RESOLUTIONS,
} from '../posts.models';

@Component({
  selector: 'lefty-post-video',
  template: `<lefty-safe-video
      [urls]="sanitizedUrls()"
      [objectFit]="objectFit()"
      (error$)="handleError($event)"
      [muted]="muted()"
      (loading$)="loading.set($event)"
      (visible$)="visible.set($event)"
      #videoElement
    ></lefty-safe-video>

    @if (
      playOnClick() &&
      videoComponent().isPlaying() === false &&
      hasError() === false &&
      loading() === false
    ) {
      <div class="play-icon">
        <lefty-icon icon="play_arrow"></lefty-icon>
      </div>
    } `,
  styleUrls: ['./post-video.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [LeftySafeVideoComponent, LeftyIconComponent],
})
export class LeftyPostVideoComponent {
  readonly objectFit = input<ObjectFit>('cover');
  readonly asThumbnail = input(false);
  readonly urls = input<GenerikSnippet_GenerikUrl[]>([]);
  readonly post = input(createPost());
  readonly muted = input(false);
  readonly playOnClick = input(false);

  readonly loading = signal(false);

  @Output()
  readonly loading$ = toObservable(this.loading);

  readonly visible = signal(false);

  @Output()
  readonly visible$ = toObservable(this.visible);

  readonly hasError = signal(false);
  readonly error$ = output<Event | 'timeout'>();

  handleError(error: Event | 'timeout'): void {
    this.hasError.set(true);
    this.error$.emit(error);
  }

  readonly sanitizedUrls = computed(() => {
    const urls = getUrlsByType(this.urls(), ContentMimeType.VIDEO);

    const resolutions = this.asThumbnail()
      ? POST_THUMBNAIL_RESOLUTIONS
      : POST_DEFAULT_RESOLUTIONS;

    return getUrlsByPriority(urls, resolutions)
      .map((u) => u.url)
      .filter(isNotEmptyString);
  });

  readonly videoComponent = viewChild.required(LeftySafeVideoComponent);

  play(): void {
    this.videoComponent().play();
  }

  pause(): void {
    this.videoComponent().pause();
  }

  togglePlay(): void {
    this.videoComponent().togglePlay();
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.playOnClick()) {
      event.preventDefault();
      event.stopPropagation();
      this.togglePlay();
    }
  }
}

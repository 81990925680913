<form
  [formGroup]="formModel"
  class="grid"
>
  <div class="col-12">
    <lefty-form-input
      formControlName="fullName"
      label="Full name"
      i18n-label
      placeholder="e.g. “Peter Smith”"
    >
    </lefty-form-input>
  </div>

  <div class="col-12">
    <lefty-form-input
      formControlName="company"
      label="Company"
      i18n-label
      placeholder="e.g. “Lefty”"
      [optional]="true"
    >
    </lefty-form-input>
  </div>

  <div class="col-12">
    <lefty-form-autocomplete
      formControlName="address"
      label="Address"
      i18n-label
      placeholder="Type an address"
      i18n-placeholder
      [noFiltering]="true"
      [options]="addressAutocompleteOptions()"
      [loading]="autocompleteLoading()"
      (inputTextChange)="searchForAddressAutocomplete($event)"
      [inputText]="addressInputText()"
      [itemRenderer]="addressAutocompleteRenderer"
      (valueChange)="autofillAddressForm($event)"
      [disabled]="addressDetailLoading()"
    >
    </lefty-form-autocomplete>
  </div>

  <div class="col-12">
    <lefty-form-input
      formControlName="address2"
      label="Apartement, suite, etc."
      i18n-label
      [optional]="true"
      placeholder="Type any further information"
      i18n-placeholder
    >
    </lefty-form-input>
  </div>

  <div class="col-6">
    <lefty-form-input
      formControlName="postalCode"
      label="Postal Code"
      i18n-label
      placeholder="Type an address"
      i18n-placeholder
      [disabled]="addressDetailLoading()"
    >
    </lefty-form-input>
  </div>

  <div class="col-6">
    <lefty-form-input
      formControlName="city"
      label="City"
      i18n-label
      placeholder="Type a city"
      i18n-placeholder
      [disabled]="addressDetailLoading()"
    >
    </lefty-form-input>
  </div>

  <div class="col-6">
    <lefty-form-input
      formControlName="province"
      label="Province/State"
      i18n-label
      [optional]="true"
      placeholder="Type an province"
      i18n-placeholder
      [disabled]="addressDetailLoading()"
    >
    </lefty-form-input>
  </div>

  <div class="col-6">
    <lefty-form-autocomplete
      formControlName="country"
      label="Country"
      i18n-label
      [popupMatchInputWidth]="true"
      [options]="countriesNames()"
      placeholder="Type a country"
      i18n-placeholder
      [disabled]="addressDetailLoading()"
    >
    </lefty-form-autocomplete>
  </div>

  <div class="divider"></div>

  <div class="col-12">
    <lefty-phone-number-input
      [optional]="!isPhoneRequired()"
      label="Phone number"
      formControlName="phone"
      i18n-label
      [defaultDialCode]="dialCode()"
    >
    </lefty-phone-number-input>
  </div>

  <div class="divider"></div>

  <div class="col-12">
    <lefty-form-input
      formControlName="email"
      label="Email"
      i18n-label
      placeholder="e.g. “lefty@lefty.io”"
      i18n-placeholder
    >
    </lefty-form-input>
  </div>
</form>

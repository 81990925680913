import { Component, Input, OnDestroy, Output, viewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { capitalize, isEqual, isNotEmptyString } from '@frontend2/core';
import { Placement } from '@popperjs/core';
import { filter, Observable, pairwise, Subject } from 'rxjs';
import { LeftyPopupComponent } from './lefty-popup/lefty-popup.component';
import { DropdownHandle, isEnterKey, LeftyComponent } from './utils';

@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class DropdownBase
  extends LeftyComponent
  implements OnDestroy, DropdownHandle
{
  constructor() {
    super();

    this.disposer.addSubject(this.popupVisibleChange);

    this.disposer.addFunction(() =>
      window.document.removeEventListener('keydown', this.enterPressedListener),
    );
  }

  private _label = '';

  get label(): string {
    return this._label;
  }

  @Input()
  set label(label: string | undefined) {
    this._label = label ? capitalize(label) : '';
  }

  @Input()
  autoDismiss = true;

  @Input()
  popupPlacement: Placement = 'bottom-start';

  get hasLabel(): boolean {
    return isNotEmptyString(this.label);
  }

  @Output()
  readonly popupVisibleChange = new Subject<boolean>();

  private _popupVisible = false;

  get popupVisible(): boolean {
    return this._popupVisible;
  }

  set popupVisible(newVal: boolean) {
    if (this.popupVisible !== newVal) {
      if (newVal === true) {
        this.listenForEnter();
      } else {
        window.document.removeEventListener(
          'keydown',
          this.enterPressedListener,
        );
      }

      this._popupVisible = newVal;
      this.popupVisibleChange.next(this.popupVisible);
      this.changeDetection.markForCheck();
    }
  }

  private enterPressedListener(event: KeyboardEvent): void {
    if (isEnterKey(event.code)) {
      this.popupVisible = false;
    }
  }

  private listenForEnter(): void {
    window.document.addEventListener('keydown', this.enterPressedListener);
  }

  readonly popupComponent = viewChild(LeftyPopupComponent);

  toggle(): void {
    this.popupComponent()?.toggle();
  }

  close(): void {
    this.popupComponent()?.close();
  }

  open(): void {
    this.popupComponent()?.open();
  }

  // DropdownHandle override
  get visible$(): Observable<boolean> {
    return this.popupVisibleChange;
  }

  // DropdownHandle override
  get isVisible(): boolean {
    return this.popupVisible;
  }

  @Output()
  readonly open$ = this.popupVisibleChange.pipe(
    pairwise(),
    filter(([prev, curr]) => prev === false && curr === true),
  );

  @Output()
  readonly close$ = this.popupVisibleChange.pipe(
    pairwise(),
    filter(([prev, curr]) => prev === true && curr === false),
  );
}

/// Shared logic for dropdown selector
///
/// Handling popup visiblity and selectionChange event
///
/// Close popup when press ENTER key
@Component({
  template: '',
})
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class SelectorDropdownBase<T> extends DropdownBase {
  constructor() {
    super();

    this.disposer.addSubject(this.selectionChange);

    this.close$.pipe(takeUntilDestroyed()).subscribe(() => {
      if (this.emitChangesOnClose) {
        this.selectionChange.next(this.selection);
      }
    });
  }

  @Output()
  readonly selectionChange = new Subject<T[]>();

  @Input()
  emitChangesOnClose = false;

  private _selection: T[] = Array.from(new Set<T>());

  get selection(): T[] {
    return this._selection;
  }

  @Input()
  set selection(newVal: T[]) {
    if (isEqual(this.selection, newVal) === false) {
      this._selection = Array.from(new Set(newVal));
      this.changeDetection.markForCheck();

      if (this.emitChangesOnClose === false) {
        this.selectionChange.next(this._selection);
      }
    }
  }

  abstract get options(): T[];

  get allSelected(): boolean {
    return (
      this.options.length !== 0 && this.selection.length === this.options.length
    );
  }

  @Input()
  canSelectAll = true;

  isSelected(item: T): boolean {
    return this.allSelected || this.selection.includes(item);
  }

  toggleItem(item: T): void {
    if (this.isSelected(item)) {
      this.unselectItem(item);
    } else {
      this.selectItem(item);
    }
  }

  selectItem(item: T): void {
    this.selection = [...this.selection, item];
  }

  unselectItem(item: T): void {
    this.selection = this.selection.filter((n) => n !== item);
  }

  selectAll(): void {
    this.selection = [...this.options];
  }

  clearAll(): void {
    this.selection = [];
  }
}

import { InjectionToken, inject } from '@angular/core';
import { PromiseClient, createPromiseClient } from '@connectrpc/connect';
import { LibrarianEntityLabels } from '@frontend2/proto/librarian/proto/entity_labels_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianEntityLabelsClient = PromiseClient<
  typeof LibrarianEntityLabels
>;

export const LibrarianEntityLabelsProvider = new InjectionToken(
  'LibrarianEntityLabelsClient',
  {
    factory: (): LibrarianEntityLabelsClient =>
      createPromiseClient(LibrarianEntityLabels, inject(GRPC_TRANSPORT)),
  },
);

export function injectLibrarianEntityLabelsClient(): LibrarianEntityLabelsClient {
  return inject(LibrarianEntityLabelsProvider);
}

import { CurrencyEnum } from '@frontend2/proto/common/proto/common_pb';
import { getEnumName, getLocale } from './utils';
import { isNil } from './utils/common.helpers';

export interface LeftyFormatOptions {
  readonly compact?: boolean;
  readonly showZero?: boolean;
  readonly currency?: CurrencyEnum;
  readonly style?: 'percent' | 'decimal' | 'currency' | 'unit';
  readonly maximumFractionDigits?: number;
  readonly locale?: string;
}

export abstract class LeftyFormat {
  static format(count: number | bigint, options?: LeftyFormatOptions): string {
    if ((count === 0 || count === BigInt(0)) && options?.showZero !== true) {
      return '-';
    }

    const currency =
      isNil(options?.currency) || options.currency === CurrencyEnum.NA
        ? CurrencyEnum.USD
        : options.currency;

    return new Intl.NumberFormat(options?.locale ?? getLocale(), {
      ...options,
      style: options?.style ?? 'decimal',
      notation: options?.compact ? 'compact' : undefined,
      currency: getEnumName(CurrencyEnum, currency),
    }).format(count);
  }

  static percent(count: number | bigint, options?: LeftyFormatOptions): string {
    return this.format(count, {
      ...options,
      style: 'percent',
      maximumFractionDigits: options?.maximumFractionDigits ?? 2,
    });
  }

  static profiles(
    count: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.format(count, options);
  }

  static pageViews(
    count: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.format(count, {
      ...options,
      compact: true,
    });
  }

  static followers(
    count: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.format(count, options);
  }

  static posts(count: number | bigint, options?: LeftyFormatOptions): string {
    return this.format(count, options);
  }

  static engagement(
    count: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.format(count, {
      ...options,
      compact: true,
    });
  }

  static likes(count: number | bigint, options?: LeftyFormatOptions): string {
    return this.engagement(count, options);
  }

  static comments(
    count: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.engagement(count, options);
  }

  static reads(count: number | bigint, options?: LeftyFormatOptions): string {
    return this.engagement(count, options);
  }

  static wows(count: number | bigint, options?: LeftyFormatOptions): string {
    return this.engagement(count, options);
  }

  static reshares(
    count: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.engagement(count, options);
  }

  static clicks(count: number | bigint, options?: LeftyFormatOptions): string {
    return this.engagement(count, options);
  }

  static reach(count: number | bigint, options?: LeftyFormatOptions): string {
    return this.format(count, {
      ...options,
      compact: true,
    });
  }

  static impressions(
    count: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.format(count, {
      ...options,
      compact: true,
    });
  }

  static views(count: number | bigint, options?: LeftyFormatOptions): string {
    return this.format(count, {
      ...options,
      compact: true,
    });
  }

  static avgReach(
    count: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.reach(count, options);
  }

  static avgCoverage(
    count: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.percent(count, options);
  }

  static saturationRate(
    count: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.percent(count, options);
  }

  static engagementRate(
    count: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.percent(count, options);
  }

  static currency(
    value: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.format(value, {
      ...options,
      style: 'currency',
    });
  }

  static emv(value: number | bigint, options?: LeftyFormatOptions): string {
    return this.currency(value, {
      ...options,
      compact: true,
    });
  }

  static spend(value: number | bigint, options?: LeftyFormatOptions): string {
    return this.currency(value, options);
  }

  static simpleCount(
    count: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.format(count, {
      ...options,
      showZero: true,
    });
  }

  static sales(value: number | bigint, options?: LeftyFormatOptions): string {
    return this.currency(value, {
      ...options,
      compact: true,
    });
  }

  static paidAmount(
    value: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.currency(value, {
      ...options,
      compact: true,
    });
  }

  static giftsAmount(
    value: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.currency(value, {
      ...options,
      compact: true,
    });
  }

  static influencerAveragePosts(
    value: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.format(value, {
      ...options,
      compact: true,
      maximumFractionDigits: 1,
    });
  }

  static campaigns(
    count: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.format(count, options);
  }

  static communityRetention(
    count: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return this.percent(count, options);
  }

  static rfm(count: number | bigint, options?: LeftyFormatOptions): string {
    return this.percent(count, options);
  }

  static roi(val: number | bigint, options?: LeftyFormatOptions): string {
    return this.percent(val, options);
  }

  static LOW_CPE = 0.01;

  private static _cpe(
    value: number | bigint,
    options?: LeftyFormatOptions,
  ): string {
    return LeftyFormat.currency(value, options);
  }

  static cpe(
    value: number | bigint,
    options?: LeftyFormatOptions,
    showLowCpe?: boolean,
  ): string {
    if (showLowCpe) {
      if (value !== 0 && value < LeftyFormat.LOW_CPE) {
        return '<' + LeftyFormat._cpe(LeftyFormat.LOW_CPE, options);
      }
      return LeftyFormat._cpe(value, { ...options, maximumFractionDigits: 2 });
    }

    return LeftyFormat._cpe(value, { ...options, maximumFractionDigits: 10 });
  }

  static cpm(value: number | bigint, options?: LeftyFormatOptions): string {
    return this.percent(value, options);
  }
}

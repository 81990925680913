import {
  ChangeDetectionStrategy,
  Component,
  Input,
  Output,
  computed,
  inject,
  signal,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Code, ConnectError } from '@connectrpc/connect';
import { LeftyAuthBloc } from '@frontend2/api';
import { LEFTY_LOGO, Messages, isNotEmptyString } from '@frontend2/core';
import {
  LoggedBootstrapping,
  LoginForm,
} from '@frontend2/proto/librarian/proto/frontend_misc_pb';
import { LeftyValidators } from '../form-validators.helpers';
import { injectToastManager } from '../toast/toast.service';
import { createOutput } from '../utils';
import { LeftyFooterInfoComponent } from '../lefty-footer-info/lefty-footer-info.component';
import { LeftyButtonDirective } from '../lefty-button-directive/lefty-button.directive';
import { LeftyFormInputComponent } from '../lefty-form-input/lefty-form-input.component';
import { LeftyFeedbackComponent } from '../lefty-feedback/lefty-feedback.component';
import { NgIf } from '@angular/common';
import { AppLogoDirective } from '../app-logo.directive';

@Component({
  selector: 'lefty-login-form',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'lefty-login-form.component.html',
  styleUrls: ['lefty-login-form.component.scss'],
  standalone: true,
  imports: [
    AppLogoDirective,
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    LeftyFeedbackComponent,
    LeftyFormInputComponent,
    LeftyButtonDirective,
    LeftyFooterInfoComponent,
  ],
})
export class LeftyLoginFormComponent {
  private _auth = inject(LeftyAuthBloc);
  @Input()
  title = '';

  @Input()
  leftyLogoSrc = LEFTY_LOGO;

  protected readonly toastManager = injectToastManager();

  @Input() resetPasswordLink = 'https://app.lefty.io/reset_password';

  @Output()
  readonly success$ = createOutput<LoggedBootstrapping>();

  @Output()
  readonly resourceExhausted$ = createOutput<ConnectError>();

  errorMessage = signal('');

  loading = signal(false);

  formModel = new FormGroup({
    email: new FormControl<string>('', [
      Validators.required,
      LeftyValidators.emailValidator,
    ]),
    password: new FormControl<string>('', Validators.required),
  });

  badCredentialsMessage = $localize`The email address or password you entered is incorrect.`;
  loginSucceedMessage = $localize`You are now logged in!`;

  get hasTitle(): boolean {
    return isNotEmptyString(this.title);
  }

  hasErrorMessage = computed(() => isNotEmptyString(this.errorMessage()));

  get year(): number {
    return new Date().getFullYear();
  }

  get helpLink(): string {
    return this.resetPasswordLink.startsWith('https://')
      ? ''
      : this.resetPasswordLink;
  }

  get externalHelpLink(): string {
    return this.resetPasswordLink.startsWith('https://')
      ? this.resetPasswordLink
      : '';
  }

  async submit(): Promise<void> {
    if (this.formModel.invalid) {
      return;
    }

    this.errorMessage.set('');
    this.loading.set(true);

    try {
      await this._login(
        this.formModel.get('email')?.value ?? '',
        this.formModel.get('password')?.value ?? '',
      );
    } catch (error) {
      if (error instanceof ConnectError) {
        this.handleError(error);
      }
    } finally {
      this.loading.set(false);
    }
  }

  private async _login(email: string, password: string): Promise<void> {
    const bootstrap = await this._auth.login(
      new LoginForm({ email: email, password: password }),
    );

    this.success$.next(bootstrap);

    this.toastManager.showSuccess(this.loginSucceedMessage);
  }

  handleError(error: ConnectError): void {
    if (error.code === Code.ResourceExhausted) {
      this.resourceExhausted$.next(error);
    }

    if (error.code === Code.Unauthenticated) {
      this.errorMessage.set(this.badCredentialsMessage);
    } else {
      this.errorMessage.set(Messages.errorHappen);
    }

    this.toastManager.showError(this.errorMessage());
  }
}

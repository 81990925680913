import { InjectionToken, inject } from '@angular/core';
import { PromiseClient, createPromiseClient } from '@connectrpc/connect';
import { LibrarianProducts } from '@frontend2/proto/librarian/proto/products_connect';
import { GRPC_TRANSPORT } from './grpc.service';

export type LibrarianProductsClient = PromiseClient<typeof LibrarianProducts>;

const factory = (): LibrarianProductsClient => {
  return createPromiseClient(LibrarianProducts, inject(GRPC_TRANSPORT));
};

const token = new InjectionToken('LibrarianProductsClient', { factory });

export function injectLibrarianProductsClient(): LibrarianProductsClient {
  return inject(token);
}

import { isNil } from '../utils/common.helpers';
import { Chip } from './chips.models';

export function createChip<Value>(
  value: Value,
  chip?: Partial<Chip<Value>>,
): Chip<Value> {
  return {
    ...chip,
    value,
    formattedValue: chip?.formattedValue ?? (isNil(value) ? '' : `${value}`),
    icon: chip?.icon ?? '',
    label: chip?.label ?? '',
  };
}

const _ghostValue = '__ghost__';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const _ghostCache = createChip<string>(_ghostValue);

export function isGhostChip(chip: Chip<unknown>): boolean {
  return _ghostCache === chip || chip.value === _ghostValue;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function createGhostChip(): Chip<any> {
  return _ghostCache;
}

import {
  ChangeDetectorRef,
  DestroyRef,
  NgZone,
  ProviderToken,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router } from '@angular/router';
import { BlocState } from '@frontend2/core';

export function injectChangeDetector(): ChangeDetectorRef {
  return inject(ChangeDetectorRef);
}

export function injectZone(): NgZone {
  return inject(NgZone);
}

export function injectActivatedRoute(): ActivatedRoute {
  return inject(ActivatedRoute);
}

export function injectRouter(): Router {
  return inject(Router);
}

export function injectDestroyRef(): DestroyRef {
  return inject(DestroyRef);
}

interface InjectBlocOptions {
  // trigger bloc.dispose when component get destroyed
  // false by default
  disposeOnDestroy?: boolean;
  // watch for bloc.state$ change
  // and trigger change detection
  // false by default
  watch?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function injectBloc<T extends BlocState<any>>(
  token: ProviderToken<T>,
  options?: InjectBlocOptions,
): T {
  const bloc = inject(token);

  if (options?.disposeOnDestroy === true) {
    const destroyRef = injectDestroyRef();
    destroyRef.onDestroy(() => bloc.dispose());
  }

  if (options?.watch === true) {
    const changeDetector = injectChangeDetector();
    bloc.state$
      .pipe(takeUntilDestroyed())
      .subscribe(() => changeDetector.markForCheck());
  }

  return bloc;
}

export function injectNavigationState(): Record<string, string> | undefined {
  const router = inject(Router);
  const navigation = router.getCurrentNavigation();

  return navigation?.extras.state;
}
